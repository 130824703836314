import gql from "graphql-tag";
import {COACHING_COMMITMENT_PROGRESS_TYPE,CUSTOM_USERCOMMITMENT_TYPE,DEFAULT_COMMITMENT_PROGRESS_TYPE,RESPONSE_COMPARE_REQUEST_TYPE,TOKEN_TYPE,RESPONSE_TYPE,COMMENT_TYPE,TEAM_TYPE,VERTICAL_TYPE,ORGANIZATION_TYPE,EMPLOYEE_TYPE,CONTACT_REQUEST_TYPE,ACTION_ITEM_TYPE,MEETING_NOTES_TYPE,MESSAGE_TYPE,FEEDBACK_TYPE,READINESS_TYPE,READINESS_LEVEL_TYPE,MEETING_TYPE, OKR_TYPE, CONTRIBUTOR_TYPE, BADGE_AWARD_TYPE, COMPETENCY_TYPE, TRACKING_TYPE, DISCARD_REQUEST_TYPE, IDP_TYPE } from './NotificationFragments.gql';
export const NOTIFICATION_SUBSCRIPTION = gql`
 ${COACHING_COMMITMENT_PROGRESS_TYPE},
  ${CUSTOM_USERCOMMITMENT_TYPE},${DEFAULT_COMMITMENT_PROGRESS_TYPE},
  ${RESPONSE_COMPARE_REQUEST_TYPE},${TOKEN_TYPE}
  ${RESPONSE_TYPE},${COMMENT_TYPE},${TEAM_TYPE},${VERTICAL_TYPE},
  ${ORGANIZATION_TYPE},${EMPLOYEE_TYPE},${CONTACT_REQUEST_TYPE},${ACTION_ITEM_TYPE},
  ${MEETING_NOTES_TYPE},${MESSAGE_TYPE},${FEEDBACK_TYPE},
  ${READINESS_TYPE},${READINESS_LEVEL_TYPE},${MEETING_TYPE},${CONTRIBUTOR_TYPE},${BADGE_AWARD_TYPE},${OKR_TYPE},${COMPETENCY_TYPE},${TRACKING_TYPE},${DISCARD_REQUEST_TYPE},${IDP_TYPE}
subscription notificationSubscription {
    notificationSubscription {
        mutation
        notification{
            id,
            actionTime,
            readTime,
            title,
            changeMessage,
            relatedObject{
              __typename
                ...CoachingCommitmentProgressType
                ...CustomUserCommitmentType
                ...DefaultCommitmentProgressType
                ...ResponseCompareRequestType
                ...TokenType
                ...ResponseType
                ...CommentType
                ...TeamType
                ...VerticalType
                ...OrganizationType
                ...EmployeeType
                ...ContactRequestType
                ...ActionItemType
                ...MeetingNotesType
                ...OKRMessageType
                ...FeedbackType
                ...ReadinessType
                ...ReadinessLevelType
                ...MeetingType
                ...ContributorType
                ...BadgeAwardType
                ...okrType
                ...CompetencyType
                ...TrackingType
                ...DiscardRequestType
                ...IdpType
            }
        }
    }
}
`


export const NOTIFICATION_SUBSCRIPTION_NAV_BAR = gql`

subscription notificationSubscription {
    notificationSubscription {
        mutation
        notification{
            id
        }
    }
}
`