import { graphql } from '@apollo/client/react/hoc';
import { ADD_CONDITION_MUTATION } from '../graphql/AddCondition.gql';
import { ADD_TEXT_SECTION_MUTATION } from '../graphql/AddTextSection.gql';
import { ADD_OPERAND_MUTATION } from '../graphql/AddOperand.gql';
import { ADD_WEIGHTED_QUESTION_MUTATION } from '../graphql/AddWeightedQuestion.gql';

import { EDIT_TEXT_SECTION } from '../graphql/EditTextSection.gql.js';
import { EDIT_CONDITION } from '../graphql/EditCondition.gql';
import { EDIT_OPERAND } from '../graphql/EditOperand.gql';
import { EDIT_WEIGHTED_QUESTION } from '../graphql/EditWeightedQuestion.gql';

import { DELETE_CONDITION } from '../graphql/DeleteCondition.gql';
import { DELETE_TEXT_SECTION } from '../graphql/DeleteTextSection.gql';
import { message } from 'antd';


  export const withAddCondtion = Component =>
  graphql( ADD_CONDITION_MUTATION, {
    props: ( { mutate, history } ) => ( {
      createCondition: async values => {
        try {
          const {
            data: { createCondition }
          } = await mutate( {
            variables: {
              ...values
            }
          } );

          message.destroy();
          message.success( 'Submitted response!!' );
          return createCondition.condition;
        } catch ( e ) {
          message.destroy();
          message.error( "Couldn't perform the action" );
        }
      }
    } )
  } )( Component );

  export const withAddTextSection = Component =>
  graphql( ADD_TEXT_SECTION_MUTATION, {
    props: ( { mutate, history } ) => ( {
      createTextSection: async values => {
        try {
          const {
            data: { createTextSection }
          } = await mutate( {
            variables: {
              ...values
            }
          } );

          message.destroy();
          message.success( 'Submitted response!!' );
          return createTextSection.textSection;
        } catch ( e ) {
          message.destroy();
          message.error( "Couldn't perform the action" );
        }
      }
    } )
  } )( Component );

  export const withAddOperand = Component =>
  graphql( ADD_OPERAND_MUTATION, {
    props: ( { mutate, history } ) => ( {
      createOperand: async values => {
        try {
          const {
            data: { createOperand }
          } = await mutate( {
            variables: {
              ...values
            }
          } );

          message.destroy();
          message.success( 'Submitted response!!' );
          return createOperand.operand;
        } catch ( e ) {
          message.destroy();
          message.error( "Couldn't perform the action" );
        }
      }
    } )
  } )( Component );

  export const withAddWeightedQuestion = Component =>
  graphql( ADD_WEIGHTED_QUESTION_MUTATION, {
    props: ( { mutate, history } ) => ( {
      createWeightedQuestion: async values => {
        try {
          const {
            data: { createWeightedQuestion }
          } = await mutate( {
            variables: {
              ...values
            }
          } );

          message.destroy();
          message.success( 'Submitted response!!' );
          return createWeightedQuestion.weightedQuestion;
        } catch ( e ) {
          message.destroy();
          message.error( "Couldn't perform the action" );
        }
      }
    } )
  } )( Component );

export const withEditTextSection = Component =>
  graphql(EDIT_TEXT_SECTION, {
    props: ({ mutate }) => ({
      editTextSection: async values => {
        try {
          const {
            data: { updateTextSection }
          } = await mutate({
            variables: {
              ...values
            }
          });
          return updateTextSection.textSection;
        } catch (e) {
          console.error(e);
        }
      }
    })
  })(Component);

export const withEditCondition = Component =>
  graphql(EDIT_CONDITION, {
    props: ({ mutate }) => ({
      editCondition: async values => {
        try {
          const {
            data: { updateCondition }
          } = await mutate({
            variables: {
              ...values
            }
          });
          return updateCondition.condition;
        } catch (e) {
          console.error(e);
        }
      }
    })
  })(Component);

export const withEditOperand = Component =>
  graphql(EDIT_OPERAND, {
    props: ({ mutate }) => ({
      editOperand: async values => {
        try {
          const {
            data: { updateOperand }
          } = await mutate({
            variables: {
              ...values
            }
          });
          return updateOperand.operand;
        } catch (e) {
          console.error(e);
        }
      }
    })
  })(Component);

export const withEditWeightedQuestion = Component =>
  graphql(EDIT_WEIGHTED_QUESTION, {
    props: ({ mutate }) => ({
      editWeightedQuestion: async values => {
        try {
          const {
            data: { updateWeightedQuestion }
          } = await mutate({
            variables: {
              ...values
            }
          });
          return updateWeightedQuestion.weightedQuestion;
        } catch (e) {
          console.error(e);
        }
      }
    })
  })(Component);

  export const withDeleteCondition = Component =>
  graphql(DELETE_CONDITION, {
    props: ({ mutate, history }) => ({
      deleteCondition: async values => {
        try {
          const {
            data: { deleteCondition }
          } = await mutate({
            variables: {
              ...values
            }
          });
  
          message.destroy();
          message.success('Successfully deleted');
          return deleteCondition.condition;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
          console.error(e);
        }
      }
    })
  })(Component);

  export const withDeleteTextSection = Component =>
  graphql(DELETE_TEXT_SECTION, {
    props: ({ mutate, history }) => ({
      deleteTextSection: async values => {
        try {
          const {
            data: { deleteTextSection }
          } = await mutate({
            variables: {
              ...values
            }
          });
  
          message.destroy();
          message.success('Successfully deleted');
          return deleteTextSection.textSection;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
          console.error(e);
        }
      }
    })
  })(Component);
