import React from 'react';
import _ from 'lodash';
import { message, Modal, Row, Col, Table, Button, Form, Checkbox } from 'antd';
import {
  InputValues,
  SelectField,
  displayDataCheck,
  CKEditorField,
  TextField,
  DeleteIcon,
  EditIcon,
  getIntFromString,
  InputValuesObject
} from '../../look';
import { PlusOutlined } from '@ant-design/icons';
import { withDeleteChoice } from '../containers/SurveyOperations';
import ReactMarkdown from '../../look/components/ReactMarkdown';

export const CKEditorFieldName2 = 'label';

const ChoiceFormComponent = props => {
  const { choices, setChoices, deleteChoice } = props;
  const [selectedRowKeys, setKeys] = React.useState([]);
  const [visible, setVisible] = React.useState(false);
  const [initialValues, setInitialValues] = React.useState({});
  const [edit, setEdit] = React.useState(false);
  const [value, setValue] = React.useState('');
  const [form] = Form.useForm();
  const [isExpectedAnswer, setIsExpectedAnswer] = React.useState(false);

  const handleEdit = record => {
    // console.log(record, 'record');
    setEdit(true);
    const obj = {
      id: record.id,
      label: typeof record.label === 'string' ? record.label : record.label.editor?.getData(),
      value: record.value,
      sequence: record.sequence,
      answerKey: record.answerKey,
      // graphic: record.graphic,
      inputType: record.inputType,
      min: record.inputType === 'range' ? record.value.split(':')[0] : 0,
      max: record.inputType === 'range' ? record.value.split(':')[1] : 0,
      jump: record.inputType === 'range' ? record.value.split(':')[2] : 0,
      default: record.inputType === 'range' ? record.value.split(':')[3] : 0
    };
    setInitialValues(obj);
    setValue(record.inputType);
    setIsExpectedAnswer(record.answerKey)
    form.setFields(Object.keys(obj).map(key => ({ name: key, value: obj[key] })));
    // setVisible(true);
  };

  const handleDelete = async record => {
    if (record.id) {
      try {
        const response = await deleteChoice({id:getIntFromString(record.id)});
        message.success('Success!');
        if (response) {
          let remainChoices = choices.filter(choice => choice.id !== record.id);
          setChoices([...remainChoices].map((ci, idx) => ({ ...ci, idx })));
        }
      } catch (e) {
        message.error('Failed!');
        throw Error(e.message);
      }
    } else {
      let remainChoices = choices.filter((choice, idx) => idx !== record.idx);
      setChoices([...remainChoices].map((ci, idx) => ({ ...ci, idx })));
    }
  };

  const columns = [
    {
      title: 'Label',
      key: 'label',
      render: (text, record) => (
        <ReactMarkdown>{typeof record.label === 'string' ? record.label : record.label.editor?.getData()}</ReactMarkdown>
      )
    },
    {
      title: 'Value',
      key: 'value',
      render: (text, record) => <>{record && displayDataCheck(record.value)}</>
    },
    // {
    //   title: 'Graphic',
    //   key: 'graphic',
    //   render: (text, record) => <>{record && displayDataCheck(record.graphic)}</>
    // },
    {
      title: 'Sequence',
      key: 'sequence',
      render: (text, record) => <>{record && record.sequence}</>
    },
    {
      title: 'Input type',
      key: 'inputType',
      render: (text, record) => <>{record && displayDataCheck(record.inputType)}</>
    },
    {
      title: 'Expected Answer',
      key: 'inputType',
      render: (text, record) => <>{record && displayDataCheck(String(record.answerKey))}</>
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'center',
      render: (text, record) => (
        <>
          <EditIcon
            tooltipSuffix="Choice"
            onClick={() => {
              handleEdit(record);
              setVisible(true);
            }}
          />
          &nbsp;&nbsp;
          <DeleteIcon tooltipSuffix="Choice" onClick={() => handleDelete(record)} />
        </>
      )
    }
  ];

  const handleChange = selectedRowKeys => {
    setKeys(selectedRowKeys);
  };

  const handelCheck = e => {
    setIsExpectedAnswer(e.target.checked)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: handleChange
  };

  const RenderTable = () => (
    <>
      <Table
        rowSelection={rowSelection}
        rowKey="id"
        dataSource={choices && choices.length > 0 ? choices.map((node, idx) => ({ ...node, idx })) : []}
        columns={columns}
      />
    </>
  );

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
  };

  const addChoice = values => {
    let editChoices = choices;
    let data;
    if (values.inputType === 'range') {
      values.value = `${values.min}:${values.max}:${values.jump}:${values.default}`;
      delete values.min;
      delete values.max;
      delete values.jump;
      delete values.default;
    }
    data = {
      ...values,
      label: typeof values.label === 'string' ? values.label : values.label.editor?.getData(),
      sequence: Number(values.sequence),
      answerKey: isExpectedAnswer
    };
    if (edit) {
      editChoices = choices.filter(choice => choice.id !== initialValues.id);
      data = {
        ...values,
        id: initialValues.id,
        label: typeof values.label === 'string' ? values.label : values.label.editor?.getData(),
        sequence: Number(values.sequence),
        answerKey: isExpectedAnswer
      };
    }
    setChoices([...editChoices, data].map((ci, idx) => ({ ...ci, idx })));
    form.resetFields();
    setInitialValues({});
    setIsExpectedAnswer(false)
    setVisible(false);
    setEdit(false);
  };
  const answerInputs = [InputValuesObject.checkbox.toLowerCase(), InputValuesObject.radio.toLowerCase()]

  return (
    <>
      <div>
        <Row>
          <Row gutter={24}>
            <Col span={10}>
              <h2>Choices</h2>
            </Col>

            <Col span={14}>
              <Button
                size="md"
                type="primary"
                ghost
                onClick={() => {
                  setInitialValues({});
                  setVisible(true);
                  setEdit(false);
                  setValue('')
                }}
              >
                <PlusOutlined />
                Add Choice
              </Button>
            </Col>
          </Row>
          <br />
          <Col span={23}>
            {/* {loading && <Loading />} */}
            {choices && <RenderTable />}
          </Col>
        </Row>
        <br />

        <Modal
          footer={null}
          centered
          width="42%"
          visible={visible}
          destroyOnClose={true}
          onCancel={() => {
            setVisible(false);
            form.resetFields();
            setInitialValues({});
            // form.setFields([{ name: 'value', value: 0 }]);
          }}
        // bodyStyle={{ padding: '0px' }}
        >
          <Row>
            <Col span={23}>
              <h3>Add Choice</h3>
            </Col>
          </Row>
          {/* {console.log(initialValues)} */}

          <Form form={form} onFinish={addChoice} initialValues={initialValues}>
            <Row justify="start">
              <Col lg={23} md={24} xs={24}>
                <CKEditorField
                  name={CKEditorFieldName2}
                  label="Label"
                  initialValue={initialValues && initialValues.label}
                  rules={[{ required: true, message: 'Label is required!' }]}
                />
                {/* <TextField
                  {...layout}
                  name="label"
                  label="Label"
                  rules={[{ required: true, message: 'Label is required!' }]}
                /> */}

                <SelectField
                  {...layout}
                  margin={false}
                  className= "question-input"
                  name="inputType"
                  label="Input Type"
                  choices={InputValues}
                  onChange={e => {
                    setValue(e);
                  }}
                // rules={[{ required: true, message: 'Input Value is required!' }]}
                />
                {value === 'range' ? (
                  <>
                    <TextField
                      {...layout}
                      name="max"
                      label="Max"
                      rules={[{ required: true, message: 'Value is required!' }]}
                    />
                    <TextField
                      {...layout}
                      name="min"
                      label="Min"
                      rules={[{ required: true, message: 'Value is required!' }]}
                    />
                    <TextField
                      {...layout}
                      name="jump"
                      label="Jump"
                      rules={[{ required: true, message: 'Value is required!' }]}
                    />
                    <TextField
                      {...layout}
                      name="default"
                      label="Default"
                      rules={[{ required: true, message: 'Value is required!' }]}
                    />
                  </>
                ) : (
                  <TextField
                    {...layout}
                    name="value"
                    label="Value"
                    rules={[{ required: true, message: 'Value is required!' }]}
                  />
                )}

                <TextField
                  {...layout}
                  name="sequence"
                  label="Sequence"
                  rules={[{ required: true, message: 'Sequence is required!' }]}
                />
                {/* <TextField
                  {...layout}
                  name="graphic"
                  label="Graphic"
                  // rules={[{ required: true, message: 'Graphic is required!' }]}
                /> */}

              </Col>
            </Row>

            <Row justify={answerInputs.includes(value) ? "space-between" : "end"}>
              {answerInputs.includes(value) &&
              <div className="checkbox-container">
                <label htmlFor="answerKey" style={{paddingRight: 7}}> Expected Answer </label>
                <Checkbox
                  name='answerKey'
                  defaultChecked={isExpectedAnswer}
                  checked={isExpectedAnswer}
                  onChange={handelCheck}
                />
                </div>}
              <Button type="link" htmlType="submit">
                Done
              </Button>
            </Row>
          </Form>
        </Modal>
      </div>
    </>
  );
};

export default withDeleteChoice(ChoiceFormComponent);
