import gql from 'graphql-tag';


export const CREATE_FEEDBACK_MUTATION = gql`
  mutation createFeedback($answers: [AnswerInput]!,$feedbackData: FeedbackInput!,$surveyId: Int!) {
    createFeedback(
        feedbackData:$feedbackData
        answers:$answers,
        surveyId:$surveyId
        ) {
        feedback {
         id
      }
    }
  }
`;
