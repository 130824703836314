// DO NOT EDIT THIS FILE

import gql from 'graphql-tag';

export const ASSIGNE_VERTICAL = gql`
  query me($search:String) {
    assignes:me{
        id
        employee{
          id
          verticalHeadEmployee(employees_User_FirstName_Icontains:$search) {
            edges{
              node{
                id
                employees(user_FirstName:$search){
                  edges{
                    node{
                      id
                      user{
                        id
                        firstName
                        lastName
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
  }
`;
