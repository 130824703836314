import gql from 'graphql-tag';
import { DEFAULT_COMMITMENTS_PROGRESS_TYPE } from './DefaultCommitmentProgressType.gql';

export const EDIT_DEFAULT_TOOL_COMMITMENT = gql`
  ${DEFAULT_COMMITMENTS_PROGRESS_TYPE}
  mutation updateDefaultToolCommitment($completed: Boolean!, $id: ID!) {
    updateDefaultToolCommitment(completed: $completed, id: $id) {
      defaultCommitmentProgress {
        ...DefaultCommitmentProgressType
      }
    }
  }
`;
