import gql from 'graphql-tag';

export const SKILLS_SET = gql`
query allCompetencies($title_Icontains:String,$competencyType: String){
    allCompetencies(title_Icontains:$title_Icontains,competencyType:$competencyType){
        edges{
          node{
            id
            title
            competencyType
          }
        }
      }
}
`

export const SKILLS_SET_SUBSCRIPTION = gql`
  subscription competencySubscriptions{
    competencySubscription {
      mutation
      competency{
        id
        title
        competencyType
      }
    }
  }
`;