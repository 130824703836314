import gql from 'graphql-tag';

export const ALL_COMPETENCY = gql`
  query allCompetencies($title_Icontains:String,$competencyType:String) {
    allCompetencies(title_Icontains:$title_Icontains,competencyType:$competencyType) {
      edges {
        node {
            id
            competencyType
            title
        }
      }
    }
  }
`;


export const COMPETENCY_PERMISSIONS = gql`
query getPermissions($codename:String){
  getPermissions(codename:$codename){
    edgeCount
  }
}
`