import gql from 'graphql-tag';
import { ORGANIZATION_TYPE } from './OrganizationType.gql';

export const DELETE_ORGANIZATION = gql`
  ${ORGANIZATION_TYPE}
  mutation deleteOrganization($id: ID!) {
    deleteOrganization(id: $id) {
      organization {
        ...OrganizationType
      }
    }
  }
`;
