import React from 'react';

import StoryDetailView from '../components/AdminStoryDetailView';

const StoryDetail = props => {
  const handleLike = () => {};
  if (typeof window !== 'undefined') {
    window.onbeforeunload = e => {
      e.preventDefault();
      // I'm about to refresh! do something...
      e.close();
    };
  }

  return <StoryDetailView onLike={handleLike} {...props} />;
};

export default StoryDetail;
