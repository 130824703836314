// @ts-nocheck
import { compose } from 'modules/core';
import React from 'react';
import { withApollo } from '@apollo/client/react/hoc';
import { ALL_EMPLOYEES_SURVEY_TOKEN } from '../graphql/AllEmployees.gql';
import { withCreateTokenBySurveyOptimized } from './SurveyOperations';
import { SURVEY_RESPONSES } from '../graphql/SurveyReponses.gql';
import SurveyTokenView from '../components/SurveyTokenView';
import { GET_USER_BY_USERNAME_OR_EMAIL_QUERY } from 'modules/user/graphql/GetUserByUsernameOrEmailQuery.gql';
import UserAccountUpdateView from '../components/userAccountUpdateview';

const UserAccountUpdate = props => {
    
    const { me,user_detail } = props
 
    

    return (
     <UserAccountUpdateView {...props} />
    )
};

export default compose(withApollo)(UserAccountUpdate);

