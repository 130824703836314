import { compose } from 'modules/core'
import React from 'react'
import ScapeReportView from '../components/scapeReportView'
import { withSurveyList } from './scrapeReportoperation'
import { SURVEY_BY_ID } from '../graphql/surveyById.gql'
import { withApollo } from '@apollo/client/react/hoc';
import {  globalPermissionValidator } from 'modules/look'
import { TABLE_DATA_NEW_QUERY } from '../graphql/TableDataNew.gql'
import { ALL_EMPLOYEE_LIST } from '../graphql/getAllEmployee.gql'
import NoPermissionView from 'modules/kudos-user/component/NoPermissionComponent'
import { soul_permission } from 'Permissions/soul.permission'
import { scape_report_permission } from 'Permissions/ScapeReport.permission'

const ScrapeReportContainer = (props) => {
    const { me, client, permissionLoader, userPermission } = props
    const [responseSurveyAnswer, setResponseSurveyAnswer] = React.useState([])
    const [responseTableData, setResponseTableData] = React.useState([])
    const [responseTableDataNew, setResponseTableDataNew] = React.useState([])
    const [scapeReportPermitted, setScapeReportPermitted] = React.useState(false);
    const [loading, setLoading] = React.useState(false)
    const [employeeList, setEmployeeList] = React.useState([])
    const selectedId = (val) => {
        setLoading(true)
        featchSurveyAnswers(val?.surveyId)
        featchNewTableData(val)
        // featchTableData(val)
        setResponseTableData([])
        setResponseSurveyAnswer([])
    }

    React.useEffect(() => {

        if (userPermission?.length) {
            let permission = globalPermissionValidator(scape_report_permission, userPermission)
            if (permission?.required_permission) {
                setScapeReportPermitted(permission)

            }
        }

    }, [userPermission])

    const featchSurveyAnswers = async (surveyId) => {
        const { data } = await client.query({
            query: SURVEY_BY_ID,
            fetchPolicy: 'network-only',
            variables: { id: surveyId },
        });
        if (data) {
            let surveyList = [].concat.apply([], data?.SurveyById?.groupSet?.edges[0]?.node?.questionSet?.edges.map(({ node }) => node?.choiceSet?.edges.map(({ node }) => node)))
            let surveyAfterRemoveDuplicates = removeDuplicateObjectFromArray(surveyList, 'value')
            setResponseSurveyAnswer(surveyAfterRemoveDuplicates)
            setLoading(false)
        }
    }
    function removeDuplicateObjectFromArray(array, key) {
        var check = new Set();
        return array.filter(obj => !check.has(obj[key]) && check.add(obj[key]));
    }

    const featchNewTableData = async (value) => {
        const { data } = await client.query({
            query: TABLE_DATA_NEW_QUERY,
            fetchPolicy: 'network-only',
            variables: { id: value?.surveyId, id_In: value?.employeeIds.join(',') },
        });
        if (data) {
            let tableData = data?.tableDataNew?.employee?.organizationSet?.edges[0]?.node?.employees?.edges.map(({ node }) => node)
            setResponseTableDataNew(tableData)
            // setLoading(false)
        }
    }
    const getAllEmployeeList = async () => {
        const { data } = await client.query({
            query: ALL_EMPLOYEE_LIST,
            fetchPolicy: 'network-only',
            variables: {},
        });
        if (data) {
            let list
            let teamMembersEmployee = [].concat.apply(
                [], data?.me?.employee?.teamMembersEmployee?.edges?.map(({ node }) => node?.managers?.edges?.map(({ node }) => node)))
            let teamManager = [].concat.apply(
                [], data?.me?.employee?.teamManagerEmployee?.edges?.map(({ node }) => node?.vertical?.heads?.edges?.map(({ node }) => node)))
            let teamManagerEmployee = [].concat.apply(
                [], data?.me?.employee?.teamManagerEmployee?.edges?.map(({ node }) => node?.employees?.edges?.map(({ node }) => node)))
            let orgPocEmployee = [].concat.apply(
                [], data?.me?.employee?.orgPocEmployee?.edges?.map(({ node }) => node?.employees?.edges?.map(({ node }) => node)))
            let orgCeoEmployee = [].concat.apply(
                [], data?.me?.employee?.orgCeoEmployee?.edges?.map(({ node }) => node?.employees?.edges?.map(({ node }) => node)))

            list = [].concat(teamMembersEmployee, teamManager, teamManagerEmployee, orgPocEmployee, orgCeoEmployee)
            let datalist = list.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t?.firstName === value?.firstName && t?.id === value?.id
                )))
            let filtered = datalist.filter(item => item?.employee?.id !== me?.employee?.id)
            setEmployeeList(filtered?.filter(item => item !== undefined))

        }
    }
    React.useEffect(() => {
        if (me) {
            getAllEmployeeList()
        }
    }, me)
    return (
        <div>
            {
                !permissionLoader &&
                <>
                    {
                        (scapeReportPermitted?.required_permission) ?
                            <ScapeReportView {...props} onSelectedId={(e) => selectedId(e)} responseSurveyAnswer={responseSurveyAnswer} responseTableData={responseTableData} responseTableDataNew={responseTableDataNew} loading={loading} employeeList={employeeList} />
                            :
                            <NoPermissionView />
                    }
                </>
            }
        </div>

    )
}

export default compose(withSurveyList, withApollo)(ScrapeReportContainer)