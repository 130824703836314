import gql from 'graphql-tag';

export const TEAM_MEMBER_LIST =gql`
query TeamByTeamId($id: ID!,$okr_Duedate_Range: [DateTime],$offset: Int,$first:Int){
    teamData:Team(id: $id) {
        id
        employees(first: $first,offset:$offset) {
            edgeCount
            totalCount
            edges {
                node {
                    id
                    user{
                        id
                        firstName
                        lastName
                        profile{
                            id
                            profileImage
                        }
                    }
                    okrContributorSet(savedOkrType: "OBJECTIVE", okr_Duedate_Range:$okr_Duedate_Range,orderBy: ["okr__duedate"]) {
                        edges {
                            node {
                            id
                            progress
                            }
                        }
                    }
                }
            }
        }
    }
}
`