import gql from 'graphql-tag';

export const ALL_BADGES_LIST =gql`
    query Badges($title_Icontains: String,$behaviouralDescription_Icontains:String,$badgeFamily: ID){
        badgeByTitle:Badges(title_Icontains:$title_Icontains,badgeFamily:$badgeFamily){
            edges {
              node {
                badgeFamily{
                  title
                  description
                  id
                }
                id
                image
                title
                behaviouralDescription
                duration
              }
        
            }
        
        }
        badgeByDescription:Badges(behaviouralDescription_Icontains:$behaviouralDescription_Icontains,badgeFamily:$badgeFamily){
            edges {
              node {
                badgeFamily{
                  title
                  description
                  id
                }
                id
                image
                title
                behaviouralDescription
                duration
              }
        
            }
        
        }
    }
`