export const feedback="feedback"
const baseName =`/role/${feedback}`
const addFeedback = '/create_feedback'
const detail = '/detail'
const update = '/update_feedback'
const home = '/home'

const feedback_route = {
    feedback: baseName+home +'/:type' ,
    Createfeedback: baseName+addFeedback +'/:id',
    detail : baseName+detail+'/:feedid'+'/:id',
    update : baseName+update+'/:feedid'+'/:id',
    feedbackHome:baseName
}

export default feedback_route