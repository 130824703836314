import gql from 'graphql-tag';

export const ME_SURVEY_FOR_VERTICAL_HEAD_QUERY = gql`
  query me($id: ID!) {
    surveys: me{
      id
    emp: employee{
      id
      verticalHeadEmployee(id: $id){
        edges{
          node{
            surveySet {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
  }
`;
