import gql from 'graphql-tag';
// DO NOT CHANGE THESE QUERY
export const CONTRIBUTORS_SUBSCRIPTION = gql`
subscription contributorSubscription($createdBy: ID) {
    contributorSubscription{
        mutation
        contributor{
            id
            role
            okrType
            progress
            requestStatus
            okr {
              id
              title
              createdAt
              goalStatement
              progress
              correctionDeadline
              duedate
              startdate
              okrType
              badgeAward(recipient:$createdBy){
                edges{
                  node{
                    id
                    evidence
                    issuer{
                      id
                      user{
                        id
                        firstName
                        lastName
                      }
                    }
                    badge{
                      id
                      image
                      badgeFamily {
                        id
                        title
                      }
                      title
                      behaviouralDescription
                    }
                  }
                }
              }
              discardRequest(status_Iexact:"PENDING"){
                totalCount
               }
               discardRequestRaised:discardRequest(initiator:$createdBy){
                totalCount
               }
              isDiscarded{
                id   
                status
              }
              contributorSet{
                edges{
                  node{
                    id
                    role
                    employee{
                      id
                      user{
                        id
                        firstName
                        lastName
                      }
                    }
                  }
                }
              }
            }
        }
    }
}
`