import gql from "graphql-tag";

export  const GET_MANAGER_EMPLOYEE_LIST = gql`
query me{
   allEmpoloyee:me{
        id
        employee{
            id
            orgCeoEmployee {
                edges {
                    node {
                        id
                        employees {
                            edges {
                                node {
                                id
                                user{
                                    id
                                    firstName
                                    lastName
                                    email
                                    employee{
                                        id
                                    }
                                }
                                }
                            }
                        }
                    }
                }
            }
            orgPocEmployee {
                edges {
                    node {
                        id
                        employees {
                            edges {
                                node {
                                id
                                user{
                                    id
                                    firstName
                                    lastName
                                    email
                                    employee{
                                        id
                                    }
                                }
                                }
                            }
                        }
                    }
                }
            }
            teamManagerEmployee {
                edges {
                    node {
                        id
                        vertical {
                            id
                            heads {
                                edges {
                                    node {
                                        id
                                        user{
                                            id
                                            firstName
                                            lastName
                                            email
                                            employee{
                                                id
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        employees {
                            edges {
                                node {
                                    id
                                    user{
                                        id
                                        firstName
                                        lastName
                                        email
                                        employee{
                                            id
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            verticalHeadEmployee {
                edges {
                    node {
                        id
                        parentvertical {
                            heads {
                                edges {
                                    node {
                                        id
                                        user{
                                            id
                                            firstName
                                            lastName
                                            email
                                            employee{
                                                id
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        employees {
                            edges {
                                node {
                                    id
                                    user{
                                        id
                                        firstName
                                        lastName
                                        email
                                        employee{
                                            id
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            teamMembersEmployee {
                edges {
                    node {
                        id
                        managers {
                            edges {
                                node {
                                    id
                                    user{
                                        id
                                        firstName
                                        lastName
                                        email
                                        employee{
                                            id
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            verticalMembersEmployee {
                edges {
                  node {
                    id
                    heads{
                        edges{
                          node{
                            id
                            user{
                              id
                              firstName
                              lastName
                              email
                              employee{
                                id
                              }
                            }
                          }
                        }
                      }
                  }
                }
            }
        }
    }   
  }
`