import React from 'react';
import { useParams } from 'react-router-dom';
import { message } from 'antd';
import { withApollo } from '@apollo/client/react/hoc';
import { compose } from '../../core';
import { getIntFromString } from '../../look';
import { withMe } from '../../user/containers/UserOperations';

import CommentSectionView from '../components/CommentSectionView';

import { withAddComment, withDeleteUserComment, withStoryState } from './StoryOperations';

import { STORY_COMMENT_SUBSCRIPTION } from '../graphql/StoryCommentSubscription.gql';
import { ALL_COMMENTS_FOR_STORY_BY_ID_QUERY } from '../graphql/AllCommentsForStoryByStoryId.gql';

const CommentSection = props => {
  const { createComment, deleteUserComment, me, client, match, navigation, filter, orderBy, pagination, setCommentCount} = props;
  const { id } = useParams();

  const [comments,setComments] = React.useState(undefined);
  

  const handleDelete = async id => {
    try {
      const response = await deleteUserComment({commentId:id});
    } catch (e) {
      throw Error(e.message);
    }
  };



  const storyCommentsDataRef = React.useRef(null);

  let storyCommentSubscription = undefined;
  const m = React.useRef(true)


  React.useEffect(()=>{

    if(me){
      getAllCommentsOfstory()
    }

  },[me])


  const getAllCommentsOfstory = async () => {
    
    const { data } = await client.query({
      query: ALL_COMMENTS_FOR_STORY_BY_ID_QUERY,
      variables: {
        story:id,
        ...pagination,
        ...filter,
        orderBy
      },
      fetchPolicy: 'network-only',
    });
    
    if (data?.comments) {
      if(setCommentCount){
        setCommentCount(data?.comments?.totalCount)
      }
      setComments(data?.comments);
      storyCommentsDataRef.current = data?.comments;
      SubscribeToStoryComments();
    }
  }


  React.useEffect(() => {
    return () => {
      m.current = false
    }
  }, [])

  React.useEffect(() => {
    m.current = true

  }, [])

  React.useEffect(() => {
    return () => {
      if (storyCommentSubscription) {
        storyCommentSubscription.unsubscribe();
      }
    }
  }, [])
 

  const SubscribeToStoryComments = () => {

    storyCommentSubscription = client.subscribe({
      query: STORY_COMMENT_SUBSCRIPTION,
    }).subscribe({
      next(result) {

        switch (result.data.storyCommentSubscription.mutation) {
         
          case 'DELETE':
           
            if (m.current) {
              let storyCommentSet = storyCommentsDataRef.current?.edges
              let storyCommentSetWithDeletedRecord = storyCommentSet?.filter((comment)=>
              comment?.node?.id !== result.data.storyCommentSubscription?.comment?.id)
              let updatedStoryCommentSet = {edgeCount:(storyCommentsDataRef.current?.edgeCount - 1 ),totalCount:(storyCommentsDataRef.current?.totalCount - 1 ),edges:storyCommentSetWithDeletedRecord}
              setComments(updatedStoryCommentSet);
              if(setCommentCount){
                setCommentCount(storyCommentsDataRef.current?.totalCount - 1)
              }
              storyCommentsDataRef.current = updatedStoryCommentSet;
            }

            break;
          default:
            break
        }

      }
    })

  }





  const handleSubmit = async values => {
    try {
      const response = await createComment({
        commentData: {
          userId: getIntFromString(me.id),
          commentText: values,
          storyId: id
        }
      });
      response && message.success('Comment added!');
    } catch (e) {
      throw Error(e.message);
    }
  };

  return <CommentSectionView comments = {comments} onDelete={handleDelete} onSubmit={handleSubmit} {...props} />;
};

export default compose(
  withMe,
  withStoryState,
  withAddComment,
  withDeleteUserComment
  ,withApollo
)(CommentSection);
