import React from "react";
import UserProfileAwardView from "../component/userProfileAwardView";
import { compose } from "modules/core";
import { withApollo } from '@apollo/client/react/hoc';
import { BADGE_AWARD_LIST } from "../graphql/BadgeAwardList.gql";
import { getIntFromString,permissionValidation } from "modules/look";
import { BADGE_AWARD_SUBSCRIPTION } from "../graphql/badgeAwardSubscription.gql";
import { BADGE_AWARD_VIEW_PERMISSION } from "../../../config";
const UserProfileAwardContainer =(props)=>{
    const {client,userPermission,me}=props
    const [loading,setLoading]=React.useState(false)
    const [badgeAwardList,setBadgeAwardList]=React.useState()
    const [permission,setpermission]=React.useState()

    const m = React.useRef( true )
    
    let badgeAwardSub = undefined

    React.useEffect( () => {
        return () => {
          if ( badgeAwardSub ) {
            badgeAwardSub.unsubscribe();
          }
        }
      } )
    
      React.useEffect( () => {
        return () => {
          m.current = false
        }
      }, [] )
    
      React.useEffect( () => {
        m.current = true
    
      }, [] )

    const getBadgeFamilyList=async(filterValue)=>{

        setLoading(true)
        const { data } = await client.query({
          query: BADGE_AWARD_LIST,
          variables: {...filterValue},
          fetchPolicy:"network-only"
        });
        if(data){
            setBadgeAwardList(data?.badgeAwards)
        }

        badgeAwardSub = client.subscribe( {
            query: BADGE_AWARD_SUBSCRIPTION,
            variables: filterValue
          } ).subscribe( {
            next( result ) {
              const data = client.readQuery( { query: BADGE_AWARD_LIST, variables: filterValue } )
              let receivedBadgeCacheData = data?.badgeAwards?.edges?.map( ( { node } ) => node )
              switch ( result.data.badgeAwardSubscription.mutation ) {
                case 'CREATE':
                  const updated = [ ...receivedBadgeCacheData, result.data.badgeAwardSubscription.badgeAward ]
                  if ( m.current ) {
                    setBadgeAwardList( updated )
                  }
                  break
                case 'UPDATE':
                  if ( m.current ) {
                    setBadgeAwardList(
                        receivedBadgeCacheData.map( item =>
                        item.id === result?.data?.badgeAwardSubscription?.badgeAward?.id
                          ? result.data.badgeAwardSubscription.badgeAward
                          : item
                      ) )
                  }
                  break
                case 'DELETE':
                  if ( m.current ) {
                    setBadgeAwardList(
                        receivedBadgeCacheData.filter( item =>
                        item.id === result?.data?.badgeAwardSubscription?.badgeAward?.id
                          ? false
                          : true
                      ) )
                  }
                  break
                default:
                  break
              }
            }
          } )
          setLoading(false)

    }
    React.useEffect(()=>{
    if(me?.employee?.user?.id){
        getBadgeFamilyList({recipient:me?.employee.id})
    }
  },[me])

    return(
        <>
            <UserProfileAwardView {...props} badgeAwardList={badgeAwardList} loading={loading}/>
        </>
    )
}

export default compose(withApollo)(UserProfileAwardContainer)