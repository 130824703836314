import gql from 'graphql-tag';

export const LOOK_TAG_STATE_QUERY = gql`
  query lookTagState {
    lookTagState @client {
      filter {
        name_Icontains
      }
      orderBy
    }
  }
`;
