import gql from 'graphql-tag';

export const REFRESH_TOKENS_MUTATION = gql`
  mutation refreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      success
      errors
      token
      refreshToken
    }
  }
`;
