

const introBaseName = '/intro'
const toolBaseName = '/tool'
const whoposhoro = '/whoposhoro'
const oneonone = '/oneonone'
const okr = '/okr'
const feedback = '/feedback'
const coaching = '/coaching'
const readiness = '/readiness'

module.exports = {
  
sales :introBaseName,
salesTool :introBaseName + toolBaseName + '/:id',
getInTouch:introBaseName  + '/getintouch',
soulIdentityTool:introBaseName   + '/soul',
whoposhoro :introBaseName + toolBaseName + whoposhoro +'/:id',
oneonone :introBaseName + toolBaseName + oneonone +'/:id',
okr :introBaseName + toolBaseName + okr +'/:id',
feedback :introBaseName + toolBaseName + feedback +'/:id',
coaching :introBaseName + toolBaseName + coaching +'/:id',
readiness :introBaseName + toolBaseName + readiness +'/:id',

};

