import gql from 'graphql-tag';


export const CREATE_MEETING_NOTES_MUTATION = gql`
  mutation createMeetingNotes($meetingNotesData: MeetingNotesInput!) {
    createMeetingNotes(
        meetingNotesData:$meetingNotesData
        ) {
        meetingNotes {
         id
      }
    }
  }
`;
