import { Button, Col, Modal, Row } from "antd";
import { displayUtcToUserTimezone } from "modules/look";
import React from "react";
import modal_close from '../../assets/close-red.svg'

const BadgeDetailPopup = ( props ) => {

    const { selectedAward, onClose, visible, shareAward,type } = props
    
    return (
        <>
            <Modal
                closable={false}
                visible={visible}
                destroyOnClose={true}
                footer={false}
                width={'100%'}
                style={{maxWidth:"800px"}}
                centered={true}>
                    {type==='family'?<div style={{ display: "flex", flexDirection: "column", justifyContent: 'center', alignItems: "center", position: "relative",width:"100%" }}>
                        <img src={modal_close} alt="" style={{ cursor: "pointer", position: "absolute", right: "-10px", top: "-10px" }} onClick={() => onClose( false )} />
                        <h4 className="kudos-modal-main-title" style={{ fontWeight: "500",marginTop:"-20px" }}>{selectedAward?.node?.title}</h4>
                        <div className="kudos-modal-overflow" style={{ overflow: 'auto', maxHeight: "450px", padding: "0px 10px 10px 10px",width:"100%",marginTop:"-20px" }}>
                            <h4 className="kudos-badge-modal-subhead" style={{ fontWeight: "400", whiteSpace: "nowrap",margin:0 }}>{selectedAward?.item?.title}</h4>
                            <p className="kudos-badge-description-modal" style={{paddingRight:"20px"}}>description: {selectedAward?.item?.description}</p>
                            <div className="user-badge-award-popup-inner-container" style={{width:"100%"}}>
                                <div className="user-profile-award-detail-left-container">
                                    <img src={selectedAward?.node?.image} alt="" style={{width:"100%"}}/>
                                </div>
                                <div className="user-profile-award-detail-right-container" style={{width:"100%"}}>
                                    <div className="user-profile-award-detail-right-container-inner">
                                        <h4 className="kudos-badge-title-modal" style={{ margin: 0, fontWeight: "500",paddingLeft:"20px" }}>1. {selectedAward?.node?.title}</h4>
                                        <p className="kudos-badge-description-modal">{selectedAward?.node?.behaviouralDescription}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>:
                      <div style={{ display: "flex", flexDirection: "column", justifyContent: 'center', alignItems: "center", position: "relative",width:"100%" }}>
                      <img src={modal_close} alt="" style={{ cursor: "pointer", position: "absolute", right: "10px", top: "10px" }} onClick={() => onClose( false )} />
                      <h4 className="kudos-modal-main-title" style={{ fontWeight: "500" }}>{selectedAward?.title}</h4>
                      <div className="kudos-modal-overflow" style={{ overflow: 'auto', maxHeight: "450px", padding: "10px 10px",width:"100%" }}>
                      <div className="user-badge-award-popup-inner-container" style={{width:"100%"}}>
                                <div className="user-profile-award-detail-left-container">
                                    <img src={selectedAward?.node?.image} alt="" />
                                </div>
                                <div className="user-profile-award-detail-right-container" style={{width:"100%"}}>
                                    <div className="user-profile-award-detail-right-container-inner">
                                        <h4 className="kudos-badge-title-modal" style={{ margin: 0, fontWeight: "500" }}>1. {selectedAward?.node?.title}</h4>
                                        <p className="kudos-badge-description-modal">{selectedAward?.node?.behaviouralDescription}</p>
                                    </div>
                                </div>
                            </div>
                      </div>
                  </div>}
                  
            </Modal>
        </>
    )
}

export default BadgeDetailPopup