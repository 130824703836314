import gql from 'graphql-tag';

export const SURVEY_STATE_QUERY = gql`
  query surveyState {
    surveyState @client {
      pagination {
        first
        offset
      }
      orderBy
      filter {
        name_Icontains
        description_Icontains
        label_Icontains
        helpText_Icontains
        survey_Name_Icontains
        sequence_Icontains
        questionText_Icontains
        questionAddress_Icontains
        user
        survey
        createdBy
        invited
        valid
        survey_Name_Icontains
      }
    }
  }
`;
