import gql from 'graphql-tag';

export const READ_NOTIFICATION_MUTATION = gql`
  mutation readNotification($id: ID!) {
    readNotification(
      id:$id
        ) {
        notification{
            id
            
        }
    }
  }
`;
