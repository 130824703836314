import React from "react";
import { compose } from "../../core";
import EmployeeLevelView from "../components/EmployeeLevelView";
import { ALL_EMPLOYEE_LEVEL, All_EMPLOYEE_LEVEL_SUBSCRIPTION } from "../graphql/EmployeeLevelQuries.gql";
import { withDeleteEmployeeLevel } from "../RoleDefinitionOperation";

const EmployeeLevelContainer = (props) => {

    const { client, permission, deleteEmployeeLevel,setCurrentTab } = props
    const [employee_level_loading, SetEmployeeLevelLoading] = React.useState(false)
    const [employee_level_list, SetEmployeeLevelList] = React.useState([])
    const [employee_level_delete,SetEmployeeLevelDelete] = React.useState()

    const employee_level_list_ref = React.useRef([])
    const employee_level_subscription = React.useRef(null)

    // to update the employee list refference when the state gets changed
    React.useEffect(() => {
        employee_level_list_ref.current = employee_level_list
    }, [employee_level_list])

    // to check if the user have permission to fetch employee level data
    React.useEffect(() => {
        if (permission && permission?.required_permission) {
            let nav ={
                parent_route :{key:'employee_level',label:'Employee Level'},
                child_route : {key:'employee_level_home_view'}
            }
            localStorage.setItem('role_definition_navigation',JSON.stringify(nav))
            setCurrentTab({key:'employee_level',label:'Employee Level'})
            getEmployeLevel()
        }
    }, [permission])

    // Code to be executed when the component is unmounted
    React.useEffect(() => {
        return () => {
            if(employee_level_subscription?.current){
                employee_level_subscription.current.unsubscribe();
                employee_level_subscription.current = null
            }
        };
    }, []);

    // qraphql api to fetch employee level data list
    const getEmployeLevel = async (filter) => {
        SetEmployeeLevelLoading(true)
        const { data } = await client.query({
            query: ALL_EMPLOYEE_LEVEL,
            fetchPolicy: 'network-only',
            variables: filter
        });
        if (data?.allEmployeeLevels) {
            SetEmployeeLevelLoading(false)
            let list = data?.allEmployeeLevels?.edges.map(({ node }) => node)
            SetEmployeeLevelList(list)
            if (!employee_level_subscription?.current) {
                employeeLevelsubscription()
            }
        }
    }

    // to add subscription to employee level api
    const employeeLevelsubscription = async () => {
        employee_level_subscription.current = client.subscribe({
            query: All_EMPLOYEE_LEVEL_SUBSCRIPTION,
            fetchPolicy: 'no-cache'
        }).subscribe({
            next(result) {
                let employee_level_cache_data = [].concat(employee_level_list_ref.current)
                let scbscription_data = result.data.employeeLevelSubscription
                switch (scbscription_data.mutation) {
                    case 'CREATE':
                        employee_level_cache_data.push(scbscription_data?.employeeLevel)
                        SetEmployeeLevelList(employee_level_cache_data)
                        break
                    case 'UPDATE':
                        let doc = employee_level_cache_data?.find(item=>item?.id === scbscription_data?.employeeLevel?.id)
                        if(doc){
                            Object.entries(scbscription_data?.employeeLevel).forEach(item=>{
                                doc[item[0]]=item[1]
                            })
                            SetEmployeeLevelList(employee_level_cache_data)
                        }
                        break
                    case 'DELETE':
                        employee_level_cache_data = employee_level_cache_data?.filter(item=>item?.id !== scbscription_data?.employeeLevel?.id)
                        SetEmployeeLevelList(employee_level_cache_data)
                        break
                    default:
                        break
                }
            }
        });
    }

    // delete employee level data
    const removeEmployeeLevel =async(id)=>{
        try{
            SetEmployeeLevelDelete(null)
            SetEmployeeLevelLoading(true)
            const response = await deleteEmployeeLevel({ id });
            if (response) {
                SetEmployeeLevelLoading(false)
            }else{
                SetEmployeeLevelLoading(false)
            }
        }catch(e){
            SetEmployeeLevelLoading(false)
        }
    }

    return (
        <div>
            <EmployeeLevelView {...props}
                employee_level_list={employee_level_list}
                employee_level_loading={employee_level_loading}
                removeEmployeeLevel={(id)=>{removeEmployeeLevel(id)}}
                employee_level_delete={employee_level_delete}
                SetEmployeeLevelDelete={(data)=>{SetEmployeeLevelDelete(data)}}
            />
        </div>
    )

}

export default compose(withDeleteEmployeeLevel)(EmployeeLevelContainer);