import gql from 'graphql-tag';

export const ASSIGNES_LIST = gql`
query me{
  assignes:me{
    employee {
      id
      orgCeoEmployee {
        edges {
          node {
            id
            employees {
              edges {
                node {
                  id
                  user {
                    id
                    firstName
                    lastName
                  }
                }
              }
            }
          }
        }
      }
      orgPocEmployee {
        edges {
          node {
            id
            employees {
              edges {
                node {
                  id
                  user {
                    id
                    firstName
                    lastName
                  }
                }
              }
            }
          }
        }
      }
      teamManagerEmployee {
        edges {
          node {
            id
            employees {
              edges {
                node {
                  id
                  user {
                    id
                    firstName
                    lastName
                  }
                }
              }
            }
          }
        }
      }
      verticalHeadEmployee {
        edges {
          node {
            id
            employees {
              edges {
                node {
                  id
                  user {
                    id
                    firstName
                    lastName
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`