import React, { useState } from 'react';
import { Button, Form } from 'antd';
import '../css/survey-look.css'
import {
    // AlphaPicker,
    // BlockPicker,
    // ChromePicker,
    // CirclePicker,
    // CompactPicker,
    // GithubPicker,
    // HuePicker,
    // MaterialPicker,
    // PhotoshopPicker,
    SketchPicker
    // SliderPicker,
    // SwatchesPicker,
    // TwitterPicker
} from 'react-color';

const FormItem = Form.Item;

const SurveyColorField = props => {
    const { type, offset = 1, value, disabled,required,hide_okay_btn, ...rest } = props;
    const [ background, setBackground ] = useState( props.value );
    const [colorButtonBg,setColorButtonBg] = useState();
    const [showPicker,setShowPicker] = useState(false);
    React.useEffect( () => {
        if ( background !== value ) setBackground( value );
    }, [ value ] );
const backgroundChange =(value)=>{
    if(value){
        setBackground(value)
        setColorButtonBg(value?.hex)
        // setShowPicker(false)
        if (props.onChange) {
        props.onChange( value.hex )
        }
    }
}

React.useEffect(()=>{
    if(hide_okay_btn){
        setShowPicker(false)
    }
},[hide_okay_btn])


    return (
        <div style={{ marginBottom: '10px'}} className={`${showPicker?'color-picker-extend-height':'color-picker-default-height'}`}>
            <FormItem required={required}{...rest}>
                <div className='generic-survey-input-filed color-picker-container-max-width' style={{ display: 'flex', flexDirection: "row", gap: "10px", position: "relative", height: "2rem", padding: "0px 10px"}} onClick={()=>setShowPicker(true)}>
                    <p className='picked-color-text'>Pick a color</p>
                    <div style={{ position: "absolute", right: "0px", top: "4px", display: 'flex', justifyContent: 'end', alignItems: 'end', flexDirection: 'column' }}>
                        <div id='colorButton' className='sketch-trigger-button' style={{background:colorButtonBg||"white"}} ></div>
                        <SketchPicker
                            className={`${showPicker?'sketch-picker-extend-height':'sketch-picker-default-height'}`}
                            color={background}
                            onChangeComplete={color => props.onChange && backgroundChange( color )}
                            // onChange={e=>{backgroundChange(e)}}
                            disabled={disabled}
                        />
                    </div>

                </div>
            </FormItem>
        </div>
    );
};

export default SurveyColorField;
