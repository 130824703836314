// @ts-nocheck
import { Button, Spin, Tooltip } from "antd";
import React, { useLayoutEffect } from "react";
import delete_icon from '../../assets/card-delete.svg';
import edit_icon from '../../assets/kudos-card-edit.svg';
import next from '../../assets/kudos-next.svg';
import prev from '../../assets/kudos-prev.svg';
import new_drop_arrow from '../../assets/new_drop_arrow.svg';
import next_active from '../../assets/next-arrow-active.svg';
import previous_active from '../../assets/previous-arrow-active.svg';
import "../kudos.css";
import BadgeDetailPopup from "./badgeDetailViewModal";
const BadgeCardView = (props) => {
    const { me, loading, dataSource, setbadge_family_update_data, edit, setdeleteType, permission, setDelete, badgeSetUpdated, badgeCacheData, fromCreationContainer } = props;

    const [current_source, setCurrentSource] = React.useState([])
    const [total_page, settotal_page] = React.useState({})
    const [expanded_card, setExpandedCard] = React.useState([])
    const [column, setcolumn] = React.useState()
    const [current_page, setcurrent_page] = React.useState(1)
    const [change_cards, setchange_cards] = React.useState(false)
    const [VisisbleDetail, setVisibleDetail] = React.useState(false)
    const [selectedBadgeDetail, setSelectedBadgeDetail] = React.useState()


    React.useEffect(() => {

        if (badgeSetUpdated && dataSource?.edgeCount) {
            let scrn_width = window.innerWidth
            let column_count
            if (scrn_width >= 1400.1) {
                column_count = 3
            }
            else if (scrn_width >= 760.1 && scrn_width < 1400) {
                column_count = 2
            } else {
                column_count = 1
            }
            let total_page = Math.ceil(dataSource?.edges?.length / (column_count * 2))
            settotal_page(total_page)
            HandleDataSource(dataSource, current_page > total_page ? total_page : current_page, null)
        }
    }, [badgeSetUpdated, dataSource, loading])




    React.useEffect(() => {
        
        if (dataSource?.edgeCount) {
            let scrn_width = window.innerWidth
            let column_count
            if (scrn_width >= 1400.1) {
                column_count = 3
            }
            else if (scrn_width >= 760.1 && scrn_width < 1400) {
                column_count = 2
            } else {
                column_count = 1
            }
            let total_page = Math.ceil(dataSource?.edges?.length / (column_count * 2))
            settotal_page(total_page)
            HandleDataSource(dataSource, current_page > total_page ? total_page : current_page, null)
        }
    }, [ dataSource ])



    const useWindowSize = () => {
        useLayoutEffect(() => {
            function updateSize() {
                let scrn_width = window.innerWidth
                let column_count
                if (scrn_width >= 1400.1) {
                    column_count = 3
                }
                else if (scrn_width >= 760.1 && scrn_width < 1400) {
                    column_count = 2
                } else {
                    column_count = 1
                }

                if (column !== column_count && dataSource) {
                    let total_page = Math.ceil(dataSource?.edges?.length / (column_count * 2))
                    settotal_page(total_page)
                    HandleDataSource(dataSource, total_page, null)
                }
            }
            window.addEventListener('resize', updateSize);
            updateSize();
            return () => window.removeEventListener('resize', updateSize);
        }, []);
    }

    const HandleDataSource = (dataSource, page, prev = null) => {
        setcurrent_page(page)
        let source = current_source.concat([])
        let scrn_width = window.innerWidth
        let column_count
        if (scrn_width >= 1400.1) {
            column_count = 3
        }
        else if (scrn_width >= 760.1 && scrn_width < 1400) {
            column_count = 2
        } else {
            column_count = 1
        }
        setcolumn(column_count)
        let current_page_data = dataSource?.edges?.slice((page - 1) * (column_count * 2), page * (column_count * 2))
        let col_data = []
        for (let i = 1; i <= column_count; i++) {
            const element = {
                node_index: i,
                data: current_page_data?.slice((i - 1) * (2), i * (2)).map(({ node }) => {
                    return {
                        ...node,
                        expanded: (node?.id === expanded_card[0]?.id && expanded_card[0]?.node_index === i) ? true : false,
                        visible: true
                    }
                })
            }
            col_data.push(element)
        }
        setCurrentSource(col_data)

    }

    React.useEffect(() => {
        if (current_source && expanded_card && change_cards) {
            setchange_cards(false)
            handleExpandedCards()
        }
    }, [change_cards])

    const handleExpandedCards = () => {
        let list = [].concat(current_source)
        list.forEach(item => {
            let expanded = expanded_card.find(exp => exp.node_index === item.node_index)
            if (expanded) {
                item.data.forEach(element => {
                    element.expanded = expanded.id === element.id ? true : false
                    element.visible = expanded.id === element.id ? true : false
                });
            } else {
                item.data.forEach(element => {
                    element.expanded = false
                    element.visible = true
                });
            }
        })

        setCurrentSource(list)
    }

    const AddandRemovetoExpand = (id, node_index) => {
        if (!permission.requried_permission) {
            return
        }
        let list = expanded_card
        if (list.find(item => item.id === id)) {
            list = list.filter(x => x.id !== id)
        } else {
            list = []
            list = list.filter(x => x.node_index !== node_index)
            list.push({ id, node_index })
        }
        setExpandedCard(list)
        if (fromCreationContainer) {
            setDelete(id)
        }
        setchange_cards(true)

    }

    const deleteitem = (data) => {
        if (data.type === 'Kudos_Family' && permission.requried_permission) {
            setdeleteType(data)
        }
        else if (data.type === 'Kudos' && permission.requried_permission) {
            setdeleteType(data)
            setDelete(data?.badge_family_id)
        }
    }
    //check_method
    const updateData = (data) => {
        if (permission.requried_permission) {
            setbadge_family_update_data(data)
           
        }

        
    }

    const updateBadgeData = (data) => {

        if (permission?.requried_permission) {

            if (data.badge_family_id) {
                setbadge_family_update_data(data)
            }
        }
    }


    const ViewDetail = (node) => {
        setSelectedBadgeDetail(node)
        if (permission?.requried_permission) {
            setVisibleDetail(true)
        }

    }
    {
        useWindowSize();
        return (
            <div className="kudos-main-card-container row-padding">
                <Spin spinning={false}>
                    <div className="kudos-space-between-row ">
                        <Button className="kudos-prev-page">
                            {current_page === 1 ? <img src={prev} alt="" className="kudos-page-arrow" /> : <img style={{ cursor: "pointer" }} className="kudos-page-arrow" onClick={() => { HandleDataSource(dataSource, current_page - 1) }} src={previous_active} alt="" />}
                        </Button>
                        <div className="card-container" >
                            {
                                current_source?.map(n => (
                                    <div className="badge-card-vertical-container" id={current_page} name={current_page}>
                                        {
                                            n?.data?.map(item => (
                                                <div className={`badge-card ${!item?.visible ? 'badge-card-hide' : ''}  ${item?.expanded ? 'badge-card-exp' : ''}`} >
                                                    <div align="end">
                                                        {
                                                            (edit && permission?.requried_permission) ?

                                                            <Tooltip title={"Delete"}>
                                                                  <img src={delete_icon} onClick={() => { deleteitem({ id: item?.id, type: 'Kudos_Family' }) }} className={`kudos-delete-icon ${!permission?.requried_permission ? 'kudos-cursor-disabled' : ''}`} alt="" />
                                                            </Tooltip>
                                                                

                                                                :
                                                                <div style={{ width: "1.125em", height: "1.5em" }}></div>
                                                        }

                                                    </div>
                                                    <div style={{ border: "0px" }} className={`kudos-card-info ${item?.expanded ? 'badge-info-card-fixed' : ''}`}>
                                                        <div className="kudos-card-title-section" >
                                                            <h4 className="kudos-card-title">{item?.title}</h4>
                                                            {
                                                                (edit && permission?.requried_permission) ?

                                                                <Tooltip title={'Edit'}>
                                                                       <img className={`kudos-card-edit ${!permission?.requried_permission ? "kudos-cursor-disabled" : ''}`} src={edit_icon} onClick={() => { updateData({ badge_family_id: item?.id }) }} alt="" />
                                                                </Tooltip>
                                                                  

                                                                    :
                                                                    ''
                                                            }

                                                        </div>
                                                        <div className="kudos-card-dis" >
                                                            <p className="card-discription">description : {item?.description}</p>
                                                        </div>
                                                        <div className="kudos-card-action-section" >
                                                            {
                                                                <img className={`new_drop_arrow ${item?.expanded ? 'kudos-rotate-arrow' : ''} ${!permission.requried_permission ? 'kudos-cursor-disabled' : ''}`} src={new_drop_arrow} onClick={() => { AddandRemovetoExpand(item?.id, n.node_index) }} style={{ width: "1em", height: "0.875em", marginLeft: "5px", visibility: permission?.requried_permission ? 'visible' : 'hidden' }} />
                                                            }


                                                            {
                                                                (edit && permission?.requried_permission) ?

                                                                    <h4 className={`kudos-card-add-badge ${!permission?.requried_permission ? 'kudos-cursor-disabled' : ''}`} onClick={() => { updateData({ badge_family_id: item?.id, type: "add" }) }}>Add Kudos +</h4>

                                                                    : ''
                                                            }

                                                        </div>
                                                    </div>

                                                    {/* badge info */}

                                                    <div className={`badge-list-container ${item?.expanded ? 'badge-list-visible' : ''}`}>

                                                        {item?.badgeSet?.edges?.length > 0 && <div className="kudos-border">
                                                            {
                                                                item?.badgeSet?.edges?.map(({ node }, index) => (
                                                                    <div className="kudos-single-badge-card" style={{ marginBottom: "1.5em" }}>
                                                                        <div className="badge-index-circle">
                                                                            <p>{index + 1}</p>


                                                                        </div>
                                                                        <div className="kudos-single-badge-info">
                                                                            <div className="kudos-card-action-section">
                                                                                <div className="kudos-card-title-section kudos-badge-sub-action" >
                                                                                    <p className="title" style={{ cursor: "pointer" }} onClick={() => ViewDetail({ node: node, item: item })}>{node?.title}</p>
                                                                                    {
                                                                                        (edit && permission?.requried_permission) ?

                                                                                            <img className={`${!permission?.requried_permission ? 'kudos-cursor-disabled' : ''}`} style={{ width: "1em", height: "1em", cursor: "pointer" }} src={edit_icon} onClick={() => { updateBadgeData({ badge_family_id: item?.id, badge_id: node?.id }) }} alt="" />


                                                                                            : ''
                                                                                    }

                                                                                </div>
                                                                                {
                                                                                    (edit && permission?.requried_permission) ?

                                                                                        <img src={delete_icon} onClick={() => { deleteitem({ id: node?.id, badge_family_id: item?.id, type: 'Kudos' }) }} className={`${!permission?.requried_permission ? 'kudos-cursor-disabled' : ''}`} style={{ width: "0.75em", height: "1em", cursor: "pointer" }} alt="" />


                                                                                        : ''
                                                                                }

                                                                            </div>
                                                                            <p className="badge-behavioural-description">
                                                                                {node?.behaviouralDescription}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>}
                                                    </div>
                                                </div>
                                            ))
                                        }

                                    </div>
                                ))
                            }

                        </div>
                        <Button className="kudos-next-page" >
                            {current_page === total_page ? <img className="kudos-page-arrow" src={next} /> : <img style={{ cursor: "pointer" }} className="kudos-page-arrow" onClick={() => { HandleDataSource(dataSource, current_page + 1) }} src={next_active} alt="" />}
                        </Button>
                    </div>
                </Spin>
                {
                    VisisbleDetail && <BadgeDetailPopup visible={VisisbleDetail} selectedAward={selectedBadgeDetail} type={"family"} onClose={() => setVisibleDetail(false)} />
                }
            </div>

        )
    }
};

export default BadgeCardView;
