import React from 'react';

import { AdminLayout, MetaTags } from '../../look';
import { user } from '../route';
import UserFormComponent from './UserFormComponent';

const EditUserView = props => {
  return (
    <>
      <MetaTags title="Edit User" description="This is Edit User" />
      <AdminLayout
        table={false}
        active={user}
        title="Edit user"
        FormComponent={props.user && <UserFormComponent {...props} />}
      />
    </>
  );
};

export default EditUserView;
