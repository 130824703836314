import { Col } from "antd";
import { getIntFromString } from "modules/look";
import React from "react";
import '../css/JobFamily.css';
import RoleDefinitionDetailView from "./RoleDefinitionDetailsView";
import role_definition_route from "../route";

const RoleDetailView = (props) => {

    const { role, deleteRole, roleDetailsLoading, rolePermission,navigateRoute } = props

    const [deleteRoleInfo, setDeleteRole] = React.useState(false);
    const [functionalCompetencies, setFunctinalCompetencies] = React.useState(undefined);
    const [responsibilities, setResponsibilities] = React.useState(undefined);
    const [eligibilities, setEligibilities] = React.useState(undefined);

    const eligibilityStruct = (data) => (
        <Col span={24} className="jf-text-content-body">

            <Col span={24}>
                {data?.education?.edges?.map(({ node }) =>
                <div className="eligibility-desc">
                    <div>
                        <span >
                            {node.degree} in  {node.stream} specialized in {node.subject}
                        </span>
                    </div>
                    <br></br>
                    </div>
                )}
            </Col>

            <span>
                Required Experience: <span style={{ fontWeight: "600" }}>{parseInt(data.quantitativeExperienceMin)} - {parseInt(data.quantitativeExperienceMax)} Years</span>
            </span>
          

        </Col>

    )



    React.useEffect(() => {

        if (role) {
            // get the list of competencies
            let requiredRoleSpecificCompetencies = role?.requiredSpecificCompetency?.edges.map(({ node }) =>
                node
            )?.filter(item => item?.competencyType === "ROLE_SPECIFIC")

            let optionalRoleSpecificCompetencies = role?.optionalSpecificCompetency?.edges.map(({ node }) =>
                node
            )?.filter(item => item?.competencyType === "ROLE_SPECIFIC")

            let combinedCompetencies = [].concat(requiredRoleSpecificCompetencies).concat(optionalRoleSpecificCompetencies);


            let functional_competencies = {
                title: 'Functional (Role specific) Competency',
                popUptitle: "Functional Competency Details",
                
                content: combinedCompetencies?.map((node) => {
                    return {
                        id: node?.id,
                        title: node?.title,
                        description:node?.description
                    }
                })
            }

            setFunctinalCompetencies(functional_competencies)

            // get the list of eligibilities
            let responsibilities = {
                title: 'Responsibilities',
                popUptitle: "Responsibilities Details",

                content: role?.responsibility?.edges?.map(({ node }) => {
                    return {
                        id: node?.id,
                        title: node?.title,
                        description: node?.description
                    }
                })
            }
            setResponsibilities(responsibilities)


            let requiredEligibilities = role?.requiredEligibility?.edges.map(({ node }) => node)

            let optionalEligibilities = role?.optionalEligibility?.edges.map(({ node }) => node)

            let combinedEligibilities = [].concat(requiredEligibilities).concat(optionalEligibilities);


            let eligiblities = {
                title: 'Eligibility',
                colClass: "jf-margin-seperator-eligibilities",
                content: combinedEligibilities?.map((node) => {
                    return {
                        id: node?.id,
                        title: eligibilityStruct(node),
                        description: node?.description
                    }
                })
            }

            setEligibilities(eligiblities)

        }

    }, [role])



    const handleDeleteRole = async (id) => {
        let roleId = getIntFromString(id)
        const response = await deleteRole({ id: roleId })
        if (response) {
            setDeleteRole(false);
            navigateRoute(role_definition_route.role_definition_home)
        }
    }

    const navigateToEditPage = () => {
        let role_definition_navigation = {
            parent_route: { key: 'role', label: 'Role' },
            child_route: { key: 'role_add_edit_view', role_id: role?.id }
        }
        localStorage.setItem('role_definition_navigation', JSON.stringify(role_definition_navigation))
        navigateRoute(role_definition_route.role_definition_home)
        
    }


    return (
        <RoleDefinitionDetailView
        entityDetails={role}
        entityTitle={"Role"}
        editNavigation = {navigateToEditPage}
        setDeleteEntity={setDeleteRole}
        gobackRoute={(role_definition_route.role_definition_home)}
        pageLoading={roleDetailsLoading}
        row_1={functionalCompetencies}
        row_2={responsibilities}
        row_3={eligibilities}
        deletePermission = {rolePermission?.delete_role}
        editPermission = {rolePermission?.edit_role}
        handleDelete={handleDeleteRole}
        deleteRecord={deleteRoleInfo}
        optionalAttribute = {{title:'Employee Level',detail:role?.employeeLevel}}
        {...props}
    />


    )
}
export default RoleDetailView;