import React from 'react';
import { Form, Slider } from 'antd';

const FormItem = Form.Item;

const RangeField = props => {
  const { min, max, marks, defaultValue, step, onAfterChange, trackStyle, handleStyle, disabled,selectedId,autoscroll, ...rest } = props;
  console.log("min, max, marks, defaultValue, step, onAfterChange, trackStyle, handleStyle, disabled,selectedId",min, max, marks, defaultValue, step, onAfterChange, trackStyle, handleStyle, disabled,selectedId)
  return (
    <div style={{ marginBottom: '5vh', padding: '0px 24px 0px 0px'}}>
      <FormItem labelCol={{ span: 24 }} wrapperCol={{ span: 24, offset: 1 }} {...rest}>
        <Slider
          id={selectedId}
          min={min}
          max={max}
          marks={marks}
          defaultValue={defaultValue}
          step={step}
          onAfterChange={onAfterChange}
          trackStyle={trackStyle}
          handleStyle={handleStyle}
          disabled={disabled}
          onChange={e=>autoscroll&&autoscroll(selectedId)}
        />
      </FormItem>
    </div>
  );
};

export default RangeField;
