import gql from 'graphql-tag';

// Make a single fragment out of the data fetched in below query and use it in updatetool mutation.






export const TOOL_TYPE_ADMIN_PANEL = gql`

fragment ToolType on ToolType{
  id
  title
  uiFlow
  thumbnailImage
  description
  status
  public
  pubDate
 
  vertical{
    edges{
      node{
        id
        name
       subvertical{
        edges{
          node{
            id
            name
            
          }
        }
      }
      }
    }
  }
  team{
    edges{
      node{
        id
        name

      }
    }
  }
  
  organization{
    edges{
      node{
        id
        name
      logo
      }
    }
  }
  
  formsection{
    edges{
      node{
        id
        title
        sequence
        editable
        referencefield{
        edges{
          node{
            id
            question{
              id
              questionText
              questionAddress
              helpText
              sequence
              numberOfAnswers
              
              pubDate
              choiceSet{
                edges{
                  node{
                    id
                    label
                    value
                    sequence
                    inputType
               
                  }
                }
              }
            }
            sequence
            editable
            helpText
            }
          }
        }
        
        simpleField{
          edges{
            node{
              id
              label
              inputType
              editable
              sequence
              helpText
              simplefieldchoiceSet{
                edges{
                  node{
                    id
                    label
                    value
                    sequence
                    graphic
                    
                  }
                }
              }
            }
          }
        }
      }
    }
  }   
  commitment {
    edges {
      node {
        id
        title
        coachingcommitmentSet{
          edges{
            node{
              id
              title
              description
            }
          }
        }
        description
        condition{
          edges{
            node{
              id
              note
              
              leftOperand
              {
                id
                note
                weightedQuestions{
                  edges{
                    node{
                      id
                      question{
                        id
                        questionText
                      }
                    }
                  }
                }
              }
              operator
              rightOperand{
                id
                note
                operation
              }
            }
          }
        }
      }
    }
  }
  textsection{
    edges{
      node{
        id
        title
        text
        sequence
        condition{
          edges{
            node{
              id
              note
              leftOperand
              {
                id
                note
                weightedQuestions{
                  edges{
                    node{
                      id
                      question{
                        id
                        questionText
                      }
                    }
                  }
                }
              }
              operator
              rightOperand{
                id
                note
               
              }
            }
          }
        }
        renderedText
      }
    }
  }
}


`;




export const TOOL_BY_ID_ADMIN_QUERY = gql`
  ${TOOL_TYPE_ADMIN_PANEL}
  query getToolByIdAdmin($id: ID!) {
    getToolByIdAdmin(id: $id) {
       ...ToolType
    }
  }
`;
