// @ts-nocheck
import React, { useState } from 'react';
import { compose } from '../../core';
import { withApollo } from '@apollo/client/react/hoc';
import {DISCARD_REQUEST_LIST} from '../graphql/DiscardRequestList.gql'
import { DISCARD_REQUEST_LIST_SUBSCRIPTION } from '../graphql/DiscardRequestSubscription.gql';
import DiscardRequestTableView from '../components/DiscardRequestTableView';
import { withDeleteDiscardRequest } from './objective-keyresult-operations';
import { ASSIGNED_REQUEST_LIST, ASSIGNED_REQUEST_SUBSCTIPTION } from '../graphql/AssignmentRequestList.gql';
import AssignmentRequestView from '../components/OkrDiscardAssignementView';
import gql from 'graphql-tag';
import {useLazyQuery } from '@apollo/client';
import { getIntFromString } from 'modules/look';
import moment from 'moment-timezone';
const OkrAssignmentContainer = (props)=>{
    
const {me,client,history,subscribeToMore,location}=props
const [discard,setDiscard]=React.useState()
const [AssignPendingList,setAssignPendingList]=React.useState()
const [NewAssignPendingList,setNewAssignPendingList]=React.useState()
const [AssignAcceptedList,setAssignAcceptedList]=React.useState()
const [loading,setLoading]=React.useState(true)
const [okrIds,setOkrIds]=React.useState()
const [actionData,setActonData]=React.useState()
const [counts,setCounts]=React.useState({})
const [filterValue,setFilterValue]=React.useState()
const dataRef = React.useRef()
const m = React.useRef( true )

  let assignSub = undefined
  React.useEffect( () => {
    return () => {
      if ( assignSub ) {
        assignSub.unsubscribe();
      }
    }
  })

  React.useEffect( () => {
    return () => {
      m.current = false
    }
  }, [] )

  React.useEffect( () => {
    m.current = true

  }, [] )

const  AssignPendingDataList = async (filterData) => {
    setLoading(true)
    let okrids=[]
      const { data } = await client.query({
          query: ASSIGNED_REQUEST_LIST,
          variables:filterData,
          fetchPolicy: 'network-only'
      });
      if(data){
        setCounts({totalCount:data?.AcceptedRequest?.totalCount>0||data?.PendingRequest?.totalCount>0?data?.AcceptedRequest?.totalCount+data?.PendingRequest?.totalCount:0})
        let mergeData =data?.PendingRequest?.edges.concat(data?.AcceptedRequest?.edges)
        setAssignPendingList(mergeData?.filter(item=>item?.node?.requestStatus!=='ACCEPTED'))
        if(data?.PendingRequest?.edges?.length){
          data.PendingRequest.edges.forEach((item)=>{
            if(item?.node?.requestStatus==='NEEDS_DISCUSSION'){
              okrids.push(item?.node?.okr?.id)
            }
          })
        }
        if(okrids?.length){
          setOkrIds(okrids)
        }
        else{
          setNewAssignPendingList(mergeData?.filter(item=>item?.node?.requestStatus!=='ACCEPTED'))
        }
        setAssignAcceptedList(mergeData?.filter(item=>item?.node?.requestStatus==='ACCEPTED'))
        dataRef.current =mergeData
        assignSub= await client.subscribe( {
          query: ASSIGNED_REQUEST_SUBSCTIPTION,
          variables: filterData,
          fetchPolicy: 'network-only'
        } ).subscribe( {
          next( result ) {
            let assignRequestCacheData = dataRef.current
            switch ( result.data.contributorSubscription.mutation ) {
              case 'CREATE':
                if ( m.current ) {
                  const updated = [ ...assignRequestCacheData, {node:result.data.contributorSubscription.contributor} ]
                  setAssignPendingList(updated?.filter(item=>item?.node?.requestStatus!=='ACCEPTED'))
                  updated.forEach((item)=>{
                    if(item?.node?.requestStatus==='NEEDS_DISCUSSION'){
                      okrids.push(item?.node?.okr?.id)
                    }
                  })
                  if(okrids?.length){
                    setOkrIds(okrids)
                  }
                  else{
                    setNewAssignPendingList(updated?.filter(item=>item?.node?.requestStatus!=='ACCEPTED'))
                  }
                  setAssignAcceptedList(updated?.filter(item=>item?.node?.requestStatus==='ACCEPTED'))
                  dataRef.current = updated
                }
                break
              case 'UPDATE':
                if ( m.current ) {
                  const Update =assignRequestCacheData.map( item =>
                      item?.node?.id === result?.data?.contributorSubscription?.contributor?.id
                        ? {node:result.data.contributorSubscription.contributor}
                        : item
                    ) 
                    if(Update){
                        setAssignPendingList(Update?.filter(item=>item?.node?.requestStatus!=='ACCEPTED'))
                        Update.forEach((item)=>{
                          if(item?.node?.requestStatus==='NEEDS_DISCUSSION'){
                            okrids.push(item?.node?.okr?.id)
                          }
                        })
                        if(okrids?.length){
                          setOkrIds(okrids)
                        }
                        else{
                          setNewAssignPendingList(Update?.filter(item=>item?.node?.requestStatus!=='ACCEPTED'))
                        }
                        setAssignAcceptedList(Update?.filter(item=>item?.node?.requestStatus==='ACCEPTED'))
                        dataRef.current = Update
                      }
                  }
                break
              case 'DELETE':
                if ( m.current ) {
                  const deleted=assignRequestCacheData.filter( item =>
                      item?.node?.id === result?.data?.contributorSubscription?.contributor?.id
                        ? false
                        : true
                    ) 
                    setAssignPendingList(deleted?.filter(item=>item?.node?.requestStatus!=='ACCEPTED'))
                    deleted.forEach((item)=>{
                      if(item?.node?.requestStatus==='NEEDS_DISCUSSION'){
                        okrids.push(item?.node?.okr?.id)
                      }
                    })
                    if(okrids?.length){
                      setOkrIds(okrids)
                    }
                    else{
                      setNewAssignPendingList(deleted?.filter(item=>item?.node?.requestStatus!=='ACCEPTED'))
                    }
                    setAssignAcceptedList(deleted?.filter(item=>item?.node?.requestStatus==='ACCEPTED'))
                    dataRef.current = deleted
                }
                break
              default:
                break
            }
          }
  
        } )
        setLoading(false)
    } 
}

const All_ACTION_ITEMS_DYNAMIC =gql`
    query allActionItems {
      ${okrIds?.map(
          (item) =>
           `query_${getIntFromString(item)}: allActionItems(okr:${JSON.stringify(item)},isAgenda:${true}) {
              edges{
                node{
                    id
                    createdAt
                    meeting{
                        id
                        title 
                    }
                    title
                }
              }
           }
         `
        )
      .join('')}
    }
`;
  
const [allActionItems ,{ data:allAction, isloading, error }] = useLazyQuery (All_ACTION_ITEMS_DYNAMIC);

React.useEffect(()=>{
  if(allAction&&AssignPendingList?.length){
    let newdata =AssignPendingList?.map(v => ({...v, meeting:allAction[`query_${getIntFromString(v?.node?.okr?.id)}`]}))
    if(newdata?.length){
      setNewAssignPendingList(newdata)
    }
    else{
      setNewAssignPendingList(AssignPendingList)
    }
  }
},[allAction,AssignPendingList])

React.useEffect(()=>{
  if(okrIds?.length>0){
    getAllActionItem()
  }
},[okrIds])

const getAllActionItem=async()=>{
  await allActionItems()
}

  React.useEffect(()=>{
    if(me){
      AssignPendingDataList({employee:me?.employee?.id,role: "ASSIGNED", requestStatus_In: ["PENDING", "NEEDS_DISCUSSION"],requestStatus_Iexact:"ACCEPTED",offset:0,first:10,tz:moment.tz.guess()})
    }
    else{
    }
  },[me])

  const PaginantionCall =(value)=>{
    if(value){
      let id=(value*10)-10
      if(filterValue){
        AssignPendingDataList({...filterValue,offset:id,first:10,tz:moment.tz.guess()})
      }
      else{
        AssignPendingDataList({employee:me?.employee?.id,role: "ASSIGNED", requestStatus_In: ["PENDING", "NEEDS_DISCUSSION"],requestStatus_Iexact:"ACCEPTED",offset:id,first:10,tz:moment.tz.guess()})
      }
    }
    else{
      AssignPendingDataList({employee:me?.employee?.id,role: "ASSIGNED", requestStatus_In: ["PENDING", "NEEDS_DISCUSSION"],requestStatus_Iexact:"ACCEPTED",offset:0,first:10,tz:moment.tz.guess()})
    }
  }

    return (
        <AssignmentRequestView {...props} AssignPendingList={NewAssignPendingList} AssignAcceptedList={AssignAcceptedList} load={loading} onfilter={e=> {AssignPendingDataList(e);setFilterValue(e)}} counts={counts} onPaginantion={e=>PaginantionCall(e)}></AssignmentRequestView>
    )
}
export default compose(withApollo,withDeleteDiscardRequest)(OkrAssignmentContainer)