import gql from 'graphql-tag';

export const UPDATE_TRACKING_MUTATION = gql`
mutation updateTracking($id: ID!,$comment: String!){
    updateTracking(id:$id,comment:$comment){
       tracking{
        id
        updatedAt
        createdAt
       }
    }
}
`