

import React from "react";
import { compose } from "../../core";
import RatingScaleView from "../components/RatingScaleView";
import { RATING_SCALES, RATING_SCALE_SUBSCRIPTION } from "../graphql/RatingScale.gql";
import { withCreateRatingScale, withDeleteRatingScale } from "./RatingScaleOperations";

const RatingScaleContainer = (props) => {

    
    const { client,permission } = props

    const [ratingScaleLoading,setRatingScaleLoading] = React.useState(false);
    const [ratingScales,setRatingScales] = React.useState([]);
    const [thresholdToDelete,setThresholdToDelete] = React.useState(undefined);
    
    const rating_scale_list_ref = React.useRef([])
    const rating_scale_subscription = React.useRef(null)

     // to update the employee list reference when the state gets changed
    React.useEffect(() => {
        rating_scale_list_ref.current = ratingScales
    }, [ratingScales])


    // to check if the user have permission to fetch employee level data
    React.useEffect(() => {
        if (permission && permission?.required_permission) {
            let nav ={
                parent_route :{key:'rating_scale',label:'Threshold'},
                child_route : {key:'rating_scale_home_view'}
            }
            localStorage.setItem('role_definition_navigation',JSON.stringify(nav))
            getRatingScales();
        }
    }, [permission])

    // Code to be executed when the component is unmounted
    React.useEffect(() => {
        return () => {
            if(rating_scale_subscription?.current){
                rating_scale_subscription.current.unsubscribe();
                rating_scale_subscription.current = null
            }
        };
    }, []);


    // Query for fetching all rating scales
    const getRatingScales = async (filter) => {
        setRatingScaleLoading(true)
        const { data } = await client.query({
            query: RATING_SCALES ,
            fetchPolicy: 'network-only',
            variables: filter
        });
        if (data?.ratingScales) {
            setRatingScaleLoading(false)
            let list = data?.ratingScales?.edges.map(({ node },index) => {return {...node , index:index}})
            setRatingScales(list)
            if (!rating_scale_subscription?.current) {
                ratingScalesubscription()
            }
        }
    }


     // to add subscription to rating scale
     const ratingScalesubscription = async () => {
        rating_scale_subscription.current = client.subscribe({
            query: RATING_SCALE_SUBSCRIPTION,
            fetchPolicy: 'no-cache'
        }).subscribe({
            next(result) {
                let rating_scale_cache_data = [].concat(rating_scale_list_ref.current)
                let subscription_data = result.data.ratingScaleSubscription
                switch (subscription_data.mutation) {
                    case 'CREATE':
                        // rating_scale_cache_data.push(subscription_data?.ratingScale)
                        // let newRecords = rating_scale_cache_data?.filter((rec)=>rec?.index !== rating_scale_cache_data[rating_scale_cache_data?.length - 1 ].sequence )
                        // newRecords = newRecords?.map((i,index)=>{return {...i,index}})
                        let new_value = rating_scale_cache_data?.map(item=>item?.index === subscription_data?.ratingScale?.sequence?
                            subscription_data?.ratingScale:
                            item
                        )
                        setRatingScales(new_value)
                      
                        break
                    case 'UPDATE':
                        let doc = rating_scale_cache_data?.find(item=>item?.id === subscription_data?.ratingScale?.id)
                        if(doc){
                            Object.entries(subscription_data?.ratingScale).forEach(item=>{
                                doc[item[0]]=item[1]
                            })
                            rating_scale_cache_data = rating_scale_cache_data?.map((i,index)=>{return {...i,index}})
                            setRatingScales(rating_scale_cache_data)
                        }
                        break
                    case 'DELETE':
                        rating_scale_cache_data = rating_scale_cache_data?.filter(item=>item?.id !== subscription_data?.ratingScale?.id)
                        rating_scale_cache_data = rating_scale_cache_data?.map((i,index)=>{return {...i,index}})
                        setRatingScales(rating_scale_cache_data)
                        break
                    default:
                        break
                }
            }
        });
    }



    return (
       <RatingScaleView ratingScaleLoading = {ratingScaleLoading} 
       setRatingScaleLoading = {setRatingScaleLoading}
       setRatingScales = {setRatingScales}
       thresholdToDelete = {thresholdToDelete}
       setThresholdToDelete = {setThresholdToDelete}
       ratingScales = {ratingScales} {...props} />
    )

}

export default compose(withCreateRatingScale,withDeleteRatingScale)(RatingScaleContainer)