// @ts-nocheck
import React from 'react';
import { Form, Slider, Modal, Spin, Row,Tooltip,Button } from 'antd';
import progress_update from '../../assets/progress_update.svg'
import { useState } from 'react';
import CustomStep from 'modules/look-v2/Components/stepProgress';
import close from '../../assets/close.svg'
import active_tick from '../../assets/active_tick.svg'
import inactive_tick from '../../assets/inactive_tick.svg'
import { AddButton } from 'modules/look-v2';
import { displayUtcToUserTimezone, getIntFromString } from 'modules/look';
import progress_note from '../../assets/progress_note.svg'
import styled from 'styled-components';
import '../ObjectiveKeyResultStyles/ProgressTracker.css'
import { PlusOutlined} from "@ant-design/icons";

const ProgessUpdate = props => {
  const { okr, createTracking, updateTracking, trackings, objectiveDetail, keyResultDetail, progressloading, setProgressloading, fetchProgress, MilestoneDetail,update_progress,editable } = props
  const [visible, setVisible] = useState(false)
  const [stepData, setstepData] = useState()
  const [addLog, setaddLog] = useState(false)
  const [previousProgress, setpreviousProgress] = useState(0)
  const [progress, setprogress] = useState()
  const [comment, setcomment] = useState()
  const [current, setcurrent] = useState(0)
  const trackStyle = { backgroundColor: '#25EA68' }
  const handleStyle = { backgroundColor: '#25EA68' }
  React.useEffect(() => {
    if (trackings) {

      setResponse(trackings)
    }
  }, [trackings])

  React.useEffect(() => {
    if (visible) {
      fetchProgress()
    } else {
      setcomment(null)
      setprogress(null)
      setaddLog(false)
    }
  }, [visible])

  const setResponse = (response) => {
    if (response?.edges?.length) {
      setpreviousProgress(parseInt(response?.edges[response?.edges?.length - 1]?.node?.progress) + 1)
    }

    let list = response?.edges?.map(({ node }, index) => {

      return {
        title: `${parseInt(node?.progress)}%`,
        comment: node?.comment,
        updatedAt: node?.updatedAt,
        description: displayUtcToUserTimezone(node?.createdAt, 'DD, MMM YYYY'),
        id: node?.id,
        progress: parseInt(node?.progress)
      }
    })

    if (list[0]?.progress !== 0) {
      list = [{ progress: 0, title: "0%" }].concat(list)
    }
    setcurrent(list?.length - 1)


    list = list?.map((item, index) => {
      return {
        ...item,
        width: list?.length !== (index + 1) ? calculateWidth(list, index) : ''
      }
    })
    setstepData(list)
  }

  const calculateWidth = (list, index) => {
    return list[index + 1]?.progress - list[index]?.progress
  }

  const onsubmit = async () => {
    if (progress) {
      let trackingData = {
        progress: `${progress}.0`,
        comment: comment || '',
        okrId: okr?.id
      }
      setProgressloading(true)
      let response = await createTracking({ trackingData })
      if (response) {
        setProgressloading(false)
        let tracking = trackings
        tracking['edges'].push({
          node: {
            id: response?.id,
            updatedAt: response?.updatedAt,
            createdAt: response?.createdAt,
            comment: comment || '',
            progress: `${progress}.0`,
          }
        })
        if(update_progress){
          update_progress(okr?.id,progress)
        }
        setResponse(tracking)
        setprogress(null)
        setaddLog(null)
        setcomment(null)
      }
    }
  }

  const update = async (data) => {
    
    setProgressloading(true)
    let response = await updateTracking({ id: getIntFromString(data?.id), comment: data[data?.id] })
    
    setProgressloading(false)
  }

  const marks = () => {
    let value = { 100: { label: <p style={{ fontWeight: "bold" }}>100%</p> } }
    value[previousProgress] = { label: <p style={{ fontWeight: "bold" }}>{previousProgress}%</p> }


    return value
  }


  const AddIndicativeProgress = (indicativeProgress) => {
    
    let trackingData = [{
      title: `${indicativeProgress}%`,
      progress: indicativeProgress,
      comment: comment || '',
      okrId: getIntFromString(okr?.id)
    }]
  
    let filteredStepData = stepData?.filter((element) =>
      (element?.progress === 0 || element?.id)
    )  
    let collectiveData = [...filteredStepData,...trackingData]
  
    if (collectiveData?.length) {
      setstepData(collectiveData)
      setprogress(indicativeProgress)
    }


  }


  return (
    <>
      <ProgressTrackerModal
        className='progress-modal'
        visible={visible}
        height={400}
        closable={false}
        footer={false}
      >
        <div style={{ width: "100%" }}>
          <Row align='middle' justify='center' style={{ marginBottom: '30px' }}>
            <img src={progress_note} style={{ width: "40px", height: "29px" }} alt="" />
            <div align='center' className='modal-title'>Track Progress</div>
          </Row>

          <ModalHeader>
            <div style={{ width: "10px", height: "10px" }}></div>

            <CloseIcon style={{ cursor: "pointer" }} onClick={(e) => { setVisible(false) }} src={close} alt="" />
          </ModalHeader>
          <p className='modal-subtitle'>{objectiveDetail?.goalStatement && <span>{objectiveDetail?.goalStatement}/</span>}{keyResultDetail && (<span>{keyResultDetail?.goalStatement}/</span>)}{MilestoneDetail?.goalStatement && <span>{MilestoneDetail?.goalStatement}/</span>}{okr?.goalStatement}</p>
          {
            !addLog && (previousProgress < 100) && (

              <div align="center"> 
              {!editable? <Tooltip placement='top' title="The field is currently inaccessible as the deadline for your OKRs has passed.">
                <Button className='add-button-disable' style={{border:'none'}} disabled={!editable} onClick={()=>setaddLog(true)}><PlusOutlined className='plus-icon'/>Add log</Button>
                </Tooltip>:
                <Button className='add-btn' onClick={()=>setaddLog(true)}><PlusOutlined className='plus-icon'/>Add log</Button>}
              
              </div>
            )
          }

          <Spin spinning={progressloading}>



            <div align="center">
              {
                addLog && (previousProgress < 100) && (
                  <div className='progress-add-container'>
                    <div style={{ width: '70%', marginTop: "28px", marginBottom: "35px" }}>
                      <Slider
                        min={previousProgress}
                        max={100}
                        marks={marks()}
                        step={1}
                        // onAfterChange={e => setprogress(e)}
                        onAfterChange={e => AddIndicativeProgress(e)}
                        trackStyle={trackStyle}
                        handleStyle={handleStyle}
                        disabled={false}
                      />
                    </div>
                    <div style={{ position: 'relative' }}>
                      <textarea value={comment} onChange={(e) => { setcomment(e?.target?.value) }} placeholder='Describe Progress (Optional)' className='progress-textarea-container' ></textarea>
                      {
                        progress ?
                          <img className='textarea-submit' onClick={(e) => { onsubmit() }} src={active_tick} alt="" />
                          : <img className='textarea-submit' src={inactive_tick} alt="" />
                      }

                    </div>
                  </div>
                )
              }
            </div>
            <ProgressUpdateDisplayContainer>
              <CustomStep stepData={stepData} current={current} popoverupdate={(e) => { update(e) }} />
            </ProgressUpdateDisplayContainer>
          </Spin>
        </div>
      </ProgressTrackerModal>
      <div style={{ width: "35px", height: "26px" }}>
        <Tooltip title={"Update Progress"}>
        <img style={{ cursor: "pointer" }} onClick={(e) => { setVisible(true) }} src={progress_update} alt="" />
        </Tooltip>
      </div>
    </>
  );
};

export default ProgessUpdate;

const ProgressUpdateDisplayContainer = styled.div`

margin-top:80px;
margin-bottom:75px;

`;


const ProgressTrackerModal = styled(Modal)`

.ant-spin-container{
  width:100%;
  overflow:auto;
}

.ant-spin-container::-webkit-scrollbar{
  display:none;
}

`;


const ModalHeader = styled.div`

display: flex;
flex-direction: row;
flex-wrap: nowrap;
gap: 10px;
width:100%;

@media screen and (max-width:420px){
  gap:0px;
}
`

const CloseIcon = styled.img`


position:absolute;
right:40px;
top:35px;

@media screen and (max-width:420px){
  margin-left:20px;

}





`;