import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Radar, Bar } from "@ant-design/charts";
import { DataSet } from "@antv/data-set";
import { Col, Row, Switch, Spin } from "antd";

import { compose } from "../../core";

import { withTheirPredictionSurveyReport } from "../containers/ReportOperations";

// const socres = _.get(report.userReport.socres, [stat, 'overall']);
// if (socres <= 5) newObj.star = 'Low';
// else if (socres > 5 && socres <= 7.5) newObj.star = 'Moderate';
// else if (socres > 7.5 && socres <= 9) newObj.star = 'High';
// else if (socres > 9 && socres <= 10) newObj.star = 'Excellent';

const RenderPredictionGraphComponent = (props) => {
  const {
    userReport,
    theirReport,
    loading,
    actionLoading,
    receiver,
    name,
    isBar,
  } = props;
  const [graphLoading, setGraphLoading] = useState(true);
  const [overall, setOverall] = React.useState([]);
  const [identity, setIdentity] = useState([]);
  const [future, setFuture] = useState([]);
  const [ability, setAbility] = useState([]);
  const [execution, setExecution] = useState([]);
  const [social, setSocial] = useState([]);

  useEffect(() => {
    const { DataView } = DataSet;
    const isUserReport =
      userReport.report && Object.keys(userReport.report).length > 0;

    function getData(i, j, k) {
      const finalObj = [];
      Object.keys(j).map((stat) => {
        const newObj = {};
        newObj.name = stat;
        if (i) {
          newObj[name] = Number(
            parseFloat(_.get(i, k ? [stat, k] : [stat])).toFixed(2)
          );
        }
        newObj[receiver] = Number(
          parseFloat(_.get(j, k ? [stat, k] : [stat])).toFixed(2)
        );
        finalObj.push(newObj);
      });
      const res = new DataView().source(finalObj);
      res.transform({
        type: "fold",
        fields: isUserReport ? [name, receiver] : [receiver],
        key: "user",
        value: "score",
      });
      return res;
    }

    if (!loading && theirReport) {
      setOverall(
        getData(
          isUserReport && userReport.report.socres,
          theirReport.socres,
          "overall"
        )
      );
      setIdentity(
        getData(
          isUserReport && userReport.report.socres.Identity.factors,
          theirReport.socres.Identity.factors
        )
      );
      setFuture(
        getData(
          isUserReport &&
            userReport.report.socres["Future Orientation"].factors,
          theirReport.socres["Future Orientation"].factors
        )
      );
      setAbility(
        getData(
          isUserReport && userReport.report.socres.Ability.factors,
          theirReport.socres.Ability.factors
        )
      );
      setExecution(
        getData(
          isUserReport && userReport.report.socres.Execution.factors,
          theirReport.socres.Execution.factors
        )
      );
      setSocial(
        getData(
          isUserReport && userReport.report.socres["Social Immunity"].factors,
          theirReport.socres["Social Immunity"].factors
        )
      );
    }
  }, [loading, name, receiver, theirReport, userReport]);

  var Bconfig = isBar
    ? {
        isGroup: true,
        marginRatio: 0,
        maxBarWidth: 40,
      }
    : {};

  const config = {
    xField: isBar ? "score" : "name",
    yField: isBar ? "name" : "score",
    seriesField: "user",
    meta: {
      score: {
        alias: "score",
        min: 0,
        max: 10,
      },
    },
    color: (props) => {
      if (props.user === receiver) {
        return "#B0CB1F";
      } else if (props.user === name) {
        return "#2194ff";
      }
    },
    onReady: () => setGraphLoading(false),
    loading:
      loading ||
      actionLoading ||
      graphLoading ||
      !(overall.rows && overall.rows.length > 0),
    xAxis: {
      line: null,
      tickLine: null,
    },
    ...Bconfig,
  };

  const configOverall = {
    data: overall.rows,
    ...config,
  };

  const configIdentity = {
    data: identity.rows,
    ...config,
  };

  const configFuture = {
    data: future.rows,
    ...config,
    xField: "score",
    yField: "name",
    isGroup: true,
    marginRatio: 0,
    maxBarWidth: 40,
  };

  const configAbility = {
    data: ability.rows,
    ...config,
  };

  const configExecution = {
    data: execution.rows,
    ...config,
  };

  const configSocial = {
    data: social.rows,
    ...config,
    xField: "score",
    yField: "name",
    isGroup: true,
    marginRatio: 0,
    maxBarWidth: 40,
  };

  const Graph = (props) => {
    if (isBar) return <Bar {...props} />;
    else return <Radar {...props} />;
  };

  return (
    <div /* style={{ height: '115vh' }} */>
      {/* {!actionLoading && !loading && overall.rows && ( */}
      <Row>
        <Col span={24}>
          <h2>Your MApp Overview:</h2>
          <Col span={24}>
            <Col span={24} style={{ minHeight: "35vh" }}>
              {overall.rows && overall.rows.length > 0 && (
                <Graph {...configOverall} />
              )}
            </Col>
          </Col>
          <br />
          <br />
        </Col>
        <Col span={24}>
          <h2>The Five MApp Anchors:</h2>
          <Col lg={{ span: 22, offset: 1 }}>
            <h3>Identity Anchor of MApp:</h3>
          </Col>
          <Col span={24} style={{ minHeight: "35vh" }}>
            {identity.rows && identity.rows.length > 0 && (
              <Graph {...configIdentity} />
            )}
          </Col>
          <br />
          <br />
        </Col>
        <Col span={24}>
          <Col lg={{ span: 22, offset: 1 }}>
            <h3>Future Orientation Anchor of MApp:</h3>
          </Col>
          <Col span={24} style={{ minHeight: "35vh" }}>
            {future.rows && future.rows.length > 0 && <Bar {...configFuture} />}
          </Col>
          <br />
          <br />
        </Col>
        <Col span={24}>
          <Col lg={{ span: 22, offset: 1 }}>
            <h3>Ability Anchor of MApp:</h3>
          </Col>
          <Col span={24} style={{ minHeight: "35vh" }}>
            {ability.rows && ability.rows.length > 0 && (
              <Graph {...configAbility} />
            )}
          </Col>
          <br />
          <br />
        </Col>
        <Col span={24}>
          <Col lg={{ span: 22, offset: 1 }}>
            <h3>Execution Anchor of MApp:</h3>
          </Col>
          <Col span={24} style={{ minHeight: "35vh" }}>
            {execution.rows && execution.rows.length > 0 && (
              <Graph {...configExecution} />
            )}
          </Col>
          <br />
          <br />
        </Col>
        <Col span={24}>
          <Col lg={{ span: 22, offset: 1 }}>
            <h3>Social Immunity Anchor of MApp:</h3>
          </Col>
          <Col span={24} style={{ minHeight: "35vh" }}>
            {social.rows && social.rows.length > 0 && <Bar {...configSocial} />}
          </Col>
          <br />
          <br />
        </Col>
      </Row>
      {/* )} */}
    </div>
  );
};

export default compose(withTheirPredictionSurveyReport)(
  RenderPredictionGraphComponent
);
