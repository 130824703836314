// @ts-nocheck
import { CheckOutlined} from "@ant-design/icons";
import { Button, Checkbox, Col, Row, Table, Tooltip, Spin } from "antd";
import EditActionItemModal from "modules/look-v2/Components/EditactionItemModal";
import SinglePageQuizView from "modules/survey/components/SinglePageQuizView";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { GROW_ADD_TOOL_ID } from '../../../config';
import discarded from '../../assets/discarded.svg';
import facing_challenges from '../../assets/facing_challenges.svg';
import In_progress from '../../assets/In_progress.svg';
import new_back_arrow from '../../assets/new_back_arrow.svg';
import not_started from '../../assets/not_started.svg';
import unsuccessful from '../../assets/unsuccessful.svg';
import { displayUtcToUserTimezone, getIntFromString, MetaTags } from "../../look";
import ReactMarkdown from '../../look/components/ReactMarkdown';
import '../css/CreateConversationView.css';
import RenderSurveyAnswer from "./RenderSurveyAnswer";
import grow_route from '../route/index'
import coaching_engagement_route from "../route/index";
import SideBulbToolContainer from "modules/look-v2/Container/SideBulbToolContainer";
import GrowAiTipContainer from "modules/ai-tips/container/grow-ai-tip-container";
import { ai_trigger_content } from "modules/ai-tips/ai-trigger-content";
const ConversationDetailView = props => {

  const { history, me, setloading, coachingConversationById, getUserCommitments,SelectSurvey, orgSurveyById, createCoachingConversation, newCreateActionItem,onCustomUserCommitment, customUserCommitmentsForCoaching, navigateRoute, detail_loading,addCoacheeComm,SetAITrigger,coachingPermitted } = props
  const [ answerList, setanswerList ] = React.useState( [] )
  const [ actionItemOfCoach, setactionItemOfCoach ] = React.useState( [] )
  const [ actionItemOfCoachee, setactionItemOfCoachee ] = React.useState( [] )
  const [ prevTotalDuration, setPrevTotalDuration ] = React.useState( 0 );
  const startTime = new Date().getTime();
  const [ submitted, setSubmitted ] = React.useState( false );
  const [ visible, setVisible ] = React.useState( false );
  const [ actionItem, setActionItem ] = React.useState();
  const [ actionItemDetails, setActionItemDetails ] = React.useState();
  const [ actionItemStatus, setActionItemStatus ] = React.useState();
  const [ dueDateCoachee, setDueDateCoachee ] = useState( undefined )
  const [ dueDateCoach, setDueDateCoach ] = useState( undefined )
  const [toolReturn,setToolReturn]=React.useState()
  const [ selectedHeight, setSelectedHeight ] = React.useState( '110vh' )


  React.useEffect( () => {
    if ( coachingConversationById?.response ) {

      let questionId = coachingConversationById?.response?.answerSet?.edges?.map( item => item?.node?.question?.id )

      var setObj = new Set(); // create key value pair from array of array
      var uniqueIds = questionId.reduce( ( acc, item ) => {
        if ( !setObj.has( item ) ) {
          setObj.add( item )
          acc.push( item )
        }
        return acc;
      }, [] )

      let edges = uniqueIds?.map( ( item ) => {
        return {
          questionId: item,
          question: coachingConversationById?.response?.answerSet?.edges?.find( que => que?.node?.question?.id === item )?.node?.question,
          answers: coachingConversationById?.response?.answerSet?.edges?.filter( answer => answer?.node?.question?.id === item )?.map( data => data?.node?.answer ),
          inputType: coachingConversationById?.response?.answerSet?.edges?.find( que => que?.node?.question?.id === item )?.node?.question?.choiceSet?.edges[ 0 ]?.node?.inputType || 'TEXT',
        }
      }
      )

      setanswerList( edges )
    }
    if ( coachingConversationById?.actionItems?.edges?.length ) {

      let ActionCoach = coachingConversationById?.actionItems?.edges?.filter( item =>
        item?.node?.owner?.user?.employee?.id !== coachingConversationById?.coachee?.user?.employee?.id
      )

      let ActionCoachee = coachingConversationById?.actionItems?.edges?.filter( item =>
        item?.node?.owner?.user?.employee?.id === coachingConversationById?.coachee?.user?.employee?.id
      )

      setactionItemOfCoach( ActionCoach )
      setactionItemOfCoachee( ActionCoachee )
    } else {
      setactionItemOfCoach( [] )
      setactionItemOfCoachee( [] )
    }
  }, [ coachingConversationById ] )

  const ai_tip_triggered_ref = React.useRef(false)
  // Ai tip trigger function
  const aiTipTrigger =(key)=>{
      ai_tip_triggered_ref.current = true
      SetAITrigger({...ai_trigger_content[key],userType:"employee"})
  }

  const checkOwnerOfActionItem = ( e, actionItem ) => {
    setVisible( true )
    setActionItem( actionItem )
    setActionItemDetails( actionItem?.details );
    setActionItemStatus( actionItem?.status );
  }

  const coachCommitment = customUserCommitmentsForCoaching?.edges?.filter( x => x?.node?.user?.id === coachingConversationById?.coach?.user?.id )
  const coacheeCommitment = customUserCommitmentsForCoaching?.edges?.filter( x => x?.node?.user?.id === coachingConversationById?.coachee?.user?.id )

  const RenderActionItem = ( actionItem, dueDatesForActionItem ) => {

    return (
      <div className="action_item_section">
        <h4 className="grow-answer-title"><ReactMarkdown style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{actionItem?.title}</ReactMarkdown></h4>
        <div className="action_data">
          <h4 className="grow-answer-title" style={{ fontWeight: 600 }}> <span style={{ color: "#8998A2", paddingRight: "1.5em" }}>By</span>  {displayUtcToUserTimezone( actionItem?.dueDate, 'DD/MM/YYYY' )}</h4>
          {
            actionItem?.status === 'COMPLETED' ?
              <div className="active"><CheckOutlined style={{ color: "#fff" }} /></div>
              :
              actionItem?.owner?.user?.employee?.id === me?.employee?.id ?

                <div >
                  {

                    actionItem?.status === 'NOT_STARTED' ?
                      <Tooltip title={'Not Started'}>
                        <img src={not_started} style={{ height: '20px', marginBottom: '3px', cursor: 'pointer' }}
                          onClick={( e ) => checkOwnerOfActionItem( e, actionItem )} />
                      </Tooltip>
                      :
                      actionItem?.status === 'IN_PROGRESS' ?
                        <Tooltip title={'In Progress'}>
                          <img src={In_progress} style={{ height: '20px', marginBottom: '3px', cursor: 'pointer' }}
                            onClick={( e ) => checkOwnerOfActionItem( e, actionItem )} />
                        </Tooltip>
                        :
                        actionItem?.status === 'FACING_CHALLENGES' ?
                          <Tooltip title={'Facing Challenges'}>
                            <img src={facing_challenges} style={{ height: '21px', marginBottom: '3px', cursor: 'pointer' }}
                              onClick={( e ) => checkOwnerOfActionItem( e, actionItem )} />
                          </Tooltip>
                          :
                          actionItem?.status === 'DISCARDED' ?
                            <Tooltip title={'Discarded'}>
                              <img src={discarded} style={{ height: '20px', marginBottom: '3px', cursor: 'pointer' }}
                                onClick={( e ) => checkOwnerOfActionItem( e, actionItem )} />
                            </Tooltip>
                            :
                            actionItem?.status === 'UNSUCCESSFUL' ?
                              <Tooltip title={'Unsuccessful'}>
                                <img src={unsuccessful} style={{ height: '20px', marginBottom: '3px', cursor: 'pointer' }}
                                  onClick={( e ) => checkOwnerOfActionItem( e, actionItem )} />
                              </Tooltip>
                              :
                              null
                  }
                </div>
                :
                <div style={{ visibility: 'hidden' }}>
                  <img src={not_started} style={{ height: '20px', marginBottom: '3px', cursor: 'pointer' }} />
                </div>
          }

        </div>
      </div>
    )
  }
  const handleSubmit = async answers => {

    setloading( true )
    let createCoachingData = {
      coacheeId: getIntFromString( coachingConversationById?.coachee?.user?.employee?.id ),
      relatedTo: coachingConversationById?.relatedTo?.id,
      previousConversationId: getIntFromString( coachingConversationById?.id ),
      followUpOn: answers[ 'follow_up' ]
    }
    if ( !answers[ 'follow_up' ] ) {
      delete createCoachingData.followUpOn
    }
    let ansValues = []
    for ( const [ q, ans ] of Object.entries( answers ) ) {
      try {
        let decode = window.atob( q );
        if ( decode?.includes( "QuestionType:" ) && ans ) {
          if ( typeof ( ans ) === 'object' ) {
            let mappedData = ans?.map( ( item, index ) => {
              return {
                'questionId': getIntFromString( q ),
                answer: item,
                sequence: index
              }
            } )
            ansValues = ansValues.concat( mappedData )
          }
          else {
            ansValues.push( { 'questionId': getIntFromString( q ), answer: ans, sequence: 0 } )
          }
        }

      } catch ( e ) {
        // not base 64 key... canbe skipped as all survey answer keys will be base 64
      }
    }

    const response = await createCoachingConversation( { answers: ansValues, createCoachingData: createCoachingData, surveyId: getIntFromString( orgSurveyById?.id ) } );
    if ( response && response?.id ) {
      let coachAnswer = answers[ 'when_need' ].map( item => {
        return { title: item[ 0 ], deadline: new Date( item[ 1 ] ) }
      } ).map( item => addCoacheeComm( item.title, 40, '  ', getIntFromString( me.id ), response?.id, item.deadline ) )
      let coacheeAnswer = answers[ 'when_next_step' ].map( item => {
        return { title: item[ 0 ], deadline: new Date( item[ 1 ] ) }
      } ).map( item => addCoacheeComm( item.title, 40, '  ', getIntFromString(coachingConversationById?.coachee?.user?.id), response?.id, item.deadline ) )

      if ( answers?.when_need?.length || answers?.when_next_step?.length ) {
        if ( answers?.when_next_step?.length ) {
          for await ( const action of answers?.when_next_step ) {
            let actionItemData = {
              ownerId: getIntFromString( coachingConversationById?.coachee?.user?.employee?.id ),
              title: action[ 0 ],
              dueDate: action[ 1 ],
              relatedTo: response?.id
            }
            let item = await newCreateActionItem( { actionItemData } )
          }
        }
        if ( answers?.when_need?.length ) {
          for await ( const actionCoach of answers?.when_need ) {
            let actionItemData = {
              ownerId: getIntFromString( me?.employee?.id ),
              title: actionCoach[ 0 ],
              dueDate: actionCoach[ 1 ],
              relatedTo: response?.id
            }
            let item = await newCreateActionItem( { actionItemData } )
          }
        }
        setloading( false )
        props.cleanup()
        history.push( grow_route?.grow_detail?.replace( ":convoId", getIntFromString( response?.id ) ) )
      } else {
        setloading( false )
        props.cleanup()
        history.push( grow_route?.grow_detail?.replace( ":convoId", getIntFromString( response?.id ) ) )
      }
    } else {
      setloading( false )
    }
  }

  function handleChange( id, title, tool, checked ) {
    if ( onCustomUserCommitment ) {
      onCustomUserCommitment( {
        id: getIntFromString( id ),
        title: title,
        toolId: getIntFromString( tool?.id ),
        completed: !checked
      } )
    }

  }

  let coacheeCommsData = coacheeCommitment?.map( ( item, idx ) => {
    const { user: { firstName, lastName, }, title, tool, id, completed, dateCreated, dateCompleted, description, dueDate } = item.node
    const date = new Date( dateCreated );
    const dueDateNew = new Date( dueDate )
    const options = { year: 'numeric', month: 'long', day: 'numeric' }
    const formattedDate = date.toLocaleDateString( 'en-US', options )
    const formattedDueDate = dueDateNew.toLocaleDateString( 'en-US', options )
    const userId = item.node.user.id
    return { key: idx + 1, name: [ firstName, lastName ], title, dateCreated: formattedDate, dateCompleted, completed, tool, id, userId, dueDate: formattedDueDate }
  } )

  let coachCommsData = coachCommitment?.map( ( item, idx ) => {
    const { user: { firstName, lastName }, title, id, tool, completed, dateCreated, dateCompleted, description, dueDate } = item.node
    const date = new Date( dateCreated );
    const dueDateNew = new Date( dueDate )
    const options = { year: 'numeric', month: 'long', day: 'numeric' }
    const formattedDate = date.toLocaleDateString( 'en-US', options )
    const formattedDueDate = dueDateNew.toLocaleDateString( 'en-US', options )
    const userId = item.node.user.id


    return { key: idx + 1, name: [ firstName, lastName ], title, dateCreated: formattedDate, dateCompleted, completed, tool, id, userId, dueDate: formattedDueDate }
  } )


  useEffect( () => {
    if ( coachCommsData?.length && coacheeCommsData?.length ) {
      setDueDateCoach( coachCommsData[ 0 ]?.dueDate )
      setDueDateCoachee( coacheeCommsData[ 0 ]?.dueDate )
    }
  }, [ coachCommsData, coacheeCommsData ] )


  return (
    <>
      <MetaTags
        title="grow engagements page"
        description="grow engagements page."
      />
      <div className="coaching-conversation-root">
        <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'start', alignItems: "center", gap: "1em" }}>
          <img src={new_back_arrow} style={{ width: "20px", height: "20px", cursor: "pointer", marginLeft: '7px' }} onClick={() => { navigateRoute('-1') }} alt="" />
          <h4 className="coaching-engagement-page-title">Coaching Conversation Report</h4>
          <div style={{ position: 'relative' }}>
            <SideBulbToolContainer
              {...props}
              widthOfContainer={24}
              containerBorder={""}
              spacing={'0px'}
              hideTopDivider={true}
              keepDesign={true}
              top={"104px"}
              toolReturn={e=>setToolReturn(e)}
              tool_id={GROW_ADD_TOOL_ID}
            />
          </div>
        </div>
        <div style={{ background: "#fff", boxShadow: " 0px 10px 15px 0px rgba(170, 186, 198, 0.2)", width: '100%', padding: '1em 2em', borderRadius: ".5em", overflow: "auto", height: "76.5vh", marginTop: "1em" }}>
          <Spin spinning={detail_loading}>
            <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", gap: "2em" }}>
              <div>
                <h3 className="inner-page-heading" style={{ textAlign: "left", fontSize: '2.2em', fontWeight: "600" }}>Coaching Conversation Report for {coachingConversationById?.coachee?.user?.firstName}</h3>
                {coachingConversationById?.relatedTo && ( <p className="subtitle" style={{ textAlign: "left", color: "#8998A2", fontSize: "1.15em", fontWeigth: "400",marginTop:".5em" }}>This coaching conversation is related to {coachingConversationById?.relatedTo?.title}</p> )}
              </div>
              <div style={{ fontWeight: "400", fontSize: "1.25em", fontFamily: "Poppins",whiteSpace:"nowrap"}}>Create Date : <span style={{ fontWeight: "500" }}>{displayUtcToUserTimezone( coachingConversationById?.createdAt, "MMMM DD YYYY" )}</span></div>
            </div>
            {/* <div align="center" >

            <Row className="next_pre_container" style={{ margin: "0px 30px" }}>
              <Row style={{ flexWrap: "nowrap" }}><Button disabled={!coachingConversationById?.previousConversation} onClick={() => changeConversation( coachingConversationById?.previousConversation?.id )} className="arrow_btn" ><img src={previous_arrow} alt="" /></Button> <h4 className="arrow_label" style={{ color: !coachingConversationById?.previousConversation ? "gray" : "#2E2E2E" }}>Previous</h4></Row>
              <div className="calender_name"><img className="calender_icon" src={calender_active} alt="" /> {displayUtcToUserTimezone( coachingConversationById?.createdAt, "MMMM DD YYYY" )}</div>
              <Row style={{ flexWrap: "nowrap" }}><h4 className="arrow_label" style={{ color: !coachingConversationById?.nextCoachingConversation ? "gray" : "#2E2E2E" }}>Next</h4> <Button disabled={!coachingConversationById?.nextCoachingConversation} onClick={() => changeConversation( coachingConversationById?.nextCoachingConversation?.id )} className="arrow_btn"><img src={next_arrow} alt="" /></Button></Row>

            </Row>
            </div> */}
            <div style={{ width: '100%' }}>


              {
                answerList?.map( item =>
                (
                  <Col lg={{ span: 20 }} md={{ span: 18 }} xs={{ span: 24 }} sm={{ span: 24 }} >
                    <div style={{ marginBottom: "20px" }}><RenderSurveyAnswer {...item} conversationId={coachingConversationById?.id} /></div>
                  </Col>
                )
                )
              }
              {
                actionItemOfCoach?.length ?
                  <Col lg={{ span: 12 }} md={{ span: 12 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                    <h4 className="grow-question-title"><ReactMarkdown>The Next Steps</ReactMarkdown></h4>
                    {actionItemOfCoach?.map?.( ( item ) => RenderActionItem( item?.node, dueDateCoach ) )}
                  </Col>
                  :
                  null
              }

              {
                actionItemOfCoachee?.length ?
                  <Col lg={{ span: 12 }} md={{ span: 12 }} xs={{ span: 24 }} sm={{ span: 24 }} style={{ marginTop: "20px", display: "flex", flexDirection: "column", justifyContent: 'start', alignItems: 'flex-start' }} className="grow-detail-title">
                    <h4 className="grow-question-title"><ReactMarkdown>Assistance needed on</ReactMarkdown></h4>
                    {actionItemOfCoachee?.map?.( ( item ) => RenderActionItem( item?.node, dueDateCoachee ) )}
                  </Col>
                  :
                  null
              }

              {
                coachingConversationById?.followUpOn ?
                  <Col lg={{ span: 12 }} md={{ span: 12 }} xs={{ span: 24 }} sm={{ span: 24 }} style={{ marginTop: "20px", display: "flex", flexDirection: "column", justifyContent: 'start', alignItems: 'flex-start' }} className="grow-detail-title">
                    <h4 className="grow-question-title"><ReactMarkdown>FollowUp on</ReactMarkdown></h4>
                    <h4 className="grow-answer-title" style={{ fontWeight: 600, marginTop: "1em" }}>{displayUtcToUserTimezone( coachingConversationById?.followUpOn, 'DD/MM/YYYY' )}</h4>
                  </Col>
                  :
                  null
              }

              <Col lg={{ span: 12 }} md={{ span: 12 }} xs={{ span: 24 }} sm={{ span: 24 }} style={{ marginTop: "20px", display: "flex", flexDirection: "column", justifyContent: 'start', alignItems: 'flex-start' }} className="grow-detail-title">
                <h4 className="coach-eng-comm-title"><ReactMarkdown>Commitments related to this Coaching Conversation</ReactMarkdown></h4>
              </Col>
              <Col className="comm-list-section">
                <h4 className="grow-table-title">Commitments by Coachee</h4>
                <table className='rd-table'>
                  <thead>
                    <tr className='rd-row-header'>
                      <th className='rd-table-row-padding-left rd-left-radious'>
                        <h4 className='rd-table-header-label' style={{ fontSize: "1.35em" }}>Sr.No.</h4>
                      </th>
                      <th className='rd-table-header-label' style={{ textAlign: "center" }}>
                        <h4 className='rd-table-header-label' style={{ fontSize: "1.35em" }}>Commitment Title</h4>
                      </th>
                      <th className='rd-table-header-label' style={{ textAlign: "center" }}>
                        <h4 className='rd-table-header-label' style={{ fontSize: "1.35em" }}>status</h4>
                      </th>
                      <th className='rd-table-row-padding-rigth rd-content-center rd-table-header-label rd-right-radious'>
                        <h4 className='rd-table-header-label' style={{ fontSize: "1.35em" }}>Deadline</h4>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {coacheeCommsData?.map( item => (
                      <tr className='idp-row-body'>
                        <td className='rd-table-row-padding-left rd-left-radious' style={{ borderRight: "none" }}>
                          <h4 className='rd-table-body-label'>{item?.key}.</h4>
                        </td>
                        <td style={{ textAlign: "center", borderRight: "none", borderLeft: "none" }}>
                          <h4 className='rd-table-body-label'>{item?.title}</h4>
                        </td>
                        <td className="rd-content-center" style={{ textAlign: "center", borderRight: "none", borderLeft: "none" }}>
                          <div style={{ width: "100%", display: "flex", justifyContent: 'center', alignItems: "center" }}>
                            {item?.completed ? <h4 className='grow-status-complete'>Completed</h4> :
                              <h4 className='grow-status-inprgress'>In Progress</h4>
                            }
                          </div>
                        </td>
                        <td className='rd-table-row-padding-rigth rd-content-center rd-right-radious' style={{ borderLeft: "none" }}>
                          <h4 className='rd-table-body-label'>{displayUtcToUserTimezone( item?.dueDate, 'DD MMM YYYY' )}</h4>
                        </td>
                      </tr>
                    ) )}
                  </tbody>
                </table>
              </Col>
              <Col className="comm-list-section">
                <h4 className="grow-table-title">Commitments by Coach</h4>
                <table className='rd-table'>
                  <thead>
                    <tr className='rd-row-header'>
                      <th className='rd-table-row-padding-left rd-left-radious'>
                        <h4 className='rd-table-header-label' style={{ fontSize: "1.35em" }}>Sr.No.</h4>
                      </th>
                      <th className='rd-table-header-label' style={{ textAlign: "center" }}>
                        <h4 className='rd-table-header-label' style={{ fontSize: "1.35em" }}>Commitment Title</h4>
                      </th>
                      <th className='rd-table-header-label' style={{ textAlign: "center" }}>
                        <h4 className='rd-table-header-label' style={{ fontSize: "1.35em" }}>status</h4>
                      </th>
                      <th className='rd-table-row-padding-rigth rd-content-center rd-table-header-label rd-right-radious'>
                        <h4 className='rd-table-header-label' style={{ fontSize: "1.35em" }}>Deadline</h4>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {coachCommsData?.map( item => (
                      <tr className='idp-row-body'>
                        <td className='rd-table-row-padding-left rd-left-radious' style={{ borderRight: "none" }}>
                          <h4 className='rd-table-body-label'>{item?.key}.</h4>
                        </td>
                        <td style={{ textAlign: "center", borderRight: "none", borderLeft: "none" }}>
                          <h4 className='rd-table-body-label'>{item?.title}</h4>
                        </td>
                        <td className="rd-content-center" style={{ textAlign: "center", borderRight: "none", borderLeft: "none" }}>
                          <div style={{ width: "100%", display: "flex", justifyContent: 'center', alignItems: "center" }}>
                            {item?.completed ? <h4 className='grow-status-complete'>Completed</h4> :
                              <h4 className='grow-status-inprgress'>In Progress</h4>
                            }
                          </div>
                        </td>
                        <td className='rd-table-row-padding-rigth rd-content-center rd-right-radious' style={{ borderLeft: "none" }}>
                          <h4 className='rd-table-body-label'>{displayUtcToUserTimezone( item?.dueDate, 'DD MMM YYYY' )}</h4>
                        </td>
                      </tr>
                    ) )}
                  </tbody>
                </table>
              </Col>

              {
                orgSurveyById && (
                  <div style={{ marginTop: "2em" }}>
                    <h4 className="grow-question-title" style={{ fontSize: "2em", marginTop: "2em", marginBottom: "1.5em" }}>Next Converstation</h4>
                    <SinglePageQuizView
                      getUserCommitments={getUserCommitments}
                      submitted={submitted}
                      onSubmit={handleSubmit}
                      setPrevTotalDuration={setPrevTotalDuration}
                      quizStartTime={startTime}
                      showAdditonalCoahee={false}
                      showActionItem={true}
                      // showTermsAndCondition={showTermsAndCondition}
                      {...props}
                    />
                  </div>
                )

              }

              <div style={{ display: "flex", flexDirection: "row", gap: "1em" }}>
                {
                  !coachingConversationById?.nextCoachingConversation && !orgSurveyById && coachingConversationById?.coach?.user?.employee?.id === me?.employee?.id && (
                    <Button className='idp-submit idp-btn' onClick={() => { SelectSurvey( coachingConversationById?.response?.survey?.id ) }}>
                      Next Conversation
                    </Button>
                  )
                }
                {
                  !orgSurveyById && (
                    <Button onClick={() => { history.goBack() }} className='idp-cancel idp-btn'>
                      Cancel
                    </Button>
                  )
                }

              </div>
            </div>
            <Row>
              <EditActionItemModal coachingConversationById={coachingConversationById} visible={visible} setVisible={setVisible}
                setActionItemDetails={setActionItemDetails} actionItemDetails={actionItemDetails} {...props}
                actionItemStatus={actionItemStatus}
                actionItem={actionItem} setActionItemStatus={setActionItemStatus} />
            </Row>
          </Spin>
        </div>

      </div >
      {coachingPermitted&&coachingPermitted?.runpod_permission&&<GrowAiTipContainer {...props}/>}
    </>
  );
};

export default ConversationDetailView
export const MyTable = styled( Table )`
  table {
    border-spacing: 0 20px;
    thead{
      tr,th{
        background:#B0CB1F;
      }
      th h4{
        color:#fff;
        fontsize:24px;
        font-weight:700;
        text-align:center;
      }
      tr{
        height: 60px;
        th:first-child {
          border-top-left-radius: 11px !important;
          border-bottom-left-radius: 11px !important;
        }
        th:last-child {
          border-bottom-right-radius: 11px !important;
          border-top-right-radius: 11px !important;
        }
      }
    
    }
    tbody {
      tr {
        height: 60px;
        td {
          border: 1px solid #AFAFAF !important;
          border-style: solid none !important;
          div{
            font-weight: 400;
            font-size: 16px;
            line-height: 33px;
            color: #000000;
            text-align:center;
          }
        }
        td:first-child {
          border-left-style: solid !important;
          border-top-left-radius: 11px !important;
          border-bottom-left-radius: 11px !important;
        }
        td:last-child {
          border-right-style: solid !important;
          border-bottom-right-radius: 11px !important;
          border-top-right-radius: 11px !important;
        }
      }
    }
  }
`;

const ColScroll = styled( Col )`
::-webkit-scrollbar {
  height: 0px;
  width: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  border-radius: 10px;
  padding-right: 10px;
  margin:20px 5px 20px 5px;
  margin-right:5px
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color:#D6D6D6;
}
@media (max-width:900px){
  padding:0px 10px 0px 45px
}
`