const baseName = '/page';
const contactUsBaseName = '/contact-us';
const faqBaseName = '/faq';
const faqQuestionBaseName = '/faq-question';
const newBaseName = '/new';
const editBaseName = '/edit';
export const soul = 'soul'

const page_route = {
  contact: baseName + contactUsBaseName + '/admin-panel', 
  faq: baseName + faqBaseName + '/admin-panel',
  faqQuestion: baseName + faqQuestionBaseName + '/admin-panel' + '/:id',
  faqQuestionLink: baseName + faqQuestionBaseName + '/admin-panel' + '/',
  addFaq: baseName + faqBaseName + newBaseName,
  addFaqQuestion: baseName + faqQuestionBaseName + newBaseName + '/:id',
  addFaqQuestionLink: baseName + faqQuestionBaseName + newBaseName + '/',
  editFaq: baseName + faqBaseName + editBaseName + '/:id',
  editFaqLink: baseName + faqBaseName + editBaseName + '/',
  editFaqQuestion: baseName + faqQuestionBaseName + editBaseName + '/:id',
  editFaqQuestionLink: baseName + faqQuestionBaseName + editBaseName + '/',
  about: baseName + '/about-us',
  faqPage: baseName + faqBaseName,
  privacy: baseName + '/terms-and-condition/privacy',
  termsAndCondition: baseName + '/terms-and-condition',
  contactUs: baseName + contactUsBaseName,
  links: baseName + '/links',
  home :`/${soul}/:type`,
  soul_report:`/${soul}/user/survey/report`
};

export default page_route