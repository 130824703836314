import gql from 'graphql-tag';
import { RESPONSE_TYPE } from './ResponseType.gql';

export const SUBMIT_TOKEN_RESPONSE_MUTATION = gql`
  ${RESPONSE_TYPE}
  mutation submitTokenResponse($token: String!, $answers: [AnswerInput]!, $surveyId: Int!, $totalDuration: Int) {
    submitTokenResponse(token: $token, answers: $answers, surveyId: $surveyId, totalDuration: $totalDuration) {
      response {
        ...ResponseType
      }
    }
  }
`;
