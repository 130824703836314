import gql from 'graphql-tag';

export const JOB_FAMILY_LIST = gql`
    query allJobFamilies($title_Icontains: String,$genericCompetency_Title_Icontains: String,$role_Title_Icontains: String,$first: Int,$after:String) {
        allJobFamilies(title_Icontains:$title_Icontains,genericCompetency_Title_Icontains:$genericCompetency_Title_Icontains,role_Title_Icontains:$role_Title_Icontains,first:$first,after:$after) {
            pageInfo{
                hasNextPage
                endCursor
            }
            edges {
                node {
                    id
                    title
                    role {
                        edges {
                            node {
                                id
                                title
                                vertical{
                                    id
                                    name
                                }
                            }
                        }
                    }
                    genericCompetency {
                        edges {
                            node {
                                id
                                title
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const JOB_FAMILY_DELETE = gql`

  mutation deleteJobFamily($id: ID!) {
    deleteJobFamily(id:$id) {
        jobFamily{
            id
        }
    }
  }
`;

export const JOB_FAMILY_SUBSCRIPTION = gql`
    subscription jobfamilySubscription {
        jobfamilySubscription{
            mutation
            jobfamily{
                id
                title
                role {
                    edges {
                        node {
                            id
                            title
                            vertical{
                                id
                                name
                            }
                        }
                    }
                }
                genericCompetency {
                    edges {
                        node {
                            id
                            title
                        }
                    }
                }
            }
        }
    }
`


export const JOB_FAMILY_BY_ID = gql`

query jobFamilyById($id:ID!) { 
    jobFamilyById(id:$id){
      id
      title
      description
      createdAt
      updatedAt
      role {
        edges {
          node {
            id
            title
            description
            vertical {
                id
                name
              }
          }
        }
      }
      genericCompetency {
        edges {
          node {
            id
            title
            description
          }
        }
      }
    }
  }

`

export const CREATE_JOB_FAMILY = gql`
  mutation createJobFamily($genericCompetency: [Int],$jobFamilyData: JobFamilyInput!,$role:[Int]) {
    createJobFamily(genericCompetency:$genericCompetency,jobFamilyData:$jobFamilyData,role:$role){
        jobFamily{
          id
        }
    }
  }
`;

export const ROLES_LIST_JOBFAMILY = gql`
  query allRoles($title_Icontains:String) {
    allRoles(title_Icontains:$title_Icontains) {
      edges {
        node {
            id
            title
            description
        }
      }
    }
  }
`;

export const COMPETENCY_LIST_JOBFAMILY = gql`
query allCompetencies($title_Icontains:String,$competencyType: String,){
    allCompetencies(title_Icontains:$title_Icontains,competencyType:$competencyType){
        edges{
          node{
            id
            title
            competencyType
            description
          }
        }
    }
}
`

export const UPDATE_JOB_FAMILY = gql`
  mutation updateJobFamily($genericCompetency: [Int],$role:[Int],$id: ID!,$title: String,$description: String) {
    updateJobFamily(genericCompetency:$genericCompetency,role:$role,id:$id,title:$title,description:$description){
        jobFamily{
          id
        }
    }
  }
`;
