// @ts-nocheck
import React from 'react'
import { Dropdown, Divider, Button, Menu } from 'antd';
import dropdown_Arrow from '../../assets/icons/drop_down_arrow.svg'
import chip_close_icon from '../../assets/chip_close_icon.svg'

const MultiSelectFormDropdownlabel = (props) => {

  const { onSelect, selected_data, placeholder, onSearch, choice_list, add_btn, on_add } = props
  const container_ref = React.useRef(null);
  const input_insider = React.useRef(null);
  // to handle the event propogation to stop the dropdown from closing after each clicks and set the data
  const changeSelection = (event, data) => {
    onSelect(data)
    event.stopPropagation()
  }

  // content to be rendered inside the dropdown
  const menuOverlay = () => (
    <div className='custom-multiselectdropdown-menu' style={{ width: `${container_ref.current?.offsetWidth}px` || "100%" }}>
      <div style={{ width: "100%", padding: "1em" }} className='custom-multiselectdropdown-scroll'>
        {
          choice_list?.map(item => (
            <div key={item?.id} className='custom-multiselectdropdown-choice' onClick={(e) => { changeSelection(e, item) }}>
              <h4 className='multiselectdropdown-choice-label'>{item?.label}</h4>
              <button className={`multiselectdropdown-choice-selection ${selected_data?.map(ele => ele?.id || ele)?.includes(item?.id) ? 'active-choice-selection' : ''}`}>+</button>
            </div>
          ))
        }
        {
          !choice_list?.length ?
            <div align="center"> <h4 className='rd-nodata-label'>No data found</h4> </div>
            : ''
        }
      </div>
      {
        (add_btn && on_add) && (
          <div className='custom-multiselectdropdown-add'>
            <span style={{ cursor: 'pointer',textAlign:"center" }} onClick={() => { on_add() }}>+ {add_btn}</span>
          </div>
        )
      }
    </div>
  );

  return (
    <Dropdown overlay={menuOverlay} overlayClassName='content-dropdown' trigger='click'>
      <div className='custom-dropdown-with-chip' ref={container_ref}>
   
          <div className='custom-dropdown-insider-list'>
          {
            selected_data.map(ele => (
              <div className='custom-dropdown-insider-ele'>
                <span>{ele?.stream || ele?.label}</span>
                <img src={chip_close_icon} onClick={(e) => { changeSelection(e, ele) }} alt="" />
              </div>
            ))
          }
          <input onChange={(e) => { onSearch(e?.target?.value) }} className='custom-multiselectdropdown-search-chip' placeholder={placeholder}></input>
        </div>
       
       
        
        <div className='custom-multiselectdropdown-icon'>
          <img src={dropdown_Arrow} alt="" style={{width:"1.2em"}}/>
        </div>
      </div>
    </Dropdown>
  )

}

export default MultiSelectFormDropdownlabel