import gql from 'graphql-tag';
import { COACHING_COMMITMENT_TYPE } from './CoachingCommitmentType.gql';

export const GET_ALL_COACHING_COMMITMENTS = gql`
  ${COACHING_COMMITMENT_TYPE}
  query getAllCoachingCommitments(
    $orderBy: [String]
    $offset: Int
    $first: Int
    $title_Icontains: String
    $description_Icontains: String
  ) {
    getAllCoachingCommitments(
      orderBy: $orderBy
      offset: $offset
      first: $first
      title_Icontains: $title_Icontains
      description_Icontains: $description_Icontains
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          ...CoachingCommitmentType
        }
        cursor
      }
      edgeCount
      totalCount
    }
  }
`;
