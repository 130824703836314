import gql from "graphql-tag";
export const ACTIONITEMS_SUBSCRIPTION = gql`
subscription actionItemSubscription {
  actionItemSubscription {
        mutation
        actionItem {
            id
              title
              completed
              meeting {
                id
                title
              }
              okr {
                id
                 title
                 okrType
                 owner {
                   id
                   user {
                     id
                     firstName
                     lastName
                     employee {
                       id
                     }
                   }
                 }
               }
              commitment {
                id
                commitment {
                  id
                  title
                }
                user{
                  id
                  firstName
                  lastName
                  employee{
                    id
                  }
                }
                completed
              }
              coachingCommitment {
                id
                coachingcommitment {
                  id
                  title
                }
                user{
                  id
                  firstName
                  lastName
                  employee{
                    id
                  }
                }
                completed
              }
              notes
              completed
              isAgenda
              createdAt
              updatedAt
        }
    }
}
`