import gql from 'graphql-tag';
import { USER_NODE } from './UserNode.gql';

export const USERS_QUERY = gql`
  ${USER_NODE}
  query users {
    users {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...UserNode
        }
      }
    }
  }
`;
