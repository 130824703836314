import gql from 'graphql-tag';

export const DELETE_DISCARD_MUTATION = gql`
mutation deleteDiscardRequest($id: ID!){
    deleteDiscardRequest(id:$id){
        discardRequest{
            id
        }
    }
}
`