import gql from 'graphql-tag';
import { EMPLOYEE_TYPE } from './EmployeeType.gql';

export const ALL_ORGANIZATION_EMPLOYEES = gql`
  ${EMPLOYEE_TYPE}
  query allOrganizationEmployees($first: Int, $offset: Int, $orderBy: [String], $nameIcontains: String) {
    allOrganizationEmployees(first: $first, offset: $offset, orderBy: $orderBy, nameIcontains: $nameIcontains) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...EmployeeType
        }
      }
      totalCount
      edgeCount
    }
  }
`;
