import React from 'react';
import { Form, Radio, Row, Col } from 'antd';
import chunk from 'lodash/chunk';
import { ReactMarkdown } from 'modules/look';


const RadioGroup = Radio.Group;
const FormItem = Form.Item;
const SurveyRadioField = props => {
  const { vertical = true, choices, onChange, disabled,selectedId,autoscroll,left,value,defaultValue,required, ...rest } = props;

  const choice = choices.map((choice, key) => (
    <Radio
      id={selectedId}
      onChange={e=>autoscroll&&autoscroll(selectedId)}
      className='survey-radio-choice'
      key={key}
      style={{ display: vertical && 'flex',marginLeft:left?left:0 }}
      value={
        choice.value || (typeof choice.label === 'string' ? <ReactMarkdown>{choice.label}</ReactMarkdown> : choice.label)
      }
      checked={true}
    >
      {typeof choice.label === 'string' ? <ReactMarkdown>{choice.label}</ReactMarkdown> : choice.label}
    </Radio>
  ));

  const parts = chunk(choice, 5);
  return (
    <div style={{ marginBottom: '5vh' }}>
      <FormItem labelCol={{ span: 24 }} wrapperCol={{ span: 24, offset: 1 }} required={required}{...rest}>
        <RadioGroup id={selectedId} disabled={disabled} value={defaultValue} onChange={onChange} style={{ width: '100%' }}>
          <Row>{choices.length && parts?.map((chunk, i) => <Col span={24 / parts?.length}>{chunk}</Col>)}</Row>
        </RadioGroup>
      </FormItem>
    </div>
  );
};

export default SurveyRadioField;
