// @ts-nocheck
import React from "react";
import { compose } from "../../core";
import CompetencyHomeView from "../components/CompetencyHomeView";
import { COMPETENCY_CATEGORY_LIST, COMPETENCY_LIST, COMPETENCY_SUBSCRIPTION } from "../graphql/CompetencyQuries.gql";
import { withDeleteCompetency, withUpdateCompetencySkill } from "../RoleDefinitionOperation";
import { getIntFromString } from "modules/look";
import { EMPLOYEE_USER_COUNT } from "modules/page/graphql/userCount.gql";

const CompetencyHomeContainer = ( props ) => {

    const { client, permission, deleteCompetency, me,updateCompetency } = props

    const [selected_competency_category, SetSelectedCompetencyCategory ] = React.useState()
    const [ competency_loading, SetCompetencyLoading ] = React.useState( false )
    const [ competency_list, SetCompetencyList ] = React.useState( [] )
    const [ competency_delete, SetCompetencyDelete ] = React.useState()
    const [ competency_page_info, SetCompetencyPageInfo ] = React.useState()
    const [ competency_cat_list, setCompetencyCatList ] = React.useState()
    const [user_permission,setUserPermission] = React.useState(false)
    const competency_list_ref = React.useRef( [] )
    const competency_subscription = React.useRef( null )
    const filter_ref = React.useRef( {} )

    //check user permissions
    const getEmployeeData = async()=>{
        const { data } = await client.query({
              query: EMPLOYEE_USER_COUNT,
          });
     
          if(data?.me){
            let Detail =data?.me?.employee
            if(Detail?.orgCeoEmployee?.totalCount>0 || Detail?.orgPocEmployee?.totalCount>0){
                setUserPermission(true)
            }
            else{
                setUserPermission(false)
            }
          }
      }

    // to update the competency list refference when the state gets changed
    React.useEffect( () => {
        competency_list_ref.current = competency_list
    }, [ competency_list ] )

    // to check if the user have permission to fetch competency data
    React.useEffect( () => {
        if ( permission && permission?.required_permission ) {
            getCompetencyList()
            getEmployeeData()
        }
    }, [ permission ] )

    // Code to be executed when the component is unmounted
    React.useEffect( () => {
        return () => {
            if ( competency_subscription?.current ) {
                competency_subscription.current.unsubscribe();
                competency_subscription.current = null
            }
        };
    }, [] );

    // Get all competency category list
    const getCompetencyCategoryList = async ( filter ) => {
        const { data } = await client.query( {
            query: COMPETENCY_CATEGORY_LIST,
            fetchPolicy: 'network-only',
            variables: { ...filter, first: 50 }
        } );
        if ( data?.allCompetencyCategory ) {
            setCompetencyCatList( data?.allCompetencyCategory?.edges.map( ( { node } ) => node ) )
        }
    }

    // qraphql api to fetch competency data list
    const getCompetencyList = async ( filter ) => {
        filter_ref.current={...filter,after:null} || {}
        SetCompetencyLoading( true )
        const { data } = await client.query( {
            query: COMPETENCY_LIST,
            fetchPolicy: 'network-only',
            variables: { ...filter, first: 25 }
        } );
        if ( data?.allCompetencies ) {
            // to set the pagination detail
            SetCompetencyPageInfo( { ...data?.allCompetencies?.pageInfo } )

            let list = data?.allCompetencies?.edges.map( ( { node } ) => node )
            // to handle pagination if after is present its a paginated data
            if ( filter?.after ) {
                list = competency_list_ref.current.concat( list )
            }
            SetCompetencyLoading( false )
            SetCompetencyList( list )
            if ( !competency_subscription?.current ) {
                competencySubscription()
            }
        }
    }

    // to add subscription to competency api
    const competencySubscription = async () => {
        competency_subscription.current = client.subscribe( {
            query: COMPETENCY_SUBSCRIPTION,
            fetchPolicy: 'no-cache'
        } ).subscribe( {
            next( result ) {
                let competency_cache_data = [].concat( competency_list_ref.current )
                let scbscription_data = result.data.competencySubscription
                switch ( scbscription_data.mutation ) {
                    case 'CREATE':
                        competency_cache_data.push( scbscription_data?.competency )
                        SetCompetencyList( competency_cache_data )
                        break
                    case 'UPDATE':
                        let doc = competency_cache_data?.find( item => item?.id === scbscription_data?.competency?.id )
                        if ( doc ) {
                            Object.entries( scbscription_data?.competency ).forEach( item => {
                                doc[ item[ 0 ] ] = item[ 1 ]
                            } )
                            SetCompetencyList( competency_cache_data )
                        }
                        break
                    case 'DELETE':
                        competency_cache_data = competency_cache_data?.filter( item => item?.id !== scbscription_data?.competency?.id )
                        SetCompetencyList( competency_cache_data )
                        break
                    default:
                        break
                }
            }
        } );
    }

    // delete competency data
    const removeCompetency = async ( id ) => {
        try {
            SetCompetencyDelete( null )
            SetCompetencyLoading( true )
            const response = await deleteCompetency( { id } );
            if ( response ) {
                SetCompetencyLoading( false )
            } else {
                SetCompetencyLoading( false )
            }
        } catch ( e ) {
            SetCompetencyLoading( false )
        }
    }

    React.useEffect( () => {
        if ( me ) {
            getCompetencyCategoryList()
        }
    }, [ me ] )

    const updateCopetencyDetail =async(comp_detail)=>{
        let competencyData_update_Data = {
            id:getIntFromString(comp_detail?.sub_com_details?.id),
            title:comp_detail?.sub_com_details?.title,
            competencyCategoryId:getIntFromString(comp_detail?.main_cop_id),
            competencyType:comp_detail?.sub_com_details?.type,
            description:comp_detail?.sub_com_details?.description
          }
        let response =await updateCompetency(competencyData_update_Data)
        if(response){
            SetSelectedCompetencyCategory()
        }
    }
    return (
        <CompetencyHomeView
            {...props}
            competency_loading={competency_loading}
            competency_list={competency_list}
            competency_delete={competency_delete}
            SetCompetencyDelete={( data ) => { SetCompetencyDelete( data ) }}
            competency_page_info={competency_page_info}
            removeCompetency={( id ) => { removeCompetency( id ) }}
            getCompetencyList={( filter ) => { getCompetencyList( {...filter_ref.current,...filter} ) }}
            getCompetencyCategoryList={( e ) => getCompetencyCategoryList( e )}
            competency_category_list={competency_cat_list}
            selected_competency_category={selected_competency_category}
            SetSelectedCompetencyCategory={(e)=>SetSelectedCompetencyCategory(e)}
            OnUpdateCompetecny ={(comp_detail)=>{updateCopetencyDetail(comp_detail);}}
            user_permission={user_permission}
        />
    )

}
export default compose( withDeleteCompetency ,withUpdateCompetencySkill)( CompetencyHomeContainer )