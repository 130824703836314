export const managing_performance = "managing-performance"
const base_name = `/role/${managing_performance}`
const create = '/add'
const edit = '/edit'
const managing_performance_route = {
    competency_assessment_home: base_name,
    competency_assessment_create: base_name + create,
    user_competency_assessment_detail: base_name + '/user-detail' + '/:id',
    competecny_assessment_detail:base_name + '/detail' + '/:id',
    competency_assessment_edit: base_name + edit + '/:assessment_id',
    user_email_competency_assessment_detail: base_name + '/user-email-detail' + '/:email',
}

export default managing_performance_route