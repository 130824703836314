import update from 'immutability-helper';
import { PAGINATION_LIMIT } from '../../../config';

import { ORGANIZATION_STATE_QUERY } from '../graphql/OrganizationStateQuery.client.gql';

const TYPE_ORGANIZATION_STATE = 'OrganizationState';

const TYPE_ORGANIZATION_STATE_FILTER = 'OrganizationFilterInput';

const TYPE_ORGANIZATION_STATE_PAGINATION = 'OrganizationPaginationInput';

const defaults = {
  organizationState: {
    filter: {
      name_Icontains: '',
      description_Icontains: '',
      email_Icontains: '',
      employees_Icontains: '',
      poc: '',
      ceo: '',
      organization: '',
      vertical: '',
      subvertical: [],
      user: '', // Employees
      __typename: TYPE_ORGANIZATION_STATE_FILTER
    },
    orderBy: ['-id'],
    pagination: {
      first: PAGINATION_LIMIT,
      offset: 0,
      __typename: TYPE_ORGANIZATION_STATE_PAGINATION
    },
    __typename: TYPE_ORGANIZATION_STATE
  }
};

const resolvers = {
  Mutation: {
    updateOrganizationFilter: (_, { filter, orderBy, pagination }, { cache }) => {
      const { organizationState } = cache.readQuery({
        query: ORGANIZATION_STATE_QUERY
      });
      const newOrganizationState = update(organizationState, {
        filter: { $merge: filter },
        orderBy: { $set: orderBy },
        pagination: { $merge: pagination }
      });

      cache.writeData({
        data: {
          organizationState: newOrganizationState,
          __type: TYPE_ORGANIZATION_STATE
        }
      });

      return null;
    }
  }
};

export default { defaults, resolvers };
