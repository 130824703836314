import gql from 'graphql-tag';
import { COMMENT_TYPE } from './CommentType.gql';

export const ADD_COMMENT = gql`
  ${COMMENT_TYPE}
  mutation createComment($commentData: CommentInput!) {
    createComment(commentData: $commentData) {
      comment {
        ...CommentType
      }
    }
  }
`;
