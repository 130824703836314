
import React from "react";
import ReadinessView from "../components/Readiness";
import { SalesPageToolSet } from "./SalesPageToolSet";


const ReadinessToolContainer = (props) => {

    const [tool,setTool] = React.useState({});

    

    React.useEffect(()=>{
        if(props.match.params.id){
            let filteredTool =  SalesPageToolSet.find(value=>value.id === parseInt(props.match.params.id))
            setTool(filteredTool)
        }

    },[props.match.params.id])


    return (
        <>
            <ReadinessView tool = {tool} />
        </>
    );
};

export default ReadinessToolContainer;

