import { Dropdown, Menu,Button,Tooltip } from "antd";
import React from "react";
import styled from "styled-components";
import dropdown_Arrow from '../../assets/dropdown_Arrow.svg'
const PaginationDropdown=(props)=>{
    const {totalCount,onselect,current,hideResult,showTooltip}=props
    const [selectedFilter,setFilter]=React.useState()
    const [filterList,setFilterList]=React.useState([])
    // hideResult={true} showTooltip={{show:true,label:null}}

    React.useEffect(()=>{
      if(totalCount){
        if(totalCount<=5){
         return setFilterList([totalCount])
        }
        else if (totalCount>5&&totalCount<=10){
         return setFilterList([5,totalCount])
        }
        else if(totalCount>10&&totalCount<=50){
          return setFilterList([5,10,totalCount])
        }
        else if(totalCount>50&&totalCount<=100){
          return setFilterList([5,10,50,totalCount])
        }
        else if(totalCount>100){
          return setFilterList([5,10,50,100])
        }
      }
     
    },[totalCount])
    React.useEffect(()=>{
      if(filterList?.length>0){
        if(current && filterList?.find(item=>item===current)){
          setFilter(current)
        }else{
          setFilter(filterList[0])
        }
        
      }
    },[filterList])
    const Filtermenu =()=> (
        <Menu style={{maxHeight:'200px',overflow:'auto'}}>
          {
            filterList?.length && (
                filterList?.map((data)=>
              <Menu.Item style={{fontFamily: 'Manrope'}} key={data} disabled={data===selectedFilter} onClick={(e)=>{setFilter(data);onselect(data)}}>
                {data}{hideResult&&(`/${totalCount} ${showTooltip?.customType||''}`)} {!hideResult&&('Results')}
              </Menu.Item>
              )
            )
          }
        </Menu>
      );
    return (
        <>
          <Tooltip title={!showTooltip?.show?'':(showTooltip?.label||`Showing ${selectedFilter} ${showTooltip?.customType||''} out of ${totalCount}`)}>
          <Dropdown overlay={Filtermenu}  className="filter-drop" style={{width:"200px"}}>
              <DropdownButton>
                  <p className='selected-filter' style={{marginTop:'10px',width:'100%',whiteSpace:"nowrap",textAlign:'left',paddingTop:"2px",fontFamily: 'Manrope'}}>{selectedFilter}{hideResult&&(`/${totalCount} ${showTooltip?.customType||''}`)} {!hideResult&&('Results')}</p>
                  <img src={dropdown_Arrow} alt="" style={{width:"13px",height:"10px"}}/>
              </DropdownButton>
          </Dropdown>
          </Tooltip>
        </>
    )

}

export default PaginationDropdown

const DropdownButton = styled(Button)`
width:100px;
display:flex;
height:36px;
justify-content:space-between;
align-items:center;
gap:5px;


@media only screen and (max-width: 768px) {
  margin-top:10px;
}

`