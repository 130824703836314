import { Button, Form, Input } from 'antd';
import React from 'react';
import MainCompetencyEditView from '../components/MainCompetencyEditView';
import { compose } from 'modules/core';
import { withUpdateCompetencyCategory } from '../RoleDefinitionOperation';
import { getIntFromString } from 'modules/look';

const MainCompetencyEditContainer =(props)=>{

    const {onCancel,edit_competency_value,updateCompetencyCategory} = props;

    const EditSelectedCompetency =async(value)=>{
        let update_data ={
            id:getIntFromString(edit_competency_value?.id),
            title:value?.title,
            competencyType:value?.competencyType
        }
        let respose = await updateCompetencyCategory(update_data)
        if(respose){
            onCancel()
        }
    }

    return (

        <MainCompetencyEditView {...props} 
        cancel={()=>onCancel()}
        onSubmit={(value)=>EditSelectedCompetency(value)}/>
    )
  
}

export default compose(withUpdateCompetencyCategory)(MainCompetencyEditContainer)