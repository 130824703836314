import React from "react";
import { Row, Col, Button } from "antd";
import styled from "styled-components";

import { displayUtcToUserTimezone } from "../../look";

import notification_content from '../../../Notification_config'
const NotificationDetailView = props => {
    const {NotificationDetail,onClose,view}=props

    return (
        <>

         <Col>
                <RowDiv >

                    <h4 style={{fontSize:'18px',marginRight:'10px'}}>{NotificationDetail?.title}</h4>
               
                   <h4 style={{marginLeft:'30px',textAlign:'center'}}>
                        {displayUtcToUserTimezone(NotificationDetail?.actionTime, "DD MMMM YYYY")}
                      </h4>
               </RowDiv>
                {
                    NotificationDetail?.changeMessage && (
                        <h4>{NotificationDetail?.changeMessage}</h4>
                    )
                }
                
            <div align="center">

                <Button style={{margin:'10px'}} onClick={()=>onClose(true)}>Close</Button>
                {
                    NotificationDetail?.relatedObject && notification_content[NotificationDetail?.relatedObject?.__typename] && NotificationDetail?.relatedObject?.__typename && NotificationDetail?.relatedObject?.id && (
               
                        <Button style={{margin:'10px'}} onClick={()=>view(NotificationDetail)}>View</Button>
              
                    )
                }
         
            </div>
          </Col>
        </>
    )

}
export default NotificationDetailView;

const RowDiv =styled(Row)`
display:flex;
justify-content:space-between;
align-items:center;
margin-bottom:20px
`

