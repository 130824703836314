// @ts-nocheck
import React from 'react';
import { Spin, Modal, Form, Select, Tooltip, Popover } from 'antd';
import { competency_icons } from '../icon';
import { displayUtcToUserTimezone, getIntFromString } from 'modules/look';
import RoleDefinitionConfirmation from '../../look-v2/Components/ConfirmationDialog';
import RoleDefinitionSearchView from './RoleDefinitionSearchView';
import CompetencyImportContainer from '../containers/CompetencyImportContainer';
import RoleDefinitionpagination from './roleDefinitionPagination';

const CompetencyHomeView = (props) => {

    const { SetCurrentCompetencyTab, competency_loading, competency_list, competency_delete, SetCompetencyDelete, competency_page_info, removeCompetency, getCompetencyList, history, getCompetencyCategoryList, competency_category_list, OnUpdateCompetecny, selected_competency_category, SetSelectedCompetencyCategory, user_permission } = props

    const [show_import_competency, setShowImportCompetency] = React.useState(false)
    const [expanded_row, SetExpandedRow] = React.useState([])
    const { Option } = Select;

    React.useEffect(() => {
        if (selected_competency_category) {
           
        }
    }, [selected_competency_category])

    // to expand each row of the table
    const expandRow = (id) => {
        if (expanded_row?.includes(id)) {
            SetExpandedRow([])
        } else {
            SetExpandedRow([id])
        }
    }
    const navigation = (view) => {
        SetCurrentCompetencyTab({ key: view })
    }

    const detailPageNavigation = (id) => {
        history.push(`role-definition/competency/${getIntFromString(id)}`)
    }

    const navigateToEdit = (item) => {
   
        // to handle the navigation and competency detail for edit
        SetCurrentCompetencyTab({ key: 'competency_add_edit_view', competency_id: getIntFromString(item?.id), main_competency: item?.competencyCategory })
        // history.push('/role-definition')
    }

    const competecnyNameFounder = (id) => {
        let find_value = competency_category_list?.find(item => getIntFromString(item?.id) === getIntFromString(id))
        if (find_value) {
            return find_value?.title
        }
        else return ''
    }

    const filter_data = [
        {
            key: "title_Icontains",
            label: "Name",
            ui_flow: "input_box",
            default: true
        },
        {
            key: "competencyType",
            label: "Type",
            ui_flow: "dropdown",
            choice: [
                {
                    key: "Generic",
                    label: "Generic",
                },
                {
                    key: "Role_Specific",
                    label: "Role Specific",
                }
            ]
        }
    ]

    return (
        <>
            {/* confirmation dialog for delete competency */}
            <Modal
                visible={competency_delete}
                footer={false}
                closable={false}
                destroyOnClose={true}
                centered={true}
                className='rd-modal'
            >
                <RoleDefinitionConfirmation
                    message={`Do you want to delete the sub competency ${competency_delete?.title}?`}
                    onCancel={() => { SetCompetencyDelete(null) }}
                    onConfirm={() => { removeCompetency(getIntFromString(competency_delete?.id)) }}
                />
            </Modal>
            <Modal
                visible={show_import_competency}
                footer={false}
                closable={false}
                destroyOnClose={true}
                centered={true}
                className='rd-modal cp-modal-max-width'
                width={"90%"}
            >
                <CompetencyImportContainer {...props} type={"sub_competency"} onCancel={() => { setShowImportCompetency(false); getCompetencyList() }} />
            </Modal>

            <div className='rd-coll-competency' style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between', alignItems: 'center', gap: ".5em", flexWrap: "wrap", width: "100%" }}>
                <div className='rd-root rd-coll-btn'>
                    <button className='rd-add-button' style={{ width: "9em", marginRight: "1.5em" }} onClick={() => { navigation('competency_add_edit_view') }}>+ Add</button>
                    <button className='rd-dotted-button' style={{ width: "9em", marginRight: "1.5em" }} onClick={() => { setShowImportCompetency(true) }}> <img src={competency_icons?.import_icon} alt="" /> Import</button>
                </div>
                <div className='rd-search-container'>
                    <RoleDefinitionSearchView filterData={filter_data} onfilterChange={e => getCompetencyList(e)} />
                </div>
            </div>
            {/* competency table listing */}
            <Spin spinning={competency_loading}>
                <div className='rd-table-scroll'>
                    <div className='rd-table-container' style={{ minWidth: '800px' }}>
                        <div className='rd-table-header'>
                            <div style={{ width: "30%" }}>
                                <h4 className='rd-table-header-label' style={{ fontSize: "1.35em" }}>Name</h4>
                            </div>
                            <div style={{ width: "30%" }}>
                                <h4 className='rd-table-header-label' style={{ fontSize: "1.35em" }}>Competency </h4>
                            </div>
                            <div style={{ width: "30%" }}>
                                <h4 className='rd-table-header-label' style={{ fontSize: "1.35em" }}>Type</h4>
                            </div>
                            <div style={{ width: "10%" }}>
                                <h4 className='rd-table-header-label' style={{ fontSize: "1.35em" }}>Actions</h4>
                            </div>
                        </div>
                        <div className='rd-table-body'>
                            {competency_list?.map(item => (
                                <div style={{ borderRadius: "0.5em", background: " #FCFCFC" }}>
                                    <div className='rd-table-content' style={{ alignItems: 'center' }}>
                                        {item?.description && <div className='el-expansion-container'>
                                            <img src={competency_icons?.table_expand_arrow} className={`rd-expansion-arrow ${expanded_row?.includes(item?.id) ? 'rd-open-expansion' : ''}`} onClick={() => { expandRow(item?.id) }} alt="" />
                                        </div>}
                                        <div style={{ width: "30%" }} >
                                            <Tooltip title={item?.title?.length > 30 ? item?.title : ""} placement='topLeft'>
                                                <h4 className='rd-td-label rd-text-ellipsis'>{item?.title}</h4>
                                            </Tooltip>
                                        </div>
                                        <div style={{ width: "29%" }}>
                                            {item?.competencyCategory?.id ?
                                                <Tooltip title={item?.competencyCategory?.title?.length > 30 ? item?.title : ""} placement='topLeft'>
                                                    <h4 className='rd-table-body-label rd-text-ellipsis '>{item?.competencyCategory?.title}</h4> </Tooltip> :
                                                <div className='rd-input-container'>
                                                    {selected_competency_category && item?.id === selected_competency_category?.sub_com_details?.id ?
                                                        <div className="rd-selected-competency-table-value-container">
                                                            <h4 className='rd-selected-competency-table-value' style={{ margin: 0 }}>{competecnyNameFounder(selected_competency_category?.main_cop_id)}</h4>
                                                            <div style={{ display: 'flex', flexDirection: "row", gap: "1em" }}>
                                                                <img src={competency_icons?.competecny_save_icon} alt="" style={{ width: '1.25em', cursor: "pointer" }} onClick={() => { OnUpdateCompetecny(selected_competency_category) }} />
                                                                <img src={competency_icons?.competecny_cancel_icon} alt="" style={{ width: '1.25em', cursor: "pointer" }} onClick={() => { SetSelectedCompetencyCategory() }} />
                                                            </div>
                                                        </div>
                                                        : <Select
                                                            className="rd-table-select-filed rd-tabel-type-select"
                                                            style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", minWidth: "100%" }}
                                                            suffixIcon={<img src={competency_icons?.dropdown_Arrow} alt="" style={{ width: "10px", height: "10px", marginLeft: ".5em" }} />}
                                                            showSearch
                                                            onSearch={(e) => getCompetencyCategoryList({ title_Icontains: e })}
                                                            filterOption={false}
                                                            placeholder='select competency category'
                                                            onSelect={(e) => SetSelectedCompetencyCategory({ main_cop_id: e, sub_com_details: item })}
                                                            {...props}
                                                        >
                                                            {competency_category_list?.map(value => (
                                                                <Option
                                                                    className='rd-select-option'
                                                                    key={value?.id}
                                                                    value={value?.id}
                                                                >
                                                                    {value?.title}
                                                                </Option>
                                                            ))}
                                                        </Select>}
                                                </div>
                                            }
                                        </div>
                                        <div style={{ width: "30%" }} >
                                            <h4 className='rd-table-body-label'>{item?.competencyType === 'GENERIC' ? 'Generic Competency' : "Functional (role specific)"}</h4>
                                        </div>

                                        <div style={{ width: "10%", display: "flex", flexDirection: "row", gap: "1em", justifyContent: 'center', alignItems: 'center' }}>
                                            <Popover overlayClassName="rd-add-new-popover" style={{ background: "rgba(170, 186, 198, 1)" }} placement="topRight" content={"Edit sub-competency"} trigger="hover">
                                                <img className='rd-delete-icon' src={competency_icons?.edit_role_definition} onClick={() => navigateToEdit(item)} alt="" />
                                            </Popover>
                                            {user_permission &&
                                                <Popover overlayClassName="rd-add-new-popover" style={{ background: "rgba(170, 186, 198, 1)" }} placement="topRight" content={"Delete sub-competency"} trigger="hover">
                                                    <img className='rd-delete-icon' src={competency_icons?.delete_icon} onClick={() => { SetCompetencyDelete(item) }} alt="" />
                                                </Popover>
                                            }
                                        </div>
                                    </div>

                                    {/* expanded detail section */}
                                    <div className={`rd-expanded-detail-comp ${expanded_row?.includes(item?.id) ? 'rd-open-expansion-detail-competency' : ''}`}>
                                        <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between', alignItems: "center", gap: "1em", width: "100%", flexWrap: "wrap" }}>
                                            <h4 className='rd-expanded-label-title-comp'>Description</h4>
                                            <div style={{ display: 'flex', flexDirection: "row", gap: '1em', flexWrap: "wrap" }}>
                                                <h4 className='rd-expand-date-lable'>Created : {displayUtcToUserTimezone(item?.createdAt, 'DD-MMM-YYYY')}</h4>
                                                <h4 className='rd-expand-date-lable'>Updated : {displayUtcToUserTimezone(item?.updatedAt, 'DD-MMM-YYYY')}</h4>
                                            </div>
                                        </div>
                                        <div className='expand-dis'>
                                            <h4 className='rd-expanded-label-comp'>{item?.description}</h4>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {
                            (!competency_list?.length && !competency_loading) ?
                                <div className='rd-nodata'>
                                    <h4 className='rd-nodata-label'>No data found</h4>
                                </div> : ''
                        }
                        {
                            (competency_page_info?.hasNextPage && competency_page_info?.endCursor) && (
                                <RoleDefinitionpagination on_pagination={() => { getCompetencyList({ after: competency_page_info?.endCursor }) }} />
                            )
                        }

                    </div>
                </div>
            </Spin>
        </>
    )

}
export default CompetencyHomeView