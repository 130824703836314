import gql from 'graphql-tag';

export const DELETE_OKR_MUTATION = gql`
mutation deleteOkr($id: ID!){
    deleteOkr(id:$id){
        OKR{
            id
        }
    }
}
`