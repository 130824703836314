import React from 'react';
import { Form, Input } from 'antd';

const TextInputField = props => {
  const { onChange, disabled,onBlur,margin,placeholder,defaultValue ,...rest } = props;

  const FormItem = Form.Item;

  return (

      <FormItem  {...rest}>
      <Input className="okr-input" autoComplete='off' defaultValue={defaultValue} placeholder={placeholder||''} onChange={onChange} onBlur={onBlur} disabled={disabled} />
      </FormItem>

  );
};

export default TextInputField;

