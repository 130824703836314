import { getIntFromString } from "modules/look";
import React from "react";
import '../css/JobFamily.css';
import RoleDefinitionDetailView from "./RoleDefinitionDetailsView";
import role_definition_route from "../route";

const JobFamilyDetailView = (props) => {

    const { jobFamily, jobFamilyDetailsLoading, deleteJobFamily, jobFamilyPermission, navigateRoute } = props

    const [verticals, setVerticals] = React.useState(undefined);
    const [roles, setRoles] = React.useState(undefined);
    const [genericCompetencies, setGenericCompetencies] = React.useState(undefined);
    const [deleteJFamily, setDeleteJobFamily] = React.useState(false);

    React.useEffect(() => {

        if (jobFamily) {

            // get the list of verticals
            let vertical_list = jobFamily?.role?.edges.map(({ node }) => node?.vertical)?.filter(item => item)

            let vertical_content = {
                title: 'Vertical',
                colClass: "jf-margin-seperator",
                content: vertical_list?.map((item) => {
                    return {
                        id: item?.id,
                        title: item?.name,
                        description: item?.description
                    }
                })
            }

            setVerticals(vertical_content)

            // get the list of Generic competencies
            let generic_competencies = {
                title: 'Generic competencies',
                popUptitle: "Generic Competency Details",
                content: jobFamily?.genericCompetency?.edges?.map(({ node }) => {
                    return {
                        id: node?.id,
                        title: node?.title,
                        description: node?.description
                    }
                })
            }
            setGenericCompetencies(generic_competencies)


            // get the list of roles
            let roles = {
                title: 'Role',
                popUptitle: "Role Details",
                content: jobFamily?.role?.edges?.map(({ node }) => {
                    return {
                        id: node?.id,
                        title: node?.title,
                        description: node?.description
                    }
                })
            }

            setRoles(roles)
        }

    }, [jobFamily])



    const handleDeleteJobFamily = async (id) => {
        let jobFamilyId = getIntFromString(id)
        const response = await deleteJobFamily({ id: jobFamilyId })
        if (response) {
            setDeleteJobFamily(false);
            navigateRoute(role_definition_route.role_definition_home)
        }
    }

    const navigateToEditPage = () => {
        let role_definition_navigation = {
            parent_route: { key: 'job_family', label: 'Job Family' },
            child_route: { key: 'jobfamily_add_edit_view', jobfamily_id: jobFamily?.id }
        }
        localStorage.setItem('role_definition_navigation', JSON.stringify(role_definition_navigation))
        navigateRoute(role_definition_route.role_definition_home)
    }


    return (
        <RoleDefinitionDetailView
        entityDetails={jobFamily}
        editNavigation={navigateToEditPage}
        entityTitle={"Job Family"}
        setDeleteEntity={setDeleteJobFamily}
        gobackRoute={(role_definition_route.role_definition_home)}
        pageLoading={jobFamilyDetailsLoading}
        row_1={roles}
        handleDelete={handleDeleteJobFamily}
        deletePermission={jobFamilyPermission?.delete_job_family}
        editPermission={jobFamilyPermission?.edit_job_family}
        row_2={genericCompetencies}
        row_3={verticals}
        deleteRecord={deleteJFamily}
        {...props}
    />
    )
}
export default JobFamilyDetailView