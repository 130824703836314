import gql from 'graphql-tag';
import { ORGANIZATION_TYPE_CONNECTION } from '../../organization/graphql/OrganizationTypeConnection.gql';
import { VERTICAL_TYPE_CONNECTION } from '../../organization/graphql/VerticalTypeConnection.gql';
import { TEAM_TYPE_CONNECTION } from '../../organization/graphql/TeamTypeConnection.gql';

import { GROUP_TYPE_CONNECTION, GROUP_TYPE_CONNECTION_VALUES } from './GroupTypeConnection.gql';

export const SURVEY_TYPE = gql`
  ${ORGANIZATION_TYPE_CONNECTION}, ${VERTICAL_TYPE_CONNECTION}, ${TEAM_TYPE_CONNECTION}, ${GROUP_TYPE_CONNECTION}
  fragment SurveyType on SurveyType {
    id
    name
    description
    termsConditions
    shuffle
    public
    activeFrom
    activeTo
    reportQuery
    multisourceResponse
    organization {
      ...OrganizationTypeConnection
    }
    vertical {
      ...VerticalTypeConnection
    }
    team {
      ...TeamTypeConnection
    }
    pubDate
    groupSet {
      ...GroupTypeConnection
    }
  }
`;

export const SURVEY_TYPE_NO_FILTER_GROUP_SET = gql`
   ${GROUP_TYPE_CONNECTION}
  fragment SurveyType on SurveyType {
    id
    name
    description
    termsConditions
    shuffle
    public
    activeFrom
    activeTo
    reportQuery
    pubDate
    groupSet {
      ...GroupTypeConnection
    }
  }
`;


export const SURVEY_TYPE_GROUP_SET = gql`
   ${GROUP_TYPE_CONNECTION_VALUES}
  fragment SurveyType on SurveyType {
    id
    name
    description
    termsConditions
    shuffle
    public
    activeFrom
    activeTo
    reportQuery
    pubDate
    groupSet {
      ...GroupTypeConnection
    }
  }
`;

export const SURVEY_TYPE_GROUP_SET_VALUES_FIRST = gql`
   ${GROUP_TYPE_CONNECTION_VALUES}
  fragment SurveyTypeFirst on SurveyType {
    id
    name
    description
    termsConditions
    shuffle
    public
    activeFrom
    activeTo
    reportQuery
    pubDate
    groupSet(first:1) {
      ...GroupTypeConnection
    }
  }
`;

export const SURVEY_TYPE_GROUP_SET_VALUES_LAST = gql`
   ${GROUP_TYPE_CONNECTION_VALUES}
  fragment SurveyTypeLast on SurveyType {
    id
    name
    description
    termsConditions
    shuffle
    public
    activeFrom
    activeTo
    reportQuery
    pubDate
    groupSet(last:1) {
      ...GroupTypeConnection
    }
  }
`;
