// @ts-nocheck

import { withApollo } from '@apollo/client/react/hoc';
import { Button, Spin } from "antd";
import React from "react";
import my_no_data from '../../assets/my-no-data.svg';
import { compose } from "../../core";
import BadgeCardView from "../components/badge_cards";
import { BADGE_FAMILY_SUBSCRIPTION, BADGE_SUBSCRIPTION } from "../graphql/badgeFamilySub.gql";
import { GET_LIST_BADGE } from "../graphql/badgeList.gql";
import { GET_BADGE_FAMILY } from "../graphql/ListBadgeFamily.gql";


const MyCreationBadgeFamilyContainer = (props) => {
  const {client,me,subcriptionChange,permission,setbadge_family_update_data,search_job_family,addNewBadgeFamily, setUpdateBadgeSet,updateBadgeSet}=props
  const [loading,setloading]=React.useState(false)
  const [badgeFamilyId,setBadgeFamilyId] = React.useState();
  const [badge_family_list,setBadgeFamilyList]= React.useState()
  const [badgeSetUpdated,setbadgeSetUpdated] = React.useState([])
  const dataRef = React.useRef()
  const m = React.useRef( true )

  let badgeFamilySub = undefined
  let badgeSub=undefined
  React.useEffect( () => {
    return () => {
      if ( badgeFamilySub ) {
        badgeFamilySub.unsubscribe();
      }
    }
  } )

  React.useEffect( () => {
    return () => {
      if ( badgeSub ) {
        badgeSub.unsubscribe();
      }
    }
  } )

  React.useEffect( () => {
    return () => {
      m.current = false
    }
  }, [] )

  React.useEffect( () => {
    m.current = true

  }, [] )

  React.useEffect(()=>{
    if(subcriptionChange?.length){
      getBadgeFamilyList()
    }
  },[subcriptionChange])

  React.useEffect(()=>{
    if(permission && me){
      if(permission?.requried_permission){
        getBadgeFamilyList()
      }
    }
  },[permission,me])

  React.useEffect(()=>{
    getBadgeFamilyList()
  },[search_job_family])

  const getBadgeFamilyList=async (filter)=>{
    setloading(true)
    let organization = me?.employee?.organizationSet?.edges[0]?.node?.id
    
    const { data } = await client.query({
      query: GET_BADGE_FAMILY,
      variables: {...filter,title_Icontains:search_job_family,organization_Isnull:false,organization:organization},
      fetchPolicy:"network-only"
    });
    if(data){
      setBadgeFamilyList(data?.BadgeFamily)
      dataRef.current=data?.BadgeFamily
    }


     badgeFamilySub = client.subscribe( {
      query: BADGE_FAMILY_SUBSCRIPTION,
      variables: {...filter,title_Icontains:search_job_family,organization_Isnull:false}
    } ).subscribe( {
      next( result ) {
        
        let badgeCacheData = data?.BadgeFamily
        switch ( result.data.badgeFamilySubscription.mutation ) {
          case 'CREATE':
            const updated = {
              pageInfo:{
                hasNextPage:badgeCacheData?.pageInfo?.hasNextPage
              },
              totalCount:badgeCacheData?.totalCount,
              edgeCount:badgeCacheData?.edgeCount,
              edges:[...badgeCacheData?.edges?.map(({node})=>node),result.data.badgeFamilySubscription.badgeFamily]
            }
            if ( m.current ) {
              setBadgeFamilyList(updated)
            }
            break
          case 'UPDATE':
            if ( m.current ) {
              let updateValue =badgeCacheData?.edges?.map(({node})=>
                node.id === result.data.badgeFamilySubscription.badgeFamily?.id
                  ? result.data.badgeFamilySubscription.badgeFamily
                  : node
              ) 
              setBadgeFamilyList(
                {
                  pageInfo:{
                    hasNextPage:badgeCacheData?.pageInfo?.hasNextPage
                  },
                  totalCount:badgeCacheData?.totalCount,
                  edgeCount:badgeCacheData?.edgeCount,
                  edges: updateValue
                })
            }
            break
          case 'DELETE':
            if ( m.current ) {
              let deleteValue = badgeCacheData?.edges?.filter(({node}) =>
                node?.id ===result?.data?.badgeFamilySubscription?.badgeFamily?.id
                  ? false
                  : true
              ) 
            
              let pageInfo = { pageInfo:{
                hasNextPage:badgeCacheData?.pageInfo?.hasNextPage
              },
              totalCount:badgeCacheData?.totalCount-1,
              edgeCount:badgeCacheData?.edgeCount-1,
              edges:deleteValue
            }
              
              setBadgeFamilyList(pageInfo)
                
            }
            break
          default:
            break
        }
      }
    } )



    setloading(false)
  }

  const getBadgeList =async(badge_family_id)=>{
    
    const { data } = await client.query({
      query: GET_LIST_BADGE,
      variables: {badgeFamily:badge_family_id},
      fetchPolicy:"network-only"
    });
    if(data){
      // badgeRef.current=data?.Badges?.edges
    }
    badgeFamilySub = client.subscribe( {
      query: BADGE_SUBSCRIPTION,
      variables: {badgeFamily:badge_family_id},
      fetchPolicy:"network-only"
    } ).subscribe( {
      next( result ) {
        let badgeCacheData = data?.Badges?.edges
        setbadgeSetUpdated(data?.Badges?.edges)
        switch ( result.data.badgeSubscription.mutation ) {
          case 'CREATE':
         
            const created_Badge = [ ...badgeCacheData,{node:result.data.badgeSubscription.badge}]
            let created_badgeFamilyList =badge_family_list?.edges?.find(({node})=>node?.id===badge_family_id)?.node
          
            let data_list ={
                description:created_badgeFamilyList?.description,
                id:created_badgeFamilyList?.id,
                title:created_badgeFamilyList?.title,
                organization:created_badgeFamilyList?.organization,
                badgeSet:created_Badge
            }
            let created_list =badge_family_list?.edges?.map(({node})=>
            node?.id === badge_family_id
              ? data_list
              : node
          ) 
            const updated = {
              pageInfo:{
                hasNextPage:badge_family_list?.pageInfo?.hasNextPage
              },
              totalCount:badge_family_list?.totalCount,
              edgeCount:badge_family_list?.edgeCount,
              edges:created_list?.map((item)=>{
                return{
                  node:item,
                  __typename: 'BadgeFamilyTypeEdge'
                }
              })
            }
            if ( m.current ) {
              setBadgeFamilyList(updated)
              setbadgeSetUpdated(created_Badge)
            }
            break
          case 'UPDATE':
            if ( m.current ) {
              let updateValue =badgeCacheData?.map(({node})=>
                node?.id === result?.data?.badgeSubscription?.badge?.id
                  ? result?.data?.badgeSubscription?.badge
                  : node
              ) 
              let created_badgeFamilyList =badge_family_list?.edges?.find(({node})=>node?.id===badge_family_id)
              let data_list ={
                description:created_badgeFamilyList?.node?.description,
                id:created_badgeFamilyList?.node?.id,
                title:created_badgeFamilyList?.node?.title,
                organization:created_badgeFamilyList?.node?.organization,
                badgeSet:{edges:updateValue}
            }
            let update_list =badge_family_list?.edges?.map(({node})=>
            node.id === badge_family_id
              ? data_list
              : node
            ) 
              setBadgeFamilyList({
                pageInfo:{
                  hasNextPage:badge_family_list?.pageInfo?.hasNextPage
                },
                totalCount:badge_family_list?.totalCount,
                edgeCount:badge_family_list?.edgeCount,
                edges:update_list?.map((item)=>{
                  return{
                    node:item,
                    __typename: 'BadgeFamilyTypeEdge'
                  }
                })
              })
              }
            break
          case 'DELETE':
            if ( m.current ) {
              let deleteData =badgeCacheData?.filter( ({node}) =>
                node?.id === result?.data?.badgeSubscription?.badge?.id
                  ? false
                  : true
              )
              let created_badgeFamilyList =badge_family_list?.edges?.find(({node})=>node?.id===badge_family_id)
            let data_list ={
                description:created_badgeFamilyList?.node?.description,
                id:created_badgeFamilyList?.node?.id,
                title:created_badgeFamilyList?.node?.title,
                organization:created_badgeFamilyList?.node?.organization,
                badgeSet:{edges:deleteData}
            }
            
            let delete_list =badge_family_list?.edges?.map(({node})=>
            node.id === badge_family_id
              ? data_list
              : node
            )
            setBadgeFamilyList({
              pageInfo:{
                hasNextPage:badge_family_list?.pageInfo?.hasNextPage
              },
              totalCount:badge_family_list?.totalCount,
              edgeCount:badge_family_list?.edgeCount,
              edges:delete_list?.map((item)=>{
                return{
                  node:item,
                  __typename: 'BadgeFamilyTypeEdge'
                }
              })
            })
            }
            break
          default:
            break
        }
      }
    } )
    setloading(false)
  }

  return (
    <>
    {badge_family_list?.edges?.length>0?<div>
      <Spin spinning={loading} size="large">
      <BadgeCardView badgeSetUpdated = {badgeSetUpdated} dataSource={badge_family_list} edit={true} setBadgeFamilyId = {setBadgeFamilyId} setbadge_family_update_data={setbadge_family_update_data} {...props} setDelete={(e)=>getBadgeList(e)}/>
      </Spin>
    </div>:
    <Spin spinning={loading} size="large">
      <div className="kudos-root-class kudos-existing-no-data">
        {!loading && (
          <div style={{display:'flex',flexDirection:"column",justifyContent:'center',alignItems:'center',width:"100%"}}>
              <img src={my_no_data} className="kudos-no-data-img" alt="" />
            
          </div>
        )}
      </div>
    </Spin>
    }
    </>
  );
};

export default compose(withApollo)(MyCreationBadgeFamilyContainer);