import React, { useLayoutEffect } from "react";
import prev from '../../assets/kudos-prev.svg'
import next from '../../assets/kudos-next.svg'
import { Button, Modal, Spin } from "antd";
import Delete_icon from '../../assets/badge-delete-icon.svg'
import DeleteConfiramtion from "./deleteConfirmationModal";
import next_arrow from '../../assets/next-arrow-active.svg'
import previous_arrow from '../../assets/previous-arrow-active.svg'
const IssuedAwardView = ( props ) => {
    const { issuedAwardList, selectedAward, deleteBadgeAward, loading } = props
    console.log( "issuedAwardList", issuedAwardList )
    const [ total_page, settotal_page ] = React.useState( {} )
    const [ current_page, setcurrent_page ] = React.useState( 1 )
    const [ current_source, setCurrentSource ] = React.useState( [] )
    const [ column, setcolumn ] = React.useState()
    const [ deleteCofirm, setDeleteConfirm ] = React.useState( false )
    const [ selectedData, setSelectedData ] = React.useState()
    React.useEffect( () => {
        if ( issuedAwardList ) {
            let total_page = Math.ceil( issuedAwardList?.length / ( 8 ) )
            settotal_page( total_page )
            let current_page_data = issuedAwardList?.slice( ( current_page - 1 ) * ( 8 ), current_page * ( 8 ) )
            setCurrentSource( current_page_data )

            // HandleDataSource( issuedAwardList, current_page > total_page ? total_page : current_page, null )
        }
    }, [ issuedAwardList ] )

    const HandleDataSource = ( dataSource, page, prev = null ) => {
        setcurrent_page( page )
        let current_page_data = dataSource?.slice( ( page - 1 ) * ( 8 ), page * ( 8 ) )
        setCurrentSource( current_page_data )

    }
    const deleteAwards = ( value ) => {
        setSelectedData( value )
        setDeleteConfirm( true )
    }

    {
        return (
            <Spin spinning={loading}>
                <div className="uer-profile-award-main-card-container" style={{ background: "rgba(246, 246, 246, 0.62)" }}>
                    <div className="kudos-space-between-row row-padding" style={{ gap: "10px" }}>
                        {issuedAwardList?.length > 0&&<Button className="kudos-prev-page" style={{ marginRight: "10px" }}>
                            {current_page === 1 ? <img src={prev} alt="" className="kudos-page-arrow" /> : <img style={{ cursor: "pointer" }} className="kudos-page-arrow" onClick={() => { HandleDataSource( issuedAwardList, current_page - 1 ) }} src={previous_arrow} alt="" />}
                        </Button>}
                        <div className="uer-profile-award-card-container">
                            {
                                current_source?.map( ( n ) => (
                                    <div id={current_page} name={current_page}>
                                        <div className="issued-award-image-container"  style={{ cursor: "pointer" }} onClick={() => selectedAward( n )}>
                                            {/* <img className="award-delete-icon" src={Delete_icon} alt="" style={{ position: 'absolute', left: '10px', top: "10px", width: "18px",cursor:"pointer" }} onClick={() => { deleteAwards( n ) }} /> */}
                                            <img src={n?.badge?.image} alt="" className="recived-award-image"/>
                                            <h4 className="recived-award-image-title">{n?.badge?.title}</h4>
                                        </div>
                                    </div>
                                ) )
                            }
                            {issuedAwardList?.length==0&&!loading&&<div style={{width:"100%",justifyContent:"center",alignItems:'center',display:'flex',paddingTop:"200px"}}>
                                <h4 className="no-data">No Given Kudos Found</h4>
                            </div>}
                        </div>
                        {issuedAwardList?.length > 0&&<Button className="kudos-next-page" style={{ marginLeft: "10px" }} >
                            {current_page === total_page ? <img src={next} alt="" className="kudos-page-arrow" /> : <img style={{ cursor: "pointer" }} className="kudos-page-arrow" onClick={() => { HandleDataSource( issuedAwardList, current_page + 1 ) }} src={next_arrow} alt="" />}
                        </Button>
                        }
                    </div>
                    {deleteCofirm && <DeleteConfiramtion {...props} Visible={deleteCofirm} deleteType={{ id: selectedData?.id, type: "badge_award" }} closeModal={() => { setDeleteConfirm( false ); setSelectedData() }} />}
                </div>
            </Spin>
        )
    }
}
export default IssuedAwardView