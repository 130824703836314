import { graphql } from '@apollo/client/react/hoc';
import { CREATE_COMPETENCY_ASSESSMENT } from './graphql/ca-create-queries.gql';
import { message } from 'antd';

export const withCreateCompetencyAssessment = Component =>
graphql(CREATE_COMPETENCY_ASSESSMENT, {
  props: ({ mutate, history }) => ({
    createAssessment: async values => {
      try {
        const {
          data: { createAssessment }
        } = await mutate({
          variables: {
            ...values
          }
        });

        message.destroy();
        message.success('Assessment created successfully');
        return createAssessment.assessment;
      } catch (e) {
        message.destroy();
        message.error("Failed to assessment");
        console.error(e);
      }
    }
  })
})(Component);