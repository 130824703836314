// @ts-nocheck
import { graphql } from '@apollo/client/react/hoc';
import { message } from 'antd';
import toolResolvers from '../resolvers';

import { GET_ALL_COMMITMENT_QUERY } from '../graphql/AllCommitments.gql';
import { GET_ALL_TOOLS_ADMIN_QUERY } from '../graphql/AllToolsAdminQuery.gql';
import { COMMITMENT_BY_ID_QUERY } from '../graphql/CommitmentById.gql';
import { GET_COMMITMENT_RECOMMENDATION_QUERY } from '../graphql/GetCommitmentRecommendations.gql';
import { TOOL_BY_ID_ADMIN_QUERY } from '../graphql/ToolByIdAdmin.gql';
import { TOOL_BY_ID_OPTIMIZED } from '../graphql/ToolByidOptimized.gql';
// Client
import { TOOL_STATE_QUERY } from '../graphql/ToolStateQuery.client.gql';
import { UPDATE_TOOL_FILTER } from '../graphql/UpdateToolFilter.client.gql';

// Mutation
import { ADD_COMMITMENT } from '../graphql/AddCommitment.gql';
import { ADD_TOOL } from '../graphql/AddTool.gql';

import { EDIT_COMMITMENT } from '../graphql/EditCommitment.gql';
import { EDIT_TOOL, EDIT_TOOL_ADMIN_PANEL } from '../graphql/EditTool.gql';

import { removeTypename } from '../../core/clientStorage';
import { DELETE_COMMITMENT } from '../graphql/DeleteCommitment.gql';
import { DELETE_TOOL } from '../graphql/DeleteTool.gql';
import { getCommitmentStringFromInt, getToolTypeBase64StringFromInt } from 'modules/look';
import { CREATE_IMAPACT_NARRATIVE, UPDATE_IMAPACT_NARRATIVE } from '../graphql/ImpactNarrative.gql';

export const withAllToolsAdminQuery = Component =>
  graphql(GET_ALL_TOOLS_ADMIN_QUERY, {
    options: ({ filter, orderBy, pagination }) => {
      // console.log(filter);
      return { variables: { ...pagination, ...filter, orderBy } };
    },
    props({ data }) {
      const { loading, error, getAllToolsAdmin, subscribeToMore, updateQuery } = data;
      return { loading, error, getAllToolsAdmin, subscribeToMore, updateQuery };
    }
  })(Component);

export const withAllCommitments = Component =>
  graphql(GET_ALL_COMMITMENT_QUERY, {
    options: ({ filter, orderBy, pagination }) => {
      return { variables: { ...pagination, ...filter, orderBy } };
    },
    props({ data }) {
      const { loading, error, getAllCommitments, subscribeToMore, updateQuery } = data;
      return { loading, error, getAllCommitments, subscribeToMore, updateQuery };
    }
  })(Component);

export const withGetCommitmentRecommendations = Component =>
  graphql(GET_COMMITMENT_RECOMMENDATION_QUERY, {
    options: ({ filter, orderBy, pagination }) => {
      return { variables: { ...pagination, ...filter, orderBy } };
    },
    props({ data }) {
      const { loading, error, getCommitmentRecommendations, subscribeToMore, updateQuery } = data;
      return { loading, error, getCommitmentRecommendations, subscribeToMore, updateQuery };
    }
  })(Component);

export const withAddCommitment = Component =>
  graphql(ADD_COMMITMENT, {
    props: ({ mutate, history }) => ({
      createCommitment: async values => {
        try {
          const {
            data: { createCommitment }
          } = await mutate({
            variables: {
              ...values
            }
          })

          message.destroy();
          console.log('addCommitment:::', createCommitment)
          return createCommitment.commitment;
        } catch (e) {
          message.destroy();
          console.error(e);
        }
      }
    })
  })(Component);


export const withAddTool = Component =>
  graphql(ADD_TOOL, {
    props: ({ mutate, history }) => ({
      createTool: async values => {
        try {
          const {
            data: { createTool }
          } = await mutate({
            variables: {
              ...values
            }
          })

          message.destroy();
          return createTool.tool;
        } catch (e) {
          message.destroy();
          console.error(e);
        }
      }
    })
  })(Component);

export const withEditTool = Component =>
  graphql(EDIT_TOOL, {
    props: ({ mutate }) => ({
      editTool: async values => {
        try {
          const {
            data: { updateTool }
          } = await mutate({
            variables: {
              ...values
            }
          });
          return updateTool.tool;
        } catch (e) {
          console.error(e);
        }
      }
    })
  })(Component);

export const withEditToolAdminPanel = Component =>
  graphql(EDIT_TOOL_ADMIN_PANEL, {
    props: ({ mutate }) => ({
      editTool: async values => {
        try {
          const {
            data: { updateTool }
          } = await mutate({
            variables: {
              ...values
            }
          });
          return updateTool.tool;
        } catch (e) {
          console.error(e);
        }
      }
    })
  })(Component);

export const withEditCommitment = Component =>
  graphql(EDIT_COMMITMENT, {
    props: ({ mutate }) => ({
      editCommitment: async values => {
        try {
          const {
            data: { updateCommitment }
          } = await mutate({
            variables: {
              ...values
            }
          });
          return updateCommitment.commitment;
        } catch (e) {
          console.error(e);
        }
      }
    })
  })(Component);

export const withCommitmentById = Component =>
  graphql(COMMITMENT_BY_ID_QUERY, {
    options: props => {
      let id = '';
      if (props.match) {
        id = props.match.params.id;
      } else if (props.navigation) {
        id = props.navigation.state.params.id;
      }

      return {
        variables: { id: getCommitmentStringFromInt(id) }
      };
    },
    props({ data: { loading, error, getCommitmentById, subscribeToMore, updateQuery } }) {
      if (error) {
        throw new Error(error.message);
      }
      return { loading, error, getCommitmentById, subscribeToMore, updateQuery };
    }
  })(Component);


export const withToolByIdAdmin = Component =>
  graphql(TOOL_BY_ID_ADMIN_QUERY, {
    options: props => {
      let id = '';
      if (props.match) {
        id = props.match.params.id;
      } else if (props.navigation) {
        id = props.navigation.state.params.id;
      }

      return {
        variables: { id: getToolTypeBase64StringFromInt(id) },
        notifyOnNetworkStatusChange: true
      };
    },
    props({ data: { loading, error, getToolByIdAdmin, subscribeToMore, updateQuery, networkStatus } }) {
      // console.log(networkStatus)
      if (error) {
        throw new Error(error.message);
      }
      return { loading, error, getToolByIdAdmin, subscribeToMore, updateQuery };
    }
  })(Component);

export const withToolById = Component =>
  graphql(TOOL_BY_ID_OPTIMIZED, {
    options: props => {
      let id = '';
      if (props.match) {
        id = props.match.params.id;
      } else if (props.navigation) {
        id = props.navigation.state.params.id;
      }

      return {
        variables: { toolId: Number(id || props.id), user: props?.me?.id },
        fetchPolicy: 'network-only'
      };
    },
    props({ data: { loading, error, getToolById, subscribeToMore, updateQuery, refetch } }) {
      if (error) {
        throw new Error(error.message);
      }
      return { loading, error, getToolById, subscribeToMore, updateQuery, refetch };
    }
  })(Component);


export const withToolDeleting = Component =>
  graphql(DELETE_TOOL, {
    props: ({ mutate }) => ({
      deleteTool: async values => {
        let id;
        if(values?.id){
          id = {...values}
        }else{
          id = {id:values}
        }
        
        try {
          const {
            data: { deleteTool }
          } = await mutate({
            variables: {
              ...id
            }
          });
          message.destroy();
          return true;
        } catch (e) {
          message.destroy();
          console.error(e)
        }
      }
    })
  })(Component);

export const withDeleteCommitmentAdminPanel = Component =>
  graphql(DELETE_COMMITMENT, {
    props: ({ mutate }) => ({
      deleteCommitment: async values => {
        try {
          const {
            data: { deleteCommitment }
          } = await mutate({
            variables: {
              ...values
            }
          });
          message.destroy();
          message.success('Successfully deleted');
          return true;
        } catch (e) {
          message.destroy();
          const subString = e.toString().includes('because they are referenced through restricted foreign keys');
          console.log('subString::::', subString);
          if (subString) {
            message.error("Oops!! Could not perform the action. The commitment that you are trying to delete is already in use.");
          } else {
            message.error("Couldn't perform the action");
          }
          console.error(e)
        }
      }
    })
  })(Component);





// Client
export const withToolState = Component =>
  graphql(TOOL_STATE_QUERY, {
    props({ data }) {
      const { orderBy, ...rest } = data.toolState;
      const toolState = { ...removeTypename(rest), orderBy };
      return { ...toolState, stateLoading: data.loading };
    }
  })(Component);

export const withToolFilterUpdating = Component =>
  graphql(UPDATE_TOOL_FILTER, {
    props: ({ mutate }) => ({
      onOrderByChange(orderBy) {
        mutate({ variables: { orderBy } });
      },
      onPaginationChange(pagination) {
        mutate({ variables: { pagination } });
      },
      onFiltersRemove() {
        mutate({ variables: { ...toolResolvers.defaults.toolState } });
      },
      onTitleChange(title_Icontains) {
        mutate({ variables: { filter: { title_Icontains } } });
      },
      onDescriptionChange(description_Icontains) {
        mutate({ variables: { filter: { description_Icontains } } });
      }
    })
  })(Component);


export const withAddImpactNarrative = Component =>
  graphql(CREATE_IMAPACT_NARRATIVE, {
    props: ({ mutate, history }) => ({
      createImpactNarrative: async values => {
        try {
          const {
            data: { createImpactNarrative }
          } = await mutate({
            variables: {
              ...values
            }
          })
          message.destroy();
          message.success('Successfully added impact narrative')
       
          return createImpactNarrative.impactNarrative;
        } catch (e) {
          message.destroy();
          if(e?.message?.includes('Data too long')){
            message.error('Data is too long')
          }else{
            message.error('Failed to add impact narrative')
          }
        }
      }
    })
  })(Component);

export const withUpdateImpactNarrative = Component =>
  graphql(UPDATE_IMAPACT_NARRATIVE, {
    props: ({ mutate, history }) => ({
      updateImpactNarrative: async values => {
        try {
          const {
            data: { updateImpactNarrative }
          } = await mutate({
            variables: {
              ...values
            }
          })

          message.destroy();
          return updateImpactNarrative.impactNarrative;
        } catch (e) {
          message.destroy();
          if(e?.message?.includes('Data too long')){
            message.error('Data is too long')
          }else{
            message.error('Failed to update impact narrative')
          }
        }
      }
    })
  })(Component);