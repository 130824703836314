import React from 'react'
import { compose } from '../../core';
import { Row, Col, Button, Card, Slider,Spin,Input,Form ,Switch ,Space ,Radio, message,Modal  } from "antd";
import { withUpdateContributorRequest } from '../container/objective-keyresult-operations';
import { getIntFromString } from 'modules/layout/Layout';

const AcceptRequest =(props)=>{

    const {visible,data,onClose,updateContributorRequestStatus,type,modalLoading}=props

    const [loading,setLoading]=React.useState(false)
    const cancel=()=>{
        onClose({response:false})
    }
    const Submited =async()=>{
        setLoading(true)
        let response = await updateContributorRequestStatus({id:getIntFromString(data?.id),requestStatus:"ACCEPTED"})
        if(response){
            window.location.reload()
            setLoading(false)
            onClose({response:true})
        }
    }
    return (
        <>
        <Modal
           footer={null}
           visible={visible}
           centered
           closable={false}
           destroyOnClose={true}
           className='custom-new-modal'>
            <Spin spinning={loading}>
            <Col style={{position:'relative'}}>
                    <div>
                        <p className="modal-sub-head">Are you sure you want to <span style={{color:"#4CCB1F",fontWeight:"500"}}>accept</span>{type==='collabouration'?' collaboration':" assignment" }<br/> request for <span style={{fontWeight:"500"}}>{data?.okr?.title}</span>?</p>
                       
                    </div>
                <Row style={{width:"100%",gap:"20px",paddingTop:"20px"}} justify="center">
                    <Button className="modal-cancel-btn" onClick={cancel}>Cancel</Button>
                    <Button className="modal-submit-btn" style={{background: '#4CCB1F',border:'1px solid #4CCB1F'}} onClick={()=>Submited()}>Accept</Button>
                </Row>
            </Col>
            </Spin>
           
         </Modal>
     </>
    )
}

export default compose(withUpdateContributorRequest)(AcceptRequest)