const baseName = '/organization';
const verticalBaseName = '/vertical';
const organizationBaseName = '/organization';
const teamBaseName = '/team';
const employeeBaseName = '/employee';
const detailBaseName = '/detail';

const newBaseName = '/new';
const editBaseName = '/edit';

module.exports = {
  organization: baseName + baseName + '/admin-panel',
  vertical: baseName + verticalBaseName + '/admin-panel',
  team: baseName + teamBaseName + '/admin-panel',
  employee: baseName + employeeBaseName + '/admin-panel',

  organizationDetail: baseName + baseName + detailBaseName + '/admin-panel' + baseName + '/:id',
  organizationDetailLink: baseName + baseName + detailBaseName + '/admin-panel' + baseName + '/',
  verticalDetail: baseName + verticalBaseName + detailBaseName + '/admin-panel' + verticalBaseName + '/:id',
  verticalDetailLink: baseName + verticalBaseName + detailBaseName + '/admin-panel' + verticalBaseName + '/',
  teamDetail: baseName + teamBaseName + detailBaseName + '/admin-panel' + teamBaseName + '/:id',
  teamDetailLink: baseName + teamBaseName + detailBaseName + '/admin-panel' + teamBaseName + '/',

  addOrganization: baseName + baseName + newBaseName,
  addVertical: baseName + verticalBaseName + newBaseName,
  addTeam: baseName + teamBaseName + newBaseName,
  addEmployee: baseName + employeeBaseName + newBaseName,

  editOrganization: baseName + organizationBaseName + editBaseName + '/:id',
  editOrganizationLink: baseName + organizationBaseName + editBaseName + '/',
  editVertical: baseName + verticalBaseName + editBaseName + '/:id',
  editVerticalLink: baseName + verticalBaseName + editBaseName + '/',
  editTeam: baseName + teamBaseName + editBaseName + '/:id',
  editTeamLink: baseName + teamBaseName + editBaseName + '/',
  editEmployee: baseName + employeeBaseName + editBaseName + '/:id',
  editEmployeeLink: baseName + employeeBaseName + editBaseName + '/'
};
