import React from 'react';
import { Row, Col, Form } from 'antd';

import {
    TextField,
    TeamManagerField,
    TeamEmployeeTranferField,
    VerticalField,
    getIntFromString,
    ButtonsComponent,
    MarkdownField,
    CKEditorField
} from '../../look';
// import VerticalEmployeeTransfer from '../../look/components/form-components/VerticalEmployeeTransfer';

import ROUTE from '../route';

export const CKEditorFieldName = 'description';

const TeamFormComponent = props => {
    const { onSubmit, teamByTeamId, verId } = props;
    const [button, setButton] = React.useState('');

    const initialValues = {
        name: teamByTeamId && teamByTeamId.name && teamByTeamId.name,
        description: teamByTeamId && teamByTeamId && teamByTeamId.description,
        managers: teamByTeamId && teamByTeamId && teamByTeamId?.managers?.edges?.map(({ node }) => getIntFromString(node.id)),
        employees:
            teamByTeamId &&
            teamByTeamId.employees &&
            teamByTeamId.employees.edges[0] &&
            teamByTeamId.employees.edges.map(({ node }) => getIntFromString(node.id))
    };

    return (
        <Row>
            <Col span={24}>
                <Form
                    layout="vertical"
                    onFinish={values => onSubmit(values, button)}
                    initialValues={initialValues}
                    required={false}
                    scrollToFirstError={true}
                >
                    <Row>
                        <Col span={10} offset={1}>
                            <Row>
                                <Col lg={14} md={20}>
                                    <TextField name="name" label="Name" rules={[{ required: true, message: 'All questions are required!' }]} />
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={10} md={13} xs={24}>
                            <Row>
                                <Col span={24}>
                                    <TeamManagerField
                                        name="managers"
                                        label="Manager"
                                        mode={'multiple'}
                                        verId={(teamByTeamId && teamByTeamId.vertical && getIntFromString(teamByTeamId.vertical.id)) || verId}
                                        rules={[{ required: true, message: 'Manager is required!' }]}
                                        onAdd={ROUTE.addemployee}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={3} md={0} xs={0} />
                        <Col span={22} offset={1}>
                            <CKEditorField
                                name={CKEditorFieldName}
                                label="Description"
                                initialValue={teamByTeamId && teamByTeamId.description}
                            // rules={[{ required: true, message: 'content is required!' }]}
                            />
                        </Col>
                    </Row>
                    {/* <MarkdownField name="description" label="Description" initialValue={teamByTeamId && teamByTeamId.description} /> */}
                    <Row justify="start">
                        <Col span={24}>
                            <TeamEmployeeTranferField
                                name="employees"
                                label="Employees"
                                verId={(teamByTeamId && teamByTeamId.vertical && getIntFromString(teamByTeamId.vertical.id)) || verId}
                                // rules={[{ required: true, message: 'Employees is required!' }]}
                                initialValues={initialValues && initialValues.employees}
                                onAdd={ROUTE.addEmployee}
                            />
                        </Col>
                    </Row>
                    <ButtonsComponent setButton={setButton} />
                </Form>
            </Col>
        </Row>
    );
};

export default TeamFormComponent;
