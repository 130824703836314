// @ts-nocheck
import React, { useEffect, useState, useRef } from "react";
import {
  Row,
  Button,
  Spin,
  DatePicker,
  message,
  Modal,
  Tooltip,
} from "antd";

import { displayUtcToUserTimezone, FEEDBACK_FETCH_QTY } from "../../look/components/functions";
import ReactMarkdown from "../../look/components/ReactMarkdown";
import "../css/ReceivedFeedbackView.css";
import moment from "moment";
import CustomSortImg from "../../assets/feedback_sub_date_sort_btn.svg";
import CustomSortImgWhite from "../../assets/feedback_sub_date_sort_btn_white.svg";
import { LoadingOutlined } from "@ant-design/icons";
import UserProfileImg from '../../assets/user-profile.svg';
import { ai_trigger_content } from "modules/ai-tips/ai-trigger-content";

const ReceivedFeedbackView = (props) => {
  const {
    history,
    me,
    pageCount,
    feedbackPermission,
    receivedHasLength,
    receivedLoading,
    noFeedbackMsg,
    onfilter,
    dataSource,
    searchBarData,
    queryObj,
    activeTab,
    setQueryObj,
    getFeedbackDetails,
    setFeedbackModalVisible,
    setFeedbackDetailSide,

  } = props;
  
  const antIcon = (
    <LoadingOutlined style={{ fontSize: "34px", color: "#4CCB1F" }} spin />
  );

  const [hoverDate, setHoverDate] = useState(false);
  const [activeBtn, setActiveBtn] = useState();
  const typingTimeoutRef = useRef(null);
  const [cardViewQty, setCardViewQty] = useState(FEEDBACK_FETCH_QTY);
  const [moreCardsBtn, setMoreCardsBtn] = useState();
  const [isClear, setIsClear] = useState();
  const [isPagination, setIsPagination] = useState(true)

  React.useEffect(() => {

    if (receivedHasLength && activeTab === 'received') {
      const now = new Date();
      const all = new Date(0).toISOString();
      const pastWeek = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000).toISOString();
      onfilter({ feedbackTo: me?.employee?.id, orderBy: ["-createdAt", "-updatedAt"], createdAt_Range: [all, now.toISOString()], first: 12 })
      setQueryObj({ feedbackTo: me?.employee?.id, orderBy: ["-createdAt", "-updatedAt"], createdAt_Range: [all, now.toISOString()], first: 12 })

    } else if (me === null) {
      history.push('/user/login')
    }

  }, [activeTab, receivedHasLength])

  const handleFeedbackSelectionForDetails = (data) => {
    getFeedbackDetails(data?.id);
    setFeedbackModalVisible(true);
    setFeedbackDetailSide("received")
  };

  // code for sorting feedbacks in terms of month year or date range
  const [openCustomPicker, setOpenCustomPicker] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState([]);

  const feedbacks = dataSource;

  const [currentPage, setcurrentPage] = React.useState(1);

  let currentFilter = React.useRef({});

  let dateFilter = React.useRef({
    okr_Duedate_Range: [
      moment().startOf("year").utc().toISOString(),
      moment().endOf("year").utc().toISOString(),
    ],
  });

  const { RangePicker } = DatePicker;

  const [sortOption, setSortOption] = useState();
  const [customDateRange, setCustomDateRange] = useState([null, null]);

  // Handle sort option change
  const handleSortOptionChange = (option) => {
    setActiveBtn(option)
    setSortOption(option);
  };

  // Handle custom date range change
  const handleCustomDateRangeChange = (range) => {
    setCustomDateRange(range?.okr_Duedate_Range);
  };

  const datefilter = (e) => {
    if (e && e?.length === 2) {
      let okr_Duedate_Range = [
        moment(e[0]).startOf("day").utc().toISOString(),
        moment(e[1]).endOf("day").utc().toISOString(),
      ];
      dateFilter["current"] = { okr_Duedate_Range };
      setcurrentPage(1);
      currentFilter.current["offset"] = 0;
      handleCustomDateRangeChange({
        ...dateFilter["current"],
        ...currentFilter["current"],
        from_search: true,
      });
      let start_date = moment(e[0]).startOf("day");
      let end_date = moment(e[1]).endOf("day");
      setSelectedValue([start_date, end_date]);


      localStorage.setItem(
        "DateFilterOKR",
        JSON.stringify({
          startDate: okr_Duedate_Range[0],
          endDate: okr_Duedate_Range[1],
        })
      );
    } else {
      dateFilter["current"] = {};
      setcurrentPage(1);
      currentFilter.current["offset"] = 0;
      handleCustomDateRangeChange({
        ...dateFilter["current"],
        ...currentFilter["current"],
        from_search: true,
      });
      let start_date = moment(e[0]).startOf("day");
      let end_date = moment(e[1]).endOf("day");
      setSelectedValue([start_date, end_date]);
    }
  };

  const dateChangeFilterData = (e) => {
    if (e && e?.length == 2) {
      if (e[0] !== null) {

      }
    }
  };

  const custonFilter = () => {
    if (selectedValue?.length && selectedValue?.length == 2) {
      setOpenCustomPicker(false);
      setActiveBtn("custom");
      setSortOption("custom");

    } else {
      message.error("please select a date range");
    }
  };

  const handleCustomDate = (option) => {

    setOpenCustomPicker(true);
  };

  // the new sort code below this 

  // code to sort with strings
  useEffect(() => {

    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }

    typingTimeoutRef.current = setTimeout(() => {

      if (searchBarData?.dropDown === "Feedback" && activeTab === 'received') {

        if (searchBarData?.input !== undefined && searchBarData?.input !== null && searchBarData?.input !== "") {
          if (queryObj) {
            let obj = queryObj;
            onfilter({
              ...obj,
              feedbackTo: me?.employee?.id,
              orderBy: ["-createdAt", "-updatedAt"],
              okr_Goalstatement_Icontains: searchBarData?.input,
              feedbackFrom_User_FirstName_Icontains: searchBarData?.input,
              feedbackFrom_User_LastName_Icontains: searchBarData?.input,
              response_Answer_Answer_Icontains: searchBarData?.input,
              first: cardViewQty + FEEDBACK_FETCH_QTY
            });
            setQueryObj({
              ...obj,
              feedbackTo: me?.employee?.id,
              orderBy: ["-createdAt", "-updatedAt"],
              okr_Goalstatement_Icontains: searchBarData?.input,
              feedbackFrom_User_FirstName_Icontains: searchBarData?.input,
              feedbackFrom_User_LastName_Icontains: searchBarData?.input,
              response_Answer_Answer_Icontains: searchBarData?.input,
              first: cardViewQty + FEEDBACK_FETCH_QTY
            });
          }
        }

        if (searchBarData?.input === "") {
          if (queryObj) {
            let obj = queryObj;
            onfilter({
              ...obj,
              feedbackTo: me?.employee?.id,
              orderBy: ["-createdAt", "-updatedAt"],
              okr_Goalstatement_Icontains: searchBarData?.input,
              feedbackFrom_User_FirstName_Icontains: searchBarData?.input,
              feedbackFrom_User_LastName_Icontains: searchBarData?.input,
              response_Answer_Answer_Icontains: searchBarData?.input,
              first: cardViewQty + FEEDBACK_FETCH_QTY
            });
            setQueryObj({
              ...obj,
              feedbackTo: me?.employee?.id,
              orderBy: ["-createdAt", "-updatedAt"],
              okr_Goalstatement_Icontains: searchBarData?.input,
              feedbackFrom_User_FirstName_Icontains: searchBarData?.input,
              feedbackFrom_User_LastName_Icontains: searchBarData?.input,
              response_Answer_Answer_Icontains: searchBarData?.input,
              first: cardViewQty + FEEDBACK_FETCH_QTY
            });
          }
        }
      }

    }, 600)

  }, [searchBarData?.input]);

  // new date sort code
  useEffect(() => {
    // setCardViewQty(0)

    const sortDebounce = setTimeout(() => {
      const now = new Date();
      const pastWeek = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000).toISOString();
      const pastMonth = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000).toISOString();
      const pastYear = new Date(now.getTime() - 365 * 24 * 60 * 60 * 1000).toISOString();
      const all = new Date(0).toISOString();
      let obj = queryObj
      let dateRangeArray = [];
      switch (sortOption) {

        case "noDate":
          dateRangeArray = [all, now.toISOString()];

          if (obj && activeTab === 'received') {
            onfilter({ ...obj, feedbackTo: me?.employee?.id, createdAt_Range: dateRangeArray, first:FEEDBACK_FETCH_QTY });
            setQueryObj({ ...obj, feedbackTo: me?.employee?.id, createdAt_Range: dateRangeArray, first:FEEDBACK_FETCH_QTY });
          } else {
            break;
          }
          break
        case "pastWeek":
          const startOfWeek = moment().subtract(1, 'weeks').startOf('week')
          const endOfWeek = moment().subtract(1, 'weeks').endOf('week')
          dateRangeArray = [startOfWeek, endOfWeek];

          if (obj && activeTab === 'received') {
            onfilter({ ...obj, feedbackTo: me?.employee?.id, createdAt_Range: dateRangeArray, first:FEEDBACK_FETCH_QTY });
            setQueryObj({ ...obj, feedbackTo: me?.employee?.id, createdAt_Range: dateRangeArray, first:FEEDBACK_FETCH_QTY })
          } else {
            break;
          }

          break;
        case "pastMonth":

          const lastmonthFirstdate = moment().subtract(1, 'months').startOf('month')
          const lastmonthLastdate = moment().subtract(1, 'months').endOf('month')
          
          dateRangeArray = [lastmonthFirstdate, lastmonthLastdate];
          if (obj && activeTab === 'received') {
            onfilter({ ...obj, feedbackTo: me?.employee?.id, createdAt_Range: dateRangeArray, first:FEEDBACK_FETCH_QTY });
            setQueryObj({ ...obj, feedbackTo: me?.employee?.id, createdAt_Range: dateRangeArray, first:FEEDBACK_FETCH_QTY })

          } else {
            break;
          }

          break;
        case "pastYear":

          const lastYearFirstDate = moment().subtract(1, 'years').startOf('year')
          const lastYearLastDate = moment().subtract(1, 'years').endOf('year')

          dateRangeArray = [lastYearFirstDate, lastYearLastDate];
          
          if (obj && activeTab === 'received') {
            onfilter({ ...obj, feedbackTo: me?.employee?.id, createdAt_Range: dateRangeArray, first:FEEDBACK_FETCH_QTY });
            setQueryObj({ ...obj, feedbackTo: me?.employee?.id, createdAt_Range: dateRangeArray, first:FEEDBACK_FETCH_QTY })

          } else {
            break;
          }
          break;

        case "custom":
          dateRangeArray = [customDateRange[0], customDateRange[1]];
          if (obj && activeTab === 'received') {
            onfilter({ ...obj, feedbackTo: me?.employee?.id, createdAt_Range: dateRangeArray, first:FEEDBACK_FETCH_QTY });
            setQueryObj({ ...obj, feedbackTo: me?.employee?.id, createdAt_Range: dateRangeArray, first:FEEDBACK_FETCH_QTY })

          } else {
            break;
          }
          // }
          break;
        default:
          break;
      }
    }, 300)

    return () => clearTimeout(sortDebounce)

  }, [sortOption, customDateRange])

  // button press activators


  // callback function for asking for more cards
  function seeMore() {
    if (searchBarData?.dropDown === "Feedback") {

      let obj = queryObj;
      delete obj["first"]
      onfilter({
        ...obj,
        first: cardViewQty + FEEDBACK_FETCH_QTY
      });
      setQueryObj({
        ...obj,
        first: cardViewQty + FEEDBACK_FETCH_QTY
      });
    }

    setCardViewQty(cardViewQty + FEEDBACK_FETCH_QTY)
  }

  // Card state worker
  useEffect(() => {
    if (searchBarData?.input === null || searchBarData?.input === undefined) {
      setIsPagination(true)
      if (dataSource?.length >= pageCount?.totalCount) {
        setMoreCardsBtn(false)
      } else {
        setMoreCardsBtn(true)
      }
    } else {
      setIsPagination(false)
    }
  }, [pageCount])

  
  
  

  return (
    <>
      <Spin spinning={receivedLoading} indicator={antIcon}>
        <div className="feedback-date-sort-container">
          <button className={activeBtn === "pastWeek" ? 'clicked-date-btn pointer' : 'unclicked-date-btn pointer'} onClick={() => handleSortOptionChange("pastWeek")}>
            Past Week
          </button>
          <button className={activeBtn === "pastMonth" ? 'clicked-date-btn pointer' : 'unclicked-date-btn pointer'} onClick={() => handleSortOptionChange("pastMonth")}>
            Past Month
          </button>
          <button className={activeBtn === "pastYear" ? 'clicked-date-btn pointer' : 'unclicked-date-btn pointer'} onClick={() => handleSortOptionChange("pastYear")}>
            Past Year
          </button>

          <button
            className={activeBtn === "custom" ? 'clicked-date-btn pointer' : 'unclicked-date-btn pointer'}
            style={{
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onMouseEnter={() => setHoverDate(true)}
            onMouseLeave={() => setHoverDate(false)}
            onClick={() => handleCustomDate("custom")}
          >
            Custom{" "}
            <img
              style={{ width: "17px", height: "17px", marginLeft: "10px" }}
              src={activeBtn === "custom" ? CustomSortImgWhite : CustomSortImg}
              alt="dateimg"
            />
          </button>
          <Tooltip title={'Clear Filter'}>
          <button className={isClear ? 'clicked-date-btn pointer' : 'unclicked-date-btn pointer'} onMouseDown={() => setIsClear(true)} onMouseUp={() => setIsClear(false)} onClick={() => handleSortOptionChange("noDate")}>
            Clear
          </button>
          </Tooltip>
        
          <div
            className="row-wrap"
            style={{ marginTop: "-29px", marginBottom: "30px" }}
          >
            <Modal
              footer={null}
              centered
              visible={openCustomPicker}
              destroyOnClose={true}

              closable={false}
            >
              <div
                style={{
                  height: "330px",
                  width: "100%",
                  position: "relative",
                  padding: "10px 10px",
                }}
              >
                <RangePicker
                  open={openCustomPicker}
                  allowClear={false}
                  dropdownClassName={"custom-meeting-picker"}
                  className="date-range-meeting"
                  value={selectedValue}
                  onChange={(e) => datefilter(e)}
                  onCalendarChange={(e) => {
                    dateChangeFilterData(e);
                  }}
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  disabledDate={(current) => {
                    return current.isAfter(moment());
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    bottom: "0px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    marginTop: "20px",
                  }}
                >
                  <Button
                    className="cancel-date-btn"
                    onClick={() => {
                      setOpenCustomPicker(false);
                      setSelectedValue([]);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="save-date-btn"
                    onClick={() => custonFilter()}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </Modal>
          </div>
        </div>

        {
          !noFeedbackMsg &&
          <div className="feedback-sub-cards-container height-fix-received adjust-margin-feedback">
            {dataSource?.map((item) => {

              const feedBackRawText = item?.response?.answerSet?.edges?.map(itm => itm)?.map(item => item?.node?.answer)

              return (
                <div className="card-border-container" onClick={(e) => handleFeedbackSelectionForDetails(item)}>
                  <div
                    className="feedback-image-title-cont"
                  >
                    <img
                      src={
                        item?.feedbackFrom?.user?.profile?.profileImage === null
                          ? UserProfileImg
                          : item?.feedbackFrom?.user?.profile?.profileImage
                      }
                      alt="profile_image"
                      className="feedback_card_picture"
                    />
                    <div className="feedback-sub-card-title">
                      <h3 className="feedback-card-from-text">From</h3>

                      <div className="feedback-sub-from-section">
                        <h1 className="feedback-sub-name-text">
                          {item.feedbackFrom?.user?.firstName}{" "}
                          {(item.feedbackFrom?.user?.lastName)
                            .substring(0, 1)
                            .toUpperCase()}
                        </h1>
                        {item?.badgeAward?.edges?.length > 0 &&
                          item?.badgeAward?.edges?.map((award) => (
                            feedbackPermission?.kudos_permission ? <img
                              src={award?.node?.badge?.image}
                              alt="kudos_badge_img"
                              style={{ height: '35px', width: '40px', borderRadius: '50%' }}
                              className="feedback-sub-kudos-image"
                            /> : null
                          ))}
                      </div>

                      <Row wrap={false} style={{ gap: '7px' }} className="received-on-section">
                        <p className="received-on-text">Received On:</p>
                        <p className="received-on-date">
                          {displayUtcToUserTimezone(
                            item?.createdAt,
                            "DD MMM YYYY"
                          )}
                        </p>
                      </Row>
                    </div>

                  </div>
                  <h5 className="feedback-rec-detail-text feedback-sub-detail-text">
                    <ReactMarkdown className="markdown-text-container">{feedBackRawText}</ReactMarkdown>
                  </h5>
                </div>

              );
            })}
            <div className="center-item" style={{ width: 'max-content', display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
              {moreCardsBtn && !receivedLoading && isPagination && <Row justify="center" align="middle" style={{ width: "100%", marginBottom: '-1em 0 1em 0' }}>
                <h4 className="load-more-title-feedback" onClick={() => seeMore()}>Load More</h4>
              </Row>}
            </div>
          </div>
        }
        {
          noFeedbackMsg &&
          <Row style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '500px' }}>
            <h1 >No feedbacks available</h1>
          </Row>
        }

      </Spin>
    </>
  );
};

export default ReceivedFeedbackView;

