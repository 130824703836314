import React, { useState } from 'react';
import { Collapse } from 'antd';
import ReactMarkdown from '../../look/components/ReactMarkdown';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';

const QuestionsView = props => {

  const { category, questions } = props;
  const [active, setActive] = useState([...Array(questions?.edges?.length).keys()].map(i => i === 0));

  const headerStyle = i => (active[i] ? { fontFamily: 'Poppins' } : { color: '#AFB0A2' });

  return (
    <>
      <h3>{category.name}</h3>
      <p>{category.description}</p>
      <Collapse
        accordion
        bordered={false}
        expandIconPosition="right"
        defaultActiveKey={'0'}
        style={{ backgroundColor: '#fff' }}
        expandIcon={({ isActive, panelKey }) => (!isActive ? <PlusOutlined /> : <CloseOutlined />)}
        onChange={e =>
          e
            ? setActive([...Array(questions?.edges?.length).keys()].map(i => i === Number(e)))
            : setActive([...Array(questions?.edges?.length).keys()].map(i => false))
        }
      >
        {questions &&
          questions.edges.length > 0 &&
          questions.edges.map(({ node }, index) => (
            <Collapse.Panel
              header={
                <span style={headerStyle(index)}>
                  <ReactMarkdown>{node.question}</ReactMarkdown>
                </span>
              }
              key={index}
            >
              <ReactMarkdown>{node.answer}</ReactMarkdown>
            </Collapse.Panel>
          ))}
      </Collapse>
    </>
  );
};

export default QuestionsView;
