import gql from 'graphql-tag';
import { COMMITMENT_TYPE_CONNECTION } from './CommitmentTypeConnection.gql';

export const GET_ALL_COMMITMENT_QUERY = gql`
  ${COMMITMENT_TYPE_CONNECTION}
  query getAllCommitments($first: Int, $offset: Int, $orderBy: [String], $description_Icontains: String) {
    getAllCommitments(first: $first, offset: $offset, orderBy: $orderBy, description_Icontains: $description_Icontains) {
      ...CommitmentTypeConnection
    }
  }
`;
