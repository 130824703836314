import survey_icon from '../assets/survey_icon.svg'
import three_dot from '../assets/three-dot.svg'
import icon_360 from '../assets/360_icon.svg'
import back_arrow from '../assets/soul-back.svg'
import delete_icon from '../assets/invite-delete-icon.svg'
import search_icon from '../assets/search-icon.svg'
import report_icon from '../assets/view_report.svg'
import invite_360 from '../assets/360 invite.svg'
import report_360 from '../assets/360 report.svg'

export const all_survey_icon ={
    survey_icon,
    three_dot,
    icon_360,
    back_arrow,
    delete_icon,
    search_icon,
    report_icon,
    invite_360,
    report_360
}