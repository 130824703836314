import baner_bg from '../assets/map-baner-bg.svg'
import tip_icon from '../assets/baner-tip-icon.svg'
import performance_no_data from '../assets/performance-no-data-bg.svg'
import engagement_no_data_bg from '../assets/conaching-engagement-no-data-bg.svg'
import kudos_no_data_bg from '../assets/kudos-no-data-bg.svg'
import call_start from '../assets/dashboard-call-start-icon.svg'
import down_arrow from '../assets/icons/drop_down_arrow.svg'
import diversity_no_data from '../assets/teams-diversity-no-data.svg'
import assessment_no_data from '../assets/teams-assessment-no-data.svg'
import teams_scape_no_data from '../assets/teams-scape-no-data.svg'
import add_new_member from '../assets/teams-add-new-employees.svg'
import card_icon_1 from '../assets/dashboard-scape-card-1-icon.svg'
import card_icon_2 from '../assets/dashboard-scape-card-2-icon.svg'
import card_icon_3 from '../assets/dashboard-scape-card-3-icon.svg'
import card_icon_4 from '../assets/dashboard-scape-card-4-icon.svg'
import card_icon_5 from '../assets/dashboard-scape-card-5-icon.svg'
import close_icon from '../assets/dashboard-kudos-close-icon.svg'
import search_icon from '../assets/search-icon.svg'
import add_icon from '../assets/dashboard-add-icon.svg'
import remove_icon from '../assets/dashboard-remove-icon.svg'
import start_active from '../assets/dashboard-one-on-one-star-active-icon.svg'
import start_inactive from '../assets/dashboard-one-on-one-star-inactive-icon.svg'
import check_box from '../assets/dashboard-check-box-icon.svg'
import user_remove from '../assets/user_remove.svg'
import profile from '../assets/avatar.jpg'
import back_arrow from '../assets/new_back_arrow.svg'
import profile_1 from '../assets/assessment-profile-1.svg'
import profile_2 from '../assets/assessment-profile-2.svg'
import profile_3 from '../assets/assessment-profile-3.svg'
import profile_4 from '../assets/assessment-profile-4.svg'
import no_team from '../assets/no-tem-member.svg'
import soul_tip_preview_gif from '../assets/soul_tip_preview.gif'
export const dasboardIcons ={
    baner_bg,
    tip_icon,
    performance_no_data,
    engagement_no_data_bg,
    kudos_no_data_bg,
    call_start,
    down_arrow,
    diversity_no_data,
    assessment_no_data,
    teams_scape_no_data,
    add_new_member,
    card_icon_1,
    card_icon_2,
    card_icon_3,
    card_icon_4,
    card_icon_5,
    close_icon,
    search_icon,
    add_icon,
    remove_icon,
    start_active,
    start_inactive,
    check_box,
    profile_1,
    profile_2,
    profile_3,
    profile_4,
    no_team,
    soul_tip_preview_gif
}

export const profile_icon = {
    user_remove,
    profile,
    back_arrow
}