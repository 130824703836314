import gql from 'graphql-tag';


export const DELETE_COMPETENCY_MUTATION = gql`

  mutation deleteCompetency($id: ID!) {
    deleteCompetency(id:$id) {
        competency  {
            id
      }
    }
  }
`;