import React from 'react';
import SelfReadinessAssessmentContainer from '../containers/SelfAssessmentContainer'
import EmployeeReadinessAssessmentContainer from '../containers/EmployeeAssessmentContainer'
import ReadinessSearchView from './filter';
const SelfReadinessAssessmentMainView = props => {
    const {
        me,SetAITrigger,ai_trigger
    } = props;
    const [tab_list, setTabList] = React.useState(
        [{
            key: 'self',
            label: 'Self',
        },
        {
            key: 'employees',
            label: 'Employees',
        }]
    )
    const [selected_tab, setSelectedTab] = React.useState(
        {
            key: 'self',
            label: 'Self',
        }
    )

    const [SubmitFilter,SetSubmitFilter] = React.useState()

    const filter_data = [
        {
            key: "readinesslevel_Skill_Title_Icontains",
            ui_flow: "input_box",
            label: "Skill",
            default: true
        },
        {
            key: "search",
            ui_flow: "input_box",
            label: "Name",   
        }
    ]


    const views = {
        self: SelfReadinessAssessmentContainer,
        employees: EmployeeReadinessAssessmentContainer,
    }
    const CurrentView = views[selected_tab?.key]

    const onchangeFilter=(e)=>{
        if (e) {
            let filter = {}
            filter_data.filter(i => i?.key != Object.keys(e)[0]).forEach(i => {
                filter[i.key] = ""
            })
            filter = { ...filter, ...e }
            SetSubmitFilter(filter)
        }
    }

    return (
        <>
            <div style={{marginTop:"20px"}} className='assessement-space'>
                {
                    (me?.employee?.orgCeoEmployee?.totalCount || me?.employee?.orgPocEmployee?.totalCount || me?.employee?.verticalHeadEmployee?.totalCount || me?.employee?.teamManagerEmployee?.totalCount) ?
                        <div className="idp-switch-tab" style={{width:"fit-content"}}>
                            {tab_list?.map(i => (
                                <div
                                    style={{ width: "9em" }}
                                    className={`idp-switch-tab-text ${selected_tab.key === i?.key ? "idp-switch-tab-selected" : ""}`}
                                    onClick={() => { setSelectedTab(i) }}
                                >
                                    {i?.label}
                                </div>
                            ))}
                        </div>
                        :
                        <div style={{ width: "1px", height: "1px" }}></div>
                }
                <ReadinessSearchView filterData={selected_tab?.key=="self"?filter_data?.filter(i=>i?.key!="search"):filter_data} id="employee_tab" selected_tab={selected_tab} onfilterChange={e => onchangeFilter(e)} {...props} />
                
            </div>
            <CurrentView {...props} SubmitFilter={SubmitFilter} SetAITrigger={SetAITrigger} ai_trigger={ai_trigger}/>
        </>
    );
};
export default SelfReadinessAssessmentMainView;
