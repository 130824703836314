import dropdown_Arrow from '../assets/dropdown_Arrow.svg'
import add_icon from '../assets/scape-admin-add-icon.svg'
import delete_icon from '../assets/scape-admin-delete-icon.svg'
import edit_icon from '../assets/scape-admin-edit-icon.svg'
import delete_warning from '../assets/scape-delete-warning-icon.svg'
import new_back_arrow from '../assets/new_back_arrow.svg'

export const createScapeIcons ={
    dropdown_Arrow
    
}

export const scapeHomeIcons = {
    add_icon,
    delete_icon,
    edit_icon,
    dropdown_Arrow,
    delete_warning,
    new_back_arrow
}