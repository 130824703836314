import React from "react";
import SoulTeamReportView from "../components/soul-team-report-view";
import { compose } from "modules/core";
import { withApollo } from '@apollo/client/react/hoc';
import { getIntFromString, globalPermissionValidator } from "modules/look";
import { soul_permission } from "Permissions/soul.permission";
import { ALL_EMPLOYEE_SOUL_REPORT, TEAM_SOUL_REPORT, VERTICAL_SOUL_REPORT } from "../graphql/CompletedSurvey.gql";

const SoulTeamReportContainer = (props) => {
    const { client, userPermission, me,filter_response,search } = props
    const [permission, setPermission] = React.useState()
    const [report_loading, setloading] = React.useState(true)
    const [all_employee_report, setAllEmployeeReport] = React.useState([])
    const [vertical_report, setVerticalRport] = React.useState([])
    const [team_report, setTeamReport] = React.useState([])
    const [soul_team_report, setSoulTeamReport] = React.useState([])
    const [ all_employee_report_page_info, SetAllEmployeeReportPageInfo ] = React.useState()
    const [ vertical_report_page_info, SetVerticalReportPageInfo ] = React.useState()
    const [ team_report_page_info, SetTeamReportPageInfo ] = React.useState()

    const all_report_list_ref = React.useRef( [] )
    const vertical_report_list_ref = React.useRef( [] )
    const team_report_list_ref = React.useRef( [] )

    React.useEffect(() => {

        if (userPermission?.length) {
            let soulPermission = globalPermissionValidator(soul_permission, userPermission);
            setPermission(soulPermission)
        }
    }, [userPermission])

    React.useEffect( () => {
        all_report_list_ref.current = all_employee_report
      }, [ all_employee_report ] )

      React.useEffect( () => {
        vertical_report_list_ref.current = vertical_report
      }, [ vertical_report ] )

      React.useEffect( () => {
        team_report_list_ref.current = team_report
      }, [ team_report ] )

    React.useEffect(() => {
        if (all_employee_report || vertical_report || team_report) {
            let lists = [...all_employee_report, ...vertical_report, ...team_report]
            var setObj = new Set();
            var result = lists?.reduce((acc, item) => {
                if (!setObj.has(item?.id)) {
                    setObj.add(item?.id)
                    acc.push(item)
                }
                return acc;
            }, [])
            setSoulTeamReport(result)
            setloading(false)
        }
    }, [all_employee_report, vertical_report, team_report])

    React.useEffect(() => {
        if (permission?.required_permission) {
            if (me?.employee?.orgCeoEmployee?.totalCount > 0 || me?.employee?.orgPocEmployee?.totalCount > 0) {
                getSoulReportForAllEmployee()
            }
            else if (me?.employee?.verticalHeadEmployee?.totalCount > 0) {
                getSoulReportForVerticals({id_In:me?.employee?.verticalHeadEmployee?.edges?.map(({ node }) => getIntFromString(node?.id))})
            }
            else if (me?.employee?.teamManagerEmployee?.totalCount > 0) {
                getSoulReportForTeam({id_In:me?.employee?.teamManagerEmployee?.edges?.map(({ node }) => getIntFromString(node?.id))})
            }
            else if (me?.employee?.verticalHeadEmployee?.totalCount > 0 && me?.employee?.teamManagerEmployee?.totalCount > 0) {
                getSoulReportForVerticals({id_In:me?.employee?.verticalHeadEmployee?.edges?.map(({ node }) => getIntFromString(node?.id))})
                getSoulReportForTeam({id_In:me?.employee?.teamManagerEmployee?.edges?.map(({ node }) => getIntFromString(node?.id))})
            }
        }
    }, [permission])

    const getPaginationReport=(filter)=>{ 
        if (me?.employee?.orgCeoEmployee?.totalCount > 0 || me?.employee?.orgPocEmployee?.totalCount > 0) {
            getSoulReportForAllEmployee({...filter_response,nameIcontains:search,...filter})
        }
        else if (me?.employee?.verticalHeadEmployee?.totalCount > 0) {
            getSoulReportForVerticals({id_In:me?.employee?.verticalHeadEmployee?.edges?.map(({ node }) => getIntFromString(node?.id)),...filter_response,user_FirstName:search,...filter})
        }
        else if (me?.employee?.teamManagerEmployee?.totalCount > 0) {
            getSoulReportForTeam({id_In:me?.employee?.teamManagerEmployee?.edges?.map(({ node }) => getIntFromString(node?.id)),...filter_response,user_FirstName:search,...filter})
        }
        else if (me?.employee?.verticalHeadEmployee?.totalCount > 0 && me?.employee?.teamManagerEmployee?.totalCount > 0) {
            getSoulReportForVerticals({id_In:me?.employee?.verticalHeadEmployee?.edges?.map(({ node }) => getIntFromString(node?.id)),...filter_response,user_FirstName:search,...filter})
            getSoulReportForTeam({id_In:me?.employee?.teamManagerEmployee?.edges?.map(({ node }) => getIntFromString(node?.id)),...filter_response,user_FirstName:search,...filter})
        }
    }
    React.useEffect(() => {
        if(filter_response !=undefined){
            if (me?.employee?.orgCeoEmployee?.totalCount > 0 || me?.employee?.orgPocEmployee?.totalCount > 0) {
                getSoulReportForAllEmployee({...filter_response,nameIcontains:search})
            }
            else if (me?.employee?.verticalHeadEmployee?.totalCount > 0) {
                getSoulReportForVerticals({id_In:me?.employee?.verticalHeadEmployee?.edges?.map(({ node }) => getIntFromString(node?.id)),...filter_response,user_FirstName:search})
            }
            else if (me?.employee?.teamManagerEmployee?.totalCount > 0) {
                getSoulReportForTeam({id_In:me?.employee?.teamManagerEmployee?.edges?.map(({ node }) => getIntFromString(node?.id)),...filter_response,user_FirstName:search})
            }
            else if (me?.employee?.verticalHeadEmployee?.totalCount > 0 && me?.employee?.teamManagerEmployee?.totalCount > 0) {
                getSoulReportForVerticals({id_In:me?.employee?.verticalHeadEmployee?.edges?.map(({ node }) => getIntFromString(node?.id)),...filter_response,user_FirstName:search})
                getSoulReportForTeam({id_In:me?.employee?.teamManagerEmployee?.edges?.map(({ node }) => getIntFromString(node?.id)),...filter_response,user_FirstName:search})
            }
        }
    },[filter_response,search])

    const soulValues = (value) => {
        const { values, strength, personality, knowledge } = value
        let data_set = {}
        if (values?.edges[0]?.node?.answerSet?.edges?.length) {
            let answer_set = values?.edges[0]?.node?.answerSet?.edges?.map(({ node }) => node)
            data_set["value"] = answer_set?.sort((a, b) => a?.question?.sequence - b?.question?.sequence)
        }

        if (strength?.edges[0]?.node?.answerSet?.edges?.length) {
            let answer_set = strength?.edges[0]?.node?.answerSet?.edges?.map(({ node }) => node)
            data_set["strength"] = answer_set?.sort((a, b) => a?.question?.sequence - b?.question?.sequence)
        }

        if (personality?.edges[0]?.node?.answerSet?.edges?.length) {
            let answer_set = personality?.edges[0]?.node?.answerSet?.edges?.map(({ node }) => node)
            data_set["personality"] = answer_set?.sort((a, b) => a?.question?.sequence - b?.question?.sequence)
        }

        if (knowledge?.edges[0]?.node?.answerSet?.edges?.length) {
            let answer_set = knowledge?.edges[0]?.node?.answerSet?.edges?.map(({ node }) => node)
            data_set["knowledge"] = answer_set?.sort((a, b) => a?.question?.sequence - b?.question?.sequence)
        }

        return data_set
    }

    const getSoulReportForAllEmployee = async (filter) => {
        try {
            setloading(true)
            const { data } = await client.query({
                query: ALL_EMPLOYEE_SOUL_REPORT,
                variables: {...filter,first:15},
                fetchPolicy: 'network-only'
            });
            if (data?.allEmployees) {
                SetAllEmployeeReportPageInfo( {
                    hasNextPage: data?.allEmployees?.pageInfo?.hasNextPage,
                    cursor: data?.allEmployees?.pageInfo?.endCursor,
                  } )
                let data_list = data?.allEmployees?.edges?.map(({ node }) => {
                    return {
                        id: node?.id,
                        firstName: node?.user?.firstName,
                        lastName: node?.user?.lastName,
                        response: soulValues(node?.user)
                    }
                })
                if ( filter?.cursor ) {
                    data_list = all_report_list_ref.current.concat( data_list )
                  }
                setAllEmployeeReport(data_list)
            }
        } catch (e) {

        }
    }

    const getSoulReportForVerticals = async (filter) => {
        setloading(true)
        try {
            const { data } = await client.query({
                query: VERTICAL_SOUL_REPORT,
                variables: { ...filter,first:15 },
                fetchPolicy: 'network-only'
            });
            if (data?.verticals) {
                SetVerticalReportPageInfo( {
                    hasNextPage: data?.verticals?.edges[0]?.node?.employees?.pageInfo?.hasNextPage,
                    cursor: data?.verticals?.edges[0]?.node?.employees?.pageInfo?.endCursor,
                  } )
                let data_list = [].concat(
                    data?.verticals?.edges?.map(({ node }) => node?.employees?.edges?.map(item => {
                        return {
                            id: item?.node?.id,
                            firstName: item?.node?.user?.firstName,
                            lastName: item?.node?.user?.lastName,
                            response: soulValues(item?.node?.user)
                        }
                    }))
                ).flat()
                if ( filter?.cursor ) {
                    data_list = vertical_report_list_ref.current.concat( data_list )
                  }
                setVerticalRport(data_list)
            }
        } catch (e) {

        }
    }

    const getSoulReportForTeam = async (filter) => {
        setloading(true)
        try {
            const { data } = await client.query({
                query: TEAM_SOUL_REPORT,
                variables: { ...filter,first:15},
                fetchPolicy: 'network-only'
            });
            if (data?.Teams) {
             
                SetTeamReportPageInfo( {
                    hasNextPage: data?.Teams?.edges[0]?.node?.employees?.pageInfo?.hasNextPage,
                    cursor: data?.Teams?.edges[0]?.node?.employees?.pageInfo?.endCursor,
                  } )
                let data_list = [].concat(data?.Teams?.edges?.map(({ node }) => node?.employees?.edges?.map(item => {
                    return {
                        id: item?.node?.id,
                        firstName: item?.node?.user?.firstName,
                        lastName: item?.node?.user?.lastName,
                        response: soulValues(item?.node?.user)
                    }
                }))).flat()
                if ( filter?.cursor ) {
                    data_list = team_report_list_ref.current.concat( data_list )
                  }
                setTeamReport(data_list)
            }
        } catch (e) {

        }
    }

    return (
        <>
            <SoulTeamReportView {...props} 
            soul_team_report={soul_team_report}
             report_loading={report_loading}
             all_employee_report_page_info={all_employee_report_page_info}
             vertical_report_page_info={vertical_report_page_info}
             team_report_page_info={team_report_page_info}
             getReportList={(filter)=>getPaginationReport(filter)}/>
        </>
    )
}

export default compose(withApollo)(SoulTeamReportContainer);