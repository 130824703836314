// @ts-nocheck
import React from "react";
import { compose } from "../../core";
import RoleMainView from "../components/RoleMainView";
import '../css/Role.css'
const RoleContainer = (props) => {

    const { back_to_home,SetbackToHome } = props

    const [current_role_tab, SetRoleTab] = React.useState()

// to set the route to local storage when child route is changed
React.useEffect(() => {
    if (current_role_tab) {
        const cached_navigation = JSON.parse( localStorage?.getItem( 'role_definition_navigation' ) )
        let role_definition_navigation = cached_navigation ? 
            {   
            ...cached_navigation, 
            parent_route: { key: 'role', label: 'Role' }, 
            child_route: current_role_tab
            }
            : {
                parent_route: { key: 'role', label: 'Role' },
                child_route: current_role_tab
            }
        localStorage.setItem( 'role_definition_navigation', JSON.stringify( role_definition_navigation ) )
    }
}, [current_role_tab])

    // to handle child route when user navigate back to role definition
    const role_navigation = JSON.parse(localStorage?.getItem('role_definition_navigation'))
    if (!current_role_tab) {
        if (role_navigation?.child_route?.key?.includes('role')) {
            SetRoleTab(role_navigation?.child_route)
        } else {
            SetRoleTab({ key: "role_home_view" })
        }
    }

    React.useEffect(()=>{
        if(back_to_home==='role_home_view'){
            let nav ={
                parent_route: { key: 'role', label: 'Role' },
                child_route: { key: "role_home_view" }
            }
            localStorage.setItem('role_definition_navigation', JSON.stringify(nav))
            SetRoleTab({ key: "role_home_view" })
            SetbackToHome(null)
        }
    },[back_to_home])

    return (
        <RoleMainView
        {...props}
        current_role_tab={current_role_tab}
        SetRoleTab={(e) => { SetRoleTab(e) }}/>
    )

}

export default compose()(RoleContainer);