import React from 'react';
import { ApartmentOutlined, TeamOutlined } from '@ant-design/icons';
import OrganizationDetail from './containers/OrganizationDetail/organizationDetailContainer'
import EditOrganization from './containers/editOrganization';
import AddVertical from './containers/addVertical'
import AddEmployee from './containers/addEmployee/addEmploye'
import VerticalDetail from './containers/verticalDetail';
import TeamDetail from './containers/teamDetail'
import EditVertical from './containers/editVertical/editVertical'
import EditTeam from './containers/editTeam'
import ROUTE from './route';
import ReportsContainer from './containers/ReportsContainer';
import addTeam from './containers/addTeam';
import EditEmployee from '../organization/containers/EditEmployee';

export default [
    {
        name: 'OrganizationDetail',
        path: ROUTE.organizationDetail,
        component: OrganizationDetail,
        exact: true,
    },
    {
        name: 'EditOrganization',
        path: ROUTE.editOrganization,
        component: EditOrganization,
        exact: true
    },
    {
        name: 'AddVertical',
        path: ROUTE.addVertical,
        component: AddVertical,
        exact: true
    },
    {
        name: 'AddEmployee',
        path: ROUTE.addemployee,
        component: AddEmployee,
        exact: true
    },
    {
        name: 'VerticalDetail',
        path: ROUTE.verticalDetail,
        component: VerticalDetail,
        icon: <ApartmentOutlined />,
        exact: true
    },
    {
        name: 'TeamDetail',
        path: ROUTE.teamDetail,
        component: TeamDetail,
        icon: <TeamOutlined />,
        exact: true
    },
    {
        name: 'EditVertical',
        path: ROUTE.editVertical,
        component: EditVertical,
        exact: true
    },
    {
        name: 'EditTeam',
        path: ROUTE.editTeam,
        component: EditTeam,
        exact: true
    },
    {
        name: 'ReportView',
        path: ROUTE.organizationReport,
        component: ReportsContainer,
        exact: true
    },
    {
        name: 'AddTeam',
        path: ROUTE.addTeam,
        component: addTeam,
        exact: true
    },
    {
        name: 'EditEmployeeNonAdmin',
        path: ROUTE.editOrgEmployee,
        component: EditEmployee,
        admin: false
    }
]