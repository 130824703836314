import gql from 'graphql-tag';

export const DELETE_ACCOUNT_MUTATION = gql`
  mutation deleteAccount($password: String!) {
    deleteAccount(password: $password) {
      success
      errors
    }
  }
`;
