import gql from 'graphql-tag';
import { QUESTION_TYPE } from './QuestionType.gql';

export const QUESTION_BY_ID_QUERY = gql`
  ${QUESTION_TYPE}
  query questionById($id: ID!) {
    questionById(id: $id) {
      ...QuestionType
    }
  }
`;
