import gql from 'graphql-tag';

export const ROLES_LIST_FOR_ASSIGN_EMPLOYEE = gql`
  query allRoles($title_Icontains:String) {
    allRoles(title_Icontains:$title_Icontains) {
      edges {
        node {
            id
            title
            employeeLevel{
                id
                name
            }
        }
      }
    }
  }
`;

export const ALL_EMPLOYEE_LIST= gql`
query allEmployees($user_FirstName:String,$first:Int){
    allEmployees(user_FirstName:$user_FirstName,first:$first){
      edges{
        node{
          id
          memberSet(first:1){
            edges{
              node{
                id
              }
            }
          }
          user{
            id
            firstName
            lastName
            email
          }
        }
      }
    }
  }
`
export const ALL_MEMBER_LIST_QUERY =gql`
query users($first:Int,$username_Icontains:String){
  users(employee_Isnull: true,first:$first,username_Icontains:$username_Icontains) {
    edges {
      node {
        id
        firstName
        lastName
        email
      }
    }
  }
}
`

export const ALL_MEMBER_LIST = gql`
query members($first:Int,$after:String,$toEmployee_User_FirstName_Icontains: String){
  members(first:$first,after:$after,toEmployee_User_FirstName_Icontains:$toEmployee_User_FirstName_Icontains,role_Isnull: false){
    pageInfo{
      hasNextPage
      endCursor
    }
    edges{
      node{
        id
        toEmployee{
          id
          user{
            id
            firstName
            lastName
          }
        }
        fromVertical{
          id
          name
        }
        role{
          id
          title
          employeeLevel{
            id
            name
          }
        }
        
      }
    }
  }
}
`
export const ALL_MEMBER_SUBSCRIPTION = gql`
subscription memberSubscription{
  memberSubscription{
    mutation
    member{
      id
      toEmployee{
        id
        user{
          firstName
          lastName
        }
			}
      fromVertical{
        id
        name
			}
      role{
        id
        title
        employeeLevel{
          id
          name
        }
			}
    }
  }
}
`
export const MEMBER_DELETE_MUTATION = gql`
mutation deleteMember($id: ID!){
  deleteMember(id:$id){
    member{
      id
    }
  }
}
`
