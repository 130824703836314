import gql from 'graphql-tag';
import { DEFAULT_COMMITMENTS_PROGRESS_TYPE } from '../graphql/DefaultCommitmentProgressType.gql';

export const GET_ALL_DEFAULT_COMMITMENTS_PROGRESS = gql`
  ${DEFAULT_COMMITMENTS_PROGRESS_TYPE}
  query getAllDefaultCommitmentsProgress($orderBy: [String], $offset: Int, $first: Int) {
    getAllDefaultCommitmentsProgress(orderBy: $orderBy, offset: $offset, first: $first) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          ...DefaultCommitmentProgressType
        }
        cursor
      }
      # edgeCount
      # totalCount
    }
  }
`;
export const GET_ALL_DEFAULT_COMMITMENTS_PROGRESS_SUBSCRIPTION = gql`
  ${DEFAULT_COMMITMENTS_PROGRESS_TYPE}
  mutation toolsDefaultCommitmentProgressSubscription{
    toolsDefaultCommitmentProgressSubscription{
      mutation
      defaultCommitmentProgress{
        ...DefaultCommitmentProgressType
      }
    }
  }
  `
