import gql from 'graphql-tag';

export const CREATE_CONTRIBUTOR_MUTATION = gql`
mutation createContributor($contributorData:ContributorInput!){
    createContributor(contributorData:$contributorData){
        contributor{
          id
        }
    }
}
`