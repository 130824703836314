import React from 'react';
import { Row, Col, Form, Divider } from 'antd';

import { TextField, ButtonsComponent } from '../../look';

const UserFormComponent = props => {
  const { onSubmit } = props;
  const [button, setButton] = React.useState('');
  return (
    <Row>
      <Col span={24}>
        <Form layout="vertical" onFinish={values => onSubmit(values, button)} required={false} scrollToFirstError={true}>
          <Row>
            <Col span={10} offset={1}>
              <Row>
                <Col span={14}>
                  <TextField name="email" label="E Mail" rules={[{ required: true, message: 'E Mail is required!' }]} />
                </Col>
              </Row>
              <Row>
                <Col span={14}>
                  <TextField
                    name="firstName"
                    label="First Name"
                    rules={[{ required: true, message: 'First Name is required!' }]}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={10}>
              <Row>
                <Col span={14}>
                  <TextField
                    name="username"
                    label="Username"
                    rules={[{ required: true, message: 'Username is required!' }]}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={14}>
                  <TextField
                    name="lastName"
                    label="Last Name"
                    rules={[{ required: true, message: 'Last Name is required!' }]}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={3} />
          </Row>
          <Divider />
          <ButtonsComponent setButton={setButton} saveadd={false} saveedit={false}/>
        </Form>
      </Col>
    </Row>
  );
};

export default UserFormComponent;
