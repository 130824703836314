import gql from 'graphql-tag';

export const UPDATE_MEETING_SERIES_MUTATION = gql`
  mutation  updateMeetingSeries( $seriesId: String!,$meetingData: UpdateMeetingInput! ) {
    updateMeetingSeries(seriesId:$seriesId,meetingData:$meetingData){
      meeting{
        id
        title
        when
        seriesId
      }
    }

  }
`;
