import gql from 'graphql-tag';
import { COACHING_COMMITMENT_TYPE } from './CoachingCommitmentType.gql';

export const DELETE_COACHING_COMMITMENT = gql`
  ${COACHING_COMMITMENT_TYPE}
  mutation deleteCoachingCommitment($id: ID!) {
    deleteCoachingCommitment(id: $id) {
      coachingCommitment {
        ...CoachingCommitmentType
      }
    }
  }
`;
