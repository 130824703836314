import React from 'react';
import UserOKRStatus from '../components/userOKRStatus';
import moment from 'moment';
import { EMPLOYEE_OKR_DETAIL } from '../graphql/EmployeeProfile.gql';
const UserOKRStatusContainer = (props) => {
    const { me,profile_detail,client } = props
    const [okr_data, SetOKRData] = React.useState()
    const [okr_page_info,SetOKRPageInfo] = React.useState()
    let okr_ref = React.useRef()
 

    React.useEffect(()=>{
        if(okr_data){
            okr_ref.current = okr_data
        }
    },[okr_data])

    React.useEffect(()=>{
        if(profile_detail){
            
            let filter ={
                createdBy:profile_detail?.employee_id,
                first:3,
                // okr_Duedate_Range:[moment().startOf('quarter').utc().toISOString(),moment().endOf('quarter').utc().toISOString()]
            }
            getOKRData(filter)
        }
    },[profile_detail])

    const getOKRData= async (filter)=>{
        const { data } = await client.query({
            query: EMPLOYEE_OKR_DETAIL,
            fetchPolicy: 'network-only',
            variables: filter,
        });
        if(data){
            let cascading = data?.AllCascadingObjectives
            let non_cascading = data?.AllNonCascadingObjectives
            let list = [...cascading?.edges?.map(({node})=>node),...non_cascading?.edges?.map(({node})=>node)]
            list = dataHandler(list)
            if((filter?.cascading_cursor || filter?.non_cascading_cursor) && okr_ref?.current){
                list = okr_ref?.current?.concat(list)
            }
            SetOKRData(list)
            let page_info ={
                total_doc:cascading?.totalCount + non_cascading?.totalCount,
                has_next_page:cascading?.pageInfo?.hasNextPage || non_cascading?.pageInfo?.hasNextPage,
                cascading_cursor:cascading?.pageInfo?.endCursor,
                non_cascading_cursor:non_cascading?.pageInfo?.endCursor
            }
            SetOKRPageInfo(page_info)
        }
    }

    const dataHandler =(list)=>{
        return list?.map(i=>{
            let d = {
                contributor_id: i?.id,
                okr_id: i?.okr?.id,
                title: i?.okr?.title,
                duedate: i?.okr?.duedate,
                okrType: i?.okrType,
                role: i?.role,
                progress: i?.progress,
                isDiscarded: i?.okr?.isDiscarded,
                discardRequest: i?.okr?.discardRequest?.totalCount?{
                    status: "PENDING"
                }:null,
                employee: profile_detail
            }

            return {
                ...d,
                status: getStatus( d ),
                colorCombination: getColor( d )
            }
        })
    }

    const getStatus = (data) => {
        if (data) {

            let currentDate = moment();

            if (currentDate.isAfter(data?.duedate) && data?.progress < 100) {
                return 'Missed'
            }

            else if (moment(data?.duedate).isAfter(moment()) && data?.progress >= 0 && data?.progress < 100) {
                return 'In progress'
            }

            else if (data?.progress === 100) {
                return 'Completed'
            }

            else if (data?.isDiscarded?.id) {
                return 'Discarded'
            }

            else if (data?.discardRequest?.status === 'PENDING') {
                return 'Pending'
            }

        }
    }

    const getColor = (data) => {
        if (data) {

            let currentDate = moment()

            if (currentDate.isAfter(data?.duedate) && data?.progress < 100) {
                return {
                    color: '#EF7878',
                    bg: '#FFF2F1'
                }
            }

            else if (moment(data?.duedate).isAfter(moment()) && data?.progress >= 0 && data?.progress < 100) {
                return {
                    color: '#FAC53C',
                    bg: '#FDF2D6'
                }
            }

            else if (data?.progress === 100) {
                return {
                    color: '#4CCB1F',
                    bg: '#D6FFC8'
                }
            }

            else if (data?.isDiscarded?.id) {
                return {
                    color: '#7A7A7A',
                    bg: '#DEDEDE'
                }
            }

            else if (data?.discardRequest?.status === 'PENDING') {
                return {
                    color: '#009AF1',
                    bg: '#C2F4FF'
                }
            }

        }
    }

    return (
        <>
            <UserOKRStatus {...props} okr_data={okr_data}/>
        </>
    )
}

export default UserOKRStatusContainer