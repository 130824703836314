import gql from "graphql-tag";

export const USER_EMPLOYEE_SEARCH = gql`
query allOrganizationEmployees($user_FirstName:String,$user_LastName:String){
  first:allOrganizationEmployees(user_FirstName:$user_FirstName) {
    edges {
      node {
        id
        user{
          firstName
          lastName
          email
          id
        	employee{
            id
          }
        }
        
      }
    }
  }
  last:allOrganizationEmployees(user_LastName:$user_LastName) {
    edges {
      node {
        id
        user{
          firstName
          lastName
          email
          id
        	employee{
            id
          }
        }
        
      }
    }
  }
}
`;
