import React from "react";
import NotificationSettingsView from "../components/notification-settings-view";
import '../css/notification-settings.css'

const NotificationSettingsContainer =(props)=>{
    return (
        <>
            <NotificationSettingsView {...props}/>
        </>
    )
}

export default NotificationSettingsContainer;