import gql from 'graphql-tag';
import { STORY_TYPE_CONNECTION } from './StoryTypeConnection.gql';


export const ALL_PUBLISHED_STORIES_QUERY = gql`
  ${STORY_TYPE_CONNECTION}
  query allPublishedStories(
    $first: Int
    $offset: Int
    $orderBy: [String]
    $title_Icontains: String
    $content_Icontains: String
    $author_Icontains: String
  ) {
    allPublishedStories(
      first: $first
      offset: $offset
      orderBy: $orderBy
      title_Icontains: $title_Icontains
      content_Icontains: $content_Icontains
      author_Icontains: $author_Icontains
    ) {
      ...StoryTypeConnection
    }
  }
`;
