// @ts-nocheck

import { Button, Col, Input, Modal, Row, Spin, Table, message } from 'antd';
import arrayMove from 'array-move';
import { getIntFromString } from 'modules/look';
import React from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import ReactMarkdown from '../../look/components/ReactMarkdown';
import '../css/RatingScale.css';
import gql from 'graphql-tag';
import '../css/RoleDefinition.css';
import { threshold_icons } from '../icon';
import RoleDefinitionConfirmation from '../../look-v2/Components/ConfirmationDialog';
import { useMutation } from '@apollo/react-hooks';


const RatingScaleView = (props) => {

    const { ratingScales, createRatingScale, deleteRatingScale, setRatingScaleLoading, ratingScaleLoading,
        setRatingScales, thresholdToDelete, setThresholdToDelete } = props
    const [addThreshold, setAddThreshold] = React.useState(undefined)
    const [thresholdCount, setThresholdCount] = React.useState(0);
    const [invalidThresholdInput, setInvalidThresholdInput] = React.useState(false);
    const [upateSource, setUpdateSource] = React.useState(undefined)
    const ratingRef =React.useRef()

    React.useEffect(() => {

        if (!isNaN(thresholdCount)) {
            setInvalidThresholdInput(false)
        }

    }, [thresholdCount])

    React.useEffect(()=>{
        if(ratingScales){
            ratingRef.current =ratingScales
        }
    },[ratingScales])
    const handleVisibilityOfPopup = () => {

        if (!ratingScales?.length) {
            setAddThreshold(true);

        } else {
            let ratingScaleRows = ratingScales?.map((scale) => scale?.createRow).filter((scale) => scale !== undefined);
            if (!ratingScaleRows?.length) {
                let list = [{ createRow: true, index: ratingScales?.length }].concat(ratingScales)
                setRatingScales(list)
            }

        }

    }


    const getRowsFromThresholdCount = () => {


        if (!thresholdCount) {
            setInvalidThresholdInput(true)
        } else {
            let arrayForCountOfThreshold = Array.from(Array(thresholdCount), (e, i) => {
                return { createRow: true, index: i,title:"" }
            })
            let list = arrayForCountOfThreshold
            setRatingScales(list)

            setAddThreshold(false)
            setThresholdCount(0);
        }


    }





    const DragHandle = SortableHandle(() => (
        <img src={threshold_icons.drag_icon_threshold}
            alt="drag"
            style={{
                cursor: 'grab',
                color: '#999',
            }}
        />
    ));

    const addThTitle=(e,indexToUpdate)=>{
        let rating_scale = ratingRef.current
        const updatedRatingScales = rating_scale.map(item => {
            if (item.index === indexToUpdate) {
                return { ...item, title: e.target.value };
            } else {
                return item;
            }
        });
        ratingRef.current = updatedRatingScales
    }


    const renderCreateRatingScale = (record) => (

        <Row id={`create-rating-scale-row-${record?.index}`} className='create-rating-scale-row' wrap={false}>
            <Col span={16}>
                <Input
                    placeholder="Enter threshold name"
                    id={`rating-scale-${record?.index}`}
                    defaultValue={record?.title}
                    onChange={(e)=>addThTitle(e,record?.index)}
                />
            </Col>
        </Row>

    )




    const handleRemovethreshold = (index) => {

        let updatedlistofRaringScaleRows = ratingScales?.filter((scale) => scale?.index !== index);
        setRatingScales(updatedlistofRaringScaleRows);

    }


    const columns = [

        {
            title: <div >
                <h4 className='rd-th-label'>Name</h4>
            </div>,
            key: 'Name',
            dataIndex: 'name',
            render: (text, record) =>
                record &&
                    record?.createRow ?
                    renderCreateRatingScale(record) :
                    <div className='threshold-record'>
                        <ReactMarkdown >{record?.name}</ReactMarkdown>
                    </div>
        },
        {
            title: <div >
                <h4 className='rd-th-label'>Action</h4>
            </div>,
            dataIndex: 'operation',
            key: 'Actions',
            width: "150px",
            render: (text, record) => record &&

                record?.createRow ?

                <Row justify='end' className='threshold-button-panel'>
                    <img className='pointer threshold-icon' onClick={(e) => handleAddThreshold(record?.index)} src={threshold_icons.submit} />
                    <img className='pointer threshold-icon' onClick={(e) => handleRemovethreshold(record?.index)} src={threshold_icons.cancel} />
                    {/* <div className='drag-visible-create-row'> <DragHandle /></div> */}
                </Row>
                :

                <Row justify='end' className='delete-button-threshold-record'>
                    <img className='pointer delete-rating-scale-icon' onClick={(e) => setThresholdToDelete(record)} src={threshold_icons.delete_icon} />
                    <div className='drag-visible'> <DragHandle /></div>
                </Row>
        }

    ];


    const handleClosureOfThresholdInutPopup = () => {

        setAddThreshold(false);
        setThresholdCount(0)

    }


    const ThresholdInput = () => (
        <div className='rd-confirmation-dialog'>
            <h4 className='rd-confirmation-message'>Threshold Scale</h4>
            <Input type='number' value={thresholdCount ? thresholdCount : null} onChange={(e) => setThresholdCount(parseInt(e.target.value))} />
            {
                invalidThresholdInput && <p className='invalid-threshold-input'>Please enter a valid input</p>
            }
            <div className='rd-confirmation-action-container'>
                <Button className='global-submit-btn' onClick={() => getRowsFromThresholdCount()}>Save</Button>
                <Button className='global-cancel-btn' onClick={() => handleClosureOfThresholdInutPopup()}>Cancel</Button>
            </div>
        </div>
    )


    const handleAddThreshold = async (indexOfRow) => {

        let thrTitle = document.getElementById(`rating-scale-${indexOfRow}`)

        if (thrTitle && thrTitle?.value && thrTitle?.value?.trim() !== "") {
            let ratings = ratingRef.current
            // let thresholds = ratingScales?.map((threshold) => threshold?.id).filter((value => value !== undefined));
            let name_list = ratingScales?.map((threshold) => threshold?.name?.trim()?.toLowerCase())
            if(name_list?.includes(thrTitle?.value?.trim()?.toLowerCase())){
                message.destroy()
                message.error('Threshold already exist')
                return
            }
            let sequenceOfThreshold = indexOfRow ? indexOfRow : 0
            
            setRatingScales(ratings)
            let ratingScaleData = {
                name: thrTitle?.value?.trim(),
                sequence: sequenceOfThreshold
            }

            setRatingScaleLoading(true)
            const response = await createRatingScale({ ratingScaleData: ratingScaleData });
            if (response) {
                setRatingScaleLoading(false)
                setAddThreshold(false)

            }
        }
        else{
            message.destroy()
            message.error("Fill in the required field")
        }
    }


    const UPDATE_LEVEL = gql`
    mutation updateRatingScale {
      ${upateSource
            ?.map(
                (item) =>
                    ` update${item.id}: updateRatingScale(id: ${item.id},sequence:${item?.sequence}) {
                ratingScale{
                  id
                  sequence
                }
              }
            `
            )
            .join('')}
    }
    `;

    const [updateRatingScale, { data, isloading, error }] = useMutation(UPDATE_LEVEL);
    const handleDeleteThreshold = async (id) => {
        let ratingScaleId = getIntFromString(id)
        setRatingScaleLoading(true)
        const response = await deleteRatingScale({ ratingScaleId: ratingScaleId });
        if (response) {
            setRatingScaleLoading(false);
            setThresholdToDelete(undefined);
        }else{
            setRatingScaleLoading(false);  
        }

    }

    React.useEffect(() => {
        if (upateSource && upateSource.length) {
            updateQualifyingLevelSeq()
        }
    }, [upateSource])

    const updateQualifyingLevelSeq = async () => {
        setRatingScaleLoading(true)
        const response = await updateRatingScale()
        setRatingScaleLoading(false)
    }

    const SortableItem = SortableElement(props => <tr {...props} />);
    const SortableContainerThreshold = SortableContainer(props => <tbody {...props} />);

    const onSortEnd = ({ oldIndex, newIndex }) => {
        const newData = arrayMove([].concat(ratingScales), oldIndex, newIndex).filter(el => !!el);

        const indexData = [];
        newData.map((node, index) => {
            const data = newData[index];
            indexData.push({ ...data, index: index, sequence: index });
        });
        setRatingScales(indexData);

        setUpdateSource(indexData.map(item => {
            return {
                id: getIntFromString(item?.id),
                sequence: item?.sequence
            }
        }))


    };


    const DraggableContainer = props => (
        <SortableContainerThreshold
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging-threshold"
            onSortEnd={onSortEnd} {...props} />
    );

    const DraggableBodyRow = ({ className, style, ...restProps }) => {
        const index = ratingScales && ratingScales?.findIndex(x => x?.index === restProps['data-row-key']);
        return <SortableItem style={{}} index={index} {...restProps} />;
    };



    return (
        <Spin spinning={ratingScaleLoading} >
            <div className='poppins rating-scale-parent'>
                <div className='rd-align-center' style={{ marginBottom: "2em" }}>
                    <button className='rd-add-button' onClick={(e) => handleVisibilityOfPopup()}  >+ Add Threshold</button>
                </div>

                {/* custom expansion table for rating scale */}
                <div className='rd-table-scroll'>
                    <div className='rd-custom-table'>

                        <div>


                            {/* All rows of threshold whih has data */}
                            <Table
                                className='ratingScale-table'
                                size={'middle'}
                                // loading={ratingScaleLoading}
                                style={{ background: "#fff" }}
                                pagination={false}
                                rowKey={'index'}

                                dataSource={
                                    ratingScales
                                        ? ratingScales
                                        : []
                                }
                                // bordered
                                columns={columns.map(c => ({ ...c, sortDirections: ['descend', 'ascend', 'descend'] }))}
                                components={{
                                    body: {
                                        wrapper: DraggableContainer,
                                        row: DraggableBodyRow,
                                    },
                                }}
                            />




                        </div>
                    </div>
                </div>

                {/* Popup for input of threshold */}

                <Modal
                    visible={addThreshold}
                    footer={false}
                    closable={false}
                    destroyOnClose={true}
                    centered={true}
                    className='rd-modal'
                >
                    <ThresholdInput ></ThresholdInput>
                </Modal>


                {/* Delete threshold popup */}
                <Modal
                    visible={thresholdToDelete}
                    footer={false}
                    closable={false}
                    destroyOnClose={true}
                    centered={true}
                    className='rd-modal'
                >
                    <RoleDefinitionConfirmation
                        message={`Do you want to delete the Threshold ${thresholdToDelete?.name}?`}
                        onCancel={() => setThresholdToDelete(undefined)}
                        onConfirm={() => handleDeleteThreshold(thresholdToDelete?.id)}
                        confirm_action_label='Yes'
                        cancel_action_label='No'
                    />
                </Modal>
            </div >
        </Spin>
    )

}

export default RatingScaleView;
