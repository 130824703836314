import gql from "graphql-tag";

export const ACTIONITEM_BY_MEETING_ID = gql`
query meetingById($id:ID!){
    meetingById(id:$id){
        id
        actionitemSet {
          edges {
            node {
              id
              title
              notes
              completed
              updatedAt
              meeting {
                id
                title
              }
              okr {
                id
                 title
                 okrType
                 progress
                 owner {
                   id
                   user {
                     id
                     firstName
                     lastName
                     employee {
                       id
                     }
                   }
                 }
               }
              commitment {
                id
                commitment {
                  id
                  title
                }
                user{
                  id
                  firstName
                  lastName
                  employee{
                    id
                  }
                }
                completed
              }
              coachingCommitment {
                id
                coachingcommitment {
                  id
                  title
                }
                user{
                  id
                  firstName
                  lastName
                  employee{
                    id
                  }
                }
                completed
              }
              notes
              completed
              isAgenda
              createdAt
              updatedAt
            }
          }
        }
      }
}
`;
