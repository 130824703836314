import gql from 'graphql-tag';
// DO NOT CHANGE THESE QUERY
export const OKR_SUBSCRIPTION = gql`
subscription okrSubscription($tz: String,$recipient: ID,$createdBy: ID){
    okrSubscription{
        mutation
        okr{
          id
          createdAt
          title(tz:$tz)
          goalStatement
          progress
          correctionDeadline
          duedate
          startdate
          okrType
          badgeAward(recipient:$recipient){
            edges{
              node{
                id
                evidence
                issuer{
                  id
                  user{
                    id
                    firstName
                    lastName
                  }
                }
                badge{
                  id
                  image
                  badgeFamily {
                    id
                    title
                  }
                  title
                  behaviouralDescription
                }
              }
            }
          }
          childSet(contributor_CreatedBy:$createdBy){
            totalCount
          }
          discardRequest(status_Iexact:"PENDING"){
            totalCount
           
           }
           discardRequestRaised:discardRequest(initiator:$recipient){
            totalCount
           }
          isDiscarded{
            id   
            status
          }
          contributorSet{
            edges{
              node{
                id
                role
                employee{
                  id
                  user{
                    id
                    firstName
                    lastName
                  }
                }
              }
            }
          }
        }
    }
}
`