import gql from 'graphql-tag';
import { DEFAULT_COMMITMENTS_PROGRESS_TYPE } from './DefaultCommitmentProgressType.gql';

export const DELETE_DEFAULT_TOOL_COMMITMENT = gql`
  ${DEFAULT_COMMITMENTS_PROGRESS_TYPE}
  mutation deleteDefaultToolCommitment($id: ID!) {
    deleteDefaultToolCommitment(id: $id) {
      defaultCommitmentProgress {
        ...DefaultCommitmentProgressType
      }
    }
  }
`;
