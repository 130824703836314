import gql from 'graphql-tag';

export const PERFORMANCE_CHART_DATA =gql`
query contributors($okr_Duedate_Range: [DateTime], $employee_In: [ID],$offset: Int,$first: Int,$savedOkrType:String) {
    PerformanceGraphData:contributors(savedOkrType: $savedOkrType,okr_Duedate_Range:$okr_Duedate_Range,employee_In:$employee_In, orderBy: ["okr__duedate"],offset:$offset,first:$first) {
        totalCount
        edgeCount
        edges {
            node {
              id
              progress
              okr{
                id
                title
                okrType
                duedate
              }
            }
        }
    }
}
`