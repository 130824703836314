import gql from 'graphql-tag';
import { GROUP_TYPE_CONNECTION, GROUP_TYPE_CONNECTION_VALUES } from './GroupTypeConnection.gql';
import { RESPONSE_TYPE } from './ResponseType.gql';
import { SURVEY_TYPE, SURVEY_TYPE_GROUP_SET, SURVEY_TYPE_GROUP_SET_VALUES_FIRST, SURVEY_TYPE_GROUP_SET_VALUES_LAST } from './SurveyType.gql';



export const SURVEY_TYPE_GROUP_SET_VALUES_FIRST_FRAGMENT = gql`
   ${GROUP_TYPE_CONNECTION_VALUES},
   ${RESPONSE_TYPE}
  fragment SurveyTypeFirst on SurveyType {
    id
    name
    description
    termsConditions
    shuffle
    public
    activeFrom
    activeTo
    reportQuery
    pubDate
    groupSet(first:1) {
      ...GroupTypeConnection
    }
    responseSet{
      edges{
        node{
          ...ResponseType
        }
      }
    }
  }
`;

export const SURVEY_TYPE_NO_FILTER_GROUP_SET = gql`
   ${GROUP_TYPE_CONNECTION}
  fragment SurveyType on SurveyType {
    id
    name
    description
    termsConditions
    shuffle
    public
    activeFrom
    activeTo
    reportQuery
    pubDate
    groupSet {
      ...GroupTypeConnection
    }
  }
`;

export const SURVEY_BY_ID_QUERY = gql`
  ${SURVEY_TYPE}
  query SurveyById($id: ID!) {
    SurveyById(id: $id) {
      ...SurveyType
    }
  }
`;


export const SURVEY_BY_ID_ADMIN_PANEL_QUERY = gql`
  ${GROUP_TYPE_CONNECTION}
  query SurveyById($id: ID!) {
    SurveyById(id: $id) {
      id
    name
    description
    termsConditions
    shuffle
    public
    activeFrom
    activeTo
    reportQuery
    multisourceResponse
    organization{
      edgeCount
      edges {
        node {
          id
          name
          description
          email
          logo
          termsConditions
          allowComparison
        }
      }
    }
    
    vertical{
      edgeCount
      edges {
        node {
          id
          name
          organization{
            id
            name
          }
        }
      } 
    }

    team{
      edgeCount
      edges {
        node {
          id
           id
           name
          description
          vertical{
            id
            organization{
              id
              name
            }
          }
        }
      }
    }
    groupSet {
      ...GroupTypeConnection
    }
    }
  }
`;

export const SURVEY_BY_ID_GROUP_SET_QUERY = gql`
  ${SURVEY_TYPE_GROUP_SET}
  query SurveyById($id: ID!,$responseUser: ID!) {
    SurveyById(id: $id) {
      ...SurveyType
    }
  }
`;

export const SURVEY_BY_ID_VALUES_QUERY = gql`
  ${SURVEY_TYPE_GROUP_SET_VALUES_FIRST}, ${SURVEY_TYPE_GROUP_SET_VALUES_LAST}
  query SurveyById($id: ID!, $responseUser: ID!) {
    surveyChoices: SurveyById(id: $id) {
        ...SurveyTypeFirst
    }
    surveyAnswers: SurveyById(id: $id) {
        ...SurveyTypeLast
    }
  }
`;



export const SURVEY_BY_ID_VALUES_SUBSCRIPTION = gql`
${SURVEY_TYPE_GROUP_SET_VALUES_LAST}
subscription surveySubscription {
  surveySubscription {
    mutation 
    survey {
       ...SurveyTypeLast
    }
  }
}`

export const SURVEY_BY_ID_VALUES_DISPLAy_QUERY = gql`
  query SurveyById($id: ID!, $responseUser: ID!) {
    surveyAnswers: SurveyById(id: $id) {
      id
      name
      groupSet(last:1) {
        edges {
          node {
            id
            questionSet {
              edges {
                node {
                  id
                  answerSet(response_Survey:$id,response_User: $responseUser,first:1) {
                    edges {
                      node {
                        id
                        answer
                        response {
                          id
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;