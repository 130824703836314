import gql from "graphql-tag";

export const CHECK_REPORTEE = gql`
query check_reportee($membersbase64:[ID],$membersint:[ID]){
    reportee:  me {
        id
        employee {
          id
          teamMembersEmployee(managers: $membersbase64) {
            totalCount
          }
          verticalMembersEmployee(heads: $membersbase64) {
            totalCount
          }
          organizationSet(ceo_In: $membersint) {
            totalCount
          }
        }
    }
}
`;
