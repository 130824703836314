import update from "immutability-helper";

import { REPORTING_TEAMS_MApp_ANCHORS_STATE_QUERY } from "../graphql/ReportingTeamsMAppAnchorsStateQuery.client.gql";

const TYPE_REPORTING_TEAMS_MApp_ANCHORS_STATE =
  "ReportingTeamsMAppAnchorsState";

const TYPE_REPORTING_TEAMS_MApp_ANCHORS_STATE_FILTER =
  "ReportingTeamsMAppAnchorsFilterInput";

const defaults = {
  reportingTeamsMAppAnchorsState: {
    filter: {
      includingManager: false,
      __typename: TYPE_REPORTING_TEAMS_MApp_ANCHORS_STATE_FILTER,
    },
    __typename: TYPE_REPORTING_TEAMS_MApp_ANCHORS_STATE,
  },
};

const resolvers = {
  Mutation: {
    updateReportingTeamsMAppAnchorsFilter: (_, { filter }, { cache }) => {
      const { reportingTeamsMAppAnchorsState } = cache.readQuery({
        query: REPORTING_TEAMS_MApp_ANCHORS_STATE_QUERY,
      });
      const newReportingTeamsMAppAnchorsState = update(
        reportingTeamsMAppAnchorsState,
        {
          filter: { $merge: filter },
        }
      );

      cache.writeData({
        data: {
          reportingTeamsMAppAnchorsState: newReportingTeamsMAppAnchorsState,
          __type: TYPE_REPORTING_TEAMS_MApp_ANCHORS_STATE,
        },
      });

      return null;
    },
  },
};

export default { defaults, resolvers };
