import gql from 'graphql-tag';
import { OBTAIN_JSON_WEB_TOKEN } from './ObtainJSONWebToken.gql';

export const LOGIN = gql`
  ${OBTAIN_JSON_WEB_TOKEN}
  mutation login($password: String!, $email: String, $username: String) {
    tokenAuth(password: $password, email: $email, username: $username) {
      ...ObtainJSONWebToken
    }
  }
`;
