import gql from 'graphql-tag';

export const ALL_EMPLOYEE_LEVEL = gql`
  query allEmployeeLevels($name_Icontains: String) {
    allEmployeeLevels(name_Icontains:$name_Icontains){
        edges{
            node{
                id
                name
            }
        }
    }
  }
`;

export const All_EMPLOYEE_LEVEL_SUBSCRIPTION =gql`
subscription employeeLevelSubscription{
  employeeLevelSubscription {
    mutation
    employeeLevel{
      id
      name
    } 
  }
}
`