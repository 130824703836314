import { graphql } from '@apollo/client/react/hoc';

import { removeTypename } from '../../core';
import lookResolvers from '../resolvers';

import { LOOK_USER_STATE_QUERY } from '../graphql/LookUserStateQuery.client.gql';
import { LOOK_SURVEY_STATE_QUERY } from '../graphql/LookSurveyStateQuery.client.gql';
import { LOOK_ORGANIZATION_STATE_QUERY } from '../graphql/LookOrganizationStateQuery.client.gql';
import { LOOK_VERTICAL_STATE_QUERY } from '../graphql/LookVerticalStateQuery.client.gql';
import { LOOK_TAG_STATE_QUERY } from '../graphql/LookTagStateQuery.client.gql';
import { LOOK_CONDITION_STATE_QUERY } from '../graphql/LookConditionStateQuery.client.gql';
import { LOOK_FORM_SECTION_STATE_QUERY } from '../graphql/LookFormSectionStateQuery.client.gql';
import { LOOK_SIMPLE_FIELD_STATE_QUERY } from '../graphql/LookSimpleFieldStateQuery.client.gql';
import { LOOK_REFERENCE_FIELD_STATE_QUERY } from '../graphql/LookReferenceFieldStateQuery.client.gql';
import { LOOK_TOOL_STATE_QUERY } from '../graphql/LookToolStateQuery.client.gql';
import { LOOK_TEXT_SECTION_STATE_QUERY } from '../graphql/LookTextSectionStateQuery.client.gql';
import { LOOK_WEIGHTED_QUESTION_STATE_QUERY } from '../graphql/WeightedQuestionStateQuery.client.gql';

import { UPDATE_LOOK_USER_FILTER } from '../graphql/UpdateLookUserFilter.client.gql';
import { UPDATE_LOOK_SURVEY_FILTER } from '../graphql/UpdateLookSurveyFilter.client.gql';
import { UPDATE_LOOK_ORGANIZATION_FILTER } from '../graphql/UpdateLookOrganizationFilter.client.gql';
import { UPDATE_LOOK_VERTICAL_FILTER } from '../graphql/UpdateLookVerticalFilter.client.gql';
import { UPDATE_LOOK_TAG_FILTER } from '../graphql/UpdateLookTagFilter.client.gql';
import { UPDATE_LOOK_CONDITION_FILTER } from '../graphql/UpdateLookConditionFilter.client.gql';
import { UPDATE_LOOK_FORM_SECTION_FILTER } from '../graphql/UpdateLookFormSectionFilter.client.gql';
import { UPDATE_LOOK_SIMPLE_FIELD_FILTER } from '../graphql/UpdateLookSimpleFieldFilter.client.gql';
import { UPDATE_LOOK_REFERENCE_FIELD_FILTER } from '../graphql/UpdateLookReferenceFieldFilter.client.gql';
import { UPDATE_LOOK_TOOL_FILTER } from '../graphql/UpdateLookToolFilter.client.gql';
import { UPDATE_LOOK_TEXT_SECTION_FILTER } from '../graphql/UpdateLookTextSectionFilter.client.gql';
import { UPDATE_LOOK_WEIGHTED_QUESTION_FILTER } from '../graphql/UpdateLookWeightedQuestionFilter.client.gql';
import { LOOK_SURVEY_RESPONSE_STATE_QUERY } from '../graphql/LookSurveyResponseStateQuery.client.gql';
import { UPDATE_SURVEY_RESPONSE_FILTER } from 'modules/survey/graphql/UpdateSurveyFilter.client.gql';

export const withLookUserState = Component =>
  graphql(LOOK_USER_STATE_QUERY, {
    props({ data }) {
      const { orderBy, ...rest } = data.lookUserState;
      const lookUserState = { ...removeTypename(rest), orderBy };
      return { ...lookUserState, stateLoading: data.loading };
    }
  })(Component);

export const withLookSurveyState = Component =>
  graphql(LOOK_SURVEY_STATE_QUERY, {
    props({ data }) {
      const { orderBy, ...rest } = data.lookSurveyState;
      const lookSurveyState = { ...removeTypename(rest), orderBy };
      return { ...lookSurveyState, stateLoading: data.loading };
    }
  })(Component);

  export const withLookSurveyResponseState = Component =>
  graphql(LOOK_SURVEY_RESPONSE_STATE_QUERY, {
    props({ data }) {
      console.log('data.lookSurveyResponseState::',data);
      const { orderBy, ...rest } = data.lookSurveyResponseState;
      const lookSurveyResponseState = { ...removeTypename(rest), orderBy };
      return { ...lookSurveyResponseState, stateLoading: data.loading };
    }
  })(Component);



export const withLookOrganizationState = Component =>
  graphql(LOOK_ORGANIZATION_STATE_QUERY, {
    props({ data }) {
      const { orderBy, ...rest } = data.lookOrganizationState;
      const lookOrganizationState = { ...removeTypename(rest), orderBy };
      return { ...lookOrganizationState, stateLoading: data.loading };
    }
  })(Component);

export const withLookVerticalState = Component =>
  graphql(LOOK_VERTICAL_STATE_QUERY, {
    props({ data }) {
      const { orderBy, ...rest } = data.lookVerticalState;
      const lookVerticalState = { ...removeTypename(rest), orderBy };
      return { ...lookVerticalState, stateLoading: data.loading };
    }
  })(Component);

export const withLookTagState = Component =>
  graphql(LOOK_TAG_STATE_QUERY, {
    props({ data }) {
      const { orderBy, ...rest } = data.lookTagState;
      const lookTagState = { ...removeTypename(rest), orderBy };
      return { ...lookTagState, stateLoading: data.loading };
    }
  })(Component);

export const withLookConditionState = Component =>
  graphql(LOOK_CONDITION_STATE_QUERY, {
    props({ data }) {
      const { orderBy, ...rest } = data.lookConditionState;
      const lookConditionState = { ...removeTypename(rest), orderBy };
      return { ...lookConditionState, stateLoading: data.loading };
    }
  })(Component);

export const withLookFormSectionState = Component =>
  graphql(LOOK_FORM_SECTION_STATE_QUERY, {
    props({ data }) {
      const { orderBy, ...rest } = data.lookFormSectionState;
      const lookFormSectionState = { ...removeTypename(rest), orderBy };
      return { ...lookFormSectionState, stateLoading: data.loading };
    }
  })(Component);

export const withLookSimpleFieldState = Component =>
  graphql(LOOK_SIMPLE_FIELD_STATE_QUERY, {
    props({ data }) {
      const { orderBy, ...rest } = data.lookSimpleFieldState;
      const lookSimpleFieldState = { ...removeTypename(rest), orderBy };
      return { ...lookSimpleFieldState, stateLoading: data.loading };
    }
  })(Component);

export const withLookRefereceFieldState = Component =>
  graphql(LOOK_REFERENCE_FIELD_STATE_QUERY, {
    props({ data }) {
      const { orderBy, ...rest } = data.lookReferenceFieldState;
      const lookReferenceFieldState = { ...removeTypename(rest), orderBy };
      return { ...lookReferenceFieldState, stateLoading: data.loading };
    }
  })(Component);

export const withLookToolState = Component =>
  graphql(LOOK_TOOL_STATE_QUERY, {
    props({ data }) {
      const { orderBy, ...rest } = data.lookToolState;
      const lookToolState = { ...removeTypename(rest), orderBy };
      return { ...lookToolState, stateLoading: data.loading };
    }
  })(Component);

export const withLookTextSectionState = Component =>
  graphql(LOOK_TEXT_SECTION_STATE_QUERY, {
    props({ data }) {
      const { orderBy, ...rest } = data.lookTextSectionState;
      const lookTextSectionState = { ...removeTypename(rest), orderBy };
      return { ...lookTextSectionState, stateLoading: data.loading };
    }
  })(Component);

export const withLookWeightedQuestionState = Component =>
  graphql(LOOK_WEIGHTED_QUESTION_STATE_QUERY, {
    props({ data }) {
      const { orderBy, ...rest } = data.lookWeightedQuestionState;
      const lookWeightedQuestionState = { ...removeTypename(rest), orderBy };
      return { ...lookWeightedQuestionState, stateLoading: data.loading };
    }
  })(Component);

export const withLookUserFilterUpdating = Component =>
  graphql(UPDATE_LOOK_USER_FILTER, {
    props: ({ mutate }) => ({
      onFiltersRemove() {
        mutate({ variables: { ...lookResolvers.defaults.lookUserState } });
      },
      onUsernameChange(username_Icontains) {
        mutate({ variables: { filter: { username_Icontains } } });
      },
      onFirstNameChange(firstName_Icontains) {
        mutate({ variables: { filter: { firstName_Icontains } } });
      },
      onLastNameChange(lastName_Icontains) {
        mutate({ variables: { filter: { lastName_Icontains } } });
      },
      onNameChange(nameIcontains) {
        mutate({ variables: { filter: { nameIcontains } } });
      }
    })
  })(Component);

export const withLookSurveyFilterUpdating = Component =>
  graphql(UPDATE_LOOK_SURVEY_FILTER, {
    props: ({ mutate }) => ({
      onFiltersRemove() {
        mutate({ variables: { ...lookResolvers.defaults.lookSurveyState } });
      },
      onNameChange(name_Icontains) {
        mutate({ variables: { filter: { name_Icontains } } });
      },
      onQuestionTextChange(questionText_Icontains) {
        mutate({ variables: { filter: { questionText_Icontains } } });
      },
      onSurveyNameChange(survey_Name_Icontains) {
        mutate({ variables: { filter: { survey_Name_Icontains } } });
      }
    })
  })(Component);

  export const withLookSurveyResponseFilterUpdating = Component =>
  graphql(UPDATE_SURVEY_RESPONSE_FILTER, {
    props: ({ mutate }) => ({
      onFiltersRemove() {
        mutate({ variables: { ...lookResolvers.defaults.lookSurveyResponseState } });
      },
      onSurveyNameChange(survey_Name_Icontains) {
        mutate({ variables: { filter: { survey_Name_Icontains } } });
      }
    })
  })(Component);

export const withLookOrganizationFilterUpdating = Component =>
  graphql(UPDATE_LOOK_ORGANIZATION_FILTER, {
    props: ({ mutate }) => ({
      onFiltersRemove() {
        mutate({ variables: { ...lookResolvers.defaults.lookOrganizationState } });
      },
      onOrganizationNameChange(name_Icontains) {
        mutate({ variables: { filter: { name_Icontains } } });
      }
    })
  })(Component);

export const withLookVerticalFilterUpdating = Component =>
  graphql(UPDATE_LOOK_VERTICAL_FILTER, {
    props: ({ mutate }) => ({
      onFiltersRemove() {
        mutate({ variables: { ...lookResolvers.defaults.lookVerticalState } });
      },
      onVerticalNameChange(name_Icontains) {
        mutate({ variables: { filter: { name_Icontains } } });
      },
      onOrganizationChange(organization) {
        mutate({ variables: { filter: { organization } } });
      }
    })
  })(Component);

export const withLookTagFilterUpdating = Component =>
  graphql(UPDATE_LOOK_TAG_FILTER, {
    props: ({ mutate }) => ({
      onFiltersRemove() {
        mutate({ variables: { ...lookResolvers.defaults.lookTagState } });
      },
      onTagNameChange(name_Icontains) {
        mutate({ variables: { filter: { name_Icontains } } });
      }
    })
  })(Component);

export const withLookConditionFilterUpdating = Component =>
  graphql(UPDATE_LOOK_CONDITION_FILTER, {
    props: ({ mutate }) => ({
      onFiltersRemove() {
        mutate({ variables: { ...lookResolvers.defaults.lookConditionState } });
      },
      onLeftOperandChange(leftOperand) {
        mutate({ variables: { filter: { leftOperand } } });
      }
    })
  })(Component);

export const withLookFormSectionFilterUpdating = Component =>
  graphql(UPDATE_LOOK_FORM_SECTION_FILTER, {
    props: ({ mutate }) => ({
      onFiltersRemove() {
        mutate({ variables: { ...lookResolvers.defaults.lookFormSectionState } });
      },
      onTitleChange(title_Icontains) {
        mutate({ variables: { filter: { title_Icontains } } });
      }
    })
  })(Component);

export const withLookSimpleFieldFilterUpdating = Component =>
  graphql(UPDATE_LOOK_SIMPLE_FIELD_FILTER, {
    props: ({ mutate }) => ({
      onFiltersRemove() {
        mutate({ variables: { ...lookResolvers.defaults.lookSimpleFieldState } });
      },
      onLabelChange(label_Icontains) {
        mutate({ variables: { filter: { label_Icontains } } });
      }
    })
  })(Component);

export const withLookRefereceFieldFilterUpdating = Component =>
  graphql(UPDATE_LOOK_REFERENCE_FIELD_FILTER, {
    props: ({ mutate }) => ({
      onFiltersRemove() {
        mutate({ variables: { ...lookResolvers.defaults.lookRefereceFieldState } });
      },
      onQuestionTextChange(questionTextIcontains) {
        mutate({ variables: { filter: { questionTextIcontains } } });
      }
    })
  })(Component);

export const withLookToolFilterUpdating = Component =>
  graphql(UPDATE_LOOK_TOOL_FILTER, {
    props: ({ mutate }) => ({
      onFiltersRemove() {
        mutate({ variables: { ...lookResolvers.defaults.lookToolState } });
      },
      onTitleChange(titleIcontains) {
        mutate({ variables: { filter: { titleIcontains } } });
      }
    })
  })(Component);

export const withLookTextSectionFilterUpdating = Component =>
  graphql(UPDATE_LOOK_TEXT_SECTION_FILTER, {
    props: ({ mutate }) => ({
      onFiltersRemove() {
        mutate({ variables: { ...lookResolvers.defaults.lookTextSectionState } });
      },
      onNoteChange(note_Icontains) {
        mutate({ variables: { filter: { note_Icontains } } });
      }
    })
  })(Component);

export const withLookWeightedQuestionFilterUpdating = Component =>
  graphql(UPDATE_LOOK_WEIGHTED_QUESTION_FILTER, {
    props: ({ mutate }) => ({
      onFiltersRemove() {
        mutate({ variables: { ...lookResolvers.defaults.lookToolState } });
      },
      onQuestionTextChange(question_QuestionText_Icontains) {
        mutate({ variables: { filter: { question_QuestionText_Icontains } } });
      }
    })
  })(Component);
