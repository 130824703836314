import gql from 'graphql-tag';
import { TAG_TYPE_CONNECTION } from './TagTypeConnection.gql';

export const STORY_TYPE = gql`
  ${TAG_TYPE_CONNECTION}
  fragment StoryType on StoryType {
    id
    author
    banner
    title
    content
    storyTags {
      ...TagTypeConnection
    }
    published
    datecreated
    likes
    thumbnailImage
    commentSet {
      totalCount
    }
  }
`;
