import SurveyComparisonReport from './containers/SurveyComparisonReport';

import ROUTE from './route';

export default [
  {
    name: 'Survey Comparison Report',
    path: ROUTE.surveyComparisonReport,
    component: SurveyComparisonReport,
    protect: true
  }
];
