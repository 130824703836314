import gql from 'graphql-tag';
export const SURVEY_LIST = gql`
query {
surveyList:me{
        id
      employee{
          id
        orgPocEmployee{
          edges{
            node {
              id
              surveySet(showScapeReport:true) {
                edges {
                  node {
                    id
                    name
                  }
                } 
              } 
            } 
          }  
        }
        orgCeoEmployee{ 
          edges{ 
            node { 
              id 
              surveySet(showScapeReport:true) {
                edges {  
                  node {  
                    id  
                    name 
                  } 
                } 
              } 
            }  
          } 
        }
        verticalHeadEmployee{
          edges{ 
            node { 
              id 
              surveySet(showScapeReport:true) { 
                edges { 
                  node { 
                    id 
                    name 
                  } 
                }  
              } 
            }  
          } 
        }
        teamManagerEmployee{ 
          edges{ 
            node {  
              id  
              surveySet(showScapeReport:true) { 
                edges { 
                  node {
                    id
                    name
                  }
                }
              } 
            }  
          } 
        } 
      }  
    }
}
`