import _ from 'lodash';
import React from 'react';

import { compose } from '../../core';
// import { ButtonsCatch, getIntFromString, withMarkdownFieldValue } from '../../look';

import EditCommitmentView from '../components/AddCommitmentView';

import { withAddCoachingCommitment, withEditCoachingCommitment } from '../../dashboard/containers/DashboardOperations';
import { EditIcon, getIntFromString, withMarkdownFieldValue } from '../../look';
import { CKEditorFieldName } from '../components/ToolFormComponent';
import { withEditCommitment /* withSurveyState */ } from './ToolOperations';

const EditCommitment = props => {
  const {
    history,
    editCommitment,
    onSubmit,
    getCommitmentById,
    setSubmitLoading,
    createCoachingCommitment,
    updateCoachingCommitment
  } = props;
  const [visible, setVisible] = React.useState(false);

  const handleSubmit = async (values, condition, coachingCommitment) => {
    setSubmitLoading(true);
    try {
      const response = await editCommitment(
        withMarkdownFieldValue(CKEditorFieldName, { id: getIntFromString(getCommitmentById.id), ...values, condition })
      );
      await Promise.all(
        coachingCommitment.map(async ({ node }) => {
          if (node.id) {
            await updateCoachingCommitment(
              _.omit({
                ...withMarkdownFieldValue(CKEditorFieldName, {
                  ...node,
                  commitmentId: getIntFromString(response.id)
                })
              }),
              'index'
            );
          } else {
            await createCoachingCommitment({
              coachingCommitmentData: _.omit(
                withMarkdownFieldValue(CKEditorFieldName, {
                  ...node,
                  commitmentId: getIntFromString(response.id)
                }),
                'index'
              )
            });
          }
        })
      );
      response && setVisible(false);
      // response && onSubmit(response.id, response);
    } catch (e) {
      throw new Error(e.message);
    }
    setSubmitLoading(false);
  };

  return (
    <>
      <EditIcon type="link" tooltipSuffix="Commitment" size="sm" onClick={() => setVisible(true)} />
      <EditCommitmentView
        title={'Edit Commitment'}
        onSubmit={handleSubmit}
        visible={visible}
        setVisible={setVisible}
        {...props}
      />
    </>
  );
};

export default compose(
 
  withEditCommitment,
  withEditCoachingCommitment,
  withAddCoachingCommitment
)(EditCommitment);
