import gql from "graphql-tag";

export const MILESTONE_BY_KEYRESULT = gql`
query milestones($keyResult:ID!){
    milestones(keyResult:$keyResult){
        edges{
            node{
                id
                title
            }
        }
    }
}
`;
