import React from "react";
import { Row, Col, Button, Input, Radio, Space, Dropdown, Checkbox, Menu, Spin, Modal, Collapse, DatePicker, Tabs, Tooltip, Select } from "antd";
import styled from "styled-components";
import { LeftOutlined, EditOutlined, DeleteOutlined, RightOutlined } from "@ant-design/icons";
import { MetaTags, getIntFromString, scrolltoview, ReactMarkdown } from "../../look";
import ProfileLogo from '../../assets/avatar.jpg';
import AdditionalDetail from './additionalDetailBasicView'
import { FEEDBACK_TOOL_ID } from '../../../config'
import ActionDetailContainer from "../containers/ActionDetailContainer";
import Right_arow from '../../assets/right-arrow-soul.svg'
import Back_icon from '../../assets/soul-back.svg'
import Instant_icon from '../../assets/instant-icon.svg'
import meeting_exapand from '../../assets/meeting-expand-arrow.svg'
import moment from "moment";
import calender_icon from '../../assets/meeting-calender-icon.svg'
import '../css/one-on-one.css'
import { CaretDownOutlined } from "@ant-design/icons";
import AgendaListContainer from "../containers/agendaListContainer";
import ActionItemsListContainer from "../containers/actionItemsListContainer";
import MeetingFeedbackContainer from '../containers/meetingFeedbackContianer'
import Agenda_close from '../../assets/agenda-close.svg'
import right_arrow from '../../assets/right-arrow.svg'
import yellowStart from '../../assets/light-start.svg'
import graystar from '../../assets/gray-light-star.svg'
import message_inactive from '../../assets/message-view-icon.svg'
import Account from '../../assets/Account.svg'

import message_active from '../../assets/message-view-active-icon.svg'
import expand_icon from '../../assets/expand-feedback-icon.svg'
import green_bulb from '../../assets/green_bulb.svg'
import expand_close_icon from '../../assets/close-feedback-icon.svg'
import TranscriptionSummaryContainer from "../containers/TranscriptionSummaryContainer";
const { Panel } = Collapse;
const PreviousMeetingCardView = props => {


  const { me, history, meetingById, remove, createdByMe, selfNotes, actionList, agendaList, allmeetingList, getmeetingById, loading, meetingFeedback, onExpand,permission } = props
  // const [agendaList, setagendaList] = React.useState([]);
  const [feedbackList, setfeedbackList] = React.useState([]);
  const [confirmationModalVisible, setdConfirmationModalVisible] = React.useState(false);
  const [actionData, setActionData] = React.useState();
  const [expandPervious, setExpandPrevious] = React.useState(false)
  const [allowedDates, setAllowedDates] = React.useState([])
  const [defaultDate, setDefaultDate] = React.useState()
  const [selectedAgendaList, setSelectedAgendaList] = React.useState([])
  const [selectedActionList, setSelectedActionList] = React.useState([])
  const [meetingNotes, setMeetingNotes] = React.useState([])
  const [showPreviousMeetings, setShowPreviousMeetings] = React.useState(false)
  const [selectedPreviousMeetingList, setSelectedPreviousMeetingList] = React.useState([])
  const [selectedPreviousDate, setSelectedPreviousDate] = React.useState('')
  const [selectedEmployeeForNotes, setSelectedEmpForNotes] = React.useState();
  const [defaultUser, setDefaultUser] = React.useState();
  const [notesToDisplay, setNotesToDisplay] = React.useState();
  const [summary_tab, setSummaryTab] = React.useState('normal_summary');
  const [myFeedbackCount, setMyFeedbackCount] = React.useState()
  const [managerFeedbackCount, setManagerFeedbackCount] = React.useState()
  const [totalAvg, setTotalAvg] = React.useState()
  const [stars, setStars] = React.useState([1, 2, 3, 4, 5])
  const [feedbackDetailModalVisible, setFeedbackDetailModalVisible] = React.useState(false)
  const [expandFeedback, setExpandFeedback] = React.useState(false)
  const { TabPane } = Tabs;

  React.useEffect(() => {
    if (meetingFeedback) {
      let manager_feeback = meetingFeedback?.edges?.map(({ node }) => node?.hostSkills);
      let manager_avg = (manager_feeback.reduce((a, b) => a + b, 0) / manager_feeback?.length);
      let my_feeback = meetingFeedback?.edges?.map(({ node }) => node?.meetingEffectiveness)
      let my_feedback_avg = (my_feeback.reduce((a, b) => a + b, 0) / my_feeback?.length);
      setMyFeedbackCount(my_feedback_avg)
      setManagerFeedbackCount(manager_avg)
      let total_avg = (my_feedback_avg + manager_avg) / 2
      setTotalAvg(total_avg)


    }
  }, [meetingFeedback])

  React.useEffect(() => {
    if (allmeetingList) {
      let datelist = allmeetingList?.edges?.map(({ node }) => moment(node?.when).format('YYYY-MM-DD'))
      let removeDuplicates = datelist.filter((item,
        index) => datelist.indexOf(item) === index)
      setAllowedDates(removeDuplicates)
      let moments = removeDuplicates.map(d => moment(d))
      var maximumDate = moment.max(moments).format('YYYY-MM-DD')
      if (maximumDate) {
        let meetList = allmeetingList?.edges?.filter(({ node }) => moment(node?.when).format('YYYY-MM-DD') === maximumDate)
        if (meetList?.length) {
          var setObj = new Set();
          let result = meetList?.reduce((acc, item) => {
            if (!setObj?.has(item?.node?.id)) {
              setObj.add(item?.node?.id)
              acc.push(item)
            }
            return acc;
          }, [])
          getmeetingById(result[result?.length - 1]?.node?.id)
        }
      }
      setDefaultDate(maximumDate)
    }
  }, [allmeetingList])

  React.useEffect(() => {
    if (selectedEmployeeForNotes!==undefined) {
      let selectedEmployee = meetingById?.meetingnotesSet?.edges?.filter((element) => getIntFromString(element?.node?.employee?.user?.id) === getIntFromString(selectedEmployeeForNotes?.node?.user?.id));
    
      if (selectedEmployee?.length) {
        let notes = selectedEmployee[0]?.node?.notes
        setNotesToDisplay(notes)
      } else {
        setNotesToDisplay(undefined)
      }
    }

  }, [selectedEmployeeForNotes])

  React.useEffect(() => {
    if (meetingById) {
      let feedbackList = meetingById?.feedback?.edges?.map(({ node }) => node)
      let setFeedback = []
      feedbackList.forEach(element => {

        let data =
        {
          id: element?.id,
          title: element?.feedbackTo?.user?.firstName,
          content: element?.feedbackType,
          navigateTo: null,
          response: element?.response?.answerSet,
          updatedAt: element?.updatedAt
        }
        setFeedback.push(data)
      })
      let list = meetingById?.actionitemSet?.edges?.map(({ node }) => node)
      let actionList = list?.filter(item => !item?.isAgenda)
      let agendaList = list?.filter(item => item?.isAgenda)

      let actionFiltered = actionList?.map((item) => {
        return {
          title: item?.title,
          okrId: item?.okr,
          commitmentId: item?.commitment?.commitment,
          coachingCommitmentId: item?.coachingCommitment?.coachingcommitment,
          commitment: item?.commitment,
          coachingCommitment: item?.coachingCommitment,
          isAgenda: false,
          id: item?.id,
          member: null,
          notes: item?.notes,
          edited: false,
          updatedAt: item?.updatedAt,
          completed:item?.completed
        }
      }
      )


      let setAgenda = []
      agendaList.forEach(element => {

        let data =
        {
          id: element?.id,
          title: element?.title,
          // content:null,
          navigateTo: null,
          notes: element?.notes,
          okrId: element?.okr,
          commitmentId: element?.commitment?.commitment,
          coachingCommitmentId: element?.coachingCommitment?.coachingcommitment,
        }


        setAgenda.push(data)
        let noteslist = meetingById?.meetingnotesSet?.edges?.map(({ node }) => node)
        setMeetingNotes(noteslist)
      });
      // setagendaList(setAgenda)
      setSelectedAgendaList(setAgenda)
      setSelectedActionList(actionFiltered)
      setfeedbackList(setFeedback)
    }
  }, [meetingById])

  React.useEffect(() => {
    if (meetingById) {

      let defaultEmployeeSelected = meetingById?.member?.edges?.filter((element) =>

        getIntFromString(element?.node.user?.id) === getIntFromString(meetingById?.owner?.user?.id)
      );
      setSelectedEmpForNotes(defaultEmployeeSelected[0])
      if (defaultEmployeeSelected?.length) {
        let notes = defaultEmployeeSelected[0]?.notes
        setNotesToDisplay(notes)
      }
    }
  }, [meetingById])



  const closeReportView = (data) => {
    setActionData(null)

  }
  const disabledDate = (current) => {
    //  Disable all dates that are not in the allowedDates array
    return !allowedDates.find(date => date === moment(current).format("YYYY-MM-DD"));
  }

  const handleDateChnage = (date) => {
    if (date) {
      setSelectedActionList([])
      setSelectedAgendaList([])
      setMeetingNotes([])
      let selectedDate = moment(date).format("YYYY-MM-DD")
      setSelectedPreviousDate(selectedDate)
      let meetList = allmeetingList?.edges?.filter(({ node }) => moment(node?.when).format('YYYY-MM-DD') === selectedDate)
      if (meetList?.length) {
        var setObj = new Set();
        let result = meetList?.reduce((acc, item) => {
          if (!setObj?.has(item?.node?.id)) {
            setObj.add(item?.node?.id)
            acc.push(item)
          }
          return acc;
        }, [])
        if (result?.length) {
          setSelectedPreviousMeetingList(result)
          if (result?.length > 1) {
            setShowPreviousMeetings(true)
          }
          else{
            getmeetingById(result[0]?.node?.id)
            setDefaultDate(moment(result[0]?.node?.when).format("YYYY-MM-DD"))
          }
        }
      }
    }


  }



  const ReportViewActionItem = (data) => {

    let list = meetingById?.actionitemSet?.edges?.map(({ node }) => node)
    let detail = actionList.find(item => item.id === data?.id)
    let actionData = list?.find(item => !item?.isAgenda && item?.id === data?.id)
    if (actionData) {
      actionData.completed = detail?.completed ? true : false
      setActionData(actionData)
    }

  }

  return (
    <>
      <Col span={24} align="center">
        {
          actionData && (
            <Modal
              closable={false}
              visible={actionData ? true : false}
              destroyOnClose={true}
              footer={false}
              width='50%'
              maxHeight="80vh"
            >

              <ActionDetailContainer
                actionDetail={actionData}
                editable={true}
                owner={meetingById?.owner}
                {...props}
                onClose={(e) => { closeReportView(e) }}
              />

            </Modal>
          )
        }
        <Modal
          closable={false}
          visible={showPreviousMeetings}
          destroyOnClose={true}
          footer={false}
          centered={true}>
          <div style={{ position: "relative" }}>
            <img src={Agenda_close} alt="" style={{ cursor: "pointer", position: 'absolute', right: "10px", top: "0px" }} onClick={() => { setShowPreviousMeetings(false); setSelectedPreviousDate('') }} />
            <h4 className="previous-meeting-title" style={{ textAlign: "center", fontWeight: "700", marginTop: "10px" }}>List of All meetings in <span style={{ color: "#009AF1" }}>{moment(selectedPreviousMeetingList[0]?.node?.when).format("YYYY-MM-DD")}</span></h4>
            <div className='meeting-overflow agenda-list-container' style={{ width: "100%", maxHeight: "280px", overflow: 'auto', marginTop: "20px", minHeight: "270px" }}>
              {selectedPreviousMeetingList?.length && selectedPreviousMeetingList?.map(({ node }) => (
                <div className="agend-list-div agend-list-div-hover-action" style={{ width: "100%", margin: "10px 0px", cursor: "pointer" }} onClick={() => { getmeetingById(node?.id); setShowPreviousMeetings(false); setDefaultDate(moment(selectedPreviousMeetingList[0]?.node?.when).format("YYYY-MM-DD")) }}>
                  <p className="one-on-one-agenda-title" style={{ textAlign: "left" }}>{node?.title}</p>
                </div>
              ))}
            </div>
          </div>
        </Modal>
        <div>



          <Spin spinning={loading}>


            {meetingById && (
              <Row className="previous-meeting-card-wrapper" justify="center" align="center">
                {!expandPervious && <div className="past-meeting-container" >
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center", gap: "20px" }}>
                    <img className={expandPervious ? "expand-meeting-arrow-up" : "expand-arrow-left"} src={meeting_exapand} alt="" style={{ cursor: "pointer" }} onClick={() => { setExpandPrevious(!expandPervious); onExpand(false) }} />
                    <div style={{ display: "flex", flexDirection: "column", alignItems: 'start', justifyContent: "start", overflow: "hidden", textOverflow: "elipsis", position: "relative", minHeight: "60px" }}>
                      <h4 className="previous-meeting-title" >Previous 1:1 - {meetingById?.title}</h4>
                      {/* <h5 className="previous-meeting-date">12 Jan 2023</h5> */}
                      <div style={{ maxWidth: "130px", display: "flex", alignItems: 'start', justifyContent: "start", left: "0px", position: 'absolute', top: "30px" }}>
                        <DatePicker
                          className="previous-meeting-filter-date-picker"
                          style={{ display: "flex", alignItems: 'start', justifyContent: "start" }}
                          disabledDate={disabledDate}
                          showToday={false}
                          value={selectedPreviousDate !== '' ? moment(selectedPreviousDate) : moment(defaultDate)}
                          allowClear={false}
                          defaultValue={moment(defaultDate)}
                          suffixIcon={<img src={calender_icon} alt="" />}
                          inputReadOnly={true}
                          format={'DD MMM YYYY'}
                          onChange={handleDateChnage}
                        />
                      </div>
                    </div>
                  </div>
                </div>}
                {expandPervious &&
                  <Col span={24} className="past-meeting-container-expand" align="center" style={{ width: "100%", background: " #F5F5F5", border: "1px solid #D9D9D9", borderRadius: "8px" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "start", gap: "20px", width: "100%" }}>
                      <img className={expandPervious ? "expand-meeting-arrow-up" : "expand-arrow-left"} src={meeting_exapand} alt="" style={{ cursor: "pointer", marginTop: "15px" }} onClick={() => { setExpandPrevious(!expandPervious); onExpand(true) }} />
                      <Row className="past-container" justify="space-between" align="center" style={{ width: "100%", flexWrap: "nowrap" }}>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: 'start', justifyContent: "start", width: expandFeedback ? "15%" : "fit-content", maxWidth: meetingFeedback?.totalCount > 0 ? "60%" : "100%", position: "relative" }}>
                          <h4 className="previous-meeting-title" style={{ textAlign: "left", textOverflow: "ellipsis", overflow: "hidden", width: "100%" }} >Previous 1:1 - {meetingById?.title}</h4>
                          <div style={{ maxWidth: "130px", display: "flex", alignItems: 'start', justifyContent: "start", left: "0px", position: 'absolute', top: "30px" }}>
                            <DatePicker
                              style={{ display: "flex", alignItems: 'start', justifyContent: "start" }}
                              className="previous-meeting-filter-date-picker"
                              disabledDate={disabledDate}
                              showToday={false}
                              value={selectedPreviousDate !== '' ? moment(selectedPreviousDate) : moment(defaultDate)}
                              allowClear={false}
                              defaultValue={moment(defaultDate)}
                              suffixIcon={<img src={calender_icon} alt="" />}
                              inputReadOnly={true}
                              format={'DD MMM YYYY'}
                              onChange={handleDateChnage}
                            />
                          </div>
                        </div>
                        {meetingFeedback?.totalCount > 0 && <div>
                          {!expandFeedback ? <Row justify="end" align="center" style={{ gap: "10px" }}>
                            <img src={expand_icon} alt="" style={{ cursor: "pointer" }} onClick={() => setExpandFeedback(true)} />
                            <div className="star-title-past-job"> 1 : 1  Experience  :</div>
                            <Row style={{ marginTop: "2px" }}>
                              <div>
                                {
                                  stars?.map((star, index) =>
                                    <img className="star-rating-effectivness-feedback" style={{ width: "25px", marginBottom: "3px" }} src={((totalAvg / 20) >= (index + 1)) ? yellowStart : graystar} />
                                  )
                                }
                              </div>
                            </Row>
                          </Row> : <Row justify="end" align="center" style={{ width: "100%", flexWrap: 'nowrap', gap: "10px", marginBottom: "20px", marginRight: "10px" }}>
                            <img src={expand_close_icon} alt="" style={{ cursor: "pointer", width: "20px", zIndex: 3 }} onClick={() => setExpandFeedback(false)} />
                            <Col style={{ display: "flex", flexWrap: "nowrap", alignItems: "center", gap: "10px" }}>
                              <div className="star-title-past-job" > Your 1:1 Experience Rate  :</div>
                              <Row>
                                <div>
                                  {
                                    stars?.map((star, index) =>
                                      <img className="star-rating-effectivness-feedback" style={{ width: "25px", marginBottom: "3px" }} src={((myFeedbackCount / 20) >= (index + 1)) ? yellowStart : graystar} />
                                    )
                                  }

                                </div>
                              </Row>
                            </Col>
                            <Col style={{ display: "flex", flexWrap: "nowrap", alignItems: "center", gap: "10px" }}>
                              <div className="star-title-past-job"> Manager’s  1:1 Skill Rate :</div>
                              <Row>
                                <div>
                                  {
                                    stars?.map((star, index) =>
                                      <img className="star-rating-effectivness-feedback" style={{ width: "25px", marginBottom: "3px" }} src={((managerFeedbackCount / 20) >= (index + 1)) ? yellowStart : graystar} />
                                    )
                                  }

                                </div>
                              </Row>
                            </Col>
                            <img style={{ cursor: "pointer", width: "25px", zIndex: 3 }} src={message_active} alt="" onClick={() => setFeedbackDetailModalVisible(true)} />
                          </Row>}
                        </div>}
                        {expandFeedback ? (<div className='overlay-dynamic-island' onClick={() => setExpandFeedback(false)} />) : null}
                      </Row>
                    </div>
                    <Row justify="center" align="center" gutter={20} style={{ width: "100%", marginTop: "20px" }}>
                      <Col lg={12} md={24} sm={24} xs={24} style={{ marginTop: "10px" }}>
                        <div className="past-meeting-data-container one-on-one-tab one-on-one-meeting-tab" >
                          <Tabs defaultActiveKey="agenda" centered={true}>
                            <TabPane tab="AGENDA" key="agenda">
                              <AgendaListContainer {...props} addbutton={true} agendaList={selectedAgendaList} meetingByIdData={meetingById} noAddOption={true} noMenu={true} />
                            </TabPane>
                            {
                              permission?.feedback_permission && (
                                <TabPane tab="FEEDBACK" key="feedback">
                                <MeetingFeedbackContainer {...props} feedbackList={feedbackList} meetingByIdData={meetingById} noAddOption={true} noMenu={true} />
                              </TabPane>
                              )
                            }
                           
                            <TabPane tab="ACTION ITEM" key="action-item">
                              <ActionItemsListContainer  {...props} addbutton={true} actionList={selectedActionList} meetingByIdData={meetingById} noAddOption={true} noMenu={true}statusChange={true} checkSub={true}/>
                            </TabPane>
                          </Tabs>
                        </div>
                      </Col>


                      <Col lg={6} md={6} sm={24} xs={24} style={{ marginTop: "10px", paddingRight: '13.5px' }}>
                        <div className="past-meeting-data-container meeting-tab" >


                          {

                            // (selfNotes?.length) &&
                            <div className="notes-container-card" >
                              <Dropdown className="meeting-notes-dropdown-prev-meeting" overlay={
                                <Menu >
                                  {meetingById?.member?.edges?.map((member, key) => (
                                    <Menu.Item key="thisWeek" onClick={(e) => { setSelectedEmpForNotes(member) }}>{member?.node?.user?.firstName} {member?.node?.user?.lastName}</Menu.Item>

                                  ))}

                                </Menu>} placement="bottomCenter">
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "5px" }}>
                                  <h4 className="past-meeting-data-title" style={{ padding: "10px 0px 0px 0px", marginBottom: '0px' }}>{`${selectedEmployeeForNotes?.node?.user?.firstName}'s notes`}</h4>
                                  <div style={{ paddingTop: '10px' }}>
                                    <CaretDownOutlined />
                                  </div>

                                </div>

                              </Dropdown>
                            </div>
                          }


                          <div className="past-meeting-data-overflow">
                            {/* <Select > */}
                            {/* { */}
                              
                              {/* (notesToDisplay!==undefined) ? */}
                                <div>


                                  {
                                    notesToDisplay ? <p className="meeting-detail-para"><ReactMarkdown>{notesToDisplay}</ReactMarkdown></p> : <h4 className='no-data pt-100'>No Notes Found</h4>

                                  }

                                </div>
                                {/* : <div style={{ justifyContent: "center", alignItems: "center", display: "flex", width: "100%" }}>
                                  <h4 className='no-data pt-100' style={{ color: '#BFBFBF' }}>No Notes Found</h4>
                                </div>} */}
                          </div>
                        </div>
                      </Col>
                      <Col lg={6} md={12} sm={24} xs={24} style={{ marginTop: "10px" }} >
                        <div className="past-meeting-data-container meeting-tab" style={{ padding: '10px' }} lg={6} md={10} sm={24} xs={24} >

                          {
                            summary_tab === 'normal_summary' && (
                              <>
                                <h4 className="past-meeting-data-title" style={{ color: "#31D0AA", padding: "0px 0px", marginBottom: '0px' }}>SUMMARY</h4>
                                <Row justify="center" align="middle" style={{ gap: '5px', marginBottom: '20px' }}>
                                  <div align='center' style={{ cursor: "pointer" }} onClick={(e) => setSummaryTab('transcription_summary')} className="transcript-link">Show full transcript</div>
                                  <img onClick={(e) => setSummaryTab('transcription_summary')} style={{ cursor: "pointer" }} src={right_arrow} />
                                </Row>
                                <div className="past-meeting-data-overflow" style={{ marginRight: '5px' }}  >
                                  {
                                    meetingById?.summary ?
                                      <p style={{ padding: '8px 15px 0px 10px' }} className="meeting-detail-para">{meetingById?.summary}</p>
                                      : <h4 className="no-data pt-75" style={{color:"rgb(191, 191, 191)"}}>No Summary Found</h4>
                                  }
                                </div>
                              </>
                            )
                          }

                          {
                            summary_tab === 'transcription_summary' && (
                              <>
                                <h4 className="past-meeting-data-title" style={{ color: "#31D0AA", padding: "0px 0px", marginBottom: '0px' }}>Transcript</h4>
                                <Row justify="center" align="middle" style={{ gap: '5px', marginBottom: '20px' }}>
                                <img onClick={(e) => setSummaryTab('normal_summary')} className="summary-arrow" src={right_arrow} />
                                  <div align='center' style={{ cursor: "pointer" }} onClick={(e) => setSummaryTab('normal_summary')} className="transcript-link">Show summary</div>
                                  
                                </Row>
                                <TranscriptionSummaryContainer {...props} />
                              </>
                            )
                          }

                        </div>
                      </Col>
                    </Row>
                  </Col>}
              </Row>
            )
            }
          </Spin>
        </div>
      </Col>
      <Modal
        className="feedback-details-modal"
        visible={feedbackDetailModalVisible}
        footer={null}
        onCancel={(e) => setFeedbackDetailModalVisible(false)}
        height={40}
        width={650}
      >

        <Spin spinning={meetingFeedback ? false : true}>
          <Col style={{ position: 'relative', top: '2em', fontFamily: 'poppins' }}>
            <Row justify="space-between" >
              <Row align="bottom" className="modal-feedback-header-for-user" wrap={false}>
                <img src={Account} />
                <div className="modal-user-name"> {`${meetingFeedback?.edges[0]?.node?.member?.user?.firstName}` + ' ' + `${meetingFeedback?.edges[0]?.node?.member?.user?.lastName}`} </div>
              </Row>

              <div className="modal-feedback-date" style={{ paddingRight: "15px" }}>{moment(meetingFeedback?.edges[0]?.node?.createdAt).format('DD-MMM-YYYY')}</div>
            </Row>

            <Col style={{ paddingLeft: "5px" }}>
              <div className="modal-section-header">1:1 experience</div>
              <Row>
                <div>
                  {
                    stars?.map((star, index) =>
                      <img className="star-rating-effectivness-feedback" src={((myFeedbackCount / 20) >= (index + 1)) ? yellowStart : graystar} />
                    )
                  }

                </div>


              </Row>
            </Col>

            <Col style={{ paddingLeft: "5px" }}>
              <div className="modal-section-header">your managers skills</div>
              <Row>
                <div>
                  {
                    stars?.map((star, index) =>
                      <img className="star-rating-effectivness-feedback" src={((managerFeedbackCount / 20) >= (index + 1)) ? yellowStart : graystar} />
                    )
                  }
                </div>
              </Row>
            </Col>

            <Col style={{ marginBottom: "20px", paddingLeft: "5px" }}>
              <div className="modal-section-header">Feedback Notes: </div>
              {meetingFeedback?.edges?.map(({ node }) => (
                <div className="feedback-note" style={{ paddingBottom: "0px" }}>{node?.suggestions}</div>
              ))}

            </Col>
          </Col>
        </Spin>

      </Modal>
    </>
  )

}
export default PreviousMeetingCardView;