// @ts-nocheck
import { Col,Row, Spin, Tooltip,Radio } from "antd";
import DoughntChartCascaded from "modules/look-v2/Components/DoughnutchartCascaded";
import DoughntChartNonCascaded from "modules/look-v2/Components/DoughnutChartNonCascaded";
import PerformanceBar from "modules/look-v2/Components/PerformanceBar";
import React from "react";
import InfoCircle from '../../assets/InfoCircle.svg'
import styled from "styled-components";
import MembersList from "modules/look-v2/Components/TeamMemberList";
import PaginationDropdown from "modules/look-v2/Components/PaginantionDropdowm";
import { MetaTags } from "modules/look";
const TeamsDashboardView =(props)=>{
    const {dateRangeTofilter,getOkrPerformanceData,cascadedOkr,getAllCascadedObjectives,NoncascadedOkr,getAllNonCascadedObjectives,PerformanceData,teamData,teamDetail,cascadLoding,NoncascadLoding,progressLoding,teamLoding,}=props
    const [performance,setPerformance]=React.useState([])
    const [memberPerformance,setMemberPerformance]=React.useState([])
    const [TeammemberList,setTeamMembersList]=React.useState([])
    const [PerformanceTab, setPerformanceTab] = React.useState('Team');

    let current_result_count = React.useRef({
        Team:5,
        Memebers:5
      })
      let member= React.useRef()
      React.useEffect(()=>{
        if(teamDetail && dateRangeTofilter){
          member['current']=null
          current_result_count['current']={
            Team:5,
            Memebers:5
          }
          setMemberPerformance(null)
          setPerformanceTab('Team')
        }
      },[teamDetail,dateRangeTofilter])
  
    React.useEffect(()=>{
        let performamceData=[]
        
        if(PerformanceData){
            performamceData= PerformanceData?.edges.map(({node})=>(
                {
                    id:node?.okr?.id,
                    title:node?.okr?.title,
                    progress:node?.progress,
                    okrType:node?.okr?.okrType,
                    isOkr:true
                }
            ))
            setPerformance(performamceData)
            
        }
    },[PerformanceData])
    
    React.useEffect(()=>{
        let teamMembers=[]
        if(teamData){
            let empData = teamData?.employees?.edges?.map(({node})=>(
                {   
                    id:node?.id,
                    empName:node?.user?.firstName+" "+node?.user?.lastName,
                    progress:node?.okrContributorSet?.edges?.map(({node})=>node?.progress),
                    profileImage:node?.user?.profile?.profileImage
                }))
               
                teamMembers=empData?.map((item)=>(
                    {
                        id:item?.id,
                        name:item?.empName,
                        progress:CheckNumber(item?.progress.reduce((a, b) => a + b, 0)/item?.progress?.length),
                        profileImage:item?.profileImage
                    }
                ))

                setTeamMembersList( teamMembers)
                if(!member?.current){
                    member.current=teamMembers
                    memberBarChart(5)
                }
                
        }
       
    },[teamData])

    const memberBarChart=(edgeCount)=>{
        current_result_count['current'].Memebers=edgeCount
        let data ={
            edgeCount:member?.current?.slice(0,edgeCount)?.length,
            totalCount:member?.current?.length,
            edges:member?.current?.slice(0,edgeCount)?.map(item=>{return {
                title:item?.name,
                id:item?.id,
                progress:Number(item?.progress)
            }})
        }
        setMemberPerformance(data)
    }

const CheckNumber=(number)=>{
    if(isNaN(number)){
        return 0
    }
    else{
        if(Number.isInteger(number)){
            return number
        }
        else{
            return number.toFixed(2)
        }
    }
}   
    const changeTab=(event)=>{
        setPerformanceTab(event?.target?.value)
    }

    const filterData=(first)=>{
        if(PerformanceTab==='Team'){
            getOkrPerformanceData(first,0,false)
            current_result_count['current'].Team=first
        }else{
            memberBarChart(first) 
        }
        
      }

    return(
        <>
        <MetaTags title="OKR" description="OKR Home" />
        <Row align="center" className='doughnutChartsWrapper' style={{gap:"30px"}}>
            <Col lg={11} className="doughnut-chart-container">
                <Spin spinning={cascadLoding}>
                    <h4 className="chart-title">Cascaded Objectives 
                        <Tooltip title={'Cascaded Objectives'}>
                            <img src={InfoCircle} alt="" style={{cursor:"pointer",marginLeft:"10px"}} />
                        </Tooltip>
                    </h4>
                    <Row align="middle" style={{width:"100%",flexDirection:"column"}}>
                        <DoughntChartCascaded
                            {...props}
                            topValue = '135px'
                            rightValue = '92px'
                            fontSize = '14px'
                            responsiveFontSize = '11px'
                            responsiveTopValue = '100px'
                            responsiverightValue = '67px'
                            getNextSetOfCascadedOkrs={(e,offset)=>getAllCascadedObjectives(e,offset)}
                            label={'Cascaded Objectives'}
                            data={cascadedOkr}
                            managerids={teamDetail?.managers?.edges?.map(({node})=>node?.id)||[]}
                            loading={cascadLoding}
                        />
                    </Row>
                </Spin>
            </Col>
            <Col lg={11} className="doughnut-chart-container" style={{display:"row",justifyContent:'center',alignItems:"center"}}>
                <Spin spinning={NoncascadLoding}>
                    <h4 className="chart-title">Non-Cascading Objectives  
                        <Tooltip title={'Non-Cascading Objectives'}>
                        <img src={InfoCircle} alt="" style={{cursor:"pointer",marginLeft:"10px"}} />
                        </Tooltip>
                    </h4>
                    <Row align="middle" style={{width:"100%",flexDirection:"column"}}>
                        <DoughntChartNonCascaded
                            {...props}
                            topValue = '135px'
                            rightValue = '92px'
                            fontSize = '14px'
                            responsiveFontSize = '11px'
                            responsiveTopValue = '100px'
                            responsiverightValue = '66px'
                            getNextSetOfNonCascadedOkrs = {(e,offset)=> getAllNonCascadedObjectives(e,offset)}
                            label={'Non cascaded Objectives'}
                            data={NoncascadedOkr}
                            managerids={teamDetail?.managers?.edges?.map(({node})=>node?.id)||[]}
                            loading={NoncascadLoding}
                        />
                    </Row>
                </Spin>
            </Col>
        </Row>
        <Row align="center" className='graphs-wrapper' style={{gap:"30px",width:'100%',display:"flex",flexDirection:"row",marginTop:"20px"}}>
            <Col lg={14} className="chart-container" >
                <Spin spinning={progressLoding}>
                    <OkrPerformanceWrapper>
                        <h4 className="chart-title" style={{textAlign:"left"}}>{teamDetail?.name}’s OKR Performance</h4>
                        <Row justify="space-between" style={{margin:"20px 0px"}}>
                            {
                                memberPerformance?.edges?.length?
                                <Col>
                                <Radio.Group value={PerformanceTab} onChange={(e) => changeTab(e)}>
                                    <RadioButton style={{borderRight:"none",width:"80px",fontFamily: 'Manrope'}} className={`section-assign ${PerformanceTab==='Team'?'active-assign':''}`} value="Team">Team</RadioButton>
                                    <RadioButton style={{width:"100px",fontFamily: 'Manrope'}}  className={`section-collab ${PerformanceTab ==='Memebers'?'active-collab':''}`} value="Memebers">Members</RadioButton>
                                </Radio.Group>
                            </Col>:<div style={{width:'5px',height:"5px"}}></div>
                            }
                           
                         
                             {(PerformanceTab==='Team' &&PerformanceData?.totalCount)?<PaginationDropdown current={current_result_count['current'].vertical} onselect={(e)=>{filterData(e)}} totalCount={PerformanceData?.totalCount}/>:''}
                            {(PerformanceTab==='Memebers' && memberPerformance?.totalCount)?<PaginationDropdown current={current_result_count['current'].team} onselect={(e)=>{filterData(e)}} totalCount={memberPerformance?.totalCount}/>:''}
                        </Row>
                        {PerformanceTab==='Team'&&<PerformanceBar {...props} performanceData={performance} loading={progressLoding}/>}
                        {PerformanceTab==='Memebers'&&<PerformanceBar {...props} performanceData={memberPerformance?.edges} loading={teamLoding}/>}
                    </OkrPerformanceWrapper>
                </Spin>
            </Col>
            <Col lg={8} className="chart-container"  style={{padding:"40px 20px"}}>
                <Spin spinning={teamLoding}>
                    <h4 className="chart-title">Team Members</h4>
                    <MembersList TeammemberList={TeammemberList} {...props}/>
                </Spin>
            </Col>
        </Row>
        </>
    )
}
const RadioButton = styled(Radio.Button)`
`;
export default TeamsDashboardView

const OkrPerformanceWrapper = styled.div`
min-height:350px;
padding:20px 30px 20px 70px;


@media only screen and (max-width: 768px) {
    padding:20px;
  }

`;
