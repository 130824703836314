import * as React from 'react';

import { compose } from '../../core';

import ForgotPasswordView from '../components/ForgotPasswordView';
import { withPasswordResetMail, withPasswordReset } from './UserOperations';
import { message } from 'antd';
const ForgotPassword = props => {
  const { passwordResetMail, passwordReset } = props;
  const handleForgotPasswordMail = async email => {
    try {
      const response = await passwordResetMail(email);
      return response;
    } catch (e) {
      // throw new Error(e.message);
      return { success: false };
    }
  };

  const handleForgotPassword = async values => {
    try {
      const response = await passwordReset(values);
      if(response?.errors){
        message.destroy();
        let error_list = []
        if(response?.errors?.nonFieldErrors?.length){
          error_list = response?.errors?.nonFieldErrors?.map(i=>i?.message)
        }
        if(response?.errors?.newPassword2?.length){
          error_list = error_list?.concat(response?.errors?.newPassword2?.map(i=>i?.message))
        }
        let message_text = error_list[1]? error_list[1] : error_list[0]
        message.error(message_text || 'failed to reset password')
      }
      return response;
    } catch (e) {
      // throw new Error(e.message);
      return { success: false };
    }
  };


  return (
    <ForgotPasswordView
      onForgotPasswordMailSubmit={handleForgotPasswordMail}
      onForgotPasswordSubmit={handleForgotPassword}
      {...props}
    />
  );
};

export default compose(withPasswordResetMail, withPasswordReset)(ForgotPassword);
