import gql from 'graphql-tag';

export const DASHBOARD_STATE_QUERY = gql`
  query dashboardState {
    dashboardState @client {
      pagination {
        first
        offset
      }
      orderBy
      filter {
        # name_Icontains
        __typename
      }
    }
  }
`;
