import gql from 'graphql-tag';

export const CREATE_ACTION_ITEM =gql`
    mutation createActionItem($actionItemData: ActionItemInput!){
        createActionItem(actionItemData:$actionItemData){
            actionItem{
                id
            }
        }
    }
`