import React, { useState, useEffect } from 'react';
import { Row, Col, Spin, Divider, Button, Space, Modal } from 'antd';
import { withApollo } from '@apollo/client/react/hoc'
import { compose } from 'modules/core'
import { getIntFromString, ReactMarkdown } from '../../look';
import { RenderFormSection } from '../../tool/components/generic-tool';
import styled from "styled-components";
import left_arrow from '../../assets/bulb-icon.svg'
import close_arrow from '../../assets/close-arrow.svg'
import { TOOL_BY_ID_OPTIMIZED } from 'modules/tool/graphql/ToolByidOptimized.gql';

const SideToolScapeView = props => {
  const { loading, admin = false, refetch, client, tool_id } = props;

  const [items, setItems] = useState([]);
  const [widthOfContainer, setWidthOfContainer] = useState(undefined)
  const [containerBorder, setContainerBorder] = useState(undefined)
  const [sideAction, setSideAction] = React.useState(false)
  const [getToolById, SetgetToolById] = React.useState()

  React.useEffect(() => {
    if (tool_id) {
      withToolById(tool_id)
    }
  }, [tool_id])

  const withToolById = async (value) => {
    let id = value;
    const { data } = await client.query({
      query: TOOL_BY_ID_OPTIMIZED,
      variables: { toolId: Number(id) },
      fetchPolicy: 'network-only'
    });
    if (data?.getToolById) {
      SetgetToolById(data?.getToolById)
    }

  }

  useEffect(() => {
    if (getToolById)
      setItems([
        ...(getToolById?.textsection?.edges?.map(({ node }) => ({ ...node, type: 'textsection' })) || []),
        ...(getToolById?.formsection?.edges?.map(({ node }) => ({ ...node, type: 'formsection' })) || [])
      ]);
  }, [getToolById]);

  useEffect(() => {
    setWidthOfContainer(props.widthOfContainer || 20)
    setContainerBorder(props.containerBorder == undefined ? '1px solid #EBEBEB' : props.containerBorder)
  }, [props]);

  const RenderTextSection = ({ textSection, idx }) => {
    return (
      <Row justify='center' align='middle' style={{ flexDirection: 'column', gap: '10px' }}>
        <Col span={24}>
          {/* Dont't show divider in back to back textsections */}
          {/* {props?.hideTopDivider && idx===0?'':
            <div>{items[idx - 1]?.type !== 'textsection' && <Divider />}</div>
            } */}
          {/* <ReactMarkdown>{textSection.text}</ReactMarkdown> */}
          <ReactMarkdown>{textSection.renderedText || '<p></p>'}</ReactMarkdown>

          {/* Dont't show divider in back to back textsections */}
          {/* {items[idx + 1]?.type !== 'textsection' && <Divider />} */}
        </Col>
      </Row>
    );
  };

  return (
    <>
      {
        getToolById && (
          <div >

            <Button className='side_tool_views' style={{ background: "#009AF1", position: "fixed", right: "0", top: '12em', border: "none", height: "50px", width: "50px", borderTopLeftRadius: "6px", borderBottomLeftRadius: "6px", cursor: "pointer", zIndex: 1000 }} onClick={() => setSideAction(true)}><img src={left_arrow} className="bulb-icon" alt="" height='25px' /></Button>
            {sideAction && (
              <Modal
                className='side-modal custom-side-modal'
                visible={sideAction}
                closable={false}
                footer={false}
                style={{ position: "fixed", right: "-20px", top: '10em', maxWidth: "500px" }}>
                <SideButton className='tool-close-btn-icon' onClick={() => setSideAction(false)}><img src={close_arrow} alt="" height='20px' /></SideButton>
                {/* <Button className='custom-close-btn' style={{background: "#009AF1",position:"fixed",right:"460px",border:"none",height:"40px",width:"40px",borderRadius:"6px"}} onClick={()=>setSideAction(false)}><img src={close_arrow} alt="" height='20px'/></Button> */}
                <div>
                  {!loading && (
                    <Row style={{ marginTop: '10px' }} justify="center">
                      {items
                        .sort((a, b) => (a.sequence < b.sequence ? -1 : a.sequence > b.sequence ? 1 : 0))
                        .map((section, idx) =>
                          section.type === 'textsection' ? (
                            <Col span={23} align="left">
                              <RenderTextSection customClass={'custom-text-render'} textSection={section} idx={idx} />
                            </Col>
                          ) : (
                            <Col span={24} style={{ backgroundColor: (2 * idx + 1) % 2 !== 0 && '#8080801c', paddingTop: '10px' }}>
                              <Row justify="center">
                                <Col span={23} align="left">
                                  <RenderFormSection
                                    admin={admin}
                                    formSectionId={getIntFromString(section.id)}
                                    formSection={section}
                                    refetch={refetch}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          )
                        )}
                    </Row>
                  )}
                </div>
              </Modal>
            )

            }
          </div>
        )
      }
    </>
  );
};

export default compose(withApollo)(SideToolScapeView);
const CustomTool = styled(Col)`
::-webkit-scrollbar {
  height: 0px;
  width: 5px;
  padding-right:10px
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  border-radius: 10px;
  padding-right: 10px;
  margin:10px 5px;
  margin-right:5px
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color:#D6D6D6;
}`
const SideButton = styled(Button)`

background: #009AF1;
position:fixed;
right:460px;
border:none;
height:40px;
width:40px;
border-radius:6px;
`