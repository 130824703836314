import { Button, Form, Input } from "antd";
import React from "react";

const CreateEducationView = ( props ) => {

    const { onSubmit,onCancel } = props

    return (
        <div className="rd-file-upload-container" style={{ position: "relative" }}>
            <h4 className='rd-input-title' style={{ marginTop: "1em",marginBottom:"1em",textAlign:'center',fontSize:"1.5em" }}>Add Education Details</h4>
            <Form
                layout="vertical"
                onFinish={values => onSubmit( values )}
                scrollToFirstError={true}
                className='rd-align-center '
                style={{ gap: '1em' }}

            >
                <div className='rd-input-container'>
                    <h4 className='rd-input-title'><span className='rd-input-star'>*</span>Stream</h4>
                   
                        <Form.Item
                            name="stream"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input stream',
                                },
                            ]}
                            style={{ width: '100%' }}
                        >
                            <Input className="rd-text-input" placeholder='Enter Stram' />
                        </Form.Item>
                </div>
                <div className='rd-input-container'>
                    <h4 className='rd-input-title'><span className='rd-input-star'>*</span>Degree</h4>
                   
                        <Form.Item
                            name="degree"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input degree',
                                },
                            ]}
                            style={{ width: '100%' }}
                        >
                            <Input className="rd-text-input" placeholder='Enter degree' />
                        </Form.Item>
                </div>
                <div className='rd-input-container'>
                    <h4 className='rd-input-title'><span className='rd-input-star'>*</span>Subject</h4>
                   
                        <Form.Item
                            name="subject"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input subject',
                                },
                            ]}
                            style={{ width: '100%' }}
                        >
                            <Input className="rd-text-input" placeholder='Enter subject' />
                        </Form.Item>
                </div>
                <div className='rd-confirmation-action-container'>
                    <Button className='global-submit-btn' htmlType="submit">Save</Button>
                    <Button className='global-cancel-btn' onClick={() =>onCancel()}>Cancel</Button>
                </div>
            </Form>
        </div>
    )

}
export default CreateEducationView
