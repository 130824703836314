import NoPermissionView from 'modules/kudos-user/component/NoPermissionComponent';
import { globalPermissionValidator } from 'modules/look';
import user from 'modules/user';
import { okr_permission } from 'Permissions/okr.permission';
import React from 'react';
import { compose } from '../../core';
import OKRHome from '../components/OKRHomeView';
import { getUserRole } from './objective-keyresult-operations';

const OkrHomeContainer = props => {

  const { userPermission, permissionLoader } = props;
  const [okrPermitted, setOkrPermitted] = React.useState({});
  const [ai_trigger,SetAITrigger] = React.useState()
  React.useEffect(() => {
    if (userPermission && !permissionLoader) {
      let okrPermission = globalPermissionValidator(okr_permission, userPermission)
      setOkrPermitted(okrPermission);
    }
  }, [userPermission, permissionLoader])


  return (
    <>
      {
        !permissionLoader ?
          <div>
            {
              (okrPermitted?.requried_permission) ?
                <OKRHome 
                {...props}
                ai_trigger={ai_trigger}
                SetAITrigger={SetAITrigger}
                permission={okrPermitted}
                ></OKRHome>
                :
                <NoPermissionView />
            }
          </div>
          :
          null
      }

    </>
  );
};

export default compose(getUserRole)(OkrHomeContainer);
