import gql from 'graphql-tag';
import { EMPLOYEE_TYPE } from './EmployeeType.gql';

export const EMPLOYEE_BY_ID_QUERY = gql`
  ${EMPLOYEE_TYPE}
  query employeeById($id: ID!) {
    employeeById(id: $id) {
      ...EmployeeType
    }
  }
`;
