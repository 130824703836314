import gql from 'graphql-tag';

export const GET_BADGE_FAMILY =gql`
    query BadgeFamily($title_Icontains: String,$organization_Isnull:Boolean,$organization:ID){
        BadgeFamily(title_Icontains:$title_Icontains,organization_Isnull:$organization_Isnull,organization:$organization){
            pageInfo{
              hasNextPage
            }
            totalCount
            edgeCount
            edges{
              node{
                id
                title
                description
                organization{
                  id
                }
                badgeSet{
                  edges{
                    node{
                      id
                      title
                      behaviouralDescription
                      image
                      duration
                    }
                  }
                }
              }
            }
          }
    }
`