import gql from 'graphql-tag';
import { COMMITMENT_TYPE } from './CommitmentType.gql';

export const COMMITMENT_BY_ID_QUERY = gql`
  ${COMMITMENT_TYPE}
  query getCommitmentById($id: ID!) {
    getCommitmentById(id: $id) {
      ...CommitmentType
    }
  }
`;
