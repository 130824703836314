import gql from 'graphql-tag';
// import { USER_TYPE } from '../../user/graphql/UserType.gql';
// import { COMMITMENT_TYPE } from './CommitmentType.gql';
// import { CUSTOM_USER_COMMITMENT_TYPE } from './CustomUserCommitmentType.gql';

// ${USER_TYPE}, ${COMMITMENT_TYPE}, ${CUSTOM_USER_COMMITMENT_TYPE}
export const COACHING_COMMITMENT_TYPE = gql`
  fragment CoachingCommitmentType on CoachingCommitmentType {
    id
    title
    description
    commitment {
      # ...CommitmentType
      id
      title
    }
    # customusercommitment {
    #   ...CustomUserCommitmentType
    # }
    # customizedByUser {
    #   ...UserType
    # }
    dateCreated
  }
`;
