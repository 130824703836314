import { graphql } from '@apollo/client/react/hoc';
import { message } from 'antd';

import { removeTypename } from '../../core/clientStorage';
import { getStoryBase64StringFromInt, getTagBase64StringFromInt } from '../../look';

import storyResolvers from '../resolvers';

// Query
import { ALL_PUBLISHED_STORIES_QUERY } from '../graphql/AllPublishedStories.gql';
import { ALL_STORY_TAGS_QUERY } from '../graphql/AllStoryTags.gql';
import { ALL_STORIES_QUERY } from '../graphql/AllStories.gql';
import { ALL_COMMENTS_FOR_STORY_BY_ID_QUERY } from '../graphql/AllCommentsForStoryByStoryId.gql';
import { PUBLISHED_STORY_BY_ID_QUERY } from '../graphql/PublishedStoryById.gql';
import { STORY_BY_ID_QUERY } from '../graphql/StoryById.gql';
import { TAG_BY_ID_QUERY } from '../graphql/TagById.gql';

// Mutation
import { ADD_STORY } from '../graphql/AddStory.gql';
import { ADD_TAG } from '../graphql/AddTag.gql';
import { ADD_COMMENT } from '../graphql/AddComment.gql';
import { LIKE_STORY } from '../graphql/LikeStory.gql';
import { EDIT_STORY } from '../graphql/EditStory.gql';
import { EDIT_TAG } from '../graphql/EditTag.gql';
import { DELETE_STORY } from '../graphql/DeleteStory.gql';
import { DELETE_TAG } from '../graphql/DeleteTag.gql';
import { DELETE_COMMENT } from '../graphql/DeleteComment.gql';
import { DELETE_USER_COMMENT } from '../graphql/DeleteUserComment.gql';

// Client
import { STORY_STATE_QUERY } from '../graphql/StoryStateQuery.client.gql';
import { UPDATE_STORY_FILTER } from '../graphql/UpdateStoryFilter.client.gql';


// Query
export const withAllPublishedStories = Component =>
  graphql(ALL_PUBLISHED_STORIES_QUERY, {
    options: ({ filter, orderBy, pagination }) => {
      return { variables: { ...pagination, ...filter, orderBy } };
    },
    props({ data }) {
      const { loading, error, allPublishedStories, subscribeToMore, updateQuery } = data;
      return { loading, error, allPublishedStories, subscribeToMore, updateQuery };
    }
  })(Component);

export const withAllStoryTags = Component =>
  graphql(ALL_STORY_TAGS_QUERY, {
    options: ({ filter, orderBy, pagination }) => {
      return { variables: { ...pagination, ...filter, orderBy } };
    },
    props({ data }) {
      const { loading, error, allStoryTags, subscribeToMore, updateQuery } = data;
      return { loading, error, allStoryTags, subscribeToMore, updateQuery };
    }
  })(Component);

export const withAllStories = Component =>
  graphql(ALL_STORIES_QUERY, {
    options: ({ filter, orderBy, pagination }) => {
      return { variables: { ...pagination, ...filter, orderBy } };
    },
    props({ data }) {
      const { loading, error, allStories, subscribeToMore, updateQuery } = data;
      return { loading, error, allStories, subscribeToMore, updateQuery };
    }
  })(Component);

export const withAllCommentsForStoryByStoryId = Component =>
  graphql(ALL_COMMENTS_FOR_STORY_BY_ID_QUERY, {
    options: ({ match, navigation, filter, orderBy, pagination }) => {
      let id = '';
      if (match) {
        id = getStoryBase64StringFromInt(match.params.id);
      } else if (navigation) {
        id = getStoryBase64StringFromInt(navigation.state.params.id);
      }

      return {
        variables: {
          story:id,
          ...pagination,
          ...filter,
          orderBy
        }
      };
    },
    props({ data }) {
      const { loading, error, comments, subscribeToMore, updateQuery } = data;
      return { loading, error, comments, subscribeToMore, updateQuery };
    }
  })(Component);

export const withPublishedStoryById = Component =>
  graphql(PUBLISHED_STORY_BY_ID_QUERY, {
    options: props => {
      let id = '';
      if (props.match) {
        id = getStoryBase64StringFromInt(props.match.params.id);
      } else if (props.navigation) {
        id = getStoryBase64StringFromInt(props.navigation.state.params.id);
      }

      return {
        variables: { id:id }
      };
    },
    props({ data: { loading, error, publishedStoryById, subscribeToMore, updateQuery } }) {
      if (error) {
        throw new Error(error.message);
      }
      return { loading, error, publishedStoryById, subscribeToMore, updateQuery };
    }
  })(Component);

export const withTagById = Component =>
  graphql(TAG_BY_ID_QUERY, {
    options: props => {
      let id = '';
      if (props.match) {
        id = getTagBase64StringFromInt(props.match.params.id);
      } else if (props.navigation) {
        id = getTagBase64StringFromInt(props.navigation.state.params.id);
      }

      return {
        variables: { id: id }
      };
    },
    props({ data: { loading, error, tagById, subscribeToMore, updateQuery } }) {
      if (error) {
        throw new Error(error.message);
      }
      return { loading, error, tagById, subscribeToMore, updateQuery };
    }
  })(Component);

export const withStoryById = Component =>
  graphql(STORY_BY_ID_QUERY, {
    options: props => {
      let id = '';
      if (props.match) {
        id = getStoryBase64StringFromInt(props.match.params.id);
      } else if (props.navigation) {
        id =getStoryBase64StringFromInt(props.navigation.state.params.id);
      }

      return {
        variables: { id:id }
      };
    },
    props({ data: { loading, error, storyById, subscribeToMore, updateQuery } }) {
      if (error) {
        throw new Error(error.message);
      }
      return { loading, error, storyById, subscribeToMore, updateQuery };
    }
  })(Component);

// Mutation

  export const withAddStory = Component =>
graphql(ADD_STORY, {
  props: ({ mutate, history }) => ({
    createStory: async values => {
      try {
        const {
          data: { createStory }
        } = await mutate({
          variables: {
            ...values
          }
        });
        
        return createStory.story;
      } catch (e) {
        message.destroy();
        message.error("Failed to create story");
        console.error(e);
      }
    }
  })
})(Component);

  export const withAddTag = Component =>
graphql(ADD_TAG, {
  props: ({ mutate, history }) => ({
    createTag: async values => {
      try {
        const {
          data: { createTag }
        } = await mutate({
          variables: {
            ...values
          }
        });
        
        return createTag.tag;
      } catch (e) {
        message.destroy();
        message.error("Failed to create tag");
        console.error(e);
      }
    }
  })
})(Component);



  export const withAddComment = Component =>
  graphql(ADD_COMMENT, {
    props: ({ mutate, history }) => ({
      createComment: async values => {
        try {
          const {
            data: { createComment }
          } = await mutate({
            variables: {
              ...values
            }
          });
          
          return createComment.comment;
        } catch (e) {
          message.destroy();
          message.error("Failed to create comment");
          console.error(e);
        }
      }
    })
  })(Component);

export const withLikeStory = Component =>
  graphql(LIKE_STORY, {
    props: ({ mutate }) => ({
      likeStory: async id => {
        try {
          const {
            data: { likeStory }
          } = await mutate({
            variables: {
              id
            }
          });
          return likeStory.story;
        } catch (e) {
          if (e.message.includes('Duplicate entry')) {
            message.error('Comment already exists!');
          }
          console.error(e);
        }
      }
    })
  })(Component);

export const withEditStory = Component =>
  graphql(EDIT_STORY, {
    props: ({ mutate }) => ({
      editStory: async values => {
        try {
          const {
            data: { updateStory }
          } = await mutate({
            variables: {
              ...values
            }
          });
          return updateStory.story;
        } catch (e) {
          console.error(e);
        }
      }
    })
  })(Component);

export const withEditTag = Component =>
  graphql(EDIT_TAG, {
    props: ({ mutate }) => ({
      editTag: async values => {
        try {
          const {
            data: { updateTag }
          } = await mutate({
            variables: {
              ...values
            }
          });
          return updateTag.tag;
        } catch (e) {
          console.error(e);
        }
      }
    })
  })(Component);

  export const withDeleteStory = Component =>
  graphql(DELETE_STORY, {
    props: ({ mutate, history }) => ({
      deleteStory: async values => {
        try {
          const {
            data: { deleteStory }
          } = await mutate({
            variables: {
              ...values
            }
          });
  
          message.destroy();
          message.success('Successfully deleted');
          return deleteStory.story;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
          console.error(e);
        }
      }
    })
  })(Component);


  export const withDeleteTag = Component =>
  graphql(DELETE_TAG, {
    props: ({ mutate, history }) => ({
      deleteTag: async values => {
        try {
          const {
            data: { deleteTag }
          } = await mutate({
            variables: {
              ...values
            }
          });
  
          message.destroy();
          message.success('Successfully deleted');
          return deleteTag.tag;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
          console.error(e);
        }
      }
    })
  })(Component);

  export const withDeleteComment = Component =>
  graphql(DELETE_COMMENT, {
    props: ({ mutate, history }) => ({
      deleteComment: async values => {
        try {
          const {
            data: { deleteComment }
          } = await mutate({
            variables: {
              ...values
            }
          });
  
          message.destroy();
          message.success('Successfully deleted');
          return deleteComment.comment;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
          console.error(e);
        }
      }
    })
  })(Component);


  export const withDeleteUserComment = Component =>
  graphql(DELETE_USER_COMMENT, {
    props: ({ mutate, history }) => ({
      deleteUserComment: async values => {
        try {
          const {
            data: { deleteUserComment }
          } = await mutate({
            variables: {
              ...values
            }
          });
  
          message.destroy();
          message.success('Successfully deleted');
          return deleteUserComment.comment;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
          console.error(e);
        }
      }
    })
  })(Component);

export const withStoryState = Component =>
  graphql(STORY_STATE_QUERY, {
    props({ data }) {
      const { orderBy, ...rest } = data.storyState;
      const storyState = { ...removeTypename(rest), orderBy };
      return { ...storyState, stateLoading: data.loading };
    }
  })(Component);

export const withStoryFilterUpdating = Component =>
  graphql(UPDATE_STORY_FILTER, {
    props: ({ mutate }) => ({
      onOrderByChange(orderBy) {
        mutate({ variables: { orderBy } });
      },
      onPaginationChange(pagination) {
        mutate({ variables: { pagination } });
      },
      onFiltersRemove() {
        mutate({ variables: { ...storyResolvers.defaults.storyState } });
      },
      onTitleChange(title_Icontains) {
        mutate({ variables: { filter: { title_Icontains } } });
      },
      onContentChange(content_Icontains) {
        mutate({ variables: { filter: { content_Icontains } } });
      },
      onNameChange(name_Icontains) {
        mutate({ variables: { filter: { name_Icontains } } });
      },
      onDescriptionChange(description_Icontains) {
        mutate({ variables: { filter: { description_Icontains } } });
      },
      onCommentTextChange(commentText_Icontains) {
        mutate({ variables: { filter: { commentText_Icontains } } });
      },
      onUserChange(user) {
        mutate({ variables: { filter: { user } } });
      },
      onAuthorChange(author) {
        mutate({ variables: { filter: { author } } });
      }
    })
  })(Component);
