
export const poc_admin_permission= {
    required_permission:[
        'view_organization',
        'view_vertical','add_vertical','change_vertical','delete_vertical',
        'view_team','add_team','change_team','delete_team',
        'view_employee','add_employee','change_employee','delete_employee',
        'view_member','add_member','change_member','delete_member',
        'view_survey','view_answer','view_response','view_license','view_user'
    ],
    }