// @ts-nocheck
import React from 'react';
import { compose } from '../../core';
import FeedbackPost from '../components/FeedbackPost'
import { withToolById } from "../../tool/containers/ToolOperations";
import { employeeList,withKeyResults,withFeedBackSurveyByid,withCreateFeedback,UseremployeeList } from './feedbackOperations'
import { withApollo } from '@apollo/client/react/hoc'

import { withUpdateMeeting } from '../../one-on-one/containers/One_on_oneOperations'
import { useHistory } from 'react-router-dom';
import { USER_EMPLOYEE_SEARCH } from '../graphql/searchEmployeee.gql';
import {CONTRIBUTOR_OKR_LIST} from '../graphql/ContributerOkrList.gql'
const CreateFeedbackContainer = props => {
    const { client,allEmployee} = props
    const history = useHistory();
    
    const [okrByrecipiant,setokrByrecipiant]= React.useState([]); 
    const [allOrganizationEmployees,setEmployees] = React.useState()


    const getOKRByRecepiant = async (filter) => {

      try{

        const { data } = await client.query({
          query: CONTRIBUTOR_OKR_LIST,
          variables: filter,
      });
      if(data?.contributors){
        setokrByrecipiant(data?.contributors)
      }
      }catch(e){
        console.error(e)
      }

      
  }
 
    let okrDetails = JSON.parse(localStorage.getItem('okrDetails'))

    const handleSearch = async(value)=>{

      try{
        const { data } = await client.query({
          query: USER_EMPLOYEE_SEARCH,
          variables: { user_FirstName: value,user_LastName:value },
          fetchPolicy: 'network-only'
          });
          if(data?.first && data?.last){
           let user=data?.first.edges.concat(data?.last?.edges)
           var setObj = new Set(); // create key value pair from array of array
           var result = user.reduce((acc,item)=>{
             if(!setObj.has(item.node?.user?.id)){
               setObj.add(item.node?.user?.id)
               acc.push(item)
             }
             return acc;
           },[])
  
            setEmployees({edges:result})
          }
      }catch(e){
        console.error(e)
      }
     
    }

    React.useEffect(()=>{
      if(allEmployee){
        setEmployees(allEmployee)
      }
    },[allEmployee])
  return (
    <>
    <FeedbackPost {...props} 
    history={history}
    okrDetails={okrDetails} 
    onSelectRecepiant={(e) => getOKRByRecepiant(e)} 
    allOrganizationEmployees={allOrganizationEmployees}
    handleSearch={(e)=>{handleSearch(e)}}
    okrSearch={(e) => getOKRByRecepiant(e)} 
    okrByrecipiant={okrByrecipiant}
    ></FeedbackPost>
    </>
  );
};

export default compose(withApollo,UseremployeeList,withFeedBackSurveyByid,withCreateFeedback,withUpdateMeeting)(CreateFeedbackContainer);
