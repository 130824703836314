import gql from "graphql-tag";

export const SUBMITTED_FEEDBACK_TYPE = gql`
  fragment FeedbackType on FeedbackType {
    id
    createdAt
    feedbackTo {
      id
      user {
        id
        firstName
        lastName
        profile {
          id
          profileImage
        }
      }
    }

    feedbackFrom {
      id
    }
    response {
      id
      answerSet {
        edges {
          node {
            id
            answer
          }
        }
      }
    }
    badgeAward {
      edges {
        node {
          id
          badge {
            id
            image
          }
        }
      }
    }
    okr {
      id
      title
    }
    detailFillIn
    createdAt
    updatedAt
  }
`;

export const SUBMITTED_FEEDBACK = gql`
  ${SUBMITTED_FEEDBACK_TYPE}
  query allFeedback(
    $feedbackFrom: ID!
    $feedbackTo_User_FirstName_Icontains: String,
    $feedbackTo_User_LastName_Icontains:String,
    $createdAt_Day_Gt: DateTime
    $orderBy: [String],
    $response_Answer_Answer_Icontains:String,
    $okr_Goalstatement_Icontains: String,
    $createdAt_Range:[DateTime],
    $okr_Isnull: Boolean, 
    $first: Int,
  ) {
    submittedFeedbackFirstName: allFeedback(
      feedbackFrom: $feedbackFrom,
       feedbackTo_User_FirstName_Icontains: $feedbackTo_User_FirstName_Icontains,
      createdAt_Day_Gt: $createdAt_Day_Gt,
      createdAt_Range:$createdAt_Range,
      orderBy: $orderBy,
      first: $first
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edgeCount
      edges {
        node {
          ...FeedbackType
        }
      }
    }

    submittedFeedbackLastName: allFeedback(
      feedbackFrom: $feedbackFrom,
      feedbackTo_User_LastName_Icontains: $feedbackTo_User_LastName_Icontains,
      createdAt_Day_Gt: $createdAt_Day_Gt,
      createdAt_Range:$createdAt_Range,
      orderBy: $orderBy,
      first: $first
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edgeCount
      edges {
        node {
          ...FeedbackType
        }
      }
    }

    submittedFeedbackOkr: allFeedback(
      feedbackFrom: $feedbackFrom,
      createdAt_Day_Gt: $createdAt_Day_Gt,
      orderBy: $orderBy,
      okr_Goalstatement_Icontains: $okr_Goalstatement_Icontains,
      createdAt_Range:$createdAt_Range,
      okr_Isnull: $okr_Isnull,
      first: $first
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edgeCount
      edges {
        node {
          ...FeedbackType
        }
      }
    }

    submittedFeedbackFeedback: allFeedback(
      feedbackFrom: $feedbackFrom,
      createdAt_Day_Gt: $createdAt_Day_Gt,
      orderBy: $orderBy,
      response_Answer_Answer_Icontains: $response_Answer_Answer_Icontains,
      createdAt_Range:$createdAt_Range,
      first: $first
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edgeCount
      edges {
        node {
          ...FeedbackType
        }
      }
    }

  }
`;

