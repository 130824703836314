import gql from 'graphql-tag';
import { WEIGHTED_QUESTION_TYPE } from './WeightedQuestionType.gql';

export const WEIGHTED_QUESTION_TYPE_CONNECTION = gql`
  ${WEIGHTED_QUESTION_TYPE}
  fragment WeightedQuestionTypeConnection on WeightedQuestionTypeConnection {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...WeightedQuestionType
      }
    }
    totalCount
    edgeCount
  }
`;
