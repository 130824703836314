import gql from 'graphql-tag';

export const CHOICE_TYPE = gql`
  fragment ChoiceType on ChoiceType {
    id
    label
    value
    sequence
    inputType
    # question {
    #   ...QuestionType
    # }
  }
`;
