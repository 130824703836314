import { impact_narrative_permission } from "./ImpactNarrative.permission";
import { competency_assessment_permission } from "./competency-assessment";
import { kudos_permission } from "./kudos.permission";
import { okr_permission } from "./okr.permission";
import { survey_permission } from "./survey.permission";


export const user_profile_permissions ={
    required_permission:['view_employee'].concat(survey_permission?.required_permission),
    award_badge_permission:kudos_permission?.can_award_badge,
    okr_permission:okr_permission?.requried_permission,
    survey_permission:survey_permission?.required_permission,
    competency_assessment_permission:competency_assessment_permission?.required_permission,
    impact_narrative_permission:impact_narrative_permission.required_permission
}