// @ts-nocheck
import { message } from 'antd';
import _ from 'lodash';
import { graphql } from '@apollo/client/react/hoc';
import { UPDATE_RESPONSE_MUTATION } from '../graphql/updateReasponseMutation.gql';


export const withUpdateResponse = Component =>
    graphql(UPDATE_RESPONSE_MUTATION, {
        props: ({ mutate, history }) => ({
            updateResponse: async values => {
                try {
                    const {
                        data: { updateResponse }
                    } = await mutate({
                        variables: {
                            ...values
                        }
                    });

                    message.destroy();
                    message.success('Submitted response!!');
                    return updateResponse.response;
                } catch (e) {
                    message.destroy();
                    message.error("Couldn't perform the action");
                    console.error(e);
                }
            }
        })
    })(Component);

