import gql from 'graphql-tag';

export const MY_RESPONSE = gql`
  query allResponsesByImplicitUserId($first: Int, $offset: Int, $orderBy: [String]) {
    allResponsesByImplicitUserId(first: $first, offset: $offset, orderBy: $orderBy) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          survey {
            id
            name
            reportQuery
            multisourceResponse
          }
          responseDate
          responseDuration
        }
      }
      totalCount
      edgeCount
    }
  }
`;
