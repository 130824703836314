import gql from 'graphql-tag';
import { ANSWER_TYPE } from './AnswerType.gql';

export const ANSWER_TYPE_CONNECTION = gql`
  ${ANSWER_TYPE}
  fragment AnswerTypeConnection on AnswerTypeConnection {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...AnswerType
      }
    }
    totalCount
    edgeCount
  }
`;
