// @ts-nocheck
import { Modal } from "antd";
import React from "react";
import OkrPerformanceView from "../OkrPerformanceView";
import { compose } from 'modules/core';
import { withApollo } from '@apollo/client/react/hoc';

import { endOfQuarter, getIntFromString, localTz, startOfQuarter } from 'modules/look';
import { MEMBER_NON_CASCADE_DATA } from "modules/Objective-keyresult/graphql/MemberNonCascadeData.gql";
import { CASCARDING_OBJECTIVE_LIST, NON_CASCARDING_OBJECTIVE_LIST } from "modules/Objective-keyresult/graphql/TeamsCascadingObjective.gql";
import { PERFORMANCE_CHART_DATA } from "modules/Objective-keyresult/graphql/PerformanceChartData.gql";
import { TEAM_MEMBER_LIST } from "modules/Objective-keyresult/graphql/TeammemberLists.gql";
import TeamOkrPerformancePopupView from "../TeamOkrpopView";
const TeamOkrPerformanceContainer=(props)=>{
    const{client,me,teamDetail,dateRangeTofilter}=props
    const [cascadedOkr, setCascadedOkr] = React.useState();
    const [NoncascadedOkr, setNonCascadedOkr] = React.useState();
    const [PerformanceData, setPerformanceData] = React.useState(); //for members
    const [teamData, setteamData] = React.useState();//for team
    const [barloader,setBarloader]= React.useState(true)
    const [donutloader,setdonutloader]= React.useState(true)
    React.useEffect(()=>{
        if(me && teamDetail && dateRangeTofilter){
            getCascardRecord(me?.employee?.id,0)
            getPerformanceData(5,0 )
        }
    },[me,teamDetail,dateRangeTofilter])

    const getCascardRecord = async(id,offset,pagination=false)=>{
        setdonutloader(true)
        const { data } = await client.query({
            query: CASCARDING_OBJECTIVE_LIST,
            variables: {
              okr_Duedate_Range: dateRangeTofilter, role_In: ["COLLABORATOR", "ASSIGNED"],
              employee_In: teamDetail?.managerId || [],
               offset: offset*100, first: 100
            },
            // fetchPolicy: 'network-only'
          });
          if(data?.AllCascadingObjectives){
            if (pagination) {
                let obj = {
                  edgeCount: cascadedOkr?.edgeCount + data?.AllCascadingObjectives?.edgeCount,
                  edges: [...cascadedOkr?.edges,...data?.AllCascadingObjectives?.edges],
                  totalCount: data?.AllCascadingObjectives?.totalCount
                }
                setCascadedOkr(obj)
            } else {
              setCascadedOkr(data?.AllCascadingObjectives)
            }
            setdonutloader(false)
          }
      
    }
    const getNonCascadeRecord =async(id,offset,pagination=false)=>{
      console.log("non-cascade okr")
        setdonutloader(true)
        const { data } = await client.query({
            query: NON_CASCARDING_OBJECTIVE_LIST,
            variables: {
              id:teamDetail?.id, okr_Duedate_Range: dateRangeTofilter,
              createdBy_In:teamDetail?.managerId||[], 
              offset: offset*100, first: 100
            },
            // fetchPolicy: 'network-only'
          });
          if(data?.AllNonCascadingObjectives){
            let emp_edges= data.AllNonCascadingObjectives.employees.edges.map(({node})=>{
              return node?.okrContributorSet?.edges?.map((item=>item))
            })
            let head_edges= data.AllNonCascadingObjectives.managers.edges.map(({node})=>{
              return node?.okrContributorSet?.edges?.map((item=>item))
            })
            let edges=[...emp_edges,...head_edges]
            edges = [].concat.apply([],edges) 
            var setObj = new Set(); 
          edges = edges.reduce((acc,item)=>{
            if(!setObj.has(item?.node?.okr?.id)){
              setObj.add(item?.node?.okr?.id)
              acc.push(item)
            }
            return acc;
          },[])
          let fetchedData ={edges:edges,edgeCount:edges?.length,totalCount:edges?.length}
          if (pagination) {
            let obj = {
              edgeCount: NoncascadedOkr?.edgeCount + fetchedData?.edgeCount,
              edges: [...NoncascadedOkr?.edges, ...fetchedData?.edges],
              totalCount: fetchedData?.totalCount
            }
            setNonCascadedOkr(obj)
          } else {
            setNonCascadedOkr(fetchedData)
          }
          setdonutloader(false)
          }
    }

    const getPerformanceData = async(first,offset,pagination=false)=>{
      setBarloader(true)
      
      const { data } = await client.query({
        query: PERFORMANCE_CHART_DATA,
        variables: {
          savedOkrType: "OBJECTIVE",okr_Duedate_Range: dateRangeTofilter,
          employee_In: teamDetail?.managerId,
          offset: offset, first: first
        },
        // fetchPolicy: 'network-only'
      });
      if(data?.PerformanceGraphData){
        console.log('====================================');
        console.log(data?.PerformanceGraphData,'team manager',teamDetail);
        console.log('====================================');
        if(pagination){
        let  fetchData ={
            totalCount:data?.PerformanceGraphData?.totalCount,
            edgeCount:data?.PerformanceGraphData?.edgeCount+PerformanceData?.edgeCount,
            edges:[...PerformanceData?.edges,...data?.PerformanceGraphData?.edges]
          }
          setPerformanceData(fetchData)
        }
        else{
          setPerformanceData(data?.PerformanceGraphData)
        }
        
        setBarloader(false)
      }
    }

    const getTeamMembersList =async(first,offset,pagination=false)=>{
      let manager =teamDetail?.managers?.edges?.map(({node})=>getIntFromString(node?.id))
      setBarloader(true)
      const { data } = await client.query({
        query: TEAM_MEMBER_LIST,
        variables: {
          id:teamDetail?.id,okr_Duedate_Range: dateRangeTofilter,employee_In: manager, createdBy_In:manager, offset: offset, first: first
        },
        // fetchPolicy: 'network-only'
      });
      console.log("team member list",data)
      if(data?.teamData){
        console.log('team member list',data?.teamData);

        let empData = data?.teamData?.employees?.edges?.map(({node})=>(
          {   
              id:node?.id,
              empName:node?.user?.firstName+" "+node?.user?.lastName,
              progress:node?.okrContributorSet?.edges?.map(({node})=>node?.progress)
          }))
         
          let edges=empData?.map((item)=>(
              {
                  id:item?.id,
                  title:item?.empName,
                  progress:CheckNumber(item?.progress.reduce((a, b) => a + b, 0)/item?.progress?.length)
              }
          ))
          let fetchData ={
            edgeCount:data?.teamData?.employees?.edgeCount,
            totalCount:data?.teamData?.employees?.totalCount,
            edges:edges
          }
        if(pagination){
          let paginatedData ={
            edgeCount:fetchData?.edgeCount+teamData?.edgeCount,
            totalCount:fetchData?.totalCount,
            edges:[teamData?.edges,...edges]
          }
          setteamData(paginatedData)
        }else{
          setteamData(fetchData)
        }
        
      }
      setBarloader(false)
    }

    const CheckNumber=(number)=>{
      if(isNaN(number)){
          return 0
      }
      else{
          if(Number.isInteger(number)){
              return number
          }
          else{
              return Number(number.toFixed(2))
          }
      }
  } 

    return (
        <>
           <TeamOkrPerformancePopupView 
           cascadedOkr={cascadedOkr}
           NoncascadedOkr={NoncascadedOkr}
           PerformanceData={PerformanceData}
           memberPerformance={teamData}
           donutloader={donutloader}
           barloader={barloader}
           dateRangeTofilter={dateRangeTofilter}
           getTeamMembersList={(first,offset,pagination)=>{getTeamMembersList(first,offset,pagination)}}
           getOkrPerformanceData={(first,offset,pagination)=>{getPerformanceData(first,offset,pagination)}}
           getCascardRecord={(id,offset,pagination)=>{getCascardRecord(id,offset,pagination)}}
           getNonCascadeRecord={(id,offset,pagination)=>{getNonCascadeRecord(id,offset,pagination)}}
          {...props} /> 
        </>
    )
}

export default  compose(withApollo)(TeamOkrPerformanceContainer)