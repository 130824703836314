import gql from 'graphql-tag';
// import { SURVEY_TYPE } from './SurveyType.gql';

// # ${SURVEY_TYPE}
export const ADD_SURVEY_MUTATION = gql`
  mutation createSurvey($organizations: [Int], $surveyData: SurveyInput!, $teams: [Int], $verticals: [Int]) {
    createSurvey(organizations: $organizations, surveyData: $surveyData, teams: $teams, verticals: $verticals) {
      survey {
        id
        # ...SurveyType // this could cause some datetime error
      }
    }
  }
`;
