import React from 'react';
import { compose } from '../../core';

import { withApollo } from '@apollo/client/react/hoc'
import { ALL_MEETING } from '../graphql/AllMeeting.gql';
import FutureMeetingView from '../components/FutureMeetingView';
import moment from 'moment-timezone'; 
const FutureMeetingContainer = props => {
  const { client,me,history,filteritem }=props
  const [meetingList,setMeeting]=React.useState([])
  const [loading, setloading] = React.useState(false);
  const [onFilterList,setOnFilterlist]=React.useState(false)

  const allMeetingList = async (filterData) => {
    setloading(true)
    if(filterData?.pagination_value){
      setOnFilterlist(true)
    }
    else{
      setOnFilterlist(false)
    }
    let weekendDate = new Date(moment().format())
    let dif = 6-weekendDate.getDay()
    weekendDate.setDate(weekendDate.getDate()+dif)
    let IsoWeekendDate = moment.utc(moment(weekendDate).endOf('day'))
    const { data } = await client.query({
        query: ALL_MEETING,
        variables: {...filterData,orderBy:['when'],when_Gt:IsoWeekendDate},
        fetchPolicy: 'network-only'
    });
    if(data?.allMeetings){
      setloading(false)
      setMeeting(data?.allMeetings)
    }
}

React.useEffect(()=>{
  if(me){
    let selected_Data =JSON.parse(localStorage.getItem("one_on_one_filter"))
      if(selected_Data==null){
        allMeetingList({nextMeeting_Isnull:true,offset:0,first:20})
      }
  }
  else if (me===null){
    history.push('/user/login')
  }
},[me])


React.useEffect(()=>{
  if(filteritem){
    allMeetingList({nextMeeting_Isnull:true,...filteritem,offset:0,first:20})
  }
},[filteritem])

const PaginantionCall =(value)=>{
  if(value){
    console.log("value",value)
    allMeetingList({ nextMeeting_Isnull:true,...filteritem,...value,first:20})
  }
  else{
    allMeetingList({ nextMeeting_Isnull:true,offset:0,first:20})
  }
}

  return (
    <>
    <FutureMeetingView {...props} allMeetings={meetingList} onfilter={(e) => allMeetingList(e)} loading={loading} setloading={(e)=>setloading(e)}onPaginantion={e=>PaginantionCall(e)} filteritem={filteritem} onFilterList={onFilterList}></FutureMeetingView>
    </>
  );
};

export default compose(withApollo)(FutureMeetingContainer);