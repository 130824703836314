import gql from "graphql-tag";

export const DASHBOARD_TEAM_LIST = gql`
  query teams($managers: [ID]) {
    Teams(managers: $managers) {
      totalCount
      edges {
        node {
          id
          name
          employees {
            edges {
              node {
                id
                user {
                  id
                  firstName
                  lastName
                  username
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const DASHBOARD_TEAMS_BY_ID = gql`
  query Team($id: ID!) {
    Team(id: $id) {
      id
      name
      vertical{
        id
      }
      employees {
        edges {
          node {
            id
            user {
              id
              firstName
              lastName
              username
              profile{
                id
                profileImage
              }
            }
            memberSet(role_Isnull: false, last: 1) {
              edges {
                node {
                  id
                  role {
                    id
                    title
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const DASHBOARD_SCAPE_REPORT = gql`
  query me(
    $value_id: ID!
    $id_In: String
    $strength_id: ID!
    $personality_id: ID!
  ) {
    valueReport: me {
      id
      username
      employee {
        id
        organizationSet {
          edges {
            node {
              id
              employees(id_In: $id_In) {
                edges {
                  node {
                    id
                    user {
                      id
                      firstName
                      lastName
                      responseSet(survey: $value_id, last: 1) {
                        edges {
                          node {
                            id
                            responseDate
                            answerSet {
                              edges {
                                node {
                                  id
                                  answer
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    struengthsReport: me {
      id
      username
      employee {
        id
        organizationSet {
          edges {
            node {
              id
              employees(id_In: $id_In) {
                edges {
                  node {
                    id
                    user {
                      id
                      firstName
                      lastName
                      responseSet(survey: $strength_id, last: 1) {
                        edges {
                          node {
                            id
                            responseDate
                            answerSet {
                              edges {
                                node {
                                  id
                                  answer
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    personalityReport: me {
      id
      username
      employee {
        id
        organizationSet {
          edges {
            node {
              id
              employees(id_In: $id_In) {
                edges {
                  node {
                    id
                    user {
                      id
                      firstName
                      lastName
                      responseSet(survey: $personality_id, last: 1) {
                        edges {
                          node {
                            id
                            responseDate
                            answerSet {
                              edges {
                                node {
                                  id
                                  answer
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const DASHBOARD_SCAPE_BY_ID_QUERY = gql`
  query scapeById($id: ID!) {
    scapeById(id: $id) {
      id
      name
      description
      active
      survey {
        id
        name
      }
      parametricgroupSet {
        edges {
          node {
            id
            name
            scape {
              id
            }
            parameterSet {
              edges {
                node {
                  id
                  show
                  choice {
                    id
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const DASHBOARD_SCAPE_LIST = gql`
  query scapes {
    scapes {
      edges {
        node {
          id
          name
          survey {
            id
          }
        }
      }
    }
  }
`;
export const NEW_DASHBOARD_SCAPE_BY_ID = gql`
  query scapeById($id: ID!,) {
    scapeById(id: $id) {
      parametricgroupSet {
        edges {
          node {
            parameterSet {
              edges {
                node {
                  id
                  choice {
                    id
                    label
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const DASHBOARD_ALL_EMPLOYEES = gql`
  query allEmployees($nameIcontains: String,$after: String,$first:Int) {
    allEmployees(nameIcontains: $nameIcontains,first:$first,after:$after) {
      pageInfo{
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          user {
            id
            firstName
            lastName
          }
          memberSet(role_Isnull: false, last: 1) {
            edges {
              node {
                id
                role {
                  id
                  title
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const EDIT_DASHBOARD_TEAM = gql`
  mutation updateTeam($employees: [Int], $id: ID!) {
    updateTeam(employees: $employees, id: $id) {
      team {
        id
      }
    }
  }
`;

export const DASHBOARD_QUESTION_BY_QUESTION_ID = gql`
  query questionById($id: ID!) {
    questionById(id: $id) {
      id
      choiceSet {
        edges {
          node {
            id
            label
            value
          }
        }
      }
    }
  }
`;

export const TEAMS_ONE_ON_ONE_LAST_DATE = gql`
  query allMeetings($owner: ID) {
    allMeetings(
      owner: $owner
      orderBy: ["-when"]
      first: 1
      nextMeeting_Isnull: true
    ) {
      edges {
        node {
          id
          title
          when
        }
      }
    }
  }
`;
export const ONE_ON_ONE_EFFECTIVENESS_FEEDBACK = gql`
query allMeetingFeedbacks($meetingHost: ID){
  allMeetingFeedbacks(meetingHost:$meetingHost){
    edges {
      node {
        id
        meetingEffectiveness
        hostSkills
      }
    }
  }
}
`;

export const TEAM_LIST_SUBSCRIPTION =gql`
subscription teamSubscription{
  teamSubscription{
    mutation
    team{
      employees {
        edges {
          node {
            id
            user {
              id
              firstName
              lastName
              username
            }
            memberSet(role_Isnull: false, last: 1) {
              edges {
                node {
                  id
                  role {
                    id
                    title
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`
export const LAST_FEEDBACK_GIVEN =gql`
query allFeedback($feedbackFrom: ID){
  allFeedback(feedbackFrom:$feedbackFrom,last:1){
    edges{
      node{
        id
        createdAt
      }
    }
  }
  }
`
export const TEAM_ASSESSMENT_LIST =gql`
query AssessmentByTeam{
	AssessmentByTeam{
    id
    name
    multisourceResponse
    summaryListByTeam
  }
}
`
export const DIVERSITY_SURVEY_RESPONSE =gql`
query surveyResponses($survey: ID,$question: ID,$user_In: [ID]){
  surveyResponses(survey:$survey,user_In:$user_In){
    edges{
      node{
        id
        responseDate
        user{
          id
          firstName
        }
        answerSet(question:$question){
          edges{
            node{
              id
              answer
            }
          }
        }
      }
    }
  }
}
`

export const SURVEY_RESPONSE_FOR_ASSESSMENT =gql`
query surveyResponses($survey_In: [ID],$user_In: [ID],$createdBy: ID,$survey: ID){
  survey_response:surveyResponses(survey_In:$survey_In,user_In:$user_In){
    edges{
      node{
        id
        user{
          id
        }
        responseDate  
      }
    }
  }
  response_360:getAllTokensList(createdBy:$createdBy,survey:$survey){
    edges{
      node{
        id
        user{
          id
          firstName
          lastName
        }
        link
        responseSourceFor{
          id
        }
      }
    }
  }
}
`
export const VERTICAL_ALL_EMPLOYEES = gql`
query vertical($id: ID!,$after: String, $first: Int) {
  vertical(id: $id) {
    id
    name
    employees( first: $first, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges{
        node{
          id
          memberSet(role_Isnull: false, last: 1){
            edges{
              node{
                id
                role{
                  id
                  title
                }
              }
            }
					}
          user{
            id
            firstName
            lastName
            username
					}
        }
      }
    }
  }
}

`