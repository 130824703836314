import React from "react";
import { compose } from "../../core";
import { withApollo } from '@apollo/client/react/hoc';
import BarsEditView from '../Components/BarsEditView'
import { getBehaviourBase64StringFromInt, getIntFromString, globalPermissionValidator } from "modules/look";
import { BARS_DETAILS } from "../graphql/BarsEditQueries.gql";
import { Row, Spin } from "antd";
import NoPermissionView from "modules/kudos-user/component/NoPermissionComponent";
import { bars_permission } from "Permissions/bars.permission";
import { ALL_EMPLOYEE_LEVELS, ALL_RATING_SCALES } from "../graphql/BarsQueries.gql";
import { withCreateBarsInBulk } from "./BarsOperations";
import BarsEditViewNoBarsAvailable from "../Components/BarsEditNoAvailableBars";




const BarsEditContainer = (props) => {

  const { client, me, permissionLoader, userPermission, navigateRoute} = props;


  const [barsDetailsLoading, setBarsDetailsLoading] = React.useState(undefined);
  const [barsDetails, setBarsDetails] = React.useState(undefined);
  const [uniqueEmpLevels, setUniqueEmpLevels] = React.useState(undefined);
  const [barsPermission, setBarsPermission] = React.useState(undefined);
  const [ratingScales, setRatingScales] = React.useState(undefined);
  const [allEmployeeLevels, setAllEmployeeLevels] = React.useState(undefined);

  const empLevelRef = React.useRef(null);
  


  React.useEffect(() => {

    // Checking for permission of user
    if (userPermission?.length) {
      let permission = globalPermissionValidator(bars_permission, userPermission);
      setBarsPermission(permission)
    }

  }, [userPermission])



  React.useEffect(()=>{

    if(allEmployeeLevels && ratingScales){
      let id = props.match.params.bar_id;
      if (id) {
        getBarsDetails(id);
      }
    }

  },[allEmployeeLevels,ratingScales])


  React.useEffect(() => {

    // based on permission of user bars will be di
    if (barsPermission?.required_permission) {
        getAllRatingScales();
        getAllEmployeeLevels();
    }

  }, [barsPermission,props.match])


    // For fetching all rating scales
    const getAllRatingScales = async () => {
      
  
      const { data } = await client.query({
        query: ALL_RATING_SCALES,
        fetchPolicy: 'network-only',
      });
      if (data?.ratingScales) {
        let scales = data?.ratingScales?.edges?.map(({ node }) => node);
        scales.current = scales
        setRatingScales(scales);
  
      }
    }
  
    // Fetching all the bars
    const getAllEmployeeLevels = async (filter) => {
  
      const { data } = await client.query({
        query: ALL_EMPLOYEE_LEVELS,
        variables:{organization:me?.employee?.organizationSet?.edges[0]?.node?.id},
        fetchPolicy: 'network-only',
      });
      if (data?.allEmployeeLevels) {
        let allLevels = data?.allEmployeeLevels?.edges?.map(({ node }) => node);
      
        setAllEmployeeLevels(allLevels)
      }
    }


    const recordExist = (scale,level,records) =>{

      let verifyScale =   records?.mainCompetency?.behaviourallyanchoredratingSet?.edges?.map(({node})=>
        {
         if(node?.employeeLevel?.id === level?.id &&  node?.ratingScale?.id === scale?.id){
           return {bar: getIntFromString(node?.id),...node?.employeeLevel,ratingScale:node?.ratingScale,behaviourExample:node?.behaviourExample}
         }else{
           return undefined
         }
        }
 
       )?.filter((value) => value !== undefined)

       return verifyScale;
 
     }





    const getEmployeeLevels = (data) =>{

    let levels =  allEmployeeLevels?.map((level)=>

    ratingScales?.map((scale)=>
         
         {
          let record  = recordExist(scale,level,data)
          if(record?.length){
            return {...record[0]}
          }else{
            return {...level,ratingScale:scale}
          }
         })
    )?.flat();
  
    
 
    return levels;

    }


  const getRecordGenarated = (node) => {

    let levels = node?.mainCompetency?.behaviourallyanchoredratingSet?.edges?.map(({ node }) => node?.employeeLevel)

    let record = {
      id: node?.id,
      title: node?.mainCompetency?.title,
      mainCompetencyId:node?.mainCompetency?.id,
      subCompetenciesLength:node?.mainCompetency?.competencySet?.totalCount,
      subCompetenciesBars:node?.mainCompetency?.competencySet?.edges?.map(({node})=>node?.behaviourallyanchoredratingSet?.totalCount),
      empLevels:getEmployeeLevels(node),
      competency: node?.mainCompetency?.behaviourallyanchoredratingSet?.edges?.map(({ node }) => { return { ...node, intId: getIntFromString(node?.id) } }),
    }

    const key = 'id';
   
    const uniqueLevels = [...new Map(levels.map(item => [item[key], item])).values()];
    setUniqueEmpLevels(uniqueLevels)

    return record;
  }


  const getBarsDetails = async (id) => {
    setBarsDetailsLoading(true)
    let record = getBehaviourBase64StringFromInt(id)
    const { data } = await client.query({
      query: BARS_DETAILS,
      fetchPolicy: 'network-only',
      variables: { id:record }
    });
    if (data?.behaviourallyAnchoredRatingById) {
      let record = getRecordGenarated(data?.behaviourallyAnchoredRatingById)

      if(record && allEmployeeLevels){
        let arr = [];
        allEmployeeLevels
         .forEach(level => (
           arr.push({...level,status: record?.empLevels?.filter(item => item.id === level?.id && item?.behaviourExample)?.length !== ratingScales?.length ? "pending" : 'completed'})
         ));
     
         
         empLevelRef.current = arr;
      
      }

    
      setBarsDetails(record)
      setBarsDetailsLoading(false)
    }
  }



  return (
    <>

      <Row justify='center' align='middle'>
        {
          <Spin spinning={permissionLoader} wrapperClassName ='permission-check-bars'>
            <div>
              {
                barsPermission !== undefined &&
                <div>
                  {
                    barsPermission?.required_permission ?
    
                     
                    barsDetails?.empLevels?.find((value)=>value?.bar) ?

                    <BarsEditView
                    barsDetails={barsDetails}
                    barsDetailsLoading={barsDetailsLoading}
                    setBarsDetailsLoading={setBarsDetailsLoading}
                    allEmployeeLevels={allEmployeeLevels}
                    allRatingScales = {ratingScales}
                    setBarsDetails={setBarsDetails}
                    empLevelRef = {empLevelRef}
                    setAllEmployeeLevels={setAllEmployeeLevels}
                    {...props}
                    />
                    :
                    <BarsEditViewNoBarsAvailable
                    barsDetails={barsDetails}
                    barsDetailsLoading={barsDetailsLoading}
                    setBarsDetailsLoading={setBarsDetailsLoading}
                    allEmployeeLevels={allEmployeeLevels}
                    allRatingScales = {ratingScales}
                    setBarsDetails={setBarsDetails}
                    empLevelRef = {empLevelRef}
                    setAllEmployeeLevels={setAllEmployeeLevels}
                    {...props}
                  />
                      :
                      <NoPermissionView />
                  }

                </div>
              }
            </div>
          </Spin>
        }
      </Row>

     
    </>
  );
};

export default compose(withApollo,withCreateBarsInBulk)(BarsEditContainer);
