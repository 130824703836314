import gql from "graphql-tag";

export const IDP_DETAIL_BY_ID = gql`
  query idpById($id: ID!) {
    idpById(id: $id) {
      id
      goal
      goalType
      activity
      resources
      status
      duedate
      createdDt
      milestone
      comments
      suggestion
      employee{
        id
        user{
          id
          firstName
          lastName
        }
      }
      approver {
        id
        firstName
        employee{
          id
        }
      }
      skillSet {
        competency {
          id
          title
        }
      }
    }
  }
`;

export const PUBLIC_IDP_DETAIL_BY_ID = gql`
  query pubidpById($id: Float,$token: String!) {
    pubidpById(id: $id,token:$token) {
     edges{
      node{
        id
        goal
        goalType
        activity
        resources
        status
        duedate
        createdDt
        milestone
        comments
        suggestion
        employee{
          id
          user{
            id
            firstName
            lastName
          }
        }
        approver {
          id
          firstName
          employee{
            id
          }
        }
        skillSet {
          competency {
            id
            title
          }
        }
      }
     }
    }
  }
`;
