import React from "react";
import { Modal, Input, Spin, Button } from "antd"
import { dasboardIcons } from "modules/home/dashboard-icons";
import { getIntFromString } from "modules/look";

const AddNewEmployeeView = ( props ) => {
    const { add_employee, onCancel, all_employee_list, loading, onSearch, team_employees_list, handleSubmit, team_id, all_employee_info, getAllEmployees } = props
    const [ selected_employees, setSelectedEmployee ] = React.useState( [] )
    React.useEffect( () => {
        if ( team_employees_list?.length > 0 ) {
            let teams_employees = team_employees_list?.map( ( item ) => getIntFromString( item?.id ) )
            setSelectedEmployee( teams_employees )
        }
        else {
            setSelectedEmployee( [] )
        }
    }, [ team_employees_list ] )

    const changeEmployeeList = ( value ) => {
        let lists = [ ...selected_employees ]

        if ( selected_employees?.includes( value ) ) {
            let index = lists?.indexOf( value )
            lists.splice( index, 1 )
            setSelectedEmployee( lists )
        }
        else {
            lists.push( value )
            setSelectedEmployee( lists )
        }

    }
    const onSubmit = () => {
        handleSubmit(
            {
                employees: selected_employees,
                id: getIntFromString(team_id) 
            }
        )
    }
    return (
        <Modal
            visible={add_employee}
            footer={null}
            centered
            closable={false}
            width={'90%'}
            style={{ maxWidth: "35em" }}>
            <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between', alignItems: "center", gap: "1em", cursor: "pointer", padding: '.5em 1em' }}>
                <h4 className="user-board-section-heading">Manage Employees</h4>
                <img src={dasboardIcons?.close_icon} alt="close_icon" style={{ cursor: "pointer" }} onClick={() => onCancel()} />
            </div>
            <div className="dashboard-member-search-section">
                <img src={dasboardIcons?.search_icon} alt="search_icon" />
                <Input className="dashboard-member-search-input" placeholder="Search Employee name" onChange={( e ) => getAllEmployees( { nameIcontains: e?.target?.value } )} />
            </div>
            <Spin spinning={loading}>
                <div style={{ display: "flex", flexDirection: "column", gap: '1em', justifyContent: "start", alignItems: "center", marginTop: "1em", overflow: "auto", height: "30em" }}>
                    {all_employee_list?.length > 0 ? all_employee_list?.map( item => ( <div className="dashboard-member-section-member-container">
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "start" }}>
                            <h4 style={{ fontFamily: "Poppins", fontSize: '1em', fontWeight: "500", color: "#000", margin: "0" }}>{item?.user?.firstName} {item?.user?.lastName}</h4>
                            <p style={{ fontFamily: "Poppins", fontSize: '.85em', fontWeight: "400", color: "#8998A2", margin: "0" }}>{item?.memberSet?.edges[ 0 ]?.node?.role?.title ? item?.memberSet?.edges[ 0 ]?.node?.role?.title : "No role assigned"}</p>
                        </div>
                        {selected_employees?.includes( getIntFromString( item?.id ) ) ?
                            <img src={dasboardIcons?.remove_icon} style={{ width: "30px", cursor: "pointer" }} alt="remove_icon" onClick={() => changeEmployeeList( getIntFromString( item?.id ) )} /> :
                            <img src={dasboardIcons?.add_icon} style={{ width: "30px", cursor: "pointer" }} alt="add_icon" onClick={() => changeEmployeeList( getIntFromString( item?.id ) )} />
                        }
                    </div> ) )
                        :
                        ( !loading && <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                            <h4 className="user-dashboard-small-card-para" style={{ fontWeight: "400", textAlign: 'center', paddingTop: "10em" }}>No Employees Found</h4>
                        </div> )
                    }
                    {all_employee_info?.hasNextPage && all_employee_info?.endCursor &&
                        <h4 className="dashboard-view-more-button" style={{ fontFamily: "Poppins", fontSize: "1em", margin: "0px", textAlign: "center", cursor: "pointer", fontWeight: "600", paddingTop: ".5em" }} onClick={() => { getAllEmployees( { after: all_employee_info?.endCursor } ) }}>View More</h4>}
                </div>
                <div style={{ width: "100%", marginBottom: "15px", display: 'flex', flexDirection: "row", justifyContent: 'flex-end', alignItems: "center", gap: '1em' }}>
                    <Button className="user-board-welcome-banner-button" style={{ fontSize: "1.1em", marginTop: '.75em', color: "#8998A2", border: '1px solid #8998A2', width: "100px" }} onClick={() => onCancel()} >
                        Cancel
                    </Button>
                    <Button className="user-board-welcome-banner-button" style={{ fontSize: "1.1em", marginTop: '.75em', width: "100px" }} onClick={() => onSubmit()}>
                        Save
                    </Button>
                </div>
            </Spin>
        </Modal>
    )
}

export default AddNewEmployeeView