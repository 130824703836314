import { withApollo } from '@apollo/client/react/hoc'
import { compose } from 'modules/core'
import React from 'react'

import ScapeReportLevel2GraphView from '../components/scape-report-level2-graph-view'

const ScapeReportLevel2GraphContainer = (props) => {
    return (
        <ScapeReportLevel2GraphView {...props}></ScapeReportLevel2GraphView>
    )
}
export default compose()(ScapeReportLevel2GraphContainer)