// @ts-nocheck
import { LoadingOutlined } from '@ant-design/icons';
import { Button, DatePicker, message, Modal, Row, Spin, Tooltip } from "antd";
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import Agenda_close from '../../assets/agenda-close.svg';
import DeleteBtn from '../../assets/feedback_card_delete_btn.svg';
import EditBtn from '../../assets/feedback_card_edit_btn.svg';
import CustomSortImg from '../../assets/feedback_sub_date_sort_btn.svg';
import CustomSortImgWhite from '../../assets/feedback_sub_date_sort_btn_white.svg';
import UserProfileImg from '../../assets/user-profile.svg';
import { getIntFromString } from "../../look";
import { displayUtcToUserTimezone, FEEDBACK_FETCH_QTY, getSelectedFeedbackType } from '../../look/components/functions';
import ReactMarkdown from '../../look/components/ReactMarkdown';
import FeedbackEditContainer from '../containers/FeedbackEditContainer';
import '../css/ReceivedFeedbackView.css';
import FeedbackDetailView from './ViewFeedbackPopUp';

const SubmittedFeedbackView = props => {
  const [openCustomPicker, setOpenCustomPicker] = React.useState(false)
  const [selectedValue, setSelectedValue] = React.useState([])
  const [feedbacks, setFeedbacks] = React.useState([]);
  const [hoverDate, setHoverDate] = useState(false)
  const antIcon = <LoadingOutlined style={{ fontSize: "34px", color: "#4CCB1F" }} spin />;
  const ref = React.useRef([]);
  const [isPagination, setIsPagination] = useState(true)

  const {
    me,
    queryObj,
    activeTab,
    feedbackPermission,
    pageCount,
    setQueryObj,
    onfilter,
    newKudosAdded,
    dataSource,
    submitLoading,
    setFeedbackModalVisible,
    searchBarData, feedbackEditModalVisible, setFeedbackEditModalVisible, getFeedbackDetails, deleteFeedback,
    setFeedbackPreviewDisplay, feedbackPreviewDisplay, setGivenKudos, setFeedBackById, feedbackDetails,
    feedBackTypeEnum, feedbackById, updateFeedback, givenKudos, createBadgeAward, setGivenBadgeAward, setNewKudosAdded,
    setUpdatedFeedback, setFeedbackDetails, feedbackDetailSide, setFeedbackDetailSide

  } = props;

  // const [feedbacks, setFeedbacks] = useState();
  const [feedbackSelected, setFeedbackSelected] = React.useState()
  const [deleteFeedbackModalVisible, setDeleteFeedbackModalVisible] = React.useState();
  const [activeBtn, setActiveBtn] = useState();
  const [loading, setloading] = React.useState(true);
  const [Recipient, setRecipient] = React.useState();
  const [moreCardsBtn, setMoreCardsBtn] = useState();
  const [cardViewQty, setCardViewQty] = useState(FEEDBACK_FETCH_QTY);
  const typingTimeoutRef = useRef(null);
  const [currentPage, setcurrentPage] = React.useState(1);
  const [isClear, setIsClear] = useState();
  const feedback_detail_ref = React.useRef()
  const [feedbackPreviewVisible, setFeedbackPreviewVisible] = React.useState(false);
  const [feedbackPreviewLoading, setFeedbackPreviewLoading] = React.useState(false);
  const [feedBackType, setFeedBackType] = React.useState();
  const [questionList, setquestionList] = React.useState([])
  const [answersList, setAnswers] = React.useState([]);
  const [okrid, setokrid] = React.useState();
  const [feedbackReceipient, setFeedbackReceipient] = React.useState(undefined);

  const handleFeedbackSelectionForDetails = (data) => {
    getFeedbackDetails(data?.id)
    setFeedbackModalVisible(true)
    setFeedbackDetailSide("submitted")
  }

  let currentFilter = React.useRef({});

  let dateFilter = React.useRef({ okr_Duedate_Range: [moment().startOf('year').utc().toISOString(), moment().endOf('year').utc().toISOString()] })

  const { RangePicker } = DatePicker;

  const [sortOption, setSortOption] = useState();
  const [customDateRange, setCustomDateRange] = useState([null, null]);

  // Filter feedbacks based on selected sort option and custom date range

  // Handle sort option change
  const handleSortOptionChange = (option) => {
    setActiveBtn(option)
    setSortOption(option);
  };

  // Handle custom date range change
  const handleCustomDateRangeChange = (range) => {
    setCustomDateRange(range?.okr_Duedate_Range);
  };

  const handleDeleteFeedback = async () => {
    if (feedbackSelected) {
      const res = deleteFeedback({ id: getIntFromString(feedbackSelected) })
      if (res) {
        setDeleteFeedbackModalVisible(false)
      }
    }
  }

  React.useEffect(() => {
    if (dataSource) {
      setFeedbacks(dataSource)
    }
  }, [dataSource?.length])

  const datefilter = (e) => {
    if (e && e?.length === 2) {

      let okr_Duedate_Range = [moment(e[0])?.startOf('day').utc().toISOString(), moment(e[1])?.endOf('day').utc().toISOString()]
      dateFilter['current'] = { okr_Duedate_Range }
      setcurrentPage(1)
      currentFilter.current['offset'] = 0
      handleCustomDateRangeChange({ ...dateFilter['current'], ...currentFilter['current'], from_search: true })
      let start_date = moment(e[0])?.startOf('day')
      let end_date = moment(e[1])?.endOf('day')
      setSelectedValue([start_date, end_date])



      localStorage.setItem('DateFilterOKR', JSON.stringify({ startDate: okr_Duedate_Range[0], endDate: okr_Duedate_Range[1] }))
    } else {
      dateFilter['current'] = {}
      setcurrentPage(1)
      currentFilter.current['offset'] = 0
      handleCustomDateRangeChange({ ...dateFilter['current'], ...currentFilter['current'], from_search: true })
      let start_date = moment(e[0])?.startOf('day')
      let end_date = moment(e[1])?.endOf('day')
      setSelectedValue([start_date, end_date])
    }
  }

  const dateChangeFilterData = (e) => {
    if (e && e?.length == 2) {
      if (e[0] !== null) {
        // setSelectedStartDte(e[0])
      }
    }
  }

  const custonFilter = () => {
    if (selectedValue?.length && selectedValue?.length == 2) {
      setActiveBtn("custom")
      handleSortOptionChange("custom")
      setOpenCustomPicker(false)
    }
    else {
      message.error("please select a date range")
    }
  }

  const handleCustomDate = (data) => {
    setOpenCustomPicker(true)
  }

  // the new sort code below this 

  //new code to sort with strings
  useEffect(() => {

    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }

    typingTimeoutRef.current = setTimeout(() => {

      if (searchBarData?.dropDown === "Feedback" && activeTab === 'submitted') {

        if (searchBarData?.input !== undefined && searchBarData?.input !== null && searchBarData?.input !== "") {

          if (queryObj) {

            let obj = queryObj;
            onfilter({
              ...obj,
              feedbackFrom: me?.employee?.id,
              orderBy: ["-createdAt", "-updatedAt"],
              okr_Goalstatement_Icontains: searchBarData?.input,
              feedbackTo_User_FirstName_Icontains: searchBarData?.input,
              feedbackTo_User_LastName_Icontains: searchBarData?.input,
              response_Answer_Answer_Icontains: searchBarData?.input,
              first: cardViewQty + FEEDBACK_FETCH_QTY
            });
            setQueryObj({
              ...obj,
              feedbackFrom: me?.employee?.id,
              orderBy: ["-createdAt", "-updatedAt"],
              okr_Goalstatement_Icontains: searchBarData?.input,
              feedbackTo_User_FirstName_Icontains: searchBarData?.input,
              feedbackTo_User_LastName_Icontains: searchBarData?.input,
              response_Answer_Answer_Icontains: searchBarData?.input,
              first: cardViewQty + FEEDBACK_FETCH_QTY
            });
          }
        }

        if (searchBarData?.input === "") {

          if (queryObj) {

            let obj = queryObj;

            onfilter({
              ...obj,
              feedbackFrom: me?.employee?.id,
              orderBy: ["-createdAt", "-updatedAt"],
              okr_Goalstatement_Icontains: searchBarData?.input,
              feedbackTo_User_FirstName_Icontains: searchBarData?.input,
              feedbackTo_User_LastName_Icontains: searchBarData?.input,
              response_Answer_Answer_Icontains: searchBarData?.input,
              first: cardViewQty + FEEDBACK_FETCH_QTY
            });
            setQueryObj({
              ...obj,
              feedbackFrom: me?.employee?.id,
              orderBy: ["-createdAt", "-updatedAt"],
              okr_Goalstatement_Icontains: searchBarData?.input,
              feedbackTo_User_FirstName_Icontains: searchBarData?.input,
              feedbackTo_User_LastName_Icontains: searchBarData?.input,
              response_Answer_Answer_Icontains: searchBarData?.input,
              first: cardViewQty + FEEDBACK_FETCH_QTY
            });
          }
        }
      }

    }, 600)

  }, [searchBarData?.input]);

  // new date sort code
  useEffect(() => {
    // setCardViewQty(12)

    const sortDebounce = setTimeout(() => {
      const now = new Date();
      const pastWeek = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000).toISOString();
      const pastMonth = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000).toISOString();
      const pastYear = new Date(now.getTime() - 365 * 24 * 60 * 60 * 1000).toISOString();
      const all = new Date(0).toISOString();

      let obj = queryObj
      let dateRangeArray = [];
      switch (sortOption) {

        case "noDate":
          dateRangeArray = [all, now.toISOString()];

          if (obj && activeTab === 'submitted') {
            onfilter({ ...obj, feedbackFrom: me?.employee?.id, createdAt_Range: dateRangeArray, first: FEEDBACK_FETCH_QTY });
            setQueryObj({ ...obj, feedbackFrom: me?.employee?.id, createdAt_Range: dateRangeArray, first: FEEDBACK_FETCH_QTY });
          } else {
            break;
          }
          break
        case "pastWeek":

          const startOfWeek = moment().subtract(1, 'weeks').startOf('week')
          const endOfWeek = moment().subtract(1, 'weeks').endOf('week')
        
          dateRangeArray = [startOfWeek, endOfWeek];
          if (obj && activeTab === 'submitted') {

            onfilter({ ...obj, feedbackFrom: me?.employee?.id, createdAt_Range: dateRangeArray, first:  FEEDBACK_FETCH_QTY});
            setQueryObj({ ...obj, feedbackFrom: me?.employee?.id, createdAt_Range: dateRangeArray, first: FEEDBACK_FETCH_QTY })
          } else {
            break;
          }

          break;
        case "pastMonth":

          const lastmonthFirstdate = moment().subtract(1, 'months').startOf('month')
          const lastmonthLastdate = moment().subtract(1, 'months').endOf('month')
          
          dateRangeArray = [lastmonthFirstdate, lastmonthLastdate];
          if (obj && activeTab === 'submitted') {
            onfilter({ ...obj, feedbackFrom: me?.employee?.id, createdAt_Range: dateRangeArray, first: FEEDBACK_FETCH_QTY });
            setQueryObj({ ...obj, feedbackFrom: me?.employee?.id, createdAt_Range: dateRangeArray, first: FEEDBACK_FETCH_QTY })

          } else {
            break;
          }

          break;
        case "pastYear":
          const lastYearFirstDate = moment().subtract(1, 'years').startOf('year')
          const lastYearLastDate = moment().subtract(1, 'years').endOf('year')

          dateRangeArray = [lastYearFirstDate, lastYearLastDate];
          if (obj && activeTab === 'submitted') {
            onfilter({ ...obj, feedbackFrom: me?.employee?.id, createdAt_Range: dateRangeArray, first: FEEDBACK_FETCH_QTY });
            setQueryObj({ ...obj, feedbackFrom: me?.employee?.id, createdAt_Range: dateRangeArray, first: FEEDBACK_FETCH_QTY })

          } else {
            break;
          }
          break;

        case "custom":

          dateRangeArray = [customDateRange[0], customDateRange[1]];

          if (obj && activeTab === 'submitted') {
            onfilter({ ...obj, feedbackFrom: me?.employee?.id, createdAt_Range: dateRangeArray, first: FEEDBACK_FETCH_QTY });
            setQueryObj({ ...obj, feedbackFrom: me?.employee?.id, createdAt_Range: dateRangeArray, first: FEEDBACK_FETCH_QTY })

          } else {
            break;
          }
          // }
          break;
        default:
          break;
      }
    }, 300)

    return () => clearTimeout(sortDebounce)

  }, [sortOption, customDateRange])

  // button press activators

  
  // callback function for asking for more cards
  function seeMore() {
    // console.log('obj obj obj', queryObj)

    if (searchBarData?.dropDown === "Feedback") {
      let obj = queryObj;
      delete obj["first"]
      onfilter({
        ...obj,

        first: cardViewQty + FEEDBACK_FETCH_QTY
      });
      setQueryObj({
        ...obj,
        first: cardViewQty + FEEDBACK_FETCH_QTY
      });
    }

    setCardViewQty(cardViewQty + FEEDBACK_FETCH_QTY)
  }

  // Card state worker
  useEffect(() => {
    console.log('searchBarData:', searchBarData, dataSource, pageCount);
    if (searchBarData?.input === null || searchBarData?.input === undefined) {
      setIsPagination(true)
      if (dataSource?.length >= pageCount?.totalCount) {
        setMoreCardsBtn(false)
      } else {
        setMoreCardsBtn(true)
      }
    } else {
      setIsPagination(false)
    }
  }, [pageCount, dataSource?.length])



  const handleKudosSubmit = async (createdFeedback) => {
    let isNewBadgeAward = createdFeedback?.badgeAward?.edges?.map((node) => getIntFromString(node?.node?.badge?.id)).includes(givenKudos?.badgeId)

    if (givenKudos && !isNewBadgeAward) {
      try {
        let badgeAwardData = {
          badgeId: givenKudos?.badgeId, evidence: givenKudos?.evidence, relatedToId: createdFeedback?.id,
          recipientId: getIntFromString(feedbackReceipient),
          notify: 'employee'
        }

        const res = await createBadgeAward({ badgeAwardData })
        if (res) {
          setGivenBadgeAward({ badgeAward: res })
          setNewKudosAdded(false)
          setFeedBackById(undefined)
          setGivenKudos(undefined)
        }
      } catch (error) {
        console.error(error);

      }
    }

  }




  const handleValidation = (type) => {
    if (feedbackReceipient && feedBackType) {
      let answerdata = []
      questionList.forEach(data => {
        if (!data?.questionAddress) {
          let answer = answersList.find(item => getIntFromString(item?.questionId) === getIntFromString(data?.id))
          answerdata.push(answer)
        }
        else {
          let typeValidations = data?.questionAddress?.split(':')
          if (typeValidations?.includes(feedBackType)) {
            let answer = answersList.find(item => getIntFromString(item?.questionId) === getIntFromString(data?.id))
            answerdata.push(answer)
          }
        }
      });


      let valid = true
      questionList.forEach(data => {
        if (!data?.questionAddress) {
          if (data?.required) {
            let answer = answerdata.find(item => getIntFromString(item?.questionId) === getIntFromString(data?.id) && item?.answer?.trim() !== '')
            if (!answer) {
              valid = false
            }
          }

        }
        else {
          let typeValidations = data?.questionAddress?.split(':')
          if (typeValidations?.includes(feedBackType) && data?.required) {
            let answer = answerdata.find(item => getIntFromString(item?.questionId) === getIntFromString(data?.id) && item?.answer?.trim() !== '')
            if (!answer) {
              valid = false
            }
          }
        }
      });
      if (valid) {
        handleSubmit(answerdata, type)
      }
      else {
        message.error('please fill in the required field')
      }
    }
    else {
      message.error('please fill in the required field')
    }
  }

  const handleModalClosureEditFeedback = () => {

    setFeedbackEditModalVisible(false);
    setGivenKudos(undefined);
    setAnswers([]);
    setFeedBackType(undefined);
    setFeedBackById(undefined);
    setFeedbackPreviewDisplay(false);

  }


  async function handleSubmit(value, type) {
    let selectedFeedbackType = getSelectedFeedbackType(feedBackTypeEnum, feedBackType)
    setloading(true)
    const response = await updateFeedback({
      id: getIntFromString(feedbackById?.id || feedback_detail_ref.current?.id),
      answers: value,
      detailFillIn: feedbackById?.detailFillIn || feedback_detail_ref.current?.detailFillIn,
      feedbackType: selectedFeedbackType,
      okrId: getIntFromString(okrid)
    });
    if (response && response?.id) {
      console.log(' response after hitting kudos mutation ::', response, givenKudos)
      if (givenKudos && newKudosAdded) {

        message.success('Kudos is given and feedback is updated Successfully')

      } else {
        message.success('Feedback is updated Successfully')

      }
      setloading(false)
      if (type !== "kudos") {
        setFeedbackPreviewVisible(false)
        setFeedBackById(undefined)
        handleKudosSubmit(response)
        setAnswers([]);
        handleModalClosureEditFeedback();
      }
      setUpdatedFeedback(response)


    } else {
      setloading(false)
    }
  }

  

  return (
    <>
      <Spin spinning={submitLoading} size='small' indicator={antIcon}>
        <div className='feedback-date-sort-container'>
          <button className={activeBtn === "pastWeek" ? 'clicked-date-btn pointer' : 'unclicked-date-btn pointer'} onClick={() => handleSortOptionChange('pastWeek')}>Past Week</button>
          <button className={activeBtn === "pastMonth" ? 'clicked-date-btn pointer' : 'unclicked-date-btn pointer'} onClick={() => handleSortOptionChange('pastMonth')}>Past Month</button>
          <button className={activeBtn === "pastYear" ? 'clicked-date-btn pointer' : 'unclicked-date-btn pointer'} onClick={() => handleSortOptionChange('pastYear')}>Past Year</button>
          <button className={activeBtn === "custom" ? 'clicked-date-btn pointer' : 'unclicked-date-btn pointer'} style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }} onMouseEnter={() => setHoverDate(true)} onMouseLeave={() => setHoverDate(false)} onClick={() => handleCustomDate("custom")}>Custom <img style={{ width: '17px', height: '17px', marginLeft: '10px' }} src={activeBtn === "custom" ? CustomSortImgWhite : CustomSortImg} alt="dateimg" /></button>
          <Tooltip title={'Clear Filter'}>
          <button className={isClear ? 'clicked-date-btn pointer' : 'unclicked-date-btn pointer'} onMouseDown={() => setIsClear(true)} onMouseUp={() => setIsClear(false)} onClick={() => handleSortOptionChange("noDate")}>
            Clear
          </button>
          </Tooltip>
    
         
          <div className="row-wrap" style={{ marginTop: "-29px", marginBottom: "30px" }}>
            <Modal
              footer={null}
              centered
              visible={openCustomPicker}
              destroyOnClose={true}
              closable={false}
            >
              <div style={{ height: "330px", width: "100%", position: "relative", padding: "10px 10px" }}>
                <RangePicker
                  open={openCustomPicker ? openCustomPicker : false}
                  allowClear={false}
                  dropdownClassName={'custom-meeting-picker'}
                  className="date-range-meeting"
                  value={selectedValue}
                  onChange={(e) => datefilter(e)}
                  onCalendarChange={(e) => { dateChangeFilterData(e) }}
                  disabledDate={current => {
                    return current.isAfter(moment())
                  }}
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                />
                <div style={{ position: "absolute", bottom: "0px", width: "100%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "10px", marginTop: "20px" }}>
                  <Button className='cancel-date-btn' onClick={() => { setOpenCustomPicker(false); setSelectedValue([]) }}>Cancel</Button>
                  <Button className='save-date-btn' onClick={() => custonFilter()}>Apply</Button>
                </div>
              </div>
            </Modal>
          </div>
        </div>
        {dataSource?.length === 0 ? <Row style={{ height: '700px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><h1>{submitLoading ? '' : 'No feedbacks available'}</h1></Row> : <div className='feedback-sub-cards-container height-fix-submitted adjust-margin-feedback'>
          {dataSource?.map(item => {
            let feedbackUserName = item?.feedbackTo?.user?.firstName + " " + item?.feedbackTo?.user?.lastName.slice(0, 1).toUpperCase()

            const feedBackRawText = item?.response?.answerSet?.edges?.map(itm => itm)?.map(item => item?.node?.answer)?.join("")

            return (
              <div className="card-border-container" onClick={(e) => handleFeedbackSelectionForDetails(item)}>
                <div className='feedback-image-title-cont'>
                  <img src={item?.feedbackTo?.user?.profile?.profileImage === null ? UserProfileImg : item?.feedbackTo?.user?.profile?.profileImage} alt="img" className='feedback_card_picture' />
                  <div className='feedback-sub-card-title'>
                    <div className='feedback-sub-main-btns' onClick={(e) => e.stopPropagation()} >
                      <img src={DeleteBtn} onClick={(e) => { setDeleteFeedbackModalVisible(true); setFeedbackSelected(item?.id) }} alt="delete-card" className='card-delete-btn-image' />
                      <img src={EditBtn} onClick={(e) => { setFeedbackEditModalVisible(true); getFeedbackDetails(item?.id) }} alt="edit-card" className='card-edit-btn-image' />
                      {/* <img src={}  /> */}
                      {/* <Button><PlusOutlined   fill='red'/></Button> */}
                    </div>
                    <h3 className='feedback-card-from-text'>To</h3>
                    <div className='feedback-sub-from-section'>

                      <h1 className='feedback-sub-name-text'>{feedbackUserName.slice(0, 12)}</h1>
                      {
                        item?.badgeAward?.edges?.length > 0 &&
                        item?.badgeAward?.edges?.map((award) =>
                          feedbackPermission?.kudos_permission ? <img src={award?.node?.badge?.image} alt="kudos_badge_img" style={{ height: '35px', width: '40px', borderRadius: '50%' }} className='feedback-sub-kudos-image' /> : null
                        )
                      }
                    </div>
                    <Row style={{ gap: '7px' }} className='received-on-section'>
                      <p className='received-on-text'>Submitted On:</p>
                      <p className='received-on-date'>{displayUtcToUserTimezone(item?.createdAt, "DD MMM YYYY")}</p>
                    </Row>
                  </div>

                </div>
                <h5 className="feedback-rec-detail-text feedback-sub-detail-text">
                    <ReactMarkdown className="markdown-text-container">{feedBackRawText}</ReactMarkdown>
                  </h5>
              

              </div>

            )
          })}
          <div className="center-item" style={{ width: 'max-content', display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
            {moreCardsBtn && !submitLoading && isPagination && <Row justify="center" align="middle" style={{ width: "100%", marginBottom: '-1em 0 1em 0' }}>
              <h4 className="load-more-title-feedback" onClick={() => seeMore()}>Load More</h4>
            </Row>}
          </div>
        </div>}

        <div className='feedback-modal'>
          <Modal visible={feedbackEditModalVisible}
            footer={null}
            centered
            destroyOnClose={true}
            closable={false}
            style={{minWidth:"40em"}}>
            <FeedbackEditContainer feedbackPermission={feedbackPermission} feedbackEditModalVisible={feedbackEditModalVisible}
              setFeedbackEditModalVisible={setFeedbackEditModalVisible} feedBackTypeEnum={feedBackTypeEnum}
              loading={loading} setloading={setloading} feedBackType={feedBackType}
              setokrid={setokrid} setFeedBackType={setFeedBackType} setAnswers={setAnswers}
              feedback_detail_ref={feedback_detail_ref} setquestionList={setquestionList} answersList={answersList}
              questionList={questionList} Recipient={Recipient} handleValidation={handleValidation}
              handleModalClosureEditFeedback={handleModalClosureEditFeedback} setGivenBadgeAward={setGivenBadgeAward}
              setFeedbackPreviewVisible={setFeedbackPreviewVisible}
              setFeedbackReceipient={setFeedbackReceipient}
              {...props}></FeedbackEditContainer>
          </Modal>
        </div>

        <Modal
          footer={null}
          centered
          visible={deleteFeedbackModalVisible}
          destroyOnClose={true}
          closable={false}>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", position: "relative" }}>
            <img src={Agenda_close} alt="" style={{ position: "absolute", top: "0px", right: "0px", cursor: "pointer" }} onClick={() => setDeleteFeedbackModalVisible(false)} />
            <h2 className="feedback-popup-head">Warning</h2>
            <p className="feedback-popup-para" style={{ marginTop: "10px" }}>Are you sure you want to delete this feedback ?</p>
            <Row justify="center" align="center" style={{ gap: "10px", marginTop: "10px" }}>
              <Button onClick={(e) => { setDeleteFeedbackModalVisible(false); }} className="feedback-cancel-btn-modal">Cancel</Button>
              <Button onClick={(e) => handleDeleteFeedback()} className="feedback-save-btn-modal" style={{ background: '#E86161', borderColor: '#E86161' }} >Delete</Button>
            </Row>
          </div>
        </Modal>




        <Modal visible={feedbackPreviewVisible}
          footer={null}
          centered
          destroyOnClose={true}
          closable={false}
        >
          <Spin spinning={feedbackPreviewLoading}>
            <FeedbackDetailView feedbackPermission={feedbackPermission} feedbackPreviewDisplay={feedbackPreviewDisplay}
              setFeedbackModalVisible={setFeedbackPreviewVisible}
              feedbackDetails={feedbackDetails}
              setFeedbackDetails={setFeedbackDetails}
              setFeedbackPreviewDisplay={setFeedbackPreviewDisplay} me={me}
              setFeedbackEditModalVisible={setFeedbackEditModalVisible}
              handleValidation={handleValidation}
              feedbackDetailSide={feedbackDetailSide}
              setFeedbackPreviewDisplay={setFeedbackPreviewDisplay}
              editFeedbackPreview={true}
            />
          </Spin>
        </Modal>
      </Spin>
    </>
  );
}

export default SubmittedFeedbackView;

