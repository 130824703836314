import React from 'react';
import { Link } from 'react-router-dom';
import { Input, Divider, Spin, Col, Row, Button, Modal } from 'antd';

import { compose } from '../../core';
import { getIntFromString, ReactMarkdown, CardLayout, RenderTableWithLayout, TeamEmployeeField } from '../../look';
import { IMG } from '../../../config';
import ROUTE from '../route';
import { withMeLevel } from '../../user/containers/UserOperations';
import {
    withOrganizationState,
    withOrganizationFilterUpdating,
  /* withTeam, */ withTeamByTeamId,
    withDeleteTeam,
    withEditTeam
} from '../../organization/containers/OrganizationOperation';

const TeamDetail = props => {
    const { history, editTeam, loading,Team, deleteTeam, onFiltersRemove, level } = props;
    console.log("team",Team)
    const [employee, setEmployee] = React.useState(false);

    const handleEmployeeSubmit = async employees => {
        try {
            await editTeam({ id: getIntFromString(Team.id), employees, name: Team.name });
        } catch (e) {
            throw new Error(e.message);
        }
    };

    return (
        <CardLayout
            active={ROUTE.team}
            title={[
                {
                    name: `${Team?.vertical?.organization?.name || ''}`,
                    link: `${ROUTE.organizationDetailLink}${Team &&
                        Team.vertical &&
                        Team.vertical.organization &&
                        getIntFromString(Team.vertical.organization.id)
                        }`
                },
                {
                    name: `${Team?.vertical?.name || ''}`,
                    link: `${ROUTE.verticalDetailLink}${Team && Team.vertical && getIntFromString(Team.vertical.id)
                        }`
                },
                {
                    name: `${Team?.name || ''}`,
                    link: `${ROUTE.teamDetailLink}${Team && getIntFromString(Team.id)}`
                }
            ]}
            showTitle={false}
            sidebar={true}
            editLink={ROUTE.editTeamLink}
            addLink={ROUTE.addTeam}
            backLink={`${ROUTE.verticalDetailLink}${Team && Team.vertical && getIntFromString(Team.vertical.id)
                }`}
            onFiltersRemove={onFiltersRemove}
            table={false}
            onEdit={level?.emp?.teamManagerEmployee?.edges.length ? false : true}
            onDelete={() => {
                deleteTeam({id:getIntFromString(Team.id)});
                history.push(
                    `${ROUTE.verticalDetailLink}${Team && Team.vertical && getIntFromString(Team.vertical.id)}`
                );
            }}
            rowKey={Team && Team.id}
        >
            <Row style={{ marginTop: '10px' }}>
                <Col span={23}>
                    <Spin spinning={loading} size="large">
                        <div style={{ minHeight: '70vh' }}>
                            {Team && (
                                <>
                                    <Row gutter={[24, 24]} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        {level?.emp?.teamManagerEmployee?.edges.length ? <Col>
                                            <Row>
                                                <Button type="primary" ghost onClick={() => setEmployee(true)}>
                                                    <Link>Add /Edit Employees</Link>
                                                </Button>
                                            </Row>
                                        </Col> : null}
                                        {level?.emp?.teamManagerEmployee?.edges.length ? <Col>
                                            <Row>
                                                <Button type="primary" ghost >
                                                    <Link to={`${ROUTE.organizationReport.replace(':id', getIntFromString(Team.id))}`}>Reports</Link>
                                                </Button>
                                            </Row>
                                        </Col> : null}

                                        {/* <Col span={1}>
                                            <Divider type="vertical" style={{ height: '100%' }} />
                                        </Col> */}
                                        <Col>
                                            <Row justify="space-between">
                                                <Col>
                                                    <h3>Manager</h3>
                                                </Col>
                                                {/* <Col>
                        <Button type="link">Edit</Button>
                      </Col> */}
                                            </Row>

                                            {Team?.managers?.edges?.map(({ node }) => (
                                                <Row gutter={[24, 24]}>
                                                    <Col>
                                                        <img
                                                            src={IMG}
                                                            alt=""
                                                            style={{
                                                                width: 70,
                                                                height: 80,
                                                                backgroundColor: '#f3f3f3'
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <h4 style={{ marginBottom: 0, fontWeight: 'normal' }}>Name</h4>
                                                        <h4
                                                            style={{
                                                                marginBottom: 0,
                                                                fontWeight: 'normal',
                                                                color: '#898A82'
                                                            }}
                                                        >
                                                            {`${node?.user?.firstName} ${node?.user?.lastName}`}
                                                        </h4>
                                                        <h4 style={{ marginBottom: 0, fontWeight: 'normal' }}>Email</h4>
                                                        <h4
                                                            style={{
                                                                marginBottom: 0,
                                                                fontWeight: 'normal',
                                                                color: '#898A82'
                                                            }}
                                                        >
                                                            {node?.user?.email}
                                                        </h4>
                                                    </Col>
                                                </Row>
                                            ))}
                                            <br />
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'baseline'
                                        }}
                                    >
                                        <h3 style={{ marginBottom: 0, fontWeight: 'normal' }}>Description</h3>
                                        {/* <Button type="link">Edit</Button> */}
                                    </Row>
                                    <div
                                        style={{
                                            border: '1px solid rgba(202, 202, 202, 0.25)',
                                            margin: '7px',
                                            padding: '15px',
                                            minHeight: '140px',
                                            borderRadius: '2px'
                                        }}
                                    >
                                        <ReactMarkdown>{Team.description}</ReactMarkdown>
                                    </div>
                                    <Row>
                                        <Divider style={{ width: '100%' }} />
                                    </Row>
                                    <br />
                                    <Row gutter={[24, 24]}>
                                        {Team &&
                                            Team.employees.edges.map(({ node }) => (
                                                <Col>
                                                    <img src={IMG} alt="" style={{ width: 48, borderRadius: 30, marginRight: 10 }} />{' '}
                                                    {`${node.user.firstName} ${node.user.lastName}`}
                                                </Col>
                                            ))}
                                    </Row>
                                </>
                            )}
                        </div>
                    </Spin>
                </Col>
            </Row>

            <Modal
                destroyOnClose
                visible={employee}
                onCancel={() => setEmployee(false)}
                footer={null}
                title="Employee"
                width="25%"
                bodyStyle={{ padding: 12 }}
            >
                <Row>
                    {Team && Team.employees && (
                        <TeamEmployeeField
                            verId={getIntFromString(Team?.vertical?.id)}
                            initialValues={Team.employees}
                            handleSubmit={handleEmployeeSubmit}
                        />
                    )}
                </Row>
                <br />

                {Team &&
                    Team.employees &&
                    Team.employees.edgeCount > 0 &&
                    Team.employees.edges.map(({ node }) => (
                        <Row
                            justify="space-between"
                            style={{
                                paddingTop: 10,
                                display: 'block',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}
                        >
                            <img src={IMG} alt="" style={{ width: 48, borderRadius: 30, marginRight: 10 }} />{' '}
                            {`${node.user.firstName} ${node.user.lastName}`}
                        </Row>
                    ))}
            </Modal>
        </CardLayout>
    );
};

export default compose(
    withOrganizationState,
    // withTeam,
    withDeleteTeam,
    withTeamByTeamId,
    withOrganizationFilterUpdating,
    withEditTeam,
    withMeLevel
)(TeamDetail);
