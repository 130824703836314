
import React from "react";
import { compose } from "../../core";
import { withApollo } from '@apollo/client/react/hoc';
import SurveyComparisonRequestView from "../Components/sureveyComparisonRequestView";
import { USER_MANUAL_EMPLOYEE_LIST } from "../graphql/survey-request-employee-list.gql";
import { createResponseCompareRequest } from "./lookV2Operation";
const SurveyComparisonRequestContainer = (props) =>{
    const { client,survey } = props
    const [user_list_info,setUserListInfo] = React.useState([])
    React.useEffect(()=>{
        if(survey){
            getOrgEmployee()
        }
    },[survey])

    const getOrgEmployee = async (filter)=>{
        const { data } = await client.query({
            query:USER_MANUAL_EMPLOYEE_LIST,
            fetchPolicy: 'network-only',
            variables: filter
          });
          if(data?.me){
            setUserListInfo([].concat([{...data?.me?.employee?.organizationSet?.edges[0]?.node?.employees,pagination:filter?.pagination}]))
          }
    }

    return (
        <>
            <SurveyComparisonRequestView getOrgEmployee={(e)=>getOrgEmployee(e)} user_list_info={user_list_info} {...props} />
        </>
    )
}

export default compose(withApollo,createResponseCompareRequest)(SurveyComparisonRequestContainer);
