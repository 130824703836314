import gql from 'graphql-tag';

export const UNREAD_NOTIFICATION_MUTATION = gql`
  mutation unreadNotification($id: ID!) {
    unreadNotification(
      id:$id
        ) {
        notification{
            id
            
        }
    }
  }
`;
