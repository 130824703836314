import gql from 'graphql-tag';
import { DEFAULT_COMMITMENTS_PROGRESS_TYPE } from './DefaultCommitmentProgressType.gql';

export const ADD_SELECT_DEFAULT_TOOL_COMMITMENT = gql`
  ${DEFAULT_COMMITMENTS_PROGRESS_TYPE}
  mutation addSelectDefaultToolCommitment($defaultCommitmentProgressData: DefaultCommitmentProgressInput!) {
    selectDefaultToolCommitment(defaultCommitmentProgressData: $defaultCommitmentProgressData) {
      defaultCommitmentProgress {
        ...DefaultCommitmentProgressType
      }
    }
  }
`;
