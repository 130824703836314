import React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import gql from 'graphql-tag';
import { Empty, Spin } from 'antd';

import { compose } from '../../../core';
import { getIntFromString, getUniqueListBy } from '../functions';
import { withLookToolFilterUpdating, withLookToolState } from '../../containers/LookOperations';

import SelectField from './SelectField';

export const ALL_PUBLIC_TOOLS_QUERY = gql`
  query getAllPublicTools($first: Int, $orderBy: [String], $title_Icontains: String) {
    getAllPublicTools(first: $first, orderBy: $orderBy, title_Icontains: $title_Icontains) {
      edges {
        node {
          id
          title
        }
      }
    }
  }
`;

export const GET_ALL_ORG_LVL_TOOLS_QUERY = gql`
  query getAllOrgLevelTools($first: Int, $orderBy: [String], $title_Icontains: String) {
    getAllOrgLevelTools(first: $first, orderBy: $orderBy, title_Icontains: $title_Icontains) {
      edges {
        node {
          id
          title
        }
      }
    }
  }
`;

export const GET_ALL_VERTICAL_LVL_TOOLS_QUERY = gql`
  query getAllVerticalLevelTools($first: Int, $orderBy: [String], $title_Icontains: String) {
    getAllVerticalLevelTools(first: $first, orderBy: $orderBy, title_Icontains: $title_Icontains) {
      edges {
        node {
          id
          title
        }
      }
    }
  }
`;

export const GET_ALL_TEAM_LVL_TOOLS_QUERY = gql`
  query getAllTeamLevelTools($first: Int, $orderBy: [String], $title_Icontains: String) {
    getAllTeamLevelTools(first: $first, orderBy: $orderBy, title_Icontains: $title_Icontains) {
      edges {
        node {
          id
          title
        }
      }
    }
  }
`;

const ToolCommitmentAutoComplete = props => {
  const {
    loading,
    getAllPublicTools,
    getAllOrgLevelTools,
    getAllVerticalLevelTools,
    getAllTeamLevelTools,
    margin,
    onTitleChange,
    onFiltersRemove
  } = props;
  const handleFilterRemove = React.useRef(() => {});

  handleFilterRemove.current = () => {
    onFiltersRemove();
  };

  React.useEffect(() => {
    return () => handleFilterRemove.current();
  }, []);

  const choices =
    !loading &&
    getUniqueListBy(
      [].concat.apply(
        [],
        [
          getAllPublicTools?.edges?.map(({ node }) => ({ label: node.title, value: getIntFromString(node.id) })) || [],
          getAllOrgLevelTools?.edges?.map(({ node }) => ({ label: node.title, value: getIntFromString(node.id) })) || [],
          getAllVerticalLevelTools?.edges?.map(({ node }) => ({ label: node.title, value: getIntFromString(node.id) })) ||
            [],
          getAllTeamLevelTools?.edges?.map(({ node }) => ({ label: node.title, value: getIntFromString(node.id) })) || []
        ]
      ),
      'value'
    );

  return (
    <SelectField
      margin={margin}
      showSearch
      choices={choices}
      offset={1}
      style={{ width: '100%', marginBottom: !margin && 0 }}
      // labelInValue
      filterOption={false}
      onSearch={onTitleChange}
      notFoundContent={
        loading ? (
          <div align="center">
            <Spin size="small" />
          </div>
        ) : (
          <Empty />
        )
      }
      {...props}
    />
  );
};

export default compose(
  withLookToolState,

  graphql(ALL_PUBLIC_TOOLS_QUERY, {
    options: ({ filter, orderBy }) => {
      return { variables: { ...filter, orderBy } };
    },
    props({ data }) {
      const { loading, error, getAllPublicTools, subscribeToMore, updateQuery } = data;
      return { loading, error, getAllPublicTools, subscribeToMore, updateQuery };
    }
  }),

  graphql(GET_ALL_ORG_LVL_TOOLS_QUERY, {
    options: ({ filter, orderBy }) => {
      return { variables: { ...filter, orderBy } };
    },
    props({ data }) {
      const { loading, error, getAllOrgLevelTools, subscribeToMore, updateQuery } = data;
      return { loading, error, getAllOrgLevelTools, subscribeToMore, updateQuery };
    }
  }),

  graphql(GET_ALL_VERTICAL_LVL_TOOLS_QUERY, {
    options: ({ filter, orderBy }) => {
      return { variables: { ...filter, orderBy } };
    },
    props({ data }) {
      const { loading, error, getAllVerticalLevelTools, subscribeToMore, updateQuery } = data;
      return { loading, error, getAllVerticalLevelTools, subscribeToMore, updateQuery };
    }
  }),

  graphql(GET_ALL_TEAM_LVL_TOOLS_QUERY, {
    options: ({ filter, orderBy }) => {
      return { variables: { ...filter, orderBy } };
    },
    props({ data }) {
      const { loading, error, getAllTeamLevelTools, subscribeToMore, updateQuery } = data;
      return { loading, error, getAllTeamLevelTools, subscribeToMore, updateQuery };
    }
  }),

  withLookToolFilterUpdating
)(ToolCommitmentAutoComplete);
