import gql from 'graphql-tag';

export const REGISTER = gql`
  mutation register($email: String!, $firstName: String, $lastName: String, $username: String!) {
    register(email: $email, firstName: $firstName, lastName: $lastName, username: $username) {
      success
      errors
    }
  }
`;
