import { UPDATE_TIP_MUTATION } from "../query/ai-tip-query.gql";
import { graphql } from '@apollo/client/react/hoc';
import { message } from "antd";
import _ from 'lodash';

export const withUpdateAiTip = Component =>
    graphql(UPDATE_TIP_MUTATION, {
        props: ({ mutate, history }) => ({
            updateRunpodtips: async values => {
                try {
                    const {
                        data: { updateRunpodtips }
                    } = await mutate({
                        variables: {
                            ...values
                        }
                    });

                    message.destroy();
                    return updateRunpodtips.runpodtips;
                } catch (e) {
                    message.destroy();
                    message.error("Couldn't perform the action");
                    console.error(e);
                }
            }
        })
    })(Component);
