// @ts-nocheck
import React from 'react';
import {Row, Col} from 'antd';
import {displayUtcToUserTimezone, getIntFromString, parseMarkDownText, ReactMarkdown, withMarkdownFieldValue } from '../../look'
import styled from 'styled-components';
import reply from '../../assets/reply.svg'
import messageSend from '../../assets/messageSend.svg'
import cross from '../../assets/cross.svg'
import moment from 'moment';
const MessageView = props => {
  const { okrMessages, createOkrMessage, me, disabled,okrId,setChatBox,updatedataRef } = props;
  const [messageList, setMessageList] = React.useState([]);
  const [typedMessage, setTypedMessage] = React.useState('');
  const [replayTo, setreplayTo] = React.useState();
  const [okrContributors,setOkrContributors] = React.useState([]);

  function groupedDays(messages) {
   
    return messages?.edges?.reduce((acc, el, i) => {
     
      const messageDay = moment(el?.node?.createdAt).format('DD MMM YYYY');
      
      if (acc[messageDay]) {
        return { ...acc, [messageDay]: acc[messageDay].concat([el]) };
      }
      return { ...acc, [messageDay]: [el] };
    }, {});
  }


    const getItems = (data) =>{
      const days = groupedDays(data);
     
      const sortedDays = Object.keys(days).sort(
        (x, y) =>  moment(x, 'DD MMM YYYY').unix() - moment(y, 'DD MMM YYYY').unix() 
      );
      const items = sortedDays.reduce((acc, date) => {
        const sortedMessages = days[date].sort(
          (x, y) =>  new Date(x.createdAt) -  new Date(y.createdAt)
        );
 

        return acc.concat([{ type:(moment.utc(date).isAfter(moment().startOf('day'))) ? 'Today': 'day' , date, id: date },...sortedMessages]);
      }, []);
        
        
      return items;
    }
  

  React.useEffect(() => {
    
    if (okrMessages ) {
      var setObj = new Set(); // create key value pair from array of array
      var unique = okrMessages?.edges.reduce((acc,item)=>{
        if(!setObj.has(item.node?.id)){
          setObj.add(item.node?.id)
          acc.push(item)
        }
        return acc;
      },[])
      let result = {edges:unique}
      const items =  getItems(result)
      setMessageList(items)
      let chatParticipants = result?.edges[0]?.node?.okr?.contributors?.edges?.map((node)=>
        `${node?.node?.user?.firstName}`+ ' ' + `${node?.node?.user?.lastName}` 
      )
      if(chatParticipants){
        setOkrContributors(chatParticipants.join(','))
      }
       
    }

  }, [okrMessages])






  React.useEffect(() => {
    if (messageList.length > 0) {
      let d = document.getElementById(`${"msg" + String(messageList.length - 1)}`)
      if (d) {
        d.scrollIntoView({ block: "end", inline: "nearest" })
      }
    }
  }, [messageList])


  async function handleSubmit() {

    try {
      let data = withMarkdownFieldValue('message', { typedMessage })
      if (data?.typedMessage) {
        let response
        let messageData = { message: data?.typedMessage, okrId: getIntFromString(okrId) }
        
        if (replayTo) {
          messageData['replyToId'] = getIntFromString(replayTo?.node?.id)
        }
        response = await createOkrMessage(messageData);
      
        if (response && response?.id) {
          // let data = messageList.concat({node:response})
          // setMessageList(data)
          setTypedMessage('')
          setreplayTo(null)
          updatedataRef(response)
        }
      }

    } catch (err) {
    }
  }

  const setreplay = (data) => {
    setreplayTo(data)
  }

 const getContainerHeight=()=>{
 
  let window_height = window.innerHeight
  let height = window_height-200
  return  (!height || isNaN(height))?'500px':`${height}px`
  
 }

 const getmessageListHeight=()=>{
  let window_height = window.innerHeight
  let height = window_height-390
  return (!height || isNaN(height))?'290px':`${height}px`
 }

 const chatBoxKeyup=(event)=>{
  if(event?.keyCode==13){
    handleSubmit()
  }
 }

  return (
    <>
      <div className='chat-container-div' style={{height:getContainerHeight(),position:"relative"}} onClick={(e)=>{setChatBox(true);e.stopPropagation()}}>
          <div align='center' style={{ width:'90%',margin:'30px 30px 10px 30px',overflow:"hidden"}}>
            <p style={{fontSize: '18px',whiteSpace:"nowrap",textOverflow:"ellipsis",fontWeight:'500',width:"100%",overflow:"hidden"}}>{okrContributors}</p>
          </div>
            <ColScroll style={{ height:getmessageListHeight(),overflow: 'auto', padding: '10px 20px' }} id="msgCon">
              {
                messageList && me && (
                  messageList?.map((item, idx) =>

                   
                      
                       item.type ?
                       <div align='center' className='poppins'>{item?.type === 'Today' ? 'Today' : item?.date}</div>
                        :
                        item?.node?.sender?.user?.profile?.id === me?.profile?.id ?
                          <div key={idx} style={{ display: 'flex', justifyContent: 'end',borderRadius:'30px' }} >
                            <MessageContainer style={{ maxWidth: '90%', width: 'fit-content',background:'#4CCB1F',fontFamily:'Poppins',fontSize:'14px',color:"#FFFFFF" ,fontWeight:'400'}} id={`${'msg' + String(idx)}`}>
                              {
                                item?.node?.replyTo?.message && (
                                  <div className='display-msg-reply'>
                                    <h4 style={{margin:'0px',fontSize:"13px",color:"#6D0DE8" }}>{item?.node?.replyTo?.sender?.user?.firstName}</h4>
                                    <ColScroll style={{maxHeight:"100px",overflow:"auto"}}>
                                    <ReactMarkdown style={{color:"rgba(0, 0, 0, 0.8956)"}}>
                                      {item?.node?.replyTo?.message}
                                    </ReactMarkdown>
                                    </ColScroll>
                                   
                                  </div>
                                )
                              }
                              <Col >
                                <ReactMarkdown style={{ width: '100%',color:"#FFFFFF" ,marginBottom:'10px',fontWeight:'400'}} >
                                  {item?.node?.message}
                                </ReactMarkdown>
                                <h6 style={{float:"right", color:"#FFFFFF"}}>{displayUtcToUserTimezone(item?.node?.createdAt,'hh:mm A')}</h6>
                              </Col>
                            </MessageContainer>
                          </div>
                          :
                          <div key={idx} style={{
                            display: 'flex', justifyContent: 'start',alignItems:'center',borderRadius:'30px'
                          }}>
                             
                            <MessageContainer style={{ maxWidth: '90%', width: 'fit-content',background:'#F3F3F3',border:'none' ,fontFamily:'Poppins',fontSize:'14px',marginRight:'15px'}} id={`${'msg' + String(idx)}`}>
                              {
                                item?.node?.replyTo?.message && (
                                  <div className='display-msg-reply'>
                                  <h4 style={{margin:'0px',fontSize:"13px",color:"#6D0DE8" }}>{item?.node?.replyTo?.sender?.user?.firstName}</h4>
                                  <ColScroll style={{maxHeight:"100px",overflow:"auto"}}>
                                  <ReactMarkdown style={{color:"rgba(0, 0, 0, 0.8956)"}}>
                                    {item?.node?.replyTo?.message}
                                    
                                  </ReactMarkdown>
                                  </ColScroll>
                                 
                                </div>
                                )
                              }
                              <Col style={{padding:'5px 5px auto 5px'}}>
                                <h5>{item?.node?.sender?.user?.firstName}</h5>
                                <ReactMarkdown style={{ width: '100%' }}>
                                  {item?.node?.message}
                                </ReactMarkdown>
                                <h6 style={{float:"right"}}>{displayUtcToUserTimezone(item?.node?.createdAt,'hh:mm A')}</h6>
                               
                              </Col>

                            </MessageContainer>
                            
                            
                            <img src={reply} alt=""  onClick={(e) => { setreplay(item) }} style={{height:'10px',width:'10px',cursor:"pointer"}}/>
                                  
                           
                          </div>
                      

       

                  )
                )
              }
              {
                !messageList?.length && (
                  <h5 style={{ textAlign: 'center', fontSize: '20px' }}> Start Your Conversation</h5>
                )
              }
            </ColScroll>
                   
            <MessageInput className='new-chat-message-box' >
              <Col style={{ width: '100%'}} >
                {
                replayTo &&
                 (
                  <div style={{margin:'10px 10px 0px 10px'}} >
                    <ReplyRow style={{background:'#F3F3F3'}} className="new-reply-box" >
                      <div style={{width:"100%",overflow:"hidden"}}>
                        <h4 style={{fontFamily: 'Poppins',margin:'0px',fontSize:"13px",color:"#6D0DE8"}}> {replayTo?.node?.sender?.user?.firstName}</h4>
                        <p style={{fontFamily: 'Poppins',fontSize:"15px",margin:'0px',whiteSpace:"nowrap",textOverflow:"ellipsis",width:"100%",overflow:"hidden"}} >{parseMarkDownText(replayTo?.node?.message)}</p>
                      </div>  
                     
                      <img  src={cross} alt="" onClick={(e) => { setreplayTo(null) }}  style={{marginRight:'10px',cursor:'pointer'}} />
                    </ReplyRow>
                  </div>
                )}
                
                <div className={replayTo?'bottom-radius':'cornor-radius'} style={{border: '1px solid #D9D9D9',height:"45px",width:'95%',margin:`${replayTo?'0px':'10px'} 10px 10px 10px`,position:"relative"}}>
                  <input autoComplete='off' style={{border: 'none',outline:'none',width:"100%",height:"40px",padding:"0px 50px 0px 5px"}} placeholder='Write a message...' onKeyUp={(e)=>{chatBoxKeyup(e)}}  disabled={disabled} value={typedMessage}  onChange={(e) => { setTypedMessage(e.target.value) }}/>
                  <img onClick={(e)=>handleSubmit()}  src={messageSend} style={{cursor:"pointer",position:"absolute",right:"10px",top:"10px"}} alt=""/>
                </div>
            
              </Col>
            </MessageInput>
      </div>
    </>
  );
};

export default MessageView



const MessageInput = styled(Row)`
margin-top:20px;
display:flex;
flex-direction:row;
gap:15px;
flex-wrap:nowrap;
align-items:end
`
const MessageContainer = styled(Row)`
margin-top:10px;
display:flex;
flex-direction:column;
gap:15px;
flex-wrap:nowrap;
border:1px solid #B0CB1F;
padding:7px;
border-radius:5px
`

const ReplyRow = styled(Row)`
display:flex;
flex-direction:row;
gap:15px;
flex-wrap:nowrap;
padding:5px;
`

const ColScroll = styled(Col)`
::-webkit-scrollbar {
  height: 0px;
  width: 4px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #e0e0e0;
  border-radius: 10px;
  padding-right: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color:#B0CB1F;
}
`