import React from "react";
import { withSurveyByIdNewOptimized } from './SurveyOperations'
import TakeTestSurveyView from '../components/checkboxSurvey'
import { compose } from "../../core";
import SurveyTermsAndCondition from "../components/SurveyTermsAndCondition";
import { useHistory } from "react-router-dom";
import NoPermissionView from "modules/kudos-user/component/NoPermissionComponent";

const TakeTestSurveyContainer = (props) => {
    const {SurveyById,loading } = props;
    const history = useHistory();
    const [showTermsAndCondition, setshowTermsAndCondition] = React.useState();
    React.useEffect(()=>{
        if(SurveyById && SurveyById?.termsConditions){
          setshowTermsAndCondition(true)
        }
   
     },[SurveyById])

    
    return (
      <div>
        {!loading&&SurveyById==null?
        <NoPermissionView content={"You Don't Have Permission To View This Survey."}/>:
        <>
             {
        showTermsAndCondition && SurveyById?.termsConditions && (
          <SurveyTermsAndCondition
            termsAndConditon={SurveyById?.termsConditions}
            accept={()=>{setshowTermsAndCondition(false)}}
            back={() => { history.goBack()}}
          />
        )
      }
            <TakeTestSurveyView {...props}></TakeTestSurveyView>
        </>}
      </div>
    )
}
export default compose(withSurveyByIdNewOptimized)(TakeTestSurveyContainer)

