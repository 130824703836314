import React, { useState, useEffect } from 'react';
import { compose } from '../../core';
import FeedbackView from '../components/NewFeedbackHome'
import NotFound from '../../page/containers/NotFound'
import { withToolById } from 'modules/tool/containers/ToolOperations';
import { useHistory } from 'react-router-dom';

// imports for permissions 
import { globalPermissionValidator } from 'modules/look';
import { feedback_permission } from 'Permissions/feedback.permission';
import NoPermissionView from 'modules/kudos-user/component/NoPermissionComponent';

const FeedbackContainer = props => {
  // const { me, userPermission, permissionLoader } = props;

  // state to store permissions data
  // const [feedbackPermission, setFeedbackPermission] = useState({})

  // code to setup the permissions validator
  // useEffect(() => {
  //   if (me && userPermission) {
  //     let permission = globalPermissionValidator(feedback_permission, userPermission);
  //     // @ts-ignore
  //     setFeedbackPermission(permission)
  //   }
  // }, [me, userPermission])

  
  const history = useHistory();
  const typeList = ['received', 'submitted']
  let type = props.match.params.type;

  
  

  if (!typeList.includes(type)) {
    return (
      <>
        <NotFound></NotFound>
      </>
    )
  }

  
  else {
    return (
      <>
        <FeedbackView {...props} type={type} history={history}
         ></FeedbackView>
      </>
    );
  }


};

export default compose(withToolById)(FeedbackContainer);
