import gql from "graphql-tag";

export const ALL_MEETING = gql`
query allMeetings($when_Range:[DateTime],$endedAt_Isnull:Boolean,$title_Icontains:String,$orderBy: [String],$when_Gt: DateTime,$when_Lt: DateTime,$actionitem_Title_Icontains:String,$owner_User_FirstName_Icontains: String,$member_User_FirstName_Icontains: String,$offset: Int,$first: Int){
  allMeetings(
    when_Range:$when_Range,
    title_Icontains:$title_Icontains,
    orderBy:$orderBy,
    when_Gt:$when_Gt,
    when_Lt:$when_Lt,
    actionitem_Title_Icontains:$actionitem_Title_Icontains,
    owner_User_FirstName_Icontains:$owner_User_FirstName_Icontains,
    member_User_FirstName_Icontains:$member_User_FirstName_Icontains,
    offset:$offset,
    first:$first,
    endedAt_Isnull:$endedAt_Isnull
      ) {
        pageInfo{
          hasNextPage
        }
        totalCount
        edges{
          node{
               id
               title
               endedAt
               rrule
               event: when
               nextMeeting{
                totalCount
              }
               actionitemSet{
                edges{
                  node{
                    id
                    title
                    meeting{
                      id
                      title
                    }
                    okr {
                      id
                      title
                   }
                    commitment{
                      id
                      commitment{
                        id
                        title
                      }
                      completed
                    }  
                    coachingCommitment{
                      id
                      coachingcommitment{
                        id
                        title
                      }
                      completed
                    }
                    notes
                    completed
                    isAgenda
                    createdAt
                    updatedAt
                  }
                }
              }
              member{
               edges{
                 node{
                   id
                   user{
                     id
                     firstName
                     lastName
                     email
                     profile{
                      id
                       profileImage
                     }
                   }   
                 }
               }
             }
            owner{
              id
              user{
                id
                firstName
                lastName
              }
            }
          }
        }
   }
}
`;
