import React, { useState } from 'react';
import { compose } from '../../core';
import { withApollo } from '@apollo/client/react/hoc';
import { DISCARD_DETAIL_BY_ID } from '../graphql/DiscardRequestById.gql';
import { getOKRContributorsStringFromInt} from 'modules/look';
import DiscardRequestDetailView from '../components/DiscardRequestDetailView';
import { CONTRIBUTORS_REQUEST_BY_ID } from '../graphql/contributorsRequestDetailById.gql';
import RequestDetailView from '../components/RequestDetailView';
import { ALL_ACTION_ITEMS } from '../graphql/AllActionItems.gql';
import moment from 'moment-timezone';
const DiscardRequestDetailContainer = (props) => {
    const { me, client, history, match } = props
    const [requestData, setRequestData] = React.useState()
    const [loading, setLoading] = React.useState(false)
    const [actionItem, setActionItem] = React.useState()


    const RequestData = async (value) => {
        try {
            const { data } = await client.query({
                query: CONTRIBUTORS_REQUEST_BY_ID,
                variables: value,
                fetchPolicy: 'network-only'
            });

            if (data?.contributorById) {
                setRequestData(data?.contributorById)
                if (data?.contributorById?.requestStatus === "NEEDS_DISCUSSION") {
                    ActionItemDetail(data?.contributorById?.okr?.id)
                }
                else {
                    setLoading(false)
                }
            }

        } catch (e) {

        }


    }

    const ActionItemDetail = async (okrId) => {

        try {
            setLoading(true)
            const { data } = await client.query({
                query: ALL_ACTION_ITEMS,
                variables: { okr: okrId, isAgenda: true },
                fetchPolicy: 'network-only'
            });
            if (data?.allActionItems) {
                setActionItem(data?.allActionItems)
                setLoading(false)
            }
        } catch (e) {

        }
        
    }



    React.useEffect(() => {
        setLoading(true)
        RequestData({ id: getOKRContributorsStringFromInt(match?.params?.id), tz: moment.tz.guess() })
    }, [match])

    return (
        <RequestDetailView {...props} requestDetail={requestData} loading={loading} actionItem={actionItem}></RequestDetailView>
    )
    
}

export default compose(withApollo)(DiscardRequestDetailContainer)