import gql from 'graphql-tag';
import { SIMPLE_FIELD_TYPE } from './SimpleFieldType.gql';

export const SIMPLE_FIELD_SUBSCRIPTION = gql`
  ${SIMPLE_FIELD_TYPE}
  subscription toolsSimpleFieldSubscription {
    toolsSimpleFieldSubscription {
      mutation
      simpleField {
        ...SimpleFieldType
      }
    }
  }
`;
