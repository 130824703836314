import gql from 'graphql-tag';
import { COMMITMENT_TYPE } from './CommitmentType.gql';

export const EDIT_COMMITMENT = gql`
  ${COMMITMENT_TYPE}
  mutation editCommitment($description: String, $id: ID!, $title: String, $condition: [Int]) {
    updateCommitment(description: $description, id: $id, title: $title, condition: $condition) {
      commitment {
        ...CommitmentType
      }
    }
  }
`;
