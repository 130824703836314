// @ts-nocheck
import React from 'react';
import { Link } from 'react-router-dom';
import arrayMove from 'array-move';
import { RightCircleOutlined, MenuOutlined, DownCircleOutlined } from '@ant-design/icons';
import { Row, Col, Button,Spin,Table ,Input, Modal, message, DatePicker } from "antd";
import { compose } from '../../core';
import styled from 'styled-components';
import ReactMarkdown from '../../look/components/ReactMarkdown';
import { getIntFromString,scrolltoview } from "../../look";
// import Filtermenu from './filter'
import { ONEONONE_ADD_EDIT_TOOL_ID } from '../../../config'
import MeetingTableView from './Table'
import moment from 'moment-timezone'; 
import MeetingCard from './OneOnOneMeetingCard';
import date_inactive from '../../assets/meeting-custom-date-inactive.svg'
import date_active from '../../assets/meeting-custom-date-active.svg'

const PastMeetingView = props => {
  const {
    history,
    me,
    allMeetings,
    onfilter,
    setloading,loading,onPaginantion,onFilterList
  } = props;

  const { RangePicker } = DatePicker;
  const [dataSource, setDataSource] = React.useState([]);
  // const [loading, setloading] = React.useState(true);
  const [openCustomPicker,setOpenCustomPicker]=React.useState(false)
  const [openPicekr,setOpenPiceker]=React.useState(false)
  const [selectedValue,setSelectedValue]=React.useState([])
  const [currentPage, setcurrentPage] = React.useState(1)
  const [pageloading, setpageloading] = React.useState(false)
  const [nextPage,setNextPage]=React.useState(false)
  let currentFilter = React.useRef({})
  function firstDayOfWeek(dateObject, firstDayOfWeekIndex) {

    const dayOfWeek = dateObject.getDay(),
      firstDayOfWeek = new Date(dateObject),
      diff = dayOfWeek >= firstDayOfWeekIndex ?
        dayOfWeek - firstDayOfWeekIndex :
        6 - dayOfWeek
  
    firstDayOfWeek.setDate(dateObject.getDate() - diff)
    firstDayOfWeek.setHours(0, 0, 0, 0)
  
    return firstDayOfWeek
  }
  React.useEffect(()=>{
    if(allMeetings){
      setNextPage(allMeetings?.pageInfo?.hasNextPage)
      let list =  allMeetings?.edges?.map(({node})=>node)
      if(dataSource?.length&&onFilterList){
        let finalMetingList =dataSource?.concat(list)
        var setObj = new Set();
        let meetList= finalMetingList.reduce((acc,item)=>{
          if(!setObj.has(item?.id)){
            setObj.add(item?.id)
            acc.push(item)
          }
          return acc;
        },[])
        setDataSource(meetList)
      }
      else{
        setDataSource(list)
      }
    }
  },[allMeetings,onFilterList])


  const pastWeekDate=(type)=>{
    let start_date
    let end_date 
    if(type==='pastWeek'){
      start_date =moment().subtract(1, 'week').startOf('week').startOf('day').utc().toISOString()
      end_date = moment().subtract(1, 'week').endOf('week').endOf('day').utc().toISOString()
      onfilter({when_Range:[start_date,end_date]})
     
    }
    else if(type==='pastMonth'){
      start_date =moment().subtract(1, 'month').startOf('month').startOf('day').utc().toISOString()
      end_date = moment().subtract(1, 'month').endOf('month').endOf('day').utc().toISOString()
      onfilter({when_Range:[start_date,end_date]})
    }
    else if (type==='pastYear'){
      start_date =moment().subtract(1, 'year').startOf('year').startOf('day').utc().toISOString()
      end_date = moment().subtract(1, 'year').endOf('year').endOf('day').utc().toISOString()
      onfilter({when_Range:[start_date,end_date]})
    }
    else if(type==='max'){
      let start_date = moment().startOf('year')
      let end_date =moment().endOf('year')
      // let date =moment().subtract(1,'day').utc().toISOString()
      onfilter({when_Range:[start_date,end_date]})
    }
  }
  const datefilter=(e)=>{
    if(e && e?.length===2){
        let start_date = moment(e[0]).startOf('day')
        let end_date = moment(e[1]).endOf('day')
        setSelectedValue([start_date,end_date])
        // SaveSelectedDate([start_date,end_date])
    }
  }
const dateChangeFilterData =(e)=>{
    if(e&&e?.length==2){
        if(e[0]!==null){
        // setSelectedStartDte(e[0])
        }
    }
}

const custonFilter = ()=>{
  if(selectedValue?.length&&selectedValue?.length==2){
    onfilter({when_Range:[selectedValue[0].utc().toISOString(),selectedValue[1].utc().toISOString()]})
    closeModal()
  }
  else{
    message.error("please select a date range")
  }
}

const pagination=()=>{
  let current_page=currentPage
  currentFilter.current['offset']=current_page*20
  setcurrentPage(current_page+1)
  setpageloading(true)
  onPaginantion({...currentFilter?.current,pagination_value:true})
}

const closeModal =()=>{
  setOpenPiceker(false)
  setTimeout(()=>{
    setOpenCustomPicker(false)
  },150)
}
  return (
    <>
        <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap"}}>
            <Button className='meeting-filter-btn' onClick={()=>pastWeekDate('pastWeek')}>Past week</Button>
            <Button className='meeting-filter-btn' onClick={()=>pastWeekDate('pastMonth')}>Past month</Button>
            <Button className='meeting-filter-btn' onClick={()=>pastWeekDate('pastYear')}>Past year</Button>
            <Button className='meeting-filter-btn' onClick={()=>pastWeekDate('max')}>This year</Button>
            <Button className='meeting-filter-btn' style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",gap:"5px",flexWrap:"nowrap",width:"fit-content"}} onClick={()=>{setOpenCustomPicker(true);setOpenPiceker(true)}}>Custom <img src={date_inactive} alt="" className='meeting_date_icon_inactive' /><img src={date_active} alt="" className='meeting_date_icon_active' /></Button>
        </div>  

        <div className='agenda-create-overflow' style={{width:"100%",maxHeight:'390px',overflow:'auto',height:"100%",minHeight:'390px'}}>
          {/* <MeetingTableView {...props} dataSource={dataSource} loading={loading} selectedTab="all"></MeetingTableView> */}
          <MeetingCard dataSource={dataSource} loading={loading} {...props} paginationLoad={()=>pagination()} nextPage={nextPage}/>
        </div>
      <Modal
         footer={null}
         centered
         visible={openCustomPicker}
         destroyOnClose={true}
        //  onCancel={() => {setquickMeeting(false)}}
         closable={false}
         
       >
        <div style={{height:"330px",width:"100%",position:"relative",padding:"10px 10px"}}>
            <RangePicker
              open={openPicekr?openPicekr:false} 
              allowClear={false}
              dropdownClassName = {'custom-meeting-picker'}
              className="date-range-meeting"
              value={selectedValue}
              onChange={datefilter} 
              inputReadOnly={true}   
              onCalendarChange={(e)=>{ dateChangeFilterData(e)}}
              disabledDate={current => {
                return current.isAfter(moment())}}
              />
            <div style={{position:"absolute",bottom:"0px",width:"100%",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",gap:"10px",marginTop:"20px"}}>
              <Button className='cancel-date-btn' onClick={()=>{closeModal();setSelectedValue([])}}>Cancel</Button>
              <Button className='save-date-btn' onClick={()=>custonFilter()}>Save</Button>
            </div>
        </div>
         
       </Modal>
    </>
  );
};

export default PastMeetingView;

const CreateButton = styled(Button)`
width:170px;
height:40px;
border-radius:5px;
`

const ViewButton = styled(Button)`
width:60px;
height:35px;
border-radius:5px;
`

export const MyTable = styled(Table)`
  table {
    border-spacing: 0 1em;
    tbody {
      tr {
        td {
          border: 1px solid rgba(0, 0, 0, 0.06) !important;
          border-style: solid none !important;
        }
        td:first-child {
          border-left-style: solid !important;
          border-top-left-radius: 5px !important;
          border-bottom-left-radius: 5px !important;
        }
        td:last-child {
          border-right-style: solid !important;
          border-bottom-right-radius: 5px !important;
          border-top-right-radius: 5px !important;
          border-bottom: 1px solid rgba(0, 0, 0, 0.06) !important;
        }
      }
    }
  }
`;
const RowDiv =styled(Row)`
display:flex;
justify-content:end;
align-items:center;
margin-bottom:20px
`