import React from 'react';
import { Form, Transfer, Row, Col, Button } from 'antd';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import { UserAddOutlined } from '@ant-design/icons';

import Loading from '../Loading';
import { getIntFromString, getVerticalBase64StringFromInt } from '../functions';

export const VERTICAL_BY_VERTICAL_Id_QUERY = gql`
  query vertical($id: ID!) {
    vertical(id: $id) {
      id
      name
      description
      employees{
        edges {
          cursor
          node {
            id
            user {
              id
              firstName
              lastName
              username
            }
          }
        }
      }
    }
  }
`;

const TeamEmployeeTranferField = props => {
  const { vertical, initialValues, onAdd } = props;
  const { ...rest } = props;
  //
  const FormItem = Form.Item;
  const [ mockData, setmockData ] = React.useState( [] );
  const [ targetKeys, setTargetKeys ] = React.useState( [] );

  React.useEffect( () => {
    const targetKeys = [];
    // const mockData = [];
    // for (let i = 0; i < 20; i++) {
    //   const data = {
    //     key: i.toString(),
    //     title: `content${i + 1}`,
    //     description: `description of content${i + 1}`,
    //     chosen: Math.random() * 2 > 1,
    //   };
    // if (data.chosen) {
    //   targetKeys.push(data.key);
    // }
    // mockData.push(data);
    // }
    const mockData =
      vertical &&
      vertical.employees &&
      vertical.employees.edges.length > 0 &&
      vertical.employees.edges.map( ( { node } ) => ( {
        title:
          node.user.firstName && node.user.lastName ? `${node.user.firstName} ${node.user.lastName}` : node.user.username,
        key: getIntFromString( node.id )
      } ) );

    initialValues &&
      initialValues.map( id => {
        targetKeys.push( id );
      } );

    setmockData( mockData );
    setTargetKeys( targetKeys );
  }, [ vertical, initialValues ] );

  const filterOption = ( inputValue, option ) => option.title.indexOf( inputValue ) > -1;

  const handleChange = targetkeys => {
    setTargetKeys( targetkeys );
  };
  return (
    <div style={{ marginBottom: '5vh' }}>
      <Row>
        <Col lg={{ span: 14, offset: 1 }} md={{ span: 15, offset: 1 }}>
          {vertical ? (
            <FormItem
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24, offset: 1 }}
              {...rest}
            // rules={[
            //   ({ getFieldValue, setFieldsValue }) => ({
            //     validator(_, value) {
            //       // if (getFieldValue('employees')) {
            //       return Promise.resolve();
            //       // }
            //       // if (!value || getFieldValue('password') === value) {
            //       // }
            //       // return Promise.reject(new Error('The two passwords that you entered do not match!'));
            //     }
            //   })
            // ]}
            >
              <Transfer
                listStyle={{ height: '35vh', width: '18vw' }}
                dataSource={mockData || []}
                showSearch
                filterOption={filterOption}
                targetKeys={targetKeys}
                onChange={handleChange}
                // onSearch={handleSearch}
                render={item => item.title}
              />
            </FormItem>
          ) : (
            <Loading size="small" />
          )}
        </Col>
        {onAdd && (
          <Col span={2} style={{ paddingTop: '4vh' }}>
            <Button shape="circle" type="primary" ghost>
              <Link to={`${onAdd}`}>
                <UserAddOutlined />
              </Link>
            </Button>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default graphql( VERTICAL_BY_VERTICAL_Id_QUERY, {
  options: props => {
    return {
      variables: {
        id:  getVerticalBase64StringFromInt(props.verId)
      },
      fetchPolicy: 'network-only'
    };
  },
  props( { data: { loading, error, vertical, subscribeToMore, updateQuery } } ) {
    return { loading, error, vertical, subscribeToMore, updateQuery };
  }
} )( TeamEmployeeTranferField );
