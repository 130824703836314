import React from 'react';
import { idp_home_icons } from '../idpIcons';
import { Tooltip, Spin } from 'antd'
import idp_route from '../route';
import { getIntFromString } from 'modules/look';
import { idp_status } from '../idp.config';
import Idppagination from './idpPagination';
const EmployeeIDPStatusView = ( props ) => {
    const { request_list, emp_idp_list_loading, employee_idp_page_info, getEmployeeIdpList,navigateRoute } = props;
    const getStatus = (status) => {
        if (status === idp_status?.Draft?.for_query) {
            return <h4 className='idp-draft-btn idp-text-transform'>{status}</h4>
        } else if (status === idp_status?.Pedning_Approval?.for_query) {
            return <h4 className='idp-pending-btn  idp-text-transform'>Pending</h4>
        } else if (status === idp_status?.Completed?.for_query) {
            return <h4 className='idp-complete-btn  idp-text-transform'>{status}</h4>
        } else if (status === idp_status?.InProgress?.for_query) {
            return <h4 className='idp-inprogress-btn  idp-text-transform'>{idp_status?.InProgress?.label}</h4>
        }
        else if (status === idp_status?.Approved?.for_query) {
            return <h4 className='idp-approved-btn  idp-text-transform'>{status}</h4>
        } else if (status === idp_status?.Revise?.for_query) {
            return <h4 className='idp-revise-btn  idp-text-transform'>{status}</h4>
        }
        else if (status === idp_status?.ToDo?.for_query) {
            return <h4 className='idp-inprogress-btn  idp-text-transform'>{idp_status?.ToDo?.label}</h4>
        }

    }
    return (
        <div className="idp-table-container" style={{ padding: ".5em 2em" }}>
            <Spin spinning={emp_idp_list_loading}>
                <table className='rd-table'>
                    <thead>
                        <tr className='rd-row-header'>
                            <th className='rd-table-row-padding-left rd-left-radious'>
                                <h4 className='rd-table-header-label' style={{ fontSize: "1.35em",width:"30%" }}>Employee</h4>
                            </th>
                            <th className='rd-table-header-label' style={{ textAlign: "center",width:"50%" }}>
                                <h4 className='rd-table-header-label' style={{ fontSize: "1.35em" }}>Goal</h4>
                            </th>
                            <th className='rd-table-row-padding-rigth rd-content-center rd-table-header-label rd-right-radious' style={{ textAlign: "center", width: "20%" }}>
                                <h4 className='rd-table-header-label' style={{ fontSize: "1.35em"}}>Status</h4>
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        {
                            request_list?.map( request => (
                                <tr className='idp-row-body' style={{cursor:"pointer"}} onClick={() => navigateRoute( idp_route?.detail.replace( ":idp_id", getIntFromString( request?.id ) ) )}>
                                    <td className='rd-left-radious rd-table-row-padding-left' style={{ borderRight: "none",width:"30%" }}>
                                        <h4 className='rd-table-body-label'>{request?.employee?.user?.firstName}</h4>
                                    </td>
                                    <td style={{ textAlign: "center", borderRight: "none", borderLeft: "none",width:"50%" }}>
                                        <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: "center", gap: "1em" }}>
                                            {request?.goalType === "LONG" ? <Tooltip title="Long-term goal"><img src={idp_home_icons?.long_term_goal} alt="long_term_goal" style={{ width: "2em", height: "2em", cursor: "pointer" }} /></Tooltip> :
                                                <Tooltip title="Short-term goal"><img src={idp_home_icons?.short_term_goal} alt="short_term_goal" style={{ width: "2em", height: "2em", cursor: "pointer" }} /></Tooltip>}
                                            <h4 className='rd-table-body-label' style={{ cursor: "pointer",wordBreak:"break-all" }}>{request?.goal}</h4>
                                        </div>
                                    </td>
                                    <td className="rd-right-radious" style={{ textAlign: "center", width: "20%", borderLeft: "none" }}>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center", width: "100%" }}>
                                            {getStatus( request?.status )}
                                        </div>
                                    </td>
                                </tr>
                            ) )
                        }

                    </tbody>

                </table>
            </Spin>
            {
                ( !request_list?.length && !emp_idp_list_loading ) ?
                    <div className='rd-nodata'>
                        <h4 className='rd-nodata-label'>No data found</h4>
                    </div> : ''
            }
            {
                ( employee_idp_page_info?.hasNextPage && employee_idp_page_info?.cursor ) && (
                    <Idppagination on_pagination={() => { getEmployeeIdpList( { cursor: employee_idp_page_info?.cursor } ) }} />
                )
            }
        </div>
    )
}

export default EmployeeIDPStatusView