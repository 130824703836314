import React from 'react';
import IDPDetailView from '../components/idpDetailView';
import { getIdpBase64StringFromInt, getIntFromString, globalPermissionValidator, replaceAITipDynamicContent } from 'modules/look';
import { IDP_DETAIL_BY_ID } from '../graphql/idpDetail.gql';
import { compose } from '../../core';
import { withApollo } from '@apollo/client/react/hoc';
import { idp_status } from '../idp.config';
import { withUpdateIdp } from '../idpOperations';
import { idp_permission } from 'Permissions/idp.permission';
import NoPermissionView from 'modules/kudos-user/component/NoPermissionComponent';
import { Spin } from 'antd';
import moment from 'moment';
import { ai_trigger_content } from 'modules/ai-tips/ai-trigger-content';

const IDPDetailComtainer = (props) => {
    const { match, client, updateIdp, userPermission,me } = props
    const [idp_detail, setIdpDetail] = React.useState()
    const [idp_detail_loading, setIdpLoading] = React.useState(false)
    const [permission, setPermission] = React.useState()
    const [ai_trigger,SetAITrigger] = React.useState()
    
    //Permission Check
    React.useEffect(() => {

        localStorage.removeItem('emailRoute')

        if (userPermission?.length) {
            let permission = globalPermissionValidator(idp_permission, userPermission)
            setPermission(permission)
        }
    }, [userPermission])

    React.useEffect(() => {
        if (match?.params && permission?.required_permission && me) {
            let id = getIdpBase64StringFromInt(match?.params?.idp_id)
            getIdpById(id)
        }
    }, [match, permission,me])

    const tip_triggered = React.useRef(false)
    const getIdpById = async (id) => {
        setIdpLoading(true)
        const { data } = await client.query({
            query: IDP_DETAIL_BY_ID,
            variables: { id: id },
            fetchPolicy: 'network-only'
        });
        if (data?.idpById) {
            setIdpDetail(data?.idpById)
            let idp = data?.idpById 
            let avoid_tip = [idp_status.Completed.for_query,idp_status.Draft.for_query]
            if(!avoid_tip?.includes(data?.idpById?.status) && !tip_triggered?.current){
                tip_triggered.current = true
                if(getIntFromString(idp?.employee?.user?.id) == getIntFromString(me?.id)){
                    let ai_trigger = {
                        trigger:ai_trigger_content.idp_detail_summary,
                        objectId:data?.idpById?.id,
                        dynamic_data:["my"],
                        optype:"VIEW",
                        custom_instruction:"While generating tip consider the status of the idp and the due date as well. do not use users name in the tip"
                    }
                    SetAITrigger(ai_trigger)
                }else{
                    let ai_trigger = {
                        trigger:ai_trigger_content.idp_detail_summary,
                        objectId:data?.idpById?.id,
                        dynamic_data:["my reportee's"],
                        optype:"VIEW",
                        custom_instruction:"While generating tip consider the status of the idp and the due date as well."
                    }
                    SetAITrigger(ai_trigger)
                }
                
            }
        }
        setIdpLoading(false)
    }

    const OnSubmitIDP = async (data) => {
        try {
            setIdpLoading(true)
            if(data?.status){
                if(data?.status == idp_status?.ToDo.label){
                    data.status = idp_status?.ToDo.for_mutation
                }
                if(data?.status == idp_status?.InProgress.label){
                    data.status = idp_status?.InProgress.for_mutation
                }
            }
            let update = { ...data, id: getIntFromString(idp_detail?.id) }
            delete update?.approverId
            delete update?.sendTo
            const response = await updateIdp(update)
            if (response?.id) {
                getIdpById(idp_detail?.id)
            } else {
                setIdpLoading(false)
            }

        } catch (error) {
            setIdpLoading(false)
        }
    }

    return (
        <>
            {
                (permission && permission?.required_permission) && (
                    <IDPDetailView
                        idp_detail={idp_detail}
                        idp_detail_loading={idp_detail_loading}
                        OnSubmitIDP={OnSubmitIDP}
                        ai_trigger={ai_trigger}
                        SetAITrigger={SetAITrigger}
                        permission={permission}
                        {...props} />
                )
            }

            {(permission && !permission?.required_permission) && (<NoPermissionView />)}
            {
                !permission && (
                    <div className="role-no-permission-container">
                        <Spin spinning={true} size="large"></Spin>
                    </div>
                )
            }
        </>
    )
}
export default compose(withApollo, withUpdateIdp)(IDPDetailComtainer)