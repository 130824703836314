import { graphql } from '@apollo/client/react/hoc';
import _ from 'lodash';
import { message } from "antd"
import { CREATE_BADGE_AWARD } from '../graphql/createBadgeAwardMutation.gql';
import { DELETE_BADGE_AWARD } from '../graphql/deleteBadgeMutation.gql';


export const withCreateBadgeAward = Component =>
graphql(CREATE_BADGE_AWARD, {
  props: ({ mutate, history }) => ({
    createBadgeAward: async values => {
      try {
        const {
          data: { createBadgeAward }
        } = await mutate({
          variables: {
            ...values
          }
        });

        message.destroy();
        return createBadgeAward.badgeAward;
      } catch (e) {
        message.destroy();
        console.error(e);
      }
    }
  })
})(Component);

export const withDeleteBadgeAward = Component =>
graphql(DELETE_BADGE_AWARD, {
  props: ({ mutate, history }) => ({
    deleteBadgeAward: async values => {
      try {
        const {
          data: { deleteBadgeAward }
        } = await mutate({
          variables: {
            ...values
          }
        });

        message.destroy();
        message.success('Successfully deleted');
        return deleteBadgeAward.badgeAward;
        
      } catch (e) {
        message.destroy();
        message.error("Failed to delete Badge award");
        console.error(e);
      }
    }
  })
})(Component);
export const withDeleteBadgeAwardForFeedback = Component =>
graphql(DELETE_BADGE_AWARD, {
  props: ({ mutate, history }) => ({
    deleteBadgeAward: async values => {
      try {
        const {
          data: { deleteBadgeAward }
        } = await mutate({
          variables: {
            ...values
          }
        });

        message.destroy();
        return deleteBadgeAward.badgeAward;
        
      } catch (e) {
        message.destroy();
        message.error("Failed to delete Badge award");
        console.error(e);
      }
    }
  })
})(Component);