import React from 'react';
import styled from 'styled-components';
import { Drawer, Row, Col, Menu, Button, Affix, Switch } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { useHistory, Link } from 'react-router-dom';

import ORGANIZATION_ROUTE from '../../organization/route';
import SURVEY_ROUTE from '../../survey/route';
import STORY_ROUTE from '../../story/route';
import TOOL_ROUTE from '../../tool/route';
import PAGE_ROUTE from '../../page/route';
import USER_ROUTE from '../../user/route';
import SCAPE_ROUTE from '../../scape-admin-panel/route'

import MetaTags from './MetaTags';
import RenderTableWithLayout from './RenderTableWithLayout';


// const AdminAffix = styled(Affix)`
const AdminAffix = styled.div`
  /* position: absolute;
  bottom: 0;
  left: 0;
  width: 78vw; */
`;
export const menuItems = [
  {
    title: 'Organizations',
    key: 'Organizations',
    children: [
      {
        key: ORGANIZATION_ROUTE.organization,
        icon: null,
        link: ORGANIZATION_ROUTE.organization,
        disabled: false,
        label: 'Organizations'
      },
      // {
      //   key: ORGANIZATION_ROUTE.vertical,
      //   icon: null,
      //   link: ORGANIZATION_ROUTE.vertical,
      //   disabled: false,
      //   label: 'Verticals'
      // },
      // { key: ORGANIZATION_ROUTE.team, icon: null, link: ORGANIZATION_ROUTE.team, disabled: false, label: 'Teams' },
      {
        key: ORGANIZATION_ROUTE.employee,
        icon: null,
        link: ORGANIZATION_ROUTE.employee,
        disabled: false,
        label: 'Employees'
      }
    ]
  },
  {
    title: 'Accounts',
    key: 'Accounts',
    children: [
      {
        key: 'email Address',
        icon: null,
        link: '//#endregion',
        disabled: true,
        label: 'Email Address'
      }
    ]
  },
  {
    title: 'Authentication & authorization',
    key: 'Authentication & authorization',
    children: [
      {
        key: 'groups',
        icon: null,
        link: '//#endregion',
        disabled: true,
        label: 'Groups'
      },
      {
        key: USER_ROUTE.user,
        icon: null,
        link: USER_ROUTE.user,
        disabled: false,
        label: 'Users'
      },
      {
        key: PAGE_ROUTE.contact,
        icon: null,
        link: PAGE_ROUTE.contact,
        disabled: false,
        label: 'Contact'
      }
    ]
  },
  /* {
    title: 'Avatar',
    key: 'Avatar',
    children: [
      {
        key: 'avatars',
        icon: null,
        link: '//#endregion',
        disabled: true,
        label: 'Avatars'
      }
    ]
  }, */
  {
    title: 'Survey',
    key: 'Survey',
    children: [
      {
        key: SURVEY_ROUTE.response,
        icon: null,
        link: SURVEY_ROUTE.response,
        disabled: false,
        label: 'Response'
      },
      {
        key: SURVEY_ROUTE.survey,
        icon: null,
        link: SURVEY_ROUTE.survey,
        disabled: false,
        label: 'Surveys'
      },
      {
        key: SURVEY_ROUTE.token,
        icon: null,
        link: SURVEY_ROUTE.token,
        disabled: false,
        label: 'Tokens'
      },
      {
        key: SURVEY_ROUTE.surveyToken,
        icon: null,
        link: SURVEY_ROUTE.surveyToken,
        disabled: false,
        label: '360 survey'
      },
      {
        key: SCAPE_ROUTE.scapeReportAdminHome,
        icon: null,
        link: SCAPE_ROUTE.scapeReportAdminHome,
        disabled: false,
        label: 'Scape admin'
      }
    ]
  },
  {
    title: 'Social Accounts',
    key: 'socialAccounts',
    children: [
      {
        key: 'socialAccounts',
        icon: null,
        link: '//#endregion',
        disabled: true,
        label: 'Social Accounts'
      },
      {
        key: 'socialApplicationToken',
        icon: null,
        link: '//#endregion',
        disabled: true,
        label: 'Social Application Token'
      },
      {
        key: 'socialApplications',
        icon: null,
        link: '//#endregion',
        disabled: true,
        label: 'Social applications'
      }
    ]
  },
  {
    title: 'Refresh Tokens',
    key: 'Refresh Tokens',
    children: [
      {
        key: 'refreshTokens',
        icon: null,
        link: '//#endregion',
        disabled: true,
        label: 'Refresh tokens'
      }
    ]
  },
  {
    title: 'Sites',
    key: 'Sites',
    children: [
      {
        key: 'sites',
        icon: null,
        link: '//#endregion',
        disabled: true,
        label: 'Sites'
      },
      {
        key: PAGE_ROUTE.faq,
        icon: null,
        link: PAGE_ROUTE.faq,
        disabled: false,
        label: 'FAQ'
      }
    ]
  },
  {
    title: 'User Profiles',
    key: 'User Profiles',
    children: [
      {
        key: 'profile',
        icon: null,
        link: '//#endregion',
        disabled: true,
        label: 'Profile'
      }
    ]
  },
  {
    title: 'Stories',
    key: 'Stories',
    children: [
      {
        key: STORY_ROUTE.story,
        icon: null,
        link: STORY_ROUTE.story,
        disabled: false,
        label: 'Stories'
      },
      {
        key: STORY_ROUTE.tag,
        icon: null,
        link: STORY_ROUTE.tag,
        disabled: false,
        label: 'Tags'
      }
    ]
  },
  {
    title: 'Tools',
    key: 'Tools',
    children: [
      {
        key: TOOL_ROUTE.tool,
        icon: null,
        link: TOOL_ROUTE.tool,
        disabled: false,
        label: 'Tools'
      }
      // {
      //   key: TOOL_ROUTE.tag,
      //   icon: null,
      //   link: TOOL_ROUTE.tag,
      //   disabled: false,
      //   label: 'Tags'
      // }
    ]
  }
];

const AdminLayout = props => {
  const {
    children,
    showTitle = true,
    table = undefined,
    active,
    title,
    loading,
    tableData,
    columns,
    onDelete,
    addLink,
    FormComponent,
    onPaginationChange,
    extra,
    rowKey = 'id',
    editLink,
    showBack = false,
    onFiltersRemove,
    components,
    onSort,
    backLink,
    specificMutation,
    handleDeleteRecord,
  } = props;
  // console.log("props.sidebar".props.sidebar)
  const [visible, setVisible] = React.useState(false);
  const [openKeys, setOpenKeys] = React.useState([]);
  const [checked, setChecked] = React.useState(null);

  const affixRef = React.useRef(null);

  const history = useHistory();

  const onSelect = params => {
    if (document) {
      if (document.body) {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }
    }
    params?.key && history.push(params.key);
  };

  const MenuItems = (
    <Menu
      mode="inline"
      defaultSelectedKeys={[`${active}`]}
      onSelect={onSelect}
      onOpenChange={setOpenKeys}
      openKeys={openKeys}
    >
      {menuItems.map(item => (
        <Menu.SubMenu title={item.title} key={item.key}>
          {item.children.map(child => (
            <Menu.Item key={child.key} icon={child.icon} disabled={child.disabled}>
              <Link to={child.link}>{child.label}</Link>
            </Menu.Item>
          ))}
        </Menu.SubMenu>
      ))}
    </Menu>
  );

  const tableWithLayout = (
    <RenderTableWithLayout
      loading={loading}
      title={title}
      tableData={tableData}
      columns={columns}
      onDelete={onDelete}
      addLink={addLink}
      handleDrawer={() => setVisible(!visible)}
      showBack={showBack}
      extra={extra}
      onFiltersRemove={onFiltersRemove}
      onPaginationChange={onPaginationChange}
      rowKey={rowKey}
      editLink={editLink}
      components={components}
      onSort={onSort}
      specificMutation = {specificMutation}
      handleDeleteRecord = {handleDeleteRecord}
      {...props}
    />
  );
  return (
    <>
      <MetaTags title={title} description={`This is ${title} page`} />
      <Row /* gutter={48} */ >
        {props.sidebar ? null : <Col lg={4} xs={0}>
          <div align="right" style={{ widht: '100%', marginBottom: '20px' }}>
            <Switch
              checked={checked}
              onChange={() =>
                !checked
                  ? setChecked(true) || setOpenKeys(menuItems.map(item => item.key))
                  : setChecked(false) || setOpenKeys([])
              }
              unCheckedChildren="Expand"
              checkedChildren="Collapse"
            />
          </div>
          {MenuItems}
        </Col>}
         
        <Col style={{ padding: props.sidebar ? '0px 20px' : '0px 0px' }} lg={{ span: props.sidebar ? 24 : 19, offset: props.sidebar ? 0 : 1 }} xs={24} /* style={{ marginTop: '5vh' }} */>
          {table === true && <AdminAffix /* offsetTop={10} */ offsetBottom={0}>{tableWithLayout}</AdminAffix>}
          {table === false && (
            <Row>
              <Col span={extra ? 18 : 20}>
                <h2>
                  <strong>{title}</strong>
                </h2>
              </Col>
              {extra && (
                <Col span={4} align="right" style={{ paddingRight: '25px' }}>
                  {extra}
                </Col>
              )}
              {backLink ? (
                <Col span={2} align="right" style={{ paddingRight: '25px' }}>
                  <Button shape="circle" href={backLink}>
                    <LeftOutlined />
                  </Button>
                  {' Back'}
                </Col>
              ) : (
                <Col span={2} align="right" style={{ paddingRight: '25px' }}>
                  <Button shape="circle" onClick={() => history.goBack()}>
                    <LeftOutlined />
                  </Button>
                  {' Back'}
                </Col>
              )}
              <Col span={24}>{FormComponent}</Col>
            </Row>
            
          )}
          {table === undefined && (
            <>
              <Row>
                {showTitle && (
                  <Col span={19}>
                    <h2>
                      <strong>{title}</strong>
                    </h2>
                  </Col>
                )}
                <Col span={24}>{children}</Col>
              </Row>
            </>
          )}
        </Col>
        <Drawer
          width="190px"
          bodyStyle={{ padding: '24px 0px' }}
          placement="left"
          closable={false}
          onClose={() => setVisible(false)}
          visible={visible}
        >
          {MenuItems}
        </Drawer>
      </Row>
    </>
  );
};

export default AdminLayout;
