import gql from 'graphql-tag';

export const DELETE_BADGE_FAMILY =gql`
    mutation deleteBadgeFamily($badgeFamilyId: ID!){
        deleteBadgeFamily(badgeFamilyId: $badgeFamilyId){
            badgeFamily{
              id
            }
        }
    }
`