import gql from 'graphql-tag';

export const ME_SURVEY_FOR_TEAM_QUERY = gql`
  query me($id: ID!) {
    surveys: me{
      id
    employee{
      id
      teamManagerEmployee(id: $id){
        edges{
          node{
            surveySet {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
  }
`;
