import gql from 'graphql-tag';


export const DELETE_MEETING_MUTATION = gql`

  mutation deleteMeeting($id: ID!) {
    deleteMeeting(id:$id) {
        meeting  {
            id
      }
    }
  }
`;
