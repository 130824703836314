import gql from 'graphql-tag';


export const ASSIGNED_REQUEST_LIST = gql`
query contributors($employee:ID, $role:String, $requestStatus_In: [String],$requestStatus_Iexact:String,$okr_Goalstatement_Icontains: String,$offset: Int,$first: Int,$tz:String) {
    PendingRequest:contributors(createdBy_Ne:$employee,employee:$employee,role:$role,requestStatus_In:$requestStatus_In,okr_Goalstatement_Icontains:$okr_Goalstatement_Icontains,offset: $offset,first: $first){
        totalCount
        edgeCount
        edges {
            node {
            id
            createdAt
            requestStatus
                okr{
                    id
                    title(tz:$tz)
                    okrType
                    goalStatement
                    startdate
                    duedate
                    correctionDeadline
                }
                createdBy{
                    id
                    user{
                    id
                    firstName
                    lastName
                    profile{
                        id
                        profileImage
                        }
                    }
                }
            }
        }
    }
    AcceptedRequest:contributors(createdBy_Ne:$employee,employee:$employee,role:$role,requestStatus_Iexact:$requestStatus_Iexact,okr_Goalstatement_Icontains:$okr_Goalstatement_Icontains,offset: $offset,first: $first){
        totalCount
        edgeCount
        edges {
            node {
            id
            createdAt
            requestStatus
                okr{
                    id
                    title(tz:$tz)
                    okrType
                    goalStatement
                    startdate
                    duedate
                    correctionDeadline
                }
                createdBy{
                    id
                    user{
                    id
                    firstName
                    lastName
                    profile{
                        id
                        profileImage
                        }
                    }
                }
            }
        }
    }
}
`

export const ASSIGNED_REQUEST_SUBSCTIPTION=gql`
subscription contributorSubscription {
    contributorSubscription {
        mutation
        contributor{
        id
        createdAt
        requestStatus
            okr{
                id
                title
                okrType
                goalStatement
                startdate
                duedate
                correctionDeadline
            }
            createdBy{
            id
                user{
                id
                firstName
                lastName
                    profile{
                    id
                    profileImage
                    }
                }
            }
        }
    }
}
`