import React from 'react';

import { AdminLayout } from '../../look';
import { employee } from '../route';

import EmployeeFormComponent from './EmployeeFormComponent';

const AddEmployeeView = props => {
  return (
    <>
      <AdminLayout
        table={false}
        active={employee}
        title="Add User As Employee"
        FormComponent={<EmployeeFormComponent {...props} />}
      ></AdminLayout>
    </>
  );
};

export default AddEmployeeView;
