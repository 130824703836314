import gql from 'graphql-tag';

export const ALL_MEMBER_LIST = gql`
query members($first:Int,$toEmployee_User_FirstName_Icontains: String){
  members(first:$first,toEmployee_User_FirstName_Icontains:$toEmployee_User_FirstName_Icontains,role_Isnull: false){
    edges{
      node{
        id
        toEmployee{
          id
          user{
            id
            firstName
            lastName
          }
        }
        role{
          id
          title
          employeeLevel{
            id
            name
          }
        }
      }
    }
  }
}
`
export const RATING_SCALES = gql`
  query ratingScales {
    ratingScales(orderBy:["sequence"]){
        totalCount
        edges{
          node{
            id
            name
            sequence
          }
        }
    }
  }
`;

export const ALL_ROLE_QUERY = gql`
  query allRoles($title_Icontains:String,$first:Int) {
    allRoles(title_Icontains:$title_Icontains,first:$first){
      edges{
        node{
          id
          title
          employeeLevel{
            id
            name
          }
        }
      }
    }
  }
`;

export const ROLE_BY_ID_QUERY = gql`
  query roleById($id: ID!,$employeeLevel: ID) {
    roleById(id: $id) {
        id
        requiredSpecificCompetency{
            edges{
                node{
                    id
                    title
                    competencyType
                    behaviourallyanchoredratingSet (employeeLevel:$employeeLevel){
                      edges {
                        node {
                          id
                          ratingScale{
                            id
                            name
                          }
                          behaviourExample
                        }
                      }
                    }
                    competencyCategory{
                      id
                      title
                      behaviourallyanchoredratingSet(employeeLevel:$employeeLevel){
                        edges{
                          node{
                            id
                            ratingScale{
                              id
                              name
                            }
                            behaviourExample
                          }
                        }
                      }
                    }
                    competencyType
                }
            }
        }
        optionalSpecificCompetency{
            edges{
                node{
                    id
                    title
                    competencyType
                    behaviourallyanchoredratingSet(employeeLevel:$employeeLevel) {
                      edges {
                        node {
                          id
                          ratingScale{
                            id
                            name
                          }
                          behaviourExample
                        }
                      }
                    }
                    competencyCategory{
                      id
                      title
                      behaviourallyanchoredratingSet(employeeLevel:$employeeLevel){
                        edges{
                          node{
                            id
                            ratingScale{
                              id
                              name
                            }
                            behaviourExample
                          }
                        }
                      }
                    }
                    competencyType
                }
            }
        }
        jobfamilySet {
          edges {
            node {
              id
              genericCompetency {
                edges {
                  node {
                    id
                    title
                    competencyType
                    behaviourallyanchoredratingSet(employeeLevel:$employeeLevel) {
                      edges {
                        node {
                          id
                          ratingScale{
                            id
                            name
                          }
                          behaviourExample
                        }
                      }
                    }
                    competencyCategory {
                      id
                      title
                      behaviourallyanchoredratingSet(employeeLevel:$employeeLevel){
                        edges{
                          node{
                            id
                            ratingScale{
                              id
                              name
                            }
                            behaviourExample
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
    }
  }
`;

export const ALL_COMPETENCY_CATEGORY = gql `
query allCompetencyCategory($title_Icontains:String,$first: Int,$employeeLevel: ID){
  allCompetencyCategory(title_Icontains:$title_Icontains,first:$first){
      edges{
        node{
          id
          title
          behaviourallyanchoredratingSet(employeeLevel:$employeeLevel){
            edges {
              node {
                id
                behaviourExample
                ratingScale{
                  id
                  name
                  sequence
                }
              }
            }
          }
          competencySet {
            edges {
              node {
                id
                title
                behaviourallyanchoredratingSet(employeeLevel:$employeeLevel) {
                  edges {
                    node {
                      id
                      behaviourExample
                      ratingScale{
                        id
                        name
                        sequence
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
  }
}
`

export const ASSESSMENT_BY_ID = gql`
  query assessmentById($id: ID!) {
    assessmentById(id:$id){
      id
      role{
        id
        title
        requiredSpecificCompetency {
          edges {
            node {
              id
              title
              competencyType
              competencyCategory {
                id
                title
                competencyType
              }
            }
          }
        }
        optionalSpecificCompetency {
          edges {
            node {
              id
              title
              competencyType
              competencyCategory {
                id
                title
                competencyType
              }
            }
          }
        }
        jobfamilySet{
          edges{
            node{
              id
              title
              genericCompetency{
                edges{
                  node{
                    id
                    title
                    competencyType
                    competencyCategory{
                      id
                      title
                      competencyType
                    }
                  }
                }
              }
            }
          }
        }
      }
      employeeLevel
      employee{
        id
        user{
          id
          firstName
          lastName
        }
      }
      employeeEmail
      correctionDeadline
      competencyassessmentSet{
        edges{
          node{
            id
            mainCompetency{
              id
              title
              competencyType
            }
            competency{
              id
              title
              competencyType
            }
            assessmentbarsSet{
              edges{
                node{
                  id
                  qualifyingLevelId
                  qualifyingLevelSequence
                  behaviourExample
                  qualifyingLevelName
                  selected
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const CREATE_COMPETENCY_ASSESSMENT = gql`
  mutation createAssessment($assessmentData: AssessmentInput!) {
    createAssessment(assessmentData:$assessmentData){
      assessment{
        id
      }
    }
  }
`;