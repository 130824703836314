import { subscribeToQuery } from '../../look';

import { TOOL_SUBSCRIPTION } from '../graphql/ToolSubscription.gql';
import { COMMITMENT_SUBSCRIPTION } from '../graphql/CommitmentSubscription.gql';

export const subscribeToToolDetail = (subscribeToMore, variables) => {
  return subscribeToQuery(
    subscribeToMore,
    {
      document: TOOL_SUBSCRIPTION,
      subscriptionName: 'toolsSubscription',
      nodeName: 'tool',
      queryName: 'getToolByIdAdmin',
      dataEdge: false
    },
    variables
  );
};

export const subscribeToCommitment = (subscribeToMore, variables) => {
  return subscribeToQuery(
    subscribeToMore,
    {
      document: COMMITMENT_SUBSCRIPTION,
      subscriptionName: 'toolsCommitmentSubscription',
      nodeName: 'commitment',
      queryName: ['getToolByIdAdmin', 'commitment']
    },
    variables
  );
};
