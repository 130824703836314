import React from "react";
import { compose } from "../../core";
import AssignEmployeeyHomeView from "../components/AssignEmployeeView";
import { ALL_MEMBER_LIST, ALL_MEMBER_SUBSCRIPTION } from "../graphql/AssignEmployee.gql";
import { withDeleteMember } from "../RoleDefinitionOperation";


const AssignEmployeeContainer = ( props ) => {

    const { client, permission,deleteMember } = props
    const [ employee_loading, SetEmployeeLoading ] = React.useState( false )
    const [ employee_list, SetEmployeeList ] = React.useState( [] )
    const [ employee_delete, SetEmployeeDelete ] = React.useState()
    const [ employee_page_info, SetEmployeePageInfo ] = React.useState()
    const [assign_Employee_view,SetAssignEmployeeView] = React.useState(false)

    const employee_list_ref = React.useRef( [] )
    const employee_subscription = React.useRef( null )
    const filter_ref = React.useRef( {} )

    // to update the employee list refference when the state gets changed
    React.useEffect( () => {
        employee_list_ref.current = employee_list
    }, [ employee_list ] )

    // to check if the user have permission to fetch employee data
    React.useEffect( () => {
        if ( permission && permission?.required_permission ) {
            let current_nav_detail = JSON.parse(localStorage.getItem('role_definition_navigation'))
            if(current_nav_detail?.child_route?.open_modal){
                SetAssignEmployeeView(true)
            }
            let nav ={
                parent_route :{key:'member',label:'Employee'},
                child_route : {key:'member_home_view'}
            }
            localStorage.setItem('role_definition_navigation',JSON.stringify(nav))
            getAllMemberList()
        }
    }, [ permission ] )

    // Code to be executed when the component is unmounted
    React.useEffect( () => {
        return () => {
            if ( employee_subscription?.current ) {
                employee_subscription.current.unsubscribe();
                employee_subscription.current = null
            }
        };
    }, [] );

    //Get Member List
    const getAllMemberList = async ( filter ) => {
        filter_ref.current = { ...filter, after: null } || {}
        SetEmployeeLoading( true )
        const { data } = await client.query( {
            query: ALL_MEMBER_LIST,
            fetchPolicy: 'network-only',
            variables: { ...filter, first: 25 }
        } );
        if ( data?.members ) {
            // to set the pagination detail
            SetEmployeePageInfo( { ...data?.members?.pageInfo } )

            let list = data?.members?.edges?.map( ( { node } ) => node )
            // to handle pagination if after is present its a paginated data
            if ( filter?.after ) {
                list = employee_list_ref.current.concat( list )
            }
            SetEmployeeList( list )
            SetEmployeeLoading( false )
            if ( !employee_subscription?.current ) {
                employeeSubscription()
            }
        }

    }

       //Member list subscription
    const employeeSubscription = async () => {
        employee_subscription.current = client.subscribe( {
            query: ALL_MEMBER_SUBSCRIPTION,
            fetchPolicy: 'no-cache'
        } ).subscribe( {
            next( result ) {
                let employee_cache_data = [].concat( employee_list_ref.current )
                let scbscription_data = result.data.memberSubscription
                switch ( scbscription_data.mutation ) {
                    case 'CREATE':
                        employee_cache_data.push( scbscription_data?.member )
                        SetEmployeeList( employee_cache_data )
                        break
                    case 'UPDATE':
                        let doc = employee_cache_data?.find( item => item?.id === scbscription_data?.member?.id )
                        if ( doc ) {
                            Object.entries( scbscription_data?.member ).forEach( item => {
                                doc[ item[ 0 ] ] = item[ 1 ]
                            } )
                            SetEmployeeList( employee_cache_data )
                        }else{
                            employee_cache_data.push( scbscription_data?.member )
                            SetEmployeeList( employee_cache_data )
                        }
                        break
                    case 'DELETE':
                        employee_cache_data = employee_cache_data?.filter( item => item?.id !== scbscription_data?.member?.id )
                        SetEmployeeList( employee_cache_data )
                        break
                    default:
                        break
                }
            }
        } );
    }

    // delete employee level data
    const removeEmployee = async ( id ) => {
        try {
           
            SetEmployeeLoading( true )
            let response = await deleteMember({id:id})
            if(response){
                SetEmployeeDelete( null )
                SetEmployeeLoading( false )
            }

        } catch ( e ) {
            SetEmployeeLoading( false )
        }
    }


    return (
        <AssignEmployeeyHomeView
            {...props}
            employee_loading={employee_loading}
            employee_list={employee_list}
            employee_delete={employee_delete}
            SetEmployeeDelete={( e ) => { SetEmployeeDelete( e ) }}
            removeEmployee={( id ) => { removeEmployee( id ) }}
            getAllMemberList={( filter ) => { getAllMemberList( { ...filter_ref.current, ...filter } ) }}
            searchMemberList={( filter ) => { getAllMemberList( {...filter } ) }}
            employee_page_info={employee_page_info}
            assign_Employee_view={assign_Employee_view}
            SetAssignEmployeeView={(e)=>{SetAssignEmployeeView(e)}}
        />
    )

}

export default compose(withDeleteMember)( AssignEmployeeContainer );