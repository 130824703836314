import React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import gql from 'graphql-tag';
import { Empty, Spin } from 'antd';

import { compose } from '../../../core';
import { getIntFromString } from '../functions';
import { withLookSimpleFieldFilterUpdating, withLookSimpleFieldState } from '../../containers/LookOperations';

import SelectField from './SelectField';

export const GET_ALL_SIMPLE_FIELD_QUERY = gql`
  query getAllSimpleFields($first: Int, $orderBy: [String], $label_Icontains: String) {
    getAllSimpleFields(first: $first, orderBy: $orderBy, label_Icontains: $label_Icontains) {
      edges {
        node {
          id
          label
        }
      }
    }
  }
`;

const SimpleFieldAutoComplte = props => {
  const { loading, getAllSimpleFields, margin, onLabelChange, onFiltersRemove } = props;
  const handleFilterRemove = React.useRef(() => {});

  handleFilterRemove.current = () => {
    onFiltersRemove();
  };

  React.useEffect(() => {
    return () => handleFilterRemove.current();
  }, []);

  const choices =
    !loading &&
    getAllSimpleFields &&
    getAllSimpleFields.edges.length > 0 &&
    getAllSimpleFields.edges.map(({ node }) => ({
      label: node.label,
      value: getIntFromString(node.id)
    }));

  return (
    <SelectField
      margin={margin}
      showSearch
      choices={choices}
      offset={1}
      style={{ width: !margin && '100%', marginBottom: !margin && 0 }}
      // labelInValue
      filterOption={false}
      onSearch={onLabelChange}
      notFoundContent={
        loading ? (
          <div align="center">
            <Spin size="small" />
          </div>
        ) : (
          <Empty />
        )
      }
      {...props}
    />
  );
};

export default compose(
  withLookSimpleFieldState,
  graphql(GET_ALL_SIMPLE_FIELD_QUERY, {
    options: ({ filter, orderBy }) => {
      return { variables: { ...filter, orderBy } };
    },
    props({ data }) {
      const { loading, error, getAllSimpleFields, subscribeToMore, updateQuery } = data;
      return { loading, error, getAllSimpleFields, subscribeToMore, updateQuery };
    }
  }),
  withLookSimpleFieldFilterUpdating
)(SimpleFieldAutoComplte);
