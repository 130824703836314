import { compose } from "modules/core"
import { withApollo } from '@apollo/client/react/hoc';
import React from "react";
import AITipsView from "../component/AI-tips-view";
import { AI_TIP_TRIGGER, AI_TIPS, TIP_SETTINGS } from "../query/ai-tip-query.gql";
import { GenerateTipTitle, getIntFromString, replaceAITipDynamicContent, setTriggerToLS } from "modules/look";
import { AI_TIP_SUBSCRIPTION } from "../query/ai-tip-subscription.gql";
import { basic_ai_prompt } from "../ai-trigger-content";

const MeetingAITipContainer = (props) => {
    const { ai_trigger, me, client, SetCancelAiTriggerTip } = props
    const [preview_tip, SetPreviewTip] = React.useState()

    const [tip_list, SetList] = React.useState([])
    const [cancel_trigger, SetCancelTrigger] = React.useState([])
    let cancel_tip_ref = React.useRef([])

    let tip_list_ref = React.useRef([])
    const mountedRef = React.useRef(true)
    let feedbackTipSub = undefined

    React.useEffect(() => {
        cancel_tip_ref.current = cancel_trigger
        setTriggerToLS(cancel_tip_ref.current)
    }, [cancel_trigger])

    React.useEffect(() => {
        return () => {
            if (feedbackTipSub) {
                feedbackTipSub.unsubscribe();
            }
            if (cancel_tip_ref.current?.length && SetCancelAiTriggerTip) {
                SetCancelAiTriggerTip([]?.concat(cancel_tip_ref.current))
            }
        }
    }, [])

    React.useEffect(() => {
        return () => {
            mountedRef.current = false
        }
    }, [])

    React.useEffect(() => {
        mountedRef.current = true
    }, [])

    React.useEffect(() => {
        if (me?.employee) {
            getAiTip({ user: getIntFromString(me?.id), tipModule: 'Meeting', first: 15 })
        }
    }, [me])

    React.useEffect(() => {
        tip_list_ref.current = tip_list
    }, [tip_list])

    React.useEffect(() => {
        if (ai_trigger) {
            generateTip(ai_trigger)
        }
    }, [ai_trigger])

    const getAiTip = async (filter) => {
        try {
            const { data } = await client.query({
                query: AI_TIPS,
                variables: { ...filter },
                fetchPolicy: 'network-only'
            })
            if (data?.allRunpodtips) {
                let data_list = data?.allRunpodtips?.edges?.map(({ node }) => {
                    return {
                        id: node?.id,
                        tipFor: node?.tipFor,
                        user: node?.user,
                        module: node?.tipModule,
                        title: GenerateTipTitle(node) ,
                        is_saved: node?.favourite,
                        content: node?.tip,
                        created_at: node?.createdAt
                    }
                })
                SetList(data_list)
            }
        } catch (error) {

        }
    }

    const generateTip = async (triger_data) => {
        try {
            if (cancel_tip_ref.current?.length && SetCancelAiTriggerTip) {
                SetCancelAiTriggerTip([]?.concat(cancel_tip_ref.current))
            }
            let is_ai_tip_enabled = await getAiTipSetting()
            if (!is_ai_tip_enabled) {
                return
            }
           
            let { trigger, dynamic_data, userType, tipFor,optype, custom_instruction,objectId } = triger_data
            const randomInt = Math.floor(Math.random() * trigger?.length);
            let instruction_detail = trigger[randomInt] || trigger[0]
            if (!instruction_detail) {
                return
            }
            if (instruction_detail?.dynamic_data >= 1 && dynamic_data?.length) {
                instruction_detail = replaceAITipDynamicContent(instruction_detail, dynamic_data)
            }
            instruction_detail.situation = `${instruction_detail?.title} - ${instruction_detail.situation}`
            instruction_detail["instruction"] = basic_ai_prompt
            startAILoader()
            const { data } = await client.query({
                query: AI_TIP_TRIGGER,
                variables: { 
                    instruction: custom_instruction ?basic_ai_prompt + custom_instruction : "",
                    situation :`${instruction_detail?.title}`,
                    aiContext:JSON.stringify({action:instruction_detail.situation}),
                    userType: userType || "employee",
                    module: "Meeting",
                    optype:optype || "CREATE",
                    objectId:objectId 
                },
                fetchPolicy: 'network-only'
            });
            feedbackTipSub = client.subscribe({
                query: AI_TIP_SUBSCRIPTION,
            }).subscribe({
                next(result) {
                    let list = tip_list_ref.current
                    let sub_data = result.data.tipSubscription?.tip
                    if (getIntFromString(sub_data?.user?.id) == getIntFromString(me?.id) && sub_data?.tipModule == "Meeting") {
                        switch (result.data.tipSubscription.mutation) {
                            case 'UPDATE':
                                let already_added = tip_list_ref.current?.find(i=>getIntFromString(i?.id)==getIntFromString(sub_data?.id))
                                if (sub_data?.tip && !already_added) {
                                    stopAILoader()
                                    SetCancelTrigger(cancel_tip_ref.current?.filter(i=>i?.jobId!=sub_data?.runpodId))
                                    let data_from_subscription = {
                                        id: sub_data?.id,
                                        tipFor: sub_data?.tipFor,
                                        user: sub_data?.user,
                                        module: sub_data?.tipModule,
                                        title: GenerateTipTitle(sub_data),
                                        is_saved: sub_data?.favourite,
                                        content: sub_data?.tip,
                                        created_at: sub_data?.createdAt
                                    }
                                    const data_set = [data_from_subscription].concat(list)
                                    if (mountedRef.current) {
                                        SetList(data_set)
                                        SetPreviewTip(data_from_subscription)
                                    }
                                }
                                break

                            case "CREATE":
                                SetCancelTrigger(cancel_tip_ref.current?.concat({
                                    jobId: sub_data?.runpodId
                                }))
                                break
                            default:
                                break
                        }
                    }
                }
            });
            // aiTipSubscription()
        } catch (error) {
            stopAILoader()
        }
    }

    const getAiTipSetting = async () => {
        try {
            const { data } = await client.query({
                query: TIP_SETTINGS,
                variables: { module: "Meeting" },
                fetchPolicy: 'network-only'
            });
            if (data) {
                if (data?.tipSettingsByUser?.edges?.length) {
                    return data?.tipSettingsByUser?.edges[0]?.node?.active
                }
                else {
                    return true
                }
            } else {
                return false
            }
        } catch (error) {
            return false
        }
    }

    let loader_timer = React.useRef()

    const startAILoader = () => {
        if (loader_timer?.current) {
            clearTimeout(loader_timer?.current)
        }
        SetPreviewTip({
            id: "loader",
            loader: true
        })
        loader_timer.current = setTimeout(() => {

            SetPreviewTip(null)

        }, 25000);
    }

    const stopAILoader = () => {
        if (loader_timer?.current) {
            clearTimeout(loader_timer?.current)
        }
    }

    return (
        <AITipsView module={"Meetings"} {...props} preview_tip={preview_tip} SetPreviewTip={SetPreviewTip} tip_list={tip_list} />
    )
}

export default compose(withApollo)(MeetingAITipContainer)
