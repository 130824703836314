import { withApollo } from '@apollo/client/react/hoc'
import { FEEDBACK_FETCH_QTY, getIntFromString } from 'modules/look'
import React, { useEffect, useState } from 'react'
import { compose } from '../../core'
import ReceivedFeedbackView from '../components/ReceivedFeedbackView'
import { RECEIVED_FEEDBACK } from '../graphql/ReceivedFeedback.gql'
import { RECEIVED_FEEDBACK_SUBSCRIPTION } from '../graphql/receivedFeedbackSubscription.gql'

const ReceivedFeedbackContainer = props => {
  // console.log('',)
  const { client, me, history, filteritem, setFeedbackDetailsLoading, receivedHasLength, setFeedbackDetails, searchBarData, loaderState, activeTab, feedbackPermission,getFeedbackDetails} = props
  const [receivedFeedback, setreceivedFeedback] = React.useState(undefined)
  const [pageCount, setPageCount] = useState({ edgeCount: null, totalCount: null })
  const [queryObj, setQueryObj] = React.useState({})
  const [noFeedbackMsg, setNoFeedbackMsg] = useState()
  const ref = React.useRef({})
  const edgeCountMain = React.useRef(null)
  const totalCountMain = React.useRef(null)
  // target below.
  const [receivedFeedbackLoading, setReceivedFeedbackLoading] = useState(true)

  const m = React.useRef(true)

  let feedbackSub = undefined

  React.useEffect(() => {
    return () => {
      if (feedbackSub) {
        feedbackSub.unsubscribe();
      }
    }
  }, [])

  useEffect(() => {
    if (activeTab !== 'received' && feedbackSub) {
      feedbackSub.unsubscribe();

    }
  }, [activeTab])

  React.useEffect(() => {
    return () => {
      m.current = false
    }
  }, [])

  React.useEffect(() => {
    m.current = true

  }, [])



  const ReceivedFeedback = async (filterData) => {
    
    try{
      setReceivedFeedbackLoading(true)
    loaderState(true)

    const { data } = await client.query({
      query: RECEIVED_FEEDBACK,
      variables: filterData,
      fetchPolicy: 'network-only'
    });

    const arrMain = Object.values(data)?.map(item => item?.edges).flat();

    const idOccurrences = {};
    const uniqueArr = arrMain.filter(obj => {
      const id = obj.node.id;
      if (!idOccurrences[id]) {
        idOccurrences[id] = true;
        return true;
      }
      return false;
    });

    const dateSortedArr = uniqueArr.sort((a, b) => {
      const dateA = new Date(a.node.createdAt);
      const dateB = new Date(b.node.createdAt);
      // @ts-ignore
      return dateB - dateA;
    })

    
    edgeCountMain.current = Object.values(data)[0]?.edgeCount
    totalCountMain.current = Object.values(data)[0]?.totalCount

    if (data?.receivedFeedback?.edges?.length === 0) {
      // @ts-ignore
      setNoFeedbackMsg(true)
    }
    else {
      // @ts-ignore
      setNoFeedbackMsg(false)
    }
    if (data) {
      setreceivedFeedback(uniqueArr?.map(({ node }) => node))
      setPageCount({ ...pageCount, edgeCount: edgeCountMain.current, totalCount: totalCountMain.current })
      ref.current = uniqueArr?.map(({ node }) => node)
    }

    if (activeTab === 'received') {
      feedbackSub = client.subscribe({
        query: RECEIVED_FEEDBACK_SUBSCRIPTION,
        variables: filterData
      }).subscribe({
        next(result) {
          if (getIntFromString(result.data.feedbackSubscription.feedback?.feedbackFrom?.id) !== getIntFromString(me?.employee?.id)) {
            const data = client.readQuery({ query: RECEIVED_FEEDBACK, variables: filterData })
            let receivedFeedbackCacheData = uniqueArr?.map(({ node }) => node)

            switch (result.data.feedbackSubscription.mutation) {
              case 'CREATE':
                setPageCount({ ...pageCount, edgeCount: edgeCountMain.current + 1, totalCount: totalCountMain.current + 1 })

                const updated = [result.data.feedbackSubscription.feedback, ...receivedFeedbackCacheData]
                edgeCountMain.current = edgeCountMain.current + 1;
                totalCountMain.current = totalCountMain.current + 1;
                if (m.current) {
                  setreceivedFeedback(updated)
                }

                break
              case 'UPDATE':
                if (m.current) {
                  setreceivedFeedback(
                    receivedFeedbackCacheData.map(item =>
                      item.id === result?.data?.feedbackSubscription?.feedback?.id
                        ? result.data.feedbackSubscription.feedback
                        : item
                    ))
                }
                break
              case 'DELETE':
                if (m.current) {
                  setPageCount({ ...pageCount, edgeCount: edgeCountMain.current - 1, totalCount: totalCountMain.current - 1 })

                  setreceivedFeedback(
                    receivedFeedbackCacheData.filter(item =>
                      item.id === result?.data?.feedbackSubscription?.feedback?.id
                        ? false
                        : true
                    ))
                }
                edgeCountMain.current = edgeCountMain.current - 1;
                totalCountMain.current = totalCountMain.current - 1;
                break
              default:
                break
            }
          }
        }
      })
    }
    setReceivedFeedbackLoading(false)
    loaderState(false);
    }catch(e){
      console.error(e)
    }

  }

  React.useEffect(() => {

    if (me && activeTab === 'received' && searchBarData?.dropDown === 'Feedback' && feedbackPermission?.requried_permission) {
      const now = new Date();
      const all = new Date(0).toISOString();
      const pastWeek = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000).toISOString();
      ReceivedFeedback({ feedbackTo: me?.employee?.id, orderBy: ["-createdAt", "-updatedAt"], first: FEEDBACK_FETCH_QTY })
      setQueryObj({ feedbackTo: me?.employee?.id, orderBy: ["-createdAt", "-updatedAt"], first: FEEDBACK_FETCH_QTY })
    }
    else if (me === null) {
      history.push('/user/login')
    }
  }, [me, feedbackPermission?.requried_permission])

  return (
    <>
      <ReceivedFeedbackView pageCount={pageCount} activeTab={activeTab} {...props} queryObj={queryObj} noFeedbackMsg={noFeedbackMsg} setQueryObj={setQueryObj} receivedLoading={receivedFeedbackLoading} dataSource={receivedFeedback} filteritem={filteritem} getFeedbackDetails={getFeedbackDetails} onfilter={(e) => ReceivedFeedback(e)}></ReceivedFeedbackView>
    </>
  );
};

export default compose(withApollo)(ReceivedFeedbackContainer)













