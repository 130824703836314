import gql from "graphql-tag";

export const THREECS_RESPONSE_QUERY = gql`
query answers($survey: ID,$question: ID,$user:ID,$search:String){
    answers(response_Survey:$survey,question:$question,answer_Icontains:$search,response_User:$user){
        edges{
            node{
                id
                response{
                    id
                    responseDate
                }
                answer
            }
        }
  }
}
`;