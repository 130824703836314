import React from 'react';

import { withApollo } from '@apollo/client/react/hoc';
import { compose } from '../../core';
import { AdminLayout, getFaqCategoriesBase64StringFromInt } from '../../look';
import ReactMarkdown from '../../look/components/ReactMarkdown';
import { FAQ_CATEGORY_BY_ID_QUERY } from '../graphql/FaqCategoryQuery.gql';
import { FAQ_QUESTION_SUBSCRIPTION } from '../graphql/FaqQuestionSubscription.gql';
import ROUTE from '../route';
import { withDeleteFaqQuestion, withPageFilterUpdating, withPageState } from './PageOperations';

const FaqQuestion = props => {
  const { loading, faqCatagoryById, onPaginationChange, deleteFaqQuestion, onFiltersRemove, subscribeToMore, client, me } = props;

  const [questionSet,setQuestionSet] = React.useState(undefined);

  const columns = [
    {
      title: 'Question',
      key: 'question',
      // sorter: () => onOrderByChange(getOrderBy('question', orderBy)),
      render: (text, record) => <>{record && <ReactMarkdown>{record.question}</ReactMarkdown>}</>
    },
    {
      title: 'Answer',
      key: 'answer',
      // sorter: () => onOrderByChange(getOrderBy('answer', orderBy)),
      render: (text, record) => <>{record && <ReactMarkdown>{record.answer}</ReactMarkdown>}</>
    }
  ];



  const handleDeleteQuestion = async(id) =>{

   await deleteFaqQuestion({questionId:id})

  }

  React.useEffect(()=>{

    if(me){
      getCategoryById();
    }

  },[me])



  const getCategoryById = async () => {
    let id;
    if (props.match) {
      id = props.match.params.id;
    } else if (props.navigation) {
      id = props.navigation.state.params.id;
    }

    const { data } = await client.query({
      query: FAQ_CATEGORY_BY_ID_QUERY,
      variables: { id: getFaqCategoriesBase64StringFromInt(id) },
      fetchPolicy: 'network-only',
    });
    
    if (data?.faqCatagoryById) {
      setQuestionSet(data?.faqCatagoryById.faqquestionSet)
      faqQuestionDataRef.current = data?.faqCatagoryById.faqquestionSet;
      SubscribeToFaqQuestion()
    }
  }




  


  let faqQuestionSubscription = undefined;
  const m = React.useRef(true)

  const faqQuestionDataRef = React.useRef(null);

  


  React.useEffect(() => {
    return () => {
      m.current = false
    }
  }, [])

  React.useEffect(() => {
    m.current = true

  }, [])

  React.useEffect(() => {
    return () => {
      if (faqQuestionSubscription) {
        faqQuestionSubscription.unsubscribe();
      }
    }
  }, [])
 

 

  const SubscribeToFaqQuestion = async () => {

    faqQuestionSubscription = client.subscribe({
      query: FAQ_QUESTION_SUBSCRIPTION,
    }).subscribe({
      next(result) {

        switch (result.data.faqQuestionSubscription.mutation) {
         
          case 'DELETE':
           
            if (m.current) {
              let questionSet = faqQuestionDataRef.current?.edges
           
              let questionSetWithDeletedRecord = questionSet?.filter((question)=>
              question?.node?.id !== result.data.faqQuestionSubscription?.faqQuestion?.id)
           
              let qSet = {edgeCount:(faqQuestionDataRef.current?.edgeCount - 1 ),totalCount:(faqQuestionDataRef.current?.totalCount - 1 ),edges:questionSetWithDeletedRecord}
              setQuestionSet(qSet);
              
              faqQuestionDataRef.current = qSet;
            }

            break;
          default:
            break
        }

      }
    })

  }



  return (
    <AdminLayout
      table={true}
      active={ROUTE.faq}
      title="FAQ Question"
      loading={loading}
      tableData={questionSet}
      columns={columns}
      handleDeleteRecord = {handleDeleteQuestion}
      specificMutation = {true}
      onDelete={deleteFaqQuestion}
      editLink={ROUTE.editFaqQuestionLink}
      addLink={`${ROUTE.addFaqQuestionLink}${props.match.params.id}`}
      onPaginationChange={onPaginationChange}
      onFiltersRemove={onFiltersRemove}
      showBack={true}
    />
  );
};

export default compose(withPageState, withDeleteFaqQuestion, withPageFilterUpdating, withApollo)(FaqQuestion);
