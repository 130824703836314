import { Col, Dropdown, Menu, Tooltip, Button } from 'antd'
import React from 'react'
import styled from 'styled-components'
import moment from 'moment/moment';
import { customTabSelectionForDate, displayUtcToUserTimezone } from 'modules/look';
import { ca_detail_icons } from '../icon';
import { CloseCircleFilled } from "@ant-design/icons";

const CustomYearPicker = ( props ) => {

    const { disabledRange, defaultValue, currentSelected, toAnnual,onDateChange } = props

    const [ dateRageOpen, setRageOpen ] = React.useState( false )
    const [ currentQuarterList, setcurrentQuarterList ] = React.useState( [] )
    const [ loadedYear, setLoadedYear ] = React.useState( 5 )
    const [ currentYearsList, setcurrentYearsList ] = React.useState( [] )
    const [ selectedValue, setSelectedValue ] = React.useState( defaultValue )
    const [ selectedQuarter, setSelectedQuarter ] = React.useState()
    const [ LoadMoreYear, setLoadMoreYear ] = React.useState( false )
    const [ selectedYear, setSelectedYear ] = React.useState( currentSelected ? new Date().getFullYear() : null )
    const [ selectedHalfValue, setSelectedHalf ] = React.useState()
    const [selected_label,SetSelectedlabel] = React.useState()

    React.useEffect(()=>{
        if(selectedValue && onDateChange){
            onDateChange(selectedValue)
        }
    },[selectedValue])

    React.useEffect( () => {
        customTabSelectionForDate()
        let today
        let current_quarter
        let current_year
        let loopCount
        if ( disabledRange != undefined ) {
            today = disabledRange?.startdate
            current_quarter = Math.ceil( ( ( disabledRange?.startdate.getMonth() + 1 ) / 3 ) )
            current_year = today.getFullYear()
            if ( new Date( disabledRange?.duedate ).getFullYear() == current_year ) {
                loopCount = Math.ceil( ( ( new Date( disabledRange?.duedate ).getMonth() + 1 ) / 3 ) )
            }
            else {
                loopCount = 4
            }
        }
        else {
            today = new Date()
            current_quarter = Math.ceil( ( ( today.getMonth() + 1 ) / 3 ) );
            current_year = today.getFullYear()
            loopCount = 4
        }
        let quaterly_list = [ {
            label: `Q${current_quarter}-${current_year}`,
            key: current_quarter,
            year: current_year
        } ]
        if ( current_quarter != 4 ) {
            for ( let i = current_quarter + 1; i <= loopCount; i++ ) {
                quaterly_list.push( {
                    label: `Q${i}-${current_year}`,
                    key: i,
                    year: current_year
                } )
            }
        }
        else {
            for ( let i = 1; i <= loopCount; i++ ) {
                quaterly_list.push( {
                    label: `Q${i}-${current_year + 1}`,
                    key: i,
                    year: current_year + 1
                } )
            }
        }
        setcurrentQuarterList( quaterly_list )
    }, [ disabledRange ] )
    const getHalfYear = ( year ) => {
        let today
        let current_half
        let current_year
        let loopCount
        if ( disabledRange != undefined ) {
            today = disabledRange?.startdate
            current_half = Math.ceil( ( ( disabledRange?.startdate.getMonth() + 1 ) / 6 ) )
            current_year = today.getFullYear()
            if ( new Date( disabledRange?.duedate ).getFullYear() == year && new Date( disabledRange?.duedate ).getMonth() + 1 < 6 ) {
                loopCount = 1
            }
            else {
                loopCount = 2
            }
        }
        else {
            loopCount = 2
            today = new Date()
            current_half = Math.ceil( ( ( today.getMonth() + 1 ) / 6 ) );
            current_year = today.getFullYear()
        }
        let half_list = []
        if ( current_half != 2 ) {
            for ( let i = current_half + 1; i <= loopCount; i++ ) {
                half_list.push( {
                    label: `H${i}-${year}`,
                    key: i,
                    year: year
                } )
            }
        }
        else {
            for ( let i = 1; i <= loopCount; i++ ) {
                half_list.push( {
                    label: `H${i}-${year}`,
                    key: i,
                    year: year
                } )
            }
        }
        return half_list
    }
    React.useEffect( () => {
        let years = []
        let today = new Date()
        let current_year = today.getFullYear()
        if ( disabledRange != undefined ) {
            today = disabledRange?.startDate
            let lastYear = new Date( disabledRange?.duedate ).getFullYear()
            if ( !LoadMoreYear ) {
                if ( lastYear > new Date().getFullYear() ) {
                    setLoadedYear( lastYear - new Date().getFullYear() )
                }
                else {
                    setLoadedYear( 0 )
                }
            }
        }
        for ( let i = 0; i <= loadedYear; i++ ) {
            years.push( {
                year: current_year + i,
                half: getHalfYear( current_year + i ),
                key: current_year + i
            } )
        }
        setcurrentYearsList( years )

    }, [ loadedYear ] )

    const selecteQuaterlyDate = ( quarter ) => {
        SetSelectedlabel({label:quarter?.label})
        setSelectedYear(null)
        setSelectedHalf(null)
        setSelectedQuarter( quarter )
        let start_date = moment().year( quarter?.year ).quarter( quarter?.key ).startOf( "quarter" )?.utc()?.toISOString()
        let end_date = moment().year( quarter?.year ).quarter( quarter?.key ).endOf( "quarter" )?.utc()?.toISOString()
        setSelectedValue( [ start_date, end_date ] )
        if(toAnnual){
            toAnnual(false)
        }
    }

    const selectedYearDate = ( value ) => {
       
        SetSelectedlabel({label:`Annual-${value?.year}`})
        setSelectedQuarter(null)
        setSelectedHalf(null)
        setSelectedYear( value?.year )
        let start_date = moment().year( value?.year ).startOf( "year" )?.utc()?.toISOString()
        let end_date = moment().year( value?.year ).endOf( "year" )?.utc()?.toISOString()
        setSelectedValue( [ start_date, end_date ] )
        if(toAnnual){
            toAnnual(true)
        }
    }
    const selectedHalf = ( value ) => {
      
        SetSelectedlabel({label:value?.label})
        setSelectedHalf( value )
        setSelectedQuarter(null)
        setSelectedYear(null)
        let start_date
        let end_date
        if ( value?.key === 1 ) {
            start_date = moment().year( value?.year ).quarter( value?.key ).startOf( "quarters" )?.utc()?.toISOString()
            end_date = moment().year( value?.year ).quarter( 2 ).endOf( "quarters" )?.utc()?.toISOString()

        }
        else if ( value?.key === 2 ) {
            start_date = moment().year( value?.year ).quarter( 3 ).startOf( "quarters" )?.utc()?.toISOString()
            end_date = moment().year( value?.year ).quarter( 4 ).endOf( "quarters" )?.utc()?.toISOString()
        }

        setSelectedValue( [ start_date, end_date ] )
        if(toAnnual){
            toAnnual(false)
        }
    }
    const loadMoreQuartor = ( yearList ) => {
        let selected_years = yearList?.map( ( item ) => item?.year )
        let quaterly_list = []
        if ( disabledRange != undefined ) {
            if ( new Date( disabledRange?.duedate ).getFullYear() > Math.max( ...selected_years ) + 1 ) {
                for ( let i = 1; i <= 4; i++ ) {
                    quaterly_list.push( {
                        label: `Q${i}-${Math.max( ...selected_years ) + 1}`,
                        key: i,
                        year: Math.max( ...selected_years ) + 1
                    } )
                }
            }
            else if ( new Date( disabledRange?.duedate ).getFullYear() == Math.max( ...selected_years ) + 1 ) {
                let loopCount = Math.ceil( ( ( new Date( disabledRange?.duedate ).getMonth() + 1 ) / 3 ) )
                for ( let i = 1; i <= loopCount; i++ ) {
                    quaterly_list.push( {
                        label: `Q${i}-${Math.max( ...selected_years ) + 1}`,
                        key: i,
                        year: Math.max( ...selected_years ) + 1
                    } )
                }
            }
        }
        else {
            for ( let i = 1; i <= 4; i++ ) {
                quaterly_list.push( {
                    label: `Q${i}-${Math.max( ...selected_years ) + 1}`,
                    key: i,
                    year: Math.max( ...selected_years ) + 1
                } )
            }
        }

        setcurrentQuarterList( [ ...currentQuarterList, ...quaterly_list ] )
    }

    const findCurrentYear = ( yearList ) => {
        let selected_years = yearList?.map( ( item ) => item?.year )
        return Math.max( ...selected_years )
    }
    const onClearFilter=()=>{
        setSelectedValue()
        SetSelectedlabel()
        setSelectedYear(null)
        setSelectedHalf(null)
        setSelectedQuarter(null)
        onDateChange()
    }
    const RageFilter = () => (
        <Menu className='ca-custom-menu-date-container'>

            <Col style={{ display: "flex", flexDirection: "column", gap: "10px", marginTop: "20px", paddingRight: "5px", height: '100%' }}>
                <div className='overflow-selection' style={{ minHeight: "130px" }}>
                    <div style={{ display: 'flex', flexDirection: "column", width: "100%", justifyContent: 'center', alignContent: "center", textAlign: 'center' }}>
                        {currentQuarterList?.map( ( item ) => (
                            <div style={{cursor:"pointer"}} className={item?.key === selectedQuarter?.key && item?.year === selectedQuarter?.year ? 'ca-date-seleted-title' : 'ca-date-seletion-title'} onClick={() => selecteQuaterlyDate( item )}>{item?.label}</div>
                        ) )}
                    </div>
                </div>
                {disabledRange == undefined ? <h4 className='ca-date-see-more' onClick={() => loadMoreQuartor( currentQuarterList )}>Show More</h4> : ( new Date( disabledRange?.duedate ).getFullYear() > findCurrentYear( currentQuarterList ) ) ? <h4 className='see-more' onClick={() => loadMoreQuartor( currentQuarterList )}>Show More</h4> : <div style={{ height: "19px", width: "119px" }}></div>}
                <hr />
                <div className='overflow-selection' style={{ minHeight: "130px" }}>
                    {currentYearsList?.map( ( item ) => (
                        <>
                            <div>
                                {item?.half?.map( ( data ) => (
                                    <Tooltip title={data?.key == 1 ? `1st Half of ${data?.year}` : `2nd Half of ${data?.year}`}><div style={{ whiteSpace: 'nowrap',cursor:"pointer" }} className={data?.key === selectedHalfValue?.key && data?.year === selectedHalfValue?.year ? 'ca-date-seleted-title' : 'ca-date-seletion-title'} onClick={() => selectedHalf( data )}>{data?.label}</div></Tooltip>
                                ) )}
                            </div>
                            <div style={{ whiteSpace: 'nowrap',cursor:"pointer" }} className={item?.year === selectedYear ? 'ca-date-seleted-title' : 'ca-date-seletion-title'} onClick={() => selectedYearDate( item )}>Annual - {item?.year}</div>
                        </>
                    ) )}
                </div>
                {disabledRange == undefined ? <h4 className='ca-date-see-more' onClick={() => setLoadedYear( loadedYear + 5 )}>Show More</h4> : <div style={{ height: "19px", width: "119px" }}></div>}
            </Col>
        </Menu>
    )

    return (
        <>
            <Dropdown overlayStyle={{zIndex:1060}} overlay={RageFilter} visible={dateRageOpen} placement="bottomLeft">
                <Button className='ca-dropdown-btn' style={{borderRadius:".5em",minWidth:"150px"}} onClick={() => setRageOpen( true )}>
                    <p className='ca-drop-label' style={{ margin: "0"}} >{selected_label?.label||'Select Date'}</p>
                    {selected_label?
                    <CloseCircleFilled style={{fontSize:"20px",cursor:"pointer",color:"#D3D3D3"}}
                    onClick={()=>onClearFilter()}/>:
                    <img src={ca_detail_icons?.dropdown_Arrow} alt="" style={{ width: "13px", height: "10px" }} />
                    }
                </Button>
            </Dropdown>
            {dateRageOpen ? ( <div className='overlay-dynamic-island' onClick={() => setRageOpen( false )} /> ) : null}
        </>
    )
}

export default CustomYearPicker

const DropdownButton = styled( Button )`
min-width:100px;
width:fit-content;
display:flex;
height:38px;
justify-content:space-between;
align-items:center;
gap:5px
border-radius: 0.75em;
background: #FFF;
`