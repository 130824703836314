import React from 'react';

import { AdminLayout } from '../../look';
import ROUTE from '../route';

import GroupFormComponent from './GroupFormComponent';

const AddGroupView = props => {
  return (
    <AdminLayout table={false} active={ROUTE.group} title="Add Group" FormComponent={<GroupFormComponent {...props} />} />
  );
};

export default AddGroupView;
