import { graphql } from '@apollo/client/react/hoc';

// Query
import { PERCEPTION_SURVEY_REPORT } from '../graphql/PerceptionSurveyReport.gql.js';
import { PREDICTION_SURVEY_REPORT } from '../graphql/PredictionSurveyReport.gql.js';

// Query
export const withTheirPerceptionSurveyReport = Component =>
  graphql(PERCEPTION_SURVEY_REPORT, {
    options: ({ requestedUserId, allowComparison }) => {
      let variables = {};
      if (allowComparison) {
        variables.requestedUserId = requestedUserId;
      } else {
        variables.requestId = requestedUserId;
      }
      return {
        variables
      };
    },
    props({ data: { loading, error, getReportDataForRequestedUsersPerceptionSurvey, subscribeToMore, updateQuery } }) {
      return {
        loading,
        error,
        theirReport:
          getReportDataForRequestedUsersPerceptionSurvey && JSON.parse(getReportDataForRequestedUsersPerceptionSurvey),
        subscribeToMore,
        updateQuery
      };
    }
  })(Component);

export const withTheirPredictionSurveyReport = Component =>
  graphql(PREDICTION_SURVEY_REPORT, {
    options: ({ requestedUserId, allowComparison }) => {
      let variables = {};
      if (allowComparison) {
        variables.requestedUserId = requestedUserId;
      } else {
        variables.requestId = requestedUserId;
      }
      return {
        variables
      };
    },
    props({ data: { loading, error, getReportDataForRequestedUsersPredictionSurvey, subscribeToMore, updateQuery } }) {
      return {
        loading,
        error,
        theirReport:
          getReportDataForRequestedUsersPredictionSurvey && JSON.parse(getReportDataForRequestedUsersPredictionSurvey),
        subscribeToMore,
        updateQuery
      };
    }
  })(Component);
