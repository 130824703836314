import { Button, Input, Modal, Spin, message } from "antd";
import gql from "graphql-tag";
import { getIntFromString } from "modules/look";
import { dashboard_home_icons, organization_icons } from "modules/poc-dashboard/icons";
import React from "react";
import { useMutation } from "@apollo/react-hooks";
const CreateVerticalView = (props) => {
    const { label, createVertical, me,verticalCount } = props
    const [mutationData, setmutationData] = React.useState();
    const [visible, setVisble] = React.useState(false)
    const [vertical_name, setVerticalName] = React.useState("")
    const [error_on_mutation, setErrorOnMutation] = React.useState([])
    const [page_loading, set_PageLoading] = React.useState(false)
    const [selected_tab, setSelectedTab] = React.useState(
        {
            key: "add_tams",
            label: " Add Teams",
        },
    )
    const [tabs, SetTabs] = React.useState([
        {
            key: "add_tams",
            label: " Add Teams",
        },
        {
            key: "create_sub_vertical",
            label: "Create Sub vertical",
        }
    ])
    const [formData, setFormData] = React.useState([]);
    const [subformData, setSubFormData] = React.useState([]);
    React.useEffect(() => {
        if (selected_tab?.key == 'add_tams') {
            setSubFormData([])
        }
        else if (selected_tab?.key == 'create_sub_vertical') {
            setFormData([])
        }
    }, [selected_tab])
    const CREATE_TEAM = gql`
        mutation createTeam {
            ${mutationData?.teamsData
            ?.map(
                (item, index) =>
                    `create${index}: createTeam(teamData:{name:"${item?.team_name}",verticalId:${Number(getIntFromString(mutationData?.vertical_id))}}) {
                    team{
                        id
                    }
                }
                `
            )
            .join("")}
        }
    `;

    const CREATE_SUBVERTICAL = gql`
        mutation createVertical {
            ${mutationData?.subVerticalData
            ?.map(
                (item, index) =>
                    `create${index}: createVertical(verticalData:{name:"${item?.sub_vertical_name}",organizationId:${Number(getIntFromString(me?.employee?.organizationSet?.edges[0]?.node?.id))},parentverticalId:${Number(getIntFromString(mutationData?.vertical_id))}}) {
                    vertical{
                        id
                    }
                }
                `
            )
            .join("")}
        }
    `;

    const [createTeamDefinition, { data, isloading, error }] = useMutation(selected_tab?.key == "add_tams" ? CREATE_TEAM : CREATE_SUBVERTICAL, { onError: (err) => errorHandling(err) });

    React.useEffect(() => {
        if (mutationData != undefined) {
            createTeamMutation()
        }
    }, [mutationData])

    const errorHandling = (err) => {
        if (err?.graphQLErrors?.length) {
            setErrorOnMutation(err?.graphQLErrors)
        }
    }
    const addForm = () => {
        let form_data = [...formData];
        form_data.push({ team_name: "", invited: false, error: false })
        setFormData(form_data);
    };

    const addSubForm = () => {
        let form_data = [...subformData];
        form_data.push({ sub_vertical_name: "", invited: false, error: false, })
        setSubFormData(form_data);
    }

    const handleChange = (index, field, value) => {
        const updatedFormData = [...formData];
        updatedFormData[index][field] = value;
        setFormData(updatedFormData);
    };

    const handleSubChange = (index, field, value) => {
        const updatedFormData = [...subformData];
        updatedFormData[index][field] = value;
        setSubFormData(updatedFormData);
    };

    const handleSubmit = () => {
        if (vertical_name !== "") {
            if (selected_tab?.key == "add_tams") {
                const isEmptyTeamNamePresent = formData.some(item => item?.team_name?.trim() === "");
                if (isEmptyTeamNamePresent) {
                    message.error('Please Enter Team Name')
                }
                else {
                    set_PageLoading(true)
                    handleCreateVertical({ vertical: vertical_name, teamsData: formData })
                }

            }
            else if (selected_tab?.key == "create_sub_vertical") {
                const isEmptyTeamNamePresent = subformData.some(item => item?.sub_vertical_name?.trim() === "");
                if (isEmptyTeamNamePresent) {
                    message.error('Please Enter Sub-vertical Name')
                }
                else {
                    handleCreateVertical({ vertical: vertical_name, subVerticalData: subformData })
                }
            }
        }
        else {
            message.error("vertical name is required!")
        }
    }

    const handleCreateVertical = async (value) => {
        let verticalData = {
            name: value.vertical,
            organizationId: getIntFromString(me?.employee?.organizationSet?.edges[0]?.node?.id)
        }
        const response = await createVertical({ verticalData: verticalData })
        if(verticalCount){
            verticalCount()
        }
        if (response && selected_tab?.key == "add_tams") {
            if (value?.teamsData?.length > 0) {
                setmutationData({ teamsData: value?.teamsData, vertical_id: response?.id })
            }
            else {
                set_PageLoading(false)
            }
        }

        else if (response && selected_tab?.key == "create_sub_vertical") {
            if (value?.subVerticalData?.length > 0) {
                setmutationData({ subVerticalData: value?.subVerticalData, vertical_id: response?.id })
            }
            else {
                set_PageLoading(false)
            }
        }
    }

    const createTeamMutation = async () => {
        try {
            const response = await createTeamDefinition()
            // new to do send back the response as props
            if (response) {
                message.success("Submitted successfully")
                set_PageLoading(false)
                setFormData([])
                setSubFormData([])
                setVisble(false)
            } else {
            }
        } catch (error) {
            if (selected_tab == 'add_tams') {
                message.error(`Faild to Create Team`)
            }
            else {
                message.error(`Faild to Create sub vertical`)
            }

            set_PageLoading(false)
        }
    }
    const removeTeam =(index)=>{
        let form_data = [...formData];
        form_data.splice(index, 1);
        setFormData(form_data);
    }
    const removeSubVertival =(index)=>{
        let form_data = [...subformData];
        form_data.splice(index, 1);
        setSubFormData(form_data);
    }
    return (
        <div style={{ marginTop: "1em" }}>
            <Button className="poc-active-btn" style={{textTransform:"none",fontWeight:"400"}} onClick={() => setVisble(true)}>
                {label}
            </Button>
            <Modal
                visible={visible}
                centered
                footer={false}
                destroyOnClose={true}
                closable={false}
                style={{ maxWidth: "40em" }}
                width={"90%"}>
                <Spin spinning={page_loading}>
                    <div  style={{ display: 'flex', flexDirection: "column", gap: '1em', width: "100%", padding: '1em'}}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "1em" }}>
                            <h4 className="poc-dashboard-card-title" style={{ fontWeight: 600 }}>Create Vertical</h4>
                            <img src={organization_icons.close_icon} alt="close_icon" style={{ cursor: "pointer" }} onClick={() => { setVisble(false); setFormData([]); setSubFormData([]) }} />
                        </div>
                        <div  style={{ display: 'flex', flexDirection: "column", gap: '1em', }}>
                            <div >
                                <h4 className='vertical-form-label'><span style={{ color: "#E86161" }}>*</span>Vertical name</h4>
                                <Input className='vertical-input' maxLength={200} style={{ marginTop: "0.75em" }} placeholder='Vertical name' onChange={(e) => setVerticalName(e?.target?.value)}></Input>
                            </div>
                            <div>
                                <div className="vertical-switch-tab">
                                    {tabs?.map(i => (
                                        <div
                                            className={`vertical-switch-tab-text ${selected_tab.key === i?.key ? "vertical-switch-tab-selected" : ""}`}
                                            onClick={() => { setSelectedTab(i) }}
                                        >
                                            {i?.label}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {selected_tab?.key == "add_tams" ? <div className="invitation-overflow" style={{overflow: "auto", maxHeight: "45vh" }}>
                                {formData?.map((field, index) => (
                                    <div style={{ marginTop: "1em" }}>
                                        <h4 className='vertical-form-label'><span style={{ color: "#E86161" }}>*</span> Add Teams {formData?.length > 1 ? `(${index + 1})` : null}</h4>
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center', gap: '.5em' }}>
                                            <Input
                                                className='vertical-input'
                                                style={{ marginTop: "0.75em" }}
                                                placeholder='Team name'
                                                value={field.team_name}
                                                onChange={(e) =>
                                                    handleChange(index, "team_name", e.target.value)
                                                }
                                                autoComplete="off"></Input>
                                            <img src={dashboard_home_icons?.close_icons} alt="" style={{ cursor: "pointer",paddingTop:"10px" }} onClick={()=>removeTeam(index)} />
                                        </div>
                                    </div>
                                ))}
                                <Button
                                    className="user-board-welcome-banner-button"
                                    style={{
                                        height: "2.5em",
                                        fontSize: "1.1em",
                                        width: "8em",
                                        cursor: "pointer",
                                    }}
                                    onClick={addForm}
                                >
                                    + Add Team
                                </Button>
                            </div> :
                                <div className="invitation-overflow" style={{overflow: "auto", maxHeight: "45vh" }}>
                                    {subformData?.map((field, index) => (
                                        <div style={{ marginTop: "1em" }}>
                                            <h4 className='vertical-form-label'><span style={{ color: "#E86161" }}>*</span> Add Sub Vertical {subformData?.length > 1 ? `(${index + 1})` : null}</h4>
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: 'center', gap: '.5em' }}>
                                                <Input
                                                    className='vertical-input'
                                                    style={{ marginTop: "0.75em" }}
                                                    placeholder='Sub vertical name'
                                                    value={field.sub_vertical_name}
                                                    onChange={(e) =>
                                                        handleSubChange(index, "sub_vertical_name", e.target.value)
                                                    }
                                                    autoComplete="off"></Input>
                                                <img src={dashboard_home_icons?.close_icons} alt="" style={{ cursor: "pointer",paddingTop:"5px" }} onClick={()=>removeSubVertival(index)}/>
                                            </div>
                                        </div>
                                    ))}
                                    <Button
                                        className="user-board-welcome-banner-button"
                                        style={{
                                            height: "2.5em",
                                            fontSize: "1.1em",
                                            width: "12em",
                                            cursor: "pointer",
                                        }}
                                        onClick={addSubForm}
                                    >
                                        + Add Sub Vertical
                                    </Button>
                                </div>}

                            <div style={{ width: "100%", display: 'flex', gap: "1em", justifyContent: 'end', alignItems: "center" }}>
                                <Button className="organization-cancel-btn" onClick={() => { setVisble(false); setFormData([]); setSubFormData([]) }}>Cancel</Button>
                                <Button className="organization-save-btn" onClick={() => handleSubmit()}>Create</Button>
                            </div>
                        </div>
                    </div>
                </Spin>

            </Modal>
        </div>
    )
}

export default CreateVerticalView;