import { ThemeProvider } from '../../look/themeProvider/ThemeProvider';
import React, { useState } from 'react';
import { compose } from '../../core';
import ReportsComponent from '../components/ReportsComponent';
import { withMeLevel } from '../../user/containers/UserOperations';
import { withApollo } from '@apollo/client/react/hoc';
import { ME_SURVEY_FOR_CEO_OR_POC_QUERY } from '../../user/graphql/GetSurveyForCeoOrPoc.gql';
import { ME_SURVEY_FOR_TEAM_QUERY } from '../../user/graphql/GetSurveyForTeam.gql';
import { ME_SURVEY_FOR_VERTICAL_HEAD_QUERY } from '../../user/graphql/GetSurveyForVerticalHead.gql';
import { getIntFromString, getOrganizationBase64StringFromInt } from '../../look';
import { ME_SURVEY_RESPONSES_FOR_TEAM_QUERY } from '../../user/graphql/GetSurveyResponsesForTeam.gql';
import { ME_SURVEY_RESPONSES_FOR_CEO_OR_POC_QUERY } from '../../user/graphql/GetSurveyResponsesForCeoOrPoc.gql';
import { ME_SURVEY_RESPONSES_FOR_VERTICAL_HEAD_QUERY } from '../../user/graphql/GetSurveyResponsesForVerticalHead.gql';


const ReportsContainer = props => {
    const {
        loading,
        level,
        client
    } = props;
    const [surveyData, setSurveyData] = React.useState([])
    const [tableData, setTableData] = React.useState([])
    const [designation, setDesignation] = React.useState('employee')
    const [surveyLoading, setSurveyLoading] = React.useState(true)

    const [columnFilters, setColumnFilters] = React.useState({});
    const [columnFiltersId, setColumnFiltersId] = React.useState([]);


    React.useEffect(() => {
        setSurveyLoading(false)
    }, [surveyData])

    const updateColumnFilters = (id, filters) => {
        if (filters === undefined){
            delete columnFilters[id]; 
            let m = columnFiltersId.filter(function (item) {
                return item !== id
            })
            setColumnFiltersId(m)
            return
        }
        let updatedValue = {};
        // let intId = getIntFromString(id)
        updatedValue[id] = filters ;
        // setColumnFilters(shopCart => ({
        //     ...columnFilters,
        //     ...updatedValue
        // }))
        Object.assign(columnFilters, updatedValue)
        setColumnFiltersId(p => [...p, id])
    }


    React.useEffect(() => {
        //Find level of Employee
        let title = 'employee'
        let q = ME_SURVEY_FOR_TEAM_QUERY
        let org = level?.emp?.orgCeoEmployee?.edges.length
        let poc = level?.emp?.orgPocEmployee?.edges.length
        let vh = level?.emp?.verticalHeadEmployee?.edges.length
        if (vh > 0) {
            title = 'vhead'
            q = ME_SURVEY_FOR_VERTICAL_HEAD_QUERY
        }
        if (poc > 0 || org > 0) {
            title = 'head'
            q = ME_SURVEY_FOR_CEO_OR_POC_QUERY
        }
        setDesignation(title)
        fetchData(q, title)

    },[level])

    const SelectedSurvay = (val) => {
        fetchSurvey(val)
    }

    // get surveys based on title
    /* eslint-disable no-unused-expressions */
    const fetchData = async (q, title) => {
        let id = '';
        if (props.match) {
            id = props.match.params.id;
        } else if (props.navigation) {
            id = props.navigation.state.params.id;
        }

        const { data } = await client.query({
            query: q,
            variables: { id: getOrganizationBase64StringFromInt(Number(id || props.id)) },
        });
        let surveysContainer = data?.surveys?.emp
        let surveys = []
        switch (title) {
            case 'employee': {
                data?.surveys?.employee.teamManagerEmployee?.edges.map(e => {
                    e?.node?.surveySet?.edges.map(s => {
                        surveys.push({ 'label': s?.node?.name, 'value': s?.node?.id })
                    })
                })
                break
            }
            case 'vhead': {
                surveysContainer?.verticalHeadEmployee?.edges.map(e => {
                    e?.node?.surveySet?.edges.map(s => {
                        surveys.push({ 'label': s?.node?.name, 'value': s?.node?.id })
                    })
                })
                break
            }
            case 'head': {
                let sIds = []
                surveysContainer?.orgCeoEmployee?.edges.map(e => {
                    e?.node?.surveySet?.edges.map(s => {
                        if (!sIds.includes(s?.node?.id)){
                            sIds.push(s?.node?.id);
                            surveys.push({ 'label': s?.node?.name, 'value': s?.node?.id })
                        }
                    })
                })
                surveysContainer?.orgPocEmployee?.edges.map(e => {
                    e?.node?.surveySet?.edges.map(s => {
                        if (!sIds.includes(s?.node?.id)) {
                            sIds.push(s?.node?.id);
                            surveys.push({ 'label': s?.node?.name, 'value': s?.node?.id })
                        }
                    })
                })
                break
            }
        }
        setSurveyData(surveys)
    }

    const fetchSurvey = async (val) => {
        let id = '';
        if (props.match) {
            id = props.match.params.id;
        } else if (props.navigation) {
            id = props.navigation.state.params.id;
        }
        let responseData = []
        switch (designation) {
            case 'employee': {
                const { data } = await client.query({
                    query: ME_SURVEY_RESPONSES_FOR_TEAM_QUERY,
                    variables: { id: getOrganizationBase64StringFromInt(Number(id || props.id)), surveyId: val },
                });
                let survayData = data?.surveys?.employee?.teamManagerEmployee?.edges
                survayData.map(data => {
                    data?.node?.employees?.edges.map(item => {
                        responseData.push(item?.node?.user)
                    })
                })
                break
            }
            case 'vhead': {
                const { data } = await client.query({
                    query: ME_SURVEY_RESPONSES_FOR_VERTICAL_HEAD_QUERY,
                    variables: { id: getOrganizationBase64StringFromInt(Number(id || props.id)), surveyId: val },
                });
                let survayData = data?.surveys?.emp?.verticalHeadEmployee?.edges
                survayData.map(data => {
                    data?.node?.employees?.edges.map(item => {
                        responseData.push(item?.node?.user)
                    })
                })
                break
            }
            case 'head': {
                const { data } = await client.query({
                    query: ME_SURVEY_RESPONSES_FOR_CEO_OR_POC_QUERY,
                    variables: { id: getOrganizationBase64StringFromInt(Number(id || props.id)), surveyId: val },
                });
                if (data && data?.surveys?.emp?.orgCeoEmployee?.edges?.length) {
                    data?.surveys?.emp?.orgCeoEmployee?.edges.map(data => {
                        data?.node?.employees?.edges.map(item => {
                            responseData.push(item?.node?.user)
                        })
                    })
                }
                else
                    data?.surveys?.emp?.orgPocEmployee?.edges.map(data => {
                        data?.node?.employees?.edges.map(item => {
                            responseData.push(item?.node?.user)
                        })
                    })
                break
            }
        }
        setTableData(responseData)
    }

    return (
        <>
            <ReportsComponent {...props} loading={loading} level={level}
            surveyData={surveyData} 
            surveyLoading={surveyLoading}
            updateColumnFilters={updateColumnFilters}
            columnFilters={columnFilters}
                columnFiltersId={columnFiltersId}
            tableData={tableData} onSurvaychange={(e) => SelectedSurvay(e)} />
         </>
    );
};


export default compose(withApollo, withMeLevel)(ReportsContainer);
