import gql from 'graphql-tag';
import { FAQ_QUESTION_TYPE } from './FaqQuestionType.gql';

export const FAQ_QUESTION_QUERY = gql`
  ${FAQ_QUESTION_TYPE}
  query faqQuestionById($id: ID!) {
    faqQuestionById(id: $id) {
      ...FaqQuestionType
    }
  }
`;
