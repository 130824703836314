import React from "react"
import { compose } from "modules/core" 
import { exportToCsv, getIntFromString, getResponsesBase64StringFromInt, globalPermissionValidator, parseMarkDownText } from "modules/look"
import { cc_graduation_test, report_tool_data, SURVEY_WHOLE_IDENTITY, SURVEY_WHOPOSHORO } from "../../../../config"
import { Spin } from "antd"
import NoPermissionView from "modules/kudos-user/component/NoPermissionComponent"
import { RESPONSE_BY_ID, SURVEY_REPORT_TOOL } from "modules/poc-dashboard/graphql/surveyreportQuery.gal"
import { withApollo } from '@apollo/client/react/hoc';
import moment from "moment"
import { survey_permission } from "Permissions/survey.permission"
import PocUserReportView from "modules/poc-dashboard/components/poc_report/pocUserReportView"
const CustomUserReportContainer = (props) => {
    const { me, match, client, userPermission } = props
    const [permission, setPermission] = React.useState()
    const [ui_flow_type,SetUIFlow] = React.useState("")
    const [loading,Setloading] = React.useState(false)
    const [basic_detail,SetBasicDetail] = React.useState()
    const [list,SetList] = React.useState([])
    const [getToolById, setToolData] = React.useState()
    //Permission Check
    React.useEffect(() => {
        if (userPermission?.length) {
            let permission = globalPermissionValidator(survey_permission, userPermission)
            setPermission(permission)
        }
    }, [userPermission, me])

    let expected_answer_format_survey = cc_graduation_test
    let report_tool_available = [SURVEY_WHOPOSHORO,SURVEY_WHOLE_IDENTITY]

    React.useEffect(() => {
     
        if (permission?.required_permission && match) { 
            if(expected_answer_format_survey?.includes(parseInt(match?.params?.survey_id))){
                SetUIFlow("EXPECTED_ANSWER")
                GetSurveyResponse(match?.params?.response_id,"EXPECTED_ANSWER")
            }
            else if(report_tool_available?.includes(parseInt(match?.params?.survey_id))){
                SetUIFlow("REPORT_TOOL")
                GetReportTool(match?.params?.response_id)
            }else{ 
                SetUIFlow("NORMAL_ANSWER")
                GetSurveyResponse(match?.params?.response_id,"NORMAL_ANSWER")
            }
            
        }
    }, [permission,match])


    const GetSurveyResponse = async (id,type) => {
        try { 
            Setloading(true)
            const { data } = await client.query({
                query: RESPONSE_BY_ID,
                variables:{id:getResponsesBase64StringFromInt(id)},
                fetchPolicy: 'network-only'
            }); 
            if (data.responseByResponseId) { 
                if(getIntFromString(data?.responseByResponseId?.survey?.id) != parseInt(match?.params?.survey_id)){
                    SetUIFlow("ERROR_PAGE")
                    return
                }
                if(type=="EXPECTED_ANSWER"){
                    handleExpectedAnswer(data.responseByResponseId)
                }else{
                    handleNormalAnswer(data.responseByResponseId)
                }
            }
        }
        catch (e) {
            Setloading(false)  
        }
    }


    
    const header_list ={
        "EXPECTED_ANSWER":[
            {
                label :"Sr.No",
                custom_style:{textAlign:"center",width:"10%"}
            },
            {
                label:"Questions",
                custom_style:{width:"40%"}
            },
            {
                label:"Answers",
                custom_style:{width:"40%"}
            },
            {
                label:"Scores",
            }
        ],
        "NORMAL_ANSWER":[
            {
                label :"Sr.No",
                custom_style:{textAlign:"center",width:"10%"}
            },
            {
                label:"Questions",
                custom_style:{width:"40%"}
            },
            {
                label:"Answers",
                custom_style:{width:"40%"}
            } 
        ]
    }

    const handleExpectedAnswer=(data)=>{
    
        let answerSet = data?.answerSet?.edges?.map(({node})=>node) 
        let question_ids = [...new Set(answerSet?.map(i=>i?.question?.id))]
        let answer_value = []
        let total_score = 0
        question_ids.forEach((q,index)=>{
            let list_answer = answerSet?.filter(a=>a?.question?.id==q)
            let choice_set = list_answer[0]?.question?.choiceSet?.edges?.map(({node})=>node)
            let user_answer = list_answer?.map(ans=>ans?.answer)?.flatMap(item => item.split(','))
            let answer_list = choice_set?.filter(c=>user_answer?.includes(c?.value))?.map(l=>parseMarkDownText(l?.label))
            let answer = answer_list?.join(",")
            let correct_answers = choice_set?.filter(cw=>cw?.answerKey)?.map(i=>i?.value)
            let user_input_is_correct = !correct_answers?.length?false : correct_answers?.some(i=>user_answer?.includes(i)) 
            if(user_input_is_correct){
                total_score = total_score + 1
            }
            let row_data = [
                {
                    type:"label",
                    key:"Sr.No",
                    value:index+1
                },
                {
                    type:"label",
                    key:"Question",
                    value:parseMarkDownText(list_answer[0]?.question?.questionText)
                },
                {
                    type:"label",
                    key:"Answer",
                    value:answer
                },
                {
                    type:"label",
                    key:"Score",
                    value:user_input_is_correct?"1":"0"
                }
            ]
            answer_value.push(row_data)
        })
     
        SetBasicDetail({
            survey_name:data?.survey?.name,
            survey_id:data?.survey?.id,
            response_date:data?.responseDate,
            response_id:data?.id,
            user:data?.user,
            show_score:true,
            total_score,
            max_score:question_ids?.length,
        })
        SetList(answer_value)
        Setloading(false)
    }

    const handleNormalAnswer=(data)=>{
    
        let answerSet = data?.answerSet?.edges?.map(({node})=>node) 
        let question_ids = [...new Set(answerSet?.map(i=>i?.question?.id))]
        let answer_value = []
        let total_score = 0
        question_ids.forEach((q,index)=>{
            let list_answer = answerSet?.filter(a=>a?.question?.id==q)
            let choice_set = list_answer[0]?.question?.choiceSet?.edges?.map(({node})=>node)
            let user_answer = list_answer?.map(ans=>ans?.answer)
            let answer_list = choice_set?.filter(c=>user_answer?.includes(c?.value))?.map(l=>parseMarkDownText(l?.label))
            let answer = answer_list?.join(",")
  
            let row_data = [
                {
                    type:"label",
                    key:"Sr.No",
                    value:index+1
                },
                {
                    type:"label",
                    key:"Question",
                    value:parseMarkDownText(list_answer[0]?.question?.questionText)
                },
                {
                    type:"label",
                    key:"Answer",
                    value:answer
                }
           
            ]
            answer_value.push(row_data)
        })
     
        SetBasicDetail({
            survey_name:data?.survey?.name,
            survey_id:data?.survey?.id,
            response_date:data?.responseDate,
            response_id:data?.id,
            user:data?.user,
        })
        SetList(answer_value)
        Setloading(false)
    }
 

    const GetReportTool = async (id) => {
        try {
            Setloading(true)
            let tool = report_tool_data?.find(i=>i?.survey_id == parseInt(match?.params?.survey_id))
            const { data } = await client.query({
                query: SURVEY_REPORT_TOOL,
                variables:{id:getResponsesBase64StringFromInt(id),responseId:id,toolId:tool?.tool_id},
                fetchPolicy: 'network-only'
            });
            if (data) {
                
                let response_detail = data?.responseByResponseId
                if(getIntFromString(response_detail?.survey?.id) != parseInt(match?.params?.survey_id)){
                    SetUIFlow("ERROR_PAGE")
                    return
                }
                SetBasicDetail({
                    survey_name:response_detail?.survey?.name,
                    survey_id:response_detail?.survey?.id,
                    response_date:response_detail?.responseDate,
                    response_id:response_detail?.id,
                    user:response_detail?.user,
                })
                if(typeof(data.responseByResponseId?.report360) === 'string'){
                    setToolData(JSON.parse(data?.responseByResponseId?.report360))
                }
                Setloading(false)
            }
        }
        catch (e) {

        }
    }

    const downloadCSV=()=>{
        let data = list?.map(i=>{
            let value ={}
            i.forEach(e => {
                value[e?.key=='Score'?`Score (Total Score : ${basic_detail?.total_score}/${basic_detail?.max_score})`:e?.key] = e?.value
            });
            return value
        }) 
        exportToCsv(`Report for ${basic_detail?.survey_name} of ${basic_detail?.user?.email}_${moment()}`, data)
    }

    return (
        <>

            {(permission && !permission?.required_permission) && (<NoPermissionView />)}

            {
                (permission && permission?.required_permission) && (
                    <PocUserReportView {...props} ui_flow_type={ui_flow_type} loading={loading} list={list} basic_detail={basic_detail} header_list={header_list[ui_flow_type]} getToolById={getToolById} downloadCSV={downloadCSV}/>
                )
            }
            {
                !permission && (
                    <div className="role-no-permission-container">
                        <Spin spinning={true} size="large"></Spin>
                    </div>
                )
            }
        </>
    )

}

export default compose(withApollo)(CustomUserReportContainer)