import gql from 'graphql-tag';


export const DELETE_ACTIONITEM_MUTATION = gql`

  mutation deleteActionItem($id: ID!) {
    deleteActionItem(id:$id) {
        actionItem  {
            id
      }
    }
  }
`;
