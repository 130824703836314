
import { TEAM_LIST, TEAM_LIST_VERTICAL_ID, VERTICAL_LIST } from "modules/poc-dashboard/graphql/organizationQuery.gql";
import EditEmployeeDetailView from "../../components/organization/editEmployeeDetailView";
import React from "react";
import { compose } from "modules/core";
import { withApollo } from '@apollo/client/react/hoc';
import { withEditPocEmpolee } from "./organizationOperations";
import { getIntFromString } from "modules/look";
const EditEmployeeDetailContainer =(props)=>{
    const {client,me,updateEmployee,onCancel,edit_details,onSuccess}=props
    const [verticals,setVerticals]=React.useState()
    const [teams,setTeams]=React.useState([])
    React.useEffect(()=>{
        if(me){
            verticalList()
        }
        
    },[me])
    const verticalList = async()=>{
        const { data } = await client.query({
            query: VERTICAL_LIST,
            fetchPolicy: 'network-only'
          });
          if (data.verticals?.edges?.length) {
            setVerticals(data.verticals?.edges?.map(({node})=>node))
          }
    }
    const getTeamlist = async(id)=>{
        const { data } = await client.query({
            query: TEAM_LIST_VERTICAL_ID,
            variables: {
                vertical:id
            },
            fetchPolicy: 'network-only'
          });
          if (data.Teams?.edges?.length) {
            setTeams(data.Teams?.edges?.map(({node})=>node))
          }
    }
    const updateUserDetail = async(value)=>{
        if(value){
            let update_data={
                id:getIntFromString(edit_details?.id),
                fname:value?.firstName,
                lname:value?.lastName,
                email:value?.email,
            }
            let response = await updateEmployee({...update_data})
            if(response){
                let edited_details = {...edit_details,firstName:value?.firstName,lastName:value?.lastName,email:value?.email}
                onSuccess(edited_details)
            }
        }
    }
    return (
        <>
        <EditEmployeeDetailView {...props} 
        verticals={verticals} teams={teams}
         getTeamlist={(key)=>getTeamlist(key)}
         updateuser={(value)=>updateUserDetail(value)}/>
        </>
    )
}

export default compose(withApollo,withEditPocEmpolee)(EditEmployeeDetailContainer);