
import DiscardContainer from './container/DiscardContainer';
import ObjectiveDetailContainer from './container/ObjectiveDetailContainer';
import ROUTE from './route';
import DiscardRequestDetailContainer from './container/DiscardRequestDetailContainer';
import OKRHomeContainer from './container/OKRHomeContainer';
import KeyResultDetailContainer from './container/KeyResultDetailsContainer';
import MilestoneDetailContainer from './container/MilestoneDetailContainer';
import RequestDetailContainer from './container/RequestDetailContainer';
import DashboardHomePageContainer from './container/DashboardHomePageContainer';
import TeamsDashboardComponent from './container/OkrTeamsDashboard';

export default [
    {
        name: 'discard Request',
        path: ROUTE.discardRequest,
        component: DiscardContainer,
        exact:true,
        protect:true
    },
    {
        name: 'View Objective',
        path: ROUTE.viewObjective,
        component: ObjectiveDetailContainer,
        exact:true,
        protect:true
    },

      {
        name: 'okrDashboard',
        path: ROUTE.okrDashboard,
        component: DashboardHomePageContainer,
        exact:true,
        protect:true
      },
  
    {
        name: 'discard Request',
        path: ROUTE.discardDetail,
        component: DiscardRequestDetailContainer,
        exact:true,
        protect:true
    },
    {
        name: 'OKR Home',
        path: ROUTE.home,
        component: OKRHomeContainer,
        exact:true,
        protect:true
    },
    {
        name: 'Key Result Details View',
        path: ROUTE.keyResultDetail,
        component: KeyResultDetailContainer,
        exact:true,
        protect:true
    },
    
    {
        name: 'Milestone Details View',
        path: ROUTE.milestoneDetail,
        component: MilestoneDetailContainer,
        exact:true,
        protect: true
    },
    {
        name: 'Request Details View',
        path: ROUTE.requestDetail,
        component: RequestDetailContainer,
        exact:true,
        protect:true
    },
    {
        name: 'Request Details View',
        path: ROUTE.okrDashboardTeam,
        component: TeamsDashboardComponent,
        exact:true,
        protect: true
    }

]



