import React from 'react';

import { AdminLayout } from '../../../../look';
import ROUTES from '../../../route';
import FaqFormComponent from './FaqFormComponent';

const EditFaqView = props => {
  return (
    <AdminLayout
      table={false}
      active={ROUTES.faq}
      title="Edit FAQ"
      backLink={`${ROUTES.faq}`}
      FormComponent={props.faqCatagoryById && <FaqFormComponent {...props} />}
    />
  );
};

export default EditFaqView;
