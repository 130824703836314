import NotFound from "../../page/containers/NotFound"
import React from "react"

export const ThemeProvider = (props) => {
 const {config, ui, loading } = props
    
    const themeSwitcher = () => {
        if (!loading){
            // component does exist
            if (typeof config.themeConfig[ui] !== "undefined") {
                return React.createElement(config.themeConfig[ui], props);
            }
            // component doesn't exist yet
            return React.createElement(NotFound,{});
        }
    }

return (
    <>
        {themeSwitcher()}
    </>
)
}