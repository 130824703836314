import CompetencyAssessmentHomeContainer from './containers/ca-home-container'
import CompetencyAssessmentCreateContainer from './containers/ca-create-container'
import ROUTE from './route'
import UserCompetecyAssessmentDetailContainer from './containers/ca-user-competecny-assessment-detail'
import CompetencyAssessmentEditContainer from './containers/ca-edit-container'
import CompetencyAssessmentDetailContainer from './containers/ca-competency-assessment-detail'
import UserEmailCompetecyAssessmentDetailContainer from './containers/ca-email-competency-assessment-detail'
export default [
    {
        name: 'CompetencyAssessmentHome',
        path: ROUTE.competency_assessment_home,
        component: CompetencyAssessmentHomeContainer,
        exact: true,
        protect: true
    },
    {
        name: 'CompetencyAssessmentCreate',
        path: ROUTE.competency_assessment_create,
        component: CompetencyAssessmentCreateContainer,
        exact: true,
        protect: true
    },
    {
        name: 'UserCompetencyAssessmentDetail',
        path: ROUTE.user_competency_assessment_detail,
        component: UserCompetecyAssessmentDetailContainer,
        exact: true,
        protect: true
    },
    {
      
        name: 'CompetencyAssessmentDetail',
        path: ROUTE.competecny_assessment_detail,
        component:CompetencyAssessmentDetailContainer,
        exact: true,
        protect: true
    },
    {
        name: 'CompetencyAssessmentEdit',
        path: ROUTE.competency_assessment_edit,
        component: CompetencyAssessmentEditContainer,
        exact: true,
        protect: true
    },
    {
        name: 'UserCompetencyAssessmentDetail',
        path: ROUTE.user_email_competency_assessment_detail,
        component: UserEmailCompetecyAssessmentDetailContainer,
        exact: true,
        protect: true
    },
]

