import React, { useState, useEffect } from 'react';
import { Row, Col, Spin, Divider, Button, Space } from 'antd';

import { getIntFromString, MetaTags, ReactMarkdown } from '../../look';
import AddToolToRecent from './AddToolToRecent';
import { RenderFormSection } from './generic-tool';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { DIVERSITY_SURVEY_TOOL_ID, WHOPOSHORO_SURVEY_TOOL_ID, WHOLE_SURVEY_TOOL_ID } from '../../../config'
import styled from "styled-components";
import survey_module_route from 'modules/survey-module/routes';

const GenericToolView = props => {
  const { loading, getToolById, admin = false, refetch, history, match, newDesign, selectedheight, noPading, minHeight, survey_360_detail } = props;
  const [items, setItems] = useState([]);
  const [widthOfContainer, setWidthOfContainer] = useState(undefined)
  const [containerBorder, setContainerBorder] = useState(undefined)

  useEffect(() => {
    if (getToolById)
      setItems([
        ...(getToolById?.textsection?.edges?.map(({ node }) => ({ ...node, type: 'textsection' })) || []),
        ...(getToolById?.formsection?.edges?.map(({ node }) => ({ ...node, type: 'formsection' })) || [])
      ]);
  }, [getToolById]);

  useEffect(() => {
    setWidthOfContainer(props.widthOfContainer || 20)
    setContainerBorder(props.containerBorder == undefined ? '1px solid #EBEBEB' : props.containerBorder)
  }, [props]);


  const RenderTextSection = ({ textSection, idx }) => {
    return (
      <Row gutter={24}>
        {
          (idx == 1 && survey_360_detail?.id && survey_360_detail?.response_id) && (
            <Col span={24} align="center" style={{ position: "absolute", paddingLeft: "37px", top: "-62px" }}>

              <Button className='green-btn'
                style={{
                  fontSize: "12px",
                  fontWeight: 700,
                  lineHeight: "22px",
                  letterSpacing: "0.04em",
                  textAlign: "left",
                  textTransform: "uppercase",
                  borderRadius: "7px",
                }}
                onClick={() => history.push(
                  survey_module_route.survey_360?.replace(":surveyid", getIntFromString(survey_360_detail?.id))?.replace(":responseid", getIntFromString(survey_360_detail?.response_id))
                )}>Invite for 360 Survey</Button>
            </Col>
          )
        }

        <Col span={24}>
          {/* Dont't show divider in back to back textsections */}
          {props?.hideTopDivider && idx === 0 ? '' :
            <div>{items[idx - 1]?.type !== 'textsection' && <Divider />}</div>
          }
          {/* <ReactMarkdown>{textSection.text}</ReactMarkdown> */}
          <ReactMarkdown>{textSection.renderedText?.replace(/<email\s+style=["']display\s*:\s*none["']>.*?<\/email>/g, '') || '<p></p>'}</ReactMarkdown>

          {/* Dont't show divider in back to back textsections */}
          {props?.hideTopDivider ? '' :
            <div>{items[idx + 1]?.type !== 'textsection' && <Divider />}</div>}
        </Col>
      </Row>
    );
  };

  return (
    <Row justify="center">
      <AddToolToRecent />
      {
        newDesign ?
          <div style={{
            paddingRight: "15px",
            border: containerBorder,
            paddingBottom: 80,
            background: "rgba(255, 255, 255, 0.24)",
            boxShadow: " 0px 4px 250px rgba(0, 0, 0, 0.08)",
            borderTopLeftRadius: "71px",
            borderBottomLeftRadius: "71px",
          }}>
            <CustomTool
              span={widthOfContainer}
              style={{
                width: "100%",
                height: selectedheight,
                overflow: 'auto',
                paddingTop: "20px",
                borderTopLeftRadius: "71px",
              }}
            >
              {match?.params?.id == DIVERSITY_SURVEY_TOOL_ID || match?.params?.id == WHOPOSHORO_SURVEY_TOOL_ID || match?.params?.id == WHOLE_SURVEY_TOOL_ID ?
                <Col xs={24} sm={24} md={12} lg={24} xl={24} style={{ textAlign: 'end' }}>
                  <Space size='small'>
                    <Button
                      shape="circle"
                      onClick={() => history.goBack()}
                      style={{ marginRight: "2em" }}
                    >
                      <ArrowLeftOutlined />
                    </Button>
                  </Space>
                </Col> : null}
              <Spin spinning={loading} size="large">
                <div style={{ minHeight: '70vh' }}>
                  {!loading && (
                    <Row style={{ marginTop: '10px' }} justify="center">
                      {items
                        .sort((a, b) => (a.sequence < b.sequence ? -1 : a.sequence > b.sequence ? 1 : 0))
                        .map((section, idx) =>
                          section.type === 'textsection' ? (
                            <Col span={20} align="left">
                              <RenderTextSection textSection={section} idx={idx} />
                            </Col>
                          ) : (
                            <Col span={24} style={{ backgroundColor: (2 * idx + 1) % 2 !== 0 && '#8080801c', paddingTop: '10px' }}>
                              <Row justify="center">
                                <Col span={20} align="left">
                                  <RenderFormSection
                                    admin={admin}
                                    formSectionId={getIntFromString(section.id)}
                                    formSection={section}
                                    refetch={refetch}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          )
                        )}
                    </Row>
                  )}
                </div>
              </Spin>
            </CustomTool>
          </div> :
          <Col
            span={widthOfContainer}
            style={{
              border: containerBorder,
              borderRadius: 10,
              padding: props?.spacing ? props?.spacing : '50px 0px',
              paddingBottom: noPading ? 0 : 80,
              backgroundColor: '#fff'
            }}
          >
            {match?.params?.id == DIVERSITY_SURVEY_TOOL_ID || match?.params?.id == WHOPOSHORO_SURVEY_TOOL_ID || match?.params?.id == WHOLE_SURVEY_TOOL_ID ?
              <Col xs={24} sm={24} md={12} lg={24} xl={24} style={{ textAlign: 'end' }}>
                <Space size='small'>
                  <Button
                    shape="circle"
                    onClick={() => history.goBack()}
                    style={{ marginRight: "2em" }}
                  >
                    <ArrowLeftOutlined />
                  </Button>
                </Space>
              </Col> : null}
            <Spin spinning={loading} size="large">
              <div style={{ minHeight: minHeight ? minHeight : '70vh' }}>
                {!loading && (
                  <>
                    <Row style={{ marginTop: '10px' }} justify="center">
                      {items
                        .sort((a, b) => (a.sequence < b.sequence ? -1 : a.sequence > b.sequence ? 1 : 0))
                        .map((section, idx) =>
                          section.type === 'textsection' ? (
                            <Col span={20} align="left">
                              <RenderTextSection textSection={section} idx={idx} />
                            </Col>
                          ) : (
                            <Col span={24} style={{ backgroundColor: (2 * idx + 1) % 2 !== 0 && '#8080801c', paddingTop: '10px' }}>
                              <Row justify="center">
                                <Col span={20} align="left">
                                  <RenderFormSection
                                    admin={admin}
                                    formSectionId={getIntFromString(section.id)}
                                    formSection={section}
                                    refetch={refetch}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          )
                        )}
                    </Row>
                  </>
                )}
              </div>
            </Spin>


          </Col>
      }
    </Row>
  );
};

export default GenericToolView;
const CustomTool = styled(Col)`
::-webkit-scrollbar {
  height: 0px;
  width: 5px;
  padding-right:10px
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  border-radius: 10px;
  padding-right: 10px;
  margin:10px 5px;
  margin-right:5px
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color:#D6D6D6;
}`