import React from 'react';
import { Spin } from 'antd';

import { compose } from '../../core';
import { ButtonsCatch, getIntFromString, withMarkdownFieldValue } from '../../look';

import EditGroupView from '../components/EditGroupView';
import ROUTE from '../route';
import { CKEditorFieldName1, CKEditorFieldName2 } from '../components/GroupFormComponent';

import { withGroupById, withEditGroup } from './SurveyOperations';

const EditGroup = props => {
  const { history, editGroup, groupById } = props;
  const [submitLoading, setSubmitLoading] = React.useState(false);

  const handleSubmit = async (values, item) => {
    setSubmitLoading(true);
    var data = withMarkdownFieldValue(
      CKEditorFieldName1,
      withMarkdownFieldValue(CKEditorFieldName2, {
        id: Number(getIntFromString(groupById.id)),
        surveyId: Number(getIntFromString(groupById.survey.id)),
        ...values
      })
    );
    try {
      const response = await editGroup(data);

      response &&
        ButtonsCatch(
          item,
          `${ROUTE.surveyDetailLink}${getIntFromString(response.survey.id)}`,
          history,
          item === undefined ? window.location.pathname : `${ROUTE.addGroupLink}${getIntFromString(response.survey.id)}`
        );
    } catch (e) {
      throw new Error(e.message);
    }
    setSubmitLoading(false);
  };

  return (
    <Spin spinning={submitLoading} size="large">
      <EditGroupView onSubmit={handleSubmit} {...props} />
    </Spin>
  );
};

export default compose(withGroupById, withEditGroup)(EditGroup);
