import React from 'react';

import { compose } from '../../core';
import { withDeleteTextSection } from '../../text-section/containers/TextSectionOperations';
import { withDeleteFormSection } from '../../form-section/containers/FormSectionOperation';

import ToolDetailView from '../components/ToolDetailView';
import AddToolToRecent from '../components/AddToolToRecent';

import { withToolByIdAdmin, withEditToolAdminPanel, withDeleteCommitmentAdminPanel } from './ToolOperations';
import { subscribeToToolDetail } from './ToolSubscription';

const ToolDetail = props => {
  const { deleteCommitment, subscribeToMore, deleteTextSection, deleteFormSection } = props;
  const [actionLoading, setActionLoading] = React.useState(false);
  const [commitmentSet, setCommitmentSet] = React.useState([]);

  React.useEffect(() => {
    const subscribe = subscribeToToolDetail(subscribeToMore);
    return () => {
      subscribe();
    };
  });

  const handleCommitmentDelete = async id => {
    setActionLoading(true);
    try {
      let response = await deleteCommitment({ id: id });
      setActionLoading(false);
      let updatedCommitmentSet = commitmentSet?.edges?.filter((node)=>node?.node?.id !== response?.id );
     
      setCommitmentSet(updatedCommitmentSet);
    } catch (e) {
      setActionLoading(false);
      throw new Error(e.message);
    }
  };

  const handleTextSectionDelete = async id => {
    setActionLoading(true);
    try {
      await deleteTextSection({id:id});
      setActionLoading(false);
    } catch (err) {
      setActionLoading(false);
      throw new Error(err.message);
    }
  };

  const handleFormSectionDelete = async id => {
    setActionLoading(true);
    try {
      await deleteFormSection({id:id});
      setActionLoading(false);
    } catch (err) {
      setActionLoading(false);
      throw new Error(err.message);
    }
  };

  return (
    <>
      <AddToolToRecent />
      <ToolDetailView
        commitmentSet={commitmentSet}
        setCommitmentSet={setCommitmentSet}
        onCommitmentDelete={handleCommitmentDelete}
        actionLoading={actionLoading}
        setActionLoading={setActionLoading}
        onTextSectionDelete={handleTextSectionDelete}
        onFormSectionDelete={handleFormSectionDelete}
        {...props}
      />
    </>
  );
};

export default compose(
  withToolByIdAdmin,
  withEditToolAdminPanel,
  withDeleteCommitmentAdminPanel,
  withDeleteTextSection,
  withDeleteFormSection
)(ToolDetail);
