import React from "react"
import "../css/soul-report.css"
import { Spin, Tooltip } from "antd"
import SoulIndex from "./soul-report-index"
import { available_personality } from "../../../config"
const UserSoulReportView = (props) => {
    const { data_set, report_loading } = props

    const userPersonalityType = (personality_types) => {
        if (personality_types?.length) {
          let personality = ''
    
          personality_types.forEach(ans => {          
            personality = personality + (ans?.answer === "Intuitive" ? "N" : ans?.answer === "Observant" ? "S" : ans?.answer?.charAt(0))
          })
          personality = personality?.slice(0, 4)
          if (available_personality?.includes(personality)) {
            return personality
          }
        }
      }
      
    return (
        <Spin spinning={report_loading}>
            <div style={{ display: "flex", flexDirection: "column", gap: "1em", width: "100%" }} className="soul-report-root">

                {
                    data_set?.value && (
                        <div className="soul-report-container-view">
                            <h4>My <span style={{ fontWeight: "700" }}>Top 5</span> Values</h4>
                            <div className="soul-report-answer-main-section">
                                {
                                    data_set?.value?.map((i, index) => (
                                        <div className="soul-report-answer value-gradient">
                                            <SoulIndex index={`index_${(index + 1)}`} color={"rgba(234, 157, 39, 1)"}/>
                                            <Tooltip title={i?.answer}>
                                                <h4 className="soul-answer">{i?.answer}</h4>
                                            </Tooltip>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    )
                }

                {
                    data_set?.strength && (
                        <div className="soul-report-container-view">
                            <h4>My <span style={{ fontWeight: "700" }}>Top 5</span> Strengths</h4>
                            <div className="soul-report-answer-main-section">
                                {
                                    data_set?.strength?.map((i,index) => (
                                        <div className="soul-report-answer strength-gradient">
                                            <SoulIndex index={`index_${(index + 1)}`} color={"rgba(220, 62, 65, 1)"}/>
                                            <Tooltip title={i?.answer}>
                                                <h4 className="soul-answer">{i?.answer}</h4>
                                            </Tooltip>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    )
                }

                {
                    data_set?.personality && (
                        <div className="soul-report-container-view">
                            <h4>My <span style={{ fontWeight: "700" }}>Top 5</span> Personalities</h4>
                            <p>{userPersonalityType(data_set?.personality)}-{data_set?.personality[4]?.answer?.charAt(0)}</p>
                            <div className="soul-report-answer-main-section">
                                {
                                    data_set?.personality?.map((i,index) => (
                                        <div className="soul-report-answer personality-gradient">
                                            <SoulIndex index={`index_${(index + 1)}`} color={"rgba(49, 205, 181, 1)"}/>
                                            <Tooltip title={i?.answer}>
                                                <h4 className="soul-answer">{i?.answer}</h4>
                                            </Tooltip>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    )
                }

                {
                    data_set?.knowledge && (
                        <div className="soul-report-container-view">

                            <h4>My <span style={{ fontWeight: "700" }}>Top 5</span> Knowledge & Skills</h4>
                            <div className="soul-report-answer-main-section">
                                {
                                    data_set?.knowledge?.map((i,index) => (
                                        <div className="soul-report-answer knowledge-gradient">
                                           <SoulIndex index={`index_${(index + 1)}`} color={"rgba(23, 46, 88, 1)"}/>
                                            <Tooltip title={i?.answer}>
                                                <h4 className="soul-answer">{i?.answer}</h4>
                                            </Tooltip>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    )
                }

            </div>
            {
                (!report_loading && (!data_set || !Object.keys(data_set)?.length)) ?
                    <div style={{ width: "100%", height: "80vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <h4 style={{ textAlign: "center", fontSize: "1.25em", fontWeight: "500" }}>
                            No Report Found.<br></br>
                            Please Complete Soul Surveys.
                        </h4>
                    </div> : ""
            }
        </Spin>
    )

}

export default UserSoulReportView