import { Button, Dropdown, Modal, Select, Tooltip, message } from "antd";
import React from "react";
import comparison_active from '../../assets/comparison-active-icon.svg'
import readiness_close_icon from '../../assets/readiness-close-icon.svg'
import comparison_icon from "../../assets/comparison-icon.svg"
const ComparisonSlectionView = (props) => {
    const { onClose, visible_selection, employee_list,compare_request } = props;
    const [selected_employee_1,setSelectedEmployee1]=React.useState()
    const [selected_employee_2,setSelectedEmployee2]=React.useState()
    const [dropdownVisible, setDropDownVisible] = React.useState(false)
    React.useEffect (()=>{
        if(selected_employee_1 || selected_employee_2){

        }
    },[selected_employee_1,selected_employee_2])
    const Compare =async()=>{
        if(selected_employee_1 && selected_employee_2){
            let compare_list ={employee_1:selected_employee_1,employee_2:selected_employee_2}
            compare_request(compare_list)
            setSelectedEmployee1(null)
            setSelectedEmployee2(null)
            setDropDownVisible(false)
        }
        else{
            message.error("Please select Employees!!")
        }
    }
    const filterMenu = () => (
        <div className="custom-filter-container" style={{ width: "100%", minWidth: "40em",marginTop:"1em"}}>
             <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'start', alignItems: "start", gap: "1em", padding: ".5em 1em",width:"100%" }}>
                <div style={{ display: 'flex', flexDirection: "row", gap: '1em', justifyContent: "center", alignItems: "center",width:"100%" }}>
                    <Select
                        className="comparison-menu-container"
                        style={{ width: "100%" }}
                        placeholder="Select Employee"
                        showSearch={false}
                        value={selected_employee_1?.id}
                        onChange={(e,option)=>setSelectedEmployee1({id:e,name:option?.key})}>
                        {
                            employee_list?.filter(value=>value?.emp_id!=selected_employee_2?.id)?.map(item => {
                                return <Select.Option className='team-size-options-menu-item' style={{ borderRight: 'none', maxHeight: "300px", overflow: 'auto' }} key={item?.name} value={item?.emp_id} >{item?.name}</Select.Option>
                            }
                            )
                        }
                    </Select>
                    <img src={comparison_active} alt="" style={{ width: "2em", height: "2em" }} />
                    <Select
                        className="comparison-menu-container"
                        style={{ width: "100%" }}
                        placeholder="Select Employee"
                        showSearch={false}
                        value={selected_employee_2?.id}
                        onChange={(e,option)=>setSelectedEmployee2({id:e,name:option?.key})}>
                        {
                            employee_list?.filter(value=>value?.emp_id!=selected_employee_1?.id)?.map(item => {
                                return <Select.Option className='team-size-options-menu-item' style={{ borderRight: 'none', maxHeight: "300px", overflow: 'auto' }} key={item?.name} value={item?.emp_id} >{item?.name}</Select.Option>
                            }
                            )
                        }
                    </Select>
                </div>
                <Button className="soul-report-active-btn" style={{marginTop:"1em"}} onClick={()=>Compare()}>Compare</Button>
            </div>
        </div>
    )
    return (
        // <Modal
        //     visible={visible_selection}
        //     centered={true}
        //     footer={null}
        //     destroyOnClose={true}
        //     closable={false}
        // >
           
        // </Modal>
         <>
         <Dropdown visible={dropdownVisible} overlay={filterMenu} placement={"bottomCenter"}>
         <Tooltip title="Compare Reports" placement="bottom">
            <img src={comparison_icon} alt="comparison_icon" style={{cursor:"pointer",width:"2.5em",height:"2.5em"}}onClick={() => setDropDownVisible(!dropdownVisible)}/>
        </Tooltip>
         </Dropdown>
         {dropdownVisible ? (<div className='overlay-dynamic-island' onClick={() => { setDropDownVisible(false); setSelectedEmployee1(null);setSelectedEmployee2(null) }} />) : null}
     </>
    )
}

export default ComparisonSlectionView;