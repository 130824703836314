import React from 'react';
import { Form, Checkbox } from 'antd';

const FormItem = Form.Item;

const CheckBoxField = props => {
  const { label, defaultChecked, margin = true, ...rest } = props;

  return (
    <div style={{ marginBottom: margin && '5vh' }}>
      <FormItem
        valuePropName="checked"
        style={{ marginBottom: !margin && 0 }}
        // /* label={label} */ labelCol={{ span: 5 }}
        wrapperCol={{ span: 22, offset: 2 }}
        {...rest}
      >
        <Checkbox defaultChecked={defaultChecked}>{label}</Checkbox>
      </FormItem>
    </div>
  );
};

export default CheckBoxField;
