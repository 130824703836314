import React from 'react';
import _ from 'lodash';
import { Spin } from 'antd';
import { compose } from '../../core';
import { useHistory } from 'react-router-dom';
import AddVerticalView from '../components/AddVerticalView';
import ROUTE from '../route';
import { getIntFromString, ButtonsCatch, withMarkdownFieldValue } from '../../look';

import { /* withVerticals, */ withAddVertical, withOrganizationState } from './OrganizationOperation';
import { CKEditorFieldName } from '../components/VerticalFormComponent';

const AddVertical = props => {
  const { createVertical, setVisible, orgId, verticalResponse } = props;
  const [ submitLoading, setSubmitLoading ] = React.useState( false );
  const history = useHistory();

  const handleSubmit = async ( values, item ) => {
    setSubmitLoading( true );
    const heads = _.get( values, 'heads' );
    const input = _.omit( values, [ 'heads', 'employees' ] );
    // const employees = (({ employees }) => ({ employees }))(values);

    try {
      const response = await createVertical( {
        employees: values.employees,
        heads,
        verticalData: { ...withMarkdownFieldValue( CKEditorFieldName, { ...input, organizationId: orgId } ) }
      } );
      if(response){
        setVisible( false );
          window.location.href.includes( 'closeonsave' ) && window.close();
      
        ButtonsCatch(
          item,
          window.location.pathname,
          history,
          item === undefined
            ? `${ROUTE.editVerticalLink}${getIntFromString( response.id )}`
            : `${window.location.pathname}?args=openmodal`
        );
      }
      verticalResponse(response);
      setSubmitLoading( false );
    } catch ( e ) {
      setSubmitLoading( false );
      throw new Error( e.message );
    }
  };

  return (
    <Spin spinning={submitLoading} size="large">
      <AddVerticalView onSubmit={handleSubmit} {...props} />
    </Spin>
  );
};

export default compose( withOrganizationState, /* withVerticals, */ withAddVertical )( AddVertical );
