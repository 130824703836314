import { message } from "antd";
import {
  CREATE_TIP_SETTINGS,
  UPDATE_TIP_SETTINGS,
} from "../graphql/profile.gql";
import { graphql } from "@apollo/client/react/hoc";

export const withCreateSettingsTip = (Component) =>
  graphql(CREATE_TIP_SETTINGS, {
    props: ({ mutate, history }) => ({
      createTipsettings: async (values) => {
        try {
          const {
            data: { createTipsettings },
          } = await mutate({
            variables: {
              ...values,
            },
          });

          message.destroy();
          return createTipsettings.tipsettings;
        } catch (e) {
          message.destroy();
          console.log("eeee", e);
          message.error("Couldn't perform the action");
          console.error(e);
        }
      },
    }),
  })(Component);
export const withUpdateSettingsTip = (Component) =>
  graphql(UPDATE_TIP_SETTINGS, {
    props: ({ mutate, history }) => ({
      updateTipsettings: async (values) => {
        try {
          const {
            data: { updateTipsettings },
          } = await mutate({
            variables: {
              ...values,
            },
          });

          message.destroy();
          return updateTipsettings.tipsettings;
        } catch (e) {
          message.destroy();
          console.log("eeee", e);
          message.error("Couldn't perform the action");
          console.error(e);
        }
      },
    }),
  })(Component);
