import gql from 'graphql-tag';


export const CREATE_CONTACT_REQUEST_MUTATION = gql`
  mutation contactRequest($contactRequestData: ContactRequestInput!) {
    contactRequest(contactRequestData: $contactRequestData) {
        contactRequest {
            id
      }
    }
  }
`;
