import React from "react";
import { Progress, Form } from "antd";

import { getStrokeColor } from "../../../look";

import { withMyVerticalMAppPulseCheck } from "../../containers/DashboardOperations";
import { ListConsumer } from "antd/lib/list";

const FormItem = Form.Item;

const RenderMyVerticalMAppPulseCheck = ({
  loading,
  myVerticalMAppPulseCheck,
}) => (
  <FormItem
    labelCol={{ span: 24 }}
    wrapperCol={{ span: 24, offset: 1 }}
    label={"User’s Vertical’s MApp Pulse Check​"}
  >
    <Progress
      format={(percent) => percent / 10 + "%"}
      percent={
        (myVerticalMAppPulseCheck &&
          JSON.parse(myVerticalMAppPulseCheck)?.overall?.toFixed(2) * 10) ||
        0
      }
      strokeColor={getStrokeColor(
        (myVerticalMAppPulseCheck &&
          JSON.parse(myVerticalMAppPulseCheck)?.overall?.toFixed(2)) ||
          0
      )}
    />
  </FormItem>
);

export default withMyVerticalMAppPulseCheck(RenderMyVerticalMAppPulseCheck);

// modifications on ListConsumer
// 6
// 11(2)
// 12
// 15(2)
// 17(2)
// 23(2)
