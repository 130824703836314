import gql from 'graphql-tag';
import { RESPONSE_TYPE } from './ResponseType.gql';

export const EMAIL_PERCEPTION_REPORT_TO_ADMIN_QUERY = gql`
  ${RESPONSE_TYPE}
  query emailPerceptionReportToAdmin($responseId: ID!, $userId: ID!) {
    emailPerceptionReportToAdmin(responseId: $responseId, userId: $userId) {
      ...ResponseType
    }
  }
`;
