import React from 'react';

import { compose } from '../../core';
import { withMe } from '../../user/containers/UserOperations';
import { withOrganizationState, withOrganizationFilterUpdating } from '../../organization/containers/OrganizationOperation';
import { withGetAllNonEmployeeUsers, withUserState } from '../../user/containers/UserOperations';

import EmployeeesRenderView from '../components/EmployeeesRenderView';

const AllNonEmployeeUsersRender = props => {
  const { getAllNonEmployeeUsers } = props;
  return <EmployeeesRenderView {...props} data={getAllNonEmployeeUsers} />;
};

export default compose(withUserState, withMe, withGetAllNonEmployeeUsers)(AllNonEmployeeUsersRender);
