import gql from 'graphql-tag';


export const CHANGE_ACTIONITEM_STATUS_MUTATION = gql`

  mutation updateActionItem($id: ID!,$completed: Boolean) {
    updateActionItem(id:$id,completed:$completed) {
        actionItem  {
            id
      }
    }
  }
`;
