import React, { useState } from 'react';
import { Form } from 'antd';
import {
  // AlphaPicker,
  // BlockPicker,
  // ChromePicker,
  // CirclePicker,
  // CompactPicker,
  // GithubPicker,
  // HuePicker,
  // MaterialPicker,
  // PhotoshopPicker,
  SketchPicker
  // SliderPicker,
  // SwatchesPicker,
  // TwitterPicker
} from 'react-color';

const FormItem = Form.Item;

const ColorField = props => {
  const { type, onChange, offset = 1, value, disabled, ...rest } = props;
  const [background, setBackground] = useState(props.value);

  React.useEffect(() => {
    if (background !== value) setBackground(value);
  }, [value]);

  return (
    <div style={{ marginBottom: '5vh' }}>
      <FormItem labelCol={{ span: 24 }} wrapperCol={{ span: 24, offset }} required={props?.required} {...rest}>
        <SketchPicker
          color={background}
          onChangeComplete={color => onChange && onChange(color.hex)}
          onChange={setBackground}
          disabled={disabled}
        />
      </FormItem>
    </div>
  );
};

export default ColorField;
