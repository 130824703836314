import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { Space, Col, Row, Empty, Button, Carousel, Progress, Input } from 'antd';
import { CaretRightOutlined, CheckOutlined } from '@ant-design/icons';
import {
    ReactMarkdown,
    RadioField,
    MLCheckBoxField,
    TextField,
    MLSelectField,
    RangeField,
    SelectField,
    displayDataCheck,
    ColorField,
    InputValuesObject,
    DatePickerField,
    RenderUpload,
    interpolate,
    getIntFromString,
    CKEditorField,
    withMarkdownFieldValue
} from '../../look';
import { withApollo } from '@apollo/client/react/hoc';
import ProgressCounterBtn from './ProgressCounterBtn';
import ProgressCounterBtnNative from './ProgressCounterBtnNative';
import { compose } from 'modules/core';
import { FILTER_SURVEY_CHOICE } from '../../diversity-survey/graphql/filterSurveyChoice.gql'
import OrderedInput from '../../look/components/OrderedInput';
import moment from 'moment-timezone';
import CKEditorFieldOneOnOne from 'modules/look/components/form-components/CKEditor-one-on-one';
import SurveyRangeField from 'modules/survey-look/containers/survey-range-filed';
import SurveyTextField from 'modules/survey-look/containers/survey-text-field';
import GrowSelectField from 'modules/survey-look/containers/grow-select-field';
export const TIME_LIMIT = 5;

const emailRules = { type: 'email', message: 'Not a vaild email' };

export function getTypeFromQuestion( question ) {
    return question?.choiceSet?.edges[ 0 ]?.node?.inputType;
}


const SinglePageSectionComponent = props => {
    const {
        questionList,
        matrix,
        sectionLabel,
        form,
        nextStep,
        srNo,
        closetimer,
        currentIsMatrix,
        currentSection,
        showOk,
        setShowOk,
        currentMatrixQuestion,
        survey,
        client,
        autoscroll
    } = props;

    const values = form.getFieldsValue( true );
    const getRangeValues = ( choiceSet, type ) => {
        let defaultValue = 0,
            marks = {},
            step = 1;
        const value = choiceSet[ 0 ]?.value?.split( ':' ) || [];
        // min:max:jump:default
        if ( type === 'RANGE' ) {
            marks[ value[ 0 ] ] = value[ 0 ];
            marks[ value[ 1 ] ] = value[ 1 ];
            step = value[ 2 ] === '0' ? '1' : value[ 2 ];
            defaultValue = value[ 3 ];
        }
        return { step, marks, defaultValue:Number(value[3]), min: Number( value[ 0 ] ), max: Number( value[ 1 ] ) };
    };

    const RenderQuestion = ( question, index ) => {
        const [ colorLoading, setColorLoading ] = React.useState( false );
        const [ load, setLoad ] = useState( false );
        const { id, sequence, questionText, required, helpText } = question;
        const [ choiceSet, setchoiceSet ] = React.useState( question?.choiceSet )
        const type = getTypeFromQuestion( question );
        const [ reset, setReset ] = useState( false );
        const obj = {};
        const keyArray = Object.keys( values );
        keyArray?.length > 0 &&
            keyArray.map( key => {
                try {
                    obj[ getIntFromString( key ) ] = values[ key ];
                } catch {
                    //skipping non base64 ids
                }

            } );

        const onSelectFeildSearch = async ( search, que ) => {
            let id = survey?.id;
            const { data } = await client.query( {
                query: FILTER_SURVEY_CHOICE,
                variables: { id: id, label_Icontains: search },
                // fetchPolicy: 'network-only'
            } );
            if ( data?.Options ) {

                let qa = data?.Options.groupSet?.edges.map( item => item?.node?.questionSet?.edges )
                qa = [].concat.apply( [], qa )
                let current = qa.find( item => item?.node?.id === que?.id )
                if ( current ) {
                    setchoiceSet( current?.node?.choiceSet )
                }
            }
        }

        const label = (
            <Space align="baseline" >
                {/* <SequenceNo>{srNo}</SequenceNo> */}
                <h4 className='coaching-engagement-form-lable'><ReactMarkdown style={{ marginLeft: '4px' }}>{interpolate( questionText?.replace( '{', '${' ), obj )}</ReactMarkdown></h4>
            </Space>
        );

        const renderLabel = (
            <div>
                {label}
                <ReactMarkdown style={{ marginLeft: '3vw', marginRight: '3vw' }}>{helpText}</ReactMarkdown>
            </div>
        );

        const requiredRule = [ { required: true, message: 'An answer is required.' } ];

        const { step, marks, defaultValue, min, max } = getRangeValues(
            choiceSet?.edges?.map( ( { node } ) => node ),
            type
        );
        const handleChange = doNext => {
            if ( doNext ) {
                if ( showOk ) {
                    setShowOk( false );
                    setShowOk( true );
                    setReset( true );
                    closetimer();
                } else {
                    setShowOk( true );
                    // timeoutFunc(srNo);
                }
            } else {
                setShowOk( true );
            }
        };

        return true ? (
            <div key={index} visible={true}>
                <div style={{ display: 'flex', flexDirection: "column", gap: '1em' }}>
                    {( question?.numberOfAnswers === undefined || question?.numberOfAnswers === 1 ) && (
                        <span>
                            {type === InputValuesObject.checkbox && (
                                <div style={{ marginTop: "1em", display: 'flex', flexDirection: 'column', gap: '.5em', width: "100%" }}>
                                    <div className='coaching-engagement-form-lable' style={{ display: 'flex', flexDirection: 'row', justifyContent: "start", alignItems: "center" }}><span className="rd-input-star-grow">*</span><span>{label}</span></div>
                                    <MLCheckBoxField
                                        style={{ marginTop: "1em" }}
                                        name={id}
                                        id={id}
                                        rules={requiredRule}
                                        choices={choiceSet.edges.map( ( { node } ) => node ).map( c => ( { label: c.label, value: c.value } ) )}
                                        onChange={e => { e && handleChange( false ); autoscroll( id ) }}
                                        required={required}
                                    />
                                </div>
                            )}
                            {type === InputValuesObject.color && !colorLoading && (
                                <div style={{ marginTop: "1em", display: 'flex', flexDirection: 'column', gap: '.5em', width: "100%" }}>
                                    <div className='coaching-engagement-form-lable' style={{ display: 'flex', flexDirection: 'row', justifyContent: "start", alignItems: "center" }}><span className="rd-input-star-grow">*</span><span>{label}</span></div>
                                    <ColorField
                                        style={{ marginTop: "1em" }}
                                        name={id}
                                        id={id}
                                        rules={requiredRule}
                                        choices={choiceSet.edges.map( ( { node } ) => node ).map( c => ( { label: c.label, value: c.value } ) )}
                                        onChange={e => {
                                            setColorLoading( true );
                                            e && form.setFieldsValue( _.set( form.getFieldsValue( true ), [ id ], e ) ) && setShowOk( true );
                                            setColorLoading( false ); autoscroll( id )
                                        }}
                                        value={form.getFieldValue( id )}
                                        required={required}
                                    />
                                </div>
                            )}
                            {type === InputValuesObject.date && (
                                <div style={{ marginTop: "1em", display: 'flex', flexDirection: 'column', gap: '.5em', width: "100%" }}>
                                    <div className='coaching-engagement-form-lable' style={{ display: 'flex', flexDirection: 'row', justifyContent: "start", alignItems: "center" }}><span className="rd-input-star-grow">*</span><span>{label}</span></div>
                                    <DatePickerField
                                        style={{ marginTop: "1em" }}
                                        margin={false}
                                        name={id}
                                        id={id}
                                        offset={3}
                                        format="YYYY-MM-DD"
                                        rules={[ { required, message: 'Please select a date' } ]}
                                        enabledisabledDate={false}
                                        onChange={e => { e && handleChange( true ); autoscroll( id ) }}
                                        disabledDate={( current ) => current.isBefore( moment().subtract( 1, "day" ) )}
                                        required={required}
                                    />
                                </div>
                            )}
                            {type === InputValuesObject.datetime && (
                                <div style={{ marginTop: "1em", display: 'flex', flexDirection: 'column', gap: '.5em', width: "100%" }}>
                                    <div className='coaching-engagement-form-lable' style={{ display: 'flex', flexDirection: 'row', justifyContent: "start", alignItems: "center" }}><span className="rd-input-star-grow">*</span><span>{label}</span></div>
                                    <DatePickerField
                                        style={{ marginTop: "1em" }}
                                        margin={false}
                                        name={id}
                                        id={id}
                                        offset={3}
                                        showTime={{ format: 'HH:mm' }}
                                        format="YYYY-MM-DD HH:mm"
                                        rules={[ { required, message: 'Please select a date and time' } ]}
                                        enabledisabledDate={false}
                                        onChange={e => { e && handleChange( true ); autoscroll( id ) }}
                                        disabledDate={( current ) => current.isBefore( moment().subtract( 1, "day" ) )}
                                        margin={"0px"}
                                        required={required}
                                    // defaultValue={moment('2015-06-06', 'YYY-MM-DD')}
                                    />
                                </div>
                            )}
                            {type === InputValuesObject.month && (
                                <div style={{ marginTop: "1em", display: 'flex', flexDirection: 'column', gap: '.5em', width: "100%" }}>
                                    <div className='coaching-engagement-form-lable' style={{ display: 'flex', flexDirection: 'row', justifyContent: "start", alignItems: "center" }}><span className="rd-input-star-grow">*</span><span>{label}</span></div>
                                    <DatePickerField
                                        style={{ marginTop: "1em" }}
                                        margin={false}
                                        name={id}
                                        id={id}
                                        offset={3}
                                        rules={[ { required, message: 'Please select a month' } ]}
                                        enabledisabledDate={true}
                                        picker="month"
                                        onChange={e => { e && handleChange( true ); autoscroll( id ) }}
                                        disabledDate={( current ) => current.isBefore( moment().subtract( 1, "day" ) )}
                                        margin={"0px"}
                                        required={required}
                                    // defaultValue={moment('2015-06-06', 'YYY-MM-DD')}
                                    />
                                </div>
                            )}
                            {type === InputValuesObject.time && (
                                <div style={{ marginTop: "1em", display: 'flex', flexDirection: 'column', gap: '.5em', width: "100%" }}>
                                    <div className='coaching-engagement-form-lable' style={{ display: 'flex', flexDirection: 'row', justifyContent: "start", alignItems: "center" }}><span className="rd-input-star-grow">*</span><span>{label}</span></div>
                                    <DatePickerField
                                        style={{ marginTop: "1em" }}
                                        margin={false}
                                        name={id}
                                        id={id}
                                        offset={3}
                                        rules={[ { required, message: 'Please select a month' } ]}
                                        enabledisabledDate={true}
                                        type="time"
                                        onChange={e => { e && handleChange( true ); autoscroll( id ) }}
                                        disabledDate={( current ) => current.isBefore( moment().subtract( 1, "day" ) )}
                                        margin={"0px"}
                                        required={required}
                                    // defaultValue={moment('2015-06-06', 'YYY-MM-DD')}
                                    />
                                </div>
                            )}
                            {type === InputValuesObject.week && (
                                <div style={{ marginTop: "1em", display: 'flex', flexDirection: 'column', gap: '.5em', width: "100%" }}>
                                    <div className='coaching-engagement-form-lable' style={{ display: 'flex', flexDirection: 'row', justifyContent: "start", alignItems: "center" }}><span className="rd-input-star-grow">*</span><span>{label}</span></div>
                                    <DatePickerField
                                        style={{ marginTop: "1em" }}
                                        margin={false}
                                        name={id}
                                        id={id}
                                        offset={3}
                                        rules={[ { required, message: 'Please select a month' } ]}
                                        enabledisabledDate={true}
                                        picker="week"
                                        onChange={e => { e && handleChange( true ); autoscroll( id ) }}
                                        disabledDate={( current ) => current.isBefore( moment().subtract( 1, "day" ) )}
                                        margin={"0px"}
                                        required={required}
                                    // defaultValue={moment('2015-06-06', 'YYY-MM-DD')}
                                    />
                                </div>
                            )}
                            {type === InputValuesObject.radio && (
                                <div style={{ marginTop: "1em", display: 'flex', flexDirection: 'column', gap: '.5em', width: "100%" }}>
                                    <div className='coaching-engagement-form-lable' style={{ display: 'flex', flexDirection: 'row', justifyContent: "start", alignItems: "center" }}><span className="rd-input-star-grow">*</span><span>{renderLabel}</span></div>
                                    <RadioField
                                        style={{ marginTop: "1em" }}
                                        name={id}
                                        id={id}
                                        selectedId={id}
                                        choices={choiceSet.edges.map( ( { node } ) => node ).map( c => ( { label: c.label, value: c.value } ) )}
                                        rules={requiredRule}
                                        onChange={e => { e?.target?.value && handleChange( true ); autoscroll( id ) }}
                                        autoscroll={e => autoscroll( e )}
                                        margin={"0px"}
                                        required={required}
                                    />
                                </div>
                            )}
                            {type === InputValuesObject.range && (
                                <div style={{ marginTop: "1em", display: 'flex', flexDirection: 'column', gap: '.5em', width: "100%" }}>
                                    <div className='coaching-engagement-form-lable' style={{ display: 'flex', flexDirection: 'row', justifyContent: "start", alignItems: "center" }}><span className="rd-input-star-grow">*</span><span>{renderLabel}</span></div>
                                    <SurveyRangeField
                                        style={{ marginTop: "1em" }}
                                        name={id}
                                        id={id}
                                        selectedId={id}
                                        tip_hidden={true}
                                        rules={requiredRule}
                                        marks={marks}
                                        step={step}
                                        defaultValue={defaultValue}
                                        min={defaultValue?defaultValue:min}
                                        max={max}
                                        trackStyle={{ backgroundColor: '#B0CB1F' }}
                                        handleStyle={{ backgroundColor: '#B0CB1F' }}
                                        onAfterChange={e => { e && handleChange( true ); autoscroll( id ) }}
                                        autoscroll={e => autoscroll( e )}
                                        margin={"0px"}
                                        required={required}
                                    />
                                </div>
                            )}
                            {type === InputValuesObject.email && (
                                <div style={{ marginTop: "1em", display: 'flex', flexDirection: 'column', gap: '.5em', width: "100%" }}>
                                    <div className='coaching-engagement-form-lable' style={{ display: 'flex', flexDirection: 'row', justifyContent: "start", alignItems: "center" }}><span className="rd-input-star-grow">*</span><span>{renderLabel}</span></div>
                                    <SurveyTextField
                                        style={{ marginTop: "1em" }}
                                        id={id}
                                        onClick={autoscroll( id )}
                                        name={id}
                                        className={'grow-survey-input-filed'}
                                        rules={required ? requiredRule.map( r => ( { ...r, ...emailRules } ) ) : [ { ...emailRules } ]}
                                        // rules={[{ type: 'email' }]}
                                        offset={2}
                                        // onChange={e => e.target.value && handleChange(false)}
                                        onBlur={e => e?.target?.value && handleChange( false )}
                                        margin={"0px"}
                                        required={required}
                                    />
                                </div>
                            )}
                            {type === InputValuesObject.file && (
                                <div style={{ marginTop: "1em", display: 'flex', flexDirection: 'column', gap: '.5em', width: "100%" }}>
                                    <div className='coaching-engagement-form-lable' style={{ display: 'flex', flexDirection: 'row', justifyContent: "start", alignItems: "center" }}><span className="rd-input-star-grow">*</span><span>{renderLabel}</span></div>
                                    <RenderUpload
                                        style={{ marginTop: "1em" }}
                                        setUrl={e => form.setFieldsValue( _.set( form.getFieldsValue( true ), id, e ) )}
                                        setLoad={setLoad}
                                        offset={2}
                                        name={id}
                                        margin={"0px"}
                                        rules={requiredRule}
                                    />
                                </div>
                            )}
                            <Row>
                                <Col lg={24} xs={20}>
                                    {type === InputValuesObject.text && (
                                        <div style={{ marginTop: "1em", display: 'flex', flexDirection: 'column', gap: '.5em', width: "100%" }}>
                                            <div className='coaching-engagement-form-lable' style={{ display: 'flex', flexDirection: 'row', justifyContent: "start", alignItems: "center" }}><span className="rd-input-star-grow">*</span><span>{renderLabel}</span></div>
                                            <SurveyTextField
                                                style={{ marginTop: "1em" }}
                                                id={id}
                                                onClick={autoscroll( id )}
                                                className={'grow-survey-input-filed'}
                                                name={id}
                                                // label={renderLabel}
                                                rules={requiredRule}
                                                offset={2}
                                                onBlur={e => e?.target?.value && handleChange( false )}
                                                margin={"0px"}
                                                required={required}
                                            />
                                        </div>
                                    )}
                                </Col>
                            </Row>
                            {type === InputValuesObject.number && (
                                <div style={{ marginTop: "1em", display: 'flex', flexDirection: 'column', gap: '.5em', width: "100%" }}>
                                    <div className='coaching-engagement-form-lable' style={{ display: 'flex', flexDirection: 'row', justifyContent: "start", alignItems: "center" }}><span className="rd-input-star-grow">*</span><span>{renderLabel}</span></div>
                                    <SurveyTextField
                                        style={{ marginTop: "1em" }}
                                        name={id}
                                        id={id}
                                        className={'grow-survey-input-filed'}
                                        onClick={autoscroll( id )}
                                        rules={requiredRule}
                                        type="number"
                                        offset={2}
                                        // onChange={e => e && handleChange(false)}
                                        onBlur={e => e?.target?.value && handleChange( false )}
                                        margin={"0px"}
                                        required={required}
                                    />
                                </div>
                            )}
                            {type === InputValuesObject.select && (
                                <div>
                                    {( question?.numberOfAnswers === undefined || question?.numberOfAnswers === 1 ) && (
                                        <div style={{ marginTop: "1em", display: 'flex', flexDirection: 'column', gap: '.5em', width: "100%" }}>
                                            <div className='coaching-engagement-form-lable' style={{ display: 'flex', flexDirection: 'row', justifyContent: "start", alignItems: "center",gap:"0px" }}><span className="rd-input-star-grow" style={{paddingTop:"-1px !important"}}>*</span><span>{renderLabel}</span></div>
                                            <GrowSelectField
                                                showSearch
                                                onSearch={( e ) => { onSelectFeildSearch( e, question ) }}
                                                otherOption={true}
                                                name={id}
                                                id={id}
                                                onClick={autoscroll( id )}
                                                offset={2}
                                                required={required}
                                                rules={requiredRule}
                                                choices={choiceSet.edges.map( ( { node } ) => node ).map( c => ( { label: c.label, value: c.value } ) )}
                                                onSelectChange={e => e && handleChange( false )}
                                                onBlur={e => e?.target?.value && handleChange( false )}
                                                margin={"0px"}
                                            />
                                        </div>
                                    )}
                                </div>
                            )}
                            {type === InputValuesObject.ckeditor && (
                                <div style={{ marginTop: "1em", display: 'flex', flexDirection: 'column', gap: '.5em', width: "100%" }}>
                                    <div className='coaching-engagement-form-lable' style={{ display: 'flex', flexDirection: 'row', justifyContent: "start", alignItems: "center" }}><span className="rd-input-star-grow">*</span><span>{renderLabel}</span></div>
                                    <div style={{ backgroundColor: 'white', paddingTop: '2.5px' }} className='feedback-pop-up-ck-editor normal-border'>
                                        <CKEditorFieldOneOnOne
                                            required={required}
                                            // label={renderLabel}
                                            rules={requiredRule}
                                            name={id}
                                            id={id}
                                            changeHandler={e => { handleChange( true ); form.setFieldsValue( _.set( form.getFieldsValue( true ), id, withMarkdownFieldValue( id, { [ id ]: e } )[ id ] ) ); autoscroll( id ) }}
                                        />
                                    </div>
                                </div>
                            )}
                        </span>
                    )}
                    {question?.numberOfAnswers && question?.numberOfAnswers > 1 && (
                        <div style={{ marginTop: "1em", display: "flex", flexDirection: "column", gap: ".5em", width: "100%" }}>
                            <div className='coaching-engagement-form-lable' style={{ display: 'flex', flexDirection: 'row', justifyContent: "start", alignItems: "center" }}><span className="rd-input-star-grow">*</span><span>{renderLabel}</span></div>
                            <OrderedInput
                                form={form}
                                type={type}
                                initCount={1}
                                maxCount={question?.numberOfAnswers}
                                minCount={1}
                                showSearch
                                onSearch={( e ) => { onSelectFeildSearch( e, question ) }}
                                otherOption={true}
                                name={id}
                                offset={2}
                                rules={requiredRule}
                                choices={choiceSet.edges.map( ( { node } ) => node ).map( c => ( { label: c.label, value: c.value } ) )}
                                onSelectChange={e => e && handleChange( false )}
                                onBlur={e => e?.target?.value && handleChange( false )}
                                autoscroll={autoscroll}
                                margin={"0px"}
                                required={required}
                            />
                        </div>
                    )}
                </div>
            </div>
        ) : null;
    };

    return matrix && matrix ? (
        <MatrixDiv>
            <Space align="baseline">
                <SequenceNo>{srNo}</SequenceNo>
                <h3 style={{ fontWeight: 'bold' }}>{displayDataCheck( sectionLabel )}</h3>
            </Space>
            <Row style={{ paddingBottom: '2vh', paddingTop: '2vh' }}>
                <Col lg={8} md={8} xs={0}></Col>
                <Col lg={16} md={16} xs={24}>
                    <Row>
                        {questionList[ 1 ]?.choiceSet?.edges?.map( ( { node: choice }, key ) => (
                            <Col span={6} align="center">
                                {choice?.label}
                            </Col>
                        ) )}
                    </Row>
                </Col>
            </Row>
            <Row style={{ backgroundColor: 'rgba(202, 202, 202, 0.05)', marginBottom: '2vh' }}>
                {currentIsMatrix && questionList?.length > 0 ? (
                    questionList
                        ?.sort( function ( a, b ) {
                            return parseInt( a.id ) - parseInt( b.id );
                        } )
                        .map( ( question, index ) => {
                            const { id, questionText, choiceSet, required } = question;
                            const rules = [ { required: true, message: 'An answer is required.' } ];
                            return (
                                <Col lg={{ span: 23, offset: 1 }} md={{ span: 23, offset: 1 }} xs={{ span: 24, offset: 0 }}>
                                    <MLSelectField
                                        otherOption={true}
                                        name={id}
                                        label={
                                            <Space>
                                                {currentMatrixQuestion === index ? (
                                                    <CaretRightOutlined />
                                                ) : (
                                                    <CaretRightOutlined style={{ opacity: 0 }} />
                                                )}
                                                {questionText}
                                            </Space>
                                        }
                                        choices={choiceSet?.edges?.map( ( { node } ) => node ).map( c => ( { label: c.label, value: c.value } ) )}
                                        rules={required ? rules : []}
                                    />
                                </Col>
                            );
                        } )
                ) : (
                    <br />
                )}
            </Row>
        </MatrixDiv>
    ) : questionList?.length > 0 ? (
        questionList?.map( RenderQuestion )
    ) : (
        <Empty />
    );
};

export default compose( withApollo )( SinglePageSectionComponent );

const SequenceNo = styled.div`
  border-radius: 50%;
  width: 28px;
  height: 28px;
  justify-content: center;
  align-items: center;
  background: rgba(98, 98, 98, 1);
  color: white;
  display: inline-flex;
  margin-top:10px
`;

const MatrixDiv = styled.div`
  height: 75vh; /* ${props => ( props.currentIsMatrix ? '75vh' : '60vh' )}; */
  padding: 3vh 0;
  overflow-y: auto;
`;

const Layout = styled.div`
  padding: 1vh 0;
`;

const ChildLayout = styled.div`
min-height: 10vh;
align-items: center;
display: grid;
`;
