import React from 'react';
import { withApollo } from '@apollo/client/react/hoc';
import styled from 'styled-components';
import { Spin, Modal, Divider, Button, Form, Row, Col, Input, message } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';

import { compose } from '../../core';
import { MetaTags } from '../../look';
import { RESEND_ACTIVATION_EMAIL } from '../graphql/ResendActivationEmail.gql';

import { withResendActivationEmail } from './UserOperations';
import { Link } from 'react-router-dom';

const SendEmailVerify = props => {
  const { client, visible, setVisible, resendActivationEmail, email, username } = props;
  const [forgotPass, setForgotPass] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const validateMessages = {
    required: '${label} is required!',
    types: { email: '${label} is not a valid email!' }
  };
  const layout = {
    labelCol: {
      span: 24
    },
    wrapperCol: {
      span: 24
    }
  };

  const EnterEmailForm = () => {
    // const handleForgotMailPassword = async () => {
    //
    //   try {
    //     const response = await resendActivationEmail(userData.email);
    //
    //     if (response.success) {
    //       setForgotPass(1);
    //     }
    //   } catch (e) {
    //     throw new Error(e.message);
    //   }
    // };
    const [ errorMsg, seterrorMsg ] = React.useState( );
    const sendVerificationEmail = async (value) => {
      setLoading(true);
      try {
        const { data } = await client.mutate({
          mutation: RESEND_ACTIVATION_EMAIL,
          variables: { email: value.email }
        });
        setLoading(false);
        if (data?.resendActivationEmail?.success) {
          setForgotPass(1);
        }
        else{
          let errors = Object.values(data?.resendActivationEmail?.errors)
          errors = [].concat.apply([],errors)
          if(errors?.length){
            message.error(errors[0]?.message || "Failed to send verification email")
          }else{
            message.error("Failed to send verification email")
          }
        }
       
      } catch (e) {
        message.error("Failed to send verification email")
        setLoading(false);
      }
      // setForgotPass(0);
      setLoading(false);
    };


    return (
      <Spin spinning={loading}>
        <div>
          <h3>Verify your email address</h3>
          <LessDivider up />
          <h4>Before proceeding, you need to verify your email ID. We had sent an email to you on your registered email ID. Please look for the email and click on the verification link. If you can't find the email or the link has expired, please enter your registered email ID below to request a fresh verification link.</h4>
          <Form
            {...layout}
            requiredMark={false}
            name="verifyEmail"
            onFinish={sendVerificationEmail}
            validateMessages={validateMessages}
          >
                <Form.Item
                  name="email"
                  label=""
                  rules={[
                    {
                      required: true,
                      message: "Email is required",
                    },
                    {
                      type: 'email',
                      message: 'Enter a valid email address.',
                    }
                  ]}
                >
                  <Input placeholder='Email' />
                </Form.Item>

          
        
            {/* <LessDivider /> */}
            {errorMsg && (
        <p style={{color:'red',marginBottom:'10px'}}>{errorMsg?.message}</p>
        )}
            <Row gutter={16} style={{ paddingTop: "10px" }}>
              <Col lg={12} md={12}>
                <Button block type="primary" htmlType="submit" /* onClick={() => setForgotPass(5)} */ size="middle">
                  Sent
                </Button>
              </Col>
              <Col lg={12} md={12}>
                <Button block type="primary" ghost onClick={() => setVisible( false )} size="middle">
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Spin>
    );
  };

  const EmailSent = () => (
    <>
      <h2>Check your email</h2>
      {/* <LessDivider up /> */}
      <Row>
        <Col lg={24} md={24} sm={0} xs={0}>
          <br />
        </Col>
      </Row>
      <div style={{ textAlign: 'center' }}>
        <MailSent>
          <CheckCircleFilled /> &nbsp;
          <span>Mail Send</span>
        </MailSent>
        <h3>An email verification link is sent to your email account</h3>
        <Row>
          <Col lg={0} md={0} sm={24} xs={24}>
            <Button
              type="primary"
              ghost
              onClick={() => {
                setForgotPass(0);
                setVisible(false);
              }}
              size="middle"
            >
              Login
            </Button>
          </Col>
        </Row>
        <Row>
          <Col lg={24} md={24} sm={0} xs={0}>
            <br />
          </Col>
        </Row>
        {/* <LessDivider /> */}
        <Row>
          <Col lg={24} md={24} sm={0} xs={0}>
            <Button type="primary" ghost   onClick={() => {setForgotPass(0);setVisible(false);}} size="middle">
              Login
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );

  return (
    <>
      <Modal
        closable={false}
        footer={null}
        centered
        visible={visible}
        onCancel={() => {setForgotPass(0);setVisible(false)}}
        bodyStyle={{ padding: '0px' /* , position:'relative' */ }}
        destroyOnClose={true}
      >
        {/* {loading && (
          <>
            <div style={{ width: '100%', height: '100%', position: 'absolute', background: 'black', opacity: '0.8' }} />
            <div style={{ position: 'absolute', width: '100%', height: '100%', display: 'grid' }}>
              <Spin size="large" style={{ color: 'green', margin: 'auto' }} />
            </div>
          </>
        )} */}
        <ModalBody>
          {forgotPass === 0 && <EnterEmailForm />}
          {forgotPass === 1 && <EmailSent />}
        </ModalBody>
      </Modal>
    </>
  );
};

export default compose(withApollo, withResendActivationEmail)(SendEmailVerify);

const MailSent = styled.div`
  color: #009c4a;
  font-size: 25px;
`;

const LessDivider = styled(Divider)`
  margin-top: ${props => {
    return props.up && '0px';
  }};
  @media only screen and (min-width: 480px) {
    margin: 0;
    margin-bottom: 0.5em;
  }
`;

const ModalBody = styled.div`
  padding: 24px 48px;
  @media only screen and (max-width: 480px) {
    padding: 24px;
  }
`;
