import gql from 'graphql-tag';
import { COMMITMENT_TYPE } from './CommitmentType.gql';

export const DELETE_COMMITMENT = gql`
  ${COMMITMENT_TYPE}
  mutation deleteCommitment($id: ID!) {
    deleteCommitment(id: $id) {
      commitment {
        ...CommitmentType
      }
    }
  }
`;
