import gql from 'graphql-tag';
import { FORM_SECTION_TYPE_CONNECTION } from './FormSectionTypeConnection.gql';

export const GET_ALL_FORM_SECTIONS = gql`
  ${FORM_SECTION_TYPE_CONNECTION}
  query getAllFormSections($first: Int, $offset: Int, $orderBy: [String]) {
    getAllFormSections(first: $first, offset: $offset, orderBy: $orderBy) {
      ...FormSectionTypeConnection
    }
  }
`;
