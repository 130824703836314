import { Button, Col, Row } from 'antd';
import React from 'react';
import footer_background_sales from '../../assets/footer_background_sales.png'
import '../css/SalesPageStyles.css'
import { useHistory } from 'react-router-dom/cjs/react-router-dom';


const BookDemoView = props => {

    const {paddingTop} = props

    const history = useHistory()
    

    return (
        <>

            <img style={{ width: '100%', position: 'absolute' }} className='book-demo-background-sales' src={footer_background_sales} />
            <Col className='book-demo-container'>
                <Row justify='center' className='poppins book-demo-header' style={{  paddingTop: paddingTop ? paddingTop : "40px" }}>BOOK A DEMO!</Row>
                <Row justify='center' className='poppins book-demo-description-sales'>We help our customers meet their needs with the help of our experts</Row>
                <Row justify='center' style={{ gap: '20px', paddingBottom: '40px' }}>
                    <Button className='book-demo-button'>
                        <Row justify='center' onClick={(e) => history.push('/intro/getintouch')} 
                        className='poppins sales-page-white-color'  style={{ gap: '10px', fontSize: '15px', letterSpacing: '1.2' }}><div style={{ fontWeight: '600' }}>Get in touch</div></Row>
                    </Button>
                </Row>
            </Col>

        </>
    );
};

export default BookDemoView;
