import React from "react";
import { Spin } from "antd";

import { compose } from "../../core";
import { ButtonsCatch, getIntFromString } from "../../look";

import EditEmployeeView from "../components/EditEmployeeView";
import ROUTE from "../route";

import { withEditEmployee, withEmployeeByIdQuery } from "./OrganizationOperation";

const EditEmployee = props => {
  const { history, editEmployee, employeeById } = props;
  const [submitLoading, setSubmitLoading] = React.useState(false);

  const handleSubmit = async (values, item) => {
    setSubmitLoading(true);

    try {
      const response = await editEmployee({ id: getIntFromString(employeeById.id) });

      response &&
        ButtonsCatch(item, ROUTE.employee, history, item === undefined ? window.location.pathname : `${ROUTE.addEmployee}`);
    } catch (e) {
      throw new Error(e.message);
    }
    setSubmitLoading(false);
  };

  return (
    <Spin spinning={submitLoading} size="large">
      <EditEmployeeView onSubmit={handleSubmit} {...props} />
    </Spin>
  );
};

export default compose(withEmployeeByIdQuery, withEditEmployee)(EditEmployee);
