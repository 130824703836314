// @ts-nocheck
import { Row } from "antd";
import { getIntFromString } from "modules/layout/Layout";
import React from "react";
import deleteContent from '../../assets/agenda-close.svg'

const GivenKudosEditFeedback = props => {
    const { givenKudos, setGivenKudos, deleteBadgeAward, fromGiveFeedback, feedbackById, newKudosAdded, deleteUpdateKudos,
        setGivenBadgeAward, buttonStyle, setNewKudosAdded } = props

    const handleGivenKudos = async () => {

        if (givenKudos && !newKudosAdded) {
            let res = await deleteBadgeAward({ badgeAwardId: getIntFromString(feedbackById?.badgeAward?.edges[0]?.node?.id) })
            if (res) {
                setGivenKudos();
                setNewKudosAdded(false)
                if (deleteUpdateKudos) {
                    deleteUpdateKudos(true)
                }
            }
        } else if (givenKudos && newKudosAdded) {
            setGivenKudos()
        } else {
            setGivenKudos()
        }
        setGivenBadgeAward(undefined)
    }

    return (
        <>

            {!fromGiveFeedback&&<img src={deleteContent} style={buttonStyle ? buttonStyle : {}} className="delete-given-kudos-button-edit-feedback" onClick={(e) => handleGivenKudos(e)} />}
            {
                fromGiveFeedback ?
                    <div className="given-kudos-container" style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: '1em', borderColor: "rgba(199, 33, 45, 1)", background: "rgba(255, 224, 226, 1)", position: "relative", width: "fit-content" }}>
                        <img src={deleteContent} style={{ position: "absolute", top: "-10px", right: "0px", width: "20px", cursor: "pointer" }} onClick={(e) => handleGivenKudos(e)} />
                        <img src={givenKudos?.image} style={{ height: "30px", width: "40px" }} />
                        <h4 style={{ fontSize: "1.1em", fontWeight: "500", fontFamily: "Poppins", margin: "0px", color: "black" }}>{givenKudos?.title}</h4>
                    </div>
                    :
                    <img src={givenKudos?.image} className="given-kudos-container" />
            }

        </>
    );
};

export default GivenKudosEditFeedback;

