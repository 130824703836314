const baseName = '/report';

const reportBaseName = '/survey/';
const userBaseName = '/user/';
const comparisonBaseName = '/comparison-with/';

module.exports = {
  reportLink: baseName + reportBaseName,
  repotUserLink: userBaseName,
  reportComparisonLink: comparisonBaseName,

  surveyComparisonReport: baseName + reportBaseName + ':id' + userBaseName + ':uid' + comparisonBaseName + ':username'
};
