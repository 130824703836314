import { GET_ALL_DEFAULT_COMMITMENTS_PROGRESS_FOR_REPORTING_EMPLOYEE_SUBSCRIPTION } from 'modules/manager/graphql/GetAllDefaultCommitmentsProgressForReportingEmployee.gql';
import { subscribeToQuery } from '../../look';
import { GET_ALL_CUSTOM_USER_COMMITMENTS_SUBSCRIPTION } from '../graphql/GetAllCustomUserCommitments.gql';

import { SURVEY_COMPARISON_REQUEST_SUBSCRIPTION } from '../graphql/SurveyComparisonRequestSubscription.gql';
import { GET_ALL_CUSTOM_USER_COMMITMENTS_FOR_REPORTING_EMPLOYEE_SUBSCRIPTION } from 'modules/manager/graphql/GetAllCustomUserCommitmentsForReportingEmployee.gql';
import { GET_ALL_DEFAULT_COMMITMENTS_PROGRESS_SUBSCRIPTION } from '../graphql/GetAllDefaultCommitmentsProgress.gql';
import { ALL_COMPARISON_REQUEST_FOR_USER_SUBSCRIPTION } from '../graphql/AllComparisonRequestForUser.gql';

export const subscribeToSurveyComparisonRequestForUser = (subscribeToMore, variables) => {
  return subscribeToQuery(
    subscribeToMore,
    {
      document: SURVEY_COMPARISON_REQUEST_SUBSCRIPTION,
      subscriptionName: 'surveyComparisonRequestSubscription',
      nodeName: 'comparisonRequest',
      queryName: 'allComparisonRequestForUser'
    },
    variables
  );
};

export const subscribeToSurveyComparisonRequestFromUser = (subscribeToMore, variables) => {
  return subscribeToQuery(
    subscribeToMore,
    {
      document: SURVEY_COMPARISON_REQUEST_SUBSCRIPTION,
      subscriptionName: 'surveyComparisonRequestSubscription',
      nodeName: 'comparisonRequest',
      queryName: 'allComparisonRequestFormUser'
    },
    variables
  );
};

export const subscribeToCustomUserCommitments = (subscribeToMore, variables) => {
  return subscribeToQuery(
    subscribeToMore,
    {
      document: GET_ALL_CUSTOM_USER_COMMITMENTS_SUBSCRIPTION,
      subscriptionName: 'toolsCustomUserCommitmentSubscription',
      nodeName: 'customUserCommitment',
      queryName: 'getAllCustomUserCommitments'
    },
    variables
  );
};

export const subscribeToDefaultCommitmentProgress = (subscribeToMore, variables) => {
  return subscribeToQuery(
    subscribeToMore,
    {
      document: GET_ALL_DEFAULT_COMMITMENTS_PROGRESS_FOR_REPORTING_EMPLOYEE_SUBSCRIPTION,
      subscriptionName: 'toolsDefaultCommitmentProgressSubscription',
      nodeName: 'defaultCommitmentProgress',
      queryName: 'getAllDefaultCommitmentsProgressForReportingEmployee'
    },
    variables
  );
};

export const subscribeToCustomUserCommitmentsForReporting = (subscribeToMore, variables) => {
  return subscribeToQuery(
    subscribeToMore,
    {
      document: GET_ALL_CUSTOM_USER_COMMITMENTS_FOR_REPORTING_EMPLOYEE_SUBSCRIPTION,
      subscriptionName: 'toolsCustomUserCommitmentSubscription',
      nodeName: 'customUserCommitment',
      queryName: 'getAllCustomUserCommitmentsForReportingEmployee'
    },
    variables
  );
};

export const subscribeToComparisonRequest = (subscribeToMore, variables) => {
  return subscribeToQuery(
    subscribeToMore,
    {
      document: ALL_COMPARISON_REQUEST_FOR_USER_SUBSCRIPTION,
      subscriptionName: 'surveyComparisonRequestSubscription',
      nodeName: 'comparisonRequest',
      queryName: 'allComparisonRequestForUser'
    },
    variables
  );
};

export const subscribeToSefaultCommitment = (subscribeToMore, variables) => {
  return subscribeToQuery(
    subscribeToMore,
    {
      document:GET_ALL_DEFAULT_COMMITMENTS_PROGRESS_SUBSCRIPTION,
      subscriptionName: 'toolsDefaultCommitmentProgressSubscription',
      nodeName: 'defaultCommitmentProgress',
      queryName: 'getAllDefaultCommitmentsProgress'
    },
    variables
  );
};
