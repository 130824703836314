import gql from 'graphql-tag';
import { TEXT_SECTION_TYPE } from './TextSectionType.gql';

export const TEXT_SECTION_SUBSCRIPTION = gql`
  ${TEXT_SECTION_TYPE}
  subscription toolsTextSectionSubscription {
    toolsTextSectionSubscription {
      mutation
      textSection {
        ...TextSectionType
      }
    }
  }
`;
