// @ts-nocheck
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { Space, Col, Row, Button, Form, Popover } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { withApollo } from '@apollo/client/react/hoc';
import {
    ReactMarkdown,
    RadioField,
    MLCheckBoxField,
    TextField,
    RangeField,
    SelectField,
    ColorField,
    InputValuesObject,
    DatePickerField,
    RenderUpload,
    getIntFromString,
    RenderUploadWithCrop
} from '../../look';

import ProgressCounterBtn from '../../survey/components/ProgressCounterBtn';
import ProgressCounterBtnNative from '../../survey/components/ProgressCounterBtnNative';
import { compose } from 'modules/core';
import { FILTER_SURVEY_CHOICE } from '../graphql/filterSurveyChoice.gql'
import SurveyCheckBoxField from 'modules/survey-look/containers/survey-checkbox-filed';
import SurveyColorField from 'modules/survey-look/containers/survey-color-filed';
import SurveyDatePickerField from 'modules/survey-look/containers/survey-datepicker-field';
import SurveyTimePicker from 'modules/survey-look/containers/survey-time-field';
import SurveyRadioField from 'modules/survey-look/containers/survey-radio-button-filed';
import SurveyRangeField from 'modules/survey-look/containers/survey-range-filed';
import SurveyTextField from 'modules/survey-look/containers/survey-text-field';
import SurveySelectField from 'modules/survey-look/containers/survey-select-filed';
import help_icon from '../../assets/help-text.svg'
import CKEditorFieldSurvey from 'modules/survey-look/containers/survey-ck-editio-filed';
export const TIME_LIMIT = 5;

const emailRules = { type: 'email', message: 'Not a vaild email' };

export function getTypeFromQuestion(question) {
    return question?.choiceSet?.edges[0]?.node?.inputType;
}

const DviversitySurveySelectionnewSkinComponent = (props) => {


    const {
        questionList,
        form,
        srNo,
        showOkbutton,
        setShowOkbutton,
        submitted,
        selectedvalues,
        survey,
        client,
        group,
        currentIdx,
        goNext,
        doNothing,
        form_values
    } = props;

    const [open, setOpen] = useState(false);
    const values = form.getFieldsValue(true);
    const [showInput, setShowInput] = React.useState(false)

    let available_type_for_okay = [
        InputValuesObject.checkbox,
        InputValuesObject.ckeditor,
        InputValuesObject.email,
        InputValuesObject.number,
        InputValuesObject.password,
        InputValuesObject.telephone,
        InputValuesObject.tel,
        InputValuesObject.text,
        InputValuesObject.textarea,
        InputValuesObject.url,
        InputValuesObject.color
    ]

    const getRangeValues = (choiceSet, type) => {
        let defaultValue = 0,
            marks = {},
            step = 1;
        const value = [];
        // min:max:jump:default
        let noChoise = !value?.length ? true : false
        if (type === 'RANGE') {
            marks[value[0]] = value[0];
            marks[value[1]] = value[1];
            step = value[2] === '0' ? '1' : value[2];
            defaultValue = value[3];
        }

        return { step, marks, defaultValue, min: Number(value[0]), max: Number(value[1]), noChoise };
    };

    const OperandValues = (id) => {
        if (values) {
            if (values[id] !== undefined) {
                return true
            }
            else {
                return false
            }
        }

    }

    const OperandSelectedValues = (id) => {
        setShowInput(true)
    }
    const helpContent = (text) => {
        return (
            <div style={{ maxWidth: "400px", width: "100%" }}>
                <p className='generic-survey-help-text'><ReactMarkdown>{text}</ReactMarkdown></p>
                <button className='generic-survey-help-text-btn' onClick={() => setOpen(false)}>Got it</button>
            </div>
        )
    }

    const RenderQuestion = (question, index) => {
        const [selectedq_id, setSelectedId] = React.useState()
        const [colorLoading, setColorLoading] = React.useState(false);
        const [load, setLoad] = useState(false);
        const { id, sequence, questionText, required, helpText } = question;
        const [choiceSet, setchoiceSet] = React.useState(question?.choiceSet)
        const type = getTypeFromQuestion(question);
        const [reset, setReset] = useState(false);
        const [numberInput, setNumberInput] = React.useState()
        const [selectedCheckboxValueStore, setSelectedCheckboxValueStore] = React.useState({})
        const [selected_question_id, setSelectedQuestionId] = React.useState()
        const [hide_okay_btn, SetHideOkayBtn] = React.useState(false)


        let oke_time_out_ref = React.useRef()
        const handleOkayBtn = (hide_okay) => {
            if (oke_time_out_ref?.current) {
                clearTimeout(oke_time_out_ref?.current)
                oke_time_out_ref.current = undefined
            }
            SetHideOkayBtn(hide_okay)
            if (!hide_okay) {
                oke_time_out_ref.current = setTimeout(() => {
                    SetHideOkayBtn(true)
                }, 10000);
            }
        }

        const onSelectFeildSearch = async (search, question) => {
            let id = survey?.id;
            const { data } = await client.query({
                query: FILTER_SURVEY_CHOICE,
                variables: { id: id, label_Icontains: search },
                // fetchPolicy: 'network-only'
            });
            if (data?.Options) {
                let groupData = data?.Options?.groupSet?.edges?.find(item => item?.node?.id === group?.node?.id)
                if (groupData) {
                    let currentQuestion = groupData?.node?.questionSet?.edges?.find(item => item?.node?.id === question?.id)
                    if (currentQuestion) {
                        setchoiceSet(currentQuestion?.node?.choiceSet)
                    }
                }
            }
        }

        React.useEffect(() => {
            if (id) {
                let selectdCheck = JSON.parse(localStorage.getItem('oldValue'))
                let checkPrevious = {}
                if (selectdCheck) {
                    checkPrevious[id] = selectdCheck[id]
                }
                setSelectedCheckboxValueStore(checkPrevious)
            }
        }, [id])
        const obj = {};
        const keyArray = Object.keys(values);
        keyArray?.length > 0 &&
            keyArray.map(key => {
                obj[getIntFromString(key)] = values[key];
            });
        const label = (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '0px' }}>
                <h4 >{srNo}.</h4>
                <ReactMarkdown style={{ marginLeft: '4px' }}>{questionText}</ReactMarkdown>
            </div>
        );

        const renderLabel = (
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: "5px", width: "100%", paddingTop: "10px" }}>
                {required && <span style={{ color: "#DE4A4A" }}>*</span>}
                {label}
            </div>
        );
        const placeholderLabel = (
            <Row>
                <p>Select a</p><ReactMarkdown style={{ marginLeft: '4px' }}>{questionText}</ReactMarkdown>
            </Row>

        )
        const rules = [{ required: true, message: 'An answer is required.' }];

        const { step, marks, defaultValue, min, max, noChoise } = getRangeValues(
            choiceSet?.edges?.map(({ node }) => node),
            type
        );

        const handleChange = (selectedId, choices, type, doNext, q_id) => {
            handleOkayBtn(false)
            let selectedAnswer
            if (type === 'CHECKBOX') {
                let selectedCheckboxValue = JSON.parse(localStorage.getItem('oldValue'))
                if (!selectedCheckboxValue) {
                    selectedCheckboxValue = {}
                }
                let oldVal = selectedCheckboxValueStore[id]
                let change = []
                let lenOldVal = oldVal ? oldVal.length : 0

                selectedCheckboxValue[id] = selectedId
                localStorage.setItem('oldValue', JSON.stringify(selectedCheckboxValue))
                if (lenOldVal > selectedId.length) {
                    change = oldVal.filter(item => !selectedId.includes(item))
                }
                else if (lenOldVal < selectedId.length) {
                    change = selectedId.filter(item => {
                        if (oldVal) {
                            return !oldVal.includes(item)
                        }
                        else {
                            return true
                        }
                    })
                }

                setSelectedCheckboxValueStore({ ...selectedCheckboxValueStore, [id]: selectedId })
                selectedAnswer = change[0]
            }
            else {
                selectedAnswer = selectedId
            }
            if (doNext) {
                setTimeout(() => {
                    setSelectedQuestionId()
                    if (!selectedq_id) {
                        setShowOkbutton(true)
                        setSelectedId()
                    }
                    else {
                        doNothing(currentIdx)
                        setSelectedId()
                    }

                }, 500)
            }
            else {
                setShowOkbutton(false)
                setSelectedQuestionId(q_id)
            }
            let select = choices?.edges.find(({ node }) => node?.value === selectedAnswer)
            selectedvalues(getIntFromString(select?.node?.id), select?.node?.sequence, id, type)
        };

        const onNextStep = (values) => {
            submitted(values)
        }

        const gotToNextAction = (id, sr_no) => {
            if (!selectedq_id) {
                goNext()
            }
            else {
                doNothing(currentIdx)
                setSelectedId()
            }
        }
        return true ? (
            <div key={index}>

                <Form
                    form={form}
                    name={"value"}
                    // layout={'vertical'}
                    required={required}
                    onFinish={(values) => { onNextStep(values) }
                    }
                >
                    {<div style={{ minWidth: '200px' }}>
                        <div className="generic-survey-question-container" style={{ background: OperandValues(id) ? "rgba(236, 241, 244, 1)" : "rgba(244, 249, 253, 1)" }} key={index} visible={srNo - 1 === currentIdx} >
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: "10px", cursor: "pointer" }} onClick={() => showInput ? setShowInput(false) : (OperandSelectedValues(id), setSelectedId(id))}>
                                <h4 className='generic-survey-question' >{renderLabel}</h4>
                                {helpText && <Popover visible={open} trigger="click" overlayClassName="generic-survey-help-text-popover" placement="topRight" content={helpContent(helpText)}><img src={help_icon} alt='' style={{ cursor: "pointer", width: "25px" }} onClick={() => setOpen(true)} />
                                </Popover>}
                            </div>
                            {(srNo - 1 === currentIdx || values[id] !== undefined || showInput) && (
                                <div style={{ marginTop: "10px" }}>
                                    {type === InputValuesObject.checkbox && (
                                        <SurveyCheckBoxField
                                            name={id}
                                            //   label={renderLabel}
                                            rules={required ? rules : []}
                                            choices={choiceSet.edges.map(({ node }) => node).map(c => ({ label: c.label, value: c.value }))}
                                            onChange={e => e && handleChange(e, choiceSet, type, false, id)}
                                            required={required}
                                        />
                                    )}
                                    {type === InputValuesObject.color && !colorLoading && (
                                        <SurveyColorField
                                            name={id}
                                            //   label={renderLabel}
                                            rules={required ? rules : []}
                                            choices={choiceSet.edges.map(({ node }) => node).map(c => ({ label: c.label, value: c.value }))}
                                            onChange={e => {
                                                setColorLoading(true);
                                                e && form.setFieldsValue(_.set(form.getFieldsValue(true), [id], e));
                                                setColorLoading(false);
                                                handleChange(e, choiceSet, type, false, id)
                                            }}
                                            value={form.getFieldValue(id)}
                                            required={required}
                                        />
                                    )}
                                    {type === InputValuesObject.date && (
                                        <SurveyDatePickerField
                                            margin={false}
                                            name={id}
                                            //   label={label}
                                            offset={3}
                                            format="YYYY-MM-DD"
                                            rules={[{ required, message: 'Please select a date' }]}
                                            enabledisabledDate={false}
                                            onChange={e => e && handleChange(e, choiceSet, type, true, id)}
                                            required={required}
                                        />
                                    )}
                                    {type === InputValuesObject.datetime && (
                                        <SurveyDatePickerField
                                            margin={false}
                                            name={id}
                                            // label={label}
                                            offset={3}
                                            showTime={{ format: 'HH:mm' }}
                                            format="YYYY-MM-DD HH:mm"
                                            rules={[{ required, message: 'Please select a date and time' }]}
                                            enabledisabledDate={false}
                                            onChange={e => e && handleChange(e, choiceSet, type, true, id)}
                                            required={required}
                                        // defaultValue={moment('2015-06-06', 'YYY-MM-DD')}
                                        />
                                    )}
                                    {type === InputValuesObject.month && (
                                        <SurveyDatePickerField
                                            margin={false}
                                            name={id}
                                            //   label={label}
                                            offset={3}
                                            rules={[{ required, message: 'Please select a month' }]}
                                            enabledisabledDate={false}
                                            picker="month"
                                            onChange={e => e && handleChange(e, choiceSet, type, true, id)}
                                            required={required}
                                        // defaultValue={moment('2015-06-06', 'YYY-MM-DD')}
                                        />
                                    )}
                                    {type === InputValuesObject.time && (
                                        <SurveyTimePicker
                                            margin={false}
                                            name={id}
                                            //   label={label}
                                            offset={3}
                                            rules={[{ required, message: 'Please select a month' }]}
                                            enabledisabledDate={false}
                                            type="time"
                                            onChange={e => e && handleChange(e, choiceSet, type, true, id)}
                                            required={required}
                                        // defaultValue={moment('2015-06-06', 'YYY-MM-DD')}
                                        />
                                    )}
                                    {type === InputValuesObject.week && (
                                        <SurveyDatePickerField
                                            margin={false}
                                            name={id}
                                            //   label={label}
                                            offset={3}
                                            rules={[{ required, message: 'Please select a month' }]}
                                            enabledisabledDate={false}
                                            picker="week"
                                            onChange={e => e && handleChange(e, choiceSet, type, true, id)}
                                            required={required}
                                        // defaultValue={moment('2015-06-06', 'YYY-MM-DD')}
                                        />
                                    )}
                                    {type === InputValuesObject.radio && (
                                        <SurveyRadioField
                                            name={id}
                                            //   label={renderLabel}
                                            choices={choiceSet.edges.map(({ node }) => node).map(c => ({ label: c.label, value: c.value }))}
                                            rules={required ? rules : []}
                                            onChange={e => e?.target?.value && handleChange(e?.target?.value, choiceSet, type, true, id)}
                                            required={required}
                                        />
                                    )}
                                    {type === InputValuesObject.range && (
                                        <SurveyRangeField
                                            name={id}
                                            //   label={
                                            //     <div style={{ minWidth: '25vw', maxWidth: 'calc(100% - 30px)' }}>
                                            //       {label}
                                            //       <ReactMarkdown style={{ marginLeft: '3vw' }}>{helpText}</ReactMarkdown>
                                            //     </div>
                                            //   }
                                            rules={required ? rules : []}
                                            marks={marks}
                                            step={step}
                                            defaultValue={defaultValue}
                                            min={min}
                                            max={max}
                                            trackStyle={{ backgroundColor: '#B0CB1F' }}
                                            handleStyle={{ backgroundColor: '#B0CB1F' }}
                                            onAfterChange={e => e && handleChange(e, choiceSet, type, true, id)}
                                            required={required}
                                        />
                                    )}
                                    {type === InputValuesObject.email && (
                                        <SurveyTextField
                                            name={id}
                                            // label={renderLabel}
                                            rules={required ? rules.map(r => ({ ...r, ...emailRules })) : [{ ...emailRules }]}
                                            // rules={[{ type: 'email' }]}
                                            offset={2}
                                            onChange={e => e.target.value && handleChange(e, choiceSet, type, false, id)}
                                            onBlur={e => e?.target?.value && handleChange(e, choiceSet, type, false, id)}
                                            required={required}
                                        />
                                    )}
                                    {type === InputValuesObject.ckeditor && (
                                        <Row>
                                            <Col lg={24} xs={20}>
                                                <CKEditorFieldSurvey
                                                    name={id}
                                                    rules={required ? rules : []}
                                                    offset={2}
                                                    onChange={e => e && handleChange(false)}
                                                    onBlur={e => e && handleChange(false)}
                                                    required={required}
                                                />
                                            </Col>
                                        </Row>
                                    )}
                                    {type === InputValuesObject.file && (
                                        <RenderUploadWithCrop
                                            setUrl={e => { e && handleChange(true); form.setFieldsValue(_.set(form.getFieldsValue(true), id, e)) }}
                                            setLoad={setLoad}
                                            offset={2}
                                            name={id}
                                            //   label={renderLabel}
                                            required={required}
                                        />
                                    )}
                                    <Row>
                                        <Col lg={24} xs={20}>
                                            {type === InputValuesObject.text && (
                                                <SurveyTextField
                                                    name={id}
                                                    //   label={renderLabel}
                                                    rules={required ? rules : []}
                                                    offset={2}
                                                    onChange={e => e && handleChange(e, choiceSet, type, false, id)}
                                                    onBlur={e => e?.target?.value && handleChange(e, choiceSet, type, false, id)}
                                                    required={required}
                                                />
                                            )}
                                        </Col>
                                    </Row>
                                    {type === InputValuesObject.number && (
                                        <SurveyTextField
                                            name={id}
                                            //   label={renderLabel}
                                            rules={required ? rules : []}
                                            type="number"
                                            offset={2}
                                            onChange={e => e && handleChange(e, choiceSet, type, false, id)}
                                            onBlur={e => e?.target?.value && handleChange(e, choiceSet, type, false, id)}
                                            required={required}
                                        />
                                    )}
                                    {type === InputValuesObject.select && (
                                        <Row>
                                            <Col lg={24} xs={20}>
                                                <SurveySelectField
                                                    showSearch
                                                    onSearch={(e) => { onSelectFeildSearch(e, question) }}
                                                    otherOption={true}
                                                    name={id}
                                                    offset={2}
                                                    placeholder={placeholderLabel}
                                                    rules={required ? rules : []}
                                                    choices={choiceSet.edges.map(({ node }) => node).map(c => ({ label: c.label, value: c.value }))}
                                                    onSelectChange={e => e && handleChange(e, choiceSet, type, true, id)}
                                                    onBlur={e => e?.target?.value && handleChange(e, choiceSet, type, false, id)}
                                                    required={required}
                                                />
                                            </Col>
                                        </Row>
                                    )}
                                    {(available_type_for_okay?.includes(type) && form_values[id] && !hide_okay_btn) && (
                                        <>
                                            <Button className='generic-survey-ok-button' style={{ height: "2.5em" }} disabled={load} onClick={e => { gotToNextAction(id, srNo - 1); setSelectedQuestionId(); handleOkayBtn(true) }}>
                                                Okay
                                            </Button>
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>}
                </Form>
            </div>
        ) : null;

    };
    return questionList?.map(RenderQuestion)
}

export default compose(withApollo)(DviversitySurveySelectionnewSkinComponent)

