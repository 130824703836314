import React from 'react';
import _ from 'lodash';
import { Spin } from 'antd';
import { useParams } from 'react-router-dom';

import { compose } from '../../core';
import { ButtonsCatch, getIntFromString, withMarkdownFieldValue } from '../../look';

import AddQuestionView from '../components/AddQuestionView';
import { CKEditorFieldName, CKEditorFieldName1 } from '../components/QuestionFormComponent';
import { CKEditorFieldName2 } from '../components/ChoiceFormComponent';

import { withAddChoice, withAddQuestion, withSurveyState } from './SurveyOperations';
import ROUTE from '../route';

const AddQuestion = props => {
  const { history, createQuestion, addChoice } = props;
  const { id } = useParams();
  const [submitLoading, setSubmitLoading] = React.useState(false);

  const handleSubmit = async (questionData, choiceData, item) => {
    setSubmitLoading(true);

    try {
      const response = await createQuestion({
        questionData: {
          ...withMarkdownFieldValue(CKEditorFieldName, {
            ...withMarkdownFieldValue(CKEditorFieldName1, {
              ...questionData,
              groupId: id
            }),
            groupId: id
          })
        }
      });
      await Promise.all(
        choiceData.map(({ idx, ...choice }) =>
          addChoice({
            ...choice,
            inputType: String(choice.inputType),
            questionId: Number(getIntFromString(response.id))
          })
        )
      );

      response &&
        ButtonsCatch(
          item,
          `${ROUTE.surveyDetailLink}${getIntFromString(response.group.survey.id)}`,
          history,
          item === undefined ? `${ROUTE.editQuestionLink}${getIntFromString(response.id)}` : window.location.pathname
        );
      setSubmitLoading(false);
    } catch (e) {
      setSubmitLoading(false);
    }
  };
  return (
    <Spin spinning={submitLoading} size="large">
      <AddQuestionView onSubmit={handleSubmit} {...props} />
    </Spin>
  );
};

export default compose(withSurveyState, withAddQuestion, withAddChoice)(AddQuestion);
