import gql from "graphql-tag";


export const BARS_DETAILS = gql`
query behaviourallyAnchoredRatingById($id:ID!){
    behaviourallyAnchoredRatingById(id:$id){
      id
      mainCompetency {
        id
        title
        competencySet {
          totalCount
          edges {
            node {
              id
              behaviourallyanchoredratingSet {
                totalCount
              }
            }
          }
        }
        behaviourallyanchoredratingSet {
          edges {
            node {
              id
              employeeLevel {
                id
                name
              }
              ratingScale {
                id
                name
              }
              behaviourExample
            }
          }
        }
      }
    }
  }
`;

export const BARS_DETAILS_SUB_COMPETENCY = gql`
query behaviourallyAnchoredRatingById($id:ID!){
    behaviourallyAnchoredRatingById(id:$id){
      id
      behaviour {
        id
        title
        behaviourallyanchoredratingSet {
          edges {
            node {
              id
              employeeLevel {
                id
                name
              }
              ratingScale {
                id
                name
              }
              behaviourExample
            }
          }
        }
      }
    }
  }
`;


export const SUB_COMPETENCY_BY_ID = gql`
query competencyById($id:ID!){
  competencyById(id:$id){
    id
    title
    behaviourallyanchoredratingSet {
      edges {
        node {
          id
          employeeLevel {
            id
            name
          }
          ratingScale {
            id
            name
          }
          behaviourExample
        }
      }
    }
  }
  
}
`;