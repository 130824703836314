import React from 'react';

import { Link } from 'react-router-dom';
import arrayMove from 'array-move';
import { RightCircleOutlined, MenuOutlined, DownCircleOutlined } from '@ant-design/icons';
import { Button, Row, Col, Table, message } from 'antd';
import { compose } from '../../core';
import styled from 'styled-components';
import {
  AdminLayout,
  displayDataCheck,
  displayBoolean,
  getIntFromString,
  GetColumnSearchProps,
  getOrderBy,
  EditIcon,
  DeleteIcon
} from '../../look';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { withEditQuestion, withEditChoice, withDeleteQuestion } from './SurveyOperations';
import ChoiceTable from './ChoiceTable';
import ROUTE from '../route';
import ReactMarkdown from '../../look/components/ReactMarkdown';


const QuestionTable = props => {
  const { record, index, expanded, indent, deleteQuestion, editQuestion, setSequenceLoading } = props;
  const { questionSet } = record;
  const SortableItem = sortableElement(props => <tr {...props} />);
  const SortableContainer = sortableContainer(props => <tbody {...props} />);
  const [dataSource, setDataSource] = React.useState([]);
  const [allowDraggable, setAllowDraggable] = React.useState(true);
  React.useEffect(() => {
    const edges = [];
    questionSet &&
      questionSet.edges.length > 0 &&
      questionSet.edges.map(({ node }, index) => {
        edges.push({ ...node, index: index + 1 });
      });
    setDataSource(edges);
  }, [questionSet]);

  const handleDelete = async id => {
    setSequenceLoading(true);
    try {
      await deleteQuestion({id:id})
    } catch (e) {
      throw new Error(e.message);
    }
    setSequenceLoading(false);
  };
  const onSort = async sortedItems => {
    try {
      await Promise.all(
        sortedItems.map(async i => {
          // const response = await
          editQuestion({ id: getIntFromString(i.id), sequence: i.index + 1 });
        })
      );
    } catch (e) {
      throw Error(e.message);
    }
  };
  const onSortEnd = ({ oldIndex, newIndex }) => {
    // console.log(oldIndex, newIndex);
    if (oldIndex !== newIndex) {
      const newData = arrayMove([].concat(dataSource), oldIndex, newIndex).filter(el => !!el);
      const indexData = [];
      newData.map((node, index) => {
        const data = newData[index];
        indexData.push({ ...data, index: index });
      });
      onSort && onSort(indexData);
      setDataSource(indexData);
    }
  };
  const DragHandle = sortableHandle(() => (
    <MenuOutlined style={{ cursor: 'grab', color: '#999', margin: '-16px', padding: '16px' }} />
  ));
  const DraggableContainer = props => (
    <SortableContainer useDragHandle disableAutoscroll helperClass="row-dragging" onSortEnd={onSortEnd} {...props} />
  );
  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = dataSource && dataSource.findIndex(x => x.index === restProps['data-row-key']);
    return <SortableItem  style={{border:"1px solid #f5f5f5",borderRadius:'5px',width:"100%"}}  index={index} {...restProps} />;
  };
  const columns = [
    {
      title: 'Sort',
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => <DragHandle />
    },
    {
      title: 'Name',
      key: 'name',
      // className: 'drag-visible',
      render: (text, record) => record && 
      <ReactMarkdown style={{marginTop:'1em'}} className="question-table-row-text-container">{record.questionText}</ReactMarkdown>
    },
    {
      title: 'help',
      key: 'help',
      render: (text, record) => record && <>
      <ReactMarkdown style={{marginTop:'1em'}}>{record.helpText}</ReactMarkdown></>
    },
    {
      title: 'Link',
      key: 'link',
      align: 'center',
      render: (text, record) => (
        <Row gutter={[24, 24]} justify="end">
          <Col>
            <EditIcon 
              type="link" 
              size="sm"
              tooltipSuffix="Question"
              href={`${ROUTE.editQuestionLink}${getIntFromString(record.id)}`} />
          </Col>
          <Col>
            <DeleteIcon 
              size="md"
              tooltipSuffix="Question"
              onClick={() => handleDelete(getIntFromString(record.id))} />
          </Col>
        </Row>
      )
    }
  ];
  const dragable = allowDraggable
    ? {
        components: {
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow
          }
        }
      }
    : {};

  return (
    <MyTable
      className="expand"
      showHeader={false}
      tableLayout={'auto'}
      rowKey={'index'}
      expandable={{
        expandedRowRender: (record, index, indent, expanded) => (
          <ChoiceTable record={record} index={index} indent={indent} expanded={expanded} />
        ),
        expandIcon: ({ expanded, onExpand, record }) =>
          record.choiceSet.edgeCount !== 0 ? (
            expanded ? (
              <DownCircleOutlined
                onClick={e => {
                  setAllowDraggable(true);
                  onExpand(record, e);
                }}
                style={{ fontSize: 18, display: 'flex', alignSelf: 'center', color: '#CACACA' }}
              />
            ) : (
              <RightCircleOutlined
                onClick={e => {
                  setAllowDraggable(false);
                  onExpand(record, e);
                }}
                style={{ fontSize: 18, display: 'flex', alignSelf: 'center', color: '#CACACA' }}
              />
            )
          ) : null
      }}
      pagination={false}
      columns={columns}
      dataSource={
        dataSource &&
        dataSource.length > 0 &&
        dataSource.sort((a, b) => (a.sequence < b.sequence ? -1 : a.sequence > b.sequence ? 1 : 0))
      }
      components={{
        body: {
          wrapper: DraggableContainer,
          row: DraggableBodyRow
        }
      }}
    />
  );
};
export default compose(withEditQuestion, withDeleteQuestion, withEditChoice)(QuestionTable);

const MyTable = styled(Table)`
  table {
    border-spacing: 0 1em;
    tbody {
      width:100%
      tr {
        td {
          border: 11px solid rgba(0, 0, 0, 0.06) !important;
          border-style: solid none !important;
        }
        td:first-child {
          border-left-style: solid !important;
          border-top-left-radius: 15px !important;
          border-bottom-left-radius: 15px !important;
        }
        td:last-child {
          border-right-style: solid !important;
          border-bottom-right-radius: 15px !important;
          border-top-right-radius: 15px !important;
          border-bottom: 11px solid rgba(0, 0, 0, 0.06) !important;
        }
      }
    }
  }
`;
