import gql from 'graphql-tag';
import { CUSTOM_FORM_SECTION_TYPE } from './Custom_FormSectionType.gql';

export const CUSTOM_FORM_SECTION_TYPE_CONNECTION = gql`
  ${CUSTOM_FORM_SECTION_TYPE}
  fragment Custom_FormSectionTypeConnection on Custom_FormSectionTypeConnection {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...Custom_FormSectionType
      }
    }
    totalCount
    edgeCount
  }
`;
