import gql from "graphql-tag";
export const USER_IDP_LIST = gql`
  query allIdp(
    $employeeId: ID
    $goal_Icontains: String
    $cursor: String
    $status: String
    $first:Int
  ) {
    allIdp(
      employeeId: $employeeId
      goal_Icontains: $goal_Icontains
      after: $cursor
      status: $status,
      first: $first,
      orderBy:["-createdDt"]
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          goal
          milestone
          duedate
          status
          goalType
          sendTo
          approver {
            id
            firstName
          }
        }
      }
    }
  }
`;

export const IDP_REQUEST = gql`
  query allIdp(
    $approver_Id: ID
    $goal_Icontains: String
    $status: String
    $cursor: String
    $status_In: [String]
    $employeeId_In: [ID]
    $first:Int
  ) {
    allIdp(
      approver_Id: $approver_Id
      goal_Icontains: $goal_Icontains
      status: $status
      after: $cursor
      status_In: $status_In
      employeeId_In: $employeeId_In
      first:$first,
      orderBy:["-createdDt"]
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          goal
          milestone
          duedate
          status
          goalType
          sendTo
          approver {
            id
            firstName
          }
          employee {
            id
            user {
              id
              firstName
            }
          }
        }
      }
    }
  }
`;

export const MANAGER_PERMISSION = gql`
  query me {
    me {
      id
      employee {
        id
        teamManagerEmployee {
          totalCount
        }
        verticalHeadEmployee {
          totalCount
        }
        orgPocEmployee {
          totalCount
        }
        orgCeoEmployee {
          totalCount
        }
      }
    }
  }
`;
export const DELETE_IDP_MUTATION = gql`
  mutation deleteIdp($id: ID!) {
    deleteIdp(id: $id) {
      idp {
        id
      }
    }
  }
`;

export const IDP_SUBSCRIPTION =gql `
subscription IdpSubscription{
  IdpSubscription{
    mutation
    Idp{
       id
        goal
        milestone
        duedate
        status
        goalType
        sendTo
        approver {
          id
          firstName
        }
    }
	}
}
`

export const MANAGER_EMPLOYEE_LIST = gql`
  query me {
    me {
      id
      employee {
        id
        teamManagerEmployee {
          totalCount
          edges{
            node{
              id
              employees{
                edges{
                  node{
                    id
                  }
                }
              }
            }
          }
        }
        verticalHeadEmployee {
          totalCount
          edges{
            node{
              id
              employees{
                edges{
                  node{
                    id
                  }
                }
              }
            }
          }
        }
        orgPocEmployee {
          totalCount
          edges{
            node{
              id
              employees{
                edges{
                  node{
                    id
                  }
                }
              }
            }
          }
        }
        orgCeoEmployee {
          totalCount
          edges{
            node{
              id
              employees{
                edges{
                  node{
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;