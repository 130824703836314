import gql from 'graphql-tag';

export const CREATE_BADGE=gql`
    mutation createBadge($badgeData: BadgeInput!){
        createBadge(badgeData:$badgeData){
            badge{
                id
            }
        }
    }
`