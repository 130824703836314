import gql from 'graphql-tag';

export const CREATE_OKR_MUTATION = gql`
mutation createOkr($okrData:okrInput!){
    createOkr(okrData:$okrData){
        OKR{
            id
            duedate
            startdate
            goalStatement
            correctionDeadline
            title
        }
    }
}
`