import gql from 'graphql-tag';


export const DELETE_QUALIFY_MUTATION = gql`

  mutation deleteRatingScale($ratingScaleId: ID!) {
    deleteRatingScale(ratingScaleId:$ratingScaleId) {
      ratingScale  {
            id
        }
    }
  }
`;