import ROUTE from './route';

import Story from './containers/Story';
import Tag from './containers/Tag';
import Comment from './containers/Comment';

import AllStories from './containers/AllStories';
import AdminStoryDetail from './containers/AdminStoryDetail';
import StoryDetail from './containers/StoryDetail';
import StoryDetailPreview from './containers/StoryDetailPreview';

import AddStory from './containers/AddStory';
import AddTag from './containers/AddTag';
import EditStory from './containers/EditStory';
import EditTag from './containers/EditTag';

export default [
  {
    name: 'Story',
    path: ROUTE.story,
    component: Story,
    // , adminDropdown: true,
    // icon: <MacCommandOutlined />
    admin: true
  },
  {
    name: 'Tag',
    path: ROUTE.tag,
    component: Tag,
    // , adminDropdown: true,
    admin: true
    // icon: <MacCommandOutlined />
  },
  {
    name: 'Comment',
    path: ROUTE.comment,
    component: Comment,
    admin: true
    // , adminDropdown: true,
    // icon: <MacCommandOutlined />
  },
  {
    name: 'AllStories',
    path: ROUTE.allStories,
    component: AllStories
  },
  {
    name: 'AdminStoryDetail',
    path: ROUTE.adminStoryDetail,
    component: AdminStoryDetail,
    exact: true,
    admin: true
  },
  {
    name: 'StoryDetail',
    path: ROUTE.storyDetail,
    component: StoryDetail
  },
  {
    name: 'StoryDetailPreview',
    path: ROUTE.storyDetailPreview,
    component: StoryDetailPreview,
    exact: true
    // admin: true
  },
  {
    name: 'AddStory',
    path: ROUTE.addStory,
    component: AddStory,
    admin: true
  },
  {
    name: 'AddTag',
    path: ROUTE.addTag,
    component: AddTag,
    admin: true
  },
  {
    name: 'EditStory',
    path: ROUTE.editStory,
    component: EditStory,
    admin: true
  },
  {
    name: 'EditTag',
    path: ROUTE.editTag,
    component: EditTag,
    admin: true
  }
];
