import gql from 'graphql-tag';
import { CONTACT_REQUEST_TYPE } from './ContactRequestType.gql';

export const CONTACT_REQUEST_MUTATION = gql`
  ${CONTACT_REQUEST_TYPE}
  mutation contactRequest($contactRequestData: ContactRequestInput!) {
    contactRequest(contactRequestData: $contactRequestData) {
      contactRequest {
        ...ContactRequestType
      }
    }
  }
`;
