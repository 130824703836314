import gql from 'graphql-tag';
// DO NOT CHANGE THESE QUERY
export const OKR_LIST_QUERY = gql`
query contributors($first:Int,$offset: Int,$createdBy: ID,$employee_User_FirstName_Icontains:String,$okr_Goalstatement_Icontains:String,$okr_Duedate_Range: [DateTime],$tz: String,$recipient: ID){
  AllCascadingObjectives:contributors(role_In: ["COLLABORATOR", "ASSIGNED"],first:$first,offset:$offset,employee_User_FirstName_Icontains:$employee_User_FirstName_Icontains,okr_Goalstatement_Icontains:$okr_Goalstatement_Icontains,orderBy:["-created_at"],okr_Duedate_Range:$okr_Duedate_Range,employee: $createdBy,
  createdBy_Ne:$createdBy,requestStatus: "ACCEPTED",) {
    totalCount
    edgeCount
    pageInfo{
      hasNextPage
    }
    edges {
      node {
        id
        role
        progress
        okr {
          id
          createdAt
          title(tz:$tz)
          goalStatement
          progress
          correctionDeadline
          duedate
          startdate
          okrType
          badgeAward(recipient:$recipient){
            edges{
              node{
                id
                evidence
                issuer{
                  id
                  user{
                    id
                    firstName
                    lastName
                  }
                }
                badge{
                  id
                  image
                  badgeFamily {
                    id
                    title
                  }
                  title
                  behaviouralDescription
                }
              }
            }
          }
          childSet(contributor_CreatedBy:$createdBy){
            totalCount
          }
          discardRequest(status_Iexact:"PENDING"){
            totalCount
           
           }
           discardRequestRaised:discardRequest(initiator:$recipient){
            totalCount
           }
          isDiscarded{
            id   
            status
          }
          contributorSet{
            edges{
              node{
                id
                role
                employee{
                  id
                  user{
                    id
                    firstName
                    lastName
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  
  AllNonCascadingObjectives:contributors(role_In: ["OWNER"], createdBy:$createdBy, okr_Parent_Isnull:true,first:$first,offset:$offset,employee_User_FirstName_Icontains:$employee_User_FirstName_Icontains,okr_Goalstatement_Icontains:$okr_Goalstatement_Icontains,orderBy:["-created_at"],okr_Duedate_Range:$okr_Duedate_Range) {
    totalCount
    edgeCount
    pageInfo{
      hasNextPage
    }
    edges {
      node {
        id
        role
        progress
        okr {
          id
          title(tz:$tz)
          goalStatement
          progress
          okrType
          correctionDeadline
          discardRequest(status_Iexact:"PENDING"){
            totalCount
           }
           discardRequestRaised:discardRequest(initiator:$recipient){
            totalCount
           }
          isDiscarded{
            id   
            status
          }
          duedate
          startdate
          badgeAward(recipient:$recipient){
            edges{
              node{
                id
                evidence
                issuer{
                  id
                  user{
                    id
                    firstName
                    lastName
                  }
                }
                badge{
                  id
                  image
                  title
                  behaviouralDescription
                }
              }
            }
          }
          childSet(contributor_CreatedBy:$createdBy){
            totalCount
          }
          contributorSet{
            edges{
              node{
                id
                role
                employee{
                  id
                  user{
                    id
                    firstName
                    lastName
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`