import React from "react";
import { compose } from "modules/core";
import { withApollo } from '@apollo/client/react/hoc';
import CompetencyAssessmentCreateView from "../components/ca-create-view";
import { ALL_MEMBER_LIST, ALL_ROLE_QUERY, RATING_SCALES, ROLE_BY_ID_QUERY } from "../graphql/ca-create-queries.gql";
import { Spin, message } from "antd";
import { globalPermissionValidator } from "modules/look";
import { competency_assessment_permission } from "Permissions/competency-assessment";
import NoPermissionView from "modules/kudos-user/component/NoPermissionComponent";
import { withCreateCompetencyAssessment } from "../competency-assessment-operations";
import ca_route from '../route/index'
import Item from "antd/lib/list/Item";
const CompetencyAssessmentCreateContainer = (props) => {
    const { client, history, userPermission, createAssessment, me, navigateRoute } = props
    const [permission, SetPermission] = React.useState()
    const [member_list, SetMemberList] = React.useState([])
    const [threshold_list, SetThresholdList] = React.useState([])
    const [all_role_list, SetAllRoleList] = React.useState([])
    const [role_by_id, SetRoleById] = React.useState()
    const [role_related_competency, SetRoleRelatedCompetency] = React.useState()
    const [is_loading, SetLoader] = React.useState(false)
    const [existing_category, SetExistingCategory] = React.useState([])

    //Permission Check
    React.useEffect(() => {
        if (userPermission?.length) {
            let permission = globalPermissionValidator(competency_assessment_permission, userPermission)
            SetPermission(permission)
        }
    }, [userPermission])

    React.useEffect(() => {
        if (permission?.required_permission) {
            getAllMemberList()
            getThresholdList()
            getAllRoleList()
        }
    }, [permission])

    React.useEffect(() => {
        if (me) {
            if (!me?.employee?.orgCeoEmployee?.totalCount &&
                !me?.employee?.orgPocEmployee?.totalCount &&
                !me?.employee?.teamManagerEmployee?.totalCount &&
                !me?.employee?.verticalHeadEmployee?.totalCount) {
                navigateRoute("-1")
            }
        }
    }, [me])
    
    React.useEffect(() => {
        if (role_related_competency) {
            let category = []
            category = category.concat(role_related_competency?.functional_competency?.good_to_have?.map(({ main_category }) => main_category?.id))
            category = category.concat(role_related_competency?.functional_competency?.must_have?.map(({ main_category }) => main_category?.id))
            category = category.concat(role_related_competency?.generic_competency?.map(({ main_category }) => main_category?.id))
            category = category.concat(role_related_competency?.other_competency?.map(({ main_category }) => main_category?.id))
            SetExistingCategory([].concat(category))
        }
    }, [role_related_competency])

    React.useEffect(() => {
        if (threshold_list?.length && role_by_id) {
            handleCompetencysAndBehaviourData(threshold_list, role_by_id)
        }
    }, [role_by_id, threshold_list])

    // to handle the change in bars data when user selection changes
    const handleCompetencysAndBehaviourData = (threshold, role) => {

        let job_family_competency_set = role?.jobfamilySet?.edges?.map(({ node }) => node?.genericCompetency?.edges)
        let generic_competency_list = [].concat.apply([], job_family_competency_set)?.map(({ node }) => node)
        let req_functional_competency_list = role?.requiredSpecificCompetency?.edges?.map(({ node }) => node)
        let opt_functional_competency_list = role?.optionalSpecificCompetency?.edges?.map(({ node }) => node)

        // handle must have functional competency
        let must_have_category = req_functional_competency_list?.map(item => item?.competencyCategory?.id)
        must_have_category = [...new Set(must_have_category)]
        let must_have_behaviour = must_have_category?.map(cat_id => {
            return {
                main_category: req_functional_competency_list?.find(sub_comp => sub_comp?.competencyCategory?.id === cat_id)?.competencyCategory,
                sub_comptency: req_functional_competency_list?.filter(sub_comp => sub_comp?.competencyCategory?.id === cat_id)
            }
        })
        // handle good to have functional competency
        let good_to_have_category = opt_functional_competency_list?.map(item => item?.competencyCategory?.id)
        good_to_have_category = [...new Set(good_to_have_category)]
        let good_to_have_behaviour = good_to_have_category?.map(cat_id => {
            return {
                main_category: opt_functional_competency_list?.find(sub_comp => sub_comp?.competencyCategory?.id === cat_id)?.competencyCategory,
                sub_comptency: opt_functional_competency_list?.filter(sub_comp => sub_comp?.competencyCategory?.id === cat_id)
            }
        })

        // handle generic comepetency 
        let generic_category = generic_competency_list?.map(item => item?.competencyCategory?.id)
        generic_category = [...new Set(generic_category)]
        let generic_behaviour = generic_category?.map(cat_id => {
            return {
                main_category: generic_competency_list?.find(sub_comp => sub_comp?.competencyCategory?.id === cat_id)?.competencyCategory,
                sub_comptency: generic_competency_list?.filter(sub_comp => sub_comp?.competencyCategory?.id === cat_id)
            }
        })
        let role_related_competency = {
            functional_competency: {
                must_have: must_have_behaviour,
                good_to_have: good_to_have_behaviour
            },
            generic_competency: generic_behaviour,
            other_competency: []
        }
        SetRoleRelatedCompetency(role_related_competency)
        SetLoader(false)
    }

    //Get Threshold List
    const getThresholdList = async (filter) => {
        const { data } = await client.query({
            query: RATING_SCALES,
            fetchPolicy: 'network-only',
            variables: { ...filter }
        });
        if (data?.ratingScales) {
            let list = data?.ratingScales?.edges?.map(({ node }, index) => { return { ...node, sequence: index + 1 } })
            SetThresholdList(list)
        }
    }

    //Get role List
    const getAllRoleList = async (filter) => {
        const { data } = await client.query({
            query: ALL_ROLE_QUERY,
            fetchPolicy: 'network-only',
            variables: { ...filter, first: 25 }
        });
        if (data?.allRoles) {
            let list = data?.allRoles?.edges?.map(({ node }) => node)
            SetAllRoleList(list)
        }
    }

    //Get Member List
    const getAllMemberList = async (filter) => {
        const { data } = await client.query({
            query: ALL_MEMBER_LIST,
            fetchPolicy: 'network-only',
            variables: { ...filter, first: 25 }
        });
        if (data?.members) {
            let data_list = data?.members?.edges?.map(({ node }) => node)
            console.log("data_list", data_list)
            SetMemberList(data_list?.filter(item => item?.toEmployee?.id !== me?.employee?.id))
        }
    }

    const getRoleDetailByid = async (id, employeeLevel) => {
        SetLoader(true)
        const { data } = await client.query({
            query: ROLE_BY_ID_QUERY,
            fetchPolicy: 'network-only',
            variables: { id, employeeLevel }
        });
        if (data?.roleById) {
            SetRoleById(data?.roleById)
        }
    }

    const addOtherCompetency = (data) => {
        let related_competency = role_related_competency
        let other_competency = related_competency?.other_competency || []
        other_competency.push({
            main_category: data,
            sub_comptency: data?.competencySet?.edges?.map(({ node }) => node) || []
        })
        let role_data = {
            functional_competency: role_related_competency?.functional_competency,
            generic_competency: role_related_competency?.generic_competency,
            other_competency: other_competency
        }
        SetRoleRelatedCompetency({ ...role_data })
    }

    // data handling for assessment mutation
    const OnSubmitAssessment = async (data, role_detail, employee_detail, employee_email) => {
        if (!employee_detail && !employee_email) {
            message.destroy()
            message.error('Employee detail missing')
            return
        }
        if (employee_email && !employee_email?.includes('@')) {
            message.destroy()
            message.error('Invalid email')
            return
        }
        let active_list = data?.filter(i => i?.bar?.id)

        let assessmentData = {
            roleId: role_detail?.id,
            employeeLevelId: role_detail?.employeeLevel?.id,
            competencyAssessments: competencyAssessments(active_list)
        }
        if (employee_detail) {
            assessmentData['employeeId'] = employee_detail?.toEmployee?.id
        } else {
            assessmentData['employeeEmail'] = employee_email
        }
        try {
            const response = await createAssessment({ assessmentData })
            if (response?.id) {
                SetLoader(false)
                history.push(ca_route?.competency_assessment_home)
            } else {
                SetLoader(false)
            }
        } catch (error) {
            SetLoader(false)
        }


    }

    const competencyAssessments = (data) => {

        return data?.map(i => {
            let assessmentBARS = [{
                behaviourallyAnchoredRatingId: i?.bar?.id,
                qualifyingLevelId: i?.threshold?.id,
                selected: true
            }]
            let non_selected_assessment_data = i?.behaviourallyanchoredratingSet?.filter(bars => bars?.ratingScale?.id != i?.threshold?.id)?.map(x => {
                return {
                    behaviourallyAnchoredRatingId: x?.id,
                    qualifyingLevelId: x?.ratingScale?.id,
                    selected: false
                }
            })
            assessmentBARS = assessmentBARS.concat(non_selected_assessment_data)
            let competency_assessments_data = {
                assessmentBARS
            }
            if (i?.type == "competency") {
                competency_assessments_data['mainCompetencyId'] = i?.key?.split('_')[0]
            } else {
                competency_assessments_data['mainCompetencyId'] = i?.parent_key?.split('_')[0]
                competency_assessments_data['behaviourId'] = i?.key?.split('_')[0]
            }
            return competency_assessments_data
        })

    }

    return (
        <>
            {(permission && permission?.required_permission) && (
                <CompetencyAssessmentCreateView
                    {...props}
                    permission={permission}
                    member_list={member_list}
                    is_loading={is_loading}
                    getRoleDetailByid={getRoleDetailByid}
                    getAllMemberList={getAllMemberList}
                    threshold_list={threshold_list}
                    role_related_competency={role_related_competency}
                    addOtherCompetency={addOtherCompetency}
                    existing_category={existing_category}
                    SetRoleRelatedCompetency={SetRoleRelatedCompetency}
                    SetRoleById={SetRoleById}
                    all_role_list={all_role_list}
                    getAllRoleList={getAllRoleList}
                    OnSubmitAssessment={OnSubmitAssessment}
                />
            )}

            {(permission && !permission?.required_permission) && (<NoPermissionView />)}
            {
                !permission && (
                    <div className="role-no-permission-container">
                        <Spin spinning={true} size="large"></Spin>
                    </div>
                )
            }
        </>
    )

}

export default compose(withApollo, withCreateCompetencyAssessment)(CompetencyAssessmentCreateContainer)