// @ts-nocheck
import React from 'react';
import { Row,Dropdown,Menu,Input, Button } from "antd";
import search_icon from '../../assets/search-gray-icon.svg'
import dropdown_Arrow from '../../assets/dropdown_Arrow.svg'
import styled from 'styled-components';
import { competency_assessment_home_icon } from '../icon';
import { CloseCircleFilled } from "@ant-design/icons";

const CompetencySearchView =(props)=>{
    const {
        filterData,
        onfilterChange
    } = props; 
    
    const [selectedFilter,setFilter]=React.useState()
    const [type,setType]=React.useState()
    const [select_default,setSelectDefault]=React.useState(false)
    const [show_close,setShowClose]=React.useState(false)
    const [search_value,setSearchValue]=React.useState()

    const timeout_ref =React.useRef()

    React.useEffect(()=>{
        if(filterData&&!selectedFilter){
           let find_value = filterData?.find(Item=>Item.default===true)
           if(find_value) {
            setFilter(find_value)
           }
           else{
            setSelectDefault(true)
           }
           
        }
    },[filterData])

    const filterValueChange=(value)=>{
      setSearchValue(value)
      if(value){
        setShowClose(true)
      }
      else{
        setShowClose(false)
      }
        let filter ={}
        filter[selectedFilter?.key]=value
        clearTimeout(timeout_ref.current)

        timeout_ref.current = setTimeout(()=>{
          onfilterChange(filter)
        },600)
       
    }

    const onClearFilter = ()=>{
      setShowClose(false)
      let find_value = filterData?.find(Item=>Item.default===true)
      if(find_value) {
       setFilter(find_value)
      }
      filterValueChange('')
    }

    //Filter choices render
    const Filtertype =(choice)=> (
        <Menu style={{maxHeight:'200px',overflow:'auto'}} suffixIcon={<img src={dropdown_Arrow} alt="" style={{width:"10px",height:"10px"}}/>}>
          {
           choice && (
            choice?.map((data)=>
              <Menu.Item className='ca-search-menu-title' style={{color:selectedFilter?.label === data?.label ? '#4CCB1F':'#BBBBBB'}} key={data?.key} onClick={()=>{filterValueChange(data?.key);setType(data?.label)}}>
                {data?.label}
              </Menu.Item>
              )
            )
          }
        </Menu>
      );

    // Filter type category
    const FilterType =(
        <div>
            {(selectedFilter?.ui_flow==='input_box'||select_default) && (
                <div style={{position:"relative"}}>
                    <Input
                    className='search-input-filter'
                    value={search_value}
                    onChange={e => (filterValueChange(e.target.value))}   
                    autoComplete='off'
                    style={{width:"18em",borderRight:"1px solid #A9A9A9"}}
                    />
                    <img src={search_icon} alt="" style={{position:'absolute',left:"10px",width:"20px",top:"13px"}} />
                    {show_close&&<CloseCircleFilled style={{position:'absolute',right:"10px",fontSize:"20px",top:"13px",cursor:"pointer",color:"#D3D3D3"}}
                     onClick={()=>onClearFilter()}/>}
                </div>
            )}
            {
                (selectedFilter?.ui_flow==='dropdown') && (
                    <Dropdown overlay={Filtertype(selectedFilter?.choice)} overlayStyle={{zIndex:"1050"}} className="rd-input-filter-new">
                    <button  className='ca-dropdown-btn'>
                        <p className='ca-search-menu-title' style={{marginTop:'13px',width:'100%',overflow:'hidden',textOverflow:'ellipsis',textAlign:'left'}}>{type?type:'Select'}</p>
                        <img src={competency_assessment_home_icon?.dropdown_Arrow} alt="" style={{ width: "10px", height: "10px" }} />
                    </button>
                </Dropdown>)
            }
        </div>
    )

    //Filter menu
    const Filtermenu =()=> (
        <Menu style={{maxHeight:'200px',overflow:'auto'}} suffixIcon={<img src={dropdown_Arrow} alt="" style={{width:"10px",height:"10px"}}/>}>
          {
            filterData && (
                filterData?.map((data)=>
              <Menu.Item className='ca-search-menu-title' style={{color:selectedFilter?.label === data?.label ? '#4CCB1F':'#BBBBBB'}} key={data?.key} onClick={()=>{setFilter(data)}}>
                {data?.label}
              </Menu.Item>
              )
            )
          }
        </Menu>
      );
    

    return (
        <>
        <Row style={{flexWrap:"nowrap"}}>
            <div>
              {FilterType}
            </div>
            <Dropdown overlay={Filtermenu} overlayStyle={{zIndex:"1050"}} className="rd-input-filter" >
                <DropdownButton style={{borderLeft:'1px solid #D9D9D9'}} style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                    <p className="ca-search-menu-title"style={{margin:"0px",width:'100%',overflow:'hidden',textOverflow:'ellipsis',textAlign:'left'}}>{selectedFilter?.label?selectedFilter?.label: <p className='rd-search-drop-placeholder'>Filter</p>}</p>
                    <img src={competency_assessment_home_icon?.dropdown_Arrow} alt="" style={{ width: "10px", height: "10px" }} />
                </DropdownButton>
            </Dropdown>
        </Row>
        </>
    )


}

export default CompetencySearchView

const DropdownButton = styled(Button)`
width:fit-content;
min-width:10em;
display:flex;
height:36px;
border-left:none;
justify-content:space-between;
align-items:center;
gap:5px
`