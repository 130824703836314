import gql from 'graphql-tag';
import { TAG_TYPE } from './TagType.gql';

export const EDIT_TAG = gql`
  ${TAG_TYPE}
  mutation editTag($description: String, $id: ID!, $name: String) {
    updateTag(description: $description, id: $id, name: $name) {
      tag {
        ...TagType
      }
    }
  }
`;
