import React from "react";
import { compose } from "../../core";
import { withApollo } from '@apollo/client/react/hoc';
import BarsDefineView from "../Components/BarsDefineView";
import { ALL_COMPETENCY_CATEGORIES } from "../graphql/DefineBarsQueries.gql";
import { Row, Spin } from "antd";
import NoPermissionView from "modules/kudos-user/component/NoPermissionComponent";
import { globalPermissionValidator } from "modules/look";
import { bars_permission } from "Permissions/bars.permission";




const BarsDefineContainer = (props) => {

    const { client, me, permissionLoader, userPermission} = props;
    const [CompetenyCaetgoriesLoading, setCompetencyCategoryLoading] = React.useState(false)
    const [competencyCategories, setCompetencyCategories] = React.useState(undefined);
    const [barsPermission, setBarsPermission] = React.useState(undefined);




    React.useEffect(() => {

        // based on permission of user bars will be di
        if (barsPermission?.required_permission ) {
            getCompetencies({organization:me?.employee?.organizationSet?.edges[0]?.node?.id});
        }
    
      }, [barsPermission])

      
    
        React.useEffect(() => {
            if ( userPermission?.length) {

                let permission = globalPermissionValidator(bars_permission, userPermission);
                setBarsPermission(permission)
            }
        }, [userPermission])




    const getCompetencies = async (filter) => {
        setCompetencyCategoryLoading(true)
        const { data } = await client.query({
            query: ALL_COMPETENCY_CATEGORIES,
            fetchPolicy: 'network-only',
            variables: { ...filter }
        });
        if (data?.allCompetencyCategory) {
          
            let filteredList = data?.allCompetencyCategory?.edges?.filter((value)=>!value?.node?.behaviourallyanchoredratingSet?.totalCount)

            let list = filteredList.map(({ node }) => {
                return {
                    ...node
                }
            })
            setCompetencyCategories(list)
            setCompetencyCategoryLoading(false)
        }
    }


    return (
        <>
          


            <Row justify='center' align='middle'>
                {
                    <Spin spinning={permissionLoader} wrapperClassName ='permission-check-bars'>
                        <div>
                            {
                                barsPermission !== undefined &&
                                <div>
                                    {
                                        barsPermission?.required_permission ?
                                        <BarsDefineView
                                        CompetenyCaetgoriesLoading = {CompetenyCaetgoriesLoading}
                                        competencyCategories={competencyCategories}
                                        {...props}
                                    />
                                            :
                                            <NoPermissionView />
                                    }

                                </div>
                            }
                        </div>
                    </Spin>
                }
            </Row>
        </>
    );
};

export default compose(withApollo)(BarsDefineContainer);
