import React from 'react';
import { Row, Spin,Button} from "antd";
import { displayUtcToUserTimezone, getIntFromString } from '../../look/components/functions'
import { Link} from 'react-router-dom';
import { CustomProgress } from '../../look-v2';
import Green_Tick from '../../assets/green-tick.svg'
import Red_close from '../../assets/red-close-icon.svg'
import DiscardActionView from './DiscardActionView';
import ShowContributors from '../formComponents/showContributers';
import new_back_arrow from '../../assets/new_back_arrow.svg';
import okr_route from '../route';
import '../OkrStyles/OkrRequestsPanelStyles.css'
const DiscardRequestDetailView = ( props ) => {
    const { discardData,loading, me, request_deleted, navigateRoute,app_sidebar_expand} = props
    const [ showDetail, setShowDetail ] = React.useState( false )
    const [ openModal, setOpenModal ] = React.useState( false )
    const [ selectedType, seSelectedType ] = React.useState()
    const action = ( value ) => {
        if ( value ) {
            seSelectedType( value )
        }
    }
    return (
        <>
            <div className="requst-detail-margin">
                <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'start', alignItems: "center", gap: "1em" }}>
                    <img src={new_back_arrow} style={{ width: "20px", height: "20px", cursor: "pointer", marginLeft: '7px' }} onClick={() => { navigateRoute( okr_route.discardRequest ) }} alt="" />
                    <h4 className="coaching-engagement-page-title"style={{cursor:"pointer"}}onClick={() => { navigateRoute( okr_route.discardRequest ) }}>Discard Request</h4>
                </div>
                <div style={{ background: "#fff", width: '100%', borderRadius: "2.5em", overflow: "auto", height: "77.5vh", marginTop: "1em", display: "flex", flexDirection: "column",position: "relative", padding: discardData?.status === "PENDING" ? '2em 2em 120px 2em' : '2em 2em',borderBottomLeftRadius:"0px",borderBottomRightRadius:"0px" }}>
                    {
                        !request_deleted ? <Spin spinning={loading} size="large">
                                <h5 className='objective-title' style={{ textAlign: "center",margin:"0px"}}>{discardData?.okr?.title}</h5>
                                <Row align='center' style={{ justifyContent: 'center', gap: "30px", width: "100%" }}>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: 'center' }}>
                                        <p className='discard-small-head'>Status</p>
                                        {discardData?.status === "APPROVED" && <p className="discard-small-data" style={{ color: "#4CCB1F", display: "flex", flexDirection: "row", flexWrap: "nowrap", justifyContent: 'center', alignItems: 'center', gap: "10px" }}>
                                            <div className='dot-status' style={{ background: "#4CCB1F" }}></div>Approved</p>}
                                        {discardData?.status === "DISAPPROVED" && <p className="discard-small-data" style={{ color: "#E86161", display: "flex", flexDirection: "row", flexWrap: "nowrap", justifyContent: 'center', alignItems: 'center', gap: "10px" }}>
                                            <div className='dot-status' style={{ background: "#E86161" }}></div> Disapproved</p>}
                                        {discardData?.status === "PENDING" && <p className="discard-small-data" style={{ color: "#CB861F", display: "flex", flexDirection: "row", flexWrap: "nowrap", justifyContent: 'center', alignItems: 'center', gap: "10px" }}>
                                            <div className='dot-status' style={{ background: "#CB861F" }}></div>Pending</p>}
                                    </div>
                                    {discardData?.status !== "PENDING" && <div style={{ display: "flex", flexDirection: "column", justifyContent: 'center' }}>
                                        <p className='discard-small-head'>Resolved on</p>
                                        <h4 className='discard-small-data'>{displayUtcToUserTimezone( discardData?.actedOn, 'DD MMM YYYY' )}</h4>
                                    </div>}
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: 'center' }}>
                                        <p className='discard-small-head'>Created on</p>
                                        <h4 className='discard-small-data'>{displayUtcToUserTimezone( discardData?.createdAt, 'DD MMM YYYY' )}</h4>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: 'center' }}>
                                        <p className='discard-small-head'>Created by</p>
                                        <h4 className='discard-small-data'>{discardData?.initiator?.user?.firstName} {discardData?.initiator?.user?.lastName}</h4>
                                    </div>
                                </Row>

                                <div style={{ padding: '0em 4.5em 0px 4.5em' }}>

                                    {discardData?.actorsComment && <div className='comment-card' style={{ justifyContent: 'start' }}>
                                        <h3 className='discard-subhead'>Comment</h3>
                                        <p className='discard-content'>{discardData?.actorsComment}</p>
                                    </div>}
                                    {discardData?.initiatorsRational && <div className='rational-div' style={{ justifyContent: 'start' }}>
                                        <h3 className='discard-subhead'>Rationale</h3>
                                        <p className='discard-content'>{discardData?.initiatorsRational}</p>
                                    </div>}
                                    <div className='details-div' style={{ width: "100%", marginTop: "0px !important" }}>
                                        {showDetail && <h3 className='discard-subhead' style={{ color: '#009AF1', fontWeight: "400", textDecoration: "underline", cursor: "pointer", textAlign: "left" }} onClick={() => setShowDetail( false )}>Hide Details</h3>}
                                        {!showDetail && <h3 className='discard-subhead' style={{ color: '#009AF1', fontWeight: "400", textDecoration: "underline", cursor: "pointer", textAlign: "left" }} onClick={() => setShowDetail( true )}>Show Details</h3>}
                                    </div>
                                    {showDetail && <Row align='middle' style={{ display: "flex", flexDirection: "column", justifyContent: 'center', marginTop: "20px", width: "100%" }}>
                                        <div className='detail-div'>
                                            <Row align='center' style={{ justifyContent: "center", alignItems: "center", gap: "20px" }}>
                                                <h5 className='objective-title'>{discardData?.okr?.title}</h5>
                                                {discardData?.okr?.okrType === 'OBJECTIVE' ? <Link to={`/role/objective-keyresult/view/${getIntFromString( discardData?.okr?.id )}`} target="_blank">
                                                    <h3 className='discard-subhead' style={{ color: '#009AF1', fontWeight: "400", textDecoration: "underline", cursor: "pointer", paddingTop: '20px' }} >See Details</h3>
                                                </Link> :
                                                    discardData?.okr?.okrType === 'KEY_RESULT' ? <Link to={`/role/objective-keyresult/keyresult/view/${getIntFromString( discardData?.okr?.id )}`} target="_blank">
                                                        <h3 className='discard-subhead' style={{ color: '#009AF1', fontWeight: "400", textDecoration: "underline", cursor: "pointer", paddingTop: '20px' }} >See Details</h3>
                                                    </Link> :
                                                        <Link to={`/role/objective-keyresult/milestone/view/${getIntFromString( discardData?.okr?.id )}`} target="_blank">
                                                            <h3 className='discard-subhead' style={{ color: '#009AF1', fontWeight: "400", textDecoration: "underline", cursor: "pointer", paddingTop: '20px' }} >See Details</h3>
                                                        </Link>
                                                }
                                            </Row>
                                            <Row align='middle' justify='center' style={{ alignItems: 'center', gap: "30px", width: "100%", justifyContent: 'space-between', padding: "20px" }}>
                                                <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: 'center', gap: "10px" }}>
                                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: 'center', width: "100%" }}>
                                                        <p className='discard-small-head'>Owner(s)</p>
                                                        <h4 className='discard-small-data' style={{ textOverflow: "ellipsis", overflow: "hidden", width: "100%", whiteSpace: "nowrap" }}>{discardData?.okr?.owner?.user?.firstName} {discardData?.okr?.owner?.user?.lastName}</h4>
                                                    </div>
                                                    <div style={{ paddingTop: "50px" }}>
                                                        <ShowContributors dataList={discardData?.okr?.contributors?.edges} />
                                                    </div>
                                                </div>
                                                <div style={{ display: "flex", flexDirection: "column", justifyContent: 'center', minWidth: "180px" }}>
                                                    <p className='discard-small-head'>Progress</p>
                                                    <div style={{ paddingTop: "20px", justifyContent: 'start' }}>
                                                        <CustomProgress progress={discardData?.okr?.progress} info={true} />
                                                    </div>
                                                </div>
                                                <div style={{ display: "flex", flexDirection: "column", justifyContent: 'center' }}>
                                                    <p className='discard-small-head'>Due</p>
                                                    <h4 className='discard-small-data'>{displayUtcToUserTimezone( discardData?.okr?.duedate, 'DD MMM YYYY' )}</h4>
                                                </div>
                                            </Row>
                                        </div>
                                    </Row>}
                                </div>
                        </Spin> :
                            <div className='okr-discard-request-delete-container'>
                                <h4 className='discard-request-delete-warning'>This discard request has been deleted by the user</h4>
                            </div>
                    }
                    {openModal && <DiscardActionView visible={openModal} type={selectedType} onClose={( e ) => setOpenModal( e )} discardData={discardData} />}
                </div>
            </div>
            {me?.employee?.user?.id !== discardData?.initiator?.user?.id && discardData?.status === "PENDING" &&!request_deleted&&
                
                <div className={`discard-botton-div ${app_sidebar_expand ? 'discard-div-margin-left' : ''}`}>
                    <Row align='center' justify='center' style={{ gap: "20px", display: "flex", flexDirection: "row", justifyContent: "center center", alignItems: "center" }}>
                        <Button className='approve-btn' onClick={() => { action( "approve" ); setOpenModal( true ) }}><img style={{ width: "20px" }} src={Green_Tick} alt="" /><span style={{ paddingLeft: "5px" }}>Approve</span></Button>
                        <Button className='disapprove-btn' onClick={() => { action( "disapprove" ); setOpenModal( true ) }}><img src={Red_close} alt="" /><span style={{ paddingLeft: "5px" }}>Disapprove</span></Button>
                    </Row>
                </div>
            }
        </>
    )
}
export default DiscardRequestDetailView