import { PlusOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Col, Divider, message, Row, Space, Spin } from 'antd';
import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import DeleteIcon from './DeleteIcon';

const RenderTableWithLayout = props => {
  const {
    history,
    title,
    loading,
    tableData,
    columns,
    onDelete,
    addLink,
    handleDrawer,
    showBack = false,
    extra,
    onFiltersRemove,
    onPaginationChange,
    rowKey = 'id',
    editLink,
    components,
    onSort,
    selectedRowKeys,
    children,
    specificMutation ,
      handleDeleteRecord 
  } = props;
  const [deleteLoading, setDeleteLoading] = React.useState(false);
  const isUser = Boolean(addLink || editLink);
  const handleFilterRemove = React.useRef(() => {});

  handleFilterRemove.current = () => {
    onFiltersRemove && onFiltersRemove();
  };

  React.useEffect(() => {
    return () => handleFilterRemove.current();
  }, []);

  const handleDelete = async () => {
    
    setDeleteLoading(true);
    try {
      if(specificMutation){
  
        await Promise.all(
          selectedRowKeys.map(async i => {
            await handleDeleteRecord(i);
          })
        );
        message.success('Deleted!');
      }else{
        await Promise.all(
          selectedRowKeys.map(async i => {
            await onDelete(i);
          })
        );
        message.success('Deleted!');
      }
     
    } catch (e) {
      
      if(e.message.includes("Cannot delete some instances of model 'Organization'")){
        message.error("Cannot delete this 'Organization' because they are referenced to Some Verticals");
      }
      else{
        message.error( 'Failed, try again!' );
      }
      
    }
    setDeleteLoading(false);
  };
  return (
    <Spin spinning={deleteLoading} size="large">
      <Row>
        <Col xs={17} lg={extra ? 14 : 22}>
          <Space>
            <Breadcrumb separator="">
              {title.length ? (
                title.length > 0 &&
                title.map((t, index) => (
                  <>
                    <Breadcrumb.Item style={{ fontSize: 20, fontWeight: 'bold' }}>
                      <div>{t.name}</div>
                    </Breadcrumb.Item>
                    {title.length !== index + 1 && <Breadcrumb.Separator>{'>'}</Breadcrumb.Separator>}
                  </>
                ))
              ) : (
                <Breadcrumb.Item>{title}</Breadcrumb.Item>
              )}
            </Breadcrumb>
            <Divider type="vertical" />
            <Button disabled={!addLink} size="medium" type="primary">
              <Link to={addLink}>
                <PlusOutlined />
                Add {title[0].name}
              </Link>
            </Button>
          </Space>
        </Col>
        <Col lg={0} md={24} xs={24}>
          <br />
        </Col>
        <Col lg={extra ? 7 : 0} md={14} xs={24} align="left">
          {extra}
        </Col>
        <Col lg={{ span: 2, offset: extra ? 1 : 0 }} md={2} xs={2} align="left">
          <DeleteIcon
            size="md"
            disabled={!(onDelete && selectedRowKeys && selectedRowKeys.length > 0)}
            onClick={handleDelete}
          />
        </Col>
        <Col span={24}>
          <Divider style={{ marginTop: '10px' }} />
        </Col>
      </Row>
      <Row>
        <Col lg={24} xs={23}>
          {children}
        </Col>
      </Row>
    </Spin>
  );
};

export default withRouter(RenderTableWithLayout);
