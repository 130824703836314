import React from 'react';
import { Result, Button } from 'antd';
import HOME_ROUTE from '../../home/route';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
  }

  render() {
    if (!this.state.error) {
      return this.props.children;
    } else {
      return (
        <div className={'HVCenter'}>
          <Result
            status="500"
            title="500"
            subTitle="Sorry, something went wrong."
            extra={
              <Button href={HOME_ROUTE.home} type="primary">
                Back Home
              </Button>
            }
          />
        </div>
      );
    }
  }
}

export default ErrorBoundary;
