import gql from 'graphql-tag';

export const CREATE_NOTIFICATION_MUTATION = gql`
  mutation createNotification($notificationData: [NotificationInput]!) {
    createNotification(
        notificationData:$notificationData
        ) {
        notifications{
            id
        }
    }
  }
`;
