import gql from 'graphql-tag';
import { GROUP_TYPE } from './GroupType.gql';

export const SURVEY_QUESTION_GRPOUP_SUBSCRIPTION = gql`
  ${GROUP_TYPE}
  subscription surveyQuestionGroupSubscription {
    surveyQuestionGroupSubscription {
      mutation
      group {
        ...GroupType
      }
    }
  }
`;
