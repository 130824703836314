import gql from 'graphql-tag';


export const CREATE_1_1_MUTATION = gql`
  mutation createMeeting($members:[Int]!,$meetingData: MeetingInput!) {
    createMeeting(
        meetingData:$meetingData
        members:$members,
        ) {
        meeting {
         id
      }
    }
  }
`;
