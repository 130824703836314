// @ts-nocheck
import React from "react";
import { compose } from "modules/core";
import JobFamilyAddEditView from "../components/JobFamilyAddEditView";
import { withCreateJobFamily, withUpdateJobFamily } from "../RoleDefinitionOperation";
import { COMPETENCY_LIST_JOBFAMILY, JOB_FAMILY_BY_ID, ROLES_LIST_JOBFAMILY } from "../graphql/JobFamilyQuries.gql";
import { getIntFromString } from "modules/look";

const JobFamilyAddEditContainer = (props) => {

    const { current_jobfamily_tab,SetJobFamilyTab,history,createJobFamily,permission,client,updateJobFamily} = props
    const [submit_loader,SetSubmitLoader] = React.useState(false)
    const [role_list,SetRoleList] = React.useState([])
    const [generic_competency_list,SetGenericCompetencyList] = React.useState([])
    const [jobfamily_detail,SetJobFamilyDetail] = React.useState()

    React.useEffect(()=>{
        if(permission?.required_permission){
            getRoleList()
            genericCompetencyList()
        }
    },[permission])

    React.useEffect(()=>{
        const cached_navigation = JSON.parse( localStorage?.getItem( 'role_definition_navigation' ) )
        if(current_jobfamily_tab.jobfamily_id){
            if(cached_navigation?.jobfamily_data){
                cachedDataToForm(cached_navigation?.jobfamily_data)
            }else{
                getjobFamilyDetail(current_jobfamily_tab.jobfamily_id)
            }
            
        }else{
            if(cached_navigation?.jobfamily_data){
                cachedDataToForm(cached_navigation?.jobfamily_data)
            }else{
                SetJobFamilyDetail({})
            }
            
        }
    },[current_jobfamily_tab])

    const cachedDataToForm=(data)=>{
        SetJobFamilyDetail( {...data,id:current_jobfamily_tab.jobfamily_id} )
        SetSubmitLoader(false)
    }

     // get jobfamily detail 
     const getjobFamilyDetail = async ( id ) => {
        SetSubmitLoader(true)
        const { data } = await client.query( {
            query: JOB_FAMILY_BY_ID,
            variables: {id},
            fetchPolicy: 'network-only'
        } );
        if ( data?.jobFamilyById ) {
            let details = {
                id:data?.jobFamilyById?.id,
                title:data?.jobFamilyById?.title,
                description:data?.jobFamilyById?.description,
                role:data?.jobFamilyById.role?.edges.map(({node})=>{ return {...node,label:node?.title}}) || [],
                generic_competency:data?.jobFamilyById.genericCompetency?.edges.map(({node})=>{ return {...node,label:node?.title}}) || [],
            } 
            SetJobFamilyDetail( details )
            SetSubmitLoader(false)
        }
    }

    // get role list 
    const getRoleList = async ( filter ) => {
        const { data } = await client.query( {
            query: ROLES_LIST_JOBFAMILY,
            variables: filter,
            fetchPolicy: 'network-only'
        } );
        if ( data?.allRoles ) {
            SetRoleList(data?.allRoles?.edges?.map(({node})=>node))
        }else{
            SetRoleList([])
        }
    }

     // get competency list 
     const genericCompetencyList = async ( filter ) => {
        const { data } = await client.query( {
            query: COMPETENCY_LIST_JOBFAMILY,
            variables: {...filter,competencyType:"Generic"},
            fetchPolicy: 'network-only'
        } );
        if ( data?.allCompetencies ) {
            SetGenericCompetencyList(data?.allCompetencies?.edges?.map(({node})=>node))
        }else{
            SetGenericCompetencyList([])
        }
    }

    /* ON Cancel Navigations */
    const navigation = (view) => {
        let cached_navigation = JSON.parse( localStorage?.getItem( 'role_definition_navigation' ) )
        delete cached_navigation?.child_route?.jobfamily_id
        delete cached_navigation.jobfamily_data
        localStorage.setItem('role_definition_navigation', JSON.stringify(cached_navigation))
        if (current_jobfamily_tab?.jobfamily_id) {
            history.goBack()
        }
        else {
            SetJobFamilyTab({ key: view })
        }
    }

    // submit the form to backend by checking if its a update or create
    const submitResponse=async(data)=>{
      try {
        SetSubmitLoader(true)
        if(current_jobfamily_tab?.jobfamily_id){
            let update_Detail = {
                genericCompetency:data.genericCompetency,
                role:data.role,
                ...data?.jobFamilyData,
                id:getIntFromString(current_jobfamily_tab?.jobfamily_id)
            }
            let response = await updateJobFamily(update_Detail)
            if(response?.id){
                let cached_navigation = JSON.parse( localStorage?.getItem( 'role_definition_navigation' ) )
                delete cached_navigation?.child_route?.jobfamily_id
                delete cached_navigation.jobfamily_data
                localStorage.setItem('role_definition_navigation', JSON.stringify(cached_navigation))
                navigation( 'jobfamily_home_view' )
            }else{
                SetSubmitLoader(false)
            }
        }
        else{ 
            let response = await createJobFamily(data)
            if(response?.id){
                let cached_navigation = JSON.parse( localStorage?.getItem( 'role_definition_navigation' ) )
                delete cached_navigation?.child_route?.jobfamily_id
                delete cached_navigation.jobfamily_data
                localStorage.setItem('role_definition_navigation', JSON.stringify(cached_navigation))
                navigation( 'jobfamily_home_view' )
            }else{
                SetSubmitLoader(false)
            }
        }
      } catch (error) {
        SetSubmitLoader(false)
      }
    }

    return (
        <>
            <JobFamilyAddEditView
                {...props}
                submit_loader={submit_loader}
                onSubmit={(value)=>submitResponse(value)}
                onCancel={(e) => navigation(e)}
                role_list={role_list}
                getRoleList={(filter)=>{getRoleList(filter)}}
                generic_competency_list={generic_competency_list}
                genericCompetencyList={(filter)=>genericCompetencyList(filter)}
                jobfamily_detail={jobfamily_detail}
                SetGenericCompetencyList={SetGenericCompetencyList}
            />
        </>
    )

}

export default compose(withCreateJobFamily,withUpdateJobFamily)(JobFamilyAddEditContainer)