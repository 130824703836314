import gql from "graphql-tag";



export const COMPETECNY_ASSESSMENT_LISTING_QUERY = gql`
query assessments($first: Int,$after: String,$role_Title_Icontains:String,$employeeLevel_Icontains: String,$offset:Int,$employee_User_FirstName_Icontains: String){
  assessments(orderBy: ["-createdAt"],first: $first,after:$after,role_Title_Icontains:$role_Title_Icontains,employeeLevel_Icontains:$employeeLevel_Icontains,offset:$offset,employee_User_FirstName_Icontains:$employee_User_FirstName_Icontains ) {
    pageInfo{
      hasNextPage
      endCursor
  }
  totalCount
    edges {
      node {
        id
        createdAt
        correctionDeadline
        assessedBy {
          id
          user{
            id
            firstName
          }
        }
        employeeEmail
        employee{
          id
          user {
            id
            username
            firstName
            profile {
              id
              profileImage
            }
          }
				}
        employeeLevel
        role {
          id
          title
          requiredSpecificCompetency{
            edges{
              node{
                id
                competencyCategory{
                  id
                }
              }
            }
          }
          optionalSpecificCompetency{
            edges{
              node{
                id
                competencyCategory{
                  id
                }
              }
            }
          }
          jobfamilySet {
            edges {
              node {
                id
                title
                genericCompetency{
                  edges{
                    node{
                      id
                      competencyCategory{
                        id
                      }
                    }
                  }
                }
              }
            }
          }
        }
        competencyassessmentSet {
          edges {
            node {
              id
              competency {
                id
                title
                competencyType
              }
              mainCompetency {
                id
                title
                competencyType
              }
              
              assessmentbarsSet(selected:true) {
                edges {
                  node {
                    id
                    qualifyingLevelName
                    qualifyingLevelSequence
                    qualifyingLevelId
                    behaviourExample
                    selected
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`

