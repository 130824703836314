import React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import gql from 'graphql-tag';
import { Empty, Spin } from 'antd';

import { compose } from '../../../core';
import { getIntFromString } from '../functions';
import { withLookTextSectionFilterUpdating, withLookTextSectionState } from '../../containers/LookOperations';

import SelectField from './SelectField';

export const ALL_OPERAND_QUERY = gql`
  query getAllOperands($first: Int, $orderBy: [String], $note_Icontains: String) {
    getAllOperands(first: $first, orderBy: $orderBy, note_Icontains: $note_Icontains) {
      edges {
        node {
          id
          note
          operation
        }
      }
    }
  }
`;

const OperandAutoComplete = props => {
  const { loading, getAllOperands, margin, onNoteChange, onFiltersRemove, isRefetch, setIsRefetch, refetch } = props;
  const handleFilterRemove = React.useRef(() => {});

  handleFilterRemove.current = () => {
    onFiltersRemove();
  };

  React.useEffect(() => {
    return () => handleFilterRemove.current();
  }, []);

  React.useEffect(() => {
    if (isRefetch) {
      refetch();
      setIsRefetch(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefetch]);

  const choices =
    !loading &&
    getAllOperands &&
    getAllOperands.edges.length > 0 &&
    getAllOperands.edges.map(({ node }) => ({
      label: node.note || 'Note not found.',
      value: getIntFromString(node.id)
    }));

  return (
    <SelectField
      margin={margin}
      showSearch
      choices={choices}
      offset={1}
      style={{ width: '100%', marginBottom: !margin && 0 }}
      // labelInValue
      filterOption={false}
      onSearch={onNoteChange}
      notFoundContent={
        loading ? (
          <div align="center">
            <Spin size="small" />
          </div>
        ) : (
          <Empty />
        )
      }
      {...props}
    />
  );
};

export default compose(
  withLookTextSectionState,
  graphql(ALL_OPERAND_QUERY, {
    options: ({ filter, orderBy }) => {
      return { variables: { ...filter, orderBy }, fetchPolicy: 'network-only' };
    },
    props({ data }) {
      const { loading, error, getAllOperands, subscribeToMore, updateQuery, refetch } = data;
      return { loading, error, getAllOperands, subscribeToMore, updateQuery, refetch };
    }
  }),
  withLookTextSectionFilterUpdating
)(OperandAutoComplete);
