import gql from "graphql-tag";



export const MEETING_TIME_SLOT = gql`
query allMeetings($when_Gte:DateTime,$member:[ID],$endedAt_Isnull:Boolean){
    allMeetings(when_Gte:$when_Gte,member:$member,endedAt_Isnull:$endedAt_Isnull) {
      edges {
        node {
          id
          when
          scheduleDuration
        }
      }
    }
  }
`;