import gql from 'graphql-tag';

export const OKR_CONTRIBUTOR_LIST_QUERY = gql`
query contributors($okr: ID,$role_In: [String]){
    contributors(okr:$okr,role_In:$role_In){
        edges{
            node{
              id
              weight
              role
              employee{
                id
                user{
                  firstName
                  lastName
                  id
                }
              }
              okr{
                id
                title
              }
            }
        }
      }
}
`