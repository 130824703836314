import gql from 'graphql-tag';


export const CREATE_CONVERSATION_MUTATION = gql`
  mutation createCoachingConversation($answers: [AnswerInput]!,$createCoachingData: CoachingConversationInput!,$surveyId: Int!) {
    createCoachingConversation(
        createCoachingData:$createCoachingData
        answers:$answers,
        surveyId:$surveyId
        ) {
        createCoaching {
         id
      }
    }
  }
`;
