import gql from 'graphql-tag';
import { FORM_SECTION_TYPE } from './FormSectionType.gql';

export const FORM_SECTION_TYPE_CONNECTION = gql`
  ${FORM_SECTION_TYPE}
  fragment FormSectionTypeConnection on FormSectionTypeConnection {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...FormSectionType
      }
    }
    totalCount
    edgeCount
  }
`;
