import gql from 'graphql-tag';

export const UPDATE_MUTATION = gql`
mutation updateReadiness($id: ID!, $readinessLevelData: [ReadinessLevelUpdateInput]!){
    updateReadiness(id: $id, readinessLevelData: $readinessLevelData){
      readiness{
        id
      }
    }
  }
`