import gql from 'graphql-tag';

export const ARCHIVE_ACCOUNT_MUTATION = gql`
  mutation archiveAccount($password: String!) {
    archiveAccount(password: $password) {
      success
      errors
    }
  }
`;
