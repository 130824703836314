import gql from "graphql-tag";

export const COMPLETE_SURVEY = gql`
  query surveyResponses(
    $user: ID
    $valuePart1: ID
    $valuePart2: ID
    $strengthsPart1: ID
    $strengthsPart2: ID
    $personalityPart1: ID
    $personalityPart2: ID
    $knowledgePart1: ID
    $knowledgePart2: ID
    $stengthQuestions: [ID]
    $personalityQuestions: [ID]
  ) {
    ValuesPart1: surveyResponses(survey: $valuePart1, user: $user) {
      totalCount
    }
    ValuesPart2: surveyResponses(survey: $valuePart2, user: $user) {
      totalCount
    }
    StrengthsPart1: answers(
      question_In: $stengthQuestions
      response_User: $user
      response_Survey: $strengthsPart1
    ) {
      totalCount
      edges {
        node {
          id
        }
      }
    }
    StrengthsPart2: surveyResponses(survey: $strengthsPart2, user: $user) {
      totalCount
    }
    PersonalityPart1: answers(
      question_In: $personalityQuestions
      response_User: $user
      response_Survey: $personalityPart1
    ) {
      totalCount
      edges {
        node {
          id
        }
      }
    }
    PersonalityPart2: surveyResponses(survey: $personalityPart2, user: $user) {
      totalCount
    }
    knowledgePart1: surveyResponses(survey: $knowledgePart1, user: $user) {
      totalCount
    }
    knowledgePart2: surveyResponses(survey: $knowledgePart2, user: $user) {
      totalCount
    }
  }
`;

export const COMPLETE_SOUL_SURVEY = gql`
  query surveyResponses(
    $user: ID
    $value: ID
    $strength: ID
    $personality: ID
    $knowledge: ID
    $gender: ID
    $HighestDegree: ID
    $YearsOfExperience: ID
    $CurrentNationality: ID
    $diversity: ID
  ) {
    Values: surveyResponses(survey: $value, user: $user) {
      totalCount
    }
    Strength: surveyResponses(survey: $strength, user: $user) {
      totalCount
    }
    Personality: surveyResponses(survey: $personality, user: $user) {
      totalCount
    }
    knowledge: surveyResponses(survey: $knowledge, user: $user) {
      totalCount
    }
    diversity: surveyResponses(
      first: 1
      user: $user
      survey: $diversity
      orderBy: ["-responseDate"]
    ) {
      edges {
        node {
          id
          gender: answerSet(question: $gender) {
            totalCount
          }

          HighestDegree: answerSet(question: $HighestDegree) {
            totalCount
          }

          YearsOfExperience: answerSet(question: $YearsOfExperience) {
            totalCount
          }

          CurrentNationality: answerSet(question: $CurrentNationality) {
            totalCount
          }
        }
      }
    }
  }
`;

export const USER_SOUL_REPORT = gql`
  query me {
    me {
      id
      values: responseSet(survey: "U3VydmV5VHlwZToz", last: 1) {
        edges {
          node {
            id
            answerSet(question_In: [634, 635, 636, 637, 638]) {
              edges {
                node {
                  id
                  question {
                    id
                    sequence
                  }
                  answer
                }
              }
            }
          }
        }
      }
      strength: responseSet(survey: "U3VydmV5VHlwZTo3", last: 1) {
        edges {
          node {
            id
            answerSet(question_In: [619, 620, 621, 622, 623]) {
              edges {
                node {
                  id
                  question {
                    id
                    sequence
                  }
                  answer
                }
              }
            }
          }
        }
      }
      personality: responseSet(survey: "U3VydmV5VHlwZTo4", last: 1) {
        edges {
          node {
            id
            answerSet(question_In: [624, 625, 626, 627, 628]) {
              edges {
                node {
                  id
                  question {
                    id
                    sequence
                  }
                  answer
                }
              }
            }
          }
        }
      }
      knowledge: responseSet(survey: "U3VydmV5VHlwZTo5", last: 1) {
        edges {
          node {
            id
            answerSet(question_In: [629, 630, 631, 632, 633]) {
              edges {
                node {
                  id
                  question {
                    id
                    sequence
                  }
                  answer
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const ALL_EMPLOYEE_SOUL_REPORT = gql`
  query allEmployees($values_answer: [String],$strength_answer: [String],$knowledge_answer: [String],$nameIcontains: String,$first:Int,$cursor: String) {
    allEmployees(nameIcontains:$nameIcontains,first:$first,after:$cursor) {
      pageInfo{
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          user {
            id
            firstName
            lastName
            values: responseSet(survey: "U3VydmV5VHlwZToz", last: 1) {
              edges {
                node {
                  id
                  answerSet(question_In: [634, 635, 636, 637, 638],answer_In:$values_answer) {
                    edges {
                      node {
                        id
                        question {
                          id
                          sequence
                        }
                        answer
                      }
                    }
                  }
                }
              }
            }
            strength: responseSet(survey: "U3VydmV5VHlwZTo3", last: 1) {
              edges {
                node {
                  id
                  answerSet(question_In: [619, 620, 621, 622, 623],answer_In:$strength_answer) {
                    edges {
                      node {
                        id
                        question {
                          id
                          sequence
                        }
                        answer
                      }
                    }
                  }
                }
              }
            }
            personality: responseSet(survey: "U3VydmV5VHlwZTo4", last: 1) {
              edges {
                node {
                  id
                  answerSet(question_In: [624, 625, 626, 627, 628]) {
                    edges {
                      node {
                        id
                        question {
                          id
                          sequence
                        }
                        answer
                      }
                    }
                  }
                }
              }
            }
            knowledge: responseSet(survey: "U3VydmV5VHlwZTo5", last: 1) {
              edges {
                node {
                  id
                  answerSet(question_In: [629, 630, 631, 632, 633],answer_In:$knowledge_answer) {
                    edges {
                      node {
                        id
                        question {
                          id
                          sequence
                        }
                        answer
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const VERTICAL_SOUL_REPORT = gql`
  query verticals($id_In: [String],$values_answer: [String],$strength_answer: [String],$knowledge_answer: [String],$user_FirstName: String,$first:Int,$cursor: String) {
    verticals(id_In: $id_In) {
      edges {
        node {
          id
          employees(user_FirstName:$user_FirstName,first:$first,after:$cursor) {
            pageInfo{
            hasNextPage
            endCursor
            }
            edges {
              node {
                id
                user {
                  id
                  firstName
                  lastName
                  values: responseSet(survey: "U3VydmV5VHlwZToz", last: 1) {
                    edges {
                      node {
                        id
                        answerSet(question_In: [634, 635, 636, 637, 638],answer_In:$values_answer) {
                          edges {
                            node {
                              id
                              question {
                                id
                                sequence
                              }
                              answer
                            }
                          }
                        }
                      }
                    }
                  }
                  strength: responseSet(survey: "U3VydmV5VHlwZTo3", last: 1) {
                    edges {
                      node {
                        id
                        answerSet(question_In: [619, 620, 621, 622, 623],answer_In:$strength_answer) {
                          edges {
                            node {
                              id
                              question {
                                id
                                sequence
                              }
                              answer
                            }
                          }
                        }
                      }
                    }
                  }
                  personality: responseSet(
                    survey: "U3VydmV5VHlwZTo4"
                    last: 1
                  ) {
                    edges {
                      node {
                        id
                        answerSet(question_In: [624, 625, 626, 627, 628]) {
                          edges {
                            node {
                              id
                              question {
                                id
                                sequence
                              }
                              answer
                            }
                          }
                        }
                      }
                    }
                  }
                  knowledge: responseSet(survey: "U3VydmV5VHlwZTo5", last: 1) {
                    edges {
                      node {
                        id
                        answerSet(question_In: [629, 630, 631, 632, 633],answer_In:$knowledge_answer) {
                          edges {
                            node {
                              id
                              question {
                                id
                                sequence
                              }
                              answer
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const TEAM_SOUL_REPORT = gql`
  query Teams($id_In: [String],$values_answer: [String],$strength_answer: [String],$knowledge_answer: [String],$user_FirstName: String,$first:Int,$cursor: String) {
    Teams(id_In: $id_In) {
      edges {
        node {
          id
          employees(user_FirstName:$user_FirstName,first:$first,after:$cursor) {
            pageInfo{
              hasNextPage
              endCursor
            }
            edges {
              node {
                id
                user {
                  id
                  firstName
                  lastName
                  values: responseSet(survey: "U3VydmV5VHlwZToz", last: 1) {
                    edges {
                      node {
                        id
                        answerSet(question_In: [634, 635, 636, 637, 638],answer_In:$values_answer) {
                          edges {
                            node {
                              id
                              question {
                                id
                                sequence
                              }
                              answer
                            }
                          }
                        }
                      }
                    }
                  }
                  strength: responseSet(survey: "U3VydmV5VHlwZTo3", last: 1) {
                    edges {
                      node {
                        id
                        answerSet(question_In: [619, 620, 621, 622, 623],answer_In:$strength_answer) {
                          edges {
                            node {
                              id
                              question {
                                id
                                sequence
                              }
                              answer
                            }
                          }
                        }
                      }
                    }
                  }
                  personality: responseSet(
                    survey: "U3VydmV5VHlwZTo4"
                    last: 1
                  ) {
                    edges {
                      node {
                        id
                        answerSet(question_In: [624, 625, 626, 627, 628]) {
                          edges {
                            node {
                              id
                              question {
                                id
                                sequence
                              }
                              answer
                            }
                          }
                        }
                      }
                    }
                  }
                  knowledge: responseSet(survey: "U3VydmV5VHlwZTo5", last: 1) {
                    edges {
                      node {
                        id
                        answerSet(question_In: [629, 630, 631, 632, 633],answer_In:$knowledge_answer) {
                          edges {
                            node {
                              id
                              question {
                                id
                                sequence
                              }
                              answer
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const All_EMPLOYEE_LIST_FOR_COMPARISON = gql`
  query allEmployees {
    allEmployees {
      edges {
        node {
          id
          user {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`;
export const EMPLOYEE_BY_ID_RESPONSE = gql`
  query employeeById($id: ID!) {
    employeeById(id: $id) {
      id
      user {
        id
        firstName
        lastName
        values: responseSet(survey: "U3VydmV5VHlwZToz", last: 1) {
          edges {
            node {
              id
              answerSet(question_In: [634, 635, 636, 637, 638]) {
                edges {
                  node {
                    id
                    question {
                      id
                      sequence
                    }
                    answer
                  }
                }
              }
            }
          }
        }
        strength: responseSet(survey: "U3VydmV5VHlwZTo3", last: 1) {
          edges {
            node {
              id
              answerSet(question_In: [619, 620, 621, 622, 623]) {
                edges {
                  node {
                    id
                    question {
                      id
                      sequence
                    }
                    answer
                  }
                }
              }
            }
          }
        }
        personality: responseSet(survey: "U3VydmV5VHlwZTo4", last: 1) {
          edges {
            node {
              id
              answerSet(question_In: [624, 625, 626, 627, 628]) {
                edges {
                  node {
                    id
                    question {
                      id
                      sequence
                    }
                    answer
                  }
                }
              }
            }
          }
        }
        knowledge: responseSet(survey: "U3VydmV5VHlwZTo5", last: 1) {
          edges {
            node {
              id
              answerSet(question_In: [629, 630, 631, 632, 633]) {
                edges {
                  node {
                    id
                    question {
                      id
                      sequence
                    }
                    answer
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const EMPLOYEE_LIST_FOR_VERTICAL = gql`
  query verticals($id_In: [String]) {
    verticals(id_In: $id_In) {
      edges {
        node {
          id
          employees {
            edges {
              node {
                id
                user {
                  id
                  firstName
                  lastName
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const EMPLOYEE_LIST_FOR_TEAM = gql`
  query Teams($id_In: [String]) {
    Teams(id_In: $id_In) {
      edges {
        node {
          id
          employees {
            edges {
              node {
                id
                user {
                  id
                  firstName
                  lastName
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const REPORT_FILTER_QUERY = gql`
  query SurveyById($value_id: ID!,$strength_id: ID!,$knowledge_id: ID!,$value_Icontains: String,$strenght_Icontains: String,$knowledge_Icontains: String) {
    values: SurveyById(id: $value_id) {
      id
      groupSet {
        edges {
          node {
            id
            questionSet {
              edges {
                node {
                  id
                  choiceSet(value_Icontains:$value_Icontains) {
                    edges {
                      node {
                        id
                        value
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    strength: SurveyById(id: $strength_id) {
      id
      groupSet {
        edges {
          node {
            id
            questionSet {
              edges {
                node {
                  id
                  choiceSet(value_Icontains:$strenght_Icontains) {
                    edges {
                      node {
                        id
                        value
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    knowledge: SurveyById(id: $knowledge_id) {
      id
      groupSet {
        edges {
          node {
            id
            questionSet {
              edges {
                node {
                  id
                  choiceSet(value_Icontains:$knowledge_Icontains) {
                    edges {
                      node {
                        id
                        value
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
