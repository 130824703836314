// @ts-nocheck
import { Row } from "antd";
import { getIntFromString } from "modules/layout/Layout";
import React from "react";
import deleteContent from '../../assets/agenda-close.svg'

const GivenKudos = props => {
    const { givenKudos, setGivenKudos, deleteBadgeAward, fromGiveFeedback,feedbackById,newKudosAdded, buttonStyle } = props
    const handleGivenKudos = async () => {
       
        if (givenKudos && !newKudosAdded) {
            let res = await deleteBadgeAward({ badgeAwardId: getIntFromString(feedbackById?.badgeAward?.edges[0]?.node?.id) })
            if (res) {
                setGivenKudos();
            }
        } else if (givenKudos && newKudosAdded) {
            setGivenKudos()
        } else {
            setGivenKudos()
        }

    }

    return (
        <>
           
            {!fromGiveFeedback &&<img src={deleteContent} style={ buttonStyle ? buttonStyle : {}} className="delete-given-kudos-button-give-feedback"  onClick={(e) => handleGivenKudos(e)} />}
            {
                fromGiveFeedback ?
                    <div className="given-kudos-container" style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",gap:'1em',borderColor:"rgba(199, 33, 45, 1)",background:"rgba(255, 224, 226, 1)",position:"relative",width:"fit-content"}}>
                        <img src={deleteContent} style={{position:"absolute",top:"-10px",right:"0px",width:"20px",cursor:"pointer"}}   onClick={(e) => handleGivenKudos(e)} />
                        <img src={givenKudos?.image} style={{height:"30px",width:"40px"}}/>
                        <h4 style={{fontSize:"1.1em",fontWeight:"500",fontFamily:"Poppins",margin:"0px",color:"black"}}>{givenKudos?.title}</h4>
                    </div>
                    
                    :
                    <img src={givenKudos?.image} className="given-kudos-container"/>
                    // <div className="given-kudos-container" >{givenKudos?.badge?.title}</div>
            }
          
           
        </>
    );
};

export default GivenKudos;

