import gql from 'graphql-tag';
import { ORGANIZATION_TYPE } from './OrganizationType.gql';
import { EMPLOYEE_TYPE } from './EmployeeType.gql';
import { EMPLOYEE_TYPE_CONNECTION } from './EmployeeTypeConnection.gql';

const VERTICAL_TYPE_FIELDS = gql`
  ${ORGANIZATION_TYPE},  ${EMPLOYEE_TYPE_CONNECTION}
  fragment VerticalTypeFields on VerticalType {
    id
    name
    description
    organization {
      ...OrganizationType
    }
    heads {
      ...EmployeeTypeConnection
    }
    employees {
      ...EmployeeTypeConnection
    }
  }
`;

export const VERTICAL_TYPE = gql`
  ${ORGANIZATION_TYPE}, ${EMPLOYEE_TYPE}, ${VERTICAL_TYPE_FIELDS}
  fragment VerticalType on VerticalType {
    ...VerticalTypeFields
    subvertical {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...VerticalTypeFields
        }
      }
      totalCount
      edgeCount
    }
  }
`;
