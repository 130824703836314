import React from 'react';
import { displayUtcToUserTimezone } from 'modules/look';
import { CustomProgress } from 'modules/look-v2';
import { Spin } from 'antd';

const UserOKRStatus = (props) => {
    const { okr_data } = props

    const role_type = {
        OWNER: "Owner",
        COLLABORATOR: "Collaborator",
        ASSIGNED: "Assignee"
    }
    return (
        <>
            {okr_data ?
                <div className='table-overflow'>
                    <table className='employee-profile-table '>
                        <thead>
                            <tr>
                                <th style={{ paddingLeft: "1.5em", textAlign: "left" }}>OBJECTIVE</th>
                                <th>PROGRESS</th>
                                <th>DUE DATE</th>
                                <th style={{ width: "8.5em" }}>STATUS</th>
                            </tr>
                        </thead>


                        {
                            okr_data?.length ?

                                okr_data?.map(record => (
                                    <tbody>
                                        <tr className='table-body-tr'>
                                            <td style={{ maxWidth: "100px" }} className='table-body-td first-child'>
                                                <div>
                                                    <h4 className='td-main-label'>{record?.title} </h4>
                                                    <p className='td-sub-label'> {role_type[record?.role]} : {record?.employee?.user?.firstName}</p>
                                                </div>
                                            </td>
                                            <td className='table-body-td'>
                                                <CustomProgress progress={record?.progress} info={true} showPercentageOnTop={true} color={record?.progress > 50 ? '#3B8855' : '#F95F53'} />
                                            </td>
                                            <td className='td-sub-label table-body-td' style={{ color: record?.colorCombination?.color }}>
                                                {displayUtcToUserTimezone(record?.duedate, "DD MMM YYYY")}
                                            </td>
                                            <td className='table-body-td last-child' style={{ width: "8.5em" }}>
                                                <div className='dashboard-performance-status-btn' style={{ color: `${record.colorCombination?.color}`, background: `${record.colorCombination?.bg}` }}>
                                                    {record?.status}
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                ))
                                :""
                        }

                    </table>
                   {okr_data?.length===0&&<div style={{display:'flex',justifyContent:"center",alignItems:"center",marginTop:"3em"}}>
                        <h4 className='no-data-profile'>No Data Found</h4>
                    </div>}
                </div>

                : <div align="center" style={{ width: "100%", height: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Spin spinning={true} ></Spin>
                </div>}
        </>
    )
}

export default UserOKRStatus
