import gql from 'graphql-tag';


export const DELETE_EMPLOYEE_MUTATION = gql`

  mutation deleteEmployeeLevel($id: ID!) {
    deleteEmployeeLevel(id:$id) {
        employeeLevel{
            id
        }
    }
  }
`;