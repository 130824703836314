import React, { useState } from "react";
import { Form, Input, Button, Spin } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import '../css/change-password.css'

const ChangePasswordView = (props) => {
    const { onsubmit, passErrors, onPassChange, submitLoading } = props

    const [form] = Form.useForm();
    const dynamicProps = pass => {

        return passErrors
            ? {
                validateStatus: passErrors && passErrors[pass] ? 'error' : '',
                help: passErrors && passErrors[pass] ? passErrors[pass].length > 0 && passErrors[pass][0].message : ''
            }
            : {};
    };
    const handleChangePassword = async values => {
        // setVisible(false);
        const response = await onPassChange(values);
        console.log("response: " , response)
        if(response.success) {
            form.resetFields();
        }
    };
    const oncancel = async ()=>{
        form.resetFields();
    }
    return (
        <div className="profile-notification-root" style={{ maxWidth: '40em' }}>
            <h2 className="proile-notification-title">Change password</h2>
            <Spin spinning={submitLoading}>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={(value) => handleChangePassword(value)}>
                    <Form.Item className="notigcation-label" 
                    label="Current password" 
                    name="oldPassword" 
                    style={{ marginTop: "1em", fontSize: "1em", fontWeight: "600" }}
                    rules={[
                        {
                            required: true,
                            message: 'Please input your current password!'
                        }
                    ]}>
                        <Input.Password
                            className="change-password-input"
                            placeholder="Enter Your Current Password"
                            autoComplete="off"
                            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        />
                    </Form.Item>

                    <Form.Item className="notigcation-label"
                        style={{ marginTop: "1em", fontSize: "1em",fontWeight:'600' }}
                        name="newPassword1"
                        label="New password"
                        {...dynamicProps('newPassword1')}
                        rules={[
                            {
                                required: true,
                                message: 'Please input your new password!'
                            },
                            {
                                min: 8,
                                message: 'Password length should be atleast 8.'
                            }
                        ]}
                        hasFeedback
                    >
                        <Input.Password
                            className="change-password-input"
                            placeholder="Enter Your New Password"
                            autoComplete="off"
                            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        />
                    </Form.Item>

                    <Form.Item className="notigcation-label"
                        style={{ marginTop: "1em", fontSize: "1em",fontWeight:"600" }}
                        name="newPassword2"
                        label="Confirm new password"
                        {...dynamicProps('newPassword2')}
                        dependencies={['newPassword1']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Please confirm your password!'
                            },
                            {
                                min: 8,
                                message: 'Password length should be atleast 8.'
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('newPassword1') === value) return Promise.resolve();

                                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                }
                            })
                        ]}
                    >
                        <Input.Password
                            className="change-password-input"
                            placeholder="Re-enter Your Password"
                            autoComplete="off"
                            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        />
                    </Form.Item>

                    <Form.Item style={{ marginTop: "2em" }}>
                        <div className="change-password-action-button-group">
                            <Button htmlType="submit" className="notification-action-active-button">
                                SET NEW PASSWORD
                            </Button>
                            <Button className="notification-action-cancel-button" onClick={()=>oncancel()}>
                                CANCEL
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </Spin>
        </div>
    );
}

export default ChangePasswordView;