import gql from 'graphql-tag';

export const MEMBER_NON_CASCADE_DATA =gql`
query contributors($okr_Duedate_Range: [DateTime],$createdBy:ID,$offset:Int,$first:Int){
    AllNonCascadingObjectives:contributors(savedOkrType: "OBJECTIVE",okr_Duedate_Range: $okr_Duedate_Range, role_In: ["OWNER"] createdBy:$createdBy,offset:$offset,first:$first) {
		totalCount
        edgeCount
        edges {
            node {
                id
                progress
                okrType
                okr{
                    id
                    duedate
                    title
                    okrType
                    isDiscarded{
                        id
                    }
                    discardRequest(status:"PENDING") {
                      edges {
                        node {
                          id
                          status
                        }
                      }
                    }
                    contributorSet {
                        edges {
                          node {
                            id
                            employee {
                              id
                              user{
                                id
                              }
                            }
                            requestStatus
                          }
                        }
                    }
                }
            }
        }
    }
}
`