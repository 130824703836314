import React from 'react'
import LandingView from '../components/landingView'
import '../../css/learning-path.css'
import { COMPLETE_LEARNING_SOUL_SURVEY, LEARNING_COMPLETED_LIST } from 'modules/learning-path/graphql/learnging-query.gql'
import { VALUE_SUERVEY_PART_1,STRENGTH_PART_1,PERSONALITY_PART_1,KNOWLEDGE_PART_1,diversity_survey_id,diversity_questions_for_in,USER_MANUAL_SURVEY_ID,SURVEY_WHOLE_IDENTITY,SURVEY_ID_3CS,SURVEY_WHOPOSHORO } from '../../../../config'
import { getQuestionBase64StringFromInt, getSurveyBase64StringFromInt } from 'modules/look'
import { compose } from 'modules/core'
import { withApollo } from '@apollo/client/react/hoc';
const LandingContainer = (props)=>{
    const {client,me}=props
    const [complete_survey_data,setCompletedSurveyData]=React.useState()
    React.useEffect(()=>{
        if (me) {
            // checkForCompletedList()
            completedSoulSurvey()
        }
    },[me])

    const checkForCompletedList = async () => {
        try {
           
            const { data } = await client.query({
                query: LEARNING_COMPLETED_LIST,
                variables: {
                    id: me?.employee?.id,
                    value: getSurveyBase64StringFromInt(VALUE_SUERVEY_PART_1),
                    strength: getSurveyBase64StringFromInt(STRENGTH_PART_1),
                    personality: getSurveyBase64StringFromInt(PERSONALITY_PART_1),
                    knowledge: getSurveyBase64StringFromInt(KNOWLEDGE_PART_1),
                    diversity: getSurveyBase64StringFromInt(diversity_survey_id),
                    gender: getQuestionBase64StringFromInt(diversity_questions_for_in?.gender),
                    HighestDegree: getQuestionBase64StringFromInt(diversity_questions_for_in?.HighestDegree),
                    YearsOfExperience: getQuestionBase64StringFromInt(diversity_questions_for_in?.YearsOfExperience),
                    CurrentNationality: getQuestionBase64StringFromInt(diversity_questions_for_in?.CurrentNationality),
                    usermanual:getSurveyBase64StringFromInt(USER_MANUAL_SURVEY_ID),
                    whole:getSurveyBase64StringFromInt(SURVEY_WHOLE_IDENTITY),
                    cs3:getSurveyBase64StringFromInt(SURVEY_ID_3CS),
                    whoposhoro:getSurveyBase64StringFromInt(SURVEY_WHOPOSHORO)
                },
                fetchPolicy: 'network-only'
            });

            if (data) {
                let diversity = data?.employeeById?.user?.diversity?.edges[0]?.node
                let diversity_completed = false
                if (diversity && diversity?.CurrentNationality?.totalCount && diversity?.HighestDegree?.totalCount && diversity?.YearsOfExperience?.totalCount && diversity?.gender?.totalCount) {
                    diversity_completed = true
                }
                console.log("data!!!!",data?.employeeById)

            }
        } 
        catch (error){
            console.log(error)
        }
    }
    const completedSoulSurvey = async () => {
        try {
           
            const { data } = await client.query({
                query: COMPLETE_LEARNING_SOUL_SURVEY,
                variables: {
                    user: me?.id,
                    value: getSurveyBase64StringFromInt(VALUE_SUERVEY_PART_1),
                    strength: getSurveyBase64StringFromInt(STRENGTH_PART_1),
                    personality: getSurveyBase64StringFromInt(PERSONALITY_PART_1),
                    knowledge: getSurveyBase64StringFromInt(KNOWLEDGE_PART_1),
                    diversity: getSurveyBase64StringFromInt(diversity_survey_id),
                    gender: getQuestionBase64StringFromInt(diversity_questions_for_in?.gender),
                    HighestDegree: getQuestionBase64StringFromInt(diversity_questions_for_in?.HighestDegree),
                    YearsOfExperience: getQuestionBase64StringFromInt(diversity_questions_for_in?.YearsOfExperience),
                    CurrentNationality: getQuestionBase64StringFromInt(diversity_questions_for_in?.CurrentNationality)
                },
                fetchPolicy: 'network-only'
            });

            if (data) {
                let diversity = data?.diversity?.edges[0]?.node
                let diversity_completed = false
                if (diversity && diversity?.CurrentNationality?.totalCount && diversity?.HighestDegree?.totalCount && diversity?.YearsOfExperience?.totalCount && diversity?.gender?.totalCount) {
                    diversity_completed = true
                }
                let detail = {
                    diversity_profile: {
                        totalCount: diversity_completed ? 1 : 0
                    },
                    value:data?.Values,
                    strengths:data?.Strength,
                    personality:data?.Personality,
                    knowledge_skills:data?.knowledge,
                    impact_narrative:data?.impact_narrative
                }
                setCompletedSurveyData(detail)
            }
        } 
        catch (error){
            console.log(error)
        }
    }
    return(
        <>
        <LandingView 
        complete_survey_data={complete_survey_data}
        {...props}/>
        </>
    )
}

export default compose(withApollo)(LandingContainer)