import React from 'react';

import { compose } from '../../core';

import AllStoriesView from '../components/AllStoriesView';

import { withAllPublishedStories, withStoryState, withStoryFilterUpdating } from './StoryOperations';
import { subscribeToAllPublishedStory } from './StorySubscriptions';

const AllStories = props => {
  const { subscribeToMore } = props;
  React.useEffect(() => {
    const subscribe = subscribeToAllPublishedStory(subscribeToMore /* , props.filter */);
    return () => subscribe();
  });

  return <AllStoriesView {...props} />;
};

export default compose(withStoryState, withAllPublishedStories, withStoryFilterUpdating)(AllStories);
