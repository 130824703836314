import gql from 'graphql-tag';
import { TEXT_SECTION_TYPE } from './TextSectionType.gql';

export const EDIT_TEXT_SECTION = gql`
  ${TEXT_SECTION_TYPE}
  mutation editTextSection($condition: [Int], $id: ID!, $sequence: Int!, $text: String, $title: String) {
    updateTextSection(condition: $condition, id: $id, sequence: $sequence, text: $text, title: $title) {
      textSection {
        ...TextSectionType
      }
    }
  }
`;
