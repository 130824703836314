import gql from 'graphql-tag';


export const RATING_SCALES = gql`
  query ratingScales {
    ratingScales(orderBy:["sequence"]){
        totalCount
        edges{
          node{
            id
            name
            sequence
          }
        }
    }
  }
`;


export const RATING_SCALE_SUBSCRIPTION =gql`
subscription ratingScaleSubscription{
  ratingScaleSubscription {
    mutation
    ratingScale{
      id
      name
      sequence
    } 
  }
}
`




export const DELETE_RATING_SCALE = gql`

  mutation deleteRatingScale($ratingScaleId: ID!) {
    deleteRatingScale(ratingScaleId:$ratingScaleId) {
      ratingScale  {
            id
        }
    }
  }
`;


export const CREATE_RATING_SCALE = gql`
mutation createRatingScale($ratingScaleData:RatingScaleInput!){
  createRatingScale(ratingScaleData:$ratingScaleData){
    ratingScale{
      id
      name
      sequence
      organization {
        id
        name
      }
    }
  }
}

`;
