import gql from 'graphql-tag';
import { GROUP_TYPE } from './GroupType.gql';

export const GROUP_TYPE_CONNECTION = gql`
  ${GROUP_TYPE}
  fragment GroupTypeConnection on GroupTypeConnection {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...GroupType
      }
    }
    totalCount
    edgeCount
  }
`;