import React from 'react';
import { Row, Col, Form } from 'antd';

import { TextField, ButtonsComponent } from '../../look';

const StoryFormComponent = props => {
  const { tagById, onSubmit } = props;
  const [button, setButton] = React.useState('');
  const initialValue = {
    name: tagById && tagById.name,
    description: tagById && tagById.description
  };

  return (
    <Row>
      <Col span={24}>
        <Form
          layout="vertical"
          initialValues={initialValue}
          onFinish={values => onSubmit(values, button)}
          required={false}
          scrollToFirstError={true}
        >
          <Row>
            <Col span={10} offset={1}>
              <Row>
                <Col lg={14} md={20}>
                  <TextField name="name" label="Name" rules={[{ required: true, message: 'name is required!' }]} />
                </Col>
              </Row>
              <Row>
                <Col lg={14} md={20}>
                  <TextField
                    type="textarea"
                    name="description"
                    label="Description"
                    // rules={[{ required: true, message: 'content is required!' }]}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={10}></Col>
            <Col span={3} />
          </Row>
          <ButtonsComponent setButton={setButton} />
        </Form>
      </Col>
    </Row>
  );
};

export default StoryFormComponent;
