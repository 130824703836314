import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ManagerImpactNarrativeComponent from '../components/ManagerImpactNarrativeComponent'
import { withGetTeamManager } from './ManagerOperations';
import { withApollo } from '@apollo/client/react/hoc';
import { DOWNLOAD_EMPLOYEE_TOOL_DATA } from '../../tool/graphql/DownloadEmployeeToolData.gql';
import { getIntFromString } from '../../look';
import { TOOL_FOR_REPORTEE_BY_TOOLID_QUERY } from '../../tool/graphql/ToolForReporteeByToolId';
import { compose } from '../../core';
import { withMeTeams } from '../../organization/containers/OrganizationOperation';


const ManagerImpactNarrativeContainer = props => {
    const {
        loading,
        getTeamManager,
        client
    } = props;
    let [downloading, setDownloading] = React.useState(false)
    let [firstUser, setFirstUser] = React.useState(undefined)
    let [secondUser, setSecondUser] = React.useState(undefined)
    let [firstitems, setFirstItems] = React.useState([])
    let [secondItems, setSecondItems] = React.useState([])
    let [teams, setTeams] = React.useState([])
    let [tool_loader, settool_loader] = React.useState(false)
    const changeUser = (value, type) => {
        if (type === 'first') {
            setFirstUser(value)
        } else {
            setSecondUser(value)
        }
        fetchData(value, type)
    }

    const clearSecondEmployee = () => {
        setSecondItems([])
        setSecondUser(undefined)
    }

    const fetchData = async (value, type) => {
        let id = '';
        if (props.match) {
            id = props.match.params.id;
        } else if (props.navigation) {
            id = props.navigation.state.params.id;
        }
        settool_loader(true)
        const { data } = await client.query({
            query: TOOL_FOR_REPORTEE_BY_TOOLID_QUERY,
            variables: { toolId: Number(id || props.id), employeeId: (value) },
        });
        if (type === 'first'){
                setFirstItems(
                [
                        ...(data?.employeeReport?.textsection?.edges?.map(({ node }) => ({
                        ...node,
                        type: "textsection",
                    })) || []),
                        ...(data?.employeeReport?.formsection?.edges?.map(({ node }) => ({
                        ...node,
                        type: "formsection",
                    })) || []),
                ].sort((a, b) =>
                    a.sequence < b.sequence ? -1 : a.sequence > b.sequence ? 1 : 0
                )
            );
        } else {
            setSecondItems(
                [
                    ...(data?.employeeReport?.textsection?.edges?.map(({ node }) => ({
                        ...node,
                        type: "textsection",
                    })) || []),
                    ...(data?.employeeReport?.formsection?.edges?.map(({ node }) => ({
                        ...node,
                        type: "formsection",
                    })) || []),
                ].sort((a, b) =>
                    a.sequence < b.sequence ? -1 : a.sequence > b.sequence ? 1 : 0
                )
            );
        }
        settool_loader(false)
    };
    


    const fetchGetTeamManager = () => {
        let ta = []
        let t = getTeamManager?.employee?.teamManagerEmployee?.edges
        if (t != undefined) {
            t.forEach((team) => {
                ta.push({ 'value': team?.node?.id, 'label': team?.node?.name });
            })
            setTeams(ta)
        }
    }

    const downloadCsv = (teamId) => {
        setDownloading(true)
        let empArr = []
        let nodes = getTeamManager?.employee?.teamManagerEmployee?.edges
        if (nodes !== undefined) {
            nodes.forEach((team) => {
                if (team.node.id === teamId){
                    let employees = team?.node?.employees?.edges
                    employees.forEach(employee => {
                        empArr.push(employee?.node?.user?.id)
                    });
                }
            })
        }
        exportJSONToCSV(empArr)
    }

    async function performDownloadQuery(empArr) {
        let objArray = []
        for (const emp of empArr) {
            const { data } = await client.query({
                query: DOWNLOAD_EMPLOYEE_TOOL_DATA,
                variables: { employeeId: getIntFromString(emp) },
            });
            objArray.push((data?.getToolForReporteeByToolId))
            objArray.push((data?.getToolForReporteeByToolId))
        }
        return objArray
    }

    async function exportJSONToCSV(empArr) {
        performDownloadQuery(empArr).then((objArray)=> {
            if (objArray.length > 0) {
                const array = [Object.keys(objArray[0])].concat(objArray)
                let csvContent = array.map(it => {
                    return Object.values(it).toString().replaceAll('#', '')
                }).join('\n')
                var element = document.createElement('a');
                element.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvContent);
                element.target = '_blank';
                element.download = 'export.csv';
                element.click();
            }
        })
        setDownloading(false)
    }


    return(
        <>
            <ManagerImpactNarrativeComponent {...props}
                firstitems={firstitems}
                secondItems={secondItems}
                onUserChange={changeUser}
                firstUser={firstUser}
                secondUser={secondUser}
                teams={teams}
                downloadCsv={downloadCsv}
                fetchGetTeamManager={fetchGetTeamManager}
                downloading={downloading}
                clearSecondEmployee={clearSecondEmployee}
                tool_loader={tool_loader}
               />
        </>

    );
}
export default compose(withGetTeamManager, withMeTeams, withApollo)(ManagerImpactNarrativeContainer);