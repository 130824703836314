import React from 'react';
import { Form, Transfer, Row, Col, Button } from 'antd';
import { Link } from 'react-router-dom';
import { UserAddOutlined } from '@ant-design/icons';

import Loading from '../../look/components/Loading'
import { getIntFromString } from '../../look/components/functions';


const TranferUserField = props => {
  const { listAllUsers, edges,functionType,dataType,initialValues,onDatachange,disable,isUserid,reset  } = props;
  const { ...rest } = props;

  const FormItem = Form.Item;
  const [mockData, setmockData] = React.useState([]);
  const [targetKeys, setTargetKeys] = React.useState([]);
  React.useEffect(() => {
    if(edges){
      const targetKeys = [];
    let mockData =[]
    if(dataType==='employee'){
      mockData= edges.map(({ node }) => ({
        title: node?.user?.firstName? `${node?.user?.firstName} ${node?.user?.email}` : dataType,
        key: getIntFromString(isUserid?node?.user?.id:node?.user?.employee?.id)
      }));
    }
    else{
      mockData= edges.map(({ node }) => ({
        title: node.title? `${node.title}` : dataType,
        key: getIntFromString(node.id)
      }));
    }
    if(initialValues && initialValues?.length){
      if(dataType==='employee'){
        initialValues.map(({ node }) => targetKeys.push(getIntFromString(isUserid?node?.user?.id:node?.user?.employee?.id)));
      }
      else{
        initialValues.map(({ node }) => targetKeys.push(getIntFromString(node.id)));
      }
      
    }
      

    setmockData(mockData);
    setTargetKeys(targetKeys);
    }
  }, [edges,initialValues,dataType]);

  React.useEffect(()=>{
    if(reset){
      setTargetKeys([]);
    }
  },[reset])

  const filterOption = (inputValue, option) => option.title.indexOf(inputValue) > -1;

  const handleChange = targetkeys => {
    setTargetKeys(targetkeys);
    onDatachange(targetkeys)
  };

  const textstyle={
    overflow:'hidden',
    textOverflow:'ellipsis',
    maxWidth:'200px',

  }
  return (
    <div style={{ marginBottom: '50px' }}>
      <Row>
        <Col span={22} offset={1}>
          {edges ? (
            <FormItem
              labelCol={{ span: 24 }}

              {...rest}
            >
              <Transfer
                listStyle={{ height: '35vh',width:'100%'}}
                dataSource={mockData}
                showSearch
                filterOption={filterOption}
                targetKeys={targetKeys}
                onChange={handleChange}
                disabled={disable}
                render={item => <p style={textstyle}>{item?.title}</p>}
              />
            </FormItem>
          ) : (
            <Loading size="small" />
          )}
        </Col>
     
      </Row>
    </div>
  );
};
export default TranferUserField
