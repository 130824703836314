import React from "react";
import { Modal, Radio, Button, Select } from 'antd'
import { createScapeIcons } from "../scapeAdminIcons";
import { ReactMarkdown } from "modules/look";

const EditChoiceView = ( props ) => {
    const { Option } = Select;
    const { edit_choice, all_choice_list, allready_selected_choices, onCancel, updateChoice } = props
    const [ choice_lists, setChoiceList ] = React.useState( [] )
    const [ selected_choice, setSelectedChoice ] = React.useState()
    const [ value, setValue ] = React.useState('')

    React.useEffect( () => {
        if ( edit_choice !== undefined ) {
            console.log("edit_choice", edit_choice)
            setValue( edit_choice?.choice_lable==="ORDER"?'order':'boolean' )
        }
    }, [ edit_choice ] )

    React.useEffect( () => {
        if ( all_choice_list?.length ) {
            if ( allready_selected_choices?.length ) {
                let filter_choice = [].concat( ...all_choice_list ).filter( item => item?.label !== "" && !allready_selected_choices?.includes( item?.id ) )
                setChoiceList( filter_choice )
            }
            else {
                setChoiceList( all_choice_list )
            }
        }
        else {
            setChoiceList( [] )
        }
    }, [ all_choice_list, allready_selected_choices ] )

    const selectNewCahoice = ( e ) => {
        console.log( "eee", e )
        setValue( e.target.value );
    }

    return (
        <Modal
            visible={true}
            footer={null}
            centered
            destroyOnClose={true}
            closable={false}
            className="sa-edit-modal">
            <div className="sa-root">
                <div className="sa-parametric-popup-container">
                    <h4 className='sa-parametric-title'>Edit Choices for {edit_choice?.group_name}</h4>
                    <div className='sa-input-container' style={{ width: '100%' }}>
                        <h4 className='sa-create-title'><span className='rd-input-star'>*</span>Input type</h4>
                        {/* <Select
                            className="sa-select-filed sa-type-select"
                            style={{ textOverflow: "ellipsis", whiteSpace: "nowrap",width:"100%" }}
                            // disabled={selected_scape!==undefined ? true : false}
                            suffixIcon={<img src={createScapeIcons?.dropdown_Arrow} alt="" style={{ width: "10px", height: "10px" }} />}
                            showSearch
                            // value={selected_choice}
                            // onSearch={( e ) => getSurveyLists( { name_Icontains: e } )}
                            onChange={(e)=>selectNewCahoice(e)}
                            filterOption={false}
                            placeholder='select a choice'

                            {...props}
                        >
                            {choice_lists?.map( value => (
                                <Option
                                    className='sa-select-option'
                                    key={value?.id}
                                    value={value?.id}
                                >
                                    <p className="sa-choice-question-text" style={{ marginBottom: "0em" }}><ReactMarkdown style={{ marginBottom: "0em" }}>{value?.label}</ReactMarkdown></p>
                                </Option>
                            ) )}
                        </Select> */}
                        <Radio.Group className="sa-radio-btn-group" onChange={selectNewCahoice} value={value}>
                            <Radio value={'boolean'}>Boolean</Radio>
                            <Radio value={'order'}>Order</Radio>
                        </Radio.Group>
                        <div style={{ display: 'flex', justifyContent: "center", flexDirection: "row", alignItems: 'center', width: "100%", gap: "1em", flexWrap: "wrap", marginTop: "1em" }}>
                            <Button className='sa-global-submit-btn' onClick={() => updateChoice( {show:value,id:edit_choice?.choice_id} )}>Save</Button>
                            <Button className='sa-global-cancel-btn' onClick={() => onCancel()}>Cancel</Button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default EditChoiceView