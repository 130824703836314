import gql from 'graphql-tag';

export const GET_RESPONSE_FROM_REQUESTED_USER =gql`
query getReportDataForRequestedUsersUserManualSurvey($requestedUserId:ID,$requestId: ID){
    getReportDataForRequestedUsersUserManualSurvey(requestedUserId: $requestedUserId,requestId: $requestId){
        id
        answerSet{
            edges{
            node{
                id
                answer
                question{
                id
                questionText
                choiceSet{
                    edges{
                    node{
                        id
                        inputType
                        label
                        value
                        question {
                        id
                        }
                    }
                    }
                }
                }
            }
            }
        }  
    }
  }
`

export const GET_RESPONSE_FROM_REQUEST =gql`
query getReportDataForRequestedUsersUserManualSurvey($requestId: ID){
    getReportDataForRequestedUsersUserManualSurvey(requestId: $requestId){
      id
    }
  }
`