import gql from 'graphql-tag';

export const MEMEBER_BY_TEAM_ID = gql`
query TeamByTeamId($id: ID!){
    Team(id:$id){
      id
      employees{
        edges{
          node{
            id
            user{
              id
              firstName
              lastName
              profile{
                id
                profileImage
              }
            }
          }
        }
      }
    }
  }
`