import gql from "graphql-tag";

export const ALL_CASCADING_OBJECTIVES = gql`
query contributors($okr_Duedate_Range:[DateTime], $role_In:[String],$employee:ID , $createdBy_Ne: ID,$offset:Int, $savedOkrType:String,$first:Int)
{
AllCascadingObjectives:contributors(role_In:$role_In,okr_Duedate_Range:$okr_Duedate_Range,
employee:$employee , createdBy_Ne:$createdBy_Ne, offset:$offset, savedOkrType:$savedOkrType,first:$first) {
totalCount
edges {
  node {
    id
    progress
    okrType
    okr{
      id
      duedate
      isDiscarded {
        id
      }
      discardRequest(status:"PENDING") {
        edges {
          node {
            id
            status
          }
        }
      }
      contributorSet {
        edges {
          node {
            id
            employee {
              id
              user{
                id
              }
            }
            requestStatus
          }
        }
      }
    }
  }
}
}
}
`;


export const ALL_NON_CASCADING_OBJECTIVES = gql`
query contributors($okr_Duedate_Range:[DateTime], $role_In:[String]
$createdBy:ID,$first:Int,$offset:Int, $savedOkrType:String)
{
  AllNonCascadingObjectives:contributors(okr_Duedate_Range:$okr_Duedate_Range, role_In:$role_In , createdBy:$createdBy, 
    first:$first,offset:$offset, savedOkrType:$savedOkrType) {
    totalCount 
    edgeCount
    edges {
  node {
    id
    progress
    okrType
    okr{
      id
      duedate
      contributorSet {
        edges {
          node {
            id
            employee {
              id
              user{
                id
              }
            }
            requestStatus
          }
        }
      }
      discardRequest(status:"PENDING") {
        edges {
          node {
            id
            status
          }
        }
      }
      isDiscarded{
        id
      }
    }
  }
}
}
}
`;


export const UPCOMING_MILESTONES = gql`
query contributors($okr_Duedate_Range: [DateTime], $employee:ID,$savedOkrType:String,$offset:Int,$first:Int,$tz:String){
contributors(okr_Duedate_Range:$okr_Duedate_Range , savedOkrType:$savedOkrType , employee:$employee, offset:$offset, first:$first ) {
  edges {
    node {
      id
      okr{
        id
        duedate
        correctionDeadline
        title(tz:$tz)
        owner{
          id
          user{
            id
            firstName
            lastName
          }
        }
        duedate
        isDiscarded {
          id
        }
        contributorSet {
          edges {
            node {
              id
              role
              employee {
                id
                user {
                  id
                  firstName
                  lastName
                }
              }
            }
          }
        }
      }
    }
  }
}
}


`;



export const ALL_OBJECTIVES = gql`
query contributors($okr_Duedate_Range: [DateTime], $role_In_cascaded:[String], $role_In_non_cascaded:[String],
$employee:ID ,$offset:Int,$orderBy:[String],$first:Int,$tz:String,$savedOkrType:String)
{
  AllCascadingObjectivesPaginated:contributors(okr_Duedate_Range:$okr_Duedate_Range, role_In:$role_In_cascaded , employee:$employee , orderBy:$orderBy,
    offset:$offset,first:$first) {
		totalCount
    edgeCount
    edges {
      node {
        id
        progress
        okrType
        okr{
          id
          title(tz:$tz)
          contributorSet {
            edges {
              node {
                id
                employee {
                  id
                  user{
                    id
                  }
                }
                requestStatus
              }
            }
          }
          title(tz:$tz)
          owner{
            id
            user{
              id
              firstName
              lastName
            }
          }
          duedate
          isDiscarded{
            id
          }
        }
      }
    }
  }
  
  AllNonCascadingObjectivesPaginated: contributors(okr_Duedate_Range:$okr_Duedate_Range, role_In:$role_In_non_cascaded , employee:$employee , orderBy:$orderBy,
    offset:$offset,first:$first, savedOkrType:$savedOkrType) {
		totalCount
    edgeCount
    edges {
      node {
        id
        progress
        okrType
        okr{
          id
          title(tz:$tz)
          contributorSet {
            edges {
              node {
                id
                employee {
                  id
                  user{
                    id
                  }
                }
                requestStatus
              }
            }
          }
          owner{
            id
            user{
              id
              firstName
              lastName
            }
          }
          duedate
          isDiscarded{
            id
          }
        }
      }
    }
  }
}
`;



export const NON_CASCADED_OKRS_PAGINATED = gql`
query contributors($okr_Duedate_Range: [DateTime],
$employee:ID ,$offset:Int,$orderBy:[String],$first:Int,$tz:String,$savedOkrType:String)
{
  AllNonCascadingObjectivesPaginated: contributors(okr_Duedate_Range:$okr_Duedate_Range,employee:$employee , orderBy:$orderBy,
    offset:$offset,first:$first,savedOkrType:$savedOkrType) {
		totalCount
    edgeCount
    edges {
      node {
        id
        progress
        okrType
        okr{
          id
          title(tz:$tz)
          contributorSet {
            edges {
              node {
                id
                employee {
                  id
                  user{
                    id
                  }
                }
                requestStatus
              }
            }
          }
          owner{
            id
            user{
              id
              firstName
              lastName
            }
          }
          duedate
          isDiscarded{
            id
          }
        }
      }
    }
  }
}
`;



export const GET_ROLES_OF_USER = gql`
query me {
teamRoles:me {
  id
  employee {
    id
    teamManagerEmployee {
      edges {
        node {
          id
          name
          managers {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    }
    teamMembersEmployee {
      edges {
        node {
          id
          name
          managers {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    }
  }
}
}

`;



export const GET_VERTICAL_ROLES_OF_USER = gql`
query me {
  verticalRoles:me {
    id
    employee {
      id
      verticalHeadEmployee {
        edges {
          node {
            id
            name
            heads {
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
      verticalMembersEmployee {
        edges {
          node {
            id
            name
            heads {
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
}

`;



export const GET_ORGANISATION_ROLES_OF_USER = gql`
query me {
   organisationRoles: me{
      id
      employee{
        id
        organizationSet {
          edges {
            node {
              id
              name
              ceo {
                id 
              }
            }
          }
        }
      }
    }
}

`;


export const PROGRESS = gql`
query contributors( $savedOkrType: String,$employee_In:[ID], $okr_Duedate_Range:[DateTime],)
{
progress:contributors(savedOkrType:$savedOkrType ,employee_In:$employee_In, okr_Duedate_Range:$okr_Duedate_Range) {
totalCount
edges {
  node {
    id
    progress 
  }
}
}
}
`;


export const ALL_OBJECTIVES_NEW_QUERY = gql`
query contributors($okr_Duedate_Range: [DateTime],
$employee:ID ,$offset:Int,$orderBy:[String],$first:Int,$tz:String,$savedOkrType:String)
{
  AllCascadingObjectivesPaginated:contributors(okr_Duedate_Range:$okr_Duedate_Range,employee:$employee,orderBy:$orderBy,savedOkrType:$savedOkrType,
    offset:$offset,first:$first) {
		totalCount
    edgeCount
    edges {
      node {
        id
        progress
        okrType
        okr{
          id
          title(tz:$tz)
          contributorSet {
            edges {
              node {
                id
                employee {
                  id
                  user{
                    id
                  }
                }
                requestStatus
              }
            }
          }
          title(tz:$tz)
          owner{
            id
            user{
              id
              firstName
              lastName
            }
          }
          duedate
          isDiscarded{
            id
          }
        }
      }
    }
  }
}
`;