import { Button, Col, Row } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import thank_you from '../../assets/thank_you.png'
import white_back_icon from '../../assets/white_back_icon.svg'



const ThankyouView = props => {

    const history = useHistory();

    return (
        <>

            <Row align='middle' justify='start' style={{ cursor: 'pointer',gap:'10px' }} onClick={(e) => history.goBack()}>
                <img className="back-icon-thank-you" src={white_back_icon} />
                <div onClick={(e)=>history.goBack()} style={{fontSize:'13px',color:'rgba(0, 0, 0, 0.85)'}} className="info-text-get-in-touch">Back to site</div>
            </Row>

            <Row justify='space-around' align='middle' style={{ paddingTop: '30px', paddingBottom: '30px' }} className='poppins'>
                <Col span={10}>
                    <img style={{ width: '100%' }} src={thank_you} />
                </Col>
                <Col span={10}>

                    <div>
                        <div style={{ fontSize: '23px', fontWeight: '700', marginBottom: '20px' }}>Thank you for reaching out!</div>
                        <div className='thank-you-message-text'>We appreciate your interest.</div>
                        <div className='thank-you-message-text' style={{marginBottom:'5px'}}>We will connect with you within 24 hours.</div>
                        <div className='thank-you-message-text' style={{marginBottom:'5px'}}>Discover more interesting information and <br></br>
                            engaging content on our blog.</div>
                        <div className='thank-you-message-text'><span onClick = {(e)=>window.open("https://brainayan.com/flagship-programs/")} style={{ color: '#237AFF', textDecoration: 'underline', cursor:'pointer'}}> Click here</span> to dive in and stay informed.</div>
                        <div style={{ fontSize: '30px', color: '#4CCB1F', fontWeight: '700' }}>Happy exploring!</div>
                    </div>

                </Col>

            </Row>
        </>

    );
};

export default ThankyouView;
