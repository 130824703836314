import gql from 'graphql-tag';

export const EMPLOYEE_DETAIL = gql`
  query me {
   me {
     id
     employee{
        id
        teamManagerEmployee{
            totalCount
            edges{
                node{
                  id
                  employees{
                    edges{
                      node{
                        id
                        user{
                          id
                          firstName
                          lastName
                          email
                          employee{
                            id
                          }
                        }
                      }
                    }
                  }
                }
              }
        }
        orgCeoEmployee{
            totalCount
            edges{
                node{
                  id
                  employees{
                    edges{
                      node{
                        id
                        user{
                          id
                          firstName
                          lastName
                          email
                          employee{
                            id
                          }
                        }
                      }
                    }
                  }
                }
              }
        }
        orgPocEmployee{
            totalCount
            edges{
                node{
                  id
                  employees{
                    edges{
                      node{
                        id
                        user{
                          id
                          firstName
                          lastName
                          email
                          employee{
                            id
                          }
                        }
                      }
                    }
                  }
                }
              }
        }
        verticalHeadEmployee{
            totalCount
            edges{
                node{
                  id
                  employees{
                    edges{
                      node{
                        id
                        user{
                          id
                          firstName
                          lastName
                          email
                          employee{
                            id
                          }
                        }
                      }
                    }
                  }
                }
              }
        }
     }
    }
  }
`;
