import gql from "graphql-tag";

export const COACHING_COMMITMENT = gql`
  query coachingCommitmentProgress($user: ID) {
    coachingCommitmentProgress(user: $user) {
      edges {
        node {
            id
            coachingcommitment{
                id
                title
            }      
        }
      }
    }
  }
`;
