

import { Input, Tabs } from "antd";
import React from "react";
import BarsLevelTraversing from "./BarsLevelTraversing";





const BarsEditDetailsTabContainer = (props) => {
    const { barsDetails, handleExampleChange, allEmployeeLevels, allRatingScales, indexOfElements, activeLevel,
        setActiveLevel, invalidBehaviourExamples } = props

    


  

    
    const { TextArea } = Input;

    const handleTraversingOfLevels = (direction) => {

        switch (direction) {
            case "Forward":

                if (activeLevel < (allEmployeeLevels?.length - 1)) {
                    setActiveLevel(activeLevel + 1)
                } else {
                    setActiveLevel(0)
                }
                break;

            case "Backward":

                if (activeLevel >= (allEmployeeLevels?.length - 1)) {

                    setActiveLevel(activeLevel - 1)
                } else if (activeLevel === 0) {
                    setActiveLevel(allEmployeeLevels?.length - 1)
                }
                else if (activeLevel < (allEmployeeLevels?.length - 1)) {
                    setActiveLevel(activeLevel - 1)
                }
                else {
                    setActiveLevel(allEmployeeLevels?.length - 1)
                }
                break;

            default:
                break;

        }
        window.scrollTo(0, 0);


    }





    const handleToggleLevel = (e) => {

        let elements = document.getElementsByClassName('ant-tabs-nav-list')[0];

        allEmployeeLevels.map((level, index) => {

            if (elements.getElementsByClassName("ant-tabs-tab")[index]) {
                if (level?.status === 'pending') {
                    elements.getElementsByClassName("ant-tabs-tab")[index].classList.add('incomplete-level-bars')
                } else {
                    elements.getElementsByClassName("ant-tabs-tab")[index].classList.add('complete-level-bars')
                }
            }

          
        }

        )

    }


    

    React.useEffect(() => {

        if (barsDetails) {
            handleToggleLevel()
        }

    }, [barsDetails, activeLevel, allEmployeeLevels])


   

    
    return (
        <>

            <Tabs activeKey={activeLevel.toString()} onChange={(e) => setActiveLevel(parseInt(e))} className='bars-tab-panel' >

                {
                    (allEmployeeLevels?.length) ? allEmployeeLevels?.map((level, index) =>
                        <Tabs.TabPane tab={level?.name} key={index}>

                            {
                                barsDetails?.empLevels?.map((node, index) =>
                                    <div>
                                        {
                                            allRatingScales?.length && allRatingScales?.map((scale) =>
                                                node?.id === level?.id && scale?.id === node?.ratingScale?.id &&
                                                <div>
                                                    <div className="create-bar-sub-heading create-bar-margin-seperator">{node?.ratingScale?.name}</div>
                                                    <TextArea onChange={(e) => handleExampleChange(e.target.value, node, level)}
                                                        value={node?.behaviourExample}
                                                        placeholder='Type...'
                                                        className='bars-rating-scale-text' id={`text-field-${node?.ratingScale?.id}`}
                                                    ></TextArea>
                                                </div>

                                            )
                                               
                                        }

                                    </div>
                                )
                            }

                            {
                                 !(allRatingScales?.length) &&
                                 <div align='center' style={{ fontSize: '1.4em', fontWeight: 500, width: '100%' }}>No threshold is available</div>
                            }
                            {
                                    invalidBehaviourExamples &&
                                <p className="poppins" style={{ color: 'red', fontSize: '1em', marginTop: '0.8em' }}>Customize the rating scale per the sub-competency to proceed.</p>
                            }



                            {
                                (allRatingScales?.length) ?
                                <BarsLevelTraversing handleTraversingOfLevels={handleTraversingOfLevels}
                                    invalidBehaviourExamples={invalidBehaviourExamples}
                                    status={allEmployeeLevels?.length ? allEmployeeLevels[activeLevel]?.status : ''} level={level} index={index} />
                                    :null
                            }


                        </Tabs.TabPane>
                    )
                        :
                        <div align='center' style={{ fontSize: '1.5em', fontWeight: 500, width: '100%' }}>No employee Level is available</div>
                }

            </Tabs>
        </>
    );
};

export default BarsEditDetailsTabContainer;

