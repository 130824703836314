import React from 'react';
import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import { Spin } from 'antd';

import { getIntFromString } from '../functions';

import SelectField from './SelectField';

export const ALL_SURVEY_QUERY = gql`
  query allSurveysForTokens {
    allSurveysForTokens {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

const SurveyField = props => {
  const { loading, allSurveysForTokens } = props;

  const choices =
    !loading &&
    allSurveysForTokens &&
    allSurveysForTokens.edges.length > 0 &&
    allSurveysForTokens.edges.map(({ node }) => ({
      label: node.name,
      value: getIntFromString(node.id)
    }));

  return (
    <Spin spinning={loading} size="small">
      <div></div>
      <SelectField {...props} choices={!loading && allSurveysForTokens ? choices : []} />
    </Spin>
  );
};

export default graphql(ALL_SURVEY_QUERY, {
  props({ data: { loading, error, allSurveysForTokens, subscribeToMore, updateQuery } }, history) {
    return { loading, error, allSurveysForTokens, subscribeToMore, updateQuery };
  }
})(SurveyField);
