// @ts-nocheck
import React from 'react';
import { Row,Button,Dropdown,Menu,Input } from "antd";
import styled from "styled-components";
import Search_icon from '../../assets/search-icon.svg'
import dropdown_Arrow from '../../assets/dropdown_Arrow.svg'
const MineFilter = props => {
 
    const {
        filterData,
        defaultFilter,
        onfilterChange
    } = props; 
    
    const [selectedFilter,setFilter]=React.useState('Objective')
    const [filterList,setfilterList]=React.useState([])
    const [filterValue,setfilterValue]=React.useState()
    React.useEffect(()=>{
        if(filterData){
            setfilterList(filterData)
        }
    },[filterData])

    React.useEffect(()=>{
        if(defaultFilter){
            setFilter(defaultFilter?.key)
        }
    },[defaultFilter])
    const filterValueChange=(value)=>{
        setfilterValue(value)
        switch (selectedFilter){
            case 'Collaborator':{
                onfilterChange({employee_User_FirstName_Icontains:value})
                break
            }
            case 'Objective':{
                onfilterChange({okr_Goalstatement_Icontains:value})
                break
            }
            default : break
        }
    }

    const FilterType =(
        <div>
            {(selectedFilter==='Collaborator'||selectedFilter==='Objective'||selectedFilter==='Outgoing') && (
                <div style={{position:'relative'}}>
                    <Input
                    placeholder=""
                   className='filter-input'
                    value={filterValue}
                    style={{paddingLeft:"30px",borderRadius:"12px 0px 0px 12px"}}
                    onChange={e => (filterValueChange(e.target.value))}  
                    autoComplete='off' 
                    />
                    <img style={{position:"absolute",left:"7px",top:"10px",width:"20px"}} src={Search_icon } alt="" />
                </div>
            )}
        </div>
    )

    const Filtermenu =()=> (
        <Menu style={{maxHeight:'200px',overflow:'auto'}} >
          {
            filterList && (
                filterList?.map((data)=>
              <Menu.Item key={data?.key} onClick={()=>{setfilterValue(null);setFilter(data?.key)}}>
                {data?.type}
              </Menu.Item>
              )
            )
          }
        </Menu>
      );

    return (
        <>
        <Row>
            <div>
              {FilterType}
            </div>
            <Dropdown overlay={Filtermenu} overlayStyle={{zIndex:"1050"}} style={{border: '1px solid #D9D9D9',color: '#BBBBBB'}} trigger="click" className="filter-drop">
                <DropdownButton style={{justifyContent:"center",borderRadius:"0px 12px 12px 0px"}}>
                    <p className='selected-filter' style={{marginTop:'13px',width:'100%',overflow:'hidden',textOverflow:'ellipsis',textAlign:'left',paddingTop:"2px"}}>{selectedFilter}</p>
                    <img src={dropdown_Arrow} alt="" style={{width:"13px",height:"10px"}}/>
                </DropdownButton>
            </Dropdown>
        </Row>
        </>
    )

}

export default MineFilter;

const DropdownButton = styled(Button)`
width:140px !important;
display:flex;
height:36px;
justify-content:space-between;
align-items:center;
gap:5px
`