import { graphql } from '@apollo/client/react/hoc';
import _ from 'lodash';
import { ORGANIZATION_DETAIL_VERTICALS_BY_ORG_ID_QUERY } from './query';
import { getOrganizationBase64StringFromInt } from 'modules/look';

export const withOrganizationDetailVerticalsByOrgIdQuery = Component =>
    graphql(ORGANIZATION_DETAIL_VERTICALS_BY_ORG_ID_QUERY, {
        options: ({ match, navigation, filter, orderBy, pagination }) => {
            let id = '';
            if (match) {
                id = getOrganizationBase64StringFromInt(match.params.id);
            } else if (navigation) {
                id = getOrganizationBase64StringFromInt(navigation.state.params.id);
            }
            return {
                variables: { ...pagination, ...filter, orgId:id, orderBy },
                fetchPolicy: 'network-only'
            };
        },
        props({ data }) {
            const { loading, error, verticals, subscribeToMore, updateQuery } = data;
            return { loading, error, verticals, subscribeToMoreVerticalsByOrgId: subscribeToMore, updateQuery };
        }
    })(Component);