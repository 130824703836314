// @ts-nocheck
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { Carousel, Card, Progress, Skeleton, Row, Button, Col, Form, Empty, Modal, Tooltip, Space } from 'antd';
import { LogoutOutlined, UpCircleTwoTone, DownCircleTwoTone, InfoCircleOutlined } from '@ant-design/icons';
import { ShortcutProvider, ShortcutConsumer } from 'react-keybind';

import SectionComponent, { getTypeFromQuestion, TIME_LIMIT } from './SectionComponent';
import { MetaTags, getIntFromString, InputValuesObject, getUniqueListBy } from '../../look';
import HOME_ROUTE from '../../home/route'
import ROUTE from '../route';
import QuizLoading from './QuizLoading';
import SaveInCompleteQuizResponse from './SaveInCompleteQuizResponse';
import SurveyQuizShortcuts, { getShortCutObj } from '../components/SurveyQuizShortcuts';

const { confirm } = Modal;

const QuizView = props => {
  const { loading, orgSurveyById, onSubmit, SurveyById, setPrevTotalDuration, quizStartTime, history, submitted,showTermsAndCondition } =
    props;
    const QuesDiv = {
      margin: 'auto',
      minHeight: '45vh',
      maxWidth: '800px',
      width: ' 100%',
      height:'100%',
      padding: `${props => !props.currentIsMatrix && '24px'}`
    };
    const BottomButton = {
      position: "absolute",
      top: "1rem",
      right: "2rem",
    }

  const ref = React.useRef();
  // const mobileRef = React.useRef();
  const currentSectionRef = React.useRef();
  const showOkRef = React.useRef();

  const [showOk, setShowOk] = React.useState([false]);
  const [timeoutId, setTimeoutId] = React.useState(null);
  const [currentSection, setCurrentSection] = React.useState(1);
  const [formValues, setFormValues] = React.useState([]);
  const [startTime, setStartTime] = React.useState(new Date().getTime());
  const [isForw, setIsForw] = React.useState(true);
  const [currentMatrixQuestion, setCurrentMatrixQuestion] = useState(0);
  const [enableShortCuts, setEnableShortCuts] = useState(false);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (!enableShortCuts) {
      // setEnableShortCuts(true);
    }
  }, [enableShortCuts]);

  const question = [].concat.apply(
    [],
    (orgSurveyById ? orgSurveyById : SurveyById)?.groupSet?.edges
      .sort(function (a, b) {
        return a.node.sequence - b.node.sequence;
      })
      .map(({ node: grpSet }, idx) =>
        grpSet.matrix
          ? { ...grpSet }
          : grpSet?.questionSet?.edges?.map(({ node: questionSet }) => ({
            ...grpSet,
            questionSet: [questionSet]
          }))
      )
  );

  const currentIsMatrix = question[currentSection - 1]?.matrix;
  let totalLength = question.length;

  const shortcutObj = question?.map(q =>
    Array.isArray(q?.questionSet)
      ? q?.questionSet?.map(getShortCutObj)
      : q?.questionSet?.edges?.map(({ node }) => getShortCutObj(node))
  );

  function showDeleteConfirm() {
    confirm({
      title: 'Are you sure you want to exit this survey?',
      icon: <LogoutOutlined />,
      content:
        'We will save all your current responses. You can choose to continue from here the next time you start this survey.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        history.push(HOME_ROUTE.home);
      },
      onCancel() {
      }
    });
  }

  const renderFormSections = survey => {
    // const carouseSettingChange = isForw
    //   ? {
    //       beforeChange
    //     }
    //   : {
    //       afterChange
    //     };
    const carouselSettings = {
      currentIsMatrix,
      accessibility: false,
      // ...carouseSettingChange,
      beforeChange,
      // afterChange,
      ref,
      // ref: mobileRef,
      dotPosition: 'left',
      dots: false,
      autoplay: false,
      infinite: false
    };

    const carouselMobileSettings = {
      currentIsMatrix,
      accessibility: false,
      // ...carouseSettingChange,
      beforeChange,
      // afterChange,
      // ref: mobileRef,
      ref,
      dotPosition: 'left',
      dots: false,
      autoplay: false,
      infinite: false
    };

    function timeoutFunc(srNo) {
      const id = setTimeout(() => nextStep(form.getFieldsValue(true), srNo), TIME_LIMIT * 1000);
      setTimeoutId(id);
    }
    function closetimer() {
      clearTimeout(timeoutId);
    }

    function getValue(value, name) {
      if (!currentIsMatrix) {
        const questionFiltered = question.filter(q => {
          return Array.isArray(q?.questionSet) && q?.questionSet?.filter(qs => qs.id === name).length > 0;
        })[0]?.questionSet[0];
        switch (questionFiltered?.choiceSet?.edges[0]?.node?.inputType) {
          case InputValuesObject.date:
            return value?.format('YYYY/MM/DD');
          case InputValuesObject.datetime:
            return value?.format('YYYY/MM/DD HH:mm:ss');
          case InputValuesObject.month:
            return value?.format('M');
          case InputValuesObject.time:
            return value?.format('HH:mm:ss');
          case InputValuesObject.week:
            return value?.week();
          case InputValuesObject.range:
            return value === undefined || value === 0 ? 0 : value;
          default:
            return value || '';
        }
      } else {
        return value || '';
      }
    }

    function getResponseDuration(endTime, value, k) {
      let time = 0;
      const existingValue = formValues.filter(f => f.questionId === getIntFromString(k))[0];

      if (!currentIsMatrix) {
        time =
          question[currentSection - 1]?.questionSet[0].id === k
            ? existingValue
              ? existingValue?.responseDuration + (endTime - startTime) / 1000 // returns time in seconds
              : (endTime - startTime) / 1000 // returns time in seconds
            : existingValue?.responseDuration || 0;
      } else {
        time = existingValue
          ? existingValue?.answer === '' || existingValue?.answer === undefined
            ? value !== '' || value !== undefined
              ? 0
              : (endTime - startTime) / 1000
            : existingValue?.responseDuration
          : value === '' || value === undefined
            ? 0
            : (endTime - startTime) / 1000;
      }
      return time;
    }

    const prevStep = () => {
      if (currentSection === 0) {
        return false;
      }
      closetimer();
      setIsForw(false);
      ref.current.prev();
      // mobileRef.current.prev();
      if (document) {
        if (document.body) {
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
        }
      }
    };

    // function afterChange(current) {
    //   setCurrentSection(current + 1);
    // }
    function beforeChange(current) {
      if (!isForw && current === 0) {
        return false;
      }
      setCurrentSection(isForw ? current + 2 : current);
      setCurrentSectionFromRef(isForw ? current + 2 : current);
      setCurrentMatrixQuestion(0);
    }

    const nextStep = values => {
      setIsForw(true);
      if (currentSection !== totalLength) ref.current && ref.current.next();
      // if (currentSection !== totalLength) mobileRef.current && mobileRef.current.next();
      closetimer();
      setShowOk(question.map(() => false));
      const endTime = new Date().getTime();

      let input = [];
      Object.keys(values).map((k, i) => {
        input.push({
          questionId: getIntFromString(k),
          answer: String(getValue(values[k], k)),
          responseDuration: getResponseDuration(endTime, values[k], k)
        });
      });
      setFormValues(getUniqueListBy([...formValues, ...input], 'questionId'));
      if (document) {
        if (document.body) {
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
        }
      }
      setStartTime(endTime);
      return getUniqueListBy([...formValues, ...input], 'questionId');
    };

    function getFieldName(index, current) {
      return shortcutObj[current - 1][index].name;
    }

    function getFieldType(index, current) {
      return shortcutObj[current - 1][index].type;
    }

    function getFieldValue(name, optionKey, current) {
      const fieldValue = shortcutObj[current - 1].filter(q => q.name === name)[0][optionKey];
      const currentFieldType = getFieldType(0, current);
      return currentFieldType === InputValuesObject.radio ? fieldValue : [...(form.getFieldValue(name) || []), fieldValue];
    }
    function setCurrentSectionFromRef(value) {
      currentSectionRef.current.innerHTML = Number(value);
    }
    function setShowOkFromRef(value) {
      showOkRef.current.innerHTML = JSON.stringify(value);
    }

    const setValueFromShortcut = optionKey => {
      const showOkFromRef = JSON.parse(showOkRef?.current?.innerHTML);
      const currentSectionFromRef = Number(currentSectionRef?.current?.innerHTML);
      const currentFieldType = getFieldType(0, currentSectionFromRef);
      const fieldNames = Object.keys(form?.getFieldsValue(true));
      const matrix = question[currentSectionFromRef - 1]?.matrix;

      if (matrix && fieldNames.length > 0) {
        const currentQuestionIndex = shortcutObj[currentSectionFromRef - 1]
          .map(s => fieldNames.indexOf(s.name) === -1)
          .indexOf(true);

        if (currentQuestionIndex !== -1) {
          const fieldName = getFieldName(currentQuestionIndex, currentSectionFromRef);
          const fieldValue = shortcutObj[currentSectionFromRef - 1].filter(q => q.name === fieldName)[0][optionKey];
          if (fieldValue) {
            form.setFieldsValue(
              _.set(
                form.getFieldsValue(true),
                getFieldName(currentQuestionIndex, currentSectionFromRef),
                getFieldValue(fieldName, optionKey, currentSectionFromRef)
              )
            );
            if (currentQuestionIndex === shortcutObj[currentSectionFromRef - 1].length - 1) {
              if (currentSectionFromRef === totalLength) onSubmit(nextStep(form.getFieldsValue(true)));
              else nextStep(form.getFieldsValue(true));
              setCurrentMatrixQuestion(0);
            } else setCurrentMatrixQuestion(currentQuestionIndex + 1);
          }
        }
      } else {
        if (currentFieldType === InputValuesObject.radio || currentFieldType === InputValuesObject.checkbox || matrix) {
          const firstFieldName = getFieldName(0, currentSectionFromRef);
          const fieldValue = shortcutObj[currentSectionFromRef - 1].filter(q => q.name === firstFieldName)[0][optionKey];
          if (fieldValue) {
            form.setFieldsValue(
              _.set(
                form.getFieldsValue(true),
                firstFieldName,
                getFieldValue(firstFieldName, optionKey, currentSectionFromRef)
              )
            );
            if (!matrix) {
              if (currentFieldType === InputValuesObject.radio) {
                setCurrentMatrixQuestion(1);
                setShowOk(question.map((q, qi) => (true ? qi === currentSectionFromRef - 1 : false)));
                setShowOkFromRef(question.map((q, qi) => (true ? qi === currentSectionFromRef - 1 : false)));

                if (!showOkFromRef[currentSectionFromRef - 1]) {
                  timeoutFunc(currentSectionFromRef);
                  // setShowOk(question.map((q, qi) => (false ? qi === currentSectionFromRef - 1 : false)));
                  // setShowOk(question.map((q, qi) => (true ? qi === currentSectionFromRef - 1 : false)));
                  // setShowOkFromRef(question.map((q, qi) => (false ? qi === currentSectionFromRef - 1 : false)));
                  // setShowOkFromRef(question.map((q, qi) => (true ? qi === currentSectionFromRef - 1 : false)));
                  // closetimer();
                }
              } else {
                setCurrentMatrixQuestion(form?.getFieldsValue(true)[firstFieldName].length);
              }
            } else setCurrentMatrixQuestion(1);
          }
        }
      }
    };

    return (
      <>
        <ShortcutProvider>
          {enableShortCuts && shortcutObj.length > 0 && (
            <SurveyQuizShortcuts 
              onNext={() => form.submit()}
              onPrev={prevStep}
              setValueFromShortcut={setValueFromShortcut}
              currentMatrixQuestion={currentMatrixQuestion}
              visible={visible}
              setVisible={setVisible}
            />
          )}

          {/* <Button onClick={() => setValueFromShortcut(Math.random() > 0.5 ? 'a' : 'b')}>Testing</Button> */}
          <span ref={currentSectionRef} style={{ display: 'none' }}>
            1
          </span>

          <span ref={showOkRef} style={{ display: 'none' }}>
            {JSON.stringify([false])}
          </span>

          {!submitted && survey?.id && showTermsAndCondition===false && (
            <SaveInCompleteQuizResponse
              form={form}
              formValues={formValues}
              itemName={survey?.id}
              getValue={getValue}
              setFormValues={setFormValues}
              setPrevTotalDuration={setPrevTotalDuration}
              startTime={quizStartTime}
            />
          )}

          <ShortcutConsumer>
            {({ keys: allKeys }) =>
              survey?.groupSet.edges.length > 0 ? (
                <>
                  <MetaTags title={survey[currentSection]?.label || survey?.name} description="This is survey page." />
                  

                  <Form
                    form={form}
                    name={survey?.name}
                    // layout={'vertical'}
                    onFinish={values => (currentSection === totalLength ? onSubmit(nextStep(values)) : nextStep(values))}
                  // requiredMark={false}
                  // scrollToFirstError={true}
                  // initialValues={initialValues}
                  >
                    <Row>
                    <Col span={24} lg={24} md={24} sm={24}>
                      <div style={{padding:'10px 10px'}}>
                      <h4>Progress</h4>
                      <Progress
                        percent={Math.round((currentSection / totalLength) * 100)}
                        strokeColor={{
                          '0%': '#108ee9',
                          '100%': '#87d068'
                        }}
                        style={{
                          display: 'block'
                        }}
                        showInfo={false}
                        status="active"
                      />
                      </div>
                    </Col>
                      <Col span={24} lg={24} md={24} sm={24} xs={24}>
                      <Col lg={24} md={24} sm={24} xs={0}>
                        <h3 style={{ fontWeight: 'bold', margin: '2vh 5vh' }}>
                          {survey[currentSection]?.label || survey?.name}
                        </h3>
                      </Col>
                      <Col lg={0} md={0} sm={0} xs={24}>
                        <Row justify="space-around" gutter={10} >
                          <Col>
                            <h3 style={{ fontWeight: 'bold', margin: '1vh' }}>
                              {survey[ currentSection ]?.label || survey?.name}
                            </h3>
                          </Col>

                          <Col>
                            <Button
                              type="danger"
                              onClick={showDeleteConfirm}
                              size="small"
                              shape="circle"
                              icon={<LogoutOutlined />}
                            />
                          </Col>
                        </Row>
                        </Col>
                        <Col style={{ width: '100%' }}>
                        <div style={QuesDiv}>
                            <CustomCarousel {...carouselSettings}>
                              {question?.map((grpSet, idx) => (
                                <Col>
                                  <SectionComponent
                                    survey={orgSurveyById}
                                    key={idx}
                                    srNo={idx + 1}
                                    form={form}
                                    currentIsMatrix={currentIsMatrix}
                                    currentMatrixQuestion={currentMatrixQuestion}
                                    currentSection={currentSection}
                                    matrix={grpSet?.matrix}
                                    timeoutFunc={timeoutFunc}
                                    closetimer={closetimer}
                                    nextStep={e => nextStep(form.getFieldsValue(true), e)}
                                    sectionLabel={grpSet?.label}
                                    sectionSequence={grpSet?.sequence}
                                    questionList={
                                      Array.isArray(grpSet?.questionSet)
                                        ? grpSet?.questionSet?.map(node => node)
                                        : grpSet?.questionSet?.edges?.map(({ node }) => node)
                                    }
                                    showOk={showOk[currentSection - 1]}
                                    setShowOk={e =>
                                      setShowOk(question.map((q, qi) => (e ? qi === currentSection - 1 : false)))
                                    }
                                  />
                                </Col>
                              ))}
                            </CustomCarousel>
                        </div>
                        

                        <Row span={24} style={{ position: "relative", justifyContent: "space-between" }}>
                          <Col lg={20} md={18} sm={18} xs={0} style={{ position: "absolute", top: "1rem", left: "3rem" }}>
                            <Button type="danger" onClick={showDeleteConfirm} size="small" shape="round">
                              <LogoutOutlined />
                              Quit survey
                            </Button>
                          </Col>
                          <Col style={BottomButton}>
                            <Space>
                              <Tooltip title={'Shortcuts'}>
                                <Button
                                  icon={<InfoCircleOutlined twoToneColor="#87d068" style={{ fontSize: '35px' }} />}
                                  type="link"
                                  size="large"
                                  style={{height:'50px'}}
                                  onClick={() => setVisible(true)}
                                />
                              </Tooltip>

                              <Tooltip title={'Previous'}>
                                <Button
                                  icon={<UpCircleTwoTone twoToneColor="#87d068" style={{ fontSize: '35px' }} />}
                                  disabled={currentSection === 1}
                                  type="link"
                                  onClick={prevStep}
                                  style={{height:'50px'}}
                                  size="large"
                                />
                              </Tooltip>
                              {currentSection === totalLength ? (
                                <Button type="primary" htmlType="submit" size="large" shape="round">
                                  Submit
                                </Button>
                              ) : (
                                <Tooltip title={'Next'}>
                                  <Button
                                    type="link"
                                    htmlType="submit"
                                    size="large"
                                    shape={'round'}
                                    style={{height:'50px',marginBottom:"3px"}}
                                    icon={<DownCircleTwoTone twoToneColor="#87d068" style={{ fontSize: '35px' }} />}
                                  />
                                </Tooltip>
                              )}
                            </Space>
                          </Col>
                        </Row>
                        </Col>
                      </Col>
                    </Row>
                  </Form>
                </>
              ) : survey ? (
                <div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <Empty />
                </div>
              ) : null
            }
          </ShortcutConsumer>
        </ShortcutProvider>
      </>
    );
  };

  return (
    <MobilePadding>
      <div>
        {loading && <QuizLoading />}
        {!loading && orgSurveyById ? renderFormSections(orgSurveyById) : renderFormSections(SurveyById)}
      </div>
    </MobilePadding>
  );
};

export default QuizView;

const MobilePadding = styled.div`
  /* background: #f5f5f5; */
  // margin: -5vh;
  /* padding: 5vh 0; */
  min-height: 100vh;

  @media only screen and (max-width: 480px) {
    padding: 0 12px;
  }
`;

const CustomCarousel = styled(Carousel)`
  /* Carousel (slick) */
  .slick-list {
    height: ${props => (props.currentIsMatrix ? '75vh !important' : '60vh !important')};
  }
`;

const CustomNativeCarousel = styled(Carousel)`
  /* Carousel (slick) */
  .slick-slide {
    height: 0px !important;
  }
  .slick-track {
    height: 100% !important;
  }
  .slick-current {
    height: 100% !important;
  }
  .slick-active {
    height: 100% !important;
  }
  .slick-list {
    height: 100% !important;
  }
`;