import gql from 'graphql-tag';
import { VERTICAL_TYPE } from './VerticalType.gql';
import { EMPLOYEE_TYPE_CONNECTION } from './EmployeeTypeConnection.gql';

export const TEAM_TYPE = gql`
  ${VERTICAL_TYPE}, ${EMPLOYEE_TYPE_CONNECTION}
  fragment TeamType on TeamType {
    id
    name
    description
    vertical {
      ...VerticalType
    }
    managers {
      ...EmployeeTypeConnection
    }
    employees{
      ...EmployeeTypeConnection
    }
  }
`;
