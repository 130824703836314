import React from "react";
import styled from "styled-components";
import { Row, Col, Divider, Button, Pagination, Spin, Empty } from "antd";
import { Link } from "react-router-dom";

import { getIntFromString, MetaTags } from "../../look";
import { PAGINATION_LIMIT, IMG } from "../../../config";

import blogBook from "../../assets/blogbook.svg";

import ROUTES from "../route";

const AllStoriesView = (props) => {
  const {
    loading,
    pagination: { first, offset },
    allPublishedStories,
    onPaginationChange,
  } = props;

  const pagination = (totalCount) =>
    totalCount > 0 && (
      <Col span={24} align="right">
        <Pagination
          current={(offset + first) / first}
          responsive={true}
          defaultCurrent={1}
          onChange={(page, pageSize) =>
            onPaginationChange({
              offset: page * pageSize - pageSize,
              first: pageSize,
            })
          }
          showTotal={(total, range) =>
            totalCount &&
            `Showing ${range[0]} to ${range[1]} of ${total} entries.`
          }
          defaultPageSize={PAGINATION_LIMIT}
          total={totalCount}
        />
      </Col>
    );

  return (
    <>
      <MetaTags title="Stories" description={`This is Stories page`} />
      <Row justify="center">
        <Col span={18} align="center">
          <NewDivider style={{ borderColor: "#7cb305" }}>
            <h2>Stories & Blogs</h2>
          </NewDivider>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={10}>
          <h3 style={{ textAlign: "center" }}>
            {/* Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries fore */}
          </h3>
        </Col>
      </Row>
      <br />
      <br />
      <Row justify="center">
        <Col span={20}>
          <Col span={24}>
            <div align="center">
              <br />
              <br />
              <Empty description="No Published Stories!" />
              <br />
              <br />
            </div>
          </Col>
          {/* <Row gutter={[34, 34]}>
            <Col lg={0} md={24} xs={0} style={{ minHeight: "35vh" }}>
              <iframe
                src="https://drive.google.com/file/d/1C3XHOdl6sELBmTGDH0CB9QkKqLMnk9ZX/preview"
                style={{ width: "100%", height: "100%", borderRadius: "15px" }}
                title="small"
                alt="col-12"
              />
            </Col>
            <Col lg={12} md={0} xs={24} style={{ minHeight: "35vh" }}>
              <iframe
                src="https://drive.google.com/file/d/1C3XHOdl6sELBmTGDH0CB9QkKqLMnk9ZX/preview"
                style={{ width: "100%", height: "100%", borderRadius: "15px" }}
                title="small"
                alt="col-12"
              />
            </Col>
            <Col lg={12} xs={24}>
              <Row
                gutter={[34, 20]}
                style={{
                  backgroundImage: `url(${blogBook})`,
                  backgroundSize: "cover",
                }}
              >
                {allPublishedStories &&
                  [
                    ...Array(
                      allPublishedStories.edges.length < 2
                        ? allPublishedStories.edges.length
                        : 2
                    ).keys(),
                  ].map((index) => {
                    const { node } =
                      allPublishedStories && allPublishedStories.edges[index];
                    return (
                      <>
                        <Col span={24}>
                          <Link
                            to={`${ROUTES.storyDetailLink}${getIntFromString(
                              node.id
                            )}`}
                          >
                            <Row gutter={[34, 34]}>
                              <Col span={10}>
                                <img
                                  src={node.thumbnailImage || IMG}
                                  // src={
                                  //   node.img
                                  //     ? node.img
                                  //     : 'https://brainayan.com/wp-content/uploads/2020/04/Brainayan-Blog_MApp-in-Trying-Times.jpg'
                                  // }
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: "15px",
                                  }}
                                  alt="col-7"
                                />
                              </Col>
                              <Col span={14} style={{ alignSelf: "center" }}>
                                <h3>{node.title}</h3>
                              </Col>
                            </Row>
                          </Link>
                        </Col>
                      </>
                    );
                  })}
              </Row>
            </Col>
          </Row> */}
          <br />

          <Row>
            <br />
            <Divider />
          </Row>
          <Row justify="center">
            <Col lg={8} xs={0} />
            <Col lg={8} xs={24} align="center">
              <br />
              <h2>Other Blogs</h2>
            </Col>
            <Col lg={8} xs={0} align="right">
              <Button size="large" type="link" disabled={true}>
                {"View more > >"}
              </Button>
            </Col>
          </Row>
          <br />
          <Spin spinning={loading} size="md">
            <Row gutter={[44, 44]}>
              {allPublishedStories?.edges?.length > 0 ? (
                allPublishedStories?.edges?.map(({ node }) => (
                  <Col lg={8} xs={24}>
                    <Link
                      to={`${ROUTES.storyDetailLink}${getIntFromString(
                        node.id
                      )}`}
                    >
                      <img
                        src={node.thumbnailImage || IMG}
                        style={{
                          width: "100%",
                          height: "70%",
                          borderRadius: "15px",
                        }}
                        alt="col-8"
                      />
                      <h3 /* style={{ opacity: blog.id !== 1 && 0 }} */>
                        <br />
                        {node.title}
                      </h3>
                    </Link>
                  </Col>
                ))
              ) : (
                <Col span={24}>
                  <div align="center">
                    <br />
                    <br />
                    <Empty description="No Published Stories!" />
                    <br />
                    <br />
                  </div>
                </Col>
              )}
            </Row>
          </Spin>
        </Col>
        {allPublishedStories && pagination(allPublishedStories.totalCount)}
        <br />
        <br />
        <br />
      </Row>
    </>
  );
};

export default AllStoriesView;

const NewDivider = styled(Divider)`
  border-color: #7cb305;
  width: 50%;
  min-width: 50%;
  @media only screen and (max-width: 480px) {
    width: 100%;
  }
`;
