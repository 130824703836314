import gql from 'graphql-tag';
import { VERTICAL_TYPE } from './VerticalType.gql';

export const VERTICAL_QUERY = gql`
  ${VERTICAL_TYPE}
  query vertical($id: ID!) {
    vertical(id: $id) {
      ...VerticalType
    }
  }
`;
