// @ts-nocheck
import { Button, Col, Form, Input, Popover, Row, Select, Spin } from "antd";
import { getIntFromString } from "modules/look";
import React, { useState } from "react";
import Slider from 'react-slick';
import modal_close from '../../assets/badge-modal-close.svg';
import drop_black from '../../assets/drop-arrow-black.svg';
import left_arrow from '../../assets/left-active-carosul.svg';
import right_arrow from '../../assets/right-active-carosul.svg';
import search_icon from '../../assets/search-icon.svg';
import "../../kudos/kudos.css";

const GiveKudosView = (props) => {
    const { onSearch, badgeList, loading, setGivenKudos, setGiveKudosPopUpVisible, setFeedbackOperationsModalVisible, setFeedbackEditModalVisible
        , feedbackById, setBadgeAwardId, setNewKudosAdded, getbadgeFailyList, onFilter, getBageFamilyById, badge_family_list, setSelectedFamily, getBadgesByFamily } = props

    const [search_toggle, setSearchToggle] = React.useState(false)
    const [filter_value, setFilterValue] = React.useState()
    const [selectedBadge, setSelectedBadge] = React.useState()
    const Option = Select.Option;
    const [form] = Form.useForm();



    const badgeSelectHandler = (node) => {
        setSelectedBadge(node)
        setNewKudosAdded(true)
    }

    const filterSearch = (value) => {
        if (value) {
            setSelectedBadge(null)
            getbadgeFailyList({ title_Icontains: value })
        }
        else {
            getbadgeFailyList({ title_Icontains: "" })
        }

    }

    const handleFamilyFilter = (e) => {

        setFilterValue(e)
        setSelectedBadge(null)
        setSelectedFamily(e)
        getBadgesByFamily({ badgeFamily: e })
        getBageFamilyById({ id: e })
        filterSearch(null)
    }


    const settings = {
        dots: false,
        infinite: true,
        autoplay: false,
        speed: 500,
        arrows: true,
        nextArrow: <img src={right_arrow} />,
        prevArrow: <img src={left_arrow} />,
        slidesToShow: 4,
        slidesToScroll: 4,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1050,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                },
            },
            {
                breakpoint: 710,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 310,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const filter_list = badge_family_list &&
        badge_family_list?.map(({ node }, key) => (
            <Option
                key={key}
                value={node?.id}
            >
                {node?.title}
            </Option>
        ))

    const handleBadgeSearch = (event) => {
        if (event?.target?.value) {
            onSearch({ title_Icontains: event?.target?.value, behaviouralDescription_Icontains: event?.target?.value, badgeFamily: filter_value })
        }
        else {
            onSearch({ title_Icontains: "", behaviouralDescription_Icontains: "", badgeFamily: filter_value })
        }
    }


    const TooltipData = (node) => {
        return (
            <div style={{ width: "100%", maxWidth: "400px", minWidth: "180px" }}>
                <h4 style={{ fontSize: '1.2em', fontWeight: "500", color: "rgba(0, 0, 0, 0.71)", margin: "0" }}>{node?.title}</h4>
                <p style={{ color: "#8C8CA2", fontSize: "1em", margin: "0", textAlign: 'justify' }}>{node?.behaviouralDescription}</p>
            </div>
        )
    }

    const handleSubmit = async (value) => {

        let badgeAwardData = {
            badgeId: getIntFromString(selectedBadge?.id),
            evidence: selectedBadge?.title,
            image: selectedBadge?.image,
            title: selectedBadge?.title,
            behaviouralDescription: selectedBadge?.behaviouralDescription
        }
        let response = setGivenKudos(badgeAwardData)
        if (response) {
            setSelectedBadge()
        }
        if (badgeAwardData) {
            handleModalClosure()
            setBadgeAwardId(feedbackById?.badgeAward?.edges[0]?.node?.id)
        }
    }

    const handleModalClosure = () => {
        setGiveKudosPopUpVisible(false)
        if (feedbackById) {
            setFeedbackEditModalVisible(true)
        } else {
            setFeedbackOperationsModalVisible(true)
        }
        setSelectedBadge(undefined)
    }

    return (
        <>
            <div className="user-badge-root-class" style={{ width: "100%", display: "flex", flexDirection: "column", gap: "10px", justifyContent: 'center', alignItems: "center", position: "relative" }}>
                <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: 'center', gap: '1em', width: '100%' }}>
                    <h4 className="user-badge-award-popup-title" style={{ fontWeight: "700", textAlign: "left" }}>Award Kudos</h4>
                    <img src={modal_close} alt="" style={{ cursor: "pointer" }} onClick={() => { handleModalClosure() }} />
                </div>
                <div className="feedback-badge-award-carousel user-badge-award-popup-overflow user-badge-award-carosul-second-container" style={{ overflow: 'auto', maxHeight: "500vh", padding: "1em", width: "100%" }}>
                    <Row justify="start" style={{ width: '100%' }}>
                        <div className="user-badge-award-popup-input-div feedbaack-give-kudos-dropdown" style={{ display: 'flex', flexDirection: "row",width:"100%"}}>
                            {!search_toggle || filter_value ? <Select
                                allowClear
                                onClear={(e) => onFilter()}
                                showSearch
                                className="filter-select-user-select"
                                name={'filter'}
                                id={'filter'}
                                placeholder={`Select`}
                                suffixIcon={<img src={drop_black} alt="" style={{ width: "10px", height: "10px", cursor: "pointer" }} onClick={() => setSearchToggle(false)} />}
                                style={{ width: filter_value && search_toggle ? '40%' : '100%', maxWidth: "100%", textOverflow: 'ellipsis', whiteSpace: "nowrap",maxWidth:"20em" }}
                                {...props}
                                onSelect={handleFamilyFilter}
                                onSearch={filterSearch}
                                onMouseEnter={() => { setSearchToggle(false) }}
                                filterOption={(input, option) => option}
                            >
                                {filter_list}
                            </Select> : <div style={{ borderRadius: "8px 0px 0px 8px", border: "1px solid #8F8D8D", height: "40px", padding: "8px 10px" }}>
                                <img src={drop_black} alt="" style={{ width: "10px", height: "10px", cursor: "pointer" }} alt="" onClick={() => setSearchToggle(false)} />
                            </div>}
                            <div className="search-input-user-kudos" style={{ display: 'flex', flexDirection: 'row' }}>
                                {search_toggle && <Input placeholder="Search kudos name here" autoComplete='off' style={{ width: "100%", border: "none" }} onChange={(e) => handleBadgeSearch(e)}></Input>}
                                <img src={search_icon} alt="" style={{ cursor: 'pointer', transform: " rotate(-270deg)", height: '20px', marginTop: "10px", marginRight: '7px', marginLeft: '7px' }} onClick={() => setSearchToggle(true)} />
                            </div>
                        </div>
                    </Row>
                    <h4 className="user-badge-award-selected-description-modal" style={{ width: "100%",paddingTop: "10px", fontWeight: "400",textAlign:"left" }}>Select a Kudos</h4>
                    <div style={{ marginTop: "10px" }} className="slider-container">
                        <Spin spinning={loading}>
                            {loading && <div className="user-badge-award-carosul-container-loading feedback-badge-award-carosul-container-loading"></div>}
                            {badgeList?.length > 0 && !loading && <Slider {...settings} className="user-badge-award-carosul-container feedback-badge-award-carosul-height">
                                {badgeList?.length && badgeList?.map(({ node }, index) => (
                                    <div key={node?.id}>
                                        {/* <Popover content={TooltipData(node)} placement="bottom" overlayClassName="badge-popover"> */}
                                        <img className={node?.id == selectedBadge?.id ? "feedback-award-badge-image-selected" : "feedback-award-badge-image"} src={node?.image} alt="" onClick={() => badgeSelectHandler(node)} />
                                        {/* </Popover> */}
                                    </div>
                                ))}
                            </Slider>}
                            {badgeList?.length == 0 && loading == false &&
                                <div className="user-badge-award-carosul-container" style={{ width: "100%", display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: "40px" }}>
                                    <h4 className="recived-award-image-title" style={{ margin: 'auto', justifyContent: 'center', textAlign: "center" }}>No Kudos Found</h4>
                                </div>
                            }
                        </Spin>
                    </div>

                    {selectedBadge ? <div>
                        {/* <h4 style={{margin:'18px 0px'}}><span className="filter-badge-family-title">{selectedBadge?.badgeFamily?.title} : </span><span className="filter-badge-family-para"> {selectedBadge?.badgeFamily?.description}</span></h4> */}
                        <div style={{display:'flex',flexDirection:"row",justifyContent:"start",alignItems:"start",gap:"1em",width:"100%"}}>
                            <div className="feedback-badge-award-carosul-second-section-image-container">
                                <img className="user-badge-award-carosul-second-section-image" src={selectedBadge?.image} alt="" />
                            </div>
                            <div>
                                <h4 className="user-badge-award-selected-title-modal" style={{ color: "#000", fontWeight: "600",margin:"0px"}}>1. {selectedBadge?.title}</h4>
                                <p className="user-badge-award-selected-description-modal" style={{ fontSize: ".85em", fontWeight: "400" ,margin:"0px",whiteSpace:'wrap',color:"#000"}}>{selectedBadge?.behaviouralDescription}</p>
                                {/* <p>{selectedBadge?.duration}</p> */}
                            </div>
                        </div>
                        <Form
                            form={form}
                            name={"competency"}
                            style={{ padding: "10px 0px 0px 0px", width: "100%" }}
                            onFinish={() => { handleSubmit() }}
                            className="formLabel"
                        >
                            <Row justify="center" align="center" style={{ width: "100%", marginTop: "10px" }}>
                                <Button className="save-btn" htmlType="submit" disabled={!selectedBadge}>
                                    Save
                                </Button>
                            </Row>
                        </Form>
                    </div> : null}
                </div>

            </div>


        </>
    )
}

export default GiveKudosView

