// @ts-nocheck
import React from "react";
import CreateMainCompetecnyView from "../components/CreateMainCompetencyView";
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { message } from "antd";

const CreateMainCompetecny = ( props ) => {

    const {onCancel}=props

    const [ mutationData, setmutationData ] = React.useState();
    const [ comploading, setcomploading ] = React.useState( false );

    const COMPETENCY_DYNAMIC = gql`
        mutation createCompetencyCategory {
            ${mutationData?.map(
        ( item, index ) =>
            `create${index}: createCompetencyCategory(competencyCategoryData:{title:"${item?.title}",competencyType:"${item?.competencyType}"}) {
                        competencyCategory{
                            id
                        }
                    }
                `
    ).join( '' )}
        }
    `
    const [ competencyDefinition, { data, isloading, error } ] = useMutation( COMPETENCY_DYNAMIC );

    React.useEffect( () => {

        if ( mutationData && mutationData?.length ) {
            competencyMutation()
        }
    }, [ mutationData ] )

    const competencyMutation = async () => {
        setcomploading( true )
        try{
        const response = await competencyDefinition()
        // new to do send back the response as props
        if ( response ) {
            setcomploading( false )
            message.success( "Submitted successfully" )
            onCancel()
        } else {
            setcomploading( false )
        }
    }
    catch ( err ) {
        if(err.message.includes("unique_CompetencyCategory")){
            message.error("selected competency already exists")
        }
        setcomploading( false )
    }

    }


    return (
        <>
            <CreateMainCompetecnyView
                comploading={comploading}
                competencyList={( value ) => { setmutationData( value )}}
                cancel ={()=>onCancel()}
            />
        </>
    )
}

export default CreateMainCompetecny