// @ts-nocheck
import React from 'react';
import { compose } from '../../core';
// import { withToolById } from "../../tool/containers/ToolOperations";
import { employeeList,withKeyResults,withFeedBackSurveyByid,UseremployeeList } from '../../feedback/containers/feedbackOperations'
import { withApollo } from '@apollo/client/react/hoc'
import { useHistory } from 'react-router-dom';
import { USER_EMPLOYEE_SEARCH } from '../../feedback/graphql/searchEmployeee.gql';
import {CONTRIBUTOR_OKR_LIST} from '../../feedback/graphql/ContributerOkrList.gql'
import OneOnOneFeedbackPostView from '../components/OneononeFeedbackPostView';
const OneononeFeedbackPostContainer = props => {
    const { client,allEmployee,me,permission} = props
    const history = useHistory();
    const [okrByrecipiant,setokrByrecipiant]= React.useState([]); 
    const [allOrganizationEmployees,setEmployees] = React.useState()
    const [loading,setLoading]=React.useState(false)

    const getOKRByRecepiant = async (filter) => {

      const { data } = await client.query({
          query: CONTRIBUTOR_OKR_LIST,
          variables: filter,
      });
      if(data?.contributors){
        setokrByrecipiant(data?.contributors)
      }
      
  }
 
    

    const handleSearch = async(value)=>{
      const { data } = await client.query({
        query: USER_EMPLOYEE_SEARCH,
        variables: { user_FirstName: value,user_LastName:value },
        fetchPolicy: 'network-only'
        });
        if(data?.first && data?.last){
         let user=data?.first.edges.concat(data?.last?.edges)
         var setObj = new Set(); // create key value pair from array of array
         var result = user.reduce((acc,item)=>{
           if(!setObj.has(item.node?.user?.id)){
             setObj.add(item.node?.user?.id)
             acc.push(item)
           }
           return acc;
         },[])

          setEmployees({edges:result})
        }
    }

    React.useEffect(()=>{
      if(allEmployee){
        setEmployees(allEmployee)
      }
    },[allEmployee])
  return (
    <>
    <OneOnOneFeedbackPostView  {...props} 
      history={history}
      onSelectRecepiant={(e) => getOKRByRecepiant(e)} 
      allOrganizationEmployees={allOrganizationEmployees}
      handleSearch={(e)=>{handleSearch(e)}}
      okrSearch={(e) => getOKRByRecepiant(e)} 
      okrByrecipiant={okrByrecipiant}
      me={me}
    ></OneOnOneFeedbackPostView >
    </>
  );
};

export default compose(withApollo,withFeedBackSurveyByid)(OneononeFeedbackPostContainer);
