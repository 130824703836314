// @ts-nocheck
import React from 'react';
import { compose } from '../../core';
import { VERTICAL_OKR_PERFORMANCE } from '../graphql/VerticalDashboardQueries';
import { displayUtcToUserTimezone, getIntFromString } from 'modules/look';
import VerticalPerfrmanceView from '../components/VerticalOkrPerformanceView';
const VerticalOkrPerformanceContainer = props => {
  const {me,client,dateRangeTofilter,verticalDetail,teams}=props
  const [okrPerformanceForVertical, setokrPerformanceForVertical] = React.useState();
  const [okrPerformanceForTeam,setokrPerformanceForTeam] = React.useState();
  const [performanceLoader, setperformanceLoader] = React.useState(true);
  const [team_manager,setteam_manager] = React.useState();
  let cachedDataForTeams = React.useRef()
  React.useEffect(()=>{
    if(verticalDetail && me && dateRangeTofilter){
      setokrPerformanceForVertical(null)
      setokrPerformanceForTeam(null)
      getOkrPerformanceForvertical(5,0)
    }
  },[verticalDetail,me,dateRangeTofilter])

  React.useEffect(()=>{
    if(verticalDetail){
      setteam_manager(null)
      cachedDataForTeams['current']=null
    }
  },[verticalDetail])

  // new to do clear dbt regarding createdBy_ne
  const getOkrPerformanceForvertical = async (first,offset,pagination=false) => {
    setperformanceLoader(true)
    const { data } = await client.query({
      query: VERTICAL_OKR_PERFORMANCE,
      variables: {
        okr_Duedate_Range: dateRangeTofilter,
        role_In: ["COLLABORATOR", "ASSIGNED"],
        managerId:verticalDetail?.heads?.edges?.map(({node})=>getIntFromString(node?.id)) || [],
        savedOkrType: "OBJECTIVE",
        offset: offset,
        first: first,
        orderBy: ["okr__duedate"]
      },
      // fetchPolicy: 'network-only'
    });

    if (data?.performance) {
        let performanceData
       if(!pagination){
        performanceData ={
          totalCount:data?.performance?.totalCount,
          edgeCount:data?.performance?.edgeCount,
          edges:data?.performance?.edges?.map(({node})=>{
            return {
              id:node?.okr?.id,
              title:`${node?.okr?.goalStatement} by ${displayUtcToUserTimezone(node?.okr?.duedate,'DD MMM YY')}`,
              progress:node?.progress,
              okrType:node?.okr?.okrType,
              isOkr:true
            }
          })
        }
       }else{
        performanceData ={
          totalCount:data?.performance?.totalCount,
          edgeCount:data?.performance?.edgeCount+okrPerformanceForVertical?.edgeCount,
          edges:[...okrPerformanceForVertical?.edges,
          ...data?.performance?.edges?.map(({node})=>{
            return {
              id:node?.okr?.id,
              title:`${node?.okr?.goalStatement} by ${displayUtcToUserTimezone(node?.okr?.duedate,'DD MMM YY')}`,
              progress:node?.progress,
              okrType:node?.okr?.okrType,
              isOkr:true
            }
          })]
        }
       }
       setokrPerformanceForVertical(performanceData)
        setperformanceLoader(false)
    }
  }

  React.useEffect(()=>{
    if(teams){
      let teamsList = teams?.map(({node})=>{
        return {
          id:node?.id,
          title:node?.name,
          managers:getTeamManagers(node?.managers)
        }
      })
      setteam_manager(teamsList)

    }
  },[teams])
  
  const getTeamManagers=(managers)=>{
    let list = managers?.edges?.map(({node})=>getIntFromString(node?.id))
    return list || []
  }
  
  const getOkrPerformanceForTeam = async (first,offset,pagination=false) => {
    setperformanceLoader(true)
    const { data } = await client.query({
      query: VERTICAL_OKR_PERFORMANCE,
      variables: {
        okr_Duedate_Range: dateRangeTofilter,
        role_In: ["COLLABORATOR", "ASSIGNED"],
        managerId: [].concat.apply([],team_manager?.map(item=>item?.managers)),
        savedOkrType: "OBJECTIVE",
        offset: offset,
        first: first,
        orderBy: ["okr__duedate"]
      },
      // fetchPolicy: 'network-only'
    });

    if (data?.performance) {
      if(!pagination){
        cachedDataForTeams['current']=data?.performance
      }else{
        cachedDataForTeams['current']={edges:[...cachedDataForTeams['current']?.edges,...data?.performance?.edges]}
      }
      
     
        let performanceData 
        const edges= getTeamWiseProgress(!pagination?data?.performance:cachedDataForTeams['current'])
       if(!pagination){
        performanceData ={
          totalCount:data?.performance?.totalCount,
          edgeCount:data?.performance?.edgeCount,
          edges:edges
        }
       }else{
        performanceData ={
          totalCount:data?.performance?.totalCount,
          edgeCount:data?.performance?.edgeCount+okrPerformanceForTeam?.edgeCount,
          edges:edges
        }
       }
       setokrPerformanceForTeam(performanceData)
        setperformanceLoader(false)
    }
  }

  const getTeamWiseProgress=(data)=>{
    let teamlistwithOKR = []
    team_manager.forEach(item=>{
      let filter_data = data?.edges?.filter(({node})=>item?.managers?.includes(getIntFromString(node?.employee?.id)))
      teamlistwithOKR.push({...item,contributorSet:filter_data?.map(({node})=>node)})
    })
    let progress = teamlistwithOKR?.map(item=>{
      return{
        ...item,
        progress:getProgress(item?.contributorSet)
      }
    })
    return progress
  }

  const getProgress=(set)=>{
    if(!set?.length){
      return 0
    }
    let data =set?.map(item=>item?.progress)
    let progress =data.reduce((a, b) => a+ b, 0)
    return (!progress || isNaN(progress) || isNaN(progress/data?.length))?0:progress/data?.length
  }
  
  return (
    <>
    <VerticalPerfrmanceView 
        setperformanceLoader={(e)=>setperformanceLoader(e)} 
        performanceLoader={performanceLoader} 
        okrPerformanceForVertical={okrPerformanceForVertical}
        getOkrPerformanceForvertical={(first,offset,pagination)=>{getOkrPerformanceForvertical(first,offset,pagination)}}
        okrPerformanceForTeam={okrPerformanceForTeam}
        getOkrPerformanceForTeam={(first,offset,pagination)=>{getOkrPerformanceForTeam(first,offset,pagination)}}
        team_manager={team_manager}
       {...props}/>
    </>
  );
};

export default compose()(VerticalOkrPerformanceContainer);
