import gql from 'graphql-tag';

export const BADGE_AWARD_LIST = gql`
query badgeAwards($recipient: ID){
    badgeAwards(recipient: $recipient) {
        edges {
          node {
            id   
            issuer {    
              id    
              user{
                id
                firstName
                lastName
              }
            }    
            recipient {    
              id    
              user{
                id
                firstName
                lastName
              }
            }     
            evidence   
            expirationDate    
            badge{   
              id 
              image    
              title    
              behaviouralDescription   
            }    
          }    
        }    
    }
}
`