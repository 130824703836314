import { Button, Modal } from "antd";
import React from "react";
import { learningPathIcons } from "../icons";
import readiness_close_icon from '../../assets/readiness-close-icon.svg'
const VideoPreviewView = (props) => {
    const { videoUrl, show_video, onClose,navigateRoute,survey_statuses } = props
    const [play_video, setPlayVideo] = React.useState(false)
    const findFirstTotalCountZero = (obj) => {
        for (let key in obj) {
            if (obj[key].totalCount === 0) {
                return key;
            }
        }
        return null;
    };
    const skipVideo =()=>{
        if(findFirstTotalCountZero(survey_statuses)==show_video?.key){
            if(show_video?.path){
                localStorage.setItem('to_leraning',show_video?.key)
                navigateRoute(show_video?.path);
            }
            else{
                onClose();
                setPlayVideo(false);
            }
        }
        else{
            onClose();
            setPlayVideo(false);
        }
        
    }
    return (
        <Modal
            visible={show_video}
            footer={false}
            closable={false}
            width={"90%"}
            centered={true}
            destroyOnClose={true}
            className='video-preview-modal'>
            <div style={{ width: "100%" }}>
                <div className='video-preview-container'>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: '100%',marginBottom:"2em",marginRight:"-2em" }}>
                        <div></div>
                        <img src={readiness_close_icon} alt="readiness_close_icon" onClick={() =>{onClose();setPlayVideo(false)}} style={{ cursor: "pointer" }} />
                    </div>
                    {!play_video ? <div className='preview-video' style={{ width: "100%", background: "#E5F9FF", display: 'flex', justifyContent: 'center', alignItems: "center", position: "relative" }}>
                        {show_video?.show_skip&&<Button className="learn-skip-btn"
                            style={{ position: "absolute", bottom: "2em", right: "2em" }}
                            onClick={() =>skipVideo()}>Skip</Button>}
                        <img src={learningPathIcons.learn_play_icon_black} alt="" style={{ height: "5em", cursor: "pointer" }} onClick={() => setPlayVideo(true)} />
                    </div> :
                        <video autoPlay={true} controls={true} controlsList="nodownload" poster={show_video?.videoUrl} style={{width:'100%',height:'100%',borderRadius:"1em"}}>
                            <source src={show_video?.videoUrl} type="video/mp4" style={{ width: "100%" }} />
                        </video>}
                </div>

            </div>

        </Modal>
    )
}

export default VideoPreviewView;