import { withShortcut } from 'react-keybind';
import styled from 'styled-components';
import { Modal, Row, Col, Tag, Space } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { getTypeFromQuestion } from './SectionComponent';

const SurveyQuizShortcuts = props => {
  const { onNext, onPrev, setValueFromShortcut, currentMatrixQuestion, visible, setVisible } = props;
  const ref = React.useRef();

  const [state, setState] = useState({
    isSaved: false,
    totalFiles: 0
  });

  const next = useCallback(
    e => {
      e.preventDefault();
      console.log('Going next...');
      onNext();
    },
    [state]
  );
  const prev = useCallback(
    e => {
      e.preventDefault();
      console.log('Going prev...');
      onPrev();
    },
    [state]
  );

  const onKeyA = e => {
    e.preventDefault();
    console.log('Selecting A...');
    setValueFromShortcut('a');
  };
  const onKeyB = useCallback(
    e => {
      e.preventDefault();
      console.log('Selecting B...');
      setValueFromShortcut('b');
    },
    [state]
  );
  const onKeyC = useCallback(
    e => {
      e.preventDefault();
      console.log('Selecting C...');
      setValueFromShortcut('c');
    },
    [state]
  );
  const onKeyD = useCallback(
    e => {
      e.preventDefault();
      console.log('Selecting D...');
      setValueFromShortcut('d');
    },
    [state]
  );
  const onKeyE = useCallback(
    e => {
      e.preventDefault();
      console.log('Selecting E...');
      setValueFromShortcut('e');
    },
    [state]
  );
  const onKeyF = useCallback(
    e => {
      e.preventDefault();
      console.log('Selecting F...');
      setValueFromShortcut('f');
    },
    [state]
  );
  const onKeyG = useCallback(
    e => {
      e.preventDefault();
      console.log('Selecting G...');
      setValueFromShortcut('g');
    },
    [state]
  );
  const onKeyH = useCallback(
    e => {
      e.preventDefault();
      console.log('Selecting H...');
      setValueFromShortcut('h');
    },
    [state]
  );
  const onKeyI = useCallback(
    e => {
      e.preventDefault();
      console.log('Selecting I...');
      setValueFromShortcut('i');
    },
    [state]
  );
  const onKeyJ = useCallback(
    e => {
      e.preventDefault();
      console.log('Selecting J...');
      setValueFromShortcut('j');
    },
    [state]
  );
  const onKeyK = useCallback(
    e => {
      e.preventDefault();
      console.log('Selecting K...');
      setValueFromShortcut('k');
    },
    [state]
  );
  const onKeyL = useCallback(
    e => {
      e.preventDefault();
      console.log('Selecting L...');
      setValueFromShortcut('l');
    },
    [state]
  );
  const onKeyM = useCallback(
    e => {
      e.preventDefault();
      console.log('Selecting M...');
      setValueFromShortcut('m');
    },
    [state]
  );
  const onKeyN = useCallback(
    e => {
      e.preventDefault();
      console.log('Selecting N...');
      setValueFromShortcut('n');
    },
    [state]
  );
  const onKeyO = useCallback(
    e => {
      e.preventDefault();
      console.log('Selecting O...');
      setValueFromShortcut('o');
    },
    [state]
  );
  const onKeyP = useCallback(
    e => {
      e.preventDefault();
      console.log('Selecting P...');
      setValueFromShortcut('p');
    },
    [state]
  );
  const onKeyQ = useCallback(
    e => {
      e.preventDefault();
      console.log('Selecting Q...');
      setValueFromShortcut('q');
    },
    [state]
  );
  const onKeyR = useCallback(
    e => {
      e.preventDefault();
      console.log('Selecting R...');
      setValueFromShortcut('r');
    },
    [state]
  );
  const onKeyS = useCallback(
    e => {
      e.preventDefault();
      console.log('Selecting S...');
      setValueFromShortcut('s');
    },
    [state]
  );
  const onKeyT = useCallback(
    e => {
      e.preventDefault();
      console.log('Selecting T...');
      setValueFromShortcut('t');
    },
    [state]
  );
  const onKeyU = useCallback(
    e => {
      e.preventDefault();
      console.log('Selecting U...');
      setValueFromShortcut('u');
    },
    [state]
  );
  const onKeyV = useCallback(
    e => {
      e.preventDefault();
      console.log('Selecting V...');
      setValueFromShortcut('v');
    },
    [state]
  );
  const onKeyW = useCallback(
    e => {
      e.preventDefault();
      console.log('Selecting W...');
      setValueFromShortcut('w');
    },
    [state]
  );
  const onKeyX = useCallback(
    e => {
      e.preventDefault();
      console.log('Selecting X...');
      setValueFromShortcut('x');
    },
    [state]
  );
  const onKeyY = useCallback(
    e => {
      e.preventDefault();
      console.log('Selecting Y...');
      setValueFromShortcut('y');
    },
    [state]
  );
  const onKeyZ = useCallback(
    e => {
      e.preventDefault();
      console.log('Selecting Z...');
      setValueFromShortcut('z');
    },
    [state]
  );

  const onVisibility = useCallback(
    e => {
      e.preventDefault();
      console.log('Showing shortcut list...');
      const visiblity = ref.current.innerHTML === 'true' ? true : false;
      setVisible(!visiblity);
      ref.current.innerHTML = !visiblity;
    },
    [state]
  );

  useEffect(() => {
    const { shortcut } = props;

    shortcut.registerShortcut(prev, ['ArrowUp'], 'Previous', 'Go previous step.');
    shortcut.registerShortcut(next, ['ArrowDown'], 'Next', 'Go next step');

    shortcut.registerShortcut(onKeyA, ['a'], 'Option A', 'Selects option A');
    shortcut.registerShortcut(onKeyB, ['b'], 'Option B', 'Selects option B');
    shortcut.registerShortcut(onKeyC, ['c'], 'Option C', 'Selects option C');
    shortcut.registerShortcut(onKeyD, ['d'], 'Option D', 'Selects option D');
    shortcut.registerShortcut(onKeyE, ['e'], 'Option E', 'Selects option E');
    shortcut.registerShortcut(onKeyF, ['f'], 'Option F', 'Selects option F');
    shortcut.registerShortcut(onKeyG, ['g'], 'Option G', 'Selects option G');
    shortcut.registerShortcut(onKeyH, ['h'], 'Option H', 'Selects option H');
    shortcut.registerShortcut(onKeyI, ['i'], 'Option I', 'Selects option I');
    shortcut.registerShortcut(onKeyJ, ['j'], 'Option J', 'Selects option J');
    shortcut.registerShortcut(onKeyK, ['k'], 'Option K', 'Selects option K');
    shortcut.registerShortcut(onKeyL, ['l'], 'Option L', 'Selects option L');
    shortcut.registerShortcut(onKeyM, ['m'], 'Option M', 'Selects option M');
    shortcut.registerShortcut(onKeyN, ['n'], 'Option N', 'Selects option N');
    shortcut.registerShortcut(onKeyO, ['o'], 'Option O', 'Selects option O');
    shortcut.registerShortcut(onKeyP, ['p'], 'Option P', 'Selects option P');
    shortcut.registerShortcut(onKeyQ, ['q'], 'Option Q', 'Selects option Q');
    shortcut.registerShortcut(onKeyR, ['r'], 'Option R', 'Selects option R');
    shortcut.registerShortcut(onKeyS, ['s'], 'Option S', 'Selects option S');
    shortcut.registerShortcut(onKeyT, ['t'], 'Option T', 'Selects option T');
    shortcut.registerShortcut(onKeyU, ['u'], 'Option U', 'Selects option U');
    shortcut.registerShortcut(onKeyV, ['v'], 'Option V', 'Selects option V');
    shortcut.registerShortcut(onKeyW, ['w'], 'Option W', 'Selects option W');
    shortcut.registerShortcut(onKeyX, ['x'], 'Option X', 'Selects option X');
    shortcut.registerShortcut(onKeyY, ['y'], 'Option Y', 'Selects option Y');
    shortcut.registerShortcut(onKeyZ, ['z'], 'Option Z', 'Selects option Z');

    shortcut.registerShortcut(onVisibility, ['ctrl+/'], 'Shortcut list', 'Show all the shortcuts list');
    return () => {
      const { shortcut } = props;

      shortcut.unregisterShortcut(['ArrowUp']);
      shortcut.unregisterShortcut(['ArrowDown']);

      shortcut.unregisterShortcut(['a']);
      shortcut.unregisterShortcut(['b']);
      shortcut.unregisterShortcut(['c']);
      shortcut.unregisterShortcut(['d']);
      shortcut.unregisterShortcut(['e']);
      shortcut.unregisterShortcut(['f']);
      shortcut.unregisterShortcut(['g']);
      shortcut.unregisterShortcut(['h']);
      shortcut.unregisterShortcut(['i']);
      shortcut.unregisterShortcut(['j']);
      shortcut.unregisterShortcut(['k']);
      shortcut.unregisterShortcut(['l']);
      shortcut.unregisterShortcut(['m']);
      shortcut.unregisterShortcut(['n']);
      shortcut.unregisterShortcut(['o']);
      shortcut.unregisterShortcut(['p']);
      shortcut.unregisterShortcut(['q']);
      shortcut.unregisterShortcut(['r']);
      shortcut.unregisterShortcut(['s']);
      shortcut.unregisterShortcut(['t']);
      shortcut.unregisterShortcut(['u']);
      shortcut.unregisterShortcut(['v']);
      shortcut.unregisterShortcut(['w']);
      shortcut.unregisterShortcut(['x']);
      shortcut.unregisterShortcut(['y']);
      shortcut.unregisterShortcut(['z']);

      shortcut.unregisterShortcut(['ctrl+/']);
    };
  }, []);

  return (
    <>
      <span ref={ref} id="shortcut-visible" style={{ display: 'none' }}>
        false
      </span>
      <Modal visible={visible} footer={null} onOk={() => setVisible(false)} onCancel={() => setVisible(false)} centered>
        <Row justify="space-between">
          <Col>
            <h3>Keyboard shortcut</h3>
          </Col>
          <Col>
            <Tag>Ctrl + /</Tag>
            &nbsp; &nbsp;
          </Col>
        </Row>
        <br />
        <h4>Navigation</h4>
        <Row justify="space-between">
          <Col>Previous Question</Col>
          <Col>
            <Space>
              <Tag>&uarr;</Tag>
            </Space>
          </Col>
        </Row>
        <Smbr />
        <Row justify="space-between">
          <Col>Next Question</Col>
          <Col>
            <Space>
              <Tag>&darr;</Tag>
            </Space>
          </Col>
        </Row>
        <br />
        <h4>Question with options</h4>
        <Row justify="space-between">
          <Col>Selecting option a</Col>
          <Col>
            <Tag>a</Tag>
          </Col>
        </Row>
        <Smbr />
        <Row justify="space-between">
          <Col>Selecting option b</Col>
          <Tag>b</Tag>
        </Row>
        <Smbr />
        <Row justify="space-between">
          <Col>Selecting option c</Col>
          <Col>
            <Tag>c</Tag>
          </Col>
        </Row>
        <Smbr />
        <Row justify="space-between">
          <Col>Selecting option d</Col>
          <Col>
            <Tag>d</Tag>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col>and so on...</Col>
        </Row>
      </Modal>
    </>
  );
};

// Wrap all components that need shortcut capabilities with the "withShortcut" HOC
export default withShortcut(SurveyQuizShortcuts);

export function getShortCutObj(question) {
  const obj = {};
  if (question?.choiceSet?.edges?.length)
    question.choiceSet.edges.map(({ node }, idx) => {
      obj[String.fromCharCode(97 + idx)] = node?.value;
    });

  return {
    name: question?.id,
    type: getTypeFromQuestion(question),
    ...obj
  };
}
const Smbr = styled.div`
  margin-top: 8px;
`;
