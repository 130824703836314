import gql from 'graphql-tag';
import { FORM_SECTION_TYPE } from './FormSectionType.gql';

export const ADD_FORM_SECTION_MUTATION = gql`
  ${FORM_SECTION_TYPE}
  mutation createFormSection($formSectionData: FormSectionInput!, $referencefieldId: [Int], $simplefieldId: [Int]) {
    createFormSection(
      formSectionData: $formSectionData
      referencefieldId: $referencefieldId
      simplefieldId: $simplefieldId
    ) {
      formSection {
        ...FormSectionType
      }
    }
  }
`;
