import { Button, Form, Input, Select, message } from 'antd';
import React from 'react';
import { competency_icons } from '../icon';


const CreateMainCompetecnyView = (props) => {

    const { competencyList, comploading, cancel } = props
    const [form] = Form.useForm();
    const [competecny_list, setCompetecyList] = React.useState([])
    const [type, setType] = React.useState();
    const [invalidInput, setInvalidInput] = React.useState(false);

    const onSubmit = (value) => {
        if(!invalidInput){
        let list = competecny_list.concat(value.title)
        setCompetecyList(list)
        form.resetFields();
      }
    }


    const removeCompetency = (index) => {
        let item = [].concat(competecny_list)
        item.splice(index, 1)
        setCompetecyList(item)
    }

    const onSave = (list) => {
        if(!invalidInput){
            if (!type) {
                message.error("please select type")
            }
            let category_list = list?.map(i => {
                return {
                    title: i,
                    competencyType: type
                }
            })
            competencyList(category_list)
    
        }
    }

    const handleTitleInput = (e) => {

        if (e.target.value?.length > 200) {
            setInvalidInput(true)
        } else {
            setInvalidInput(false);
        }

    }

    return (
        <div className='rd-create-main-competency-container'>
            <img className='cp-modal-close-icon' style={{ width: '2em' }} src={competency_icons?.jf_modal_close} alt="" onClick={() => cancel()} />
            <Form
                form={form}
                onFinish={values => onSubmit(values)}
                scrollToFirstError={true}
                className='rd-align-center rd-add-edit-competency-container'
                style={{ gap: '.5em', marginTop: '1em' }}
            >
                <div style={{ display: "flex", flexDirection: "row", gap: "1em", width: "100%", justifyContent: "space-between", alignItems: 'center' }}>
                    <div className='rd-input-container'>
                        <h4 className='rd-input-title'><span className='rd-input-star'>*</span> Title</h4>
                        <Form.Item
                            name="title"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input title',
                                },
                            ]}
                            style={{ width: '100%' }}
                        >
                            <Input className="rd-text-input" onChange={(e) => handleTitleInput(e)} style={{ width: "100%" }} placeholder='Enter competency title' />
                        </Form.Item>
                    </div>

                    <Button disabled={!(type) || invalidInput} className='rd-add-plus-btn' style={{ marginRight: "0.25em" }} htmlType="submit">+</Button>
                </div>
                {
                    invalidInput &&
                    <p className='poppins' style={{ color: 'red', fontSize: '1em',marginTop:'-1.8em',position:'relative',right:'12em' }}>Whoops, looks like the title is bit too long! </p>
                }
                <div style={{ display: 'flex', flexDirection: "row", gap: ".5em", flexWrap: 'wrap', width: "100%" }}>
                    {competecny_list.map((item, index) => (
                        <div className='rd-selected-main-competecny-title-container'>{item} <img style={{ cursor: "pointer", width: ".875em" }} onClick={() => removeCompetency(index)} src={competency_icons?.comp_cancel} alt="" /></div>
                    ))}
                </div>

            </Form>
           
            <div className='rd-input-container'>
                <h4 className='rd-input-title'><span className='rd-input-star'>*</span> Type</h4>
                <Select
                    className="rd-select-filed rd-type-select"
                    style={{ width: '100%', marginTop: "10px" }}
                    suffixIcon={<img src={competency_icons?.dropdown_Arrow} alt="" style={{ width: "10px", height: "10px" }} />}
                    placeholder='select competency type'
                    onChange={(e) => { setType(e) }}>
                    <Select.Option
                        className='rd-select-option'
                        key={'Generic'}
                        value={'Generic'}
                    >
                        Generic Competency
                    </Select.Option>
                    <Select.Option
                        className='rd-select-option'
                        key={'Role_Specific'}
                        value={'Role_Specific'}
                    >
                        Functional competency(Role specific)
                    </Select.Option>
                </Select>
            </div>
            <div className='rd-confirmation-action-container' style={{ marginTop: "1em" }}>
                <Button className='global-submit-btn' disabled={(!competecny_list?.length || invalidInput) ? true : false} onClick={() => onSave(competecny_list)}>Save</Button>
                <Button className='global-cancel-btn' onClick={() => cancel()}>Cancel</Button>
            </div>
        </div>
    )
}
export default CreateMainCompetecnyView