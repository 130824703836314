import React from 'react';
import JobFamilyAddEditContainer from '../containers/JobFamilyAddEditContianer'
import JobFamilyHomeContainer from '../containers/JobFamilyHomeContainer';

const JobFamilyMainView = (props) => {

    const { current_jobfamily_tab } = props

    // list all view that are in the role definition
    const views = {
        jobfamily_home_view: JobFamilyHomeContainer,
        jobfamily_add_edit_view: JobFamilyAddEditContainer
    }
    const CurrentView = views[ Object.keys(views)?.includes(current_jobfamily_tab?.key)?current_jobfamily_tab?.key:'jobfamily_home_view' ]

    return (
        <CurrentView {...props} />
    )

}
export default JobFamilyMainView