import React from "react";
import MyProfileHomeView from "../components/my-profile-home-view";
import { globalPermissionValidator } from "modules/look";
import { ai_tip_settings_permission } from "Permissions/aiTipSettings.permissions";
import { Spin } from "antd";

const MyProfileHomeContainer =(props)=>{
    const {userPermission,location}=props
    const [tip_settings_permission, SetTipSettingsPermission] = React.useState()
    const [permission_loading,setPermissionLoading] = React.useState(true)
   
    React.useEffect(()=>{
    
        if (userPermission?.length) {
            let permission = globalPermissionValidator(
                ai_tip_settings_permission,
                userPermission);
                SetTipSettingsPermission(permission)
                setPermissionLoading(false)
           
        }
    },[userPermission])

    return(
        <>
            {!permission_loading?<MyProfileHomeView permission_loading={permission_loading} tip_settings_permission={tip_settings_permission} {...props}/>:
            <Spin spinning={true}>
                <div style={{height:"70vh"}}>
                </div>
            </Spin>
            }
        </>
    )
}

export default MyProfileHomeContainer;