import gql from 'graphql-tag';
import { TOOL_TYPE_ADMIN_PANEL } from './ToolByIdAdmin.gql';
import { TOOL_TYPE } from './ToolType.gql';

export const EDIT_TOOL = gql`
  ${TOOL_TYPE}
  mutation editTool(
    $commitment: [Int]
    $description: String
    $formsection: [Int]
    $id: ID!
    $organization: [Int]
    $public: Boolean
    $status: Boolean
    $team: [Int]
    $textsection: [Int]
    $thumbnailImage: String
    $title: String
    $uiFlow: String
    $vertical: [Int]
  ) {
    updateTool(
      commitment: $commitment
      description: $description
      formsection: $formsection
      id: $id
      organization: $organization
      public: $public
      status: $status
      team: $team
      textsection: $textsection
      thumbnailImage: $thumbnailImage
      title: $title
      uiFlow: $uiFlow
      vertical: $vertical
    ) {
      tool {
        ...ToolType
      }
    }
  }
`;


export const EDIT_TOOL_ADMIN_PANEL = gql`
  ${TOOL_TYPE_ADMIN_PANEL}
  mutation editTool(
    $commitment: [Int]
    $description: String
    $formsection: [Int]
    $id: ID!
    $organization: [Int]
    $public: Boolean
    $status: Boolean
    $team: [Int]
    $textsection: [Int]
    $thumbnailImage: String
    $title: String
    $uiFlow: String
    $vertical: [Int]
  ) {
    updateTool(
      commitment: $commitment
      description: $description
      formsection: $formsection
      id: $id
      organization: $organization
      public: $public
      status: $status
      team: $team
      textsection: $textsection
      thumbnailImage: $thumbnailImage
      title: $title
      uiFlow: $uiFlow
      vertical: $vertical
    ) {
      tool { 
           ...ToolType
      }
    }
  }
`;
