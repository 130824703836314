import { Button, Col, Modal, Row, Spin } from "antd";
import React, { useLayoutEffect } from "react";
import prev from '../../assets/kudos-prev.svg'
import next from '../../assets/kudos-next.svg'
import AwardDetailPopup from "../container/awardDetaailPopup";
import ShareModal from "./shareModalView";
import share from '../../assets/award-shared-icon.svg'
import next_arrow from '../../assets/next-arrow-active.svg'
import previous_arrow from '../../assets/previous-arrow-active.svg'
import RecentBadge from "./recentBadgeModalView";
import { setItem } from "modules/core";

const RecivedAwardsView = ( props ) => {
    const { recievedAwardList,loading,me } = props
    const [ total_page, settotal_page ] = React.useState( {} )
    const [ current_page, setcurrent_page ] = React.useState( 1 )
    const [ current_source, setCurrentSource ] = React.useState( [] )
    const [ column, setcolumn ] = React.useState()
    const [ selectedAward, setSelectedAward ] = React.useState()
    const [ visible, setVisible ] = React.useState( false )
    const [ shared, setShared ] = React.useState( false )
    const [ sharedValue, setSharedValue ] = React.useState()
    const [recentVisible,setRecentVisible]=React.useState(false)
    const [newBadgeList,setNewBadgeList]=React.useState()
    React.useEffect( () => {
        if ( recievedAwardList ) {
            let received_data =JSON.parse(localStorage.getItem("recieved_Badge"))
         
            if( received_data&&received_data[me?.employee?.user?.id]!=undefined||null){
                let data =received_data[me?.employee?.user?.id]
                let allNew =recievedAwardList?.map((item)=>item).filter(x=>!data?.includes(x?.id))
                if(allNew?.length>0){
                    setNewBadgeList(allNew)
                    setRecentVisible(true)
                }
            }
            else if(received_data==null||undefined&&recievedAwardList?.length){
                let allNew =recievedAwardList?.map((item)=>item)
                if(allNew?.length>0){
                    setNewBadgeList(allNew)
                    setRecentVisible(true)
                }
            }
            else{
                let local_storage_Data ={
                    [me?.employee?.user?.id]:recievedAwardList?.map(item=>item?.id)
                }
                localStorage.setItem("recieved_Badge",JSON.stringify(local_storage_Data))
            }
            let total_page = Math.ceil( recievedAwardList?.length / ( 8 ) )
            settotal_page( total_page )
            let current_page_data = recievedAwardList?.slice( ( current_page - 1 ) * ( 8 ), current_page * ( 8 ) )

            setCurrentSource( current_page_data )
            // HandleDataSource( recievedAwardList, current_page > total_page ? total_page : current_page, null )
        }
    }, [ recievedAwardList ] )


    const HandleDataSource = ( dataSource, page, prev = null ) => {
        setcurrent_page( page )
        let current_page_data = dataSource?.slice( ( page - 1 ) * ( 8 ), page * ( 8 ) )
        setCurrentSource( current_page_data )

    }

    const showDetal = ( value ) => {
        if ( value ) {
            setSelectedAward( value )
            setVisible( true )
        }
    }

    const ShareAwardSocialMedia = ( value ) => {
        setSharedValue( value )
        setShared( true )
    }

    const onCancelRecent =()=>{
        let local_storage_Data ={
            [me?.employee?.user?.id]:recievedAwardList?.map(item=>item?.id)
        }
        localStorage.setItem("recieved_Badge",JSON.stringify(local_storage_Data))
        setRecentVisible(false)
    }

    {
        return (
            <>
            <Spin spinning={loading}>
                <div className="uer-profile-award-main-card-container" style={{background:"rgba(246, 246, 246, 0.62)"}}>
                    <div className="kudos-space-between-row">
                       {recievedAwardList?.length>0&&<Button className="kudos-prev-page">
                            {current_page === 1 ? <img src={prev} alt="" className="kudos-page-arrow" /> : <img style={{ cursor: "pointer" }} className="kudos-page-arrow" onClick={() => { HandleDataSource( recievedAwardList, current_page - 1 ) }} src={previous_arrow} alt="" />}
                        </Button>}
                        <div className="uer-profile-award-card-container" >
                            {
                                current_source?.map( ( n ) => (
                                    <div id={current_page} name={current_page}>
                                        <div className="recived-award-image-container" style={{ position: "relative" }} onClick={() => { showDetal( n ); setSharedValue( n ) }}>
                                            {/* <img className="award-share-icon" src={share} alt="" onClick={() => ShareAwardSocialMedia( n?.badge )} style={{ position: 'absolute', right: "10px", top: "10px", cursor: "pointer", width: "18px" }} /> */}
                                            <img src={n?.badge?.image} alt="" className="recived-award-image"  />
                                            <h4 className="recived-award-image-title" >{n?.badge?.title}</h4>
                                        </div>
                                    </div>
                                ) )
                            }
                           {recievedAwardList?.length==0&&!loading&&<div style={{width:"100%",justifyContent:"center",alignItems:'center',display:'flex',paddingTop:"200px"}}>
                                <h4 className="no-data">No Received Kudos Found</h4>
                            </div>}
                        </div>
                       {recievedAwardList?.length>0&&<Button className="kudos-next-page">
                            {current_page === total_page ? <img src={next} alt="" className="kudos-page-arrow" /> : <img style={{ cursor: "pointer" }} className="kudos-page-arrow" onClick={() => { HandleDataSource( recievedAwardList, current_page + 1 ) }} src={next_arrow} alt="" />}
                        </Button>}
                    </div>

                    {
                        visible && <AwardDetailPopup selectedAward={selectedAward} onClose={( e ) => { setVisible( e ); setSharedValue(); setSelectedAward() }} visible={visible} shareAward={( e ) => { setShared( e ); setVisible( false ); setSelectedAward() }} />
                    }
                    {
                        shared && <ShareModal SharedVisible={shared} sharedClose={( e ) => { setShared( e ); setSharedValue(); setVisible( false ); setSelectedAward() }} sharedValue={sharedValue} />
                    }
                </div>
            </Spin>
            <RecentBadge recentVisible={recentVisible} newBadgeList={newBadgeList} onCancel={(e)=>onCancelRecent()}/>
            </>
        )
    }

}
export default RecivedAwardsView