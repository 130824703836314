import React from 'react';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';

import { AddFromXLSLayout, displayDataCheck } from '../../look';

import ROUTE from '../route';

import { withRegister } from './UserOperations';

const AddMultipleUser = props => {
  const { register } = props;

  const columns = [
    {
      title: 'Status',
      key: 'success',
      dataIndex: 'success',
      render: text =>
        text ? (
          <CheckCircleFilled style={{ color: '#009fff' }} />
        ) : (
          text !== undefined && <CloseCircleFilled style={{ color: '#ff4d4f' }} />
        )
    },
    {
      title: 'Username',
      key: 'username',
      dataIndex: 'username',
      render: text => text && displayDataCheck(text)
    },
    {
      title: 'First name',
      key: 'firstName',
      dataIndex: 'firstName',
      render: text => text && displayDataCheck(text)
    },
    {
      title: 'Last Name',
      key: 'lastName',
      dataIndex: 'lastName',
      render: text => text && displayDataCheck(text)
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
      render: text => text && displayDataCheck(text)
    }
  ];

  return (
    <AddFromXLSLayout
      title={'Users'}
      active={ROUTE.user}
      columns={columns}
      dataMutation={register}
      fileColumns={`"firstName", "lastName", "username", and "email"`}
      expandable={{
        expandedRowRender: record => (
          <div key={record.id}>
            {Object.keys(record.errors).map((key, index) => record.errors[key].map(e => <p>{e.message}</p>))}
          </div>
        ),
        rowExpandable: record => {
          return record.errors;
        }
      }}
    />
  );
};

export default withRegister(AddMultipleUser);
