import gql from 'graphql-tag';
import { USER_TYPE } from '../../user/graphql/UserType.gql';
// import { TOKEN_TYPE } from './TokenType.gql';
import { SURVEY_TYPE } from './SurveyType.gql';

//   ${TOKEN_TYPE}
export const SURVEY_TOKEN_SUBSCRIPTION = gql`
  ${USER_TYPE}, ${SURVEY_TYPE}
  subscription surveyTokenSubscription {
    surveyTokenSubscription {
      mutation
      token {
        id
        user {
          ...UserType
        }
        survey {
          ...SurveyType
        }
        invited
        dateCreated
        message
        valid
        createdBy {
          ...UserType
        }
        # updatedBy {
        #   ...UserType
        # }
        link
      }
    }
  }
`;


export const SURVEY_TOKEN_SUBSCRIPTION_OPTIMIZED =gql `
  subscription  surveyTokenSubscription{
    surveyTokenSubscription{
      mutation
      token {
        id
        user {
          id
          username
          firstName
          lastName
          email
          profile {
            phoneNumber
          }
        }
        invited
        createdBy {
          firstName
          lastName
        }
        valid
        dateCreated
        survey {
          id
          name
        }
        link
      }
    }
  }
`