import React from 'react';
import _ from 'lodash';
import { Spin } from 'antd';

import { compose } from '../../core';
import { ButtonsCatch, getIntFromString, withMarkdownFieldValue } from '../../look';

import AddSurveyView from '../components/AddSurveyView';
import { CKEditorFieldName, CKEditorFieldName1 } from '../components/SurveyFormComponent';

import { withAddSurvey, withSurveyState } from './SurveyOperations';
import ROUTE from '../route';

const AddSurvey = props => {
  const { history, createSurvey } = props;
  const [submitLoading, setSubmitLoading] = React.useState(false);

  const handleSubmit = async (values, item) => {
    setSubmitLoading(true);
    if (_.get(values, ['surveyData', 'activeFrom']) === null) delete values.surveyData.activeFrom;
    if (_.get(values, ['surveyData', 'activeTo']) === null) delete values.surveyData.activeTo;
    try {
      const response = await createSurvey(
        withMarkdownFieldValue(CKEditorFieldName, withMarkdownFieldValue(CKEditorFieldName1, values))
      );
      response &&
        ButtonsCatch(
          item,
          false,
          history,
          item === undefined ? `${ROUTE.editSurveyLink}${getIntFromString(response.id)}` : window.location.pathname
        );
    } catch (e) {
      throw new Error(e.message);
    }
    setSubmitLoading(false);
  };

  return (
    <Spin spinning={submitLoading} size="large">
      <AddSurveyView onSubmit={handleSubmit} {...props} />
    </Spin>
  );
};

export default compose(withSurveyState, withAddSurvey)(AddSurvey);
