const { default: EditSurveyResponse } = require("../components/EditSurveyResponse");
const { default: EditSurveyResponseNew } = require( "../components/EditSurveyResponseNew" );
const { default: EditSurveyResponseQuestionBased } = require("../components/EditSurveyResponseQuestionBased");
const { default: EditSurveyResponseQuestionBasedNew } = require( "../components/EditSurveyResponseQuestionNew" );
module.exports = {
    themeConfig : {
        "SURVEY_EDIT_SELECT": EditSurveyResponse,
        "SURVEY_EDIT_QUESTION_BASED_SELECT": EditSurveyResponseQuestionBased,
        "SURVEY_EDIT_QUESTION_BASED_SELECT_NEW": EditSurveyResponseQuestionBasedNew,
        "SURVEY_EDIT_SELECT_NEW":EditSurveyResponseNew
    }
}