import gql from 'graphql-tag';
import { CHOICE_TYPE } from './ChoiceType.gql';

export const ADD_CHOICE_MUTATION = gql`
  ${CHOICE_TYPE}
  mutation addChoice($choiceData: ChoiceInput!) {
    createChoice(choiceData: $choiceData) {
      choice {
        ...ChoiceType
      }
    }
  }
`;
