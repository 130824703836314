// @ts-nocheck
import React from "react";
import { Button, Select, Modal, message } from "antd";
import bim from '../../assets/bin.png'
import accept_icon from '../../assets/accept.png'
import pending_icon from '../../assets/hour_glass.png'
import msg_active from '../../assets/message_active.png'
import msg_inactive from '../../assets/message_inactive.png'
import dropdown_Arrow from '../../assets/dropdown_Arrow.svg'
import "../Css/survey-comparison-req.css";
import { displayUtcToUserTimezone, getIntFromString } from "modules/look/components/functions";
import MarkdownField from "modules/look/components/form-components/MarkdownField";
import ReactMarkdown from "modules/look/components/ReactMarkdown";
const ComparisonRequestReceived = (props) => {
    const { allowComparison, request_list_info, survey, me, onEmployeeSelect, survey_response, respondToResponseCompareRequest, deleteResponseCompareRequest,preview_shared_user_manual,getRequestList } = props
    const [request_list, setRequestList] = React.useState([])
    const [has_next_page, setHasNextPage] = React.useState(false)
    const [cursor, setCursor] = React.useState()
    const [expanded_msg, setExpandedMsg] = React.useState([])
    const [expanded_accept, setExpandedAccept] = React.useState([])
    const [delete_req, setdelete_req] = React.useState()
    const Option = Select.Option;
    const form = React.useRef({})
    React.useEffect(() => {
        if (request_list_info?.length) {
            let data = request_list_info[0]
            setHasNextPage(data?.pageInfo?.hasNextPage)
            setCursor(data?.pageInfo?.endCursor)
            if (data?.pagination) {
                setRequestList(request_list.concat(data?.edges?.map(({ node }) => node)))
            } else {
                setRequestList([].concat(data?.edges?.map(({ node }) => node)))
            }

        }
    }, [request_list_info])

    const handleRequstSelect = (data) => {
        onEmployeeSelect({ ...data, type: "req_id" })
    }

    const handleAcceptSelect = (data) => {
        let list = [].concat(expanded_accept)
        let msg_list = [].concat(expanded_msg)
        if (list?.includes(data?.id)) {
            list = list.filter(item => item != data?.id)
        } else {
            list.push(data?.id)
            msg_list = msg_list.filter(item => item != data?.id)
        }
        setExpandedMsg(msg_list)
        setExpandedAccept(list)
    }

    const openMsg = (data) => {
        let list = [].concat(expanded_accept)
        let msg_list = [].concat(expanded_msg)
        if (msg_list?.includes(data?.id)) {
            msg_list = msg_list.filter(item => item != data?.id)
        } else {
            msg_list.push(data?.id)
            list = list.filter(item => item != data?.id)
        }
        setExpandedAccept(list)
        setExpandedMsg(msg_list)
    }

    const handleSubmitForm = async (item) => {
        try {
            let value = form.current
            if(value[`response_${item?.id}`]){
                let data = {
                    accepted: true,
                    id: getIntFromString(item.id),
                    sharedResponseId:value[`response_${item?.id}`],
                    message:value[`notes_${item?.id}`]?`<p>${me?.firstName || ''} ${me?.lastName || ''}: ${value[`notes_${item?.id}`]}</p>`:''  
                }
                let response = await respondToResponseCompareRequest(data)
                if(response?.id){
                    let list =[].concat(request_list) 
                    let doc=list?.find(val=>item?.id===val?.id)
                    console.log('doc',doc);
                    if(doc){
                        doc.accepted = true
                        doc.sharedResponse = response?.sharedResponse
                        let acc_list = [].concat(expanded_accept)
                        acc_list = acc_list.filter(val => val != item?.id)
                        setExpandedAccept(acc_list)
                        setRequestList(list)
                    }
                }
                else{
                    message.error('failedd to update')
                }
                
            }else{
                message.error('select the response')
            }
            
        } catch (error) {
            message.error('failedd to update')
            console.log('error',error);
        }
    }

    const handleDelete = async(item)=>{
        try {
            let res = await deleteResponseCompareRequest({id:getIntFromString(item?.id)})
            if(res){
                let list = [].concat(request_list)
                list = list?.filter(val=>val?.id != item?.id)
                setdelete_req(null)
                setRequestList(list)
            }
        } catch (error) {
            
        }
    }

    const changePage = ()=>{
        getRequestList({survey:survey?.id,endCursor:cursor,pagination:true})
    }

    const renderAcceptForm = (item) => {
        return (
            <div className="accept-form-section" id={item?.id} >
                <h4>Accept Request</h4>
                <Select
                    className='user-badge-select-active'
                    showSearch
                    name={`response_${item?.id}`}
                    id={item?.id}
                    placeholder="Select a response"
                    suffixIcon={<img src={dropdown_Arrow} alt="" style={{ width: "10px", height: "10px", cursor: "pointer" }} />}
                    style={{ width: '100%', maxWidth: "100%" }}
                    {...props}
                    onSelect={(e) => form.current[`response_${item?.id}`] = e}
                >
                    {
                        survey_response?.map(({ node }, index) => (
                            <Option
                                value={getIntFromString(node?.id)}
                            >
                                {`Response ${index + 1} ${displayUtcToUserTimezone(node?.responseDate, 'DD/MM/yyy')}`}
                            </Option>
                        ))
                    }

                </Select>
                <textarea className="comp-request-note" style={{ height: "10em" }} onChange={(e) => form.current[`notes_${item?.id}`] = e?.target?.value} placeholder="Say something here... (Optional)"></textarea>
            </div>
        )
    }

    return (
        <>
            {
                request_list?.length ?
                    <>
                         <div className="request-data-container">
                        {request_list?.map(item => (
                            <div className="request-main-padding">
                                <div className="request-card">
                                    <div className="request-card-section">
                                        <div className="profile-info-contianer">
                                            <div className={`req-user-image-div ${!item?.sender?.profile?.profileImage ? 'req-user-char-div' : ''}`}>
                                                {
                                                    item?.sender?.profile?.profileImage ?
                                                        <img src={item?.sender?.profile?.profileImage} className="profile-image" alt="" />
                                                        :
                                                        <h4>{item?.sender?.firstName.charAt(0).toUpperCase()}</h4>
                                                }

                                            </div>
                                            <h4 className="request-user-name">{item?.sender?.firstName}</h4>
                                        </div>
                                        <img src={bim} onClick={()=>{setdelete_req(item)}} className="request-bin" alt="" />
                                    </div>
                                    <div className="request-card-section" style={{ justifyContent: "start" }}>
                                        <h4>{displayUtcToUserTimezone(item?.requestDate, 'DD/MM/yyy')}</h4>
                                        {
                                            item?.accepted ?
                                                <h4 className="request-accepted">
                                                    <img src={accept_icon} alt="" /> <span>accepted</span>
                                                </h4>
                                                :
                                                <h4 className="request-pending">
                                                    <img src={pending_icon} alt="" /> <span>pending</span>
                                                </h4>
                                        }
                                    </div>

                                    <div className="request-card-section">
                                        <h4>{`Requested ${item?.survey?.name} response`}</h4>
                                        <img src={expanded_msg?.includes(item?.id) ? msg_active : msg_inactive} onClick={()=>openMsg(item)} className="req-msg-icon" alt="" />
                                    </div>

                                    {
                                        expanded_accept.includes(item?.id) && (
                                            renderAcceptForm(item)
                                        )
                                    }

                                    {
                                        expanded_msg.includes(item?.id) && (
                                            <div>
                                                <ReactMarkdown>{item?.message}</ReactMarkdown>
                                            </div>
                                        )
                                    }

                                    <div align="center" style={{ width: "100%" }}>
                                        {
                                            item?.accepted ?
                                                <Button className="comp-request-btn" onClick={() => { preview_shared_user_manual(item) }} >View</Button>
                                                :
                                                expanded_accept.includes(item?.id) ?
                                                    <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                                                        <Button className="comp-request-btn" style={{ margin: "5px" }} onClick={() => { handleSubmitForm(item) }} >Save</Button>
                                                        <Button className="comp-request-btn" style={{ margin: "5px" }} onClick={() => { handleAcceptSelect(item) }} >Cancel</Button>
                                                    </div>
                                                    :
                                                    !expanded_msg.includes(item?.id)?
                                                        <Button className="comp-request-btn" onClick={() => { handleAcceptSelect(item) }} >Accept</Button>
                                                        :''
                                        }

                                    </div>
                                </div>
                            </div>
                        ))}
                         </div>
                         {
                            has_next_page && (
                                <div align="center">
                                    <h4 className="req_pagination_view" onClick={()=>{changePage()}}>View more</h4>
                                </div>
                            )
                         }
                        
                    </>
                    :
                    <div align="center">
                        <h4>No request found</h4>
                    </div>
            }
            <Modal visible={delete_req} footer={false} destroyOnClose={true} onCancel={()=>{setdelete_req(null)}}>
                <div align="center">
                    <h4 style={{fontSize:"1.2em",margin:".5em"}}>Are you sure you want to delete this request?</h4>
                    <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                        <Button className="comp-request-btn" style={{ margin: "5px",fontSize:"1em",fontWeight:"500" }} onClick={() => { handleDelete(delete_req) }} >Yes</Button>
                        <Button className="comp-request-btn" style={{ margin: "5px",fontSize:"1em",fontWeight:"500" }} onClick={() => { setdelete_req(null) }} >No</Button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ComparisonRequestReceived;
