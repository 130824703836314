import update from 'immutability-helper';

import { PAGINATION_LIMIT } from '../../../config';

import { STORY_STATE_QUERY } from '../graphql/StoryStateQuery.client.gql';

const TYPE_STORY_STATE = 'StoryState';

const TYPE_STORY_STATE_FILTER = 'StoryFilterInput';

const TYPE_STORY_STATE_PAGINATION = 'StoryPaginationInput';

const defaults = {
  storyState: {
    filter: {
      title_Icontains: '',
      content_Icontains: '',
      name_Icontains: '',
      description_Icontains: '',
      commentText_Icontains: '',
      user: '',
      author: '',
      __typename: TYPE_STORY_STATE_FILTER
    },
    orderBy: ['-id'],
    pagination: {
      first: PAGINATION_LIMIT,
      offset: 0,
      __typename: TYPE_STORY_STATE_PAGINATION
    },
    __typename: TYPE_STORY_STATE
  }
};

const resolvers = {
  Mutation: {
    updateStoryFilter: (_, { filter, orderBy, pagination }, { cache }) => {
      const { storyState } = cache.readQuery({
        query: STORY_STATE_QUERY
      });
      const newStoryState = update(storyState, {
        filter: { $merge: filter },
        orderBy: { $set: orderBy },
        pagination: { $merge: pagination }
      });

      cache.writeData({
        data: {
          storyState: newStoryState,
          __type: TYPE_STORY_STATE
        }
      });

      return null;
    }
  }
};

export default { defaults, resolvers };
