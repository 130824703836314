import gql from 'graphql-tag';

export const CONTRIBUTORS_REQUEST_BY_ID = gql`
query contributorById($id: ID!,$tz:String) {
    contributorById(id:$id){
        weight
        progress
        id
        requestStatus
        okrType
        okr{
            id
            title(tz:$tz)
            okrType
            goalStatement
            startdate
            duedate
            correctionDeadline
            progress
            owner{
                id
                user{
                  id
                  firstName
                  lastName
                }
            }
            contributors{
                edges{
                    node{
                        id
                        user{
                            id
                            username
                            firstName
                            lastName
                        }
                    }
                }
            } 
        }
        createdBy{
          id
            user{
                id
                firstName
                lastName
            }
        }
    }
}
`