import gql from 'graphql-tag';
import { EMPLOYEE_TYPE_CONNECTION } from './EmployeeTypeConnection.gql';
import { EMPLOYEE_TYPE } from './EmployeeType.gql';

export const ALL_EMPLOYEES_QUERY = gql`
  ${EMPLOYEE_TYPE_CONNECTION}
  query allEmployees($first: Int, $offset: Int, $orderBy: [String], $user: ID) {
    allEmployees(first: $first, offset: $offset, orderBy: $orderBy, user: $user) {
      ...EmployeeTypeConnection
    }
  }
`;

export const ALL_EMPLOYEES_QUERY_SUBSCRIPTION = gql`
${EMPLOYEE_TYPE}
  subscription employeeSubscription{
    employeeSubscription{
      mutation
      employee{
        ...EmployeeType
      }
    }
  }
  `