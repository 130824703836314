import gql from 'graphql-tag';
import { CUSTOM_USER_COMMITMENT_TYPE } from '../../dashboard/graphql/CustomUserCommitmentType.gql';

export const GET_ALL_CUSTOM_USER_COMMITMENTS_FOR_REPORTING_EMPLOYEE = gql`
  ${CUSTOM_USER_COMMITMENT_TYPE}
  query getAllCustomUserCommitmentsForReportingEmployee($employeeId: ID!, $orderBy: [String], $offset: Int, $first: Int) {
    getAllCustomUserCommitmentsForReportingEmployee(
      employeeId: $employeeId
      orderBy: $orderBy
      offset: $offset
      first: $first
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          ...CustomUserCommitmentType
        }
        cursor
      }
      edgeCount
      totalCount
    }
  }
`;
export const GET_ALL_CUSTOM_USER_COMMITMENTS_FOR_REPORTING_EMPLOYEE_SUBSCRIPTION = gql`
  ${CUSTOM_USER_COMMITMENT_TYPE}
  subscription toolsCustomUserCommitmentSubscription{
    toolsCustomUserCommitmentSubscription{
      mutation
      customUserCommitment{
        ...CustomUserCommitmentType
      }
    }
  }
  `