// @ts-nocheck
import React, { useState } from "react";
import "../kudos.css";
import { Row, Col, Button, Spin, Input, Form, Select, message } from "antd";
import { CLOUDINARY_CLOUDNAME, CLOUDINARY_PRESET } from '../../../config';
import upload_icon from '../../assets/upload-icon.svg'
import red_close from '../../assets/red_close.svg'
import new_drop_arrow from '../../assets/new_drop_arrow.svg'
import gql from 'graphql-tag';
import {useMutation } from '@apollo/client';
import { getIntFromString } from "modules/look";
import red_delete from '../../assets/red-delete.svg'
import transparent_delete from '../../assets/transparent_delete.svg'
const BadgeFamilyCreateView = (props) => {
  const { me, update, createBadgeFamily,closeModal,permission } = props;
  const [title, setTitle] = React.useState();
  const [titleInvalid, settitleInvalid] = React.useState(false);
  const [detail, setdetail] = React.useState();
  const [addclick, setaddclick] = React.useState(false);
  const [loading, setLoading] = React.useState(false)
  const [badge_dynamic_mutation_list, setdynamicMutationList] = React.useState();
  const [validation,setValidation]=React.useState()
  const [validationSave,setValidationSave]=React.useState(true)
  const [initial,setInitial]=React.useState(false)
  const Option = Select.Option;
  const [cat_list, setCatList] = React.useState([
    { value: "academic", label: "Academic" },
    { value: "professional", label: "Professional" },
    { value: "recreational", label: "Recreational" },
  ])

  const [fileList, setFileList] = React.useState([])

  const fileKeyRef = React.useRef(1)
  const cloudName = `${CLOUDINARY_CLOUDNAME}/image`;
  const unsignedUploadPreset = `${CLOUDINARY_PRESET}`;
  const cloudinary_url = 'https://api.cloudinary.com/v1_1/' + cloudName + '/upload';

  const BADGE_MUTATION_QUERY = gql`
  mutation badgeApi {
    ${badge_dynamic_mutation_list
      ?.map(
        (item) =>
         `create${item.key}: createBadge(badgeData:{
          title:${JSON.stringify(item?.title)},
          ${item?.behaviouralDescription?`behaviouralDescription:${JSON.stringify(item?.behaviouralDescription)}`:''}
          catrgory:${JSON.stringify(item?.catrgory)},
          badgeFamilyId:${item?.badgeFamilyId},
          image:${JSON.stringify(item?.image)},
          ${item?.duration?`duration:${item?.duration}`:''}
         }) {
          badge{
            id
          }
         }
       `
      )
    .join('')}
  }
  `;

  const [executeBadgeApi ,{ data, isloading, error }] = useMutation(BADGE_MUTATION_QUERY);

  React.useEffect(()=>{
    if(permission.requried_permission){
      setFileList([
        {
          key: 1,
          badge_url: ""
        }
      ])
    }
  },[permission])

  React.useEffect(()=>{
    if(badge_dynamic_mutation_list && badge_dynamic_mutation_list?.length){
      executeMutation()
    }
  },[badge_dynamic_mutation_list])

  const executeMutation =async()=>{
    setLoading(true)
    const response = await executeBadgeApi()
    if(response?.data){
      message.success('Created successfully');
      setLoading(false)
      closeModal()
    }else{
      setLoading(false)
    }
    
   
   
  }

  const category_option = cat_list &&
    cat_list.map((choice, key) => (
      <Option
        key={key}
        value={choice?.value}
      >
        {choice?.label}
      </Option>
    ));

  const addBadegForm=()=>{
    let file_list = fileList.concat([])
    if(fileList.filter(ele=>!ele.id).length<10){
      fileKeyRef.current =fileKeyRef.current+1
      let key = fileKeyRef.current

      file_list.push(
        {
          key: key,
          badge_url: ""
        }
      )
      setFileList(file_list)
      setaddclick(true)
      CheckValidation()
      
    }
   
  }

  React.useEffect(()=>{
    if(addclick){
      let scroll = document.getElementById('kudos-overflow')
      if(scroll){
        scroll.scrollIntoView({inline:"end",behavior:"smooth"})
      }
      setaddclick(false)
    }
  },[addclick])
  
  const fileUpload =(event,doc)=>{
    let file = event?.target.files[0]
    let file_list = fileList.concat([])
    let current_item = file_list.find(item=>item.key===doc?.key)
    if(current_item && file){
      current_item['image']=""
      current_item['badge_url']=URL.createObjectURL(file)
      setFileList(file_list)
      uploadtocloudnary(file,current_item)
    } 
  }

  const uploadtocloudnary=(file,doc)=>{
    let file_list = fileList.concat([])
    let current_item = file_list.find(item=>item.key===doc?.key)
    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset',unsignedUploadPreset);
    fetch(cloudinary_url, {
      method: 'POST',
      body: formData,
    })
      .then((res) => res.json())
      .then((response) => {
        console.log('response?.secure_url',response?.secure_url);
        current_item['image']=response?.secure_url
        current_item['badge_url']=""
        setFileList(file_list)
        CheckValidation() 
      })
      .catch((err) => {  
        current_item['badge_url']="" 
        setFileList(file_list)
      })
  }
  

  const badgeFormDataChange=(data,doc,field)=>{
  
    let file_list = fileList.concat([])
    let current_item = file_list.find(item=>item.key===doc?.key)
    if(current_item){
      current_item[field]=data
      setFileList(file_list)
    }
  }
 
  const changeduration=(data,doc,field)=>{
    let file_list = fileList.concat([])
    let current_item = file_list.find(item=>item.key===doc?.key)
    if(current_item){
      if(isNaN(data) || parseInt(data)<=0 || !data){
        current_item[field]=""
      }else{
        current_item["duration"]=parseInt(data)
      }
      setFileList(file_list)
    }
  }

  React.useEffect(()=>{
    CheckValidation()
    setInitial(true)
  },[])
  const CheckValidation =()=>{
    setValidation(null)
    let main_err={}
    if(!title || title?.trim()===''){
      main_err['badgefamily_title']="badge family title missing"
    }
    if(!detail || detail?.trim()===''){
      main_err['badgefamily_detail']="badge family description missing"
    }

    let badge_list = fileList.concat([])

    badge_list.forEach(item=>{
      let item_err={}
      if(!item?.title || !item?.title?.trim()===''){
        item_err['title']=true
      }
      if(!item?.catrgory){
        item_err['catrgory']=true
      }
      if(!item?.image){
        item_err['image']=true
      }
      if(Object.keys(item_err).length){
        main_err[`badge_err_${item.key}`]=item_err
        item['err']=item_err
      }
    })
    console.log("main_err",main_err)
    if(Object.keys(main_err).length){
      setValidation(main_err)
      
    }else{
      setValidation(null)
      setInitial(false)
    }
  }

  const handleValidation=()=>{
    setValidationSave(null)
    let main_err={}
    if(!title || title?.trim()===''){
      main_err['badgefamily_title']="badge family title missing"
    }
    if(!detail || detail?.trim()===''){
      main_err['badgefamily_detail']="badge family description missing"
    }

    let badge_list = fileList.concat([])

    badge_list.forEach(item=>{
      let item_err={}
      if(!item?.title || !item?.title?.trim()===''){
        item_err['title']=true
      }
      if(!item?.catrgory){
        item_err['catrgory']=true
      }
      if(!item?.image){
        item_err['image']=true
      }
      if(Object.keys(item_err).length){
        main_err[`badge_err_${item.key}`]=item_err
        item['err']=item_err
      }
    })
    if(Object.keys(main_err).length){
      message.error('fill in the required fields')
      setFileList(badge_list)
      setValidationSave(main_err)
    }else{
      handleSubmit(badge_list)
    }

  }

  const handleSubmit=async(badge_list)=>{
    setLoading(true)
    let badgeFamilyData={title,description:detail}
    const create_badge_family = await createBadgeFamily({badgeFamilyData})
    if(create_badge_family?.id){
      if(badge_list.length){
        let list = badge_list?.map(data=>{
          return{
            ...data,
            badgeFamilyId:getIntFromString(create_badge_family?.id)
          }
        })
        setdynamicMutationList(list)
      }else{
        setLoading(false)
        message.success('Created successfully');
        closeModal()
      }
    }
  }

  const removeForm=(form)=>{
    let file_list = fileList.concat([])
    file_list=file_list.filter(item=>item?.key!==form.key)
    setFileList(file_list)
  }

  React.useEffect(()=>{
    CheckValidation()
  },[fileList])

  return (
    <>
      <Spin spinning={loading}>
        <div align="end" style={{marginBottom:"0.25em"}}>
          <img style={{cursor:"pointer"}} src={red_close} onClick={()=>{closeModal()}} alt="" />
        </div>
        <div className="kudos-root-class kudos-modal-overflow" >
          {/* Badge family */}
          <h4 className="kudos-modal-title">Kudos Family</h4>
          <div

            name={"badge_family"}
            className=" kudos-badge-form"
          >
            <div className="badge-info-main">
              <div className=" dummy-container">
                      
              </div>
              <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                <Col className="kudos-form-group" >

                  <Input
                    // disabled={memberlist?.length?false:true}
                    style={{
                      border: !validationSave?.badgefamily_title? '1px solid #C2C2C2' : '1px solid #ff4d4f',
                      backgroundColor: "#ffff",
                      height: "40px",
                      borderRadius: "8px",
                    }}
                    placeholder="Add Title"
                    autoComplete='off'
                    value={title}
                    onChange={(e) => { setTitle(e?.target?.value);CheckValidation() }}
                  />
                </Col>
                <Col style={{ marginTop: '1em' }} className="kudos-form-group">

                  <textarea name="description" style={{border: !validationSave?.badgefamily_detail? '1px solid #C2C2C2' : '1px solid #ff4d4f'}} placeholder="Description" maxLength={200} id="" className="kudos-description" value={detail} onChange={(e) => { setdetail(e?.target?.value);CheckValidation() }}></textarea>

                </Col>
              </div>
              <img src={transparent_delete} alt="" />
            </div>

          </div>

          {/* add badge  */}
          {
            fileList.length?
            <h4 className="kudos-modal-title">Add Kudos</h4>:''
          }
          <div
          
            name={"badge_orm"}
            className="kudos-badge-form"
          >

            {
              fileList?.map(item => (
                <div className="badge-info-main">
    
                  {
                    (item?.image||item?.badge_url)?
                    <div className="kudos-form-row">
                      <Spin spinning={(item?.badge_url && !item?.image)?true:false}>
                        <div className="upload-success-container">
                        <img src={item?.image || item?.badge_url} alt="" />
                      </div>
                      </Spin>
                    <img src={red_delete} className="delete-show-mobile" style={{cursor:"pointer"}} onClick={()=>removeForm(item)} alt="" />
                    </div>
                    :
                    <label htmlFor={`file_input_${item?.key}`} style={{ cursor: "pointer" }} className="kudos-form-row">
                    <div className="upload-container" style={{border:validationSave!=undefined&&validationSave[`badge_err_${item?.key}`]?.image? '1px solid #ff4d4f':'1px solid #D9D9D9'}}>
                      <img src={upload_icon} alt="" />
                    </div>
                    <img src={red_delete} className="delete-show-mobile" style={{cursor:"pointer"}} onClick={()=>removeForm(item)} alt="" />
                    </label>
                  }
                  <input type="file" id={`file_input_${item?.key}`} onChange={(e)=>{fileUpload(e,item)}} name={`file_input_${item?.key}`} style={{ display: "none" }} />

                  <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                  <Col style={{ marginTop: '1em' }} span={24}  className="kudos-form-group" >

                    <Input
                      // disabled={memberlist?.length?false:true}
                      style={{
                        border:validationSave!=undefined&&validationSave[`badge_err_${item?.key}`]?.title? '1px solid #ff4d4f':'1px solid #C2C2C2',
                        backgroundColor: "#ffff",
                        height: "40px",
                        borderRadius: "8px",
                      }}
                      value={item?.title}
                      name={`title_${item?.key}`}
                      placeholder="Add Title"
                      onChange={(e) => { badgeFormDataChange(e?.target?.value,item,'title')}}
                      autoComplete='off'
                    />

                    </Col>
                    <div className="badge-info-row" style={{ marginTop: '1em' }}>
                    <Col  lg={{ span: 13 }} md={{ span: 13 }}  sm={{ span: 13 }} className="kudos-form-group" >

                      <Input
                        // disabled={memberlist?.length?false:true}
                        style={{
                          border:'1px solid #C2C2C2',
                          backgroundColor: "#ffff",
                          height: "40px",
                          borderRadius: "8px",
                        }}
                        value={item?.duration}
                        placeholder="Duration in days (Optional)"
                        name={`duration_${item?.key}`}
                        onChange={(e) => { changeduration(e?.target?.value,item,'duration') }}
                        autoComplete='off'
                      />

                      </Col>
                      <Col className="kudos-form-group no-border-category" style={{height:"40px"}}>
                      <Select 
                      style={{width:'100%',
                        border:validationSave!=undefined&&validationSave[`badge_err_${item?.key}`]?.catrgory? '1px solid #ff4d4f':'1px solid #C2C2C2',
                        borderRadius:"8px"}}
                        name={`badge_cat_${item.key}`}  
                        suffixIcon={<img src={new_drop_arrow}  alt="" style={{width:"10px",height:"10px",cursor:"pointer"}}/> }
                        placeholder="Category"
                        {...props} 
                        onSelect={(e) => { badgeFormDataChange(e,item,'catrgory');CheckValidation() }} 
                        filterOption={(input,option)=> option} 
                        >
                        {/* {otherOption ? (choices?.length > 0 ? [...choice, otherField] : [otherField]) : choice} */}
                        {category_option}
                      </Select>
                      </Col>
                    </div>


                    <Col style={{ marginTop: '1em' }} className="kudos-form-group">

                      <textarea name={`description_${item?.key}`} placeholder="Behavioral Description" maxLength={200} value={item?.behaviouralDescription} className="kudos-description" onChange={(e) => { badgeFormDataChange(e?.target?.value,item,'behaviouralDescription') }}></textarea>

                    </Col>
                    
                  </div>
                   <img src={red_delete} style={{cursor:"pointer"}} className="delete-hide-mobile" onClick={()=>removeForm(item)} alt="" />
                </div>
              ))
            }



          </div>

         <div align="center" id="kudos-overflow">
         <Button className="kudos-modal-btn kudos-save" disabled={initial} onClick={()=>{handleValidation()}}>
            SAVE
          </Button>
         {
          permission.requried_permission?
          <Button className="kudos-modal-btn kudos-new-badge" disabled={initial}  onClick={()=>{addBadegForm();setInitial(true)}}>
            ADD BADGES
          </Button>:''
         }
         </div>
        </div>
      </Spin>

    </>
  )
};

export default BadgeFamilyCreateView;
