import gql from 'graphql-tag';
import { COMMENT_TYPE } from './CommentType.gql';

export const STORY_COMMENT_SUBSCRIPTION = gql`
  ${COMMENT_TYPE}
  subscription storyCommentSubscription {
    storyCommentSubscription {
      mutation
      comment {
        ...CommentType
      }
    }
  }
`;
