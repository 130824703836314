import React from 'react';
import { Spin } from 'antd';

import { compose } from '../../core';
import { ButtonsCatch, getIntFromString, withMarkdownFieldValue,getSurveyBase64StringFromInt } from '../../look';

import EditSurveyView from '../components/EditSurveyView';
import ROUTE from '../route';

import { withEditSurvey, withSurveyById } from './SurveyOperations';
import { CKEditorFieldName, CKEditorFieldName1 } from '../components/SurveyFormComponent';
import { SURVEY_BY_ID } from 'modules/scapeReport/graphql/surveyById.gql';
import { withApollo } from '@apollo/client/react/hoc';

const EditSurvey = props => {
  const { history, editSurvey,client,me,match } = props;
  const [submitLoading, setSubmitLoading] = React.useState(false);
  const [SurveyById,setSurveyById]=React.useState()
  const handleSubmit = async (values, item) => {
    setSubmitLoading(true);

    try {
      const response = await editSurvey({
        id: getIntFromString(SurveyById.id),
        ...values,
        ...withMarkdownFieldValue(CKEditorFieldName[1], withMarkdownFieldValue(CKEditorFieldName1[1], values.surveyData))
      });

      response &&
        ButtonsCatch(
          item,
          `${ROUTE.surveyDetailLink}${getIntFromString(SurveyById.id)}`,
          history,
          item === undefined ? window.location.pathname : `${ROUTE.surveyDetailLink}${getIntFromString(SurveyById.id)}`
        );
    } catch (e) {
      throw new Error(e.message);
    }
    setSubmitLoading(false);
  };

  React.useEffect(()=>{
    if(me){
      let id = getSurveyBase64StringFromInt( match.params.id );
      let responseUser = me?.id
      getSurveyDetail( { id, responseUser }  )
    }
  }
  ,[me,match])

  const  getSurveyDetail= async (value)=>{

    const { data } = await client.query( {
      query: SURVEY_BY_ID,
      variables: value,
      fetchPolicy: 'network-only'
  } );
  if ( data?.SurveyById) {
    console.log("data?.SurveyById",data?.SurveyById)
    setSurveyById(data?.SurveyById)
  }
  }
  return (
    <Spin spinning={submitLoading} size="large">
      <EditSurveyView onSubmit={handleSubmit} {...props} SurveyById={SurveyById}/>
    </Spin>
  );
};

export default compose(withEditSurvey, withApollo)(EditSurvey);
