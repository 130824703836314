export const UpdatedRule =
    [
        { 688: [0, 25], 689: [0, 25], 690: [0, 25], 691: [0, 25]}, 
        { 688: [0, 25], 689: [0, 25], 690: [0, 25], 691: [61, 80] }, 
        { 688: [26, 50], 689: [0, 25], 690: [0, 25], 691: [81, 100] }, 
        { 688: [51, 60], 689: [10, 25], 690: [0, 9], 691: [81, 100] }, 
        { 688: [51, 60], 689: [51, 60], 690: [10, 25], 691: [81, 100] }, 
        { 688: [51, 60], 689: [51, 60], 690: [0, 25], 691: [0, 25] }, 
        { 688: [51, 60], 689: [51, 60], 690: [51, 60], 691: [0, 25] }, 
        { 688: [81, 100], 689: [81, 100], 690: [51, 60], 691: [81, 100] }, 
        { 688: [81, 100], 689: [81, 100], 690: [51, 60], 691: [81, 100] }, 
        { 688: [81, 100], 689: [81, 100], 690: [81, 100], 691: [81, 100] }, 
        { 688: [81, 100], 689: [81, 100], 690: [61, 80], 691: [26, 50] }, 
    ]



const range = (start, stop, step = 1) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));

const pushUnique = ( arr, arr2) => {
    arr2.forEach(element => {
        if (!arr.includes(element)){
            arr.push(element)
        }

    });
    return arr 
}


function findRanges(numbers) {
    return [...numbers].sort((a, b) => a - b).reduce((acc, x, i) => {
        if (i === 0) {
            acc.ranges.push([x]);
            acc.rangeStart = x;
        } else {
            if (x === acc.last + 1) {
                acc.ranges[acc.ranges.length - 1] = [acc.rangeStart, x];
            } else {
                acc.ranges.push([x]);
                acc.rangeStart = x;
            }
        }
        acc.last = x;
        return acc;
    }, { ranges: [] }).ranges;
}

export const FetchValidRuleStates = (a,b,c,d) => {
    // console.log("fetch", a, b, c, d)
    let valid = []
    if(a === undefined){
        UpdatedRule.forEach(r => {
            valid = pushUnique(valid, range(r[688][0], r[688][1]))
            
        })
    }
    else if (b === undefined) {
        let aValids = UpdatedRule.filter(item => item[688][0] <= a && a <= item[688][1])
        aValids.forEach(r => {
            valid = pushUnique(valid, range(r[689][0], r[689][1]))
        })
    }
    else if (c === undefined) {
        let aValids = UpdatedRule.filter(item => item[688][0] <= a && a <= item[688][1])
        let bValids = aValids.filter(item => item[689][0] <= b && b <= item[689][1])
        bValids.forEach(r => {
            valid = pushUnique(valid, range(r[690][0], r[690][1]))

        })
    }
    else if (d === undefined) {
        let aValids = UpdatedRule.filter(item => item[688][0] <= a && a <= item[688][1])
        let bValids = aValids.filter(item => item[689][0] <= b && b <= item[689][1])
        let cValids = bValids.filter(item => item[690][0] <= c && c <= item[690][1])
        cValids.forEach(r => {
            valid = pushUnique(valid, range(r[691][0], r[691][1]))

        })
    }
    return findRanges(valid)


} 
