import { withUpdateContributorWeights } from 'modules/Objective-keyresult/container/objective-keyresult-operations';
import React from 'react';
import { compose } from '../../../core';
import AddWeight from '../addWeight';
import ProgessUpdate from '../progeressUpdate';
import { withApollo } from '@apollo/client/react/hoc';
import AddWeightButton from '../AddWeightButton';
const AddWeightContainer = props => {

    const { okrId, client, me, visible,allokrweights} = props
    const [loading, setloading] = React.useState(true)
    return (
        <>
            <AddWeightButton {...props} visible={visible} allokrweights={allokrweights} loading={loading} setloading={(e) => setloading(e)}/>
        </>
    );
};

export default compose(withApollo, withUpdateContributorWeights)(AddWeightContainer);
