import React from 'react';
import close from '../../assets/icons/popup_close.svg'
import {Button} from 'antd'
const IDPApproveView = ( props ) => {
    const { onCancel ,onSuccess} = props
    const [comment,setComment]=React.useState("")
    const Confirm =()=>{
        onSuccess(comment)
    }
    return (
        <>
            <div className="row-space-between">
                <h4 className="idp-form-label" style={{ fontWeight: "500",margin:0 }}>Status : Approved</h4>
                <img src={close} alt="close" onClick={() => { onCancel() }} style={{ cursor: "pointer" }} />
            </div>
            <p className='idp-note' style={{ margin:0 }}>
                You have chosen to approve this IDP
            </p>
            <div style={{display:"flex",flexDirection:"column",gap:"1em",marginTop:"1em"}}>
                <h4 className="idp-form-label"style={{ fontWeight: "500" }}>Add Comments <span className='idp-note'>(Optional)</span></h4>
                <textarea className="idp-textarea" placeholder="Write your Comments here" onChange={(e)=>setComment(e?.target?.value)}/>
            </div>
            <div style={{display:'flex',justifyContent:"end",marginTop:"2.5em"}}>
                <Button className='idp-cancel idp-btn'onClick={() => { onCancel() }}>CANCEL</Button>
                <Button className='idp-submit-outline idp-btn'onClick={() => { Confirm() }}>CONFIRM</Button>
            </div>
        </>
    )
}

export default IDPApproveView