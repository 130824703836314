// @ts-nocheck
import React from 'react';
import { Link } from 'react-router-dom';
import arrayMove from 'array-move';
import { UserOutlined,FileTextOutlined,PlusOutlined,EditOutlined } from '@ant-design/icons';
import { Row, Col, Button,Spin,Table ,Tooltip,Modal, Menu, Dropdown } from "antd";
import styled from 'styled-components';
import { displayUtcToUserTimezone } from '../../look/components/functions'
import ReactMarkdown from '../../look/components/ReactMarkdown';
import { getIntFromString,scrolltoview } from "../../look";
// import Filtermenu from './filter'
import { ONEONONE_ADD_EDIT_TOOL_ID,ONE_ON_ONE_TOOL_WHILE_MEETING } from '../../../config'
import ProfileLogo from '../../assets/avatar.jpg'
import MemberListModal from './MemberListModel'
import AdditionalDetail from './additionalDetailBasicView'
import ActionDetailContainer from "../containers/ActionDetailContainer";
import { UPCOMING_MEETING } from '../graphql/UpcomingMeeting.gql';
import one_on_one_route from '../route/index'
const MeetingTableView = props => {
  const {
    selectedTab,
    dataSource,
    history,me,loading
  } = props;

  const [load, setloading] = React.useState(false);
  const [viewMember, setviewMember] = React.useState(false);
  const [popupMemberList, setpopupMemberList] = React.useState([]);
  const [agendaPopup, setagendaPopup] = React.useState(false);
  const [agendaList, setagendaList] = React.useState([]);
  const [actionData,setActionData] = React.useState();
  // React.useEffect(()=>{
  //   if(dataSource){
  //       setloading(false)
  //   }
  // },[dataSource])

  React.useEffect(()=>{
    setloading(loading)
  },[dataSource,loading])

  const anchorStyle={
    whiteSpace:'nowrap',
    overflow:'hidden',
    textOverflow:'ellipsis',
    maxWidth:'110px',
    marginTop:'5px'
  }
  
  const MemberListPopup=(membersList)=>{
    setpopupMemberList(membersList)
    setviewMember(true)
  }
    

  const showMember=(members)=>{
   
    const userList = members?.map(({node})=>node)
    return (
      <Row style={{gap:'5px'}}>
        {
          userList.map((userData,index)=>
          index<=1?
            <Tooltip title={userData?.user?.firstName}>
               {
                 userData?.user?.profile?.profileImage?
                 <img src={userData?.user?.profile?.profileImage} style={{width:'30px',height:'30px',borderRadius:'50%',marginTop:'5px'}} alt="" />
                 :
                 <img src={ProfileLogo} style={{width:'30px',height:'30px',borderRadius:'50%',marginTop:'5px'}} alt="" />
                 
               }
            </Tooltip>
            :null
          )
        }
        {
          userList?.length>2 && (
            <Tooltip title="View Other Members">
              <Button type='primary' style={{width:'30px',height:'30px',borderRadius:'50%',marginTop:'5px'}} size='small' onClick={()=>{MemberListPopup(userList)}}>
                <UserOutlined />
              </Button>
            </Tooltip>
          )
        }
        
      </Row>
    )
  }

  const columns = [
    {
      title: <h4 id="1_1_compressed_title" style={{cursor:'pointer'}} onClick={()=>{scrolltoview('1_1_compressed_title')}}>Title</h4>,
      key: 'Title',
      render: (text, record) => record && 
      <Tooltip title={record?.title?.length>16?record?.title:''} placement="right">
       <p style={anchorStyle}>{record?.title}</p>
      </Tooltip>
    },
    {
      title: <h4 id="1_1_compressed_event_time" style={{cursor:'pointer'}} onClick={()=>{scrolltoview('1_1_compressed_event_time')}}>Event Time</h4>,
      key: 'Event_Time',
      render: (text, record) => record && 
     
        record?.event ? <p>{displayUtcToUserTimezone(record?.event, "MMMM DD YYYY, h:mm a")}</p>:''
      
      
    },
    
    {
        title: <h4 id="1_1_compressed_creator" style={{cursor:'pointer'}} onClick={()=>{scrolltoview('1_1_compressed_creator')}}>Creator</h4>,
        key: 'Creator',
        render: (text, record) => record && 
        <p>{record?.owner?.user?.firstName}</p>
      },
      {
        title: <h4 id="1_1_compressed_member" style={{cursor:'pointer'}} onClick={()=>{scrolltoview('1_1_compressed_member')}}>Member</h4>,
        key: 'Members',
        render: (text, record) => record && 
       <div>
          {showMember(record?.member?.edges)}
       </div>
      },
      {
        title: '',
        key: '',
        render: (text, record) => record && 
        <Row style={{gap:'15px'}} align="end">
          {viewAgenda(record)}
      
          {(selectedTab==="all" && !record?.nextMeeting?.totalCount )?(
            <Tooltip title="Add next 1:1">
              <Button type='primary' onClick={()=>{navigateTocreate1_1withPrevious(record?.id)}} style={{width:'30px',height:'30px',borderRadius:'50%',marginTop:'5px'}} size='small'>
                <PlusOutlined />
              </Button>
            </Tooltip>
          ) :''            
          }



      {me?.employee?.user?.id === record?.owner?.user?.id  &&  selectedTab === 'upcoming' &&(
            // <Tooltip title="Edit 1:1">
                  <Dropdown overlay={<Menu>
                    <Menu.Item key="occurrence" onClick={()=>history.push(one_on_one_route?.EditMeeting?.replace(":meetingid",getIntFromString(record?.id)))}>Edit occurrence</Menu.Item>
                    { record?.rrule &&(<Menu.Item key="series"onClick={()=>history.push(one_on_one_route?.EditMeetingSeries?.replace(":meetingid",getIntFromString(record?.id)))}>Edit series</Menu.Item>)}
                  </Menu>}  placement="bottomCenter">
                    <Button type='primary'  
                      style={{width:'30px',height:'30px',borderRadius:'50%',marginTop:'5px'}} size='small'>
                      <EditOutlined/>
                    </Button>
                  </Dropdown>
            // </Tooltip>
          )
             
        }
      {me?.employee?.user?.id === record?.owner?.user?.id  &&  selectedTab === 'future'&&(
       
        <Dropdown overlay={
        <Menu>
          <Menu.Item key="occurrence" onClick={()=>history.push(one_on_one_route?.EditMeeting?.replace(":meetingid",getIntFromString(record?.id)))}>Edit occurrence</Menu.Item>
          { record?.rrule &&(<Menu.Item key="series"onClick={()=>history.push(one_on_one_route?.EditMeetingSeries?.replace(":meetingid",getIntFromString(record?.id)))}>Edit series</Menu.Item>)}
        </Menu>}  placement="bottomCenter">
          <Button type='primary'  
            style={{width:'30px',height:'30px',borderRadius:'50%',marginTop:'5px'}} size='small'>
            <EditOutlined/>
          </Button>
        </Dropdown>
      )}

        <ViewButton type='primary' style={{marginTop:'5px'}} onClick={()=>navigateToDetail(record?.id)}>{selectedTab!=="upcoming"?'View':'Start'}</ViewButton>
        </Row>
      }
      

  ];
 
  const navigateToDetail=(id)=>{
    if(selectedTab==="all" || selectedTab==="future"){
      history.push(`${one_on_one_route?.viewMeeting?.replace(":meetingid",getIntFromString(id))}?tab=${selectedTab}`)
    }else if(selectedTab==="upcoming"){
      history.push(`${one_on_one_route?.Meeting?.replace(":meetingid",getIntFromString(id))}?tab=${selectedTab}`)
    }
  }

  const navigateTocreate1_1withPrevious=(id)=>{
    history.push(`${one_on_one_route?.addOneonOne}?previous=${getIntFromString(id)}`)
  }

  const viewAgenda=(record)=>{
 
    let list =  record?.actionitemSet?.edges?.map(({node})=>node)
    let agendaList = list.filter(item=>item?.isAgenda)
    return(
          agendaList?.length ? <Tooltip title="View Agenda">
          <Button type='primary' onClick={()=>{agendaPopView(record)}} style={{width:'30px',height:'30px',borderRadius:'50%',marginTop:'5px'}} size='small'>
            <FileTextOutlined />
          </Button>
        </Tooltip>
        : null
    )
 
  }


  const agendaPopView =(record)=>{
    let list =  record?.actionitemSet?.edges?.map(({node})=>node)
    let agendaList = list.filter(item=>item?.isAgenda)
    let setAgenda =[]
    // createdAt
    // updatedAt
    agendaList.forEach(element => {
        let data =
        {
            id:element?.id,
            title:element?.title,
            content:null,
            navigateTo:null,
            notes:element?.notes,
            createdAt:element?.createdAt,
            updatedAt:element?.updatedAt,
            okr:element?.okr
        }
        // if(element?.milestone){
        //     data.content= element?.milestone?.title
        //   }
        //   else if(element?.commitment){
        //     data.content= element?.commitment?.commitment?.title
        //   }
        //   else if(element?.coachingCommitment){
        //     data.content= element?.coachingCommitment?.coachingcommitment?.title
        //   }
          setAgenda.push(data)
    });
    setagendaList(setAgenda)
    setagendaPopup(true)
  }

  const ReportViewActionItem =(data)=>{
  
    // let list =  meetingById?.actionitemSet?.edges?.map(({node})=>node)
    // let detail = actionList.find(item=>item.id===data?.id)   
    let actionData =  agendaList?.find(item=>item?.id===data?.id)
    
    if(actionData){
        // actionData.completed=detail?.completed?true:false
        setActionData(actionData)
    }
    
  }

  return (
    <>
      {
        viewMember && (
          <MemberListModal 
          memberlist={popupMemberList}
          onClose={(e)=>{setpopupMemberList([]);setviewMember(false)}}
          ></MemberListModal>
        )
      }
       {
                actionData && (
                    <Modal 
                    closable={false}
                    visible={actionData?true:false}
                    destroyOnClose={true}
                    footer={false}
                    width='50%'
                    maxHeight="80vh"
                    >

                       <ActionDetailContainer
                        actionDetail={actionData}
                         editable={false}
                          {...props} 
                          onClose={(e)=>{ setActionData(null)}}
                           /> 

                    </Modal>
                )
            }
      {
        agendaList?.length && agendaPopup?
         <Modal
         footer={null}
         centered
         visible={agendaPopup}
         width={"50%"}
         destroyOnClose={true}
         onCancel={() => {setagendaPopup(false);setagendaList([])}}
         closable={false}
       >
         <AdditionalDetail 
            label="Agenda"
            dataSource={agendaList}
            showReport={true}
            showFull={true}
            reportView={(e)=>{ReportViewActionItem(e)}}
          />

       </Modal>
       :null
      }

      <MyTable
        size={'middle'}
        loading={load}
        showHeader={true}
        dataSource={
          dataSource
            ? dataSource
            : []
        }
        columns={columns.map(c => ({ ...c, sortDirections: ['descend', 'ascend', 'descend'] }))}
      />
 
    </>
  );
};

export default MeetingTableView;

const CreateButton = styled(Button)`
width:170px;
height:40px;
border-radius:5px;
`

const ViewButton = styled(Button)`
width:60px;
height:35px;
border-radius:5px;
`

export const MyTable = styled(Table)`
  table {
    border-spacing: 0 1em;
    tbody {
      tr {
        td {
          border: 1px solid rgba(0, 0, 0, 0.06) !important;
          border-style: solid none !important;
        }
        td:first-child {
          border-left-style: solid !important;
          border-top-left-radius: 5px !important;
          border-bottom-left-radius: 5px !important;
        }
        td:last-child {
          border-right-style: solid !important;
          border-bottom-right-radius: 5px !important;
          border-top-right-radius: 5px !important;
          border-bottom: 1px solid rgba(0, 0, 0, 0.06) !important;
        }
      }
    }
  }
`;
const RowDiv =styled(Row)`
display:flex;
justify-content:space-between;
align-items:center;
margin-bottom:20px
`