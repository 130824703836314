import React from 'react';
import { dasboardIcons } from '../dashboard-icons';
import { Spin } from 'antd'
import moment from 'moment';
import { displayUtcToUserTimezone, getIntFromString } from 'modules/look';
import one_on_one_route from 'modules/one-on-one/route';

const UserOneOneView = ( props ) => {
    const { me, action_item_list, action_item_loading, upcoming_meeting, navigateRoute, upcoming_meeting_loading } = props
    console.log("action_item_list",action_item_list)
    const tomorrowDate = moment().add( 1, 'day' ).startOf( 'day' )
    const memberName = ( member ) => {
        if ( member?.length ) {
            let find = member.find( ( { node } ) => node?.user?.id !== me?.employee?.user?.id )
            if(find){
                return `${find?.node?.user?.firstName} ${find?.node?.user?.lastName}`
            }
            else{
                return "-"
            }
        }
        else {
            return ""
        }
    }
    return (
        <div style={{ display: 'flex', flexDirection: "column", gap: '1em' }}>
            <div className="user-dasboard-small-card-container" style={{ minHeight: "5em" }}>
                <h4 className="user-dashboard-small-card-title">Upcoming 1:1 Meeting</h4>
                <Spin spinning={upcoming_meeting_loading}>
                    {upcoming_meeting && moment( upcoming_meeting?.when ).isAfter( moment() ) ? <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: '1em', width: "100%" }}>
                        <div style={{ display: 'flex', flexDirection: "column", justifyContent: "start", width: 'calc(85% - 1em)' }}>
                            <div style={{ display: 'flex', flexDirection: "column", justifyContent: "start", textOverflow: "ellipsis", overflow: "hidden" }}>
                                <h4 className="user-dashboard-small-card-title" style={{ whiteSpace: "nowrap", margin: "0px", overflow: 'hidden', textOverflow: 'ellipsis' }}>{upcoming_meeting?.title}</h4>
                                <p className="user-dashboard-small-card-para-one-on-one" style={{ margin: "0px", overflow: 'hidden', textOverflow: 'ellipsis' }}>Participant: {memberName( upcoming_meeting?.member?.edges )}</p>
                            </div>
                            <p className="user-dashboard-small-card-para" style={{ paddingTop: "1em", overflow: 'hidden', textOverflow: 'ellipsis' }}>{moment( upcoming_meeting?.when ).isSame( moment(), 'day' ) ? 'Today' : moment( upcoming_meeting?.when ).isSame( tomorrowDate, 'day' ) ? 'Tomorrow' : displayUtcToUserTimezone( upcoming_meeting?.when, "DD MMM YYYY" )}, {displayUtcToUserTimezone( upcoming_meeting?.when, "hh:mm A" )}</p>
                        </div>
                        <div style={{ width: "15%" }}>
                            <img src={dasboardIcons?.call_start} alt="" style={{ cursor: "pointer" }} onClick={() => navigateRoute( one_on_one_route?.Meeting.replace( ":meetingid", getIntFromString( upcoming_meeting?.id ) ) )} />
                        </div>
                    </div>
                        :
                        ( !upcoming_meeting_loading && <h4 className="user-dashboard-small-card-para" style={{ fontWeight: "400", textAlign: "center", paddingTop: "1em" }}>No Upcoming Meeting</h4> )}
                </Spin>
            </div>
            <div className='small-one-on-one-container'>
                <h4 className="user-dashboard-small-card-title">1:1 Action Items</h4>
                <Spin spinning={action_item_loading}>
                    <div className="small-one-on-one-container-overflow">
                        {action_item_list?.length > 0 ? action_item_list?.map( ( item ) => (
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: '1em', alignItems: "center", paddingTop: ".5em" }}>
                                <div style={{ display: 'flex', flexDirection: "column", justifyContent: "start" }}>
                                    <h4 className="user-dashboard-small-card-para" style={{ whiteSpace: "nowrp", textOverflow: "elipsis", overflow: "hidden" }}>{item?.actionitemSet?.edges[ 0 ]?.node?.title}</h4>
                                    <p className="user-dashboard-small-card-para-one-on-one">Participant :  {memberName( item?.member?.edges )}</p>
                                </div>
                                <h4 className='one-on-one-see-detail-button' style={{whiteSpace:"nowrap"}} onClick={() => navigateRoute( one_on_one_route?.viewMeeting.replace( ":meetingid", getIntFromString( item?.id ) ) )}>See Detail</h4>
                            </div>
                        ) ) :
                            ( !action_item_loading && action_item_list?.length === 0 && <h4 className="user-dashboard-small-card-para" style={{ fontWeight: "400", textAlign: 'center', paddingTop: "7em" }}>No Previous Action Items Found</h4> )
                        }
                    </div>
                </Spin>
            </div>
        </div>
    )
}
export default UserOneOneView