import gql from 'graphql-tag';

export const TEAMS_BY_VERTICAL = gql`
query($vertical:ID!){
    Teams(vertical:$vertical){
      edges{
        node{
          id
          name
          managers{
            edges{
              node{
                id
              }
            }
          }
          employees{
            totalCount
          }
        }
      }
    }
    }
`