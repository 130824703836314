import gql from 'graphql-tag';

export const ALL_RECEPIENT_LIST =gql`
query badgeAwards($badge: ID,$recipient_User_FirstName_Icontains: String,$recipient_User_LastName_Icontains: String,$issuer:ID){
    firstName:badgeAwards(badge:$badge,recipient_User_FirstName_Icontains:$recipient_User_FirstName_Icontains,issuer:$issuer) {
        edges {
          node {
            id   
            issuer {    
              id    
              user{
                id
                firstName
                lastName
              }
            }    
            recipient {    
              id    
              user{
                id
                firstName
                lastName
              }
            }    
            evidence   
            expirationDate    
            badge{  
                id  
              image    
              title    
              behaviouralDescription   
            }   
          }    
        }  
    } 
    lastName:badgeAwards(badge:$badge,recipient_User_LastName_Icontains:$recipient_User_LastName_Icontains,issuer:$issuer) {
      edges {
        node {
          id   
          issuer {    
            id    
            user{
              id
              firstName
              lastName
            }
          }    
          recipient {    
            id    
            user{
              id
              firstName
              lastName
            }
          }    
          evidence   
          expirationDate    
          badge{  
              id  
            image    
            title    
            behaviouralDescription   
          }   
        }    
      }     
    }
}
`

export const ALL_RECEPIENT_LIST_SUBSCRIPTION = gql`
subscription badgeAwardSubscription {
  badgeAwardSubscription {
        mutation
        badgeAward{
          id   
          issuer {    
            id    
            user{
              id
              firstName
              lastName
            }
          }    
          recipient {    
            id    
            user{
              id
              firstName
              lastName
            }
          }    
          evidence   
          expirationDate    
          badge{  
              id  
            image    
            title    
            behaviouralDescription   
          }     
        }
    }
}`