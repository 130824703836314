import React from "react";
const UserReport = props => {
    const { user_report } = props
    console.log('user_report', user_report);
    return (
        <>
            {
                (user_report) && (
                    <div className='report-tool-impact-container'>
                        {
                            user_report?.strength?.length ?
                                <div>
                                    <h4 className='impact-tool-head'>Strengths</h4>
                                    <div className="impact-tool-survey-container">
                                        {
                                            user_report?.strength?.map(i => (
                                                <div className="impact-tool-res-chip">
                                                    <p className='impact-tool-content'>{i?.answer}</p>
                                                </div>
                                            ))
                                        }

                                    </div>
                                </div>
                                : ''
                        }

                        {
                            user_report?.value?.length ?
                                <div>
                                    <h4 className='impact-tool-head'>Values</h4>
                                    <div className="impact-tool-survey-container">
                                        {
                                            user_report?.value?.map(i => (
                                                <div className="impact-tool-res-chip">
                                                    <p className='impact-tool-content'>{i?.answer}</p>
                                                </div>
                                            ))
                                        }

                                    </div>
                                </div>
                                : ''
                        }

                        {
                            user_report?.personality?.length ?
                                <div>
                                    <h4 className='impact-tool-head'>Personality Orientations</h4>
                                    <div className="impact-tool-survey-container">
                                        {
                                            user_report?.personality?.map(i => (
                                                <div className="impact-tool-res-chip">
                                                    <p className='impact-tool-content'>{i?.answer}</p>
                                                </div>
                                            ))
                                        }

                                    </div>
                                </div>
                                : ''
                        }

                        {
                            user_report?.knowledge?.length ?
                                <div>
                                    <h4 className='impact-tool-head'>Knowledge Areas And Skills</h4>
                                    <div className="impact-tool-survey-container">
                                        {
                                            user_report?.knowledge?.map(i => (
                                                <div className="impact-tool-res-chip">
                                                    <p className='impact-tool-content'>{i?.answer}</p>
                                                </div>
                                            ))
                                        }

                                    </div>
                                </div>
                                : ''
                        }

                        {
                            user_report?.impact_narrative[0]?.decisionMaking && (
                                <div>
                                    <h4 className='impact-tool-head'>Decision making</h4>
                                    <p className='impact-tool-content'>{user_report?.impact_narrative[0]?.decisionMaking}</p>
                                </div>
                            )
                        }

                        {
                            user_report?.impact_narrative[0]?.communication && (
                                <div>
                                    <h4 className='impact-tool-head'>Communication</h4>
                                    <p className='impact-tool-content'>{user_report?.impact_narrative[0]?.communication}</p>
                                </div>
                            )
                        }

                        {
                            user_report?.impact_narrative[0]?.collaboration && (
                                <div>
                                    <h4 className='impact-tool-head'>Collaboration</h4>
                                    <p className='impact-tool-content'>{user_report?.impact_narrative[0]?.collaboration}</p>
                                </div>
                            )
                        }

                        {
                            user_report?.impact_narrative[0]?.relationshipBuilding && (
                                <div>
                                    <h4 className='impact-tool-head'>Relationship Building</h4>
                                    <p className='impact-tool-content'>{user_report?.impact_narrative[0]?.relationshipBuilding}</p>
                                </div>
                            )
                        }

                    </div>
                )
            }
        </>
    )

}

export default UserReport