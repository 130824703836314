import { okr_competency_permission } from "./okrCompetency.permission";
import { runpod_permission } from "./runpod.permission";

export const okr_permission= {
    requried_permission:['add_contributor', 'change_contributor', "delete_contributor", "view_contributor", 
    "add_discardrequest", "change_discardrequest", "delete_discardrequest", "view_discardrequest",
     "add_okr", "change_okr", "delete_okr", "view_okr", "add_okrcompetency", "change_okrcompetency",
      "delete_okrcompetency", "view_okrcompetency", "add_okrmessage", "change_okrmessage", "delete_okrmessage", 
      "view_okrmessage", "add_tracking", "change_tracking", "delete_tracking", "view_tracking" ],
    okrCompetencyPermissions:okr_competency_permission.required_permission,
    runpod_permission:runpod_permission?.required_permission
} 
