// @ts-nocheck
import React from 'react';
import { compose } from '../../core';
import MessagesView from '../components/MessagesView'
import { MESSAGE_BY_OKR_SUBSCRIPTION, OKR_MESSAGES } from '../graphql/OkrMessages.gql';
import { withCreateOkrMessage, withOkrMessages } from './OKrOperations';
import { withApollo } from '@apollo/client/react/hoc';
import { withMe } from 'modules/user/containers/UserOperations';
import { subscribeToMessageByOkrId } from '../graphql/ObjKeyResultSubscriptions.gql';
import { subscribeToMessage, withMessage } from './objective-keyresult-operations';


const MessageContainer = props => {
  const { match, okrMessages,disabled,client,subscribeToMore,okrId,contributors} = props
  const [messages,setMessages] = React.useState();
  const mountedRef = React.useRef(true)
  const dataRef = React.useRef()
  const [loading,setloading] = React.useState(false);
  


  React.useEffect(()=>{
     if(okrId){
        getOkrMessages({okr:okrId})     
     }
  },[okrId])

  let messageSub = undefined;

  React.useEffect( () => {
    return () => {
      mountedRef.current = false
    }
  }, [] )

  React.useEffect( () => {
    mountedRef.current = true

  }, [] )

  React.useEffect( () => {
    return () => {
      if ( messageSub ) {
        messageSub.unsubscribe();
      }
    }
  })


  const getOkrMessages = async(filter)=>{

  
    const { data } = await client.query({
      query: OKR_MESSAGES,
      variables: filter,
      fetchPolicy:"network-only"
    });
    
    if(data?.okrMessages){

      setMessages(data?.okrMessages)
      dataRef.current =data?.okrMessages
      setloading(false)
      if(!messageSub){
        messageSub =await client.subscribe({
          query:  MESSAGE_BY_OKR_SUBSCRIPTION,
          fetchPolicy: 'network-only',
          
      }).subscribe({
          next(result) {
         
            let okrMessageCachedData = dataRef.current
            if(result.data.okrMessageSubscription?.okrMessage?.okr?.id ===okrId){
              switch (result.data.okrMessageSubscription.mutation) {
                case 'CREATE':
                 
                    const added = okrMessageCachedData?.edges?.filter(item =>
                        item.id === result.data.okrMessageSubscription.okrMessage.id
                            ? false
                            : true).concat([{node:result.data.okrMessageSubscription.okrMessage}])
                           
                    if (mountedRef.current && added) {
                      setMessages({edges:added})
                   
                      dataRef.current ={edges:added}
                    }
                  
                    break
              
                  default:
                  break
            }
            }
          }
      });
      }
    }
 
  }
  
  const updatedataRef=(msg)=>{
    let ref = dataRef?.current
    ref.edges.push({node:msg})    
    dataRef.current =ref
    setMessages(ref)  
  }


  return (
    <>
      <MessagesView {...props}  okrId={okrId}
       contributors={contributors} okrMessages={messages} 
       updatedataRef={(e)=>{updatedataRef(e)}}
       ></MessagesView>
    </>
  );
};

export default   compose(withApollo,withMe,withCreateOkrMessage)(MessageContainer);