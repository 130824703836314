import React from 'react';
import styled from 'styled-components';
import { Divider, Modal, Checkbox, Row, Col, Form, Input, Card, Button } from 'antd';
import { CheckCircleFilled, UserAddOutlined, LoginOutlined, GoogleOutlined } from '@ant-design/icons';

import SignUpImg from '../../assets/SignUpImg.svg';
import LogoImg from '../../assets/LogoImg.png';
import { MetaTags } from '../../look';
import ROUTE from '../route';

const FormItems = Form.Item;

const layout = {
  labelCol: {
    span: 24
  },
  wrapperCol: {
    span: 24
  }
};
const RegisterView = props => {
  const { onSubmit, formErrors } = props;
  const [ visible, setVisible ] = React.useState( false );
  const [ check, setCheck ] = React.useState( false );

  return (
    <>
      <MetaTags title="Register" description="This is Register page" />
      <Row justify="center" align="middle">
        <Col xs={22} lg={15}>
          <br />
          <Card bodyStyle={{ padding: '0' }} style={{ boxShadow: '0px 4px 20px 10px rgba(84, 84, 84, 0.1)' }}>
            <Row /* justify="center" */ align="middle">
              <Col lg={11} md={11} sm={0} xs={0} style={{ overflow: 'hidden' }}>
                <SignUpContainer>
                  <SignUpTitle>Already have an account ?</SignUpTitle>
                  {/* <br />
                  <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                  <br /> */}
                  <br />
                  <a href={ROUTE.login}>
                    <SingUpBtn type="secondary" size="large">
                      <LoginOutlined />
                      Login
                    </SingUpBtn>
                  </a>
                </SignUpContainer>
                <img src={SignUpImg} alt="" height="100%" /* width="100%" */ />
              </Col>
              <FormCol lg={13} md={13} sm={24} xs={24}>
                <Row justify="center" align="middle">
                  <Col lg={22} md={22} sm={24} xs={24}>
                    <Row>
                      <Col lg={24} md={24} sm={0} xs={0}>
                        <img src={LogoImg} alt="" height={'50px'} />
                        {/* <Logo>Logo</Logo> */}
                      </Col>
                    </Row>
                    <br />
                    <br />
                    <Welcome>Create Account</Welcome>
                    <br />
                    <Form
                      {...layout}
                      requiredMark={false}
                      name="register-form"
                      onFinish={onSubmit}
                    // validateMessages={validateMessages}
                    >
                      <Row gutter={12}>
                        <Col span={12}>
                          <FormItem
                            name={[ 'user', 'firstName' ]}
                            label="First Name"
                            rules={[
                              {
                                required: true,
                                message: 'First Name is required'
                              }
                            ]}
                          >
                            <Input />
                          </FormItem>
                        </Col>
                        <Col span={12}>
                          <FormItem
                            name={[ 'user', 'lastName' ]}
                            label="Last Name"
                            rules={[
                              {
                                required: true
                              }
                            ]}
                          >
                            <Input />
                          </FormItem>
                        </Col>
                      </Row>

                      <FormItem
                        name={[ 'user', 'email' ]}
                        label="Email"
                        validateStatus={formErrors.email ? 'error' : ''}
                        help={formErrors.email && formErrors.email[ 0 ].message}
                        rules={[
                          {
                            required: true,
                            type: 'email',
                            message: 'Email is required'
                          }
                        ]}
                      >
                        <Input />
                      </FormItem>
                      <FormItem
                        name={[ 'user', 'username' ]}
                        label="User name"
                        validateStatus={formErrors.username ? 'error' : ''}
                        help={formErrors.username && formErrors.username[ 0 ].message}
                        rules={[
                          {
                            required: true,
                            message: 'user name is required'
                          }
                        ]}
                      >
                        <Input />
                      </FormItem>
                      {/* <FormItem
                          name={['user', 'mobile']}
                          label="Mobile Number"
                          rules={[
                            {
                              required: true
                            }
                          ]}
                        >
                          <Input />
                        </FormItem> */}
                      {/* <FormItem
                        name="password"
                        label="Password"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your password!',
                          },
                        ]}
                        validateStatus={formErrors.password1 ? 'error' : ''}
                        help={formErrors.password1 && formErrors.password1[0].message}
                        hasFeedback
                      >
                        <Input.Password placeholder="at least 8 characters" />
                      </FormItem>

                      <FormItem
                        name="confirm"
                        label="Confirm Password"
                        dependencies={['password']}
                        hasFeedback
                        validateStatus={formErrors.password2 ? 'error' : ''}
                        help={formErrors.password2 && formErrors.password2[0].message}
                        rules={[
                          {
                            required: true,
                            message: 'Please confirm your password!',
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue('password') === value) return Promise.resolve();

                              return Promise.reject(new Error('The two passwords that you entered do not match!'));
                            },
                          }),
                        ]}
                      >
                        <Input.Password />
                      </FormItem> */}
                      <FormItem
                        name="agreement"
                        valuePropName="checked"
                      // rules={[
                      //   {
                      //     validator: (_, value) =>
                      //       value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement')),
                      //   },
                      // ]}
                      // {...tailFormItemLayout}
                      >
                        <Checkbox onClick={() => setCheck( !check )}>
                          I accept
                          <a href="/terms"> Privacy policy </a>
                          and
                          <a href="/terms"> Terms of Services</a>
                        </Checkbox>
                      </FormItem>
                      <br />
                      <>
                        <Button
                          size="medium"
                          block
                          type="primary"
                          htmlType="submit"
                          disabled={!check}
                        // onClick={() => setVisible(true)}
                        >
                          <UserAddOutlined />
                          Sign up
                        </Button>
                        <Modal
                          closable={false}
                          footer={null}
                          centered
                          visible={visible}
                          onCancel={() => setVisible( false )}
                        // bodyStyle={{ padding: '24px 64px' }}
                        >
                          <h2>Check Email</h2>
                          <LessDivider />
                          <br />
                          <div style={{ textAlign: 'center' }}>
                            <MailSent>
                              <CheckCircleFilled /> &nbsp;
                              <span>Mail Send</span>
                            </MailSent>
                            <h3>Please check your Email.</h3>
                            <br />
                            <LessDivider />
                            <Button type="primary" ghost onClick={() => setVisible( false )} size="medium">
                              Okay
                            </Button>
                          </div>
                        </Modal>
                      </>
                    </Form>
                    {/* <br />
                    <Button size="medium" block type="default">
                    <GoogleOutlined />
                    Login with Google
                    </Button> */}
                    <Row>
                      <Col lg={0} md={0} sm={24} xs={24}>
                        <br />
                        <span style={{ color: 'grey' }}>Already have an Account ?</span>
                        &nbsp;
                        <a href={ROUTE.login}>Login</a>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </FormCol>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default RegisterView;

const FormItem = styled( FormItems )`
  margin-bottom: 5px;
`;

const FormCol = styled( Col )`
  @media only screen and (max-width: 480px) {
    padding: 24px;
  }
`;

const MailSent = styled.div`
  color: #009c4a;
  font-size: 25px;
`;

const LessDivider = styled( Divider )`
  margin: 0;
  margin-bottom: 0.5em;
`;

const SingUpBtn = styled( Button )`
  background-color: #fff;
  color: #4e0080;
`;

const Logo = styled.h3`
  font-weight: bold;
`;

const Welcome = styled.h2`
  font-weight: bold;
`;

const SignUpTitle = styled.span`
  font-size: 30px;
  font-weight: bold;
`;

const SignUpContainer = styled.div`
  position: absolute;
  z-index: 1;
  width: 68%;
  color: white;
  margin: 50px;
  bottom: 80px;
`;
