import gql from 'graphql-tag';
import { GROUP_TYPE } from './GroupType.gql';

export const ADD_GROUP_MUTATION = gql`
  ${GROUP_TYPE}
  mutation createGroup($groupData: GroupInput!) {
    createGroup(groupData: $groupData) {
      group {
        ...GroupType
      }
    }
  }
`;
