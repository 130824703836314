// @ts-nocheck
import React from 'react';
import { Form } from 'antd';
import { TextField } from 'modules/look';

const UserAccountUpdateView = props => {

    const { me, user_detail } = props
    const [form] = Form.useForm();


    return (
        <div>
            <h4>Update Profile</h4>
            <div style={{ width: "100%" }}>
                <Form form={form}
                    initialValues={
                        {
                            firstName: user_detail?.firstName,
                            lastName: user_detail?.lastName
                        }
                    }
                >
                    <TextField
                        className='token-input-field'
                        name="firstName"
                        label="firstName"
                        rules={[{ required: true, message: "firstName is required!", whitespace: false }]}
                    />
                    <TextField
                        name="lastName"
                        className='token-input-field'
                        label="lastName"
                        rules={[{ required: true, message: "lastName is required!", whitespace: false }]}
                    />
                </Form>
            </div>
        </div>
    )
};

export default UserAccountUpdateView;

