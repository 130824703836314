import gql from 'graphql-tag';
import { CONTACT_REQUEST_TYPE } from './ContactRequestType.gql';

export const CONTACT_SUBSCRIPTION = gql`
  ${CONTACT_REQUEST_TYPE}
  subscription contactUsSubscription {
    contactUsSubscription {
      mutation
      contactRequest {
        ...ContactRequestType
      }
    }
  }
`;
