import gql from 'graphql-tag';
import { SURVEY_TYPE } from './SurveyType.gql';

export const DELETE_SURVEY = gql`
  ${SURVEY_TYPE}
  mutation deleteSurvey($id: ID!) {
    deleteSurvey(id: $id) {
      survey {
        ...SurveyType
      }
    }
  }
`;
