import React from 'react';
import {  Progress, Tooltip} from "antd"
import styled from 'styled-components';

const CustomProgress =(props)=>{
    const {progress,showTooltip,info=false,lineHeight,showPercentageOnTop = false,color}=props
    return (
       <Tooltip title={showTooltip?`${progress}%`:''}>
         {
            !showPercentageOnTop ? 
            <Progress
            className='progress'
            percent={progress}
            strokeColor={{
                "0%": '#02D7A4',
                "100%":' #25EA68',
               
                
            }}
            style={{
                display: "block",
                lineHeight:lineHeight ? lineHeight : null
            }}
            showInfo={info}
        />
        :
       
        <PercentageOnTop
        className='progress'
        percent={progress}
        color = {color}
       
        style={{
            display:'flex',
            flexDirection:'column-reverse',
            color:color
           
        }}
        showInfo={info}
    />
         }
        
       </Tooltip>
    )
}

export default CustomProgress



const PercentageOnTop = styled(Progress).attrs((props:{color:String})=>props)`
.ant-progress-text{
    margin-bottom:5px;
    margin-left:0px;
    color:${(color)=>color.color};
}

.ant-progress-bg{
    height:6px !important; 
    background:${(color)=>color.color} !important;
    background-image: none;
}

.ant-progress-outer {
    margin-right:none;
    padding-right:0px !important;
}
`;