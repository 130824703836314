import gql from 'graphql-tag';

export const GET_BADGE_BY_ID =gql`
    query badge($id: ID!){
        badge(id:$id){
            id
            image
            title
            behaviouralDescription
            catrgory
            badgeFamily{
              id
              title
            }
        }
    }
`