import { Col, Row } from 'antd';
import React from 'react';
import '../css/SalesPageStyles.css'
import svg_log from '../../assets/new-map-logo.svg'
import { useHistory } from 'react-router-dom/cjs/react-router-dom';




const SalesPageFooter = props => {


    const SalesPageToolsSet1 = [{ title: "Soul identity ", id: 10, link: '/intro/soul' }, { title: "Whoposhoro", type:"whoposhoro", id: 1 }, { title: "One-On-One",type:"oneonone",  id: 2 }, { title: "OKR",type:"okr", id: 3 }, { title: "Feedback",type:"feedback", id: 4 }]
    const SalesPageToolsSet2 = [{ title: "Coaching Conversation",type:"coaching", id: 5 }, { title: "Readiness Tool",type:"readiness",id: 6 }, { title: "Trust Tool", id: 7 }, { title: "Scape Tool", id: 8 }, { title: "The Whole", id: 9 }]

    const Programs = [{ title: "Org development", link: "https://brainayan.com/organizational-development/" }, "Leadership development", { title: "Manager development", link: "https://brainayan.com/manager-development/" },
    { title: "Diversity and inclusion", link: "https://brainayan.com/diversity-and-inclusion/" },
    { title: "Communication skills", link: "https://brainayan.com/communication-skills/" }, { title: "Psychometry", link: "https://brainayan.com/psychometry/" }]

    const history = useHistory()

    return (
        <>
            <Row justify='center' className='poppins sales-page-footer-container' style={{ gap: '10px' }}>
                <Col xs={21} sm={21} md={5} lg={5} className="sales-footer-spacer ">
                    <img src={svg_log} style={{ width: '170px', marginBottom: '30px' }} />
                    <div className="footer-mapp-description">Manager Awesomeness App comprises a suite of online tools that enhance manager effectiveness by offering a
                        range of solutions.</div>
                </Col>

                <Col xs={21} sm={21} md={7} lg={7} className="sales-footer-spacer tool-set-footer-sales" >
                    <div style={{ marginLeft: '8px' }} className='footer-column-header-sales-page'>Tools</div>
                    <Row justify='center' >
                        <Col xs={21} sm={21} md={11} lg={11}>
                            {
                                SalesPageToolsSet1.map((tool) =>
                                    <div onClick={(e) =>
                                        history.push(tool?.link ? tool?.link : `/intro/tool/${tool.type}/${tool.id}`)
                                    } className='footer-menu-item-sales-page'>{tool.title}</div>
                                )
                            }
                        </Col>

                        <Col xs={21} sm={21} md={12} lg={12}>
                            {
                                SalesPageToolsSet2.map((tool) =>
                                    <div onClick={(e) => {
                                        (tool?.id === 6 || tool?.id === 5) &&
                                            history.push(`/intro/tool/${tool.type}/${tool.id}`)
                                    }
                                    }
                                        className='footer-menu-item-sales-page' style={{ cursor: (tool?.id === 6 || tool?.id === 5) ? "pointer" : "default" }}>{tool.title}</div>
                                )
                            }
                        </Col>

                    </Row>

                </Col>
                <Col xs={21} sm={21} md={4} lg={4} className="sales-footer-spacer" >
                    <div className='footer-column-header-sales-page'>Programs</div>
                    {
                        Programs?.map((program) =>
                            <div className='footer-menu-item-sales-page' onClick={(e) => window.open(program?.link)} >{program?.title}</div>
                        )
                    }
                </Col>
                <Col xs={21} sm={21} md={4} lg={4} className="sales-footer-spacer" >
                    <div className='footer-column-header-sales-page'>Legal</div>
                    <div className='footer-menu-item-sales-page' onClick={(e) => window.open("/page/terms-and-condition/privacy", "_blank")} >Privacy Policy </div>
                    <div className='footer-menu-item-sales-page' onClick={(e) => window.open("/page/terms-and-condition/terms-and-condition", "_blank")}>Terms & conditions</div>
                </Col>
            </Row>
            <div className='poppins' style={{ background: "#ECF1F4", padding: '25px', textAlign: 'center', color: '#868686' }}>© 2023 · MApp | All Rights Reserved.</div>
        </>
    );
};

export default SalesPageFooter;
