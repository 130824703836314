
import React from "react";
import { compose } from "../../core";
import { withApollo } from '@apollo/client/react/hoc';
import UserManualHomeView from "../components/user-manuel-home-view";
import { CHECK_ORG_COMPARISON } from "../graphql/checkOrgComparison";
import { RESPONSE_BY_RESPONSE_ID } from "../graphql/responseByResponseId.gql";
import { SURVEY_BY_ID } from "../graphql/surveyById.gql";
import { getResponsesBase64StringFromInt, getSurveyBase64StringFromInt, globalPermissionValidator } from "modules/look";
import { GET_RESPONSE_FROM_REQUESTED_USER } from "../graphql/userReportData.gql";
import NoPermissionView from "modules/kudos-user/component/NoPermissionComponent";
import { user_manual_permissions } from "Permissions/usermanual.permission";
import { Spin, message } from "antd";
const UserManualHomeContainer = (props) => {
    const { client, me, userPermission } = props

    const [allowComparison, setallowComparison] = React.useState()
    const [surveyReasponse, setSurveyResponse] = React.useState()
    const [survey_by_id, setSurveyById] = React.useState()
    const [comparison_response, setComparisonResponse] = React.useState()
    const [loading, setLoading] = React.useState(true)
    const [permission, setPermission] = React.useState()

    React.useEffect(() => {
        if (permission?.required_permission) {
            checkOrgComparison()
            survayResponse()
            surveyById()
        }
    }, [permission])


    //Permission Check
    React.useEffect(() => {
        if (userPermission?.length) {
            let permission = globalPermissionValidator(user_manual_permissions, userPermission)
            setPermission(permission)
        }
    }, [userPermission])

    const checkOrgComparison = async () => {
        const { data } = await client.query({
            query: CHECK_ORG_COMPARISON,
            variables: {},
            fetchPolicy: "network-only"
        });
        if (data?.me?.employee?.organizationSet) {
            setallowComparison(data?.me?.employee?.organizationSet?.edges[0]?.node?.allowComparison)
        }
    }

    const surveyById = async () => {
        const { data } = await client.query({
            query: SURVEY_BY_ID,
            variables: { id: getSurveyBase64StringFromInt(props.match.params.survey_id) },
            fetchPolicy: "network-only"
        });
        if (data) {
            setSurveyById(data?.SurveyById)
        }
    }

    const survayResponse = async () => {
        setLoading(true)
        try {
            const { data } = await client.query({
                query: RESPONSE_BY_RESPONSE_ID,
                variables: { id: getResponsesBase64StringFromInt(props.match.params.response_id) },
                fetchPolicy: "network-only"
            });
            if (data?.responseByResponseId) {
                setSurveyResponse(data?.responseByResponseId?.answerSet?.edges)
                setLoading(false)
            }
            else {
                setLoading(false)
            }
        }
        catch (e) {
            setLoading(false)
            throw new Error(e.message);
        }
    }

    const getRequestResponse = async (value) => {
        setLoading(true)
        try {
            const { data } = await client.query({
                query: GET_RESPONSE_FROM_REQUESTED_USER,
                variables: { ...value },
                fetchPolicy: "network-only"
            });
            if (data?.getReportDataForRequestedUsersUserManualSurvey) {
                setComparisonResponse(data?.getReportDataForRequestedUsersUserManualSurvey?.answerSet?.edges)
                setLoading(false)
            }
        }
        catch (e) {
            setLoading(false)
            setComparisonResponse([])
            message.error("User has not taken user manual survey")
        }
    }


    return (
        <>
            {
                (permission && permission?.required_permission) && (
                    <UserManualHomeView allowComparison={allowComparison} {...props} surveyReasponse={surveyReasponse} surveyById={survey_by_id} loading={loading} comparisonResponse={comparison_response} getRequestResponse={(e) => getRequestResponse(e)} clearComparison={() => setComparisonResponse()} permission={permission}/>
                )
            }
            {
                (permission && !permission?.required_permission) && (
                    <NoPermissionView />
                )
            }

            {
                !permission && (
                    <div style={{ width: "100%", height: "80vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Spin spinning={true}>

                        </Spin>
                    </div>
                )
            }

          
        </>
    )
}

export default compose(withApollo)(UserManualHomeContainer);
