import gql from 'graphql-tag';

export const UPDATE_OKR_CONTRIBUTOR_MUTATION = gql`
mutation updateContributor($id: ID!,$role: String,$weight: Decimal){
    updateContributor(id:$id,role:$role,id:$id,weight:$weight){
        contributor{
            id
            okr{
              id
              title
            }
          } 
    }
}
`