import gql from 'graphql-tag';
import { QUESTION_TYPE } from './QuestionType.gql';

export const ADD_QUESTION_MUTATION = gql`
  ${QUESTION_TYPE}
  mutation createQuestion($questionData: QueationInput!) {
    createQuestion(questionData: $questionData) {
      question {
        ...QuestionType
      }
    }
  }
`;
