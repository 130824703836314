import gql from 'graphql-tag';
import { GROUP_TYPE } from './GroupType.gql';

export const DELETE_GROUP = gql`
  ${GROUP_TYPE}
  mutation deleteGroup($id: ID!) {
    deleteGroup(id: $id) {
      group {
        ...GroupType
      }
    }
  }
`;
