import React from 'react';
import { useParams } from 'react-router-dom';
import { message } from 'antd';

import { compose } from '../../core';

import StoryDetailView from '../components/AdminStoryDetailView';

import { withLikeStory, withPublishedStoryById } from './StoryOperations';
import { subscribeToPublishedStory } from './StorySubscriptions';

const StoryDetail = props => {
  const { likeStory, subscribeToMore } = props;
  const { id } = useParams();

  React.useEffect(() => {
    const subscribe = subscribeToPublishedStory(subscribeToMore /* , props.filter */);
    return () => subscribe();
  });

  const handleLike = async () => {
    try {
      const response = await likeStory(id);
      response && message.success('Story liked!');
      return response;
    } catch (e) {
      throw Error(e.message);
    }
  };

  return <StoryDetailView onLike={handleLike} {...props} />;
};

export default compose(withPublishedStoryById, withLikeStory)(StoryDetail);
