import gql from 'graphql-tag';


export const DISCARD_REQUEST_NEW_LIST = gql`
query discardRequests($okr_Goalstatement_Icontains:String,$offset: Int,$first: Int,$tz:String){
    discardRequest: me{
        id
        employee {
            id
            orgCeoEmployee {
              edges {
                node {
                  id
                  employees {
                    edges {
                      node {
                        id
                        initiatedDiscardRequestSet(okr_Goalstatement_Icontains:$okr_Goalstatement_Icontains,offset:$offset,first:$first) {
                            pageInfo{
                                hasNextPage
                            }
                            totalCount
                          edges {
                            node {
                              id
                              status
                              createdAt
                              initiator{
                                id
                                  user{
                                    id
                                    firstName
                                    lastName
                                  }
                                }
                              okr{
                                id
                                title(tz:$tz)
                                okrType
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            teamManagerEmployee {
              edges {
                node {
                  id
                  employees {
                    edges {
                      node {
                        id
                        initiatedDiscardRequestSet(okr_Goalstatement_Icontains:$okr_Goalstatement_Icontains,offset:$offset,first:$first) {
                            totalCount
                            pageInfo{
                                hasNextPage
                            }
                          edges {
                            node {
                              id
                              status
                              createdAt
                              initiator{
                                id
                                  user{
                                    id
                                    firstName
                                    lastName
                                  }
                                }
                              okr{
                                id
                                title(tz:$tz)
                                okrType
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            verticalHeadEmployee {
              edges {
                node {
                  id
                  employees {
                    edges {
                      node {
                        id
                        initiatedDiscardRequestSet(okr_Goalstatement_Icontains:$okr_Goalstatement_Icontains,offset:$offset,first:$first) {
                            totalCount
                            pageInfo{
                                hasNextPage
                            }
                          edges {
                            node {
                              id
                              status
                              createdAt
                              initiator{
                                id
                                  user{
                                    id
                                    firstName
                                    lastName
                                  }
                                }
                              okr{
                                id
                                title(tz:$tz)
                                okrType  
                            }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
    }
}
`

export const DISCARD_REQUEST_FOR_PENDING = gql`
query discardRequests($okr_Goalstatement_Icontains:String,$offset: Int,$first: Int,$tz:String){
    discardRequest: me{
        id
        employee {
            id
            orgCeoEmployee {
              edges {
                node {
                  id
                  employees {
                    edges {
                      node {
                        id
                        initiatedDiscardRequestSet(okr_Goalstatement_Icontains:$okr_Goalstatement_Icontains,offset:$offset,first:$first,status:"PENDING") {
                            pageInfo{
                                hasNextPage
                            }
                            totalCount
                          edges {
                            node {
                              id
                              status
                              createdAt
                              initiator{
                                id
                                  user{
                                    id
                                    firstName
                                    lastName
                                  }
                                }
                              okr{
                                id
                                title(tz:$tz)
                                okrType
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            teamManagerEmployee {
              edges {
                node {
                  id
                  employees {
                    edges {
                      node {
                        id
                        initiatedDiscardRequestSet(okr_Goalstatement_Icontains:$okr_Goalstatement_Icontains,offset:$offset,first:$first,status:"PENDING") {
                            totalCount
                            pageInfo{
                                hasNextPage
                            }
                          edges {
                            node {
                              id
                              status
                              createdAt
                              initiator{
                                id
                                  user{
                                    id
                                    firstName
                                    lastName
                                  }
                                }
                              okr{
                                id
                                title(tz:$tz)
                                okrType
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            verticalHeadEmployee {
              edges {
                node {
                  id
                  employees {
                    edges {
                      node {
                        id
                        initiatedDiscardRequestSet(okr_Goalstatement_Icontains:$okr_Goalstatement_Icontains,offset:$offset,first:$first,status:"PENDING") {
                            totalCount
                            pageInfo{
                                hasNextPage
                            }
                          edges {
                            node {
                              id
                              status
                              createdAt
                              initiator{
                                id
                                  user{
                                    id
                                    firstName
                                    lastName
                                  }
                                }
                              okr{
                                id
                                title(tz:$tz)
                                okrType  
                            }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
    }
}
`