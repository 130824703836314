import { compose } from "modules/core";
import TeamAssessmentReportView from "modules/poc-dashboard/components/dashboard/teamAssessmentReportView";
import React from "react";
import { withApollo } from '@apollo/client/react/hoc'
import { ASSESSMENT_RESPONSE_SET_FOR_EMPLOYEE, POC_MANAGER_EMPLOYEE_DETAIL, SURVEY_BY_ID_FOR_REPORT, TEAM_ASSESSMENT_REPORTS } from "modules/poc-dashboard/graphql/pocDashboardQuery.gql";
import { getSurveyBase64StringFromInt } from "modules/look";
const TeamAssessmentContainer =(props)=>{
    const { client,me,history } = props
    const [SurveyReport,setSurveyReport]=React.useState()
    const [permission,setpermission]= React.useState(false)
    const [ surveyDetail,setSurveyDetail ]=React.useState()
    const [employees,setEmployees] = React.useState([]) 
    const [responseSet,setresponseSet] = React.useState() 

    const getSurveyReport = async (filterData) => {
      try{
        const { data } = await client.query({
            query: TEAM_ASSESSMENT_REPORTS,
            variables: filterData
        });
        if(data?.teamMemberReport){
           let report =data?.teamMemberReport?.employee?.organizationSet?.edges[0]?.node?.employees?.edges[0]?.node?.user?.responseSet?.edges[0]?.node?.report360
            if(report){
              let report360 = JSON.parse(report)
              setSurveyReport({report360})
            }else{
              setSurveyReport(null)
            }
    
        }else{
          setSurveyReport(null)
        }
      }catch(error){
        console.error(error)
      }
    }
    const getResponseSet = async (filterData) => {
      try{
        let filter = {...filterData,survey:getSurveyBase64StringFromInt(props?.match?.params?.survey_id)} 
        const { data } = await client.query({
            query: ASSESSMENT_RESPONSE_SET_FOR_EMPLOYEE,
            variables: filter,
            fetchPolicy: "network-only"
        });
        if(data?.responseSetList){
            setresponseSet(data?.responseSetList)
        }
      }catch(error){
        console.error(error)
      }
    }

      const getEmployeeDetail = async()=>{
        const { data } = await client.query({
              query: POC_MANAGER_EMPLOYEE_DETAIL,
          });
          if(data?.me){
            let Detail =data?.me?.employee
            if(Detail?.teamManagerEmployee?.totalCount || Detail?.orgCeoEmployee?.totalCount || Detail?.orgPocEmployee?.totalCount || Detail?.verticalHeadEmployee?.totalCount){
              let employees =[]
              if(Detail?.orgCeoEmployee?.totalCount){
               
                employees=employees.concat(Detail?.orgCeoEmployee?.edges?.map(({node})=>node?.employees?.edges))
              }
              if(Detail?.teamManagerEmployee?.totalCount){
                employees=employees.concat(Detail?.teamManagerEmployee?.edges?.map(({node})=>node?.employees?.edges))
              }
              if(Detail?.orgPocEmployee?.totalCount){
            
                employees=employees.concat(Detail?.orgPocEmployee?.edges?.map(({node})=>node?.employees?.edges))
              }
              if(Detail?.verticalHeadEmployee?.totalCount){
                employees=employees.concat(Detail?.verticalHeadEmployee?.edges?.map(({node})=>node?.employees?.edges))
              }
          
              
              employees = [].concat.apply([],employees) 
              var setObj = new Set(); // create key value pair from array of array
  
                var result = employees.reduce((acc,item)=>{
                  if(!setObj.has(item.node?.user?.id)){
                    setObj.add(item.node?.user?.id)
                    acc.push(item)
                  }
                  return acc;
                },[])
              result = result.map(({node})=>node?.user) 
              console.log("result",result)
              setEmployees(result)
            }
            else{
              history.push('/home')
            }
           
          }
      }
  
      const getSurveyDetail = async(filter)=>{
          const { data } = await client.query({
              query: SURVEY_BY_ID_FOR_REPORT,
              variables: filter
          });
  
          if(data?.SurveyById){
              setSurveyDetail(data?.SurveyById)
          }
      }
    
      React.useEffect(()=>{
          if(me){
              if(props?.match?.params?.survey_id){
                  let id = getSurveyBase64StringFromInt(props?.match?.params?.survey_id)
                  getSurveyDetail({id:id})
                  getEmployeeDetail()
              }
              
            }
            else if (me===null){
              history.push('/user/login')
            }
      },[me])
    return (
        <>
        <TeamAssessmentReportView 
        {...props} 
         SurveyReport={SurveyReport} 
         surveyDetail={surveyDetail} 
         employees={employees} 
         changeUser={(e)=>{getResponseSet(e)}} 
         responseSet={responseSet} 
         get360Report={(e)=>{getSurveyReport(e)}}/>
        </>
    )
}

export default compose(withApollo)(TeamAssessmentContainer)