import React from "react";
import { Spin } from "antd";

import { compose } from "../../core";
import { ButtonsCatch, getIntFromString, withMarkdownFieldValue } from "../../look";

import AddStoryView from "../components/AddStoryView";
import ROUTE from "../route";
import { CKEditorFieldName } from "../components/StoryFormComponent";

import { withAddStory, withStoryState } from "./StoryOperations";

const AddStory = props => {
  const { history, createStory } = props;
  const [submitLoading, setSubmitLoading] = React.useState(false);

  const handleSubmit = async (values, item) => {
    setSubmitLoading(true);
    try {
      const response = await createStory(withMarkdownFieldValue(CKEditorFieldName, values));
      response &&
        ButtonsCatch(
          item,
          ROUTE.story,
          history,
          item === undefined ? `${ROUTE.editStoryLink}${getIntFromString(response.id)}` : window.location.pathname
        );
    } catch (e) {
      throw new Error(e.message);
    }
    setSubmitLoading(false);
  };

  return (
    <Spin spinning={submitLoading} size="large">
      <AddStoryView onSubmit={handleSubmit} {...props} />
    </Spin>
  );
};

export default compose(withStoryState, withAddStory)(AddStory);
