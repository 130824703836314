import React from 'react';

import { AdminLayout } from '../../../../look';
import ROUTES from '../../../route';
import FaqQuestionFormComponent from './FaqQuestionFormComponent';

const AddFaqQuestionView = props => {
  return (
    <AdminLayout
      table={false}
      active={ROUTES.faq}
      title="Add FAQ Question"
      FormComponent={<FaqQuestionFormComponent {...props} />}
    />
  );
};

export default AddFaqQuestionView;
