// @ts-nocheck
import React from 'react';
const ReadinessNavbar = (props) => {
    const { permission, setCurrentTab, current_tab } = props

    const [sidebar_tab, setSideBarTab] = React.useState([
        {
            key: 'assessments',
            label: 'Assessments',
        },
        {
            key: 'self_assessments',
            label: 'Self Assessments',
        },

    ]) // list of sidebar menu


    return (
        <>
            <div className='rd-container rd-sidebar-view'>
                {
                    sidebar_tab?.map(sidebar => (
                        <div className={`rd-sidebar-normal ${(current_tab?.key === sidebar?.key) ? 'rd-active-sidebar' : ''}`} onClick={() => setCurrentTab({ ...sidebar })}>
                            <h4 className={`rd-sidebar-label ${(current_tab?.key === sidebar?.key) ? 'rd-active-sidebar-label' : ''}`}>{sidebar?.label}</h4>
                        </div>

                    ))
                }
                <div className='border-line'>

                </div>
            </div>
        </>
    );


}

export default ReadinessNavbar