export const readiness_color_zone = [
    {
        color:"green",
        zone:"Green Zone - Aspiration",
        zone_id:"zone_1",
        rule:[
            {
                question_id:"UXVlc3Rpb25UeXBlOjY4OA==",
                conditions:[
                    {
                        operation:"<=",
                        value:25
                    }
                ]
            },
            {
                question_id:"UXVlc3Rpb25UeXBlOjY4OQ==",
                conditions:[
                    {
                        operation:"<=",
                        value:25
                    }
                ]
            },
            {
                question_id:"UXVlc3Rpb25UeXBlOjY5MA==",
                conditions:[
                    {
                        operation:"<=",
                        value:25
                    }
                ]
            },
            {
                question_id:"UXVlc3Rpb25UeXBlOjY5MQ==",
                conditions:[
                    {
                        operation:"<=",
                        value:80
                    },
                    {
                        operation:">",
                        value:60
                    }
                ]
            }
        ]
    },
    {
        color:"green",
        zone:"Green Zone - Possible Weakness",
        zone_id:"zone_2",
        rule:[
            {
                question_id:"UXVlc3Rpb25UeXBlOjY4OA==",
                conditions:[
                    {
                        operation:"<=",
                        value:25
                    }
                ]
            },
            {
                question_id:"UXVlc3Rpb25UeXBlOjY4OQ==",
                conditions:[
                    {
                        operation:"<=",
                        value:25
                    }
                ]
            },
            {
                question_id:"UXVlc3Rpb25UeXBlOjY5MA==",
                conditions:[
                    {
                        operation:"<=",
                        value:25
                    }
                ]
            },
            {
                question_id:"UXVlc3Rpb25UeXBlOjY5MQ==",
                conditions:[
                    {
                        operation:"<=",
                        value:25
                    }
                ]
            }
        ]
    },
    {
        color:"green",
        zone:"Green Zone - Clear Aspiration/Possible Strength",
        zone_id:"zone_3",
        rule:[
            {
                question_id:"UXVlc3Rpb25UeXBlOjY4OA==",
                conditions:[
                    {
                        operation:">",
                        value:25
                    },
                    {
                        operation:"<=",
                        value:50
                    }
                ]
            },
            {
                question_id:"UXVlc3Rpb25UeXBlOjY4OQ==",
                conditions:[
                    {
                        operation:"<=",
                        value:25
                    }
                ]
            },
            {
                question_id:"UXVlc3Rpb25UeXBlOjY5MA==",
                conditions:[
                    {
                        operation:"<=",
                        value:25
                    }
                ]
            },
            {
                question_id:"UXVlc3Rpb25UeXBlOjY5MQ==",
                conditions:[
                    {
                        operation:">",
                        value:80
                    }
                ]
            }
        ]
    },
    {
        color:"orange",
        zone:"Organe Zone - Intern",
        zone_id:"zone_4",
        rule:[
            {
                question_id:"UXVlc3Rpb25UeXBlOjY4OA==",
                conditions:[
                    {
                        operation:">",
                        value:50
                    },
                    {
                        operation:"<=",
                        value:60
                    }
                ]
            },
            {
                question_id:"UXVlc3Rpb25UeXBlOjY4OQ==",
                conditions:[
                    {
                        operation:">",
                        value:9
                    },
                    {
                        operation:"<=",
                        value:25
                    }
                ]
            },
            {
                question_id:"UXVlc3Rpb25UeXBlOjY5MA==",
                conditions:[
                    {
                        operation:"<=",
                        value:9
                    }
                ]
            },
            {
                question_id:"UXVlc3Rpb25UeXBlOjY5MQ==",
                conditions:[
                    {
                        operation:">",
                        value:80
                    }
                ]
            }
        ]
    },
    {
        color:"orange",
        zone:"Organe Zone - Learner",
        zone_id:"zone_5",
        rule:[
            {
                question_id:"UXVlc3Rpb25UeXBlOjY4OA==",
                conditions:[
                    {
                        operation:">",
                        value:50
                    },
                    {
                        operation:"<=",
                        value:60
                    }
                ]
            },
            {
                question_id:"UXVlc3Rpb25UeXBlOjY4OQ==",
                conditions:[
                    {
                        operation:">",
                        value:50
                    },
                    {
                        operation:"<=",
                        value:60
                    }
                ]
            },
            {
                question_id:"UXVlc3Rpb25UeXBlOjY5MA==",
                conditions:[
                    {
                        operation:">",
                        value:9
                    },
                    {
                        operation:"<=",
                        value:25
                    }
                ]
            },
            {
                question_id:"UXVlc3Rpb25UeXBlOjY5MQ==",
                conditions:[
                    {
                        operation:">",
                        value:80
                    }
                ]
            }
        ]
    },
    {
        color:"orange",
        zone:"Organe Zone - Struggling Learner",
        zone_id:"zone_6",
        rule:[
            {
                question_id:"UXVlc3Rpb25UeXBlOjY4OA==",
                conditions:[
                    {
                        operation:">",
                        value:50
                    },
                    {
                        operation:"<=",
                        value:60
                    }
                ]
            },
            {
                question_id:"UXVlc3Rpb25UeXBlOjY4OQ==",
                conditions:[
                    {
                        operation:">",
                        value:50
                    },
                    {
                        operation:"<=",
                        value:60
                    }
                ]
            },
            {
                question_id:"UXVlc3Rpb25UeXBlOjY5MA==",
                conditions:[
                    {
                        operation:">",
                        value:9
                    },
                    {
                        operation:"<=",
                        value:25
                    }
                ]
            },
            {
                question_id:"UXVlc3Rpb25UeXBlOjY5MQ==",
                conditions:[
                    {
                        operation:"<=",
                        value:80
                    }
                ]
            }
        ]
    },
    {
        color:"red",
        zone:"Red zone - Weakness",
        zone_id:"zone_7",
        rule:[
            {
                question_id:"UXVlc3Rpb25UeXBlOjY4OA==",
                conditions:[
                    {
                        operation:">",
                        value:50
                    },
                    {
                        operation:"<=",
                        value:60
                    }
                ]
            },
            {
                question_id:"UXVlc3Rpb25UeXBlOjY4OQ==",
                conditions:[
                    {
                        operation:">",
                        value:50
                    },
                    {
                        operation:"<=",
                        value:60
                    }
                ]
            },
            {
                question_id:"UXVlc3Rpb25UeXBlOjY5MA==",
                conditions:[
                    {
                        operation:"<=",
                        value:25
                    }
                ]
            },
            {
                question_id:"UXVlc3Rpb25UeXBlOjY5MQ==",
                conditions:[
                    {
                        operation:"<=",
                        value:25
                    }
                ]
            }
        ]
    },
    {
        color:"red",
        zone:"Red zone - Strong Weakness",
        zone_id:"zone_8",
        rule:[
            {
                question_id:"UXVlc3Rpb25UeXBlOjY4OA==",
                conditions:[
                    {
                        operation:">",
                        value:50
                    },
                    {
                        operation:"<=",
                        value:60
                    }
                ]
            },
            {
                question_id:"UXVlc3Rpb25UeXBlOjY4OQ==",
                conditions:[
                    {
                        operation:">",
                        value:50
                    },
                    {
                        operation:"<=",
                        value:60
                    }
                ]
            },
            {
                question_id:"UXVlc3Rpb25UeXBlOjY5MA==",
                conditions:[
                    {
                        operation:">",
                        value:50
                    },
                    {
                        operation:"<=",
                        value:60
                    }
                ]
            },
            {
                question_id:"UXVlc3Rpb25UeXBlOjY5MQ==",
                conditions:[
                    {
                        operation:"<=",
                        value:25
                    }
                ]
            }
        ]
    },
    {
        color:"red",
        zone:"Red zone - Misplaced/Forced Investment",
        zone_id:"zone_9",
        rule:[
            {
                question_id:"UXVlc3Rpb25UeXBlOjY4OA==",
                conditions:[
                    {
                        operation:">",
                        value:80
                    }
                ]
            },
            {
                question_id:"UXVlc3Rpb25UeXBlOjY4OQ==",
                conditions:[
                    {
                        operation:">",
                        value:80
                    }
                ]
            },
            {
                question_id:"UXVlc3Rpb25UeXBlOjY5MA==",
                conditions:[
                    {
                        operation:">",
                        value:60
                    },
                    {
                        operation:"<=",
                        value:80
                    }
                ]
            },
            {
                question_id:"UXVlc3Rpb25UeXBlOjY5MQ==",
                conditions:[
                    {
                        operation:">",
                        value:25
                    },
                    {
                        operation:"<=",
                        value:50
                    }
                ]
            }
        ]
    },
    {
        color:"purple",
        zone:"Purple zone - Strengthening",
        zone_id:"zone_10",
        rule:[
            {
                question_id:"UXVlc3Rpb25UeXBlOjY4OA==",
                conditions:[
                    {
                        operation:">",
                        value:50
                    },
                    {
                        operation:"<=",
                        value:60
                    }
                ]
            },
            {
                question_id:"UXVlc3Rpb25UeXBlOjY4OQ==",
                conditions:[
                    {
                        operation:">",
                        value:50
                    },
                    {
                        operation:"<=",
                        value:60
                    }
                ]
            },
            {
                question_id:"UXVlc3Rpb25UeXBlOjY5MA==",
                conditions:[
                    {
                        operation:">",
                        value:50
                    },
                    {
                        operation:"<=",
                        value:60
                    }
                ]
            },
            {
                question_id:"UXVlc3Rpb25UeXBlOjY5MQ==",
                conditions:[
                    {
                        operation:">",
                        value:80
                    }
                ]
            }
        ]
    },
    {
        color:"purple",
        zone:"Purple zone - Confirmed Strength",
        zone_id:"zone_11",
        rule:[
            {
                question_id:"UXVlc3Rpb25UeXBlOjY4OA==",
                conditions:[
                    {
                        operation:">=",
                        value:80
                    }
                ]
            },
            {
                question_id:"UXVlc3Rpb25UeXBlOjY4OQ==",
                conditions:[
                    {
                        operation:">",
                        value:50
                    },
                    {
                        operation:"<=",
                        value:60
                    }
                ]
            },
            {
                question_id:"UXVlc3Rpb25UeXBlOjY5MA==",
                conditions:[
                    {
                        operation:">",
                        value:50
                    },
                    {
                        operation:"<=",
                        value:60
                    }
                ]
            },
            {
                question_id:"UXVlc3Rpb25UeXBlOjY5MQ==",
                conditions:[
                    {
                        operation:">",
                        value:80
                    }
                ]
            }
        ]
    },
    {
        color:"blue",
        zone:"Blue zone - Productive",
        zone_id:"zone_12",
        rule:[
            {
                question_id:"UXVlc3Rpb25UeXBlOjY4OA==",
                conditions:[
                    {
                        operation:">",
                        value:80
                    }
                ]
            },
            {
                question_id:"UXVlc3Rpb25UeXBlOjY4OQ==",
                conditions:[
                    {
                        operation:">",
                        value:80
                    }
                ]
            },
            {
                question_id:"UXVlc3Rpb25UeXBlOjY5MA==",
                conditions:[
                    {
                        operation:">",
                        value:50
                    },
                    {
                        operation:"<=",
                        value:60
                    }
                ]
            },
            {
                question_id:"UXVlc3Rpb25UeXBlOjY5MQ==",
                conditions:[
                    {
                        operation:">",
                        value:80
                    }
                ]
            }
        ]
    },
    {
        color:"blue",
        zone:"Blue zone - Master",
        zone_id:"zone_13",
        rule:[
            {
                question_id:"UXVlc3Rpb25UeXBlOjY4OA==",
                conditions:[
                    {
                        operation:">",
                        value:80
                    }
                ]
            },
            {
                question_id:"UXVlc3Rpb25UeXBlOjY4OQ==",
                conditions:[
                    {
                        operation:">",
                        value:80
                    }
                ]
            },
            {
                question_id:"UXVlc3Rpb25UeXBlOjY5MA==",
                conditions:[
                    {
                        operation:">",
                        value:80
                    }
                ]
            },
            {
                question_id:"UXVlc3Rpb25UeXBlOjY5MQ==",
                conditions:[
                    {
                        operation:">",
                        value:80
                    }
                ]
            }
        ]
    }
    
]

export const readiness_zone_color = {
    green:"green",
    orange:"orange",
    red:"red",
    purple:"purple",
    blue:"blue"
}