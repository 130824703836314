import React from 'react';
import RoleAddEditContainer from '../containers/RoleAddEditContainer';
import RoleHomeContainer from '../containers/RoleHomeContainer';


const RoleMainView = (props)=>{

    const { current_role_tab } = props

        // list all view that are in the role definition
        const views = {
            role_home_view: RoleHomeContainer,
            role_add_edit_view: RoleAddEditContainer
        }
        
        const CurrentView = views[ Object.keys(views)?.includes(current_role_tab?.key)?current_role_tab?.key:'role_home_view' ]

        return (
            <CurrentView {...props} />
        )
    
}

export default RoleMainView