import React from "react";
import { withSurveyByIdNewOptimized } from './SurveyOperations'
import SoulSurveySetView from '../components/SoulSurveyView'
import { compose } from "../../core";
import NotFound from '../../page/containers/NotFound'
const SoulSurveySetContainer = (props) => {
    const selected= JSON.parse(localStorage.getItem('selectedSoulValue'))

    if(!selected){
        return (
            <>
            <NotFound></NotFound>
            </>
          )
    }
    else{
        return (
            <>
                <SoulSurveySetView {...props} data={selected}></SoulSurveySetView>
            </>
        )
    }
    
}
export default compose(withSurveyByIdNewOptimized)(SoulSurveySetContainer)

