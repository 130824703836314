import React from "react";
import { Modal } from "antd";
import { useHistory } from "react-router-dom";
import VerticalFormComponent from "./VerticalFormComponent";

const AddVerticalView = props => {
  const { setVisible } = props;
  const history = useHistory();

  return (
    <>
      <Modal
        destroyOnClose={true}
        visible={props.visible || window.location.href.includes("openmodal")}
        onCancel={() => {
          setVisible(false);
          history.push(window.location.pathname);
        }}
        footer={null}
        title="Add Vertical"
        width="80%"
      >
        <VerticalFormComponent {...props} />
      </Modal>
    </>
  );
};

export default AddVerticalView;
