import gql from 'graphql-tag';

export const UPDATE_QUALIFICATION_LEVEL = gql`

  mutation updateRatingScale($id: ID!,$sequence: Int) {
    updateRatingScale(id: $id,sequence:$sequence) {
      ratingScale{
            id
            sequence
        }
    }
  }
`;
