import { dasboardIcons, profile_icon } from 'modules/home/dashboard-icons';
import React from 'react';
import styled from 'styled-components';
import { Row, Spin } from 'antd'
import { CustomProgress } from 'modules/look-v2';
import AssessmentMemberContainer from '../containers/assessmentMemberContainer';

const TeamAssessmentView = ( props ) => {
    const {assessment_list,assessment_loading,team_member_list}=props
    const [ view_member, setViewMember ] = React.useState()
    const getTeamMemners=(team_list)=>{
        if(team_list?.length&&team_list?.length>4){
            return team_member_list?.slice(0,4)
        }
        else{
            return team_member_list
        }
    }
    return (
        <div className='user-dashboard-okr-performance-container'>
            <h4 className='user-board-section-heading'>Team Assessment Status</h4>
            <Spin spinning={assessment_loading}>
            <div className='dashboard-performance-table' style={{overflow:"auto",height:"25em"}}>
                <div className='dashboard-performance-table-heading' style={{ width: "100%", justifyContent: "start" }}>
                    <div className='dashobard-performance-table-heading' style={{ width: "40%", whiteSpace: "nowrap", paddingLeft: "1em" }}>ASSESSMENT NAME</div>
                    <div className='dashobard-performance-table-heading ' style={{ width: "30%", whiteSpace: "nowrap", paddingLeft: "1em" }}>TEAM STAUS</div>
                    <div className='dashobard-performance-table-heading' style={{ width: "30%", whiteSpace: "nowrap" }}>PROGRESS</div>
                </div>
                {assessment_list?.length ?assessment_list?.map(item=>(
                    <div className='team-assessment-data-cintainer'>
                    <div style={{ width: "40%", whiteSpace: "nowrap" }}>
                        <h4 style={{ fontFamily: "Poppins", fontWeight: "500", fontSize: "1em", margin: 0, color: "#000",textTransform:"uppercase",textOverflow:"ellipsis",overflow:"hidden" }}>{item?.name}</h4>
                    </div>
                    
                        <div style={{ width: "30%", position: "relative", background: "black" }} >
                        {team_member_list?.length>0&& getTeamMemners(team_member_list)?.map((value,index)=>(
                          <img style={{ height: "2.5em", width: "2.5em", borderRadius: "50%", position: "absolute", left:`${index}em`, top: "-18px",cursor:"pointer" }} src={value?.user?.profile?.profileImage?value?.user?.profile?.profileImage:profile_icon?.profile} alt="profile_1" onClick={() => setViewMember( item )}/>
                          ))}
                           {team_member_list?.length>4&&<div style={{ background: "white",height: "2.5em", width: "2.5em", borderRadius: "50%", position: "absolute", left: "4em", top: "-18px", border: '1px dashed #8998A2', color: "#8998A2", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
                            onClick={() => setViewMember( item )}>{(team_member_list?.length-4)>9?"9+":`+${team_member_list?.length-4}`}</div>}
                        </div>
                    <div style={{ width: "25%", whiteSpace: "nowrap" }}>
                        <ProgressRow
                            style={{
                                fontSize: '14px', color: '#000000',
                                fontWeight: '600',
                                width: "100%"
                            }} align='middle'>
                            <CustomProgress progress={Math.round(item?.summaryListByTeam['Completed%'])} info={true} showPercentageOnTop={true} color={item?.summaryListByTeam['Completed%']>50?'#3B8855':'#F95F53'} />
                        </ProgressRow>
                    </div>
                </div>
                )):
                <div className='no-performance-container' style={{ border: "none" }}>
                        <img src={dasboardIcons?.assessment_no_data} alt="diversity_no_data" />
                        <h4 className='baner-tip-title' style={{textAlign:'center'}}>Here you will see the  assessment update <br/>
                        (who has completed the assessment and  who is not)</h4>
                    </div>}
            </div>
            </Spin>
            {view_member && <AssessmentMemberContainer view_member={view_member} onCancel={() => setViewMember()} {...props} />}
        </div>
    )
}

export default TeamAssessmentView

const ProgressRow = styled( Row )`

.ant-progress-bg{
height:6px !important;
}
`;