import gql from 'graphql-tag';
export const USER_TYPE = gql`
  query me {
    userType:me {
     id
     employee{
        id
        teamManagerEmployee{
            totalCount
        }
        orgCeoEmployee{
            totalCount
        }
        orgPocEmployee{
            totalCount
        }
        verticalHeadEmployee{
            totalCount
        }
     }
    }
  }
`;




