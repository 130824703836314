// DO NOT EDIT THIS FILE

import gql from 'graphql-tag';
export const COMPARISON_REQ_RECEIVED = gql`
query allComparisonRequestForUser($survey:ID,$endCursor:String) {
  allComparisonRequestForUser(survey: $survey,first:10,after:$endCursor) {
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
        edges{
          node{
            id
            message
            requestDate
            accepted
            sharedResponse{
              id
            }
            survey{
              id
              name
            }
            sender{
              id
              firstName
              lastName
              profile{
                id
                profileImage
              }
            }
            receiver{
              id
              firstName
              lastName
              profile{
                id
                profileImage
              }
            }
          }
        }
    }
  }
`;
