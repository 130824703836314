import { Col, Modal, Row } from "antd";
import React from "react";
import badge_sample from '../../assets/badge-sample.png'
import modal_close from '../../assets/agenda-close.svg'
const RecentBadge = ( props ) => {
    const { recentVisible,onCancel,newBadgeList } = props
    console.log(newBadgeList)
    const [selectedItem,setSelectedItem]=React.useState()
    React.useEffect(()=>{
        if(newBadgeList?.length>0){
            setSelectedItem(newBadgeList)
        }
    },[newBadgeList])

    const closeData =(closeId)=>{
        if(selectedItem?.length>1){
            let list =selectedItem?.filter(item=>item?.id!==closeId)
            setSelectedItem(list)
        }
        else{
            onCancel()
        }
    }
    return (
        <>
            <Modal
                closable={false}
                visible={recentVisible}
                destroyOnClose={true}
                footer={false}
                centered={true}
                className="recent-badge-modal">
                {selectedItem?.length>0&&<div className="recent-modal-container" style={{  display: "flex", flexDirection: "column", justifyContent: "space-between",alignItems:"center", width: "100%",cursor:"pointer",position:'relative'}}onClick={()=>closeData(selectedItem[0]?.id)}>
                <img src={modal_close} alt="" style={{ cursor: "pointer", position: "absolute", right: "-5px", top: "-5px" }} onClick={() =>  onCancel()} />
                    <div className="recent-modal-image">
                    <img  src={selectedItem[0]?.badge?.image} alt="" style={{width:"100%",height:"100%"}} />
                    </div> 
                    <div style={{background:'#fff',display:'flex',flexDirection:"column", justifyContent:'center',alignItems:"center",padding:"5px 10px",marginTop:"10px"}}>
                        <h2 className="recent-modal-title"style={{lineHeight:"2em"}}>congratulations ! </h2>
                        <h2 style={{fontFamily:"Poppins",fontWeight:"600",fontSize:"2em",margin:"0",lineHeight:"2em"}}>{selectedItem[0]?.badge?.title}</h2>
                        <h4 className="recent-modal-sub-title"style={{lineHeight:"2em"}}>you got a new kudos</h4>
                    </div>
                </div>}
            </Modal>
        </>
    )

}

export default RecentBadge