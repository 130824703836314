import React from 'react';
import { Col, Button, Spin, Modal, Form, Divider, Row, Switch } from 'antd';

import {
  SelectField,
  TextField,
  CKEditorField,
  InputValues,
  withMarkdownFieldValue,
  QuestionAutoComplete,
  getIntFromString,
  EditIcon
} from '../../look';

import { withAddReferenceField, withEditReferenceField } from './FormSectionOperation';
import { compose } from '../../core';

export const CKEditorFieldName = 'helpText';

const AddReferenceField = props => {
  const { btn, referenceField, btnText, createReferenceField, onSubmit, editReferenceField } = props;
  const [visible, setVisible] = React.useState(false);
  const [actionLoading, setActionLoading] = React.useState(false);

  const handleSubmit = async values => {
    setActionLoading(true);
    try {
      if (!referenceField) {
        const response = await createReferenceField({ referenceFieldData: withMarkdownFieldValue(CKEditorFieldName, values) });
        response && setVisible(false);
        response && onSubmit(getIntFromString(response.id));
      } else {
        const response = await editReferenceField({
          ...withMarkdownFieldValue(CKEditorFieldName, values),
          id: getIntFromString(referenceField.id)
        });
        response && setVisible(false);
        response && onSubmit(getIntFromString(response.id));
      }
      setActionLoading(false);
    } catch (err) {
      setActionLoading(false);
      throw Error(err.message);
    }
  };

  const initialValue = {
    questionId: getIntFromString(referenceField?.question?.id),
    // sequence: referenceField?.sequence,
    editable: referenceField?.editable,
    helpText: referenceField?.helpText
  };

  return (
    <>
      {btn === 'add' ? (
        <Button type="primary" ghost onClick={() => setVisible(true)}>
          Add Reference Field
        </Button>
      ) : (
        <EditIcon tooltipSuffix="Reference Field" type="link" size="sm" onClick={() => setVisible(true)} />
      )}
      <Modal
        destroyOnClose
        centered
        visible={/* true || */ visible || window.location.href.includes('openmodal')}
        onCancel={() => setVisible(false)}
        // footer={null}
        width="50%"
        title={btn === 'add' ? 'Add Reference Field' : 'Edit Reference Field'}
        footer={null}
      >
        <Spin spinning={actionLoading} size="medium">
          <Form layout="vertical" initialValues={initialValue} onFinish={handleSubmit} scrollToFirstError={true}>
            <Row gutter={24}>
              <Col lg={12} md={24}>
                <QuestionAutoComplete
                  name="questionId"
                  offset={1}
                  label="Question"
                  rules={[{ required: true, message: 'Question is required!' }]}
                />
              </Col>
              <Col lg={12} md={24}>
                <Form.Item
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24, offset: 1 }}
                  name="editable"
                  label="Editable"
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
              </Col>
              <Col span={24}>
                <CKEditorField
                  name={CKEditorFieldName}
                  label="Help Text"
                  initialValue={initialValue && initialValue.helpText}
                  // rules={[{ required: true, message: 'All questions are required!' }]}
                />
              </Col>
            </Row>

            <Divider />
            <Row>
              <Col>
                <Button type="primary" htmlType="submit">
                  {btnText}
                </Button>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default compose(withAddReferenceField, withEditReferenceField)(AddReferenceField);
