import gql from 'graphql-tag';
import { USER_TYPE } from '../../user/graphql/UserType.gql';
// import { STORY_TYPE } from './StoryType.gql';

// ${STORY_TYPE}
export const COMMENT_TYPE = gql`
  ${USER_TYPE}
  fragment CommentType on CommentType {
    id
    commentText
    commentDate
    user {
      ...UserType
    }
    # story {
    #   ...StoryType
    # }
  }
`;
