import gql from 'graphql-tag';

export const GET_BADGEFAMILY_BY_ID =gql`
    query badgeFamilyById($id: ID!){
        badgeFamilyById(id:$id){
            id
            title
            description
            badgeSet{
              edges{
                node{
                  id
                  title
                  behaviouralDescription
                  image
                  duration
                  catrgory
                }
              }
            }
        }
    }
`