import gql from 'graphql-tag';
import { CONDITION_TYPE } from './ConditionType.gql';

export const EDIT_CONDITION = gql`
  ${CONDITION_TYPE}
  mutation editCondition($id: ID!, $leftOperandId: Int, $note: String, $operator: String, $rightOperandId: Int) {
    updateCondition(
      id: $id
      leftOperandId: $leftOperandId
      note: $note
      operator: $operator
      rightOperandId: $rightOperandId
    ) {
      condition {
        ...ConditionType
      }
    }
  }
`;
