// @ts-nocheck
import React from "react";
import { compose } from "../../core";
import MainCompetencyHomeView from "../components/MainCompetencyHomeView";
import { ALL_COMPETENCY_CATEGORY_QUERY, ALL_COMPETENCY_CATEGORY_SUBSCRIPTION } from "../graphql/CompetencyQuries.gql";


const MainCompetencyHomeContainer = (props) => {
    const {client,me} = props;

    const [competency_loading,SetCompetencyLoading] =React.useState(false)
    const [competency_page_info,SetCompetencyPageInfo] = React.useState()
    const [competency_category_list, SetCompetencyCtegoryList] = React.useState([])

    const competency_list_ref = React.useRef([])
    const competency_subscription = React.useRef(null)
    const filter_ref = React.useRef( {} )

     // to update the competency list refference when the state gets changed
     React.useEffect(() => {
        competency_list_ref.current = competency_category_list
    }, [competency_category_list])

     // to check if the user have permission to fetch competency data
     React.useEffect(() => {
        if(me){
            getCompetencyCategoryList()
        }
    }, [me])

     // qraphql api to fetch competency data list
     const getCompetencyCategoryList = async (filter) => {
        filter_ref.current={...filter,after:null} || {}
        SetCompetencyLoading(true)
        const { data } = await client.query({
            query: ALL_COMPETENCY_CATEGORY_QUERY,
            fetchPolicy: 'network-only',
            variables: {...filter,first:25}
        });

        if (data?.allCompetencyCategory) {
            // to set the pagination detail
            SetCompetencyPageInfo({...data?.allCompetencyCategory?.pageInfo})
            let list = data?.allCompetencyCategory?.edges?.map(({ node }) => node)
            // to handle pagination if after is present its a paginated data
            if(filter?.after){
                list = competency_list_ref.current.concat(list)
            }
            SetCompetencyLoading(false)
            SetCompetencyCtegoryList(list)
            if (!competency_subscription?.current) {
                competencyCategorySubscription()
            }
        }

    }

    // to add subscription to competency category api
    const competencyCategorySubscription = async () => {
        competency_subscription.current = client.subscribe({
            query: ALL_COMPETENCY_CATEGORY_SUBSCRIPTION,
            fetchPolicy: 'no-cache'
        }).subscribe({
            next(result) {
                let competency_cache_data = [].concat(competency_list_ref.current)
                let scbscription_data = result.data.competencyCategorySubscription
                switch (scbscription_data.mutation) {
                    case 'CREATE':
                        competency_cache_data.push(scbscription_data?.competencyCategory)
                        SetCompetencyCtegoryList(competency_cache_data)
                        break
                    case 'UPDATE':
                        let doc = competency_cache_data?.find(item=>item?.id === scbscription_data?.competencyCategory?.id)
                        if(doc){
                            Object.entries(scbscription_data?.competencyCategory).forEach(item=>{
                                doc[item[0]]=item[1]
                            })
                            SetCompetencyCtegoryList(competency_cache_data)
                        }
                        break
                    case 'DELETE':
                        competency_cache_data = competency_cache_data?.filter(item=>item?.id !== scbscription_data?.competencyCategory?.id)
                        SetCompetencyCtegoryList(competency_cache_data)
                        break
                    default:
                        break
                }
            }
        });
    }

    return (
        <div>
            <MainCompetencyHomeView
             {...props}
             competency_loading={competency_loading}
             competency_category_list={competency_category_list}
             competency_page_info={competency_page_info}
             onFilter={(filter)=>{getCompetencyCategoryList(filter)}}
             getCompetencyCategoryList={(filter)=>{getCompetencyCategoryList({...filter_ref.current,...filter} )}}
             />
        </div>
    )

}

export default compose()(MainCompetencyHomeContainer)