import gql from 'graphql-tag';
import { RESPONSE_TYPE } from './ResponseType.gql';

export const DELETE_RESPONSE = gql`
  ${RESPONSE_TYPE}
  mutation deleteResponse($id: ID!) {
    deleteResponse(id: $id) {
      response {
        ...ResponseType
      }
    }
  }
`;



export const DELETE_RESPONSE_OPTIMIZED = gql`
  
  mutation deleteResponse($id: ID!) {
    deleteResponse(id: $id) {
      response {
        id
        survey {
          id
          name
        }
      }
    }
  }
`;
