// DO NOT EDIT THIS FILE

import gql from 'graphql-tag';

export const COLLAB_MY_TEAM = gql`
query me($search:String) {
  collab: me{
      id
      employee {
        id
        teamManagerEmployee(employees_User_FirstName_Icontains:$search) {
          edges {
            node {
              id
              employees(user_FirstName:$search){
                edges{
                  node{
                    id
                    user{
                      id
                      firstName
                      lastName
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
}
`;
