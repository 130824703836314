import gql from 'graphql-tag';

import { RESPONSE_TYPE } from '../../survey/graphql/ResponseType.gql';

// import { SIMPLE_FIELD_TYPE_CONNECTION } from './SimpleFieldTypeConnection.gql';
import { CUSTOM_SIMPLE_FIELD_TYPE_CONNECTION } from './CustomSimpleFieldTypeConnection.gql';
import { REFERENCE_FIELD_TYPE_CONNECTION } from './ReferenceFieldTypeConnection.gql';

export const CUSTOM_FORM_SECTION_TYPE = gql`
  ${CUSTOM_SIMPLE_FIELD_TYPE_CONNECTION}, ${REFERENCE_FIELD_TYPE_CONNECTION}, ${RESPONSE_TYPE}
  fragment Custom_FormSectionType on Custom_FormSectionType {
    id
    title
    editable
    simpleField {
      ...Custom_SimpleFieldTypeConnection
    }
    referencefield {
      ...ReferenceFieldTypeConnection
    }
    sequence
    referanceFieldResponse{
      ...ResponseType
    }
  }
`;
