import gql from 'graphql-tag';
import { STORY_TYPE } from './StoryType.gql';

export const STORY_BY_ID_QUERY = gql`
  ${STORY_TYPE}
  query storyById($id: ID!) {
    storyById(id: $id) {
      ...StoryType
    }
  }
`;
