import React from 'react';
import { Row, Col, Button, Form } from 'antd';
import { TextField, CKEditorField, ButtonsComponent, ToolCommitmentAutoComplete } from '../../look';

export const CKEditorFieldName = 'description';

const CustomCommitFormComponentView = props => {
  const { setVisible, visible, customUserCommitment, onSubmit } = props;
  const [button, setButton] = React.useState('');

  const initialValue = {
    title: customUserCommitment?.title,
    description: customUserCommitment?.description,
    toolId: customUserCommitment?.toolId
  };

  return (
    <>
      {!visible ? (
        <Row justify="space-between" align="middle">
          <Col>
            <Button type="link" onClick={() => setVisible(true)}>
              <a>Add Custom Commitment +</a>
            </Button>
          </Col>

          <Col>
            <Button type="link" disabled>
              See more >>
            </Button>
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            <Col span={24}>
              <Form
                layout="vertical"
                initialValues={initialValue}
                onFinish={values => onSubmit(values, button)}
                required={false}
                scrollToFirstError={true}
              >
                <Row gutter={24}>
                  <Col span={24}>
                    <TextField
                      name={'title'}
                      label="Title"
                      rules={[{ required: true, message: 'Title is required!' }]}
                      offset={1}
                    />
                  </Col>

                  <Col span={24}>
                    <ToolCommitmentAutoComplete
                      name="toolId"
                      offset={1}
                      label="Tool"
                      rules={[{ required: true, message: 'Tool is required!' }]}
                    />
                  </Col>

                  <Col span={24}>
                    <CKEditorField name={CKEditorFieldName} label="Description" initialValue={initialValue.description} />
                  </Col>
                </Row>

                <ButtonsComponent title={'Add'} setButton={setButton} saveadd={false} saveedit={false} />
              </Form>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default CustomCommitFormComponentView;
