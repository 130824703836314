import React, { useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Upload, Modal, Form } from 'antd';
import ImgCrop from 'antd-img-crop';

import { CLOUDINARY_CLOUDNAME, CLOUDINARY_PRESET } from '../../../../config';
import { PlusOutlined } from '@ant-design/icons';

const FormItem = Form.Item;

const RenderUploadWithCrop = props => {
  const {
    height,
    width,
    label,
    setUrl,
    /* icon = 'UploadOutlined', */ name,
    setLoad,
    cropPropSettings = {},
    value,
    offset = 1,
    required
  } = props;
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState(
    value
      ? [
          {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: value
          }
        ]
      : []
  );

  const onChangeHandler = ({ file, fileList }) => {
    if (file.status === 'uploading') {
      setLoad(true);
    }
    if (file.status === 'done') {
      setLoad(false);
      if (file.response) {
        let url = file.response.secure_url;
        if (url) {
          //set value in form
          setUrl(url);
        }
      }
    } else if (file.status === 'removed') {
      setLoad(false);
      //remove value in form
      setUrl('');
      // input.onChange('');
    }
    setFileList(fileList);
  };

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = file => {
    setPreviewImage(file.url || file.thumbUrl);
    setPreviewVisible(true);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const cloudName = `${CLOUDINARY_CLOUDNAME}/image`;
  const unsignedUploadPreset = `${CLOUDINARY_PRESET}`;

  const cloudinary_url = 'https://api.cloudinary.com/v1_1/' + cloudName + '/upload';
  const cloudinary_data = { upload_preset: unsignedUploadPreset };

  let validateStatus = '';

  const cropSettings = {
    aspect: width / height,
    modalTitle: 'Align image',
    ...cropPropSettings
  };

  // console.log('props', shape);
  return (
    <FormItem
      name={name}
      label={label}
      validateStatus={validateStatus}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24, offset }}
      required={required}
    >
      <div className="clearfix">
        <ImgCrop {...cropSettings}>
          <Upload
            action={cloudinary_url}
            data={cloudinary_data}
            listType="picture-card"
            fileList={fileList}
            onPreview={handlePreview}
            onChange={onChangeHandler}
          >
            {fileList.length >= 1 ? null : uploadButton}
          </Upload>
        </ImgCrop>
        <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
          <img alt="thumbnail" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </div>{' '}
    </FormItem>
  );
};

RenderUploadWithCrop.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  setLoad: PropTypes.func,
  value: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  form: PropTypes.object,
  cropPropSettings: PropTypes.object,
  name: PropTypes.string,
  icon: PropTypes.string,
  shape: PropTypes.string
};

export default RenderUploadWithCrop;
