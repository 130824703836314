import React from 'react';
import { compose } from '../../core';
import ActionDetailView from '../components/ActionDetailView'
import { withUpdateActionItems } from '../containers/One_on_oneOperations'
import { withApollo } from '@apollo/client/react/hoc';


const ActionDetailContainer = props => {

    return (
      <>
      <ActionDetailView {...props} />
      </>
    )

};

export default compose(withApollo,withUpdateActionItems)(ActionDetailContainer);
