import React from "react";
import TeamAssessmentView from "../components/teamAssessmentView";
import { compose } from "modules/core";
import { withApollo } from '@apollo/client/react/hoc';
import { TEAM_ASSESSMENT_LIST } from "modules/home/graphql/teamDashboardQueries.gql";
import { getIntFromString } from "modules/look";
import { SURVEY_READINESS_LEVEL, USER_MANUAL_SURVEY_ID, effectiveness_indicator_one_on_one, feedback_1_1_survey_id, feedback_survey_id, grow_survey_id } from "../../../../config";

const TeamAssessmentContainer =(props)=>{
    const {me,team_id,client,team_employees}=props

    const [assessment_loading,setAssessmentLoading]=React.useState(false)
    const [assessment_list,setAssessmentList]=React.useState()
    const [team_member_list,setTeamMemberList]=React.useState()

    let skip_survey=[grow_survey_id,SURVEY_READINESS_LEVEL,feedback_survey_id,feedback_1_1_survey_id,USER_MANUAL_SURVEY_ID,effectiveness_indicator_one_on_one]
    
    React.useEffect(()=>{
        setTeamMemberList(team_employees)
    },[team_employees])
    
    React.useEffect(()=>{
        if(me&&team_id && team_member_list?.length){
            getAssessmentList()
        }else{
            setAssessmentLoading(false)
            setAssessmentList([])
        }
    },[me,team_id,team_member_list])

    // Function to convert string to object
    const parseObject = (str) => {
        return JSON.parse(str.replace(/'/g, '"'));
    };

    const getAssessmentList = async()=>{
        setAssessmentLoading(true)
        const { data } = await client.query( {
            query: TEAM_ASSESSMENT_LIST,
            fetchPolicy: 'network-only',
        } );
        if(data?.AssessmentByTeam){
            let team_assessment_list = data?.AssessmentByTeam?.map(item=>{
                return {
                    ...item,
                    summaryListByTeam:item?.summaryListByTeam?.map(parseObject).find(obj => obj.Team === getIntFromString(team_id))
                }
            })
        
            team_assessment_list = team_assessment_list?.filter(i=>!skip_survey?.includes(getIntFromString(i?.id)))
            setAssessmentList(team_assessment_list)
        }
        setAssessmentLoading(false)
    }
    return (
        <>
        <TeamAssessmentView assessment_loading={assessment_loading} team_member_list={team_member_list} assessment_list={assessment_list} {...props}/>
        </>
    )
}

export default compose( withApollo)(TeamAssessmentContainer)