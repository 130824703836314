import gql from 'graphql-tag';

export const INVITE_LIST_SUBSCRIPTION = gql`
subscription surveyTokenSubscription{
    surveyTokenSubscription{
        mutation
        token{
            id
            user {
              id
              username
              firstName
              lastName
              email
              profile {
                id
                phoneNumber
              }
            }
            invited
            createdBy {
                id
                firstName
                lastName
            }
            valid
            dateCreated
            survey {
                id
                name
            }
            link
        }
    }
}
`