import React from 'react';
import EditChoiceView from '../components/editChoicevew';
import { GET_QUESTION_LIST, GET_SCAPE_BY_ID_CHOICE } from '../graphql/listingQueries.gql';
import { compose } from 'modules/core';
import { withApollo } from '@apollo/client/react/hoc'
import { withUpdateParameter } from './scapeReportOperations';
import {message} from 'antd'
import { getIntFromString } from 'modules/look';

const EditChoiceContainer = (props)=>{

    const {edit_choice,client,updateParameter,onCancel}=props
    const [allready_selected_choices,setAllreadySeletedChoices]=React.useState([])
    const [all_choice_list,setAllChoiceList]=React.useState([])
    React.useEffect( () => {
        if ( edit_choice !== undefined ) {
            getscapeById(edit_choice?.scape_id)
            getQuestionLists( edit_choice?.survey_id )
        }
    }, [ edit_choice ] )

    const getscapeById =async (id)=>{
        const { data } = await client.query( {
            query: GET_SCAPE_BY_ID_CHOICE,
            fetchPolicy: 'network-only',
            variables: { id: id }
        } );
        if(data?.scapeById){
            let choices =[].concat(...data?.scapeById?.parametricgroupSet?.edges?.map(({node})=>node?.parameterSet?.edges?.map(item=>item?.node?.choice?.id)))
            setAllreadySeletedChoices(choices)
        }
    }

    const getQuestionLists = async ( id ) => {
        const { data } = await client.query( {
            query: GET_QUESTION_LIST,
            fetchPolicy: 'network-only',
            variables: { id: id }
        } );
        if ( data?.SurveyById ) {
            let question_list =[].concat(...data?.SurveyById?.groupSet?.edges?.map( ( { node } ) => node?.questionSet?.edges?.map( item => item?.node ) ))
            // setAllquestionList( [].concat(...data?.SurveyById?.groupSet?.edges?.map( ( { node } ) => node?.questionSet?.edges?.map( item => item?.node ) )) )
            // setQuestionLoading(false)
            setAllChoiceList([].concat(...question_list?.map(item=>item?.choiceSet?.edges?.map(({node})=>node))))
            console.log("question_list",)
        }
    }

    const updateChoice =async(value)=>{
        try{
        let resposne =await updateParameter({show:value?.show,id:getIntFromString(value?.id)})
        if(resposne){
            message.success('choice successfully updated')
            onCancel()
        }
    }
    catch(error){
        message.error(error?.message)
    }
    }
    return (
        <div>
            <EditChoiceView allready_selected_choices={allready_selected_choices} {...props}all_choice_list={all_choice_list} updateChoice={(value)=>{updateChoice(value)}}/>
        </div>
    )

}

export default compose(withApollo,withUpdateParameter)(EditChoiceContainer)