
export const poc_admin ='org-admin'
export const poc_dashboard = "poc-dashboard"
export const poc_organization = "organization"
export const poc_token = "token"
export const poc_report = "report"
export const poc_vertical = "vertical"
export const invitation = "invitation"
export const assessment_report = "assessment-report"
export const team_assessment_report = "team-assessment-report"
export const overview = "overview"
export const poc_dashboard_routes ={
    dashboard_home: `/${poc_admin}/${poc_dashboard}`,
    poc_organization: `/${poc_admin}/${poc_organization}`,
    poc_token: `/${poc_admin}/${poc_token}`,
    poc_report: `/${poc_admin}/${poc_report}`,
    poc_vertical_detail: `/${poc_admin}/${poc_dashboard}/${poc_vertical}/:id/:survey_id`,
    create_invitation:`/${poc_admin}/${poc_organization}/${invitation}`,
    assessment_report:`/${poc_admin}/${poc_organization}/${assessment_report}/:user_id/:responseid`,
    team_assessment_report:`/${poc_admin}/${poc_organization}/${team_assessment_report}/:user_id/:survey_id`,
    module_usage: `/${poc_admin}/${poc_dashboard}/${overview}/:tab`,
    poc_user_report: `/${poc_admin}/${poc_report}/user-report/:survey_id/:response_id`,
}

export default poc_dashboard_routes
