import React from 'react';

import { AdminLayout, getIntFromString } from '../../look';
import ROUTE from '../route';

import GroupFormComponent from './GroupFormComponent';

const EditGroupView = props => {
  return (
    <AdminLayout
      table={false}
      active={ROUTE.group}
      title="Edit Group"
      backLink={`${ROUTE.surveyDetailLink}${props.groupById && getIntFromString(props.groupById.survey.id)}`}
      FormComponent={props.groupById && <GroupFormComponent {...props} />}
    />
  );
};

export default EditGroupView;
