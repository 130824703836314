import gql from 'graphql-tag';
import { TEAM_TYPE } from './TeamType.gql';

export const TEAM_TYPE_CONNECTION = gql`
  ${TEAM_TYPE}
  fragment TeamTypeConnection on TeamTypeConnection {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...TeamType
      }
    }
    totalCount
    edgeCount
  }
`;
