import gql from 'graphql-tag';
import { REFERENCE_FIELD_TYPE } from './ReferenceFieldType.gql';

export const ADD_REFERENCE_FIELD_MUTATION = gql`
  ${REFERENCE_FIELD_TYPE}
  mutation createReferenceField($referenceFieldData: ReferenceFieldInput!) {
    createReferenceField(referenceFieldData: $referenceFieldData) {
      referenceField {
        ...ReferenceFieldType
      }
    }
  }
`;
