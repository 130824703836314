import { Button, Modal, Row } from "antd";
import React from "react";
import Agenda_close from '../../assets/agenda-close.svg'
import { displayUtcToUserTimezone } from "modules/look";

const OneOnOneCongirmationModal =(props)=>{
    const {heading,content,content_2,onCancel,onSubmit,actionButton,actionButtonColor,visible,hideIcon,meetingData}=props
    return(
        <>
        <Modal
             footer={null}
             centered
             visible={/* true || */ visible}
             destroyOnClose={true}
            //  onCancel={() => setVisible(false)}
             closable={false}
             style={{padding: '20px 10px',minHeight:"200px"}}
           >
            <div style={{minHeight:"130px",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
                <div style={{position:"relative",width:"100%"}} >
                    {heading&&<h2 className="one-on-one-meeting-popup-head" style={{textAlign:"center"}}>{heading}</h2>}
                {!hideIcon&&<img src={Agenda_close} alt=""style={{position:"absolute",right:"0px",top:"0px",cursor:"pointer"}}onClick={()=>{onCancel()}} />}
                </div>
                <Row justify="center" align="center">
                    <p className="one-on-one-meeting-popup-para" style={{marginTop:"10px"}}>{content}</p>
                    {(!content&&meetingData)&&<Row justify="center" align="center" style={{flexDirection:"column"}}>
                        <p className="one-on-one-meeting-popup-para" style={{marginTop:"10px",}}>This 1:1 is scheduled on <span>{displayUtcToUserTimezone(meetingData?.event, "DD MMM")}</span> at <span>{displayUtcToUserTimezone(meetingData?.event, "hh:mm a")}</span>.<br/>Are you sure you want to start the 1:1 now instead?</p>
                        </Row>
                    }
                    <Row justify="center" align="center" style={{gap:"10px",width:"100%",marginTop:content?"20px":"0px"}}>
                        <Button  className="one-on-one-cancel-btn-modal" onClick={()=>onCancel()}>Cancel</Button>
                        <Button  className="one-on-one-save-btn-modal" style={{background:actionButtonColor,border:`1px solid ${actionButtonColor}`}} onClick={()=>onSubmit(true)}>{actionButton}</Button>
                    </Row>
                </Row>
            </div>
        </Modal>
        </>
    )
}

export default OneOnOneCongirmationModal