import React from 'react';
import { Pagination } from 'antd';

const NumberPaginantion =(props)=>{
    const {totalPage,pageSize,onActive}=props
    const onChange= (current) => {
        onActive(current)
      };

    return(
        <>
        <Pagination
            pageSize={pageSize||10}
            total={totalPage}
            defaultCurrent={1}
            onChange={onChange}
        />
        </>
    )
}

export default NumberPaginantion