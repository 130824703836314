import { message } from 'antd';
import _ from 'lodash';
import { graphql } from '@apollo/client/react/hoc';
import { CREATE_TEAM, CREATE_VERTICAL, UPDATE_POC_EMPLOYEE_MUTATION } from 'modules/poc-dashboard/graphql/organizationQuery.gql';
import { DELETE_POC_EMPLOYEE, DELETE_TEAM, DELETE_VERTICAL } from 'modules/poc-dashboard/graphql/OrganizationTreeQuery.gql';

export const withCreateVertical = Component =>
  graphql(CREATE_VERTICAL, {
    props: ({ mutate, history }) => ({
      createVertical: async values => {
        try {
          const {
            data: { createVertical }
          } = await mutate({
            variables: {
              ...values
            }
          });

          message.destroy();
          // message.success('Submitted response!!');
          return createVertical.vertical;
        } catch (e) {
          message.destroy();
          message.error("Failed To create Vertical");
          console.error(e);
        }
      }
    })
  })(Component);

export const withCreateTeam = Component =>
  graphql(CREATE_TEAM, {
    props: ({ mutate, history }) => ({
      createTeam: async values => {
        try {
          const {
            data: { createTeam }
          } = await mutate({
            variables: {
              ...values
            }
          });

          message.destroy();
          // message.success('Submitted response!!');
          return createTeam.team;
        } catch (e) {
          message.destroy();
          message.error("Failed To create Team");
          console.error(e);
        }
      }
    })
  })(Component);


  export const withDeleteVertical = Component =>
    graphql( DELETE_VERTICAL, {
      props: ( { mutate } ) => ( {
        deleteVertical: async values => {
          try {
            const {
              data: { deleteVertical }
            } = await mutate( {
              variables: {
                ...values
              }
            } );
            message.destroy();
            message.success("Vertical deleted successfully")
            return deleteVertical.vertical;
          } catch ( e ) {
            message.error("Failed to delete vertical")
   
          }
        }
      } )
    } )( Component );
  
    export const withDeleteTeam = Component =>
    graphql( DELETE_TEAM, {
      props: ( { mutate } ) => ( {
        deleteTeam: async values => {
          try {
            const {
              data: { deleteTeam }
            } = await mutate( {
              variables: {
                ...values
              }
            } );
            message.destroy();
            message.success("Delete Team successfully")
            return deleteTeam.team;
          } catch ( e ) {
            message.error("Failed to delete team")
          }
        }
      } )
    } )( Component );

    export const withDeletePocEmployee = Component => 
      graphql( DELETE_POC_EMPLOYEE, {
        props: ( { mutate } ) => ( {
          deleteEmployee: async values => {
            try {
              const {
                data: { deleteEmployee }
              } = await mutate( {
                variables: {
                  ...values
                }
              } );
              message.destroy();
              message.success("Delete User successfully")
              return deleteEmployee.employee;
            } catch ( e ) {
              message.error("Failed to delete user")
            }
          }
        } )
      } )( Component );

    export const withEditPocEmpolee = Component => 
      graphql( UPDATE_POC_EMPLOYEE_MUTATION, {
        props: ( { mutate } ) => ( {
          updateEmployee: async values => {
            try {
              const {
                data: { updateEmployee }
              } = await mutate( {
                variables: {
                  ...values
                }
              } );
              message.destroy();
              message.success("Updated successfully")
              return updateEmployee.employee;
            } catch ( e ) {
              message.error("Failed to Update")
            }
          }
        } )
      } )( Component );