import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { Input, Divider, Card, Spin, Col, Row, Modal, Button } from 'antd';
import { SearchOutlined, RightOutlined, LeftOutlined } from '@ant-design/icons';
import { Tree, TreeNode } from 'react-organizational-chart';

import { compose } from '../../core';
import {
  getIntFromString,
  ReactMarkdown,
  CardLayout,
  AddSubVerticalField,
  VerticalEmployeeTransfer,
  withMarkdownFieldValue
} from '../../look';
import { IMG } from '../../../config';

import ROUTE from '../route';

import {
  withOrganizationState,
  withOrganizationFilterUpdating,
  // withVertical,
  withAddVertical,
  withDeleteVertical,
  withDeleteTeam,
  withVerticalByVerticalId,
  withTeamsByVerticalIdQuery,
  withEditVertical,
  withVerticalByVerticalIdOptimized,
  withTeamsByVerticalIdQueryOptimized
} from './OrganizationOperation';
import SelectFilterField from '../../look/components/SelectFilterField';
import AddTeam from './AddTeam';
import { TeamNode } from '../../survey/containers/OrganizationTree';
import { subscribeToOrgEmployee, subscribeToOrganization, subscribeToTeamsByVerticalId } from './OrganizationSubscriptions';
import { CKEditorFieldName } from '../components/VerticalFormComponent';

const VerticalDetail = props => {
  const {
    match,
    loading,
    vertical,
    Teams,
    editVertical,
    onOrderByChange,
    orderBy,
    onNameChange,
    onDescriptionChange,
    deleteTeam,
    onFiltersRemove,
    onPaginationChange,
    onSubverticalChange,
    subscribeToMoreTeamsByVerticalId,
    deleteVertical,
    navigation,
    history,
    createVertical,
    subscribeToMore
  } = props;
  const [TeamModal, setTeamModal] = React.useState(false);
  const [employee, setEmployee] = React.useState(false);
  const [team, setTeam] = React.useState(false);
  const [verticalModal, setVerticalModal] = React.useState(false);
  const [team_data,setTeamData] = React.useState();
  const [total, setTotal] = React.useState(0);
  const { id } = useParams();

  React.useEffect(() => {
    const subscribe = subscribeToTeamsByVerticalId(subscribeToMoreTeamsByVerticalId /* , props.filter */);
    const subscribe2 =subscribeToOrganization(subscribeToMore/* , props.filter */)
    const subscribe3 = subscribeToOrgEmployee(subscribeToMore /* , props.filter */)
    return () => {
      subscribe();
      subscribe2()
      subscribe3();
    }
  });




  React.useEffect(() => {
    setTeamData(Teams)
  },[Teams])

  const handleEmployeeSubmit = async employees => {
    try {
      await editVertical({ id: getIntFromString(vertical.id), name: vertical.name, employees });
    } catch (e) {
      throw new Error(e.message);
    }
  };

  const TeamOperrations =(data,type)=>{
    let team_list=team_data?.edges
      team_list.push({node:data})
      Teams['edges']=team_list
      Teams['edgeCount']=team_list.length
      setTeamData({...Teams})
  }
  const handleSubVertical = async values => {
    const input = (({ name, description, subverticalId, headId }) => ({
      name,
      description,
      subverticalId,
      headId
    }))(values);
    try {
      const response = await createVertical({
        employees: values.employees,
        verticalData: {
          ...withMarkdownFieldValue(CKEditorFieldName, {
            ...input,
            organizationId: getIntFromString(vertical.organization.id)
          })
        }
      });
      await editVertical({
        id: getIntFromString(vertical.id),
        name: vertical.name,
        subverticalId: getIntFromString(response.id)
      });
    } catch (e) {
      throw new Error(e.message);
    }
  };

  const DeleteVertical =()=>{
    let resopnse = deleteVertical({id:getIntFromString(vertical.id)});
    if(resopnse) {
    history.push(
      `${ROUTE.organizationDetailLink}${vertical &&
      vertical.organization &&
      getIntFromString(vertical.organization.id)
      }`
    );
    }
  }
  return (
    <CardLayout
      active={ROUTE.vertical}
      title={[
        {
          name: `${vertical?.organization?.name || ''}`,
          link: `${ROUTE.organizationDetailLink}${vertical &&
            vertical.organization &&
            getIntFromString(vertical.organization.id)
            }`
        },
        {
          name: `${vertical?.name || ''}`,
          link: `${ROUTE.verticalDetailLink}${vertical && getIntFromString(vertical.id)}`
        }
      ]}
      showTitle={false}
      editLink={ROUTE.editVerticalLink}
      addLink={ROUTE.addVertical}
      showEdit={true}
      backLink={`${ROUTE.organizationDetailLink}${vertical && vertical.organization && getIntFromString(vertical.organization.id)
        }`}
      table={false}
      onDelete={() => {DeleteVertical()}}
      onFiltersRemove={onFiltersRemove}
      rowKey={vertical && vertical.id}
    >
      <Row>
        <Col span={23}>
          <Spin spinning={loading} size="large">
            <div style={{ minHeight: '70vh' }}>
              {vertical && (
                <>
                  <Row gutter={[24, 24]}>
                    <Col lg={12} md={12} sm={24} xs={24}>
                      <Row justify="space-between">
                        <Col>
                          <Button type="primary" ghost onClick={() => setEmployee(true)}>
                            <Link>Add /Edit Employees</Link>
                          </Button>
                        </Col>
                      </Row>
                      <br />
                      <Row
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'baseline'
                        }}
                      >
                        <h3 style={{ marginBottom: 0, fontWeight: 'normal' }}>Description</h3>
                      </Row>
                      <div
                        style={{
                          border: '1px solid rgba(202, 202, 202, 0.25)',
                          margin: '7px',
                          padding: '15px',
                          minHeight: '140px',
                          borderRadius: '2px'
                        }}
                      >
                        <ReactMarkdown>{vertical.description}</ReactMarkdown>
                      </div>
                    </Col>
                    <Col lg={1} md={1} sm={24} xs={24}>
                      <Divider type="vertical" style={{ height: '100%' }} />
                    </Col>
                    <Col lg={11} md={11} sm={24} xs={24}>
                      <Row justify="space-between">
                        <Col>
                          <h3>Manager</h3>
                        </Col>
                      </Row>
                      {vertical?.heads?.edges?.map(({ node }) => (
                        <Row gutter={[24, 24]}>
                          <Col>
                            <img
                              src={IMG}
                              alt=""
                              style={{
                                width: 70,
                                height: 80,
                                backgroundColor: '#f3f3f3'
                              }}
                            />
                          </Col>
                          <Col>
                            <h4 style={{ marginBottom: 0, fontWeight: 'normal' }}>Name</h4>
                            <h4
                              style={{
                                marginBottom: 0,
                                fontWeight: 'normal',
                                color: '#898A82'
                              }}
                            >
                              {node.user && `${node.user.firstName} ${node.user.lastName}`}
                            </h4>
                            <h4 style={{ marginBottom: 0, fontWeight: 'normal' }}>Email</h4>
                            <h4
                              style={{
                                marginBottom: 0,
                                fontWeight: 'normal',
                                color: '#898A82'
                              }}
                            >
                              {node?.user && node?.user?.email}
                            </h4>
                          </Col>
                        </Row>
                      ))}
                      <br />
                    </Col>
                  </Row>

                  <Row>
                    <Divider style={{ width: '100%' }} />
                  </Row>

                  <Row justify="end" gutter={[24, 24]}>
                    <Col>
                      {vertical &&
                        !vertical.subvertical &&
                        vertical &&
                        vertical.organization && (
                          <AddSubVerticalField
                            handleSubmit={e => handleSubVertical(e)}
                            organizationId={getIntFromString(vertical.organization.id)}
                            initialValues={vertical}
                          />
                        )}
                    </Col>
                    <Col>
                      <Button type="primary" onClick={() => setTeamModal(true)}>
                        Add Team
                      </Button>
                    </Col>
                    <Col>
                      <SelectFilterField
                        placeholder="Search Vertical by"
                        onFiltersRemove={onFiltersRemove}
                        options={[
                          {
                            label: 'Name',
                            type: 'text',
                            value: onNameChange
                          },
                          {
                            label: 'Description',
                            type: 'text',
                            value: onDescriptionChange
                          },
                          {
                            label: 'Subvertical',
                            type: 'subvertical',
                            value: onSubverticalChange,
                            organizationId: id
                          }
                        ]}
                      />
                    </Col>
                  </Row>
                  <br />

                  {vertical && team_data && (
                    <Row justify="center" style={{ minHeight: '70vh' }}>
                      <Col span={1}>
                        <Button
                          type="ghost"
                          icon={<LeftOutlined />}
                          shape="circle"
                          style={{ marginTop: '4em' }}
                          onClick={() => setTotal(total - 1)}
                          disabled={total === 0}
                        />
                      </Col>

                      <Col span={22} style={{ overflow: 'hidden' }}>
                        <Tree
                          label={
                            <Button type="link" style={{ boxShadow: '0px 0px 2px 2px rgba(0, 0, 0, 0.1)', margin: 5 }}>
                              {/* <h3 style={{ marginBottom: 0, fontWeight: 'normal' }}> */}
                              {vertical && vertical.name}
                              {/* </h3> */}
                            </Button>
                          }
                          lineColor={'#CACACA'}
                          lineBorderRadius={'5px'}
                        >
                          {vertical?.subvertical
                            ? vertical?.subvertical?.edges?.map(({ node: sv }) => (
                              <TreeNode
                                label={
                                  <Link to={`${ROUTE.verticalDetailLink}${getIntFromString(sv.id)}`}>
                                    <Button
                                      type="link"
                                      style={{ boxShadow: '0px 0px 2px 2px rgba(0, 0, 0, 0.1)', margin: 5 }}
                                    >
                                      {sv.name}
                                    </Button>
                                  </Link>
                                }
                              >
                                {team ? null : <TeamNode setTeam={setTeam} verticalId={getIntFromString(sv.id)} />}
                              </TreeNode>
                            ))
                            : null}
                          {[...Array(team_data.edgeCount > 6 ? 6 : team_data.edgeCount).keys()].map(i => (
                            <>
                              <TreeNodeComponent node={team_data?.edges[total + i]?.node} />
                            </>
                          ))}
                          {/* {TeamsByVerticalId && TeamsByVerticalId.edges.map(({ node }) => <TreeNodeComponent node={node} />)} */}
                        </Tree>
                      </Col>

                      <Col span={1}>
                        <Button
                          type="ghost"
                          icon={<RightOutlined />}
                          shape="circle"
                          style={{ marginTop: '4em' }}
                          onClick={() => setTotal(total + 1)}
                          disabled={total + 6 >= team_data.edgeCount}
                        />
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </div>
          </Spin>
        </Col>
      </Row>

      <Modal
        destroyOnClose
        visible={employee}
        onCancel={() => setEmployee(false)}
        footer={null}
        title="Employee"
        width="25%"
        bodyStyle={{ padding: 12 }}
      >
        <Row>
          {vertical && vertical?.employees && (
            <VerticalEmployeeTransfer
              orgId={getIntFromString(vertical?.organization?.id)}
              initialValues={vertical?.employees}
              handleSubmit={handleEmployeeSubmit}
            />
          )}
        </Row>
        <br />

        {vertical &&
          vertical?.employees &&
          vertical?.employees?.edgeCount > 0 &&
          vertical?.employees?.edges?.map(({ node }) => (
            <Row
              justify="space-between"
              style={{
                paddingTop: 10,
                display: 'block',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              <img src={IMG} alt="" style={{ width: 48, borderRadius: 30, marginRight: 10 }} />{' '}
              {`${node.user.firstName} ${node.user.lastName}`}
            </Row>
          ))}
      </Modal>

      {/* <Modal
        visible={team}
        onCancel={() => setTeam(false)}
        footer={null}
        title="Members"
        width="30%"
        bodyStyle={{ padding: 12 }}
      >
        {verticalByVerticalId &&
          verticalByVerticalId.employees &&
          verticalByVerticalId.employees.edgeCount > 0 &&
          verticalByVerticalId.employees.edges.map(({ node }) => (
            <Row justify="space-between" style={{ paddingTop: 10 }}>
              <Col>
                <img src={IMG} alt="" style={{ width: 48, borderRadius: 30, marginRight: 10 }} />{' '}
                {`${node.user.firstName} ${node.user.lastName}`}
              </Col>
            </Row>
          ))}
      </Modal> */}
      {/* {verticalByVerticalId && verticalByVerticalId.organization && (
        <AddSubVerticalField
          visible={verticalModal}
          setVisible={setVerticalModal}
          orgId={getIntFromString(verticalByVerticalId.organization.id)}
        />
      )} */}

      {vertical && (
        <AddTeam
          visible={TeamModal}
          match={match}
          navigation={navigation}
          TeamDataReasponse={e=>TeamOperrations(e,'add')}
          history={history}
          setVisible={e=>setTeamModal(e)}
          verId={getIntFromString(vertical.id)}
        />
      )}
    </CardLayout>
  );
};

export default compose(
  withOrganizationState,
  withVerticalByVerticalIdOptimized,
  withTeamsByVerticalIdQueryOptimized,
  withDeleteVertical,
  withDeleteTeam,
  withAddVertical,
  withOrganizationFilterUpdating,
  withEditVertical
)(VerticalDetail);

const TreeNodeComponent = props => {
  const { node } = props;
  const [visible, setVisible] = React.useState(false);
  return (
    <TreeNode
      label={
        <Link to={`${ROUTE.teamDetailLink}${getIntFromString(node.id)}`}>
          <Button
            type="link"
            style={{ boxShadow: '0px 0px 2px 2px rgba(0, 0, 0, 0.1)', margin: 5 }}
            onMouseEnter={() => setVisible(true)}
          // onMouseLeave={() => setVisible(false)}
          >
            {node.name}
          </Button>
        </Link>
      }
    >
      {visible
        ? node.subvertical && (
          <TreeNode
            label={
              <Link to={`${ROUTE.teamDetailLink}${getIntFromString(node.subvertical.id)}`}>
                <Button
                  type="link"
                  style={{ boxShadow: '0px 0px 2px 2px rgba(0, 0, 0, 0.1)', margin: 5 }}
                  onMouseLeave={() => setVisible(false)}
                >
                  {node.subvertical.name}
                </Button>
              </Link>
            }
          ></TreeNode>
        )
        : null}
    </TreeNode>
  );
};

