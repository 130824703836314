import gql from 'graphql-tag';

export const SIMPLE_FIELD_CHOICE_TYPE = gql`
  fragment SimpleFieldChoiceType on SimpleFieldChoiceType {
    id
    label
    value
    sequence
    graphic
  }
`;
