// @ts-nocheck
import React from "react";
import { compose } from "modules/core";
import { withApollo } from '@apollo/client/react/hoc';
import TreeEditView from "modules/poc-dashboard/components/organization/TreeEditView";
import { ORGANIZATION_EMPLOYEE, TEAM_DETAIL_TREE, VERTICAL_DETAIL_TREE, VERTICAL_EMPLOYEE } from "modules/poc-dashboard/graphql/organizationQuery.gql";
import { gql, useMutation } from "@apollo/react-hooks";
import { getIntFromString } from "modules/look";
import { message } from "antd";
const EditTeamContainer = (props) => {
    const { client, selected_node, UpdateNode,onClose } = props
    const [detail, setDetail] = React.useState()
    const [managers_list, setManagerList] = React.useState([])
    const [selected_employee_list, setSelectedEmployeeList] = React.useState([])
    const [all_employee_list, SetAllEmployeeList] = React.useState([])
    const [api_loading, setApiLoading] = React.useState(true)
    const [saveChanges, SetSaveChanges] = React.useState()
    const selected_employee_ref = React.useRef([])

    React.useEffect(() => {
        if (selected_employee_list?.length) {
            selected_employee_ref.current = selected_employee_list
        }
    }, [selected_employee_list])

    React.useEffect(() => {
        if (selected_node) {
            TeamDetail({ id: selected_node?.node_id })
           if(selected_node?.node_type == "TEAM"){
                getAllVerticalEmployee({ id: selected_node?.node?.parent_node })
            }
        }

    }, [selected_node])



    const UPDATE_VERTICAL = gql`
    mutation updateTeamData {
        ${saveChanges ?
            `updateTeam(id:${getIntFromString(selected_node?.node_id)},name:"${saveChanges?.name}",managers:[${saveChanges?.managers_list?.map(i => getIntFromString(i?.id))}]){
                team{
                    id
                }
            }
            
            ${saveChanges?.selected_employee_list?.filter(i => !i.member_id && !i?.member_deleted)?.map((i, index) =>
                `create${index}:createMember(memberData:{
                        employeeId:"${i?.id}",
                        reportingLine: "solid",
                        memberFrom:{
                        teamId:"${selected_node?.node_id}"
                        }
                    }){
                        member{
                            id
                        }    
                    }
                    `
            ).join('')
            }
             ${saveChanges?.selected_employee_list?.filter(i => i.member_id && i?.member_deleted)?.map((i, index) =>
                `delete${index}:deleteMember(id:"${i.member_id}"){
                        member{
                            id
                        }
                    }
                    `
            ).join('')
            }
            `
            : null
        }
    }
    `;

    const [updateDefinition, { data, error }] = useMutation(UPDATE_VERTICAL);

    React.useEffect(() => {
        if (saveChanges) {
            update()
        }
    }, [saveChanges])

    const update = async () => {
        setApiLoading(true)
        try {
            const response = await updateDefinition()

            if (response?.data) {
                message.success('Changes saved successfully');
                let node = selected_node?.node
                node["name"] = saveChanges?.name
                node.attribute.detail.label = saveChanges?.name
                node.attribute["employee"] = saveChanges?.selected_employee_list?.filter(i => !i?.member_deleted)?.length
                node.attribute["manager"] = saveChanges?.managers_list?.map((i => {
                    return {
                        email: i?.user?.email,
                        empolee_id: i?.id,
                        name: `${i?.user?.firstName} ${i?.user?.lastName||""}`,
                        profile_image: i?.user?.profile?.profileImage,
                        role: "VERTICAL HEAD"
                    }
                }))
                
                setApiLoading(false)
                UpdateNode(node)
            } else {
                setApiLoading(false)
            }
        } catch (error) {
            message.error('Failed to save changes')
            onClose()
        }
    }

    const getAllOrganizationEmployee = async (filter) => {
        const { data } = await client.query({
            query: ORGANIZATION_EMPLOYEE,
            variables: filter,
            fetchPolicy: 'network-only'
        });
        if (data?.me?.employee?.organizationSet?.edges?.length) {
            let organization = data?.me?.employee?.organizationSet?.edges[0]?.node
            let employees = organization?.employees?.edges?.map(({ node }) => node)?.sort((a,b)=>a?.user?.firstName?.toLowerCase().localeCompare(b?.user?.firstName?.toLowerCase()))
            SetAllEmployeeList(employees)
        }
    }

    const getAllVerticalEmployee = async (filter) => {
        const { data } = await client.query({
            query: VERTICAL_EMPLOYEE,
            variables: filter,
            fetchPolicy: 'network-only'
        });
        if (data?.vertical) {
            let heads = data?.vertical?.heads?.edges?.map(({ node }) => node)
            let employees = data?.vertical?.employees?.edges?.map(({ node }) => node)
            let list = [...heads,...employees]
            var setObj = new Set();
            list = list.reduce((acc, item) => {
                if (!setObj.has(item?.user?.id)) {
                  setObj.add(item?.user?.id)
                  acc.push(item)
                }
                return acc;
              }, [])
              list = list?.sort((a,b)=>a?.user?.firstName?.toLowerCase().localeCompare(b?.user?.firstName?.toLowerCase()))
            SetAllEmployeeList(list)
        }
    }

    const TeamDetail = async (filter) => {
        const { data } = await client.query({
            query: TEAM_DETAIL_TREE,
            variables: filter,
            fetchPolicy: 'network-only'
        });
        if (data?.Team) {
            let detail = {
                id: data?.Team?.id,
                name: data?.Team?.name
            }
            setDetail(detail)
            let managers = data?.Team?.managers?.edges?.map(({ node }) => node)
            setManagerList(managers)
            let selected_employee = data?.Team?.employees?.edges?.map(({ node }) => { return { ...node, member_id: node?.memberSet?.edges?.length ? node?.memberSet?.edges[0]?.node?.id : null } })
            selected_employee_ref.current = selected_employee_ref.current?.concat(selected_employee)
            setSelectedEmployeeList(selected_employee_ref.current)
            if (data?.Team?.employees?.pageInfo?.hasNextPage) {
                TeamDetail({ id: filter?.id, after: data?.Team?.employees?.pageInfo?.endCursor })
            }
            setApiLoading(false)
        }
    }

    let search_debounce = React.useRef()
    const onSearchEmployee = (search) => {
        if (search_debounce?.current) {
            clearTimeout(search_debounce?.current)
        }
        search_debounce.current = setTimeout(() => {
            immediateSearchFeature(search)
        }, 1000);
    }

    const immediateSearchFeature = (search) => {
        if (search_debounce?.current) {
            clearTimeout(search_debounce?.current)
        }
        if(selected_node?.node_type == "TEAM"){
            getAllVerticalEmployee({ id: selected_node?.node?.parent_node,user_FirstName: search })
        }
    }

    return (
        <>
            <TreeEditView {...props} onSearchEmployee={onSearchEmployee} immediateSearchFeature={immediateSearchFeature} edit_view={selected_node?.node_type} api_loading={api_loading} setSelectedEmployeeList={setSelectedEmployeeList} setManagerList={setManagerList} detail={detail} managers_list={managers_list} selected_employee_list={selected_employee_list} all_employee_list={all_employee_list} SetSaveChanges={SetSaveChanges} />
        </>
    )
}

export default compose(withApollo)(EditTeamContainer);