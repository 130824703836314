import React from 'react';
import ProfileDetail from '../components/profileDetail';
import { compose } from 'modules/core';
import { withApollo } from '@apollo/client/react/hoc';
import { EMPLOYEE_PROFILE_DETAIL, EMPLOYEE_PROFILE_TEAM, EMPLOYEE_SURVEY_DETAIL } from '../graphql/EmployeeProfile.gql';
import { getEmployeeBase64StringFromInt, getIntFromString, getSurveyBase64StringFromInt, getTeamBase64StringFromInt, globalPermissionValidator } from 'modules/look';
import { PERSONALITY_PART_1, VALUE_SUERVEY_PART_1, STRENGTH_PART_1, personality_questions, available_personality, SURVEY_DIVERSITY_PROFILE, diversity_questions_for_in } from '../../../config';
import { withUpdateTeam } from '../teamsDashboard/containers/teamsDashboard.operation';
import { message,Spin } from 'antd'
import { user_profile_permissions } from 'Permissions/userProfile.permission';
import NoPermissionView from 'modules/kudos-user/component/NoPermissionComponent';

const ProfileDetailContainer = (props) => {
    const { client, match,location,updateTeam,userPermission } = props
    const [profile_detail, SetProfileDetail] = React.useState()
    const [team_detail, SetTeamDetail] = React.useState()
    const [permission,SetPermission] = React.useState()

    React.useEffect( () => {
        if ( userPermission?.length ) {
            let permission = globalPermissionValidator(
                user_profile_permissions,
                userPermission );
                SetPermission( permission )
            
        }
    }, [ userPermission ] )

    React.useEffect(() => {
        if (match?.params?.id && permission?.required_permission) {
            getProfileData({
                id: getEmployeeBase64StringFromInt(match?.params?.id)
            }) 
        }
      
    }, [match,permission])

    React.useEffect(()=>{
        if(location?.search){
            let  params = new URLSearchParams(location.search)
            let team_id = params?.get('team')
            if(team_id){
                getTeamData({id:getTeamBase64StringFromInt(team_id)})
            }
        }   
    },[location])

    const getTeamData = async (filters) => {
        try {
            const { data } = await client.query({
                query: EMPLOYEE_PROFILE_TEAM,
                fetchPolicy: 'network-only',
                variables: filters,
            });
            if(data?.Team){
                
                let detail ={
                    ...data?.Team,
                    employees:data?.Team?.employees?.edges?.map(({node})=>node?.id)
                }
                SetTeamDetail(detail)
            }
          
        } catch (error) {

        }
    }

    const getProfileData = async (filters) => {
        try {
            const { data } = await client.query({
                query: EMPLOYEE_PROFILE_DETAIL,
                fetchPolicy: 'network-only',
                variables: filters,
            });

            if (data?.profile_detail) {

                let emp = data?.profile_detail
                let role = emp?.memberSet?.edges[0]?.node?.role
                let profile = {
                    employee_id: emp?.id,
                    user: emp?.user,
                    role,
                    job_family: role?.jobfamilySet?.edges[0]?.node
                }
                getEmployeeSurveyDetail(profile)
            }
        } catch (error) {

        }
    }

    const getEmployeeSurveyDetail = async (profile) => {
        try {
            let filter = {
                user: profile?.user?.id,
                personality_survey: getSurveyBase64StringFromInt(PERSONALITY_PART_1),
                strength_survey: getSurveyBase64StringFromInt(STRENGTH_PART_1),
                value_survey: getSurveyBase64StringFromInt(VALUE_SUERVEY_PART_1),
                personality_questions,
                diversity_survey:getSurveyBase64StringFromInt(SURVEY_DIVERSITY_PROFILE),
                diversity_questions:[diversity_questions_for_in.YearsOfExperience]
            }

            const { data } = await client.query({
                query: EMPLOYEE_SURVEY_DETAIL,
                fetchPolicy: 'network-only',
                variables: filter,
            });

            if (data) {
                let details = {
                    ...profile,
                    strength: data?.strength?.edges[0]?.node?.answerSet?.edges?.map(({ node }) => node),
                    value: data?.value?.edges[0]?.node?.answerSet?.edges?.map(({ node }) => node),
                }
                let p = data?.personality?.edges[0]?.node?.answerSet?.edges?.map(({ node }) => {
                    return {
                        ...node,
                        question: getIntFromString(node?.question?.id)
                    }
                })
                let personality = ''
          
                personality_questions.forEach(q=>{
                    let ans = p?.find(a=>a.question==q)
                    if(ans){
                        personality = personality + (ans?.answer === "Intuitive" ? "N":ans?.answer ==="Observant"?"S" : ans?.answer?.charAt(0))
                    }
                })

                personality = personality?.slice(0, 4)
                if (available_personality?.includes(personality)) {
                    details['personality'] = personality
                }
                if(data?.diversity){
                  
                    let set = data?.diversity?.edges[0]?.node?.answerSet?.edges?.map(({ node }) => node)
                    
                    let diversity_list = set?.map(i=>{
                        return {
                            question_id :getIntFromString(i?.question?.id),
                            answer:i?.answer
                        }
                    })

                    let user_experinece = diversity_list?.find(i=>i?.question_id==diversity_questions_for_in.YearsOfExperience)
                    if(user_experinece){
                        details['experinece'] = user_experinece?.answer 
                    }
                }
                SetProfileDetail(details)
            }
        } catch (error) {

        }
    }

    const manageEmployee = async (type) => {
        let employees =team_detail?.employees || []
        if(type=='add'){
            employees.push(profile_detail?.employee_id)
        }else{
            employees = employees?.filter(i=>i!=profile_detail?.employee_id)
        }
       let value = {
            employees: employees?.map(i=>getIntFromString(i)),
            id: getIntFromString(team_detail?.id) 
        }
        let response = await updateTeam(value)
        if (response?.id) {
            let team ={
                ...team_detail,
                employees
            }
            SetTeamDetail(team)
            message.success( 'Updated Successfully' )
        }
    }

    return (
        <>
            {
                (permission && permission?.required_permission) && (
                    <ProfileDetail {...props} profile_detail={profile_detail} team_detail={team_detail} manageEmployee={manageEmployee} permission={permission}/>
                )
            }
             {(permission && !permission?.required_permission) && (
                <NoPermissionView />
            )}

            {(!permission) && (
                <div align="center" style={{ padding: "200px" }}>
                    <Spin spinning={true} size='large' />
                </div>
            )}
        </>
    )
}

export default compose(withApollo,withUpdateTeam)(ProfileDetailContainer)