import gql from "graphql-tag";

export const MEETING_BY_ID_TRANSCRIPTION_DATA = gql`
query meetingById($id:ID!,$page:Int,$textIcontains: String){
    meetingById(id:$id){
        id
        title
        transcription(page:$page,textIcontains:$textIcontains) {
            totalCount
            pageInfo {
              hasNextPage
            }
            page {
              id
              userId
              meetingId
              requestedAt
              transcribedText
            }
        }
    }
}
`;
