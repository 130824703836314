import { Row, Spin, Tooltip } from "antd";
import React from "react";
import expand_icon from '../../assets/expand-award-icon.svg'
import { useHistory } from "react-router-dom";
import AwardDetailPopup from "../container/awardDetaailPopup";
const UserProfileAwardView = ( props ) => {
    const { badgeAwardList, loading, permission } = props
    const [ awardList, setAwardList ] = React.useState()
    const [visible,setVisible]=React.useState(false)
    const [selectedAward,setSelectedAward]=React.useState()
    const history = useHistory()
    React.useEffect( () => {
        if ( badgeAwardList ) {
            let list = badgeAwardList?.edges?.map( ( { node } ) => node)
            setAwardList( list )
        }
    }, [ badgeAwardList ] )

    const viewDetail=(item)=>{
        console.log('item',item)
        setSelectedAward(item)
        setVisible(true)
    }
    return (
        <>

            <div className="user-awards-view-container user-badge-root-class" style={{ position: "relative" }}>
            { permission && permission?.can_award_badge &&<Tooltip title={"Click here to expand awards"}>
                { <img src={expand_icon} alt="" style={{ position: 'absolute', right: "15px", top: "10px", width: "20px", cursor: "pointer" }} onClick={() => history.push( '/kudos/awards/badge?ecived' )} />}
            </Tooltip>}
            <h4 className="user-awards-view-header">Kudos</h4>
            <Spin spinning={loading}>
                <Row className="user-awards-view-slider" id="user-awards-view-slider" style={{ gap: "20px", flexWrap: "nowrap", minWidth: loading ? "100%" : "auto", minHeight: '160px' }}>

                    {awardList?.length>0 && awardList?.map( item => (
                        <>
                            <img  src={item?.badge?.image}className="user-awards-view-image" style={{ margin: "0px 10px" }} onClick={()=>viewDetail(item)} />   
                        </>
                    ) )}
                    {awardList?.length == 0 && loading == false && 
                    <div style={{width:"100%", display: 'flex', justifyContent: 'space-evenly', alignItems: 'center'}}>
                        <h4 className="recived-award-image-title" style={{ margin:'auto', justifyContent: 'center', textAlign: "center" }}>No Kudos Found</h4>
                    </div>}
                </Row>
            </Spin>
            </div>
        
           
            {
                visible && <AwardDetailPopup selectedAward={selectedAward} onClose={( e ) => { setVisible( e );setSelectedAward() }} visible={visible} hideShare={true}/>
            }
        </>
    )
}

export default UserProfileAwardView