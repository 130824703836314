import { CalendarOutlined } from '@ant-design/icons';
import { withApollo } from '@apollo/client/react/hoc';
import { Button, Col, Divider, Row, Spin, Switch } from 'antd';
import moment from 'moment';
import React from 'react';
import { compose } from '../../core';
import { CardLayout, DatePickerField, getIntFromString, getSurveyBase64StringFromInt, ReactMarkdown } from '../../look';
import { SURVEY_BY_ID_ADMIN_PANEL_QUERY } from '../graphql/SurveyByIdQuery.gql';
import { SURVEY_SUBSCRIPTION } from '../graphql/SurveySubscription.gql';
import ROUTE from '../route';
import Group from './Group';
import SurveyAssign from './SurveyAssign';
import { withEditSurvey } from './SurveyOperations';

const SurveyDetail = props => {
  const { match, loading, editSurvey, onFiltersRemove,client,me } = props;
  const [assign, setAssign] = React.useState(false);
  const [actionLoading, setActionLoading] = React.useState(false);
  const [SurveyById,setSurveyById]=React.useState()

  let surveySubscription = undefined;
  const m = React.useRef(true)

  const handleEditSurvey = async (e, field) => {
    setActionLoading(true);
    let data = {};
    if (field === 'public') data = { public: e };
    if (field === 'shuffle') data = { shuffle: e };
    if (field === 'activeTo') data = { activeTo: e };
    try {
      await editSurvey({
        id: Number(getIntFromString(SurveyById.id)),
        name: SurveyById.name,
        ...data
      });
    } catch (e) {
      throw new Error(e.message);
    }
    setActionLoading(false);
  };

  const handleEditDate = async (e, field) => {
    setActionLoading(true);
    var date = e;
    date.setDate(date.getDate());
    try {
      await editSurvey({
        id: Number(getIntFromString(SurveyById.id)),
        name: SurveyById.name,
        activeTo: date
      });
    } catch (e) {
      throw new Error(e.message);
    }
    setActionLoading(false);
  };

  React.useEffect(()=>{
    if(me){
      let id = getSurveyBase64StringFromInt( match.params.id );
      let responseUser = me?.id
      getSurveyDetail( { id} )
    }
  }
  ,[me,match])

  const  getSurveyDetail= async (value)=>{

    const { data } = await client.query( {
      query: SURVEY_BY_ID_ADMIN_PANEL_QUERY,
      variables: value,
      fetchPolicy: 'network-only'
  } );
  if ( data?.SurveyById) {
    setSurveyById(data?.SurveyById)
    SubscribeToSurveys();
  }
  }



  React.useEffect(() => {
    return () => {
      m.current = false
    }
  }, [])

  React.useEffect(() => {
    m.current = true

  }, [])


  React.useEffect(() => {
    return () => {
      if (surveySubscription) {
        surveySubscription.unsubscribe();
      }
    }
  }, [])
 

  const SubscribeToSurveys = () => {

    surveySubscription = client.subscribe({
      query: SURVEY_SUBSCRIPTION,
    }).subscribe({
      next(result) {

        switch (result.data.surveySubscription.mutation) {
         
          case 'UPDATE':
           
            if (m.current) {
            setSurveyById(result.data.surveySubscription.survey)
            }

            break;
          default:
            break
        }

      }
    })

  }



  return (
    <CardLayout
      active={ROUTE.survey}
      title={[
        { name: SurveyById?.name || '', link: `${ROUTE.surveyDetailLink}${SurveyById && getIntFromString(SurveyById.id)}` }
      ]}
      showTitle={false}
      editLink={ROUTE.editSurveyLink}
      addLink={ROUTE.addSurvey}
      backLink={ROUTE.survey}
      onFiltersRemove={onFiltersRemove}
      showBack={false}
      table={false}
      rowKey={SurveyById && SurveyById.id}
    >
      <Row>
        <Col span={24}>
          <Spin spinning={loading || actionLoading} size="large">
            <div style={{ minHeight: '70vh' }}>
              {SurveyById && (
                <>
                  <Row gutter={[24, 24]}>
                    <Col lg={12} md={12} xs={24}>
                      <Row gutter={[24, 24]} style={{ alignItems: 'center' }}>
                        <Col>
                          <h3 style={{ marginBottom: 0 }}>{SurveyById.name}</h3>
                        </Col>
                      </Row>
                      <br />
                      <Row
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'baseline'
                        }}
                      >
                        <h3 style={{ marginBottom: 0, fontWeight: 'normal' }}>Description</h3>
                      </Row>
                      <div
                        style={{
                          border: '1px solid rgba(202, 202, 202, 0.25)',
                          margin: '7px',
                          padding: '15px',
                          minHeight: '140px',
                          borderRadius: '2px'
                        }}
                      >
                        <ReactMarkdown>{SurveyById.description}</ReactMarkdown>
                      </div>
                    </Col>
                    <Col span={1}>
                      <Divider type="vertical" style={{ height: '100%' }} />
                    </Col>
                    <Col lg={11} md={11} xs={24}>
                      <Row>
                        <Col lg={10} md={14} xs={24}>
                          <Row>
                            <SurveyAssign SurveyById={SurveyById} setSurveyById = {setSurveyById} assign={assign} setAssign={setAssign} {...props} />
                          </Row>
                          <br />
                          <Row>
                            <Col>
                              <h4 /* style={{ marginBottom: 0 }} */>Active till</h4>
                              <DatePickerField
                                allowClear={false}
                                defaultValue={moment.utc(SurveyById.activeTo).local()}
                                style={{ color: '#84A100', borderRadius: 5 }}
                                showTime={{ format: 'HH:mm' }}
                                format="YYYY-MM-DD HH:mm"
                                size="large"
                                suffixIcon={<CalendarOutlined style={{ fontSize: 20, color: '#84A100' }} />}
                                onChange={e => handleEditDate(new Date(e._d), 'activeTo')}
                              />
                            </Col>
                          </Row>
                          <br />
                          <br />
                          <Row justify="space-between" align="bottom" gutter={[24, 24]}>
                            <Col>
                              Public
                              <br />
                              <Switch
                                // onChange={(e)=>handleEditSurvey()}
                                checked={SurveyById.public}
                                size="small"
                                
                                onChange={e => handleEditSurvey(e, 'public')}
                              />
                            </Col>
                            <Col>
                              Shuffle
                              <br />
                              <Switch
                                checked={SurveyById.shuffle}
                                size="small"
                                onClick={e => handleEditSurvey(e, 'shuffle')}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Divider style={{ width: '100%' }} />
                  </Row>

                  <Row justify="end" gutter={[24, 24]}>
                    <Col>
                      <Button type="primary" href={`${ROUTE.addGroupLink}${getIntFromString(SurveyById.id)}`}>
                        Add Group
                      </Button>
                    </Col>
                  </Row>
                  <br />

                  <Row justify="center" style={{ minHeight: '70vh' }}>
                    <Col span={24}>
                      <Group {...props} SurveyById={SurveyById}/>
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </Spin>
        </Col>
      </Row>
    </CardLayout>
  );
};

export default compose(withEditSurvey, withApollo)(SurveyDetail);

