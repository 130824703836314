import React from 'react';
import { Form, Checkbox, Row, Col } from 'antd';
import chunk from 'lodash/chunk';

import ReactMarkdown from '../ReactMarkdown';

const FormItem = Form.Item;

const MLCheckBoxField = props => {
  const { vertical = true, choices, onChange,left,selectedValue, ...rest } = props;
  const choice = choices.map((choice, i) => (
    <Checkbox style={{ display: vertical && 'flex', marginBottom: '1vh', marginLeft: left?left:'2vw' }} value={choice.value} key={i}>
      {typeof choice.label === 'string' ? <ReactMarkdown>{choice.label}</ReactMarkdown> : choice.label}
    </Checkbox>
  ));

  const parts = chunk(choice, 5);
  return (
    <div style={{ marginBottom: '5vh' }}>
      <FormItem labelCol={{ span: 24 }} wrapperCol={{ span: 24, offset: 1 }}required={props?.required} {...rest}>
        <Checkbox.Group onChange={onChange} style={{ width: '100%' }}>
          <Row>{choices.length && parts?.map((chunk, i) => <Col span={24 / parts?.length}>{chunk}</Col>)}</Row>
        </Checkbox.Group>
      </FormItem>
    </div>
  );
};

export default MLCheckBoxField;
