// @ts-nocheck
import React from 'react';
import { CheckOutlined, EyeOutlined } from '@ant-design/icons';
import { Menu, Row, Col, Button, Tooltip, Modal, Spin } from "antd";
import styled from "styled-components";
import { getIntFromString, displayUtcToUserTimezone } from '../../look';
import { useHistory } from 'react-router-dom';
import NotificationDetailView from './NotificationDetail';
import notification_content from '../../../Notification_config'
import okr_route from '../../Objective-keyresult/route/index'
import closed_envelop from '../../assets/icons/closed_envelop.png' 
import opened_envelop from '../../assets/icons/open_envelop.png' 
import Green_small_arrow from '../../assets/green-small-arrow.svg'
import Sent_note from '../../assets/sent-note.svg'
import FeedbackDetailView from 'modules/feedback/components/ViewFeedbackPopUp';
import AwardDetailPopup from 'modules/kudos-user/container/awardDetaailPopup';
const NotificationView = props => {

    const { permission, notifications, readNotification, Readednotifications, unreadNotification,
        getFeedbackDetails, feedbackDetailsLoading, feedbackDetails, me,notification_loading,notification_permission } = props
    const [notificationList, setNotificationList] = React.useState([])
    const [read_notificationList, setReadNotificationList] = React.useState([])
    const [selectedNotification, setselectedNotification] = React.useState()
    const [selected, setSelected] = React.useState('Unread')
    const [feedbackPermission, setFeedbackPermission] = React.useState()
    const [selectedAward, setSelectedAward] = React.useState()
    const [feedbackModalVisible, setFeedbackModalVisible] = React.useState(false)
    const [visible, setvisible] = React.useState(false);

    React.useEffect(() => {
        if (notifications) {
            let list = notifications?.edges?.map(({ node }) => node)
            setNotificationList([].concat(list))
        }else{
            setNotificationList([])
        }
    }, [notifications])

    React.useEffect(() => {
        if (Readednotifications) {
            let list = Readednotifications?.edges?.map(({ node }) => node)
            setReadNotificationList([].concat(list))
        }else{
            setReadNotificationList([])
        }
    }, [Readednotifications])


    const routeToOkrDetailsPage = (data) => {

        switch (data?.okrType) {
            case "OBJECTIVE":
                window.open(okr_route?.viewObjective?.replace(':okrId', getIntFromString(data?.id)))
                break;
            case "KEY_RESULT":
                window.open(okr_route?.keyResultDetail?.replace(':okrId', getIntFromString(data?.id)))
                break;
            case "MILESTONE":
                window.open(okr_route?.milestoneDetail?.replace(':okrId', getIntFromString(data?.id)))
                break;
        }
    }


    const viewData = (data) => {
        let detail = notification_content[data?.relatedObject?.__typename]
        if (detail && data?.relatedObject?.id) {
            if (detail?.model === "okrType") {
                routeToOkrDetailsPage(data?.relatedObject?.okr)
            } else if (detail?.model === "ContributorType") {
                routeToOkrDetailsPage(data?.relatedObject?.okr)
            } else {
                let url = detail?.url.replace('{{objectId}}', detail?.isIntid ? getIntFromString(data?.relatedObject?.id) : data?.relatedObject?.id)
                window.open(url)
            }
        }
    }



    const MarkAsRead = async (data) => {
        if (data) {
            try {
                let response = await readNotification({ id: getIntFromString(data?.id) })
            } catch (error) {
            }
        }
    }

    const undoRead = async (data) => {
        if (data) {
            try {
                let response = await unreadNotification({ id: getIntFromString(data?.id) })
            } catch (error) {

            }
        }
    }

    const redirect = (data) => {
        if (data?.relatedObject && data?.relatedObject?.__typename && data?.relatedObject?.id) {
            if (data?.relatedObject?.__typename === "FeedbackType") {
                setFeedbackModalVisible(true);
                getFeedbackDetails(data?.relatedObject?.id)
            }
            else if (data?.relatedObject?.__typename === "okrType") {
                routeToOkrDetailsPage(data?.relatedObject?.okr)
            }
            else if (data?.relatedObject?.__typename === "ContributorType") {
                routeToOkrDetailsPage(data?.relatedObject?.okr)
            }
            else if (data?.relatedObject?.__typename === "BadgeAwardType") {
                setvisible(true)
                setSelectedAward(data?.relatedObject)
            }
            else if (notification_content[data?.relatedObject?.__typename]) {
                viewData(data)
            }
            else if (data?.relatedObject?.__typename === "DiscardRequestType"){
                window.open(okr_route?.discardDetail.replace(":id",getIntFromString(data?.relatedObject?.id)))
            }
        }


    }
    return (
        <>
            {
                selectedNotification && (
                    <Modal
                        visible={selectedNotification}
                        destroyOnClose={true}
                        closable={false}
                        footer={false}
                        width={'40%'}
                    >
                        <NotificationDetailView
                            NotificationDetail={selectedNotification}
                            onClose={() => { setselectedNotification(null) }}
                            view={(e) => { setselectedNotification(null); viewData(e) }}
                        />
                    </Modal>
                )
            }

            <NotificationMenu style={{ margin: "10px 0px", padding: '20px 20px 0px 20px' }}>
                <h4 className='notification-title-head' style={{ textAlign: 'center' }} >Notifications</h4>

                <div style={{ display: "flex", flexDirection: "row", gap: '20px', justifyContent: 'center', alignItems: "center", marginTop: "30px" }}>
                    <div onClick={() => setSelected('Unread')} style={{ position: "relative", width: "70px" }}>
                        {notifications?.totalCount > 0 && selected === 'Unread' ?
                            <div style={{ width: notifications?.totalCount <= 9 ? "19px" : "24px", height: notifications?.totalCount <= 9 ? "19px" : "24px", borderRadius: "50%", background: "#E86161", color: "#fff", textAlign: 'center', position: "absolute", top: "-15px", right: "-5px", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", fontSize: "11px", fontWeight: "400", fontFamily: 'Poppins' }}>{notifications?.totalCount > 99 ? '99+' : notifications?.totalCount}</div>
                            :
                            (notifications?.totalCount > 0 &&
                                <div style={{ width: notifications?.totalCount <= 9 ? "19px" : "24px", height: notifications?.totalCount <= 9 ? "19px" : "24px", borderRadius: "50%", background: "#8C8C8C", color: "#fff", textAlign: 'center', position: "absolute", top: "-15px", right: "-5px", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", fontSize: "11px", fontWeight: "400", fontFamily: 'Poppins' }}>{notifications?.totalCount > 99 ? '99+' : notifications?.totalCount}</div>
                            )
                        }
                        <h4 className={selected === 'Unread' ? 'selected-tab-note' : 'tab-style-note'}>Unread</h4>

                    </div>
                    <div onClick={() => setSelected('Read')}>
                        <h4 className={selected === 'Read' ? 'selected-tab-note' : 'tab-style-note'}>Read</h4>
                    </div>
                </div>
                <Spin spinning={notification_loading}>
                    <Overflow style={{ paddingTop: "20px", overflow: 'auto', paddingRight: "10px" }}>
                        {
                            selected === 'Unread' ?
                                <>
                                    {
                                        notificationList?.length ?
                                            notificationList?.map((data) =>
                                                <div style={{ borderBottom: '0.5px solid #BFBFBF' }} >
                                                    {/* <Container> */}
                                                    <View style={{ display: 'flex', flexDirection: "column", width: "100%", height: "fit-content", padding: "15px 0px 12px 0px", gap: "10px" }}>
                                                        <div style={{ marginBottom: "6px" }}>
                                                            <h6 style={{ textAlign: 'left', margin: 0, color: '#8C8C8C', fontSize: "10px", fontWeight: "500", fontFamily: "Poppins" }}>{displayUtcToUserTimezone(data?.actionTime, "DD MMM YYYY")}</h6>
                                                        </div>
                                                        <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between', flexDirection: "row", gap: "5px", flexWrap: "nowrap", alignItems: 'center' }}>
                                                            <h5 className={`notification-title ${data?.relatedObject && data?.relatedObject?.__typename && data?.relatedObject?.id && notification_content[data?.relatedObject?.__typename] ? 'hover-notification-titile' : ''}`} style={{ margin: 0 }} onClick={() => { redirect(data) }}>{data?.title}</h5>
                                                            <Tooltip title="Mark as read" overlayStyle={{ "zIndex": "1160" }}>
                                                                <img src={closed_envelop} alt="" style={{ cursor: "pointer",width:"20px",height:"20px" }} onClick={(e) => { MarkAsRead(data); e.stopPropagation() }} />
                                                            </Tooltip>

                                                        </div>
                                                    </View>
                                                    <View>
                                                        {
                                                            data?.changeMessage ?
                                                                <p className="message" className='pointer' onClick={() => setselectedNotification(data)}>{data?.changeMessage}</p>
                                                                :
                                                                <div></div>
                                                        }

                                                    </View>
                                                    {/* </Container> */}
                                                </div>
                                            )
                                            : null
                                    }
                                    {!notification_loading && !notificationList?.length && (
                                        <div align="center" style={{ height: "10" }}>
                                            <h4 style={{ margin: '10px' }} >No new notifications</h4>
                                        </div>
                                    )}
                                </>
                                :
                                <>
                                    {
                                        read_notificationList?.length ?
                                        read_notificationList?.map((data) =>
                                                <div style={{ borderBottom: '0.5px solid #BFBFBF' }} >
                                                    {/* <Container> */}
                                                    <View style={{ display: 'flex', flexDirection: "column", width: "100%", height: "fit-content", padding: "15px 0px 12px 0px", gap: "10px" }}>
                                                        <div style={{ marginBottom: "6px" }}>
                                                            <h6 style={{ textAlign: 'left', margin: 0, color: '#8C8C8C', fontSize: "10px", fontWeight: "500", fontFamily: "Poppins" }}>{displayUtcToUserTimezone(data?.actionTime, "DD MMM YYYY")}</h6>
                                                        </div>
                                                        <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between', flexDirection: "row", gap: "5px", flexWrap: "nowrap", alignItems: 'center' }}>
                                                            <h5 className={`notification-title ${data?.relatedObject && data?.relatedObject?.__typename && data?.relatedObject?.id && notification_content[data?.relatedObject?.__typename] ? 'hover-notification-titile' : ''}`} style={{ margin: 0 }} onClick={() => { redirect(data) }}>{data?.title}</h5>
                                                            <Tooltip title="Mark as unread" overlayStyle={{ "zIndex": "1160" }}>
                                                                <img src={opened_envelop} alt="" style={{ cursor: "pointer" ,width:"20px",height:"20px"}} onClick={(e) => { undoRead(data); e.stopPropagation() }} />
                                                            </Tooltip>

                                                        </div>
                                                    </View>
                                                    <View>
                                                        {
                                                            data?.changeMessage ?
                                                                <p className="message" className='pointer' onClick={() => setselectedNotification(data)}>{data?.changeMessage}</p>
                                                                :
                                                                <div></div>
                                                        }

                                                    </View>
                                                    {/* </Container> */}
                                                </div>
                                            )
                                            : null
                                    }
                                    {!notification_loading && !read_notificationList?.length && (
                                        <div align="center" style={{ height: "10" }}>
                                            <h4 style={{ margin: '10px' }} >No notifications</h4>
                                        </div>
                                    )}
                                </>
                        }

                    </Overflow >
                </Spin>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', height: "30px" }} >
                    {
                        (permission && notification_permission?.create_notification_permission) && (
                            <a href='/notification/create' target='_blank'><h4 className='detail' style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", fontSize: '13px', color: "#4CCB1F", textAlign: 'center', flexWrap: "nowrap", gap: "5px" }}>Send notification <img src={Sent_note} alt="" /></h4></a>
                        )
                    }
                    <a href='/notification/view' target='_blank'><h4 className='detail' style={{ fontSize: '13px', color: "#4CCB1F", textAlign: 'center' }}>Show all <img src={Green_small_arrow} alt="" /></h4></a>
                </div>
            </NotificationMenu>

            <Modal visible={feedbackModalVisible} footer={false}
                footer={null}
                centered
                destroyOnClose={true}
                closable={false}
            >
                <Spin spinning={feedbackDetailsLoading}>
                    <FeedbackDetailView feedbackPermission={feedbackPermission} feedbackDetails={feedbackDetails} setFeedbackModalVisible={setFeedbackModalVisible} me={me} />
                </Spin>
            </Modal>

            {
                visible && <AwardDetailPopup selectedAward={selectedAward} onClose={(e) => { setvisible(e); setSelectedAward() }} visible={visible} shareAward={(e) => { setvisible(false); setSelectedAward() }} />
            }
        </>
    );
}

export default NotificationView;

const View = styled(Row)`
display:flex;
flex-direction:colum;
.message{
    width:100%;
    overflow:hidden;
    text-overflow:ellipsis;
    text-align:left;
    curspor:pointer;
}
.message:hover{
    color:#B0CB1F;
    text-decoration:underline
}
`

const Container = styled(Col)`
width:380px;

`

const NotificationMenu = styled(Col)`
width:350px;
border-radius:14px;
background: #F5F5F5;
.detail{
    color:#B0CB1F;
    text-align:center;
    cursor:pointer;
    margin:10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.01em;
}
`
const Overflow = styled(Col)`
height:100%;
width:340px;
max-height:330px;
::-webkit-scrollbar {
    height: 0px;
    width: 4px;
  }
  
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #e0e0e0;
    border-radius: 10px;
    padding-right: 10px;
  }
  
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#D9D9D9;
  }
`