import { dasboardIcons } from "modules/home/dashboard-icons";
import { getSurveyBase64StringFromInt } from "modules/look";
import React from "react";
import { Spin } from 'antd'
const TeamsScpaeReportView = (props) => {
    const { scape_report_list, scape_loading } = props
    const icon_list = [dasboardIcons?.card_icon_1, dasboardIcons?.card_icon_2, dasboardIcons?.card_icon_3, dasboardIcons?.card_icon_4, dasboardIcons?.card_icon_5]
    return (
        <div className='user-dashboard-okr-performance-container' style={{ minHeight: "20em" }}>
            <h4 className='user-board-section-heading'>Team Scape  Report</h4>
            <Spin spinning={scape_loading}>
                {scape_report_list?.values?.length > 0 && <div style={{ display: "flex", flexDirection: "column", gap: "1em", justifyContent: "start", width: "100%" }}>
                    <h4 className='user-board-section-heading' style={{ fontSize: "1em", fontWeight: "700" }}>TOP VALUES</h4>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: 'start', alignItems: "center", gap: "2em", flexWrap: "wrap" }}>
                        {scape_report_list?.values?.length > 0 && scape_report_list?.values?.map((item, index) => (
                            <div className="dashboard-scape-card">
                                <div style={{ background: "rgba(76, 203, 31, 0.1)", borderRadius: ".5em", padding: ".25", display: 'flex', justifyContent: "center", alignItems: "center", width: "3.25em", height: "3.25em" }}>
                                    <img src={icon_list[index]} alt={`card_icon_${index}`} />
                                </div>
                                <div>
                                    <h4 className='user-board-section-heading' style={{ fontSize: "1em", fontWeight: "500" }}>{item?.title}</h4>
                                    <p style={{ fontFamily: "Poppins", fontWeight: "400", fontSize: ".85em", color: "#AABAC6", margin: "0" }}>{item?.count} people</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>}
                {scape_report_list?.strength?.length > 0 && <div style={{ display: "flex", flexDirection: "column", gap: "2em", justifyContent: "start", width: "100%", marginTop: "1em" }}>
                    <h4 className='user-board-section-heading' style={{ fontSize: "1em", fontWeight: "700" }}>TOP STRENGTHS</h4>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: 'start', alignItems: "center", gap: "1em", flexWrap: "wrap" }}>
                        {scape_report_list?.strength?.length > 0 && scape_report_list?.strength?.map((item, index) => (
                            <div className="dashboard-scape-card">
                                <div style={{ background: "rgba(76, 203, 31, 0.1)", borderRadius: ".5em", padding: ".25", display: 'flex', justifyContent: "center", alignItems: "center", width: "3.25em", height: "3.25em" }}>
                                    <img src={icon_list[index]} alt={`card_icon_${index}`} />
                                </div>
                                <div>
                                    <h4 className='user-board-section-heading' style={{ fontSize: "1em", fontWeight: "500" }}>{item?.title}</h4>
                                    <p style={{ fontFamily: "Poppins", fontWeight: "400", fontSize: ".85em", color: "#AABAC6", margin: "0" }}>{item?.count} people</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>}
                {scape_report_list?.personality?.length > 0 && <div style={{ display: "flex", flexDirection: "column", gap: "2em", justifyContent: "start", width: "100%", marginTop: '1em' }}>
                    <h4 className='user-board-section-heading' style={{ fontSize: "1em", fontWeight: "700" }}>TOP PERSONALITIES</h4>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: 'start', alignItems: "center", gap: "1em", flexWrap: "wrap" }}>
                        {scape_report_list?.personality?.length > 0 && scape_report_list?.personality?.map((item, index) => (
                            <div className="dashboard-scape-card">
                                <div style={{ background: "rgba(76, 203, 31, 0.1)", borderRadius: ".5em", padding: ".25", display: 'flex', justifyContent: "center", alignItems: "center", width: "3.25em", height: "3.25em" }}>
                                    <img src={icon_list[index]} alt={`card_icon_${index}`} />
                                </div>
                                <div>
                                    <h4 className='user-board-section-heading' style={{ fontSize: "1em", fontWeight: "500" }}>{item?.title}</h4>
                                    <p style={{ fontFamily: "Poppins", fontWeight: "400", fontSize: ".85em", color: "#AABAC6", margin: "0" }}>{item?.count} people</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>}
                {(scape_report_list?.personality?.length === 0 && scape_report_list?.strength?.length === 0 && scape_report_list?.values?.length === 0||scape_report_list==undefined) &&
                    <div className='no-performance-container'>
                        <img src={dasboardIcons?.teams_scape_no_data} alt="teams_scape_no_data" />
                        <h4 className='baner-tip-title' style={{ textAlign: "center" }}>Here you will see top values ,strengths , personalities</h4>
                    </div>}
            </Spin>
        </div>

    )
}

export default TeamsScpaeReportView