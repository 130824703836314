import React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import gql from 'graphql-tag';

import { compose } from '../../../core';
import { readableTime, getIntFromString } from '../functions';

import SelectField from './SelectField';

export const ALL_RESPONSES_BY_IMPLICIT_USER = gql`
  query allResponsesByImplicitUserId($first: Int, $orderBy: [String], $survey: ID) {
    allResponsesByImplicitUserId(first: $first, orderBy: $orderBy, survey: $survey) {
      edges {
        node {
          id
          responseDuration
          responseDate
        }
      }
    }
  }
`;

const ResponseAutoComplete = props => {
  const { loading, allResponsesByImplicitUserId, margin } = props;

  const choices =
    !loading &&
    allResponsesByImplicitUserId &&
    allResponsesByImplicitUserId.edges.length > 0 &&
    [].concat.apply(
      [],
      allResponsesByImplicitUserId.edges.map(({ node }, i) => ({
        label: `Response ${i + 1} | ${readableTime(node?.responseDuration)} | ${
          node?.responseDate ? new Date(node?.responseDate).toDateString() : 'Not-accepted'
        }`,
        value: getIntFromString(node.id)
      }))
    );

  return (
    <SelectField
      margin={margin}
      choices={choices}
      offset={1}
      style={{ width: '100%', marginBottom: !margin && 0 }}
      // labelInValue
      {...props}
    />
  );
};

export default compose(
  graphql(ALL_RESPONSES_BY_IMPLICIT_USER, {
    options: ({ filter, orderBy, survey }) => {
      return { variables: { ...filter, orderBy, survey } };
    },
    props({ data }) {
      const { loading, error, allResponsesByImplicitUserId, subscribeToMore, updateQuery } = data;
      return { loading, error, allResponsesByImplicitUserId, subscribeToMore, updateQuery };
    }
  })
)(ResponseAutoComplete);
