import gql from 'graphql-tag';
import { TOOL_TYPE } from './ToolType.gql';

export const DELETE_TOOL = gql`
  ${TOOL_TYPE}
  mutation deleteTool($id: ID!) {
    deleteTool(id: $id) {
      tool {
        ...ToolType
      }
    }
  }
`;
