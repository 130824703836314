import gql from 'graphql-tag';

export const UPDATE_OKR_MUTATION = gql`
mutation updateOkr($duedate: DateTime,$goalStatement: String,$id: ID!,$startdate: DateTime){
    updateOkr(duedate:$duedate,goalStatement:$goalStatement,id:$id,startdate:$startdate){
        OKR{
          id
          duedate
          startdate
          title
          goalStatement
        }
    }
}
`