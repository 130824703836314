import React from 'react';

const Idppagination = (props) => {

    const { on_pagination } = props

    return (
        <>
            {on_pagination && (
                <div align="center" style={{margin:"2em"}}>
                    <h4 onClick={() => { on_pagination() }} className='rd-pagination'>View More</h4>
                </div>
            )}
        </>
    )

}

export default Idppagination