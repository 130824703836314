import React from 'react';
import { Row, Col, Form } from 'antd';

import { ButtonsComponent, CKEditorField, ResponseAutoComplete } from '../../look';

export const CKEditorFieldName = 'message';

const AcceptCompareRequest = props => {
  const { survey, onSubmit } = props;
  const [button, setButton] = React.useState('');

  return (
    <Row>
      <Col span={24}>
        <Form
          layout="vertical"
          // initialValues={initialValue}
          onFinish={values => onSubmit(values, button)}
          required={false}
          scrollToFirstError={true}
        >
          <Row>
            <Col span={24}>
              <ResponseAutoComplete
                survey={survey}
                name="sharedResponseId"
                offset={1}
                label="Response"
                rules={[{ required: true, message: 'Response is required!' }]}
              />
            </Col>

            <Col span={24}>
              <CKEditorField
                name={CKEditorFieldName}
                label="Message"
                // initialValue={organizationById && organizationById.message}
                // rules={[{ required: true, message: 'All questions are required!' }]}
              />
            </Col>
          </Row>

          <ButtonsComponent title={'Send'} setButton={setButton} saveadd={false} saveedit={false} />
        </Form>
      </Col>
    </Row>
  );
};

export default AcceptCompareRequest;
