
import { withApollo } from '@apollo/client/react/hoc';
import { Row, Spin } from 'antd';
import { COMPETENCY_BY_ID } from 'modules/Bars-module/graphql/basrsDetailQueries.gql';
import { compose } from 'modules/core';
import NoPermissionView from 'modules/kudos-user/component/NoPermissionComponent';
import { getCompetencyTypeBase64StringFromInt, globalPermissionValidator } from 'modules/look';
import { bars_permission } from 'Permissions/bars.permission';
import React from 'react';
import SubCompetencyDetailView from '../Components/SubCompetencyDetailView';

const BarsDetailContainer = (props) => {

    const { me, client, match, userPermission, permissionLoader } = props;


    const [subCompetecny_behaviour, setSubCompetecnyBehavior] = React.useState()

    const [sub_competecny_employee_level, setsubCompetencyEmployeeLevel] = React.useState()
    const [loading, setLoading] = React.useState(true)

    const [sub_competecny_loading, setSubCompetencyLoading] = React.useState(false)
    const [barsPermission, setBarsPermission] = React.useState(undefined);

    //Remove duplicates
    const employeeLevelList = (list) => {
        var setObj = new Set();
        var result = list?.reduce((acc, item) => {
            if (!setObj.has(item?.id)) {
                setObj.add(item?.id)
                acc.push(item)
            }
            return acc;
        }, [])

        return result
    }




    //Sub competency employee Level fetch data
    const SubCompetencyEmployeeLevel = async (id) => {
        const { data } = await client.query({
            query: COMPETENCY_BY_ID,
            fetchPolicy: 'network-only',
            variables: { id: getCompetencyTypeBase64StringFromInt(id) }
        });
        if (data?.competencyById) {
            let sub_data = {
                bar_id: data.competencyById?.id,
                sub_competency:
                {
                    id: data.competencyById?.id,
                    title: data.competencyById?.title
                },

                sub_competency_employee_level: employeeLevelList([].concat(data.competencyById?.behaviourallyanchoredratingSet?.edges?.map(({ node }) => {
                    return {
                        id: node?.employeeLevel?.id,
                        name: node?.employeeLevel?.name,
                    }
                }

                )))
            }

            setsubCompetencyEmployeeLevel(sub_data)
            getBehaviourAnchorListByIdSubCompetecny(data)
            setLoading(false)
        }
    }

    //sub competency behaviour rating scale fetch data
    const getBehaviourAnchorListByIdSubCompetecny = async (data) => {
        setSubCompetencyLoading(true)
       
        let behaviour_sub_data = data?.competencyById?.behaviourallyanchoredratingSet?.edges?.map(({ node }) => {
            return {
                id: node?.ratingScale?.id,
                title: node?.ratingScale?.name,
                behaviourExample: node?.behaviourExample,
                employeeLevel:node?.employeeLevel
            }
        }
        )

        setSubCompetecnyBehavior(behaviour_sub_data)
        setSubCompetencyLoading(false)
        // }
    }






    React.useEffect(() => {

        // based on permission of user bars will be di
        if (barsPermission?.required_permission && match.params?.id) {
            SubCompetencyEmployeeLevel(match.params?.id)
        }

    }, [barsPermission, match])

    React.useEffect(() => {
        if (userPermission?.length) {
            let permission = globalPermissionValidator(bars_permission, userPermission);
            setBarsPermission(permission)
        }
    }, [userPermission])


    return (
        <>


            <Row justify='center' align='middle'>
                {
                    <Spin spinning={permissionLoader} wrapperClassName='permission-check-bars'>
                        <div>
                            {
                                barsPermission !== undefined &&
                                <div>
                                    {
                                        barsPermission?.required_permission ?
                                            <SubCompetencyDetailView
                                                {...props}
                                                loading={loading}
                                                sub_competecny_employee_level={sub_competecny_employee_level}
                                                getBehaviourAnchorListByIdSubCompetecny={(e) => getBehaviourAnchorListByIdSubCompetecny(e)}
                                                subCompetecny_behaviour={subCompetecny_behaviour}
                                                sub_competecny_loading={sub_competecny_loading}
                                            ></SubCompetencyDetailView>
                                            :
                                            <NoPermissionView />
                                    }

                                </div>
                            }
                        </div>
                    </Spin>
                }
            </Row>


        </>
    )
}

export default compose(withApollo)(BarsDetailContainer);