import gql from 'graphql-tag';

export const CREATE_RESPONSE_OPTIMIZED_MUTATION = gql`
  mutation createResponse($answers: [AnswerInput]!, $surveyId: Int!, $totalDuration: Int) {
    createResponse(answers: $answers, surveyId: $surveyId, totalDuration: $totalDuration) {
      response {
        id
        responseDuration
        responseDate
        norm
        answerSet {
          edges{
            node{
              answer
              id
            }
          }
        }
      }
    }
  }
`;
