import React from 'react';
import ROUTE from './route';
import SalesPageContainer from '../sales/containers/SalesPageContainer';
import GetInTouchContainer from '../sales/containers/GetInTouchContainer';
import SoulIdentityContainer from '../sales/containers/SoulIndetityContainer';
import WhoposhoroContainer from './containers/CoachingContainer';
import OneOnOneContainer from './containers/OneOnOneContainer';
import OkrToolContainer from './containers/OkrContainer';
import FeedbackContainer from './containers/FeedbackContainer';
import CoachingContainer from './containers/CoachingContainer';
import ReadinessToolContainer from './containers/ReadinessContainer';
export default [

  {
    name: 'Sales page home',
    path: ROUTE.sales,
    component: SalesPageContainer,
    exact: true,
    protect: false
  },
  {
    name: 'Whoposhoro tool',
    path: ROUTE.whoposhoro,
    component: WhoposhoroContainer,
    exact: true,
    protect: false
  },
  {
    name: 'One on one tool',
    path: ROUTE.oneonone,
    component: OneOnOneContainer,
    exact: true,
    protect: false
  },
  {
    name: 'okr tool',
    path: ROUTE.okr,
    component: OkrToolContainer,
    exact: true,
    protect: false
  },
  {
    name: 'Feedback tool',
    path: ROUTE.feedback,
    component: FeedbackContainer,
    exact: true,
    protect: false
  },
  {
    name: 'Coaching tool',
    path: ROUTE.coaching,
    component: CoachingContainer,
    exact: true,
    protect: false
  },
  {
    name: 'Readiness tool',
    path: ROUTE.readiness,
    component: ReadinessToolContainer,
    exact: true,
    protect: false
  },
  {
    name: 'Get in touch page',
    path: ROUTE.getInTouch,
    component: GetInTouchContainer,
    exact: true,
    protect: false
  },
  {
    name: 'Soul identity container',
    path: ROUTE.soulIdentityTool,
    component: SoulIdentityContainer,
    exact: true,
    protect: false
  }
 
  
];
