import gql from 'graphql-tag';
import { CUSTOM_FORM_SECTION_TYPE_CONNECTION } from '../../form-section/graphql/Custom_FormSectionTypeConnection.gql';

export const REPORTED_BY_TOOL_BY_ID = gql`
  ${CUSTOM_FORM_SECTION_TYPE_CONNECTION}
  query getToolForReporteeByToolId($toolId: ID!,$employeeId: ID!) {
    getToolForReporteeByToolId(toolId: $toolId,employeeId: $employeeId) {
      id
        title
        uiFlow
        thumbnailImage
        description
        status
        public
        pubDate
        textsection{
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          edges {
            cursor
            node {
              id
              title
              text
              renderedText(employeeId:$employeeId) 
              sequence
            }
          }
          totalCount
          edgeCount
        }
        formsection {
         ...Custom_FormSectionTypeConnection
        }
    }
  }
`;
