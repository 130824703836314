import React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import gql from 'graphql-tag';
import { Empty, Spin } from 'antd';

import { compose } from '../../../core';
import { getIntFromString } from '../functions';
import { withLookFormSectionFilterUpdating, withLookFormSectionState } from '../../containers/LookOperations';

import SelectField from './SelectField';

export const ALL_FORM_SECTIONS_QUERY = gql`
  query getAllFormSections($first: Int, $orderBy: [String], $title_Icontains: String) {
    getAllFormSections(first: $first, orderBy: $orderBy, title_Icontains: $title_Icontains) {
      edges {
        node {
          id
          title
        }
      }
    }
  }
`;

const FormSectionAutoComplete = props => {
  const { loading, getAllFormSections, margin, onTitleChange, onFiltersRemove } = props;
  const handleFilterRemove = React.useRef(() => {});

  handleFilterRemove.current = () => {
    onFiltersRemove();
  };

  React.useEffect(() => {
    return () => handleFilterRemove.current();
  }, []);

  const choices =
    !loading &&
    getAllFormSections &&
    getAllFormSections.edges.length > 0 &&
    getAllFormSections.edges.map(({ node }) => ({
      label: node.title,
      value: getIntFromString(node.id)
    }));

  return (
    <SelectField
      margin={margin}
      showSearch
      choices={choices}
      style={{ width: !margin && '100%', marginBottom: !margin && 0 }}
      // labelInValue
      filterOption={false}
      onSearch={onTitleChange}
      notFoundContent={
        loading ? (
          <div align="center">
            <Spin size="small" />
          </div>
        ) : (
          <Empty />
        )
      }
      {...props}
    />
  );
};

export default compose(
  withLookFormSectionState,
  graphql(ALL_FORM_SECTIONS_QUERY, {
    options: ({ filter, orderBy }) => {
      return { variables: { ...filter, orderBy }, fetchPolicy: 'network-only' };
    },
    props({ data }) {
      const { loading, error, getAllFormSections, subscribeToMore, updateQuery } = data;
      return { loading, error, getAllFormSections, subscribeToMore, updateQuery };
    }
  }),
  withLookFormSectionFilterUpdating
)(FormSectionAutoComplete);
