import { line } from "@antv/g2plot";
import React from "react";

import { compose } from "../../core";

import EmployeeMAppAnchorsView from "../components/EmployeeMAppAnchorsView";

import { withmyResilienceAnchors } from "./DashboardOperations";

const MAppAnchors = (props) => {
  const { myResilienceAnchors } = props;

  return <EmployeeMAppAnchorsView MAppAnchors={myResilienceAnchors} {...props} />;
};

export default compose(withmyResilienceAnchors)(MAppAnchors);

// modifications on line
// 6(2)
// 8
// 10
// 11
// 13(3)
// 16(2)
