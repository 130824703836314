import gql from 'graphql-tag';

export const UPDATE_SURVEY_FILTER = gql`
  mutation updateSurveyFilter($filter: SurveyFilterInput!, $orderBy: [String], $pagination: SurveyPaginationInput) {
    updateSurveyFilter(filter: $filter, orderBy: $orderBy, pagination: $pagination) @client
  }
`;

export const UPDATE_SURVEY_RESPONSE_FILTER = gql`
  mutation updateSurveyResposesFilter($filter: SurveyResponseFilterInput!, $orderBy: [String], $pagination: SurveyResponsePaginationInput) {
    updateSurveyResposesFilter(filter: $filter, orderBy: $orderBy, pagination: $pagination) @client
  }
`;