import React from 'react';

import { AdminLayout } from '../../look';
import { organization } from '../route';

import OrganizationFormComponent from './OrganizationFormComponent';

const AddOrganizationView = props => {
  return (
    <>
      <AdminLayout
        table={false}
        active={organization}
        title="Add Organization"
        FormComponent={<OrganizationFormComponent {...props} />}
      ></AdminLayout>
    </>
  );
};

export default AddOrganizationView;
