// @ts-nocheck

import React from "react";
import { compose } from "../../core";
import { withApollo } from '@apollo/client/react/hoc';
import KudosDeleteConfiramtion from "../components/DeleteConfirmation";
import { withDeleteBadge, withDeleteBadgeFamily } from "./kudos_operation";


const KudosDeleteConfirmation = (props) => {
  const {client,me,history}=props
  const [loading,setloading]=React.useState(false)

  return (
    <>
       <KudosDeleteConfiramtion {...props} />
    </>
  );
};

export default compose(withApollo,withDeleteBadge,withDeleteBadgeFamily)(KudosDeleteConfirmation);
