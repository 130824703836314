import gql from 'graphql-tag';
import { SURVEY_TYPE } from './SurveyType.gql';

export const ALL_ORG_SURVEY_BY_IMPLICIT_ORG_ID_QUERY = gql`
  ${SURVEY_TYPE}
  query allOrgSurveysByImplicitOrgId(
    $first: Int
    $offset: Int
    $orderBy: [String]
    $name_Icontains: String
    $description_Icontains: String
  ) {
    allOrgSurveysByImplicitOrgId(
      first: $first
      offset: $offset
      orderBy: $orderBy
      name_Icontains: $name_Icontains
      description_Icontains: $description_Icontains
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...SurveyType
        }
      }
      totalCount
      edgeCount
    }
  }
`;
