import gql from 'graphql-tag';

export const BADGE_AWARD_SUBSCRIPTION = gql`

subscription badgeAwardSubscription {
  badgeAwardSubscription {
        mutation
        badgeAward{
          id   
          issuer {    
            id    
            user{
              id
              firstName
              lastName
            }
          }    
          recipient {    
            id    
            user{
              id
              firstName
              lastName
            }
          }    
          evidence   
          expirationDate    
          badge{   
            id 
            image    
            title    
            behaviouralDescription   
          }   
        }
    }
}`