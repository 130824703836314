import React from 'react';

import { getIntFromString, AdminLayout } from '../../look';
import ROUTE from '../route';

import TeamFormComponent from './teamFormComponent';

const EditTeamView = props => {
    return (
        <>
            <AdminLayout
                table={false}
                active={ROUTE.team}
                title="Edit team"
                sidebar={true}
                backLink={`${ROUTE.teamDetailLink}${props.teamByTeamId && getIntFromString(props.teamByTeamId.id)}`}
                FormComponent={props.teamByTeamId && <TeamFormComponent {...props} />}
            />
        </>
    );
};

export default EditTeamView;
