import gql from 'graphql-tag';
import { FAQ_QUESTION_TYPE } from './FaqQuestionType.gql';

export const FAQ_QUESTION_SUBSCRIPTION = gql`
  ${FAQ_QUESTION_TYPE}
  subscription faqQuestionSubscription {
    faqQuestionSubscription {
      mutation
      faqQuestion {
        ...FaqQuestionType
      }
    }
  }
`;
