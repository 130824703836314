import React from 'react';
import UserSurveyStatus from '../components/userSurveyStatus';
import { ORG_SURVEY_LIST_EMPLOYEE } from '../graphql/EmployeeProfile.gql';
import { SURVEY_ID_3CS,VALUE_SUERVEY_PART_1,PERSONALITY_PART_1,STRENGTH_PART_1,KNOWLEDGE_PART_1,grow_survey_id ,SURVEY_READINESS_LEVEL,SURVEY_WHOLE_IDENTITY,feedback_survey_id, report_tool_data, feedback_1_1_survey_id, USER_MANUAL_SURVEY_ID, effectiveness_indicator_one_on_one} from '../../../config'
import { getIntFromString } from 'modules/look';
const UserSurveyStatusContainer = (props) => {
    const { profile_detail,client } = props
    const [survey_data, SetSurveyData] = React.useState()
    const [survey_page_info,SetSurveyPageInfo] = React.useState()
    let survey_ref = React.useRef()
   

    React.useEffect(()=>{
        if(survey_data){
            survey_ref.current = survey_data
        }
    },[survey_data])


    React.useEffect(()=>{
        if(profile_detail){
            let filter={ user: profile_detail?.user?.id, first: 30, last: 1 }
            getSurveyList(filter)
        }
    },[profile_detail])
    let skip_survey=[VALUE_SUERVEY_PART_1,PERSONALITY_PART_1,STRENGTH_PART_1,KNOWLEDGE_PART_1,grow_survey_id,SURVEY_READINESS_LEVEL,feedback_survey_id,feedback_1_1_survey_id,effectiveness_indicator_one_on_one]
    const getSurveyList= async (filter)=>{
        const { data } = await client.query({
            query: ORG_SURVEY_LIST_EMPLOYEE,
            fetchPolicy: 'network-only',
            variables: filter,
        });
        if(data?.allOrgSurveysByImplicitOrgId){
            let survey = data?.allOrgSurveysByImplicitOrgId
            let list = survey?.edges?.map(({node})=>node) 
            list = list?.filter(i=>!skip_survey?.includes(getIntFromString(i?.id)))
            list = dataHandler(list)
            if((filter?.cursor) && survey_ref?.current){
                list = survey_ref?.current?.concat(list)
            }
            
            SetSurveyData(list)
            let page_info ={
                total_doc:survey?.totalCount ,
                has_next_page:survey?.pageInfo?.hasNextPage ,
                cursor:survey?.pageInfo?.endCursor,
            }
            SetSurveyPageInfo(page_info)
        }
    }

    const dataHandler =(list)=>{
        return list.map(i=>{
            let response = i?.responseSet?.edges[0]?.node
            let data={
                id:i?.id,
                name:i?.name,
                response_id:response?.id,
                response_date:response?.responseDate,
                status: response?.id?'Taken':'Pending',
                colorCombination: response?.id?{
                    color: '#4CCB1F',
                    bg: '#D6FFC8'
                }:{
                    color: '#EF7878',
                    bg: '#FFF2F1'
                }
               }
            return data
        })
    }
  
    return (
        <>
            <UserSurveyStatus {...props} survey_data={survey_data}/>
        </>
    )
}

export default UserSurveyStatusContainer