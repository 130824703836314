import gql from 'graphql-tag';
import { FAQ_QUESTION_TYPE } from './FaqQuestionType.gql';

export const FAQ_CATEGORY_TYPE = gql`
  ${FAQ_QUESTION_TYPE}
  fragment FaqCategoryType on FAQCategoryType {
    id
    name
    description
    active
    faqquestionSet {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...FaqQuestionType
        }
      }
      totalCount
      edgeCount
    }
  }
`;
