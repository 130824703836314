// @ts-nocheck
import React from "react";
import CreateEligibilityView from "../components/CreateEligibilityView";
import { ALL_EDUCATIONS_LIST } from "../graphql/RoleQueries.gql";
import { compose } from "modules/core";
import { withCreateEligibility, withUpdateEligibility } from "../RoleDefinitionOperation";
import { getIntFromString } from "modules/look";


const CreateEligibilityContainer = ( props ) => {
    
    const { permission,client,eligibility_detail,createEligibility,updateEligibility,eligibilityResponse } = props
    const [education_list,SetEducationList] = React.useState([])
    const [eligibility_loading,SetEligibility_loading] = React.useState(false)

    React.useEffect(()=>{
      if(permission?.required_permission){
        getEducationList()
      }
    },[permission])

    const getEducationList = async (filter) => {
      const { data } = await client.query({
          query: ALL_EDUCATIONS_LIST,
          fetchPolicy: 'network-only',
          variables: {...filter,first:25}
      });
      if (data?.allEducations) {
          let list = data?.allEducations?.edges.map(({ node }) => node)
          SetEducationList(list)
      }
  }

  const SubmitEligibility=async(data)=>{
    if(eligibility_detail?.id){
      try {
        SetEligibility_loading(true)
        let update = {
          education:data?.education?.map(item=>getIntFromString(item?.id)),
          id:getIntFromString(eligibility_detail?.id),
          qualitativeExperience:data?.eligibilityData?.qualitativeExperience,
          quantitativeExperience:data?.eligibilityData?.quantitativeExperience,
        } 
        const response = await updateEligibility(update)
        if(response?.id){
          eligibilityResponse({...data,id:response?.id,is_updated:true})
          SetEligibility_loading(false)
        }else{
          SetEligibility_loading(false)
        }
      } catch (error) {
        SetEligibility_loading(false)
      }
    }else{
      try {
        SetEligibility_loading(true)
        let create = {
          education:data?.education?.map(item=>getIntFromString(item?.id)),
          eligibilityData:data?.eligibilityData
        } 
        const response = await createEligibility(create)
        if(response?.id){
          eligibilityResponse({...data,id:response?.id,is_updated:false})
          SetEligibility_loading(false)
        }else{
          SetEligibility_loading(false)
        }
      } catch (error) {
        SetEligibility_loading(false)
      }
    }
  }

    return (
        <>
          <CreateEligibilityView 
          {...props} 
            education_list={education_list}
            SetEducationList={SetEducationList}
            getEducationList={(filter)=>{getEducationList(filter)}}
            onSubmitEligibility={(data)=>SubmitEligibility(data)}
            eligibility_loading={eligibility_loading}
            eligibility_detail={eligibility_detail}
          />
        </>
    )
}

export default compose(withCreateEligibility,withUpdateEligibility)(CreateEligibilityContainer)