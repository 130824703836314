import gql from 'graphql-tag';
import { SIMPLE_FIELD_RESPONCES_TYPE } from './SimpleFieldResponcesType.gql';

export const EDIT_SIMPLE_FIELD_RESPONCE = gql`
  ${SIMPLE_FIELD_RESPONCES_TYPE}
  mutation editSimpleFieldResponce(
    $answer: String!
    $formSectionId: Int!
    $simpleFieldId: ID!
    $simpleFieldResponcesId: ID!
  ) {
    updateSimpleFieldResponce(
      answer: $answer
      formSectionId: $formSectionId
      simpleFieldId: $simpleFieldId
      simpleFieldResponcesId: $simpleFieldResponcesId
    ) {
      simpleFieldResponce {
        ...SimpleFieldResponcesType
      }
    }
  }
`;

export const EDIT_SIMPLE_FIELD_RESPONCE_OPTIMIZED = gql`
  ${SIMPLE_FIELD_RESPONCES_TYPE}
  mutation editSimpleFieldResponce(
    $answer: String!
    $formSectionId: Int!
    $simpleFieldId: ID!
    $simpleFieldResponcesId: ID!
  ) {
    updateSimpleFieldResponce(
      answer: $answer
      formSectionId: $formSectionId
      simpleFieldId: $simpleFieldId
      simpleFieldResponcesId: $simpleFieldResponcesId
    ) {
      simpleFieldResponce {
        id
      }
    }
  }
`;