// @ts-nocheck
import { graphql } from '@apollo/client/react/hoc';
import _ from 'lodash';
import { SURVEY_LIST } from '../graphql/surveyList.gql';

export const withSurveyList = Component =>
    graphql(SURVEY_LIST, {
        options: props => {
            let id = '';
            if (props?.me) {
                id = props?.me?.employee?.id;
            }
            return {
                variables: {}
            };
        },
        props({ data: { loading, error, surveyList } }) {
            if (error) {
                throw new Error(error.message);
            }
            return { loading, error, surveyList };
        }
    })(Component);    