import React from 'react';
import { Spin } from 'antd';

import { compose } from '../../core';

import EditUserView from '../components/EditUserView';
// import ROUTE from '../route';

import { /* withEditUser, */ withUser } from './UserOperations';
// import { ButtonsCatch } from '../../look';

const EditUser = props => {
  // const { history, editUser } = props;
  const [submitLoading, setSubmitLoading] = React.useState(false);

  const handleSubmit = async (values, item) => {
    setSubmitLoading(true);

    try {
      // const response = await editUser({ id: values.userId });
      // response && ButtonsCatch(item, ROUTE.user, history);
    } catch (e) {
      throw new Error(e.message);
    }
    setSubmitLoading(false);
  };

  return (
    <Spin spinning={submitLoading} size="large">
      <EditUserView onSubmit={handleSubmit} {...props} />
    </Spin>
  );
};

export default compose(withUser /* withEditUser */)(EditUser);
