import gql from "graphql-tag";

export const OKR_MESSAGES = gql`
query okrMessages($okr:ID!){
    okrMessages(okr:$okr) {
      edges {
        node {
          id
          message   
          okr {
            id
            contributors {
              edges {
                node {
                  id
                  user {
                    id
                    firstName
                    lastName
                  }
                }
              }
            }
          }
          replyTo {
            id
            message
            sender{
              id
              user{
                id
                firstName
              }
            }
          }
             sender{
          id
          user{
            id
            firstName
            lastName
            email
            employee{
              id
            }
            profile{
              id
            }
          }
        }
        createdAt
        }
      }
    }
  }
`

export const OKR_MESSAGE_TYPE = gql`
  fragment OkrMessageType on OKRMessageType {
    id
    message
    replyTo {
      id
      message
      sender{
        id
        user{
          id
          firstName
        }
      }
    }
    okr{
      id
      
    }
    sender{
    id
    user{
      id
      firstName
      lastName
      email
      employee{
        id
      }
      profile{
        id
      }
    }
  }
  createdAt
  }
`;



export const CREATE_OKR_MESSAGE_MUTATION = gql`
  ${OKR_MESSAGE_TYPE}
  mutation createOkrMessage($message: String!, $okrId: Int!,$replyToId: Int) {
    createOkrMessage(messageData:{message: $message, okrId: $okrId,replyToId:$replyToId}) {
        message {
        ...OkrMessageType
      }
    }
  }
`;


export const MESSAGE_BY_OKR_SUBSCRIPTION = gql`
${OKR_MESSAGE_TYPE}
subscription okrMessageSubscription {
  okrMessageSubscription {
      mutation
      okrMessage {
        ...OkrMessageType
      }
    }
  }
`