import gql from 'graphql-tag';
import { GROUP_TYPE } from './GroupType.gql';

export const GROUP_BY_ID_QUERY = gql`
  ${GROUP_TYPE}
  query groupById($id: ID!) {
    groupById(id: $id) {
      ...GroupType
    }
  }
`;
