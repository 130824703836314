import gql from 'graphql-tag';
// import { QUESTION_TYPE } from './QuestionType.gql';

//   ${QUESTION_TYPE}
export const ALL_QUESTIONS_FOR_A_GROUP_BY_GROUP_ID_QUERY = gql`
  query allQuestionsForAGroupByGroupId(
    $first: Int
    $offset: Int
    $orderBy: [String]
    $id: ID!
    $questionText_Icontains: String
    $questionAddress_Icontains: String
    $helpText_Icontains: String
    $sequence_Icontains: Int
  ) {
    allQuestionsForAGroupByGroupId(
      first: $first
      offset: $offset
      orderBy: $orderBy
      id: $id
      questionText_Icontains: $questionText_Icontains
      questionAddress_Icontains: $questionAddress_Icontains
      helpText_Icontains: $helpText_Icontains
      sequence_Icontains: $sequence_Icontains
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          # ...QuestionType
          id
          questionText
          helpText
          sequence
          pubDate
          required
        }
      }
      totalCount
      edgeCount
    }
  }
`;
