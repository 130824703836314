import gql from 'graphql-tag';
import { RESPONSE_COMPARE_REQUEST_TYPE } from './ResponseCompareRequestType.gql';

export const SURVEY_COMPARISON_REQUEST_SUBSCRIPTION = gql`
  ${RESPONSE_COMPARE_REQUEST_TYPE}
  subscription surveyComparisonRequestSubscription {
    surveyComparisonRequestSubscription {
      mutation
      comparisonRequest {
        ...ResponseCompareRequestType
      }
    }
  }
`;
