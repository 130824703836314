import gql from 'graphql-tag';
import { SIMPLE_FIELD_RESPONCES_TYPE } from './SimpleFieldResponcesType.gql';

export const ADD_SIMPLE_FIELD_RESPONCE_MUTATION = gql`
  ${SIMPLE_FIELD_RESPONCES_TYPE}
  mutation createSimpleFieldResponce($simpleFieldResponceData: SimpleFieldResponcesInput!) {
    createSimpleFieldResponce(simpleFieldResponceData: $simpleFieldResponceData) {
      simpleFieldResponce {
        ...SimpleFieldResponcesType
      }
    }
  }
`;

export const ADD_SIMPLE_FIELD_RESPONCE_MUTATION_OPTIMIZED = gql`
  ${SIMPLE_FIELD_RESPONCES_TYPE}
  mutation  createSimpleFieldResponce($simpleFieldResponceData: SimpleFieldResponcesInput!) {
    createSimpleFieldResponce(simpleFieldResponceData: $simpleFieldResponceData) {
      simpleFieldResponce {
        id
      }
    }
  }
`;
