// DO NOT EDIT THIS FILE

import gql from 'graphql-tag';

export const USER_MANUAL_EMPLOYEE_LIST = gql`
  query userlist($search:String,$endCursor:String) {
    me {
      id
        employee {
          id
          organizationSet {
            edges {
              node {
                id
                employees(user_FirstName:$search,first:10,after:$endCursor) {
                  totalCount
                  pageInfo {
                    hasNextPage
                    endCursor
                  }
                  edges {
                    node {
                      id
                      user{
                        id
                        firstName
                        lastName
                        profile{
                          id
                          profileImage
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
  }
`;
