import gql from 'graphql-tag';

export const PASSWORD_RESET_MAIL = gql`
  mutation passwordResetMail($email: String!) {
    sendPasswordResetEmail(email: $email) {
      success
      errors
    }
  }
`;
