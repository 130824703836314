import gql from 'graphql-tag';
import { GROUP_TYPE_CONNECTION } from './GroupTypeConnection.gql';
export const SURVEY_TYPE = gql`

  ${GROUP_TYPE_CONNECTION}
  fragment SurveyType on SurveyType {
    id
    groupSet {
      ...GroupTypeConnection
    }
  }
`;