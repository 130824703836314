import gql from 'graphql-tag';

export const CREATE_READINESS_LEVEL = gql`
mutation createReadinessLevel($readinessId: Int!,$readinessLevelData:[ReadinessLevelInput]!){
    createReadinessLevel(readinessId: $readinessId,readinessLevelData:$readinessLevelData){
    readiness{
      id
    }
  }
}
`