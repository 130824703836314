import gql from "graphql-tag";

export const RECEIVED_FEEDBACK_TYPE = gql`
  fragment FeedbackType on FeedbackType {
    id
    createdAt
    feedbackType
    feedbackFrom {
      id
      user {
        id
        firstName
        lastName
        profile {
          id
          profileImage
        }
      }
    }
    response {
      id
      answerSet {
        edges {
          node {
            id
            answer
          }
        }
      }
    }
    badgeAward {
      edges {
        node {
          id
          badge {
            id
            image
          }
        }
      }
    }    
    okr {
      id
      title
    }
    detailFillIn
    createdAt
    updatedAt
  }
`;

export const RECEIVED_FEEDBACK = gql`
  ${RECEIVED_FEEDBACK_TYPE}
  query allFeedback(
    $feedbackTo: ID!,
    $feedbackFrom_User_FirstName_Icontains: String,
    $feedbackFrom_User_LastName_Icontains: String,
    $createdAt_Day_Gt: DateTime,
    $response_Answer_Answer_Icontains:String,
    $orderBy: [String],
    $okr_Goalstatement_Icontains: String,
    $createdAt_Range:[DateTime],
    $okr_Isnull: Boolean,
    $first: Int
  ) {

    receivedFeedbackFirstName: allFeedback(
      feedbackTo: $feedbackTo,
      feedbackFrom_User_FirstName_Icontains: $feedbackFrom_User_FirstName_Icontains,
      createdAt_Day_Gt: $createdAt_Day_Gt,
      createdAt_Range:$createdAt_Range,
      orderBy: $orderBy,
      first: $first
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edgeCount
      edges {
        node {
          ...FeedbackType
        }
      }
    }

    receivedFeedbackLastName: allFeedback(
      feedbackTo: $feedbackTo
      feedbackFrom_User_LastName_Icontains: $feedbackFrom_User_LastName_Icontains
      createdAt_Day_Gt: $createdAt_Day_Gt
      createdAt_Range:$createdAt_Range,
      orderBy: $orderBy,
      first: $first
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edgeCount
      edges {
        node {
          ...FeedbackType
        }
      }
    }

    receivedFeedbackOkr: allFeedback(
      feedbackTo: $feedbackTo
      createdAt_Day_Gt: $createdAt_Day_Gt
      orderBy: $orderBy,
      okr_Goalstatement_Icontains: $okr_Goalstatement_Icontains,
      createdAt_Range:$createdAt_Range,
      okr_Isnull: $okr_Isnull,
      first: $first
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edgeCount
      edges {
        node {
          ...FeedbackType
        }
      }
    }

    receivedFeedbackFeedback: allFeedback(
      feedbackTo: $feedbackTo
      createdAt_Day_Gt: $createdAt_Day_Gt
      orderBy: $orderBy,
      response_Answer_Answer_Icontains:$response_Answer_Answer_Icontains,
      createdAt_Range:$createdAt_Range,
      first: $first
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edgeCount
      edges {
        node {
          ...FeedbackType
        }
      }
    }

  }
`;
