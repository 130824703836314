import gql from 'graphql-tag';


export const PENDING_COUNTS = gql`
query PendingCount($employee:ID){
    discardCount:  me{
        id
        employee {
          id
          orgCeoEmployee {
            edges {
              node {
                id
                employees {
                  edges {
                    node {
                      id
                      initiatedDiscardRequestSet(status:"PENDING"){
                        totalCount
                        edges{
                          node{
                            okr{
                              id
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          teamManagerEmployee {
            edges {
              node {
                id
                employees {
                  edges {
                    node {
                      id
                      initiatedDiscardRequestSet(status:"PENDING") {
                        totalCount
                        edges{
                          node{
                            okr{
                              id
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          verticalHeadEmployee {
            edges {
              node {
                id
                employees {
                  edges {
                    node {
                      id
                      initiatedDiscardRequestSet(status:"PENDING") {
                        totalCount
                         edges{
                          node{
                            okr{
                              id
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          
        }
      }
    AssigneCount:contributors(createdBy_Ne:$employee,employee:$employee, role:"ASSIGNED", requestStatus_In: ["PENDING", "NEEDS_DISCUSSION"]){
        totalCount
    }
    CollabCount:contributors(createdBy_Ne:$employee,employee:$employee, role:"COLLABORATOR", requestStatus_In: ["PENDING", "NEEDS_DISCUSSION"]){
        totalCount
    }
}
`