// @ts-nocheck
import React from "react";
import { compose } from "../../core";
import CreateResponsibilityView from "../components/CreateResponsibilityView";
import { withCreateResponsibility } from "../RoleDefinitionOperation";

const CreateResponsibilityContainer = ( props ) => {

    const { onSuccess,createResponsibility } = props

     //Create a new responsibility
     const handleCreateResponsibility = async(data)=>{
        let responsibilityData ={
            title:data.title,
            description:data.description
        }
        let response  = await createResponsibility({responsibilityData})
        console.log('response',response);
        if(response.id){
            onSuccess(response)
        }
    }

    return (
        <>
          <CreateResponsibilityView 
           {...props} 
           onSubmit={(data)=>{handleCreateResponsibility(data)}}
         />
        </>
    )
}

export default compose(withCreateResponsibility)(CreateResponsibilityContainer)