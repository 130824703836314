import gql from 'graphql-tag';
import {COMPETENCY_TYPE, OKR_TYPE } from 'modules/notification/graphql/NotificationFragments.gql';

export const CONVERSATION_BY_ID = gql`
${COMPETENCY_TYPE},
${OKR_TYPE}
  query coachingConversationById($id: ID!) {
    coachingConversationById(id:$id){
       createdAt
        id
        followUpOn
        coachee{
          id
          user{
            id
            firstName
            lastName
            employee{
              id
            }
          }
        }
        coach{
          id
          user{
            id
            firstName
            lastName
            employee{
              id
            }
          }
        }
        relatedTo{
          __typename
          ...CompetencyType
          ...okrType
        }
        previousConversation{
          id
        }
        nextCoachingConversation{
          id
        }
        actionItems{
          edges{
            node{
              owner{
                id
                user{
                  id
                  employee{
                    id
                  }
                }
              }
              id
              title
              status
              details
              dueDate
            }
          }
        }
        response{
          id
          survey{
            id
          }
          answerSet{
            edges{
              node{
                id
                question{
                  id
                  questionText
                  choiceSet{
                    edges{
                      node{
                        id
                        value
                        inputType
                      }
                    }
                  }
                }
                answer
              }
            }
          }
        }
     
      }
  }
`;


export const ACTION_ITEM_SUBSCRIPTION = gql`
subscription newActionItemSubscription{
  newActionItemSubscription{
    mutation
    actionItem{
      id
      status
      details
    }
  }
}


`;
