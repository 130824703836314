import React from 'react';
import { compose } from '../../core';
import FeedbackUpdate from '../components/UpdateFeedbackView'
import { withFeedBackByid,withFeedBackSurveyByid,WithUpdateFeedback,withKeyResults } from './feedbackOperations'
import { withToolById } from "../../tool/containers/ToolOperations";
import { withApollo } from '@apollo/client/react/hoc'
import { MILESTONE_BY_KEYRESULT } from '../graphql/MilestoneByKeyresult,gql'
import { KEYRESULT_LIST } from '../graphql/KeyresultList.gql';
import { useHistory } from 'react-router-dom';
import { CONTRIBUTOR_OKR_LIST } from '../graphql/ContributerOkrList.gql';
const FeedbackUpadteContainer = props => {
  const { client,feedbackById } = props
  const history = useHistory();
  const [okrByrecipiant,setokrByrecipiant]= React.useState([]);

  const getOKRByRecepiant = async (filter) => {

    const { data } = await client.query({
        query: CONTRIBUTOR_OKR_LIST,
        variables: filter,
    });
    if(data?.contributors){
      setokrByrecipiant(data?.contributors)
    }
    
}

  React.useEffect(()=>{
    if(feedbackById && feedbackById?.feedbackTo?.user?.employee?.id){
      getOKRByRecepiant({employee:feedbackById?.feedbackTo?.user?.employee?.id})
    }
  },[feedbackById])

 

  return (
    <>
    <FeedbackUpdate {...props} 
    okrSearch={(e) => getOKRByRecepiant(e)} 
    okrByrecipiant={okrByrecipiant}
    history={history}
    ></FeedbackUpdate>
    </>
  );
};

export default compose(withApollo,withFeedBackByid,withFeedBackSurveyByid,withToolById,WithUpdateFeedback)(FeedbackUpadteContainer);
