import { displayUtcToUserTimezone, getIntFromString } from 'modules/look';
import React from 'react';
import styled from 'styled-components';
import { Row, Select, Spin } from 'antd'
import { CustomProgress } from 'modules/look-v2';
import moment from 'moment';
import { dasboardIcons } from '../dashboard-icons';
import okr_route from 'modules/Objective-keyresult/route';

const OkrPerformanceView = ( props ) => {
    const { onPagination, me, paginatedData, paginatedOkrLoading,navigateRoute } = props
    const [ filter, setFilter ] = React.useState( 'objective' );
    const [ objectivePageCount, setObjectivePageCount ] = React.useState( 0 );
    const [ krPageCount, setkrPageCount ] = React.useState( 0 );
    const [ msPageCount, setMsPageCount ] = React.useState( 0 );
    const [ paginationData, setPaginationData ] = React.useState( [] );
    const handleFilterChange = ( e ) => {

        onPagination( 0, e );
        setFilter( e )

        if ( e === 'objective' ) {
            setObjectivePageCount( 0 )
        }
        else if ( e === 'keyresult' ) {
            setkrPageCount( 0 );
        }
        else {
            setMsPageCount( 0 );
        }

    }
    const getPaginatedData = ( selectedFilter ) => {

        if ( selectedFilter === 'objective' ) {
            setObjectivePageCount( objectivePageCount + 1 )
            onPagination( objectivePageCount + 1, selectedFilter, true )

        }
        if ( selectedFilter === 'keyresult' ) {
            setkrPageCount( krPageCount + 1 );
            onPagination( krPageCount + 1, selectedFilter, true )

        }
        if ( selectedFilter === 'milestone' ) {
            setMsPageCount( msPageCount + 1 );
            onPagination( msPageCount + 1, selectedFilter, true )

        }

    }
    const getStatus = ( data ) => {
        if ( data ) {

            let currentDate = moment();

            console.log('dataokr',data);
            if ( data?.node?.okr?.isDiscarded?.id ) {
                return 'Discarded'
            }
            else if ( currentDate.isAfter( data?.node?.okr?.duedate ) && data?.node?.progress < 100 ) {
                return 'Missed'
            }

            else if ( moment( data?.node?.okr?.duedate ).isAfter( moment() ) && data?.node?.progress >= 0 && data?.node?.progress < 100 ) {
                return 'In progress'
            }

            else if ( data?.node?.progress === 100 ) {
                return 'Completed'
            }

            else if ( data?.node?.okr?.discardRequest?.status === 'PENDING' || getPendingRequest( data ) ) {
                return 'Pending'
            }

        }
    }


    const getColor = ( data ) => {
        if ( data ) {

            let currentDate = moment();
            if ( data?.node?.okr?.isDiscarded?.id ) {
                return {
                    color: '#7A7A7A',
                    bg: '#DEDEDE'
                }
            }
            else if ( currentDate.isAfter( data?.node?.okr?.duedate ) && data?.node?.progress < 100 ) {
                return {
                    color: '#EF7878',
                    bg: '#FFF2F1'
                }
            }

            else if ( moment( data?.node?.okr?.duedate ).isAfter( moment() ) && data?.node?.progress >= 0 && data?.node?.progress < 100 ) {
                return {
                    color: '#FAC53C',
                    bg: '#FDF2D6'
                }
            }

            else if ( data?.node?.progress === 100 ) {
                return {
                    color: '#4CCB1F',
                    bg: '#D6FFC8'
                }
            }

            else if ( data?.node?.okr?.discardRequest?.status === 'PENDING' || getPendingRequest( data ) ) {
                return {
                    color: '#009AF1',
                    bg: '#C2F4FF'
                }
            }

        }
    }

    const getPendingRequest = ( data ) => {

        let pendingAssignCollReq =
            data?.node?.okr?.contributorSet?.edges?.map( ( element ) =>
                ( element?.node?.role === 'OWNER' && getIntFromString( element?.node?.employee?.user?.id ) === getIntFromString( me?.id ) && element?.node?.requestStatus === "PENDING" ) ?
                    element

                    :
                    ( ( element?.node?.role === 'ASSIGNED' || element?.node?.role === 'COLLABORATOR' ) && getIntFromString( element?.node?.employee?.user?.id ) === getIntFromString( me?.id ) && element?.node?.requestStatus === "PENDING" ) ?
                        element
                        : null

            ).filter( ( node ) => node != null )




        if ( pendingAssignCollReq?.length >= 2 ) {
            pendingAssignCollReq = pendingAssignCollReq?.filter( ( { node } ) => node?.role === 'OWNER' )
        }

        return pendingAssignCollReq?.length;
    }
    React.useEffect( () => {
        if ( paginatedData ) {
            let list = {
                edgeCount: paginatedData?.edgeCount,
                totalCount: paginatedData?.totalCount,
                edges: paginatedData?.edges?.map( ( { node } ) => {
                    return {
                        node: {
                            ...node,
                            status: getStatus( { node } ),
                            colorCombination: getColor( { node } )
                        }
                    }
                } )
            }
            if ( paginatedData?.pagination ) {
                let paginatedList = {
                    edgeCount: paginationData?.edgeCount + list?.edgeCount,
                    totalCount: paginatedData?.totalCount,
                    edges: [ ...paginationData?.edges, ...list?.edges ]
                }
                setPaginationData( paginatedList )
            } else {
                setPaginationData( list )
            }
        }

    }, [ paginatedData ] )

    const gotoDetail =(id)=>{
        if(filter ==='objective'){
            navigateRoute(okr_route?.viewObjective.replace(":okrId",getIntFromString(id)))
        }
        else if (filter ==='keyresult'){
            navigateRoute(okr_route?.keyResultDetail.replace(":okrId",getIntFromString(id)))
        }
        else{
            navigateRoute(okr_route?.milestoneDetail.replace(":okrId",getIntFromString(id)))
        }
    }
    return (
        <div className='user-dashboard-okr-performance-container'>
            <h4 className='user-board-section-heading'>My OKR Performance</h4>
            <Spin spinning={paginatedOkrLoading}>
                <div className='dashboard-performance-table' style={{minHeight:"25em"}}>
                    <div className='dashboard-performance-table-heading'>
                        <div className='dasboard-performance-title-column'>
                            <CustomSelect dropdownClassName="content-dropdown" onChange={( e ) => handleFilterChange( e )} defaultValue={filter}>
                                <Select.Option className='dashobard-performance-table-heading' style={{ color: '#A3A3A3', fontSize: '13px' }} key='objective' value='objective' >OBJECTIVE</Select.Option>
                                <Select.Option className='dashobard-performance-table-heading' style={{ color: '#A3A3A3', fontSize: '13px' }} key='keyresult' value='keyresult' >KEY RESULT</Select.Option>
                                <Select.Option className='dashobard-performance-table-heading' style={{ color: '#A3A3A3', fontSize: '13px' }} key='milestone' value='milestone' >MILESTONE</Select.Option>
                            </CustomSelect>
                        </div>
                        <div className='dashobard-performance-table-heading dasboard-performance-progress-column'>PROGRESS</div>
                        <div className='dashobard-performance-table-heading dasboard-performance-date-column'>DUE DATE</div>
                        <div className='dashobard-performance-table-heading dasboard-performance-status-column'>STATUS</div>
                    </div>
                    {paginationData?.edges?.length>0 ?
                        <div className='dashboard-performance-overflow'>
                            {paginationData?.edges?.map( record => (
                                <div className='dashboard-performance-table-data-container'>
                                    <div className='dasboard-performance-title-column'>
                                        <div className='Manrope' style={{
                                            fontSize: '14px', color: '#000000',
                                            textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', fontWeight: '600',
                                            maxWidth: "300px",
                                            cursor:"pointer"
                                        }} onClick={()=>gotoDetail(record?.node?.okr?.id)}>
                                            {record?.node?.okr?.title}
                                        </div>
                                        <div>
                                            <div className='Manrope' style={{ marginBottom: '10px', fontSize: '13px', fontStyle: 'normal', paddingRight: '5px', color: '#8D8D8D', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                                {`${me?.employee?.user?.firstName} ${me?.employee?.user?.lastName}`}
                                            </div>
                                        </div>
                                    </div>
                                    <ProgressRow
                                        className='dasboard-performance-progress-column'
                                        style={{
                                            fontSize: '14px', color: '#000000',
                                            fontWeight: '600',
                                            width: "100%"
                                        }} align='middle'>
                                        <CustomProgress progress={record?.node?.progress} info={true} showPercentageOnTop={true} color={record?.node?.progress>50?'#3B8855':'#F95F53'} />
                                    </ProgressRow>
                                    <div className='Manrope dasboard-performance-date-column' style={{
                                        fontSize: '13px', color: `${record?.node?.colorCombination?.color}`,
                                        whiteSpace: "nowrap"
                                    }}>
                                        {displayUtcToUserTimezone( record?.node?.okr?.duedate, "DD MMM YYYY" )}
                                    </div>
                                    <div className='dasboard-performance-status-column'>
                                        <div className='dashboard-performance-status-btn' style={{ color: `${record?.node?.colorCombination?.color}`, background: `${record?.node?.colorCombination?.bg}` }}>
                                            {record?.node?.status}
                                        </div>
                                    </div>
                                </div>
                            ) )}
                        </div> :
                       (!paginatedOkrLoading&&<div className='no-performance-container'>
                            <img style={{height:"20em"}} className='' src={dasboardIcons?.performance_no_data} alt="" />
                            <h4 className='baner-tip-title'>Here you will see your {filter=="objective"?"Objective":filter=="keyresult"?"Key result":"Milestone"} status </h4>
                        </div>)}

                    {
                        paginationData?.edges?.length>0 &&
                        <div style={{ margin: '10px auto 10px auto' }}>Showing {paginationData?.edgeCount}/{paginationData?.totalCount}
                            {( paginationData?.edgeCount ) !== ( paginationData?.totalCount ) &&
                                <span onClick={( e ) => getPaginatedData( filter )} style={{ cursor: 'pointer', textDecoration: 'underline', marginLeft: '10px', color: '#3B8855' }} >Load more</span>
                            }

                        </div>
                    }
                </div>
            </Spin>
        </div>
    )
}
export default OkrPerformanceView

const CustomSelect = styled( Select )`
.ant-select-selector{
    border:none !important;
    color:  #A3A3A3;
    font-size: 1em;
    font-family: 'Manrope';
    line-height: normal;
    font-style: normal;
    line-height: normal;
    font-weight: 700;
}
`;

const ProgressRow = styled( Row )`

.ant-progress-bg{
height:6px !important;
}
`;
