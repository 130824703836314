import { Button, Col } from "antd";
import React from "react";
import existing_no_data from '../../assets/existing-no-data.svg'
import { useHistory } from "react-router-dom";

const NoPermissionView = (props) => {
    const {content} =props
    const history = useHistory()

    return (
        <>
            <div className="kudos-root-class kudos-existing-no-data">
                <img src={existing_no_data} className="kudos-no-data-img" alt="" />
                <Col align="center" className="kudos-root-class">
                    <h4 className="kuods-not-found">{content?content:"You don't have permission to view this page"}</h4>
                    <Button onClick={() => { history.goBack() }} className="kudos-go-back-btn">Go Back</Button>
                </Col>
            </div>
        </>
    )

}

export default NoPermissionView