import gql from "graphql-tag";

export const KEYRESULT_LIST = gql`
query keyresults($owner:ID!){
    keyresults(owner:$owner){
        edges{
          node{
            id
            title
          }
        }
    }
}
`;
