import React, { useEffect, useState, useRef } from 'react';
import * as d3 from 'd3';
import '../css/scape-report-graph.css'

const ScapeReportGraphView = (props) => {
    const { data, graph_type, setEffect } = props
    const [container, setContainer] = useState(null);
    const svgRef = useRef(null);
    const label_timeout_ref = useRef(null);

    useEffect(() => {
        if (!container || !data) return;
        d3.selectAll('.main-group').remove()
        const width = container.clientWidth;
        const height = container.clientHeight;
        const word_length = 100
        const svg = d3.select(svgRef.current)
            .attr('width', width)
            .attr('height', height);

        const center_x = width / 2;
        const center_y = height / 2;
        const transition_duration = 500
        const tree_layout = d3.tree().size([2 * Math.PI, width / 2 - 50]);

        const hierarchy_data = d3.hierarchy(data);
        const tree_data = tree_layout(hierarchy_data);

        const nodes = tree_data.descendants();

        // Create the root g element for panning and zooming
        const svg_group = svg.append('g').attr('class', 'main-group');;
        let parent_node_size = 10
        let node_radious = 8

        let path = []

        // Create links from parent to children nodes
        const link_selection = svg_group.selectAll('.link')
            .data(nodes)
            .enter()
            .append('path')
            .attr('class', (d) => `link link_${d?.data?.id}`)
            .attr('d', (d, i) => {
                if (!d?.parent) {
                    let x = center_x
                    let y = center_y
                    path.push({
                        x: center_x,
                        y: center_y,
                        data: d?.data,
                        tl: {
                            x: x - parent_node_size,
                            y: y - parent_node_size
                        },
                        tr: {
                            x: x + parent_node_size,
                            y: y - parent_node_size
                        },
                        bl: {
                            x: x - parent_node_size,
                            y: y + parent_node_size
                        },
                        br: {
                            x: x + parent_node_size,
                            y: y + parent_node_size
                        }
                    })
                    return null
                } else {
                    let x, y
                    let parent = d?.parent
                    let angle = (i / parent.children?.length) * 2 * Math.PI;
                    let radius = 250; // Initial radius


                    const parent_position = path?.find(p => p?.data?.id == d?.parent?.data?.id)
                    let gap = 100
                    do {
                        x = parent_position.x + radius * Math.cos(angle);
                        y = parent_position.y + radius * Math.sin(angle);
                        let cordinate_data = {
                            data: d?.data,
                            x, y,
                            tl: {
                                x: x - (!d?.data?.expanded ? node_radious : graph_type != 'weight' ? ((d?.data?.employee?.length || 1) * node_radious) : (d?.data?.weight * node_radious) <= 30 ? 30 : (d?.data?.weight * node_radious)) - (d?.data?.expanded ? word_length : 0) - gap,
                                y: y - (!d?.data?.expanded ? node_radious : graph_type != 'weight' ? ((d?.data?.employee?.length || 1) * node_radious) : (d?.data?.weight * node_radious) <= 30 ? 30 : (d?.data?.weight * node_radious)) - gap
                            },
                            tr: {
                                x: x + (!d?.data?.expanded ? node_radious : graph_type != 'weight' ? ((d?.data?.employee?.length || 1) * node_radious) : (d?.data?.weight * node_radious) <= 30 ? 30 : (d?.data?.weight * node_radious)) + (d?.data?.expanded ? word_length : 0) + gap,
                                y: y - (!d?.data?.expanded ? node_radious : graph_type != 'weight' ? ((d?.data?.employee?.length || 1) * node_radious) : (d?.data?.weight * node_radious) <= 30 ? 30 : (d?.data?.weight * node_radious)) - gap
                            },
                            bl: {
                                x: x - (!d?.data?.expanded ? node_radious : graph_type != 'weight' ? ((d?.data?.employee?.length || 1) * node_radious) : (d?.data?.weight * node_radious) <= 30 ? 30 : (d?.data?.weight * node_radious)) - (d?.data?.expanded ? word_length : 0) - gap,
                                y: y + (!d?.data?.expanded ? node_radious : graph_type != 'weight' ? ((d?.data?.employee?.length || 1) * node_radious) : (d?.data?.weight * node_radious) <= 30 ? 30 : (d?.data?.weight * node_radious)) + gap
                            },
                            br: {
                                x: x + (!d?.data?.expanded ? node_radious : graph_type != 'weight' ? ((d?.data?.employee?.length || 1) * node_radious) : (d?.data?.weight * node_radious) <= 30 ? 30 : (d?.data?.weight * node_radious)) + (d?.data?.expanded ? word_length : 0) + gap,
                                y: y + (!d?.data?.expanded ? node_radious : graph_type != 'weight' ? ((d?.data?.employee?.length || 1) * node_radious) : (d?.data?.weight * node_radious) <= 30 ? 30 : (d?.data?.weight * node_radious)) + gap
                            }
                        }
                        // Check for collisions
                        let collision = false;
                        for (const position of path) {

                            if (isPointInsideCordinate(cordinate_data?.tl, position?.tl, position?.tr, position?.bl, position?.br)) {
                                collision = true
                            } else if (isPointInsideCordinate(cordinate_data?.tr, position?.tl, position?.tr, position?.bl, position?.br)) {
                                collision = true
                            } else if (isPointInsideCordinate(cordinate_data?.bl, position?.tl, position?.tr, position?.bl, position?.br)) {
                                collision = true
                            } else if (isPointInsideCordinate(cordinate_data?.br, position?.tl, position?.tr, position?.bl, position?.br)) {
                                collision = true
                            }
                            else if (isPointInsideCordinate(position?.tl, cordinate_data?.tl, cordinate_data?.tr, cordinate_data?.bl, cordinate_data?.br)) {
                                collision = true
                            } else if (isPointInsideCordinate(position?.tr, cordinate_data?.tl, cordinate_data?.tr, cordinate_data?.bl, cordinate_data?.br)) {
                                collision = true
                            } else if (isPointInsideCordinate(position?.bl, cordinate_data?.tl, cordinate_data?.tr, cordinate_data?.bl, cordinate_data?.br)) {
                                collision = true
                            } else if (isPointInsideCordinate(position?.br, cordinate_data?.tl, cordinate_data?.tr, cordinate_data?.bl, cordinate_data?.br)) {
                                collision = true
                            }
                        }

                        if (collision) {
                            radius += (node_radious * 5); // Increase radius in case of collision
                        } else {
                            path.push(cordinate_data);
                            radius = 250
                            break;
                        }

                    } while (true)

                    return `M ${parent_position?.x},${parent_position?.y} L ${x},${y}`;
                }
            })
            .style('stroke', '#AABAC6')
            .style('stroke-dasharray', '5,5');

        // Create circles for nodes
        const node_selection = svg_group.selectAll('.node')
            .data(nodes)
            .enter()
            .append('g')
            .attr('class', (d) => `node node_${d?.data?.id}`)
            .attr('transform', (d, i) => {
                let node = path?.find(nd => nd?.data?.id === d?.data?.id)
                return `translate(${node?.x},${node?.y})`
            })

        node_selection.append('g').attr('class', (d) => `right_text_${d?.data?.id}`)
        node_selection.append('g').attr('class', (d) => `right_bottom_text_${d?.data?.id}`)
        node_selection.append('g').attr('class', (d) => `left_text_${d?.data?.id}`)
        node_selection.append('g').attr('class', (d) => `left_bottom_text_${d?.data?.id}`)
        //to add the circles
        const circle_section = node_selection.append('circle')
            .attr('r', (d) => (!d?.parent || d?.data?.is_parent) ? parent_node_size :
                graph_type == 'weight' ? (d?.data?.weight * node_radious) <= 30 ? 30 : (d?.data?.weight * node_radious) : 30)
            .attr('fill', (d) => (!d?.parent || d?.data?.is_parent) ? '#AABAC6' : `url(#gradiant_${d?.data?.id})`)
            .attr('filter', (d) => `url(#shadow_${d?.data?.id})`)
            .attr('class', (d) => `circle_${d?.data?.id} ${d?.data?.circle_id}`)
            .on('mouseover', (event, node) => {
                if (!node?.data?.is_parent && graph_type != 'weight') {
                    mouseover(node)
                }
            }) // Add mouseover event handler
            .on('mouseout', (event, node) => {
                if (!node?.data?.is_parent && graph_type != 'weight') {
                    mouseout(node)
                }

            }); // Add mouseout event handler

        let label_height = "-40"
        // Append the text labels (text elements) before the circles in the nodeSelection
        node_selection.append('text')
            .attr('dy', (d) => graph_type != 'weight' ? label_height : (d?.data?.weight * node_radious) <= 30 ? label_height : `-${((d?.data?.weight || 1) * node_radious) + 30}`)
            .attr('class', (d) => `node_label_${d?.data?.id}`)
            .attr('text-anchor', 'middle')
            .attr('font-size', (d) => d.parent ? '14' : '18')
            .attr('fill', '#000')
            .attr('font-weight', '600')
            .text(d => d.data.name);

        node_selection.append('text')
            .attr('dy', '10')
            .attr('dx', '0')
            .attr('class', (d) => `count_${d?.data?.id}`)
            .attr('text-anchor', 'middle')
            .attr('font-size', '28')
            .attr('fill', '#FFF')
            .attr('font-weight', '600')
            .text(d => d?.data?.is_parent ? '' : graph_type == 'weight' ? d?.data?.weight : d.data.employee?.length)
            .on('mouseover', (event, node) => {
                if (!node?.data?.is_parent && graph_type != 'weight') {
                    mouseover(node)
                }
            }) // Add mouseover event handler
            .on('mouseout', (event, node) => {
                if (!node?.data?.is_parent && graph_type != 'weight') {
                    mouseout(node)
                }
            }); // Add mouseout event handler

        function mouseover(node) {
            setEffect(null)
            d3.select(`.circle_${node?.data?.id}`)
                .transition()
                .duration(transition_duration)
                .attr('r', (d) => {
                    if (((node?.data?.employee?.length || 1) * node_radious) <= 30) {
                        return 30
                    } else {
                        return (node?.data?.employee?.length || 1) * node_radious
                    }
                });
            d3.select(`.node_label_${node?.data?.id}`)
                .transition()
                .duration(transition_duration)
                .attr('dy', (d) => {
                    if (((node?.data?.employee?.length || 1) * node_radious) <= 30) {
                        return label_height
                    } else {
                        return `-${((d?.data?.employee?.length || 1) * node_radious) + 30}`
                    }
                })

            d3.select(`.count_${node?.data?.id}`)
                .transition()
                .duration(transition_duration)
                .attr('dy', (d) => {
                    if (((node?.data?.employee?.length || 1) * node_radious) <= 30) {
                        return 10
                    } else {
                        return 7
                    }
                })
            d3.select(`#gradiant_${node?.data?.id}`)
                .transition()
                .duration(transition_duration)
                .attr('gradientTransform', (d) => 'translate(70 56) rotate(77.1957) scale(157.927)')
            d3.selectAll('.employee_text').remove()
            if (label_timeout_ref?.current) {
                clearTimeout(label_timeout_ref?.current)
            }
            label_timeout_ref.current = setTimeout(() => {
                let right_section = node.data.employee.filter((i, no) => no % 2 === 0);
                let rd = ((node?.data?.employee?.length || 1) * node_radious)
                let rt = right_section?.slice?.(0, Math.ceil(right_section?.length / 2))
                let rb = right_section?.slice?.(Math.ceil(right_section?.length / 2), right_section?.length)
                rt.forEach((element, index) => {
                    console.log('element', element);
                    d3.select(`.right_text_${node?.data?.id}`)
                        .append('text')
                        .attr('dy', `-${index * 20}`)
                        .attr('dx', (d) => (rd + (rt?.length === 1 ? 30 : 20) - (index * 7)))
                        .attr('font-size', '14')
                        .attr('fill', '#000')
                        .attr('font-weight', '500')
                        .text(element?.name?.slice(0, 10))
                        .attr('class', `employee_text`);
                });
                rb.forEach((element, index) => {
                    d3.select(`.right_bottom_text_${node?.data?.id}`)
                        .append('text')
                        .attr('dy', `${(index + 1) * 20}`)
                        .attr('dx', (rd + 20 - ((index + 1) * 7)))
                        .attr('font-size', '14')
                        .attr('fill', '#000')
                        .attr('font-weight', '500')
                        .text(element?.name?.slice(0, 10))
                        .attr('class', `employee_text`);
                });

                let left_section = node.data.employee.filter((i, no) => no % 2 !== 0);
                let lt = left_section?.slice?.(0, Math.ceil(left_section?.length / 2))
                let lb = left_section?.slice?.(Math.ceil(left_section?.length / 2), left_section?.length)
                lt.forEach((element, index) => {

                    d3.select(`.left_text_${node?.data?.id}`)
                        .append('text')
                        .attr('dy', `-${index * 20}`)
                        .attr('dx', -(rd + 20 - (index * 7) + (element?.name?.length >= 10 ? (11 * 7) : ((element?.name?.length + 1) * 7))))
                        .attr('font-size', '14')
                        .attr('fill', '#000')
                        .attr('font-weight', '500')
                        .text(element?.name?.slice(0, 10))
                        .attr('class', `employee_text`);
                });
                lb.forEach((element, index) => {

                    d3.select(`.left_bottom_text_${node?.data?.id}`)
                        .append('text')
                        .attr('dy', `${(index + 1) * 20}`)
                        .attr('dx', -(rd + 20 - ((index + 1) * 7) + (element?.name?.length >= 10 ? (11 * 7) : ((element?.name?.length + 1) * 7))))
                        .attr('font-size', '14')
                        .attr('fill', '#000')
                        .attr('font-weight', '500')
                        .text(element?.name?.slice(0, 10))
                        .attr('class', `employee_text`);
                });
            }, transition_duration)
        }

        function mouseout(node) {
            setEffect(null)
            if (label_timeout_ref?.current) {
                clearTimeout(label_timeout_ref?.current)
            }
            d3.selectAll('.employee_text').remove()
            d3.select(`.circle_${node?.data?.id}`)
                .transition()
                .duration(transition_duration)
                .attr('r', (d) => !d?.parent ? parent_node_size : 30);
            d3.select(`.count_${node?.data?.id}`)
                .transition()
                .duration(transition_duration)
                .attr('dy', '10')
            d3.select(`.node_label_${node?.data?.id}`)
                .transition()
                .duration(transition_duration)
                .attr('dy', (d) => label_height);
            d3.select(`#gradiant_${node?.data?.id}`)
                .transition()
                .duration(transition_duration)
                .attr('gradientTransform', (d) => 'translate(25.3488 23.7209) rotate(75.408) scale(58.1549)')
            d3.selectAll(".sr-employee-name").remove()
        }

        let def_section = node_selection
            .data(nodes.slice(1))
            .append('defs');

        // to add the box shadow around the circles
        def_section.append('filter').attr('id', (d) => `shadow_${d?.data?.id}`).attr('width', '140%').attr('height', '140%').attr('x', "-20%").attr('y', "-20%")
            .append('feDropShadow').attr('dx', '2').attr('dy', '2').attr('stdDeviation', '3').attr('flood-color', (d) => d?.data?.color);

        //to add graient color to circles
        let graient_section = def_section.append('radialGradient').attr('id', (d) => `gradiant_${d?.data?.id}`)
            .attr('cx', '0').attr('cy', '0').attr('r', '1').attr('gradientUnits', 'userSpaceOnUse').attr('gradientTransform', (d) => 'translate(25.3488 23.7209) rotate(75.408) scale(58.1549)')

        graient_section.append('stop').attr('stop-color', (d) => getOpacityColor(d?.data?.color, 0.65))
        graient_section.append('stop').attr('stop-color', (d) => d?.data?.color).attr('offset', 1);
 
        // Add the zoom behavior to the root g element
        const zoom = d3.zoom()
            .extent([
                [0, 0],
                [width, height]
            ])
            .filter((event)=>{
              return event.ctrlKey 
            })
            .scaleExtent([0.05, 3]) // Adjust the minimum and maximum scale values
            .on('zoom', (event) => {
                console.log('       d3.scaleIdentity',       d3.scaleIdentity);
                svg_group.attr('transform', event.transform);
            })
            
        // Set the initial scale to 0.5
        let initialTransform
        if (data?.children?.length < 7) {
            initialTransform = d3.zoomIdentity.translate(0, 0)
            initialTransform = initialTransform.scale(1);
        } else {
            initialTransform = d3.zoomIdentity.translate(center_x / 2, center_y / 2)
            initialTransform = initialTransform.scale(0.5);
        }

        svg.call(zoom.transform, initialTransform);

        svg.call(zoom);

        return () => {
            // Clean up code, if needed.
        };
    }, [container, data]);

    const isPointInsideCordinate = (cordinate, tl, tr, bl, br) => {

        const minX = Math.min(tl.x, tr?.x, bl?.x, br?.x);
        const maxX = Math.max(tl.x, tr?.x, bl?.x, br?.x);
        const minY = Math.min(tl.y, tr?.y, bl?.y, br?.y);
        const maxY = Math.max(tl.y, tr?.y, bl?.y, br?.y);

        return cordinate?.x >= minX && cordinate?.x <= maxX && cordinate?.y >= minY && cordinate?.y <= maxY;
    }

    const getOpacityColor = (hex, opacity) => {
        hex = hex?.replace(/^#/, '');

        // Parse the hex color into its RGB components
        const r = parseInt(hex.slice(0, 2), 16);
        const g = parseInt(hex.slice(2, 4), 16);
        const b = parseInt(hex.slice(4, 6), 16);

        const rgb = { r, g, b }
        return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${opacity})`;
    }

    return (
        <div className='scape-report-svg-container' ref={setContainer}>
            <svg ref={svgRef}></svg>
        </div>
    );
}
export default ScapeReportGraphView