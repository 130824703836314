import { compose } from "modules/core";
import React from "react";
import { withApollo } from '@apollo/client/react/hoc';
import CompetencyModalView from "../components/ca-competecny-modal-view";
import { ALL_COMPETENCIES } from "../graphql/ca-detail-queries.gql";

const CompetecnyModalContainer =(props)=>{

    const {client,me,onCancel} = props;
    const [competecny_list,setCopetencyList]=React.useState([])

    React.useEffect(()=>{
        if(me){
            getCompetecyList()
        }
    },[me])

    const getCompetecyList =async(filter)=>{
        const { data } = await client.query( {
            query: ALL_COMPETENCIES,
            variables: { ...filter ,first:25},
            fetchPolicy: 'network-only'
        } );
        if(data?.allCompetencyCategory){
            let list  =data?.allCompetencyCategory?.edges?.map(({node})=>node)
            setCopetencyList(list)
        }
    }

    return (
        <>
        <CompetencyModalView {...props}
            competecny_list={competecny_list}
            getCompetecyList={(filter)=>getCompetecyList(filter)}
            onCancel={()=>onCancel()}/>
        </>
    )

}
export default compose(withApollo)(CompetecnyModalContainer)