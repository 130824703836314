import React from "react";
import { Row, Col, Button,Input,Radio ,Space, Table ,Checkbox,Menu,Spin, Tooltip  } from "antd";
import styled from "styled-components";
import { SnippetsOutlined,DatabaseOutlined } from '@ant-design/icons';
import { displayBoolean, ReactMarkdown } from "../../look";
const AdditionalDetail = props => {

    const {label,dataSource,isModal,showReport,showStatus,reportView,isFeedback,showFull} = props
  
    const anchorStyle={
        whiteSpace:'nowrap',
        overflow:'hidden',
        textOverflow:'ellipsis',
        maxWidth:'110px',
        marginTop:'5px'
      }

    const columns = [
        {
          title: 'title',
          key: 'Title',
          render: (text, record) => record && 
           <NewRow>
            {
              showReport && (
                <Tooltip title={isFeedback?"View Feedback Detail":"View Detail"}>
                     <Button type='primary' style={{width:'30px',height:'30px',borderRadius:'50%',marginRight:'10px'}} size='small' onClick={()=>{reportView(record)}}>
                      {isFeedback?<DatabaseOutlined />:<SnippetsOutlined />}
                    </Button>
                </Tooltip>
              )
            }
            <Col>
              <p style={!showFull?anchorStyle:{marginTop:'5px',fontWeight:'bold'}}>{record?.title}</p>
              {record?.okr?<p style={!showFull?anchorStyle:{marginTop:'5px',fontWeight:'normal'}}>Okr: {record?.okr?.title}</p>:null}
              {
                record?.notes && (
                  <div style={{maxHeight:'75px',overflow:'hidden'}}>
                    <p><u>Notes</u></p>
                    <ReactMarkdown >{record?.notes}</ReactMarkdown>
                  </div>
                )
              }
              
            </Col>
            {showStatus && (
              <div style={{paddingTop:'8px',marginLeft:'10px'}}>
                {displayBoolean(record?.completed)}
              </div>
            )}
            
           </NewRow>
        },
          {
          title: 'content',
          key: 'content',
          render: (text, record) => record && 
          <Tooltip title={record?.content}>
           <p style={anchorStyle}>{record?.content}</p>
          </Tooltip>
        }
    ]

    return (
        <>
        <p style={{fontSize:'15px',fontWeight:'bold'}}>{label}</p>  
        <MyTable
        size={'middle'}
        showHeader={false}
        pagination={false}
        dataSource={
          dataSource
            ? dataSource
            : []
        }
        columns={columns.map(c => ({ ...c, sortDirections: ['descend', 'ascend', 'descend'] }))}
      />
        </>
    )
}

export default AdditionalDetail;


export const MyTable = styled(Table)`
  table {
    margin-top:20px
    border-spacing: 0 1em;
    tbody {
      tr {
        td {
          border: 1px solid rgba(0, 0, 0, 0.06) !important;
          border-style: solid none !important;
        }
        td:first-child {
          border-left-style: solid !important;
          border-top-left-radius: 5px !important;
          border-bottom-left-radius: 5px !important;
        }
        td:last-child {
          border-right-style: solid !important;
          border-bottom-right-radius: 5px !important;
          border-top-right-radius: 5px !important;
          border-bottom: 1px solid rgba(0, 0, 0, 0.06) !important;
        }
      }
    }
  }
`;

const NewRow = styled(Row)`
display:flex;
flex-direction:row;
flex-wrap:nowrap;
gap:5px;
`