import gql from 'graphql-tag';
import { COMMITMENT_TYPE } from './CommitmentType.gql';

export const COMMITMENT_SUBSCRIPTION = gql`
  ${COMMITMENT_TYPE}
  subscription toolsCommitmentSubscription {
    toolsCommitmentSubscription {
      mutation
      commitment {
        ...CommitmentType
      }
    }
  }
`;
