import { Button, Form, Input, Modal, Select } from "antd";
import { organization_icons } from "modules/poc-dashboard/icons";
import React from "react";
import PocCustomDropdown from "../common/pocCustomDropdown";

const EditEmployeeDetailView = (props) => {
    const { edit_details, onCancel, updateuser } = props
    const [form] = Form.useForm();
    const [initialValues, setInitialValues] = React.useState({});
    React.useEffect(() => {
        if (edit_details != undefined) {
            const initialValue = {
                email: edit_details?.email,
                firstName: edit_details?.firstName,
                lastName: edit_details?.lastName
            };
            setInitialValues(initialValue)
            form.setFieldsValue(initialValue);
        };

    }, [edit_details, form]);

    const handleUpdate = (value) => {
        if(value != undefined){
            updateuser(value)
        }
    }
    return (
        <div style={{ marginTop: "1em" }}>
            <Modal
                visible={edit_details}
                centered
                footer={false}
                destroyOnClose={true}
                closable={false}
                style={{ maxWidth: '35rem' }}
                width={"90%"}>
                <div style={{ display: 'flex', flexDirection: "column", gap: '1em', width: "100%", padding: '1em' }}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "1em" }}>
                        <h4 className="poc-dashboard-card-title" style={{ fontWeight: 600 }}>Edit</h4>
                        <img src={organization_icons.close_icon} alt="close_icon" style={{ cursor: "pointer" }} onClick={() => onCancel(false)} />
                    </div>
                    <Form
                        form={form}
                        name={"employee edit"}
                        style={{ width: "100%" }}
                        onFinish={(value) => { handleUpdate(value) }}>
                        <div>
                            <h4 className='vertical-form-label'>Email id</h4>
                            <Form.Item
                                name="email"
                                rules={[{ required: true, message: 'Please input your email!' }]}
                                required={true}
                            >
                                <Input className='vertical-input' maxLength={200} style={{ marginTop: "0.75em" }} placeholder='Enter email id'></Input>
                            </Form.Item>
                        </div>
                        <div style={{ marginTop: "1em" }}>
                            <h4 className='vertical-form-label'>First Name</h4>
                            <Form.Item
                                name="firstName"
                                rules={[{ required: true, message: 'Please input first name!' }]}
                                required={true}
                            >
                                <Input className='vertical-input' name="firstName" maxLength={200} style={{ marginTop: "0.75em" }} placeholder='Enter First name'></Input>
                            </Form.Item>
                        </div>
                        <div style={{ marginTop: "1em" }}>
                            <h4 className='vertical-form-label'>Last Name</h4>
                            <Form.Item
                                name="lastName"
                                rules={[{ required: true, message: 'Please input last name!' }]}
                                required={true}
                            >
                                <Input className='vertical-input' maxLength={200} style={{ marginTop: "0.75em" }} placeholder='Enter Last Name'></Input>
                            </Form.Item>

                        </div>
                        <div style={{ width: "100%", display: 'flex', gap: "1em", justifyContent: 'end', alignItems: "center", marginTop: "2em" }}>
                            <Button className="organization-cancel-btn" onClick={() => onCancel(false)}>Cancel</Button>
                            <Button className="organization-save-btn" htmlType="submit">Save the changes</Button>
                        </div>
                    </Form>
                </div>
            </Modal>
        </div>
    )
}

export default EditEmployeeDetailView;