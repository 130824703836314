import React from "react";
import { Row, Col, Button, Card, Divider, Modal, Spin, message } from "antd";
import styled from "styled-components";

import { useHistory } from "react-router-dom";
import { MetaTags } from "../../look";
import ReactMarkdown from '../../look/components/ReactMarkdown';
const NewH3 = styled.h3`
  @media only screen and (max-width: 480px) {
    font-size: 2vh;
    font-weight: bold;
    text-align: center;
  }
  font-size: 3vh;
`;

const NewH2 = styled.h2`
  @media only screen and (max-width: 480px) {
    font-size: 3vh;
    font-weight: bold;
    text-align: center;
  }
  font-size: 4vh;
`;
const NewRow = styled(Row)`
  background-color: #e5e5e5;
  border-radius: 0 0 0 25vh;
  margin: -5vh -5vh 0 -5vh;
  @media only screen and (max-width: 1000px) {
    margin-top: 0;
    padding-bottom: 6vh;
  }
`;
const NewDivider = styled(Divider)`

`;

const CardView = styled(Card)`
text-align:center;
margin-bottom:16px;
cursor:pointer;
border:2px solid #CACACA;
&:hover{
    background:#B0CB1F!important;
    border:2px solid #B0CB1F;
}
`

const TakeTestSurveyView = (props) => {
  const {
    loading,
    SurveyById
  } = props;
  const [visible, setVisible] = React.useState(false);
  let [selectedSurvey, setSelectedSurvey] = React.useState([]);
  const survey = SurveyById?.groupSet?.edges[0]?.node?.questionSet?.edges[0]?.node;

  const handleSelection = id => {
    if (!selectedSurvey.find(value => value === id) && selectedSurvey?.length >= 10) {
      // setVisible(true)
      message.destroy()
      message.warning("You can only select 10 values")
    } else {
      setSelectedSurvey(
        !selectedSurvey.find(value => value === id) && selectedSurvey?.length < 10 ? [...selectedSurvey, id] : selectedSurvey.filter(s => s !== id)
      )
    }

  };

  React.useEffect(() => {
    if (selectedSurvey?.length == 10) {
      setVisible(true)
    } else {
      setVisible(false)
    }
  }, [selectedSurvey])

  const history = useHistory();
  const navigateTo = () => {

    let edgeData = []
    selectedSurvey.forEach(element => {
      let question = SurveyById?.groupSet?.edges?.find(seq => seq?.node?.label === element)
      if (question) {
        edgeData.push(question)
      }
    });

    let set = [].concat.apply(
      [], edgeData.sort(function (a, b) {
        return a.node.sequence - b.node.sequence;
      }).map(f => f.node.questionSet?.edges))
    localStorage.setItem('selectedSoulValue', JSON.stringify({ values: selectedSurvey, questions: set }))
    history.push('/soul/values/survey/detail/3')
  };

  return (
    <>
      <MetaTags title="soul" description="This is the soul survey page" />


      <Spin spinning={loading}>
        {!loading && survey && (
          <div>
            <Col >
              <h3 style={{ fontWeight: 'bold' }}>
                <ReactMarkdown>{survey?.questionText}</ReactMarkdown>
              </h3>
              <h3 style={{ fontWeight: 'normal', marginTop: '10px', marginLeft: '1vh' }}>
                <ReactMarkdown>Select 10 values that resonate with you the most</ReactMarkdown>
              </h3>
              <NewDivider style={{ borderColor: "#7cb305" }} />

            </Col>
            <div className="site-card-wrapper">
              <Row gutter={16}>
                {survey?.choiceSet?.edges?.map(data => (
                  <Col lg={{ span: 6 }} xs={{ span: 24 }} sm={{ span: 12 }}>
                    <CardView className={`value-survey-card ${selectedSurvey?.includes(data?.node?.value)?'value-active-card':''}`} onClick={() => handleSelection(data?.node?.value)} >
                      {
                        selectedSurvey?.includes(data?.node?.value) && (
                          <h4 className="value-counter">{selectedSurvey?.indexOf(data?.node?.value) + 1}</h4>
                        )
                      }

                      <h3 className="value-card-text"><ReactMarkdown>{data?.node?.value}</ReactMarkdown></h3>
                    </CardView>
                  </Col>
                ))

                }
              </Row>

              <Col span={24} align="right">
                {selectedSurvey?.length >= 10 ? <Button  className="take-test-btn" onClick={navigateTo}>Proceed</Button> : ''}
              </Col>
              <Modal
                footer={null}
                centered
                visible={/* true || */ visible}
                width={"500px"}
                destroyOnClose={true}
                onCancel={() => setVisible(false)}
                closable={false}
              //  bodyStyle={{ border: '0px' }}
              >
                <Col>
                  <h3 style={{ fontWeight: 'bold', textAlign: "center",fontSize:"20px" }}>You have selected your 10 values!</h3>
                </Col>
                <div style={{ display: "flex", flexDirection: "row", gap: "1em", justifyContent: "center", alignItems: "center" }}>
                  <Button onClick={() => setVisible(false)} className="take-test-btn" style={{height:"36px",fontSize:"16px",padding:"2px 20px 35px 20px"}}>Change</Button>
                  <Button onClick={() => { setVisible(false); navigateTo() }} className="take-test-btn" style={{height:"36px",fontSize:"16px",padding:"2px 20px 35px 20px"}}>Proceed</Button>
                </div>
              </Modal>
            </div>
          </div>
        )

        }
      </Spin>

    </>
  );
};

export default TakeTestSurveyView;
