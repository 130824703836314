import gql from "graphql-tag"

export const ALL_SURVEY_RESPONSE = gql`
query  SurveyById($gender:ID!,$HighestDegree:ID!,$DiplomasCertificates:ID!,$Specializations:ID!,$YearsOfExperience:ID!,$CurrentNationality:ID!,$value: ID!,$strength: ID!,$personality: ID!,$knowledge: ID!, $responseUser: ID!,$diversity:ID!){
    value: SurveyById(id: $value) {
        id
        name
        groupSet(last:1) {
          edges {
            node {
              id
              questionSet {
                edges {
                  node {
                    id
                    answerSet(response_Survey:$value,response_User: $responseUser,first:1) {
                      edges {
                        node {
                          id
                          answer
                          response {
                            id
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      strength:SurveyById(id: $strength) {
        id
        name
        groupSet(last:1) {
          edges {
            node {
              id
              questionSet {
                edges {
                  node {
                    id
                    answerSet(response_Survey:$strength,response_User: $responseUser,first:1) {
                      edges {
                        node {
                          id
                          answer
                          response {
                            id
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      personality: SurveyById(id: $personality) {
        id
        name
        groupSet(last:1) {
          edges {
            node {
              id
              questionSet {
                edges {
                  node {
                    id
                    answerSet(response_Survey:$personality,response_User: $responseUser,first:1) {
                      edges {
                        node {
                          id
                          answer
                          response {
                            id
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      knowledge: SurveyById(id: $knowledge) {
        id
        name
        groupSet(last:1) {
          edges {
            node {
              id
              questionSet {
                edges {
                  node {
                    id
                    answerSet(response_Survey:$knowledge,response_User: $responseUser,first:1) {
                      edges {
                        node {
                          id
                          answer
                          response {
                            id
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      diversity:surveyResponses(first: 1, user: $responseUser, survey: $diversity, orderBy: ["-responseDate"]) {
        edges {
          node {
            id
           
            gender: answerSet(question: $gender){
              edges {
                node {
                  id
                  answer
                }
              }
            }
     
            HighestDegree: answerSet(question: $HighestDegree){
              edges {
                node {
                  id
                  answer
                }
              }
            }

            DiplomasCertificates: answerSet(question: $DiplomasCertificates){
              edges {
                node {
                  id
                  answer
                }
              }
            }
     
            Specializations: answerSet(question: $Specializations){
              edges {
                node {
                  id
                  answer
                }
              }
            }
            YearsOfExperience: answerSet(question: $YearsOfExperience){
              edges {
                node {
                  id
                  answer
                }
              }
            }
     
            CurrentNationality: answerSet(question: $CurrentNationality){
              edges {
                node {
                  id
                  answer
                }
              }
            }
          }
        }
      }
}
`
//Donot edit this query
export const USER_IMAPACT_NARRATIVE = gql`
query allImpactNarrative($user: ID){
    allImpactNarrative(user:$user,last:1,orderBy:["createdAt"]){
        edges{
            node{
              id
              overview
              communication
              collaboration
              decisionMaking
              relationshipBuilding
            }
          }
    }
   
  }`

export const CREATE_IMAPACT_NARRATIVE = gql`
mutation createImpactNarrative($impactNarrativeData: ImpactNarrativeInput!){
  createImpactNarrative(impactNarrativeData:$impactNarrativeData){
    impactNarrative{
      id
    }
    }
  }`

export const UPDATE_IMAPACT_NARRATIVE = gql`
mutation updateImpactNarrative($id: ID!,$collaboration: String,$communication: String,$decisionMaking: String,$overview: String,$relationshipBuilding: String){
  updateImpactNarrative(id: $id,collaboration: $collaboration,communication: $communication,decisionMaking: $decisionMaking,overview: $overview,relationshipBuilding: $relationshipBuilding){
    impactNarrative{
      id
    }
    }
  }`