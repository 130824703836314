import React from 'react';

import { compose } from '../../core';
import { AdminLayout, displayDataCheck, GetColumnSearchProps, getOrderBy } from '../../look';
import { ALL_STORY_TAGS_QUERY } from '../graphql/AllStoryTags.gql';
import { withApollo } from '@apollo/client/react/hoc';
import ROUTE from '../route';

import { withAllStoryTags, withDeleteTag, withStoryFilterUpdating, withStoryState } from './StoryOperations';
import { STORY_TAG_SUBSCRIPTION } from '../graphql/StoryTagSubscription.gql';

const Tag = props => {
  const {
    loading,
    allStoryTags,
    onPaginationChange,
    deleteTag,
    onOrderByChange,
    orderBy,
    onNameChange,
    onDescriptionChange,
    onFiltersRemove,
    client,
    pagination,
    filter,me
  } = props;

 
  const [allStoryTagSet,setStoryTagSet] = React.useState(undefined);

  const storyTagDataRef = React.useRef(null);

  let storyTagSubscription = undefined;
  const m = React.useRef(true)


  React.useEffect(()=>{

    if(me){
      getAllTags()
    }

  },[me])


  const getAllTags= async () => {
    
    const { data } = await client.query({
      query: ALL_STORY_TAGS_QUERY,
      variables: { ...pagination, ...filter, orderBy },
      fetchPolicy: 'network-only',
    });
    
    if (data?.allStoryTags) {
      
      setStoryTagSet(data?.allStoryTags);
      storyTagDataRef.current = data?.allStoryTags;
      SubscribeToStoryComments();
    }
  }


  React.useEffect(() => {
    return () => {
      m.current = false
    }
  }, [])

  React.useEffect(() => {
    m.current = true

  }, [])


  React.useEffect(() => {
    return () => {
      if (storyTagSubscription) {
        storyTagSubscription.unsubscribe();
      }
    }
  }, [])
 

  const SubscribeToStoryComments = () => {

    storyTagSubscription = client.subscribe({
      query: STORY_TAG_SUBSCRIPTION,
    }).subscribe({
      next(result) {

        switch (result.data.storyTagSubscription.mutation) {
         
          case 'DELETE':
           
            if (m.current) {
              let storyTagSet = storyTagDataRef.current?.edges
              let storyTagSetWithDeletedRecord = storyTagSet?.filter((tag)=>
              tag?.node?.id !== result.data.storyTagSubscription?.tag?.id)
              let updatedStoryTagSet = {edgeCount:(storyTagDataRef.current?.edgeCount - 1 ),totalCount:(storyTagDataRef.current?.totalCount - 1 ),edges:storyTagSetWithDeletedRecord}
              setStoryTagSet(updatedStoryTagSet);
              storyTagDataRef.current = updatedStoryTagSet;
            }

            break;
          default:
            break
        }

      }
    })

  }





  const columns = [
    {
      title: 'Name',
      key: 'name',
      sorter: () => onOrderByChange(getOrderBy('name', orderBy)),
      ...GetColumnSearchProps('name', onNameChange),
      render: (text, record) => record && displayDataCheck(record.name)
    },
    {
      title: 'Description',
      key: 'description',
      sorter: () => onOrderByChange(getOrderBy('description', orderBy)),
      ...GetColumnSearchProps('description', onDescriptionChange),
      render: (text, record) => record && displayDataCheck(record.description)
    }
  ];

  const handleDeleteRecord = async(id) => {

    await deleteTag({id:id})

  }

  return (
    <AdminLayout
      table={true}
      active={ROUTE.tag}
      title="Tags"
      loading={loading}
      tableData={allStoryTagSet}
      columns={columns}
      specificMutation = {true}
      handleDeleteRecord = {handleDeleteRecord}
      onDelete={deleteTag}
      editLink={ROUTE.editTagLink}
      addLink={ROUTE.addTag}
      onPaginationChange={onPaginationChange}
      onFiltersRemove={onFiltersRemove}
    />
  );
};

export default compose(withStoryState, withAllStoryTags, withDeleteTag, withStoryFilterUpdating,withApollo)(Tag);
