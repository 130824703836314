import gql from 'graphql-tag';
import { QUESTION_TYPE } from './QuestionType.gql';

export const QUESTION_SUBSCRIPTION = gql`
  ${QUESTION_TYPE}
  subscription surveyQuestionSubscription {
    surveyQuestionSubscription {
      mutation
      question {
        ...QuestionType
      }
    }
  }
`;
