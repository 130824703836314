// @ts-nocheck
import React from "react";
import { Table ,Row,Col,Slider} from "antd";
import styled from "styled-components";
import { displayUtcToUserTimezone,scrolltoview } from "../../look";
import ReactMarkdown from '../../look/components/ReactMarkdown';
import SurveyRangeField from "modules/survey-look/containers/survey-range-filed";
const RenderSurveyAnswer = props => {
  
  const {answers,question,inputType,questionId,conversationId} = props

    const getRangeValues = (choiceSet, type) => {
        let defaultValue = 0,
          marks = {},
          step = 1;
        const value = choiceSet[0]?.value.split(':') || [];
        // min:max:jump:default
        if (type === 'RANGE') {
          marks[value[0]] = value[0];
          marks[value[1]] = value[1];
          step = value[2] === '0' ? '1' : value[2];
          defaultValue = value[3];
        }
       
        return {  min: Number(value[0]), max: Number(value[1]) };
    };  

  const RenderRange = (value,index)=>{
    const { min, max  } = getRangeValues(
        question?.choiceSet?.edges?.map(({ node }) => node),
        inputType
      );
      return (
        <Col lg={{ span: 18 }}  md={{ span: 20 }} xs={{ span: 24 }} sm={{ span: 24 }}>
        <SurveyRangeField
        style={{width:"100%"}}
        name={`${value}_${index}_${questionId}_${conversationId}`}
        min={min}
        max={max}
        hideMarker={true}
        id={question?.id}
        onChange={()=>scrolltoview(question?.id)}
        defaultValue={value}
        trackStyle={{ backgroundColor: '#B0CB1F' }}
        handleStyle={{ backgroundColor: '#B0CB1F' }}
        disabled={true}
         />
        </Col>

      )
  }
    
  const Render=() =>{
    return (
        <>
        <h4 className="grow-question-title"><ReactMarkdown>{question?.questionText}</ReactMarkdown></h4>
        <Col>
        {
          (inputType !=="RANGE" && inputType !=="DATE" && inputType !=="DATETIME") && (
            
                
            answers?.length?
            answers?.length===1?
                answers?.map(item=>
                  <h4 className="grow-answer-title"><ReactMarkdown>{item}</ReactMarkdown></h4>
                ):
                answers?.map((item,index)=>
                <Row>
                    <h4 className="grow-answer-title" style={{marginRight:"10px"}}>{index+1} .</h4>  <ReactMarkdown className="grow-answer-title" id={question?.id} onClick={()=>scrolltoview(question?.id)}>{item}</ReactMarkdown>
                </Row>
                )
            :
            null
                
            
            
          )
        }
        {
          inputType ==="DATE"  && (
            answers?.map((item,index)=>
            <h4 className="grow-answer-title" style={{fontWeight:600}}><ReactMarkdown id={question?.id} onClick={()=>scrolltoview(question?.id)}>{displayUtcToUserTimezone(item, "DD MMM YYYY")}</ReactMarkdown></h4>
            )
          )
        }
        {
          inputType ==="DATETIME"  && (
            answers?.map((item,index)=>
            <h4 className="grow-answer-title" style={{fontWeight:600}}><ReactMarkdown id={question?.id} onClick={()=>scrolltoview(question?.id)}>{displayUtcToUserTimezone(item, "DD MMM YYYY hh:mm")}</ReactMarkdown></h4>
            )
          )
        }
        {
          inputType ==="RANGE"  && (
            answers?.map((item,index)=>
            RenderRange(item,index)
            )
          )
        }
       
        </Col>
        </>
    )
  }

  return (
    <>
        {Render()}
    </>
  );
};

export default RenderSurveyAnswer
