import React from "react";
import { Modal,Input,Button } from 'antd'
import '../css/parametric-group.css'
import '../css/create-scape.css'

const AddParametricGrupView = ( props ) => {
    const { view_grop_popup, title, onSubmit,onCancel,create_response,selected_group } = props
    const [name,setName]=React.useState("")
    const [default_value,setDefaultValue] =React.useState("")

    React.useEffect(()=>{
        if(selected_group!==undefined) {
            setDefaultValue(selected_group?.name)
        }
    },[selected_group])
    React.useEffect(()=>{
        if(create_response!==undefined ){
            setDefaultValue("")
            setName("")
        }
    },[create_response ])
    const changeName =(event)=>{
        if(event.target.value){
            setName(event.target.value)
            setDefaultValue(event.target.value)
        }
        else{
            setName("")
            setDefaultValue("")
        }
    }
    const createGroup=(type)=>{
        onSubmit({group_name:name,save_type:type})
    }
    return (
        <Modal
            visible={view_grop_popup}
            footer={null}
            centered
            destroyOnClose={true}
            closable={false}
            className="sa-gropup-modal">
            <div className="sa-root">
                <div className="sa-parametric-popup-container">
                    <h4 className='sa-parametric-title'>{selected_group!==undefined?'Edit Group':'Create Group'}</h4>
                    <div className='sa-input-container'>
                    <h4 className='sa-create-title'><span className='rd-input-star'>*</span>Group Name</h4>
                    <Input className="sa-text-input" value={default_value} placeholder='Enter Group Name' onChange={(e)=>changeName(e)}/>
                    <div className='sa-confirmation-action-container'>
                            <Button className='sa-global-submit-btn' disabled={name===""} onClick={()=>createGroup("save_close")}>Save</Button>
                          {selected_group===undefined&&<Button className='sa-global-submit-btn' disabled={name===""} onClick={()=>createGroup("save_continnue")}>Save & Add Another</Button>}
                            <Button className='sa-global-cancel-btn' onClick={() => onCancel()}>Cancel</Button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default AddParametricGrupView