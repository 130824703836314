// @ts-nocheck
import React from 'react';
import { compose } from '../../core';
import { withCreateContributor, withCreateOKR } from './objective-keyresult-operations';
import { withApollo } from '@apollo/client/react/hoc';
import { OKR_CHILD_LIST_QUERY, OKR_CHILD_SUBSCRIPTION } from '../graphql/ChildOKRList.gql';
import SubMileStoneDetailTableView from '../components/submilestonDetailTableView';
import moment from 'moment';
import { BADGE_AWARD_ADD_PERMISSION } from 'config';
import { globalPermissionValidator, permissionValidation } from 'modules/look';
import { kudos_permission } from 'Permissions/kudos.permission';
const SubMileStoneDetailTableContainer = props => {
  const {me,client,MilestoneDetail,userPermission}=props
  const [loading,setloading]= React.useState(true)
  const [allsubMilestone,setallsubMilestone]= React.useState()
  const [permission,setpermission]= React.useState()
  const dataRef = React.useRef()
  const m = React.useRef( true )

  let contributorsSub = undefined
  // React.useEffect( () => {
  //   return () => {
  //     if ( contributorsSub ) {
  //       contributorsSub.unsubscribe();
  //     }
  //   }
  // })

  React.useEffect( () => {
    return () => {
      m.current = false
    }
  }, [] )

  React.useEffect( () => {
    m.current = true

  }, [] )

  React.useEffect(()=>{
    if(userPermission?.length){
      let permission=globalPermissionValidator(kudos_permission,userPermission)
      setpermission(permission)
    }
    else{
      setpermission(null)
    }
  },[userPermission])

  const getMilestoneList =async(filter)=>{
    setloading(true)
    const { data } = await client.query({
      query:OKR_CHILD_LIST_QUERY,
      fetchPolicy: 'network-only',
      variables: {okr_Parent:MilestoneDetail?.id,createdBy:me?.employee?.id,...filter,tz:moment.tz.guess()} 
    });

    if(data){
        setallsubMilestone(data?.contributors)
        dataRef.current =data?.contributors
        contributorsSub = await client.subscribe( {
          query: OKR_CHILD_SUBSCRIPTION,
          variables: {okr_Parent:MilestoneDetail?.id,createdBy:me?.employee?.id,...filter}, 
          fetchPolicy: 'network-only'
        } ).subscribe( {
          next( result ) {
            let contributorsCacheData = dataRef.current
            if(!result?.data?.contributorSubscription?.contributor?.okrType && result?.data?.contributorSubscription?.contributor?.role ==="OWNER" && result?.data?.contributorSubscription?.contributor?.okr?.parent?.id===MilestoneDetail?.id){
               switch ( result.data.contributorSubscription.mutation ) {
              case 'CREATE':
                const create = contributorsCacheData?.edges?.filter(item =>
                  item.id === result.data?.contributorSubscription?.contributor?.id
                      ? false
                      : true).concat([{node:result?.data?.contributorSubscription?.contributor}])
                if ( m.current ) {
                 
                  if(create){
                    setallsubMilestone({edges:create})
                    dataRef.current =  {edges:create}
                  }
                  
                }
                break
              case 'UPDATE':
                if ( m.current ) {
                  const Update =contributorsCacheData?.edges?.map( item =>
                      item?.node?.id === result?.data?.contributorSubscription.contributor?.id
                        ? result.data.contributorSubscription.contributor
                        : item
                    ) 
                    if(Update){
                        setallsubMilestone({edges:Update})
                      dataRef.current = {edges:Update}
                    }
                    
                }
                break
                case 'DELETE':
                  if ( m.current ) {
                    const deleted=contributorsCacheData?.edges?.filter( item =>
                        item?.node?.id === result?.data?.contributorSubscription.contributor?.id
                          ? false
                          : true
                      ) 
          
                      if(deleted){
                        setallsubMilestone({edges:deleted})
                        dataRef.current = {edges:deleted}
                      }
                     
                  }
                  break
                default:
                  break
            }
          }
          }})
    }
  
}

React.useEffect(()=>{
  if(me && MilestoneDetail){
    getMilestoneList()
  }
},[me,MilestoneDetail])
  return (
    <>
    <SubMileStoneDetailTableView  allsubMilestone={allsubMilestone} loading={loading} setloading={(e)=>setloading(e)} {...props}permission={permission} />
    </>
  );
};

export default  compose(withApollo,withCreateOKR,withCreateContributor)(SubMileStoneDetailTableContainer);
