import gql from 'graphql-tag';

export const LOOK_TEXT_SECTION_STATE_QUERY = gql`
  query lookTextSectionState {
    lookTextSectionState @client {
      filter {
        note_Icontains
      }
      orderBy
    }
  }
`;
