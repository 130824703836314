import React, { useState } from 'react';
import { compose } from '../../core';
import { withApollo } from '@apollo/client/react/hoc';
import DiscardRequestView from '../components/DiscardView';
import { PENDING_COUNTS } from '../graphql/TabPendingCounts.gql';
import { globalPermissionValidator } from 'modules/look';
import { okr_permission } from 'Permissions/okr.permission';
import NoPermissionView from 'modules/kudos-user/component/NoPermissionComponent';
const OkrDiscardContainer = (props) => {

  const { me, client, location, userPermission, permissionLoader } = props

  const [discardCount, setDiscardCount] = React.useState()
  const [AssignmentCount, setAssignmentCount] = React.useState()
  const [CollaborationCount, setCollaborationCount] = React.useState()
  const [okrPermitted, setOkrPermitted] = React.useState({});



  const PendingCount = async (filterData) => {
    let okrids = []
    const { data } = await client.query({
      query: PENDING_COUNTS,
      variables: filterData,
      fetchPolicy: 'network-only'
    });
    if (data) {
      let mergeDataForCeo = data?.discardCount?.employee?.orgCeoEmployee?.edges?.map(({ node }) => node?.employees?.edges?.map(({ node }) => node?.initiatedDiscardRequestSet))
      let mergeDataForTeam = data?.discardCount?.employee?.teamManagerEmployee?.edges?.map(({ node }) => node?.employees?.edges?.map(({ node }) => node?.initiatedDiscardRequestSet))
      let mergeDataForVertical = data?.discardCount?.employee?.verticalHeadEmployee?.edges?.map(({ node }) => node?.employees?.edges?.map(({ node }) => node?.initiatedDiscardRequestSet))
      let finalDataMerge = [].concat.apply([], [...mergeDataForCeo, ...mergeDataForTeam, ...mergeDataForVertical])
      var setObj = new Set();
      let finalEdges = [].concat.apply([], [...finalDataMerge?.map((item) => item?.edges?.map(({ node }) => node))])
      var setObj = new Set();
      let result = finalEdges.reduce((acc, item) => {
        if (!setObj.has(item?.okr?.id)) {
          setObj.add(item?.okr?.id)
          acc.push(item)
        }
        return acc;
      }, [])
      setDiscardCount(result?.length)
      setCollaborationCount(data?.CollabCount?.totalCount)
      setAssignmentCount(data?.AssigneCount?.totalCount)
    }
  }

  React.useEffect(() => {
    if (userPermission && !permissionLoader) {
      let okrPermission = globalPermissionValidator(okr_permission, userPermission)
      setOkrPermitted(okrPermission);
    }
  }, [userPermission, permissionLoader])


  React.useEffect(() => {
    if (me && okrPermitted && !permissionLoader) {
      PendingCount({ employee: me?.employee?.id })
    }
    else {
    }
  }, [me, location, okrPermitted, permissionLoader])


  return (
    <div>
      {
        !permissionLoader ?
          <div>
            {
              (okrPermitted?.requried_permission) ?
                <DiscardRequestView {...props} discardCount={discardCount} AssignmentCount={AssignmentCount} CollaborationCount={CollaborationCount}></DiscardRequestView>
                :
                <NoPermissionView />
            }
          </div>
          :
          null
      }
    </div>

  )
}
export default compose(withApollo)(OkrDiscardContainer)