import NoPermissionView from 'modules/kudos-user/component/NoPermissionComponent';
import React from 'react';

const PermissionContainer = props => {

    return (
        <NoPermissionView {...props} />
    )

}

export default PermissionContainer
