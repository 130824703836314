import React from 'react';

import { AdminLayout } from '../../../../look';
import ROUTES from '../../../route';
import FaqFormComponent from './FaqFormComponent';

const AddFaqView = props => {
  return (
    <>
      <AdminLayout
        table={false}
        active={ROUTES.faq}
        title="Add FAQ"
        FormComponent={<FaqFormComponent {...props} />}
      ></AdminLayout>
    </>
  );
};

export default AddFaqView;
