import React from 'react';
import { MailOutlined } from '@ant-design/icons';

import AboutUs from './containers/AboutUs';
import FaqPage from './containers/FaqPage';
import Faq from './containers/Faq';
import TermsAndCondition from './containers/TermsAndCondition';
import Contact from './containers/Contact';
import Links from './containers/Links';
import NotFound from './containers/NotFound';
import ROUTE from './route';
import FaqQuestion from './containers/FaqQuestion';
import AddFaq from './containers/faqPages/AddFaq';
import EditFaq from './containers/faqPages/EditFaq';
import AddFaqQuestion from './containers/faqPages/AddFaqQuestion';
import EditFaqQuestion from './containers/faqPages/EditFaqQuestion';
import SoulContainer from './containers/SoulContainer';
import GetInTouchContainer from '../sales/containers/GetInTouchContainer';
import soulUserReportContainer from './containers/soul-user-report-container';
import SoulReportHomeContainer from './containers/soul-report-home-container';



export default [
  {
    name: 'Contact',
    path: ROUTE.contact,
    component: Contact,
    icon: <MailOutlined />,
    admin: true
  },
  {
    name: 'Faq',
    path: ROUTE.faq,
    component: Faq
  },
  {
    name: 'AboutUs',
    path: ROUTE.about,
    component: AboutUs
  },
  {
    name: 'EditFaqQuestion',
    path: ROUTE.editFaqQuestion,
    component: EditFaqQuestion,
    admin: true
  },
  {
    name: 'EditFaq',
    path: ROUTE.editFaq,
    component: EditFaq,
    admin: true
  },
  {
    name: 'AddFaqQuestion',
    path: ROUTE.addFaqQuestion,
    component: AddFaqQuestion,
    admin: true
  },
  {
    name: 'AddFaq',
    path: ROUTE.addFaq,
    component: AddFaq,
    admin: true
  },
  {
    name: 'FaqPage',
    path: ROUTE.faqPage,
    component: FaqPage,
    protect: false
  },
  {
    name: 'HomePage',
    path: ROUTE.home,
    component: SoulContainer,
    exact: true,
    protect: true
  },
  {
    name: 'FaqQuestion',
    path: ROUTE.faqQuestion,
    component: FaqQuestion,
    admin: true
  },
  {
    name: 'TermsAndCondition',
    path: ROUTE.termsAndCondition,
    component: TermsAndCondition
  },
  {
    name: 'ContactUs',
    path: ROUTE.contactUs,
    component: GetInTouchContainer
  },

  {
    name: 'Links',
    path: ROUTE.links,
    component: Links
  },
  {
    name: 'Soul Report',
    path: ROUTE.soul_report,
    component: SoulReportHomeContainer,
    exact: true,
    protect: true
  },
  {
    name: 'NotFound',
    component: NotFound
  },
  
];
