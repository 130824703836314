import gql from 'graphql-tag';
import { SIMPLE_FIELD_CHOICE_TYPE } from './SimpleFieldChoiceType.gql';

export const EDIT_SIMPLE_FIELD_CHOICE = gql`
  ${SIMPLE_FIELD_CHOICE_TYPE}
  mutation editSimpleFieldChoice($graphic: String, $id: ID!, $label: String!, $sequence: Int, $value: String) {
    updateSimpleFieldChoice(graphic: $graphic, id: $id, label: $label, sequence: $sequence, value: $value) {
      simpleFieldChoice {
        ...SimpleFieldChoiceType
      }
    }
  }
`;
