import { feedback_permission } from "./feedback.permission";
import { okr_permission } from "./okr.permission";
import { runpod_permission } from "./runpod.permission";
import { soultip_permission } from "./soul-tip.permission";

export const oneonone_permission= {
    requried_permission:['view_meeting', 'view_meetingnotes', 'view_actionitem', 'view_meetingfeedback', 
        'add_meeting', 'add_meetingnotes', 'add_actionitem', 'add_meetingfeedback',
        'change_meeting', 'change_meetingnotes', 'change_actionitem', 'change_meetingfeedback', 
        'delete_meeting', 'delete_meetingnotes', 'delete_actionitem', 'delete_meetingfeedback' ],
    feedback_permission:feedback_permission.requried_permission,
    okr_permission:okr_permission.requried_permission,
    kudos_permission:feedback_permission.kudos_permission,
    soul_tip_permission:soultip_permission.required_permission,
    user_report_permission:['view_response',"view_impactnarrative"],
    runpod_permission:runpod_permission?.required_permission
}