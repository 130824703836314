import gql from 'graphql-tag';

export const TOOL_STATE_QUERY = gql`
  query toolState {
    toolState @client {
      pagination {
        first
        offset
      }
      orderBy
      filter {
        title_Icontains
        description_Icontains
      }
    }
  }
`;
