import gql from "graphql-tag";



export const EDIT_ACTION_ITEM = gql`
mutation newUpdateActionItem($id:ID!,$details:String,$status:String){
    newUpdateActionItem(id:$id,details:$details,status:$status){
          actionItem{
            id
            details
            status
          }
    }
}

`

export const CREATE_CONVERSATION_MUTATION = gql`
  mutation createCoachingConversation($answers: [AnswerInput]!,$createCoachingData: CoachingConversationInput!,$surveyId: Int!) {
    createCoachingConversation(
        createCoachingData:$createCoachingData
        answers:$answers,
        surveyId:$surveyId
        ) {
        createCoaching {
         id
      }
    }
  }
`;
