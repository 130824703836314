import React from 'react';
import { Spin,message } from 'antd';

import { compose } from '../../core';
import { ButtonsCatch, getIntFromString } from '../../look';

import ROUTE from '../route';
import AddEmployeeView from '../components/AddEmployeeView';

import { withAddEmployee, withOrganizationState } from './OrganizationOperation';

const AddEmployee = props => {
  const { history, addEmployee } = props;
  const [submitLoading, setSubmitLoading] = React.useState(false);

  const handleSubmit = async (values, item) => {
    console.log("values",values)
    setSubmitLoading(true);
    if (!Array.isArray(values.AddEmployee)) {
      try {
        const response = await addEmployee({ employeeData: { userId: values.AddEmployee } });
        response && window.location.href.includes('closeonsave') && window.close();
        response &&
          ButtonsCatch(
            item,
            ROUTE.employee,
            history,
            item === undefined ? `${ROUTE.editEmployeeLink}${getIntFromString(response.id)}` : window.location.pathname
          );
      } catch (e) {
        message.error(e.message?.includes('Duplicate entry')?'User Already exist in this organization':'Failed to Add user');
        setSubmitLoading(false)
        // throw new Error(e.message);
      }
    } else {
      await Promise.all(
        values?.AddEmployee?.map(async id => {
          try {
            const response = await addEmployee({ employeeData: { userId: id } });
            response && window.location.href.includes('closeonsave') && window.close();
          } catch (e) {
            message.error(e.message?.includes('Duplicate entry')?'User Already exist in this organization':'Failed to Add user');
            // throw new Error(e.message);
            setSubmitLoading(false);
          }
        })
      );
      ButtonsCatch(item, ROUTE.employee, history, item === undefined ? `${ROUTE.employee}` : window.location.pathname);
    }

    setSubmitLoading(false);
  };

  return (
    <Spin spinning={submitLoading} size="large">
      <AddEmployeeView onSubmit={handleSubmit} {...props} />
    </Spin>
  );
};

export default compose(withOrganizationState, withAddEmployee)(AddEmployee);
