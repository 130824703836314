import React from 'react';

import { compose } from '../../core';
import { withMe } from '../../user/containers/UserOperations';
import {
  withAllOrganizationEmployees,
  withOrganizationState,
  withOrganizationFilterUpdating
} from '../../organization/containers/OrganizationOperation';

import EmployeeesRenderView from '../components/EmployeeesRenderView';

const OrganizationEmployeesRender = props => {
  const { allOrganizationEmployees } = props;
  return <EmployeeesRenderView {...props} data={allOrganizationEmployees} />;
};

export default compose(
  withOrganizationState,
  withMe,
  withAllOrganizationEmployees,
  withOrganizationFilterUpdating
)(OrganizationEmployeesRender);
