// @ts-nocheck

import React from 'react'
import { Carousel,Tooltip, Spin, Tabs } from 'antd';
import { getIntFromString } from 'modules/look';
import styled from 'styled-components';
import white_ball from '../../assets/white_ball.svg'

const DashboardHeader = (props) => {

    const {SelectedTab, onTabChange, teams, verticals, individualOverallProgress,OkrProgress, organisations, currentTabRef } = props


    const [showTab, setshowTab] = React.useState(false);
    const timerRef = React.useRef(null);
    let TabRef = React.useRef({
        Team: 0,
        Vertical: 0,
        Organization: 0
    })


    React.useEffect(() => {

        if (!showTab && organisations && teams && verticals) {
            let cachedTab = localStorage.getItem('dashboard_tab')
            
            if(cachedTab){
                if(cachedTab==='Team' && teams?.length){
                    onTabChange({ tab: 'Team', detail: teams[0] })
                }
                else if(cachedTab==='Vertical' && verticals?.length){
                    onTabChange({ tab: 'Vertical', detail: verticals[0] })
                }
                else if(cachedTab==='Organization' && organisations?.length){
                    onTabChange({ tab: 'Organization', detail: organisations[0] })
                }else{
                    onTabChange({ tab: 'Individual'})
                }
            }else{
                onTabChange({ tab: 'Individual'})
            }
            setshowTab(true)
        }
    }, [organisations,teams,verticals])

    const IndividualCard = () => (
        <div className='dashboard-tab-inner-container' style={{ width: "100%" }}>
            <p className='dashboard-tab-title' style={{paddingTop:'-10px !important'}}>My OKRs <span><img alt='' src={white_ball} /></span></p>
            <p className='dashboard-tab-content'>{individualOverallProgress}%</p>
           <div style={{width:"20px",height:"23px"}}>

           </div>
        </div>
    )

    const onTeamChange = (currentSlide) => {
        // if (timer) {
            
            clearTimeout(timerRef?.current);
            let employees = teams[currentSlide]?.managers?.edges?.map((node) =>
            getIntFromString(node?.node?.id)
            )
            if (!teams[currentSlide]?.progress) {
                OkrProgress(employees, 'team', currentSlide, teams);
            }
            timerRef.current = setTimeout(() => {
                
                TabRef.current.Team = currentSlide
                onTabChange({ tab: 'Team', detail: teams[currentSlide] })
                
                currentTabRef(TabRef.current)
            }, 2000);
      

    };

    const onVerticalChange = (currentSlide) => {
      
            
            clearTimeout(timerRef.current);
            let employees = verticals[currentSlide]?.heads?.edges?.map((node) =>
                getIntFromString(node?.node?.id)
            )
            if (!verticals[currentSlide]?.progress) {
                OkrProgress(employees, 'vertical', currentSlide, verticals)
            }
            timerRef.current = setTimeout(() => {
                
                TabRef.current.Vertical = currentSlide
                onTabChange({ tab: 'Vertical', detail: verticals[currentSlide] })
                
               
                currentTabRef(TabRef.current)
            }, 2000);
        
       

    };

    const onOrgChange = (currentSlide) => {

            clearTimeout(timerRef.current);
            let employees = [getIntFromString(organisations[currentSlide]?.ceo?.id)]
            if (!organisations[currentSlide]?.progress) {
                OkrProgress(employees, 'org', currentSlide, organisations)
            }
            timerRef.current = setTimeout(() => {
                
                TabRef.current.Organization = currentSlide
                onTabChange({ tab: 'Organization', detail: organisations[currentSlide] })
              
                currentTabRef(TabRef.current)
            }, 2000);
        
       
    };

    const Team = () => (
        <TabContainer >

            <Carousel
                className='dashboard-tab'
                afterChange={(e) => onTeamChange(e)}
                arrows
                effect={false}
            >

                {teams?.map((team,index) => (
                    <div className='dashboard-tab-inner-container' key={index}>
                        <p className='dashboard-tab-title'>Team</p>
                        {
                            team?.progress ?
                                <p className='dashboard-tab-content'>{team?.progress}%</p>
                                :
                                <p className='dashboard-tab-content' align="center" style={{ padding: "0px"}}>
                                    <Spin spinning={!team?.progress} className={SelectedTab==='Team'?"":"green-spinner"} tip={<span style={{ fontSize: "15px", marginLeft: '5px',textAlign:'center',color:SelectedTab==='Team'?"#fff":"#D9D9D9",fontWeight:"400" }}>Calculating</span>}>
                                    </Spin>
                                </p>
                        }

                       {SelectedTab==='Team'?<Tooltip title={team?.name}>
                            <p className='dashboard-tab-title'>{team?.name}</p>
                        </Tooltip>:<p className='dashboard-tab-title' style={{color:"#fff"}}>{team?.name}</p>}
                    </div>

                ))}

            </Carousel>

        </TabContainer>

    )

    const vertical = () => (
        <TabContainer >
            <Carousel 
                arrows
                className='dashboard-tab'
                afterChange={(e) => onVerticalChange(e)}
                effect={false}
            >
                {verticals?.map((vertical) => (

                    <div className='dashboard-tab-inner-container' style={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:'center'}}>
                        <p className='dashboard-tab-title'>Vertical</p>
                        {
                            vertical?.progress ?
                                <p className='dashboard-tab-content'>{vertical?.progress}%</p>
                                :
                                <p className='dashboard-tab-content' align="center" style={{ padding: "0px" }}>
                                    <Spin className={SelectedTab==='Vertical'?"":"green-spinner"}  spinning={!vertical?.progress} tip={<span style={{ fontSize: "15px", marginLeft: '5px',textAlign:'center',color:SelectedTab==='Vertical'?"#fff":"#D9D9D9",fontWeight:"400" }}>Calculating</span>}>
                                    </Spin>
                                   
                                </p>
                        }
                        {SelectedTab==='Vertical'?<Tooltip title={vertical?.name}>
                        <p className='dashboard-tab-title'>{vertical?.name}</p>
                        </Tooltip>: <p className='dashboard-tab-title' style={{color:"#fff"}}>{vertical?.name}</p>}
                    </div>
                ))}
            </Carousel>

        </TabContainer>
    )


    const organisation = () => (

        <TabContainer>
            {/* <Row> */}
            <Carousel className='dashboard-tab'
                afterChange={(e) => onOrgChange(e)}
                arrows
                effect={false}
            >

                {organisations?.map((org) => (

                    <div className='dashboard-tab-inner-container'>
                        <p className='dashboard-tab-title'>Organisation</p>
                        {
                            org?.progress ?
                                <p className='dashboard-tab-content'>{org?.progress}%</p>
                                :
                                <p className='dashboard-tab-content' align="center" style={{ padding: "0px" }}>
                                     <Spin className={SelectedTab==='Organization'?"":"green-spinner"} spinning={!org?.progress} tip={<span style={{ fontSize: "15px", marginLeft: '5px',textAlign:'center',color:SelectedTab==='Organization'?"#fff":"#D9D9D9",fontWeight:"400" }}>Calculating</span>}>
                                    </Spin>
                                </p>
                        }
                        {SelectedTab==='Organization'?<Tooltip title={org?.name}>
                        <p className='dashboard-tab-title'>{org?.name}</p>
                        </Tooltip>: <p className='dashboard-tab-title' style={{color:"#fff"}}>{org?.name}</p>}
                    </div>
                ))}

            </Carousel>
            {/* </Row> */}
        </TabContainer>

    )


    const onPanelChange = (e) => {
        // setSelectedTab(e)
        clearTimeout(timerRef.current);
        if (e === 'Team') {
            onTabChange({ tab: e, detail: teams[TabRef.current.Team] })
        } else if (e === 'Vertical') {
            onTabChange({ tab: e, detail: verticals[TabRef.current.Vertical] })
        } else if (e === 'Organization') {
            onTabChange({ tab: e, detail: organisations[TabRef.current.Organization] })
        }
        else {
            onTabChange({ tab: e, detail: null })
        }
    }

    return (

        <TabPanel className='okr-dashboard-tab' style={{ marginTop: '70px' }}>
            {
                showTab ?
                    <Tabs defaultActiveKey={SelectedTab} centered onChange={(e) => { onPanelChange(e) }}>
                        <Tabs.TabPane tab={IndividualCard()} key="Individual">
                        </Tabs.TabPane>
                       {teams?.length&&<Tabs.TabPane tab={Team()} key="Team">
                        </Tabs.TabPane>}
                        {verticals?.length&&<Tabs.TabPane tab={vertical()} key="Vertical">
                        </Tabs.TabPane>}
                        {organisations?.length&&<Tabs.TabPane tab={organisation()} key="Organization">
                        </Tabs.TabPane>}
                    </Tabs>
                    : ""
            }
        </TabPanel>
    )

}



export default DashboardHeader


// <div>


const TabContainer = styled.div`



.ant-carousel .slick-list .slick-slide.slick-active{
    text-align:center;
}

.slick-list{
    margin:0px !important;
}

.slick-dots {
    display:none !important;
}

.slick-arrow{
    
    
} 

.slick-prev::before{
    content: '<';
    display: block;
    position: relative;
    z-index: 11;
    font-size: 25px;
    color:#fff;
    right:0px;
    background-color: transparent;
    border: 3px solid #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding-bottom:5px;
    padding-left:0px
    
} 

.slick-next::before {
    content: '>';
    display: block;
    position: relative;
    z-index: 11;
    font-size: 25px;
    color:#fff;
    right:0px;
    background-color: transparent;
    border: 3px solid #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding-bottom:5px;
    padding-left:2px
  }

  .ant-carousel .slick-prev, .ant-carousel .slick-next{
    top:75px !important
  }
  
  .ant-carousel .slick-prev{
    z-index:1500;
    left:13px;
  }

  .ant-carousel .slick-next{
    right:20px !important
  }
 `;

const TabPanel = styled.div`

.ant-tabs-tab{
    background: #FFFFFF;   
    border: 1px solid #D5E5F2;
}

.ant-tabs-tab-active{
    border: 1px solid #4CCB1F;
    background: #4CCB1F !important;   
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list{
    width:100%;
}

.ant-tabs-tab{
    width:23%;
}

.ant-tabs-tab-active div{
    color:#FFFFFF !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    width:100%;
}

.ant-tabs-tab div{
   color:#D9D9D9;
}
.ant-tabs-nav-operations .ant-tabs-nav-more{
    display:none !important;
}
.ant-tabs-nav-operations .ant-tabs-nav-more .anticon anticon-ellipsis{
    display:none !important;
}
 `;


