import React from "react";
import { Row, Col, Form, Button, Switch } from "antd";

import { TextField, SurveyField, ButtonsComponent, MarkdownField, CKEditorField, SurveyAutoComplete } from "../../look";
import { NODE_ENV } from "../../../config";

export const CKEditorFieldName = "message";

const TokenFormComponent = props => {
  const { onSubmit } = props;
  const [button, setButton] = React.useState("");

  const handleSubmit = values => {
    const data = {
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      mobileNumber: values.mobileNumber,
      invite: Boolean(values.invite),
      message: values.message,
      surveyId: Number(values.surveyId),
      valid: values.valid
    };
    onSubmit(data, button);
  };
  return (
    <Row>
      <Col span={24}>
        <Form layout="vertical" onFinish={handleSubmit} required={false} scrollToFirstError={true}>
          <Row>
            <Col span={10} offset={1}>
              {/* <Row>
                <Col span={24}>
                  <UsersField
                    id={false}
                    name="username"
                    label="User"
                    rules={[{ required: true, message: 'user is required!' }]}
                    // onSelect={setPocId}
                    onAdd={'add'}
                    onEdit={'edit'}
                  />
                </Col>
              </Row> */}
              <Row>
                <Col span={14}>
                  <TextField
                    name="email"
                    label="E-mail"
                    rules={[
                      {
                        type: "email",
                        message: "The input is not valid E-mail!"
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            (value === undefined && getFieldValue("mobileNumber") === undefined) ||
                            (value === "" && getFieldValue("mobileNumber") === "")
                          ) {
                            return Promise.reject(new Error("Either email or mobile number is required!"));
                          }
                          return Promise.resolve();
                        }
                      })
                    ]}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={14}>
                  <TextField
                    name="firstName"
                    label="firstName"
                    rules={[{ required: true, message: "firstName is required!", whitespace: true }]}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={14}>
                  <TextField
                    name="mobileNumber"
                    label="Mobile Number"
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            (value === undefined && getFieldValue("email") === undefined) ||
                            (value === "" && getFieldValue("email") === "")
                          ) {
                            return Promise.reject(new Error("Either email or mobile number is required!"));
                          }
                          return Promise.resolve();
                        }
                      })
                    ]}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={10}>
              <Row>
                <Col span={24}>
                  <SurveyAutoComplete
                    name="surveyId"
                    label="Survey"
                    rules={[{ required: true, message: "survey is required!" }]}
                    onAdd={"add"}
                    onEdit={"edit"}
                    margin={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={14}>
                  <TextField
                    name="lastName"
                    label="lastName"
                    rules={[{ required: true, message: "lastName is required!", whitespace: true }]}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  {/* <SelectField
                    name="invite"
                    label="invited"
                    choices={inviteChoices}
                    // rules={[{ required: true, message: 'All questions are required!' }]}
                  /> */}
                  <Form.Item name="invite" label="Invite" valuePropName="checked">
                    <Switch />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={3} />
            <Col span={22} offset={1}>
              <CKEditorField
                name={CKEditorFieldName}
                label="Message"
                // initialValue={organizationById && organizationById.message}
                // rules={[{ required: true, message: 'All questions are required!' }]}
              />
            </Col>
          </Row>
          {/* <MarkdownField
            name="message"
            label="Message"
            // initialValue={organizationById && organizationById.message}
            // rules={[{ required: true, message: 'All questions are required!' }]}
          /> */}
          <ButtonsComponent edit={true} setButton={setButton} />
        </Form>
      </Col>
    </Row>
  );
};

export default TokenFormComponent;
