export const bars ='bars'
const baseName =`/hr/${bars}`
const create ='/create'
const home ='/home'
const define = '/define'
const subcompetency = '/subcompetency'
const edit = '/edit'
const detail ='/detail'

const bars_route = {
    bars_home:baseName+home ,
    bars_create:baseName+create + '/:competency_id',
    bars_define:baseName+define,
    bars_create_sub_comp:baseName+create+subcompetency + '/:competency_id',
    edit_bars:baseName+edit + '/:bar_id' +  '/:level_id?',
    edit_bars_sub_competency:baseName+ subcompetency + edit + '/:bar_id' +  '/:level_id?',
    bars_detail:baseName + detail + '/:id',
    bars_sub_competency_detail:baseName + subcompetency + detail  + '/:id',
}

export default bars_route