import gql from "graphql-tag";
export const FEEDBACK_SUBSCRIPTION = gql`
subscription feedbackSubscription {
    feedbackSubscription {
        mutation
        feedback {     
            id
            feedbackTo {
                id
                user {
                id
                firstName
                lastName
                }
            }
            response{
              id
                answerSet{
                  edges{
                    node{
                      id
                      answer
                      question{
                        id
                        questionAddress
                        questionText
                      }
                    }
                  }
                }
              }
            feedbackType
        }
    }
}
`