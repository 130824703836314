import gql from 'graphql-tag';

export const ALL_ROLES_LIST = gql`
  query allRoles($title_Icontains:String,$employeeLevel_Name_Icontains: String,$first:Int,$minimum_cursor:String,$maximum_cursor:String,$title: String,$vertical_Name_Icontains:String,$requiredEligibility_Education_Stream: String,$requiredEligibility_QuantitativeExperienceMin: Int,$requiredEligibility_QuantitativeExperienceMax:Int) {
    minimum:allRoles(title_Icontains:$title_Icontains,employeeLevel_Name_Icontains:$employeeLevel_Name_Icontains,first:$first,after:$minimum_cursor,title:$title,vertical_Name_Icontains:$vertical_Name_Icontains,requiredEligibility_Education_Stream:$requiredEligibility_Education_Stream,requiredEligibility_QuantitativeExperienceMin:$requiredEligibility_QuantitativeExperienceMin) {
        pageInfo{
            hasNextPage
            endCursor
        }
      edges {
        node {
            id
            title
            vertical{
                id
                name
            }
            employeeLevel{
                id
                name
            }
        }
      }
    }
    maximum:allRoles(title_Icontains:$title_Icontains,employeeLevel_Name_Icontains:$employeeLevel_Name_Icontains,first:$first,after:$maximum_cursor,title:$title,vertical_Name_Icontains:$vertical_Name_Icontains,requiredEligibility_Education_Stream:$requiredEligibility_Education_Stream,requiredEligibility_QuantitativeExperienceMax:$requiredEligibility_QuantitativeExperienceMax) {
      pageInfo{
          hasNextPage
          endCursor
      }
    edges {
      node {
          id
          title
          vertical{
              id
              name
          }
          employeeLevel{
              id
              name
          }
      }
    }
  }
  }
`;

export const All_ROLE_SUBSCRIPTION = gql`
subscription roleSubscription{
  roleSubscription {
    mutation
    role{
      id
      title
      vertical{
        id
        name
        }
        employeeLevel{
            id
            name
        }
    } 
  }
}
`



export const ROLE_BY_ID_QUERY = gql`
  query roleById($id: ID!) {
    roleById(id: $id) {
        id
        title
        vertical{
            id
            name
        }
        employeeLevel{
             id
            name
        }
        description
        requiredSpecificCompetency{
            edges{
                node{
                    id
                    title
                    description
                    competencyType
                }
            }
            
        }
        optionalSpecificCompetency{
            edges{
                node{
                    id
                    title
                    description
                    competencyType
                }
            }
        }
        responsibility{
           edges{
                node{
                    id
                    title
                    description
                }
            }
        }
        requiredEligibility{
            edges{
                node{
                    id
                    qualitativeExperience
                    quantitativeExperienceMin
                    quantitativeExperienceMax
                    education{
                        edges{
                            node{
                                id
                                stream
                                degree
                                subject
                            }
                        }
                    }
                }
            }
            
        }
        optionalEligibility{
           edges{
                node{
                    id
                    qualitativeExperience
                    quantitativeExperienceMin
                    quantitativeExperienceMax
                    education{
                        edges{
                            node{
                                id
                                stream
                                degree
                                subject
                            }
                        }
                    }
                }
            }
        }
    }
  }
`;



export const DELETE_ROLE = gql`

  mutation deleteRole($id: ID!) {
    deleteRole(id:$id) {
        role{
            id
        }
    }
  }
`;

export const ALL_ROLES_LIST_NAME_FILTER = gql`
  query allRoles($title_Icontains:String) {
    allRoles(title_Icontains:$title_Icontains) {
      edges {
        node {
            id
            title
        }
      }
    }
  }
`;
export const ALL_ROLES_LIST_LEVEL_FILTER = gql`
  query allRoles($employeeLevel_Name_Icontains: String) {
    allRoles(employeeLevel_Name_Icontains:$employeeLevel_Name_Icontains) {
      edges {
        node {
            id
            employeeLevel{
                id
                name
            }
        }
      }
    }
  }
`;
export const ALL_ROLES_LIST_VERTICAL_FILTER = gql`
  query allRoles($vertical_Name_Icontains: String){
    allRoles(vertical_Name_Icontains:$vertical_Name_Icontains) {
      edges {
        node {
            id
            vertical{
                id
                name
            }
        }
      }
    }
  }
`;

export const VERTICAL_LIST_FOR_ROLE= gql`
  query verticals($organization: ID!,$name_Icontains: String,$first:Int) {
    verticals(organization: $organization,name_Icontains:$name_Icontains,first:$first) {
      edges {
        node {
            id
            name
        }
      }
    }
  }
`;

export const RESPONSIBILITY_LIST_FOR_ROLE = gql`
  query allResponsibilities($title_Icontains:String,$first:Int) {
    allResponsibilities(title_Icontains:$title_Icontains,first:$first) {
      edges {
        node {
            id
            title
            description
        }
      }
    }
  }
`;

export const CREATE_ROLE = gql`
  mutation createRole($optionalEligibility: [Int],$optionalSpecificCompetency: [Int],$requiredEligibility: [Int],$requiredSpecificCompetency: [Int],$responsibility: [Int],$roleData: RoleInput!) {
    createRole(
        optionalEligibility:$optionalEligibility
        optionalSpecificCompetency:$optionalSpecificCompetency,
        requiredEligibility:$requiredEligibility,
        requiredSpecificCompetency:$requiredSpecificCompetency,
        responsibility:$responsibility,
        roleData:$roleData
        ) {
        role {
         id
      }
    }
  }
`;

export const UPDATE_ROLE = gql`
  mutation updateRole($optionalEligibility: [Int],$optionalSpecificCompetency: [Int],$requiredEligibility: [Int],$requiredSpecificCompetency: [Int],$responsibility: [Int],$description:String,$employeeLevelId: Int!,$verticalId: Int,$id: ID!,$title: String!) {
    updateRole(
        optionalEligibility:$optionalEligibility
        optionalSpecificCompetency:$optionalSpecificCompetency,
        requiredEligibility:$requiredEligibility,
        requiredSpecificCompetency:$requiredSpecificCompetency,
        responsibility:$responsibility,
        description:$description,
        employeeLevelId:$employeeLevelId,
        verticalId:$verticalId,
        id:$id
        title:$title
        ) {
        role {
         id
      }
    }
  }
`;

export const CREATE_RESPONSILBILITY = gql`
  mutation createResponsibility($responsibilityData: ResponsibilityInput! ) {
    createResponsibility(responsibilityData: $responsibilityData){
        responsibility {
            id
            title
            description
        }
    }
  }
`;

export const ALL_EDUCATIONS_LIST = gql`
  query allEducations($first:Int,$stream_Icontains:String) {
    allEducations(first:$first,stream_Icontains:$stream_Icontains) {
      edges {
        node {
            id
            stream
            degree
            subject
        }
      }
    }
  }
`;

export const CREATE_ELIGIBILITY = gql`
  mutation createEligibility($education: [Int]!, $eligibilityData: EligibilityInput! ) {
    createEligibility(education: $education, eligibilityData: $eligibilityData){
        eligibility {
            id
        }
    }
  }
`;


export const UPDATE_ELIGIBILITY = gql`
  mutation updateEligibility($education: [Int]!, $qualitativeExperience: String,$id: ID!,$quantitativeExperience:EligibilityExperienceInput ) {
    updateEligibility(education: $education, qualitativeExperience: $qualitativeExperience,id:$id,quantitativeExperience:$quantitativeExperience){
        eligibility {
            id
        }
    }
  }
`;

export const CREATE_EDUCATION = gql`
mutation createEducation($educationData: EducationInput!){
  createEducation(educationData: $educationData){
    education{
      id
      stream
      degree
      subject
    }
  }
}
`