import React from 'react';
import { Row, Col, Form } from 'antd';

import { TextField, CheckBoxField, ButtonsComponent } from '../../../../look';

const FaqFormComponent = props => {
  const { onSubmit, faqCatagoryById } = props;
  const [button, setButton] = React.useState('');

  const initialValues = {
    name: faqCatagoryById && faqCatagoryById.name && faqCatagoryById.name,
    description: faqCatagoryById && faqCatagoryById && faqCatagoryById.description,
    active: (faqCatagoryById && faqCatagoryById.active) || false
  };
  return (
    <Row>
      <Col span={24}>
        <Form
          layout="vertical"
          onFinish={values => onSubmit(values, button)}
          initialValues={initialValues}
          required={false}
          scrollToFirstError={true}
        >
          <Row>
            <Col span={10} offset={1}>
              <Row>
                <Col lg={14} md={20}>
                  <TextField name="name" label="Name" rules={[{ required: true, message: 'Name is required!' }]} />
                </Col>
              </Row>

              <Row>
                <Col span={14}>
                  <TextField
                    type="textarea"
                    name="description"
                    label="Description"
                    // rules={[{ required: true, message: 'All questions are required!' }]}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <CheckBoxField name="active" label="Active" />
                </Col>
              </Row>
            </Col>
            <Col span={10}></Col>
          </Row>
          <ButtonsComponent setButton={setButton} />
        </Form>
      </Col>
    </Row>
  );
};

export default FaqFormComponent;
