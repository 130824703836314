 import React from 'react';
import UserDasboardView from '../components/userDashboardView';
import { getIntFromString, getSurveyBase64StringFromInt, globalPermissionValidator } from 'modules/look';
import { user_dashboard_permissions } from 'Permissions/userDashboard.permission';
import { Spin } from 'antd'
import { compose } from 'modules/core';
import { AI_SOUL_TIP, SOUL_AI_TIP_FOR_OWNER, SURVEY_CHECK } from '../graphql/userDashboardQueries.gql';
import { withApollo } from '@apollo/client/react/hoc';
import { SURVEY_DIVERSITY_PROFILE, SURVEY_WHOPOSHORO } from '../../../config';

const UserDasboardContainer = (props) => {
    const { userPermission, me, client } = props

    const [user_permission, setUserPermissions] = React.useState()
    const [dashboard_loading, setDashboardLoading] = React.useState(false)
    const [taken_survey, setTakenSurvey] = React.useState()



    React.useEffect(() => {
        if (me && userPermission?.length) {
            setDashboardLoading(true)
            let permission = globalPermissionValidator(
                user_dashboard_permissions,
                userPermission);
            setUserPermissions(permission)
            setDashboardLoading(false)

        }
    }, [me, userPermission])

    React.useEffect(() => {
        if (user_permission) {
        
            if (user_permission?.survey_permission) {
                checkSurvey({ diversitysurvey: getSurveyBase64StringFromInt(SURVEY_DIVERSITY_PROFILE), whoposhorosurvey: getSurveyBase64StringFromInt(SURVEY_WHOPOSHORO) })
            }

        }
    }, [user_permission])

  
    const checkSurvey = async (filter) => {
        const { data } = await client.query({
            query: SURVEY_CHECK,
            variables: { ...filter },
            fetchPolicy: "network-only"
        });
        if (data) {
            setTakenSurvey({
                diversity: data?.diversity?.responseSet?.totalCount,
                whoposhoro: data?.whoposhoro?.responseSet?.totalCount
            })

        }
    }

  


    return (
        <>
            {user_permission ? <UserDasboardView user_permission={user_permission} dashboard_loading={dashboard_loading}  taken_survey={taken_survey}{...props} /> :
                <Spin spinning={true} size='large'><div style={{ width: "100%", height: "80vh" }}></div></Spin>}
        </>
    )
}

export default compose(withApollo)(UserDasboardContainer)