import gql from 'graphql-tag';
import { CUSTOM_USER_COMMITMENT_TYPE } from './CustomUserCommitmentType.gql';

export const DELETE_CUSTOM_USER_COMMITMENT = gql`
  ${CUSTOM_USER_COMMITMENT_TYPE}
  mutation deleteCustomUserCommitment($id: ID!) {
    deleteCustomUserCommitment(id: $id) {
      customUserCommitment {
        ...CustomUserCommitmentType
      }
    }
  }
`;
