import gql from 'graphql-tag';
import { RESPONSE_TYPE } from './ResponseType.gql';
export const UNAUTH_SUBMIT_TOKEN_RESPONSE_MUTATION = gql`
  mutation submitTokenResponse($token: String!, $answers: [AnswerInput]!, $surveyId: Int!, $totalDuration: Int) {
    submitTokenResponse(token: $token, answers: $answers, surveyId: $surveyId, totalDuration: $totalDuration) {
      response {
        id
        survey {
          id
        }
      }
    }
  }
`;