import React from 'react';
import IDPSuggessionandRejectView from '../components/idpSuggessionandRejectView';

const IDPSuggessionandRejectContainer =(props)=>{
    return (
        <>
        <IDPSuggessionandRejectView {...props}/>
        </>
    )
}

export default IDPSuggessionandRejectContainer