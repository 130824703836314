import React from "react";
import SoulReportHomeView from "../components/soul-report-home-view";


const SoulReportHomeContainer =(props)=>{

    return(
        <>
        <SoulReportHomeView {...props}/>
        </>
    )
}

export default SoulReportHomeContainer;