import React from 'react'
import AssesssmentMemberView from '../components/assessmentMemeberView'
import { getIntFromString } from 'modules/look';
import { SURVEY_RESPONSE_FOR_ASSESSMENT } from 'modules/home/graphql/teamDashboardQueries.gql';
import { SURVEY_RESPONSE_FOR_360 } from 'modules/home/graphql/userDashboardQueries.gql';
import { useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { message } from 'antd';

const AssessmentMemberContainer = (props) => {
    const { view_member, team_member_list, client, me } = props
    const [user_survey_response, SetUserSurveyResponse] = React.useState()
    const [selected_employees, setSelectedEmployee] = React.useState([])
    const [reminder_data, SetRemainderData] = React.useState()
    const [query_loader, SetQueryLoader] = React.useState(false)
    
    const ALL_REMINDER = gql`
    query remindUserSurveyById {
      ${reminder_data?.map(
        (item,index) =>
            `query_${index}: remindUserSurveyById(id:${item?.id},usr:${item?.usr},tlink:"${item?.tlink}")`
    )
        .join('')}
    }
`;

    const [emailReminder] = useLazyQuery(ALL_REMINDER, { onCompleted: (data) => { onCompletedReminder(data) }, onError: (data) => { onErrorReminder(data) } });

    React.useEffect(() => {
        if (reminder_data?.length) {
            SetQueryLoader(true)
            emailReminder()
        }
    }, [reminder_data])

    const onCompletedReminder = (data) => {
        message.success('Email reminders has been send')
        setSelectedEmployee([])
        SetQueryLoader(false)
    }

    const onErrorReminder = (data) => {
        message.error('Failed to send email reminders')
        SetQueryLoader(false)
    }

    React.useEffect(() => {
        if (view_member && team_member_list?.length && me) {
            let survey_In = [getIntFromString(view_member?.id)]
            let user_In = team_member_list?.map(i => getIntFromString(i?.user?.id))
            getResponseData({ survey_In, user_In, createdBy: me?.employee?.user?.id, survey: view_member?.id })
        }
    }, [view_member, team_member_list, me])

    React.useEffect(() => {
        if (user_survey_response) {
            SetQueryLoader(false)
        }
    }, [user_survey_response])

    const getResponseData = async (filter) => {
        SetQueryLoader(true)
        const { data } = await client.query({
            query: SURVEY_RESPONSE_FOR_ASSESSMENT,
            fetchPolicy: 'network-only',
            variables: filter
        });
        if (data) {
            let res = data?.survey_response?.edges?.map(({ node }) => node)
            let list = team_member_list?.map(i => {
                let responses = res?.filter(r => r?.user?.id == i?.user?.id)?.sort((a, b) => new Date(b?.responseDate) - new Date(a?.responseDate))
                return {
                    ...i,
                    is_360: false,
                    survey_response: responses?.length ? responses[0] : null
                }
            })
            let response_360 = data?.response_360?.edges?.map(({ node }) => node)
            if (response_360?.length) {
                user360Response(response_360, list)
            } else {
                SetUserSurveyResponse(list)
            }

        }
    }

    const user360Response = async (list_360, res_normal) => {
        let user_In = list_360?.map(i => getIntFromString(i?.user?.id))
        let survey_In = [getIntFromString(view_member?.id)]
        let responseSourceFor_In = list_360?.map(i => getIntFromString(i?.responseSourceFor?.id))
        const { data } = await client.query({
            query: SURVEY_RESPONSE_FOR_360,
            fetchPolicy: 'network-only',
            variables: { user_In, survey_In, responseSourceFor_In }
        });
        if (data?.surveyResponses) {
            let res = data?.surveyResponses?.edges?.map(({ node }) => node)

            let token_list = list_360?.map(i => {
                let survey_response = res?.find(r => r?.user?.id == i?.user?.id && r?.responseSourceFor?.id == i?.responseSourceFor?.id)
                return {
                    ...i,
                    is_360: true,
                    survey_response
                }
            })

            SetUserSurveyResponse(res_normal?.concat(token_list))

        }

    }
    return (
        <>

            <AssesssmentMemberView 
            {...props} 
            user_survey_response={user_survey_response} 
            SetRemainderData={SetRemainderData} 
            query_loader={query_loader}
            selected_employees={selected_employees} 
            setSelectedEmployee={setSelectedEmployee}
            />

        </>
    )
}

export default AssessmentMemberContainer