// @ts-nocheck
import { graphql } from '@apollo/client/react/hoc';
import _ from 'lodash';
import { READINESS_DETAIL } from '../graphql/ReadinessDetail.gql'
import { EMPLOYEE_LIST } from '../graphql/EmployeeList.gql';
import { SURVEY_BY_ID } from '../graphql/SurvayById.gql';
import { ADD_REDINESS } from '../graphql/AddReadinessLevel.gql';
import { DELETE_REDINESS } from '../graphql/DeleteReadinessLevel.gql';
import { UPDATE_MUTATION } from '../graphql/updateMutation.gql';
import { CREATE_READINESS_LEVEL } from '../graphql/CreateReadinessLevel.gql'
import { message } from "antd"
import { DELETE_REDINESS_BY_LEVEL } from '../graphql/DeleteReadinessByLevel.gql';
import { SURVEY_READINESS_LEVEL } from "../../../config"
import { getReadinessBase64StringFromInt, getSurveyBase64StringFromInt } from '../../look/components/functions';
import { CREATE_COMPETENCY_SKILL } from '../graphql/AddSkill.gql'
import { EMPLOYEE_LIST_FILTER } from '../graphql/employeeListfilter.gql'
    export const withReadinessDetail = Component =>
    graphql(READINESS_DETAIL, {
        options: props => {
            let id = '';
            if (props?.match?.params) {
                id = getReadinessBase64StringFromInt(props?.match?.params?.readid);
            }
            return {
                variables: { id: id },
                fetchPolicy: 'network-only'
            };
        },
        props({ data: { loading, error, readinessById } }) {
            if (error) {
                throw new Error(error.message);
            }
            return { loading, error, readinessById };
        }
    })(Component);

export const withEmployeeList = Component =>
    graphql(EMPLOYEE_LIST, {
        options: props => {
            let id = '';
            if (props?.me) {
                id = props?.me?.employee?.id;
            }
            return {
                variables: {}
            };
        },
        props({ data: { loading, error, allOrganizationEmployees } }) {
            if (error) {
                throw new Error(error.message);
            }
            return { loading, error, allOrganizationEmployees };
        }
    })(Component);
export const withSurvayById = Component =>
    graphql(SURVEY_BY_ID, {
        options: props => {
            let id = '';
            if (props?.me) {
                id = props?.me?.employee?.id;
            }
            return {
                variables: { id:getSurveyBase64StringFromInt(Number(SURVEY_READINESS_LEVEL)) }
            };
        },
        props({ data: { loading, error, SurveyById } }) {
            if (error) {
                throw new Error(error.message);
            }
            return { loading, error, SurveyById };
        }
    })(Component);

export const withCreateReadiness = Component =>
    graphql(ADD_REDINESS, {
        props: ({ mutate, history }) => ({
            createReadiness: async values => {
                try {
                    const {
                        data: { createReadiness }
                    } = await mutate({
                        variables: {
                            ...values
                        }
                    });

                    message.destroy();
                    message.success('Submitted response!!');
                    return createReadiness.readiness;
                } catch (e) {
                    message.destroy();
                    message.error("Couldn't perform the action");
                    console.error(e);
                }
            }
        })
    })(Component);

export const withDeleteReadiness = Component =>
    graphql(DELETE_REDINESS, {
        props: ({ mutate, history }) => ({
            deleteReadiness: async values => {
                try {
                    const {
                        data: { deleteReadiness }
                    } = await mutate({
                        variables: {
                            ...values
                        }
                    });

                    message.destroy();
                    message.success('Deleted sucessfully!!');
                    return deleteReadiness.readiness;
                } catch (e) {
                    message.destroy();
                    message.error("Couldn't perform the action");
                    console.error(e);
                }
            }
        })
    })(Component);

export const withDeleteReadinessLevel = Component =>
    graphql(DELETE_REDINESS_BY_LEVEL, {
        props: ({ mutate, history }) => ({
            deleteReadinessLevel: async values => {
                try {
                    const {
                        data: { deleteReadinessLevel }
                    } = await mutate({
                        variables: {
                            ...values
                        }
                    });

                    message.destroy();
                    message.success('Deleted sucessfully!!');
                    return deleteReadinessLevel.readinessLevel;
                } catch (e) {
                    message.destroy();
                    message.error("Couldn't perform the action");
                    console.error(e);
                }
            }
        })
    })(Component);

export const withUpdateReadiness = Component =>
    graphql(UPDATE_MUTATION, {
        props: ({ mutate, history }) => ({
            updateReadiness: async values => {
                try {
                    const {
                        data: { updateReadiness }
                    } = await mutate({
                        variables: {
                            ...values
                        }
                    });

                    message.destroy();
                    message.success('Submitted response!!');
                    return updateReadiness.readiness;
                } catch (e) {
                    message.destroy();
                    message.error("Couldn't perform the action");
                    console.error(e);
                }
            }
        })
    })(Component);

export const withCreateReadinessLevel = Component =>
    graphql(CREATE_READINESS_LEVEL, {
        props: ({ mutate, history }) => ({
            createReadinessLevel: async values => {
                try {
                    const {
                        data: { createReadinessLevel }
                    } = await mutate({
                        variables: {
                            ...values
                        }
                    });

                    message.destroy();
                    message.success('Submitted response!!');
                    return createReadinessLevel.readiness;
                } catch (e) {
                    message.destroy();
                    message.error("Couldn't perform the action");
                    console.error(e);
                }
            }
        })
    })(Component);


    export const withCreateSkill = Component =>
    graphql(CREATE_COMPETENCY_SKILL, {
        props: ({ mutate, history }) => ({
            createSkill: async values => {
                try {
                    const {
                        data: { createCompetency }
                    } = await mutate({
                        variables: {
                            ...values
                        }
                    });
                    message.destroy();
                    message.success('Submitted response!!');
                    return createCompetency.competency;
                } catch (e) {
                    message.destroy();
                    if(e?.message?.includes('Competency or Skill already exists.')){
                        message.error('Competency or Skill already exists.')
                    }else{
                        message.error("Couldn't perform the action");
                    }
                    
                    console.error(e);
                }
            }
        })
    })(Component);


    export const withEmployeeListFilter = Component =>
    graphql(EMPLOYEE_LIST_FILTER, {
        options: props => {
            let id = '';
            if (props?.me) {
                id = props?.me?.employee?.id;
            }
            return {
                variables: {}
            };
        },
        props({ data: { loading, error, allEmployees } }) {
            if (error) {
                throw new Error(error.message);
            }
            return { loading, error, allEmployees };
        }
    })(Component);