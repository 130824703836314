import gql from 'graphql-tag';

export const UPDATE_ACTIONITEM_MUTATION = gql`
  mutation updateActionItem($id:ID!,$title: String, $notes: String,$coachingCommitmentId: Int,$commitmentId: Int,$isAgenda: Boolean,$okrId: Int,$completed: Boolean) {
    updateActionItem(id:$id,title: $title, notes: $notes,coachingCommitmentId:$coachingCommitmentId,commitmentId:$commitmentId,isAgenda:$isAgenda,okrId:$okrId,completed:$completed) {
        actionItem {
            id
            title
            completed
            meeting {
              id
              title
            }
            okr {
              id
               title
               owner {
                 id
                 user {
                   id
                   firstName
                   lastName
                   employee {
                     id
                   }
                 }
               }
             }
            commitment {
              id
              commitment {
                id
                title
              }
              user{
                id
                firstName
                lastName
                employee{
                  id
                }
              }
              completed
            }
            coachingCommitment {
              id
              coachingcommitment {
                id
                title
              }
              user{
                id
                firstName
                lastName
                employee{
                  id
                }
              }
              completed
            }
            notes
            completed
            isAgenda
            createdAt
            updatedAt
      }
    }
  }
`;
