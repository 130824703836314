import gql from 'graphql-tag';

export const ME_SURVEY_RESPONSES_FOR_TEAM_QUERY = gql`
  query me($id: ID!, $surveyId:ID!) {
    surveys: me{
      id
    employee{
      id
      teamManagerEmployee(id: $id){
        edges{
          node{
             employees{
              edges{
                node{
                  user{
                    id
                    firstName
                    responseSet(survey:  $surveyId) {
                      edges {
                        node {
                          id
                          survey {
                            id
                            name
                          }
                          user{
                            id
                          }
                          answerSet{
                            edges{
                              node{
                                id
                                answer
                                question{
                                  id
                                  questionText
																}
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  }
`;
