import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { Space, Col, Row, Empty, Button, Carousel, Progress, Form } from 'antd';
import { CaretRightOutlined, CheckOutlined } from '@ant-design/icons';
import {
  ReactMarkdown,
  RadioField,
  MLCheckBoxField,
  TextField,
  MLSelectField,
  RangeField,
  SelectField,
  displayDataCheck,
  ColorField,
  InputValuesObject,
  DatePickerField,
  RenderUpload,
  getIntFromString
} from '../../look';

import ProgressCounterBtn from '../../survey/components/ProgressCounterBtn';
import ProgressCounterBtnNative from '../../survey/components/ProgressCounterBtnNative';
import {EditTwoTone,CheckCircleOutlined,CloseCircleOutlined} from "@ant-design/icons"
export const TIME_LIMIT = 5;

const emailRules = { type: 'email', message: 'Not a vaild email' };

export function getTypeFromQuestion(question) {
  return question?.choiceSet?.edges[0]?.node?.inputType;
}

const SurveySelectionView = props => {
  const {
    questionList,
    matrix,
    sectionLabel,
    form,
    nextStep,
    srNo,
    showOk,
    submitted,
    selectedvalues,
    Closeedit,
    onSubmit,
    answer
  } = props;
  console.log('questionList',questionList,answer);
  const getRangeValues = (choiceSet, type) => {
    let defaultValue = 0,
      marks = {},
      step = 1;
    const value = choiceSet[0]?.value?.split(':') || [];
    if (type === 'RANGE') {
      marks[value[0]] = value[0];
      marks[value[1]] = value[1];
      defaultValue = value[2];
      step = value[3];
    }
    return { step, marks, defaultValue, min: Number(value[0]), max: Number(value[1]) };
  };

  const RenderQuestion = (question, index) => {
    const [colorLoading, setColorLoading] = React.useState(false);
    const [load, setLoad] = useState(false);
    const { id, sequence, questionText, required, helpText } = question?.question;
    const [ choiceSet, setchoiceSet ] = React.useState(question?.question?.choiceSet)
    const type = getTypeFromQuestion(question?.question);
    const [reset, setReset] = useState(false);
    const [selectedValue,setSelectedValue]=useState(question?.question?.answerSet?.edges[0]?.node?.answer)
    const [ selectedCheckboxValueStore, setSelectedCheckboxValueStore ] = React.useState( {} )
    const [submittedValue,setSubmittedValue]=React.useState()
    const renderLabel = (
      <div>
        <ReactMarkdown>{helpText}</ReactMarkdown>
      </div>
    );

    const rules = [{ required: true, message: 'All questions are required!' }];

    const { step, marks, defaultValue, min, max } = getRangeValues(
      choiceSet?.edges?.map(({ node }) => node),
      type
    );

    const handleChange = ( selectedId, choices, type ) => {
    };
    const nextStep = ( values ) => {
        onSubmit(values)
    }
    
    let initialValues
    if(typeof(answer)=='string' && type === InputValuesObject.checkbox) {
        let list=[]
        let choice_list = choiceSet.edges.map(({ node }) => node).map(c => c.value)
        choice_list.forEach(item=>{
            if(answer?.includes(item)){
                list.push(item)  
            }
        })
        initialValues={[id]:list}
        console.log(list,'answer xxx',answer);
    }
    else{
        initialValues={[id]:answer}
    }
return true ? (
            <div key={index} >

                <Form
                    form={form}
                    name={"value"}
                    // layout={'vertical'}
                    initialValues={initialValues}
                    onFinish={( values ) => nextStep( values )
                    }
                > <div  style={{width:"100%",display:"flex",flexDirection:"row",gap:"10px",alignItems:"center"}}>
                    <div className='survey-edit-section' style={{width:"100%"}}>
                        <div style={{ margin: '0 0' }}>
                            {type === InputValuesObject.checkbox && (
                                <MLCheckBoxField
                                    name={id}
                                    rules={required ? rules : []}
                                    choices={choiceSet.edges.map(({ node }) => node).map(c => ({ label: c.label, value: c.value }))}
                                    onChange={e => e && handleChange(false)}
                                    selectedValue={answer}
                                />
                            )}
                            {type === InputValuesObject.color && !colorLoading && (
                                <ColorField
                                    name={id}
                                    label={renderLabel}
                                    rules={required ? rules : []}
                                    choices={choiceSet.edges.map( ( { node } ) => node ).map( c => ( { label: c.label, value: c.name } ) )}
                                    onChange={e => {
                                        setColorLoading( true );
                                        e && form.setFieldsValue( _.set( form.getFieldsValue( true ), [ id ], e ) );
                                        setColorLoading( false );
                                        handleChange( e, choiceSet, type )
                                    }}
                                    value={form.getFieldValue( id )}
                                />
                            )}
                            {type === InputValuesObject.date && (
                                <DatePickerField
                                    margin={false}
                                    name={id}
                                    offset={3}
                                    format="YYYY-MM-DD"
                                    rules={[ { required, message: 'Please select a date' } ]}
                                    enabledisabledDate={false}
                                    onChange={e => e && handleChange( e )}
                                />
                            )}
                            {type === InputValuesObject.datetime && (
                                <DatePickerField
                                    margin={false}
                                    name={id}
                                    offset={3}
                                    showTime={{ format: 'HH:mm' }}
                                    format="YYYY-MM-DD HH:mm"
                                    rules={[ { required, message: 'Please select a date and time' } ]}
                                    enabledisabledDate={false}
                                    onChange={e => e && handleChange( e )}
                                // defaultValue={moment('2015-06-06', 'YYY-MM-DD')}
                                />
                            )}
                            {type === InputValuesObject.month && (
                                <DatePickerField
                                    margin={false}
                                    name={id}
                                    offset={3}
                                    rules={[ { required, message: 'Please select a month' } ]}
                                    enabledisabledDate={false}
                                    picker="month"
                                    onChange={e => e && handleChange( e )}
                                // defaultValue={moment('2015-06-06', 'YYY-MM-DD')}
                                />
                            )}
                            {type === InputValuesObject.time && (
                                <DatePickerField
                                    margin={false}
                                    name={id}
                                    offset={3}
                                    rules={[ { required, message: 'Please select a month' } ]}
                                    enabledisabledDate={false}
                                    type="time"
                                    onChange={e => e && handleChange( e )}
                                // defaultValue={moment('2015-06-06', 'YYY-MM-DD')}
                                />
                            )}
                            {type === InputValuesObject.week && (
                                <DatePickerField
                                    margin={false}
                                    name={id}
                                    offset={3}
                                    rules={[ { required, message: 'Please select a month' } ]}
                                    enabledisabledDate={false}
                                    picker="week"
                                    onChange={e => e && handleChange( e )}
                                // defaultValue={moment('2015-06-06', 'YYY-MM-DD')}
                                />
                            )}
                            {type === InputValuesObject.radio && (
                                <RadioField
                                    name={id}
                                    left={'2vw'}
                                    label={renderLabel}
                                    choices={choiceSet.edges.map( ( { node } ) => node ).map( c => ( { label: c.label, value: c.value } ) )}
                                    rules={required ? rules : []}
                                    onChange={e => e?.target?.value && handleChange( e?.target?.value, choiceSet, type )}
                                    defaultValue={answer}
                                />
                            )}
                            {type === InputValuesObject.range && (
                                <RangeField
                                    name={id}
                                    rules={required ? rules : []}
                                    marks={marks}
                                    step={step}
                                    defaultValue={answer}
                                    min={min}
                                    max={max}
                                    trackStyle={{ backgroundColor: '#B0CB1F' }}
                                    handleStyle={{ backgroundColor: '#B0CB1F' }}
                                    onAfterChange={e => e && handleChange( e )}
                                />
                            )}
                            {type === InputValuesObject.email && (
                                <TextField
                                    name={id}
                                    rules={required ? rules.map( r => ( { ...r, ...emailRules } ) ) : [ { ...emailRules } ]}
                                    // rules={[{ type: 'email' }]}
                                    offset={2}
                                    onChange={e => e && handleChange(e?.target?.value,null,type)}
                                    onBlur={e => e?.target?.value && handleChange( e )}
                                    value={answer}
                                />
                            )}
                            {type === InputValuesObject.file && (
                                <RenderUpload
                                    setUrl={e => form.setFieldsValue( _.set( form.getFieldsValue( true ), id, e ) )}
                                    setLoad={setLoad}
                                    offset={2}
                                    name={id}
                                />
                            )}
                            <Row>
                                <Col lg={24} xs={20}>
                                    {type === InputValuesObject.text && (
                                        <TextField
                                            name={id}
                                            rules={required ? rules : []}
                                            offset={2}
                                            onChange={e => e && handleChange(e?.target?.value,null,type)}
                                            onBlur={e => e?.target?.value && handleChange( e )}
                                            value={answer}
                                        />
                                    )}
                                </Col>
                            </Row>
                            {type === InputValuesObject.number && (
                                <TextField
                                    name={id}
                                    rules={required ? rules : []}
                                    offset={2}
                                    type='number'
                                    onChange={e => e && handleChange(e?.target?.value,null,type)}
                                    onBlur={e => e?.target?.value && handleChange( false )}
                                    value={answer}
                                />
                            )}
                            {type === InputValuesObject.select && (
                                <Row>
                                    <Col lg={24} xs={20}>
                                        <SelectField
                                            otherOption={true}
                                            name={id}
                                            offset={2}
                                            rules={required ? rules : []}
                                            choices={choiceSet.edges.map( ( { node } ) => node ).map( c => ( { label: c.label, value: c.value } ) )}
                                            onSelectChange={e => e && handleChange( e, choiceSet, type )}
                                            onBlur={e => e?.target?.value && handleChange( false )}
                                            selectedValue={answer}
                                        />
                                    </Col>
                                </Row>
                            )}
                            {showOk && (
                                <>
                                    {/* <Col lg={24} md={0} xs={0}>
                                        <ProgressCounterBtn
                                            idIndex={srNo}
                                            timeLimit={TIME_LIMIT - 2}
                                            timer={
                                                type !== InputValuesObject.checkbox ||
                                                type !== InputValuesObject.text ||
                                                type !== InputValuesObject.range
                                            }
                                            reset={reset}
                                            setReset={setReset}
                                            btn={props => (
                                                <Button type="primary" disabled={load} onClick={e => nextStep( srNo )} {...props}>
                                                    OK
                                                    <CheckOutlined />
                                                </Button>
                                            )}
                                        />
                                    </Col> */}
                                    {/* <Col lg={0} md={24} xs={24}>
                                        <ProgressCounterBtnNative
                                            idIndex={srNo}
                                            timeLimit={TIME_LIMIT - 2}
                                            timer={
                                                type !== InputValuesObject.checkbox ||
                                                type !== InputValuesObject.text ||
                                                type !== InputValuesObject.range
                                            }
                                            reset={reset}
                                            setReset={setReset}
                                            btn={props => (
                                                <Button type="primary" disabled={load} onClick={e => nextStep( srNo )} {...props}>
                                                    OK
                                                    <CheckOutlined />
                                                </Button>
                                            )}
                                        />
                                    </Col> */}
                                </>
                            )}
                        </div>
                    </div>
                    <div style={{display:'flex',flexDirection:"row",justifyContent:'start',alignItems:"center",gap:"10px"}}>
                        <CloseCircleOutlined style={{color:"red",fontSize:"25px",cursor:"pointer"}} onClick={()=>{form.resetFields();Closeedit()}}/>
                        <Button htmlType="submit" style={{border:"none",boxShadow:"none"}}><CheckCircleOutlined style={{color:"#4CCB1F",fontSize:"25px",cursor:"pointer"}}/></Button>
                    </div>
                </div>
                </Form>
            </div>
         
        ) : null;

    };
    return questionList?.map( RenderQuestion )
};

export default SurveySelectionView;

