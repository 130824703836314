import React from "react";
import { Button, Col, Form, Modal, Row } from "antd";
import {EditTwoTone,CheckCircleOutlined,CloseCircleOutlined} from "@ant-design/icons"
import {
    ReactMarkdown, getIntFromString,
  } from '../../look';
import SurveySelectionView from "./surveySelectionView";

const SurveyResponseRenderView =(props)=>{
    const {me,surveyQuestionResponse,updateResponse,editable,noQuestion,index,other_employee,showNodata}=props
    const [edit,setEdit]=React.useState(false)
    const [selectedEdit,setSelectedEdit]=React.useState([])
    const [formData,setFormData]=React.useState()
    const [ form ] = Form.useForm();
    const editAnswer=(data)=>{
        console.log('data',data);
        setSelectedEdit([data])
        setEdit(true)
    }

    const saveData =async(value)=>{
        try {
           let AnswerInput={
                questionId: getIntFromString(value?.question_id),
                answer: value?.answer[value?.question_id].toString()
            }
            let response = await updateResponse({
                answers:[AnswerInput],
                responseId: Number(props.match.params.response_id)
            })
            if(response){
                surveyQuestionResponse.question[me.employee.user.id]=value?.answer[value?.question_id]
                setEdit(false)
            }
        }catch (e) {
            throw new Error(e.message);
        }
    }
    return (
        <>
      
            {surveyQuestionResponse!=undefined&&
                <Col style={{width:"100%"}}>
                    <div className={`question-title ${showNodata?"":"comparison-left-section"}`} style={{display:"flex",flexDirection:"row",justifyContent:"start",alignItems:"center",gap:'5px',visibility:noQuestion?"hidden":"visible",paddingRight:'20px'}}><span style={{ fontSize: '1.125em',fontWeight:"500"}}>{index+1}.</span><ReactMarkdown style={{ fontSize: '1.125em',fontWeight:"500"}}>{surveyQuestionResponse?.question?.questionText}</ReactMarkdown></div>
                    { !edit?
                        <div style={{width:"100%",display:"flex",flexDirection:"row",paddingTop:"10px",gap:"20px"}}>
                        <p className={showNodata?"":"comparison-left-section"} style={{margin:'0px',fontSize: '1.05em',fontWeight:"400",paddingLeft:"18px",display:"table-cell",verticalAlign: "bottom"}}>{surveyQuestionResponse?.question?.[me.employee.user.id]}{editable&&<EditTwoTone color="#4CCB1F" style={{cursor:"pointer",marginLeft:"10px"}} onClick={()=>editAnswer(surveyQuestionResponse)} />}</p>
                        {other_employee&&!showNodata&&<p className="comparison-right-section"style={{margin:'0px',fontSize: '1.05em',fontWeight:"400",paddingLeft:"18px",display:"table-cell",verticalAlign: "bottom"}}>{surveyQuestionResponse?.question?.[other_employee?.type=='user_id'?other_employee?.user?.id:other_employee?.receiver?.id]}</p>}
                        </div>
                        :
                        <div key={index} className={showNodata?"":"comparison-left-section"}>
                            <SurveySelectionView answer={surveyQuestionResponse?.question?.[me.employee.user.id]} questionList={selectedEdit} form={form} onSubmit={(e)=>saveData( {question_id:surveyQuestionResponse?.question?.id,answer:e})}  Closeedit={()=>setEdit(false)} selectedvalues={(e)=>console.log("eeeee",e)}/>
                        </div>
                    }
                </Col >
            }
        </>
    )
}

export default SurveyResponseRenderView