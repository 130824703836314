import React from 'react';
import UserKudos from '../components/UserKudos';
import { EMPLOYEE_KUDOS } from '../graphql/EmployeeProfile.gql';

const UserKudosContainer = (props) => {
    const { profile_detail,client } = props
    const [kudos_page_info,SetKudosPageInfo] = React.useState()
    let kudos_ref = React.useRef()
    const [kudos, SetKudos] = React.useState()

    React.useEffect(()=>{
        if(kudos){
            kudos_ref.current = kudos
        }
    },[kudos])

    React.useEffect(()=>{
        if(profile_detail){
            let filter={ recipient: profile_detail?.employee_id, first: 10}
            getKudos(filter)
        }
    },[profile_detail])

    const getKudos= async (filter)=>{
        const { data } = await client.query({
            query: EMPLOYEE_KUDOS,
            fetchPolicy: 'network-only',
            variables: filter,
        });
        if(data?.badgeAwards){
            let badge = data?.badgeAwards
            let list = badge?.edges?.map(({node})=>node)
         
            if((filter?.cursor) && kudos_ref?.current){
                list = kudos_ref?.current?.concat(list)
            }
            SetKudos(list)
            let page_info ={
                total_doc:badge?.totalCount ,
                has_next_page:badge?.pageInfo?.hasNextPage ,
                cursor:badge?.pageInfo?.endCursor,
            }
            SetKudosPageInfo(page_info)
        }
    }



    return (
        <>
            <UserKudos {...props} kudos={kudos}/>
        </>
    )
}

export default UserKudosContainer