const baseName = '/kudos'
const home="/home"
const badge_family="/badge-family"
const create="/create"
const badge="/badge"
const edit="/edit"
module.exports={
    kudos_home: baseName + home,
    badge_family_home:baseName+badge_family+home,
    badge_family_create:baseName+badge_family+create,
    badge_home:baseName+badge+home,
    badge_create:baseName+badge+create,
    badge_edit:baseName+badge+edit+'/:badgeid'
}