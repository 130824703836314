import gql from 'graphql-tag';

export const READINESS_DETAIL = gql`
query readinessById($id: ID!) {
    readinessById(id:$id){
        id
        createdAt
        updatedAt
    readinessTo {
      id
      user{
        id
        employee{
          id
				}
        firstName
        lastName
      }
    }
    readinessFrom {
      id
      user{
        id
        employee{
          id
		}
        firstName
        lastName
		}
    }
    createdAt
    updatedAt
    readinesslevelSet {
      edges {
        node {
          id
          skill{
            id
            title
            description
					}
          response{
            id
            answerSet{
              edges{
                node{
                  id
                  answer
                  question{
                    id
                    questionText
                    required
                  }
                }
              }
						}
          }
        }

      }
    }
    }
}
`