import gql from 'graphql-tag';

export const ORGANIZATION_UPCOMMING_MILESTONE_LIST =gql`
query me($createdBy_Ne: ID,$employee:ID,$okr_Duedate_Range: [DateTime],$tz:String,$offset:Int,$first:Int){
    UpcommingMilestones:me{
        id
        employee {
          id
          organizationSet {
            totalCount
            edgeCount
            edges {
              node {
                id
                ceo {
                  id
                  okrContributorSet(savedOkrType: "MILESTONE", role_Iexact: "OWNER", createdBy_Ne:$createdBy_Ne, employee:$employee orderBy: ["okr__duedate"],okr_Duedate_Range:$okr_Duedate_Range) {
                    edges {
                      node {
                        id
                        progress
                        okr {
                          id
                          title(tz:$tz)
                          okrType
                          owner{
                            id
                            user{
                              id
                              firstName
                              lastName
                            }
                          }
                          duedate
                          isDiscarded {
                            id
                          }
                          contributorSet(offset:$offset,first:$first) {
                            edges {
                              node {
                                id
                                employee {
                                  id
                                  user {
                                    id
                                    firstName
                                    lastName
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
    }
}
`