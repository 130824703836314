import React from 'react';
import { Bar,Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const CompetencyBarChart = ( props ) => {

    const {bar_graph_data} = props
    const [graph_data,setGraphData]=React.useState()

    React.useEffect(()=>{
        if(bar_graph_data){
            console.log("bar_graph_data",bar_graph_data)
            setGraphData(bar_graph_data)
        }
    },[bar_graph_data])
    const chartData = {
        labels:graph_data?.graph_data_set?.map(i=>i.title),
        datasets: [
            {
                data: graph_data?.graph_data_set?.map(i=>i.sequence),
                backgroundColor: graph_data?.graph_data_set?.map(i=>i.color),
                
                barThickness: 50, // Adjust the bar width
                borderRadius: 8, // Set the border radius for all four corners
                categoryPercentage: 0.9,
            }
        ]
    };


    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scrollX: {
            enabled: true
        },
        scales: {
            y: {
                min: 0,
                max: graph_data?.labels?.length,
                ticks: {
                    stepSize: 1,
                    color: '#000',
                    font: {
                        size: 15,
                        weight:600,

    
                    },
                    callback: function(value, index, ticks) {
                        let label = graph_data?.labels?.find(i=>i.value==value)
                        if(!value){
                            return ''
                        }
                        return label?.name?.length>10?label?.name?.slice(0,10)+'...':label?.name || '';
                    }
                },
                grid: {
                    display: true,
                    color:"#8C8CA1",
                    borderDash: [8, 8],
                }
            },
            x: {
                grid: {
                    display: false,
                    offset: false
                },
                ticks: {
                    color: '#000',
                    font: {
                        size: 15,
                        weight:600
                    }
                }
            }
        },
        indexAxis: 'x',
        elements: {
            bar: {
                borderWidth: 0,
                borderHeight: 0
            },
        },
        plugins: {
            legend: {
                position: 'bottom',
                display: false,
            },
            tooltip: {
                backgroundColor:'#0E0E2C',
                callbacks: {
                    label: function (context) {
                        
                        let doc = graph_data?.graph_data_set[context?.dataIndex]?.behaviourExample
                        if(doc?.length>100){
                            doc = doc?.slice(0,100)+'...'
                        }
                        return doc||''
                        ;
                    }
                }
            },
            title: {
                display: false,
            }
        },
    };
    return (
        <>
           
            <div className='ca-scroll-container' style={{padding:"1.87em 2.19em"}}>
            <Bar style={{minWidth:"700px"}} data={chartData} options={options} height={'150px'}/>
            </div>
            {/* <Line options={options} data={chartData} /> */}
        </>
        
    )

}
export default CompetencyBarChart