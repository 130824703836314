import gql from 'graphql-tag';
import { CONTACT_REQUEST_TYPE } from './ContactRequestType.gql';

export const ALL_CONTACT_REQUESTS_QUERY = gql`
  ${CONTACT_REQUEST_TYPE}
  query allContactRequests(
    $first: Int
    $offset: Int
    $orderBy: [String]
    $name_Icontains: String
    $email_Icontains: String
    $message_Icontains: String
  ) {
    allContactRequests(
      first: $first
      offset: $offset
      orderBy: $orderBy
      name_Icontains: $name_Icontains
      email_Icontains: $email_Icontains
      message_Icontains: $message_Icontains
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...ContactRequestType
        }
      }
      totalCount
      edgeCount
    }
  }
`;
