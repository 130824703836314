import React from 'react';
import { Row, Col, Button,Spin,Table ,Modal,Input, Menu, Dropdown } from "antd";
import styled from 'styled-components';
import { ONEONONE_ADD_EDIT_TOOL_ID, ONE_ON_ONE_TOOL_WHILE_MEETING } from '../../../config'
import MeetingTableView from './Table'
import Quick1_1 from './QuickMeeting'
import { displayUtcToUserTimezone, getIntFromString } from "../../look";
import agenda_arrow from '../../assets/agenda-arrow.svg'
import creator from '../../assets/one-on-one-creator.svg'
import assigne from '../../assets/one-on-one-contributor.svg'
import edit from '../../assets/one-on-one-edit.svg'
import bookmark_disabel from '../../assets/bookmark-inactive.svg'
import { LoadingOutlined } from '@ant-design/icons';
import series_meeting from '../../assets/series_meeting.svg'
import AgendaPopup from './AgendaPopupView';
import meeting_view from '../../assets/meeting-view-icon.svg'
import hovered_eye from '../../assets/hovered_eye.svg'
import moment from 'moment';
import OneOnOneCongirmationModal from './one-on-one-confirmation-modal';
import one_on_one_route from '../route/index'
const MeetingCard =(props)=>{
    const{dataSource,selectedTab,history,me,loading,paginationLoad,nextPage,tab}=props
    const antIcon = <LoadingOutlined style={{ fontSize: "34px",color:"#4CCB1F" }} spin />;
    const [quickMeeting, setquickMeeting] = React.useState();
    const [agendaPopup, setagendaPopup] = React.useState(false);
    const [agendaList, setagendaList] = React.useState([]);
    const [startMeeting,setStartMeeting]=React.useState(false)
    const [startPop,setStartPop]=React.useState(false)
    const [startUpcommingPop,setStartUpcommingPop]=React.useState(false)
    const [selectedMeet,setSelectedMeet]=React.useState()
    const viewAgenda=(meeting)=>{
        setagendaList(meeting)
        setagendaPopup(true)
      }
    
      const MettingMember=(item)=>{
        let membersList =item?.member?.edges?.map(({node})=>node?.user)
        let list =membersList?.filter((data)=>data?.id!==item?.owner?.user?.id)
        return list[0]?.firstName
      }
      const navigateToDetail=(item)=>{
        if(selectedTab==="1_1" || selectedTab==="future"){
          history.push(`${one_on_one_route?.viewMeeting?.replace(":meetingid",getIntFromString(item?.id))}?tab=${selectedTab}`)
        }else if(selectedTab==="upcoming"){
          setSelectedMeet(item)
          if(moment(new Date(item?.event)).isBefore(new Date())){
            setStartPop(true)
          }
          else{
            setStartUpcommingPop(true)
          }
        }
      }
      const navigateTocreate1_1withPrevious=(id)=>{
        history.push(`${one_on_one_route?.addOneonOne}?previous=${getIntFromString(id)}`)
      }
      const meetingStart=(value)=>{
          if(value){
            setStartPop(false)
            setStartUpcommingPop(false)
            history.push(one_on_one_route?.Meeting?.replace(":meetingid",getIntFromString(selectedMeet?.id)))
            setSelectedMeet()
          }
      }



    return (
      <>
        <Spin spinning={loading} indicator={antIcon}>
          {(!dataSource?.length&&loading)&& <div style={{minHeight:'400px'}}></div>}
        <div style={{height:"100%",minHeight: tab==='upComming'?"400px":"350px"}}>
            <Row justify='start' align='start' style={{height:"100%"}}>
              {dataSource?.length? dataSource.map(item=>(<>
                <Col lg={11} className='one-on-one-card-div'>
                  {/* <img src={bookmark_disabel} alt=""className='bookmark-disable'style={{cursor:"pointer"}} /> */}
                    {(me?.employee?.user?.id === item?.owner?.user?.id&&selectedTab!=="1_1")&&<Dropdown className='edit-one-on-one'  overlay={
                      <Menu>
                        <Menu.Item key="occurrence" className="one-on-one-input-field-style" onClick={()=>history.push(one_on_one_route?.EditMeeting?.replace(":meetingid",getIntFromString(item?.id)))}>Edit occurrence</Menu.Item>
                        { item?.rrule &&(<Menu.Item className="one-on-one-input-field-style" key="series"onClick={()=>history.push(one_on_one_route?.EditMeetingSeries?.replace(":meetingid",getIntFromString(item?.id)))}>Edit series</Menu.Item>)}
                      </Menu>}  placement="bottomCenter">
                    <img src={edit} alt="" style={{cursor:"pointer"}}/>
                    </Dropdown>}
                    {selectedTab==="1_1"&&<img className='view-meeting-icon' src={meeting_view} alt="" style={{cursor:"pointer",position:"absolute",right:"10px",top:"10px"}} onClick={()=> history.push(`${one_on_one_route?.viewMeeting?.replace(":meetingid",getIntFromString(item?.id))}?tab=${selectedTab}`)}/>}
                    {selectedTab==="1_1"&&<img className='view-meeting-icon-hovered' src={hovered_eye} alt="" style={{cursor:"pointer",position:"absolute",right:"10px",top:"10px"}} onClick={()=> history.push(`${one_on_one_route?.viewMeeting?.replace(":meetingid",getIntFromString(item?.id))}?tab=${selectedTab}`)}/>}
                    <Row justify='start'className='card-data-container'>
                    <div className='one-on-one-date-section' align='center' style={{display:"flex",flexDirection:'column',justifyContent:'center',alignItems:"center"}}>
                        <h4 className='one-on-one-date'>{displayUtcToUserTimezone(item?.event, "DD MMM YY")}</h4>
                        
                        {
                           item?.nextMeeting?.totalCount ? 
                           <Row style={{gap:'5px',marginTop:'3px'}} justify='end' align='middle'>
                            <p className='one-on-one-time'>{displayUtcToUserTimezone(item?.event, "hh:mm a")}</p>
                            <img style={{width:'12px'}} src={series_meeting}/> 
                           </Row>
                           :
                           <div align='center' style={{marginTop:'3px',textAlign:'center'}} >
                           <p className='one-on-one-time'>{displayUtcToUserTimezone(item?.event, "hh:mm a")}</p>
                           </div>
                        }
                      </div>
                      <div style={{display:'flex', flexDirection:"column",width:"100%"}}>
                        <Row  className='title-btn-section' style={{display:'flex', flexDirection:"row",justifyContent:"space-between",alignItems:"center",gap:"10px",width:"100%",flexWrap:"nowrap",paddingTop:"5px"}}>
                          <Col className='one-on-one-title-container'>
                            <h4 className='one-on-one-title'style={{width:"fit-content"}}>{item?.title}</h4>
                          </Col>
                          <Col style={{height:"100%",display:'flex',flexDirection:"column",justifyContent:"center",alignItems:"center"}} className='button-section'>
                            {selectedTab!=="1_1"&&<Button className='one-on-one-start-btn' onClick={()=>navigateToDetail(item)}>{selectedTab==='future'?'View':'Start'}</Button>}
                            {(selectedTab==="1_1" && !item?.nextMeeting?.totalCount )?(
                              <Button  className='one-on-one-start-btn' onClick={()=>{navigateTocreate1_1withPrevious(item?.id)}}>
                                  Add NEXT
                              </Button>
                              ) :'' }
                            <p className='agenda-button'onClick={()=>viewAgenda(item)}>Agenda <img src={agenda_arrow } alt="" /></p>
                          </Col>
                        </Row>
                        <div style={{display:'flex',flexDirection:"row",justifyContent:"start",gap:"10px",alignItems:"center"}}>
                              <p className='user-name' style={{display:'flex',flexDirection:"row",alignItems:'center',justifyContent:'center',gap:"5px"}}><img src={creator} alt="" /><span style={{textOverflow:"ellipsis",overflow:"hidden"}}>{item?.owner?.user?.firstName}</span></p>
                              <p className='user-name' style={{display:'flex',flexDirection:"row",alignItems:'center',justifyContent:'center',gap:"5px"}}><img src={assigne} alt="" /><span style={{textOverflow:"ellipsis",overflow:"hidden"}}>{MettingMember(item)}</span></p>
                        </div>
                      </div>
                    
                    </Row>
                </Col>
              </>)):<div style={{justifyContent:"center",alignItems:"center",display:"flex",width:"100%",paddingTop:"180px",height:"350x"}}>
                      <h4 className='no-data'>{loading?"":"No Meetings Found"}</h4>
                </div>}
            </Row>
        </div>
        
        {nextPage&&<Row justify="center" align="middle" style={{width:"100%",bottom:"0px"}}>
            <h4 className="load-mode-title" onClick={()=>paginationLoad()}>Load More</h4>
          </Row>}
        </Spin>

        <Modal
          footer={null}
          centered
          visible={agendaPopup}
          destroyOnClose={true}
          //  onCancel={() => {setquickMeeting(false)}}
          closable={false}
        >
          <AgendaPopup me={me} agendaList={agendaList} onClose={()=>setagendaPopup(false)}/>

        </Modal>

        {
          startPop&& (

        <OneOnOneCongirmationModal
            heading={'Are you ready to start the 1:1?'}
            onSubmit={(e)=>meetingStart(e)}
            visible={startPop}
            onCancel={()=>{setStartPop(false);setSelectedMeet()}}
            actionButton={"Start 1:1"}
            actionButtonColor={"#4CCB1F"}
            hideIcon={true}
        />
          )
        }
        {
              startUpcommingPop&& (

            <OneOnOneCongirmationModal
                heading={'Caution'}
                onSubmit={(e)=>meetingStart(e)}
                visible={startUpcommingPop}
                onCancel={()=>{setStartUpcommingPop(false);setSelectedMeet()}}
                actionButton={"Start 1:1"}
                meetingData={selectedMeet}
                actionButtonColor={"#4CCB1F"}
                hideIcon={false}
            />
          )
        }
    </>
    )
}


export default MeetingCard