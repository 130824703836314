// @ts-nocheck
import React from 'react';
import EmployeeLevelContainer from '../containers/EmployeeLevelContainer';
import CompetencyContainer from '../containers/CompetencyContainer';
import RoleContainer from '../containers/RoleContainer';
import JobFamilyContainer from '../containers/JobFamilyContainer';
import RatingScaleContainer from '../containers/RatingScaleContainer';
import { home_container_icons } from '../icon';
import { Row } from 'antd';
import { ROLEGOAL_HOME_TOOL_ID,ROLE_HOME_TOOL_ID } from "../../../config";
import PAGE_ROUTE from '../../page/route';
import { useHistory } from 'react-router-dom';
import SubCompetencyContainer from '../containers/SubCompetencyContainer';
import AssignEmployeeContainer from '../containers/AssignEmployeeContainer';

const RoleDefinitionHomeView = ( props ) => {

    const { current_tab } = props
    const [remove_background,SetRemoveBaground] = React.useState(false)
    const history =useHistory()
    // list all view that are in the role definition
    const views = {
        employee_level: EmployeeLevelContainer,
        competency: CompetencyContainer,
        sub_competency:SubCompetencyContainer,
        role: RoleContainer,
        job_family: JobFamilyContainer,
        rating_scale: RatingScaleContainer,
        member:AssignEmployeeContainer
    }
    const CurrentView = views[ Object.keys(views)?.includes(current_tab?.key)?current_tab?.key:'employee_level' ]

    return (
        <>
            
            <div className={`rd-container ${!remove_background?'rd-main-content':''}`}>
                <CurrentView {...props} SetRemoveBaground={(e)=>{SetRemoveBaground(e)}} remove_background={remove_background}/>
            </div>
        </>
    );

}

export default RoleDefinitionHomeView