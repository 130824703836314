import React from 'react';
import { useHistory } from 'react-router-dom';
import { compose } from '../../core';
import FeedbackDetailedView from '../components/FeedBackDetailedView'
import { withFeedBackByid,withDeleteFeedback } from './feedbackOperations'
import { subscribeToFeedbackById } from './feedbackSubscription'
const FeedbackDetailedContainer = props => {
  
  const { match, subscribeToMore } = props
  const history = useHistory();
  React.useEffect( () => {
    if ( match?.params?.feedid ) {
      const subscribe = subscribeToFeedbackById( subscribeToMore, { id: match?.params?.feedid } );
      return () => subscribe();
    }
  }, [ match ] );

  return (
    <>
      <FeedbackDetailedView {...props} history={history}></FeedbackDetailedView>
    </>
  );
};

export default compose(withFeedBackByid,withDeleteFeedback)(FeedbackDetailedContainer);
