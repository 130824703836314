// @ts-nocheck


import React from 'react'
import VerticalDashboardContainer from '../container/verticalDashboardContainer';
import TeamsDashboardComponent from '../container/OkrTeamsDashboard'
import DashboardHeaderContainer from '../container/DashboardHeaderContainer';
import OrganizationDashboardContainer from '../container/OrganizationDasboardContainer'
import { Col, DatePicker, Row, Spin } from 'antd';
import moment from 'moment-timezone';
import IndividualDashboardContainer from '../container/IndividualDashboardContainer';
import styled from 'styled-components';
import dropdown_Arrow from '../../assets/dropdown_Arrow.svg'
import CustomOkrRange from 'modules/look-v2/Components/customOkrRangePicker';
import OkrVerticalBar from './OkrVerticalBar';
import CustomOkrRangeForFilter from 'modules/look-v2/Components/CustomOkrRangePickerForFilter';
import { MetaTags } from 'modules/look';
const DashboardHomePage = (props) => {
    let currentFilter = React.useRef({})
    const { RangePicker } = DatePicker;
    const [selectedTab, setSelectedTab] = React.useState();
    const [verticalDetail, setverticalDetail] = React.useState();
    const [teamDetail, setteamDetail] = React.useState();
    const [organizationDetail, setorganizationDetail] = React.useState();
    const [defaultSelectedDate, setDefaultSelectedDate] = React.useState()
    const [filterDateRange, setFilterDateRange] = React.useState([moment().startOf('year').utc().toISOString(), moment().endOf('year').utc().toISOString()])


    const datefilter = (e) => {
        if (e && e?.length === 2) {
            setDefaultSelectedDate(e)
            let okr_Duedate_Range = [moment(e[0]).startOf('day').utc().toISOString(), moment(e[1]).endOf('day').utc().toISOString()]
            currentFilter.current['offset'] = 0
            setFilterDateRange(okr_Duedate_Range)
        }
        else{
            setFilterDateRange([])
        }
    }

    React.useEffect(() => {
        if (selectedTab) {
            localStorage.setItem('dashboard_tab', selectedTab)
        }
    }, [selectedTab])

    const onTabChange = (e) => {
        if (e?.tab === 'Team') {
            setSelectedTab(e?.tab)
            setteamDetail(e?.detail)
            setverticalDetail(null)
            setorganizationDetail(null)
        }
        else if (e?.tab === 'Vertical') {
            setSelectedTab(e?.tab)
            setverticalDetail(e?.detail)
            setteamDetail(null)
            setorganizationDetail(null)
        }
        else if (e?.tab === 'Organization') {
            setSelectedTab(e?.tab)
            setorganizationDetail(e?.detail)
            setteamDetail(null)
            setverticalDetail(null)
        }
        else {
            setSelectedTab(e?.tab)
            // setverticalDetail(e?.detail)
        }
    }

    return (
        <div className="rd-root-style">
            <MetaTags title="OKR" description="OKR Home" />
            <OkrVerticalBar {...props} current_tab={
              {
                key: 'okrdashboard',
                label: 'Dashboard',
              }
            } />
            <DashboardContainer>

                <Col span={24}>

                    <Row align='middle' justify='center' style={{ marginTop: "20px" }}>
                        <div className="custom-okr-picker">
                            <CustomOkrRangeForFilter defaultValue={[moment().startOf('year'), moment().endOf('year')]} selectedDateRange={(e) => datefilter(e)} currentSelected={true} />
                        </div>
                        <div className="okr-picker">
                            <RangePicker
                                allowClear={false}
                                dropdownClassName={'dashboard-date-range'}
                                defaultValue={defaultSelectedDate?.length ? defaultSelectedDate : [moment().startOf('year'), moment().endOf('year')]}
                                format={'DD/MMM/YY'}
                                style={{ width: "260px" }}
                                disabled={!selectedTab}
                                onChange={(e) => { datefilter(e) }}
                                suffixIcon={<img src={dropdown_Arrow} alt="" />}
                            />
                        </div>

                    </Row>
                    <div align="center">
                        <Col span={23} className="dashboard-tabs" style={{ overflow: "auto" }}>
                            <div style={{ width: "100%", marginBottom: "10px", padding: "0px 10px" }} className="tab-child">
                                <DashboardHeaderContainer SelectedTab={selectedTab} onTabChange={(e) => { onTabChange(e) }}  {...props} dateRangeTofilter={filterDateRange} />
                            </div>
                        </Col>
                    </div>
                    <div>
                        {
                            selectedTab === "Individual" &&
                            <IndividualDashboardContainer {...props} dateRangeTofilter={filterDateRange} />
                        }
                        {selectedTab === "Vertical" &&
                            <VerticalDashboardContainer verticalDetail={verticalDetail}  {...props} dateRangeTofilter={filterDateRange} />
                        }
                        {selectedTab === "Team" &&
                            <TeamsDashboardComponent {...props} teamDetail={teamDetail} dateRangeTofilter={filterDateRange} />
                        }
                        {
                            selectedTab === "Organization" &&
                            <OrganizationDashboardContainer {...props} organizationDetail={organizationDetail} dateRangeTofilter={filterDateRange} />
                        }
                        {
                            !selectedTab &&
                            <div style={{ width: "100%" }} align="center">
                                <Spin spinning={true} />
                            </div>
                        }
                    </div>
                </Col>
            </DashboardContainer>
        </div>
    )

}


export default DashboardHomePage



const DashboardContainer = styled(Row)`

column-gap:25px;

@media only screen and (max-width: 768px) {
    column-gap:0px;
  }

  .ant-picker-dropdown  {
    background:red;
  }
  
  .date-range .ant-picker-input > input{
    color:#595959 !important;
  }

`;

