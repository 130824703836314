import gql from 'graphql-tag';


export const SURVEY_SUBSCRIPTION = gql`
  
  subscription surveySubscription {
    surveySubscription {
      mutation
      survey {
        id
        public
        organization{
          edgeCount
          edges {
            node {
              id
              name
              description
              email
              logo
              termsConditions
              allowComparison
            }
          }
        }
        
        vertical{
          edgeCount
          edges {
            node {
              id
              name
              organization{
                id
                name
              }
            }
          } 
        }
        
        team{
          edgeCount
          edges {
            node {
              id
               id
               name
              description
              vertical{
                id
                organization{
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;
