// @ts-nocheck
import React from "react";
import { compose } from 'modules/core';
import { withApollo } from '@apollo/client/react/hoc';
import OrganizationDashboardView from "../components/OrganizationDashboardView";
import { ORGANIZATION_CHART_DATA } from "../graphql/OrganizationChartData.gql";
import { endOfQuarter, getIntFromString, localTz, startOfQuarter } from 'modules/look';
import { ORGANIZATION_UPCOMMING_MILESTONE_LIST } from "../graphql/OrganizationUpcommingMilestone.gql";
import { ORGANIZATION_PERFORMANCE_DATA } from "../graphql/OrganizationPerformanceData.gql";
import { ORGANIZATION_VERTICAL_DATA } from "../graphql/OrganizationVerticalData.gql";
const OrganizationDashboardContainer =(props)=>{

    const {me,client,organizationDetail,dateRangeTofilter}=props

    const [chartData,setChartData]=React.useState()
    const [performanceData,setPerformanceData]=React.useState()
    const [VerticalperformanceData,setVerticalPerformanceData]=React.useState()
    const [upcomingMilestones,setUpcomingMmilestones]=React.useState()
    const [performanceDataloader,setPerformanceDataloader]=React.useState(false)
    React.useEffect(()=>{
        if(me&&organizationDetail&&dateRangeTofilter){
            getNonCascardRecord(0)
            getUpcomingMilestones(organizationDetail?.ceo?.id,0)
            getPerformanceRecord(5,0)
        
            // getVertovalPerformanceRecord(5,0)
        }
    },[me,organizationDetail,dateRangeTofilter])
 
    const getNonCascardRecord = async(offset,pagination=false)=>{
        let id =organizationDetail?.ceo?.id
        const { data } = await client.query({
            query: ORGANIZATION_CHART_DATA ,
            variables: {
                okr_Duedate_Range: dateRangeTofilter,
                employee:id,createdBy:id,
                offset: offset*100, first: 100
            },
            // fetchPolicy: 'network-only'
          });
          if(data?.OrganizationChartData){
            if (pagination) {
                let obj = {
                  edgeCount: chartData?.edgeCount + data?.AllCascadingObjectives?.edgeCount,
                  edges: [...chartData?.edges,...data?.AllCascadingObjectives?.edges],
                  totalCount: data?.AllCascadingObjectives?.totalCount
                }
                setChartData(obj)
            } else {
                setChartData(data?.OrganizationChartData?.employee?.organizationSet)
            }
          }
    }


    const getUpcomingMilestones = async (id,offset) => {
        let okrType = "MILESTONE"
        const { data } = await client.query({
          query: ORGANIZATION_UPCOMMING_MILESTONE_LIST,
          variables: {okr_Duedate_Range: dateRangeTofilter,createdBy_Ne:id, offset:offset, first: 5, tz: localTz },
          // fetchPolicy: 'network-only'
        });
    
        if (data?.UpcommingMilestones) {
            let dataList =data?.UpcommingMilestones?.employee?.organizationSet?.edges?.map(({node})=>node?.ceo?.okrContributorSet?.edges.map((item)=>item))
            let listData={
                edgeCount:data?.UpcommingMilestones?.employee?.organizationSet?.edgeCount,
                totalCount:data?.UpcommingMilestones?.employee?.organizationSet?.totalCount,
                edges:dataList[0]
            }
          setUpcomingMmilestones(listData)
        }
    
      }

      const getPerformanceRecord = async(first,offset,pagination=false)=>{
        setPerformanceDataloader(true)
        let id = organizationDetail?.ceo?.id
        const { data } = await client.query({
            query: ORGANIZATION_PERFORMANCE_DATA,
            variables: {
              okr_Duedate_Range: dateRangeTofilter,
              employee:id,createdBy:id, 
              offset: offset, first: first
            },
            // fetchPolicy: 'network-only'
          });
          if(data?.OrganizationPerformanceData){
            const performance= data?.OrganizationPerformanceData?.employee?.organizationSet?.edges?.find(({node})=>node?.id===organizationDetail?.id)
            if (pagination) {
                let obj = {
                  edgeCount: performanceData?.edgeCount + performance?.node?.ceo?.okrContributorSet?.edgeCount,
                  edges: [...performanceData?.edges,...performance?.node?.ceo?.okrContributorSet?.edges],
                  totalCount: performance?.totalCount
                }
                setPerformanceData(obj)
            } else {
                
                setPerformanceData(performance?.node?.ceo?.okrContributorSet)
            }
            setPerformanceDataloader(false)
          }
    }
    
    const getVertovalPerformanceRecord = async(first,offset,pagination=false)=>{
      setPerformanceDataloader(true) 
      // let id =organizationDetail?.ceo?.id
        const { data } = await client.query({
            query: ORGANIZATION_VERTICAL_DATA,
            variables: {
                okr_Duedate_Range: dateRangeTofilter, offset: offset, first: first
            },
            // fetchPolicy: 'network-only'
          });
          if(data?.OrganizationVerticalData){
            const verticalNode= data?.OrganizationVerticalData?.employee?.organizationSet?.edges?.find(({node})=>node?.id===organizationDetail?.id)
            let fetchedData={
              edgeCount:verticalNode?.node?.verticalSet?.edgeCount,
              totalCount:verticalNode?.node?.verticalSet?.totalCount,
              edges:getVerticalGroup(verticalNode?.node?.verticalSet?.edges?.map(({node})=>node))
            }
            if (pagination) {
                let obj = {
                  edgeCount: VerticalperformanceData?.edgeCount + fetchedData?.edgeCount,
                  edges: [...VerticalperformanceData?.edges,...fetchedData?.edges],
                  totalCount: fetchedData?.totalCount
                }
                setVerticalPerformanceData(obj)
            } else {
                setVerticalPerformanceData(fetchedData)
            }
            setPerformanceDataloader(false)
          }
    }

    const getVerticalGroup=(verticals)=>{
      
      let edges =verticals?.map(item=>{
        return {
          id:item?.id,
          title:item?.name,
          progress:calculateVerticalProgress(item?.heads?.edges)
        }
      }) 
      return edges || []
    }

    const calculateVerticalProgress = (heads)=>{
      console.log('====================================');
      console.log('heads tab',heads);
      console.log('====================================');
      let list = heads?.map(({node})=>{
        return node?.okrContributorSet?.edges?.map(({node})=>node?.progress)
        // ?.filter(item=>item?.node?.employee?.id === node?.id && item?.node?.createdBy?.id !==node?.id)
      })
      
      list=[].concat.apply([],list)
      let progress =list.reduce((a, b) => a+ b, 0)
      return (!progress || isNaN(progress)|| isNaN(progress/list?.length)||(progress/list?.length)===Infinity)?0:progress/list?.length

    }

    return(
        <>
            <OrganizationDashboardView 
            performanceDataloader={performanceDataloader}
            getPerformanceRecord={(first,offset,pagination)=>{getPerformanceRecord(first,offset,pagination)}}
            getVertovalPerformanceRecord={(first,offset,pagination)=>{getVertovalPerformanceRecord(first,offset,pagination)}}
            getAllNonCascadedObjectives={(offset,pagination)=>{getNonCascardRecord(offset,pagination)}}
           {...props} chartData={chartData} upcomingMilestones={upcomingMilestones} performanceData={performanceData} VerticalperformanceData={VerticalperformanceData}/>
        </>
    )
   
}
export default compose(withApollo)(OrganizationDashboardContainer)