import React from "react";
import TeamIdpView from "../components/teamIdpView";

const TeamIdpContainer  = (props)=>{

    return (
        <>
        <TeamIdpView {...props}/>
        </>
    )
}

export default TeamIdpContainer