import React from "react";
import { compose } from '../../core';
import { getIntFromString} from "../../look";
import { Row, Col, Button,message,Modal  } from "antd";
import {  withCreateDiscardRequest } from "../container/objective-keyresult-operations";
import Blue_tick from '../../assets/blue-tick.svg'
import close_small from '../../assets/close-small.svg'
import styled from "styled-components";

const RequestDiscard = props => {
    const {discardData,createDiscardRequest,visible,onClose,discardedSuccess}=props
    const [rationalText,setRational]=React.useState()
    const [submitted,setSubmitted]=React.useState(false)
    const rationalValue=(e)=>{
        setRational(e.target.value)
    }

    const Discard = async()=>{
        if(rationalText){
            let discardRequestData
            discardRequestData={
                okrId:getIntFromString(discardData?.id),
                initiatorsRational:rationalText
            }
            const response =await createDiscardRequest({discardRequestData})
            if(response?.id){
                setSubmitted(true)
            }
        }
        else{
            message.error(" rationale is required !")
        }
    }
    const cancel =()=>{
        onClose()
    }

    return (
        <>
           <DiscardModal
              footer={null}
              visible={visible}
              centered = {true}
              closable={false}
              destroyOnClose={true}
              width={600}
              className='custom-new-modal'>
                <Col >
                {submitted&& (
                    <div align="end" style={{width:"100%"}}>
                        <img style={{cursor:'pointer'}} src={ close_small} alt="" onClick={()=>{discardedSuccess(discardData);setSubmitted(false)}}/>
                    </div>
                )
                }
                
                {!submitted?  <Col>
                <h3 className="modal-head">Raise Discard Request</h3>
                    <p className="modal-sub-head">{discardData?.title}</p>
                    <Col>
                        <p className="modal-sub-head">Are you sure you want to discard this {discardData?.title} ?</p>
                        <p className="modal-sub-head" style={{margin:0}}><span>Note</span>: Your manager will be notified with the <br/> request along with your rationale for it.</p>
                        <textarea className="modal-textarea" placeholder="Rationale for discarding:" onChange={rationalValue}></textarea>
                        <Row style={{width:"100%",gap:"20px",paddingTop:"20px"}} justify="center">
                            <Button className="modal-cancel-btn poppins" onClick={cancel}>Cancel</Button>
                            <Button className="modal-submit-btn poppins" onClick={Discard}>Send</Button>
                        </Row>
                    </Col>
                </Col>:
                <Col>
                 <h3 className="modal-head"><img src={Blue_tick}style={{paddingRight:'10px'}}/> Sent</h3>
                 <p className="modal-sub-head">Your discard request has been successfully created and sent to your manager. You can track the details in your Requests Panel.</p>
                </Col>}
                </Col>
            </DiscardModal>
        
        </>
    )
}
export default compose( withCreateDiscardRequest)(RequestDiscard)



const DiscardModal = styled(Modal)`
.ant-modal-body{
    padding:40px;
}

`;