import gql from 'graphql-tag';
import { WEIGHTED_QUESTION_TYPE_CONNECTION } from './WeightedQuestionTypeConnection.gql';

export const OPERAND_TYPE = gql`
  ${WEIGHTED_QUESTION_TYPE_CONNECTION}
  fragment OperandType on OperandType {
    id
    note
    weightedQuestions {
      ...WeightedQuestionTypeConnection
    }
    defaultOperandValue
    operation
  }
`;
