import gql from "graphql-tag";

export const USER_EMPLOYEE_QUERY = gql`
query allOrganizationEmployees($user_FirstName:String,$user_LastName:String){
  allEmployee:allOrganizationEmployees(user_FirstName:$user_FirstName,user_LastName:$user_LastName) {
    edges {
      node {
        id
        user{
          firstName
          lastName
          id
        	employee{
            id
          }
        }
        
      }
    }
  }
}
`;
