import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Layout, PageHeader, Select, Skeleton, Row, Button, Col, Form, Empty, Modal, Tooltip, Space, Spin } from 'antd';
import { LeftCircleOutlined, InfoCircleOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { ButtonsCatch, getIntFromString, ReactMarkdown, SelectField } from '../../look';
import { useHistory } from 'react-router-dom';
import homepageContent  from '../../page/containers/homePageContent'

const EditSurveyResponseQuestionBased = (props) => {
    const { Header, Content, Footer, Sider, } = Layout;
    const { Option } = Select;
    const {
        loading,
        qa,
        allOpts,
        editResponse,
        responseId,
        sId,
        sname,
        createResponse
    } = props;
    const [submitLoading, setSubmitLoading] = React.useState(false);
    const [updatedAns, setUpdatedAns] = React.useState({});
    const history = useHistory();
    const [form] = Form.useForm();

    var timer = 0

    useEffect(() => {
        let timerInterVal = setInterval(() => { 
            timer = timer + 1
        }, 1000);
        return () => {
            clearInterval(timerInterVal)
            timer = 0
        }
    }, [loading])



    const handleSubmit = async () => {
        setSubmitLoading(true);
        
        if (responseId){
            let ansToSend = []
            Object.entries(updatedAns).forEach(item => {
                ansToSend.push({ 'questionId': item[0], answer: item[1]?.value, 'responseDuration': timer })
            })
            try {
                await editResponse({ answers: ansToSend, responseId: getIntFromString(responseId) });
                let param=props.match.params.surveyType;
                if(param){
                    let data
                    if(param==='personality'){
                        data=homepageContent['personalities']
                    }
                    else if(param==='knowledge-and-skills'){
                        data=homepageContent['knowledge_and_skills']
                    }
                    else{
                        data=homepageContent[param]
                    }
                    if(data){
                        history.push(data?.button2?.navigateTo)
                    }
                }
                

            } catch (e) {
                setSubmitLoading(false);
                throw new Error(e.message);
            }

        } else{
            let ansToSend = []
            Object.entries(updatedAns).forEach(item => {
                ansToSend.push({ 'questionId': item[0], answer: item[1]?.value})
            })
            try {
                await createResponse({ answers: ansToSend, surveyId: getIntFromString(sId), totalDuration: timer });
                let param=props.match.params.surveyType;
                if(param){
                    let data
                    if(param==='personality'){
                        data=homepageContent['personalities']
                    }
                    else if(param==='knowledge-and-skills'){
                        data=homepageContent['knowledge_and_skills']
                    }
                    else{
                        data=homepageContent[param]
                    }
                    if(data){
                        history.push(data?.button2?.navigateTo)
                    }
                }
            } catch (e) {
                setSubmitLoading(false);
                throw new Error(e.message);
            }
        }
        
        setSubmitLoading(false);
    };

    function onSelectChange(id, k) {
        let qid = getIntFromString(id)
        setUpdatedAns(prevInfo => ({ ...prevInfo, [qid]: { 'value': k } }))
    }

    function onInputChange(id, e) {
        if (e.target.value) {
            let qid = getIntFromString(id)
            setUpdatedAns(prevInfo => ({ ...prevInfo, [qid]: { 'value': e.target.value } }))
        }
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>
            <Spin spinning={submitLoading} size="small">
                <Row justify="center">
                    <Col
                        span={20}
                        style={{
                            border: "1px solid #EBEBEB",
                            borderRadius: 10,
                            padding: "50px 0px",
                            paddingBottom: 80,
                            backgroundColor: "#fff",
                        }}
                    >
                        <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <PageHeader
                                    className="site-page-header"
                                    backIcon={false}
                                    style={{ fontWeight: 'bold' }}
                                    title={`${sname}`}
                                />
                            </Col>

                            <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ textAlign: 'end' }}>
                                <Button
                                    shape="circle"
                                    onClick={() => history.goBack()}
                                    style={{ margin: "2em" }}
                                >
                                    <ArrowLeftOutlined />
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} style={{ minHeight: '5em' }}></Col>
                        </Row>
                        <Row>
                            <Row style={{ width: '100%' }}>
                                <Col span={24}>
                                    <Content style={{ width: '100 %' }}>
                                        <Form form={form} name="control-hooks" onFinish={handleSubmit} onFinishFailed={onFinishFailed}>
                                            <Row style={{ display: 'flex', justifyContent: 'space-between', marginLeft: '4vw', marginRight: '4vw' }}>
                                                
                                                {qa.map((q, idx) => {
                                                    return (
                                                        <>
                                                            <Col xs={24} sm={24} md={8} lg={8} xl={8} style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: '2vh' }}>
                                                                <SelectField
                                                                    name={q.id}
                                                                    label={<ReactMarkdown>{q.q}</ReactMarkdown>}
                                                                    rules={[{ required: q.required || false, message: 'Value is required!' }]}
                                                                    otherOption={true}
                                                                    initialValue={q.a}
                                                                    showSearch
                                                                    choices={allOpts[q.id]}
                                                                    onBlur={(v) => { onInputChange(q.id, v) }}
                                                                    optionFilterProp="children"
                                                                    onChangeSelect={(v, k) => { onSelectChange(q.id, v) }}
                                                                    filterOption={(input, option) =>
                                                                        option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                    }
                                                                >
                                                                </SelectField>
                                                            </Col>
                                                            {idx % 2 != 0 && (
                                                                <div style={{ flexBasis: '100%', height: '0' }}>
                                                                </div>
                                                            )}
                                                        </>
                                                    )
                                                })

                                                }

                                            </Row>
                                            <Row style={{ display: 'flex', justifyContent: 'center' }}>
                                                <Col span={3} style={{ minHeight: '12em', textAlign: 'center' }}>
                                                    <Form.Item>
                                                        <Button
                                                            type="primary" htmlType="submit" style={{ marginTop: '5em' }}>Save And Go To Step 2</Button>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Content>
                                </Col>
                            </Row>
                        </Row>
                    </Col>
                </Row>
            </Spin>
        </>
    )
}

export default EditSurveyResponseQuestionBased;