import gql from 'graphql-tag';
import { TOKEN_TYPE } from './TokenType.gql';

export const DELETE_TOKEN_UNDER_SURVEY = gql`
  mutation deleteToken($token: String!) {
    deleteToken(token: $token) {
      token {
        id
      }
    }
  }
`;
