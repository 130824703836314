import gql from 'graphql-tag';
import { SURVEY_TYPE, SURVEY_TYPE_GROUP_SET, SURVEY_TYPE_NO_FILTER_GROUP_SET } from './SurveyType.gql';
import { ANSWER_TYPE_CONNECTION } from './AnswerTypeConnection.gql';

export const RESPONSE_TYPE = gql`
  ${SURVEY_TYPE}, ${ANSWER_TYPE_CONNECTION}
  fragment ResponseType on ResponseType {
    id
    survey {
      ...SurveyType
    }
    responseDuration
    responseDate
    norm
    answerSet {
      ...AnswerTypeConnection
    }
  }
`;


export const RESPONSE_TYPE_GROUP_SET = gql`
  ${SURVEY_TYPE_NO_FILTER_GROUP_SET}, ${ANSWER_TYPE_CONNECTION}
  fragment ResponseType on ResponseType {
    id
    survey {
      ...SurveyType
    }
    responseDuration
    responseDate
    norm
    answerSet {
      ...AnswerTypeConnection
    }
  }
`;
