import gql from 'graphql-tag';
import { TOOL_TYPE } from './ToolType.gql';

export const TOOL_SUBSCRIPTION = gql`
  ${TOOL_TYPE}
  subscription toolsSubscription {
    toolsSubscription {
      mutation
      tool {
        ...ToolType
      }
    }
  }
`;
