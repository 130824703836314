import gql from 'graphql-tag';

export const DELETE_MEETING_SERIES_MUTATION = gql`
  mutation  deleteMeetingSeries($seriesId: String!){ 
    deleteMeetingSeries(seriesId:$seriesId){
      meeting{ 
        id  
    } 
    }
    
  }
`;
