// @ts-nocheck
import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Select, Spin } from "antd";
import React from 'react';
import dropdown_Arrow from '../../assets/dropdown_Arrow.svg';
import { displayUtcToUserTimezone, getIntFromString, MetaTags } from '../../look';
import Back_icon from '../../assets/soul-back.svg';
import GenericToolView from "modules/tool/components/GenericToolView";
import survey_route from "../route";
import { SURVEY_ID_3CS } from "config";


const ThreeCSReportView = (props) => {
    const { response_list, viewReport, api_loading, getToolById,navigateRoute,ResponseList3cs } = props
    const [enable_btn, SetEnableBtn] = React.useState(false)
    const [selected_response_id, SetRespponseId] = React.useState()

    React.useEffect(() => {
        if (getToolById) {
            SetEnableBtn(false)
        }
    }, [getToolById])

    const selectRes = (id) => {
        SetRespponseId(id)
        SetEnableBtn(true)
    }

    return (
        <Spin spinning={api_loading}>
            <MetaTags title="3C Report" />
            <div className="scape-heading-container" style={{justifyContent:"center"}}>
              
                <h4 className="scape-main-title" style={{textAlign:"center"}}>3C Report</h4>
             
            </div>
            <div className="cs-col-section">
                <div className="cs-action-container">
                    <Select
                        suffixIcon={<img src={dropdown_Arrow} alt="" style={{ width: "13px", height: "10px", marginTop: "-5px" }} />}
                        className='cs-dropdown-employee'
                        placeholder="Select Employees"
                        style={{ textAlign: "left", cursor: "pointer" }}
                        maxTagCount={1}
                        onChange={(value, options) => { selectRes(value) }}
                        id="select-employee"
                        showSearch={true}
                        showArrow={true}
                        onSearch={e=>ResponseList3cs({search:e})}
                        filterOption={false}
        
                    >

                        {
                            response_list?.map(item => {
                                return <Select.Option key={item?.id} value={item?.id} >{item?.user_name} ({displayUtcToUserTimezone(item?.response_date, 'DD MMM YYYY')})</Select.Option>
                            }
                            )
                        }

                    </Select>
                </div>

                <div className="cs-action-container">
                    <Button className='cs-action-button' disabled={(!enable_btn)} onClick={() => { viewReport(selected_response_id) }}>View Report</Button>
                    <Button className='cs-action-button' onClick={()=> navigateRoute(`${survey_route?.surveyQuiz3cs?.replace(":id",SURVEY_ID_3CS?.toString())}` )} >Take 3C Assessment</Button>
                </div>
            </div>

            {
                getToolById && (
                    <div style={{ width: "100%", marginTop: "2em" }}>
                        <GenericToolView {...props} getToolById={getToolById} loading={api_loading} />
                    </div>
                )
            }

        </Spin>
    )
}
export default ThreeCSReportView
