import gql from 'graphql-tag';

export const LOOK_FORM_SECTION_STATE_QUERY = gql`
  query lookFormSectionState {
    lookFormSectionState @client {
      filter {
        title_Icontains
      }
      orderBy
    }
  }
`;
