import React from "react";
import CustomSoulReportFilterView from "../components/custom-soul-report-filter-view";
import { compose } from "modules/core";
import { withApollo } from '@apollo/client/react/hoc';
import { REPORT_FILTER_QUERY } from "../graphql/CompletedSurvey.gql";

const CustomSoulReportFilterContainer =(props)=>{
    const {me,client} = props;
    const [values,setValues]=React.useState([])
    const [strengths,setStrengths]=React.useState([])
    const [knowledge,setKnowledge]=React.useState([])
    const [filter_loading,setFilterLoading] = React.useState(false)
    React.useEffect(()=>{
        if(me){
            getFilterData()
        }
    },[me])
    const removeDuplicates =(values)=>{
        var setObj = new Set();
        var result = values?.reduce((acc, item) => {
            if (!setObj.has(item?.value)) {
                setObj.add(item?.value)
                acc.push(item)
            }
            return acc;
        }, [])
        return result;
    }
    const getFilterData =async(filter)=>{
        try {
            setFilterLoading(true)
            const { data } = await client.query({
                query: REPORT_FILTER_QUERY,
                variables: {
                    value_id:"U3VydmV5VHlwZToz",
                    strength_id:"U3VydmV5VHlwZTo3",
                    knowledge_id:"U3VydmV5VHlwZTo5",
                    ...filter},
                fetchPolicy: 'network-only'
            });
            if (data) {
                let value_choice = data?.values?.groupSet?.edges?.flatMap(({ node }) =>
                    node?.questionSet?.edges?.flatMap(item =>
                        item?.node?.choiceSet?.edges?.map(value => value?.node)
                    )
                );
                let values =removeDuplicates(value_choice?.filter(item=>item?.value!=' ' && item?.value!='0:10:1:0'))
                let strength_choice = removeDuplicates(data?.strength?.groupSet?.edges?.flatMap(({ node }) =>
                    node?.questionSet?.edges?.flatMap(item =>
                        item?.node?.choiceSet?.edges?.map(value => value?.node))
                    )
                );
                let knowledge_choice = removeDuplicates(data?.knowledge?.groupSet?.edges?.flatMap(({ node }) =>
                    node?.questionSet?.edges?.flatMap(item =>
                        item?.node?.choiceSet?.edges?.map(value => value?.node))
                    )
                );
                setValues(values)
                setStrengths(strength_choice)
                setKnowledge(knowledge_choice)
                setFilterLoading(false)
            }
        }
        catch(e){

        }
    }
    return (
        <>
        <CustomSoulReportFilterView 
            {...props}
            values={values}
            strengths={strengths}
            knowledge={knowledge}
            filter_loading={filter_loading}
            getFilterData={(filter)=>getFilterData({...filter})}
        />
        </>
    )
}

export default compose(withApollo)(CustomSoulReportFilterContainer);