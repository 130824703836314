import React from 'react'
import { Col, DatePicker, Row, Spin,Dropdown,Menu,Button,Input,Form, Tooltip } from 'antd';
import styled from "styled-components";
import moment from 'moment/moment';
import { displayUtcToUserTimezone,customTabSelectionForDate } from 'modules/look/components/functions';
import dropdown_Arrow from '../../assets/dropdown_Arrow.svg'

const CustomOkrRange =(props)=>{
    const {disabledRange,defaultValue,selectedDateRange,disabledType,name,currentSelected,...rest}=props
    console.log("disabledRange",disabledRange)
    const FormItem = Form.Item;
    const { RangePicker } = DatePicker;
    const [dateRageOpen,setRageOpen]=React.useState(false)
    const [currentQuarterList,setcurrentQuarterList]=React.useState([])
    const [loadedYear,setLoadedYear]=React.useState(5)
    const [currentYearsList,setcurrentYearsList]=React.useState([])
    const [selectedValue,setSelectedValue]=React.useState(defaultValue)
    const [selectedQuarter,setSelectedQuarter]=React.useState()
    const [selectedYear,setSelectedYear]=React.useState(currentSelected?new Date().getFullYear():null)
    const [selectedHalfValue,setSelectedHalf]=React.useState()
    const [selectedStartDate,setSelectedStartDte]=React.useState()
    const [selecionClear,setSelectionClear]=React.useState(false)
    const [LoadMoreYear,setLoadMoreYear]=React.useState(false)
    React.useEffect(()=>{
        customTabSelectionForDate()
        let today 
        let current_quarter
        let current_year
        let loopCount
        if(disabledRange!=undefined){
            today=disabledRange?.startdate
            current_quarter= Math.ceil(((disabledRange?.startdate.getMonth()+1)/3))
            current_year=today.getFullYear()
            if(new Date(disabledRange?.duedate).getFullYear()==current_year){
                loopCount=Math.ceil(((new Date(disabledRange?.duedate).getMonth()+1)/3))
            }
            else{
                loopCount=4
            }
        }
        else{
            today = new Date()
            current_quarter = Math.ceil(((today.getMonth()+1)/ 3));
            current_year=today.getFullYear()
            loopCount=4
        }
        let quaterly_list=[{
            label:`Q${current_quarter}-${current_year}`,
            key:current_quarter,
            year:current_year
        }]
            if( current_quarter!=4){
                for(let i=current_quarter+1;i<=loopCount;i++){
                    quaterly_list.push({
                        label:`Q${i}-${current_year}`,
                        key:i,
                        year:current_year
                    })
                }
            }
            else {
                for(let i=1;i<=loopCount;i++){
                    quaterly_list.push({
                        label:`Q${i}-${current_year+1}`,
                        key:i,
                        year:current_year+1
                    })
                }
            }
        setcurrentQuarterList(quaterly_list)
    },[disabledRange])
    const getHalfYear =(year)=>{
        let today 
        let current_half
        let current_year
        let loopCount 
        if(disabledRange!=undefined){
            today=disabledRange?.startdate
            current_half= Math.ceil(((disabledRange?.startdate.getMonth()+1)/6))
            current_year=today.getFullYear()
            if(new Date(disabledRange?.duedate).getFullYear()==year && new Date(disabledRange?.duedate).getMonth()+1<6){
                loopCount=1
            }
            else{
                loopCount=2
            }
        }
        else{
            loopCount=2
            today = new Date()
            current_half = Math.ceil(((today.getMonth()+1)/ 6));
            current_year=today.getFullYear()
        }
        let half_list=[{
            label:`H${current_half }-${year}`,
            key:current_half ,
            year:year
        }]
        if( current_half!=2){
            for(let i=current_half+1;i<=loopCount;i++){
                half_list.push({
                    label:`H${i}-${year}`,
                    key:i,
                    year:year
                })
            }
        }
        else {
            for(let i=1;i<=loopCount;i++){
                half_list.push({
                    label:`H${i}-${year}`,
                    key:i,
                    year:year
                })
            }
        }
        return half_list
    }
    React.useEffect(()=>{
        let years=[]
        let today = new Date()
        let current_year=today.getFullYear()
        if(disabledRange!=undefined){
            today=disabledRange?.startDate
            let lastYear =new Date(disabledRange?.duedate).getFullYear()
            if(!LoadMoreYear){
                if(lastYear > new Date().getFullYear()){
                    setLoadedYear(lastYear-new Date().getFullYear())
                }
                else{
                    setLoadedYear(0)
                }
            }
        }
            for(let i=0;i<=loadedYear;i++){
                years.push({
                    year:current_year+i,
                    half:getHalfYear(current_year+i),
                    key:current_year+i
                })
            }
        setcurrentYearsList(years)
        
    },[loadedYear])
    const selecteQuaterlyDate =(quarter)=>{
        // setSelectedYear()
        // setSelectedHalf()
        setSelectedQuarter(quarter)
        let start_date
        let end_date
        if(disabledRange!=undefined){
            start_date=moment().year(quarter?.year).quarter(quarter?.key).startOf("quarter")<moment(disabledRange?.startdate)?moment(disabledRange?.startdate):moment().year(quarter?.year).quarter(quarter?.key).startOf("quarter")
            end_date= end_date=moment().year(quarter?.year).quarter(quarter?.key).endOf("quarter")>moment(disabledRange?.duedate)?moment(disabledRange?.duedate):moment().year(quarter?.year).quarter(quarter?.key).endOf("quarter")
        }
        else{
            if(moment(moment().year(quarter?.year).quarter(quarter?.key).startOf("quarter")).isBefore(moment())&&disabledType){
                start_date=moment()
                end_date=moment().year(quarter?.year).quarter(quarter?.key).endOf("quarter")
            }
            else{
                start_date=moment().year(quarter?.year).quarter(quarter?.key).startOf("quarter")
                end_date=moment().year(quarter?.year).quarter(quarter?.key).endOf("quarter")
            }
        }
        setSelectedValue([start_date,end_date])
        // SaveSelectedDate([start_date,end_date])
    }

    const selectedYearDate =(value)=>{
        // setSelectedQuarter()
        // setSelectedHalf()
        setSelectedYear(value?.year)
        let start_date
        let end_date
        if(disabledRange!=undefined){
            start_date=moment().year(value?.year).startOf("year")<moment(disabledRange?.startdate)?moment(disabledRange?.startdate):moment().year(value?.year).startOf("year")
            end_date= end_date=moment().year(value?.year).endOf("year")>moment(disabledRange?.duedate)?moment(disabledRange?.duedate):moment().year(value?.year).endOf("year")
        }
        else{
            if(disabledType){
                start_date=moment()
                end_date=moment().year(value?.year).endOf("year")
            }
            else{
                start_date=moment().year(value?.year).startOf("year")
                end_date=moment().year(value?.year).endOf("year")
            }
        }
        setSelectedValue([start_date,end_date])
        // SaveSelectedDate([start_date,end_date])
    }
    const selectedHalf=(value)=>{
        setSelectedHalf(value)
        let start_date
        let end_date
        if(value?.key===1){
            if(disabledRange!=undefined){
                start_date=moment().year(value?.year).quarter(value?.key).startOf("quarters")<moment(disabledRange?.startdate)?moment(disabledRange?.startdate):moment().year(value?.year).quarter(value?.key).startOf("quarters")
                end_date= moment().year(value?.year).quarter(2).endOf("quarters")>moment(disabledRange?.duedate)?moment(disabledRange?.duedate):moment().year(value?.year).quarter(2).endOf("quarters")
            }
            else{
                if(moment(moment().year(value?.year).quarter(value?.key).startOf('quarters')).isBefore(moment())&&disabledType){
                    start_date=moment()
                    end_date=moment().year(value?.year).quarter(2).endOf("quarters")
                }
                else{
                    start_date=moment().year(value?.year).quarter(value?.key).startOf("quarters")
                    end_date=moment().year(value?.year).quarter(2).endOf("quarters")
                }
            }
            
        }
        else if(value?.key===2){
            if(moment(moment().year(value?.year).quarter(3).startOf('quarters')).isBefore(moment())){
                start_date=moment()
                end_date=moment().year(value?.year).quarter(4).endOf("quarters")
            }
            else{
                start_date=moment().year(value?.year).quarter(3).startOf("quarters")
                end_date=moment().year(value?.year).quarter(4).endOf("quarters")
            }
        }
        
        setSelectedValue([start_date,end_date])
        // SaveSelectedDate([start_date,end_date])
    }
    const loadMoreQuartor =(yearList)=>{
        let selected_years =yearList?.map((item)=>item?.year)
        let quaterly_list=[]
        if(disabledRange!=undefined){
            if(new Date(disabledRange?.duedate).getFullYear()>Math.max(...selected_years)+1){
                for(let i=1;i<=4;i++){
                    quaterly_list.push({
                        label:`Q${i}-${Math.max(...selected_years)+1}`,
                        key:i,
                        year:Math.max(...selected_years)+1
                    })
                }
            }
            else if(new Date(disabledRange?.duedate).getFullYear()==Math.max(...selected_years)+1){
                let loopCount=Math.ceil(((new Date(disabledRange?.duedate).getMonth()+1)/3))
                for(let i=1;i<=loopCount;i++){
                    quaterly_list.push({
                        label:`Q${i}-${Math.max(...selected_years)+1}`,
                        key:i,
                        year:Math.max(...selected_years)+1
                    })
                }
            }
        }
        else{
            for(let i=1;i<=4;i++){
                quaterly_list.push({
                    label:`Q${i}-${Math.max(...selected_years)+1}`,
                    key:i,
                    year:Math.max(...selected_years)+1
                })
            }
        }
        
        setcurrentQuarterList([...currentQuarterList,...quaterly_list])
    }

    const datefilter=(e)=>{
        if(e && e?.length===2){
            let okr_Duedate_Range = [moment(e[0]).startOf('day'),moment(e[1]).endOf('day')]
            setSelectedValue(okr_Duedate_Range)
        }
      }
    const dateChangeFilterData =(e)=>{
        if(e&&e?.length==2){
            if(e[0]!==null){
            setSelectedStartDte(e[0])
            }
        }
    }
    const SaveSelectedDate=(value)=>{
        if(value && value?.length===2){
            let selected_range = [moment(value[0]),moment(value[1])]
                selectedDateRange(selected_range)
                setRageOpen(false)
        }
    }
    const findCurrentYear=(yearList)=>{
        let selected_years =yearList?.map((item)=>item?.year)
        return Math.max(...selected_years)
    }
    const RageFilter =()=>(
            <Menu id="custom-date-rage" className='custom-menu' style={{maxWidth:"600px",position:"relative",minWidth:"600px",height:disabledRange==undefined?'350px':'330px'}}>
                <Row  style={{display:'flex',flexDirection:"row",justifyContent:"space-between"}}>
                        <Col className='custom-datePicker-container' span={19}>
                        <RangePicker
                            open={dateRageOpen}
                            allowClear={false}
                            dropdownClassName = {'custom-date'}
                            defaultValue={defaultValue}
                            value={selectedValue}
                            format={'DD/MMM/YY'}
                            disabledDate={current => {
                                return current.isBefore(moment(selectedStartDate)) ;
                              }}
                            onChange={(e)=>{datefilter(e)}}
                            onCalendarChange={(e)=>{datefilter(e)}}
                            className="date-range-okr"
                            // suffixIcon={<img src={dropdown_Arrow} alt=""/>}
                        />
                        </Col>
                    <Col  span={5} style={{display:"flex",flexDirection:"column",gap:"10px",marginTop:"20px",paddingRight:"5px"}}>
                        <div className='overflow-selection' style={{minHeight:"110px"}}>
                            <div style={{display:'flex',flexDirection:"column",width:"100%",justifyContent:'center',alignContent:"center",textAlign:'center'}}>
                                {currentQuarterList?.map((item)=>(
                                    <div className={item?.key===selectedQuarter?.key&&item?.year===selectedQuarter?.year?'seletion-title-selected':'seletion-title'} onClick={()=>selecteQuaterlyDate(item)}>{item?.label}</div>
                                ))}
                            </div>
                        </div>
                        {disabledRange==undefined?<h4 className='see-more' onClick={()=>loadMoreQuartor(currentQuarterList)}>Show More</h4>: (new Date(disabledRange?.duedate).getFullYear() >findCurrentYear(currentQuarterList))?<h4 className='see-more' onClick={()=>loadMoreQuartor(currentQuarterList)}>Show More</h4>:<h4 className='see-more'></h4>}
                        <hr/>
                        <div className='overflow-selection'  style={{minHeight:"110px"}}>
                        {currentYearsList?.map((item)=>(
                             <>
                             <div>
                                 {item?.half?.map((data)=>(
                                <Tooltip title={data?.key==1?`1st Half of ${data?.year}`:`2nd Half of ${data?.year}`}><div style={{whiteSpace:'nowrap'}}  className={data?.key===selectedHalfValue?.key&&data?.year===selectedHalfValue?.year?'seletion-title-selected':'seletion-title'}  onClick={()=>selectedHalf(data)}>{data?.label}</div></Tooltip>
                                 ))}
                             </div>
                             <div style={{whiteSpace:'nowrap'}}  className={item?.year===selectedYear?'seletion-title-selected':'seletion-title'}  onClick={()=>selectedYearDate(item)}>Annual - {item?.year}</div>
                             </>
                        ))}
                        </div>
                       {disabledRange==undefined?<h4 className='see-more' onClick={()=>setLoadedYear(loadedYear+5)}>Show More</h4>:<h4 className='see-more'></h4>}
                    </Col>
                </Row>
                <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:'center',gap:"24px",padding:"0px 20px",marginBottom:"5px",marginTop:"-65px"}}>
                    <div style={{display:'flex',flexDirection:"row",justifyContent:'center',alignItems:"center",gap:"20px"}}>
                            {selectedValue?.length&&selectedValue[0]?<Tooltip title='Click below to edit Start date'><div style={{display:"flex",flexDirection:'column',justifyContent:"center",alignItems:'center',zIndex:1000,cursor:"pointer"}}>
                                <h4 className='date-label-head' style={{fontSize:"12px",margin:"0"}}>Start Date</h4>
                            </div>
                            </Tooltip>
                            :<div style={{display:"flex",flexDirection:'column',justifyContent:"center",alignItems:'center'}}></div>
                            }
                            {selectedValue?.length&&selectedValue[1]?<Tooltip title='Click below to edit End date'>
                                <div style={{display:"flex",flexDirection:'column',justifyContent:"center",alignItems:'center',zIndex:1000,cursor:"pointer"}}>
                                <h4 className='date-label-head'style={{fontSize:"12px",margin:"0",paddingLeft:"5px"}}>End Date</h4>
                                </div>
                            </Tooltip>
                            :<div  style={{display:"flex",flexDirection:'column',justifyContent:"center",alignItems:'center'}}></div>
                            }
                    </div>
                    <div style={{display:"flex",flexDirection:"row",gap:"20px",justifyContent:'center',alignItems:"center",marginRight:"120px",marginTop:'10px'}}>
                        <Button className='custon-datepicker-btn-cancel'  onClick={() => {setRageOpen(false)}}>Cancel</Button>
                        <Button className='custon-datepicker-btn' onClick={()=>SaveSelectedDate(selectedValue)}>Save</Button>
                    </div>
                </div>
            </Menu>
    )
    return(
        <>
            <Dropdown overlay={RageFilter} visible={dateRageOpen} placement="bottomLeft">
                <DropdownButton >
                    <p className='selected-filter' style={{marginTop:'10px',width:'100%',textAlign:'center',paddingTop:"2px",fontSize:"14px",paddingRight:"5px",color:selectedValue?.length>0?"#303030":"#BBBBBB"}} onClick={()=>setRageOpen(true)}>{selectedValue?.length>0?displayUtcToUserTimezone(selectedValue[0], 'DD MMM YYYY')+" - "+displayUtcToUserTimezone(selectedValue[1], 'DD MMM YYYY'):'StartDate - EndDate'}</p>
                    <img  onClick={()=>setRageOpen(true)} src={dropdown_Arrow} alt="" style={{width:"13px",height:"10px"}}/>
                </DropdownButton>
            </Dropdown>
            {dateRageOpen ? (<div className='overlay-dynamic-island' onClick={() => setRageOpen(false)} />) : null}
        </>
    )
}
export default CustomOkrRange
const DropdownButton = styled(Button)`
// width:200px;
display:flex;
height:38px;
justify-content:space-between;
align-items:center;
gap:5px
`