import gql from 'graphql-tag';
import { TOKEN_TYPE } from './TokenType.gql';

export const DELETE_TOKEN = gql`
  ${TOKEN_TYPE}
  mutation deleteToken($token: String!) {
    deleteToken(token: $token) {
      token {
        ...TokenType
      }
    }
  }
`;

export const DELETE_TOKEN_OPTIMIZED = gql`
mutation deleteToken($token: String!) {
  deleteToken(token: $token) {
    token {
      id
    }
  }
}
`