import React from 'react';
import CreateNotificationContainer from './container/CreateNotificationContainer';
import NotificationViewAllContainer from './container/NotificationViewAllContainer';
import ROUTE from './route';

export default [
    {
      name: 'CreateNotification',
      path: ROUTE.createNotification,
      component: CreateNotificationContainer,
      protect:true,
    },
    {
      name: 'ViewCreateNotification',
      path: ROUTE.viewNotification,
      component: NotificationViewAllContainer,
      protect:true,
    }
]