import React from 'react';
import { Spin } from 'antd';

import { compose } from '../../../core';
import { ButtonsCatch, getIntFromString } from '../../../look';

import ROUTE from '../../route';
import AddEmployeeView from '../../components/addEmployeeView';

import { withAddEmployee, withOrganizationState } from '../../../organization/containers/OrganizationOperation';

const AddEmployee = props => {
    const { history, addEmployee } = props;
    const [submitLoading, setSubmitLoading] = React.useState(false);

    const handleSubmit = async (values, item) => {
        setSubmitLoading(true);
        if (!Array.isArray(values.userId)) {
            try {
                const response = await addEmployee({ employeeData: { userId: values.userId } });
                response && window.location.href.includes('closeonsave') && window.close();
                response &&
                    ButtonsCatch(
                        item,
                        ROUTE.employee,
                        history,
                        item === undefined ? `${ROUTE.editEmployeeLink}${getIntFromString(response.id)}` : window.location.pathname
                    );
            } catch (e) {
                throw new Error(e.message);
            }
        } else {
            await Promise.all(
                values?.userId?.map(async id => {
                    try {
                        const response = await addEmployee({ employeeData: { userId: id } });
                        response && window.location.href.includes('closeonsave') && window.close();
                    } catch (e) {
                        throw new Error(e.message);
                    }
                })
            );
            ButtonsCatch(item, ROUTE.employee, history, item === undefined ? `${ROUTE.employee}` : window.location.pathname);
        }

        setSubmitLoading(false);
    };

    return (
        <Spin spinning={submitLoading} size="large">
            <AddEmployeeView onSubmit={handleSubmit} {...props} />
        </Spin>
    );
};

export default compose(withOrganizationState, withAddEmployee)(AddEmployee);
