import gql from "graphql-tag";

export const CREATE_RESPONSE_COMPARE = gql`
mutation createResponseCompareRequest($compareRequestData: ResponseCompareRequestInput!){
    createResponseCompareRequest(compareRequestData:$compareRequestData){
        compareRequest{
            id
        }
    }
}
`