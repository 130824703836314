import gql from 'graphql-tag';

export const UPDATE_COMPETENCY_SKILL = gql`
mutation updateCompetency($competencyType: String,$description: String,$id: Int!,$title: String){
    updateCompetency(competencyType: $competencyType,description:$description,id:$id,title:$title){
    competency{
      id
    }
  }
}
`