import gql from 'graphql-tag';
import { FAQ_CATEGORY_TYPE } from './FaqCategoryType.gql';

export const FAQ_CATEGORY_SUBSCRIPTION = gql`
  ${FAQ_CATEGORY_TYPE}
  subscription faqCategorySubscription {
    faqCategorySubscription {
      mutation
      faqCategory {
        ...FaqCategoryType
      }
      __typename
    }
  }
`;
