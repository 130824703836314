import React from 'react';
import IDPDetailView from '../components/idpDetailView';
import { getIdpBase64StringFromInt, getIntFromString } from 'modules/look';
import { IDP_DETAIL_BY_ID, PUBLIC_IDP_DETAIL_BY_ID } from '../graphql/idpDetail.gql';
import { compose } from '../../core';
import { withApollo } from '@apollo/client/react/hoc';
import logo from '../../assets/new-map-logo.svg';
import { withPublicUpdateIdp } from '../idpOperations';

const IDPPublicManagerComtainer = (props) => {
    const { match, client, externalApprovalIdp } = props
    const [idp_detail, setIdpDetail] = React.useState()
    const [idp_detail_loading, setIdpLoading] = React.useState(true)
    let token_ref = React.useRef()
    React.useEffect(() => {
        if (match?.params?.idp_id && match?.params?.token) {
            let id = match?.params?.idp_id
            let token = match?.params?.token
            token_ref.current = token
            getIdpById({id,token})
        }
    }, [match])

    const getIdpById = async (filter) => {
       try {
        setIdpLoading(true)
        const { data } = await client.query({
            query: PUBLIC_IDP_DETAIL_BY_ID,
            variables: filter,
            fetchPolicy: 'network-only'
        });
        if (data?.pubidpById?.edges?.[0]?.node) {
            console.log('node',data?.pubidpById?.edges?.[0]?.node);
            setIdpDetail(data?.pubidpById?.edges?.[0]?.node)
            setIdpLoading(false)
        }
        
       } catch (error) {
        
       }
    }

    const OnSubmitIDP = async (data) => {
        try {
            setIdpLoading(true)
            let update = { ...data, id: getIntFromString(idp_detail?.id) ,token:token_ref?.current}
            delete update?.approverId
            delete update?.sendTo
            const response = await externalApprovalIdp(update)
            if (response?.id) {
                getIdpById({id:getIntFromString(idp_detail?.id),token:token_ref?.current})
            } else {
                setIdpLoading(false)
            }

        } catch (error) {
            setIdpLoading(false)
        }
    }

    return (
        <>
            <div>
                <div className='idp-public-header'>
                    <img src={logo} alt="logo" />
                </div>
                <div className='idp-public-container'>
                    <IDPDetailView
                        idp_detail={idp_detail}
                        idp_detail_loading={idp_detail_loading}
                        OnSubmitIDP={OnSubmitIDP}
                        public_manager={true}
                        {...props} />
                </div>

            </div>
        </>
    )
}
export default compose(withApollo, withPublicUpdateIdp)(IDPPublicManagerComtainer)