import * as React from 'react';

import { compose } from '../../core';

import { withAllFaqCategories, withFaqSearchQuery, withPageState, withPageFilterUpdating } from './PageOperations';
import FaqPageView from '../components/FaqPageView';
import { subscribeToFAQPage } from './PageSubscriptions';

const FaqPage = compose(
  withPageState,
  withFaqSearchQuery,
  withAllFaqCategories,
  withPageFilterUpdating
)(props => {
  React.useEffect(() => {
    const subscribe = subscribeToFAQPage(props.subscribeToMore /* , props.filter */);
    return () => subscribe();
  });

  return <FaqPageView {...props} />;
});

const FaqPageWrapper = props => {
  return <FaqPage {...props} addFilter={{ active: true }} />;
};

export default FaqPageWrapper;
