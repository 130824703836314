import React from 'react';
import { Row, Col, Card, Divider, Button } from 'antd';
import styled from 'styled-components';
import { withAllPublishedStories, withStoryState } from '../../story/containers/StoryOperations';
import { compose } from '../../core';
import { Link } from 'react-router-dom';

import STORY_ROUTES from '../../story/route';
import { getIntFromString, globalPermissionValidator } from '../../look';
import { IMG, USER_IMG } from '../../../config';
import CompareYourScore from '../containers/CompareYourScore';
import { story_permission } from 'Permissions/story.permission';

const TeamComponent = props => {
  const { me, allPublishedStories,userPermission } = props;
  const [permission,setpermission]=React.useState()
  React.useEffect(()=>{
    if(userPermission?.length){
      let permission_list=globalPermissionValidator(story_permission,userPermission)
      setpermission(permission_list)
    }
    else{
      setpermission({})
    }
  },[userPermission])
  return (
    <>
      {me && (
        <>
          {permission && permission.story_optional_permission&&<Col lg={{ span: 8, offset: 1 }} xs={{ span: 24}}>
            <Card bodyStyle={{ boxShadow: '5px 0px 25px 3px rgba(32, 32, 32, 0.1)', borderRadius: '2%' }} bordered={false}>
              <Row>
                <h2 style={{ fontWeight: 'normal' }}>Team</h2>
              </Row>
              <Row gutter={[12, 12]}>
                {me.employee &&
                  me.employee.teamMembersEmployee.edges &&
                  me.employee.teamMembersEmployee.edges.map((member, index) => (
                    <Col span={6} key={index}>
                      <img src={USER_IMG} alt="" style={{ width: '100%', height: '100%', borderRadius: '50%' }} />
                    </Col>
                  ))}
              </Row>
              <br />

              {/* <CompareYourScore /> */}

              {allPublishedStories &&
                [...Array(allPublishedStories.edges.length < 2 ? allPublishedStories.edges.length : 2).keys()].map(index => {
                  const { node } = allPublishedStories && allPublishedStories.edges[index];
                  return (
                    <>
                      <NewCol span={24}>
                        <Link to={`${STORY_ROUTES.storyDetailLink}${getIntFromString(node.id)}`} target="_blank">
                          <Row gutter={[14, 14]}>
                            <Col span={8}>
                              <img
                                src={node.thumbnailImage || IMG}
                                style={{ width: '100%', height: '100%', borderRadius: '15px' }}
                                alt="col-7"
                              />
                            </Col>

                            <Col span={16}>
                              <H3>{node.title}</H3>
                            </Col>
                          </Row>
                        </Link>
                      </NewCol>
                      <br />
                    </>
                  );
                })}

              <Row justify="end">
                <Link target={'_blank'} to={STORY_ROUTES.allStories}>{`View all stories`}</Link>
              </Row>
            </Card>
          </Col>}

          <Col lg={0} xs={1}></Col>
        </>
      )}
    </>
  );
};

export default compose(withStoryState, withAllPublishedStories)(TeamComponent);

const NewCol = styled(Col)`
  padding: 5px 2px;
  :hover {
    box-shadow: 5px 0px 25px 3px rgba(32, 32, 32, 0.1);
    border-radius: 10px;
  }
`;
const H3 =styled.h3`
font-size:14px;
white-space:'no-wrap'
display: -webkit-box;
-webkit-line-clamp: 3;
-webkit-box-orient: vertical;
overflow: hidden;
text-overflow: ellipsis;
`
