import React from "react";
import { compose } from "../../core";
import SoulView from '../components/SoulView'
import homepageContent from './homePageContent'
import NotFound from './NotFound'
import { withApollo } from '@apollo/client/react/hoc';
import { COMPLETE_SURVEY } from "../graphql/CompletedSurvey.gql";
import { getIntFromString, getSurveyBase64StringFromInt, globalPermissionValidator, replaceAITipDynamicContent } from "modules/look";
import { VALUE_SURVEY_ID, VALUE_SUERVEY_PART_1, VALUE_SUERVEY_PART_2, STRENGTH_PART_1, STRENGTH_PART_2, PERSONALITY_PART_1, PERSONALITY_PART_2, KNOWLEDGE_PART_1, KNOWLEDGE_PART_2, WHOPOSHORO_SURVEY_TOOL_ID, personality_questions } from '../../../config'
import { soul_permission } from "Permissions/soul.permission";
import NoPermissionView from "modules/kudos-user/component/NoPermissionComponent";
import { SURVEY_BY_ID_VALUES_DISPLAy_QUERY } from "modules/survey/graphql/SurveyByIdQuery.gql";
import { ai_trigger_content } from "modules/ai-tips/ai-trigger-content";


const HomePageContainer = (props) => {
  let type = 'strength';
  const { client, me, history, userPermission, permissionLoader, match } = props
  const [dataSet, setData] = React.useState()
  const [loading, setloading] = React.useState(true)
  const [soulPermitted, setSoulPermitted] = React.useState(false)
  const [response_data, setResponseData] = React.useState([])
  const [survey, setSurvey] = React.useState()
  const [ai_trigger,SetAITrigger] = React.useState()
  if (props.match) {
    type = props.match.params.type;
  } else if (props.navigation) {
    type = props.navigation.state.params.type;
  }
  const GetCompletedCount = async (value) => {
    try {
      const { data } = await client.query({
        query: COMPLETE_SURVEY,
        variables: {
          user: value,
          stengthQuestions: [619, 620, 621, 622, 623],
          personalityQuestions: personality_questions,
          valuePart1: getSurveyBase64StringFromInt(VALUE_SUERVEY_PART_1),
          valuePart2: getSurveyBase64StringFromInt(VALUE_SUERVEY_PART_2),
          strengthsPart1: getSurveyBase64StringFromInt(STRENGTH_PART_1),
          strengthsPart2: getSurveyBase64StringFromInt(STRENGTH_PART_2),
          personalityPart1: getSurveyBase64StringFromInt(PERSONALITY_PART_1),
          personalityPart2: getSurveyBase64StringFromInt(PERSONALITY_PART_2),
          knowledgePart1: getSurveyBase64StringFromInt(KNOWLEDGE_PART_1),
          knowledgePart2: getSurveyBase64StringFromInt(KNOWLEDGE_PART_2)
        },
        fetchPolicy: 'network-only'
      });
  
      if (data) {
        setData(data)
      }
    } catch (error) {
      
    }
  }



  React.useEffect(() => {
    if (userPermission && !permissionLoader) {
      let soulPermission = globalPermissionValidator(soul_permission, userPermission);
      setSoulPermitted(soulPermission)
    }
  }, [userPermission, permissionLoader])

  React.useEffect(() => {
    if (me && soulPermitted?.required_permission) {
      GetCompletedCount(me?.employee?.user?.id)
      let survey_type
      if (props.match) {
        survey_type = props.match.params.type;
      } else if (props.navigation) {
        survey_type = props.navigation.state.params.type;
      }
      let survey_detail = homepageContent[survey_type]
      if (survey_detail) {
        let id = getSurveyBase64StringFromInt(survey_detail.id);
        getSurveyDetail({ id, responseUser: me?.id })
      }

    }

  }, [me, soulPermitted, match]);

  const getSurveyDetail = async (value) => {
    setloading(true)
    try {
      const { data } = await client.query({
        query: SURVEY_BY_ID_VALUES_DISPLAy_QUERY,
        variables: value,
        fetchPolicy: 'network-only'
      });
      if (data?.surveyAnswers) {
        setSurvey({
          id: data?.surveyAnswers?.id,
          name: data?.surveyAnswers?.name
        })
        let responseData = []
        let survey_type
        if (props.match) {
          survey_type = props.match.params.type;
        } else if (props.navigation) {
          survey_type = props.navigation.state.params.type;
        }
  
        data.surveyAnswers.groupSet.edges.forEach((gs) => {
          gs.node.questionSet.edges.slice(0, 5).forEach((qs) => {
            let d = {}
            d['question_id'] = qs?.node?.id
            qs.node.answerSet.edges.slice(0, 1).forEach((as) => {
              d['answer'] = as?.node?.answer
              d['answer_id'] = as?.node?.id
              d['response_id'] = as?.node?.response?.id
            })
            responseData.push(d)
          })
        }) 
      
        setResponseData(responseData)
        setloading(false)
     
        let answer_data = responseData?.map(i=>i?.answer)?.filter(i=>i)
   
        if(answer_data?.length){ 
          if(ai_trigger_content[`survey_${getIntFromString(data?.surveyAnswers?.id)}_completed`]){
            let answer_list = answer_data?.join(",")
            let trigger = {
              trigger:ai_trigger_content[`survey_${getIntFromString(data?.surveyAnswers?.id)}_completed`],
              dynamic_data:[answer_list]
            }
            SetAITrigger( {...trigger } )
          }
      
        }else{
          if(ai_trigger_content[`survey_${getIntFromString(data?.surveyAnswers?.id)}_pending`]){
            
            let trigger = {
              trigger:ai_trigger_content[`survey_${getIntFromString(data?.surveyAnswers?.id)}_pending`]
            }
            SetAITrigger(  {...trigger} )
          }
    
        }
       
      }
    } catch (error) { 
     
    }
  }

 
  const staticData = homepageContent[type]
  if (staticData) {
    return (
      <>
        {
          (soulPermitted && soulPermitted?.required_permission && !permissionLoader) ?
            <SoulView 
            key={type} 
            loading={loading} 
            data={staticData} 
            dataSet={dataSet} 
            response_data={response_data} 
            survey={survey}
            ai_trigger={ai_trigger}
            SetAITrigger={SetAITrigger}
            permission={soulPermitted}
            {...props} 
            />
            :
            (soulPermitted && !soulPermitted?.required_permission && !permissionLoader) ?
              <NoPermissionView />
              : null
        }

      </>
    )
  } else {
    return (
      <>
        <NotFound></NotFound>
      </>
    )
  }

}
export default compose(withApollo)(HomePageContainer)