import gql from 'graphql-tag';
import { TAG_TYPE } from './TagType.gql';

export const DELETE_TAG = gql`
  ${TAG_TYPE}
  mutation deleteTag($id: ID!) {
    deleteTag(id: $id) {
      tag {
        ...TagType
      }
    }
  }
`;
