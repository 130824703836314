import gql from "graphql-tag";

export const SUBVERTICAL_AND_TEAMS_UNDER_VERTICAL_ORGANIZATION = gql`
query vertical($id: ID!,$savedOkrType:String,$okr_Duedate_Range: [DateTime], $role_In:[String],$managerId: [ID],$orderBy:[String])
{
  vertical(id: $id) {
    id
    name
    parentvertical{
      id
    }
    subvertical {
      edges {
        node {
          id
          name
          heads{
            edges{
              node{
                id
              }
            }
          }
          teamSet{
            totalCount
          }
          subvertical{
            totalCount
          }
          employees {
            edges {
              node {
                id
                okrContributorSet(savedOkrType:$savedOkrType, okr_Duedate_Range: $okr_Duedate_Range, role_In:$role_In, employee_In:$managerId, orderBy:$orderBy) {
                  edges {
                    node {
                      id
                      progress
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    teamSet {
      edges {
        node {
          id
          name
          managers {
            edges {
              node {
                id
              }
            }
          }
          employees {
            totalCount
          }
        }
      }
    }
  }
}
`;


export const TEAMS_UNDER_VERTICAL_ORGANIZATION  = gql`
query vertical($id: ID!)
{
  vertical(id: $id) {
    id
    name
    teamSet {
      edges {
        node {
          id
          name
          managers {
            edges {
              node {
                id
              }
            }
          }
          employees {
            totalCount
          }
        }
      }
    }
  }
}
`;