import gql from 'graphql-tag';

export const CREATE_SCAPE = gql`
mutation createScape($scapeData: ScapeInput!){
    createScape(scapeData: $scapeData){
        scape{
            id
        }
    }
}
`
export const UPDATE_SCAPE = gql`
mutation updateScape($id: ID!,$name: String!,$active: Boolean,$description: String){
    updateScape(id: $id,name: $name,active: $active,description: $description){
        scape{
            id
        }
    }
}
`
export const DELETE_SCAPE = gql`
mutation deleteScape($scapeId: ID!){
    deleteScape(scapeId:$scapeId){
        scape{
            id
        }
    }
}
`