import React from "react";
import { compose } from "../../core";
import { withApollo } from '@apollo/client/react/hoc';
import SurveyResponseRenderView from "../components/surveyResponseRenderView";
import { withUpdateResponse } from "./userManualOperation";
const SurveyResponseRenderContainer =(props)=>{
    const {client,userPermission,me}=props
return (
    <>
< SurveyResponseRenderView {...props}/>
    </>
)
}

export default compose(withApollo,withUpdateResponse)(SurveyResponseRenderContainer);