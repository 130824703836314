import gql from 'graphql-tag';
import { WEIGHTED_QUESTION_TYPE } from './WeightedQuestionType.gql';

export const EDIT_WEIGHTED_QUESTION = gql`
  ${WEIGHTED_QUESTION_TYPE}
  mutation editWeightedQuestion($weight: Float, $questionId: Int!, $id: ID!) {
    updateWeightedQuestion(weight: $weight, questionId: $questionId, id: $id) {
      weightedQuestion {
        ...WeightedQuestionType
      }
    }
  }
`;
