import React from 'react';
import AddParametricGrupView from '../components/addEditParametricGroupView';
import { compose } from 'modules/core';
import { withCreateParametricGroup, withUpdateParametricGroup } from './scapeReportOperations';
import { message } from "antd";
import { getIntFromString } from 'modules/look';

const AddParameterGroupContainer = ( props ) => {
    const { onCancel, createParametricGroup, scape_id, selected_group, updateParametricGroup } = props

    const [ create_response, setResponse ] = React.useState()
    const [add_next,setAddNext]=React.useState(false)
    const createGroup = async ( value ) => {
        let ParametricGroupInput = {
            name: value?.group_name,
            scapeId: scape_id
        }
        let response
        if ( selected_group !== undefined ) {
            response = await updateParametricGroup( { name: value?.group_name, id:getIntFromString(selected_group?.id) } )
        }
        else {
            response = await createParametricGroup( { parametricGroupData: ParametricGroupInput } )
        }
        if ( response ) {
            setResponse( response )
            message.success( 'Create new group successfully' )
            if ( value?.save_type === "save_close" ) {
                onCancel()
            }
            else{
                setAddNext(true)
            }
        }
    }
    return (
        <>
            <AddParametricGrupView
                {...props}
                onSubmit={( value ) => createGroup( value )}
                create_response={create_response}
                add_next={add_next} />
        </>
    )
}

export default compose( withCreateParametricGroup, withUpdateParametricGroup )( AddParameterGroupContainer )