import React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import gql from 'graphql-tag';
import { Empty, Spin } from 'antd';

import { compose } from '../../../core';
import { getIntFromString } from '../functions';
import { withLookSurveyFilterUpdating, withLookSurveyState } from '../../containers/LookOperations';

import SelectField from './SelectField';

export const ALL_SURVEY_QUERY = gql`
  query allSurveysForTokens($first: Int, $orderBy: [String], $name_Icontains: String, $questionText_Icontains: String) {
    allSurveysForTokens(first: $first, orderBy: $orderBy, name_Icontains: $name_Icontains) {
      edges {
        node {
          id
          name
          groupSet {
            edges {
              node {
                id
                name
                questionSet(questionText_Icontains: $questionText_Icontains) {
                  edges {
                    node {
                      id
                      questionText
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const QuestionAutoComplete = props => {
  const { loading, allSurveysForTokens, margin, onQuestionTextChange, onFiltersRemove } = props;
  const handleFilterRemove = React.useRef(() => {});

  handleFilterRemove.current = () => {
    onFiltersRemove();
  };

  React.useEffect(() => {
    return () => handleFilterRemove.current();
  }, []);

  const getQuestionList = (gC, name) => {
    return gC.questionSet?.edges?.map(({ node }) => ({
      label: `${name} | ${gC.name} | ${node.questionText}`,
      value: getIntFromString(node.id)
    }));
  };

  const getQuestionFromGroupList = survey =>
    [].concat.apply(
      [],
      survey.groupSet?.edges?.map(({ node }) => getQuestionList(node, survey.name))
    );

  const choices =
    !loading &&
    allSurveysForTokens &&
    allSurveysForTokens.edges.length > 0 &&
    [].concat.apply(
      [],
      allSurveysForTokens.edges.map(({ node }) => getQuestionFromGroupList(node))
    );

  return (
    <SelectField
      margin={margin}
      showSearch
      choices={choices}
      offset={1}
      style={{ width: '100%', marginBottom: !margin && 0 }}
      // labelInValue
      filterOption={false}
      onSearch={onQuestionTextChange}
      notFoundContent={
        loading ? (
          <div align="center">
            <Spin size="small" />
          </div>
        ) : (
          <Empty />
        )
      }
      {...props}
    />
  );
};

export default compose(
  withLookSurveyState,
  graphql(ALL_SURVEY_QUERY, {
    options: ({ filter, orderBy }) => {
      return { variables: { ...filter, orderBy } };
    },
    props({ data }) {
      const { loading, error, allSurveysForTokens, subscribeToMore, updateQuery } = data;
      return { loading, error, allSurveysForTokens, subscribeToMore, updateQuery };
    }
  }),
  withLookSurveyFilterUpdating
)(QuestionAutoComplete);
