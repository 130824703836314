// @ts-nocheck
import { graphql } from '@apollo/client/react/hoc';
import _ from 'lodash';
import { message } from 'antd';

import { removeTypename } from '../../core/clientStorage';
import organizationResolvers from '../resolvers';
import { getIntFromString, withOperation, getEmployeeBase64StringFromInt, getVerticalBase64StringFromInt,getOrganizationBase64StringFromInt ,getTeamBase64StringFromInt} from '../../look';

// Query
import { ALL_ORGANIZATION_FILTER_LIST, ALL_ORGANIZATION_QUERY, UPDATE_ORGANIZATION } from '../graphql/AllOrganizationQuery.gql';
import { ALL_ORGANIZATION_EMPLOYEES } from '../graphql/AllOrganizationEmployeesQuery.gql';
import { VERTICALS_QUERY } from '../graphql/VerticalsQuery.gql';
import { VERTICALS_BY_ORG_ID_QUERY, VERTICALS_BY_ORG_ID_QUERY_OPTIMIZED } from '../graphql/VerticalsByOrgIdQuery.gql';
import { TEAMS_QUERY } from '../graphql/TeamsQuery.gql';
import { TEAMS_BY_VERTICAL_ID_QUERY, TEAMS_BY_VERTICAL_ID_QUERY_OPTUMIZED } from '../graphql/TeamsByVerticalIdQuery.gql';
import { ALL_EMPLOYEES_QUERY } from '../graphql/AllEmployees.gql';
import { ORGANIZATION_BY_ID_QUERY, ORGANIZATION_BY_ID_QUERY_OPTIMIZED } from '../graphql/OrganizationByIdQuery.gql';
import { EMPLOYEE_BY_ID_QUERY } from '../graphql/EmployeeByIdQuery.gql';
import { VERTICAL_BY_VERTICAL_Id_QUERY, VERTICAL_BY_VERTICAL_Id_QUERY_OPTIMIZED } from '../graphql/VerticalByVerticalIdQuery.gql';
import { VERTICAL_QUERY } from '../graphql/VerticalQuery.gql';
import { TEAM_QUERY } from '../graphql/TeamQuery.gql';
import { TEAM_BY_TEAM_ID_QUERY, TEAM_BY_TEAM_ID_QUERY_OPTIMIZED } from '../graphql/TeamByTeamIdQuery.gql';
// Client
import { ORGANIZATION_STATE_QUERY } from '../graphql/OrganizationStateQuery.client.gql';
import { UPDATE_ORGANIZATION_FILTER } from '../graphql/UpdateOrganizationFilter.client.gql';

// Mutation
import { ADD_ORGANIZATION } from '../graphql/AddOrganization.gql';
import { ADD_VERTICAL, ADD_VERTICAL_FOR_ORG } from '../graphql/AddVertical.gql';
import { ADD_EMPLOYEE, ADD_EMPLOYEE_OPTIMIZED } from '../graphql/AddEmployee.gql';
import { ADD_TEAM } from '../graphql/AddTeam.gql';
import { EDIT_EMPLOYEE } from '../graphql/EditEmployee.gql';
import { EDIT_TEAM } from '../graphql/EditTeam.gql';
import { EDIT_ORGANIZATION } from '../graphql/EditOrganization.gql';
import { EDIT_VERTICAL } from '../graphql/EditVertical.gql';
import { DELETE_ORGANIZATION } from '../graphql/DeleteOrganization.gql';
import { DELETE_VERTICAL } from '../graphql/DeleteVertical.gql';
import { DELETE_TEAM } from '../graphql/DeleteTeam.gql';
import { DELETE_EMPLOYEE } from '../graphql/DeleteEmployee.gql';
import { FetchMeTeams } from '../graphql/FetchMeTeams.gql';

// Query
export const withOrganizationById = Component =>
  graphql( ORGANIZATION_BY_ID_QUERY, {
    options: props => {
      let id = '';
      if ( props.match ) {
        id = getOrganizationBase64StringFromInt(props.match.params.id);
      }
      // else if (props.navigation) {
      //   id = props.navigation.state.params.id;
      // }

      return {
        variables: { id: id}
      };
    },
    props( { data: { loading, error, organizationById, subscribeToMore, updateQuery } } ) {
      if ( error ) {
        throw new Error( error.message );
      }
      return { loadingVerifyToken: loading, error, organizationById, subscribeToMore, updateQuery };
    }
  } )( Component );

  export const withOrganizationByIdOptimized = Component =>
  graphql( ORGANIZATION_BY_ID_QUERY_OPTIMIZED, {
    options: props => {
      let id = '';
      if ( props.match ) {
        id = getOrganizationBase64StringFromInt(props.match.params.id);
      }
      
      return {
        variables: { id: id}
      };
    },
    props( { data: { loading, error, organizationById, subscribeToMore, updateQuery } } ) {
      if ( error ) {
        throw new Error( error.message );
      }
      return { loadingVerifyToken: loading, error, organizationById, subscribeToMore, updateQuery };
    }
  } )( Component );

export const withVerticalByVerticalId = (
  Component // Admin
) =>
  graphql( VERTICAL_BY_VERTICAL_Id_QUERY, {
    options: props => {
      let id = '';
      if ( props.match ) {
        id = getVerticalBase64StringFromInt(props.match.params.id);
      } else if ( props.navigation ) {
        id = getVerticalBase64StringFromInt(props.navigation.state.params.id);
      }

      return {
        variables: { id:id},
        fetchPolicy: "network-only"
      };
    },
    props( { data: { loading, error, vertical, subscribeToMore, updateQuery } } ) {
      if ( error ) {
        throw new Error( error.message );
      }
      return { loadingVerifyToken: loading, error, vertical, subscribeToMore, updateQuery };
    }
  } )( Component );


  export const withVerticalByVerticalIdOptimized = (
    Component // Admin
  ) =>
    graphql( VERTICAL_BY_VERTICAL_Id_QUERY_OPTIMIZED, {
      options: props => {
        let id = '';
        if ( props.match ) {
          id = getVerticalBase64StringFromInt(props.match.params.id);
        } else if ( props.navigation ) {
          id = getVerticalBase64StringFromInt(props.navigation.state.params.id);
        }
  
        return {
          variables: { id:id},
          fetchPolicy: "network-only"
        };
      },
      props( { data: { loading, error, vertical, subscribeToMore, updateQuery } } ) {
        if ( error ) {
          throw new Error( error.message );
        }
        return { loadingVerifyToken: loading, error, vertical, subscribeToMore, updateQuery };
      }
    } )( Component );


export const withVertical = Component =>
  graphql( VERTICAL_QUERY, {
    options: props => {
      let id = '';
      if ( props.match ) {
        id = getVerticalBase64StringFromInt(props.match.params.id);
      } else if ( props.navigation ) {
        id = getVerticalBase64StringFromInt(props.navigation.state.params.id);
      }

      return {
        variables: { id: Number( id ) },
        fetchPolicy: "network-only"
      };
    },
    props( { data: { loading, error, vertical, subscribeToMore, updateQuery } } ) {
      if ( error ) {
        throw new Error( error.message );
      }
      return { loadingVerifyToken: loading, error, vertical, subscribeToMore, updateQuery };
    }
  } )( Component );

export const withTeam = Component =>
  graphql( TEAM_QUERY, {
    options: props => {
      let id = '';
      if ( props.match ) {
        id = props.match.params.id;
      } else if ( props.navigation ) {
        id = props.navigation.state.params.id;
      }

      return {
        variables: { id: Number( id ) },
        fetchPolicy: "network-only"
      };
    },
    props( { data: { loading, error, Team, subscribeToMore, updateQuery } } ) {
      if ( error ) {
        throw new Error( error.message );
      }
      return { loading, error, team: Team, subscribeToMore, updateQuery };
    }
  } )( Component );


export const withMeTeams = Component =>
  graphql( FetchMeTeams, {
    props( { data: { loading, error, meTeams, subscribeToMore, updateQuery } } ) {
      if ( error ) {
        throw new Error( error.message );
      }
      return { loading, error, team: meTeams, subscribeToMore, updateQuery };
    }
  } )( Component );

export const withTeamByTeamId = Component =>
  graphql( TEAM_BY_TEAM_ID_QUERY, {
    options: props => {
      let id = '';
      if ( props.match ) {
        id = getTeamBase64StringFromInt(props.match.params.id);
      } else if ( props.navigation ) {
        id = getTeamBase64StringFromInt(props.navigation.state.params.id);
      }

      return {
        variables: { id:id },
        fetchPolicy: "network-only"
      };
    },
    props( { data: { loading, error, Team, subscribeToMore, updateQuery } } ) {
      if ( error ) {
        throw new Error( error.message );
      }
      return { loading, error, Team: Team, subscribeToMore, updateQuery };
    }
  } )( Component );


  export const withTeamByTeamIdOptimized = Component =>
  graphql( TEAM_BY_TEAM_ID_QUERY_OPTIMIZED, {
    options: props => {
      let id = '';
      if ( props.match ) {
        id = getTeamBase64StringFromInt(props.match.params.id);
      } else if ( props.navigation ) {
        id = getTeamBase64StringFromInt(props.navigation.state.params.id);
      }

      return {
        variables: { id:id },
        fetchPolicy: "network-only"
      };
    },
    props( { data: { loading, error, Team, subscribeToMore, updateQuery } } ) {
      if ( error ) {
        throw new Error( error.message );
      }
      return { loading, error, Team: Team, subscribeToMore, updateQuery };
    }
  } )( Component );


export const withEmployeeByIdQuery = Component =>
  graphql( EMPLOYEE_BY_ID_QUERY, {
    options: props => {
      let id = '';
      if ( props.match ) {
        id = props.match.params.id;
      } else if ( props.navigation ) {
        id = props.navigation.state.params.id;
      }

      return {
        variables: { id: getEmployeeBase64StringFromInt( Number( id ) ) },
        fetchPolicy: "network-only"
      };
    },
    props( { data: { loading, error, employeeById, subscribeToMore, updateQuery } } ) {
      if ( error ) {
        throw new Error( error.message );
      }
      return { loading, error, employeeById, subscribeToMore, updateQuery };
    }
  } )( Component );

export const withVerticals = Component =>
  graphql( VERTICALS_QUERY, {
    options: ( { filter, orderBy, pagination } ) => {
      return { 
        variables: { ...pagination, ...filter, orderBy },
        fetchPolicy: 'network-only' };
    },
    props( { data } ) {
      const { loading, error, verticals, subscribeToMore, updateQuery } = data;
      return { loading, error, verticals, subscribeToMore, updateQuery };
    }
  } )( Component );

export const withVerticalsByOrgIdQuery = Component =>
  graphql( VERTICALS_BY_ORG_ID_QUERY, {
    options: ( { match, navigation, filter, orderBy, pagination } ) => {
      let id = '';
      if ( match ) {
        id = getOrganizationBase64StringFromInt(match.params.id);
      } else if ( navigation ) {
        id = getOrganizationBase64StringFromInt(navigation.state.params.id);
      }
      return {
        variables: { ...pagination, ...filter, organization:id, orderBy },
        fetchPolicy: 'network-only'
      };
    },
    props( { data } ) {
      const { loading, error, verticals, subscribeToMore, updateQuery } = data;
      return { loading, error, verticals, subscribeToMore, updateQuery };
    }
  } )( Component );

  export const withVerticalsByOrgIdQueryOptimized = Component =>
  graphql( VERTICALS_BY_ORG_ID_QUERY_OPTIMIZED, {
    options: ( { match, navigation, filter, orderBy, pagination } ) => {
      let id = '';
      if ( match ) {
        id = getOrganizationBase64StringFromInt(match.params.id);
      } else if ( navigation ) {
        id = getOrganizationBase64StringFromInt(navigation.state.params.id);
      }
      return {
        variables: { ...pagination, ...filter, organization:id, orderBy },
        fetchPolicy: 'network-only'
      };
    },
    props( { data } ) {
      const { loading, error, verticals, subscribeToMore, updateQuery } = data;
      return { loading, error, verticals, subscribeToMore, updateQuery };
    }
  } )( Component );

export const withAllOrganization = Component =>
  graphql( ALL_ORGANIZATION_QUERY, {
    options: ( { filter, orderBy, pagination } ) => {
      
      let variables;
      if(filter?.name_Icontains?.length || filter?.description_Icontains || filter?.email_Icontains){
        variables = {...filter}
      }else{
        variables = {...pagination,...filter}
      }
      return { 
      
        variables: { ...variables, orderBy },
        fetchPolicy: 'network-only' };
    },
    props( { data } ) {
      const { loading, error, allOrganization, subscribeToMore, updateQuery } = data;
      return { loading, error, allOrganization, subscribeToMore, updateQuery };
    }
  } )( Component );

  export const withAllOrganizationList = Component =>
  graphql( ALL_ORGANIZATION_FILTER_LIST, {
    options: ( { filter, orderBy, pagination } ) => {
      return { 
        variables: { ...pagination, ...filter, orderBy },
        fetchPolicy: 'network-only' };
    },
    props( { data } ) {
      const { loading, error, allOrganization, subscribeToMore, updateQuery } = data;
      return { loading, error, allOrganization, subscribeToMore, updateQuery };
    }
  } )( Component );

export const withAllOrganizationEmployees = Component =>
  graphql( ALL_ORGANIZATION_EMPLOYEES, {
    options: ( { filter, orderBy, pagination } ) => {
      let filters = filter;
      if ( filter.name_Icontains === '' ) filters = _.omit( filter, 'name_Icontains' );
      else filters.nameIcontains = filter.name_Icontains;

      return {
         variables: { ...pagination, ...filters, orderBy } ,
         fetchPolicy: 'network-only'
        };
    },
    props( { data: { loading, error, allOrganizationEmployees, subscribeToMore, updateQuery } } ) {
      return { loading, error, allOrganizationEmployees, subscribeToMore, updateQuery };
    }
  } )( Component );

export const withTeams = Component =>
  graphql( TEAMS_QUERY, {
    options: ( { filter, orderBy, pagination } ) => {
      return { variables: { ...pagination, ...filter, orderBy } };
    },
    props( { data } ) {
      const { loading, error, Teams, subscribeToMore, updateQuery } = data;
      return { loading, error, Teams, subscribeToMore, updateQuery };
    }
  } )( Component );

export const withTeamsByVerticalIdQuery = Component =>
  graphql( TEAMS_BY_VERTICAL_ID_QUERY, {
    options: ( { match, navigation, filter, orderBy, pagination } ) => {
      let id = '';
      if ( match ) {
        id = getVerticalBase64StringFromInt(match.params.id);
      } else if ( navigation ) {
        id = getVerticalBase64StringFromInt(navigation.state.params.id);
      }
      return { 
        variables: { ...pagination, ...filter, vertical: id, orderBy },
        fetchPolicy: 'network-only'
       };
    },
    props( { data } ) {
      const { loading, error, Teams, subscribeToMore, updateQuery } = data;
      return { loading, error, Teams, subscribeToMoreTeamsByVerticalId: subscribeToMore, updateQuery };
    }
  } )( Component );


export const withTeamsByVerticalIdQueryOptimized = Component =>
graphql( TEAMS_BY_VERTICAL_ID_QUERY_OPTUMIZED, {
  options: ( { match, navigation, filter, orderBy, pagination } ) => {
    let id = '';
    if ( match ) {
      id = getVerticalBase64StringFromInt(match.params.id);
    } else if ( navigation ) {
      id = getVerticalBase64StringFromInt(navigation.state.params.id);
    }
    return { 
      variables: { ...pagination, ...filter, vertical: id, orderBy },
      fetchPolicy: 'network-only'
     };
  },
  props( { data } ) {
    const { loading, error, Teams, subscribeToMore, updateQuery } = data;
    return { loading, error, Teams, subscribeToMoreTeamsByVerticalId: subscribeToMore, updateQuery };
  }
} )( Component );

export const withAllEmployees = Component =>
  graphql( ALL_EMPLOYEES_QUERY, {
    options: ( { filter, orderBy, pagination } ) => {
      return {
         variables: { ...pagination, ...filter, orderBy },
         fetchPolicy: 'network-only' };
    },
    props( { data } ) {
      const { loading, error, allEmployees, subscribeToMore, updateQuery } = data;
      return { loading, error, allEmployees, subscribeToMore, updateQuery };
    }
  } )( Component );

// Mutation


  export const withUpdateOrganization = Component =>
graphql(UPDATE_ORGANIZATION, {
  props: ({ mutate, history }) => ({
    updateOrganization: async values => {
      try {
        const {
          data: { updateOrganization }
        } = await mutate({
          variables: {
            ...values
          }
        });

        message.destroy();
        return updateOrganization.organization;
      } catch (e) {
        message.destroy();
        console.error(e);
      }
    }
  })
})(Component);

  export const withAddOrganization = Component =>
graphql(ADD_ORGANIZATION, {
  props: ({ mutate, history }) => ({
    createOrganization: async values => {
      try {
        const {
          data: { createOrganization }
        } = await mutate({
          variables: {
            ...values
          }
        });

        message.destroy();
        message.success("New Organization added successfully")
        return createOrganization.organization;
      } catch (e) {
        message.destroy();
        console.error(e);
      }
    }
  })
})(Component);


  export const withAddVertical = Component =>
  graphql(ADD_VERTICAL, {
    props: ({ mutate, history }) => ({
      createVertical: async values => {
        try {
          const {
            data: { createVertical }
          } = await mutate({
            variables: {
              ...values
            }
          });
  
          message.destroy();
          message.success("New Vertical added successfully")
          return createVertical.vertical;
        } catch (e) {
          message.destroy();
          console.error(e);
        }
      }
    })
  })(Component);

export const withAddVerticalForOrg = Component =>
graphql(ADD_VERTICAL_FOR_ORG, {
  props: ({ mutate, history }) => ({
    createVertical: async values => {
      try {
        const {
          data: { createVertical }
        } = await mutate({
          variables: {
            ...values
          }
        });

        message.destroy();
        return createVertical.vertical;
      } catch (e) {
        message.destroy();
        console.error(e);
      }
    }
  })
})(Component);


  export const withAddTeam = Component =>
  graphql(ADD_TEAM, {
    props: ({ mutate, history }) => ({
      createTeam: async values => {
        try {
          const {
            data: { createTeam }
          } = await mutate({
            variables: {
              ...values
            }
          });
  
          message.destroy();
          message.success("New Team added successfully")
          return createTeam.team;
        } catch (e) {
          message.destroy();
          console.error(e);
        }
      }
    })
  })(Component);



  export const withAddEmployee = Component =>
  graphql(ADD_EMPLOYEE, {
    props: ({ mutate, history }) => ({
      addEmployee: async values => {
        try {
          const {
            data: { addEmployee }
          } = await mutate({
            variables: {
              ...values
            }
          });
  
          message.destroy();
          message.success("Success")
          return addEmployee.addEmployee;
          
        } catch (e) {
          message.destroy();
          console.error(e);
        }
      }
    })
  })(Component);


 

export const withEditEmployee = Component =>
  graphql( EDIT_EMPLOYEE, {
    props: ( { mutate } ) => ( {
      editEmployee: async values => {
        try {
          const {
            data: { updateEmployee }
          } = await mutate( {
            variables: {
              ...values
            }
          } );
          return updateEmployee.employee;
        } catch ( e ) {
          if ( e.message.includes( 'Duplicate entry' ) ) {
            message.error( 'Employee already exists!' );
          }
          console.error( e );
        }
      }
    } )
  } )( Component );

export const withEditTeam = Component =>
  graphql( EDIT_TEAM, {
    props: ( { mutate } ) => ( {
      editTeam: async values => {
        try {
          const {
            data: { updateTeam }
          } = await mutate( {
            variables: {
              ...values
            }
          } );
          return updateTeam.team;
        } catch ( e ) {
          console.error( e );
        }
      }
    } )
  } )( Component );

export const withEditVertical = Component =>
  graphql( EDIT_VERTICAL, {
    props: ( { mutate } ) => ( {
      editVertical: async values => {
        try {
          const {
            data: { updateVertical }
          } = await mutate( {
            variables: {
              ...values
            }
          } );
          message.destroy();
          message.success("Edit vertical successfully")
          return updateVertical.vertical;
        } catch ( e ) {
          console.error( e );
        }
      }
    } )
  } )( Component );

export const withEditOrganization = Component =>
  graphql( EDIT_ORGANIZATION, {
    props: ( { mutate } ) => ( {
      editOrganization: async values => {
        try {
          const {
            data: { updateOrganization }
          } = await mutate( {
            variables: {
              ...values
            }
          } );
          message.destroy();
          message.success("Organization details successfully edited")
          return updateOrganization.organization;
        } catch ( e ) {
          console.error( e );
        }
      }
    } )
  } )( Component );



  export const withDeleteOrganization = Component =>
  graphql( DELETE_ORGANIZATION, {
    props: ( { mutate } ) => ( {
      deleteOrganization: async values => {
        try {
          const {
            data: { deleteOrganization }
          } = await mutate( {
            variables: {
              ...values
            }
          } );
          message.destroy();
          return deleteOrganization.organization;
        } catch ( e ) {
          console.error( e );
          if(e.message.includes("Cannot delete some instances of model 'Organization'")){
            message.error("Cannot delete this 'Organization' because they are referenced to Some other data ");
          }
          else{
            message.error( 'Failed, try again!' );
          }
        }
      }
    } )
  } )( Component );


  export const withDeleteVertical = Component =>
  graphql( DELETE_VERTICAL, {
    props: ( { mutate } ) => ( {
      deleteVertical: async values => {
        try {
          const {
            data: { deleteVertical }
          } = await mutate( {
            variables: {
              ...values
            }
          } );
          message.destroy();
          message.success("Delete Vertical successfully")
          return deleteVertical.vertical;
        } catch ( e ) {
          console.error( e );
        }
      }
    } )
  } )( Component );

  export const withDeleteTeam = Component =>
  graphql( DELETE_TEAM, {
    props: ( { mutate } ) => ( {
      deleteTeam: async values => {
        try {
          const {
            data: { deleteTeam }
          } = await mutate( {
            variables: {
              ...values
            }
          } );
          message.destroy();
          message.success("Delete Team successfully")
          return deleteTeam.team;
        } catch ( e ) {
          console.error( e );
        }
      }
    } )
  } )( Component );

  export const withDeleteEmployee = Component =>
  graphql( DELETE_EMPLOYEE, {
    props: ( { mutate } ) => ( {
      deleteEmployee: async values => {
        try {
          const {
            data: { deleteEmployee }
          } = await mutate( {
            variables: {
              ...values
            }
          } );
          message.destroy();
          message.success("Delete Employee successfully")
          return deleteEmployee.employee;
        } catch ( e ) {
          console.error( e );
        }
      }
    } )
  } )( Component );

export const withOrganizationState = Component =>
  graphql( ORGANIZATION_STATE_QUERY, {
    props( { data } ) {
      const { orderBy, ...rest } = data.organizationState;
      const organizationState = {
        ...removeTypename( rest ),
        orderBy
      };
      organizationState.filter.subvertical = rest.filter.subvertical;
      return { ...organizationState, stateLoading: data.loading };
    }
  } )( Component );

export const withOrganizationFilterUpdating = Component =>
  graphql( UPDATE_ORGANIZATION_FILTER, {
    props: ( { mutate } ) => ( {
      onOrderByChange( orderBy ) {
        mutate( { variables: { orderBy } } );
      },
      onPaginationChange( pagination ) {
        mutate( { variables: { pagination } } );
      },
      onFiltersRemove() {
        mutate( { variables: { ...organizationResolvers.defaults.organizationState } } );
      },
      onNameChange( name_Icontains ) {
        mutate( { variables: { filter: { name_Icontains } } } );
      },
      onDescriptionChange( description_Icontains ) {
        mutate( { variables: { filter: { description_Icontains } } } );
      },
      onEmailChange( email_Icontains ) {
        mutate( { variables: { filter: { email_Icontains } } } );
      },
      onPocChange( poc ) {
        mutate( { variables: { filter: { poc } } } );
      },
      onCeoChange( ceo ) {
        mutate( { variables: { filter: { ceo } } } );
      },
      onOrganizationChange( organization ) {
        mutate( { variables: { filter: { organization } } } );
      },
      onVerticalChange( vertical ) {
        mutate( { variables: { filter: { vertical } } } );
      },
      onSubverticalChange( subvertical ) {
        mutate( { variables: { filter: { subvertical: [ getIntFromString( subvertical ) ] } } } );
      },
      onUserChange( user ) {
        mutate( { variables: { filter: { user } } } );
      }
      // onEmployeesChange(employees_Icontains) {
      //   mutate({ variables: { filter: { employees_Icontains } } });
      // }
    } )
  } )( Component );

  export const withAddEmployeeOptimized = Component =>
    graphql(ADD_EMPLOYEE_OPTIMIZED, {
      props: ({ mutate, history }) => ({
        addEmployee: async values => {
          try {
            const {
              data: { addEmployee }
            } = await mutate({
              variables: {
                ...values
              }
            });
            return addEmployee?.employee;
            
          } catch (e) {
            message.destroy();
            console.error(e);
          }
        }
      })
    })(Component);
