import gql from 'graphql-tag';

export const FetchMeTeams = gql`
  query fetchmeteams {
    me{
  id
    employee{
      id
      teamManagerEmployee {
        edges {
          node {
            id
          }
        }
      }
    }
  }
  }
`;
