// @ts-nocheck

import React from "react";
import { Row, Col, Button, Table, Tooltip, Spin, Form, Card, Modal, Tabs, Carousel, message } from "antd";
import styled from "styled-components";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { MetaTags, getIntFromString, scrolltoview, withMarkdownFieldValue, displayUtcToUserTimezone } from "../../look";
import ProfileLogo from '../../assets/meeting-tool-profile.svg';
import ReportTool from "../../tool/containers/ReportTool";
import Back_icon from '../../assets/soul-back.svg'
import Instant_icon from '../../assets/instant-icon.svg'
import PreviousMeetingCardContainer from "../containers/previousMeetingCardContainer";
import AgendaListContainer from '../containers/agendaListContainer'
import ActionItemsListContainer from "../containers/actionItemsListContainer";
import agenda_cancel from '../../assets/agenda-cancel.svg'
import CKEditorFieldOneOnOne from "modules/look/components/form-components/CKEditor-one-on-one";
import save_disabled from '../../assets/icons/save_disabled.png'
import save_enabled from '../../assets/icons/save_enabled.png'
import Agenda_close from '../../assets/agenda-close.svg'
import MeetingFeedbackContainer from '../containers/meetingFeedbackContianer'

import MeetingEndedModal from "./MeetingEndedModal";
import EffectiveFeedback from "./effective-feedback";
import ScheduleNextMeeting from "./ScheduleNextMeeting";
import green_bulb from '../../assets/green_bulb.svg'
import info_button from '../../assets/info-button.svg'
import CallTranscriptHandler from "../../look-v2/Components/CallTranscriptHandler";
import one_on_one_route from '../route/index'
import MeetingAiTipContainer from "modules/ai-tips/container/meeting-ai-tip-container";
const MeetingStartView = props => {
  const { history, createMeetingNotes, me, meetingById, createActionItem, updateActionItem, updateMeetingNotes, agendaList, actionList, feedbackList, selfNotes, changeActionItem, deleteActionItem, ReportToolID, endMeeting, location, createMeetingFeedback, is_reportee, meetingEnded, setMeetingEnded, meetingEndedBy, disconnectSocket, recording_status, permission, meetingParticipiants,app_sidebar_expand,setnote,SetAITrigger,ai_trigger } = props

  const [form] = Form.useForm();

  const [loading, setloading] = React.useState(false);
  const [ActionItem, setActionsItem] = React.useState();
  const [confirmationModalVisible, setdConfirmationModalVisible] = React.useState(false);
  const [createdByMe, setcreatedByMe] = React.useState(false);
  const [addActionItem, setaddActionItem] = React.useState(false);
  const [memberlist, setmemberlist] = React.useState([]);
  const [FieldName, setFieldName] = React.useState({});
  const [ToolMember, setToolMember] = React.useState();
  const [currentIndex, setcurrentIndex] = React.useState(0);
  const [id, setid] = React.useState(1);
  const [closeAddAgenda, setcloseAddAgenda] = React.useState()
  const [enable, setEnable] = React.useState(false)
  const [savewarning, setSaveWarning] = React.useState(false)
  const [effective_feedback, Seteffective_feedback] = React.useState(false)
  const [backButtonWarning, setBackButtonWarning] = React.useState(false)
  const [ckHeight, setCkHeight] = React.useState()
  const [rateoneonone, setRateOneonOne] = React.useState(false)
  const [managerRatingMsg, setManagerRatingMsg] = React.useState(false)
  const [meetingNote, setMeetingNotesAvailable] = React.useState();
  const [scheduleNextMEeeting, setScheduleNextMeeting] = React.useState(false)
  const [hideButtonPanel, setHideButtonPanel] = React.useState(false);
  const [tempVar, setTempVar] = React.useState();
  const [toolLoader, setToolLoader] = React.useState(false);
  const [showTip, setShowTip] = React.useState(true)
  const [callState, setcallState] = React.useState()
  const [previous_count, setPreviousCount] = React.useState()
  const [unsaved_data_warning,UnSavedDataWarning] = React.useState()

  const [action_item_unsaved, setActionItemUnsaved] = React.useState(false)
  const [agenda_unsaved, setAgendaUnsaved] = React.useState(false)
  const [note_unsaved, setNoteUnsaved] = React.useState(false)

  const { TabPane } = Tabs;
  React.useEffect(() => {
    if (meetingById && me) {

      if (me?.employee?.id === meetingById?.owner?.user?.employee?.id) {
        setcreatedByMe(true)
      }
      else {
        setcreatedByMe(false)
      }
      let memberlist = meetingById?.member?.edges?.map(({ node }) => node.user)
      memberlist = memberlist.concat(meetingById?.owner?.user)
      let myProfile = memberlist.find(item => item.employee?.id === me?.employee?.id)

      if (myProfile) {
        let index = memberlist.indexOf(myProfile)
        memberlist.splice(index, 1)
      }
      setmemberlist(memberlist)

    }
  }, [meetingById, me])

  React.useEffect(() => {
    if (memberlist?.length) {


      setTimeout(function () { //Start the timer
        setToolMember(memberlist[currentIndex])
      }.bind(this), 5000)


    }
  }, [memberlist])

  React.useEffect(() => {
    if (meetingEnded) {
      setHideButtonPanel(true)
    }
  }, [meetingEnded])


  const changeIndex = (index) => {
    setcurrentIndex(index)
    setToolMember(memberlist[index])
  }


  React.useEffect(() => {
    if(selfNotes?.notes){
      setTempVar(selfNotes?.notes)
    }
  }, [selfNotes])

  const anchorStyle = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '150px',
    marginTop: '5px'
  }

  const columns = [
    {
      title: "Title",
      key: 'Title',
      render: (text, record) => record &&
        <Tooltip title={record?.title?.length > 16 ? record?.title : ''} placement="right">
          <p style={anchorStyle}>{record?.title}</p>
        </Tooltip>
    },
    {
      title: "relatedTo",
      key: 'relatedTo',
      render: (text, record) => record &&
        <p >{relate(record)}</p>
    },
    {
      title: '',
      key: '',
      render: (text, record) => record &&
        <div align="end" >
          {
            record?.id && (
              <Button shape="circle" type="primary" disabled={ActionItem} onClick={() => { editActions(record) }} >
                <EditOutlined />
              </Button>
            )
          }
          {
            createdByMe && (
              <Button shape="circle" type="danger" disabled={ActionItem} style={{ marginLeft: '10px' }} onClick={() => DeleteWarning(record)}>
                <DeleteOutlined />
              </Button>
            )
          }

        </div>

    }
  ];

  const editActions = (record) => {
    setActionsItem(record)
    setaddActionItem(true)
  }

  const DeleteWarning = (record) => {
    setActionsItem(record)
    setdConfirmationModalVisible(true)
  }



  const checkReportee = () => {
    if (is_reportee) {
      setMeetingEnded(false)
      Seteffective_feedback(true)
    } else {
      if (!meetingById?.isInstant && !meetingById?.nextMeeting?.totalCount) {
        setScheduleNextMeeting(true)
      } else {
        history.push(one_on_one_route?.oneOnOneHome?.replace(":type","upcoming") )
      }

    }
  }

  const relate = (data) => {

    if (data?.okrId) {
      return data?.okrId?.title
    }
    else if (data?.commitmentId) {
      return data?.commitmentId?.title
    }
    else if (data?.coachingCommitmentId) {
      return data?.coachingCommitmentId?.title
    }
  }

  const SubmitActionItem = async (data) => {
    setloading(true)

    if (data?.id) {
      try {
        const response = await updateActionItem(
          {
            id: getIntFromString(data?.id),
            title: data?.title,
            notes: data?.notes,
            coachingCommitmentId: data?.coachingCommitmentId?.id ? getIntFromString(data?.coachingCommitmentId?.id) : -1,
            commitmentId: data?.commitmentId?.id ? getIntFromString(data?.commitmentId?.id) : -1,
            isAgenda: data?.isAgenda,
            okrId: data?.okrId?.id ? getIntFromString(data?.okrId?.id) : -1,
          });
        if (response && response?.id) {
          setActionsItem(null)
          setaddActionItem(false)
          setcloseAddAgenda(false)
          if(data?.isAgenda){
            setAgendaUnsaved(false)
          }else{
            setActionItemUnsaved(false)
          }
   
        }

      }
      catch {
        setloading(false)
        setActionsItem(null)
        setaddActionItem(false)
      }
      setloading(false)
      setActionsItem(null)
      setaddActionItem(false)
    }
    else {
      try {
        let meetingid = getIntFromString(meetingById?.id)
        let actionItemData = {
          title: data?.title,
          meetingId: meetingid,
          okrId: data?.okrId?.id ? getIntFromString(data?.okrId?.id) : null,
          commitmentId: data?.commitmentId?.id ? getIntFromString(data?.commitmentId?.id) : null,
          coachingCommitmentId: data?.coachingCommitmentId?.id ? getIntFromString(data?.coachingCommitmentId?.id) : null,
          notes: data?.notes,
          isAgenda: data?.isAgenda
        }
        const CreateActionItem = await createActionItem({ actionItemData });

        if (CreateActionItem && CreateActionItem?.id) {
          setActionsItem(null)
          setaddActionItem(false)
          if(data?.isAgenda){
            setAgendaUnsaved(false)
          }else{
            setActionItemUnsaved(false)
          }
        }

      }
      catch {
        setloading(false)
        setActionsItem(null)
        setaddActionItem(false)
      }
      setloading(false)
      setActionsItem(null)
      setaddActionItem(false)
    }

  }


  const handleSubmit = async (prev = false) => {

    if (backButtonWarning) {
      setBackButtonWarning(false)
    }

    try {
      setloading(true)

      let meetingId = getIntFromString(meetingById?.id)
      let id;
      if (selfNotes?.id) {
        id = getIntFromString(selfNotes?.id)
      } else {
        id = getIntFromString(meetingNote)
      }
      if (id) {

        let updateData = { notes: tempVar, id: id }
        const response = await updateMeetingNotes(updateData)
        if (response && response?.id) {
          if (!meetingEnded) {
            MeetingEnd(meetingId, prev)
            setloading(false)
          } else {
            checkReportee()
          }
        }
        else {
          setloading(false)
        }
      }
      else {

        if (tempVar?.length) {

          let meetingNotesData = { notes: tempVar, meetingId: meetingId }

          // if(meetingNotesData?.notes){
          const response = await createMeetingNotes({ meetingNotesData: meetingNotesData })

          if (response && response?.id) {

            if (!meetingEnded) {
              MeetingEnd(meetingId, prev)
              setloading(false)
            } else {
              checkReportee()
            }
          }

        } else {
          setloading(false)
          if (!meetingEnded) {
            MeetingEnd(meetingId, prev)
            checkReportee()
            setloading(false)
          } else {
            checkReportee()
            setloading(false)

          }
        }
      }

    }
    catch {
      setloading(false)
    }


  }

  const MeetingEnd = async (meetingId) => {
    meetingEndedBy.current = true
    try {
      const res = await endMeeting({ id: meetingId })
      if (res) {
        disconnectSocket()
      }
      if (res && is_reportee) {
        setSaveWarning(false)
        Seteffective_feedback(true)
        setHideButtonPanel(true)
        setloading(false)
      } else if (res) {
        setHideButtonPanel(true)
        setSaveWarning(false)
        setloading(false)
        if (!meetingById?.isInstant && !meetingById?.nextMeeting?.totalCount) {
          setScheduleNextMeeting(true)
        } else {
          history.push( one_on_one_route?.oneOnOneHome?.replace(":type","upcoming") )
        }
      } else {
        disconnectSocket()
        checkReportee()
        meetingEndedBy.current = null
      }
    } catch (e) {
      meetingEndedBy.current = null
    }

  }

  const saveMeetingNote = async () => {
    setloading(true)
    let meetingId = getIntFromString(meetingById?.id)

    let response
    if (selfNotes?.id) {
      let id = getIntFromString(selfNotes?.id)
      let updateData = { notes: tempVar, id: id }
      response = await updateMeetingNotes(updateData)
      if (!response?.id) {
        message.error("Failed to save meeting note")
      }
      setNoteUnsaved(false)
      setloading(false)
      setEnable(false)
      message.success("Meetign note saved successfully")
    }
    else {
      let meetingNotesData = { notes: tempVar, meetingId: meetingId }
      if (meetingNotesData?.notes) {
        response = await createMeetingNotes({ meetingNotesData: meetingNotesData })
        if (!response?.id) {
          message.error("Failed to save meeting note")
        }
        setNoteUnsaved(false)
        setloading(false)
        setEnable(false)
      }
    }

    if(response?.id){
      let updated_note = {
        id : response?.id,
        notes:tempVar
      }
      setnote(updated_note)
    }

  }

  const goOneOnOne = () => {
    history.push( one_on_one_route?.oneOnOneHome?.replace(":type","upcoming"))
  }


  const updateWindowDimensions = () => {
    const newWidth = window.innerHeight;

    if (newWidth > 800 && newWidth < 920) {
      setCkHeight(340)
    }
    else if (newWidth > 920) {
      setCkHeight(410)
    }
    else if (newWidth < 800) {
      setCkHeight(190)
    }
  }


  React.useEffect(() => {

    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions)

  })




  const handleEditorChange = e => {
    let feed = withMarkdownFieldValue("notes", { notes: e })
    setTempVar(feed?.notes)
    
    if(selfNotes?.notes!=feed?.notes){
      setNoteUnsaved(true)
      setEnable(true)
    }else{
      setNoteUnsaved(false)
      setEnable(false)
    }
    
  };

  const resetFieldName = (e) => {
    setTempVar();
  }




  const handleOpenFeedBack = () => {
    if (is_reportee) {
      Seteffective_feedback(true)
    } else {
      endMeetingHandler()
    }
  }

  const endMeetingHandler = async () => {

    try {
      setloading(true)

      let meetingId = getIntFromString(meetingById?.id)
      let id;
      if (selfNotes?.id) {
        id = getIntFromString(selfNotes?.id)
      } else {
        id = getIntFromString(meetingNote)
      }
      if (id) {

        let updateData = { notes: tempVar, id: id }
        const response = await updateMeetingNotes(updateData)
        if (response && response?.id) {
          if (!meetingEnded) {
            MeetingEnd(meetingId)
            setloading(false)
          }

        }
        else {
          setloading(false)
        }
      }
      else {

        if (tempVar?.length) {
          let meetingNotesData = { notes: tempVar, meetingId: meetingId }
          const response = await createMeetingNotes({ meetingNotesData: meetingNotesData })

          if (response && response?.id) {
            if (!meetingEnded) {
              MeetingEnd(meetingId)
              setloading(false)
            }
          }

        } else {
          setloading(false)
          if (!meetingEnded) {
            MeetingEnd(meetingId)
            setloading(false)
          } else {
            checkReportee()
          }
        }

      }

    }
    catch {
      setloading(false)
    }


    // end the meeting at the success of this mutation and check if it is a instant 1:1 if not then open a modal to schedule next meeting
  }

  const sumitEffectivenessFeedback = async (managerStarCount, oneononeStarCount, sugessions, scheduleNextMeeting) => {

    setloading(true);
    if (sugessions && (!managerStarCount || !oneononeStarCount)) {
      setManagerRatingMsg(true)
      setloading(false)
    }
    else if (!sugessions && managerStarCount && !oneononeStarCount) {
      setManagerRatingMsg(true)
      setloading(false)
    }
    else if (!managerStarCount && !oneononeStarCount) {
      setManagerRatingMsg(true)
      setloading(false)
    }
    else if (!sugessions && !managerStarCount && oneononeStarCount) {
      setManagerRatingMsg(true)
      setloading(false)
    }

    else if (oneononeStarCount && managerStarCount) {


      let manager;
      if (is_reportee) {
        manager = meetingById?.member?.edges?.filter((element) =>
          getIntFromString(element?.node?.user?.id) !== getIntFromString(me?.id)
        )
      } else {
        manager = [{ node: me }]
      }

      let meetingFeedbackitems = {
        meetingId: getIntFromString(meetingById?.id),
        meetingEffectiveness: 20 * (oneononeStarCount - 1),
        hostSkills: 20 * (managerStarCount - 1),
        suggestions: sugessions,
        meetingHostId: getIntFromString(manager[0]?.node?.user?.employee?.id),
        anonymous: rateoneonone
      }
      let res = await createMeetingFeedback({ meetingFeedback: meetingFeedbackitems })

      if ( res ) {
          if ( scheduleNextMeeting ) {

            history.push( `${one_on_one_route?.addOneonOne}?previous=${getIntFromString( meetingById?.id )}` )
          }
          else {

            history.push( one_on_one_route?.oneOnOneHome?.replace(":type","upcoming") )
          }
      }
    } else {
      setloading( false )
      history.push( one_on_one_route?.oneOnOneHome?.replace(":type","upcoming") )
    }


  }
  const soulToolTip = () => (
    <p>
      Here's a tip to make your 1:1 more effective!
      <br />
      <br />
      Based on your team member's values, you can weave in a directly actionable tip that focuses on bringing those values to work every day. This will help them feel more engaged in their work and strengthen your relationship with them. The purpose of these tips is to provide actionable steps that you can take or encourage your team members to take.
      <br />
      <br />
      For example, active listening can be a helpful tip if empathy is your team member's core value. Encourage uninterrupted listening when others are speaking, both for yourself and your team members.
    </p>
  )

  const SaveMeeting=()=>{ 
    if(agenda_unsaved || action_item_unsaved || note_unsaved){
      UnSavedDataWarning(true)
      setHideButtonPanel(true)
    }else{
      setSaveWarning(true); 
      setHideButtonPanel(true)
    }
   }


  return (
    <>
      <MetaTags
        title="1:1 meeting on going"
        description="This is 1:1 meeting on going page."
      />


    
      <Spin spinning={loading}>
        <Row justify="center" align="center" style={{ display: 'flex', flexDirection: 'column', gap: "10px", position: 'relative', marginTop: "25px", paddingBottom: '25px' }} className="one-on-one-heading-seaction">
          {
            !callState && (
              <img className="back-icon-one-on-one back-arrow-global" style={{ cursor: "pointer", margin: '0 0.3em' }} src={Back_icon} alt="" onClick={() => { setBackButtonWarning(true); setHideButtonPanel(true) }} />
            )
          }

          <div style={{ display: "flex", flexDirection: "row", gap: "10px", justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>
              <h2 className="one-on-one-head">{location.search == "" && <img src={Instant_icon} style={{ marginRight: '10px' }} alt="" />}  {meetingById?.title}</h2>
              <h4 className="one-on-one-time-title">{displayUtcToUserTimezone(meetingById?.when, "DD MMM YYYY")}</h4>
            </div>
            <img className="back-icon-one-on-one back-arrow-global" style={{ visibility: 'hidden', margin: '0 0.3em' }} src={Back_icon} alt="" />

          </div>

        </Row>
        <Row align="center" justify="center" style={{ width: "100%", marginBottom: "85px", position: "relative" }}>
          <PreviousMeetingCardContainer
            {...props}
            permission={permission}
            MeetingId={meetingById?.previousMeeting?.id}
            meetingByIdData={meetingById}
            me={me}
            is_reportee={is_reportee}
            createdByMe={createdByMe}
            history={history}
            onExpand={( e ) => setShowTip( e )}
            previousMeetingCout={count=>setPreviousCount(count)}
            
          />
       

          <Col span={24} align="center" style={{ marginTop: "20px" }}>

            <Row justify="center" align="center" style={{ gap: "20px" }}>
              <Col lg={permission?.user_report_permission?17:22} md={24} sm={24} xs={24} className="meeting-details-container">
                <h4 className="current-one-on-one-meeting-head" style={{ paddingLeft: "10px" }}><span>Current 1:1 -</span> {meetingById?.title}</h4>
                <Row justify="center" align="center" style={{ width: "100%" }} gutter={20} >
                  <Col lg={14} md={23} sm={24} xs={24} style={{ marginTop: "10px" }}>
                    <div className="past-meeting-data-container one-on-one-tab one-on-one-meeting-tab" >
                      <Tabs defaultActiveKey="agenda" centered={true}>
                        <TabPane tab="AGENDA" key="agenda">
                          <AgendaListContainer {...props} setHideButtonPanel={setHideButtonPanel} hidePlaceHolder={true} addbutton={true} agendaList={agendaList} meetingByIdData={meetingById} noAddOption={false} memberslist={memberlist} onSubmit={(e) => { SubmitActionItem(e) }} setcloseAddAgenda={closeAddAgenda} actionEnable={(e) => setAgendaUnsaved(e)} />
                        </TabPane>
                        <TabPane tab="ACTION ITEM" key="action-item">
                          <ActionItemsListContainer  {...props} meetingStarted={true} setHideButtonPanel={setHideButtonPanel} hidePlaceHolder={true} addbutton={true} actionList={actionList} meetingByIdData={meetingById} noAddOption={false} memberslist={memberlist} onSubmit={(e) => { SubmitActionItem(e) }} setcloseAddAgenda={closeAddAgenda} actionEnable={(e) => setActionItemUnsaved(e)} statusChange={true} checkSub={false} />
                        </TabPane>
                        <TabPane tab="NOTE" key="note" style={{ height: '100%', border: "1px solid #D9D9D9", borderRadius: "8px", padding: "10px" }}>
                          <Spin spinning={loading}>
                            <Col style={{ width: "100%" }}>
                              <div className="one-on-one-ck-editor"  >
                                <CKEditorFieldOneOnOne
                                  // value={FieldName}
                                  name={FieldName}
                                  id="1_1_selfNote"
                                  height={ckHeight == 340 ? 340 : ckHeight == 410 ? 410 : 190}
                                  onClick={scrolltoview('1_1_selfNote')}

                                  initialValue={tempVar}
                                  changeHandler={(e) => handleEditorChange(e)}

                                />
                              </div>
                            </Col>
                            <Row align="end">
                              <img src={agenda_cancel} alt="" style={{ marginLeft: '10px', cursor: "pointer" }} onClick={(e) => resetFieldName(e)} />
                              {enable ? <img src={save_enabled} alt="" style={{ marginLeft: '10px', cursor: "pointer",width:"15px" }} onClick={() => saveMeetingNote()} /> : <img src={save_disabled} alt="" style={{ marginLeft: '10px', cursor: "pointer",width:"15px" }} />}
                            </Row>
                          </Spin>
                        </TabPane>

                        {
                          permission?.feedback_permission && (
                            <TabPane tab="FEEDBACK" key="feedback">
                              <MeetingFeedbackContainer {...props} meetingStarted={true} setHideButtonPanel={setHideButtonPanel} hidePlaceHolder={true} feedbackList={feedbackList} meetingByIdData={meetingById} noAddOption={false} memberlist={memberlist} />
                            </TabPane>
                          )
                        }
                      </Tabs>
                    </div>
                  </Col>
                  <Col lg={10} md={23} sm={24} xs={24} style={{ marginTop: "10px" }}>
                    <CallTranscriptHandler setToolLoader={setToolLoader} relateddocument={meetingById} document_type={"one_on_one_meeting"} {...props} transcription={true} participants={meetingParticipiants} setcallState={(e) => { setcallState(e) }} />
                  </Col>
                </Row>
              </Col>
              {
                permission?.user_report_permission && (
                  <Col className="meeting-tool-container" lg={{ span: 6 }} md={{ span: 0 }} xs={{ span: 0 }} sm={{ span: 0 }} style={{ position: 'relative'  }}>

                    <Row justify="center" align="center" style={{ width: '100%', flexDirection: "column", padding: "10px 0px" }}>
                      <h2 className="meeting-tool-title" style={{ display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "10px" }}><img src={ProfileLogo} alt="" /> About {ToolMember?.firstName} </h2>
                      <p className="meeting-tool-title-para">Powered by SOUL</p>
                    </Row>


                    <ColScroll style={{ height: '400px', overflow: 'auto', padding: '0px 10px', marginTop: "10px" }}>

                      {(ToolMember) ?
                        <Col id={id}>

                          <ReportTool
                            {...props}
                            user={ToolMember}
                          />
                        </Col>
                        :
                        <div align="center" style={{ marginTop: "150px" }}>
                          <Spin spinning={true} ></Spin>
                        </div>
                      }
                    </ColScroll>


                  </Col>
                )
              }

            </Row>
          </Col>
        </Row>

        {
          (!hideButtonPanel) &&
          <div className={`one-on-one-bottom-div ${app_sidebar_expand ? 'one-on-one-div-margin-left' : ''}`}>
            <Button className="one-on-one-bo-back-btn" disabled={callState} onClick={() => history.goBack()}>Go Back</Button>
            <Button className="one-on-one-save-btn" onClick={() => SaveMeeting()} disabled={callState}>I'm Done</Button>
          </div>
        }

        {/* Unsaved data warning */}

        <Modal
          footer={null}
          centered
          visible={unsaved_data_warning}
          destroyOnClose={true}
          closable={false}>

          <>
            <Row align="middle" justify="center">
              <h2 className="one-on-one-meeting-popup-head" style={{ fontWeight: 700 }}>Warning</h2>
              <img src={Agenda_close} alt="" style={{ cursor: "pointer", position: 'absolute', right: '18px', top: '27px' }} onClick={() => { UnSavedDataWarning(false); setHideButtonPanel(false) }} />
            </Row>

            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", padding: "10px 10px 10px 10px" }}>
              <div style={{ width: "5px", height: "5px" }}></div>
              <p className="one-on-one-meeting-popup-para">{`Your ${action_item_unsaved?'action item':""} ${agenda_unsaved?'agenda':""} ${note_unsaved?'meeting notes':""} might not be saved!.`}<br/> Do you wish to leave without saving them?</p>
            </div>
            <div>
              <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "center", paddingBottom: '8px' }} >
                <Button onClick={() => { UnSavedDataWarning(false); setHideButtonPanel(false) }} style={{ width: "100%", maxWidth: "120px", margin: "5px" }} className="one-on-one-cancel-btn-modal">NO</Button>
                <Button onClick={() => { UnSavedDataWarning(false); handleSubmit() }} style={{ width: "100%", maxWidth: "120px", margin: "5px" }} className="one-on-one-save-btn-modal">Yes</Button>
              </div>
            </div>
          </>
        </Modal>


        <Modal
          footer={null}
          centered
          visible={savewarning}
          destroyOnClose={true}
          closable={false}>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", position: "relative" }}>
            <img src={Agenda_close} alt="" style={{ position: "absolute", top: "0px", right: "0px", cursor: "pointer" }} onClick={() => setSaveWarning(false)} />
            <h2 className="one-on-one-meeting-popup-head">Warning</h2>
            <p className="one-on-one-meeting-popup-para" style={{ marginTop: "10px" }}>Are you sure you want to save the 1:1 ?</p>
            <Row justify="center" align="center" style={{ gap: "10px", marginTop: "10px" }}>
              <Button onClick={() => { setSaveWarning(false); setHideButtonPanel(false) }} className="one-on-one-cancel-btn-modal"> Cancel</Button>
              <Button onClick={() => handleSubmit()} className="one-on-one-save-btn-modal" >Save</Button>
            </Row>
          </div>
        </Modal>

        <Modal
          footer={null}
          centered
          visible={backButtonWarning}
          destroyOnClose={true}
          closable={false}>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", position: "relative" }}>
            <img src={Agenda_close} alt="" style={{ position: "absolute", top: "0px", right: "0px", cursor: "pointer" }} onClick={() => { setBackButtonWarning(false); setHideButtonPanel(false) }} />
            <h2 className="one-on-one-meeting-popup-head">Warning</h2>
            <p className="one-on-one-meeting-popup-para" style={{ marginTop: "10px" }}>You have not saved your 1:1. Any unsaved data will be lost.
              Are you sure you want to discard unsaved data?</p>
            <Row justify="center" align="center" style={{ gap: "10px", marginTop: "10px" }}>
              <Button onClick={() => { setBackButtonWarning(false); history.goBack() }} style={{ padding: '8px 21px' }} className="one-on-one-cancel-btn-modal"> discard</Button>
              <Button onClick={() => handleSubmit()} className="one-on-one-save-btn-modal">Save</Button>
            </Row>
          </div>
        </Modal>

        <Modal
          footer={null}
          centered
          visible={savewarning}
          destroyOnClose={true}
          closable={false}>

          <>
            <Row align="middle" justify="center">
              <h2 className="one-on-one-meeting-popup-head" style={{ fontWeight: 700 }}>Warning</h2>
              <img src={Agenda_close} alt="" style={{ cursor: "pointer", position: 'absolute', right: '18px', top: '27px' }} onClick={() => { setSaveWarning(false); setHideButtonPanel(false) }} />
            </Row>

            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", padding: "10px 10px 10px 10px" }}>
              <div style={{ width: "5px", height: "5px" }}></div>
              <p className="one-on-one-meeting-popup-para">Are you sure you want to leave this meeting? </p>
            </div>
            <div>
              <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "center", paddingBottom: '8px' }} >
                <Button onClick={() => { setSaveWarning(false); setHideButtonPanel(false) }} style={{ width: "100%", maxWidth: "120px", margin: "5px" }} className="one-on-one-cancel-btn-modal">NO</Button>
                <Button onClick={() => { setSaveWarning(false); handleSubmit() }} style={{ width: "100%", maxWidth: "120px", margin: "5px" }} className="one-on-one-save-btn-modal">Yes</Button>

              </div>
            </div>
          </>
        </Modal>

        <Modal
          footer={null}
          centered
          visible={effective_feedback}
          destroyOnClose={true}
          closable={false}>
          <>
            <EffectiveFeedback
              is_reportee={is_reportee}
              goOneOnOne={goOneOnOne}
              setRateOneonOne={setRateOneonOne}
              setManagerRatingMsg={setManagerRatingMsg} managerRatingMsg={managerRatingMsg} meetingById={meetingById}
              sumitEffectivenessFeedback={sumitEffectivenessFeedback} setHideButtonPanel={setHideButtonPanel} handleOpenFeedBack={handleOpenFeedBack} Seteffective_feedback={Seteffective_feedback} {...props} />
          </>
        </Modal>

        <MeetingEndedModal meetingEnded={meetingEnded} setMeetingEnded={setMeetingEnded} setHideButtonPanel={setHideButtonPanel} handleSubmit={handleSubmit} />

        <Modal footer={null}
          centered
          visible={scheduleNextMEeeting}
          destroyOnClose={true}
          closable={false}>
          <ScheduleNextMeeting setScheduleNextMeeting={setScheduleNextMeeting} goOneOnOne={goOneOnOne} meetingById={meetingById} />
        </Modal>

        <MeetingEndedModal endMeetingHandler={endMeetingHandler} meetingEnded={meetingEnded} setMeetingEnded={setMeetingEnded} setHideButtonPanel={setHideButtonPanel} handleSubmit={handleSubmit} />

      </Spin>
      {permission&&permission?.runpod_permission&&<MeetingAiTipContainer {...props}/>}
    </>
  )


}

export default MeetingStartView;

const RowDiv = styled(Row)`
display:flex;
justify-content:space-between;
align-items:center;
margin-bottom:20px
`
const CreateButton = styled(Button)`
min-width:90px;
height:40px;
border-radius:5px;
margin-top:10px;
`

const ColScroll = styled(Col)`
::-webkit-scrollbar {
  height: 0px;
  width: 4px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  -moz-box-shadow:  inset 0 0 5px #fff;
  -webkit-box-shadow: inset 0 0 5px #fff;
  border-radius: 10px;
  padding-right: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color:#F5F5F5;
}
`

export const MyTable = styled(Table)`
  table {
    border-spacing: 0 1em;
    tbody {
      tr {
        td {
          border: 1px solid rgba(0, 0, 0, 0.06) !important;
          border-style: solid none !important;
        }
        td:first-child {
          border-left-style: solid !important;
          border-top-left-radius: 5px !important;
          border-bottom-left-radius: 5px !important;
        }
        td:last-child {
          border-right-style: solid !important;
          border-bottom-right-radius: 5px !important;
          border-top-right-radius: 5px !important;
          border-bottom: 1px solid rgba(0, 0, 0, 0.06) !important;
        }
      }
    }
  }
`;
const ExpansionCard = styled(Card)`
border:2px solid #CACACA
`

const NavigateButton = styled(Button)`
max-width:fit-content;
cursor:pointer;
min-width:90px;
height:40px;
border-radius:5px;
`