import gql from 'graphql-tag';
import { VERTICAL_TYPE } from './VerticalType.gql';

export const DELETE_VERTICAL = gql`
  ${VERTICAL_TYPE}
  mutation deleteVertical($id: ID!) {
    deleteVertical(id: $id) {
      vertical {
        ...VerticalType
      }
    }
  }
`;
