import React from 'react';
import EmployeeIDPStatusView from '../components/employeeIDPStuatusview';
import { compose } from '../../core';
import { withApollo } from '@apollo/client/react/hoc';
import { IDP_REQUEST, MANAGER_EMPLOYEE_LIST } from '../graphql/userIdpList.gql';
import { idp_status } from '../idp.config';
import { getIntFromString, globalPermissionValidator } from 'modules/look';
import { idp_permission } from 'Permissions/idp.permission';
import NoPermissionView from 'modules/kudos-user/component/NoPermissionComponent';
import { Spin } from "antd"

const EmployeeIDPStatus = (props) => {

  const { me, client, userPermission } = props
  const [idp_page_info, SetIdpPageInfo] = React.useState()
  const [request_list, setRequestList] = React.useState([])
  const [permission, setPermission] = React.useState()
  const [emp_idp_list_loading, setEmpIdpListLoading] = React.useState(false)
  const [org_employees, setORGEmployees] = React.useState()
  const filter_ref = React.useRef({})
  const reuest_list_ref = React.useRef([])

  let available_status = Object.values(idp_status)?.map(i => i?.for_mutation)?.filter(i => i != idp_status?.Draft?.for_mutation)
  //Permission Check
  React.useEffect(() => {
    if (userPermission?.length) {
      let permission = globalPermissionValidator(idp_permission, userPermission)
      setPermission(permission)
    }
  }, [userPermission])

  React.useEffect(() => {
    reuest_list_ref.current = request_list
  }, [request_list])

  React.useEffect(() => {
    if (org_employees?.length) {
      EmployeeidpRequest({ first: 10 })
    } else {
      emptyDataSet()
    }
  }, [org_employees])

  React.useEffect(() => {
    if (me && permission?.required_permission) {
      getManagerPermission()
    }
  }, [me, permission])

  const getManagerPermission = async () => {
    setEmpIdpListLoading(true)
    const { data } = await client.query({
      query: MANAGER_EMPLOYEE_LIST
    });
    if (data?.me) {
      let { teamManagerEmployee, verticalHeadEmployee, orgPocEmployee, orgCeoEmployee } = data?.me?.employee
      let employee_list = []
    
      if (teamManagerEmployee?.totalCount) {
        let list = teamManagerEmployee?.edges?.map(({ node }) => node?.employees?.edges)
        list = [].concat.apply([], list)?.map(({ node }) => getIntFromString(node?.id))
        employee_list = employee_list.concat(list)
      }
      if (verticalHeadEmployee?.totalCount) {
        let list = verticalHeadEmployee?.edges?.map(({ node }) => node?.employees?.edges)
        list = [].concat.apply([], list)?.map(({ node }) => getIntFromString(node?.id))
        employee_list = employee_list.concat(list)
      }
      if (orgPocEmployee?.totalCount) {
        let list = orgPocEmployee?.edges?.map(({ node }) => node?.employees?.edges)
        list = [].concat.apply([], list)?.map(({ node }) => getIntFromString(node?.id))
        employee_list = employee_list.concat(list)
      }
      if (orgCeoEmployee?.totalCount) {
        let list = orgCeoEmployee?.edges?.map(({ node }) => node?.employees?.edges)
        list = [].concat.apply([], list)?.map(({ node }) => getIntFromString(node?.id))
        employee_list = employee_list.concat(list)
      }
      employee_list = [...new Set(employee_list)]
      employee_list = employee_list?.filter(i=>i!=getIntFromString(me?.employee?.id))
      setORGEmployees(employee_list)
    }
  }

  const EmployeeidpRequest = async (filter) => {
    filter_ref.current = { ...filter, cursor: null } || {}
    if(!org_employees?.length){
      emptyDataSet()
      return
    }
    setEmpIdpListLoading(true)
    const { data } = await client.query({
      query: IDP_REQUEST,
      variables: { ...filter, status_In: available_status, employeeId_In: org_employees },
      fetchPolicy: 'network-only'
    });
    if (data?.allIdp) {
      SetIdpPageInfo({
        hasNextPage: data?.allIdp?.pageInfo?.hasNextPage,
        cursor: data?.allIdp?.pageInfo?.endCursor,
      })

      let idpLists = data?.allIdp?.edges?.map(({ node }) => node)
      if (filter?.cursor) {
        idpLists = reuest_list_ref.current.concat(idpLists)
      }
      setRequestList(idpLists)
    }
    setEmpIdpListLoading(false)
  }

  const emptyDataSet =()=>{
    setRequestList([])
    SetIdpPageInfo({
      hasNextPage: false
    })
    setEmpIdpListLoading(false)
  }

  return (
    <>
      {
        (permission && permission?.required_permission) && (
          <EmployeeIDPStatusView {...props}
            request_list={request_list}
            emp_idp_list_loading={emp_idp_list_loading}
            employee_idp_page_info={idp_page_info}
            getEmployeeIdpList={(filter) => EmployeeidpRequest({ ...filter_ref.current, ...filter })}
          />)}
      {(permission && !permission?.required_permission) && (<NoPermissionView />)}
      {
        !permission && (
          <div className="role-no-permission-container">
            <Spin spinning={true} size="large"></Spin>
          </div>
        )
      }
    </>
  )
}

export default compose(withApollo)(EmployeeIDPStatus);