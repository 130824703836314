import React from 'react';
import { Col } from "antd";
import '../OkrStyles/OkrRequestsPanelStyles.css';
import OkrVerticalBar from './OkrVerticalBar';
import OkrAssignmentContainer from '../container/OkrAssignmentContainer';
import OkrCollaborateContainer from '../container/OkrCollaborateContainer';
import OkrDiscardTableContainer from '../container/DiscardRequestTableContainer'
import { MetaTags } from 'modules/look';
const DiscardRequestView = (props) => {
    const {location} = props
    const [discard_tab,setDiscardTab]=React.useState("discard")
    let avilabe_tab=["discard","collaboration","assignment"]
    React.useEffect(()=>{
        if(location?.search){
            let  params = new URLSearchParams(location.search)
            let tab = params?.get('tab')
            if(avilabe_tab?.includes(tab)){
                setDiscardTab(tab)
            }else{
                setDiscardTab("discard")
            }
        }else{
            setDiscardTab("discard")
        }
    },[location])

    const list = {
        discard:OkrDiscardTableContainer,
        collaboration:OkrCollaborateContainer,
        assignment:OkrAssignmentContainer
    }
    const Current_view = list[discard_tab || "discard"]
    return (
        <>
        <MetaTags title="OKR" description="OKR Home" />
        <div className="rd-root-style">
            <OkrVerticalBar {...props} current_tab={
              {
                key: 'request',
                label: 'Request'
              }
            } />
                <Col span={24}>
                    <Col className='tab-inner'>
                    <Current_view  {...props}/>
                    </Col>
                </Col>
            </div>
        </>
    )
}
export default DiscardRequestView