import gql from 'graphql-tag';
// DO NOT CHANGE THESE QUERY
export const OKR_CHILD_LIST_QUERY = gql`
query contributors($createdBy: ID,$okr_Parent: ID,$tz: String,$recipient:ID){
    contributors(createdBy:$createdBy,okr_Parent:$okr_Parent,role:"OWNER",orderBy: ["okr__duedate"]){
        edges {
          node {
            id
            progress
            okr {
              id
              title(tz:$tz)
              goalStatement
              correctionDeadline
              duedate
              startdate
              badgeAward(recipient:$recipient){
                edges{
                  node{
                    id
                    evidence
                    issuer{
                      id
                      user{
                        id
                        firstName
                        lastName
                      }
                    }
                    badge{
                      id
                      image
                      title
                      behaviouralDescription
                    }
                  }
                }
              }
              childSet(contributor_CreatedBy:$createdBy){
                totalCount
              }
              progress
              discardRequestRaised:discardRequest(initiator:$recipient){
                totalCount
               }
              discardRequest(status_Iexact:"PENDING"){
                totalCount
               }
               isDiscarded {
                id
                status
              }
              contributorSet{
                edges{
                  node{
                    id
                    role
                    employee{
                      id
                      user{
                        id
                        firstName
                        lastName
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
}
`

export const OKR_CHILD_SUBSCRIPTION = gql`
subscription contributorSubscription($createdBy: ID) {
    contributorSubscription{
        mutation
        contributor{
          id
          okrType
          role
          progress
          okr {
            id
            title
            goalStatement
            correctionDeadline
            duedate
            startdate
            parent{
              id
            }
            progress
            childSet{
              totalCount
            }
            badgeAward{
              edges{
                node{
                  id
                  evidence
                  issuer{
                    id
                    user{
                      id
                      firstName
                      lastName
                    }
                  }
                  badge{
                    id
                    image
                    title
                    behaviouralDescription
                  }
                }
              }
            }
            discardRequestRaised:discardRequest(initiator:$createdBy){
              totalCount
             }
            discardRequest(status_Iexact:"PENDING"){
              totalCount
             }
             isDiscarded {
              id
              status
            }
            contributorSet{
              edges{
                node{
                  id
                  role
                  employee{
                    id
                    user{
                      id
                      firstName
                      lastName
                    }
                  }
                }
              }
            }
          }
        }
    }
}
`

// import gql from 'graphql-tag';

export const OKR_LIST_QUERY = gql`
query contributors($first:Int,$offset: Int,$createdBy: ID,$recipient:ID){
  AllCascadingObjectives:contributors(role_In: ["COLLABORATOR", "ASSIGNED"],first:$first,offset:$offset) {
    totalCount
    edgeCount
    pageInfo{
      hasNextPage
    }
    edges {
      node {
        okrType
        role
        id
        okr {
          id
          title
          goalStatement
          progress
          correctionDeadline
          duedate
          startdate
          badgeAward(recipient:$recipient){
            edges{
              node{
                id
                evidence
                issuer{
                  id
                  user{
                    id
                    firstName
                    lastName
                  }
                }
                badge{
                  id
                  image
                  title
                  behaviouralDescription
                }
              }
            }
          }
          discardRequest(status_Iexact:"PENDING"){
            totalCount
            edges{
              node{
                id
              }
            }
           }
          isDiscarded{
            id   
            status
          }
          contributors{
            edges{
              node{
                id
                user{
                  id
                  firstName
                  lastName
                  employee{
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  
  AllNonCascadingObjectives:contributors(role_In: ["OWNER"], createdBy:$createdBy, okr_Parent_Isnull:true,first:$first,offset:$offset) {
    totalCount
    edgeCount
    pageInfo{
      hasNextPage
    }
    edges {
      node {
        id
        role
        okrType
        okr {
          id
          title
          goalStatement
          progress
          badgeAward(recipient:$recipient){
            edges{
              node{
                id
                evidence
                issuer{
                  id
                  user{
                    id
                    firstName
                    lastName
                  }
                }
                badge{
                  id
                  image
                  title
                  behaviouralDescription
                }
              }
            }
          }
          discardRequest(status_Iexact:"PENDING"){
            totalCount
           }
          isDiscarded{
            id   
            status
          }
          duedate
          startdate
          contributors{
            edges{
              node{
                id
                user{
                  id
                  firstName
                  lastName
                  employee{
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`