import React from 'react';
import { Row, Col, Button } from 'antd';
import { useLocation } from 'react-router-dom';

export const ButtonsComponent = props => {
  const { setButton, title = 'Save', edit = false, disabled = false, save = true, saveadd = true, saveedit = true } = props;
  const location = useLocation();
  return (
    <Row justify="start" gutter={20}>
      {save && (
        <Col /* span={11} */ offset={1}>
          <Button
            disabled={disabled}
            style={{ borderRadius: 8 }}
            htmlType="submit"
            shape="round"
            onClick={() => setButton( true )}
          >
            {title}
          </Button>
        </Col>
      )}

      {saveadd && !location.pathname.includes( 'edit' ) && (
        <Col /* span={11} */ offset={1}>
          <Button
            disabled={disabled}
            style={{ borderRadius: 8 }}
            htmlType="submit"
            shape="round"
            onClick={() => setButton( false )}
          >
            Save and add another
          </Button>
        </Col>
      )}

      {saveedit && (
        <Col /* span={12} */ offset={1}>
          <Button
            disabled={edit || disabled}
            style={{ borderRadius: 8 }}
            htmlType="submit"
            shape="round"
            onClick={() => setButton( undefined )}
          >
            Save and continue editing
          </Button>
        </Col>
      )}
    </Row>
  );
};

export function ButtonsCatch( item, route, history, redirectRoute ) {
  if ( item ) {
    if ( route ) {
      history.push( route );
    } else history.goBack();
  } else if ( item === undefined ) {
    history.push( redirectRoute );
  } else {
    if ( window.location.href.includes( 'edit' ) ) {
      history.push( redirectRoute );
    } else redirectRoute ? ( window.location = redirectRoute ) : window.location.reload();
  }
}
