import gql from 'graphql-tag';

export const CREATE_DISCARD_MUTATION = gql`
mutation createDiscardRequest($discardRequestData:DiscardRequestInput!){
    createDiscardRequest(discardRequestData:$discardRequestData){
        discardRequest{
            id
        }
    }
}
`