import { Col, Form, Row } from 'antd';
import React from 'react';
import { ButtonsComponent, CKEditorField } from '../../../../look';

export const CKEditorFieldName = 'question';
export const CKEditorFieldName1 = 'answer';

const FaqQuestionFormComponent = props => {
  const { onSubmit, faqQuestionById } = props;
  const [button, setButton] = React.useState('');

  const initialValues = {
    question: faqQuestionById && faqQuestionById.question,
    answer: faqQuestionById && faqQuestionById.answer
  };

  return (
    <Row>
      <Col span={24}>
        <Form
          layout="vertical"
          onFinish={values => onSubmit(values, button)}
          initialValues={initialValues}
          required={false}
          scrollToFirstError={true}
        >
          <CKEditorField
            name={CKEditorFieldName}
            label="Question"
            rules={[{ required: true, message: 'Question is required!' }]}
            initialValue={initialValues && initialValues.question}
          />

          <CKEditorField
            name={CKEditorFieldName1}
            label="Answer"
            rules={[{ required: true, message: 'Answer is required!' }]}
            initialValue={initialValues && initialValues.answer}
          />
          <ButtonsComponent setButton={setButton} />
        </Form>
      </Col>
    </Row>
  );
};

export default FaqQuestionFormComponent;
