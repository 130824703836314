import React from "react";
import { Row, Col, Button,Input,Radio ,Space, Dropdown ,Checkbox,Menu,Spin, Modal,Collapse  } from "antd";
import styled from "styled-components";
import { LeftOutlined,EditOutlined,DeleteOutlined,RightOutlined } from "@ant-design/icons";
import { MetaTags,getIntFromString,scrolltoview,ReactMarkdown } from "../../look";
import ProfileLogo from '../../assets/avatar.jpg';
import AdditionalDetail from './additionalDetailBasicView'
import { FEEDBACK_TOOL_ID } from '../../../config'
import ActionDetailContainer from "../containers/ActionDetailContainer";
const { Panel } = Collapse;
const PreviousView = props => {
    const { me,history,meetingById,remove,createdByMe,selfNotes,navigateToFeedback,actionList }=props
    const [agendaList, setagendaList] = React.useState([]);
    
    const [feedbackList, setfeedbackList] = React.useState([]);
    const [confirmationModalVisible, setdConfirmationModalVisible] = React.useState(false);
    const [actionData,setActionData] = React.useState();
    React.useEffect(()=>{
        if(meetingById ){
           
            
            let feedbackList =  meetingById?.feedback?.edges?.map(({node})=>node)
          
            // let setAgenda =[]
            // agendaList.forEach(element => {
            //     let data =
            //     {
            //         id:element?.id,
            //         title:element?.title,
            //         content:null,
            //         navigateTo:null
            //     }
            //     if(element?.milestone){
            //         data.content= element?.milestone?.title
            //       }
            //       else if(element?.commitment){
            //         data.content= element?.commitment?.commitment?.title
            //       }
            //       else if(element?.coachingCommitment){
            //         data.content= element?.coachingCommitment?.coachingcommitment?.title
            //       }
            //       setAgenda.push(data)
            // });
            let setFeedback =[]
            feedbackList.forEach(element => {
                let data =
                {
                    id:element?.id,
                    title:element?.feedbackTo?.user?.firstName,
                    content:element?.feedbackType,
                    navigateTo:null
                }
                setFeedback.push(data)
            })
            // setagendaList(setAgenda)
           
            setfeedbackList(setFeedback)
        }
    },[meetingById])

   
      const ReportViewActionItem =(data)=>{
  
        let list =  meetingById?.actionitemSet?.edges?.map(({node})=>node)
        let detail = actionList.find(item=>item.id===data?.id)   
        let actionData =  list?.find(item=>!item?.isAgenda && item?.id===data?.id)
        if(actionData){
            actionData.completed=detail?.completed?true:false
            setActionData(actionData)
        }
        
      }

     const closeReportView =(data)=>{
        setActionData(null)
        // if(data?.id){
        //     let list = [].concat(actionList)
        //     let detail = list.find(item=>item.id===data?.id)         
        //     if(detail && detail?.completed !== data?.completed){
        //        let index = list.indexOf(detail)
        //        list[index].completed=data?.completed
        //        setactionList(list)
        //     }
        // }
     }
  
    return (
        <>
            {
                actionData && (
                    <Modal 
                    closable={false}
                    visible={actionData?true:false}
                    destroyOnClose={true}
                    footer={false}
                    width='50%'
                    maxHeight="80vh"
                    >

                       <ActionDetailContainer
                        actionDetail={actionData}
                         editable={true}
                         owner={meetingById?.owner}
                          {...props} 
                          onClose={(e)=>{closeReportView(e)}}
                           /> 

                    </Modal>
                )
            }
            <div>
       
            {meetingById && (
              <>
                <div style={{padding:'0px 15px'}}>
                <h4 style={{fontSize:'20px'}}>{meetingById?.title}</h4>
                {
                    selfNotes && (
                        <div style={{marginTop:'20px'}}>
                        {/* <p style={{fontSize:'15px'}}>Notes</p> */}
                        <Collapse >
                            {
                                selfNotes?.map((element,index) => {
                                    return(
                                        <Panel header={`${element?.employee?.user?.username}'s Notes`} key={index}>
                                            <div style={{padding:"10px 15px"}}>
                                            <ReactMarkdown>{element?.notes}</ReactMarkdown>
                                            </div>
                                           
                                        </Panel>
                                    )
                                })
                            }
                        </Collapse>
                       
                        </div>
                    )
                }
              
                
                    {/* <Col>
                        <p style={{fontSize:'15px',fontWeight:'bold'}}>Creator</p>  
                        <ProfileContainer >
                        {
                            meetingById?.owner?.user?.profile?.profileImage?
                            <img src={meetingById?.owner?.user?.profile?.profileImage} style={{width:'40px',height:'40px',borderRadius:'50%'}} alt="" />
                            :
                            <img src={ProfileLogo} style={{width:'40px',height:'40px',borderRadius:'50%'}} alt="" />
                            
                        }
                        <p style={{fontSize:'15px',marginTop:'10px'}}>{meetingById?.owner?.user?.firstName}</p> 
                        </ProfileContainer>
                    </Col>
                    <Col style={{marginTop:'15px'}}>
                        <p style={{fontSize:'15px',fontWeight:'bold'}}>Members</p>  
                        <Row style={{gap:'15px'}}>
                            {
                                meetingById?.member?.edges?.map(({node})=>node).map(item=>
                                    <ProfileContainer >
                                    {
                                        item.user?.profile?.profileImage?
                                        <img src={item?.user?.profile?.profileImage} style={{width:'40px',height:'40px',borderRadius:'50%'}} alt="" />
                                        :
                                        <img src={ProfileLogo} style={{width:'40px',height:'40px',borderRadius:'50%'}} alt="" />
                                        
                                    }
                                    <p style={{fontSize:'15px',marginTop:'10px'}}>{item?.user?.firstName}</p> 
                                    </ProfileContainer>
                                )
                            }
                        </Row>
                      
                    </Col> */}

                    <Row gutter={20} >
                       
                        {/* agenda list*/}
                        {/* {
                            agendaList?.length?
                            <Col style={{marginTop:'20px'}}  lg={{ span: 8 }}  md={{ span: 12 }} xs={{ span: 24 }} sm={{ span: 24 }} >
                                <AdditionalDetail 
                                 label="Agenda"
                                 dataSource={agendaList}
                                />
                            </Col>
                            :null
                        } */}
                        {/* action list  */}
                       
                            <Col style={{marginTop:'20px'}} lg={{ span: 12 }}  md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }} >
                               <p style={{fontSize:'15px',fontWeight:'bold'}}>({actionList?.length}) Action Items </p>  
                                <AdditionalDetail 
                                 label=""
                                 dataSource={actionList}
                                 showReport={true}
                                 showStatus={true}
                                 showFull={true}
                                 reportView={(e)=>{ReportViewActionItem(e)}}
                                />
                            </Col>
                       
                        {/* feedback list */}
                    
                            <Col style={{marginTop:'20px'}} lg={{ span: 12 }}  md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }} >
                                <p style={{fontSize:'15px',fontWeight:'bold'}}>({feedbackList?.length}) Feedbacks </p>  
                                <AdditionalDetail 
                                  label=""
                                  dataSource={feedbackList}
                                  showReport={true}
                                  isFeedback={true}
                                  reportView={(e)=>{navigateToFeedback(e)}}
                                />
                            </Col>
                       
                    </Row>        

                </div>
              </>
            )   
            }
            </div>
        </>
    )

}
export default PreviousView;