import gql from 'graphql-tag';

export const CREATE_BADGE_LEVEL =gql`
    mutation createBadgeLevel($badgeLevelData: BadgeLevelInput!){
        createBadgeLevel(badgeLevelData:$badgeLevelData){
            badgeLevel{
                id
            }
        }
    }
`