import React from 'react';
import { Button, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

class AddIcon extends React.Component {
  render() {
    const { color = 'primary', type, size, ghost = true, tooltipSuffix, ...props } = this.props;

    let buttonSize = 'default';

    if (size === 'sm') {
      buttonSize = 'small';
    } else if (size === 'lg') {
      buttonSize = 'large';
    }

    return (
      <Tooltip title={"Add " + (tooltipSuffix ? tooltipSuffix : "")} placement="left">
        <Button
          type={color}
          htmlType={type}
          ghost={ghost}
          size={buttonSize}
          icon={<PlusOutlined />}
          shape="circle"
          {...props}
        />
      </Tooltip>
    );
  }
}
export default AddIcon;
