// @ts-nocheck
import * as React from 'react';
import { graphql } from '@apollo/client/react/hoc';

import { getItem, compose, removeItem } from '../../core';
// import ORGANIZATION_ROUTE from '../../organization/route';

import ProfileView from '../components/ProfileView';
import ROUTE from '../route';
import { globalPermissionValidator } from 'modules/look';
import { kudos_permission } from 'Permissions/kudos.permission';
import { story_permission } from 'Permissions/story.permission';

const ProfilePage = props => {
  const  {userPermission} = props
  const [permission,setpermission]=React.useState()
  const [storypermission,setStorypermission]=React.useState()
  React.useEffect(()=>{
    if(userPermission?.length){
      let permission=globalPermissionValidator(kudos_permission,userPermission)
      let permission_list=globalPermissionValidator(story_permission,userPermission)
      setpermission(permission)
      setStorypermission(permission_list)
    }
    else{
      setpermission({})
      setStorypermission({})
    }
  },[userPermission])

  return <ProfileView {...props} permission={permission} storypermission={storypermission}/>;
};

export default compose()(ProfilePage);
