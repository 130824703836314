import React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import gql from 'graphql-tag';
import { Spin, Empty } from 'antd';

import { PAGINATION_LIMIT } from '../../../../config';
import { compose } from '../../../core';

import { getIntFromString } from '../functions';

import SelectField from './SelectField';
import { withLookTagFilterUpdating, withLookTagState } from '../../containers/LookOperations';

export const ALL_STORY_TAGS_QUERY = gql`
  query allStoryTags($first: Int, $orderBy: [String], $name_Icontains: String) {
    allStoryTags(first: $first, orderBy: $orderBy, name_Icontains: $name_Icontains) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

const TagAutoComplete = props => {
  const { loading, allStoryTags, id = true, margin = true, onTagNameChange, newTab, onFiltersRemove } = props;
  const handleFilterRemove = React.useRef(() => {});

  handleFilterRemove.current = () => {
    onFiltersRemove();
  };

  React.useEffect(() => {
    return () => handleFilterRemove.current();
  }, []);

  const choices =
    allStoryTags &&
    allStoryTags.edges.length > 0 &&
    allStoryTags.edges.map(({ node }) => ({
      label: node.name,
      value: getIntFromString(node.id)
    }));

  return (
    <SelectField
      margin={margin}
      showSearch
      choices={choices}
      style={{ width: !margin && 200, marginBottom: !margin && 0 }}
      newTab={newTab}
      // labelInValue
      filterOption={false}
      offset={1}
      onSearch={onTagNameChange}
      mode="multiple"
      notFoundContent={
        loading ? (
          <div align="center">
            <Spin size="small" />
          </div>
        ) : (
          <Empty />
        )
      }
      {...props}
    />
  );
};

export default compose(
  withLookTagState,
  graphql(ALL_STORY_TAGS_QUERY, {
    options: ({ filter, orderBy }) => {
      return { variables: { first: PAGINATION_LIMIT, ...filter, orderBy } };
    },
    props({ data }) {
      const { loading, error, allStoryTags, subscribeToMore, updateQuery } = data;
      return { loading, error, allStoryTags, subscribeToMore, updateQuery };
    }
  }),
  withLookTagFilterUpdating
)(TagAutoComplete);
