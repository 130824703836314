import React from "react";
import { Spin } from "antd";

import { compose } from "../../../core";
import { ButtonsCatch, getIntFromString, withMarkdownFieldValue } from "../../../look";

import EditVerticalView from "../../components/editVerticalView";
import ROUTE from "../../route";

import { withVertical, withEditVertical, withVerticalByVerticalId } from "../../../organization/containers/OrganizationOperation";
import { CKEditorFieldName } from "../../components/verticalFormComponent";
import { withEditVerticalVerticalByVerticalId } from "./operation";

const EditVertical = props => {
    const { history, editVertical, vertical } = props;
    const [submitLoading, setSubmitLoading] = React.useState(false);

    const handleSubmit = async (values, item) => {
        setSubmitLoading(true);
        try {
            const response = await editVertical({
                id: getIntFromString(vertical.id),
                organizationId: getIntFromString(vertical.organization.id),
                ...withMarkdownFieldValue(CKEditorFieldName, values)
            });

            response &&
                ButtonsCatch(
                    item,
                    `${ROUTE.verticalDetailLink}${getIntFromString(vertical.id)}`,
                    history,
                    item === undefined ? window.location.pathname : `${ROUTE.organizationDetailLink}${getIntFromString(response.organization.id)}?args=openmodal`
                );
        } catch (e) {
            throw new Error(e.message);
        }
        setSubmitLoading(false);
    };

    return (
        <Spin spinning={submitLoading} size="large">
            <EditVerticalView onSubmit={handleSubmit} {...props} />
        </Spin>
    );
};

export default compose(
    // withVertical,
    withEditVerticalVerticalByVerticalId,
    withEditVertical
)(EditVertical);
