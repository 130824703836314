import React from "react";
import styled from "styled-components";
import { withApollo } from "@apollo/client/react/hoc";
import gql from 'graphql-tag';
import { useParams } from "react-router-dom";
import { Spin, Button, Result, Modal, Row, Col } from "antd";
import { compose } from "../../core";

import ROUTE from "../route";
import HOME_ROUTE from "../../home/route/index"

import { EMAIL_PERCEPTION_REPORT_QUERY } from "../graphql/EmailPerceptionReport.gql";
import { EMAIL_PREDICTION_REPORT_QUERY } from "../graphql/EmailPredictionReport.gql";

import { withResponseByResponseIdOptimized } from "./SurveyOperations";
import { generateReport, getIntFromString, MetaTags } from "../../look";
import { SURVEY_ID_3CS, USER_MANUAL_SURVEY_ID } from "../../../config";
import bg_image from '../../assets/thank-you-new-bg.svg'
import logo from '../../assets/logo.png'
import '../css/thank-you.css'
import survey_module_route from "modules/survey-module/routes";
import user_manual_route from "modules/user-manual/route";
import survey_route from "../route";

const ORGANIZATION_COLOR = gql`
query me{
  organization:me {
    id
    employee {
      id
      organizationSet(first:1) {
        edges {
          node {
            id
            logo
            primaryColor
            secondaryColor
          }
        }
      }
    }
  }
}`

const SurveyResponse = (props) => {
  const { loading, responseByResponseId, client, navigateRoute, me } = props;
  const [visible, setVisible] = React.useState(false);
  const [reportLoading, setReportLoading] = React.useState(false);
  const [organization_detail, setOrganizationDetail] = React.useState();
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  const { id } = useParams();
  // const generateReport = async () => {
  //   setReportLoading(true);

  //   try {
  //     if (Number(getIntFromString(responseByResponseId.survey.id)) === 1) {
  //       const { data } = client.query({
  //         query: EMAIL_PERCEPTION_REPORT_QUERY,
  //         variables: { responseId: id }
  //       });
  //     } else if (Number(getIntFromString(responseByResponseId.survey.id)) === 2) {
  //       const { data } = client.query({
  //         query: EMAIL_PREDICTION_REPORT_QUERY,
  //         variables: { responseId: id }
  //       });
  //     }
  //     setVisible(true);
  //     setReportLoading(false);
  //   } catch (e) {
  //     setReportLoading(false);
  //     // message.error('Failed! try again.');
  //     // throw new Error(e.message);
  //   }
  // };

  const organozationDetail = async () => {
    const { data } = await client.query({
      query: ORGANIZATION_COLOR,
      variables: {},
      fetchPolicy: 'network-only'
    });
    if (data) {
      setOrganizationDetail(data?.organization?.employee?.organizationSet?.edges[0]?.node)
    }
  }

  React.useEffect(() => {
    // Attach the event listener when the component mounts
    window.addEventListener('resize', handleResize);

    // Remove the event listener when the component unmounts to avoid memory leaks
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Event handler to update the screen width state
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  React.useEffect(() => {
    if (me?.employee?.organizationSet?.edges?.length) {
      organozationDetail()
    }
  }, [me])
  function handleCancel() {
    setVisible(false);
    navigateRoute(ROUTE.allOrganizationSurvey);
  }

  const EmailSent = () => (
    <>
      <Row>
        <Col lg={24} md={24} sm={0} xs={0}>
          <br />
        </Col>
      </Row>
      <div style={{ textAlign: "center" }}>
        {/* <MailSent>
          <CheckCircleFilled style={{ color: '#009c4a', fontSize: '25px' }} /> &nbsp; Email Sent! <br />
        </MailSent> */}
        <h3>
          This could take a minute.
          <br />
          Generated report will be sent to your registered email.
        </h3>
        <Row>
          <Col lg={0} md={0} sm={24} xs={24}>
            <a>
              <Button type="primary" ghost onClick={handleCancel} size="middle">
                Close
              </Button>
            </a>
          </Col>
        </Row>
        <Row>
          <Col lg={24} md={24} sm={0} xs={0}>
            <br />
          </Col>
        </Row>
        {/* <LessDivider /> */}
        <Row>
          <Col lg={24} md={24} sm={0} xs={0}>
            <a>
              <Button type="primary" ghost onClick={handleCancel} size="middle">
                Close
              </Button>
            </a>
          </Col>
        </Row>
      </div>
    </>
  );

  const handleGetFunc = () => {
    try {
      generateReport(
        id,
        getIntFromString(responseByResponseId.survey.id),
        client
      );
    } catch (err) { }
    setVisible(true);
  };

  const hexToRgba = (hex, opacity) => {
    // Remove '#' if present
    if (hex) {
      hex = hex.replace('#', '');

      // Parse the hexadecimal values for red, green, and blue
      const r = parseInt(hex.substring(0, 2), 16);
      const g = parseInt(hex.substring(2, 4), 16);
      const b = parseInt(hex.substring(4, 6), 16);

      // Calculate the alpha value based on the provided opacity (from 0 to 1)
      const alpha = opacity || 1;

      // Return the RGBA color format
      if (screenWidth > 600) {
        return `linear-gradient(to right,rgba(${r}, ${g}, ${b}, ${alpha}) 70%,#fff 70%)`;
      }
      else {
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
      }
    }
    else {
      return `linear-gradient(to right,rgba(176, 203, 31, 0.2) 70%,#fff 70%);`
    }
  }
  return (
    <div className="hank-you-root">
      <Spin
        spinning={loading || reportLoading}
        size="large"
        tip={
          reportLoading ? (
            <span style={{ color: "#000" }}>
              This could take few minutes...
            </span>
          ) : (
            ""
          )
        }
      >
        <MetaTags title="response" description="This is response page" />
        {responseByResponseId && (
          <Spin spinning={loading} size="large">
            <div className="thank-you-container" style={{ background: me?.employee?.organizationSet?.edges?.length ? hexToRgba(organization_detail?.primaryColor, 0.2) : 'linear-gradient(to right,rgba(176, 203, 31, 0.2) 70%,#fff 70%)' }}>
              <Row className="content-container" justify="space-between" align="center" style={{ gap: "20px", width: "100%" }}>
                <div className="thank-you-content-container">
                  {me?.employee?.organizationSet?.edges?.length > 0 && <img className="thank-you-logo-image" src={organization_detail?.logo} alt="" />}
                  <h1 className="thank-you-title">Thank you </h1>
                  <h4 className="thank-you-sub-title" style={{ paddingTop: "10px" }}>for participating in our survey!</h4>

                  {responseByResponseId?.survey?.multisourceResponse ?
                    <p className="thank-you-paragraph" style={{ paddingTop: "10px" }}>Invite your peers, managers, reports to take a 360 assessment for you! Their responses will help shape your managerial journey! This comprehensive evaluation would allow you to gain an insight on your strengths and areas for growth from different angles - in addition to your unique perspective, which remains integral. Rest assured, the responses received will be confidential! </p>
                    :
                    <p className="thank-you-paragraph" style={{ paddingTop: "10px" }}>
                      Your valuable feedback is greatly appreciated and will help us improve our products/services. We value your opinion and will take your responses into consideration as we strive to meet and exceed your expectations.
                    </p>}

                  <Row align="middle" className="button-div" style={{ gap: "10px", width: "100%" }}>
                    {responseByResponseId?.survey?.reportQuery && (
                      <h4 className="thank-you-sub-title" style={{ paddingTop: "10px" }}>See you Survey reports <span className="click-here-button" style={{ color: 'rgba(115, 204, 255, 1)', cursor: "pointer" }} onClick={handleGetFunc}>here</span></h4>)}
                    <button className="back-to-home-btn" onClick={() => navigateRoute(HOME_ROUTE.userdashboard)}>Back to home</button>
                    {SURVEY_ID_3CS == getIntFromString(responseByResponseId?.survey?.id) &&
                      <button className="back-to-home-btn"
                        onClick={() => navigateRoute(`${survey_route?.report3cs}`)}>See 3C Report</button>}

                    {USER_MANUAL_SURVEY_ID == getIntFromString(responseByResponseId?.survey?.id) &&
                      <button className="back-to-home-btn"
                        onClick={() => navigateRoute(user_manual_route?.home?.replace(":survey_id", getIntFromString(responseByResponseId?.survey?.id))?.replace(":response_id", getIntFromString(responseByResponseId?.id)))}>See User Manual</button>}
                    {responseByResponseId?.survey?.multisourceResponse && (<button className="back-to-home-btn" onClick={() => navigateRoute(survey_module_route?.survey_360?.replace(":surveyid", getIntFromString(responseByResponseId?.survey?.id)).replace(':responseid', getIntFromString(responseByResponseId?.id)))}>Start 360</button>)}
                  </Row>

                </div>
                <div className="bg-container">
                  <img className="thank-you-bg-image" src={bg_image} alt="" />
                </div>
              </Row>
            </div>
          </Spin>
        )}
      </Spin>

      <Modal
        footer={null}
        centered
        visible={visible}
        onCancel={handleCancel}
        bodyStyle={{ padding: "0px" }}
      >
        <ModalBody>
          <EmailSent />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default compose(withApollo, withResponseByResponseIdOptimized)(SurveyResponse);

// const MailSent = styled.div`
//   color: #bbd33e;
//   font-size: 21px;
//   margin-bottom: 10px;
// `;

const ModalBody = styled.div`
  padding: 24px 48px;
  @media only screen and (max-width: 480px) {
    padding: 24px;
  }
`;
