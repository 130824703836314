import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

import { AdminLayout, MetaTags } from '../../look';

import { user } from '../route';
import UserFormComponent from './UserFormComponent';

const AddUser = props => {
  return (
    <>
      <MetaTags title="Add user" description="This is Add User page" />
      <AdminLayout table={false} active={user} title="Add user" FormComponent={<UserFormComponent {...props} />} />
    </>
  );
};

export default AddUser;
