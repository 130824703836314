import gql from 'graphql-tag';

export const RESPONSE_SET_FOR_EMPLOYEE = gql`
  query me($user: ID,$survey: ID) {
    responseSetList:me{
        id
        employee{
          id
          organizationSet{
            edges{
              node
              {
                id
                employees(user:$user){
                  edges{
                    node{
                      id
                      user{
                        id
                        firstName
                        responseSet(survey_Multisourceresponse:true,survey:$survey){
                          edges{
                            node{
                              id
                              survey{
                                name
                              }
                              responseDuration
                              responseDate
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
  }
`;