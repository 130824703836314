import gql from 'graphql-tag';
import { OPERAND_TYPE } from './OperandType.gql';

export const CONDITION_TYPE = gql`
  ${OPERAND_TYPE}
  fragment ConditionType on ConditionType {
    id
    note
    leftOperand {
      ...OperandType
    }
    operator
    rightOperand {
      ...OperandType
    }
  }
`;
