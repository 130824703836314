import gql from 'graphql-tag';
import { EMPLOYEE_TYPE } from '../../../organization/graphql/EmployeeType.gql';

const ORGANIZATION_TYPE = gql`
  ${EMPLOYEE_TYPE}
  fragment OrganizationType on OrganizationType {
    id
  }
`;


const VERTICAL_TYPE_FIELDS = gql`
  ${ORGANIZATION_TYPE},  
  fragment VerticalTypeFields on VerticalType {
    id
    name
    description
    organization {
      ...OrganizationType
    }
  }
`;

const VERTICAL_TYPE = gql`
  ${ORGANIZATION_TYPE}, ${VERTICAL_TYPE_FIELDS}
  fragment VerticalType on VerticalType {
    ...VerticalTypeFields
    subvertical {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...VerticalTypeFields
        }
      }
      totalCount
      edgeCount
    }
  }
`;


export const EDIT_VERTICAL_VERTICAL_BY_VERTICAL_Id_QUERY = gql`
  ${VERTICAL_TYPE}
  query vertical($id: ID!) {
    verticalBy(id: $id) {
      ...VerticalType
    }
  }
`;