// @ts-nocheck
import React from 'react';
import './side-bar.css'
import { bread_crums, menu_type, navigations } from './navigations'
import { navigationIcons } from "./navigation-icons";
import NavBar from './Nav-bar';
import { Button, Input, Modal, message } from 'antd'
import home_route, { home } from '../home/route/index'
import { getIntFromString, getQuestionBase64StringFromInt, getSurveyBase64StringFromInt } from 'modules/look';
import { compose } from 'modules/core';
import { withApollo } from '@apollo/client/react/hoc';
import { COMPLETE_SOUL_SURVEY } from 'modules/page/graphql/CompletedSurvey.gql';
import { poc_admin } from 'modules/poc-dashboard/routes';
import { dasboardIcons } from 'modules/home/dashboard-icons';
import { PERSONALITY_PART_1, VALUE_SUERVEY_PART_1, STRENGTH_PART_1, KNOWLEDGE_PART_1, diversity_survey_id, diversity_questions_for_in, impact_generation_status } from '../../config';
const SideNavBar = (props) => {

    const { location, setAppSidebarExpand, app_sidebar_expand, navigateRoute, checkSurveyStatus, org_modules, me, match, client } = props
    const [show_child_content, SetShowChildVontent] = React.useState(false)
    const [selected_parent_route, setSelectedParentRoute] = React.useState()
    const [selected_child_route, setSlectedChildRoute] = React.useState()
    const [selected_child_list, setSelectedChildList] = React.useState([])
    const [team_list, setTeamList] = React.useState()
    const [expand_child_list, setExpandChildList] = React.useState(false)
    const [show_mobile_side_bar, ShowMobileSidebar] = React.useState(JSON.parse(localStorage.getItem("app_sidebar_expand"))?.app_sidebar_expand || false)
    const [search_menu, SetSearchMenu] = React.useState()
    const [impact_warning, SetImpactWarning] = React.useState(false)
    const [navigation_warning, SetNavigationWarning] = React.useState(false)

    const side_bar_close_timer = React.useRef()

    React.useEffect(() => {
        if (me?.employee) {
            setTeamList(me?.employee?.teamManagerEmployee?.edges?.map(({ node }) => {
                return {
                    id: node.id,
                    title: node.name
                }
            }))
        }
    }, [me])

    React.useEffect(() => {
        if (app_sidebar_expand) {
            if (side_bar_close_timer.current) {
                clearTimeout(side_bar_close_timer.current)
            }

            SetShowChildVontent(app_sidebar_expand)
        } else {
            if (side_bar_close_timer.current) {
                clearTimeout(side_bar_close_timer.current)
            }
            side_bar_close_timer.current = setTimeout(() => {
                SetShowChildVontent(false)
            }, 999);
        }
    }, [app_sidebar_expand])

    React.useEffect(() => {

        if (search_menu) {
            let child_routes = navigations?.map(i => i?.child)
            child_routes = [].concat.apply([], child_routes)?.filter(i => i)
            let list = child_routes?.filter(i => (!org_modules?.length || org_modules?.includes(i?.module_name)))
            let data = list?.filter(i => i?.title?.toLowerCase()?.includes(search_menu?.toLowerCase()))
            setSelectedChildList([].concat(data))
        } else {
            let list = selected_parent_route?.child?.filter(i => (!org_modules?.length || org_modules?.includes(i?.module_name)))
            setSelectedChildList([].concat(list || []))
        }
    }, [search_menu])

    const addToMenuStorage = (parent_key, child_key) => {
        let menu_store = JSON.parse(localStorage.getItem("menu_store"))
        if (menu_store) {
            menu_store[parent_key] = child_key
        } else {
            menu_store = { [parent_key]: child_key }
        }
        menu_store = localStorage.setItem("menu_store", JSON.stringify(menu_store))
    }

    React.useEffect(() => {
        if (location?.pathname) {
            let list = location?.pathname?.split('/')
            let parent_key = list[1]
            let child_key = list[2]

            let parent_route_data = navigations?.find(i => i?.key == parent_key)
            if (parent_route_data) {
                setSelectedParentRoute(parent_route_data)
                if (parent_route_data?.child?.length) {
                    let list = parent_key === 'home' && team_list?.length === 0 ? parent_route_data?.child?.filter(i => (!org_modules?.length || org_modules?.includes(i?.module_name)))[0] :
                        parent_route_data?.child?.filter(i => (!org_modules?.length || org_modules?.includes(i?.module_name)))
                    if (search_menu) {
                        list = list?.filter(i => i?.title?.toLowerCase()?.includes(search_menu?.toLowerCase()))
                    }
                    setSelectedChildList(list)
                    let child_route_data = parent_route_data?.child?.find(i => i?.key == child_key)
                    if (child_route_data) {
                        addToMenuStorage(parent_route_data?.key, child_route_data?.key)
                        setSlectedChildRoute(child_route_data)
                    }
                } else {
                    setAppSidebarExpand(false)
                    setSelectedChildList([])
                }

            } else {
                setSelectedParentRoute(null)
                let home_route = navigations?.find(i => i?.key == home)
                setSelectedChildList(home_route?.child)
            }

        }
    }, [location?.pathname])

    const selectNavigation = (route, force_navigation = false) => {
        if (!force_navigation && impact_generation_status) {
            let impact_narrative_status = localStorage.getItem(impact_generation_status)
            let data = impact_narrative_status ? JSON.parse(impact_narrative_status) : null
            if (data?.impact_generation_status == "INPROGRESS") {
                let warning = {
                    navigation_type: "parent_type",
                    route: route
                }
                SetNavigationWarning(warning)
                return
            }
        }


        if (route?.is_coming_soon) {
            message.destroy()
            message.warning('Feature coming soon')
        } else {
            if (route?.child?.length) {
                setSelectedChildList(route?.child)
                openSidebar()
                let menu_store = JSON.parse(localStorage.getItem("menu_store"))
                if (menu_store[route.key]) {
                    let child_key = menu_store[route.key]
                    if (child_key == "my-team" && team_list?.length) {
                        navigateRoute(home_route?.my_team?.replace(":id", getIntFromString(team_list[0]?.id)))
                        return
                    }
                    let child_route = route?.child?.find(i => i?.key == child_key)
                    let module_avilable = true
                    if (org_modules?.length) {
                        module_avilable = org_modules?.includes(child_route?.module_name)
                    }
                    if (child_route && module_avilable) {
                        selectChildRoute(child_route, force_navigation)
                        return
                    }
                }
                if (org_modules?.length) {
                    let child_modules = route?.child?.map(i => i?.module_name)
                    let avilable_child_module = child_modules?.find(i => org_modules?.includes(i))
                    if (avilable_child_module) {
                        let child_route = route?.child?.find(i => i?.module_name == avilable_child_module)
                        if (child_route) {
                            selectChildRoute(child_route, force_navigation)
                        }
                    }
                } else {
                    selectChildRoute(route?.child[0], force_navigation)
                }
            } else {
                localStorage.setItem("app_sidebar_expand", JSON.stringify({ app_sidebar_expand: false }))
                setAppSidebarExpand(false)
                if (route?.menu_type == menu_type.survey && route?.check) {
                    checkSurveyStatus({
                        id: route?.survey_id,
                        type: route?.key,
                        is_navigate: true
                    })
                } else if (route?.path) {
                    navigateRoute(route?.path)
                }
            }

        }
    }

    const selectChildRoute = (route, force_navigation = false) => {

        if (!force_navigation && impact_generation_status) {
            let impact_narrative_status = localStorage.getItem(impact_generation_status)
            let data = impact_narrative_status ? JSON.parse(impact_narrative_status) : null
            if (data?.impact_generation_status == "INPROGRESS") {
                let warning = {
                    navigation_type: "child_type",
                    route: route
                }
                SetNavigationWarning(warning)
                return
            }
        }

        if (route?.is_coming_soon) {
            message.destroy()
            message.warning('Feature coming soon')
        }
        else {
            if (route?.key == "impact-narrative") {
                checkForImpactNarrative(route)
                return
            }
            if (route?.menu_type == menu_type.survey && route?.check) {
                checkSurveyStatus({
                    id: route?.survey_id,
                    type: route?.key,
                    is_navigate: true
                })
            } else if (route?.path) {
                navigateRoute(route?.path)
            }
        }

    }

    const checkForImpactNarrative = async (route) => {
        try {
            if (!me) {
                navigateRoute(route?.path)
            }
            const { data } = await client.query({
                query: COMPLETE_SOUL_SURVEY,
                variables: {
                    user: me?.id,
                    value: getSurveyBase64StringFromInt(VALUE_SUERVEY_PART_1),
                    strength: getSurveyBase64StringFromInt(STRENGTH_PART_1),
                    personality: getSurveyBase64StringFromInt(PERSONALITY_PART_1),
                    knowledge: getSurveyBase64StringFromInt(KNOWLEDGE_PART_1),
                    diversity: getSurveyBase64StringFromInt(diversity_survey_id),
                    gender: getQuestionBase64StringFromInt(diversity_questions_for_in?.gender),
                    HighestDegree: getQuestionBase64StringFromInt(diversity_questions_for_in?.HighestDegree),
                    YearsOfExperience: getQuestionBase64StringFromInt(diversity_questions_for_in?.YearsOfExperience),
                    CurrentNationality: getQuestionBase64StringFromInt(diversity_questions_for_in?.CurrentNationality)
                },
                fetchPolicy: 'network-only'
            });

            if (data) {
                let diversity = data?.diversity?.edges[0]?.node
                let diversity_completed = false
                if (diversity && diversity?.CurrentNationality?.totalCount && diversity?.HighestDegree?.totalCount && diversity?.YearsOfExperience?.totalCount && diversity?.gender?.totalCount) {
                    diversity_completed = true
                }
                let detail = {
                    ...data,
                    diversity: {
                        totalCount: diversity_completed ? 1 : 0
                    }
                }

                if (!detail?.Values?.totalCount || !detail?.Strength?.totalCount || !detail?.Personality?.totalCount || !detail?.knowledge?.totalCount || !detail?.diversity?.totalCount) {
                    SetImpactWarning(true)
                } else {
                    navigateRoute(route?.path)
                }


            }
        } catch (error) {
            navigateRoute(route?.path)
        }
    }

    const openSidebar = () => {
        localStorage.setItem("app_sidebar_expand", JSON.stringify({ app_sidebar_expand: true }))
        setAppSidebarExpand(true);
    }

    const setParentChild = () => {
        let list = selected_parent_route?.child?.filter(i => (!org_modules?.length || org_modules?.includes(i?.module_name)))
        setSelectedChildList([].concat(list || []))
    }
    const customPermission = (item) => {
        let { manager_user } = localStorage.getItem("manager_user") ? JSON.parse(localStorage.getItem("manager_user")) : { manager_user: null }
        let value = true
        switch (item?.key) {
            case "hr": {
                if (manager_user?.ceo || manager_user?.poc || me?.employee?.orgCeoEmployee?.totalCount > 0 || me?.employee?.orgPocEmployee?.totalCount > 0) {
                    value = true
                } else {
                    value = false
                }
                break;
            }
            case "my-team": {
                if (manager_user?.team_manager || me?.employee?.teamManagerEmployee?.totalCount > 0) {
                    value = true
                } else {
                    value = false
                }
                break;
            }
            case "scape-report": {
                if (manager_user?.ceo || manager_user?.poc || manager_user?.vertical_head || manager_user?.team_manager || me?.employee?.orgCeoEmployee?.totalCount > 0 || me?.employee?.orgPocEmployee?.totalCount > 0 || me?.employee?.verticalHeadEmployee?.totalCount > 0 || me?.employee?.teamManagerEmployee?.totalCount > 0) {
                    value = true
                } else {
                    value = false
                }
                break;
            }
            case poc_admin: {
                if ((manager_user?.poc || me?.employee?.orgPocEmployee?.totalCount > 0) && (manager_user?.org_user || me?.employee?.organizationSet?.edges?.length) ) {
                    value = true
                } else {
                    value = false
                }
                break;
            }
            default:
                value = true;
        }
        return value
    }
    const matchLocation = (location, route) => {
        if (route?.check_param) {
            return `${location?.pathname}${location.search}`
        }
        else {
            if (route?.type && match?.url?.includes(route?.type)) {
                return match?.path?.replace(":type", route.type)
            }
            return match?.path
        }
    }
    const RenderBreadCrums = () => {
        let data = bread_crums?.find(i => i?.path == matchLocation(location, i))
        return (
            <>
                {data?.crums?.map((i, index) => (
                    <div className='bread-crum-container'>

                        {index === 0 ?
                            <img src={navigationIcons.bread_crum_arrow} alt="" /> : ''}
                        <div className={index === data?.crums?.length - 1 ? 'bread-crum-btn' : 'bread-crum-btn-inactive'}>
                            {i}
                        </div>
                        {data?.crums[index + 1] ?
                            <img src={navigationIcons.bread_crum_arrow} alt="" /> : ''}
                    </div>
                ))}
            </>
        )
    }

    const forceNavigate = (data) => {
        SetNavigationWarning(null)
        if (data?.navigation_type == "child_type") {
            selectChildRoute(data?.route, true)
        } else {
            selectNavigation(data?.route, true)
        }
    }

    return (
        <>

            <Modal
                visible={navigation_warning}
                footer={false}
                closable={false}
                destroyOnClose={true}
                width={"90%"}
                style={{ maxWidth: "550px" }}
            >
                <div style={{ width: "100%", height: "100%" }}>

                    <div className="soul-tip-preview-container">
                        <h4 style={{fontSize:"1.75em",textAlign:"center",fontWeight:"bold"}}>You're Almost There!</h4>
                        <h4 className="preview-tip-popup">Leaving this page will stop generating your Impact Narrative. Are you sure you want to leave this page?</h4>

                        <div >
                            <Button className='navigation-warning-btn' onClick={() => { forceNavigate(navigation_warning) }}>Leave</Button>
                            <Button className='navigation-warning-btn' onClick={() => SetNavigationWarning(null)}>Cancel</Button>
                        </div>
                    </div>

                </div>
            </Modal>

            <Modal
                visible={impact_warning}
                footer={false}
                closable={false}
                destroyOnClose={true}
                centered={true}
                style={{ maxWidth: "45em" }}
                className='idp-modal'
                width={"90%"}
            >

                <div className='rd-confirmation-dialog'>
                    <h4 className='rd-confirmation-message' style={{ fontSize: "1.5em" }}>Please complete all soul surveys and diversity profile survey</h4>
                    <div className='rd-confirmation-action-container'>

                        <Button className='global-submit-btn' onClick={() => { SetImpactWarning(false) }}>CLOSE</Button>
                    </div>
                </div>
            </Modal>

            <div className='main-header-continer'>
                <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'start', alignItems: "center" }}>
                    <div className='side-bar-log-continer' >
                        <a href={`${home_route?.userdashboard}`}>
                            <img className='side-bar-logo' src={navigationIcons?.side_bar_logo} alt="" />
                        </a>
                    </div>
                    {(app_sidebar_expand) ?
                        <div className='search-input-container main-side-bar-container-for-web'>
                            <img src={navigationIcons?.search_icon} alt="" />
                            <Input className='search-input-container-input' onChange={(e) => { SetSearchMenu(e?.target?.value) }} placeholder='Search' />
                        </div> :

                        (!app_sidebar_expand) ?
                            <div className='search-icon-container main-side-bar-container-for-web' onClick={() => { setParentChild(); openSidebar() }}>
                                <img src={navigationIcons?.search_icon} alt="" />
                            </div> : ''
                    }

                    <h4 className='header-page-title'>{(selected_child_route?.alternative_title || selected_child_route?.title) || (selected_parent_route?.alternative_title || selected_parent_route?.title)}</h4>
                    {RenderBreadCrums()}
                </div>
                <NavBar {...props} />
            </div>

            {!show_mobile_side_bar && <img className='sidebar-mobile-expand-icon'
                src={navigationIcons?.expand_icon}
                alt=""
                onClick={() => {
                    setParentChild();
                    ShowMobileSidebar(true);
                    openSidebar()
                }} />}

            {/* For Web Screen */}
            <div className={`main-side-bar-container main-side-bar-container-for-web ${app_sidebar_expand ? 'expand-main-side-bar' : ''}`}>
                <div className='side-bar-container'>
                    <div className='side-bar-scroll-container'>
                        {
                            navigations?.map(item => (
                                <>
                                    {((!org_modules?.length || org_modules?.includes(item?.module_name)) && customPermission(item)) ?
                                        <div className='nav-card-container'>
                                            <img className={item?.key === selected_parent_route?.key ? 'side-nav-selector-active' : 'side-nav-selector'}
                                                src={navigationIcons?.side_nav_selector} alt="" />
                                            <div className='side-nav-icon-card' onClick={() => { selectNavigation(item) }}>
                                                <img src={(selected_parent_route?.key==poc_admin && item.key == poc_admin)?item?.icon_active:item?.icon} alt="" style={{ width: "35px", height: "35px" }} />

                                                <h4 className={`side-nav-ttile ${item?.key === selected_parent_route?.key ? 'side-nav-ttile-active' : ''} ${(selected_parent_route?.key==poc_admin && item.key == poc_admin) ? 'side-nav-ttile-green-active' : ''}`} >{item?.title}</h4>
                                            </div>
                                        </div>
                                        : ''}
                                </>


                            ))
                        }

                    </div>
                    {(!show_child_content && selected_parent_route?.child?.length > 0) ? <img src={navigationIcons?.expand_icon} alt="" className='sidebar-expand-icon' onClick={() => { setParentChild(); openSidebar() }} /> : ''}
                </div>
                {
                    show_child_content && (
                        <div className='expand-side-nav-section'>

                            <div className='child-route-container'>
                                {
                                    selected_child_list?.map((value) => (
                                        <>
                                            {
                                                ((!org_modules?.length || org_modules?.includes(value?.module_name)) && customPermission(value)) ?
                                                    <>
                                                        {!value?.is_expand ? <div className={`child-route-button ${value?.key === selected_child_route?.key ? 'child-route-button-selected' : ''}`} onClick={() => selectChildRoute(value)}>
                                                            <img className='non-active-side-icon' src={value?.icon} alt="icon" />
                                                            <img className='active-side-icon' src={value?.icon_active} alt="icon_active" />
                                                            {value?.title}
                                                        </div> :
                                                            <div className={expand_child_list ? "expand-child-route" : ""} style={{ paddingBottom: expand_child_list ? "1em" : '0em' }}>
                                                                <div style={{ display: 'flex', flexDirection: "column", gap: '.5em', justifyContent: "center" }}>
                                                                    <div style={{ justifyContent: "space-between", padding: "0.5em .5em" }} className={expand_child_list ? "child-route-button-active" : "child-route-button"} onClick={() => setExpandChildList(!expand_child_list)}>
                                                                        <div style={{ display: 'flex', flexDirection: "row", gap: '1em', alignItems: "center" }}>
                                                                            <img className='non-active-side-icon' src={value?.icon} alt="icon" />
                                                                            <img className='active-side-icon' src={value?.icon_active} alt="icon_active" />
                                                                            {value?.title}
                                                                        </div>
                                                                        <img style={{ transform: expand_child_list ? 'rotate(-180deg)' : 'rotate(0deg)' }} src={value?.arrow_icon} alt="arrow_icon" />
                                                                    </div>
                                                                    {expand_child_list && team_list?.length > 0 && team_list?.map(item => (
                                                                        <div style={{ marginLeft: ".5em !important", marginRight: ".5em !important", padding: "0.5em 0.25em 0.5em 0.75em" }} className={`child-route-button ${location?.pathname.includes(getIntFromString(item?.id)) ? 'child-route-button-selected' : ''}`} onClick={() => { navigateRoute(home_route?.my_team.replace(':id', getIntFromString(item?.id))); setAppSidebarExpand(false) }}>{item?.title}</div>
                                                                    ))}
                                                                </div>
                                                            </div>}
                                                    </> : ''
                                            }
                                        </>

                                    ))
                                }
                            </div>

                            <img src={navigationIcons?.expand_icon} alt="" style={{ position: "absolute", right: "0px", top: "21px", cursor: "pointer", rotate: "180deg", width: "24px" }} onClick={() => { setAppSidebarExpand(false) }} />
                        </div>
                    )
                }
            </div>

            {/* For Mobile Screen */}

            {show_mobile_side_bar && <div className={`main-side-bar-container ${app_sidebar_expand ? 'expand-main-side-bar' : ''}`}>
                <div className='side-bar-container'>
                    <div className='side-bar-scroll-container'>
                        {
                            navigations?.map(item => (
                                <>
                                    {((!org_modules?.length || org_modules?.includes(item?.module_name)) && customPermission(item)) ?
                                        <div className='nav-card-container'>
                                            <img className={item?.key === selected_parent_route?.key ? 'side-nav-selector-active' : 'side-nav-selector'}
                                                src={navigationIcons?.side_nav_selector} alt="" />
                                            <div className='side-nav-icon-card' onClick={() => { selectNavigation(item) }}>
                                                <img src={(selected_parent_route?.key==poc_admin && item.key == poc_admin)?item?.icon_active:item?.icon} alt="" style={{ width: "35px", height: "35px" }} />

                                                <h4 className={`side-nav-ttile ${item?.key === selected_parent_route?.key ? 'side-nav-ttile-active' : ''} ${(selected_parent_route?.key==poc_admin && item.key == poc_admin) ? 'side-nav-ttile-green-active' : ''}`} >{item?.title}</h4>
                                            </div>
                                        </div>
                                        : ''}
                                </>


                            ))
                        }

                    </div>
                    {(!show_child_content) ? <img src={navigationIcons?.expand_icon} alt="" className='sidebar-expand-icon' onClick={() => { setParentChild(); openSidebar() }} /> : ''}
                </div>
                {
                    show_child_content && (
                        <div className='expand-side-nav-section'>

                            <div className='child-route-container'>
                                {
                                    selected_child_list?.map((value) => (
                                        <>
                                            {
                                                ((!org_modules?.length || org_modules?.includes(value?.module_name)) && customPermission(value)) ?
                                                    <>
                                                        {!value?.is_expand ? <div className={`child-route-button ${value?.key === selected_child_route?.key ? 'child-route-button-selected' : ''}`} onClick={() => selectChildRoute(value)}>
                                                            <img className='non-active-side-icon' src={value?.icon} alt="icon" />
                                                            <img className='active-side-icon' src={value?.icon_active} alt="icon_active" />
                                                            {value?.title}
                                                        </div> :
                                                            <div className={expand_child_list ? "expand-child-route" : ""} style={{ paddingBottom: expand_child_list ? "1em" : '0em' }}>
                                                                <div style={{ display: 'flex', flexDirection: "column", gap: '.5em', justifyContent: "center" }}>
                                                                    <div style={{ justifyContent: "space-between", width: '92%' }} className={expand_child_list ? "child-route-button-active" : "child-route-button"} onClick={() => setExpandChildList(!expand_child_list)}>
                                                                        <div style={{ display: 'flex', flexDirection: "row", gap: '1em', alignItems: "center" }}>
                                                                            <img className='non-active-side-icon' src={value?.icon} alt="icon" />
                                                                            <img className='active-side-icon' src={value?.icon_active} alt="icon_active" />
                                                                            {value?.title}
                                                                        </div>
                                                                        <img style={{ transform: expand_child_list ? 'rotate(-180deg)' : 'rotate(0deg)' }} src={value?.arrow_icon} alt="arrow_icon" />
                                                                    </div>
                                                                    {expand_child_list && team_list?.length > 0 && team_list?.map(item => (
                                                                        <div style={{ marginLeft: ".5em !important", marginRight: ".5em !important" }} className={`child-route-button ${location?.pathname.includes(getIntFromString(item?.id)) ? 'child-route-button-selected' : ''}`} onClick={() => { navigateRoute(home_route?.my_team.replace(':id', getIntFromString(item?.id))) }}>{item?.title}</div>
                                                                    ))}
                                                                </div>
                                                            </div>}
                                                    </> : ''
                                            }
                                        </>

                                    ))
                                }
                            </div>

                            <img src={navigationIcons?.expand_icon} alt="" style={{ position: "absolute", right: "0px", top: "21px", cursor: "pointer", rotate: "180deg", width: "24px" }} onClick={() => { setAppSidebarExpand(false); ShowMobileSidebar(false) }} />
                        </div>
                    )
                }
            </div>}
        </>
    )
}

export default compose(withApollo)(SideNavBar)
