import React from "react";
import { compose } from "modules/core";
import { COMPETENCY_CATEGORY_LIST } from "../graphql/CompetencyQuries.gql";
import { withCreateCompetencyCategory, withCreateCompetencySkill } from "../RoleDefinitionOperation";
import { getIntFromString } from "modules/look";
import SubCompetencyCreateView from "../components/SubCompetencyCreateView";

const SubCompetencyAddContainer =(props)=>{
    
    const { client,createCompetency,competency_type,onCancel,onSuccess} = props

    const [competency_cat_list,setCompetencyCatList]=React.useState()

    React.useEffect(()=>{
            getCompetencyCategoryList()
    },[competency_type])

    // Get all competency category list
    const getCompetencyCategoryList = async (filter) => {
        const { data } = await client.query({
            query: COMPETENCY_CATEGORY_LIST,
            fetchPolicy: 'network-only',
            variables: {...filter,first:50}
        });
        if(data?.allCompetencyCategory){
            let type = competency_type=='Role_Specific'?"ROLE_SPECIFIC":"GENERIC"
            let list 
            if(competency_type){
                list = data?.allCompetencyCategory?.edges.map(({node})=>node)?.filter(i=>i.competencyType==type)
            }else{
                list = data?.allCompetencyCategory?.edges.map(({node})=>node)
            }
            
            setCompetencyCatList(list)
            // competency_type
        }
    }

    // create sub competency 
    const submitReasponse = async(value)=>{
        let competencyData={
            title:value?.title,
            competencyCategoryId:getIntFromString(value?.competency_category),
            competencyType:competency_type || value?.competency_type,
            description:value?.description
        }
        let response = await createCompetency({competencyData})
        if(onSuccess && response?.id){
            onSuccess({...response,must_have:value?.modal_competency_must_have})
        }
    }

    return (
        <>
            <SubCompetencyCreateView {...props} 
            onSubmit={(value)=>submitReasponse(value)}
            competency_category_list={competency_cat_list}
            getCompetencyCategoryList={(filter)=>{getCompetencyCategoryList(filter)}}
            />
        </>
    )

}

export default compose(withCreateCompetencySkill,withCreateCompetencyCategory)(SubCompetencyAddContainer)