import gql from 'graphql-tag';

export const CREATE_BADGE_AWARD =gql`
    mutation createBadgeAward($badgeAwardData: BadgeAwardInput!){
        createBadgeAward(badgeAwardData:$badgeAwardData){
            badgeAward{
                id
                badge{
                    id
                    image
                    title
                }
            }
        }
    }
`