import gql from 'graphql-tag';
import { COACHING_COMMITMENT_PROGRESS_TYPE } from './CoachingCommitmentProgressType.gql';


export const DELETE_COACHING_COMMITMENT_PROGRESS = gql`
  ${COACHING_COMMITMENT_PROGRESS_TYPE}
  mutation deleteCoachingCommitmentProgress($id: ID!) {
    deleteCoachingCommitmentProgress(id: $id) {
      coachingCommitmentProgress {
        ...CoachingCommitmentProgressType
      }
    }
  }
`;
