import gql from "graphql-tag";


export const COMPLETE_LEARNING_SOUL_SURVEY =gql`
query surveyResponses($user:ID,$value:ID,$strength:ID,$personality:ID,$knowledge:ID,$gender:ID,$HighestDegree:ID,$YearsOfExperience:ID,$CurrentNationality:ID,$diversity:ID){
    Values: surveyResponses(survey: $value, user: $user) {
      totalCount
    }
    Strength: surveyResponses(survey: $strength, user: $user) {
      totalCount
    }
    Personality: surveyResponses(survey: $personality, user: $user) {
      totalCount
    }
    knowledge: surveyResponses(survey: $knowledge, user: $user) {
      totalCount
    }
    diversity:surveyResponses(first: 1, user: $user, survey: $diversity, orderBy: ["-responseDate"]) {
      edges{
        node{
          id
          gender: answerSet(question: $gender){
            totalCount
          }
   
          HighestDegree: answerSet(question: $HighestDegree){
            totalCount
          }

          YearsOfExperience: answerSet(question: $YearsOfExperience){
            totalCount
          }
   
          CurrentNationality: answerSet(question: $CurrentNationality){
            totalCount
          }
        }
      }
    }
    impact_narrative:  allImpactNarrative(user:$user){
        totalCount
    }
}
`

export const LEARNING_COMPLETED_LIST =gql`
query employeeById($id:ID!,$value:ID,$strength:ID,$personality:ID,$knowledge:ID,$gender:ID,$HighestDegree:ID,$YearsOfExperience:ID,$CurrentNationality:ID,$diversity:ID,$usermanual:ID,$whole:ID,$cs3:ID,$whoposhoro:ID){
  employeeById(id:$id){
    id
    user{
      id
      value:responseSet(survey:$value){
        totalCount
      }
      strength:responseSet(survey:$strength){
        totalCount
      }
      personality:responseSet(survey:$personality){
        totalCount
      }
      knowledge:responseSet(survey:$knowledge){
        totalCount
      }
      usermanual:responseSet(survey:$usermanual){
      totalCount
      }
      impactnarrativeSet{
        totalCount
      }
      whole:responseSet(survey:$whole){
        totalCount
      }
      cs3:responseSet(survey:$cs3){
        totalCount
      }
      whoposhoro:responseSet(survey:$whoposhoro){
      totalCount
      }
      diversity:responseSet(first: 1,survey: $diversity){
        edges{
          node{
            id
            gender: answerSet(question: $gender){
              totalCount
            }
    
            HighestDegree: answerSet(question: $HighestDegree){
              totalCount
            }

            YearsOfExperience: answerSet(question: $YearsOfExperience){
              totalCount
            }
    
            CurrentNationality: answerSet(question: $CurrentNationality){
              totalCount
            }
          }
        }
      }
    }
    okrContributorSet(role:"OWNER"){
      totalCount
    }
    readinessGiven{
      totalCount
    }
    idpSet{
      totalCount
    }
    meetingMemberSet(endedAt_Isnull:false){
      totalCount
    }
    issuedBadgeAwardSet{
      totalCount
    }
    feedbackGiven{
      totalCount
    }
  }
}
`

export const SURVEY_BY_ID_VALUES_DISPLAy_QUERY = gql`
  query SurveyById($id: ID!, $responseUser: ID!) {
    surveyAnswers: SurveyById(id: $id) {
      id
      name
      groupSet(last:1) {
        edges {
          node {
            id
            questionSet {
              edges {
                node {
                  id
                  answerSet(response_Survey:$id,response_User: $responseUser,first:1) {
                    edges {
                      node {
                        id
                        answer
                        response {
                          id
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;