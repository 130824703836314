export const role_definition = "role-definition"
const baseName =`/hr/${role_definition}`
const competency ='/competency'
const jobFamily = '/jobFamily'
const role = '/role'
const role_definition_route = {
    role_definition_home: baseName,
    competency_detail_page:baseName+competency+'/:competency_id',
    job_family_detail_page:baseName+ jobFamily+'/:jobFamily_id',
    role_detail_page:baseName + role + '/:role_id',
}

export default role_definition_route