import gql from 'graphql-tag';

export const RESEND_ACTIVATION_EMAIL = gql`
  mutation resendActivationEmail($email: String!) {
    resendActivationEmail(email: $email) {
      success
      errors
    }
  }
`;
