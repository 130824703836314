import React, { useEffect, useState, useRef } from 'react';
import ScapeReportLevel2GraphContainer from '../containers/scape-report-level2-graph-container';
import ScapeReportGraphContainer from '../containers/scape-report-graph-container';
import { SCAPE_REPORT_GRAPH_VIEW } from '../icons';
import { Button } from 'antd';

const ScapeReportGraphMain = (props) => {
    const { level_2_source, level_1_source, graph_level, SetGraphLevel, grahp_data_set, is_personality } = props

    return (
        <div className='scape-report-container'>
            {

                <div style={{ width: "50px" }}>
                    {(grahp_data_set?.selected_preference_choice?.key !== "weight" && !is_personality) && (

                        <>
                            {
                                graph_level != "level_1" ?
                                    <Button className='scape-active-btn' onClick={() => SetGraphLevel('level_1')}>
                                        <img src={SCAPE_REPORT_GRAPH_VIEW.previous_scape} alt="" />
                                    </Button> : <Button className='scape-inactive-btn'>
                                        <img src={SCAPE_REPORT_GRAPH_VIEW.prev_scape_dis} alt="" />
                                    </Button>
                            }
                        </>
                    )}
                </div>

            }



            {
                (graph_level === 'level_1' && level_1_source) && (
                    <ScapeReportGraphContainer data={level_1_source} graph_type={grahp_data_set?.selected_preference_choice?.key} {...props} />
                )
            }
            {
                (graph_level === 'level_2' && level_2_source) && (
                    <ScapeReportLevel2GraphContainer data={level_2_source} graph_type={grahp_data_set?.selected_preference_choice?.key} {...props} />
                )
            }

            {

                <div style={{ width: "50px" }}>
                    {(grahp_data_set?.selected_preference_choice?.key !== "weight" && !is_personality) && (
                        <>
                            {
                                graph_level != "level_2" ?
                                    <Button className='scape-active-btn' onClick={() => SetGraphLevel('level_2')}>
                                        <img src={SCAPE_REPORT_GRAPH_VIEW.next_scape} alt="" />
                                    </Button>
                                    :
                                    <Button className='scape-inactive-btn'>
                                        <img src={SCAPE_REPORT_GRAPH_VIEW.next_scape_dis} alt="" />
                                    </Button>
                            }
                        </>
                    )}
                </div>

            }



        </div>
    );

}
export default ScapeReportGraphMain