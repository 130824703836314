import gql from 'graphql-tag';

export const ORGANIZATION_VERTICAL_DATA =gql`
query me($okr_Duedate_Range: [DateTime],$offset:Int,$first: Int){
    OrganizationVerticalData: me {
        id
        employee {
          id
          organizationSet {
            edges {
              node {
                id
                verticalSet(offset:$offset,first:$first) {
                    totalCount
                    edgeCount
                  edges {
                    node {
                      id
                      name
                      heads {
                        edges {
                          node {
                            id
                            okrContributorSet(savedOkrType: "OBJECTIVE", okr_Duedate_Range:$okr_Duedate_Range,orderBy: ["okr__duedate"]) {
                              edges {
                                node {
                                  id
                                  progress
                                  employee{
                                    id
                                  }
                                  createdBy{
                                    id
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
}
`