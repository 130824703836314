import { Button, Modal } from "antd";
import React from "react";
import { getIntFromString } from "modules/look";


const CancelInvitationView =(props)=>{
    const {me,cancel_invitation,onCancel,changeStatus}=props
    return (
        <>
            <Modal
            visible={cancel_invitation}
            centered
            footer={false}
            destroyOnClose={true}
            closable={false}
            style={{ maxWidth:"40em" }}
            width={"90%"}>
                <div style={{display:"flex",flexDirection:'column',gap:'1em',padding:'1em',justifyContent:'center'}}>
                    {cancel_invitation?.active_status==='Active'?<h4 className="poc-dashboard-card-title" style={{fontSize:"1.5em",fontWeight:500,whiteSpace:"normal",textAlign:'center'}}>{cancel_invitation?.email} will no longer be able to login to this account. Are you sure you want to continue?</h4>:
                    <h4 className="poc-dashboard-card-title" style={{fontSize:"1.5em",fontWeight:500,whiteSpace:"normal",textAlign:'center'}}> Are you sure you want to ativivate this account {cancel_invitation?.email} ?</h4>}
                    <div style={{ width: "100%", display: 'flex', gap: "1em", justifyContent: 'center', alignItems: "center",marginTop:'1em'}}>
                        <Button className="organization-cancel-btn" onClick={()=>onCancel(false)}>Cancel</Button>
                        {cancel_invitation?.active_status==='Active'?<Button className="organization-delete-btn" onClick={()=>changeStatus(false)}>Deactivate</Button>:
                        <Button className="organization-save-btn" onClick={()=>changeStatus(true)}>Activate</Button>}
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default CancelInvitationView;