import React from 'react';

import { Link } from 'react-router-dom';
import arrayMove from 'array-move';
import { RightCircleOutlined, MenuOutlined, DownCircleOutlined } from '@ant-design/icons';
import { Button, Row, Col, Table } from 'antd';
import { compose } from '../../core';
import styled from 'styled-components';
import {
  AdminLayout,
  displayDataCheck,
  displayBoolean,
  getIntFromString,
  GetColumnSearchProps,
  getOrderBy,
  EditIcon
} from '../../look';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { withEditChoice } from './SurveyOperations';
import { ReactMarkdown } from '../../look';

const ChoiceTable = props => {
  const { record, index, expanded, indent, editChoice } = props;
  const { choiceSet } = record;
  const SortableItem = sortableElement(props => <tr  style={{border:"1px solid #f5f5f5",borderRadius:'5px',width:"100%"}} {...props} />);
  const SortableContainer = sortableContainer(props => <tbody {...props} />);
  const [dataSource, setDataSource] = React.useState([]);
  React.useEffect(() => {
    const edges = [];
    choiceSet &&
      choiceSet.edges.length > 0 &&
      choiceSet.edges.map(({ node }, index) => {
        edges.push({ ...node, index: index + 1 });
      });
    setDataSource(edges);
  }, [choiceSet]);
  const onSort = async sortedItems => {
    try {
      await Promise.all(
        sortedItems.map(async i => {
          // const response = await
          editChoice({ id: getIntFromString(i.id), sequence: i.index + 1 });
        })
      );
    } catch (e) {
      throw Error(e.message);
    }
  };
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMove([].concat(dataSource), oldIndex, newIndex).filter(el => !!el);
      const indexData = [];
      newData.map((node, index) => {
        const data = newData[index];
        indexData.push({ ...data, index: index });
      });
      onSort && onSort(indexData);
      setDataSource(indexData);
    }
  };
  const DragHandle = sortableHandle(() => (
    <MenuOutlined style={{ cursor: 'grab', color: '#999', margin: '-16px', padding: '16px' }} />
  ));
  const DraggableContainer = props => (
    <SortableContainer useDragHandle disableAutoscroll helperClass="row-dragging" onSortEnd={onSortEnd} {...props} />
  );
  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = dataSource && dataSource.findIndex(x => x.index === restProps['data-row-key']);
    return <SortableItem  style={{border:"1px solid #f5f5f5",borderRadius:'5px',width:"100%"}} index={index} {...restProps} />;
  };
  const columns = [
    {
      title: 'Sort',
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => <DragHandle />
    },
    {
      title: 'Name',
      key: 'name',
      // className: 'drag-visible',
      render: (text, record) => record && <ReactMarkdown style={{marginTop:'1em'}}>{record.label}</ReactMarkdown>
    },
    {
      title: 'help',
      key: 'help',
      render: (text, record) => record && <>{record.inputType}</>
    },
    {
      title: 'Link',
      key: 'link',
      align: 'center',
      render: (text, record) => (
        <Row gutter={[24, 24]} justify="end">
          <Col>
            {/* <EditIcon
              type="link"
              size="sm"
              target="_blank"
            /> */}
          </Col>
        </Row>
      )
    }
  ];
  return (
    <Table
      className="expand"
      showHeader={false}
      tableLayout={'auto'}
      rowKey={'index'}
      // expandable={{
      //   expandedRowRender: (record, index, indent, expanded) => (
      //     <ExpandedRowRenderTopic subjectId={id} record={record} index={index} indent={indent} expanded={expanded} />
      //   )
      //   // expandIcon: ({ expanded, onExpand, record }) =>
      //   //   expanded ? (
      //   //     <Icon type="DownOutlined" onClick={(e) => onExpand(record, e)} />
      //   //   ) : (
      //   //     !record.isLeaf && <Icon type="RightOutlined" onClick={(e) => onExpand(record, e)} />
      //   //   ),
      // }}
      pagination={false}
      columns={columns}
      dataSource={
        dataSource &&
        dataSource.length > 0 &&
        dataSource.sort((a, b) => (a.sequence < b.sequence ? -1 : a.sequence > b.sequence ? 1 : 0))
      }
      components={{
        body: {
          wrapper: DraggableContainer,
          row: DraggableBodyRow
        }
      }}
    />
  );
};
export default compose(withEditChoice)(ChoiceTable);
