import React from 'react';
import readiness_close_icon from '../../assets/readiness-close-icon.svg'
const VideoPreview = (props) => {
    const { onClose, videoUrl } = props
    return (
        <div style={{ width: "100%" }}>
            <div style={{ display: "flex",flexDirection:"row", justifyContent:"space-between",alignItems:"center", width: '100%' }}>
                <div></div>
                <img src={readiness_close_icon} alt="readiness_close_icon" onClick={() => onClose()} style={{ cursor: "pointer" }} />
            </div>
            <div className='video-preview-container'>
                <video autoPlay={true} controls={true} controlsList="nodownload" poster={videoUrl} className='preview-video'>
                    <source src={videoUrl} type="video/mp4" style={{width:"100%"}}/>
                </video>
            </div>
        </div>
    )
}
export default VideoPreview
