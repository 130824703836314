import gql from 'graphql-tag';

export const UPDATE_BADGE_FAMILY =gql`
    mutation updateBadgeFamily($id: ID!,$description: String,$title: String){
        updateBadgeFamily(id: $id,description:$description,title:$title){
            badgeFamily{
              id
            }
        }
    }
`