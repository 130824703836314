import gql from 'graphql-tag';
import { FAQ_CATEGORY_TYPE } from './FaqCategoryType.gql';

export const DELETE_FAQ_CATEGORY = gql`
  ${FAQ_CATEGORY_TYPE}
  mutation deleteFaqCategory($categoryId: ID!) {
    deleteFaqCategory(categoryId: $categoryId) {
      category {
        ...FaqCategoryType
      }
    }
  }
`;
