import React from 'react';
import { Form, Row, Col, DatePicker, TimePicker } from 'antd';
import moment from 'moment-timezone';
import { getMomentUserTimezoneWithFormat, getMomentUtcToUserTimezone } from '../functions';

const FormItem = Form.Item;

const DatePickerField = props => {
  const { margin, type, offset = 1, enabledisabledDate,dropdownClassName, ...rest } = props;
  const [val,setval] = React.useState(undefined)

React.useEffect(() => {
  if (props?.value){ 
  setval(getMomentUtcToUserTimezone(props.value))
  }
}, [props.value])  

  let today=moment().subtract(18, 'year');
  function disabledDate(current) {
    let customDate = new Date();
    return !enabledisabledDate ? false : current && current < moment(customDate, 'YYYY-MM-DD');
  }

  function handleChangeToUtc(value) {
    //Vals to be submitted to backend must be in utc
    if (typeof (props.onChange) === 'function') {
      setval(value)
      props.onChange(value ? moment.utc(value) : value)
    }
  }

  const tzAwareTimeFormat = () => {
    if (!val) {
      return ""
    }
    if (props.format !== undefined) {
      return `${getMomentUserTimezoneWithFormat(val, props.format)}`;
    }
    return `${getMomentUserTimezoneWithFormat(val, "HH:mm")}`;
  }


  return (
    <div style={{ marginBottom: margin ? '5vh' : '' }}>
      <Row>
        <Col span={margin ? 14 : 24}>
          <FormItem labelCol={{ span: 24 }} required={props?.required} wrapperCol={{ span: 24, offset }} {...rest}>
            {type === 'time' ? (
              <TimePicker style={{ width: '100%', height: '36px' }} {...props} onChange={handleChangeToUtc} format={tzAwareTimeFormat} />
            ) : (
              <DatePicker
                dropdownClassName={dropdownClassName||''}
                style={{ width: '100%', height: '36px' }}
                disabledDate={props?.name==='dob'?d => !d || d.isAfter(today):disabledDate}
                {...props}
                onChange={handleChangeToUtc}
              />
            )}
          </FormItem>
        </Col>
      </Row>
    </div>
  );
};

export default DatePickerField;
