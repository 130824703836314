import gql from 'graphql-tag';
import { FAQ_CATEGORY_TYPE } from './FaqCategoryType.gql';

export const FAQ_CATEGORY_TYPE_CONNECTION = gql`
  ${FAQ_CATEGORY_TYPE}
  fragment FaqCategoryTypeConnection on FAQCategoryTypeConnection {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...FaqCategoryType
      }
    }
    totalCount
    edgeCount
  }
`;
