import { graphql } from "@apollo/client/react/hoc";

import { withOperation } from "../../look";
import { removeTypename } from "../../core";

// Query
import { TEAMS_MApp_ANCHORS } from "../graphql/TeamsMAppAnchors.gql";
import { EMPLOYEE_MApp_ANCHORS } from "../graphql/EmployeeMAppAnchors.gql";
import { GET_ALL_CUSTOM_USER_COMMITMENTS_FOR_REPORTING_EMPLOYEE } from "../graphql/GetAllCustomUserCommitmentsForReportingEmployee.gql";
import { GET_ALL_DEFAULT_COMMITMENTS_PROGRESS_FOR_REPORTING_EMPLOYEE } from "../graphql/GetAllDefaultCommitmentsProgressForReportingEmployee.gql";
import { GET_MY_COACH_COMMITMENTS_PROGRESS } from "../graphql/GetMyCoachCommitmentsProgress.gql";

// Mutation
import { DELETE_COACHING_COMMITMENT_PROGRESS } from "../../dashboard/graphql/DeleteCoachingCommitmentProgress.gql";
import { ADD_COACHING_COMMITMENT_PROGRESS } from "../../dashboard/graphql/AddCoachingCommitmentProgress.gql";
import { EDIT_COACHING_COMMITMENT_PROGRESS } from "../../dashboard/graphql/EditCoachingCommitmentProgress.gql";

// State
import { REPORTING_TEAMS_MApp_ANCHORS_STATE_QUERY } from "../graphql/ReportingTeamsMAppAnchorsStateQuery.client.gql";
import { UPDATE_REPORTING_TEAMS_MApp_ANCHORS_FILTER } from "../graphql/UpdateReportingTeamsMAppAnchorsFilter.client.gql";
import { GET_MY_CUSTOMIZED_COACHING_COMMITMENTS_QUERY } from "../graphql/GetMyCustomizedCoachingCommitments.gql";
import { ADD_CUSTOMIZED_COACHING_COMMITMENT_MUTATION } from "../graphql/AddCustomizedCoachingCommitment.gql";
import { GET_TEAM_MANAGER } from "../graphql/GetTeamManager.gql";
import { message } from "antd";

// Query
export const withReportingTeamsMAppAnchorsState = (Component) =>
  graphql(REPORTING_TEAMS_MApp_ANCHORS_STATE_QUERY, {
    props({ data }) {
      const { ...rest } = data.reportingTeamsMAppAnchorsState;
      const reportingTeamsMAppAnchorsState = {
        ...removeTypename(rest),
      };
      return { ...reportingTeamsMAppAnchorsState, stateLoading: data.loading };
    },
  })(Component);

export const withGetMyCoachingCommitmentsProgress = (Component) =>
  graphql(GET_MY_COACH_COMMITMENTS_PROGRESS, {
    options: ({ filter, orderBy, pagination, forUser }) => {
      return { variables: { ...pagination, ...filter, orderBy, forUser } };
    },
    props({ data }) {
      const {
        loading,
        error,
        getMyCoachingCommitmentsProgress,
        subscribeToMore,
        updateQuery,
      } = data;
      return {
        loading,
        error,
        getMyCoachingCommitmentsProgress,
        subscribeToMore,
        updateQuery,
      };
    },
  })(Component);


export const withGetTeamManager = (Component) =>
  graphql(GET_TEAM_MANAGER, {
    options: ({ filter, orderBy, pagination, forUser }) => {
      return { variables: { ...pagination, ...filter, orderBy, forUser } };
    },
    props({ data }) {
      const {
        loading,
        error,
        getTeamManager,
        subscribeToMore,
        updateQuery,
      } = data;
      return {
        loading,
        error,
        getTeamManager,
        subscribeToMore,
        updateQuery,
      };
    },
  })(Component);

export const withGetAllCustomUserCommitmentsForReportingEmployee = (
  Component
) =>
  graphql(GET_ALL_CUSTOM_USER_COMMITMENTS_FOR_REPORTING_EMPLOYEE, {
    props({ data }) {
      const {
        loading,
        error,
        getAllCustomUserCommitmentsForReportingEmployee,
        subscribeToMore,
        updateQuery,
      } = data;
      return {
        loading,
        error,
        getAllCustomUserCommitmentsForReportingEmployee,
        subscribeToMore,
        updateQuery,
      };
    },
  })(Component);

export const withGetAllDefaultCommitmentsProgressForReportingEmployee = (
  Component
) =>
  graphql(GET_ALL_DEFAULT_COMMITMENTS_PROGRESS_FOR_REPORTING_EMPLOYEE, {
    props({ data }) {
      const {
        loading,
        error,
        getAllDefaultCommitmentsProgressForReportingEmployee,
        subscribeToMore,
        updateQuery,
      } = data;
      return {
        loading,
        error,
        getAllDefaultCommitmentsProgressForReportingEmployee,
        subscribeToMore,
        updateQuery,
      };
    },
  })(Component);

export const withTeamsMAppAnchors = (Component) =>
  graphql(TEAMS_MApp_ANCHORS, {
    options: ({ filter }) => {
      return {
        variables: { ...filter },
      };
    },
    props({ data }) {
      const {
        loading,
        error,
        reportingTeamsMAppAnchors,
        subscribeToMore,
        updateQuery,
      } = data;
      return {
        loading,
        error,
        reportingTeamsMAppAnchors,
        subscribeToMore,
        updateQuery,
      };
    },
  })(Component);

export const withEmployeeMAppAnchors = (Component) =>
  graphql(EMPLOYEE_MApp_ANCHORS, {
    options: ({ employeeId }) => {
      return {
        variables: { employeeId },
      };
    },
    props({ data }) {
      const {
        loading,
        error,
        reportingEmployeesMAppAnchors,
        subscribeToMore,
        updateQuery,
      } = data;
      return {
        loading,
        error,
        reportingEmployeesMAppAnchors,
        subscribeToMore,
        updateQuery,
      };
    },
  })(Component);


  export const withDeleteCoachingCommitmentProgress = Component =>
  graphql(DELETE_COACHING_COMMITMENT_PROGRESS, {
    props: ({ mutate, history }) => ({
      deleteCoachingCommitmentProgress: async values => {
        try {
          const {
            data: { deleteCoachingCommitmentProgress }
          } = await mutate({
            variables: {
              ...values
            }
          });
  
          message.destroy();
          message.success('Add Default tool Commitment Successfully');
          return deleteCoachingCommitmentProgress?.coachingCommitmentProgress;
        } catch (e) {
          message.destroy();
          message.error("Failed to Add Default tool Commitment");
          console.error(e);
        }
      }
    })
  })(Component);


  export const withAddCoachingCommitmentProgress  = Component =>
  graphql(ADD_COACHING_COMMITMENT_PROGRESS, {
    props: ({ mutate, history }) => ({
      createCoachingCommitmentProgress: async values => {
        try {
          const {
            data: { createCoachingCommitmentProgress }
          } = await mutate({
            variables: {
              ...values
            }
          });
  
          message.destroy();
          message.success('Add Coaching Commitmet Progress Successfully');
          return createCoachingCommitmentProgress?.coachingCommitmentProgress;
        } catch (e) {
          message.destroy();
          message.error("Failed to Add Coaching Commitmet Progress");
          console.error(e);
        }
      }
    })
  })(Component);


  export const withEditCoachingCommitmentProgress  = Component =>
  graphql(EDIT_COACHING_COMMITMENT_PROGRESS, {
    props: ({ mutate, history }) => ({
      updateCoachingCommitmentProgress: async values => {
        try {
          const {
            data: { updateCoachingCommitmentProgress }
          } = await mutate({
            variables: {
              ...values
            }
          });
  
          message.destroy();
          message.success('Edit Coaching Commitmet Progress Successfully');
          return updateCoachingCommitmentProgress?.coachingCommitmentProgress;
        } catch (e) {
          message.destroy();
          message.error("Failed to Edit Coaching Commitmet Progress");
          console.error(e);
        }
      }
    })
  })(Component);


export const withReportingTeamsMAppAnchorsFilterUpdating = (Component) =>
  graphql(UPDATE_REPORTING_TEAMS_MApp_ANCHORS_FILTER, {
    props: ({ mutate }) => ({
      onIncludingManagerChange(includingManager) {
        mutate({ variables: { filter: { includingManager } } });
      },
    }),
  })(Component);

export const withMyCustomizedCoachingCommitments = (Component) =>
  graphql(GET_MY_CUSTOMIZED_COACHING_COMMITMENTS_QUERY, {
    options: ({ filter, orderBy, pagination }) => {
      return { variables: { ...pagination, ...filter, orderBy } };
    },
    props({ data }) {
      const {
        loading,
        error,
        getMyCustomizedCoachingCommitments,
        subscribeToMore,
        updateQuery,
      } = data;
      return {
        loading,
        error,
        getMyCustomizedCoachingCommitments,
        subscribeToMore,
        updateQuery,
      };
    },
  })(Component);

  
  export const withAddCustomizedCoachingCommitment = Component =>
  graphql(ADD_CUSTOMIZED_COACHING_COMMITMENT_MUTATION, {
    props: ({ mutate, history }) => ({
      createCustomizedCoachingCommitment: async values => {
        try {
          const {
            data: {  createCustomizedCoachingCommitment }
          } = await mutate({
            variables: {
              ...values
            }
          });
  
          message.destroy();
          message.success('Add Customized Coaching Commitmet Successfully');
          return  createCustomizedCoachingCommitment?.coachingCommitment;
        } catch (e) {
          message.destroy();
          message.error("Failed to Add Customized Coaching Commitmet");
          console.error(e);
        }
      }
    })
  })(Component);
