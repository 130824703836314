import React from 'react';
import { Link } from 'react-router-dom';

import { compose } from '../../core';
import { AdminLayout, displayDataCheck, GetColumnSearchProps, getIntFromString, getOrderBy } from '../../look';
// import SURVEY_ROUTE from '../../survey/route';

import ROUTE from '../route';
import { withTeams, withDeleteTeam, withOrganizationFilterUpdating, withOrganizationState } from './OrganizationOperation';
import { subscribeToTeam } from './OrganizationSubscriptions';
import ReactMarkdown from '../../look/components/ReactMarkdown';

export const teamColumns = (onOrderByChange, orderBy, searchFunc) => [
  {
    title: 'Name',
    key: 'name',
    sorter: () => onOrderByChange(getOrderBy('name', orderBy)),
    ...GetColumnSearchProps('user', searchFunc.onNameChange),
    render: (text, record) =>
      record && (
        // <Link to={`${SURVEY_ROUTE.teaSurveyLink}${getIntFromString(record.id)}`}>{displayDataCheck(record.name)}</Link>
        <Link to={`${ROUTE.teamDetailLink}${getIntFromString(record.id)}`}>{displayDataCheck(record.name)}</Link>
      )
  },
  {
    title: 'Description',
    key: 'description',
    sorter: () => onOrderByChange(getOrderBy('description', orderBy)),
    ...GetColumnSearchProps('description', searchFunc.onDescriptionChange),
    render: (text, record) => record && <ReactMarkdown>{record.description}</ReactMarkdown>
  },
  {
    title: 'Organization',
    key: 'organization',
    // ...GetColumnSearchProps('organization', searchFunc.onOrganizationChange, 'organization'),
    render: (text, record) => record && displayDataCheck(record.vertical?.organization.name)
  },
  {
    title: 'Vertical',
    key: 'vertical',
    ...GetColumnSearchProps('subvertical', searchFunc.onVerticalChange, 'subvertical'),
    render: (text, record) => record && displayDataCheck(record.vertical?.name)
  },
  {
    title: 'Manager',
    key: 'manager',
    render: (text, record) => record && displayDataCheck(`${record.manager.user.firstName} ${record.manager.user.lastName}`)
  }
];

const Team = props => {
  const {
    loading,
    Teams,
    onPaginationChange,
    deleteTeam,
    onOrderByChange,
    orderBy,
    onNameChange,
    onDescriptionChange,
    onFiltersRemove,
    onOrganizationChange,
    onVerticalChange,
    subscribeToMore
  } = props;

  React.useEffect(() => {
    const subscribe = subscribeToTeam(subscribeToMore /* , props.filter */);
    return () => subscribe();
  });

  return (
    <AdminLayout
      table={true}
      active={ROUTE.team}
      title="Team"
      loading={loading}
      tableData={Teams}
      columns={teamColumns(onOrderByChange, orderBy, {
        onNameChange,
        onDescriptionChange,
        onOrganizationChange,
        onVerticalChange
      })}
      onDelete={deleteTeam}
      editLink={ROUTE.editTeamLink}
      // addLink={ROUTE.addTeam}
      onPaginationChange={onPaginationChange}
      onFiltersRemove={onFiltersRemove}
    />
  );
};

export default compose(withOrganizationState, withTeams, withDeleteTeam, withOrganizationFilterUpdating)(Team);
