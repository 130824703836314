


import CreateScapeContainer from './containers/createScapeContainer';
import scapeDashboardContainer from './containers/scapeDashboardContainer';
import ROUTE from './route';

export default [
    {
        name: 'scapeReportAdmin',
        path: ROUTE.scapeReportAdminHome,
        component: scapeDashboardContainer,
        protect: true,
        exact:true
    },
    {
        name: 'createScape',
        path: ROUTE.createScape,
        component: CreateScapeContainer,
        protect: true,
        exact:true
    },
    {
        name: 'editScape',
        path: ROUTE.editScape,
        component: CreateScapeContainer,
        protect: true,
        exact:true
    }
]