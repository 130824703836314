import gql from 'graphql-tag';
import { USER_NODE } from './UserNode.gql';

export const OBTAIN_JSON_WEB_TOKEN = gql`
  ${USER_NODE}
  fragment ObtainJSONWebToken on ObtainJSONWebToken {
    success
    errors
    user {
      ...UserNode
    }
    unarchiving
    token
    refreshToken
  }
`;
