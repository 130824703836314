import React from "react";
import TeamsScpaeReportView from "../components/teamScapeReportView";
import { VALUE_SUERVEY_PART_1, PERSONALITY_PART_1, STRENGTH_PART_1 } from '../../../../config';
import { getIntFromString, getSurveyBase64StringFromInt } from "modules/look";
import { compose } from "modules/core";
import { DASHBOARD_SCAPE_LIST, DASHBOARD_SCAPE_REPORT, NEW_DASHBOARD_SCAPE_BY_ID } from "modules/home/graphql/teamDashboardQueries.gql";
import { log } from "@craco/craco/lib/logger";

const TeamsScapeReportContainer = ( props ) => {

    const { me, team_employees, client,permission } = props
    const [ scape_list, setScapeList ] = React.useState( [] )
    const [ scape_data, setScapeData ] = React.useState()
    const [parameters_list,setParametersList] = React.useState()
    const [scape_report_list,setScapeReportList] = React.useState()
    const [scape_loading,setLoading]=React.useState(false)
    React.useEffect( () => {
        if ( permission?.scape_report_permission ) {
            setLoading(true)
            ScapeList()
        }
    }, [ permission ] )


    const ScapeList = async () => {
        const { data } = await client.query( {
            query: DASHBOARD_SCAPE_LIST,
            fetchPolicy: 'network-only'
        } );
        if ( data?.scapes ) {
            setScapeList( data?.scapes?.edges?.map( ( { node } ) => node ) )
        }
    }

    React.useEffect( () => {
        if ( scape_list?.length > 0 && team_employees?.length > 0 ) {
            getTeamsScapeReportContainer( scape_list, team_employees?.map( item => getIntFromString( item?.id ) ) )
        }
        else{
            setLoading(false)
        }
    }, [ scape_list, team_employees ] )

    
    const getTeamsScapeReportContainer = async ( scape_list, ids ) => {
        repoerQuery( {
                value_id: getSurveyBase64StringFromInt( VALUE_SUERVEY_PART_1 ),
                strength_id: getSurveyBase64StringFromInt( STRENGTH_PART_1 ),
                personality_id:getSurveyBase64StringFromInt( PERSONALITY_PART_1 ),
                id_In: ids.toString()
        })
        getParametterList(scape_list)
    }
    const getParametterList =async(scape_list)=>{
        let scape_reports={
            valueScape:scape_list?.find(item=>item?.survey?.id===getSurveyBase64StringFromInt( VALUE_SUERVEY_PART_1 ))?.id?await scapeById({id:scape_list?.find(item=>item?.survey?.id===getSurveyBase64StringFromInt( VALUE_SUERVEY_PART_1 ))?.id}):null,
            strengthScape:scape_list?.find(item=>item?.survey?.id===getSurveyBase64StringFromInt( STRENGTH_PART_1 ))?.id?await scapeById({id:scape_list?.find(item=>item?.survey?.id===getSurveyBase64StringFromInt( STRENGTH_PART_1 ))?.id}):null,
            personalityScape:scape_list?.find(item=>item?.survey?.id===getSurveyBase64StringFromInt( PERSONALITY_PART_1 ))?.id?await scapeById({id:scape_list?.find(item=>item?.survey?.id===getSurveyBase64StringFromInt( PERSONALITY_PART_1 ))?.id}):null
        }
        let scape_report_list = Object.keys(scape_reports).reduce((acc, key) => {
            if (scape_reports[key] !== null) {
                acc[key] = scape_reports[key];
            }
            return acc;
        }, {});
        if(scape_report_list){
            setParametersList(scape_report_list)
        }
        
    }
    const scapeById = async(filter)=>{
        try{
            const { data } = await client.query( {
                query: NEW_DASHBOARD_SCAPE_BY_ID,
                fetchPolicy: 'network-only',
                variables: {...filter}
            } );
            if(data){
                return data?.scapeById
            }
        }
        catch(e){

        }
    }
    const repoerQuery = async ( filter ) => {
        const { data } = await client.query( {
            query: DASHBOARD_SCAPE_REPORT,
            fetchPolicy: 'network-only',
            variables: { ...filter }
        } );
        if ( data) {
            setScapeData(data)
        }

    }

    React.useEffect(()=>{
        if(scape_data!==undefined&&parameters_list!==undefined){
            let report_list ={
                values:parameters_list?.valueScape?getReportList(parameters_list?.valueScape,scape_data?.valueReport):[],
                strength:parameters_list?.strengthScape?getReportList(parameters_list?.strengthScape,scape_data?.struengthsReport):[],
                personality:parameters_list?.personalityScape?getReportList(parameters_list?.personalityScape,scape_data?.personalityReport):[]
            }
            setScapeReportList(report_list)
            setLoading(false)
        }
    },[scape_data,parameters_list])

    const getReportList=(parameters,scape_data)=>{
        let parameters_list =[].concat(...parameters?.parametricgroupSet?.edges?.map(({node})=>node?.parameterSet?.edges?.map((item)=>item?.node?.choice?.value)))
        let tableData = scape_data?.employee?.organizationSet?.edges[ 0 ]?.node?.employees?.edges.map( ( { node } ) => node )
        let value = [].concat( ...tableData?.map( item => item?.user?.responseSet?.edges?.map( ( { node } ) => node ) ) )
        let sequence_list = ( [].concat( ...value.map( item => item?.answerSet?.edges?.map( ( { node } ) => node ) ) ) )
        const countMap = new Map();
            sequence_list.forEach(obj => {
                const key = obj.answer;
                countMap[key] = (countMap[key] || 0) + 1;
              });
              const resultArray = Object.keys(countMap).map(answer => ({
                answer,
                count: countMap[answer],
              }));
              resultArray.sort((a, b) => b.count - a.count);
            let final_list = parameters_list?.map((item)=>{
                return{
                    title:item,
                    count:resultArray?.find(value=>value?.answer===item)?.count==undefined?0:resultArray?.find(value=>value?.answer===item)?.count
                }
            }).sort((a, b) => b.count - a.count)
            return final_list?.slice(0, 5);
    }
    return (
        <>
            {permission?.scape_report_permission&&<TeamsScpaeReportView scape_report_list={scape_report_list} scape_loading={scape_loading} {...props} />}
        </>
    )
}

export default compose()( TeamsScapeReportContainer )