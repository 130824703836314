import React from 'react';

import { AdminLayout } from '../../look';
import ROUTE from '../route';

import QuestionFormComponent from './QuestionFormComponent';

const AddQuestionView = props => {
  return (
    <AdminLayout
      table={false}
      active={ROUTE.question}
      title="Add Question"
      FormComponent={<QuestionFormComponent {...props} />}
    />
  );
};

export default AddQuestionView;
