import React from 'react';
import _ from 'lodash';
import { Spin } from 'antd';
import { compose } from '../../core';
import { useHistory } from 'react-router-dom';
import AddVerticalView from '../components/addVerticalView';
import ROUTE from '../route';
import { getIntFromString, ButtonsCatch, withMarkdownFieldValue } from '../../look';

import { withAddVerticalForOrg, withOrganizationState } from '../../organization/containers/OrganizationOperation';
import { CKEditorFieldName } from '../components/verticalFormComponent';

const AddVertical = props => {
    const { createVertical, setVisible, orgId } = props;
    const [submitLoading, setSubmitLoading] = React.useState(false);
    const history = useHistory();

    const handleSubmit = async (values, item) => {
        setSubmitLoading(true);
        const heads = _.get(values, 'heads');
        const input = _.omit(values, ['heads', 'employees']);
        // const employees = (({ employees }) => ({ employees }))(values);

        try {
            const response = await createVertical({
                employees: values.employees,
                heads,
                verticalData: { ...withMarkdownFieldValue(CKEditorFieldName, { ...input, organizationId: orgId }) }
            });
            response && setVisible(false);
            response && window.location.href.includes('closeonsave') && window.close();
            response &&
                ButtonsCatch(
                    item,
                    window.location.pathname,
                    history,
                    item === undefined
                        ? `${ROUTE.editVerticalLink}${getIntFromString(response.id)}`
                        : `${window.location.pathname}?args=openmodal`
                );
        } catch (e) {
            throw new Error(e.message);
        }
        setSubmitLoading(false);
    };

    return (
        <Spin spinning={submitLoading} size="large">
            <AddVerticalView onSubmit={handleSubmit} {...props} />
        </Spin>
    );
};

export default compose(withOrganizationState, withAddVerticalForOrg)(AddVertical);
