import gql from "graphql-tag";

export const VERTICAL_ALL_CASCADING_OBJECTIVES = gql`
query contributors($okr_Duedate_Range: [DateTime], $role_In:[String],$managerId: [ID],$offset:Int,$first:Int)
{
    AllCascadingObjectives:contributors(okr_Duedate_Range:$okr_Duedate_Range,role_In:$role_In,offset:$offset,first:$first,employee_In: $managerId,savedOkrType:"OBJECTIVE"){
		totalCount
        edges {
        node {
            id
            progress
            okrType
            okr{
            id
            duedate
            isDiscarded{
                id
            }
            discardRequest(status:"PENDING") {
              edges {
                node {
                  id
                  status
                }
              }
            }
            contributorSet {
              edges {
                node {
                  id
                  employee {
                    id
                    user{
                      id
                    }
                  }
                  requestStatus
                }
              }
            }
            }
        }
        }
    }
}
`;

// ,$offset:Int,$first:Int
export const VERTICAL_ALL_NON_CASCADING_OBJECTIVES = gql`
query contributors($okr_Duedate_Range: [DateTime],$id: ID!, $managerId: [ID])
{
    AllNonCascadingObjectives:vertical(id:$id) {
        id
        name
        heads {
          edges {
            node {
              id
              okrContributorSet(okr_Duedate_Range: $okr_Duedate_Range, role: "OWNER",savedOkrType:"OBJECTIVE", createdBy_In: $managerId) {
                edges {
                  node {
                    id
                    okrType
                    progress
                    requestStatus
                    okr {
                      id
                      duedate
                      title
                      isDiscarded {
                        id
                      }
                      discardRequest(status:"PENDING") {
                        edges {
                          node {
                            id
                            status
                          }
                        }
                      }
                      contributorSet {
                        edges {
                          node {
                            id
                            employee {
                              id
                              user{
                                id
                              }
                            }
                            requestStatus
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        employees {
          edges {
            node {
              id
              okrContributorSet(okr_Duedate_Range:$okr_Duedate_Range, role: "OWNER",savedOkrType:"OBJECTIVE", createdBy_In: $managerId) {
                edges {
                  node {
                    id
                    okrType
                    progress
                    requestStatus
                    okr {
                      id
                      title
                      duedate
                      isDiscarded {
                        id
                      }
                      discardRequest(status:"PENDING") {
                        edges {
                          node {
                            id
                            status
                          }
                        }
                      }
                      contributorSet {
                        edges {
                          node {
                            id
                            employee {
                              id
                              user{
                                id
                              }
                            }
                            requestStatus
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
}
`;


export const VERTICAL_OKR_PERFORMANCE = gql`
query contributors($savedOkrType:String,$okr_Duedate_Range: [DateTime],$managerId: [ID],$offset:Int,$first:Int,$orderBy:[String])
{
  performance:contributors(savedOkrType:$savedOkrType, okr_Duedate_Range: $okr_Duedate_Range, employee_In: $managerId, orderBy:$orderBy ,offset:$offset,first:$first,role_In: ["COLLABORATOR", "ASSIGNED"]) {
		totalCount
    edgeCount
    edges {
      node {
        id
        progress
        employee{
          id
        }
        okr{
          id
          goalStatement
          duedate
          okrType
        }
      }
    }
  }
}
`;


export const SUBVERTICAL_AND_TEAMS_UNDER_VERTICAL = gql`
query vertical($id: ID!,$savedOkrType:String,$okr_Duedate_Range: [DateTime], $role_In:[String],$orderBy:[String])
{
  vertical(id: $id) {
    id
    name
    parentvertical{
      id
    }
    heads{
      edges{
        node{
          id
        }
      }
    }
    subvertical {
      edges {
        node {
          id
          name
          teamSet{
            totalCount
          }
          subvertical{
            totalCount
          }
          heads{
            edges{
              node{
                id
                okrContributorSet(savedOkrType:$savedOkrType, okr_Duedate_Range: $okr_Duedate_Range, role_In:$role_In, orderBy:$orderBy) {
                  edges {
                    node {
                      id
                      progress
                    }
                  }
                }
              }
            }
          }
          employees {
            edges {
              node {
                id
              
              }
            }
          }
        }
      }
    }
    teamSet {
      edges {
        node {
          id
          name
          managers {
            edges {
              node {
                id
              }
            }
          }
          employees {
            totalCount
          }
        }
      }
    }
  }
}
`;


export const TEAMS_UNDER_VERTICAL = gql`
query vertical($id: ID!)
{
  vertical(id: $id) {
    id
    name
    teamSet {
      edges {
        node {
          id
          name
          managers {
            edges {
              node {
                id
              }
            }
          }
          employees {
            totalCount
          }
        }
      }
    }
  }
}
`;