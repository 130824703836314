import gql from 'graphql-tag';
//donot add new feild to this query
export const SIMPLE_SURVEY_BY_ID = gql`
  query SurveyById($id: ID!,$user: ID) {
    SurveyById(id: $id) {
        id
        name
        multisourceResponse
        responseSet(last:1,user:$user){
            edges{
              node{
                id
              }
            }
          }
    }
  }
`;
