import gql from 'graphql-tag';

export const ALL_ACTION_ITEMS =gql`
query allActionItems($okr: ID,$isAgenda: Boolean){
    allActionItems(okr:$okr,isAgenda:$isAgenda){
        edges{
            node{
                id
                createdAt
                meeting{
                    id
                    title 
                }
                title
            }
        }
    }
}
`