// @ts-nocheck
import {Col,Spin,Tooltip } from "antd";
import React from "react";
import ash_expansion from '../../assets/ash_expansion.svg'
import vertical_icon from '../../assets/vertical_icon.svg'
import team_icon from '../../assets/team_icon.svg'
import transparent_expansion from '../../assets/transparent_expansion.svg'
import backspace from '../../assets/backspace.svg'
import { getIntFromString } from "modules/look";
import EmployeeListContainer from "../container/employeeListContainer";
import TeamListContainer from "../container/TeamListContainer";
import TeamOkrPerformanceContainerPopup from "../formComponents/Container/TeamOkrPerformanceContainerPopup";
import OkrVerticalContainerPopup from "../formComponents/Container/OkrVerticalContainerPopup";
const SubVerticalAndTeamsView = ( props ) => {
  const {me,verticalDetail,dateRangeTofilter,dataSourceLoader,dataSource,getSubverticalAndTeams}=props
  const [expandedRow, setexpandedRow] = React.useState([])
  const[selectedTeam,setselectedTeam]=React.useState()
  const[selectedVertical,setselectedVertical]=React.useState()
  const openTab=(item)=>{
    if(item?.subvertical){
      getSubverticalAndTeams(getIntFromString(item?.id),false,item?.managerId)
    }else{
      setselectedVertical(item)
    }
  }

  const expand=(id)=>{
    let list = [].concat(expandedRow)
    if(!list?.includes(id)){
        // list.push(id)
        list = [id]
    }else{
        list = list.filter(item=>item!==id)
    }
    setexpandedRow(list)
  }

  return (
   <>
    {selectedVertical&&<OkrVerticalContainerPopup dateRangeTofilter={dateRangeTofilter} visible={selectedVertical} me={me} verticalDetailModal={selectedVertical} onClose={()=>{setselectedVertical(null)}}/>}
    {selectedTeam&&<TeamOkrPerformanceContainerPopup dateRangeTofilter={dateRangeTofilter} visible={selectedTeam} me={me} teamDetail={selectedTeam} onClose={()=>{setselectedTeam(null)}}/>}
   <Spin spinning={dataSourceLoader}>
    <div style={{minHeight:"350px"}}>
    <div style={{width:"100%",display:"flex",flexDirection:"row",flexWrap:"nowrap",gap:"5px",justifyContent:"space-between"}}>
      <div>
        {(dataSource && dataSource?.vertical?.id!==verticalDetail?.id && dataSource?.vertical?.parentvertical?.id) ?
        <img onClick={(e)=>{getSubverticalAndTeams(getIntFromString(dataSource?.vertical?.parentvertical?.id))}} src={backspace} alt="" style={{cursor:"pointer",width:"28px",marginTop:"15px"}} />
        :
        <div style={{width:"28px",height:"5px"}}></div>
        }
      </div>
      <Col style={{width:"100%"}}>
   
      <h4 className="chart-title" style={{marginBottom:"1px"}} >
        {
          dataSource?.vertical?.id!==verticalDetail?.id?
          dataSource?.vertical?.name:'Sub verticals & Teams'
        }
      </h4>
        {
         dataSource && dataSource?.vertical?.id!==verticalDetail?.id?
          <p style={{color:"#009AF1",cursor:"pointer",marginTop:"1px",textAlign:"center"}} onClick={()=>{setselectedVertical(dataSource?.vertical)}}>See Performance</p>:''
        }
    </Col>
      <div style={{width:"2px",height:"2px"}}></div>
    </div>
       <div style={{display:"flex",flexDirection:"column",gap:"15px"}} >
        {
          dataSource?.edges?.map((item)=>(
           
            item.type==="vertical"?
            <>
            <div className="user-card user-card-attribute" style={{margin:"0px"}} onClick={()=>{openTab(item)}}>
            <div style={{width:"100%",display:"flex",flexDirection:"row",flexWrap:"nowrap",gap:"10px",alignItems:"center"}}>
              <div style={{width:"68px",minWidth:"68px"}}>
                {(!item?.subvertical && item?.teamSet)?
                <img src={ash_expansion} onClick={(e)=>{expand(item?.id);e.stopPropagation()}} className={`expansion-arrow ${expandedRow?.includes(item?.id)?'expansion-arrow-down':''}`} alt="" />:
                <img src={transparent_expansion} alt="" />
                }
                <img style={{width:"33px",height:"33px",marginLeft:"10px"}} src={vertical_icon} alt="" />
              </div>
              <Tooltip title={item?.name}>
              <h4 className="graph-title" style={{padding:"0px",fontWeight:"600",textAlign:'left',margin:0}}>{item?.name} </h4>
              </Tooltip>
            </div>
            <div style={{width:"fit-content",maxWidth:"fit-content",minWidth:"fit-content"}}>
            <h4 className="member-progress" style={{margin:0}}>
                {item?.progress}
            </h4>
            </div>
            </div>
            {
            expandedRow?.includes(item?.id) && ( 
                <TeamListContainer dateRangeTofilter={dateRangeTofilter} {...props} verticalDetail={item}/>
            ) 
            }
            </>
            :
           <>
           <div className="user-card user-card-attribute" style={{margin:"0px"}} onClick={(e)=>{setselectedTeam(item)}}>
           <div style={{width:"100%",display:"flex",flexDirection:"row",flexWrap:"nowrap",gap:"10px",alignItems:"center"}}>
             <div style={{width:"68px",minWidth:"68px"}}>
               {item?.employeeCount?
               <img src={ash_expansion} onClick={(e)=>{expand(item?.id);e.stopPropagation()}} className={`expansion-arrow ${expandedRow?.includes(item?.id)?'expansion-arrow-down':''}`} alt="" />:
               <img src={transparent_expansion} alt="" />
               }
               <img style={{width:"33px",height:"33px",marginLeft:"10px"}} src={team_icon} alt="" />
             </div>
             <Tooltip title={item?.name}>
             <h4 className="graph-title" style={{padding:"0px",fontWeight:"600",textAlign:'left',margin:0}}>{item?.name}</h4>
             </Tooltip>
           </div>
        
           </div>
           {
            expandedRow?.includes(item?.id) && ( 
                <EmployeeListContainer {...props} teamDetail={item} dateRangeTofilter={dateRangeTofilter}/>
            ) 
            }
           
           </>
           
          ))
        }
       </div>
    </div>
    </Spin>
    </>
  )
};

export default SubVerticalAndTeamsView

