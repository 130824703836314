import { withApollo } from '@apollo/client/react/hoc';
import { withCreateBadgeAward } from 'modules/kudos-user/container/badgeAwardOperationFeedback';
import { FEEDBACK_FETCH_QTY, getIntFromString } from 'modules/look';
import React, { useEffect, useState } from 'react';
import { compose } from '../../core';
import SubmittedFeedbackView from '../components/SubmittedFeedbackView';
import { FEEDBACK_BY_ID } from '../graphql/FeedbackById.gql';
import { SUBMITTED_FEEDBACK } from '../graphql/SubmittedFeedback.gql';
import { SUBMITTED_FEEDBACK_SUBSCRIPTION } from '../graphql/submittedFeedBackSubscription.gql';
import { withDeleteFeedback, WithUpdateFeedback } from './feedbackOperations';

const SubmittedFeedbackContainer = (props) => {
  const { client, me, history, searchBarData, setFeedBackById, feedbackById, setFeedbackDetailsLoading, activeTab, setFeedbackDetails, grabSubmittedLoaderStatus, loaderState, feedbackPermission } = props
  const [submittedFeedbackLoading, setSubmittedFeedbackLoading] = useState(false);
  const [submittedFeedback, setsubmittedFeedback] = React.useState([])
  const [givenBadgeAward, setGivenBadgeAward] = React.useState();
  const [updatedFeedback, setUpdatedFeedback] = React.useState()
  const [queryObj, setQueryObj,] = React.useState({});
  const [pageCount, setPageCount] = useState({ edgeCount: null, totalCount: null });

  const ref = React.useRef([]);
  const edgeCountMain = React.useRef(null)
  const totalCountMain = React.useRef(null)
  const [selectedFeedback, setSelectedFeedback] = React.useState();
  const m = React.useRef(true)

  let feedbackSub = undefined

  React.useEffect(() => {
    return () => {
      if (feedbackSub) {
        feedbackSub.unsubscribe();
      }
    }
  }, [])

  useEffect(() => {
    if (activeTab !== 'submitted' && feedbackSub) {
      feedbackSub.unsubscribe();
    }
  }, [activeTab])

  React.useEffect(() => {
    return () => {
      m.current = false
    }
  }, [])

  React.useEffect(() => {
    m.current = true

  }, [])

  const SubmittedFeedback = async (filterData) => {

    try{
      setSubmittedFeedbackLoading(true)
    loaderState(true)
    const { data } = await client.query({
      query: SUBMITTED_FEEDBACK,
      variables: filterData,
      fetchPolicy: 'network-only'
    });

    const arrMain = Object.values(data)?.map(item => item?.edges).flat();

    const idOccurrences = {};
    const uniqueArr = arrMain.filter(obj => {
      const id = obj.node.id;
      if (!idOccurrences[id]) {
        idOccurrences[id] = true;
        return true;
      }
      return false;
    });

   
    edgeCountMain.current = Object.values(data)[0]?.edgeCount
    totalCountMain.current = Object.values(data)[0]?.totalCount
   
    const dateSortedArr = uniqueArr.sort((a, b) => {
      const dateA = new Date(a.node.createdAt);
      const dateB = new Date(b.node.createdAt);
      // @ts-ignore
      return dateB - dateA;
    })

   
   

    let isInvalidUserPresent = uniqueArr?.filter(item => getIntFromString(item?.node?.feedbackTo?.id) === getIntFromString(me?.employee?.id))

    if (data?.submittedFeedbackFeedback && data?.submittedFeedbackFirstName && data?.submittedFeedbackLastName && data?.submittedFeedbackOkr)  {
      setsubmittedFeedback(uniqueArr?.map(({ node }) => node))
     
      setPageCount({ ...pageCount, edgeCount: edgeCountMain.current, totalCount: totalCountMain.current })
      ref.current = uniqueArr?.map(({ node }) => node)
    }
    // let temp = [...ref.current];
    feedbackSub = client.subscribe({
      query: SUBMITTED_FEEDBACK_SUBSCRIPTION,
      variables: filterData,
      fetchPolicy: 'network-only'
    }).subscribe({
      next(result) {
        
        if (getIntFromString(result.data.feedbackSubscription.feedback?.feedbackTo?.id) !== getIntFromString(me?.employee?.id)) {

          switch (result.data.feedbackSubscription.mutation) {

            case 'CREATE':

              if (m.current) {
                const updated = [result.data.feedbackSubscription.feedback, ...ref.current]
                setPageCount({ ...pageCount, 
                  edgeCount: edgeCountMain.current + 1,
                   totalCount: totalCountMain.current + 1 })
                setsubmittedFeedback(updated)
                ref.current = [...updated]
                edgeCountMain.current = edgeCountMain.current + 1;
                totalCountMain.current = totalCountMain.current + 1;

              }
              break
            case 'UPDATE':
              if (m.current) {


                const updatedFeedback = ref.current?.map(item =>
                  item.id === result?.data?.feedbackSubscription?.feedback?.id
                    ? result.data.feedbackSubscription.feedback
                    : item
                )
                setsubmittedFeedback(updatedFeedback)
                ref.current = [...updatedFeedback]
              }

              break
            case 'DELETE':
              if (m.current) {
               
                setPageCount({ ...pageCount, 
                  edgeCount: edgeCountMain.current - 1
                  , totalCount:  totalCountMain.current - 1})
                let removedFeedback = ref.current?.filter(item =>
                  getIntFromString(item.id) !== getIntFromString(result?.data?.feedbackSubscription?.feedback?.id)
                )
                
                setsubmittedFeedback(removedFeedback)
                edgeCountMain.current = edgeCountMain.current - 1;
                totalCountMain.current = totalCountMain.current - 1;
                ref.current = [...removedFeedback]
              }

              break
            default:
              break
          }
        }
      }
    })

    loaderState(false)
    setSubmittedFeedbackLoading(false)
    }catch(e){
      console.error(e)
    }
  }


  React.useEffect(() => {

    if (givenBadgeAward && updatedFeedback) {
      let badgeAward = { edges: [{ node: givenBadgeAward?.badgeAward }] }
      let newUpdatedFeedbacks = submittedFeedback?.map((item) => {
        if (item?.id === updatedFeedback?.id) {
          return { ...item, badgeAward }
        } else {
          return item
        }
      }
      )
      setsubmittedFeedback(newUpdatedFeedbacks)
    }


  }, [givenBadgeAward, updatedFeedback])

  const getFeedbackDetails = async (id) => {
    setFeedbackDetailsLoading(true)

    try {
      const { data } = await client.query({
        query: FEEDBACK_BY_ID,
        variables: { id: id },
        fetchPolicy: 'network-only'
      });

      if (data?.feedbackById) {
        setFeedBackById(data?.feedbackById)
        setFeedbackDetails(data?.feedbackById)
      }
    } catch (e) {
      console.error(e)
    }
    setFeedbackDetailsLoading(false)
  }

  React.useEffect(() => {
    if (me && activeTab === 'submitted' && feedbackPermission?.requried_permission) {
      const now = new Date();
      const all = new Date(0).toISOString();
      SubmittedFeedback({ feedbackFrom: me?.employee?.id, orderBy: ["-createdAt", "-updatedAt"], first: FEEDBACK_FETCH_QTY })
      setQueryObj({ feedbackFrom: me?.employee?.id, orderBy: ["-createdAt", "-updatedAt"], first: FEEDBACK_FETCH_QTY })
    }
    else if (me === null) {
      history.push('/user/login')
    }

  }, [me,feedbackPermission?.requried_permission])

  return (
    <>
      <SubmittedFeedbackView pageCount={pageCount} activeTab={activeTab} {...props} queryObj={queryObj} setQueryObj={setQueryObj} setUpdatedFeedback={setUpdatedFeedback} givenBadgeAward={givenBadgeAward} setGivenBadgeAward={setGivenBadgeAward} submitLoading={submittedFeedbackLoading} searchBarData={searchBarData} getFeedbackDetails={getFeedbackDetails} feedbackById={feedbackById} setSelectedFeedback={setSelectedFeedback} dataSource={submittedFeedback} onfilter={(e) => SubmittedFeedback(e)}></SubmittedFeedbackView>
    </>
  );
};

export default compose(withApollo, withDeleteFeedback,WithUpdateFeedback,withCreateBadgeAward)(SubmittedFeedbackContainer);
