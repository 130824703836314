import gql from 'graphql-tag';

export const CREATE_RESPONSE_MUTATION = gql`
  mutation createResponse($answers: [AnswerInput]!, $surveyId: Int!, $totalDuration: Int) {
    createResponse(answers: $answers, surveyId: $surveyId, totalDuration: $totalDuration) {
      response {
        id
    responseDuration
    responseDate
    norm
    answerSet {
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          edges {
            cursor
            node {
                id
                answer
                responseDuration
            }
          }
          totalCount
          edgeCount
    }
      }
    }
  }
`;
