import gql from "graphql-tag";

export const EMPLOYEE_REPORT_SURVEY_DETAIL = gql`
query employeeSurvey($user:ID,$personality_survey: ID,$strength_survey: ID,$value_survey: ID,$personality_questions:[ID],$knowledge_survey:ID){
    personality: surveyResponses(user: $user, survey: $personality_survey) {
        edges {
            node {
            id
            answerSet(question_In:$personality_questions) {
                edges {
                node {
                    id
                    answer
                    sequence
                    question{
                    id
                    }
                }
                }
            }
            }
        }
    }
    strength: surveyResponses(user: $user, survey: $strength_survey,last:1) {
        edges {
            node {
            id
            answerSet(first:5) {
                edges {
                node {
                    id
                    answer
                }
                }
            }
            }
        }
    }
    value: surveyResponses(user: $user, survey: $value_survey,last:1) {
        edges {
            node {
            id
            answerSet (first:5){
                edges {
                node {
                    id
                    answer
                }
                }
            }
            }
        }
    }

    knowledge: surveyResponses(user: $user, survey: $knowledge_survey,last:1) {
        edges {
            node {
            id
            answerSet (first:5){
                edges {
                node {
                    id
                    answer
                }
                }
            }
            }
        }
    }

    impact_narrative:allImpactNarrative(user:$user,last:1,orderBy:["createdAt"]){
        edges{
            node{
                id
                overview
                communication
                collaboration
                decisionMaking
                relationshipBuilding
            }
        }
    }
   
}
`