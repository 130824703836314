import gql from 'graphql-tag';
import { FAQ_QUESTION_TYPE } from './FaqQuestionType.gql';

export const ADD_FAQ_QUESTION = gql`
  ${FAQ_QUESTION_TYPE}
  mutation createFaqQuestion($questionData: QuestionInput!) {
    createFaqQuestion(questionData: $questionData) {
      question {
        ...FaqQuestionType
      }
    }
  }
`;
