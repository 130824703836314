import React from 'react';
import { Form, Row, Col, Button, InputNumber } from 'antd';

import { CKEditorField, getIntFromString, WeightedQuestionsAutoComplete, OperandField } from '../../look';
import AddWeightedQuestion from '../containers/AddWeightedQuestion';

export const CKEditorFieldName = 'note';
const FormItem = Form.Item;

const OperandFormComponent = props => {
  const { btnText, operand, onSubmit, setVisible } = props;

  const initialValue = {
    note: (operand && operand.note) || '',
    defaultOperandValue: (operand && operand.defaultOperandValue) || 1.0,
    operation: (operand && operand.operation) || '',
    weightedQuestions: (operand && operand.weightedQuestions.edges.map(({ node }) => getIntFromString(node.id))) || []
  };

  return (
    <Form
      onFinish={
        // console.log
        onSubmit
      }
      layout="vertical"
      initialValues={initialValue}
      required={false}
      scrollToFirstError={true}
    >
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row align="middle" gutter={24}>
            <Col span={22}>
              <WeightedQuestionsAutoComplete
                mode="multiple"
                name="weightedQuestions"
                offset={1}
                label="Weighted Questions"
                rules={[{ required: true, message: 'Weighted Question is required!' }]}
              />
            </Col>

            <Col span={2}>
              <AddWeightedQuestion btn={'add'} />
            </Col>
          </Row>
        </Col>

        <Col span={12}>
          <OperandField
            name="operation"
            label="Operation"
            offset={1}
            rules={[{ required: true, message: 'Operation is required' }]}
          />
        </Col>

        <Col span={11}>
          <FormItem
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24, offset: 1 }}
            name="defaultOperandValue"
            label="Default Operand Value"
            placeholder="Default Operand Value"
          >
            <InputNumber
            autoComplete='off'
              style={{
                width: '100%'
              }}
              defaultValue="1.0"
              min="0"
              max="10"
              step="0.1" /* stringMode */
            />
          </FormItem>
        </Col>

        <Col span={23}>
          <CKEditorField
            name={CKEditorFieldName}
            label="Note"
            initialValue={operand && operand.note}
            // rules={[{ required: true, message: 'All questions are required!' }]}
          />
        </Col>
      </Row>
      <Row justify="space-between">
        <Col>
          <Button htmlType="submit" type="primary">
            {btnText}
          </Button>
        </Col>
        <Col>
          <Button ghost type="primary" onClick={() => setVisible(false)}>
            Cancel
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default OperandFormComponent;
