import gql from 'graphql-tag';

export const DELETE_REDINESS = gql`
mutation  deleteReadiness($id:ID!){
  deleteReadiness(id:$id){
    readiness{
      id
    }
  }
}
`