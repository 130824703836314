import gql from 'graphql-tag';

export const ALL_EMPLOYEE_LIST =gql`
query me($employees_User_FirstName_Icontains:String,$employees_User_LastName_Icontains:String){
    firstName:me {
      id
      employee {
        id
        organizationSet(employees_User_FirstName_Icontains:$employees_User_FirstName_Icontains, employees_User_LastName_Icontains: "") {
          edges {
            node {
              id
              employees {
                edges {
                  node {
                    id
                    user {
                      id
                      firstName
                      lastName
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    lastName:me {
        id
        employee {
          id
          organizationSet(employees_User_LastName_Icontains: $employees_User_LastName_Icontains) {
            edges {
              node {
                id
                employees {
                  edges {
                    node {
                      id
                      user {
                        id
                        firstName
                        lastName
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
  }
`