import gql from 'graphql-tag';
import { USER_TYPE } from '../../user/graphql/UserType.gql';

export const RESPONSE_COMPARE_REQUEST_TYPE = gql`
  ${USER_TYPE}
  fragment ResponseCompareRequestType on ResponseCompareRequestType {
    id
    sender {
      ...UserType
    }
    message
    survey {
      id
      name
      description
      shuffle
      public
    }
    requestDate
    responseDate
    sharedResponse {
      id
      # ...ResponseType
    }
    accepted
    receiver {
      ...UserType
    }
  }
`;
