import gql from 'graphql-tag';

export const CHECK_ORG_COMPARISON =gql`
query me{
    me{
        id
        employee {
            id
            organizationSet {
                edges {
                    node {
                        id
                        allowComparison
                    }
                }
            }
        }
    }
}
`