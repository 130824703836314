// @ts-nocheck
import React from 'react';
import { Row, Col, Button,Spin,Table ,Tooltip, } from "antd";
import styled from 'styled-components';
import { displayUtcToUserTimezone } from '../../look/components/functions'
import ReactMarkdown from '../../look/components/ReactMarkdown';
import { BarChartOutlined } from '@ant-design/icons';
import { dashboard_home_icons } from 'modules/poc-dashboard/icons';
const ResponseSetTable = props => {
  const {
    dataSource,
    setChart
  } = props;
  const anchorStyle={
    whiteSpace:'nowrap',
    overflow:'hidden',
    textOverflow:'ellipsis',
    maxWidth:'300px',
    marginTop:'5px'
  }
  
  const columns = [
    {
      title: <h4 >Survey Name</h4>,
      key: 'name',
      render: (text, record) => record && 
      <Tooltip title={record?.survey?.name?.length>16?record?.survey?.name:''} placement="right">
       <p style={anchorStyle}>{record?.survey?.name}</p>
      </Tooltip>
    },
    {
        title: <h4 >Response Duration</h4>,
        key: 'Duration',
        render: (text, record) => record &&  
         <p style={anchorStyle}>{record?.responseDuration}</p> 
    },
    {
        title: <h4 >Response Date</h4>,
        key: 'Date',
        render: (text, record) => record &&  
          <p style={anchorStyle}>{displayUtcToUserTimezone(record?.responseDate, "DD MMMM YYYY")}</p> 
    },
    {
        title: '',
        key: 'action',
        render: (text, record) => record &&  
         <Tooltip title="View report">
            <img src={dashboard_home_icons?.report_icon} alt="report_icon" style={{ cursor: "pointer" }}  onClick={()=>{setChart(record)}}/>
         </Tooltip>
    },

  ];
 

  return (
    <>
      <MyTable
        size={'middle'}
        showHeader={true}
        pagination={false}
        dataSource={
          dataSource
            ? dataSource
            : []
        }
        columns={columns.map(c => ({ ...c, sortDirections: ['descend', 'ascend', 'descend'] }))}
      />
 
    </>
  );
};

export default ResponseSetTable;

export const MyTable = styled(Table)`
  table {
    border-spacing: 0 1em;
    tbody {
      tr {
        td {
          border: 1px solid rgba(0, 0, 0, 0.06) !important;
          border-style: solid none !important;
        }
        td:first-child {
          border-left-style: solid !important;
          border-top-left-radius: 5px !important;
          border-bottom-left-radius: 5px !important;
        }
        td:last-child {
          border-right-style: solid !important;
          border-bottom-right-radius: 5px !important;
          border-top-right-radius: 5px !important;
          border-bottom: 1px solid rgba(0, 0, 0, 0.06) !important;
        }
      }
    }
  }
`;