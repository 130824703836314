import gql from 'graphql-tag';

export const MEETING_RATING =gql`
query meetingById($id:ID!){
    meetingById(id:$id){
      id
      meetingfeedbackSet {
        totalCount
        edges {
          node {
            id
            createdAt
            suggestions
            member{
                user{
                  id
                  firstName
                  lastName
                }
            }
            meetingEffectiveness
            hostSkills
            suggestions
          }
        }
      }
    }
}
`