import gql from 'graphql-tag';
import { TEXT_SECTION_TYPE } from './TextSectionType.gql';

export const ADD_TEXT_SECTION_MUTATION = gql`
  ${TEXT_SECTION_TYPE}
  mutation createTextSection($condition: [Int], $textSectionData: TextSectionInput!) {
    createTextSection(condition: $condition, textSectionData: $textSectionData) {
      textSection {
        ...TextSectionType
      }
    }
  }
`;
