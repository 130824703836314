
import React from 'react';
import BarsDetailVew from '../Components/BarsDetailView';
import { getBehaviourBase64StringFromInt, globalPermissionValidator } from 'modules/look';
import { withApollo } from '@apollo/client/react/hoc';
import { BEHAVIOR_ANCHOR_RATING_BY_ID_MAIN_COMPETECNY, BEHAVIOR_ANCHOR_RATING_BY_ID_MAIN_COMPETECNY_EMPLOYEE_LEVEL, BEHAVIOR_ANCHOR_RATING_BY_ID_SUB_COMPETECNY, BEHAVIOR_ANCHOR_RATING_BY_ID_SUB_COMPETECNY_EMPLOYEE_LEVEL } from 'modules/Bars-module/graphql/basrsDetailQueries.gql';
import { compose } from 'modules/core';
import NoPermissionView from 'modules/kudos-user/component/NoPermissionComponent';
import { Row, Spin } from 'antd';
import { bars_permission } from 'Permissions/bars.permission';

const BarsDetailContainer = (props) => {

    const { me, client, match, userPermission, permissionLoader } = props;

    const [main_competecny_behavior, setMainCompetencyBehavior] = React.useState()
    const [subCompetecny_behaviour, setSubCompetecnyBehavior] = React.useState()
    const [main_competecny_employee_level, setMainCompetencyEmployeeLevel] = React.useState()
    const [sub_competecny_employee_level, setsubCompetencyEmployeeLevel] = React.useState()
    const [loading, setLoading] = React.useState(true)
    const [main_competecny_loading, setMainCompetencyLoading] = React.useState(false)
    const [sub_competecny_loading, setSubCompetencyLoading] = React.useState(false)
    const [barsPermission, setBarsPermission] = React.useState(undefined);

    //Remove duplicates
    const employeeLevelList = (list) => {
        var setObj = new Set();
        var result = list.reduce((acc, item) => {
            if (!setObj.has(item?.id)) {
                setObj.add(item?.id)
                acc.push(item)
            }
            return acc;
        }, [])

        return result
    }

    //Main competency employee Level fetch data
    const MainCompetencyEmployeeLevel = async (id) => {
        const { data } = await client.query({
            query: BEHAVIOR_ANCHOR_RATING_BY_ID_MAIN_COMPETECNY_EMPLOYEE_LEVEL,
            fetchPolicy: 'network-only',
            variables: { id: getBehaviourBase64StringFromInt(id) }
        });
        if (data?.behaviourallyAnchoredRatingById) {
            let main_data = {
                bar_id: data.behaviourallyAnchoredRatingById?.id,
                main_competency: {
                    id: data.behaviourallyAnchoredRatingById?.mainCompetency?.id,
                    title: data.behaviourallyAnchoredRatingById?.mainCompetency?.title
                },
                main_competency_employee_levels: employeeLevelList(data.behaviourallyAnchoredRatingById?.mainCompetency?.behaviourallyanchoredratingSet?.edges?.map(({ node }) =>
                    node?.employeeLevel)),
            }
            setMainCompetencyEmployeeLevel(main_data)
            getBehaviourAnchorListByIdMinCompetency({ employeeLevel: main_data?.main_competency_employee_levels[0]?.id })
            setLoading(false)
        }
    }

    //Main competency behaviour rating scale fetch data
    const getBehaviourAnchorListByIdMinCompetency = async (filter) => {
        setMainCompetencyLoading(true)
        let id = getBehaviourBase64StringFromInt(match.params?.id)
        const { data } = await client.query({
            query: BEHAVIOR_ANCHOR_RATING_BY_ID_MAIN_COMPETECNY,
            fetchPolicy: 'network-only',
            variables: { id: id, ...filter }
        });
        if (data?.behaviourallyAnchoredRatingById) {

            let behaviour_data = {
                bar_id: data.behaviourallyAnchoredRatingById?.id,
                main_competency: data.behaviourallyAnchoredRatingById?.mainCompetency?.id,
                main_competency_employee_levels: employeeLevelList(data.behaviourallyAnchoredRatingById?.mainCompetency?.behaviourallyanchoredratingSet?.edges?.map(({ node }) =>
                    node?.employeeLevel)),
                rating_scale_with_employee_level: data.behaviourallyAnchoredRatingById?.mainCompetency?.behaviourallyanchoredratingSet?.edges?.map(({ node }) => {
                    return {
                        id: node?.ratingScale?.id,
                        title: node?.ratingScale?.name,
                        behaviourExample: node?.behaviourExample
                    }
                }),
            }
            setMainCompetencyBehavior(behaviour_data)
            setMainCompetencyLoading(false)
        }
    }

    //Sub competency employee Level fetch data
    const SubCompetencyEmployeeLevel = async (id) => {
        const { data } = await client.query({
            query: BEHAVIOR_ANCHOR_RATING_BY_ID_SUB_COMPETECNY_EMPLOYEE_LEVEL,
            fetchPolicy: 'network-only',
            variables: { id: getBehaviourBase64StringFromInt(id) }
        });
        if (data?.behaviourallyAnchoredRatingById) {
            let sub_data = {
                bar_id: data.behaviourallyAnchoredRatingById?.id,
                sub_competency: data.behaviourallyAnchoredRatingById?.mainCompetency?.competencySet?.edges?.map(({ node }) => {
                    return {
                        id: node?.id,
                        title: node?.title
                    }
                }),
                sub_competency_employee_level: employeeLevelList([].concat(...data.behaviourallyAnchoredRatingById?.mainCompetency?.competencySet?.edges?.map(({ node }) =>
                    node?.behaviourallyanchoredratingSet?.edges?.map(({ node }) => {
                        return {
                            id: node?.employeeLevel?.id,
                            name: node?.employeeLevel?.name,
                        }
                    }))))
            }
            setsubCompetencyEmployeeLevel(sub_data)
            getBehaviourAnchorListByIdSubCompetecny({ comp_id: sub_data?.sub_competency[0]?.id, employeeLevel: sub_data?.sub_competency_employee_level[0]?.id })
            setLoading(false)
        }
    }

    //sub competency behaviour rating scale fetch data
    const getBehaviourAnchorListByIdSubCompetecny = async (filter) => {
        setSubCompetencyLoading(true)
        let id = getBehaviourBase64StringFromInt(match.params?.id)
        const { data } = await client.query({
            query: BEHAVIOR_ANCHOR_RATING_BY_ID_SUB_COMPETECNY,
            fetchPolicy: 'network-only',
            variables: { id: id, ...filter }
        });
        if (data?.behaviourallyAnchoredRatingById) {
            let behaviour_sub_data = {
                bar_id: data.behaviourallyAnchoredRatingById?.id,
                rating_scale_with_employee_level: [].concat(...data.behaviourallyAnchoredRatingById?.mainCompetency?.competencySet?.edges?.map(({ node }) =>
                    node?.behaviourallyanchoredratingSet?.edges?.map(({ node }) => {
                        return {
                            id: node?.ratingScale?.id,
                            title: node?.ratingScale?.name,
                            behaviourExample: node?.behaviourExample
                        }
                    }))),
            }
            setSubCompetecnyBehavior(behaviour_sub_data)
            setSubCompetencyLoading(false)
        }
    }



 


  React.useEffect(() => {

    // based on permission of user bars will be di
    if (barsPermission?.required_permission &&  match.params?.id ) {
        MainCompetencyEmployeeLevel(match.params?.id)
        SubCompetencyEmployeeLevel(match.params?.id)
    }

  }, [barsPermission,match])

    React.useEffect(() => {
        if ( userPermission?.length) {
            let permission = globalPermissionValidator(bars_permission, userPermission);
            setBarsPermission(permission)
        }
    }, [userPermission])


    return (
        <>


            <Row justify='center' align='middle'>
                {
                    <Spin spinning={permissionLoader} wrapperClassName ='permission-check-bars'>
                        <div>
                            {
                                barsPermission !== undefined &&
                                <div>
                                    {
                                        barsPermission?.required_permission ?
                                        <BarsDetailVew
                                        {...props}
                                        main_competecny_employee_level={main_competecny_employee_level}
                                        main_competecny_behavior={main_competecny_behavior}
                                        getBehaviourAnchorListByIdMinCompetency={(e) => getBehaviourAnchorListByIdMinCompetency(e)}
                                        main_competecny_loading={main_competecny_loading}
                                        loading={loading}
                                        sub_competecny_employee_level={sub_competecny_employee_level}
                                        getBehaviourAnchorListByIdSubCompetecny={(e) => getBehaviourAnchorListByIdSubCompetecny(e)}
                                        subCompetecny_behaviour={subCompetecny_behaviour}
                                        sub_competecny_loading={sub_competecny_loading}
                                    ></BarsDetailVew>
                                            :
                                            <NoPermissionView />
                                    }

                                </div>
                            }
                        </div>
                    </Spin>
                }
            </Row>

            
        </>
    )
}

export default compose(withApollo)(BarsDetailContainer);