// @ts-nocheck
import React from "react";
import { compose } from "../../core";
import '../css/RoleDefinition.css'
import RoleDefinitionHomeView from "../components/RoleDefinitionHomeView";
import RoleDefinitionSideBar from "../components/RoleDefinitionSideBar";
import { ROLE_HOME_TOOL_ID } from "../../../config";
import { withApollo } from '@apollo/client/react/hoc';
import { role_definition_permission } from "Permissions/roledefinition.permission";
import { globalPermissionValidator } from "modules/look";
import NoPermissionView from "modules/kudos-user/component/NoPermissionComponent";
import { Spin } from "antd";

const RoleDefinitionHomeContainer = (props) => {

    const { userPermission, history } = props
    const [permission, setPermission] = React.useState()
    const [current_tab, setCurrentTab] = React.useState()
    const [back_to_home, SetbackToHome] = React.useState()

    //Permission Check
    React.useEffect(() => {
        if (userPermission?.length) {
            let permission = globalPermissionValidator(role_definition_permission, userPermission)
            setPermission(permission)
        }
    }, [userPermission])

    // to set the route to local storage when sidebar selection is changed
    React.useEffect(() => {
        if (current_tab) {
            const cached_navigation = JSON.parse(localStorage?.getItem('role_definition_navigation'))
            let role_definition_navigation = cached_navigation ? { ...cached_navigation, parent_route: current_tab }
                : { parent_route: current_tab }
            localStorage.setItem('role_definition_navigation', JSON.stringify(role_definition_navigation))
        }
    }, [current_tab])

    // to handle route when user navigate back to role definition
    const role_definition_navigation = JSON.parse(localStorage?.getItem('role_definition_navigation'))
    if (!current_tab) {
        if (role_definition_navigation) {
            setCurrentTab(role_definition_navigation?.parent_route)
        } else {
            setCurrentTab({ key: 'employee_level', label: 'Employee Level' })
        }
    }

    const goBack = () => {
        const cached_navigation = JSON.parse(localStorage?.getItem('role_definition_navigation'))
        if (cached_navigation) {
            let parent_route = cached_navigation?.parent_route
            let child_route = cached_navigation?.child_route
            if (parent_route?.key === 'job_family' && child_route?.key === 'jobfamily_add_edit_view') {
                if (child_route?.jobfamily_id) {
                    let nav = {
                        parent_route: { key: 'job_family', label: 'Job Family' },
                        child_route: { key: "jobfamily_home_view" }
                    }
                    localStorage.setItem('role_definition_navigation', JSON.stringify(nav))
                    history.goBack()
                } else {
                    SetbackToHome('jobfamily_home_view')
                }
            } else if (parent_route?.key === 'role' && child_route?.key === 'role_add_edit_view') {
                if (child_route?.role_id) {
                    let nav = {
                        parent_route: { key: 'role', label: 'Role' },
                        child_route: { key: "role_home_view" }
                    }
                    localStorage.setItem('role_definition_navigation', JSON.stringify(nav))
                    history.goBack()
                } else {
                    SetbackToHome('role_home_view')
                }
            }
            else if (parent_route?.key === 'sub_competency' && child_route?.key === 'competency_add_edit_view') {
                if (child_route?.main_competency) {
                    setCurrentTab({ key: 'competency', label: 'Competency' })
                } else {
                    SetbackToHome('competency_home_view')
                }

            }
            else {
                history.push(`/page/role/home/${ROLE_HOME_TOOL_ID}`)
            }
        } else {
            history.push(`/page/role/home/${ROLE_HOME_TOOL_ID}`)
        }

    }

    return (
        <>

            {(permission && permission?.required_permission) && (
                <div className="rd-root rd-root-style" >
                    <div className="rd-sidebar-container">
                        <RoleDefinitionSideBar current_tab={current_tab} setCurrentTab={(e) => { setCurrentTab(e) }} permission={permission}  {...props} />
                    </div>
                    <div className="rd-router-outlet">
                        <RoleDefinitionHomeView setCurrentTab={(e) => { setCurrentTab(e) }} current_tab={current_tab} permission={permission} SetbackToHome={SetbackToHome} back_to_home={back_to_home} {...props} />
                    </div>
                </div>
            )}
            {(permission && !permission?.required_permission) && (<NoPermissionView />)}
            {
                !permission && (
                    <div className="role-no-permission-container">
                        <Spin spinning={true} size="large"></Spin>
                    </div>
                )
            }
        </>
    );

};

export default compose(withApollo)(RoleDefinitionHomeContainer);
