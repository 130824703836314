import React from 'react';
import gql from 'graphql-tag';
import { message, Spin } from 'antd';
import { graphql } from '@apollo/client/react/hoc';

import * as ROUTE from '../../../organization/route';

import SelectField from './SelectField';
import { getIntFromString } from '../functions';

export const ALL_ORGANIZATION_EMPLOYEES_BY_ID = gql`
  query allOrganizationEmployeesById($id: ID!, $before: String, $after: String, $first: Int, $last: Int) {
    allOrganizationEmployeesById(id: $id, before: $before, after: $after, first: $first, last: $last) {
      edges {
        node {
          id
          user {
            id
            firstName
            lastName
            username
          }
        }
      }
    }
  }
`;

const VerticalHeadField = props => {
  const { allOrganizationEmployeesById, loading, deleteEmployee } = props;
  console.log("props.addemployee", props.onAdd)
  const [deleteLoading, setDeleteLoading] = React.useState(false);

  const handleDelete = async id => {
    setDeleteLoading(true);
    try {
      const response = await deleteEmployee({id:id});
      // response ? message.success('Action Successfull!') : message.error('Cannot delete this employee!');
    } catch (e) {
      message.error('Action Failed!');
      throw new Error(e.message);
    }
    setDeleteLoading(false);
  };
  const choices =
    allOrganizationEmployeesById &&
    allOrganizationEmployeesById.edges.map(({ node }) => ({
      label: node.user.firstName && node.user.lastName ? `${node.user.firstName} ${node.user.lastName}` : node.user.username,
      value: getIntFromString(node.id)
    }));

  return (
    <Spin spinning={loading || deleteLoading} size="small">
      {allOrganizationEmployeesById && (
        <SelectField
          onAdd={props.onAdd ? props.onAdd : ROUTE.addEmployee}
          onEdit={ROUTE.editEmployeeLink}
          // onDelete={handleDelete}
          {...props}
          choices={choices}
        />
      )}
    </Spin>
  );
};

export default graphql(ALL_ORGANIZATION_EMPLOYEES_BY_ID, {
  options: props => {
    return {
      variables: {
        id: props.orgId
      }
    };
  },
  props({ data: { loading, error, allOrganizationEmployeesById, subscribeToMore, updateQuery } }) {
    return { loading, error, allOrganizationEmployeesById, subscribeToMore, updateQuery };
  }
})(VerticalHeadField);
