import gql from 'graphql-tag';
export const ALL_EMPLOYEES_SURVEY_TOKEN = gql`
query allEmployees($nameIcontains: String) {
  allEmployees(nameIcontains: $nameIcontains) {
        edges {
            node {
              id
              user {
                id
                firstName
                lastName
                email
              }
            }
          }
       } 
     }
`;


export const ORGANIZATION_EMPLOYEE_LIST = gql`
query allemployee($search:String){
  first_name:allEmployees(user_FirstName:$search,first:10){
    edges{
      node{
        id
        user{
          id
          firstName
          lastName
          email
        }
      }
    }
  }
  last_name:allEmployees(user_LastName:$search,first:10){
    edges{
      node{
        id
        user{
          id
          firstName
          lastName
          email
        }
      }
    }
  }
  email:allEmployees(emailIcontains:$search,first:10){
    edges{
      node{
        id
        user{
          id
          firstName
          lastName
          email
        }
      }
    }
  }
}
`