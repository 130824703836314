import gql from 'graphql-tag';
import { USER_TYPE } from '../../user/graphql/UserType.gql';
import { COACHING_COMMITMENT_TYPE } from './CoachingCommitmentType.gql';

export const COACHING_COMMITMENT_PROGRESS_TYPE = gql`
  ${COACHING_COMMITMENT_TYPE}, ${USER_TYPE}
  fragment CoachingCommitmentProgressType on CoachingCommitmentProgressType {
    id
    user {
      ...UserType
    }
    notes
    completed
    dateCreated
    dateCompleted
    coachingcommitment {
      ...CoachingCommitmentType
    }
  }
`;
