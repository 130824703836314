import gql from "graphql-tag";

export const CONTRIBUTOR_OKR_LIST = gql`
query contributors($okr_Goalstatement_Icontains:String,$employee: ID){
    contributors(okr_Goalstatement_Icontains:$okr_Goalstatement_Icontains,employee:$employee){
        edges{
          node{
            id
            okr{
              goalStatement
              title
              id
              okrType
            }
          }
        }
      }
}
`;
