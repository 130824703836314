import { Col, Divider, Menu, Row, Spin, Switch, Tag } from 'antd';
import React from 'react';

import { CommentOutlined, LikeFilled, LikeOutlined } from '@ant-design/icons';

import { CardLayout, displayUtcToUserTimezone, getIntFromString, MetaTags, TagTransfer } from '../../look';
import ReactMarkdown from '../../look/components/ReactMarkdown';

import { IMG, STORY_BANNER_HEIGHT } from '../../../config';
import CommentSection from '../containers/CommentSection';
import SimilarStoryByAuthor from '../containers/SimilarStoryByAuthor';
import ROUTE from '../route';

export const PREVIEW_DIV_ID = 'previewContent';
export const PREVIEW_TITLE_ID = 'previewTitle';
export const PREVIEW_TAG_ID = 'previewTag';

const StoryDetailView = props => {
  const {
    loading,
    actionLoading = false,
    publishedStoryById,
    admin = false,
    handleEdit,
    onLike,
    comments,
    match,
    navigation,
    editStory
  } = props;
  const [action, setAction] = React.useState(null);
  const [visible, setVisible] = React.useState(null);
  const [commentCount,setCommentCount] = React.useState(undefined);
  const commentRef = React.useRef(null);
  const menu = id => (
    <Menu>
      <Menu.Item key="1" /* onClick={() => handleDelete(id)} */ danger={true}>
        Delete
      </Menu.Item>
      <Menu.Item key="2" /* onClick={() => handleSelect(id)} */>Select</Menu.Item>
    </Menu>
  );
  const handleSubmit = async storyTags => {
    try {
      await editStory({
        id: getIntFromString(publishedStoryById?.id),
        storyTags
      });
    } catch (e) {
      throw new Error(e.message);
    }
  };

  const story = (
    <>
      <Col lg={23} md={24}>
        <Row gutter={[92, 24]} align="middle">
          <Col lg={24} md={12} sm={24} xs={24}>
            <h2 id={PREVIEW_TITLE_ID} style={{ fontSize: '40px' }}>
              {publishedStoryById?.title}
            </h2>
            {!admin && (
              <div style={{ paddingBottom: 5 }}>
                <Row style={{ paddingBottom: 5 }} align="middle">
                  <Col>
                    <div style={{ width: 48, borderRadius: 30, backgroundColor: '#f3f3f3', marginRight: 10 }}>
                      <img src={IMG} alt="" style={{ width: 48, borderRadius: 30, border: '1px solid #f0f0f0' }} />
                    </div>
                  </Col>
                  <Col>
                    <h4 style={{ marginBottom: 0, fontWeight: 'normal' }}>{`${publishedStoryById?.author?.split(',')[0] || 'Firstname Lastname'
                      }`}</h4>
                    <h5 style={{ marginBottom: 0, fontWeight: 'normal' }}>
                      {displayUtcToUserTimezone(publishedStoryById?.datecreated, "YYYY-MM-DD")}
                    </h5>
                  </Col>
                </Row>

                <Row>
                  <Col span={1} />
                  <Col span={22}>
                    <span
                      onClick={async () => {
                        if (action !== 'liked') {
                          const res = await onLike();
                          res && setAction('liked');
                        }
                      }}
                    >
                      <a>
                        {action === 'liked' ? (
                          <LikeFilled style={{ color: '#BCC1B5' }} />
                        ) : (
                          <LikeOutlined style={{ color: '#BCC1B5' }} />
                        )}
                        &nbsp;
                        <span className="comment-action" style={{ color: '#BCC1B5' }}>
                          {publishedStoryById?.likes || 0}
                        </span>
                      </a>
                    </span>
                  </Col>
                </Row>
              </div>
            )}
          </Col>

          <Col lg={24} md={24} sm={24} xs={24} style={{ overflow: 'hidden' }}>
            <img
              src={publishedStoryById?.banner || IMG}
              alt=""
              style={{
                /* width: '100%', height: '100%', */ width: '100%',
                maxHeight: !publishedStoryById?.banner && STORY_BANNER_HEIGHT
              }}
            />
          </Col>
        </Row>
        <br />
        <div id={PREVIEW_DIV_ID}></div>
        <ReactMarkdown>{publishedStoryById?.content}</ReactMarkdown>
        <Row>
          <Col span={24}>
            <div id={PREVIEW_TAG_ID}>
              {publishedStoryById?.storyTags.edges.map(({ node }) => renderTag(node?.name))}
              {admin && publishedStoryById && publishedStoryById?.storyTags && (
                <TagTransfer initialValues={publishedStoryById?.storyTags} handleSubmit={handleSubmit} />
              )}
            </div>
          </Col>
        </Row>
        <br />
      </Col>
      <Divider />
      <Col span={20}>
        <h4 style={{ fontWeight: 'normal', color: '#999' }}>Written by:</h4>
        <Row justify="space-between" align="middle">
          <Col>
            <Row align="middle">
              <Col>
                <img
                  src={IMG}
                  alt=""
                  style={{ width: 70, borderRadius: '50%', marginRight: 10, border: '1px solid #f0f0f0' }}
                />
              </Col>
              <Col>
                <h4 style={{ marginBottom: 0, fontWeight: 'normal' }}>{`${publishedStoryById?.author?.split(',')[0] || 'Firstname Lastname'
                  }`}</h4>
                <h5 style={{ marginBottom: 0, fontWeight: 'normal', color: '#999' }}>
                  {publishedStoryById?.author?.split(',')[1]}
                </h5>
              </Col>
            </Row>
          </Col>
          {admin && (
            <>
              <Col>
                <h4 style={{ marginBottom: 0, fontWeight: 'normal' }}>{'Published'}</h4>
                <Switch
                  checked={publishedStoryById?.published}
                  onChange={e => handleEdit(e, publishedStoryById?.id)}
                  size="small"
                  style={{ color: '#84A100' }}
                />
              </Col>
              <Col>
                <LikeOutlined
                  style={{
                    padding: 8,
                    borderRadius: 15,
                    backgroundColor: '#EDEDED',
                    color: '#84A100',
                    marginRight: 5
                  }}
                />
                {publishedStoryById?.likes}
              </Col>
              <Col>
                <CommentOutlined
                  style={{
                    padding: 8,
                    borderRadius: 15,
                    backgroundColor: '#EDEDED',
                    color: '#84A100',
                    marginRight: 5
                  }}
                />
                {publishedStoryById?.commentSet.totalCount}
              </Col>
            </>
          )}
        </Row>
      </Col>
      <Divider />
      {window.location.pathname !== ROUTE.storyDetailPreview && (
        <Col lg={20} md={24}>
          {admin ? (
            <>
              <Row>
                <h3 style={{ marginBottom: 0, fontWeight: 'normal' }}>
                  Comments({commentCount})
                </h3>
              </Row>
              <br />
              <br />
              <CommentSection admin={admin} setCommentCount = {setCommentCount}  match={match} navigation={navigation} commentRef={commentRef} />
            </>
          ) : (
            <CommentSection match={match} navigation={navigation} commentRef={commentRef} />
          )}
          <Col lg={20} md={24}>
            <br />
            <SimilarStoryByAuthor
              filter={{ first: 4, author_Icontains: publishedStoryById?.author }}
              author={publishedStoryById?.author}
              hide={publishedStoryById?.id}
            />
          </Col>
        </Col>
      )}
    </>
  );

  return admin ? (
    <CardLayout
      active={ROUTE.story}
      title={[{ name: 'Stories' }]}
      showTitle={false}
      editLink={ROUTE.editStoryLink}
      // addLink={ROUTE.addSurvey}
      showBack={false}
      backLink={ROUTE.story}
      table={false}
      rowKey={publishedStoryById && publishedStoryById?.id}
    // extra={<Button type="primary" /* onClick={() => setAssign(true)} */>Story Assigned</Button>}
    >
      <MetaTags title="Story Details" description={`This is story Details page`} />
      <Row justify="space-between">
        <h3 style={{ fontWeight: 'normal' }}>Content</h3>
      </Row>
      <Spin spinning={loading || actionLoading} size="large">
        <div style={{ minHeight: '70vh' }}>
          <Row style={{ marginTop: '10px' }}>{story}</Row>
        </div>
      </Spin>
    </CardLayout>
  ) : (
    <Row justify="center">
      <MetaTags title="Story Details" description={`This is story Details page`} />
      <Col span={20} style={{ border: '1px solid #EBEBEB', borderRadius: 10, padding: '50px 25px', paddingBottom: 80 }}>
        {/* <Row justify="center">
          <Col span={20}>
            <h2>Stories</h2>
          </Col>
        </Row> */}
        <Spin spinning={loading || actionLoading} size="large">
          <div style={{ minHeight: '70vh' }}>
            <Row style={{ marginTop: '10px' }} justify="center">
              {story}
            </Row>
          </div>
        </Spin>
      </Col>
    </Row>
  );
};

export default StoryDetailView;

export const renderTag = name => (
  <Tag style={{ color: '#84A100', fontWeight: 'bold', padding: '3px 6px', backgroundColor: '#fff' }}>{name}</Tag>
);
