import gql from 'graphql-tag';

export const USER_LEVEL_QUERY = gql`
  query me {
    level: me {
      username
      id
    emp: employee {
      id
      orgPocEmployee {
        edges {
          node {
            id
          }
        }
      }
      orgCeoEmployee {
        edges {
          node {
            id
          }
        }
      }
      verticalHeadEmployee {
        edges {
          node {
            id
          }
        }
      }
      teamManagerEmployee{
        edges{
          node{
            id
          }
        }
      }
    }
  }
  }
`;
