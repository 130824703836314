import gql from 'graphql-tag';

export const CREATE_PARAMETER = gql`
mutation createParameter($parameterData: ParameterInput!){
    createParameter(parameterData:$parameterData){
        parameter{
            id
        }
        
    }
}
`
export const UPDATE_PARAMETER = gql`
mutation updateParameter($id: ID!,$show: String!){
    updateParameter(id:$id,show: $show){
        parameter{
            id
        }
        
    }
}
`
export const DELETE_PARAMETER = gql`
mutation deleteParameter($parameterId: ID!){
    deleteParameter(parameterId:$parameterId){
        parameter{
            id
        }
        
    }
}
`