import gql from "graphql-tag";

export const ALL_OBJECTIVES_PERFORMANCE_NEW_QUERY = gql`
  query contributors(
    $employee: ID
    $offset: Int
    $orderBy: [String]
    $first: Int
    $tz: String
    $savedOkrType: String
    $requestStatus: String
  ) {
    AllCascadingObjectivesPaginated: contributors(
      employee: $employee
      orderBy: $orderBy
      savedOkrType: $savedOkrType
      offset: $offset
      first: $first
      requestStatus:$requestStatus
    ) {
      totalCount
      edgeCount
      edges {
        node {
          id
          progress
          okrType
          okr {
            id
            title(tz: $tz)
            contributorSet {
              edges {
                node {
                  id
                  employee {
                    id
                    user {
                      id
                    }
                  }
                  requestStatus
                }
              }
            }
            title(tz: $tz)
            owner {
              id
              user {
                id
                firstName
                lastName
              }
            }
            duedate
            isDiscarded {
              id
            }
          }
        }
      }
    }
  }
`;

export const NON_CASCADED_OKRS_PERFORMANCE_PAGINATED = gql`
  query contributors(
    $employee: ID
    $offset: Int
    $orderBy: [String]
    $first: Int
    $tz: String
    $savedOkrType: String
  ) {
    AllNonCascadingObjectivesPaginated: contributors(
      employee: $employee
      orderBy: $orderBy
      offset: $offset
      first: $first
      savedOkrType: $savedOkrType
    ) {
      totalCount
      edgeCount
      edges {
        node {
          id
          progress
          okrType
          okr {
            id
            title(tz: $tz)
            contributorSet {
              edges {
                node {
                  id
                  employee {
                    id
                    user {
                      id
                    }
                  }
                  requestStatus
                }
              }
            }
            owner {
              id
              user {
                id
                firstName
                lastName
              }
            }
            duedate
            isDiscarded {
              id
            }
          }
        }
      }
    }
  }
`;

export const ACTION_ITEMS_LIST = gql`
  query allMeetings($member: [ID]) {
    allMeetings(endedAt_Isnull: false, orderBy: ["-endedAt"], member: $member) {
      edges {
        node {
          id
          title
          member {
            edges {
              node {
                id
                user {
                  id
                  firstName
                  lastName
                }
              }
            }
          }
          actionitemSet(first: 1) {
            edges {
              node {
                id
                title
              }
            }
          }
        }
      }
    }
  }
`;

export const UPCOMMING_LATEST_METTING = gql`
  query allMeetings(
    $when_Range: [DateTime]
    $endedAt_Isnull: Boolean
    $orderBy: [String]
  ) {
    allMeetings(
      endedAt_Isnull: $endedAt_Isnull
      orderBy: $orderBy
      first: 1
      when_Range: $when_Range
    ) {
      edges {
        node {
          id
          title
          when
          member {
            edges {
              node {
                id
                user {
                  id
                  firstName
                  lastName
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const COACHING_ENGAGEMENT_DASHBOARD_LISTING_QUERY = gql`
  query allCoachingConversation($question_QuestionAddress: String,$employee: ID) {
    coach:allCoachingConversation(
      orderBy: ["-createdAt"],
      first:5,
      coach:$employee
    ) {
      edges {
        node {
          id
          followUpOn
          actionItems{
            edges{
              node{
                id
                dueDate
                title
                owner{
                  id
                  user{
                    id
                  }
                }
              }
            }
          }

          coachee {
            id
            user {
              id
              firstName
              lastName
              employee {
                id
              }
            }
          }
          coach {
            id
            user {
              id
              firstName
              lastName
              employee {
                id
              }
            }
          }
          response {
            id
            answerSet(question_QuestionAddress: $question_QuestionAddress) {
              edges {
                node {
                  id
                  question {
                    id
                    questionAddress
                  }
                  answer
                }
              }
            }
          }
          createdAt
        }
      }
    }
      coachee:allCoachingConversation(
      orderBy: ["-createdAt"],
      first:5,
      coachee:$employee
    ) {
      edges {
        node {
          id
          followUpOn
          actionItems{
            edges{
              node{
                id
                dueDate
                title
                owner{
                  id
                  user{
                    id
                  }
                }
              }
            }
          }

          coachee {
            id
            user {
              id
              firstName
              lastName
              employee {
                id
              }
            }
          }
          coach {
            id
            user {
              id
              firstName
              lastName
              employee {
                id
              }
            }
          }
          response {
            id
            answerSet(question_QuestionAddress: $question_QuestionAddress) {
              edges {
                node {
                  id
                  question {
                    id
                    questionAddress
                  }
                  answer
                }
              }
            }
          }
          createdAt
        }
      }
    }
  }
`;
export const DASHBOARD_RECIVED_AWARD_LIST = gql`
  query awardLists($recipient: ID, $feedbackTo: ID!, $orderBy: [String]) {
    badges: badgeAwards(recipient: $recipient, first: 5) {
      edges {
        node {
          id
          createdAt
          issuer {
            id
            user {
              id
              firstName
              lastName
            }
          }
          evidence
          expirationDate
          badge {
            id
            image
            badgeFamily {
              id
              title
            }
            title
            behaviouralDescription
          }
        }
      }
    }
    feedbacks: allFeedback(
      feedbackTo: $feedbackTo
      first: 5
      orderBy: $orderBy
    ) {
      edges {
        node {
          id
          createdAt
          feedbackFrom {
            id
            user {
              id
              firstName
              lastName
            }
          }
          response {
            id
            answerSet {
              edges {
                node {
                  id
                  answer
                }
              }
            }
          }
          badgeAward {
            edges {
              node {
                id
                badge {
                  id
                  image
                  title
                  behaviouralDescription
                }
              }
            }
          }
          okr {
            id
            title
          }
          detailFillIn
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const AI_SOUL_TIP = gql`
  query getSoulAiTip($reportUserId: ID!, $other: Boolean!) {
    getSoulAiTip(reportUserId: $reportUserId, other: $other) {
      id
      forOwner
    }
  }
`;
export const SURVEY_360_TOKEN_LIST =gql`
query getAllTokensList($createdBy: ID,$cursor:String,$first:Int,$survey: ID){
  getAllTokensList(createdBy:$createdBy,after:$cursor,first:$first,survey:$survey){
    pageInfo{
      hasNextPage
      endCursor
    }
    edges{
      node{
        id
        user{
          id
          firstName
          lastName
        }
        createdBy{
          id
          firstName
          lastName
        }
        survey{
          id
          name
        }
        link
        rstype
        invited
        valid
        dateCreated
        responseSourceFor{
          id
        }
      }
    }
  }
}
`

export const SURVEY_RESPONSE_FOR_360 =gql`
query surveyResponses($survey_In: [ID],$user_In: [ID],$responseSourceFor_In: [ID]){
  surveyResponses(survey_In:$survey_In,user_In:$user_In,responseSourceFor_In:$responseSourceFor_In){
    pageInfo{
      hasNextPage
      endCursor
    }
    edges{
      node{
        id
        survey{
          id
        }
        responseSourceFor{
          id
        }
        user{
          id
          firstName
        }
        responseDate  
      }
    }
  }
}
`
export const SURVEY_CHECK = gql`
query me($diversitysurvey:ID,$whoposhorosurvey:ID){
    diversity:me{
        id
        responseSet(survey: $diversitysurvey,last:1) {
            totalCount
            edges{
              node{
                id
              }
            }
        }
    }
    whoposhoro:me{
      id
      responseSet(survey: $whoposhorosurvey,last:1) {
          totalCount
          edges{
            node{
              id
            }
          }
      }
  }
}`

export const SOUL_AI_TIP_FOR_OWNER =gql`
subscription soulTipSubscription {
  soulTipSubscription {
     soulTip{
      id
      forOwner
    }
    mutation
  }
}
`