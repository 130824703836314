import gql from 'graphql-tag';
// import { QUESTION_TYPE } from '../../survey/graphql/QuestionType.gql';

//   ${QUESTION_TYPE}
export const WEIGHTED_QUESTION_TYPE = gql`
  fragment WeightedQuestionType on WeightedQuestionType {
    id
    question {
      # ...QuestionType
      id
      questionText
    }
    weight
  }
`;
