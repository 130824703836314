import { Row } from "antd";
import React from "react";
import User_profile from '../../assets/user-profile.svg'
import Call_Made from '../../assets/call-made.svg'
import OkrPerformanceContainer from "modules/Objective-keyresult/formComponents/Container/OkrPerformancePopupContainer";

const MembersList =(props)=>{
    const{TeammemberList,me,dateRangeTofilter}=props
    const[openDetail,setOpenDetail]=React.useState(false)
    const[selectedMember,setSelectedMember]=React.useState()
    const openDetails =(item)=>{
        setOpenDetail(true)
        setSelectedMember(item)
    }

    return (
        <>
           <div className="performamce-overFlow">
                <div className="horizontal-overflow-team-dashboard" style={{width:'100%',position:"relative",border:"none",minHeight:"350px"}}>
                    {TeammemberList?.length>0&&TeammemberList?.map((item)=>(
                        <div className="user-card" onClick={()=>openDetails(item)}>
                            <Row style={{gap:"10px",flexWrap:"nowrap",alignItems:"center",justifyContent:"space-between",width:"100%"}}>
                                <img style={{width:"40px",height:"40px",borderRadius:"50%"}} src={item?.profileImage||User_profile} alt="" />
                                    <div style={{display:'flex',flexDirection:"row",alignItems:'start'}}>
                                        <h4 className="graph-title" style={{maxWidth:'170px',paddingLeft:"0px",fontWeight:"600",textAlign:'left',margin:0,width:'100%'}}>{item?.name}</h4>
                                    {item?.id===me?.employee?.user?.id&&<img src={Call_Made} alt="" style={{marginLeft:"10px",height:"14px"}} />}
                                    </div>
                                <h4 className="member-progress" style={{margin:0,textAlign:"right"}}>
                                    {Number.isNaN(item?.progress)?0:item?.progress}%
                                </h4>
                            </Row>
                        </div>
                    ))}
                </div>
            </div>
            {openDetail&&<OkrPerformanceContainer dateRangeTofilter={dateRangeTofilter} visible={openDetail} {...props} selectedMember={selectedMember} onClose={()=>{setOpenDetail(false);setSelectedMember(null)}}/>}
        </>
    )
}

export default MembersList