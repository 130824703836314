import gql from 'graphql-tag';

export const COMPETENCY_LIST = gql`
query allCompetencies($title_Icontains:String,$competencyType: String,$first: Int,$after: String,$competencyCategory_Isnull:Boolean){
    allCompetencies(title_Icontains:$title_Icontains,competencyType:$competencyType,first:$first,after:$after,competencyCategory_Isnull:$competencyCategory_Isnull){
        pageInfo{
          hasNextPage
          endCursor
        }
        edges{
          node{
            id
            title
            competencyType
            description
            createdAt
            updatedAt
            competencyCategory{
              id
              title
              competencyType
            }
          }
        }
    }
}
`

export const COMPETENCY_SUBSCRIPTION = gql`
  subscription competencySubscriptions{
    competencySubscription {
      mutation
      competency{
        id
        title
        competencyType
        description
        createdAt
        updatedAt
        competencyCategory{
          id
          title
          competencyType
        }
      }
    }
  }
`;

export const DELETE_COMPETENCY = gql`

  mutation deleteCompetency($id: ID!) {
    deleteCompetency(id:$id) {
        competency  {
            id
      }
    }
  }
`;

export const CREATE_COMPETENCY_SKILL = gql`
mutation createCompetency($competencyData: CompetencyInput!){
    createCompetency(competencyData: $competencyData){
    competency{
      id
      title
      competencyType
      description
    }
  }
}
`

export const COMPETENCY_CATEGORY_LIST = gql`
query allCompetencyCategory($title_Icontains:String,$first: Int){
  allCompetencyCategory(title_Icontains:$title_Icontains,first:$first){
    edges{
      node{
        id
        title
        competencyType
      }
    }
  }
}
`

export const COMPETENCY_DETAIL_BY_ID_QUERY = gql`
  query competencyById($id: ID!) {
    competencyById(id: $id) {
      id
      title
      competencyType
      description
      competencyCategory{
        id
        title
        competencyType
      }
    }
  }
  `      

export const COMPETENCY_CATEGORY_BY_ID = gql`
  query competencyCategoryId($id: ID!) {
    competencyCategoryId(id: $id) {
      id
      competencyType
      title
      competencySet {
        edges {
          node {
            id
            title
            competencyType
          }
        }
      }
    }
  }
`;

export const UPDATE_SUB_COMPETENCY_SKILL = gql`
mutation updateCompetency($competencyType: String,$description: String,$id: Int!,$title: String,$competencyCategoryId:Int!){
    updateCompetency(competencyType: $competencyType,description:$description,id:$id,title:$title,competencyCategoryId:$competencyCategoryId){
    competency{
      id
    }
  }
}
`
export const CREATE_COMPETENCY_CATEGORY = gql`
mutation createCompetencyCategory($competencyCategoryData: CompetencyCategoryInput!){
  createCompetencyCategory(competencyCategoryData:$competencyCategoryData){
    competencyCategory{
      id
    }
  }
}
`

export const ALL_COMPETENCY_CATEGORY_QUERY = gql `
query allCompetencyCategory($title_Icontains:String,$first: Int,$after: String){
  allCompetencyCategory(title_Icontains:$title_Icontains,first:$first,after:$after){
      pageInfo{
        hasNextPage
        endCursor
      }
      edges{
        node{
          id
          title
          competencyType
        }
      }
  }
}
`
export const ALL_COMPETENCY_CATEGORY_SUBSCRIPTION = gql `
subscription competencyCategorySubscription{
  competencyCategorySubscription{
     mutation
     competencyCategory{
        id
        title
        competencyType
      }
  }
}
`

export const EDIT_COMPETENCY_CATEGORY =gql `
mutation updateCompetencyCategory($id: Int!,$title: String,$competencyType: String!){
  updateCompetencyCategory(id: $id,title: $title,competencyType:$competencyType){
    competencyCategory{
      id
		}
  }
}
`

