import gql from 'graphql-tag';
import { TEAM_TYPE } from './TeamType.gql';

export const TEAM_SUBSCRIPTION = gql`
  ${TEAM_TYPE}
  subscription teamSubscription {
    teamSubscription {
      mutation
      team {
        ...TeamType
      }
    }
  }
`;
