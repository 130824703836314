import gql from 'graphql-tag';
import { TEAM_TYPE } from './TeamType.gql';

export const TEAM_BY_TEAM_ID_QUERY = gql`
  ${TEAM_TYPE}
  query TeamByTeamId($id: ID!) {
    Team(id: $id) {
      ...TeamType
    }
  }
`;


export const TEAM_BY_TEAM_ID_QUERY_OPTIMIZED = gql`
  
  query TeamByTeamId($id: ID!) {
    Team(id: $id) {
      id
      name
      vertical {
        id
      }
      description
      employees {
        edgeCount
        totalCount
        edges {
          node {
            id
            user {
              id
              firstName
              lastName
            }
          }
        }
      }

      managers {
        edges {
          node {
            id
            user {
              id
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`;


