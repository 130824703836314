import gql from 'graphql-tag';
import { TOOL_TYPE } from './ToolType.gql';

export const ADD_TOOL = gql`
  ${TOOL_TYPE}
  mutation createTool(
    $commitment: [Int]
    $formsection: [Int]
    $organization: [Int]
    $team: [Int]
    $textsection: [Int]
    $vertical: [Int]
    $toolData: ToolInput!
  ) {
    createTool(
      commitment: $commitment
      formsection: $formsection
      organization: $organization
      team: $team
      textsection: $textsection
      vertical: $vertical
      toolData: $toolData
    ) {
      tool {
        ...ToolType
      }
    }
  }
`;
