// @ts-nocheck
import { subscribeToQuery } from '../../look';
import { FEEDBACK_BY_ID_SUBSCRIPTION } from '../graphql/FeedbackById.gql'

export const subscribeToFeedbackById = ( subscribeToMore, variables ) => {
    return subscribeToQuery(
        subscribeToMore,
        {
            document: FEEDBACK_BY_ID_SUBSCRIPTION,
            subscriptionName: 'feedbackSubscription',
            nodeName: 'feedback',
            queryName: 'feedbackById'
        },
        variables
    );
};