import React from 'react';

import { AdminLayout } from '../../look';

import survey_route from '../route';
import TokenFormComponent from './TokenFormComponent';

const AddTokenView = props => {
  return <AdminLayout table={false} active={survey_route.token} title="Add Token" FormComponent={<TokenFormComponent {...props} />} />;
};

export default AddTokenView;
