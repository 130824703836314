// import React from 'react';
// import { graphql } from '@apollo/client/react/hoc';
// import gql from 'graphql-tag';
// import { Empty, Spin } from 'antd';

// import RefereceFieldOperator from '../../../text-section/helpers/helpers';
// import { compose } from '../../../core';
// import { getIntFromString } from '../functions';
// import { withLookRefereceFieldFilterUpdating, withLookRefereceFieldState } from '../../containers/LookOperations';
// import QuestionAutoComplete from './QuestionAutoComplete';

// import SelectField from './SelectField';
// import { Row } from 'antd';
// import { Col } from 'antd';
// import AddIcon from '../AddIcon';

// export const GET_ALL_REFERENCE_FIELD_QUERY = gql`
//   query getAllReferenceFields($first: Int, $orderBy: [String], $question: ID) {
//     getAllReferenceFields(first: $first, orderBy: $orderBy, question: $question) {
// edges {
//   node {
//     id
//     question {
//       id
//       questionText
//     }
//     editable
//   }
// }
//     }
//   }
// `;

// const ReferenceFieldAutoComplete = props => {
//   const { loading, getAllReferenceFields, margin, onAdd, onQuestionChange, onFiltersRemove } = props;
//   const handleFilterRemove = React.useRef(() => {});

//   handleFilterRemove.current = () => {
//     onFiltersRemove();
//   };

//   React.useEffect(() => {
//     return () => handleFilterRemove.current();
//   }, []);

//   const choices =
//     !loading &&
//     getAllReferenceFields &&
//     getAllReferenceFields?.edges.length > 0 &&
//     getAllReferenceFields?.edges?.map(({ node }) => ({
//       label: (
//         <Row justify="space-between" align="middle">
//           <Col span={10} align="left">
//             <span>{node?.question?.questionText}</span>
//           </Col>
//           <Col span={10} align="left">
//             <span>{node?.editable ? 'Editable' : 'Not-editable'}</span>
//           </Col>
//           <Col span={4} align="right">
//             <AddIcon color={'primary'} size="sm" ghost onClick={() => onAdd({ node })} />
//           </Col>
//         </Row>
//       ),
//       value: { node }
//     }));

//   return (
//     <>
//       <QuestionAutoComplete margin={false} label="Question" onChangeSelect={onQuestionChange} />
//       <br />
//       <Spin spinning={loading}>
//         <Row gutter={[24, 24]}>{choices && choices?.map(c => <Col span={24}>{c?.label}</Col>)}</Row>
//       </Spin>
//     </>
//   );
// };

// export default compose(
//   withLookRefereceFieldState,
//   graphql(GET_ALL_REFERENCE_FIELD_QUERY, {
//     options: ({ filter, orderBy }) => {
//       return {
//         variables: { ...filter, first: 3, orderBy }
//       };
//     },
//     props({ data }) {
//       const { loading, error, getAllReferenceFields, subscribeToMore, updateQuery } = data;
//       return { loading, error, getAllReferenceFields, subscribeToMore, updateQuery };
//     }
//   }),
//   withLookRefereceFieldFilterUpdating
// )(ReferenceFieldAutoComplete);

import React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import gql from 'graphql-tag';
import { Empty, Spin } from 'antd';

import { compose } from '../../../core';
import { getIntFromString } from '../functions';
import { withLookRefereceFieldFilterUpdating, withLookRefereceFieldState } from '../../containers/LookOperations';

import SelectField from './SelectField';

export const GET_ALL_REFERENCE_FIELD_QUERY = gql`
  query getAllReferenceFieldsByQuestionTextIcontains($first: Int, $questionTextIcontains: String!) {
    getAllReferenceFieldsByQuestionTextIcontains(first: $first, questionTextIcontains: $questionTextIcontains) {
      edges {
        node {
          id
          question {
            id
            questionText
            group {
              id
              name
              survey {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

const ReferenceFieldAutoComplete = props => {
  const { loading, getAllReferenceFieldsByQuestionTextIcontains, margin, onQuestionTextChange, onFiltersRemove } = props;
  const handleFilterRemove = React.useRef(() => {});

  handleFilterRemove.current = () => {
    onFiltersRemove();
  };

  React.useEffect(() => {
    return () => handleFilterRemove.current();
  }, []);

  const choices =
    !loading &&
    getAllReferenceFieldsByQuestionTextIcontains &&
    getAllReferenceFieldsByQuestionTextIcontains.edges.length > 0 &&
    getAllReferenceFieldsByQuestionTextIcontains.edges.map(({ node }) => ({
      label: `${node?.question?.group?.survey?.name} | ${node?.question?.group?.name} | ${node?.question?.questionText}`,
      value: getIntFromString(node.id)
    }));

  return (
    <SelectField
      margin={margin}
      showSearch
      choices={choices}
      offset={1}
      style={{ width: !margin && '100%', marginBottom: !margin && 0 }}
      // labelInValue
      filterOption={false}
      onSearch={onQuestionTextChange}
      notFoundContent={
        loading ? (
          <div align="center">
            <Spin size="small" />
          </div>
        ) : (
          <Empty />
        )
      }
      {...props}
    />
  );
};

export default compose(
  withLookRefereceFieldState,
  graphql(GET_ALL_REFERENCE_FIELD_QUERY, {
    options: ({ filter, orderBy }) => {
      return { variables: { ...filter, orderBy } };
    },
    props({ data }) {
      const { loading, error, getAllReferenceFieldsByQuestionTextIcontains, subscribeToMore, updateQuery } = data;
      return { loading, error, getAllReferenceFieldsByQuestionTextIcontains, subscribeToMore, updateQuery };
    }
  }),
  withLookRefereceFieldFilterUpdating
)(ReferenceFieldAutoComplete);
