import { Modal } from 'antd';
import React from 'react';

import { withApollo } from '@apollo/client/react/hoc';
import { compose } from '../../core';
import { withDeleteCoachingCommitment } from '../../dashboard/containers/DashboardOperations';
import { getIntFromString } from '../../look';
import { withDeleteCondition } from '../../text-section/containers/TextSectionOperations';
import { TOOL_COACHING_COMMITMENT_SUBSCRIPTION } from '../graphql/ToolCoachingCommitmentSubscription.gql';
import AdminPanelCoachingCommitmentFormComponent from './AdminPanelAddCoachingCommitment';

const AddCommitmentView = props => {
  const { visible, setVisible, title = 'Add Commitment', getCommitmentById, deleteCondition, client, setToolDetails, toolDetails } = props;
  const [data, setData] = React.useState(getCommitmentById?.condition?.edges || 0);
  const [coachingCommitmentData, setCoachingCommitmentData] = React.useState(
    getCommitmentById?.coachingcommitmentSet?.edges?.map(({ node }, index) => ({ node: { ...node, index } })) || 0
  );

  

  let coachingCommitmentSubscription = undefined;
  const m = React.useRef(true)

  const coachingCommitmentDataRef = React.useRef([]);

  React.useEffect(() => {
    if (visible) {
      SubscribeToCoachingCommitment()
      setData(getCommitmentById?.condition?.edges || []);
      setCoachingCommitmentData(getCommitmentById?.coachingcommitmentSet?.edges || []);
    }
    
    
  }, [visible, getCommitmentById,toolDetails]);



  React.useEffect(() => {
    return () => {
      m.current = false
    }
  }, [])

  React.useEffect(() => {
    m.current = true

  }, [])

  React.useEffect(() => {
    return () => {
      if (coachingCommitmentSubscription) {
        coachingCommitmentSubscription.unsubscribe();
      }
    }
  }, [])

  React.useEffect(()=>{

    coachingCommitmentDataRef.current = coachingCommitmentData

  },[coachingCommitmentData])

 

  const getIndexOfCommitment = (result) =>{

    let indexOfCommitment = toolDetails?.commitment?.edges?.findIndex((commitment)=>
    commitment?.node?.id === result.data.toolsCoachingCommitmentSubscription.coachingCommitment?.commitment?.id
    )
   
    
 return indexOfCommitment;
  }


  const SubscribeToCoachingCommitment = async () => {

    coachingCommitmentSubscription = client.subscribe({
      query: TOOL_COACHING_COMMITMENT_SUBSCRIPTION,
      variables: {}
    }).subscribe({
      next(result) {

        switch (result.data.toolsCoachingCommitmentSubscription.mutation) {
          case 'CREATE':
            if (m.current) {
                
              // new set of coaching commitments
                  let updatedCoachingCommitments = [...coachingCommitmentDataRef.current, { node: result.data.toolsCoachingCommitmentSubscription.coachingCommitment }] .filter((commitment) => commitment?.node?.id)
                  let details = {edges:updatedCoachingCommitments};
                  
                  // index of array where the commitmentset of tool commitment has to get changed
                  let indexOfCommitmentToChange = getIndexOfCommitment(result)
                  if(indexOfCommitmentToChange >= 0){
                    let toolInfo = toolDetails;
                    if(toolInfo?.commitment){
                      // update the coachingcommitmentSet with new coachingcommitmentSet
                      toolInfo.commitment.edges[indexOfCommitmentToChange].node.coachingcommitmentSet = details
                    }
                      setToolDetails(toolInfo);
                  }
                
            }
            break;
          case 'UPDATE':
            
            if (m.current) {  
              
              let indexOfCommitment = getIndexOfCommitment(result)
            
               let commitment = toolDetails?.commitment?.edges[indexOfCommitment];
              let indexOfCoachingCommitment = commitment?.node?.coachingcommitmentSet?.edges?.findIndex((coachingCommitment)=>
              
              coachingCommitment?.node?.id === result.data.toolsCoachingCommitmentSubscription.coachingCommitment?.id

              )
             
              let toolInfo = toolDetails;
              if(indexOfCoachingCommitment >= 0){
                toolInfo.commitment.edges[indexOfCommitment].node.coachingcommitmentSet.edges[indexOfCoachingCommitment] = {node: result.data.toolsCoachingCommitmentSubscription.coachingCommitment}
              }
              
              }
                  
            break;
          case 'DELETE':
            
            if (m.current) {
  
              // index of array where the commitmentset of tool commitment has to get changed
               let indexOfCommitmentToChange = getIndexOfCommitment(result)
              
               let updatedCoachingCommitments = coachingCommitmentDataRef.current?.filter((coachingCommitment)=>
                // console.log('coachingCommitment in filter:::::',coachingCommitment,result.data.toolsCoachingCommitmentSubscription.coachingCommitment)
                getIntFromString(coachingCommitment?.node?.id) !== getIntFromString(result.data.toolsCoachingCommitmentSubscription.coachingCommitment?.id)
               
               )
 
               
               let details = {edges:updatedCoachingCommitments};
               
               if(indexOfCommitmentToChange >= 0){
                 let toolInfo = toolDetails;
                 if(toolInfo?.commitment){
                   // update the coachingcommitmentSet with new coachingcommitmentSet
                   toolInfo.commitment.edges[indexOfCommitmentToChange].node.coachingcommitmentSet = details
                 }
                
                   setToolDetails(toolInfo);
                  
               }

            }
            break;
          default:
            break
        }

      }
    })


  }



  async function handleCancel() {
    const ids = [];
    if (data && getCommitmentById?.condition?.edges?.length > 0) {
      data.map(({ node: d }) => {
        const com = getCommitmentById?.condition?.edges?.filter(({ node }) => node.id === d.id);
        if (com.length === 0) ids.push(d.id);
      });
    }

    if (getCommitmentById?.condition?.edges?.length > 0 ? ids.length > 0 : data.length > 0) {
      try {
        (getCommitmentById?.condition?.edges?.length > 0 ? ids : data?.map(({ node }) => node.id)).map(id => {
          deleteCondition({id:getIntFromString(id)});
        });
      } catch (err) {
        throw Error(err.message);
      }
    }
    setVisible(false);
    setData([]);
    setCoachingCommitmentData([]);
  }

  return (
    <Modal
      destroyOnClose
      centered
      visible={props.visible || window.location.href.includes('openmodal')}
      onCancel={handleCancel}
      // footer={null}
      title={title}
      width="75%"
      footer={null}
    >
      <AdminPanelCoachingCommitmentFormComponent
        handleCancel={handleCancel}
        data={data}
        setData={setData}
        coachingCommitmentData={coachingCommitmentData}
        setCoachingCommitmentData={setCoachingCommitmentData}
        setVisible={setVisible}
        {...props}
      />
    </Modal>
  );
};

export default compose(withDeleteCondition, withDeleteCoachingCommitment, withApollo)(AddCommitmentView);
