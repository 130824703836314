import gql from 'graphql-tag';

export const DELETE_BADGE =gql`
    mutation deleteBadge($badgeId: ID!){
        deleteBadge(badgeId: $badgeId){
            badge{
              id
            }
        }
    }
`