import gql from 'graphql-tag';

export const CHILD_OKR_CONTRIBUTOR_WEIGHT_QUERY = gql`
query contributors($createdBy: ID,$okr_Parent: ID){
    contributors(createdBy:$createdBy,okr_Parent:$okr_Parent,role:"OWNER"){
        edges{
            node{
              id
              weight
              role
              okr{
                id
                title
              }
            }
        }
      }
}
`
export const CHILD_OKR_WEIGHT_SUBSCRIPTION = gql`
subscription contributorSubscription {
  contributorSubscription{
      mutation
      contributor{
              id
              weight
              role
              okr{
                id
                title
              }
        }
      }
}
`