import gql from 'graphql-tag';
import { SIMPLE_FIELD_TYPE } from './SimpleFieldType.gql';

export const EDIT_SIMPLE_FIELD = gql`
  ${SIMPLE_FIELD_TYPE}
  mutation editSimpleField(
    $editable: Boolean
    $helpText: String
    $id: ID!
    $inputType: String!
    $label: String
    $sequence: Int
  ) {
    updateSimpleField(
      editable: $editable
      helpText: $helpText
      id: $id
      inputType: $inputType
      label: $label
      sequence: $sequence
    ) {
      simpleField {
        ...SimpleFieldType
      }
    }
  }
`;
