// @ts-nocheck
import React from "react";
import { Row, Col, Button, Spin } from "antd";
import styled from "styled-components";
import { MetaTags, getIntFromString } from "../../look";
import { LeftOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import ReactMarkdown from '../../look/components/ReactMarkdown';
import { displayUtcToUserTimezone } from '../../look/components/functions'

import { FEEDBACK_TOOL_ID, FEEDBACK_COMPRESSED_TOOL_ID } from '../../../config'
import new_back_arrow from '../../assets/new_back_arrow.svg'
import ConfirmationModal from "modules/look-v2/Components/confirmationModal";
import feedback_route from '../route/index'
const FeedbackDetailedView = props => {

  const { history, me, feedbackById, deleteFeedback } = props
  const [loading, setloading] = React.useState(false);
  const [submittedByMe, setsubmittedByMe] = React.useState();
  const [feedBackType, setFeedBackType] = React.useState();
  const [answerList, setanswerList] = React.useState([]);
  const [confirmationModalVisible, setdConfirmationModalVisible] = React.useState(false);

  const feedBackTypeEnum = {
    MOTIVATIONAL_RECOGNITION: "Motivational/Recognition",
    DEVELOPMENTAL_CONSTRUCTIVE: "Developmental/Constructive"
  }

  const back = () => {
    history.goBack();
  }


  React.useEffect(() => {
    if (feedbackById && me) {
      if (me?.employee?.id === feedbackById?.feedbackFrom?.user?.employee?.id) {
        setsubmittedByMe(true)
      }
      else {
        setsubmittedByMe(false)
      }
      let list = feedbackById?.response?.answerSet?.edges?.map(({ node }) => node)
      setanswerList(list)
    }
  }, [feedbackById, me])

  const dashboardData = (
    <Row>
      {
        submittedByMe ?
          <h4>{`Your ${feedBackTypeEnum[feedbackById?.feedbackType]} feedback to  ${feedbackById?.feedbackTo?.user?.firstName}`}
            {feedbackById?.okr ? <span> {`related to ${feedbackById?.okr?.title}`}</span> : ''} </h4>
          : <h4>{`Feedback received from ${feedbackById?.feedbackFrom?.user?.firstName}`}
            {feedbackById?.okr ? <span> {`related to ${feedbackById?.okr?.title}`}</span> : ''} </h4>
      }
    </Row>
  )
  // {
  //   submittedByMe?
  //   <h4>{`Your ${feedbackById?.feedbackType} feedback to  ${feedbackById?.feedbackTo?.user?.firstName}`} 
  //   {feedbackById?.keyResult?<span> {`related to ${feedbackById?.keyResult?.title}`}</span>:''} </h4>
  //   :<h4>{`Feedback received from ${feedbackById?.feedbackFrom?.user?.firstName}`} 
  //   {feedbackById?.keyResult?<span> {`related to ${feedbackById?.keyResult?.title}`}</span>:''} </h4>
  // }

  async function RemoveFeedback(value) {
    setdConfirmationModalVisible(false)
    if (value) {
      try {

        setloading(true)
        const response = await deleteFeedback({ id: getIntFromString(feedbackById?.id) });
        if (response) {
          setloading(false)
          history.push(feedback_route?.feedback?.replace(":type","submitted") )
        }
        else {
          setloading(false)
        }

      }
      catch {
        setloading(false)
      }
    }
  }

  const showData = (item) => {

    let type
    if (feedbackById?.feedbackType === 'MOTIVATIONAL_RECOGNITION') {
      type = '1'
    }
    else if (feedbackById?.feedbackType === 'DEVELOPMENTAL_CONSTRUCTIVE') {
      type = '2'
    }
    let typeValidations = item?.question?.questionAddress.split(':')

    if (typeValidations?.includes(type)) {
      return (
        <ReactMarkdown>{item?.answer}</ReactMarkdown>
      )
    }


  }

  const navigateTo = () => {
    history.push(feedback_route?.feedback?.replace(":type","received"))
  };

  return (
    <>
      <MetaTags
        title="feedback Detail"
        description="This is feedback Detail page."
      />
      <Spin spinning={loading}>
        {/* Page Title and Back Arrow */}
        <div className="main-title-container back-arrow-global" style={{ position: 'relative', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', position: 'relative', width: '100' }}>
          <img className="feedback-grid-item" onClick={navigateTo} src={new_back_arrow} alt="back-arrow" style={{ marginRight: '38%', height: '21%', marginLeft: '0.79em', cursor: 'pointer' }} />
          <p className="goalheader poppins feedback-grid-item" style={{ color: 'black', marginRight: 'auto' }}>View Feedback</p>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {
              submittedByMe && (
                <div>

                  <Button shape="circle" style={{ marginLeft: '20px' }} onClick={() => { history.push(`/role/feedback/update_feedback/${feedbackById?.id}/${FEEDBACK_TOOL_ID}`) }}>
                    <EditOutlined />
                  </Button>
                  <Button shape="circle" type="danger" style={{ marginLeft: '20px' }} onClick={() => setdConfirmationModalVisible(true)}>
                    <DeleteOutlined />
                  </Button>

                </div>
              )
            }
          </div>
        </div>
        {
          feedbackById && (
            <div>
              {
                confirmationModalVisible && (

                  <ConfirmationModal
                    titile={'You are about to delete this Feedback. Are you sure?'}
                    onConfirm={(e) => RemoveFeedback(e)}
                    visible={confirmationModalVisible}
                  />
                )
              }
              <RowDiv style={{ position: 'relative', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <h4 style={{ fontSize: '20px', visibility: 'hidden' }}>View Feedback</h4>

              </RowDiv>
              <RowDiv style={{ background: '#EEEEEE', padding: '15px 25px' }}>
                <div>
                  {dashboardData}
                </div>
                <Row>
                  <h4 style={{ textAlign: 'center' }}>
                    Received On<br />
                    {displayUtcToUserTimezone(feedbackById?.createdAt, "DD MMM")}
                  </h4>
                  <h4 style={{ marginLeft: '30px', textAlign: 'center' }}>
                    Updated On<br />
                    {displayUtcToUserTimezone(feedbackById?.updatedAt, "DD MMM")}
                  </h4>
                </Row>
              </RowDiv>

              <Col style={{paddingLeft:"15px",paddingRight:'15px'}}>
                {
                  answerList?.map((item) =>
                    <div>
                      {!item.question.questionAddress ? (
                        <ReactMarkdown>{item?.answer}</ReactMarkdown>
                      ) :
                        <div>
                          {showData(item)}
                        </div>
                      }
                    </div>
                  )
                }
              </Col>
            </div>
          )

        }
      </Spin>
    </>
  );
};

export default FeedbackDetailedView
const RowDiv = styled(Row)`
display:flex;
justify-content:space-between;
align-items:center;
margin-bottom:20px;
`