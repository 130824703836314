import gql from "graphql-tag"


export const OKR_COMPETENCIES = gql`
query okrById($id:ID!){
    okrById(id: $id){
      id
      okrcompetencySet {
        edges {
          node {
            id
            competency {
              id
              title
              competencyType
            }
          }
        }
      }
    }
  }
`

