import { message } from "antd";
import { graphql } from '@apollo/client/react/hoc';
import { JOB_FAMILY_DELETE } from "../graphql/JobFamilyQuries.gql";






  export const withDeleteJobFamily = Component =>
  graphql(JOB_FAMILY_DELETE, {
    props: ({ mutate}) => ({
      deleteJobFamily: async values => {
        try {
          const {
            data: { deleteJobFamily }
          } = await mutate({
            variables: {
              ...values
            }
          });

          message.destroy();
          message.success('Successfully deleted');
          return true;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
          console.error(e);
        }
      }
    })
  })(Component);

