// @ts-nocheck
import React from "react";
import { Row, Col, Button, Input, Radio, Table, Dropdown, Tooltip, Menu, Spin, Form, Divider, Card, Modal, DatePicker, Tabs, Switch } from "antd";
import start_inactive from '../../assets/start-inactive.svg'
import start_active from '../../assets/start-active.svg'
import Agenda_close from '../../assets/agenda-close.svg'
import { useHistory } from "react-router-dom";
import one_tick from '../../assets/one-on-one-tick.svg'
const EffectiveFeedback = props => {
  const { meetingById, me, setHideButtonPanel, handleOpenFeedBack, Seteffective_feedback, sumitEffectivenessFeedback, setManagerRatingMsg, managerRatingMsg, isInstant,goOneOnOne,setRateOneonOne } = props
  const stars = [1, 2, 3, 4, 5]
  const [oneononeStarCount, setOneononeStarCount] = React.useState(0)
  const [managerStarCount, setmanagerStarCount] = React.useState(0)
  const [sugessions, setsugessions] = React.useState()
  const { TextArea } = Input;

  const history = useHistory();

  React.useEffect(() => {
    if (managerStarCount && oneononeStarCount) {
      setManagerRatingMsg(false)
    }

  }, [managerStarCount, oneononeStarCount])

  const changeSwitch = (checked) => {
    console.log('checked:::',checked);
    if (checked) {
      setRateOneonOne(true)
    }
    else {
      setRateOneonOne(false)
    }
  }

  const selectOneOnOneStar = (value) => {
    setOneononeStarCount(value + 1)
  }

  const selectManagerStar = (value) => {
    setmanagerStarCount(value + 1)
  }

  const handleChangeInSuggestions = (e) => {

    setsugessions(e.target.value)
    if (e.target.value.trim() === '') {
      setManagerRatingMsg(false)

    }

  }





  return (
    <>
      <Row align="middle" justify="center" style={{ marginTop: "10px", marginBottom: '6px' }}>
        
      <h2 className="one-on-one-meeting-popup-head" style={{fontWeight:"600"}}><img src={one_tick} alt="" /> 1:1 Saved Successfully!</h2>

        
        {/* <img src={Agenda_close} alt="" style={{position:"absolute",top:"10px",right:"0px",cursor:"pointer"}} onClick={(e)=>{setHideButtonPanel(false);Seteffective_feedback(false)}} /> */}
        <img src={Agenda_close} alt="" style={{ position: "absolute", top: "36px", right: "20px", cursor: "pointer" }} onClick={(e) => { history.goBack() }} />
      </Row>
      <p className="one-on-one-meeting-popup-head" >Help me make our 1:1s more effective.</p>

      <div align='center' style={{ color: 'red', marginBottom: '10px' }}>(Optional)</div>
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", gap: "10px" }}>
        <Row justify="center" align="center" style={{ gap: "10px" }}><p className="one-on-one-meeting-popup-para">Stay Anonymous:</p>
          <Switch onChange={(e)=>changeSwitch(e)}></Switch>
        </Row>
        <p className="one-on-one-meeting-popup-para">Please rate your 1:1 experience</p>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "10px", flexWrap: "nowrap" }}>
          {stars?.map((item) => (
            <h4 key={item}><img src={start_active} alt="" onClick={() => selectOneOnOneStar(item)} style={{ cursor: "pointer" }} className={item >= oneononeStarCount ? "inactive-rating-star-count" : "inactive-rating-start"} /><img src={start_inactive} className={item >= oneononeStarCount ? "active-rating-star-count" : "active-rating-star"} alt="" style={{ cursor: "pointer" }} onClick={() => selectOneOnOneStar(item)} /></h4>
          ))}
        </div>
        <p className="one-on-one-meeting-popup-para">Please rate your manager’s 1:1 skills.</p>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "10px", flexWrap: "nowrap" }}>
          {stars?.map((item) => (
            <h4 key={item}><img src={start_active} alt="" onClick={() => selectManagerStar(item)} style={{ cursor: "pointer" }} className={item >= managerStarCount ? "inactive-rating-star-count" : "inactive-rating-start"} /><img src={start_inactive} className={item >= managerStarCount ? "active-rating-star-count" : "active-rating-star"} alt="" style={{ cursor: "pointer" }} onClick={() => selectManagerStar(item)} /></h4>
          ))}
        </div>
        <p style={{ color: 'red', visibility: managerRatingMsg ? "visible" : 'hidden',marginBottom: managerRatingMsg ?  '1em' :'0em' }}>Please select the ratings.</p>
        <TextArea className="meeting-rating-text-area" style={{ width: "90%", resize: "none", height: "80px", border: "1px solid #D9D9D9", borderRadius: "8px", marginTop: managerRatingMsg ? '0px' : '-14px'}} placeholder="What can be done better?" onChange={(e) => handleChangeInSuggestions(e)}></TextArea>

        {
          (meetingById?.isInstant || meetingById?.nextMeeting?.totalCount) ?
            <Row justify="center" align="center" style={{ gap: "10px", marginTop: "10px" }}>
              <Button onClick={(e) => { goOneOnOne() }} style={{ padding: '8px 21px' }} className="one-on-one-cancel-btn-modal"> CANCEL</Button>
              <Button onClick={(e) => sumitEffectivenessFeedback(managerStarCount, oneononeStarCount, sugessions, false)} className="one-on-one-save-btn-modal">SUBMIT</Button>
            </Row>
          :
        <Row justify="center" align="center" style={{ gap: "10px", marginTop: "10px" }}>
          <Button  onClick={(e) => sumitEffectivenessFeedback(managerStarCount, oneononeStarCount, sugessions, false)} style={{ padding: '8px 21px' }} className="one-on-one-cancel-btn-modal"> SUBMIT</Button>
          <Button style={{ width: '300px' }} onClick={(e) => sumitEffectivenessFeedback(managerStarCount, oneononeStarCount, sugessions, true)} className="one-on-one-save-btn-modal">SUBMIT & SCHEDULE NEXT 1:1</Button>
        </Row>

        }
      </div>
    </>
  )

}

export default EffectiveFeedback