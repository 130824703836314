import gql from 'graphql-tag';
import { CUSTOM_FORM_SECTION_TYPE_CONNECTION } from '../../../form-section/graphql/Custom_FormSectionTypeConnection.gql';
import { TEXT_SECTION_TYPE_CONNECTION } from '../../../text-section/graphql/TextSectionTypeConnection.gql';

const TOOL_TYPE = gql`
  ${CUSTOM_FORM_SECTION_TYPE_CONNECTION}, ${TEXT_SECTION_TYPE_CONNECTION}
  fragment ToolType on ToolType {
    id
    title
    uiFlow
    thumbnailImage
    description
    status
    public
    pubDate
    textsection {
      ...TextSectionTypeConnection
    }
    formsection {
      # ...FormSectionTypeConnection
      ...Custom_FormSectionTypeConnection
    }
  }
`;



export const ADD_EDIT_TOOL_BY_ID_QUERY = gql`
  ${TOOL_TYPE}
  query getToolById($toolId: ID!) {
    getToolById(toolId: $toolId) {
      ...ToolType
    }
  }
`;
