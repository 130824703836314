import gql from "graphql-tag";

export const AI_TIPS =gql`
query allRunpodtips( 
    $user: ID
    $tipModule:String
    $favourite: Boolean
    $first:Int,
    $after:String
    $createdAt_Range: [DateTime]
    $tipModule_In: [String]
){
  allRunpodtips(
    user: $user
    tipModule:$tipModule
    favourite:$favourite,
    first:$first,
    after:$after,
    createdAt_Range:$createdAt_Range,
    tip_Isnull:false,
    tipModule_In:$tipModule_In
  ){
    pageInfo{
      hasNextPage
      endCursor
    }
    edges{
      node{
        id
        tipModule
        tipContext
        favourite
        createdAt
        tip
        runpodId
        tipFor{
          id
        }
        user{
          id 
        }
      }
    }
  }
}
`

export const CANCEL_AI_TRIGGER = gql`
  mutation cancelRunpodtips($jobId: String!){
    cancelRunpodtips(jobId:$jobId){
      runpodtips{
        id
      }
    }
  }
`

export const SOUL_AI_TIP_TRIGGER =gql`
query getAiSoulTip(
$situation: String
$instruction: String 
$userType: String!
$module: String!
){
  getAiSoulTip(
    situation: $situation
    instruction: $instruction 
    userType: $userType
    module: $module
  ) 
}
`

export const AI_TIP_TRIGGER =gql`
query getTip(
$situation: String
$instruction: String 
$userType: String!
$module: String!
$tipFor:Int
$optype: String!
$aiContext: JSONString
$objectId: ID
){
  getTip(
    situation: $situation
    instruction: $instruction 
    userType: $userType
    module: $module
    tipFor:$tipFor
    optype:$optype
    aiContext:$aiContext
    objectId:$objectId
  ) 
}
`

export const UPDATE_TIP_MUTATION =gql`
mutation updateRunpodtips($favourite: Boolean,$id: ID!){
  updateRunpodtips(favourite:$favourite,id:$id){
    runpodtips{
      id
    }
  }
}
`
export const TIP_SETTINGS =gql`
query tipSettingsByUser($module: String){
tipSettingsByUser(module:$module,first:1){
     edges{
      node{
        id
        module
        active
      }
    }
  }
}`

