import gql from 'graphql-tag';
export const SURVEY_BY_ID = gql`
query SurveyById($id:ID! ){
    SurveyById: SurveyById(id:$id ){
        id
        name
        groupSet {
            edges {
                node {
                    questionSet {
                        edges {
                            node {
                                id
                                choiceSet {
                                    edges {
                                        node {
                                            id
                                            value # use this
                                        }

                                    }


                                }

                            }

                        }

                    }

                }

            }
        }    
    }
}
`