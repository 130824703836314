import gql from 'graphql-tag';

export const LOOK_TOOL_STATE_QUERY = gql`
  query lookToolState {
    lookToolState @client {
      filter {
        title_Icontains
      }
      orderBy
    }
  }
`;
