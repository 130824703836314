import gql from 'graphql-tag';
import { OPERAND_TYPE } from './OperandType.gql';

export const EDIT_OPERAND = gql`
  ${OPERAND_TYPE}
  mutation editOperand($defaultOperandValue: Float, $id: ID!, $note: String, $operation: String, $weightedQuestions: [Int]) {
    updateOperand(
      defaultOperandValue: $defaultOperandValue
      id: $id
      note: $note
      operation: $operation
      weightedQuestions: $weightedQuestions
    ) {
      operand {
        ...OperandType
      }
    }
  }
`;
