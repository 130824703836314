import gql from 'graphql-tag';
import { VERTICAL_TYPE } from './VerticalType.gql';

export const VERTICAL_SUBSCRIPTION = gql`
  ${VERTICAL_TYPE}
  subscription verticalSubscription {
    verticalSubscription {
      mutation
      Vertical {
        ...VerticalType
      }
    }
  }
`;
