import gql from 'graphql-tag';

export const TRACKING_LIST = gql`
query trackings($okr:ID){
    trackings(okr:$okr,orderBy:["progress"]){
        edges{
            node{
                id
                progress
                comment
                updatedAt
                createdAt
            }
        }
    }
}
`