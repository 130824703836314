import { Select, Spin } from 'antd';
import { BARS_HOME_TOOL_ID } from 'config';
import { getIntFromString } from 'modules/look';
import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import '../css/BarsDetail.css';
import { Bars_Details_Subcompetency_Icons, Bars_Detail_Icons } from '../Icon';
import bars_route from '../route';

const SubCompetencyDetailView = (props) => {

    const {
        loading,
        sub_competecny_employee_level,
        getBehaviourAnchorListByIdSubCompetecny,
        subCompetecny_behaviour,
        sub_competecny_loading, navigateRoute } = props
    const { Option } = Select;

    const [selected_sub_competency, setSelectedSubCompetency] = React.useState()
    const [selected_sub_competency_employe, setSelectedSubCompetency_employee] = React.useState()
    const [selectedEmpLevel, setSelectecEmpLevel] = React.useState(undefined);



    React.useEffect(() => {

        window.scrollTo(0, 0);

    }, [])

    React.useEffect(() => {
        if (sub_competecny_employee_level?.sub_competency) {
            setSelectedSubCompetency(sub_competecny_employee_level?.sub_competency[0]?.id)
        }
    }, [sub_competecny_employee_level])

    React.useEffect(() => {
        if (sub_competecny_employee_level?.sub_competency_employee_level) {
            setSelectedSubCompetency_employee(sub_competecny_employee_level?.sub_competency_employee_level[0]?.id)
            setSelectecEmpLevel(sub_competecny_employee_level?.sub_competency_employee_level[0]?.id)
        }
    }, [sub_competecny_employee_level])




    const handleEmpLevelSelection = (e) => {

        setSelectedSubCompetency_employee(e)
        setSelectecEmpLevel(e)

    }

    return (
        <div>
            <Spin spinning={loading}>

                <img src={Bars_Details_Subcompetency_Icons.new_back_arrow} onClick={(e) => navigateRoute(bars_route?.bars_home)} className="create-bar-back-arrow pointer" />


                {/* Main competecny thershold listing */}
                <div className='bar-detail-container-seaction'>



                    {/* Sub competecny thershold listing */}

                    <div style={{ width: "100%" }}>

                        <div className="bar-sub-title-container">
                            <p className="bar-detail-title" style={{ textAlign: "center", fontSize: '1.8em' }}>Sub Competency</p>
                        </div>

                        <div className='bar-detail-container-seaction'>
                            <div className='bar-tab-container-seaction' style={{ width: '100%' }}>
                                <div className='bar-tab-container'>
                                    {

                                        <h4 className='bar-tab-title' style={{ color: '#000' }} >{sub_competecny_employee_level?.sub_competency?.title}</h4>

                                    }
                                </div>
                            </div>

                            {
                                sub_competecny_employee_level?.sub_competency_employee_level?.length > 0 ?
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                        <Select

                                            dropdownClassName="content-dropdown"
                                            className="bar-select-filed bar-type-select"
                                            style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                                            suffixIcon={<img src={Bars_Detail_Icons?.dropdown_Arrow} alt="" style={{ width: '15px' }} />}
                                            value={selected_sub_competency_employe}
                                            onSelect={(e) => handleEmpLevelSelection(e)}
                                            // onSelect={e => {  getBehaviourAnchorListByIdSubCompetecny( { employeeLevel: e, comp_id: selected_sub_competency } ) }}
                                            {...props}
                                        >
                                            {sub_competecny_employee_level?.sub_competency_employee_level?.map(value => (
                                                <Option
                                                    className='rd-select-option'
                                                    key={value?.id}
                                                    value={value?.id}

                                                >
                                                    {value?.name}
                                                </Option>
                                            ))}
                                        </Select>
                                        <Spin spinning={sub_competecny_loading}>
                                            <div className="bar-raing-scale-container">
                                                {subCompetecny_behaviour?.length > 0 ? (subCompetecny_behaviour?.map(item => (
                                                    item?.employeeLevel?.id === selectedEmpLevel &&
                                                    <div className="bar-rating-card-container">
                                                        <div className="bar-rating-card">
                                                            <h4 className='bar-rating-scale-title'>{item?.title}</h4>
                                                            <p className='bar-rating-scale-description'>
                                                                {item?.behaviourExample}
                                                            </p>
                                                        </div>
                                                    </div>
                                                ))) : <div style={{ width: "100%", marginTop: '5em' }}>
                                                    <h4 className='no-data'>No Data Found</h4>
                                                </div>}
                                            </div>
                                        </Spin>
                                    </div>
                                    :
                                    <div style={{ width: "100%", marginTop: '5em' }}>
                                        <h4 className='no-data'>No Data Found</h4>
                                    </div>
                            }


                        </div>
                    </div>
                </div>
            </Spin>
        </div>
    )
}

export default SubCompetencyDetailView;