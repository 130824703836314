import { onAdd, onEdit, onDelete, subscribeToQuery } from '../../look';
import _ from 'lodash';

import { CONTACT_SUBSCRIPTION } from '../graphql/ContactSubscription.gql';
import { FAQ_CATEGORY_SUBSCRIPTION } from '../graphql/FaqSubscription.gql';
import { FAQ_QUESTION_SUBSCRIPTION } from '../graphql/FaqQuestionSubscription.gql';

export const subscribeToFAQPage = (subscribeToMore, variables) => {
  return subscribeToQueryFAQPage(
    subscribeToMore,
    {
      document: FAQ_CATEGORY_SUBSCRIPTION,
      subscriptionName: 'faqCategorySubscription',
      nodeName: 'faqCategory',
      queryName: 'allFaqCategories'
    },
    variables
  );
};

const subscribeToQueryFAQPage = (
  subscribeToMore,
  { document, subscriptionName, nodeName, queryName, dataEdge = true },
  variables
) =>
  subscribeToMore({
    document,
    variables,
    updateQuery: (prev, { subscriptionData: { data } }) => {
      let newResult = prev;
      const subscriptionData = _.get(data, subscriptionName);
      const mutation = _.get(subscriptionData, 'mutation');
      const node = _.get(subscriptionData, nodeName);

      if (node.active) {
        if (mutation === 'CREATE') {
          newResult = onAdd(prev, node, queryName);
        } else if (mutation === 'UPDATE') {
          newResult = onEdit(prev, node, queryName, dataEdge);
        } else if (mutation === 'DELETE') {
          newResult = onDelete(prev, node.id, queryName, dataEdge);
        }
        return newResult;
      }
    }
  });

export const subscribeToFAQCategory = (subscribeToMore, variables) => {
  return subscribeToQuery(
    subscribeToMore,
    {
      document: FAQ_CATEGORY_SUBSCRIPTION,
      subscriptionName: 'faqCategorySubscription',
      nodeName: 'faqCategory',
      queryName: 'allFaqCategories'
    },
    variables
  );
};

export const subscribeToFaqQuestion = (subscribeToMore, variables) => {
  return subscribeToQuery(
    subscribeToMore,
    {
      document: FAQ_QUESTION_SUBSCRIPTION,
      subscriptionName: 'faqQuestionSubscription',
      nodeName: 'faqQuestion',
      queryName: ['faqCatagoryById', 'faqquestionSet']
    },
    variables
  );
};

export const subscribeToContact = (subscribeToMore, variables) => {
  return subscribeToQuery(
    subscribeToMore,
    {
      document: CONTACT_SUBSCRIPTION,
      subscriptionName: 'contactUsSubscription',
      nodeName: 'contactRequest',
      queryName: 'allContactRequests'
    },
    variables
  );
};
