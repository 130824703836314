import React from 'react';
import '../css/global.css';
import { Button, Spin, Tooltip, message } from "antd";
import { useHistory } from 'react-router-dom';
import { scapeHomeIcons } from '../scapeAdminIcons';
import AddParameterGroupContainer from '../containers/addEditParamerterGroupContainer';
import DeleteScapeView from './deleteScapePopup';
import { ReactMarkdown, getIntFromString } from 'modules/look';
import CreateChoiceContainer from '../containers/createChoiceContainer';
import EditChoiceContainer from '../containers/editChoiceContainer';

const ScapeDashboardView = ( props ) => {
    const { scape_list, data_loading, deleteParameter, deleteParametricGroup, deleteScape, getParameterGroupList, parameter_group_list, parameter_list, getParameterList, group_loading, choice_loading } = props;
    const history = useHistory()
    const [ expanded_row, SetExpandedRow ] = React.useState( [] )
    const [ expanded_group, SetExpandedGroup ] = React.useState( [] )
    const [ expand_detail, setExpandDetail ] = React.useState( false )
    const [ create_group, setCreateGroup ] = React.useState()
    const [ selected_group, setSelectedGropup ] = React.useState()
    const [ select_deleted, setSelectedDeleted ] = React.useState()
    const [ create_choice, setCreateChoice ] = React.useState()
    const [ edit_choice, setEditChoice ] = React.useState()
    const expandRow = ( id ) => {
        if ( expanded_row?.includes( id ) ) {
            SetExpandedRow( [] )
        } else {
            SetExpandedRow( [ id ] )
        }
    }

    const expandGroup = ( id ) => {
        if ( expanded_group?.includes( id ) ) {
            SetExpandedGroup( [] )
        } else {
            SetExpandedGroup( [ id ] )
        }
    }

    const DeleteSelected = async () => {
        if ( select_deleted ) {
            let response
            if ( select_deleted?.type == "Scape" ) {
                response = await deleteScape( { scapeId: getIntFromString( select_deleted?.item_id ) } )
            }
            else if ( select_deleted?.type == "Group" ) {
                response = await deleteParametricGroup( { parametricGroupId: getIntFromString( select_deleted?.item_id ) } )
            }
            else if ( select_deleted?.type == "Choice" ) {
                response = await deleteParameter( { parameterId: getIntFromString( select_deleted?.item_id ) } )
            }

            if ( response ) {
                message.success( `Delete selected ${select_deleted?.type} successfully` )
                setSelectedDeleted()
            }
        }
    }
    const parameterListProcess = ( list, group_id ) => {
        if ( list.length > 0 && group_id ) {
            let values = list.filter( item => item?.parametricGroup?.id === group_id )
            var setObj = new Set();
            let parameterData = values.reduce( ( acc, item ) => {
                if ( !setObj.has( item?.id ) ) {
                    setObj.add( item?.id )
                    acc.push( item )
                }
                return acc;
            }, [] )
            return parameterData
        }
        else {
            return []
        }
    }
    return (

        <div className='sa-root'>
            <div className='sa-main-page-container'>
                <div className="scape-heading-container">
                    <img onClick={( e ) => history.push( '/organization/organization/admin-panel' )} style={{ paddingLeft: ".5em", cursor: "pointer" }} src={scapeHomeIcons?.new_back_arrow} alt="" />
                    <h4 className='sa-page-title'>Scape Admin</h4>
                    <div></div>
                </div>

                <div className='sc-main-container-section'>
                    <div className='sc-main-content-container sa-home-page-section'>
                        <Button className='sa-create-btn-large' onClick={() => history.push( '/scape-report-admin/create' )}>Create Scape</Button>
                        <div className='sa-table-container'>
                            <div className='sc-table-header-container'>
                                <h4 className='sa-table-header-label' style={{ fontSize: "1.35em", width: "33.33%", paddingLeft: "1.5em" }}>Scape </h4>
                                <h4 className='rd-table-header-label' style={{ fontSize: "1.35em", width: "33.33%", textAlign: "center" }}>Survey name </h4>
                                <h4 className='sa-table-header-label' style={{ fontSize: "1.35em", width: "33.33%", textAlign: "right", paddingRight: ".5em" }}>Actions</h4>
                            </div>
                            <Spin spinning={data_loading}>
                                {scape_list?.length > 0 && scape_list?.map( item => (
                                    <div className='sa-table-body-container'>
                                        <div className='sa-table-boday-header-section' >
                                            <h4 className={expanded_row?.includes( item?.id ) ? 'sa-table-body-label-head' : 'sa-table-body-label'} style={{ width: "33.33%" }}><img src={scapeHomeIcons?.dropdown_Arrow} alt="" style={{ marginRight: "1em" }} className={`sa-expansion-arrow ${expanded_row?.includes( item?.id ) ? 'sa-open-expansion' : ''}`} onClick={() => { getParameterGroupList( item?.id ); expandRow( item?.id ) }} />{item?.name}</h4>
                                            <h4 className={expanded_row?.includes( item?.id ) ? 'sa-table-body-label-head' : 'sa-table-body-label'} style={{ width: "33.33%", textAlign: "center" }}>{item?.survey?.name}</h4>
                                            <div style={{ display: 'flex', flexDirection: "row", justifyContent: "end", alignItems: "center", gap: "1em", width: "33.33%", paddingRight: "1.5em" }} >
                                                <img className='sa-action-icon' src={scapeHomeIcons?.edit_icon} alt="" onClick={() => history.push( `/scape-report-admin/edit/${getIntFromString( item?.id )}` )} />
                                                <img className='sa-action-icon' src={scapeHomeIcons?.delete_icon} alt="" onClick={() => setSelectedDeleted( { item_id: item?.id, type: "Scape" } )} />
                                                <Tooltip placement="top" title={"Add Group"}>
                                                    <img className='sa-action-icon' src={scapeHomeIcons?.add_icon} alt="" onClick={() => setCreateGroup( item?.id )} />
                                                </Tooltip>

                                            </div>
                                        </div>
                                        {expanded_row?.includes( item?.id ) && <div className='sa-table-body-inner-container'>
                                            {item?.description != "" && <div style={{ background: "#F4F9FD", borderRadius: ".5em", flexDirection: "column", width: "100%" }}>
                                                <div className='sa-table-boday-header-section' >
                                                    <h4 className='sa-table-body-label-head'>Description</h4>
                                                    <img src={scapeHomeIcons?.dropdown_Arrow} alt="" style={{ marginRight: "1em" }} className={`sa-expansion-arrow ${expand_detail ? 'sa-open-expansion' : ''}`} onClick={() => { setExpandDetail( !expand_detail ) }} />
                                                </div>
                                                {expanded_row?.includes( item?.id ) && expand_detail && <div style={{ padding: "1em 2em" }}>
                                                    <p className='sa-table-body-label-des'>{item?.description}</p>
                                                </div>}
                                            </div>}
                                            {parameter_group_list?.length > 0 ? parameter_group_list?.map( group => (
                                                <Spin spinning={group_loading}>
                                                    <div style={{ background: '#F4F9FD', borderRadius: ".5em", marginTop: "1em", paddingBottom: expanded_group?.includes( group?.id ) ? '1em' : '0em', marginBottom: expanded_group?.includes( group?.id ) ? '2em' : '0em' }}>
                                                        <div className='sa-table-boday-header-section' >
                                                            <h4 className='sa-table-body-label-head'><img src={scapeHomeIcons?.dropdown_Arrow} alt="" style={{ marginRight: "1em" }} className={`sa-expansion-arrow ${expanded_group?.includes( group?.id ) ? 'sa-open-expansion' : ''}`} onClick={() => { getParameterList( group?.id ); expandGroup( group?.id ) }} />{group?.name}</h4>
                                                            <div style={{ display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "1em" }}>
                                                                <img className='sa-action-icon' src={scapeHomeIcons?.edit_icon} alt="" onClick={() => { setSelectedGropup( group ); setCreateGroup( item?.id ) }} />
                                                                <img className='sa-action-icon' src={scapeHomeIcons?.delete_icon} alt="" onClick={() => setSelectedDeleted( { item_id: group?.id, type: "Group" } )} />
                                                                <Tooltip placement="top" title={"Add Choice"}>
                                                                    <img className='sa-action-icon' src={scapeHomeIcons?.add_icon} alt="" onClick={() => setCreateChoice( { group_id: group, survey_id: item?.survey?.id, spape_id: item?.id } )} />
                                                                </Tooltip>
                                                            </div>
                                                        </div>
                                                        {expanded_group?.includes( group?.id ) && parameterListProcess( parameter_list, group?.id )?.map( value => (
                                                            <Spin spinning={choice_loading}>
                                                                <div className='sa-table-boday-header-section' style={{ background: "#fff", borderRadius: ".5em", margin: "1em 1em 0em 1em" }}>
                                                                    <p className='sa-table-body-label-head' style={{ marginBottom: "0em" }}><ReactMarkdown style={{ marginBottom: "0em" }}>{value?.choice?.label}</ReactMarkdown></p>
                                                                    <div style={{ display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "1em" }}>
                                                                        <img className='sa-action-icon' src={scapeHomeIcons?.edit_icon} alt="" onClick={() => setEditChoice( { group_name: group?.name, choice_id: value?.id, choice_lable: value?.show, survey_id: item?.survey?.id, scape_id: item?.id } )} />
                                                                        <img className='sa-action-icon' src={scapeHomeIcons?.delete_icon} alt="" onClick={() => setSelectedDeleted( { item_id: value?.id, type: "Choice" } )} />
                                                                    </div>
                                                                </div>
                                                                {parameter_list?.length === 0 && !choice_loading && expanded_group?.includes( group?.id ) && <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: "center", paddingBottom: '1em' }}><h4 className='sa-table-body-label-des'>No Choices Found</h4></div>}
                                                            </Spin>
                                                        ) )}
                                                    </div>
                                                </Spin>
                                            ) ) : !group_loading && expanded_row?.includes( item?.id ) && <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: "center" }}><h4 className='sa-table-body-label-des'>No Groups Found</h4></div>

                                            }
                                        </div>}
                                    </div>
                                ) )}
                            </Spin>

                        </div>
                    </div>
                </div>
            </div>
            {create_group && <AddParameterGroupContainer {...props}
                view_grop_popup={create_group !== undefined ? true : false}
                scape_id={create_group}
                selected_group={selected_group}
                onCancel={() => setCreateGroup()}
            />}
            {select_deleted && <DeleteScapeView
                {...props}
                select_deleted={select_deleted}
                onDelete={() => DeleteSelected()}
                onCancel={() => setSelectedDeleted()} />}
            {create_choice &&
                <CreateChoiceContainer {...props}
                    onCancel={() => { setCreateChoice() }}
                    create_choice={create_choice} />
            }
            {
                edit_choice !== undefined &&
                <EditChoiceContainer {...props}
                    onCancel={() => setEditChoice()}
                    edit_choice={edit_choice}
                />
            }
        </div>
    )
}

export default ScapeDashboardView