import React from 'react';
import styled from 'styled-components';
import { graphql } from '@apollo/client/react/hoc';
import { Input, Divider, Modal, Spin, Col, Row, Button, Switch, List, Tooltip } from 'antd';
import { compose } from '../../core';
import { withApollo } from '@apollo/client/react/hoc';
import { getIntFromString, getOrgListFromTeam, getOrgListFromVer, getSurveyBase64StringFromInt, OrganizationTransfer, removeDuplicate } from '../../look';
import { withAdminPanelEditSurvey, withEditSurvey } from './SurveyOperations';
import { IMG } from '../../../config';
import {
  withOrganizationState,
  withAllOrganization,
  withOrganizationFilterUpdating
} from '../../organization/containers/OrganizationOperation';
import SelectFilterField from '../../look/components/SelectFilterField';
import OrganizationTreeSurveyAssign from './OrganizationTreeSurveyAssign';
import { SURVEY_SUBSCRIPTION } from '../graphql/SurveySubscription.gql';

const SurveyAssign = props => {
  const {
    assign,
    setAssign,
    SurveyById,
    onFiltersRemove,
    editSurvey,
    allOrganization,
    onNameChange,
    onDescriptionChange,
    subscribeToMore,
    onEmailChange,
    client,
    setSurveyById,
    me
  } = props;
  const [visible, setVisible] = React.useState(false);
  const [orgId, setOrgId] = React.useState(0);
  const [assignedOrgs,setAssignedOrgs] = React.useState({});
  const dataRef = React.useRef()
  const m = React.useRef( true )
  const [tree, setTree] = React.useState(false);
  const handleSubmit = async organizations => {
    try {
      await editSurvey({ id: getIntFromString(SurveyById.id), name: SurveyById.name, organizations });
    } catch (e) {
      throw new Error(e.message);
    }
  };

  let surveySub = undefined;

  const surveySubscription = async() =>{
    let id = getSurveyBase64StringFromInt( props.match.params.id );
    surveySub = await client.subscribe( {
      query: SURVEY_SUBSCRIPTION,
      variables: {id},
      fetchPolicy: 'network-only'
    } ).subscribe( {
      next( result ) {
           switch ( result.data.surveySubscription.mutation ) {
  
          case 'UPDATE':
            
            let changedOrgSet = [];
            let changedVerticalSet = [];
            let changedTeamSet = [];
            changedOrgSet = [...result.data.surveySubscription.survey.organization?.edges]
             changedTeamSet = [...result.data.surveySubscription.survey.team?.edges]
             changedVerticalSet = [...result.data.surveySubscription.survey.vertical?.edges]
            let surveyDetails = {...SurveyById};
            
            surveyDetails = {...surveyDetails,organization:{edges:changedOrgSet,edgeCount:result.data.surveySubscription.survey.organization?.edgeCount},
            vertical:{edges:changedVerticalSet,edgeCount:result.data.surveySubscription.survey.vertical?.edgeCount},
            team:{edges:changedTeamSet,edgeCount:result.data.surveySubscription.survey.team?.edgeCount}}
            
            setSurveyById(surveyDetails)
            break;

          default:
              break
        }
      
      }})
  }

  React.useEffect(()=>{
    if(surveySub){
      surveySub.unsubscribe();
    }
  },[])


  React.useEffect( () => {
    return () => {
      m.current = false
    }
  }, [] )

  React.useEffect( () => {
    m.current = true

  }, [] )

  React.useEffect(()=>{
    if(SurveyById){
      
      surveySubscription()
      const assignedOrg = removeDuplicate([
        ...((SurveyById?.organization && SurveyById?.organization?.edgeCount > 0 && SurveyById?.organization?.edges) || []),
        ...((SurveyById?.vertical && SurveyById.vertical?.edgeCount > 0 && getOrgListFromVer(SurveyById.vertical).edges) || []),
        ...((SurveyById?.team && SurveyById.team?.edgeCount > 0 && renderOrgList(getOrgListFromTeam(SurveyById.team)).edges) ||
          [])
      ]);
       
      setAssignedOrgs(assignedOrg)
    }
  },[SurveyById])


  const allVerticals =
    SurveyById && SurveyById.vertical && SurveyById.vertical.edges.map(({ node }) => getIntFromString(node.id));
  const allTeams = SurveyById && SurveyById.team && SurveyById.team.edges.map(({ node }) => getIntFromString(node.id));
  const allOrganizations =
    SurveyById && SurveyById.organization && SurveyById.organization.edges.map(({ node }) => getIntFromString(node.id));
  const renderOrgList = (org, header = false) => (
    <ListScroll
      header={header && <div align="center">Organizations</div>}
      // footer={<div>Footer</div>}
      bordered
      dataSource={(org && org?.edgeCount > 0 && org?.edges.map(({ node }) => node)) || []}
      renderItem={item => (
        <Selectable>
          {/* <OrganizationTree item={item} orgId={getIntFromString(item.id)} /> */}
          <List.Item  onClick={() => { setOrgId(item.id); setTree(true); }}>
            <Row justify="space-between">
              <Col
                type="default"
                ghost
               
              >
                <img src={item?.logo ? item?.logo : IMG} alt="" style={{ width: 40, borderRadius: 30, marginRight: 10 }} /> {`${item.name}`}
              </Col>
            </Row>
          </List.Item>
        </Selectable>
      )}
    />
  );

  
  




  

  return (
    <>
      <Button type="primary" ghost onClick={() => setAssign(true)}>
        Survey Assigned
      </Button>

      <Modal
        destroyOnClose
        visible={assign}
        onCancel={() => setAssign(false)}
        centered
        footer={null}
        title="Assign Survey"
        width={'45%'}
        bodyStyle={{ padding: 16, minHeight: '80%' }}
      >
        {visible ? (
          <>
            <Row wrap={false}>
              <Button type="primary" onClick={() => setVisible(false)}>
              Assigned
              </Button>
            </Row>
            <br />
            {SurveyById && renderOrgList(assignedOrgs, true)}
          </>
        ) : (
          <>
            <Row justify="space-between">
              <Button type="primary" onClick={() => setVisible(true)}>
                All Organizations
              </Button>

              <SelectFilterField
                placeholder="Search Organization by"
                onFiltersRemove={onFiltersRemove}
                options={[
                  {
                    label: 'Name',
                    type: 'text',
                    value: onNameChange
                  },
                  {
                    label: 'Description',
                    type: 'text',
                    value: onDescriptionChange
                  },
                  {
                    label: 'Email',
                    type: 'text',
                    value: onEmailChange
                  }
                ]}
              />
            </Row>
            <br />

            {allOrganization && allOrganization.edgeCount > 0 && (
              <ListScroll
                header={<div align="center">Organizations</div>}
                // footer={<div>Footer</div>}
                bordered
                dataSource={
                  allOrganization &&
                  allOrganization.edgeCount > 0 &&
                  allOrganization.edges
                    .filter(({ node }) => assignedOrgs?.edges?.map(({ node }) => node.id).indexOf(node.id) === -1)
                    .map(({ node }) => node)
                }
                renderItem={item => (
                  <Selectable>
                    {/* <OrganizationTree item={item} orgId={getIntFromString(item.id)} /> */}
                    <List.Item  onClick={() => {
                            setOrgId(item.id);
                            setTree(true);
                          }}>
                      <Row justify="space-between" wrap={false}>
                        <Col
                          type="default"
                          ghost
                         
                        >
                          <img src={item.logo || IMG} alt="" style={{ width: 40, borderRadius: 40, marginRight: 10 }} />{' '}
                          {`${item.name}`}
                        </Col>
                      </Row>
                    </List.Item>
                  </Selectable>
                )}
              />
            )}
          </>
        )}
      </Modal>

      <Modal
        destroyOnClose
        centered
        visible={tree}
        onCancel={() => setTree(false)}
        footer={null}
        title="Survey Assigned"
        width={'80%'}
        bodyStyle={{ padding: 16, maxHeight: '80vh', overflowX: 'scroll' }}
      >
        {tree && (
          <OrganizationTreeSurveyAssign
            orgId={getIntFromString(orgId)}
            surveyId={SurveyById.id}
            surveyName={SurveyById.name}
            allVerticals={allVerticals}
            allTeams={allTeams}
            allOrganizations={allOrganizations}
            editSurvey={editSurvey}
          />
        )}
      </Modal>
    </>
  );
};

const Selectable = styled.div`
  &:hover {
    cursor: pointer;
    background-color: #fffff0;
  }
`;

const ListScroll = styled(List)`
  .ant-list-items {
    max-height: 60vh;
    overflow-y: scroll;
  }
`;
export default compose(
  withOrganizationState,
  withAllOrganization,
  withOrganizationFilterUpdating,
  withAdminPanelEditSurvey,
  withApollo
)(SurveyAssign);
