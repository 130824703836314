// @ts-nocheck
import React from "react";
import { compose } from "modules/core";
import CreateEducationView from "../components/CreateEducationView";
import { withCreateEducation } from "../RoleDefinitionOperation";

const CreateEducationContainer = ( props ) => {
    
    const { permission,client,OnResponse,createEducation,onCancel } = props
 
    React.useEffect(()=>{
      if(permission?.required_permission){

      }
    },[permission])

    const createNewEducation= async(value)=>{
      let educationData ={
        stream: value?.stream,
        degree: value?.degree,
        subject: value?.subject
      }
      let response = await createEducation({educationData})
      if(response){
        OnResponse(response)
      }
    }
    return (
        <>
          <CreateEducationView 
          onSubmit={(value)=>{createNewEducation(value)}}
          onCancel={()=>onCancel()}
          {...props}
          />
        </>
    )
}

export default compose(withCreateEducation)(CreateEducationContainer)