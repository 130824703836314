import React from 'react';
import MainCompetencyHomeContainer from '../containers/MainCompetencyHomeContainer';

const CompetencyMainView = (props) => {

    const { current_comptencymain_tab } = props

    // list all view that are in the role definition
    const views = {
        competencymain_home_view:MainCompetencyHomeContainer,
    }
    const CurrentView = views[ Object.keys(views)?.includes(current_comptencymain_tab?.key)?current_comptencymain_tab?.key:'competencymain_home_view' ]

    return (
        <CurrentView {...props} />
    )

}
export default CompetencyMainView