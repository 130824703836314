import employee_level_active from '../assets/employee_level_active.svg'
import employee_level_inactive from '../assets/employee_level_inactive.svg'
import new_back_arrow from '../assets/new_back_arrow.svg'
import competency_active from '../assets/competency_active.svg'
import competency_inactive from '../assets/competency_inactive.svg'
import role_inactive from '../assets/role_inactive.svg'
import role_active from '../assets/role_active.svg'
import jobfamily_active from '../assets/jobfamily_active.svg'
import jobfamily_inactive from '../assets/jobfamily_inactive.svg'
import ratingscale_active from '../assets/ratingscale_active.svg'
import ratingscale_inactive from '../assets/ratingscale_inactive.svg'
import delete_icon from '../assets/delete-rd.svg'
import table_expand_arrow from '../assets/el-table-arrow.svg'
import upload_icon from '../assets/upload_image_icon.svg'
import file_icon from '../assets/file-icon.svg'
import file_close from '../assets/file-upload-close.svg'
import detail_page_view from '../assets/detail_page_view.svg'
import import_icon from '../assets/import_icon.svg'
import { DeleteFilled } from "@ant-design/icons";
import dropdown_Arrow from '../assets/dropdown_Arrow.svg'
import submit from '../assets/submit.svg';
import cancel from '../assets/cancel.svg';
import drag_icon_threshold from '../assets/drag-icon-threshold.svg'
import jf_modal_close from '../assets/rd-modal-close.svg'
import Home_soul from '../assets/home-icon-soul.svg'
import Right_arow from '../assets/right-arrow-soul.svg'
import edit_role_definition from '../assets/edit_role_definition.svg'
import comp_cancel from '../assets/comp-cancel.svg'
import add_new_competency from  '../assets/add-new-competency.svg'
import sub_competency_active_icon from '../assets/sub_competency_active.svg'
import sub_competency_inactive_icon from '../assets/sub_competency_inactive.svg'
import sidebar_expansion_inactive_icon from '../assets/sb_expansion_inactive_icon.svg'
import sidebar_expansion_active_icon from '../assets/sb_expansion_active_icon.svg'
import cancel_role_definition from '../assets/rd-modal-close.svg'
import employee_active_icon from '../assets/employee_active_icon.svg'
import employee_inactive_icon from '../assets/employee_inactive_icon.svg'
import chip_close_icon from '../assets/chip_close_icon.svg'
import competecny_cancel_icon from '../assets/close_icon_competency.svg'
import competecny_save_icon from  '../assets/save_icon_competency.svg'
import plus from '../assets/plus.svg'
import sidebar_menu from '../assets/role_definition_sidebar_menu.png'
import eligible_delete from '../assets/eligible_delete.svg'
import eligible_edit from '../assets/eligible_edit.svg'

export const sidebar_icons ={
    employee_level_active,
    employee_level_inactive,
    competency_active,
    competency_inactive,
    role_inactive,
    role_active,
    jobfamily_inactive,
    jobfamily_active,
    ratingscale_active,
    ratingscale_inactive,
    sub_competency_active_icon,
    sub_competency_inactive_icon,
    sidebar_expansion_inactive_icon,
    sidebar_expansion_active_icon,
    employee_active_icon,
    employee_inactive_icon
}

export const home_container_icons = {
    new_back_arrow,
    Home_soul,
    Right_arow,
    sidebar_menu
}

export const employee_level_icons = {
    delete_icon,
    table_expand_arrow
}

export const competency_icons ={
    upload_icon,
    file_icon,
    file_close,
    delete_icon,
    detail_page_view,
    import_icon,
    dropdown_Arrow,
    jf_modal_close,
    comp_cancel,
    add_new_competency,
    edit_role_definition,
    table_expand_arrow,
    competecny_cancel_icon,
    competecny_save_icon
}

export const threshold_icons = {
    delete_icon,
    table_expand_arrow,
    DeleteFilled,
    submit,
    cancel,
    drag_icon_threshold
}

export const jobfamily_icons ={
    delete_icon,
    detail_page_view,
    jf_modal_close,
    table_expand_arrow,
    edit_role_definition,
    cancel_role_definition,
}

export const role_icons ={
    delete_icon,
    detail_page_view,
    edit_role_definition,
    cancel_role_definition,
    new_back_arrow,
    dropdown_Arrow,
    plus,
    table_expand_arrow,
    import_icon,
    eligible_delete,
    eligible_edit
}

export const employee_icons ={
    delete_icon,
    detail_page_view,
    dropdown_Arrow,
    chip_close_icon
}