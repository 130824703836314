// @ts-nocheck
import { DownCircleOutlined, MenuOutlined, RightCircleOutlined } from '@ant-design/icons';
import { withApollo } from '@apollo/client/react/hoc';
import { Divider, Table } from 'antd';
import arrayMove from 'array-move';
import React from 'react';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import styled from 'styled-components';
import { compose } from '../../core';
import {
  AddIcon,
  DeleteIcon, displayDataCheck, EditIcon, GetColumnSearchProps, getIntFromString, getOrderBy, ReactMarkdown
} from '../../look';
import { QUESTION_SUBSCRIPTION } from '../graphql/QuestionSubscription.gql';
import { SURVEY_QUESTION_GRPOUP_SUBSCRIPTION } from '../graphql/SurveyQuestionGroupSubscription.gql';
import ROUTE from '../route';

import QuestionTable from './QuestionTable';
import {
  withDeleteGroup, withEditGroup, withSurveyFilterUpdating, withSurveyState
} from './SurveyOperations';
import { subscribeToGroup } from './SurveySubscriptions';

const Group = props => {
  const {
    loading,
    allGroupsForSurveyBySurveyId,
    onPaginationChange,
    match,
    deleteGroup,
    onNameChange,
    onLabelChange,
    onOrderByChange,
    orderBy,
    // onHelpTextChange,
    onSequenceChange,
    onFiltersRemove,
    subscribeToMore,
    editGroup,
    SurveyById,
    me,
    client
  } = props;
  const [sequenceLoading, setSequenceLoading] = React.useState(false);
  const [allowDraggable, setAllowDraggable] = React.useState(true);
  const [dataSource, setDataSource] = React.useState([]);
  const components = true;



  const surveyGroupDataRef = React.useRef(null);
  


  let groupSubscription = undefined;
  const m = React.useRef(true)


  React.useEffect(()=>{

    if(me){
      SubscribeToGroup();
      SubscribeToQuestion();
    }

  },[me])





  React.useEffect(() => {
    return () => {
      m.current = false
    }
  }, [])

  React.useEffect(() => {
    m.current = true

  }, [])


  React.useEffect(() => {
    return () => {
      if (groupSubscription) {
        groupSubscription.unsubscribe();
      }
    }
  }, [])
 


  const SubscribeToGroup = () => {

    groupSubscription = client.subscribe({
      query: SURVEY_QUESTION_GRPOUP_SUBSCRIPTION,
    }).subscribe({
      next(result) {

        switch (result.data.surveyQuestionGroupSubscription.mutation) {
         
          case 'DELETE':
           
            if (m.current) {
            
              let groupSet;
                groupSet = surveyGroupDataRef?.current;
                let groupSetWithDeletedRecord = groupSet?.filter((group)=>
                group?.id !== result.data.surveyQuestionGroupSubscription?.group?.id
                )
              
                setDataSource(groupSetWithDeletedRecord);
                surveyGroupDataRef.current = groupSetWithDeletedRecord;

            }

            break;
          default:
            break
        }

      }
    })

  }


  
  
  const SubscribeToQuestion = () => {

    groupSubscription = client.subscribe({
      query: QUESTION_SUBSCRIPTION,
    }).subscribe({
      next(result) {

        switch (result.data.surveyQuestionSubscription.mutation) {
         
          case 'DELETE':
           
            if (m.current) {
              let indexOfUpdatedGroup = surveyGroupDataRef?.current.findIndex((value)=> value?.node?.id === result.data.surveyQuestionSubscription?.id)
              if(indexOfUpdatedGroup >= 0){
                let updatedGroup = surveyGroupDataRef?.current[indexOfUpdatedGroup];
              
                let groupWithDeletedQuestion = updatedGroup?.questionSet?.edges?.filter((question)=>
                question?.node?.id !== result.data.surveyQuestionSubscription?.question?.id
                )
               
                updatedGroup.questionSet = {edgeCount:(surveyGroupDataRef?.current[indexOfUpdatedGroup]?.questionSet?.edgeCount - 1 ),totalCount:(surveyGroupDataRef?.current[indexOfUpdatedGroup]?.questionSet?.totalCount - 1 ),edges:groupWithDeletedQuestion};
               
                surveyGroupDataRef.current[indexOfUpdatedGroup] = updatedGroup;
              
                setDataSource(surveyGroupDataRef?.current);
              }
            
            }

            break;
          default:
            break
        }

      }
    })

  }






  React.useEffect(() => {
    if (components) {
      const edges = [];
      SurveyById?.groupSet &&
        SurveyById.groupSet.edges.length > 0 &&
        SurveyById.groupSet.edges.map(({ node }, index) => {
          edges.push({ ...node, index: index + 1 });
        });
      surveyGroupDataRef.current = edges
      setDataSource(edges);
    } else {
      surveyGroupDataRef.current = SurveyById;
      setDataSource(SurveyById);
    }
  }, [components, SurveyById]);

  const handleDelete = async id => {
    setSequenceLoading(true);
    try {
      await deleteGroup({id:id});
    } catch (e) {
      throw new Error(e.message);
    }
    setSequenceLoading(false);
  };

  const onSort = async sortedItems => {
    setSequenceLoading(true);
    try {
      await Promise.all(
        sortedItems.map(async i => {
          // const response = await
          editGroup({ id: getIntFromString(i.id), name: i.name, sequence: i.index + 1 });
        })
      );
      setSequenceLoading(false);
    } catch (e) {
      setSequenceLoading(false);
      throw Error(e.message);
    }
  };

  const DragHandle = sortableHandle(() => (
    <MenuOutlined style={{ cursor: 'grab', color: '#999', margin: '-16px', padding: '16px' }} />
  ));

  const columns = [
    {
      title: 'Sort',
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => <DragHandle />
    },
    {
      title: 'Name',
      key: 'name',
      className: 'drag-visible',
      sorter: () => onOrderByChange(getOrderBy('name', orderBy)),
      ...GetColumnSearchProps('name', onNameChange),
      render: (text, record) => record && <div>{displayDataCheck(record.name)}</div>  
    },
    {
      title: 'Label',
      key: 'label',
      sorter: () => onOrderByChange(getOrderBy('label', orderBy)),
      ...GetColumnSearchProps('label', onLabelChange),
      render: (text, record) => record && <div><ReactMarkdown style={{marginTop:'1em'}}>{displayDataCheck(record.label)}</ReactMarkdown></div> 
    },
    {
      title: 'Link',
      key: 'link',
      align: 'right',
      render: (text, record) => (
        <div style={{width:'30px'}}>
          <AddIcon
            type="link"
            size="sm"
            tooltipSuffix="Question"
            /* target="_blank" */ href={`${ROUTE.addQuestionLink}${getIntFromString(record.id)}`}
          />
          <Divider type="vertical" />
          <EditIcon
            type="link"
            tooltipSuffix="Group"
            size="sm"
            /* target="_blank" */ href={`${ROUTE.editGroupLink}${getIntFromString(record.id)}`}
          /> 
          <Divider type="vertical" />
          <DeleteIcon size="sm" tooltipSuffix="Group" onClick={() => handleDelete(getIntFromString(record.id))} />
        </div>
      )
    }
  ];

  const rowKey = 'index';
  const SortableItem = sortableElement(props => <tr {...props} />);
  const SortableContainer = sortableContainer(props => <tbody {...props} />);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMove([].concat(dataSource), oldIndex, newIndex).filter(el => !!el);
      const indexData = [];
      newData.map((node, index) => {
        const data = newData[index];
        indexData.push({ ...data, index: index });
      });
      onSort && onSort(indexData);
      setDataSource(indexData);
    }
  };

  const DraggableContainer = props => (
    <SortableContainer useDragHandle disableAutoscroll helperClass="row-dragging-group" onSortEnd={onSortEnd} {...props}/>
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = dataSource && dataSource.findIndex(x => x.index === restProps['data-row-key']);
    return <SortableItem style={{border:"1px solid #f5f5f5",borderRadius:'5px',width:"100%",justifyContent:"space-between"}}  index={index} {...restProps} />;
  };

  const dragable = allowDraggable
    ? {
        components: {
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow
          }
        }
      }
    : {};

    console.log('dataSource::::',dataSource);

  return (
    <>
      <MyTable
        size={!(SurveyById && SurveyById.groupSet && SurveyById && SurveyById.groupSet.totalCount) && 'middle'}
        loading={loading || sequenceLoading}
        rowKey={record => (rowKey === 'id' ? getIntFromString(record[rowKey]) : record[rowKey])}
        showHeader={false}
        dataSource={
          dataSource
            ? components
              ? dataSource.sort((a, b) => (a.sequence < b.sequence ? -1 : a.sequence > b.sequence ? 1 : 0))
              : dataSource.edges && dataSource.edges.map(({ node }) => node)
            : []
        }
        // bordered
        columns={columns.map(c => ({ ...c, sortDirections: ['descend', 'ascend', 'descend'] }))}
        expandable={{
          expandedRowRender: (record, index, indent, expanded) => (
            <QuestionTable
              record={record}
              index={index}
              indent={indent}
              expanded={expanded}
              setSequenceLoading={setSequenceLoading}
            />
          ),
          expandIcon: ({ expanded, onExpand, record }) =>
            record.questionSet.edgeCount !== 0 ? (
              expanded ? (
                <DownCircleOutlined
                  onClick={e => {
                    setAllowDraggable(true);
                    onExpand(record, e);
                  }}
                  style={{ fontSize: 18, display: 'flex', alignSelf: 'center', color: '#CACACA' }}
                />
              ) : (
                <RightCircleOutlined
                  onClick={e => {
                    setAllowDraggable(false);
                    onExpand(record, e);
                  }}
                  style={{ fontSize: 18, display: 'flex', alignSelf: 'center', color: '#CACACA' }}
                />
              )
            ) : null
        }}
        {...dragable}
      />
    </>
  );
};

export default compose(withSurveyState, withDeleteGroup, withEditGroup, withSurveyFilterUpdating, withApollo)(Group);

export const MyTable = styled(Table)`
  table {
    border-spacing: 0 1em;
    tbody {
      tr {
        td {
          border: 1px solid rgba(0, 0, 0, 0.06) !important;
          border-style: solid none !important;
        }
        td:first-child {
          border-left-style: solid !important;
          border-top-left-radius: 5px !important;
          border-bottom-left-radius: 5px !important;
        }
        td:last-child {
          border-right-style: solid !important;
          border-bottom-right-radius: 5px !important;
          border-top-right-radius: 5px !important;
          border-bottom: 1px solid rgba(0, 0, 0, 0.06) !important;
        }
      }
    }
  }
`;
