import gql from "graphql-tag";
import { CHOICE_TYPE_CONNECTION } from "./ChoiceTypeConnection.gql";

export const QUESTION_TYPE = gql`
  ${CHOICE_TYPE_CONNECTION}
  fragment QuestionType on QuestionType {
    id
    questionText
    required
    helpText
    sequence
    questionAddress
    group {
      id
      survey {
        id
      }
      # ...GroupType
    }
    answerSet {
      edges {
        node {
          id
          question {
            id
            questionText
          }
          answer
          response {
            id
            user {
              id
              username
            }
          }
        }
      }
    }
    pubDate
    required
    choiceSet {
      ...ChoiceTypeConnection
    }
  }
`;


export const QUESTION_TYPE_VALUES = gql`
  ${CHOICE_TYPE_CONNECTION}
  fragment QuestionType on QuestionType {
    id
    questionText
    required
    helpText
    sequence
    group {
      id
      survey {
        id
      }
      # ...GroupType
    }
    answerSet(response_Survey:$id,response_User: $responseUser,first:1) {
      edges {
        node {
          id
          question {
            id
            questionText
          }
          answer
          sequence
          response {
            id
            user {
              id
              username
            }
          }
        }
      }
    }
    pubDate
    required
    choiceSet {
      ...ChoiceTypeConnection
    }
  }
`;