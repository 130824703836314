import gql from "graphql-tag";

export const GET_TEAM_MANAGER = gql`
  query me {
      getTeamManager : me {
    employee {
      teamManagerEmployee {
        edges {
          node {
            id
            name
            employees {
              edges {
                node {
                  id
                  user {
                    id
                    firstName
                    lastName
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  }
`;
