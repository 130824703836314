import gql from 'graphql-tag';
import { COMMENT_TYPE } from './CommentType.gql';

export const COMMENT_TYPE_CONNECTION = gql`
  ${COMMENT_TYPE}
  fragment CommentTypeConnection on CommentTypeConnection {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...CommentType
      }
    }
    totalCount
    edgeCount
  }
`;
