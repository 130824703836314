import React from 'react';
import { Modal,Button,Divider,Table } from "antd";
import styled from "styled-components";
import { getIntFromString, ReactMarkdown } from "../../look";
import { RightCircleOutlined, MenuOutlined, DownCircleOutlined } from '@ant-design/icons';
import SurveyChildExpansion from './SurveyChildExpansion';

const SurveySelectorView = props => {
    const {closeSelector,identifier,NavigateToSurvey}=props
    const [ dataSource, setDataSource ] = React.useState( [] );
    const [ expandedRecord, setexpandedRecord ] = React.useState( [] );

    React.useEffect(()=>{
      if(identifier?.sourceData){
        let list = identifier?.sourceData.map(item=>{
          return {
            id:item?.id,
            name:item?.name,
            survey:item?.survey,
            key:getIntFromString(item?.id)
          }
        })
        setDataSource(list)
      }
    },[identifier])

    const columns = [
      // onClick={()=> history.push(`/okr/edit_objective/${record.id}`)}
      {
        title: <h4 >Name</h4>,
        key: 'Name',
        render: ( text, record ) => record &&
         <ReactMarkdown>{record?.name}</ReactMarkdown>
      },
      
    ];
    
    return (
        <>
           <Modal
             visible={identifier} 
             footer={false}
             closable={false}  
             width='60%'
           >
            <Divider>
              <Heading>{identifier?.title||'Select your survey'}</Heading>
             </Divider>
             <MyTable
        size={'middle'}
        showHeader={true}
        expandedRowKeys={expandedRecord}
        pagination={false}
        dataSource={
          dataSource
            ? dataSource.map( ( item ) => item )
            : []
        }
        // bordered
        columns={columns.map( c => ( { ...c, sortDirections: [ 'descend', 'ascend', 'descend' ] } ) )}
        expandable={{
          expandedRowRender: ( record, index, indent, expanded ) => (
            <SurveyChildExpansion
              record={record}
              index={index}
              indent={indent}
              expanded={expanded}
              selectSurvey={(e)=>{NavigateToSurvey(e)}}
            />
          ),
          expandIcon: ( { expanded, onExpand, record } ) =>
            record?.survey.edges?.length !== 0 ? (
              expanded ? (
                <DownCircleOutlined
                  onClick={e => {
                    onExpand( record, e ); setexpandedRecord( [] );
                  }}
                  style={{ fontSize: 18, display: 'flex', alignSelf: 'center', color: '#CACACA' }}
                />
              ) : (
                <RightCircleOutlined
                  onClick={e => {
                    onExpand( record, e ); setexpandedRecord( [ record?.key ] );
                  }}
                  style={{ fontSize: 18, display: 'flex', alignSelf: 'center', color: '#CACACA' }}
                />
              )
            ) : null
        }}

      />
            {/* <div align="center">
            <TermButton  onClick={(e)=>{closeSelector()}}>Cancel</TermButton>
            </div> */}
            </Modal> 
        </>
      )

}
export default SurveySelectorView;

const TermButton = styled(Button)`
  width:120px;
  margin:10px
`;
const Heading = styled.h3`
 font-size:18px,
 text-align:center,
 white-space:nowrap
`;

const MyTable = styled( Table )`
  table {
    border-spacing: 0 1em;
    tbody {
      tr {
        td {
          border: 1px solid rgba(0, 0, 0, 0.06) !important;
          border-style: solid none !important;
        }
        td:first-child {
          border-left-style: solid !important;
          border-top-left-radius: 5px !important;
          border-bottom-left-radius: 5px !important;
        }
        td:last-child {
          border-right-style: solid !important;
          border-bottom-right-radius: 5px !important;
          border-top-right-radius: 5px !important;
          border-bottom: 1px solid rgba(0, 0, 0, 0.06) !important;
        }
      }
    }
  }
`;