import gql from 'graphql-tag';

export const LOOK_ORGANIZATION_STATE_QUERY = gql`
  query lookOrganizationState {
    lookOrganizationState @client {
      filter {
        name_Icontains
      }
      orderBy
    }
  }
`;
