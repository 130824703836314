import React from 'react';
import { Button, Spin } from 'antd'
import managing_performance_route from 'modules/competency-assessment/route';
import { getIntFromString } from 'modules/look';

const UserCompetencyAssessment = (props) => {
    const { assessment,navigateRoute} = props
    return (
        <>
            {
                assessment ?
                    assessment?.id ?
                        <div className="cs-user-progress-container">
                            {assessment?.functional_assessment &&
                                (<div className="cs-employe-progress-view" style={{ border: `1px solid ${assessment?.functional_assessment?.border}`, background: assessment?.functional_assessment?.background }}>
                                    <p className="cs-label">Functional competency</p>
                                    <h4 className="cs-employee-progress-status" style={{ color: assessment?.functional_assessment?.color }}>{assessment?.functional_assessment?.qualifyingLevelName}</h4>
                                </div>)
                            }
                            {assessment?.generic_assessment && (
                                <div className="cs-employe-progress-view" style={{ border: `1px solid ${assessment?.generic_assessment?.border}`, background: assessment?.generic_assessment?.background }}>
                                    <p className="cs-label">Generic Competencies</p>
                                    <h4 className="cs-employee-progress-status" style={{ color: assessment?.generic_assessment?.color }}>{assessment?.generic_assessment?.qualifyingLevelName}</h4>
                                </div>
                            )
                            }
                            {assessment?.other_assessment && (
                                <div className="cs-employe-progress-view" style={{ border: `1px solid ${assessment?.other_assessment?.border}`, background: assessment?.other_assessment?.background }}>
                                    <p className="cs-label">Other Competencies</p>
                                    <h4 className="cs-employee-progress-status" style={{ color: assessment?.other_assessment?.color }}>{assessment?.other_assessment?.qualifyingLevelName}</h4>
                                </div>
                            )}
                            {
                                assessment?.id && (
                                    <div align='center'>
                                        <Button className='green-btn detail-btn' onClick={()=>{navigateRoute(`${managing_performance_route?.user_competency_assessment_detail?.replace(":id",getIntFromString( assessment?.user_details?.employee_id ))}`)}}>See Detail</Button>
                                    </div>
                                )
                            }

                        </div>
                        : <div style={{display:'flex',justifyContent:"center",alignItems:"center",marginTop:"3em"}}>
                            <h4 className='no-data-profile'>No Data Found</h4>
                        </div> :
                    <div align="center" style={{ width: "100%", height: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Spin spinning={true} ></Spin>
                    </div>
            }

        </>
    )
}

export default UserCompetencyAssessment
