import React from "react";
import { compose } from "../../core";

import MAppAnchorsView from "../components/MAppAnchorsView";
import {
  withReportingTeamsMAppAnchorsFilterUpdating,
  withReportingTeamsMAppAnchorsState,
  withTeamsMAppAnchors,
} from "./ManagerOperations";

const TeamsMAppAnchors = (props) => {
  const { reportingTeamsMAppAnchors } = props;

  return (
    <MAppAnchorsView
      MAppAnchors={
        reportingTeamsMAppAnchors && JSON.parse(reportingTeamsMAppAnchors)
      }
      {...props}
    />
  );
};

export default compose(
  withReportingTeamsMAppAnchorsState,
  withReportingTeamsMAppAnchorsFilterUpdating,
  withTeamsMAppAnchors
)(TeamsMAppAnchors);
