import * as React from 'react';

const TermsAndConditions = () => {
  return (
    <>
      <h1>Terms and conditions</h1>
      <h2>Introduction</h2>
      <h4>
        These Website Standard Terms and Conditions written on this webpage shall manage your use of our website
        www.brainayan.com and all other sites hosted on this domain.
        <br />
        <br /> These Terms will be applied fully and affect to your use of this Website. By using this Website, you agreed to
        accept all terms and conditions written in here. You must not use this Website if you disagree with any of these
        Website Standard Terms and Conditions.
        <br />
        <br /> Minors or people below 18 years old are not allowed to use this Website.
      </h4>
      <br />
      <h2>Intellectual Property Rights</h2>
      <h4>
        Other than the content you own, under these Terms, Brainayan and/or its licensors own all the intellectual property
        rights and materials contained in this Website.
        <br />
        <br /> You are granted limited license only for purposes of viewing the material contained on this Website. You are
        voluntarily providing your information to us. Brainayan will use the data provided for analysis. The data provided
        will not be personally identifiable.
      </h4>
      <br />
      <h2>Restrictions</h2>
      <h4>
        You are specifically restricted from all of the following.
        <br />
        <br /> 1. Publishing any Website material in any other media.
        <br /> 2 Selling, sublicensing and/or otherwise commercializing any Website material. <br />
        3. Publicly performing and/or showing any Website material.
        <br /> 4. Using this Website in any way that is or may be damaging to this Website. <br />
        5. Using this Website in any way that impacts user access to this Website.
        <br /> 6. Using this Website contrary t o applicable laws and regulations, or in any way may cause harm to the
        Website, or to any person or business entity. <br />
        7. Engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to this
        Website.
        <br /> 8. Using this Website to engage in any advertising or marketing.
        <br />
        <br /> Certain areas of this Website are restricted from being access by you and Brainayan may further restrict
        access by you to any areas of this Website, at any time, in absolute discretion. Any user ID and password you may
        have for this Website are confidential and you must maintain confidentiality as well.
      </h4>
      <br />
      <h2>Your Content</h2>
      <h4>
        In these Website Standard Terms and Conditions, &quot;Your Content&quot; shall mean any audio, video text, Surveys,
        images or other material you choose to display on this Website. By displaying Your Content, you grant Brainayan a
        non-exclusive, worldwide irrevocable, sub licensable license to use, reproduce, adapt, publish, translate and
        distribute it in any and all media.
        <br />
        <br />
        Your Content must be your own and must not be invading any third-party’s rights. Brainayan reserves the right to
        remove any of Your Content from this Website at any time without notice.
      </h4>
      <br />
      <h2>Your Privacy</h2>
      <h4>
        Please read<a href="//#endregion"> Privacy Policy</a>
      </h4>
      <br />
      <h2>No Warranties</h2>
      <h4>
        This Website is provided &quot;as is,&quot; with all faults, and Brainayan express no representations or warranties,
        of any kind related to this Website or the materials contained on this Website. Also, nothing contained on this
        Website shall be interpreted as advising you. The data provided by you will not be personally identifiable and the
        data will be used for analysis.
      </h4>
      <br />
      <h2>Limitation of liability</h2>
      <h4>
        In no event shall Brainayan, nor any of its officers, directors and employees, shall be held liable for anything
        arising out of or in any way connected with your use of this Website whether such liability is under contract.
        Brainayan, including its officers, directors and employees shall not be held liable for any indirect, consequential
        or special liability arising out of or in any way related to your use of this Website.
      </h4>
      <br />
      <h2>Indemnification</h2>
      <h4>
        You hereby indemnify to the fullest extent Brainayan from and against any and/or all liabilities, costs, demands,
        causes of action, damages and expenses arising in any way related to your breach of any of the provisions of these
        Terms.
      </h4>
      <br />
      <h2>Severability</h2>
      <h4>
        If any provision of these Terms is found to be invalid under any applicable law, such provisions shall be deleted
        without affecting the remaining provisions herein.
      </h4>
      <br />
      <h2>Variation of Terms</h2>
      <h4>
        Brainayan is permitted to revise these Terms at any time as it sees fit, and by using this Website you are expected
        to review these Terms on a regular basis.
      </h4>
      <br />
      <h2>Assignment</h2>
      <h4>
        The Brainayan is allowed to assign, transfer, and subcontract its rights and/or obligations under these Terms without
        any notification. However, you are not allowed to assign, transfer, or subcontract any of your rights and/or
        obligations under these Terms.
      </h4>
      <br />
      <h2>Entire Agreement</h2>
      <h4>
        These Terms constitute the entire agreement between Brainayan and you in relation to your use of this Website, and
        supersede all prior agreements and understandings.
      </h4>
      <br />
      <h2>Governing Law & Jurisdiction</h2>
      <h4>
        These Terms will be governed by and interpreted in accordance with the laws of the State of India, and you submit to
        the non-exclusive jurisdiction of the state and federal courts located in in for the resolution of any disputes.
      </h4>
      <br />
    </>
  );
};

export default TermsAndConditions;
