import React from 'react';
import { Button, Modal, Spin } from 'antd';

import { compose } from '../../core';
import { EditIcon, getIntFromString, withMarkdownFieldValue } from '../../look';

import CoachingCommitmentFormComponent, { CKEditorFieldName } from '../components/CoachingCommitmentFormComponent';

import { withEditCoachingCommitment, withAddCoachingCommitment } from '../../dashboard/containers/DashboardOperations';

const AddCoachingCommitment = props => {
  const { btn, onSubmit, createCoachingCommitment, updateCoachingCommitment, btnText, coachingCommitment } = props;
  const [visible, setVisible] = React.useState(false);
  const [actionLoading, setActionLoading] = React.useState(false);

  const handleSubmit = async values => {
    setActionLoading(true);
    try {
      if (coachingCommitment?.id) {
        const response = await updateCoachingCommitment(
          withMarkdownFieldValue(CKEditorFieldName, { id: getIntFromString(coachingCommitment.id), ...values })
        );
        setActionLoading(false);
        response && onSubmit(response);
        response && setVisible(false);
      } else {
        // const response = await createCoachingCommitment({
        //   coachingCommitmentData: withMarkdownFieldValue(CKEditorFieldName, values)
        // });
        setActionLoading(false);
        /* response && */ setVisible(false);
        /* response && */ onSubmit(values);
      }
    } catch (e) {
      setActionLoading(false);
      throw Error(e.message);
    }
  };

  return (
    <>
      {btn === 'add' ? (
        <Button block type={'primary'} ghost onClick={() => setVisible(true)}>
          {btnText}
        </Button>
      ) : (
        <EditIcon tooltipSuffix="Coaching Commitment" color="primary" size="sm" onClick={() => setVisible(true)} />
      )}

      <Modal
        destroyOnClose
        centered
        visible={/* true || */ visible || window.location.href.includes('openmodal')}
        onCancel={() => setVisible(false)}
        // footer={null}
        width="50%"
        title={btn === 'add' ? 'Add CoachingCommitment' : 'Edit CoachingCommitment'}
        footer={null}
      >
        <Spin spinning={actionLoading} size="medium">
          <CoachingCommitmentFormComponent
            btnText={btn === 'add' ? 'Add' : 'Save'}
            coachingCommitment={coachingCommitment}
            setVisible={setVisible}
            onSubmit={handleSubmit}
          />
        </Spin>
      </Modal>
    </>
  );
};

export default compose(withEditCoachingCommitment /* , withAddCoachingCommitment */)(AddCoachingCommitment);
