import React from "react";
import { Button, Popconfirm, Tooltip } from "antd";
import { QuestionCircleOutlined, DeleteOutlined,DeleteFilled } from "@ant-design/icons";
class DeleteIcon extends React.Component {
  render() {
    const {
      color = "danger",
      type,
      size,
      onClick,
      tooltipSuffix,
      title = "Are you sure？",
      disabled,
      customicon,
      ...props
    } = this.props;

    let buttonSize = "default";

    if (size === "sm") {
      buttonSize = "small";
    } else if (size === "lg") {
      buttonSize = "large";
    }

    return (
      <Tooltip
        placement="left"
        title={"Delete " + (tooltipSuffix ? tooltipSuffix : "")}
      >
        {disabled ? (
          <Button
            type={color}
            htmlType={type}
            size={buttonSize}
            icon={<DeleteOutlined />}
            shape="circle"
            ghost
            disabled={disabled}
            {...props}
          />
        ) : (
          <Popconfirm
            title={title}
            placement = "topRight"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            onConfirm={onClick}
            okText="Yes"
          >
            {customicon?<DeleteFilled style={{color:"#E86161",marginLeft:"10px",fontSize:"20px",cursor:"pointer"}}/>:
            <Button
              type={color}
              htmlType={type}
              size={buttonSize}
              icon={<DeleteOutlined />}
              shape="circle"
              ghost
              disabled={disabled}
              {...props}
            />}
          </Popconfirm>
        )}
      </Tooltip>
    );
  }
}

export default DeleteIcon;
