import gql from 'graphql-tag';
import { FAQ_CATEGORY_TYPE_CONNECTION } from './FaqCategoryTypeConnection.gql';

export const ALL_FAQ_CATEGORIES_QUERY = gql`
  ${FAQ_CATEGORY_TYPE_CONNECTION}
  query allFaqCategories(
    $first: Int
    $offset: Int
    $orderBy: [String]
    $name_Icontains: String
    $description_Icontains: String
    $active: Boolean
  ) {
    allFaqCategories(
      first: $first
      offset: $offset
      orderBy: $orderBy
      name_Icontains: $name_Icontains
      description_Icontains: $description_Icontains
      active: $active
    ) {
      ...FaqCategoryTypeConnection
    }
  }
`;
