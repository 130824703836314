import React, { useState } from 'react';
import gql from 'graphql-tag';
import { message, Spin, Empty } from 'antd';
import { graphql } from '@apollo/client/react/hoc';

import * as ROUTE from '../../../organization/route';
import { compose } from '../../../core';

import SelectField from './SelectField';
import { getEmployeeBase64StringFromInt, getIntFromString } from '../functions';
import { withLookUserState, withLookUserFilterUpdating } from '../../containers/LookOperations';
import { withApollo } from '@apollo/client/react/hoc';
import { employee } from '../../../organization/route';
import styled from 'styled-components';
import GrowSelectField from 'modules/survey-look/containers/grow-select-field';

const EMPLOYEE_OKR_QUERY = gql`
query contributors($okr_Goalstatement_Icontains:String,$employee: ID){
    contributors(okr_Goalstatement_Icontains:$okr_Goalstatement_Icontains,employee:$employee){
        edges{
          node{
            id
            okr{
              goalStatement
              title
              id
              isDiscarded{
                id
                status
              }
            }
          }
        }
    }
}
`;

const OkrField = props => {
    const { loading, margin, employee, client } = props;
    const handleFilterRemove = React.useRef(() => { });

    const [okr, setOkr] = useState()
    const [chars, setChars] = useState("")
    const [selected_value,setSelectedValue]=React.useState("")

    const getOkr = async () => {
        const { data } = await client.query({
            query: EMPLOYEE_OKR_QUERY,
            variables: { employee:getEmployeeBase64StringFromInt(employee), okr_Goalstatement_Icontains: chars,requestStatus:"ACCEPTED" },
        });
        let list =data?.contributors?.edges?.filter(item=>item?.node?.okr?.isDiscarded==null)?.map(({ node }) =>({
            label: node?.okr?.title,
            value: node?.okr?.id
        }))
        var setObj = new Set();
        var result = list?.reduce((acc, item) => {
            if (!setObj.has(item?.value)) {
                setObj.add(item?.value)
                acc.push(item)
            }
            return acc;
        }, [])
        setOkr(result);


    }

    React.useEffect(() => {
        if (employee !== undefined) {
            setSelectedValue("")
            getOkr()
        }
    }, [chars, props.owner,employee])

    const onSelectFeildSearch = (s) => {
        setChars(s)
    }


    return (
        <GrowSelectField
            style={{width:"100%"}}
            id="grow_okr"
            showSearch
            onSearch={(e) => { onSelectFeildSearch(e) }}
            choices={okr}
            name={"okr"}
            filterOption={false}
            onBlur={handleFilterRemove.current}
            placeholder={"Select OKR"}
            margin={"0px"}
            selectedValue={selected_value}
            employee={employee}
            {...props}
            conditon={"ignore"}
        />
    );
};

export default compose(withApollo)(OkrField);

