import gql from "graphql-tag";

export const ALL_RESPONSES_BY_IMPLICIT_USER_TOP_RESPONSES_QUESTION_BASED_CHOICE = gql`
query allResponsesByImplicitUserId($survey: ID) {
   allResponsesByImplicitUserId(survey: $survey) {
    edges {
        node {
          id
          survey {
            id
            name
            uiFlow
            groupSet(last: 1) {
              edges {
                node {
                  id
                  questionSet(first:5) {
                    edges {
                      node {
                        id
                        questionText
                        choiceSet{
                          edges {
                            node {
                              id
                              label
                              value
                            }
                          }
                        }
                        answerSet(first: 1) {
                          edges {
                            node {
                              id
                              answer
                              response{
                                id
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }  
`;