import React from 'react';
import TeamsMembersView from '../components/teamMembersView';

const TeamMembersContainer = ( props ) => {

    return (
        <>
            <TeamsMembersView {...props} />
        </>
    )
}

export default TeamMembersContainer