import { subscribeToQuery } from '../../look';

import { ORGANIZATION_SUBSCRIPTION } from '../graphql/OrganizationSubscription.gql';
import { VERTICAL_SUBSCRIPTION } from '../graphql/VerticalSubscription.gql';
import { TEAM_SUBSCRIPTION } from '../graphql/TeamSubscription.gql';
import { EMPLOYEE_SUBSCRIPTION } from '../graphql/EmployeeSubscription.gql';
import { VERTICALS_BY_ORG_ID_QUERY_SUBSCRIPTION } from '../graphql/VerticalsByOrgIdQuery.gql';
import { ALL_EMPLOYEES_QUERY_SUBSCRIPTION } from '../graphql/AllEmployees.gql';

export const subscribeToOrganization = (subscribeToMore, variables) => {
  return subscribeToQuery(
    subscribeToMore,
    {
      document: ORGANIZATION_SUBSCRIPTION,
      subscriptionName: 'organizationSubscription',
      nodeName: 'organization',
      queryName: 'allOrganization'
    },
    variables
  );
};

export const subscribeToOrganizationById = (subscribeToMore, variables) => {
  return subscribeToQuery(
    subscribeToMore,
    {
      document: ORGANIZATION_SUBSCRIPTION,
      subscriptionName: 'organizationSubscription',
      nodeName: 'organization',
      queryName: 'organizationById'
    },
    variables
  );
};

export const subscribeToVertical = (subscribeToMore, variables) => {
  return subscribeToQuery(
    subscribeToMore,
    {
      document: VERTICAL_SUBSCRIPTION,
      subscriptionName: 'verticalSubscription',
      nodeName: 'Vertical',
      queryName: 'verticals'
    },
    variables
  );
};

export const subscribeToVerticalByOrgId = (subscribeToMore, variables) => {
  return subscribeToQuery(
    subscribeToMore,
    {
      document: VERTICAL_SUBSCRIPTION,
      subscriptionName: 'verticalSubscription',
      nodeName: 'Vertical',
      queryName: 'verticalsByOrgId'
    },
    variables
  );
};

export const subscribeToTeam = (subscribeToMore, variables) => {
  return subscribeToQuery(
    subscribeToMore,
    {
      document: TEAM_SUBSCRIPTION,
      subscriptionName: 'teamSubscription',
      nodeName: 'team',
      queryName: 'Teams'
    },
    variables
  );
};

export const subscribeToTeamsByVerticalId = (subscribeToMore, variables) => {
  return subscribeToQuery(
    subscribeToMore,
    {
      document: TEAM_SUBSCRIPTION,
      subscriptionName: 'teamSubscription',
      nodeName: 'team',
      queryName: 'Teams'
    },
    variables
  );
};

export const subscribeToEmployee = (subscribeToMore, variables) => {
  return subscribeToQuery(
    subscribeToMore,
    {
      document: EMPLOYEE_SUBSCRIPTION,
      subscriptionName: 'employeeSubscription',
      nodeName: 'employee',
      queryName: 'allEmployees'
    },
    variables
  );
};

export const subscribeToVerticalByOrg = (subscribeToMore, variables) => {
  return subscribeToQuery(
    subscribeToMore,
    {
      document: VERTICALS_BY_ORG_ID_QUERY_SUBSCRIPTION,
      subscriptionName: 'verticalSubscription',
      nodeName: 'Vertical',
      queryName: 'verticals'
    },
    variables
  );
};

export const subscribeToOrgEmployee = (subscribeToMore, variables) => {
  return subscribeToQuery(
    subscribeToMore,
    {
      document: EMPLOYEE_SUBSCRIPTION,
      subscriptionName: 'employeeSubscription',
      nodeName: 'employee',
      queryName: 'organizationById'
    },
    variables
  );
};