import gql from 'graphql-tag';

export const CASCARDING_OBJECTIVE_LIST =gql`
query contributors($okr_Duedate_Range: [DateTime], $role_In:[String],$employee_In: [ID] ,$offset:Int,$first:Int){
    AllCascadingObjectives:contributors(okr_Duedate_Range: $okr_Duedate_Range, role_In: $role_In, employee_In: $employee_In,offset:$offset,first:$first) {
        totalCount
        edgeCount
        edges {
            node {
              id
              progress
              okrType
              okr{
                id
                duedate
                isDiscarded {
                  id
                }
                discardRequest(status:"PENDING") {
                  edges {
                    node {
                      id
                      status
                    }
                  }
                }
                contributorSet {
                  edges {
                    node {
                      id
                      employee {
                        id
                        user{
                          id
                        }
                      }
                      requestStatus
                    }
                  }
                }
              }
            }
        }
    }
}
`

export const NON_CASCARDING_OBJECTIVE_LIST =gql`
query TeamByTeamId($id: ID!,$okr_Duedate_Range: [DateTime],$createdBy_In: [ID],$offset: Int,$first:Int){
  AllNonCascadingObjectives:Team(id: $id) {
    id
    name
    managers(offset:$offset,first:$first) {
      totalCount
      edgeCount
      edges {
        node {
          id
          okrContributorSet(okr_Duedate_Range:$okr_Duedate_Range, role: "OWNER",savedOkrType:"OBJECTIVE", createdBy_In: $createdBy_In) {
            edges {
              node {
                id
                okrType
                progress
                requestStatus
                okr {
                  id
                  title
                  duedate
                  isDiscarded {
                    id
                  }
                  contributorSet {
                    edges {
                      node {
                        id
                        employee {
                          id
                          user{
                            id
                          }
                        }
                        requestStatus
                      }
                    }
                  }
                  discardRequest(status:"PENDING") {
                    edges {
                      node {
                        id
                        status
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    employees(offset:$offset,first:$first) {
      totalCount
      edgeCount
      edges {
        node {
          id
          okrContributorSet(okr_Duedate_Range:$okr_Duedate_Range, role: "OWNER",savedOkrType:"OBJECTIVE", createdBy_In:$createdBy_In,offset:$offset,first:$first) {
            edges {
              node {
                id
                okrType
                progress
                requestStatus
                okr {
                  id
                  title
                  duedate
                  isDiscarded {
                    id
                  }
                  discardRequest(status:"PENDING") {
                    edges {
                      node {
                        id
                        status
                      }
                    }
                  }
                  contributorSet {
                    edges {
                      node {
                        id
                        employee {
                          id
                          user{
                            id
                          }
                        }
                        requestStatus
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`