// @ts-nocheck
import { Button, Checkbox, Col, Dropdown, Form, Input, Menu, Popover, Row, Select, Spin } from "antd";
import { displayUtcToUserTimezone, getIntFromString, MetaTags } from "modules/look";
import React from "react";
import { useHistory } from "react-router-dom";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import drop_black from '../../assets/drop-arrow-black.svg';
import dropdown_Arrow from '../../assets/dropdown_Arrow.svg';
import left_arrow from '../../assets/left-active-carosul.svg';
import right_arrow from '../../assets/right-active-carosul.svg';
import search_icon from '../../assets/search-icon.svg';
import Back_icon from '../../assets/soul-back.svg';
import "../../kudos/kudos.css";
import '../user-kudos.css';

const IssueNewBadgeView = (props) => {
    const { visible, onClose, onSearch, badgeList, loading, createBadgeAward, type, selectedItem, me, employeeList, employeeLoading, employeeSearch, getOkrList, getMeetingList, getFeedbackList, selectedModuleList, okrList, permission, badge_family_list, onFilter, getbadgeFailyList, getBageFamilyById, badge_Family_by_id } = props
    const [search_toggle, setSearchToggle] = React.useState(false)

    const moduleList = [
        { value: "1:1", label: "1 On 1" },
        { value: "Okr", label: "OKR" },
        { value: "Feedback", label: "Feedback" },
    ]
    const cateList = [
        { value: "employee", label: "Employee" },
        { value: "team", label: "Team" },
        { value: "vertical", label: "Vertical" },
        { value: "organization", label: "Organization" },
        { value: "none", label: "None" },
    ]
    const history = useHistory()
    const [selectedBadge, setSelectedBadge] = React.useState()
    const [detail, setdetail] = React.useState();
    const [Employees, setEmployees] = React.useState([]);
    const [selectedEmployee, setSelectedEmployee] = React.useState()
    const [selectedNotify, setSelectedNotify] = React.useState()
    const [selectedModule, setSelectedModule] = React.useState()
    const [allEmployeeList, setAllEmployeeList] = React.useState([])
    const [cat_list, setCatList] = React.useState(cateList)
    const [moduleSelectionList, setModuleSelectionList] = React.useState()
    const [module_list, setModuleList] = React.useState(moduleList)
    const [selectedModuleValue, setSelectedModuleValue] = React.useState()
    const [relatedtoName, setRelatedtoName] = React.useState()
    const [filter_value, setFilterValue] = React.useState()
    const [inflinite_slider,setInfiniteSlider] = React.useState(false)
    const sliderRef = React.useRef();
    const Option = Select.Option;
    const [form] = Form.useForm();
    const [invalidInput,setInvalidInput] = React.useState(false)
   
    const back = () => {
        history.push('/')
    }


    React.useEffect(()=>{

        if(detail?.trim() !== ""){
           setInvalidInput(false) 
        }


    },[detail])


    React.useEffect(() => {
        if (employeeList?.length) {
            let list = employeeList?.filter(({ node }) => node?.user?.id !== me?.employee?.user?.id)
            setAllEmployeeList(list)
        }
    }, [employeeList])
    React.useEffect(() => {
        if (selectedModuleList) {
            let list

            if (selectedModule === '1:1') {
                list = selectedModuleList?.map((item) => {
                    return {
                        id: item?.id,
                        title: `${item?.title}-${displayUtcToUserTimezone(item?.createdAt, 'DD MMM YY')}`
                    }
                })
            }
            else if (selectedModule === 'Feedback') {
                list = selectedModuleList?.map((item) => {
                    return {
                        id: item?.id,
                        title: `${item?.feedbackType} form ${item?.feedbackTo?.user?.firstName} - ${displayUtcToUserTimezone(item?.createdAt, 'DD MMM YY')}`
                    }
                })
            }
            setModuleSelectionList(list)
        }
    }, [selectedModuleList])
    const Relatedmenu = () => (
        <Menu style={{ maxHeight: '200px', overflowY: 'auto', width: "100%" }}>
            {
                okrList?.length ?
                    okrList.map((data) => (
                        <Menu.Item onClick={() => handleRelatedSelection(data)} style={{ maxWidth: "300px", width: "100%" }}>
                            {/* <Row align="middle" style={{flexWrap:"nowrap"}}> */}
                            <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'start', alignItems: 'center', flexWrap: "nowrap" }}>
                                {
                                    data?.okrType === "OBJECTIVE" &&
                                    <div style={{ border: '1px solid black', opacity: '0.4', paddingLeft: '5px', paddingRight: '5px', marginRight: '5px', fontSize: '10px', borderColor: '#4CCB1F', color: '#4CCB1F' }}>O</div>
                                    ||
                                    data?.okrType === "KEY_RESULT" &&
                                    <div style={{ border: '1px solid black', opacity: '0.4', paddingLeft: '3px', borderColor: '#009AF1', paddingRight: '3px', marginRight: '5px', fontSize: '10px', color: '#009AF1' }}>KR</div>
                                    ||
                                    data?.okrType === "MILESTONE" &&
                                    <div style={{ border: '1px solid black', opacity: '0.4', paddingLeft: '5px', borderColor: 'rgb(250, 197, 60)', paddingRight: '5px', marginRight: '5px', fontSize: '10px', color: 'rgb(250, 197, 60)' }}>M</div>
                                }
                                <p className="kudos-user-home-select-option" style={{ margin: "0px", whiteSpace: "nowrap", textOverflow: 'ellipsis', overflow: "hidden" }}>{data?.title}</p>
                            </div>
                            {/* </Row> */}
                        </Menu.Item>
                    ))
                    :
                    employeeLoading ?
                        <Menu.Item  >
                            <Spin spinning={true}></Spin>
                        </Menu.Item> :
                        <Menu.Item  >
                            no data found
                        </Menu.Item>
            }
        </Menu>
    );

    const handleRelatedSelection = (value) => {
        if (value) {
            setRelatedtoName(value?.title)
            setSelectedModuleValue(value?.id)
        }
    }
    const handleBadgeSearch = (event) => {
        if (event?.target?.value) {
            onSearch({ title_Icontains: event?.target?.value, behaviouralDescription_Icontains: event?.target?.value, badgeFamily: filter_value })
        }
        else {
            onSearch({ title_Icontains: "", behaviouralDescription_Icontains: "", badgeFamily: filter_value })
        }
    }
    React.useEffect(() => {

        if (selectedItem) {
            let emp = selectedItem?.contributors.filter((item) => item?.id != me?.employee?.user?.id)
            setEmployees(emp)
        }
    }, [selectedItem])

    const badgeSelectHandler = (node) => {
        setSelectedBadge(node)
    }
    const filter_list = badge_family_list &&
        badge_family_list?.map(({ node }, key) => (
            <Option
                key={key}
                value={node?.id}
            >
                {node?.title}
            </Option>
        ))
    const category_option = cat_list &&
        cat_list.map((choice, key) => (
            <Option style={{ border: "1px solid #000000", borderRadius: "8px" }} className="kudos-user-home-select-option"
                key={key}
                value={choice?.value}
            >
                {choice?.label}
            </Option>
        ));
    const module_option = module_list &&
        module_list.map((choice, key) => (
            <Option
                style={{ border: "1px solid #000000", borderRadius: "8px" }}
                className="kudos-user-home-select-option"
                key={key}
                value={choice?.value}
            >
                {choice?.label}
            </Option>
        ))

    const module_selection_option = moduleSelectionList &&
        moduleSelectionList.map((item) => (
            <Option
                className="kudos-user-home-select-option"
                key={item?.id}
                value={item?.id}
            >
                {item?.title}
            </Option>
        ))

    const choices = employeeList &&
        employeeList?.map(({ node }) => (
            <Option
                className="kudos-user-home-select-option"
                key={node?.user?.id}
                value={node?.user?.id}
            >
                {node?.user?.firstName} {node?.user?.lastName}
            </Option>
        ))
    const handleSubmit = async (value) => {
        if (detail && selectedEmployee && selectedNotify) {
            try {
                if(detail?.trim() !== ""){
                    
                let badgeAwardData = {
                    recipientId: getIntFromString(selectedEmployee?.node?.id),
                    badgeId: getIntFromString(selectedBadge?.id),
                    relatedToId: selectedModuleValue,
                    notify: selectedNotify,
                    evidence: detail
                }
                //   setloading(true)
                const res = await createBadgeAward({ badgeAwardData })
                if (res) {
                    history.push('/kudos/awards/badge?issued')
                }
            }else{
                setInvalidInput(true)
            }
                //   setloading(false)
            } catch (error) {
                //   setloading(false)
            }


        }
    }
    const searchEmployes = (value) => {
        if (value) {
            let valueList = allEmployeeList?.filter(({ node }) => node?.user?.firstName.toLowerCase()?.includes(value?.toLowerCase()))
            setAllEmployeeList(valueList)
        }
        else {
            setAllEmployeeList(employeeList)
        }
    }
    const searchModule = (value) => {
        if (value) {
            let valueList = module_list?.filter(item => item?.label.toLowerCase()?.includes(value.toLowerCase()))
            setModuleList(valueList)
        }
        else {
            setModuleList(moduleList)
        }
    }

    const searchNotify = (value) => {
        if (value) {
            let valueList = cat_list?.filter(item => item?.label.toLowerCase()?.includes(value.toLowerCase()))
            setCatList(valueList)
        }
        else {
            setCatList(cateList)
        }
    }
    const handleSelectEmployee = (e) => {
        if (e) {
            let data = allEmployeeList.find(({ node }) => node?.id === e)
            setSelectedEmployee(data)
            setSelectedModule()
            setSelectedModuleValue()
            setRelatedtoName()
            setSelectedNotify()
            setdetail()
        }

    }
    const handleSelectNotify = (e) => {
        setSelectedNotify(e)
    }
    const handleSelectModuleValue = (e) => {
        setSelectedModuleValue(e)
    }
    const handleFamilyFilter = (e) => {
        setFilterValue(e)
        setSelectedBadge(null)
        onFilter({ badgeFamily: e })
        getBageFamilyById({ id: e })
        filterSearch(null)
    }
    const handleSelectModule = (e) => {
        setSelectedModule(e)
        if (e === '1:1') {
            getMeetingList({ owner: selectedEmployee?.node?.id, member_User_In: [getIntFromString(selectedEmployee?.node?.id)], title_Icontains: "" })
        }
        else if (e == 'Okr') {
            getOkrList({ employee: selectedEmployee?.node?.id, okr_Goalstatement_Icontains: "" })
        }
        else if (e === 'Feedback') {
            getFeedbackList({ feedbackTo_User_FirstName_Icontains: "", $feedbackFrom_User_FirstName_Icontains: "", feedbackTo: selectedEmployee?.node?.id, feedbackFrom: selectedEmployee?.node?.id })
        }
    }
    const searchSelectedModule = (value) => {
        if (selectedModule === '1:1') {
            getMeetingList({ owner: selectedEmployee?.node?.id, member_User_In: [getIntFromString(selectedEmployee)], title_Icontains: "" })
        }
        else if (selectedModule == 'Okr') {
            getOkrList({ employee: selectedEmployee?.node?.id, okr_Goalstatement_Icontains: value })
        }
        else if (selectedModule === 'Feedback') {
            getFeedbackList({ feedbackTo_User_FirstName_Icontains: value, $feedbackFrom_User_FirstName_Icontains: value, feedbackTo: selectedEmployee?.node?.id, feedbackFrom: selectedEmployee?.node?.id })
        }
    }
    const onCheckChangeOneOnOne = (checkedValues) => {
        if (checkedValues?.target?.checked) {
            setSelectedModuleValue()
            setSelectedModule("1:1")
            getMeetingList({ owner: selectedEmployee?.node?.id, member_User_In: [getIntFromString(selectedEmployee?.node?.id)], title_Icontains: "" })
        }
        else {
            setSelectedModule("")
        }
    }
    const onCheckChangeOkr = (checkedValues) => {
        if (checkedValues?.target?.checked) {
            setSelectedModuleValue()
            setSelectedModule("Okr")
            getOkrList({ employee: selectedEmployee?.node?.id, okr_Goalstatement_Icontains: "" })
        }
        else {
            setSelectedModule("")

        }
    }
    const onCheckChangeFeedback = (checkedValues) => {
        if (checkedValues?.target?.checked) {
            setSelectedModuleValue()
            setSelectedModule("Feedback")
            getFeedbackList({ feedbackTo_User_FirstName_Icontains: "", $feedbackFrom_User_FirstName_Icontains: "", feedbackTo: selectedEmployee?.node?.id, feedbackFrom: selectedEmployee?.node?.id })
        }
        else {
            setSelectedModule("")
        }
    }
    const filterSearch = (value) => {
        if (value) {
            setSelectedBadge(null)
            getbadgeFailyList({ title_Icontains: value })
        }
        else {
            getbadgeFailyList({ title_Icontains: "" })
        }

    }

    const TooltipData = (node) => {
        return (
            <div style={{ width: "100%", maxWidth: "400px", minWidth: "180px" }}>
                <h4 style={{ fontSize: '1.2em', fontWeight: "500", color: "rgba(0, 0, 0, 0.71)", margin: "0" }}>{node?.title}</h4>
                <p style={{ color: "#8C8CA2", fontSize: "1em", margin: "0", textAlign: 'justify' }}>{node?.behaviouralDescription}</p>
            </div>
        )
    }
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        arrows: true,
        nextArrow: <img src={right_arrow} alt=''/>,
        prevArrow: <img src={left_arrow} />,
        slidesToShow: 4,
        slidesToScroll: 2,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                },
            },
            {
                breakpoint: 710,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 310,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    const onRadioChange =(e)=>{
        console.log('radio checked', e.target.value);
        setSelectedModule(e.target.value)
        if(e?.target?.value === '1:1'){
            getMeetingList({ owner: selectedEmployee?.node?.id, member_User_In: [getIntFromString(selectedEmployee?.node?.id)], title_Icontains: "" })
        }
        else if(e?.target?.value === 'Okr'){
            getOkrList({ employee: selectedEmployee?.node?.id, okr_Goalstatement_Icontains: "" })
        }
        else if(e?.target?.value === 'Feedback'){
            getFeedbackList({ feedbackTo_User_FirstName_Icontains: "", $feedbackFrom_User_FirstName_Icontains: "", feedbackTo: selectedEmployee?.node?.id, feedbackFrom: selectedEmployee?.node?.id })
        }
    }
    return (
        <>
            <MetaTags
                title="Add Badge"
                description="This is Add New Badge page."
            />

<div>
                    <div className="kudos-root-class" style={{ marginTop: "20px" }}>
                        <div className="kudos-space-between-row" style={{ margin: '0 0.92em' }}>
                            <img style={{ cursor: "pointer" }} src={Back_icon} onClick={() => { history.goBack() }} alt="back" />
                            <h4 className="kudos-tool-titile" style={{ fontWeight: "700" }}>Give Kudos</h4>
                            <img style={{ visibility: 'hidden' }} src={Back_icon} alt="back" />
                        </div>
                    </div>
                    <div className="user-badge-root-class user-given-badge-popup-container">
                        <div style={{width:"100%"}}>
                            <div className="user-badge-award-popup-input-div" style={{ display: 'flex', flexDirection: "row" }}>
                                {!search_toggle || filter_value ? <Select
                                    showSearch
                                    className="filter-select-user-select"
                                    name={'filter'}
                                    id={'filter'}
                                    placeholder={`Select`}
                                    suffixIcon={<img src={drop_black} alt="" style={{ width: "10px", height: "10px", cursor: "pointer" }} onClick={() => setSearchToggle(false)} />}
                                    style={{ width: filter_value && search_toggle ? '40%' : '100%', maxWidth: "100%", textOverflow: 'ellipsis', whiteSpace: "nowrap" }}
                                    {...props}
                                    onSelect={handleFamilyFilter}
                                    onSearch={filterSearch}
                                    allowClear
                                    onClear={() => { onFilter();}}
                                    onMouseEnter={() => setSearchToggle(false)}
                                    filterOption={(input, option) => option}
                                >
                                    {/* {otherOption ? (choices?.length > 0 ? [...choice, otherField] : [otherField]) : choice} */}

                                    {filter_list}

                                </Select> : <div style={{ borderRadius: "8px 0px 0px 8px", border: "1px solid #8F8D8D", height: "40px", padding: "8px 10px" }}>
                                    <img src={drop_black} alt="" style={{ width: "10px", height: "10px", cursor: "pointer" }} alt="" onClick={() => setSearchToggle(false)} />
                                </div>}
                                <div className="search-input-user-kudos" style={{ display: 'flex', flexDirection: 'row' }}>
                                    {search_toggle && <Input placeholder="Search kudos name here" autoComplete='off' style={{ width: "100%", border: "none" }} onChange={(e) => handleBadgeSearch(e)}></Input>}
                                    <img src={search_icon} alt="" style={{ cursor: 'pointer', transform: " rotate(-270deg)", height: '20px', marginTop: "10px", marginRight: '7px', marginLeft: '7px' }} onClick={() => setSearchToggle(true)} />
                                </div>
                            </div>
                            <div style={{ marginTop: "20px" }} className="slider-container">
                                <div className="user-badge-award-carosul-container">
                                    
                                <Spin spinning={loading} style={{marginTop:'7.3em'}}>
                                {badgeList?.length > 0 && !loading && <Slider {...settings}>
                                        {badgeList?.length && badgeList?.map(({ node }, index) => (
                                            <div key={index}>
                                                <Popover content={TooltipData(node)} placement="bottom" overlayClassName="badge-popover">
                                                    <img className={node?.id == selectedBadge?.id ? "user-award-badge-image-selected" : "user-award-badge-image"} src={node?.image} alt={`Slide ${index}`} onClick={() => badgeSelectHandler(node)} />
                                                </Popover>
                                            </div>
                                        ))}
                                    </Slider>}
                                    {badgeList?.length == 0 && loading == false &&
                                        <div style={{ width: "100%", display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: "40px" }}>
                                            <h4 className="recived-award-image-title" style={{ margin: 'auto', justifyContent: 'center', textAlign: "center" }}>No Kudos Found</h4>
                                        </div>
                                    }
                                </Spin>
                                
                                </div>
                               
                            </div>
                            <div className="user-badge-award-carosul-second-container" style={{ marginTop: '10px' }}>
                                {badge_Family_by_id ? <h4><span className="filter-badge-family-title">{badge_Family_by_id?.title} : </span><span className="filter-badge-family-para"> {badge_Family_by_id?.description}</span></h4> :
                                    <h4>{selectedBadge && <span className="filter-badge-family-title">{selectedBadge?.badgeFamily?.title} : </span>}<span className="filter-badge-family-para"> {selectedBadge?.badgeFamily?.description}</span></h4>}
                                {selectedBadge ? <Row justify="center" align="center" gutter={20} style={{ width: "100%" }}>
                                    <Col lg={7} md={8} sm={24} xs={24} style={{ width: "100%", display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                                        <div className="user-badge-award-carosul-second-section-image-container">
                                            <img className="user-badge-award-carosul-second-section-image" src={selectedBadge?.image} alt="" />
                                        </div>
                                    </Col>
                                    <Col lg={17} md={16} sm={24} xs={24}>
                                        <div>
                                            <h4 className="user-badge-award-selected-title-modal">1. {selectedBadge?.title}</h4>
                                            <p className="user-badge-award-selected-description-modal">{selectedBadge?.behaviouralDescription}</p>
                                            {/* <p>{selectedBadge?.duration}</p> */}
                                        </div>
                                    </Col>
                                </Row> : <div>
                                    <p className="user-badge-award-selected-description-modal" style={{ textAlign: "center", paddingTop: "100px", fontWeight: '400' }}>Select a badge to see the details. </p>
                                </div>}
                            </div>
                        </div>
                        <div className="give-kudos-form-section given-kudos-right-container"style={{ width: "100%", marginTop: "10px" }}>
                            <Form
                                form={form}
                                name={"competency"}
                                style={{ padding: "20px", width: "100%" }}
                                onFinish={() => { handleSubmit() }}
                                className="formLabel"
                            >
                                <Col className="kudos-form-group">
                                    {/* <label style={{ fontSize: "18px", textAlign: "right",color:"#FF0404",fontWeight:"700" }}>*</label> */}
                                    <Select
                                        className={selectedEmployee ? "user-badge-select-active" : "user-badge-select"}
                                        showSearch
                                        name={'employee'}
                                        id={'employee'}
                                        placeholder="Search Employee"
                                        suffixIcon={<img src={dropdown_Arrow} alt="" style={{ width: "10px", height: "10px", cursor: "pointer" }} />}
                                        style={{ width: '100%', maxWidth: "100%" }}
                                        {...props}
                                        onSearch={searchEmployes}
                                        onSelect={handleSelectEmployee}
                                        filterOption={(input, option) => option}
                                    >
                                        {/* {otherOption ? (choices?.length > 0 ? [...choice, otherField] : [otherField]) : choice} */}
                                        {allEmployeeList?.map(({ node }) => (
                                            <Option
                                                className="kudos-user-home-select-option"
                                                key={node?.id}
                                                value={node?.id}
                                            >
                                                {node?.user?.firstName} {node?.user?.lastName}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>
                                <Col style={{ display: 'flex', flexDirection: "row", justifyContent: 'start', alignItems: "center", gap: "10px", flexWrap: "wrap", width: "100%", paddingTop: "20px" }}>
                                    {
                                        permission?.okr_permission && <Checkbox className="badge-check" disabled={!selectedEmployee} onChange={onCheckChangeOkr} checked={selectedModule === "Okr" ? true : false}>OKR</Checkbox>
                                    }
                                    {
                                        permission?.feedback_permission && <Checkbox className="badge-check" disabled={!selectedEmployee} onChange={onCheckChangeFeedback} checked={selectedModule === "Feedback" ? true : false}>Feedback</Checkbox>
                                    }
                                    {
                                        permission?.one_on_one_permission && <Checkbox className="badge-check" disabled={!selectedEmployee} onChange={onCheckChangeOneOnOne} checked={selectedModule === "1:1" ? true : false}>1 : 1</Checkbox>
                                    }
                                   
                                </Col>
                               
                                {selectedModule !=""&&selectedModule !=undefined && <Col className="kudos-form-group" style={{ paddingTop: "20px" }}>
                                    {/* <label style={{ fontSize: "18px" }}> Module </label> */}
                                    {selectedModule != "Okr" ? <Select
                                        showSearch
                                        className={selectedModuleValue ? "user-badge-select-active" : "user-badge-select"}
                                        name={'module'}
                                        id={'module'}
                                        value={selectedModuleValue}
                                        placeholder={`Select ${selectedModule}`}
                                        suffixIcon={<img src={dropdown_Arrow} alt="" style={{ width: "10px", height: "10px", cursor: "pointer" }} />}
                                        style={{ width: '100%', maxWidth: "100%" }}
                                        {...props}
                                        onSelect={handleSelectModuleValue}
                                        onSearch={searchSelectedModule}
                                        filterOption={(input, option) => option}
                                    >
                                        {/* {otherOption ? (choices?.length > 0 ? [...choice, otherField] : [otherField]) : choice} */}

                                        {module_selection_option}

                                    </Select> :
                                        <Dropdown overlay={Relatedmenu} style={{ width: "100%" }}
                                        >
                                            <div
                                                className={selectedModuleValue ? "user-badge-drop-active" : "user-badge-drop"} style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: "0px 10px", maxWidth: "100%" }}>
                                                <p className="one-on-one-okr-selection-drop" style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'left', whiteSpace: "nowrap", margin: "0px" }}>{relatedtoName ? relatedtoName : <span style={{ color: "#D9D9D9" }}>{`Select ${selectedModule}`}</span>}</p>
                                                <img src={dropdown_Arrow} alt="" style={{ width: "10px", height: "10px", cursor: "pointer" }} />
                                            </div>
                                        </Dropdown>}
                                </Col>}
                                <Col className="kudos-form-group no-bg-drop" style={{ paddingTop: "20px" }}>
                                    {/* <label style={{ fontSize: "18px",color:"#FF0404",fontWeight:"700" }}> * </label> */}
                                    <Select
                                        className={selectedNotify ? "user-badge-select-active" : "user-badge-select"}
                                        name={'notify_category'}
                                        id={'notify_category'}
                                        placeholder="Select Whom To Notify"
                                        suffixIcon={<img src={dropdown_Arrow} alt="" style={{ width: "10px", height: "10px", cursor: "pointer" }} />}
                                        style={{ width: '100%' }}
                                        value={selectedNotify}
                                        {...props}
                                        onSelect={handleSelectNotify}
                                        onSearch={searchNotify}
                                    >
                                        {category_option}
                                    </Select>
                                </Col>
                                {/* </Row> */}

                                <Col style={{ marginTop: '20px' }} className="kudos-form-group" >
                                    {/* <label style={{ fontSize: "18px" }}>Rational</label> */}
                                    <Col>
                                        <textarea style={{ width: "100%", height: "200px", backgroundColor: detail?.length ? "#fff" : "rgba(246, 246, 246, 0.62)", border: detail?.length ? "1px solid #9CD600" : "1px solid #000000", }} placeholder="Rationale" name="description" id="" className="kudos-description" value={detail?.length?detail:''} onChange={(e) => { setdetail(e?.target?.value) }}></textarea>
                                    </Col>
                                </Col>
                                {
                                    invalidInput && <div className="poppins" style={{fontSize:'1em',color:'red'}}>Please enter a valid input</div>
                                }
                            </Form>
                            <Row justify="center" align="center" style={{ width: "100%", marginBottom: "15px", marginTop: "-10px" }}>
                                <Button className="save-btn give-kudos-save-btn" style={{ width: "fit-content", height: 'fit-content' }} onClick={() => handleSubmit()} disabled={!selectedBadge || !selectedEmployee || !selectedNotify|| !detail}>
                                    Save
                                </Button>
                            </Row>
                        </div>
                    </div>
                </div>

        </>
    )
}


export default IssueNewBadgeView