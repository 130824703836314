import gql from 'graphql-tag';

export const DISCARD_DETAIL_BY_ID = gql`
query discardRequestById($id:ID!,$tz:String){
  discardRequestById(id: $id){
    id
    createdAt
    updatedAt
    actedOn
    status
    initiator{
      id
      user{
        id
        firstName
        lastName
        profile{
          id
          profileImage
        }
      }
    }
    actor{
      id
      user{
        id
        firstName
        lastName
        profile{
          id
          profileImage
        }
			}
		}
    initiatorsRational
    okr{
      id
      title(tz:$tz)
      okrType
      goalStatement
      duedate
      progress  
      correctionDeadline
      owner{
        id
        user{
          id
          firstName
          lastName
          profile{
            id
            profileImage
          }
        }
      }
      contributors{
        edges{
          node{
            id
            user{
              id
              username
              firstName
              lastName
              profile{
                id
                profileImage
              }
						}
					}
        }
      } 
		}
    actorsComment
  }
}
`