import { withApollo } from '@apollo/client/react/hoc';
import { FEEDBACK_BY_ID } from 'modules/feedback/graphql/FeedbackById.gql';
import { getIntFromString } from 'modules/look';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { compose } from '../../core';
import { withFeedBackSurveyByid } from '../../feedback/containers/feedbackOperations';
import OneOnOneFeedbackUpdateView from '../components/one-on-one-edit-feedback-view';

const OneOnOneFeedbackUpadteContainer = props => {
  const { feedbackById, getOKRByRecepiant, okrByrecipiant} = props
  const history = useHistory();
  
    



  return (
    <>
    <OneOnOneFeedbackUpdateView {...props} 
    okrSearch={(e) => getOKRByRecepiant(e)} 
    okrByrecipiant={okrByrecipiant}
    history={history}
    feedbackById={feedbackById}
    ></OneOnOneFeedbackUpdateView>
    </>
  );
};

export default compose(withApollo,withFeedBackSurveyByid)(OneOnOneFeedbackUpadteContainer);
