import React from 'react';

import { AdminLayout } from '../../look';
import ROUTE from '../route';

import TagFormComponent from './TagFormComponent';

const EditTagView = props => {
  return (
    <AdminLayout
      table={false}
      active={ROUTE.tag}
      title="Edit Tag"
      backLink={`${ROUTE.tag}`}
      FormComponent={props.tagById && <TagFormComponent {...props} />}
    />
  );
};

export default EditTagView;
