import React from "react";
import { ca_create_icon } from "../icon";
import { Select, Button } from "antd";

const CompetencySelectorView = (props) => {
    const { competency_category_list, getCompetencyCategoryList,onCancel,saveCategory } = props
    const { Option } = Select;
    const [selected_category,SetSelectedCategory] = React.useState()

    return (
        <>
            <h4>Select Competency</h4>
            <Select
                className="rd-select-filed rd-type-select"
                style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", width: "100%",marginTop:"1em" }}
                suffixIcon={<img src={ca_create_icon?.dropdown_Arrow} alt="" style={{ width: "10px", height: "10px" }} />}
                showSearch
                onSelect={(e) => { SetSelectedCategory(e)}}
                onSearch={(e) => { getCompetencyCategoryList({title_Icontains:e})}}
                filterOption={false}
                placeholder='Select competency'
                {...props}
            >
                {competency_category_list?.map(value => (
                    <Option
                        className='rd-select-option'
                        key={value?.id}
                        value={value?.id}
                    >
                        {value?.title}
                    </Option>
                ))}
            </Select>
            <div style={{display:"flex",flexDirection:"row",width:"100%",justifyContent:"center",alignItems:"center",marginTop:"2em"}}>
                    <Button className="global-cancel-btn" onClick={()=>{onCancel()}}>Cancel</Button>
                    <Button className="global-submit-btn" disabled={!selected_category} onClick={()=>saveCategory(selected_category)}>Save</Button>
            </div>
        </>
    )

}

export default CompetencySelectorView