import gql from 'graphql-tag';
import { CONDITION_TYPE_CONNECTION } from './ConditionTypeConnection.gql';

export const TEXT_SECTION_TYPE = gql`
  ${CONDITION_TYPE_CONNECTION}
  fragment TextSectionType on TextSectionType {
    id
    title
    text
    renderedText
    sequence
    condition {
      ...ConditionTypeConnection
    }
  }
`;
