// @ts-nocheck
import React from 'react';
import { Row, Col, Button, Table, Card, Tooltip } from 'antd';
import styled from 'styled-components';
import ReactMarkdown from '../../look/components/ReactMarkdown';
import * as html2pdf from 'html2pdf.js'
import { Bar } from 'react-chartjs-2';
import pdflogo from '../../assets/pdflogo.png'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Legend,
} from 'chart.js';
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Legend
);

const TeamMember360ReportView = props => {
    const {SurveyReport,downloadComplete,loading } = props
    const [ReportList, setReportList] = React.useState([]);

    React.useEffect(()=>{
        if(SurveyReport){
            if(SurveyReport?.report360?.self?.length){
                let report = SurveyReport?.report360
                let selfList = SurveyReport?.report360?.self
                selfList.forEach(element => {
                    element['overAll']=report?.overall.filter(item=>element?.answer__question__group__id===item?.answer__question__group__id)
                    element['respondents']=report?.respondents.filter(item=>element?.answer__question__group__id===item?.answer__question__group__id)
                });
                setReportList(selfList)
            }
            else{
                setReportList([])
            }
        }
    },[SurveyReport])

   

    const ToDecimal =(value)=>{
        if(value%1){
            return value.toFixed(1)
        }
        else{
            return value
        }
    }

   

    const chartData = (chart)=>{
       
        let Values =[]
        let labels=[]
        let dataSource = []
        const columns = [
            {
                title: <Tooltip title="Type of Respondent">TYPE</Tooltip>,
                key: 'TYPE',
                render: (text, record) => record && 
                <Tooltip title={record?.type}>
                    <p style={{maxWidth:'100px',overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap',marginTop:'5px'}}>{record?.type}</p>
                </Tooltip>
            },
            {
              title: <Tooltip title="Average">AVG</Tooltip>,
              key: 'AVG',
              render: (text, record) => record && 
              <p style={{marginTop:'5px'}}>{record?.AVG}</p>
            },
            {
              title: <Tooltip title="Standard Deviation">SD</Tooltip>,
              key: 'SD',
              render: (text, record) => record &&
               <p style={{marginTop:'5px'}}>{record?.SD}</p>
            },
            
            {
                title:<Tooltip title="High">HI</Tooltip>,
                key: 'HI',
                render: (text, record) => record && 
                <p style={{marginTop:'5px'}}>{record?.HI}</p>
              },
              {
                title: <Tooltip title="Low">LO</Tooltip>,
                key: 'LO',
                render: (text, record) => record && 
                <p style={{marginTop:'5px'}}>{record?.LO}</p>
              }
        ]
        if(chart?.overAll?.length){
            labels.push('OverAll')
            const Avg = chart?.overAll.reduce((a, b) => a + b?.Avg_response, 0) 
            Values.push(Avg)
            let tableOverall={
                type:'OverAll',
                AVG:ToDecimal(Avg),
                SD:ToDecimal(chart?.overAll.reduce((a, b) => a + b?.StdDev_response, 0)),
                HI:ToDecimal(chart?.overAll.reduce((a, b) => a + b?.Max_response, 0)) ,
                LO:ToDecimal(chart?.overAll.reduce((a, b) => a + b?.Min_response, 0)) ,
            }
            dataSource.push(tableOverall)
        }
      
        if(chart?.respondents?.length){
    
            chart.respondents.forEach(element => {
                const Avg = element?.Avg_response
                labels.push(element?.respondentType)
                Values.push(Avg)
                let tablerespondents={
                    type:element?.respondentType,
                    AVG:ToDecimal(Avg),
                    SD:ToDecimal(element.StdDev_response),
                    HI:ToDecimal(element?.Max_response), 
                    LO:ToDecimal(element.Min_response),
                }
                dataSource.push(tablerespondents)
            });
        }
     
        
        
        labels.push('Self')
        Values.push(chart?.Avg_response)
        let tableSelf={
            type:'Self',
            AVG:ToDecimal(chart?.Avg_response),
            SD:ToDecimal(chart?.StdDev_response),
            HI:ToDecimal(chart?.Max_response) ,
            LO:ToDecimal(chart?.Min_response) ,
        }
        dataSource.push(tableSelf)
        const chartData = {
            labels:labels,
            datasets: [{
                data: Values,
                backgroundColor: '#B0CB1F',
            }]
        };
        let maxValue = ( (parseInt(Math.max(...Values)/10)+1 )* 10 )

        const options = {
            responsive: true,
            scales: {
                x: {
                    min:0,
                    max:maxValue>100?maxValue:100
                }
            },
            indexAxis: 'y',
            elements: {
                bar: {
                  borderWidth: 1,
                  borderHeight:1
                },
              },
            plugins: {
                legend: {
                    position: 'bottom',
                    display:false
                },
                title: {
                    display: false,
                }
            },
        };
        return (
            <>
             <Row >
                <Col  lg={{span:14}} sm={{span:24}} xs={{span:24}} style={{paddingTop:'34px'}}>
                    <Bar data={chartData} options={options} height={dataSource?.length*30} />
                </Col>
                <Col  lg={{span:10}} sm={{span:24}} xs={{span:24}} >
                    <MyTable
                        size={'middle'}
                        showHeader={true}
                        pagination={false}
                        dataSource={
                        dataSource
                            ? dataSource
                            : []
                        }
                        columns={columns.map(c => ({ ...c, sortDirections: ['descend', 'ascend', 'descend'] }))}

                    />
                </Col>
             </Row>
               
            </>
        )
    }

    const downloadPdf =()=>{
       
        let div = document.createElement('div')
        let title = document.getElementById('team_report_title').cloneNode(true)
        let chart = document.getElementById('team_report_chart')
        let img = document.createElement('img')
        img.src = pdflogo
        img.setAttribute('style','width:150px;height:80px;margin-bottom:15px;')
        if(chart){
            loading(true)
            div.appendChild(img)
            div.appendChild(title)
            div.appendChild(chart)
            
            var options = {
                margin: 0.7,
                filename: `${title?.innerHTML?.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g,'')}_${new Date()}.pdf`,
                image: { type: 'jpeg', quality: 2 },
                html2canvas: { scale: 2 },
                jsPDF: {
                unit: 'cm',
                format: 'tabloid',
                orientation: 'landscape',
                compress: false,
                putOnlyUsedFonts: true
                }
            };
            html2pdf().from(div).set(options).save().then((e)=>{
                loading(false)
                downloadComplete()
            }).catch((e)=>{
                loading(false)
                downloadComplete()
            });

        } 
    }

    return (
        <>

      
             {
                SurveyReport && (
                   <>
                    
         
                         {ReportList?.length?
                            <Col>
                             <Button type='primary' onClick={()=>{downloadPdf()}}  style={{marginTop:'10px'}}>
                                    Download PDF
                            </Button>
                            <div style={{marginTop:'20px'}} id="team_report_chart">
                                {
                                    ReportList.map(chart=>
                                        <ExpansionCard style={{marginTop:'20px'}}>
                                            <Row gutter={20}>
                                                <Col lg={{span:9}} sm={{span:24}} xs={{span:24}}>
                                                    <ReactMarkdown>{chart?.answer__question__group__label}</ReactMarkdown>
                                                    <ReactMarkdown>{chart?.answer__question__group__help_text}</ReactMarkdown>
                                                </Col>
                                                <Col lg={{span:15}} sm={{span:24}} xs={{span:24}}>
                                                    {chartData(chart)}
                                                </Col>
                                            </Row>
                                        </ExpansionCard>
                                    )
                                }

                            </div>
                            </Col>
                            :
                            <h3 style={{textAlign:'center',marginTop:'20px'}}>NO REPORTS FOUND</h3> 
                        }
             
                   </>
                ) 
             }
      

        </>
    )
 
};

export default TeamMember360ReportView

const ExpansionCard = styled(Card)`
border:2px solid #CACACA
`

export const MyTable = styled(Table)`
  table {
    th{
        text-align:center !important;
    }
    tbody {
      th,td{
          padding:3px 5px 0px 5px !important;  
          font-size:13px;
          text-align:center;
      }  
      tr {
        td { 
          border: 1px solid rgba(0, 0, 0, 0.06) !important;
          border-style: solid none !important;
        }
        td:first-child {
          border-left-style: solid !important;
        }
        td:last-child {
          border-right-style: solid !important;
          border-bottom: 1px solid rgba(0, 0, 0, 0.06) !important;
        }
      }
    }
  }
`;
