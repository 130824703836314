import * as React from 'react';
import { Card, Tabs, Col, Row } from 'antd';

import { MetaTags } from '../../look';
import ROUTE from '../route';
import TermsAndConditions from './TermsAndConditions';
import PrivacyPolicy from './PrivacyPolicy';
import Cookie from './Cookie';

const TermsPage = props => {
  const { location } = props;

  return (
    <div style={{ padding: '0 24px' }}>
      <MetaTags title="T & C" description="This is T & C page" />
      <Row>
        <Col lg={24} md={24} xs={0}>
          <Tabs
            defaultActiveKey={location.pathname === ROUTE.privacy ? '2' : '1'}
            tabPosition="left"
            tabBarStyle={{ paddingTop: '30px' }}
          >
            <Tabs.TabPane tab="1. Terms and Conditions" key="1">
              <Card bordered={false}>
                <TermsAndConditions />
              </Card>
            </Tabs.TabPane>
            <Tabs.TabPane tab="2. Privacy policy" key="2">
              <Card bordered={false}>
                <PrivacyPolicy />
              </Card>
            </Tabs.TabPane>
            <Tabs.TabPane tab="3. Cookie Uage Statement" key="3">
              <Card bordered={false}>
                <Cookie />
              </Card>
            </Tabs.TabPane>
          </Tabs>
        </Col>
        <Col lg={0} md={0} xs={24}>
          <Tabs tabPosition="top" tabBarStyle={{ paddingTop: '30px' }}>
            <Tabs.TabPane tab="1. Terms and Conditions" key="1">
              <Card bordered={false}>
                <TermsAndConditions />
              </Card>
            </Tabs.TabPane>
            <Tabs.TabPane tab="2. Privacy policy" key="2">
              <Card bordered={false}>
                <PrivacyPolicy />
              </Card>
            </Tabs.TabPane>
            <Tabs.TabPane tab="3. Cookie Uage Statement" key="3">
              <Card bordered={false}>
                <Cookie />
              </Card>
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>
    </div>
  );
};

export default TermsPage;
