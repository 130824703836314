import React from "react";
import { Modal, Button, Checkbox, Radio, Spin } from 'antd'
import { ReactMarkdown } from "modules/look";
const CreateChoiceView = ( props ) => {
    const { onCancel, all_questions_list, allready_selected_choices, question_loading,setmutationData,create_choice } = props

    const [ selected_questions, setSelectedQuestion ] = React.useState( [] )
    const [ choice_lists, setChoiceLists ] = React.useState( [] )
    const [ selected_choice_list, setSelectedChoiceList ] = React.useState( [] )
    const [ selected_choice_ids, setSelectedChoiceIds ] = React.useState( [] )
    const [ value, setValue ] = React.useState();

    const selectQuestion = ( e, value ) => {
        let ids = [ ...selected_questions ]
        let choices = [ ...choice_lists ]
        if ( ids.includes( value?.id ) ) {
            let index = ids.indexOf( value?.id )
            ids.splice( index, 1 )
            choices = choices.filter( item => item?.parent_id !== value?.id )
        }
        else {
            ids.push( value?.id )
            choices.push( value?.choiceSet?.edges?.map( ( { node } ) => { return { node: node, parent_id: value?.id } } ) )
        }
        setSelectedQuestion( ids )

        let filter_choice = [].concat( ...choices ).filter( item => item?.node?.label !== "" && !allready_selected_choices?.includes( item?.node?.id ) )
        var setObj = new Set();
        let result = filter_choice.reduce( ( acc, item ) => {
            if ( !setObj.has( item?.node?.id ) ) {
                setObj.add( item?.node?.id )
                acc.push( item )
            }
            return acc;
        }, [] )
        setChoiceLists( result )

    }
    const selectChoice = ( e, choice ) => {
        let choices = [ ...selected_choice_ids ]
        let choice_list = [ ...selected_choice_list ]
        if ( choices?.includes( choice?.id ) ) {
            let index = choices.indexOf( choice?.id )
            choices.slice( index, 1 )
            choice_list = choice_list?.filter( item => item?.id !== choice?.id )
        }
        else {
            choices.push( choice?.id )
            choice_list.push( { id: choice?.id, label: choice?.label } )
        }
        setSelectedChoiceList(choice_list)
        setSelectedChoiceIds(choices)
    }

    const onChange = ( e ) => {
        setValue( e.target.value );
    };

    const createChoice =()=>{
        let mutation_value ={
            choices:selected_choice_ids,
            show:value,
            parameter_id:create_choice?.group_id?.id
        }

        setmutationData(mutation_value)
    }

    return (
        <Modal
            visible={true}
            footer={null}
            centered
            destroyOnClose={true}
            closable={false}

            className="sa-choice-modal">
            <Spin spinning={question_loading}>
                <div style={{ display: 'flex', justifyContent: "center", flexDirection: "column", alignItems: 'center', width: "100%", gap: "1em" }}>
                    <h4 className='sa-create-main-title' style={{ marginTop: '1em' }}>Add Choices to {create_choice?.group_id?.name}</h4>
                    <div className='sa-input-container'>
                        <h4 className='sa-create-title'><span className='rd-input-star'>*</span>Input type</h4>
                        <Radio.Group className="sa-radio-btn-group" onChange={onChange} value={value}>
                            <Radio value={'boolean'}>Boolean</Radio>
                            <Radio value={'order'}>Order</Radio>
                        </Radio.Group>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: '1em', width: "100%" }}>
                        <div style={{ display: 'flex', flexDirection: "column", gap: ".5em", justifyContent: 'start', width: "100%" }}>
                            <h4 className='sa-create-title' style={{ textAlign: "left" }}><span className='rd-input-star'>*</span>Select Questions </h4>
                            <div className="selection-container">

                                {
                                    all_questions_list?.map( item => (
                                        <div className="sa-choice-question-container">
                                            <Checkbox onChange={( e ) => selectQuestion( e, item )} checked={selected_questions?.includes( item?.id )} />
                                            <p className="sa-choice-question-text" style={{ marginBottom: "0em" }}><ReactMarkdown style={{ marginBottom: "0em" }}>{item?.questionText}</ReactMarkdown></p>
                                        </div>

                                    ) )
                                }
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: "column", gap: ".5em", justifyContent: 'start', width: "100%" }}>
                            <h4 className='sa-create-title' style={{ textAlign: "left" }}><span className='rd-input-star'>*</span>Select Answers </h4>
                            <div className="selection-container">

                                {
                                    choice_lists?.length > 0 ? choice_lists?.map( item => (
                                        <div className="sa-choice-question-container">
                                            <Checkbox onChange={( e ) => selectChoice( e, item?.node )} checked={selected_choice_ids?.includes( item?.node?.id )} />
                                            <p className="sa-choice-question-text" style={{ marginBottom: "0em" }}><ReactMarkdown style={{ marginBottom: "0em" }}>{item?.node?.label}</ReactMarkdown></p>
                                        </div>
                                    ) ) : (
                                        selected_questions?.length === 0&&!question_loading? <h4 className="sa-choice-question-text" style={{ paddingTop: "10em", textAlign: 'center' }}>Please select a question first</h4> : !question_loading&&<h4 className="sa-choice-question-text" style={{ paddingTop: "10em", textAlign: 'center' }}>No Choices found</h4>
                                    )
                                }

                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: "center", flexDirection: "row", alignItems: 'center', width: "100%", gap: "1em", flexWrap: "wrap", marginTop: "1em" }}>
                        <Button className='sa-global-submit-btn' disabled={!value||selected_choice_ids?.length===0} onClick={() => createChoice()}>Save</Button>
                        <Button className='sa-global-cancel-btn' onClick={() => onCancel()}>Cancel</Button>
                    </div>
                </div>
            </Spin>
        </Modal>
    )
}

export default CreateChoiceView