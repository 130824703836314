// @ts-nocheck
import { Button, Dropdown, Menu } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { scape_report_home_icon } from '../icons';
import { preference_colors } from '../preference-color-config';
import { SCAPE_PERSONALITY_SURVEY_ID } from '../../../config';
import { getIntFromString } from 'modules/look';

const ScapePreferneceTabView = ( props ) => {
    const { selected_choice, table_data, selectedItem, SetPreferenceHandler, selected_drop_choice, selected_survey_id, graph_level, me, parametric_groups, selectedEffect } = props

    const [ selectedFilter, setSelectedFilter ] = React.useState( {
        label: "Preference",
        key: "preference"
    } )
    const [ preferences, setPreferences ] = React.useState( [] )
    const [ selected_choice_list, setSelectedChoiceList ] = React.useState( [] )
    const [ weightageList, setWeightageList ] = React.useState( [] )

    React.useEffect( () => {
        if ( preferences ) {
            SetPreferenceHandler( preferences )
        }
    }, [ preferences ] )

    React.useEffect( () => {
        selected_drop_choice( selectedFilter )
        SetPreferenceHandler()
        if ( selectedFilter?.key === 'preference' ) {
            SetPreferenceHandler( preferences )
        }
    }, [ selectedFilter, graph_level ] )

    React.useEffect( () => {
        if ( selectedFilter?.key === 'choice' ) {
            SetPreferenceHandler( selected_choice_list )
        }
    }, [ selected_choice_list, selectedFilter ] )

    React.useEffect( () => {
        if ( selectedFilter?.key === 'weight' ) {
            SetPreferenceHandler( weightageList )
        }
    }, [ weightageList, selectedFilter ] )

    React.useEffect( () => {
        if ( getIntFromString( selected_survey_id ) === SCAPE_PERSONALITY_SURVEY_ID ) {
            SetPreferenceHandler( allPersonalities )
        }
    }, [ selected_survey_id ] )

    React.useEffect( () => {
        if ( table_data) {
            let value = [].concat( ...table_data?.map( item => item?.user?.responseSet?.edges?.map( ( { node } ) => node ) ) )
            let sequence_list = ( [].concat( ...value.map( item => item?.answerSet?.edges?.map( ( { node } ) => node ) ) ) ).filter( item => item?.sequence !== null )

            var setObj = new Set();
            var result = sequence_list.reduce( ( acc, item ) => {
                if ( !setObj.has( item?.sequence ) ) {
                    setObj.add( item?.sequence )
                    acc.push( item )
                }
                return acc;
            }, [] ).sort()
            setPreferences( result?.map( ( item, index ) => {
                let color = newGradient()
                return {
                    id: index + 1,
                    title: `Preferences ${item?.sequence}`,
                    sequence: item?.sequence,
                    // answer: item?.answer,
                    background: index < 10 ? `${preference_colors[ `preference_${index + 1}` ]?.gradient}` : color.gradient,
                    hex: index < 10 ? `${preference_colors[ `preference_${index + 1}` ]?.hex}` : color.hex,
                    gradient_hex: index < 10 ? `${preference_colors[ `preference_${index + 1}` ]?.gradient_hex}` : color.gradient_hex

                }
            } ) )
        }
    }, [ table_data ] )

    React.useEffect( () => {
        if ( selected_choice?.type === 'vertical' ) {
            if ( graph_level === 'level_1' ) {
                let color = newGradient()
                setSelectedChoiceList( [
                    {
                        id: selected_choice?.item?.id,
                        title: selected_choice?.item?.name,
                        background: color.gradient,
                        hex: color.hex,
                        gradient_hex: color.gradient_hex
                    }
                ] );
            }
            else {
                setSelectedChoiceList( selected_choice?.item?.teamSet?.edges?.map( ( { node }, index ) => {
                    let color = newGradient()
                    return {
                        id: node?.id,
                        title: node?.name,
                        background: index < 10 ? `${preference_colors[ `preference_${index + 1}` ]?.gradient}` : color.gradient,
                        hex: index < 10 ? `${preference_colors[ `preference_${index + 1}` ]?.hex}` : color.hex,
                        gradient_hex: index < 10 ? `${preference_colors[ `preference_${index + 1}` ]?.gradient_hex}` : color.gradient_hex
                    }
                } ) )
            }
        }
        else if ( selected_choice?.type === 'team' ) {
            if ( graph_level === 'level_1' ) {
                setSelectedChoiceList( selected_choice?.item?.map( ( value, index ) => {
                    let color = newGradient()
                    return {
                        id: value?.id,
                        title: value?.name,
                        background: index < 10 ? `${preference_colors[ `preference_${index + 1}` ]?.gradient}` : color.gradient,
                        hex: index < 10 ? `${preference_colors[ `preference_${index + 1}` ]?.hex}` :color.hex,
                        gradient_hex: index < 10 ? `${preference_colors[ `preference_${index + 1}` ]?.gradient_hex}` :color.gradient_hex
                    }
                }
                ) );
            } else {
                let groups = parametric_groups?.filter( ( { node } ) => node?.id !== "1" )
                setSelectedChoiceList( groups?.map( ( { node }, index ) => {
                    let color = newGradient()
                    return {
                        id: node?.id,
                        title: node?.name,
                        background: index < 10 ? `${preference_colors[ `preference_${index + 1}` ]?.gradient}` : color.gradient,
                        hex: index < 10 ? `${preference_colors[ `preference_${index + 1}` ]?.hex}` : color.hex,
                        gradient_hex: index < 10 ? `${preference_colors[ `preference_${index + 1}` ]?.gradient_hex}` : color.gradient_hex
                    }
                }
                ) );
            }
        }
        else if ( selected_choice?.type === "employee" ) {
            setSelectedChoiceList( selected_choice?.item?.map( ( value, index ) => {
                let color = newGradient()
                return {
                    id: value?.id,
                    title: value?.user?.firstName,
                    background: index < 10 ? `${preference_colors[ `preference_${index + 1}` ]?.gradient}` : color.gradient,
                    hex: index < 10 ? `${preference_colors[ `preference_${index + 1}` ]?.hex}` : color.hex,
                    gradient_hex: index < 10 ? `${preference_colors[ `preference_${index + 1}` ]?.gradient_hex}` : color.gradient_hex
                }
            }
            ) );
        }
        else if ( selected_choice?.type === "organization" ) {
            if ( graph_level === 'level_1' ) {
                let color = newGradient()
                setSelectedChoiceList( [
                    {
                        id: me?.employee?.organizationSet?.edges[ 0 ]?.node?.id,
                        title: me?.employee?.organizationSet?.edges[ 0 ]?.node?.name,
                        background: color.gradient,
                        hex: color.hex,
                        gradient_hex: color.gradient_hex
                    }
                ] )
            }
            else {
                setSelectedChoiceList( selected_choice?.item?.map( ( item, index ) => {
                    let color = newGradient()
                    return {
                        id: item?.id,
                        title: item?.name,
                        background: index < 10 ? `${preference_colors[ `preference_${index + 1}` ]?.gradient}` : color.gradient,
                        hex: index < 10 ? `${preference_colors[ `preference_${index + 1}` ]?.hex}` : color.hex,
                        gradient_hex: index < 10 ? `${preference_colors[ `preference_${index + 1}` ]?.gradient_hex}` : color.gradient_hex
                    }
                } ) )
            };
        }

    }, [ table_data, graph_level ] )

    React.useEffect( () => {
        console.log("selected_choice",selected_choice)
        if ( selected_choice?.type === 'vertical' ) {
            let color = newGradient()
            setWeightageList(
                [
                    {
                        id: 1,
                        title: `Top Values in ${selected_choice?.item?.name}`,
                        background: color.gradient,
                        hex: color.hex,
                        gradient_hex: color.gradient_hex
                    }
                ]
            )
        }
        else if ( selected_choice?.type === 'team' ) {
            setWeightageList( selected_choice?.item?.map( ( value, index ) => {
                let color = newGradient()
                return {
                    id: index + 1,
                    title: `Top Values in ${value?.name}`,
                    background: index < 10 ? `${preference_colors[ `preference_${index + 1}` ]?.gradient}` : color.gradient,
                    hex: index < 10 ? `${preference_colors[ `preference_${index + 1}` ]?.hex}` : color.hex,
                    gradient_hex: index < 10 ? `${preference_colors[ `preference_${index + 1}` ]?.gradient_hex}` : color.gradient_hex
                }
            }
            )

            )
        }
        else if ( selected_choice?.type === "organization" ) {
            let color = newGradient()
            setWeightageList(
                [
                    {
                        id: 1,
                        title: `Top Values in ${me?.employee?.organizationSet?.edges[ 0 ]?.node?.name}`,
                        background: color.gradient,
                        hex: color.hex,
                        gradient_hex: color.gradient_hex
                    }
                ]
            );
        }
        else if(selected_choice?.type === "employee"){
            setWeightageList(
                selected_choice?.team?.map( ( value, index ) => {
                    let color = newGradient()
                    return {
                        id: index + 1,
                        title: `Top Values in ${value?.name}`,
                        background: index < 10 ? `${preference_colors[ `preference_${index + 1}` ]?.gradient}` : color.gradient,
                        hex: index < 10 ? `${preference_colors[ `preference_${index + 1}` ]?.hex}` : color.hex,
                        gradient_hex: index < 10 ? `${preference_colors[ `preference_${index + 1}` ]?.gradient_hex}` : color.gradient_hex
                    }
                }
                )
            );
        }
    }, [ table_data, graph_level ] )

    const getRandomHexCode = () => {
        return '#' + Math.floor( Math.random() * 16777215 ).toString( 16 );
    }
    const getOpacityColor = ( hex, opacity ) => {
        hex = hex?.replace( /^#/, '' );
        // Parse the hex color into its RGB components
        const r = parseInt( hex.slice( 0, 2 ), 16 );
        const g = parseInt( hex.slice( 2, 4 ), 16 );
        const b = parseInt( hex.slice( 4, 6 ), 16 );
        const rgb = { r, g, b }
        return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${opacity})`;
    }
    const newGradient = () => {
        // Get two random hex codes
        var color1 = getRandomHexCode();
        var color2 = getOpacityColor( color1, .5 );
        return {
            hex: color1,
            gradient_hex: color2,
            gradient: 'linear-gradient(to right, ' + color1 + ', ' + color2 + ')'

        }

    }

    const preference_filter = [
        {
            label: "Preference",
            key: "preference"
        },
        {
            label: "Choice",
            key: "choice"
        },
        {
            label: "Weightage",
            key: "weight"
        }
    ]

    const Filtermenu = () => (
        <Menu>
            {preference_filter?.map( data => (
                <Menu.Item className='ca-search-menu-title' style={{ color: selectedFilter?.label === data?.label ? '#4CCB1F' : '#BBBBBB' }} key={data?.key} onClick={() => { setSelectedFilter( data ) }}>
                    {data?.label}
                </Menu.Item>
            ) )}

        </Menu>
    )

    const personalities = [
        {
            id: '001',
            title: "ISTJ",
            background: `${preference_colors[ `preference_${1}` ]?.gradient}`,
            hex: `${preference_colors[ `preference_${1}` ]?.hex}`,
            gradient_hex: `${preference_colors[ `preference_${1}` ]?.gradient_hex}`
        },
        {
            id: '002',
            title: "ISFJ",
            background: `${preference_colors[ `preference_${2}` ]?.gradient}`,
            hex: `${preference_colors[ `preference_${2}` ]?.hex}`,
            gradient_hex: `${preference_colors[ `preference_${2}` ]?.gradient_hex}`
        },
        {
            id: '003',
            title: "INFJ",
            background: `${preference_colors[ `preference_${3}` ]?.gradient}`,
            hex: `${preference_colors[ `preference_${3}` ]?.hex}`,
            gradient_hex: `${preference_colors[ `preference_${3}` ]?.gradient_hex}`
        },
        {
            id: '004',
            title: "INTJ",
            background: `${preference_colors[ `preference_${4}` ]?.gradient}`,
            hex: `${preference_colors[ `preference_${4}` ]?.hex}`,
            gradient_hex: `${preference_colors[ `preference_${4}` ]?.gradient_hex}`
        },
        {
            id: '005',
            title: "ISTP",
            background: `${preference_colors[ `preference_${5}` ]?.gradient}`,
            hex: `${preference_colors[ `preference_${5}` ]?.hex}`,
            gradient_hex: `${preference_colors[ `preference_${5}` ]?.gradient_hex}`
        },
        {
            id: '006',
            title: "ISFP",
            background: `${preference_colors[ `preference_${6}` ]?.gradient}`,
            hex: `${preference_colors[ `preference_${6}` ]?.hex}`,
            gradient_hex: `${preference_colors[ `preference_${6}` ]?.gradient_hex}`
        },
        {
            id: '007',
            title: "INFP",
            background: `${preference_colors[ `preference_${7}` ]?.gradient}`,
            hex: `${preference_colors[ `preference_${7}` ]?.hex}`,
            gradient_hex: `${preference_colors[ `preference_${7}` ]?.gradient_hex}`
        },
        {
            id: '008',
            title: "INTP",
            background: `${preference_colors[ `preference_${8}` ]?.gradient}`,
            hex: `${preference_colors[ `preference_${8}` ]?.hex}`,
            gradient_hex: `${preference_colors[ `preference_${8}` ]?.gradient_hex}`
        },
        {
            id: '009',
            title: "ESTP",
            background: `${preference_colors[ `preference_${9}` ]?.gradient}`,
            hex: `${preference_colors[ `preference_${9}` ]?.hex}`,
            gradient_hex: `${preference_colors[ `preference_${9}` ]?.gradient_hex}`
        },
        {
            id: '0010',
            title: "ESFP",
            background: `${preference_colors[ `preference_${10}` ]?.gradient}`,
            hex: `${preference_colors[ `preference_${10}` ]?.hex}`,
            gradient_hex: `${preference_colors[ `preference_${10}` ]?.gradient_hex}`
        },
      
    ]
    const [allPersonalities,SetAllPersonalities] = React.useState([])
    React.useEffect(()=>{
        if(selected_survey_id && !allPersonalities?.length){

            if(getIntFromString( selected_survey_id ) == SCAPE_PERSONALITY_SURVEY_ID ){
                let list = [].concat(personalities)
                let values = [  {
                    id: '0011',
                    title: "ENFP",
                },
                {
                    id: '0012',
                    title: "ENTP",
                },
                {
                    id: '0013',
                    title: "ESTJ",
                },
                {
                    id: '0014',
                    title: "ESFJ",
                },
                {
                    id: '0015',
                    title: "ENFJ",
                },
                {
                    id: '0016',
                    title: "ENTJ",
                }]
               
                values.forEach(i=>{
                    let color = newGradient()
                    i['background']= color?.gradient
                    i['hex']= color?.hex
                    i['gradient_hex']= color?.gradient_hex
                    list.push(i)
                })
                SetAllPersonalities(list)
            }
        }
        
    },[selected_survey_id])

    return (
        <>
            {getIntFromString( selected_survey_id ) !== SCAPE_PERSONALITY_SURVEY_ID ?
                <div className='scape-preference-tab-container'>
                    <Dropdown overlay={Filtermenu} className="scape-input-filter" >
                        <DropdownButton style={{ border: "1px solid #ECF1F4", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <p className="ca-search-menu-title" style={{ margin: "0px", width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'left' }}>{selectedFilter?.label ? selectedFilter?.label : <p className='rd-search-drop-placeholder'>Filter</p>}</p>
                            <img src={scape_report_home_icon?.dropdown_Arrow} alt="" style={{ width: "10px", height: "10px" }} />
                        </DropdownButton>
                    </Dropdown>
                    <div className='scape-preference-tab'>
                        {selectedFilter?.label === "Preference" && preferences?.map( ( item ) => (
                            <div className='scape-preference' onClick={() => selectedEffect( item )}>
                                {item?.title}
                                <div className='scape-preference-color-container' style={{ backgroundImage: item?.background }}></div>
                            </div>
                        ) )}
                        {selectedFilter?.label === "Choice" && selected_choice_list?.map( ( item ) => (
                            <div className='scape-preference' onClick={() => selectedEffect( item )}>
                                {item?.title}
                                <div className='scape-preference-color-container' style={{ backgroundImage: item?.background }}></div>
                            </div>
                        ) )}
                        {selectedFilter?.label === "Weightage" && weightageList?.map( ( item ) => (
                            <div className='scape-preference' onClick={() => selectedEffect( item )}>
                                {item?.title}
                                <div className='scape-preference-color-container' style={{ backgroundImage: item?.background }}></div>
                            </div>
                        ) )}
                    </div>
                </div> :
                <div className='scape-preference-tab-container'>
                    <div className='scape-preference-tab'>
                        {allPersonalities?.map( item => (
                            <div className='scape-preference' onClick={() =>selectedEffect( item )}>
                                {item?.title}
                                <div className='scape-preference-color-container' style={{ backgroundImage: item?.background }}></div>
                            </div>
                        ) )}
                    </div>
                </div>
            }
        </>
    )

}


export default ScapePreferneceTabView
const DropdownButton = styled( Button )`
width:fit-content;
min-width:10em;
display:flex;
height:36px;
border-left:none;
justify-content:space-between;
align-items:center;
gap:5px
`