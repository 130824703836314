import new_back_arrow from '../assets/new_back_arrow.svg'
import dropdown_Arrow from '../assets/dropdown_Arrow.svg'
import green_tick from '../assets/green_tick.svg'
import sr_graph_left from '../assets/sr-graph-left.svg'
import sr_graph_right from '../assets/sr-graph-right.svg'
import next_scape from '../assets/next_scape.png'
import next_scape_dis from '../assets/next_scape_dis.png'
import previous_scape from '../assets/previous_scape.png'
import prev_scape_dis from '../assets/prev_scape_dis.png'

export const scape_report_home_icon ={
    new_back_arrow,
    dropdown_Arrow,
}

export const SCAPE_REPORT_TABLE_ICON =  {
    green_tick
}

export const SCAPE_REPORT_GRAPH_VIEW =  {
    sr_graph_left,
    sr_graph_right,
    next_scape,
    next_scape_dis,
    previous_scape,
    prev_scape_dis
}