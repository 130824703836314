import gql from 'graphql-tag';
import { USER_TYPE } from './UserType.gql';

export const LIST_USERS_FOR_ORG_QUERY = gql`
  ${USER_TYPE}
  query listUsersForOrg($before: String, $after: String, $first: Int, $last: Int) {
    listUsersForOrg(before: $before, after: $after, first: $first, last: $last) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...UserType
        }
      }
    }
  }
`;
