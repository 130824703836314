import React from 'react';
import { Empty } from 'antd';

import { OPERAND_VALUE } from '../../constants';
import SelectField from './SelectField';

const OperandField = props => {
  const { margin } = props;

  return (
    <SelectField
      margin={margin}
      showSearch
      choices={OPERAND_VALUE}
      offset={1}
      style={{ width: '100%', marginBottom: !margin && 0 }}
      notFoundContent={<Empty />}
      filterOption={(input, option) => option?.children.props.children.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0}
      {...props}
    />
  );
};

export default OperandField;
