// @ts-nocheck

import React from "react";
import { compose } from "../../core";
import { withApollo } from '@apollo/client/react/hoc';
import BadgeFamilyListView from "../components/badge_family_list_view";
import { globalPermissionValidator } from "modules/look";
import { BADGE_FAMILY_SUBSCRIPTION, BADGE_SUBSCRIPTION } from "../graphql/badgeFamilySub.gql";
import { kudos_permission } from "Permissions/kudos.permission";
import NoPermissionView from "modules/kudos-user/component/NoPermissionComponent";




const BadgeFamilyHomeContainer = (props) => {
  const { client, me, history, userPermission } = props
  const [loading, setloading] = React.useState(false)
  const [permission, setpermission] = React.useState()
  const [subcriptionChange, setsubcriptionChange] = React.useState([])
  const [showPermission, setShowPermission] = React.useState(false)
  let badgeFamilySub = undefined
  let badgeSub = undefined

  React.useEffect(() => {
    if (userPermission?.length) {
      let permission = globalPermissionValidator(kudos_permission, userPermission)
      if (permission?.requried_permission) {
        subscribeToMore()
        setShowPermission(false)
      }
      else {
        setShowPermission(true)
      }
      setpermission(permission)
    }
    else {
      setpermission(null)
    }
  }, [userPermission])



  const subscribeToMore = () => {
    //badge family sub
    if (!badgeFamilySub) {
      badgeFamilySub = client.subscribe({
        query: BADGE_FAMILY_SUBSCRIPTION,
        fetchPolicy: 'network-only',
      }).subscribe({
        next(result) {
          if (result.data.badgeFamilySubscription) {
            setsubcriptionChange([result.data.badgeFamilySubscription])
          }
        }
      })
    }

    if (badgeSub) {
      
      badgeSub = client.subscribe({
        query: BADGE_SUBSCRIPTION,
        fetchPolicy: 'network-only',
      }).subscribe({
        next(result) {
          
          if (result.data.badgeSubscription) {
            setsubcriptionChange([result.data.badgeSubscription])
          }
        }
      })
    }


  }

  return (
    <>
      {(permission && permission?.requried_permission) && (
        <BadgeFamilyListView showPermission={showPermission} permission={permission} subcriptionChange={subcriptionChange} loading={loading} {...props} />
      )
      }


      {(permission && !permission?.requried_permission) && (
        <NoPermissionView />
      )
      }
    </>
  );
};

export default compose(withApollo)(BadgeFamilyHomeContainer);
