import React from 'react';
import { Bar} from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const TeamDiversityBarChart =(props)=>{
    const {filter,graph_data_list} = props
    const [data_list,setDataList]=React.useState([])

    React.useEffect(()=>{
        if(graph_data_list?.length){
            setDataList(graph_data_list)
        }
    },[graph_data_list])

    const chartData = {
        labels:data_list?.map(i=>i.title),
        datasets: [
            {
                data: data_list?.map(i=>i.value),
                backgroundColor: data_list?.map(i=>i.color),
                
                barThickness: 50, // Adjust the bar width
                borderRadius: 8, // Set the border radius for all four corners
                categoryPercentage: 0.9,
            }
        ]
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scrollX: {
            enabled: true
        },
        scales: {
            y: {
                min: 0,
                max: 100,
                ticks: {
                    color: '#000',
                    font: {
                        size: 13,
                        weight:600,

    
                    },
                    callback: function(value) {
                    return value + '%';
                    }
                },
                grid: {
                    display: true,
                    color:"rgba(201, 208, 218, 1)",
                    borderDash: [8, 8],
                }
            },
            x: {
                grid: {
                    display: false,
                    offset: false
                },
                ticks: {
                    color: '#000',
                    font: {
                        size: 13,
                        weight:600
                    }
                }
            }
        },
        indexAxis: 'x',
        elements: {
            bar: {
                borderWidth: 0,
                borderHeight: 0
            },
        },
        plugins: {
            legend: {
                position: 'bottom',
                display: false,
            },
            tooltip: {
                backgroundColor:'#0E0E2C',
                callbacks: {
                    label: function (context) {
                        
                        let doc = data_list[context?.dataIndex]?.detail
                        if(doc?.length>100){
                            doc = doc?.slice(0,100)+'...'
                        }
                        return doc||''
                        ;
                    }
                }
            },
            title: {
                display: false,
            }
        },
    };
    return (
        <>
            <h4 style={{fontSize:"1em",fontFamily:"Poppins",fontWeight:"700",fontStyle:"normal",color:"#4CCB1F",margin:"0"}}>{filter}</h4>
            <div className='ca-scroll-container' >
            <Bar style={{minWidth:"800px"}} data={chartData} options={options} height={'150px'}/>
            </div>
            {/* <Line options={options} data={chartData} /> */}
        </>
        
    )

}

export default TeamDiversityBarChart
