import gql from 'graphql-tag';

// import { EMPLOYEE_TYPE } from '../../organization/graphql/EmployeeType.gql';
// import { RESPONSE_TYPE } from '../../survey/graphql/ResponseType.gql';
// import { PROFILE_TYPE } from './ProfileType.gql';
//  ${EMPLOYEE_TYPE}, ${RESPONSE_TYPE}, ${PROFILE_TYPE}

export const USER_TYPE = gql`
  fragment UserType on UserType {
    id
    username
    firstName
    lastName
    email
    isActive
    dateJoined
    # updatedId {
    #   ...EmployeeType
    # }
    # responseSet {
    #   ...ResponseType
    # }
    # profile {
    #   ...ProfileType
    # }
    # UserprofileCraetedId {
    #   ...ProfileType
    # }
    # UserprofileUpdatedId {
    #   ...ProfileType
    # }
  }
`;
