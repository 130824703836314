const baseName = '/myorganization';
const editBaseName = '/edit';
const verticalBaseName = '/vertical';
const newBaseName = '/new';
const detailBaseName = '/detail';
const teamBaseName = '/team';
const emploeeBaseName = '/employee';
const reportBaseName = '/report';

module.exports = {

    empoloyee: baseName + '/employee',

    organization: baseName + '/myorganization-detail',
    organizationDetail: baseName + '/myorganization-detail' + '/:id',
    organizationDetailLink: baseName + '/myorganization-detail' + '/',
    organizationReport: baseName + '/myorganization-detail' + '/:id' + reportBaseName + '/',
    organizationReportVertical: baseName + verticalBaseName + '/:id' + reportBaseName + '/',

    verticalDetail: baseName + verticalBaseName + detailBaseName + verticalBaseName + '/:id',
    verticalDetailLink: baseName + verticalBaseName + detailBaseName + verticalBaseName + '/',
    teamDetail: baseName + teamBaseName + detailBaseName + teamBaseName + '/:id',
    teamDetailLink: baseName + teamBaseName + detailBaseName + teamBaseName + '/',
    addVertical: baseName + verticalBaseName + newBaseName,
    addemployee: baseName + '/employee' + newBaseName,
    addTeam: baseName + teamBaseName + newBaseName,

    editVertical: baseName + verticalBaseName + editBaseName + '/:id',
    editVerticalLink: baseName + verticalBaseName + editBaseName + '/',
    editOrganization: baseName + editBaseName + '/:id',
    editOrganizationLink: baseName + editBaseName + '/',
    editTeam: baseName + teamBaseName + editBaseName + '/:id',
    editOrgEmployeeLink: baseName + emploeeBaseName + editBaseName + '/',
    editOrgEmployee: baseName + emploeeBaseName + editBaseName + '/:id',
    editTeamLink: baseName + teamBaseName + editBaseName + '/',
};