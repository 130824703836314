import gql from 'graphql-tag';
import { CUSTOM_SIMPLE_FIELD_TYPE } from './CustomSimpleFieldType.gql';

export const CUSTOM_SIMPLE_FIELD_TYPE_CONNECTION = gql`
  ${CUSTOM_SIMPLE_FIELD_TYPE}
  fragment Custom_SimpleFieldTypeConnection on Custom_SimpleFieldTypeConnection {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...Custom_SimpleFieldType
      }
    }
    totalCount
    edgeCount
  }
`;
