import { Row } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';


const MappModulesCardsView = props => {

    const { setHoveredId, card } = props

    const history = useHistory()

    // remove developed tools logic from line no 26 once all tools gets developed
    const develoedTools = [1,2,3,4,6,5]

    return (
        <>
            <Row justify='start' style={{ flexDirection: 'column',cursor: develoedTools?.includes(card?.id) ? "pointer" : "default"}}  onClick={(e) => 
                   {
                    if(develoedTools?.includes(card?.id)){
                        history.push(`/intro/tool/${card.type}/${card.id}`)
                    }
                   }
                  
                    } className="tool-card-sales-page" onMouseEnter={(e) => setHoveredId(card.id)} onMouseLeave={(e) => setHoveredId(null)} >
                <Row justify='start' style={{height:'76px'}}>
                    <div className='card-image-sales-page'>{card.image}</div>
                </Row>
                <div className='tool-title-sales-pape poppins' style={{ marginTop: '20px', marginBottom: '20px' }}>{card.title}</div>
                <div className='tool-description-sales-pape poppins'>{card.description}</div>
                <div className='sales-page-tool-link poppins pointer' >Learn more</div>
            </Row>
        </>
    );
};

export default MappModulesCardsView;

