import React from 'react';
import { Link } from 'react-router-dom';
import { compose } from '../../core';
import { withApollo } from '@apollo/client/react/hoc';

import {
  AdminLayout,
  displayDataCheck,
  getIntFromString,
  displayBoolean,
  GetColumnSearchProps,
  getOrderBy
} from '../../look';
import { FAQ_CATEGORY_SUBSCRIPTION } from '../graphql/FaqSubscription.gql';
import ROUTE from '../route';
import { withDeleteFaqCategory, withPageFilterUpdating, withPageState } from './PageOperations';
import { ALL_FAQ_CATEGORIES_QUERY } from '../graphql/AllFaqCategoriesQuery.gql';


const Organization = props => {
  const {
    loading,
    allFaqCategories,
    deleteFaqCategory,
    onPaginationChange,
    onFiltersRemove,
    onOrderByChange,
    orderBy,
    onNameChange,
    onDescriptionChange,
    client,filter,pagination,me
  } = props;

  const [allCategories,setAllFaqCategories] = React.useState(undefined);
  const [paginationFilter,setPaginationFilter] = React.useState({first: 10,offset: 0});

  let faqCategorySubscription = undefined;
  const m = React.useRef(true)

  const faqCategoryDataRef = React.useRef(null);

  React.useEffect(()=>{
    let updatedFilters;
     if(filter?.email_Icontains?.length || filter?.message_Icontains?.length || filter?.searchText?.length || filter?.name_Icontains?.length){
       
       updatedFilters = {...filter}
      
     }else{
       updatedFilters = {...paginationFilter,...filter}
     }
    
     getAllFAQs(updatedFilters)
 
   },[filter])


  React.useEffect(() => {
    return () => {
      m.current = false
    }
  }, [])

  React.useEffect(() => {
    m.current = true

  }, [])

  React.useEffect(() => {
    return () => {
      if (faqCategorySubscription) {
        faqCategorySubscription.unsubscribe();
      }
    }
  }, [])
 

 

  const SubscribeToFaqCategory = async () => {

    faqCategorySubscription = client.subscribe({
      query: FAQ_CATEGORY_SUBSCRIPTION,
    }).subscribe({
      next(result) {

        switch (result.data.faqCategorySubscription.mutation) {
         
          case 'DELETE':
           
            if (m.current) {
              let categorySet = faqCategoryDataRef.current?.edges
              let categorySetWithDeletedRecord = categorySet?.filter((category)=>
              category?.node?.id !== result.data.faqCategorySubscription?.faqCategory?.id)
              let cSet = {edgeCount:(faqCategoryDataRef.current?.edgeCount - 1 ),totalCount:(faqCategoryDataRef.current?.totalCount - 1 ),edges:categorySetWithDeletedRecord}
              setAllFaqCategories(cSet);
              faqCategoryDataRef.current = cSet;
            }

            break;
          default:
            break
        }

      }
    })

  }



  const getAllFAQs = async (filters) => {

    if(filters?.first){
      setPaginationFilter({...filters})
    }
    
    const { data } = await client.query({
      query: ALL_FAQ_CATEGORIES_QUERY,
      variables: {...filters, orderBy},
      fetchPolicy: 'network-only',
    });
    
    if (data?.allFaqCategories) {
      setAllFaqCategories(data?.allFaqCategories);
      SubscribeToFaqCategory();
      faqCategoryDataRef.current = data?.allFaqCategories;
      
    }
  }





  const columns = [
    {
      title: 'Name',
      key: 'name',
      sorter: () => onOrderByChange(getOrderBy('name', orderBy)),
      ...GetColumnSearchProps('name', onNameChange),
      render: (text, record) =>
        record && <Link to={`${ROUTE.faqQuestionLink}${getIntFromString(record.id)}`}>{displayDataCheck(record.name)}</Link>
    },
    {
      title: 'Description',
      key: 'description',
      sorter: () => onOrderByChange(getOrderBy('description', orderBy)),
      ...GetColumnSearchProps('description', onDescriptionChange),
      render: (text, record) => record && displayDataCheck(record.description)
    },
    {
      title: 'Active',
      key: 'active',
      sorter: () => onOrderByChange(getOrderBy('active', orderBy)),
      render: (text, record) => displayBoolean(record.active)
    }
  ];




  const handleDeleteQuestion = async(id) =>{
    await deleteFaqCategory({categoryId:id})
   }
 


  

  return (
    <AdminLayout
      table={true}
      active={ROUTE.faq}
      title="FAQ"
      loading={loading}
      tableData={allCategories}
      columns={columns}
      onDelete={deleteFaqCategory}
      handleDeleteRecord = {handleDeleteQuestion}
      specificMutation = {true}
      editLink={ROUTE.editFaqLink}
      addLink={ROUTE.addFaq}
      onPaginationChange={getAllFAQs}
      onFiltersRemove={onFiltersRemove}
    />
  );
};

export default compose(withPageState, withDeleteFaqCategory, withPageFilterUpdating, withApollo)(Organization);
