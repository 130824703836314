import gql from 'graphql-tag';
import { USER_TYPE } from '../../user/graphql/UserType.gql';
// import { TOOL_TYPE } from '../../tool/graphql/ToolType.gql';

// , ${TOOL_TYPE}
export const CUSTOM_USER_COMMITMENT_TYPE = gql`
  ${USER_TYPE}
  fragment CustomUserCommitmentType on CustomUserCommitmentType {
    id
    coaching{
      id
      createdAt
      
      coachee{
        id
        user{
          id
          firstName
          lastName
        }
}
      coach{
        id
        user{
          id
          firstName
          lastName
        }
      }
}
    user {
      ...UserType
    }
    title
    description
    tool {
      id
      title
      uiFlow
      thumbnailImage
      description
      status
      public
      pubDate
    }
    completed
    dateCreated
    dateCompleted
    dueDate
  }
`;
