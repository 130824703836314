import gql from 'graphql-tag';
export const BADGE_FAMILY_SUBSCRIPTION =gql`
subscription badgeFamilySubscription {
    badgeFamilySubscription {
    mutation
    badgeFamily{
        id
        title
        description
        organization{
            id
        }
        badgeSet{
          edges{
            node{
              id
              title
              behaviouralDescription
              image
              duration
            }
          }
        }
    }
  }
}
`

export const BADGE_SUBSCRIPTION =gql`
subscription badgeSubscription {
  badgeSubscription {
    mutation
    badge{
      id
      title
      behaviouralDescription
      image
      duration
    }
  }
}
`