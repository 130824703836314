import React from 'react';
import { DatePicker, Form, Input, Space } from 'antd';
import { Tooltip } from 'antd';
import { PlusSquareOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import ad_icon from '../../../assets/grow-drag-add-icon.svg'
const TextWithDateField = props => {
    const { type = 'input', onChange, disabled, offset = 1, onBlur, margin, scrolltoview, selectedid, count = 1, ...rest } = props;

    const [ numOfFields, setNumOfFields ] = React.useState( count )

    const [ results, setResults ] = React.useState( [] )

    const FormItem = Form.Item;
    const { TextArea } = Input;

    const onPlus = () => {
        setNumOfFields( numOfFields + 1 )
    }

    React.useEffect( () => {
        if ( props.form !== undefined ) {
            props.form.setFieldsValue( {
                [ props.name ]: results
            } )
        }
    }, [ results ] )


    const onValsChange = ( typ, e, indx ) => {
        console.log( "typ, e ,indx", typ, e, indx, results.length, results[ indx ] )
        if ( results.length >= indx + 1 && indx !== 0 ) {
            console.log( "calling inbtw" )
            let p = results.slice( 0, indx )
            let n = results.slice( indx + 1, results.length )
            let c = results[ indx ]
            console.log( "pnc", p, n, c )
            if ( typ === "txt" ) {
                c[ 0 ] = e
            } else {
                c[ 1 ] = e
            }
            setResults( [ ...p, c, ...n ] )
        }
        if ( indx === 0 ) {
            console.log( "calling 0" )
            let c = []
            let nxt = []
            if ( results.length !== 0 ) {
                c = results[ indx ]
                nxt = results.slice( 1, results.length )
            }
            if ( typ === "txt" ) {
                c[ 0 ] = e
            } else {
                c[ 1 ] = e
            }
            setResults( [ c, ...nxt ] )
        }
        if ( results.length < indx + 1 && indx !== 0 ) {
            console.log( "calling end" )
            let c = []
            if ( typ === "txt" ) {
                c[ 0 ] = e
            } else {
                c[ 1 ] = e
            }
            setResults( [ ...results, c ] )
        }
    }



    return (
        <>

            <div style={{ marginBottom: margin ? margin : '5vh', width: '100%' }} >

                <FormItem style={{marginBottom:margin ? margin : '24px'}} labelCol={{ span: 24 }} wrapperCol={{ span: 24, offset }} required={props.required} {...rest}>
                    {[ ...Array( numOfFields ) ].map( ( x, i ) =>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: "space-between", gap: "1em" ,marginTop:"1em"}} key={i}>
                            <span style={{ flexGrow: 3 }}>
                                {type === 'textarea' ? (
                                    <TextArea className="grow-survey-input-filed" rows={6} onChange={onChange} disabled={disabled} />
                                ) : type === 'number' ? (
                                    <NumericInput className="grow-survey-input-filed" onChange={onChange} disabled={disabled} type='number' pattern="[0-9]+"
                                        inputmode="numeric" />
                                ) : (
                                    <span style={{ display: 'flex', width: "100%", flexDirection: "row", gap: "2em", alignItems: 'center' }}>
                                        <Input className="grow-survey-input-filed" id={selectedid} autoComplete='off' onChange={e => { onValsChange( "txt", e.target.value, i ); scrolltoview( selectedid ) }} onBlur={onBlur} disabled={disabled} style={{ width: "100%", minWidth: "300px" }} />
                                        <span className='coaching-engagement-form-lable'> By</span>
                                        <DatePicker className='generic-survey-date-picker-custom' id={selectedid} onChange={e => { onValsChange( "date", e, i ); scrolltoview( selectedid ) }} style={{ width: "100%",minWidth:"17em",height:"2.5em",maxWidth:'20em' }} disabledDate={( current ) => current.isBefore( moment().subtract( 1, "day" ) )} />
                                    </span>

                                )}
                            </span>
                            {i + 1 === numOfFields && (
                                <span>
                                    <img src={ad_icon} style={{ width: "2.5em", height: "2.5em", cursor: 'pointer',marginTop:".3em" }} alt="ad_icon" onClick={onPlus} />
                                    {/* <PlusSquareOutlined onClick={onPlus}/> */}
                                </span>
                            )}
                            {i + 1 !== numOfFields && (
                                <span style={{ width: "2.5em", height: "2.5em"}}>
                                    {/* <img src={ad_icon} style={{ width: "2.5em", height: "2.5em", cursor: 'pointer' }} alt="ad_icon" onClick={onPlus} /> */}
                                </span>
                            )}
                        </div>
                    )}
                </FormItem>
            </div>

        </>
    );
};

export default TextWithDateField;

class NumericInput extends React.Component {
    onChange = e => {
        const { value } = e.target;
        const reg = /^[0-9]+$/;
        if ( ( !isNaN( value ) && reg.test( value ) ) || value === '' || value === '-' ) {
            this.props.onChange( Number( value ) );
        }
    };

    // '.' at the end or only '-' in the input box.
    onBlur = () => {
        const { value, onBlur, onChange } = this.props;
        let valueTemp = value;
        if ( !( typeof value === 'number' && isFinite( value ) ) ) {
            if ( value?.charAt( value?.length - 1 ) === '.' || value === '-' ) {
                valueTemp = value?.slice( 0, -1 );
            }
            onChange( valueTemp?.replace( /0*(\d+)/, '$1' ) );
            if ( onBlur ) {
                onBlur();
            }
        }
    };

    render() {
        const { value } = this.props;
        const title = value ? (
            <span className="numeric-input-title">{value !== '-' ? formatNumber( value ) : '-'}</span>
        ) : (
            'Input a number'
        );
        return (
            <Tooltip trigger={[ 'focus' ]} title={title} placement="topLeft" overlayClassName="numeric-input">
                <Input className="grow-survey-input-filed" autoComplete='off' {...this.props} onChange={this.onChange} onBlur={this.onBlur} placeholder="Input a number" maxLength={25} />
            </Tooltip>
        );
    }
}

function formatNumber( value ) {
    value += '';
    const list = value.split( '.' );
    const prefix = list[ 0 ].charAt( 0 ) === '-' ? '-' : '';
    let num = prefix ? list[ 0 ].slice( 1 ) : list[ 0 ];
    let result = '';
    while ( num.length > 3 ) {
        result = `,${num.slice( -3 )}${result}`;
        num = num.slice( 0, num.length - 3 );
    }
    if ( num ) {
        result = num + result;
    }
    return `${prefix}${result}${list[ 1 ] ? `.${list[ 1 ]}` : ''}`;
}
