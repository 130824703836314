import gql from 'graphql-tag';
import { CHOICE_TYPE } from './ChoiceType.gql';

export const CHOICE_TYPE_CONNECTION = gql`
  ${CHOICE_TYPE}
  fragment ChoiceTypeConnection on ChoiceTypeConnection {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...ChoiceType
      }
    }
    totalCount
    edgeCount
  }
`;
