import gql from 'graphql-tag';


export const RESPONSE_BY_RESPONSE_ID_OPTIMIZED = gql`

  query responseByResponseId($id: ID!) {
    responseByResponseId(id: $id) {
        id
        survey {
            id
            reportQuery
            multisourceResponse
        }
        responseDuration
        responseDate
        norm
    }
  }
`;
