import { MoreOutlined } from '@ant-design/icons';
import { withApollo } from '@apollo/client/react/hoc';
import { Button, Card, Col, Dropdown, Empty, Menu, Row, Spin, Switch, Tag } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { compose } from '../../core';
import {
  CardLayout, displayBoolean, displayDataCheck, displayUtcToUserTimezone, GetColumnSearchProps,
  getIntFromString,
  getOrderBy, Pagination,
  SelectFilterField
} from '../../look';

import { IMG, PRIMARY_CLR } from '../../../config';
import { ALL_STORIES_QUERY } from '../graphql/AllStories.gql';
import { STORY_SUBSCRIPTION } from '../graphql/StorySubscription.gql';
import ROUTE from '../route';
import { withDeleteStory, withEditStory, withStoryFilterUpdating, withStoryState } from './StoryOperations';
import { subscribeToStory } from './StorySubscriptions';

const Story = props => {
  const {
    loading,
    allStories,
    onPaginationChange,
    deleteStory,
    onOrderByChange,
    orderBy,
    onFiltersRemove,
    onTitleChange,
    onContentChange,
    onAuthorChange,
    subscribeToMore,
    editStory,
    pagination,
    client,me,
    filter
  } = props;

  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
  const [allStoriesSet,setAllStories] = React.useState(undefined);
  const [actionLoading, setActionLoading] = React.useState(false);

  React.useEffect(() => {
    const subscribe = subscribeToStory(subscribeToMore /* , props.filter */);
    return () => subscribe();
  });

  const handleSelect = id => {
    setSelectedRowKeys(
      selectedRowKeys.indexOf(id) === -1 ? [...selectedRowKeys, id] : selectedRowKeys.filter(s => s !== id)
    );
  };

  const storyDataRef = React.useRef(null);

  let storySubscription = undefined;
  const m = React.useRef(true)


  React.useEffect(()=>{
    getAllStories();

  },[me])


  React.useEffect(() => {
    return () => {
      m.current = false
    }
  }, [])

  React.useEffect(() => {
    m.current = true

  }, [])

  React.useEffect(() => {
    return () => {
      if (storySubscription) {
        storySubscription.unsubscribe();
      }
    }
  }, [])
 

  const SubscribeToStories = () => {

    storySubscription = client.subscribe({
      query: STORY_SUBSCRIPTION,
    }).subscribe({
      next(result) {

        switch (result.data.storySubscription.mutation) {
         
          case 'DELETE':
           
            if (m.current) {
              let storySet = storyDataRef.current?.edges
              let storySetWithDeletedRecord = storySet?.filter((category)=>
              category?.node?.id !== result.data.storySubscription?.story?.id)
              let updatedStorySet = {edgeCount:(storyDataRef.current?.edgeCount - 1 ),totalCount:(storyDataRef.current?.totalCount - 1 ),edges:storySetWithDeletedRecord}
              setAllStories(updatedStorySet);
              storyDataRef.current = updatedStorySet;
            }

            break;
          default:
            break
        }

      }
    })

  }

  

  const getAllStories = async () => {
    
    const { data } = await client.query({
      query: ALL_STORIES_QUERY,
      variables: {...pagination, orderBy,...filter},
      fetchPolicy: 'network-only',
    });
    
    if (data?.allStories) {
      setAllStories(data?.allStories);
      storyDataRef.current = data?.allStories;
      SubscribeToStories();
    }
  }

  

  const handleDelete = async id => {
    // setDeleteLoading(true);
    try {
      await deleteStory({id:id});
    } catch (e) {
      throw new Error(e.message);
    }
    // setDeleteLoading(false);
  };
  const handleEdit = async (e, id) => {
    setActionLoading(true);
    try {
      await editStory({
        id: Number(getIntFromString(id)),
        published: e
      });
      setActionLoading(false);
    } catch (e) {
      setActionLoading(false);
      throw new Error(e.message);
    }
  };

  const columns = [
    {
      title: 'Title',
      key: 'title',
      sorter: () => onOrderByChange(getOrderBy('title', orderBy)),
      ...GetColumnSearchProps('title', onTitleChange),
      render: (text, record) =>
        record && <Link to={`${ROUTE.commentLink}${getIntFromString(record.id)}`}>{displayDataCheck(record.title)}</Link>
    },
    {
      title: 'Author',
      key: 'author',
      sorter: () => onOrderByChange(getOrderBy('author', orderBy)),
      ...GetColumnSearchProps('author', onAuthorChange, 'user'),
      render: (text, record) => record && displayDataCheck(record.author && `${record.author?.split(',')[0]}`)
    },
    {
      title: 'Tags',
      key: 'tags',
      render: (text, record) => (
        <>
          {record.storyTags.edges.map(({ node }) => (
            <Tag color="orange">{node.name}</Tag>
          ))}
        </>
      )
    },
    {
      title: 'Content',
      key: 'content',
      ellipsis: true,
      sorter: () => onOrderByChange(getOrderBy('content', orderBy)),
      ...GetColumnSearchProps('content', onContentChange),
      render: (text, record) =>
        record && (
          <Button
            type="link"
            onClick={() => {
              // setContent(withHTMLFieldValue(displayDataCheck(record.content)));
              // setVisible(true);
            }}
          >
            View
          </Button>
        )
    },
    {
      title: 'Published',
      key: 'published',
      render: (text, record) => displayBoolean(record.published)
    },
    {
      title: 'Date',
      key: 'datecreated',
      sorter: () => onOrderByChange(getOrderBy('datecreated', orderBy)),
      render: (text, record) => `${displayUtcToUserTimezone(record.datecreated, 'YYYY-M-DD HH:mm')}`
    },
    {
      title: 'Link',
      key: 'link',
      render: (text, record) => (
        <Button
          type="link"
          disabled={!record.published}
          href={`${ROUTE.storyDetailLink}${getIntFromString(record.id)}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Link
        </Button>
      )
    }
  ];
  const menu = id => (
    <Menu>
      <Menu.Item key="0">
        <Link to={`${ROUTE.editStoryLink}${id}`}>Edit</Link>
      </Menu.Item>
      <Menu.Item key="1" onClick={() => handleDelete(id)} danger={true}>
        Delete
      </Menu.Item>
      <Menu.Item key="3" onClick={() => handleSelect(id)}>
        Select
      </Menu.Item>
    </Menu>
  );

  const handleDeleteStory = async(id) =>{
    await deleteStory({id:id})
   }
 

  return (
    <CardLayout
      table={true}
      active={ROUTE.story}
      title={[{ name: 'Stories' }]}
      loading={loading}
      tableData={allStoriesSet}
      columns={columns}
      handleDeleteRecord = {handleDeleteStory}
      specificMutation = {true}
      onDelete={deleteStory}
      editLink={ROUTE.editStoryLink}
      addLink={ROUTE.addStory}
      onPaginationChange={onPaginationChange}
      onFiltersRemove={onFiltersRemove}
      selectedRowKeys={selectedRowKeys}
      extra={
        <SelectFilterField
          placeholder="Search Stories by"
          onFiltersRemove={onFiltersRemove}
          options={[
            {
              label: 'Title',
              type: 'text',
              value: onTitleChange
            },
            {
              label: 'Author',
              type: 'user',
              value: onAuthorChange
            },
            {
              label: 'Content',
              type: 'text',
              value: onContentChange
            }
          ]}
        />
      }
    >
      <Spin spinning={loading || actionLoading} size="large">
        <div style={{ minHeight: '70vh' }}>
          <Row gutter={[24, 24]}>
            {allStoriesSet?.edges?.map(({ node: story }) => (
              <Col xs={24} md={12} lg={8}>
                <Dropdown overlay={() => menu(getIntFromString(story.id))} placement="bottomLeft">
                  <MoreOutlined
                    style={{
                      fontSize: 'large',
                      position: 'absolute',
                      right: 0,
                      zIndex: 1,
                      margin: '16px 24px'
                    }}
                  />
                </Dropdown>
                <Switch
                  checked={story.published}
                  onChange={e => handleEdit(e, story.id)}
                  size="small"
                  style={{ color: '#84A100', position: 'absolute', zIndex: 1, top: 325, left: 30 }}
                />
                <Link to={`${ROUTE.adminStoryDetailLink}${getIntFromString(story.id)}`}>
                  {/* {console.log(selectedRowKeys.indexOf(getIntFromString(story.id)) !== -1)} */}
                  <StyledCard
                    bodyStyle={{
                      padding: '15px 18px'
                    }}
                    cover={
                      <img
                        alt={story.title}
                        src={story.thumbnailImage || IMG}
                        style={{ /* width: '100%', */ height: '250px' }}
                      />
                    }
                    active={selectedRowKeys.indexOf(getIntFromString(story.id)) !== -1}
                  >
                    <div style={{ marginBottom: '8px' }}>
                      <h3 style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{story.title}</h3>
                      <h5 style={{ marginBottom: '1em', fontWeight: 'normal' }}>{'Published'}</h5>
                      <br />
                      <Row gutter={[2, 6]}>
                        {story?.storyTags?.edges?.map(({ node: tag }) => (
                          <Col>
                            <Tag color="default">{tag.name}</Tag>
                          </Col>
                        ))}
                      </Row>
                    </div>
                    <div>
                      <h4 style={{ color: '#84A100', marginBottom: 0, fontWeight: 'normal' }}>
                        Author : {`${story?.author?.split(',')[0] || ''}`}
                      </h4>
                      <h4 style={{ color: '#84A100', marginBottom: 0, fontWeight: 'normal' }}>Likes : {story?.likes}</h4>
                    </div>
                  </StyledCard>
                </Link>
              </Col>
            ))}

            {allStoriesSet && allStoriesSet.edgeCount === 0 && (
              <div className="HVCenter" style={{ width: '70vw' }}>
                <Empty description="No result found" />
              </div>
            )}
          </Row>
          <br />
          {!loading && <Pagination data={allStories} pagination={pagination} onPaginationChange={onPaginationChange} />}
        </div>
      </Spin>
    </CardLayout>
  );
};

export default compose(withStoryState, withEditStory, withDeleteStory, withStoryFilterUpdating, withApollo)(Story);

const StyledCard = styled(Card)`
  height: 100%;
  border-color: ${props => props.active && PRIMARY_CLR};
  box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  &:hover {
    border-color: ${props => PRIMARY_CLR};
  }
`;
