import gql from "graphql-tag";

export const GET_ALL_SURVEYS = gql`
  query allSurveys($name_Icontains: String) {
    allSurveys(name_Icontains: $name_Icontains) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;
export const GET_ALL_CHOICES = gql`
  query SurveyById($id: ID!) {
    SurveyById(id: $id) {
      groupSet {
        edges {
          node {
            questionSet {
              edges {
                node {
                  choiceSet {
                    edges {
                      node {
                        id
                        label
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_ALL_SCAPS = gql`
  query scaps {
    scapes {
      edges {
        node {
          id
          name
          description
          active
          survey {
            id
            name
          }
        }
      }
    }
  }
`;
export const GET_SCAPE_BY_ID = gql`
  query scapeById($id: ID!) {
    scapeById(id: $id) {
      id
      name
      description
      survey {
        id
        name
      }
      active
    }
  }
`;

export const GET_QUESTION_LIST = gql`
query SurveyById($id: ID!){
  SurveyById(id:$id){
    groupSet{
      edges{
        node{
          questionSet{
            edges{
              node{
                id
                questionText
                choiceSet{
                  edges{
                    node{
                      id
                      label
                      value
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export const GET_CHOICE_LISTS=gql`
query choiceForQuestionByQuestionId($id: ID!){
  choiceForQuestionByQuestionId(id:$id){
    edges{
      node{
        question{
          questionText
        }
        id
        value
        label
      }
    }
  }
}
`
export const GET_SCAPE_BY_ID_CHOICE = gql`
query scapeById($id: ID!) {
  scapeById(id: $id) {
    id
    parametricgroupSet{
      edges{
        node{
          id
          parameterSet{
            edges{
              node{
                id
                choice{
                  id
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export const SCAPE_SUBSCRIPTION =gql`
subscription scapeSubscription {
  scapeSubscription {
    mutation
    scape {
      id
      name
      description
      active
      survey {
        id
        name
      }
    }
  }
}
`


export const PARAMETRIC_GROUP_LISTS =gql`
query parametricGroups($scape:ID){
  parametricGroups(scape:$scape){
    edges{
      node{
        id
        name
      }
    }
  }
}
`
export const PARAMETRIC_GROUP_SUBSCRIPTION =gql`
subscription parametricGroupQuestionSubscription{
  parametricGroupQuestionSubscription{
    mutation
    parametricGroupQuestion{
      id
      name
    }
  }
}
`

export const PARAMETR_LISTS =gql`
query parameters($parametricGroup:ID){
  parameters(parametricGroup:$parametricGroup){
    edges{
      node{
        id
        show
        parametricGroup{
          id
				}
        choice {
          id
          label
          value
        }
      }
    }
  }
}`

export const PARAMETER_SUBSCRIPTION =gql`
subscription parameterSubscription{
  parameterSubscription{
    mutation
    parameter{
      id
      show
      parametricGroup{
        id
      }
      choice {
        id
        label
        value
      }
    }
  }
}
`