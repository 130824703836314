import readiness_zone_route from 'modules/readiness-level/routes'
import one_on_one_route from 'modules/one-on-one/route'
import kudos_user_route from 'modules/kudos-user/route'
import idp_route from 'modules/IDP/route'

const notification_content = {
    FeedbackType:{
        model:'FeedbackType',
        isIntid : false,
    },
  
    ReadinessType:{
        model:'ReadinessType',
        url : readiness_zone_route.readinessHome?.replace(":type","assessments"),
        isIntid : true,
    },
    MeetingType:{
        model:'MeetingType',
        url :  one_on_one_route.oneOnOneHome?.replace(":type","upcoming"),
        isIntid : true,
    },
    BadgeAwardType:{
        model:'BadgeAwardType',
        url : kudos_user_route?.badge_awards_view,
        isIntid : true,
    }, 
    okrType:{
        model:'okrType',
        isIntid : false,
    },
    ContributorType:{
        model:"ContributorType",
        isIntid : false
    },
    IdpType:{
        model:'IdpType',
        url : idp_route.detail?.replace(":idp_id","{{objectId}}"),
        isIntid : true,
    }
} 

// field name and model name should be same model name should not contain white space
export default notification_content
