import React from 'react';
import { Button, Modal, Spin, message } from 'antd';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { LIST_ALL_USERS_ADMIN, LIST_ALL_USERS_QUERY } from '../graphql/ListAllUsersQuery.gql';
import { compose } from '../../core';
import { withApollo } from '@apollo/client/react/hoc';
import { AdminLayout, displayBoolean, displayDataCheck, getOrderBy, GetColumnSearchProps } from '../../look';

import ROUTES from '../route';

import { withListAllUsers, withUserState, withUserFilterUpdating, withPasswordResetMail } from './UserOperations';
import AddUserToOrgContainer from './AddUserToOrgContainer';

const User = props => {
  const {
    deleteUser,
    onPaginationChange,
    onFiltersRemove,
    client,
    me,
    passwordResetMail
  } = props;

  const [paginationFilter, setPaginationFilter] = React.useState({ first: 10, offset: 0 });
  const [listAllUsers, setAllUsers] = React.useState(undefined);
  const [add_to_org, SetAddToOrg] = React.useState();
  const [verification_email, SetVerificationEmail] = React.useState();
  const [loader,Setloader] = React.useState(false);
  const [email_loader,setEmailLoader] = React.useState(false);
  const [verified_tab,setVerifiedTab] = React.useState({
    label:"Verified Users",
    key:true
  });

  const [selected_search_filter,SetSelectedSearchFilter] = React.useState( {
    label:"Email",
    key:"email_Icontains"
  });

  React.useEffect(()=>{
    getAllUserList({after:null})
  },[verified_tab])

  React.useEffect(() => {
    if (add_to_org) {
      SetVerificationEmail(null)
    }
  }, [add_to_org])

  React.useEffect(() => {
    if (verification_email) {
      SetAddToOrg(null)
    }
  }, [verification_email])


  let filter_ref = React.useRef({})
  let data_ref = React.useRef({edges:[]})

  React.useEffect(()=>{
    data_ref.current = listAllUsers
  },[listAllUsers])

  const getAllUserList = async (filter) => {
    filter_ref.current = {...filter_ref.current,...filter}
    Setloader(true)
    const { data } = await client.query({
      query: LIST_ALL_USERS_ADMIN,
      variables: { first: 20,status_Verified:verified_tab?.key, ...filter_ref.current },
      fetchPolicy: 'network-only',
    });

    if (data?.users) {
      let detail = {
        pageInfo:data?.users?.pageInfo,
        edges :  filter?.after?data_ref?.current?.edges?.concat(data?.users?.edges) : data?.users?.edges
      }
      setAllUsers(detail);
      Setloader(false)
    }
  }


  const columns = [
    {
      title: 'Username',
      key: 'username',
      // sorter: () => onOrderByChange(getOrderBy('username', orderBy)),
      // ...GetColumnSearchProps('username', onUsernameChange),
      render: (text, record) => record && displayDataCheck(record.username)
    },
    {
      title: 'Email',
      key: 'email',
      // sorter: () => onOrderByChange(getOrderBy('email', orderBy)),
      // ...GetColumnSearchProps('email', onEmailChange),
      render: (text, record) => record && displayDataCheck(record.email)
    },
    {
      title: 'Name',
      key: 'firstName',
      // sorter: () => onOrderByChange(getOrderBy('firstName', orderBy)),
      // ...GetColumnSearchProps('firstName', onFirstNameChange),
      render: (text, record) => record && <>{displayDataCheck(record.firstName)} {displayDataCheck(record.lastName)}</>
    },
    {
      title: '',
      key: 'action',
      // sorter: () => onOrderByChange(getOrderBy('firstName', orderBy)),
      // ...GetColumnSearchProps('firstName', onFirstNameChange),
      render: (text, record) => record && <>
        {(!record?.verified && record?.employee) && (
          <Button onClick={()=>{SetVerificationEmail(record)}} className='sa-create-btn-large' style={{fontSize:"1em"}}>Resend Password email</Button>
        )}
        {(!record?.verified && !record?.employee) && (
          <Button onClick={() => SetAddToOrg(record)} className='sa-create-btn-large' style={{fontSize:"1em"}}>Add to organization</Button>
        )}
        {(record?.verified && record?.email_send) && (
          <Button className='sa-create-btn-large' style={{fontSize:"1em",cursor:"default"}}>Email Delivered</Button>
        )}
      </>
    }
    // ,
    // {
    //   title: 'Last Name',
    //   key: 'lastName',
    //   sorter: () => onOrderByChange(getOrderBy('lastName', orderBy)),
    //   ...GetColumnSearchProps('lastName', onLastNameChange),
    //   render: (text, record) => record && displayDataCheck(record.lastName)
    // }
    // ,
    // {
    //   title: 'Is staff',
    //   key: 'isStaff',
    //   sorter: () => onOrderByChange(getOrderBy('isStaff', orderBy)),
    //   render: (text, record) => record && displayBoolean(record.isStaff)
    // }
  ];

  const UseredAddedToOrg = (data) => {
    let user_list = listAllUsers?.edges?.concat([])
    let all_user_detail = { ...listAllUsers }
    let user_detail = user_list?.find(({ node }) => node?.id == add_to_org?.id)
    if (user_detail) {
      user_detail.node.employee = {
        id: data?.user?.employeeId,
        organizationSet: {
          totalCount: 1
        }
      }
      all_user_detail["edges"] = user_list
      setAllUsers(all_user_detail)
      SetAddToOrg(null)
      SetVerificationEmail(user_detail.node)
    }


  }

  const sendAccountVerificationEmail = async() =>{
    setEmailLoader(true)
    let response = await passwordResetMail(verification_email?.email)
    setEmailLoader(false)
    if(response?.success){
      let user_list = listAllUsers?.edges?.concat([])
      let all_user_detail = { ...listAllUsers }
      let user_detail = user_list?.find(({ node }) => node?.id == verification_email?.id)
      if (user_detail) {
        user_detail.node.verified = true
        user_detail.node.email_send = true
        all_user_detail["edges"] = user_list
        setAllUsers(all_user_detail)
        SetVerificationEmail(null)
      }
      message.success("Email send successfully")
    }else{
      let error_list = []
      
      if(response?.errors?.email?.length){
        error_list = response?.errors?.email?.map(i=>i?.message)
      }
      if(response?.errors?.field_name?.length){
        error_list = response?.errors?.field_name?.map(i=>i?.message)
      }
      if(response?.errors?.other_field?.length){
        error_list = response?.errors?.other_field?.map(i=>i?.message)
      }
      if(response?.errors?.nonFieldErrors?.length){
        error_list = response?.errors?.nonFieldErrors?.map(i=>i?.message)
      }

      let message_text = error_list[1]? error_list[1] : error_list[0]
      message.error(message_text || 'failed to send set password email')
      SetVerificationEmail(null)
    }
    
  }

  const onSearchChange=(text)=>{
  
    if(selected_search_filter?.key=="email_Icontains"){
      getAllUserList({after:null,email_Icontains:text,username_Icontains:""})
    }
    else if(selected_search_filter?.key=="username_Icontains"){
      getAllUserList({after:null,email_Icontains:"",username_Icontains:text})
    }
    
  }

  return (
    <>
      <Modal
        visible={add_to_org}
        closable={false}
        footer={false}
        centered={true}
        destroyOnClose={true}
      >
        <AddUserToOrgContainer {...props} onsucess={(e) => { UseredAddedToOrg(e) }} org_confirmation={add_to_org} add_to_org={true} />
      </Modal>

      <Modal
        visible={verification_email}
        closable={false}
        footer={false}
        centered={true}
        destroyOnClose={true}
      >
        <Spin spinning={email_loader}>

          <h3 style={{ fontWeight: 'bold', textAlign: 'center' }}>Do you want to send set password email to {verification_email?.email}?</h3>
          <div style={{ display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "1em", width: "100%" }}>
            <Button className='sa-create-btn-large' onClick={() => { sendAccountVerificationEmail() }} style={{ width: '75px', fontSize: "1.2em" }}>YES</Button>
            <Button className='sa-create-btn-large' onClick={() => { SetVerificationEmail(null) }} style={{ width: '75px', fontSize: "1.2em" }}>NO</Button>
          </div>
        </Spin>
      </Modal>
      <AdminLayout
        table={true}
        active={ROUTES.user}
        title="Users"
        loading={loader}
        tableData={listAllUsers}
        columns={columns}
        onDelete={deleteUser}
        addLink={ROUTES.addUser}
        onPaginationChange={onPaginationChange}
        onFiltersRemove={onFiltersRemove}
        disable_row_selection={true}
        show_search_input={true}
        search_filter={[
          {
            label:"Username",
            key:"username_Icontains"
          },
          {
            label:"Email",
            key:"email_Icontains"
          }
        ]}
        selected_search_filter={selected_search_filter}
        SetSelectedSearchFilter={SetSelectedSearchFilter}
        onSearchTable={(e)=>{onSearchChange(e)}}
        view_more_pagination={loader?false:listAllUsers?.pageInfo?.hasNextPage}
        onViewMore={()=>{getAllUserList({after:listAllUsers?.pageInfo?.endCursor})}}
        set_table_tab={setVerifiedTab}
        selected_table_tab={verified_tab}
        table_tabs={[
          {
            label:"Verified Users",
            key:true
          },
          {
            label:"Non Verified Users",
            key:false
          }
        ]}
        extra={
          <Button size="md" type="primary" ghost>
            <Link to={ROUTES.addMultipleUser}>
              <PlusOutlined />
              Add Multiple User
            </Link>
          </Button>
        }
      />

    </>
  );
};

export default compose(withUserState, withListAllUsers /* , withDeleteUser */, withUserFilterUpdating, withApollo,withPasswordResetMail)(User);
