// @ts-nocheck
/* eslint-disable import/first */
import React from "react";
import ReactDOM from "react-dom";
import { fromPromise } from "apollo-link";
import { onError } from "apollo-link-error";
import { ApolloProvider } from "@apollo/react-hooks";
import {
  // ApolloClient,
  ApolloLink,
  InMemoryCache,
  HttpLink,
  IntrospectionFragmentMatcher,
} from "apollo-boost";
import { RetryLink } from "@apollo/client/link/retry";
import { withClientState } from "apollo-link-state";
import ApolloCacheRouter from "apollo-cache-router";
import { hasDirectives } from "apollo-utilities";
import { getOperationAST } from "graphql";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { setContext } from "@apollo/client/link/context";
import { ApolloClient } from '@apollo/client';
import { createHttpLink } from "apollo-link-http";
require("dotenv").config();

import App, { clientResolvers } from "./App";
import "./index.css";
import "./style.css";
import "./GlobalStyle.css"
import * as serviceWorker from "./serviceWorker";
import { ACCESS_TOKEN, BACKEND_URI, BACKEND_WS_URI, REFRESH_TOKEN } from "./config";

import { setItem, getItem, removeItem, getSyncItem } from "./modules/core";
import { REFRESH_TOKENS_MUTATION } from "./modules/user/graphql/RefreshToken.gql";
import REPORTHELPER from "./modules/survey/helpers";
import ROUTES from "./modules/user/route";
import DASHBOARD_ROUTES from "./modules/dashboard/route";
import REPORT_ROUTE from "./modules/report/route";
import SignInAgain from "./modules/user/containers/SignInAgain";
import { RoutesConfigs } from "modules/RouteConfig";

let isRefreshing = false;
let pendingRequests = [];
let client;
let subscriptionClient;
let allLinks;
let wsLink;
let splitLink;
let setRoute = false;


const resolvePendingRequests = () => {
  pendingRequests.map((callback) => callback());
  pendingRequests = [];
};




subscriptionClient = new SubscriptionClient(
  `${BACKEND_WS_URI}`,
  {
    lazy: true,
    reconnect: true,
    connectionParams: () => ( {
      headers: {
        Authorization: getSyncItem( ACCESS_TOKEN )
          ? `JWT ${getSyncItem( ACCESS_TOKEN )}`
          : "",
      },
    } ),
  }
);


let params;
let path;
const url = window.location.href;


if(window.location?.search){
   params = new URLSearchParams(window.location.search)
   path = window.location.pathname.slice(1).slice(0,-1);
}else{
  path = window.location.pathname.slice(1)
}




let output1 = RoutesConfigs.hasOwnProperty(path)
  

const employee = params?.get('employee');
const feedbackId = params?.get("feedback")
const meeting = params?.get("meeting")
const tab = params?.get("tab")
const okr = params?.get("okr")
const token = params?.get("token")
const resetToken = params?.get("resetToken")
const readiness_zone = params?.get("readiness_zone");
const tool = params?.get("tool");
const idp_id = params?.get("idp_id")
const request = params?.get("request")
const survey = params?.get("survey")


  
if(output1){
  localStorage.setItem('emailRoute',JSON.stringify({route:RoutesConfigs[path],employee,feedbackId,meeting,tab,okr,token,
    resetToken,readiness_zone,tool,idp_id,request,survey}))
}


const getNewToken = async () => {
  const ERROR = "Invalid RefreshToken";
  return client
    .mutate( {
      mutation: REFRESH_TOKENS_MUTATION,
      variables: { refreshToken: await getItem( REFRESH_TOKEN ) },
    } )
    .then( ( response ) => {
      // extract your accessToken from your response data and return it
      const { token } = response.data.refreshToken;
      if ( response.data.refreshToken.errors ) {
        throw Error( ERROR );
      }
      setItem( ACCESS_TOKEN, token );
      resolvePendingRequests();
      subscriptionClient.connectionParams.authToken = token;
      subscriptionClient.url = `${BACKEND_WS_URI}` + `${token ? `?token=${token}` : ""}`
      return token;
    } )
    .catch( ( err ) => {
      if ( err ) {
        SignInAgain( client, resolvePendingRequests );
      }
    } );
};

const errorLink = onError(
  ( { graphQLErrors, networkError, operation, forward } ) => {
    if ( graphQLErrors ) {
      for ( let err of graphQLErrors ) {
        console.log('err_permission',err);
        switch ( err.message ) {
          case "Signature has expired":
            // error code is set to UNAUTHENTICATED
            // when AuthenticationError thrown in resolver
            let forward$;
            subscriptionClient.close();
            if ( !isRefreshing ) {
              isRefreshing = true;
              forward$ = fromPromise(
                getNewToken().catch( ( error ) => {
                  console.log( "New token fetch failed", error );
                  // console.log('RefreshToken', getItem('refreshToken'), 'AccessToken', getItem('accessToken'));
                  // Handle token refresh errors e.g clear stored tokens, redirect to login
                  removeItem( ACCESS_TOKEN );
                  removeItem( REFRESH_TOKEN );
                  return;
                } )
              ).filter( ( value ) => Boolean( value ) );
              isRefreshing = false;
            } else {
              // Will only emit once the Promise is resolved
              forward$ = fromPromise(
                new Promise( ( resolve ) => {
                  pendingRequests.push( () => resolve() );
                } )
              );
            }

            return forward$.flatMap( () => forward( operation ) );
          case "Error decoding signature":
            removeItem( ACCESS_TOKEN );
            removeItem( REFRESH_TOKEN );
            break;
          case "Permission Denied.":
            if ( typeof window !== undefined && !window.location?.pathname.includes('permissions') ){
              window.location = ROUTES.permissions;
            } 
            break;
          default:
            const noBackTopRoutes = [
              DASHBOARD_ROUTES.personal,
              ROUTES.login,
              REPORT_ROUTE.reportComparisonLink,
            ].includes( window.location.pathname.replace( /[0-9]/g, "" ) );
            if (
              err.message.includes( "does not exist" ) &&
              noBackTopRoutes
              // window.location.pathname !== ROUTES.login &&
              // will be removed onece the myResilienceAnchors error message for non employee user is changed
              // window.location.pathname !== DASHBOARD_ROUTES.personal
            )
              if ( window.location.pathname !== ROUTES.login ) {
                window.location = "/";
              }

            break;
        }
      }
    }
    if ( networkError ) {
      // if you would also like to retry automatically on
      // network errors, we recommend that you use
      // apollo-link-retry
    }
  }
);

const retryIf = ( error, operation ) => {
  const doNotRetryCodes = [ 500, 400 ];
  return (
    !!error &&
    !doNotRetryCodes.includes( error.statusCode ) &&
    !Object.keys( REPORTHELPER ).includes( "emailPerceptionReport" )
  );
};

const retryLink = new RetryLink( {
  delay: {
    initial: 100,
    max: 2000,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf,
  },
} );


 wsLink = new WebSocketLink( subscriptionClient );

// const httpLink = new BatchHttpLink({
//   uri: BACKEND_URI
//   // credentials: "include",
//   // fetch
// });

const httpLink = createHttpLink( {
  uri: BACKEND_URI,
  credentials: 'include',
} );

const authLink = setContext( async ( operation ) => {
  // Retrieve the authorization token from local storage.
  const token = await getItem( ACCESS_TOKEN );

  return {
    headers: {
      Authorization: token ? `JWT ${token}` : "",
    },
  };
} );

splitLink = ApolloLink.split(
  ( { query } ) => {
    const definition = getMainDefinition( query );
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  authLink.concat( httpLink )
);

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: {
      types: [], // no types provided
    },
  },
});


const netCache = new InMemoryCache({fragmentMatcher});
const localCache = new InMemoryCache({fragmentMatcher});
const cache = ApolloCacheRouter.override(
  ApolloCacheRouter.route( [ netCache, localCache ], ( document ) => {
    const operationName = getOperationAST( document ).name;
    if (
      hasDirectives( [ "client" ], document ) ||
      ( operationName && operationName.value === "GeneratedClientQuery" )
    ) {
      // Pass all @client queries and @client defaults to localCache
      return localCache;
    } else {
      // Pass all the other queries to netCache);
      return netCache;
    }
  } ),
  {
    reset: () => {
      // On apolloClient.resetStore() reset only netCache and keep localCache intact
      return netCache.reset();
    },
  }
);

const linkState = withClientState({ ...clientResolvers, cache });
allLinks = [retryLink, errorLink, splitLink, linkState];

client = new ApolloClient( {
  link: ApolloLink.from( allLinks ),
  cache,
  credentials: 'include',
  mode: 'cors',
  resolvers: clientResolvers.resolvers,
  shouldBatch: true,
} );



const setSubscriptionClient = (token) => {
  if(token){
    setRoute = true;
     
  }
}

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={/* apolloClient */ client}>
      <App setSubscriptionClient={setSubscriptionClient} emailRoute = {output1} />
    </ApolloProvider>

  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
