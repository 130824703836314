import gql from 'graphql-tag';
import { SURVEY_TYPE_CONNECTION } from './SurveyTypeConnection.gql';

export const ALL_ORG_SURVEY_BY_ORG_ID_QUERY = gql`
  ${SURVEY_TYPE_CONNECTION}
  query allOrgSurveysByOrgId(
    $orgId: ID!
    $first: Int
    $offset: Int
    $orderBy: [String]
    $name_Icontains: String
    $description_Icontains: String
  ) {
    allOrgSurveysByOrgId(
      orgId: $orgId
      first: $first
      offset: $offset
      orderBy: $orderBy
      name_Icontains: $name_Icontains
      description_Icontains: $description_Icontains
    ) {
      ...SurveyTypeConnection
    }
  }
`;
