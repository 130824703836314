import { MoreOutlined } from '@ant-design/icons';
import { Col, Dropdown, Empty, Menu, Pagination, Row, Spin, Switch } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { compose } from '../../core';
import { CardLayout, displayDataCheck, displayUtcToUserTimezone, GetColumnSearchProps, getIntFromString, getOrderBy } from '../../look';
import { withApollo } from '@apollo/client/react/hoc';
import ROUTE from '../route';

import { PAGINATION_LIMIT, PRIMARY_CLR } from '../../../config';
import ReactMarkdown from '../../look/components/ReactMarkdown';
import SelectFilterField from '../../look/components/SelectFilterField';
import {
  withAllSurveysQuery,
  withDeleteSurvey,
  withEditSurvey, withSurveyFilterUpdating, withSurveyState
} from './SurveyOperations';
import { SURVEY_SUBSCRIPTION } from '../graphql/SurveySubscription.gql';
import { ALL_SURVEY_QUERY } from '../graphql/AllSurveysQuery.gql';

const Survey = props => {
  const {
    loading,
    allSurveysForTokens,
    onPaginationChange,
    deleteSurvey,
    onNameChange,
    onDescriptionChange,
    orderBy,
    onOrderByChange,
    onFiltersRemove,
    subscribeToMore,
    editSurvey,
    pagination,filter,me,
    client,
    pagination: { offset, first }
  } = props;
  const [deleteLoading, setDeleteLoading] = React.useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);

  const [allSurveysSet,setAllSurveySet] = React.useState(undefined);
  const [paginationFilter,setPaginationFilter] = React.useState({first: 12,offset: 0});
  const surveyDataRef = React.useRef(null);
  const [filterText,setFilterText] = React.useState(undefined);
  const [paginationProps,setPaginationProps] = React.useState(undefined);
  

  let surveySubscription = undefined;
  const m = React.useRef(true)





  
  const getAllSurveys = async (filters) => {
      
      if(filters?.first){
        setPaginationFilter({...filters})
      }
    const { data } = await client.query({
      query: ALL_SURVEY_QUERY,
      variables: {...filters, orderBy } ,
      fetchPolicy: 'network-only',
    });
    
    if (data?.allSurveysForTokens) {
      
       setAllSurveySet(data?.allSurveysForTokens);
       surveyDataRef.current = data?.allSurveysForTokens;
       SubscribeToSurveys();
    }
  }

  
  React.useEffect(()=>{
    
    if(allSurveysSet){
    
      let  paginationData = !(allSurveysSet && allSurveysSet?.totalCount)
        ? {}
        : {
            defaultCurrent: 1,
    
            showTotal: (total, range) =>
            allSurveysSet.totalCount && (
                <>
                  {total < PAGINATION_LIMIT
                    ? `Showing 1 to ${total} of ${total} entries.`
                    : `Showing ${paginationFilter?.offset + 1} to ${
                      paginationFilter?.offset + paginationFilter?.first <= total ?
                      paginationFilter?.offset + paginationFilter?.first : total
                      } of ${total} entries.`}
                </>
              ),
            onChange: (page, pageSize) =>
              getAllSurveys({
                  offset: page * PAGINATION_LIMIT - PAGINATION_LIMIT,
                  first: PAGINATION_LIMIT
                }),
               
             
            defaultPageSize: PAGINATION_LIMIT,
            total: allSurveysSet?.totalCount,
            style: {
              textAlign: 'right'
            }
          };

          setPaginationProps(paginationData)
    }

  },[allSurveysSet])





  React.useEffect(()=>{
   let updatedFilters;
    if(filter?.name_Icontains?.length || filter?.description_Icontains?.length){
      updatedFilters = {...filter}
    }else{
      setFilterText(undefined)
      updatedFilters = {...paginationFilter,...filter}
    }
   
    getAllSurveys(updatedFilters)

  },[filter])


  React.useEffect(() => {
    return () => {
      m.current = false
    }
  }, [])

  React.useEffect(() => {
    m.current = true

  }, [])


  React.useEffect(() => {
    return () => {
      if (surveySubscription) {
        surveySubscription.unsubscribe();
      }
    }
  }, [])
 


  

  const SubscribeToSurveys = () => {

    surveySubscription = client.subscribe({
      query: SURVEY_SUBSCRIPTION,
    }).subscribe({
      next(result) {

        switch (result.data.surveySubscription.mutation) {
         
          case 'DELETE':
           
            if (m.current) {
              let surveySet = surveyDataRef.current?.edges
              let surveySetWithDeletedRecord = surveySet?.filter((survey)=>
              survey?.node?.id !== result.data.surveySubscription?.survey?.id)
              
              let updatedSurveySet = {edgeCount:(surveyDataRef.current?.edgeCount - 1 ),totalCount:(surveyDataRef.current?.totalCount - 1 ),edges:surveySetWithDeletedRecord}
              setAllSurveySet(updatedSurveySet);
             
              surveyDataRef.current = updatedSurveySet;
            }

            break;
          default:
            break
        }

      }
    })

  }




  const columns = [
    {
      title: 'Name',
      key: 'name',
      sorter: () => onOrderByChange(getOrderBy('name', orderBy)),
      ...GetColumnSearchProps('name', onNameChange),
      render: (text, record) => (
        <>{record && <Link to={`${ROUTE.groupLink}${getIntFromString(record.id)}`}>{displayDataCheck(record.name)}</Link>}</>
      )
    },
    {
      title: 'Description',
      key: 'description',
      ellipsis: true,
      sorter: () => onOrderByChange(getOrderBy('description', orderBy)),
      ...GetColumnSearchProps('description', onDescriptionChange),
      render: (text, record) => <ReactMarkdown>{record && record.description}</ReactMarkdown>
    },
    {
      title: 'Date Published',
      key: 'pubDate',
      sorter: () => onOrderByChange(getOrderBy('pubDate', orderBy)),
      render: (text, record) => `${displayUtcToUserTimezone(record.pubDate, 'YYYY-M-DD HH:mm')}`
    }
  ];
  const handleSelect = id => {
    setSelectedRowKeys(
      selectedRowKeys.indexOf(id) === -1 ? [...selectedRowKeys, id] : selectedRowKeys.filter(s => s !== id)
    );
  };


  const handleDelete = async id => {
    setDeleteLoading(true);
    try {
      await deleteSurvey({id:id});
    } catch (e) {
      throw new Error(e.message);
    }
    setDeleteLoading(false);
  };
  const menu = id => (
    <Menu>
      <Menu.Item key="0">
        <Link to={`${ROUTE.editSurveyLink}${id}`}>Edit</Link>
      </Menu.Item>
      <Menu.Item key="1" onClick={() => handleDelete(id)} danger={true}>
        Delete
      </Menu.Item>
      <Menu.Item key="3" onClick={() => handleSelect(id)}>
        Select
      </Menu.Item>
    </Menu>
  );
  const handleEditSurvey = async field => {
    try {
      await editSurvey({
        ...field
      });
    } catch (e) {
      throw new Error(e.message);
    }
  };

  const handleDeleteRecord = async(id) =>{

    const response = await deleteSurvey({id:id})

  }

  return (
    <CardLayout
      table={true}
      active={ROUTE.survey}
      title={[{ name: 'Survey' }]}
      loading={loading}
      tableData={allSurveysSet}
      columns={columns}
      onDelete={deleteSurvey}
      editLink={ROUTE.editSurveyLink}
      addLink={ROUTE.addSurvey}
      onPaginationChange={onPaginationChange}
      onFiltersRemove={onFiltersRemove}
      selectedRowKeys={selectedRowKeys}
      specificMutation = {true}
      handleDeleteRecord = {handleDeleteRecord}
      extra={
        <SelectFilterField
          placeholder="Search Survey by"
          onFiltersRemove={onFiltersRemove}
          filterText={filterText}
          setFilterText={setFilterText}
          options={[
            {
              label: 'Name',
              type: 'text',
              value: onNameChange
            },
            {
              label: 'Description',
              type: 'text',
              value: onDescriptionChange
            }
          ]}
        />
      }
    >
      <Spin spinning={deleteLoading} size="large">
        <div style={{ minHeight: '70vh' }}>
          <Row gutter={[24, 24]}>
            {allSurveysSet &&
              allSurveysSet.edges.map(({ node }) => (
                <>
                  <Col xs={24} md={12} lg={8}>
                    <Dropdown overlay={() => menu(getIntFromString(node.id))} placement="bottomLeft">
                      <MoreOutlined
                        style={{
                          // fontSize: 'large',
                          position: 'absolute',
                          right: 20,
                          zIndex: 1,
                          top: 20
                        }}
                      />
                    </Dropdown>
                    <Link to={`${ROUTE.surveyDetailLink}${getIntFromString(node.id)}`}>
                      <StyledCard
                        active={selectedRowKeys.indexOf(getIntFromString(node.id)) !== -1}
                        // title={<Row></Row>}
                      >
                        <Row>
                          <Col>
                            <h3 style={{ marginBottom: 0, fontWeight: 'normal' }}>{node.name}</h3>
                            <h5 /* style={{ color: '#CACACA' }} */>
                              {node && <ReactMarkdown>{node.description}</ReactMarkdown>}
                            </h5>
                          </Col>
                        </Row>
                        <div>
                          <Row style={{ marginTop: 10 }}>
                            <span style={{ marginBottom: 0, fontWeight: 'normal' }}>
                              Active till : {`${displayUtcToUserTimezone(node.activeFrom,'DD-MM-YYYY')}`}
                            </span>
                          </Row>
                        </div>
                      </StyledCard>
                    </Link>
                    <Row
                      justify="space-between"
                      align="bottom"
                      style={{ position: 'relative', bottom: 80, padding: '0 24px' }}
                    >
                      <Col>
                        <h4 style={{ marginBottom: 0, fontWeight: 'normal' }}>Public</h4>
                        <Switch
                         style={{cursor:'default'}}
                          checked={node.public}
                          size="small"
                          onClick={e => handleEditSurvey({ id: getIntFromString(node.id), public: e, name: node.name })}
                        />
                      </Col>
                      <Col>
                        <h4 style={{ marginBottom: 0, fontWeight: 'normal' }}>Shuffle</h4>
                        <Switch
                          style={{cursor:'default'}}
                          checked={node.shuffle}
                          size="small"
                          onClick={e => handleEditSurvey({ id: getIntFromString(node.id), shuffle: e, name: node.name })}
                        />
                      </Col>
                    </Row>
                  </Col>
                </>
              ))}
          </Row>
          {allSurveysSet && allSurveysSet.edgeCount === 0 && (
            <div className="HVCenter" style={{ width: '70vw' }}>
              <Empty description="No result found" />
            </div>
          )}
        </div>
        <br />
        <Pagination {...paginationProps} />
      </Spin>
    </CardLayout>
  );
};

export default compose(
  withSurveyState,
  withEditSurvey,
  withAllSurveysQuery,
  withDeleteSurvey,
  withSurveyFilterUpdating,
  withApollo
)(Survey);

const StyledCard = styled.div`
  height: 100%;
  border-color: ${props => props.active && PRIMARY_CLR};
  border: ${props => props.active && '1px solid'};
  padding: 24px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.05);
  &:hover {
    border-color: ${props => PRIMARY_CLR};
  }
`;
