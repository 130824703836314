// @ts-nocheck
import React from 'react';
import { compose } from '../../core';
import { withToolById } from 'modules/tool/containers/ToolOperations';
import EditMeetingView from '../components/EditMeetingView';
import { MEETING_BY_ID } from '../graphql/MeetingByid.gql';
import { withApollo } from '@apollo/client/react/hoc';
import { withCreateActionItemsEditPage, withDeleteActionItem,withUpdateActionItems,withUpdateMeeting,employeeList } from './One_on_oneOperations';
// import { employeeList } from '../../feedback/containers/feedbackOperations'
import { UPCOMING_MEETING } from '../graphql/UpcomingMeeting.gql';
import { Button,Spin,Tooltip } from "antd";
import moment from 'moment';
import { getMeetingTypeBase64StringFromInt,getIntFromString, globalPermissionValidator } from "../../look";
import { withDeleteMeeting } from './One_on_oneOperations'
import { ONEONONE_HOME_TOOL_ID } from '../../../config'
import { GET_MANAGER_EMPLOYEE_LIST_SEARCH } from '../graphql/getEmployeSerach.gql';
import NoPermissionView from 'modules/kudos-user/component/NoPermissionComponent';
import { oneonone_permission } from 'Permissions/oneonone.permission';
import one_on_one_route from '../route/index'
const EditMeetingContainer = props => {
  const {client,me,history,deleteMeeting,userPermission}=props
  const [loading,setloading]=React.useState(false)
  const [meetingById,setMeeting]=React.useState()
  const [upcomingMeeting,setUpcomingMeeting]=React.useState([])
  const [allEmpoloyeeResult,setAllEmpoloyeeResult]=React.useState()
  const [permission, setpermission] = React.useState()

  const getMeetingDetail = async (value) => {
      let id = getMeetingTypeBase64StringFromInt(value);
      const { data } = await client.query({
          query: MEETING_BY_ID,
          variables: { id: id },
      });
      if(data?.meetingById){
          setMeeting(data?.meetingById)
      }
  }

  React.useEffect(() => {
    if (userPermission?.length) {
      let permission = globalPermissionValidator(oneonone_permission, userPermission)
      setpermission(permission)
    }
    else {
      setpermission(null)
    }
  }, [userPermission])
 
  const UpcomingMeeting = async (filterData) => {

    const { data } = await client.query({
        query: UPCOMING_MEETING,
        variables: {...filterData,orderBy:['-when']},
        fetchPolicy: 'network-only'
    });
    if(data?.upcomingMeeting){
        setUpcomingMeeting(data?.upcomingMeeting)
    }
    
}

async function DeleteMeeting(id) {
  setloading(true)
  try{

    setloading(true)
    const response = await deleteMeeting({id:getIntFromString(id)});
    if(response){
      setloading(false)
      history.push(one_on_one_route?.oneOnOneHome?.replace(":type","upcoming"))
    }
    else{
      setloading(false)
    }
    
  }
  catch{
    setloading(false)
  }
 }

  React.useEffect(()=>{
    if(permission?.requried_permission){
        let id =props?.match?.params?.meetingid
        getMeetingDetail(id)
        let currentDate = moment.utc(new Date()).startOf('day')
        let weekendDate = new Date()
        let dif = 6-weekendDate.getDay()
        weekendDate.setDate(weekendDate.getDate()+dif)
        let IsoWeekendDate=moment.utc(weekendDate).endOf('day')
        UpcomingMeeting({ when_Range: [currentDate,IsoWeekendDate]})
    }
  },[permission])

  const AllEmployeeListSearch = async(searchValue)=>{
    const { data } = await client.query({
      query: GET_MANAGER_EMPLOYEE_LIST_SEARCH,
      variables: {user_LastName: searchValue,user_FirstName: searchValue},
      fetchPoicy:'network-only'
      });
      if(data){
        let allEmpoloyee =data
        if(allEmpoloyee){
          setAllEmpoloyeeResult(allEmpoloyee)
        }
      }
    }
const SearchKey=(value)=>{
    AllEmployeeListSearch(value)
}
    return (
        <>
      

        {
        (permission && permission?.requried_permission) && (
          <Spin spinning={loading}>
          <EditMeetingView {...props} upcomingMeeting={upcomingMeeting}  meetingById={meetingById} searchKey={e=>SearchKey(e)} allEmpoloyeeResult={allEmpoloyeeResult}  remove={(e)=>DeleteMeeting(e)}></EditMeetingView>
          </Spin>
        )
      }

      {
        (permission && !permission?.requried_permission) && (
          <NoPermissionView />
        )
      }

        </>
      )


};

export default compose(withApollo,employeeList,withCreateActionItemsEditPage,withDeleteActionItem,withUpdateActionItems,withUpdateMeeting,withDeleteMeeting)(EditMeetingContainer);