import React from 'react';

import { AdminLayout, getIntFromString } from '../../../../look';
import ROUTES from '../../../route';
import FaqQuestionFormComponent from './FaqQuestionFormComponent';

const EditFaqQuestionView = props => {
  return (
    <>
      <AdminLayout
        table={false}
        active={ROUTES.faq}
        title="Edit FAQ Question"
        backLink={
          props?.faqQuestionById?.catagory &&
          `${ROUTES.faqQuestionLink}${getIntFromString(props?.faqQuestionById?.catagory?.id)}`
        }
        FormComponent={props.faqQuestionById && <FaqQuestionFormComponent {...props} />}
      />
    </>
  );
};

export default EditFaqQuestionView;
