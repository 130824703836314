import { withApollo } from '@apollo/client/react/hoc';
import { Col, Form, Input, message, Row } from 'antd';
import React from 'react';

import { compose } from '../../core';
import { AdminLayout, DeleteIcon, getIntFromString } from '../../look';
import { GET_USER_BY_USERNAME_OR_EMAIL_QUERY } from '../../user/graphql/GetUserByUsernameOrEmailQuery.gql';

import ROUTE from '../route';

import ResponsesByUserIdTable from './ResponsesByUserIdTable';
import { withDeleteResponseOptimized } from './SurveyOperations';

const { Search } = Input;

const Response = props => {
  const { deleteResponse, client, me} = props;

  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
  const [searchLoading, setSearchLoading] = React.useState(false);
  const [userId, setUserId] = React.useState(null);
  const [username, setUsername] = React.useState(null);

  const handleSearch = async username => {
    setSearchLoading(true);
    try {
      const { data } = await client.query({
        query: GET_USER_BY_USERNAME_OR_EMAIL_QUERY,
        variables: { username }
      });
      if (data.users) {
        setUserId(getIntFromString(data?.users?.edges[0]?.node?.id));
      }
      setUsername(username);
      setSearchLoading(false);
    } catch (e) {
      setUsername(e.message);
      message.error('Failed! try again.');
      setSearchLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      await Promise.all(
        selectedRowKeys.map(async i => {
          await deleteResponse(i);
        })
      );
      message.success('Deleted!');
    } catch (e) {
      message.error('Failed, try again!');
      throw new Error(e.message);
    }
  };

  return (
    <AdminLayout active={ROUTE.response} history={props.history} title={'Generate Reports'}>
      <>
        <Row align="middle">
          <Col span={10} offset={1}>
            <Form.Item
              labelCol={{ span: 10, style: { display: 'contents' } }}
              name="search"
              label={'Search'}
              validateStatus={username === 'GraphQL error: User matching query does not exist.' ? 'error' : ''}
              help={username === 'GraphQL error: User matching query does not exist.' ? `Invalid username!!` : null}
              rules={[
                {
                  required: true,
                  message: 'Email/username is required'
                }
              ]}
            >
              <Search onSearch={handleSearch} size="md" placeholder="Enter username" loading={searchLoading} enterButton />
            </Form.Item>
          </Col>
          <Col span={11} />
          <Col span={2} align="left">
            <DeleteIcon tooltipSuffix={"Response"} size="lg" disabled={!(deleteResponse && selectedRowKeys.length > 0)} onClick={handleDelete} />
          </Col>
          <Col span={23}>
            <br />
            {userId && (
              <ResponsesByUserIdTable
                userId={userId}
                username={username}
                selectedRowKeys={selectedRowKeys}
                handleChange={e => setSelectedRowKeys(e)}
                me = {me}
              />
            )}
          </Col>
        </Row>
      </>
    </AdminLayout>
  );
};

export default compose(withApollo, withDeleteResponseOptimized)(Response);
