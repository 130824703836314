import React from 'react';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';

import { AddFromXLSLayout, displayDataCheck } from '../../look';

import ROUTE from '../route';

import { withMultipleCreateToken } from './SurveyOperations';

const AddMultipleToken = props => {
  const { createToken } = props;
  const columns = [
    {
      title: 'Status',
      key: 'success',
      dataIndex: 'success',
      render: text =>
        text ? (
          <CheckCircleFilled style={{ color: '#009fff' }} />
        ) : (
          text !== undefined && <CloseCircleFilled style={{ color: '#ff4d4f' }} />
        )
    },
    {
      title: 'Mobile Number',
      key: 'mobileNumber',
      dataIndex: 'mobileNumber',
      render: text => text && displayDataCheck(text)
    },
    {
      title: 'First name',
      key: 'firstName',
      dataIndex: 'firstName',
      render: text => text && displayDataCheck(text)
    },
    {
      title: 'Last Name',
      key: 'lastName',
      dataIndex: 'lastName',
      render: text => text && displayDataCheck(text)
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
      render: text => text && displayDataCheck(text)
    },
    {
      title: 'Survey Id',
      key: 'surveyId',
      dataIndex: 'surveyId',
      render: text => text && displayDataCheck(text)
    },
    {
      title: 'Message',
      key: 'message',
      dataIndex: 'message',
      render: text => text && displayDataCheck(text)
    },
    {
      title: 'Invite',
      key: 'invite',
      dataIndex: 'invite',
      render: text =>
        text ? (
          <CheckCircleFilled style={{ color: '#009fff' }} />
        ) : (
          text !== undefined && <CloseCircleFilled style={{ color: '#ff4d4f' }} />
        )
    }
  ];

  return (
    <AddFromXLSLayout
      title={'Tokens'}
      active={ROUTE.user}
      columns={columns}
      dataMutation={createToken}
      fileColumns={`"invite", "message", "surveyId", "mobileNumber", "firstName", "lastName", "username", and "email"`}
      expandable={{
        expandedRowRender: record => <div key={record.id}>{record.errors.message}</div>,
        rowExpandable: record => {
          return record.errors;
        }
      }}
    />
  );
};

export default withMultipleCreateToken(AddMultipleToken);
