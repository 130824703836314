// @ts-nocheck
import React from 'react';
import { compose } from '../../core';
import { withApollo } from '@apollo/client/react/hoc';
import PreviousView from '../components/PreviousView';
import { MEETING_BY_ID, MEETING_BY_ID_SUBSCTIPTION } from '../graphql/MeetingByid.gql';
import { Button, Spin, Tooltip } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { ACTIONITEMS_SUBSCRIPTION } from '../graphql/ActionItemSubscription.gql'
import { ACTIONITEM_BY_MEETING_ID } from '../graphql/ActionItemSub.gql'
import PreviousMeetingCardView from '../components/PreviousMeetingCardView';
import moment from 'moment';
import { PREVOIUS_MEETING_LIST } from '../graphql/getAllPreviousMeetinglist.gql';
import { getIntFromString, getMeetingTypeBase64StringFromInt } from 'modules/look';
import { MEETING_RATING } from '../graphql/meetingRating.gql';
import { withUpdateActionItems } from './One_on_oneOperations';
const PreviousMeetingCardContainer = props => {
  const { client, MeetingId, me, meetingByIdData, permission,previousMeetingCout } = props

  const [notes, setnote] = React.useState()
  const [meetingById, setMeeting] = React.useState()
  const [loading, setloading] = React.useState(false)
  const [meetinglist, setMeetingList] = React.useState([])
  const [currentMeetingIndex, setcurrentMeetingIndex] = React.useState()
  const [nextMeetings, setnextMeetings] = React.useState([])
  const [agendaList, setagendaList] = React.useState([]);
  const [actionList, setactionList] = React.useState([]);
  const [allmeetingList, setAllMeetingList] = React.useState()
  const [feedbackList, setfeedbackList] = React.useState([]);
  const [feedbackLoading, setFeedbackLoading] = React.useState(false)
  const [meetingFeedback, setMeetingFeedBack] = React.useState()
  let actionItemSubscription = undefined
  const mountedRef = React.useRef(true)
  React.useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  React.useEffect(() => {
    mountedRef.current = true
  }, [])

  let actionSub = undefined
  const actionRef = React.useRef()

  React.useEffect(() => {
    return () => {
      if (actionSub) {
        actionSub.unsubscribe();
      }
    }
  })
  const getAllMeetingId = async (member, date_range) => {
    const { data } = await client.query({
      query: PREVOIUS_MEETING_LIST,
      variables: { member: member, when_Range: date_range, endedAt_Isnull: false },
      fetchPolicy: 'network-only'
    });
    if (data?.allMeetings) {
      setAllMeetingList(data?.allMeetings)
      previousMeetingCout(data?.allMeetings?.edges?.length)
      // subscribeToMore()
    }
  }

  const getMeetingFeedBack = async (value) => {
    setFeedbackLoading(true)
    let id = value;
    const { data } = await client.query({
      query: MEETING_RATING,
      variables: { id: id },
      fetchPolicy: 'network-only'
    });
    if (data?.meetingById) {
      setMeetingFeedBack(data?.meetingById?.meetingfeedbackSet)
      setFeedbackLoading(false)
    }
    else {
      setFeedbackLoading(false)
    }
  }

  React.useEffect(() => {
    if (meetingByIdData && permission?.requried_permission) {
      let otherMeetingOfMember = meetingByIdData?.member?.edges?.filter(({ node }) => getIntFromString(node?.user?.id) !== getIntFromString(me?.id))
      let start_date = moment().subtract(1, 'month').startOf('month').startOf('day').utc().toISOString()
      let end_date = moment().utc().toISOString()
      let date_range = [start_date, end_date]
      getAllMeetingId(otherMeetingOfMember[0]?.node?.user?.employee?.id, date_range)
    }
  }, [meetingByIdData, permission])

  const getMeetingDetail = async (value) => {
    setloading(true)
    let id = value;
    const { data } = await client.query({
      query: MEETING_BY_ID,
      variables: { id: id },
      fetchPolicy: 'network-only'
    });
    if (data?.meetingById) {
      actionRef.current = data?.meetingById
      setMeeting(data?.meetingById)
      // localStorage.setItem('currentpreviousMeeting',data?.meetingById?.id)
      // subscribeToMore()
      setloading(false)
    }
  }

  React.useEffect(() => {
    return () => {
      if (actionItemSubscription) {
        actionItemSubscription.unsubscribe();
      }

    }
  })


  React.useEffect(() => {

    if (meetingById) {
      let meetings = [].concat(nextMeetings)
      if (!meetinglist?.find(item => item === meetingById?.id)) {
        meetings = [meetingById.id].concat(meetings)
      }
      if (meetingById?.previousMeeting && !meetinglist?.find(item => item === meetingById?.previousMeeting?.id)) {
        meetings = [meetingById?.previousMeeting?.id].concat(meetings)
      }
      let uniqueMeetings = [...new Set(meetings)];
      let index = uniqueMeetings.indexOf(meetingById?.id)
      let noteslist = meetingById?.meetingnotesSet?.edges?.map(({ node }) => node)
      // let note = noteslist?.find(item=>item?.employee.id===me?.employee?.id)
      setnote(noteslist)
      setMeetingList(uniqueMeetings)
      setnextMeetings(uniqueMeetings)
      setcurrentMeetingIndex(index)
      let list = meetingById?.actionitemSet?.edges?.map(({ node }) => node)
      let feedbackList = meetingById?.feedback?.edges?.map(({ node }) => node)
      let agendaList = list?.filter(item => item?.isAgenda)
      let actionList = list?.filter(item => !item?.isAgenda)
      let actionFiltered = actionList?.map((item) => {
        return {
          title: item?.title,
          okrId: item?.okr,
          commitmentId: item?.commitment?.commitment,
          coachingCommitmentId: item?.coachingCommitment?.coachingcommitment,
          commitment: item?.commitment,
          coachingCommitment: item?.coachingCommitment,
          isAgenda: false,
          id: item?.id,
          member: null,
          notes: item?.notes,
          edited: false,
          updatedAt: item?.updatedAt,
          completed:item?.completed
        }
      }
      )

      let setAgenda = []
      agendaList.forEach(element => {

        let data =
        {
          id: element?.id,
          title: element?.title,
          // content:null,
          navigateTo: null,
          notes: element?.notes,
          okrId: element?.okr,
          commitmentId: element?.commitment?.commitment,
          coachingCommitmentId: element?.coachingCommitment?.coachingcommitment
        }


        setAgenda.push(data)
      });

      setagendaList(setAgenda)
      setactionList(actionFiltered)
      
      let setFeedback = []
      feedbackList.forEach(element => {
        let data =
        {
          id: element?.id,
          title: element?.feedbackTo?.user?.firstName,
          content: element?.feedbackType,
          navigateTo: null,
          response: element?.response?.answerSet,
          updatedAt: element?.updatedAt
        }
        setFeedback.push(data)
      })
      setfeedbackList(setFeedback)
      setloading(false)
    }

  }, [meetingById])


  React.useEffect(() => {
    if (MeetingId && permission.requried_permission) {
      let id = MeetingId
      getMeetingDetail(id)
      if (permission?.feedback_permission) {
        getMeetingFeedBack(id)
      }

    }
  }, [MeetingId, permission])


  return (
    <>

      {
        (permission && permission?.requried_permission) && (
          <PreviousMeetingCardView
            actionList={actionList}
            agendaList={agendaList}
            selfNotes={notes}
            allmeetingList={allmeetingList}
            getmeetingById={(e) => { getMeetingDetail(e); getMeetingFeedBack(e)}}
            {...props}
            permission={permission}
            meetingById={meetingById}
            loading={loading}
            feedbackLoading={feedbackLoading}
            meetingFeedback={meetingFeedback} />
        )
      }

    </>
  )

};

export default compose(withApollo,withUpdateActionItems)(PreviousMeetingCardContainer);