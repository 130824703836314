// @ts-nocheck
import React from 'react';
import { ReactDOM } from 'react-dom';
import styled from 'styled-components';

const ProgressCounterBtn = props => {
  const { idIndex, timeLimit = 5, btn: Btn, reset, setReset } = props;
  const [timesup, setTimesup] = React.useState(false);
  const progressBarId = `progressBtn${idIndex}`;
  const baseTimerPathRemainingId = `base-timer-path-remaining${idIndex}`;
  const baseTimerLabelId = `base-timer-label${idIndex}`;
  const [timeLeft, setTimeLeft] = React.useState(timeLimit);
  
  function formatTime(time) {
    const minutes = Math.floor(time / 60);
    let seconds = time % 60;

    if (seconds < 10) {
      seconds = `0${seconds}`;
    }

    return `${minutes}:${seconds}`;
  }

  const handleFilterRemove = React.useRef(() => {});
  // React.useEffect(() => {
  //   console.log('thisisuseeffect', document.getElementById(progressBarId));
  //   try {
  //     document.getElementById(progressBarId).innerHTML = `
  //      <div className="base-timer">
  //       <svg className="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
  //       <g className="base-timer__circle">
  //           <circle className="base-timer__path-elapsed" cx="50" cy="50" r="45"></circle>
  //           <path
  //             id=${baseTimerPathRemainingId}
  //             stroke-dasharray="283"
  //             className="base-timer__path-remaining ${remainingPathColor}"
  //             d="
  //               M 50, 50
  //               m -45, 0
  //               a 45,45 0 1,0 90,0
  //               a 45,45 0 1,0 -90,0
  //             "
  //             ></path>
  //         </g>
  //         </svg>
  //       <span id=${baseTimerLabelId} className="base-timer__label">${/* formatTime */ timeLeft}</span>
  //       </div>
  //       `;
  //   } catch (e) {
  //     console.log(e);
  //   }

  //   handleFilterRemove.current = () => {
  //     startTimer();
  //   };

  //   handleFilterRemove.current();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [progressBarId]);

  const FULL_DASH_ARRAY = 283;
  const WARNING_THRESHOLD = 10;
  const ALERT_THRESHOLD = 5;

  const COLOR_CODES = {
    info: {
      color: 'rgb(65, 184, 131)'
    },
    warning: {
      color: '#ffa500',
      threshold: WARNING_THRESHOLD
    },
    alert: {
      color: '#ff0000',
      threshold: ALERT_THRESHOLD
    }
  };

  const TIME_LIMIT = timeLimit;
  let timePassed = 0;

  let timerInterval = null;
  let remainingPathColor = COLOR_CODES.info.color;

  function onTimesUp() {
    clearInterval(timerInterval);
    setTimeLeft(TIME_LIMIT);
    setTimesup(true);
  }
  function calculateTimeFraction() {
    const rawTimeFraction = timeLeft / TIME_LIMIT;
    return rawTimeFraction - (1 / TIME_LIMIT) * (1 - rawTimeFraction);
  }
  function setRemainingPathColor() {
    const { alert, warning, info } = COLOR_CODES;
    if (timeLeft <= alert.threshold) {
      return alert.color;
    } else if (timeLeft <= warning.threshold) {
      return warning.color;
    } else {
      return info.color;
    }
  }

  function setCircleDasharray() {
    const circleDasharray = `${(calculateTimeFraction() * FULL_DASH_ARRAY).toFixed(0)} 283`;
    document.getElementById(`${baseTimerPathRemainingId}`).setAttribute('strokeDasharray', circleDasharray);
  }

  function startTimer() {
    timerInterval = setInterval(() => {
      setTimeLeft(timeLeft - 1);
      // document.getElementById(`${baseTimerLabelId}`).innerHTML = /* formatTime */ timeLeft;
      // setCircleDasharray();
      // setRemainingPathColor(timeLeft);

      if (timeLeft === 0) {
        setTimesup(true);
        onTimesUp();
      }
    }, 1000);
  }

  // React.useEffect(() => {
  //   if (reset === true) {
  //     console.log('bleh');
  //     setTimesup(true);
  //     onTimesUp();
  //     console.log(document.getElementById(progressBarId));
  //     setReset(false);
  //   }
  // }, [reset, setReset]);

  React.useEffect(() => {
    if (timeLeft !== -1) {
      startTimer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft]);

  return (
    <>
      <div style={{ position: 'relative', display: timesup && 'none' }} id={progressBarId}>
        <div className="base-timer">
          <svg className="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <g className="base-timer__circle">
              <Newcircle cx="50" cy="50" r="45" pathColor={timeLeft}></Newcircle>
              <path
                strokeDasharray="283"
                // className={`base-timer__path-remaining ${remainingPathColor}`}
                pathColor="ff0000red"
                d="
                M 50, 50
                m -45, 0
                a 45,45 0 1,0 90,0
                a 45,45 0 1,0 -90,0
              "
              ></path>
            </g>
          </svg>
          <span id={baseTimerLabelId} className="base-timer__label">
            {/* formatTime */ timeLeft}
          </span>
        </div>
      </div>
      <Btn style={{ display: !timesup ? 'none' : 'block' }} />
    </>
  );
};

export default ProgressCounterBtn;
const Newcircle = styled.circle`
  stroke: ${props => (props.pathColor <= 5 ? '#ff0000' : props.pathColor <= 10 ? '#ffa500' : 'rgb(65, 184, 131)')};
  stroke-width: 7px;
  /* stroke: rgb(65, 184, 131); */
  /* stroke: ${props => props.pathColor}; */
`;
