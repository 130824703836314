export const idp='idp'
const role='role'
const add = 'add'
const edit = 'edit'
const detail = 'detail'
const team ='team'

const idp_route = {
    home:`/${role}/${idp}`,
    create:`/${role}/${idp}/${add}`,
    edit:`/${role}/${idp}/${edit}/:idp_id`,
    detail:`/${role}/${idp}/${detail}/:idp_id`,
    team_idp:`/${role}/${idp}/${team}`,
    public_detail:`/${role}/${idp}/manager/:idp_id/:token`,
}

export default idp_route