import JobFamilyDetailContainer from './containers/JobFamilyDetailContainer'
import RoleDefinitionHomeContainer from './containers/RoleDefinitionHomeContainer'
import RoleDetailContainer from './containers/RoleDetailContainer'
import ROUTE from './route'

export default [
    {
        name: 'RoleDefinitionHome',
        path: ROUTE.role_definition_home,
        component: RoleDefinitionHomeContainer,
        exact: true,
        protect: true
    },
    {
        name: 'JobFmilyDetailPage',
        path: ROUTE.job_family_detail_page,
        component: JobFamilyDetailContainer,
        exact: true,
        protect: true
    },
    {
        name: 'RoleDetailPage',
        path: ROUTE.role_detail_page,
        component: RoleDetailContainer,
        exact: true,
        protect: true
    }
]

