import gql from 'graphql-tag';

export const LOOK_SURVEY_RESPONSE_STATE_QUERY = gql`
  query lookSurveyResponseState {
    lookSurveyResponseState @client {
      filter {
        first
        survey_Name_Icontains
      }
      orderBy
    }
  }
`;
