import gql from 'graphql-tag';
import { TEXT_SECTION_TYPE } from './TextSectionType.gql';

export const TEXT_SECTION_TYPE_CONNECTION = gql`
  ${TEXT_SECTION_TYPE}
  fragment TextSectionTypeConnection on TextSectionTypeConnection {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...TextSectionType
      }
    }
    totalCount
    edgeCount
  }
`;
