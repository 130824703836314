import React from 'react'
import '../../survey.css'
import { all_survey_icon } from 'modules/survey-module/icon';
import { Button, Tooltip } from 'antd'
import survey_module_route from 'modules/survey-module/routes';
import { parseMarkDownText } from 'modules/look';
import { SURVEY_DIVERSITY_PROFILE } from '../../../../config';
import diversity_survey_route from 'modules/diversity-survey/route';
import survey_route from 'modules/survey/route';
const OrgSurveyComponent = (props) => {
    const { all_surveys,navigateRoute,survey_loading,permission } = props

    const navigateTo360=(survey)=>{
        let url = survey_module_route.survey_360?.replace(":surveyid",survey?.id)?.replace(":responseid",survey?.response_id)
        navigateRoute(url)
    }

    const navigateToSurvey=(survey)=>{
        let url
        if(survey?.id == SURVEY_DIVERSITY_PROFILE){
            url = diversity_survey_route?.diversityHome?.replace(":id",survey?.id)
        }else{
            url = survey_route.surveyQuiz?.replace(":id",survey?.id)
        }
        if(url){
            navigateRoute(url)
        }
    }

    const navigateToReport=(survey)=>{
        navigateRoute(survey_module_route?.report_tool?.replace(":id",survey?.report_tool_id).replace(":response_id",survey?.response_id))
    }

    return (
        <>
            {
                all_surveys?.length ?
                    <div className='global-root-em'>
                        <div className='container-row-wrap'>
                            {
                                all_surveys?.map(i => (
                                    <div className='main-survey-card'>
                                        <div className='survey-card'>
                                            <div>
                                                <div className='survey-main-section'>
                                                    <div className='survey-title-section'>
                                                        <img src={i?.icon} alt="icon" style={{ width: "1.375em", height: "1.375em" }} />
                                                        <Tooltip title={i?.title}>
                                                        <h4 className='survey-title'>{i?.title}</h4>
                                                        </Tooltip>

                                                    </div>
                                                    {
                                                        (i?.is_360_available && i?.response_id && permission?.token_survey_permission) && (
                                                            <img src={all_survey_icon?.icon_360} alt="360" onClick={()=>navigateTo360(i)} style={{ cursor: "pointer" }} />
                                                        )
                                                    }
                                                </div>
                                                <p className='survey-info'>{parseMarkDownText(i?.info)}</p>
                                            </div>
                                            <div className='survey-main-section'>
                                                <Button className='common-btn survey-green-btn' onClick={()=>{navigateToSurvey(i)}}>{i?.response_id ? 'RETAKE' : 'TAKE SURVEY'}</Button>
                                                {
                                                    (i?.response_id && i?.report_tool_id && i?.reportAvailable) && (
                                                        <Button className='common-btn survey-blue-btn' onClick={()=>navigateToReport(i)}>REPORT</Button>
                                                    )
                                                }

                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div> : 
                    !survey_loading &&(
                        <div className='no-data-container'>
                            <h4 className='no-data-label'>No survey found</h4>
                        </div>
                    )
                    
            }
        </>
    )
}

export default OrgSurveyComponent