import { message } from "antd";
import { graphql } from '@apollo/client/react/hoc';
import { DELETE_ROLE } from "../graphql/RoleQueries.gql";


  export const withDeleterole = Component =>
  graphql(DELETE_ROLE, {
    props: ({ mutate}) => ({
        deleteRole: async values => {
        try {
          const {
            data: { deleteRole }
          } = await mutate({
            variables: {
              ...values
            }
          });

          message.destroy();
          message.success('Successfully deleted');
          return true;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
          console.error(e);
        }
      }
    })
  })(Component);

