import gql from 'graphql-tag';

export const COLLABORATOR_LIST = gql`
query me {
  collaborator:me{
    id
    employee {
      id
      organizationSet {
        edges {
          node {
            id
            employees {
              edges {
                node {
                  id
                  user {
                    id
                    firstName
                    lastName
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`