import React from 'react';
import { Row, Col, Form, Button, Divider } from 'antd';

import { TextField, CheckBoxField, ButtonsComponent, MarkdownField, CKEditorField } from '../../look';

export const CKEditorFieldName1 = 'helpText';
export const CKEditorFieldName2 = 'label';

const GroupFormComponent = props => {
  const { onSubmit, groupById } = props;
  const [button, setButton] = React.useState('');

  const handleSubmit = values => {
    const data = {
      ...values,
      sequence: Number(values.sequence)
    };
    onSubmit(data, button);
  };

  const initialValues = {
    name: groupById && groupById.name && groupById.name,
    label: groupById && groupById.label && groupById.label,
    helpText: groupById && groupById.helpText && groupById.helpText,
    sequence: groupById && groupById.sequence && groupById.sequence,
    showInResult: groupById && groupById.showInResult,
    matrix: groupById && groupById.matrix,
    showAvgInResult: groupById && groupById.showAvgInResult
  };

  return (
    <Row>
      <Col span={24}>
        <Form
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={initialValues}
          required={false}
          scrollToFirstError={true}
        >
          <Row>
            <Col span={10} offset={1}>
              <Row>
                <Col lg={14} md={20}>
                  <TextField name="name" label="Name" rules={[{ required: true, message: 'Name is required!' }]} />
                </Col>
              </Row>
              <Row>
                <Col lg={14} md={20}>
                  <TextField
                    name="sequence"
                    label="Sequence"
                    type="number"
                    rules={[{ required: true, message: 'Sequence is required!' }]}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={10}>
              <Row>
                <Col span={24}>
                  <CheckBoxField name="matrix" label="Matrix" />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <CheckBoxField name="showInResult" label="Show in Result" />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <CheckBoxField name="showAvgInResult" label="Show avg in Result" />
                </Col>
              </Row>
            </Col>
            <Col span={3} />
            <Col span={22} offset={1}>
              <CKEditorField name={CKEditorFieldName2} label="Label" initialValue={groupById && groupById.label} />
            </Col>
            <Col span={22} offset={1}>
              <CKEditorField name={CKEditorFieldName1} label="Help Text" initialValue={groupById && groupById.helpText} />
            </Col>
          </Row>
          {/* <MarkdownField name="helpText" label="Help Text" initialValue={groupById && groupById.helpText} /> */}
          <Divider />
          <ButtonsComponent setButton={setButton} />
        </Form>
      </Col>
    </Row>
  );
};

export default GroupFormComponent;
