import PersonalDashboard from "./containers/PersonalDashboard";

import ROUTE from "./route";

export default [
  {
    name: "personal Dashboard",
    path: ROUTE.personal,
    component: PersonalDashboard,
    protect: true,
  },
];
