import gql from 'graphql-tag';

export const UPDATE_MEETIING_NOTES_MUTATION = gql`
  mutation updateMeetingNotes($id:ID!,$notes: String!) {
    updateMeetingNotes(id:$id,notes: $notes) {
        meetingNotes {
        id
      }
    }
  }
`;
