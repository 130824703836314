// @ts-nocheck
import { message } from 'antd';
import { graphql } from '@apollo/client/react/hoc';
import { CREATE_EMPLOYEE_LEVEL, DELETE_EMPLOYEE_MUTATION } from './graphql/EmployeeLevelQuries.gql';
import { CREATE_COMPETENCY_CATEGORY, CREATE_COMPETENCY_SKILL, DELETE_COMPETENCY, EDIT_COMPETENCY_CATEGORY, UPDATE_SUB_COMPETENCY_SKILL } from './graphql/CompetencyQuries.gql';
import { CREATE_JOB_FAMILY, JOB_FAMILY_DELETE, UPDATE_JOB_FAMILY } from './graphql/JobFamilyQuries.gql';
import { MEMBER_DELETE_MUTATION } from './graphql/AssignEmployee.gql';
import { CREATE_EDUCATION, CREATE_ELIGIBILITY, CREATE_RESPONSILBILITY, CREATE_ROLE, DELETE_ROLE, UPDATE_ELIGIBILITY, UPDATE_ROLE } from './graphql/RoleQueries.gql';

// Add New Employee Level
export const withCreateEmployeeLevel = Component =>
graphql(CREATE_EMPLOYEE_LEVEL, {
  props: ({ mutate, history }) => ({
    createEmployeeLevel: async values => {
      try {
        const {
          data: { createEmployeeLevel }
        } = await mutate({
          variables: {
            ...values
          }
        });

        message.destroy();
        message.success('Employee Level created successfully ');
        return createEmployeeLevel.employeeLevel;
      } catch (e) {
        message.destroy();
        message.error("Failed to create employee level");
        console.error(e);
      }
    }
  })
})(Component);

export const withDeleteEmployeeLevel = Component =>
graphql(DELETE_EMPLOYEE_MUTATION, {
  props: ({ mutate}) => ({
    deleteEmployeeLevel: async values => {
      try {
        const {
          data: { deleteEmployeeLevel }
        } = await mutate({
          variables: {
            ...values
          }
        });
        message.destroy();
        message.success('Successfully deleted employee level');
        return true;
      } catch (e) {
        message.destroy();
        message.error("Failed to delete employee level");
      }
    }
  })
})(Component);

export const withDeleteCompetency = Component =>
  graphql(DELETE_COMPETENCY, {
    props: ({ mutate}) => ({
      deleteCompetency: async values => {
        try {
          const {
            data: { deleteCompetency }
          } = await mutate({
            variables: {
              ...values
            }
          });

          message.destroy();
          message.success('Successfully deleted sub competency');
          return true;
        } catch (e) {
          message.destroy();
          message.error("Failed to delete sub competency");
        }
      }
    })
  })(Component);

  export const withDeleteJobFamily= Component =>
  graphql(JOB_FAMILY_DELETE, {
    props: ({ mutate}) => ({
      deleteJobFamily: async values => {
        try {
          const {
            data: { deleteJobFamily }
          } = await mutate({
            variables: {
              ...values
            }
          });
          message.destroy();
          message.success('Successfully deleted job family');
          return true; 
        } catch (e) {
          message.destroy();
          message.error("Failed to delete job family");
          console.error(e);
        }
      }
    })
  })(Component);


  export const withCreateCompetencySkill = Component =>
  graphql(CREATE_COMPETENCY_SKILL, {
      props: ({ mutate, history }) => ({
          createCompetency: async values => {
              try {
                  const {
                      data: { createCompetency }
                  } = await mutate({
                      variables: {
                          ...values
                      }
                  });
                  message.destroy();
                  message.success('Sub Competency created successfully');
                  return createCompetency.competency;
              } catch (e) {
                  message.destroy();
                  if(e?.message?.includes('Competency or Skill already exists.')){
                      message.error('Competency or Skill already exists.')
                  }else{
                      message.error("Couldn't perform the action");
                  }
                  
                  console.error(e);
              }
          }
      })
  })(Component);

  export const withUpdateCompetencySkill = Component =>
  graphql(UPDATE_SUB_COMPETENCY_SKILL, {
      props: ({ mutate, history }) => ({
        updateCompetency: async values => {
              try {
                  const {
                      data: { updateCompetency }
                  } = await mutate({
                      variables: {
                          ...values
                      }
                  });
                  message.destroy();
                  message.success('Competency updated successfully');
                  return updateCompetency.competency;
              } catch (e) {
                  message.destroy();
                  if(e?.message?.includes('Competency or Skill already exists.')){
                      message.error('Competency or Skill already exists.')
                  }else{
                      message.error("Couldn't perform the action");
                  }
                  
                  console.error(e);
              }
          }
      })
  })(Component);

  export const withCreateJobFamily = Component =>
  graphql(CREATE_JOB_FAMILY, {
    props: ({ mutate, history }) => ({
      createJobFamily: async values => {
        try {
          const {
            data: { createJobFamily }
          } = await mutate({
            variables: {
              ...values
            }
          });

          message.destroy();
          message.success('Job family created successfully');
          return createJobFamily.jobFamily;
        } catch (e) {
          message.destroy();
          if(e?.message?.includes('unique_JobFamily')){
            message.error("This job family already exist");
          }else{
            message.error("Failed to create job family");
          }
          
          console.error(e);
        }
      }
    })
  })(Component);

  export const withUpdateJobFamily = Component =>
  graphql(UPDATE_JOB_FAMILY , {
    props: ({ mutate, history }) => ({
      updateJobFamily: async values => {
        try {
          const {
            data: { updateJobFamily }
          } = await mutate({
            variables: {
              ...values
            }
          });

          message.destroy();
          message.success('job family updated successfully');
          return updateJobFamily.jobFamily;
        } catch (e) {
          message.destroy();
          if(e?.message?.includes('unique_JobFamily')){
            message.error("This job family already exist");
          }else{
            message.error("Failed to create job family");
          }
          console.error(e);
        }
      }
    })
  })(Component);

  export const withUpdateCompetencyCategory = Component =>
  graphql(EDIT_COMPETENCY_CATEGORY , {
    props: ({ mutate, history }) => ({
      updateCompetencyCategory: async values => {
        try {
          const {
            data: { updateCompetencyCategory }
          } = await mutate({
            variables: {
              ...values
            }
          });

          message.destroy();
          message.success('competency category updated successfully');
          return updateCompetencyCategory.competencyCategory;
        } catch (e) {
          message.destroy();
          message.error("Failed to update competency category");
          console.error(e);
        }
      }
    })
  })(Component);

  export const withDelete = Component =>
  graphql(DELETE_ROLE , {
    props: ({ mutate, history }) => ({
      deleteRole: async values => {
        try {
          const {
            data: { deleteRole }
          } = await mutate({
            variables: {
              ...values
            }
          });

          message.destroy();
          message.success('Role successfully deleted');
          return deleteRole.role;
        } catch (e) {
          message.destroy();
          message.error("Failed to delete role")
          console.error(e);
        }
      }
    })
  })(Component);

  export const withCreateRole = Component =>
  graphql(CREATE_ROLE, {
    props: ({ mutate, history }) => ({
      createRole: async values => {
        try {
          const {
            data: { createRole }
          } = await mutate({
            variables: {
              ...values
            }
          });

          message.destroy();
          message.success('Role created successfully');
          return createRole.role;
        } catch (e) {
          message.destroy();
          message.error("Failed to create role");
          console.error(e);
        }
      }
    })
  })(Component);


  export const withUpdateRole = Component =>
  graphql(UPDATE_ROLE, {
    props: ({ mutate, history }) => ({
      updateRole: async values => {
        try {
          const {
            data: { updateRole }
          } = await mutate({
            variables: {
              ...values
            }
          });

          message.destroy();
          message.success('Role updated successfully');
          return updateRole.role;
        } catch (e) {
          message.destroy();
          message.error("Failed to update role");
          console.error(e);
        }
      }
    })
  })(Component);
  
  export const withDeleteMember = Component =>
  graphql(MEMBER_DELETE_MUTATION , {
    props: ({ mutate, history }) => ({
      deleteMember: async values => {
        try {
          const {
            data: { deleteMember }
          } = await mutate({
            variables: {
              ...values
            }
          });

          message.destroy();
          message.success('Role assigned has been removed successfully');
          return deleteMember.member;
        } catch (e) {
          message.destroy();
          message.error("Failed to remove assigned role")
          console.error(e);
        }
      }
    })
  })(Component);

  export const withCreateResponsibility = Component =>
  graphql(CREATE_RESPONSILBILITY, {
    props: ({ mutate, history }) => ({
      createResponsibility: async values => {
        try {
          const {
            data: { createResponsibility }
          } = await mutate({
            variables: {
              ...values
            }
          });

          message.destroy();
          message.success('Responsibility created successfully');
          return createResponsibility.responsibility;
        } catch (e) {
          message.destroy();
          message.error("Failed to create responsibility");
          console.error(e);
        }
      }
    })
  })(Component);

  export const withCreateEligibility = Component =>
  graphql(CREATE_ELIGIBILITY, {
    props: ({ mutate, history }) => ({
      createEligibility: async values => {
        try {
          const {
            data: { createEligibility }
          } = await mutate({
            variables: {
              ...values
            }
          });

          message.destroy();
          message.success('Eligibility created successfully');
          return createEligibility.eligibility;
        } catch (e) {
          message.destroy();
          message.error("Failed to create eligibility");
          console.error(e);
        }
      }
    })
  })(Component);

  export const withUpdateEligibility = Component =>
  graphql(UPDATE_ELIGIBILITY, {
    props: ({ mutate, history }) => ({
      updateEligibility: async values => {
        try {
          const {
            data: { updateEligibility }
          } = await mutate({
            variables: {
              ...values
            }
          });

          message.destroy();
          message.success('Eligibility updated successfully');
          return updateEligibility.eligibility;
        } catch (e) {
          message.destroy();
          message.error("Failed to update eligibility");
          console.error(e);
        }
      }
    })
  })(Component);

  export const withCreateEducation = Component =>
  graphql(CREATE_EDUCATION, {
    props: ({ mutate, history }) => ({
      createEducation: async values => {
        try {
          const {
            data: { createEducation }
          } = await mutate({
            variables: {
              ...values
            }
          });

          message.destroy();
          message.success('New education successfully added');
          return createEducation.education;
        } catch (e) {
          message.destroy();
          message.error("Failed to add new education");
          console.error(e);
        }
      }
    })
  })(Component);

  export const withCreateCompetencyCategory = Component =>
  graphql(CREATE_COMPETENCY_CATEGORY, {
      props: ({ mutate, history }) => ({
        createCompetencyCategory: async values => {
              try {
                  const {
                      data: { createCompetencyCategory }
                  } = await mutate({
                      variables: {
                          ...values
                      }
                  });
                  
                  message.destroy();
                  
                  return createCompetencyCategory.competencyCategory;
              } catch (e) {
                  message.destroy();
                  if(e?.message?.includes('Competency or Skill already exists.')){
                      message.error('Competency or Skill already exists.')
                  }else{
                      message.error("Couldn't perform the action");
                  }
                  
                  console.error(e);
              }
          }
      })
  })(Component);