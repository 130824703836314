import _ from 'lodash';
import Cookies from 'js-cookie';

const cookieOptions = { path: '/', domain: '.brainayan.com' };

export const getItem = async name =>
  process.env.NODE_ENV !== 'production' ? Cookies.get(name) : Cookies.get(name, cookieOptions);
export const getSyncItem = name =>
  process.env.NODE_ENV !== 'production' ? Cookies.get(name) : Cookies.get(name, cookieOptions);
export const setItem = async (name, value) =>
  process.env.NODE_ENV !== 'production' ? Cookies.set(name, value) : Cookies.set(name, value, cookieOptions);
export const removeItem = async name =>
  process.env.NODE_ENV !== 'production' ? Cookies.remove(name) : Cookies.remove(name, cookieOptions);

export const omitNested = (obj, paths) => {
  const omittedObject = _.omit(obj, paths);

  Object.keys(omittedObject).forEach(key => {
    if (typeof omittedObject[key] === 'object') {
      omittedObject[key] = omitNested(omittedObject[key], paths);
    }
  });

  return omittedObject;
};

export const removeTypename = obj => omitNested(obj, '__typename');


export const setCookieFlag = async (name, value,options) =>
  Cookies.set(name, value,options);

export const getCookieFlag = async name =>
  Cookies.get(name)
