import React from "react";
import { Col, Form, Input, List, Row } from "antd";
import ReactDragListView from "react-drag-listview";
import SelectField from "./form-components/SelectField";
import { InputValuesObject } from "./InputValues";
import { getBase64String, getBase64StringFromInt, withMarkdownFieldValue } from "./functions";
import { PlusSquareOutlined, DragOutlined, DeleteOutlined } from '@ant-design/icons';
import TextField from "./form-components/TextField";
import CKEditorField from "./form-components/CKEditorField";
import GrowSelectField from "modules/survey-look/containers/grow-select-field";
import SurveyTextField from "modules/survey-look/containers/survey-text-field";
import ad_icon from '../../assets/grow-drag-add-icon.svg'
import drag_icon from '../../assets/grow-drag-item-icon.svg'
import delete_icon from '../../assets/grow-drag-delete-icon.svg'
const FormItem = Form.Item;
const rules = [ { required: true, message: 'An answer is required.' } ];
function OrderedInput( props ) {
    console.log( "ORDERED_PROPS", props )
    const { offset = 1, autoscroll,margin } = props;
    const [ data, setData ] = React.useState( Array.from( Array( props.initCount ).keys() ).map( m => m.toString() ) )
    const [ deletedVals, setDeletedVals ] = React.useState( [] )


    const onDragEnd = ( fromIndex, toIndex ) => {
        if ( toIndex < 0 ) return; // Ignores if outside designated area
        const items = [ ...data ];
        const item = items.splice( fromIndex, 1 )[ 0 ];
        items.splice( toIndex, 0, item );
        setData( items )

        let curVals = props.form.getFieldValue( props.name )
        if ( curVals !== undefined ) {
            const vals = [ ...curVals ];
            const val = vals.splice( fromIndex, 1 )[ 0 ];
            vals.splice( toIndex, 0, val );
            props.form.setFieldsValue( {
                [ props.name ]: vals
            } )
        }
    };

    const plus = () => {
        if ( data.filter( e => !deletedVals.includes( e ) ).length < props.maxCount ) {
            let nVal = data.length + 1
            setData( Array.from( Array( nVal ).keys() ).map( m => m.toString() ) )
        }
    }


    const minus = ( idx, i ) => {
        if ( data.filter( e => !deletedVals.includes( e ) ).length > props.minCount ) {
            setDeletedVals( [ ...deletedVals, idx ] )
        }

        let indx = parseInt( idx )
        let curVal = props.form.getFieldValue( props.name )
        if ( curVal !== undefined ) {
            if ( curVal.length >= indx + 1 && indx !== 0 ) {
                let p = curVal.slice( 0, indx )
                let n = curVal.slice( indx + 1, curVal.length )
                props.form.setFieldsValue( {
                    [ props.name ]: [ ...p, ...n ]
                } )
            }
            if ( indx === 0 ) {
                let next = curVal.slice( 1, curVal.length )
                props.form.setFieldsValue( {
                    [ props.name ]: [ ...next ]
                } )
            }
            if ( curVal.length < indx + 1 && indx !== 0 ) {
                let next = curVal.slice( 0, curVal.length - 1 )
                props.form.setFieldsValue( {
                    [ props.name ]: [ ...next ]
                } )
            }
        }
    }

    const onSelectChange = ( e ) => {
        let curVal = props.form.getFieldValue( props.name )
        if ( curVal === undefined ) {
            props.form.setFieldsValue( {
                [ props.name ]: [ e ]
            } )

        } else {
            props.form.setFieldsValue( {
                [ props.name ]: [ ...curVal, e ]
            } )
        }
        props.onSelectChange( e );
    }

    const onBlur = ( e, idx ) => {
        let indx = parseInt( idx )
        let curVal = props.form.getFieldValue( props.name )
        let newVal = e?.target?.value || e

        if ( curVal === undefined ) {
            props.form.setFieldsValue( {
                [ props.name ]: [ newVal ]
            } )

        } else {
            props.form.setFieldsValue( {
                [ props.name ]: [ ...curVal, newVal ]
            } )
            if ( curVal.length >= indx + 1 && indx !== 0 ) {

                let p = curVal.slice( 0, indx )
                let n = curVal.slice( indx + 1, curVal.length )
                props.form.setFieldsValue( {
                    [ props.name ]: [ ...p, newVal, ...n ]
                } )
            }
            if ( indx === 0 ) {

                let next = curVal.slice( 1, curVal.length )
                props.form.setFieldsValue( {
                    [ props.name ]: [ newVal, ...next ]
                } )
            }
            if ( curVal.length < indx + 1 && indx !== 0 ) {
                props.form.setFieldsValue( {
                    [ props.name ]: [ ...curVal, newVal ]
                } )
            }
        }
        // props.onBlur(e);
    }



    return (
        <div>
            <FormItem
                name={props.name}
                rules={props.rules}
                labelCol={{ span: 24 }} wrapperCol={{ span: 24}}
                required={props?.required}
                style={{ paddingLeft: "0px",marginBottom:margin?margin:"24px"}}
            >
                <ReactDragListView
                    nodeSelector=".ant-list-item.draggble"
                    onDragEnd={onDragEnd}
                    style={{ padding: "0px" }}
                >
                    <List
                        size="large"
                        bordered={false}
                        className="grow-list"
                        style={{ padding: "0px" }}
                        dataSource={data.filter( e => !deletedVals.includes( e ) )}
                        renderItem={( item, i ) => {

                            return (
                                <List.Item
                                    actions={[]}
                                    className="draggble"
                                    style={{ padding: "0px", marginLeft: "0px", borderBottom: "none" }}
                                >

                                    <div style={{ width: '100%', display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "center", gap: '1em', marginTop: '1em' }}>
                                        <div style={{ width: "100%" }}>
                                            {props.type === InputValuesObject.select && (
                                                <>{item}
                                                    <GrowSelectField
                                                        showSearch
                                                        onSearch={props.onSelectFeildSearch}
                                                        otherOption={true}
                                                        name={`${( "ignore-" + props.name + item )}`}
                                                        choices={props.choices}
                                                        onSelectChange={onSelectChange}
                                                        onBlur={props.onBlur}
                                                        required={props?.required}
                                                    />
                                                </>
                                            )}

                                            {props.type === InputValuesObject.text && (
                                                <>
                                                    <SurveyTextField
                                                        name={`${( "ignore-" + props.name + item )}`}
                                                        onBlur={e => onBlur( e, item )}
                                                        className={'grow-survey-input-filed'}
                                                        margin={'0px'}
                                                        required={props?.required}
                                                    />
                                                </>
                                            )}
                                            {props.type === InputValuesObject.ckeditor && (
                                                <>
                                                    <CKEditorField
                                                        required={props?.required}
                                                        name={`${( "ignore-" + props.name + item )}`}
                                                        changeHandler={e => onBlur( withMarkdownFieldValue( props.name, { [ props.name ]: e } )[ props.name ], item )}
                                                    />
                                                </>
                                            )}
                                        </div>
                                       {data.filter( e => !deletedVals.includes( e ) ).length>1&&<div>
                                            <div className="menu-icon">
                                                <div className="dot"></div>
                                                <div className="dot"></div>
                                                <div className="dot"></div>
                                                <div className="dot"></div>
                                                <div className="dot"></div>
                                                <div className="dot"></div>
                                            </div>
                                        </div>}
                                        {i + 1 !== data.filter( e => !deletedVals.includes( e ) ).length && <div>
                                            <img src={delete_icon} style={{ width: "2.5em", height: "2.5em", cursor: 'pointer' }} alt="delete_icon" onClick={() => minus( item, i )} />
                                        </div>}
                                        {i + 1 === data.filter( e => !deletedVals.includes( e ) ).length && (
                                            <div>
                                                <img src={ad_icon} style={{ width: "2.5em", height: "2.5em", cursor: 'pointer' }} alt="add_icon" onClick={plus} />
                                            </div>
                                        )}
                                        {/* <Col lg={2} xs={2}>
                                                <span style={{ fontSize: '1.5em', paddingLeft: '2vw' }}>
                                                    <DragOutlined />
                                                </span>
                                            </Col> */}
                                    </div>

                                </List.Item>
                            );
                        }}

                    >

                    </List>
                </ReactDragListView>
            </FormItem>
        </div>
    );
}


export default OrderedInput;
