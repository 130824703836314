import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { compose } from '../../core';
import { withToolById } from '../../tool/containers/ToolOperations';
import { getIntFromString } from '../../look';
import TOOL_ROUTE from '../../tool/route';

const RecentToolComponent = props => {
  const { getToolById } = props;
  
  return <>{getToolById ? 
    <Link to={`${TOOL_ROUTE.genericToolLink}${getIntFromString(getToolById?.id)}`} target="_blank">
      <Hoverable>{getToolById?.title}</Hoverable>
    </Link>
    : <span>Tool not published yet.</span>}</>;
};

export default compose(withToolById)(RecentToolComponent);
const Hoverable = styled.div`
  // white-space: nowrap;
  overflow: hidden;
  color: black;
  :hover {
    white-space: unset;
    overflow: unset;
    cursor: pointer;
  };
`;
