import update from 'immutability-helper';

import { PAGINATION_LIMIT } from '../../../config';

import { TOOL_STATE_QUERY } from '../graphql/ToolStateQuery.client.gql';

const TYPE_TOOL_STATE = 'ToolState';

const TYPE_TOOL_STATE_FILTER = 'ToolFilterInput';

const TYPE_TOOL_STATE_PAGINATION = 'ToolPaginationInput';

const defaults = {
  toolState: {
    filter: {
      title_Icontains: '',
      description_Icontains: '',
      __typename: TYPE_TOOL_STATE_FILTER
    },
    orderBy: ['-id'],
    pagination: {
      first: PAGINATION_LIMIT,
      offset: 0,
      __typename: TYPE_TOOL_STATE_PAGINATION
    },
    __typename: TYPE_TOOL_STATE
  }
};

const resolvers = {
  Mutation: {
    updateToolFilter: (_, { filter, orderBy, pagination }, { cache }) => {
      const { toolState } = cache.readQuery({ query: TOOL_STATE_QUERY });
      const newToolState = update(toolState, {
        filter: { $merge: filter },
        orderBy: { $set: orderBy },
        pagination: { $merge: pagination }
      });

      cache.writeData({
        data: {
          toolState: newToolState,
          __type: TYPE_TOOL_STATE
        }
      });

      return null;
    }
  }
};

export default { defaults, resolvers };
