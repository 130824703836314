import gql from "graphql-tag";

export const FEEDBACK_SURVEY_BY_ID = gql`
query SurveyById($id:ID!){
    SurveyById(id:$id){
        id
        groupSet{
          edges{
            node{
              id
              questionSet{
                edges{
                  node{
                    id
                    questionText
                    questionAddress
                    required
                    choiceSet{
                      edges{
                        node{
                          id
                          inputType
                          label
                        }
                      }
                    }
                  }
                }
                
              }
            }
          }
        }
      }
}
`;
