// @ts-nocheck
import { compose } from 'modules/core';
import React from 'react';
import { withApollo } from '@apollo/client/react/hoc';
import { localTz } from 'modules/look';
import { ALL_CASCADING_OBJECTIVES, ALL_NON_CASCADING_OBJECTIVES, ALL_OBJECTIVES, ALL_OBJECTIVES_NEW_QUERY, NON_CASCADED_OKRS_PAGINATED, UPCOMING_MILESTONES } from '../graphql/okrDahboardQueries.gql';

import IndividualDashBoardView from '../components/IndividualDashboardView';
import moment from 'moment';




const IndividualDashboardContainer = props => {


  const { client, me, milestones, dateRangeTofilter } = props
  const [nonCascadedOkr, setNonCascadedOkr] = React.useState();
  const [cascadedOkr, setCascadedOkr] = React.useState();
  const [upcomingMilestones, setUpcomingMmilestones] = React.useState();
  const [dateRange, setdateRange] = React.useState(dateRangeTofilter);
  const [paginatedOkrLoading, setPaginatedOkrLoading] = React.useState(true);
  const [upcomingMilestoneLoading, setUpcomingMilestoneLoading] = React.useState(true);
  const [cascadedOkrLoading, setCascadedOkrLoading] = React.useState(true);
  const [nonCascadedOkrLoading, setNonCascadedOkrLoading] = React.useState(true);
  const [performanceData,setPerformanceData]=React.useState()
  let currentTab = React.useRef('objective')


  React.useEffect(() => {



    if (me && dateRangeTofilter) {
      
      setdateRange(dateRangeTofilter)
      getAllCascadedObjectives(me?.employee?.id, 0);
      getAllNonCascadedObjectives(me?.employee?.id, 0);
      getUpcomingMilestones(me?.employee?.id)
      paginaionLoad(0,currentTab['current'])

    }
  }, [me, dateRangeTofilter])





  const getFilters = (page, okrType) => {

    let  filter = {
      orderBy: ["okr__duedate"],
      okr_Duedate_Range: dateRangeTofilter,
      offset: page*5,
      first: 5,
      employee: me?.employee?.id,
      // role_In_non_cascaded: ["OWNER"],
      savedOkrType: okrType==='objective'?"OBJECTIVE":okrType==='keyresult'?'KEY_RESULT':'MILESTONE',
      tz: localTz
    }
    // if(okrType==='objective'){
    //   filter['role_In_cascaded']=["COLLABORATOR", "ASSIGNED"]
    // }
    return filter
  }




  const getOkrs = (okrType,data,pagination) => {
   
    let obj;
    
      if(okrType==='objective'){
        obj = {
          edgeCount: data?.AllCascadingObjectivesPaginated?.edgeCount,
          edges:[...data?.AllCascadingObjectivesPaginated?.edges],
          totalCount: data?.AllCascadingObjectivesPaginated?.totalCount,
          pagination:pagination
        }
        
      }
      else if(okrType==='keyresult')  {
        obj = {
          edgeCount:data?.AllNonCascadingObjectivesPaginated?.edgeCount,
          edges: data?.AllNonCascadingObjectivesPaginated?.edges,
          totalCount: data?.AllNonCascadingObjectivesPaginated?.totalCount,
          pagination:pagination
        }

        
      }
     else {

        obj = {
          edgeCount:data?.AllNonCascadingObjectivesPaginated?.edgeCount, 
          edges:data?.AllNonCascadingObjectivesPaginated?.edges,
          totalCount: data?.AllNonCascadingObjectivesPaginated?.totalCount,
          pagination:pagination
        }

        
      }
   
    
    return obj;

  }


  const paginaionLoad = async (page, okrType,pagination=false) => {
    currentTab['current']=okrType
    let filters = getFilters(page, okrType);
    // switch (okrType) {
      if(okrType==='objective'){
        setPaginatedOkrLoading(true);
        const { data } = await client.query({
          query: ALL_OBJECTIVES_NEW_QUERY,
          // fetchPolicy: 'network-only',
          variables: filters,
        });
        if (data) {
          let fetchedData=getOkrs(okrType,data,pagination)
          
          setPerformanceData(fetchedData)
        }
        setPaginatedOkrLoading(false);
        
      }
      else if(okrType==='keyresult'){
        setPaginatedOkrLoading(true);
        const { data } = await client.query({
          query: NON_CASCADED_OKRS_PAGINATED,
          variables: filters,
          // fetchPolicy: 'network-only'
        });

        if (data) {
          let fetchedData=getOkrs(okrType,data,pagination)
         
          setPerformanceData(fetchedData)
        }
        setPaginatedOkrLoading(false);
        
      }
      else{
        setPaginatedOkrLoading(true);
        const { data } = await client.query({
          query: NON_CASCADED_OKRS_PAGINATED,
          variables: filters,
          // fetchPolicy: 'network-only'
        });
        if (data) {
          let fetchedData=getOkrs(okrType,data,pagination)
          setPerformanceData(fetchedData)
        }
        setPaginatedOkrLoading(false);
        
      }
     

  }


  const getAllCascadedObjectives = async (id, offset,pagination=false) => {
    setCascadedOkrLoading(true);

    const { data } = await client.query({
      query: ALL_CASCADING_OBJECTIVES,
      variables: {
        okr_Duedate_Range: dateRangeTofilter, role_In: ["COLLABORATOR", "ASSIGNED"], employee: id, createdBy_Ne: id, offset: offset * 100, first: 100,
        savedOkrType: 'OBJECTIVE'
      },
      
    });

    if (data?.AllCascadingObjectives) {
      if (pagination) {
        let obj = {
          edgeCount: cascadedOkr?.edgeCount + data?.AllCascadingObjectives?.edgeCount,
          edges: [...cascadedOkr?.edges, ...data?.AllCascadingObjectives?.edges],
          totalCount: data?.AllCascadingObjectives?.totalCount
        }
        setCascadedOkr(obj)
        setCascadedOkrLoading(false)
      } else {
        setCascadedOkr(data?.AllCascadingObjectives)
        setCascadedOkrLoading(false)
      }
    }
  }

  const getAllNonCascadedObjectives = async (id, offset,pagination=false) => {
    setNonCascadedOkrLoading(true);
   
    const { data } = await client.query({
      query: ALL_NON_CASCADING_OBJECTIVES,
      variables: {
        okr_Duedate_Range: dateRangeTofilter, role_In: ["OWNER"], createdBy: id, savedOkrType: 'OBJECTIVE', offset: offset * 100,
        first: 100
      },
      
    });

    if (data?.AllNonCascadingObjectives) {

      if (pagination) {
        let obj = {
          edgeCount: nonCascadedOkr?.edgeCount + data?.AllNonCascadingObjectives?.edgeCount,
          edges: [...nonCascadedOkr?.edges, ...data?.AllNonCascadingObjectives?.edges],
          totalCount: data?.AllNonCascadingObjectives?.totalCount
        }
        setNonCascadedOkr(obj)
        setNonCascadedOkrLoading(false);
      } else {
        setNonCascadedOkr(data?.AllNonCascadingObjectives)
        setNonCascadedOkrLoading(false);
      }

    }

  }


  const getUpcomingMilestones = async (id) => {
    let okrType = "MILESTONE"
    let isStartDateSame = moment.utc(dateRangeTofilter[0]).isSame(moment.utc(dateRange[0]))
    let isEndDateSame = moment(dateRangeTofilter[1]).isSame(moment.utc(dateRange[1]))
    setUpcomingMilestoneLoading(true)
    const { data } = await client.query({
      query: UPCOMING_MILESTONES,
      variables: { okr_Duedate_Range: [moment().toISOString(), moment().endOf('year').utc().toISOString()] ,
        employee: id, savedOkrType: okrType, offset: 0, first: 5, tz: localTz },
      fetchPolicy: (isStartDateSame && isEndDateSame) ? 'cache-first' : 'network-only'
    });

    if (data?.contributors) {
    
      const sortedDates = data?.contributors?.edges?.map(node => { return { ...node, date: new Date(node?.node?.okr?.duedate) } })
  .sort((a, b) => a.date - b.date)
  
      setUpcomingMmilestones({edges:sortedDates})
      setUpcomingMilestoneLoading(false);
    }

  }





  return (
    <>
      <IndividualDashBoardView
        me={me}
        cascadedOkr={cascadedOkr}
        upcomingMilestones={upcomingMilestones}
        nonCascadedOkr={nonCascadedOkr}
        cascadedOkrLoading={cascadedOkrLoading}
        nonCascadedOkrLoading={nonCascadedOkrLoading}
        getAllNonCascadedObjectives={(e,offset)=>{getAllNonCascadedObjectives(e,offset,true)}}
        getAllCascadedObjectives={(e,offset)=>{getAllCascadedObjectives(e,offset,true)}}
        onPagination={(e, okrType,pagination) => { paginaionLoad(e,okrType,pagination) }}
        upcomingMilestoneLoading={upcomingMilestoneLoading}
        paginatedOkrLoading={paginatedOkrLoading}
        paginatedData={performanceData}
        {...props}
      />
    </>
  );
};

export default compose(withApollo)(IndividualDashboardContainer)
