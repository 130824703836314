import gql from 'graphql-tag';

export const PAGE_STATE_QUERY = gql`
  query pageState {
    pageState @client {
      pagination {
        first
        offset
      }
      orderBy
      filter {
        name_Icontains
        email_Icontains
        message_Icontains
        searchText
      }
    }
  }
`;
