import gql from 'graphql-tag';

export const DELETE_CONTRIBUTOR_MUTATION = gql`
mutation deleteContributor($id: ID!){
    deleteContributor(id:$id){
        contributor{
            id
        }
    }
}
`