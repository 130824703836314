import gql from 'graphql-tag';
import { STORY_TYPE } from './StoryType.gql';

export const EDIT_STORY = gql`
  ${STORY_TYPE}
  mutation editStory(
    $content: String
    $id: ID!
    $published: Boolean
    $storyTags: [Int]
    $title: String
    $banner: String
    $thumbnailImage: String
    $author: String
  ) {
    updateStory(
      content: $content
      id: $id
      published: $published
      banner: $banner
      storyTags: $storyTags
      title: $title
      thumbnailImage: $thumbnailImage
      author: $author
    ) {
      story {
        ...StoryType
      }
    }
  }
`;
