import gql from "graphql-tag";

export const AI_TIP_SUBSCRIPTION =gql`
subscription tipSubscription {
  tipSubscription {
    mutation
    tip{
      id
      tipModule
      tipContext
      favourite
      createdAt
      tip
      runpodId
      tipFor{
        id
      }
      user{
        id 
      }
    }
  }
}
`
