import React from 'react';

import { AdminLayout } from '../../look';
import { tool } from '../route';

import ToolFormComponent from './ToolFormComponent';

const AddToolView = props => {
  return <AdminLayout table={false} active={tool} title="Add tool" FormComponent={<ToolFormComponent {...props} />} />;
};

export default AddToolView;
