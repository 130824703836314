export const threshold_colors = {
    threshold_0: '225, 63, 63',
    threshold_1: '225, 63, 63',
    threshold_2: '255, 199, 0',
    threshold_3: '76, 203, 31',
    threshold_4: '65, 105, 225',
    threshold_5: '255, 0, 0',
    threshold_6: '0, 255, 0',
    threshold_7: '0, 0, 255',
    threshold_8: '255, 255, 0',
    threshold_9: '128, 0, 128',
    threshold_10: '0, 128, 128',
    threshold_11: '255, 165, 0',
    threshold_12: '0, 128, 0',
}