import gql from 'graphql-tag';

export const CONTACT_REQUEST_TYPE = gql`
  fragment ContactRequestType on ContactRequestType {
    id
    name
    email
    contactType
    teamSize
    message
    requestDate
    complete
    completeDate
  }
`;
