import gql from 'graphql-tag';
import { STORY_TYPE } from './StoryType.gql';


export const STORY_SUBSCRIPTION = gql`
  ${STORY_TYPE}
  subscription storySubscription {
    storySubscription {
      mutation
      story {
        ...StoryType
      }
    }
  }
`;
