// @ts-nocheck
import { Form, Select, Checkbox, Button,message} from 'antd';
import React from 'react';
import { employee_icons } from '../icon';

const CreateAssignEmployeeView = (props) => {

    const { onCancel, role_list, getRoleLevel, org_employee_list, getOrgEmployee,me,setmutationData } = props
    const [selected_employee,SetSelectedEmployee] = React.useState([])
    const [selected_role,SetSelectedRole] = React.useState()
    const { Option } = Select;

    const ChooseEmployee=(id)=>{
        let list = [].concat(selected_employee)
        let employee = org_employee_list?.find(item=>item?.id==id)

        if(employee){
            let already_exist = list?.find(item=>item?.id===employee?.id)
            if(already_exist){
                list = list?.filter(item=>item?.id!=employee?.id)
            }
            else{
                list.push(employee)
            }
            SetSelectedEmployee(list)
        }

    }

    const removeSelectedEmployee=(id)=>{
        let list = [].concat(selected_employee)
        list = list?.filter(item=>item?.id!=id)
        SetSelectedEmployee(list)
    }

    const handleValidation=()=>{
        if(!selected_employee?.length || !selected_role){
            message.error('Fill in the required fields')
        }else{
            let mutation_data = selected_employee?.map(item=>{
                return {
                    memberFrom:{
                        organizationId:me?.employee?.organizationSet?.edges[0]?.node?.id
                    },
                    is_member:item?.member_id!==undefined?true:false,
                    employeeId:item?.member_id!==undefined?item?.member_id:item?.id,
                    roleId:selected_role,
                    reportingLine:item?.member_id!==undefined?"dotted":"solid"
                }
            })
            setmutationData(mutation_data)
        }
    }

    return (
        <Form
            className='rd-align-center rd-add-edit-competency-container'
            style={{ gap: '.5em', marginTop: '1em' }}
        >

            <div className='rd-input-container' >
                <h4 className='rd-input-title'><span className='rd-input-star'>*</span> Select employees</h4>
                <Select
                    className="rd-select-filed rd-type-select chip-select"
                    style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", width: "100%" }}
                    suffixIcon={<img src={employee_icons?.dropdown_Arrow} alt="" style={{ width: "10px", height: "10px" }} />}
                    showSearch
                    onSearch={(e) => {getOrgEmployee({user_FirstName:e}) }}
                    filterOption={false}
                    placeholder='Search by name'
                    onSelect={(e)=>{ChooseEmployee(e)}}
                    {...props}
                >
                    {
                        org_employee_list.map(item => (
                            <Option
                                className='rd-select-option'
                                key={item?.id}
                                value={item?.id}
                            >
                                <Checkbox checked={selected_employee?.find(val=>val?.id==item?.id)} ></Checkbox>
                                <span style={{marginLeft:"0.5em"}}>{item?.user?.firstName} {item?.user?.lastName || ''} - {item?.user?.email}</span>
                            </Option>
                        ))
                    }

                </Select>
            </div>
                
            {/* list the selected employee */}
            <div className='rd-chiplist-container'>
                    {
                        selected_employee?.map(item=>(
                            <div className='rd-chip'>
                                <span className='rd-chip-label'>{item?.user?.firstName} {item?.user?.lastName || ''} - {item?.user?.email}</span>
                                <img src={employee_icons.chip_close_icon} style={{cursor:'pointer'}} onClick={()=>{removeSelectedEmployee(item?.id)}} alt="" />
                            </div>
                        ))
                    }
            </div>

            <div className='rd-input-container' style={{ marginTop: "2.5em" }} >
                <h4 className='rd-input-title'><span className='rd-input-star'>*</span> Assign role & level</h4>
                <Select
                    className="rd-select-filed rd-type-select"
                    style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", width: "100%" }}
                    suffixIcon={<img src={employee_icons?.dropdown_Arrow} alt="" style={{ width: "10px", height: "10px" }} />}
                    showSearch
                    onSearch={(e) => {getRoleLevel({title_Icontains:e}) }}
                    filterOption={false}
                    placeholder='select role and level'
                    onSelect={(e)=>SetSelectedRole(e)}
                    {...props}
                >
                    {
                        role_list?.map(item => (
                            <Option
                                className='rd-select-option'
                                key={item?.id}
                                value={item?.id}
                            >
                                {item?.title} - {item?.employeeLevel?.name}
                            </Option>
                        ))
                    }

                </Select>
            </div>
            <div className='rd-confirmation-action-container' style={{ margin: "3em 2em 2em 2em" }}>
                <Button className='global-submit-btn' onClick={()=>{handleValidation()}} >Save</Button>
                <Button className='global-cancel-btn' onClick={() => onCancel()}>Cancel</Button>
            </div>
        </Form>
    )

}

export default CreateAssignEmployeeView