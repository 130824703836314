import gql from 'graphql-tag';

export const ORGANIZATION_PERFORMANCE_DATA =gql`
query me($okr_Duedate_Range: [DateTime],$createdBy: ID,$employee: ID,$offset:Int,$first: Int){
    OrganizationPerformanceData:me{
        id
        employee {
          id
          organizationSet {
            totalCount
            edgeCount
            edges {
              node {
                id
                ceo {
                  id
                  okrContributorSet(savedOkrType: "OBJECTIVE", role:"OWNER", employee:$employee,createdBy:$createdBy,okr_Duedate_Range:$okr_Duedate_Range,orderBy: ["okr__duedate"],offset:$offset,first:$first) {
                    totalCount
                    edgeCount
                    edges {
                        node {
                          id
                          okrType
                          progress
                          requestStatus
                          okr {
                            id
                            title
                            okrType
                            isDiscarded {
                              id
                            }
                          }
                        }
                    }
                  }
                }
              }
            }
          }
        }
      }
}
`