import React from 'react';
import gql from 'graphql-tag';
import { Spin } from 'antd';
import { graphql } from '@apollo/client/react/hoc';

import ROUTE from '../../../organization/route';

import SelectField from './SelectField';
import { getIntFromString } from '../functions';

const ALL_ORGANIZATION_QUERY = gql`
  query allOrganization {
    allOrganization {
      edges {
        cursor
        node {
          id
          name
        }
      }
    }
  }
`;

const OrganizationField = props => {
  const { loading, allOrganization, onAdd, onEdit } = props;

  const choices =
    allOrganization &&
    allOrganization.edges.length > 0 &&
    allOrganization.edges.map(({ node }) => ({ label: node.name || '', value: Number(getIntFromString(node.id)) }));

  return (
    <Spin spinning={loading} size="small">
      <SelectField
        {...props}
        onAdd={onAdd && ROUTE.addOrganization}
        onEdit={onEdit && ROUTE.editOrganizationLink}
        choices={choices}
      />
    </Spin>
  );
};

export default graphql(ALL_ORGANIZATION_QUERY, {
  props({ data: { loading, error, allOrganization, subscribeToMore, updateQuery } }) {
    return { loading, error, allOrganization, subscribeToMore, updateQuery };
  }
})(OrganizationField);
