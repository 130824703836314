// @ts-nocheck
import React from 'react';
import { compose } from '../../core';
import { withApollo } from '@apollo/client/react/hoc';
import IDPCreateView from '../components/idpCreateView';
import { COMPETENCY_LIST_JOBFAMILY } from 'modules/role_definition/graphql/JobFamilyQuries.gql';
import { DIRECT_MANAGER } from '../graphql/idpCreate.gql';
import { withCreateIdp } from '../idpOperations';
import { globalPermissionValidator } from 'modules/look';
import { idp_permission } from 'Permissions/idp.permission';
import NoPermissionView from 'modules/kudos-user/component/NoPermissionComponent';
import { Spin } from 'antd'

const IDPCreateContainer = props => {
  const { navigateRoute, client, createIdp, userPermission } = props
  const [role_specific_competency_list, SetRoleSpecificCompList] = React.useState([])
  const [loading, Setloading] = React.useState(false)
  const [selected_manager, SetSelectedManager] = React.useState()
  const [permission, setPermission] = React.useState()
  const [ai_trigger,SetAITrigger] = React.useState()
  //Permission Check
  React.useEffect(() => {
    if (userPermission?.length) {
      let permission = globalPermissionValidator(idp_permission, userPermission)
      setPermission(permission)
    }
  }, [userPermission])

  React.useEffect(() => {
    if (permission?.required_permission) {
      getCompetencyList()
      getDirectManager()
    }
  }, [permission])

  const getDirectManager = async () => {
    const { data } = await client.query({
      query: DIRECT_MANAGER
      // fetchPolicy: 'network-only'
    });
    if (data?.me) {
      let member_data = data?.me?.employee?.memberSet?.edges[0]?.node
      if (member_data) {

        let manager
        if (member_data?.fromTeam) {
          manager = member_data?.fromTeam?.managers?.edges[0]?.node
        }
        else if (member_data?.fromVertical) {
          manager = member_data?.fromVertical?.heads?.edges[0]?.node
        }
        else if (member_data?.fromOrganization) {
          manager = member_data?.fromOrganization?.ceo
        }

        if (manager) {
          let manager_data = {
            email_user: null,
            employee_id: manager?.id,
            user_id: manager?.user?.id,
            label: manager?.user?.firstName
          }
          SetSelectedManager(manager_data)
        }
      }
    }
  }

  const getCompetencyList = async (filter) => {
    const { data } = await client.query({
      query: COMPETENCY_LIST_JOBFAMILY,
      variables: { ...filter },
      fetchPolicy: 'network-only'
    });
    if (data?.allCompetencies) {
      SetRoleSpecificCompList(data?.allCompetencies?.edges?.map(({ node }) => node))
    } else {
      SetRoleSpecificCompList([])
    }
  }

  const OnSubmitIDP = async (data) => {
    try {
      Setloading(true)
      const response = await createIdp(data)
      if (response?.id) {
        navigateRoute("-1")
      }
      Setloading(false)
    } catch (error) {
      Setloading(false)
    }
  }

  return (
    <>
      {
        (permission && permission?.required_permission) && (
          <IDPCreateView
            role_specific_competency_list={role_specific_competency_list}
            SetRoleSpecificCompList={(e) => { SetRoleSpecificCompList(e) }}
            getCompetencyList={(filter) => { getCompetencyList(filter) }}
            SetSelectedManager={SetSelectedManager}
            selected_manager={selected_manager}
            OnSubmitIDP={OnSubmitIDP}
            loading={loading}
            ai_trigger={ai_trigger}
            SetAITrigger={SetAITrigger}
            permission={permission}
            {...props} />
        )
      }

      {(permission && !permission?.required_permission) && (<NoPermissionView />)}
      {
        !permission && (
          <div className="role-no-permission-container">
            <Spin spinning={true} size="large"></Spin>
          </div>
        )
      }
    </>
  );
};

export default compose(withApollo, withCreateIdp)(IDPCreateContainer);
