import BarsCreateContainer from './Containers/BarsCreateContainer';
import BarsDefineContainer from './Containers/BarsDefineContainer';
import BarsHomeContainer from './Containers/BarsHomeContainer';
import BarsCreateSubCompContainer from './Containers/BarsCreateSubCompContainer'

import ROUTE from './route';
import BarsEditContainer from './Containers/BarsEditContainer';
import BarsDetailContainer from './Containers/BarsDetailContainer';
import BarsEditSubCompetencyContainer from './Containers/BarsEditSubCompetencyContainer';
import SubCompetencyDetailContainer from './Containers/SubCompetencyDetailContainer';
export default [
  
    {
        name: 'BarsHome',
        path: ROUTE.bars_home,
        component: BarsHomeContainer,
        exact: true,
        protect: true
    },
    {
        name: 'Create Bars',
        path: ROUTE.bars_create,
        component: BarsCreateContainer,
        exact: true,
        protect: true
    },
    {
        name: 'Define Bars',
        path: ROUTE.bars_define,
        component: BarsDefineContainer,
        exact: true,
        protect: true
    },
    {
        name: 'Create Bars Sub Competency',
        path: ROUTE.bars_create_sub_comp,
        component: BarsCreateSubCompContainer,
        exact: true,
        protect: true
    },
    {
        name:'Bars Edit Sub Competency',
        path:ROUTE.edit_bars_sub_competency,
        component: BarsEditSubCompetencyContainer,
        exact: true,
        protect: true
    },
    {
        name: 'Bars Edit',
        path: ROUTE.edit_bars,
        component: BarsEditContainer,
        exact: true,
        protect: true
    },
    {
        name:'Bars Detail SubCompetency',
        path:ROUTE.bars_sub_competency_detail,
        component: SubCompetencyDetailContainer,
        exact: true,
        protect: true
    },
    {
        name:'Bars_detail',
        path:ROUTE.bars_detail,
        component: BarsDetailContainer,
        exact: true,
        protect: true
    }
   
    
    

]