import React from 'react';

import { AdminLayout } from '../../look';
import ROUTE from '../route';

import QuestionFormComponent from './QuestionFormComponent';

const EditQuestionView = props => {
  return (
    <AdminLayout
      table={false}
      active={ROUTE.question}
      title="Edit Question"
      FormComponent={props.questionById && <QuestionFormComponent {...props} />}
    />
  );
};

export default EditQuestionView;
