import gql from 'graphql-tag';
import { USER_TYPE } from '../../user/graphql/UserType.gql';
import { COMMITMENT_TYPE } from '../../tool/graphql/CommitmentType.gql';

export const DEFAULT_COMMITMENTS_PROGRESS_TYPE = gql`
  ${COMMITMENT_TYPE}, ${USER_TYPE}
  fragment DefaultCommitmentProgressType on DefaultCommitmentProgressType {
    id
    user {
      ...UserType
    }
    completed
    dateCreated
    dateCompleted
    commitment {
      ...CommitmentType
    }
  }
`;
