import React from "react";
import {Modal,Button} from 'antd'
import { scapeHomeIcons } from "../scapeAdminIcons";

const DeleteScapeView =(props)=>{
    const {select_deleted,onDelete,onCancel} =props
    return (
        <Modal
        visible={true}
        footer={null}
        centered
        destroyOnClose={true}
        closable={false}>
            <div style={{display:'flex',justifyContent:"center",flexDirection:"column",alignItems:'center',width:"100%",gap:"1em"}}>
                <img src={scapeHomeIcons?.delete_warning} alt="" />
                <h4 className="sa-delete-warning-para" style={{marginTop:'1em'}}>Are you sure you want to Delete this {select_deleted?.type} ?</h4>
                <div style={{display:'flex',justifyContent:"center",flexDirection:"row",alignItems:'center',width:"100%",gap:"1em",flexWrap:"wrap",marginTop:"1em"}}>
                    <Button className="sa-delete-cancel-btn" onClick={()=>onCancel()}>GoBack</Button>
                    <Button className="sa-delete-delete-btn" onClick={()=>onDelete()}>Delete</Button>
                </div>
            </div>
        </Modal>
    )
}

export default DeleteScapeView