import React from 'react';

import { compose } from '../../core';
import CommitmentView from '../../dashboard/components/CommitmentView';

import { withGetMyCoachingCommitmentsProgress, withEditCoachingCommitmentProgress } from './ManagerOperations';
import { withGetAllDefaultCommitmentsProgress } from '../../dashboard/containers/DashboardOperations';
import {subscribeToCustomUserCommitments,subscribeToDefaultCommitmentProgress,subscribeToCustomUserCommitmentsForReporting,subscribeToSefaultCommitment} from '../../dashboard/containers/DashboardSubscriptions'
import {subscribeToCoachingCommitmentProgress} from './ManagerSubscription'
const MyCoachingCommitment = props => {
  const {
    setSubmitLoading,
    editDefaultToolCommitment,
    editCustomUserCommitment,
    getMyCoachingCommitmentsProgress,
    getAllDefaultCommitmentsProgress,
    updateCoachingCommitmentProgress,
    subscribeToMore,
    // getAllDefaultCommitmentsProgressForReportingEmployee
  } = props;

  React.useEffect(() => {
    const subscribe = subscribeToSefaultCommitment(subscribeToMore /* , props.filter */) ||
    subscribeToCoachingCommitmentProgress(subscribeToMore /* , props.filter */)
    return () => subscribe();
  });


  async function handleDefaultCommitmentProgress(values) {
    setSubmitLoading(true);
    try {
      const response = await updateCoachingCommitmentProgress(values);
      response && setSubmitLoading(false);
      return response && true;
    } catch (err) {
      setSubmitLoading(false);
      return false;
      // throw new Error(err.message);
    }
  }

  // async function handleCustomUserCommitment(values) {
  //   setSubmitLoading(true);
  //   try {
  //     const response = await editCustomUserCommitment(values);
  //     response && setSubmitLoading(false);
  //   } catch (err) {
  //     setSubmitLoading(false);
  //     throw new Error(err.message);
  //   }
  // }

  return (
    <CommitmentView
      title={'Coaching Commitments'}
      // customUserCommitments={getMyCoachingCommitmentsProgress}
      defaultCustomUserCommitments={getMyCoachingCommitmentsProgress}
      allDefaultCommitmentsProgress={getAllDefaultCommitmentsProgress}
      onDefaultCommitmentProgress={handleDefaultCommitmentProgress}
      // onCustomUserCommitment={handleCustomUserCommitment}
      commitmentKey={'coachingcommitment'}
      {...props}
    />
  );
};

export default compose(
  // withEditDefaultToolCommitment,
  withGetMyCoachingCommitmentsProgress,
  // withEditCustomUserCommitment
  withEditCoachingCommitmentProgress,
  withGetAllDefaultCommitmentsProgress
)(MyCoachingCommitment);
