import React from 'react';
import { compose } from '../../core';
import NotificationView from '../components/notificationDropdownView';
import { withApollo } from '@apollo/client/react/hoc';
import { EMPLOYEE_COUNT } from '../graphql/EmployeeCount.gql';
import { withReadNotification, withUndoNotification } from './NotificationOperation';
import { FEEDBACK_BY_ID } from 'modules/feedback/graphql/FeedbackById.gql';
const NotificationContainer = props => {
  const { client, me } = props
  const [permission, setpermission] = React.useState(false)
  const [feedbackDetails, setFeedbackDetails] = React.useState();
  const [feedbackDetailsLoading, setFeedbackDetailsLoading] = React.useState(true)


  const getEmployeeData = async () => {
    const { data } = await client.query({
      query: EMPLOYEE_COUNT,
    });

    if (data?.me) {
      let Detail = data?.me?.employee
      if (Detail?.teamManagerEmployee?.totalCount || Detail?.orgCeoEmployee?.totalCount || Detail?.orgPocEmployee?.totalCount || Detail?.verticalHeadEmployee?.totalCount) {
        setpermission(true)
      }
      else {
        setpermission(false)
      }
    }
  }


  const getFeedbackDetails = async (id) => {
    setFeedbackDetailsLoading(true)

    try {
      const { data } = await client.query({
        query: FEEDBACK_BY_ID,
        variables: { id: id },
        fetchPolicy: 'network-only'
      });

      if (data?.feedbackById) {
        setFeedbackDetails(data?.feedbackById)
      }
    } catch (e) {
      console.error(e)
    }
    setFeedbackDetailsLoading(false)
  }

  React.useEffect(() => {
    if (me) {
      getEmployeeData()
    }
  }, [me])

  return (
    <>
      <NotificationView {...props} getFeedbackDetails={getFeedbackDetails} permission={permission}
        feedbackDetailsLoading={feedbackDetailsLoading}
        feedbackDetails={feedbackDetails}
      />
    </>
  );
}

export default compose(withApollo, withReadNotification, withUndoNotification)(NotificationContainer);