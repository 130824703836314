import React from 'react';
import { withHTMLFieldValue } from './functions';
import { Helmet } from 'react-helmet';

const ReactMarkdown = props => {
  const { children = '<p></p>', style } = props;
  const [scripts, setScripts] = React.useState(false);
  const [load, setLoad] = React.useState(false);

  const handleFilterRemove = React.useRef(() => { });
  handleFilterRemove.current = () => {
    const scr = [];
    var doc = document.implementation.createHTMLDocument(); // Sandbox
    doc.body.innerHTML = children; // Parse HTML properly
    [].map.call(doc.getElementsByTagName('script'), function (el) {
      if (el?.attributes?.src?.value) {
        const script = document.createElement('script');
        script.src = el?.attributes?.src?.value; // whatever url you want here
        script.charset = 'utf-8';
        script.async = true;
        script.onload = function () {
          setLoad(true);
        };
        document.head.appendChild(script);
      } else {
        scr.push(el.textContent);
      }
    });
    return scr;
  };

  React.useEffect(() => {
    setScripts(handleFilterRemove.current());
  }, []);

  React.useEffect(() => {
    load && scripts.map(st => eval(st));
  }, [load]);

  return <div style={style} dangerouslySetInnerHTML={{ __html: withHTMLFieldValue(children) }} />;
};

export default ReactMarkdown;
