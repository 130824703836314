import gql from "graphql-tag";
export const SURVEY_BY_ID = gql`
query SurveyById($id: ID!) {
    SurveyById(id:$id ){
        id

        groupSet {

            edges {

                node {

                id

                    questionSet {

                        edges {

                            node {

                                id

                                questionText

                                required

                                choiceSet {

                                    edges {

                                        node {

                                        id

                                        label

                                        inputType

                                        }

                                    }

                                }

                            }

                        }

                    }

                }

            }

        }

    }

}
`