import BadgeFamilyHomeContainer from './container/badge_family_list_container'
import ROUTE from './route';

export default [
  
    {
        name: 'BadgeFamilyHome',
        path: ROUTE.badge_family_home,
        component: BadgeFamilyHomeContainer,
        exact: true,
        protect: true
    }
    
]