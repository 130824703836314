import React from 'react';
import { Row, Spin,Button} from "antd";
import { displayUtcToUserTimezone, getIntFromString } from '../../look/components/functions'
import {CustomProgress } from '../../look-v2';
import Green_Tick from '../../assets/green-tick.svg'
import ShowContributors from '../formComponents/showContributers';
import chat_bubble from '../../assets/chat_bubble_blue.svg'
import AcceptRequest from '../formComponents/AcceptRequest';
import RequestDiscussContainer from '../formComponents/Container/RequestDiscussContainer';
import one_on_one_route from '../../one-on-one/route/index'
import new_back_arrow from '../../assets/new_back_arrow.svg';
import '../OkrStyles/OkrRequestsPanelStyles.css'
import { MetaTags } from 'modules/look';
const RequestDetailView = ( props ) => {
    const { requestDetail,match, actionItem, navigateRoute,app_sidebar_expand} = props
    const [ openModal, setOpenModal ] = React.useState( false )
    const [ openDiscussModal, setopenDiscussModal ] = React.useState( false )
    const [ stateloading, setLoading ] = React.useState( true )
    const [ sortedActionItem, setSortedActionItem ] = React.useState()
    const [ requestData, setRequestData ] = React.useState( null )
    console.log("requestDetail",requestDetail)
    React.useEffect( () => {
        if ( requestDetail ) {
            setRequestData( requestDetail )
        }
    }, [ requestDetail ] )
    const goToDetail = ( type, id ) => {
        console.log( "type", type, requestDetail )
        if ( type === 'OBJECTIVE' ) {
            window.open( `/role/objective-keyresult/view/${getIntFromString( id )} `, '_blank' );
        }
        else if ( type === 'KEY_RESULT' ) {
            window.open( `/role/objective-keyresult/keyresult/view/${getIntFromString( id )}`, '_blank' );
        }
        else if ( type === 'MILESTONE' ) {
            window.open( `/role/objective-keyresult/milestone/view/${getIntFromString( id )}`, '_blank' );
        }
    }

    React.useEffect( () => {
        if ( actionItem?.edges?.length ) {
            console.log("actionItem",actionItem)
            let sortedList = actionItem?.edges?.sort( function ( a, b ) {
                return new Date( b?.node?.createdAt ) - new Date( a?.node?.createdAt );
            } )
            setSortedActionItem( sortedList[ 0 ] )
        }
    }, [ actionItem ] )

   
    const CloseResponse = ( value ) => {
        if ( value?.response ) {
            setRequestData( null )
            window.location.reload()
        }
    }
    return (
        <>
        <MetaTags title="OKR" description="OKR Home" />
            <Spin spinning={!requestData ? true : false} size="large">
                <div className="requst-detail-margin">
                    <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'start', alignItems: "center", gap: "1em" }}>
                        <img src={new_back_arrow} style={{ width: "20px", height: "20px", cursor: "pointer", marginLeft: '7px' }} onClick={() => { navigateRoute( "-1" ) }} alt="" />
                        {match?.params?.type == "assignment" && <h4 className="coaching-engagement-page-title"style={{cursor:"pointer"}}onClick={() => { navigateRoute( "-1" ) }}>Assignment Request</h4>}
                        {match?.params?.type == "collabouration" && <h4 className="coaching-engagement-page-title"style={{cursor:"pointer"}}onClick={() => { navigateRoute( "-1" ) }}>Collaboration Request</h4>}
                        <div></div>
                    </div>
                    <div style={{ background: "#fff", width: '100%', borderRadius: "2.5em", overflow: "auto", height: "76.5vh", marginTop: "1em", display: "flex", flexDirection: "column", position: "relative", padding: requestData?.requestStatus !== "ACCEPTED" ? '2em 2em 120px 2em' : '2em 2em' }}>
                        <h5 className='objective-title' style={{ textAlign: "center",margin:"0px" }}>{requestData?.okr?.title}</h5>
                        <Row align='center' style={{ justifyContent: 'center', gap: "30px", width: "100%"}}>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: 'center' }}>
                                <p className='discard-small-head'>Status</p>
                                {requestData?.requestStatus === "ACCEPTED" && <p className="discard-small-data" style={{ color: "#4CCB1F", display: "flex", flexDirection: "row", flexWrap: "nowrap", justifyContent: 'center', alignItems: 'center', gap: "10px" }}>
                                    <div className='dot-status' style={{ background: "#4CCB1F" }}></div>Approved</p>}
                                {requestData?.requestStatus === "NEEDS_DISCUSSION" && <p className="discard-small-data" style={{ color: "#009AF1", display: "flex", flexDirection: "row", flexWrap: "nowrap", justifyContent: 'center', alignItems: 'center', gap: "10px" }}>
                                    <div className='dot-status' style={{ background: "#009AF1" }}></div> Needs Discussion</p>}
                                {requestData?.requestStatus === "PENDING" && <p className="discard-small-data" style={{ color: "#CB861F", display: "flex", flexDirection: "row", flexWrap: "nowrap", justifyContent: 'center', alignItems: 'center', gap: "10px" }}>
                                    <div className='dot-status' style={{ background: "#CB861F" }}></div>Pending</p>}
                            </div>
                            {requestData?.requestStatus !== "PENDING" && <div style={{ display: "flex", flexDirection: "column", justifyContent: 'center' }}>
                                <p className='discard-small-head'>Resolved on</p>
                                <h4 className='discard-small-data'>{displayUtcToUserTimezone( requestData?.actedOn, 'DD MMM YYYY' )}</h4>
                            </div>}
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: 'center' }}>
                                <p className='discard-small-head'>Created on</p>
                                <h4 className='discard-small-data'>{displayUtcToUserTimezone( requestData?.createdAt, 'DD MMM YYYY' )}</h4>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: 'center' }}>
                                <p className='discard-small-head'>Created by</p>
                                <h4 className='discard-small-data'>{requestData?.createdBy?.user?.firstName} {requestData?.createdBy?.user?.lastName}</h4>
                            </div>
                        </Row>
                        {requestData?.requestStatus === "NEEDS_DISCUSSION" && <Row align='middle' style={{ display: "flex", flexDirection: "column", justifyContent: 'center', marginTop: "2em", width: "100%" }}>
                            <div style={{ borderRadius: "17px", boxShadow: "-1px 3px 173px -18px rgba(0, 0, 0, 0.14)", padding: "20px", display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: "center", minWidth: "30%" }}>
                                <h3 className='objective-title' style={{ fontWeight: "600" }}>Action Selected</h3>
                                {actionItem?.edges?.length > 0 && <h5 className='objective-title objective-title-wrap' style={{width:"400px"}}>To be discussed on <span style={{ color: '#009AF1', cursor: "pointer" }} onClick={() => navigateRoute( one_on_one_route?.viewMeeting?.replace( ":meetingid", getIntFromString( sortedActionItem?.node?.meeting?.id ) ) )}>{sortedActionItem?.node?.title}</span></h5>}
                                {!actionItem?.edges?.length && <h5 className='objective-title objective-title-wrap center' style={{ color: '#009AF1', cursor: "pointer" }}>F2F Discussion</h5>}
                            </div>
                        </Row>}
                        <Row align='middle' style={{ display: "flex", flexDirection: "column", justifyContent: 'center', marginTop: "2em", width: "100%" }}>
                            <div className='detail-div' style={{ minWidth: "75%" }}>
                                <Row align='center' style={{ justifyContent: "center", alignItems: "center", gap: "30px" }}>
                                    <h5 className='objective-title objective-title-wrap'>{requestData?.okr?.title}</h5>
                                    <h3 className='discard-subhead' style={{ color: '#009AF1', fontWeight: "400", textDecoration: "underline", cursor: "pointer", paddingTop: '20px' }} onClick={() => goToDetail( requestData?.okrType, requestData?.okr?.id )}>See Details</h3>
                                </Row>
                                <Row align='middle' justify='center' style={{ alignItems: 'center', gap: "30px", width: "100%", justifyContent: 'space-between', padding: "20px" }}>
                                    <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: 'center', gap: "10px" }}>
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: 'center', width: "100%" }}>
                                            <p className='discard-small-head'>Owner(s)</p>
                                            <h4 className='discard-small-data objective-title-wrap' style={{ textOverflow: "ellipsis", overflow: "hidden", width: "100%", whiteSpace: "nowrap" }}>{requestData?.okr?.owner?.user?.firstName} {requestData?.okr?.owner?.user?.lastName}</h4>
                                        </div>
                                        <div style={{ paddingTop: "50px" }}>
                                            <ShowContributors dataList={requestData?.okr?.contributors?.edges} />
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: 'center' }}>
                                        <p className='discard-small-head'>Progress</p>
                                        <div style={{ paddingTop: "20px", justifyContent: 'start', minWidth: "200px" }}>
                                            <CustomProgress progress={requestData?.okr?.progress} info={true} />
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: 'center' }}>
                                        <p className='discard-small-head'>Due</p>
                                        <h4 className='discard-small-data'>{displayUtcToUserTimezone( requestData?.okr?.duedate, 'DD MMM YYYY' )}</h4>
                                    </div>
                                </Row>
                            </div>
                        </Row>
                        {openModal && <AcceptRequest type={match?.params?.type} visible={openModal} data={requestData} onClose={( e ) => { CloseResponse( e ); setOpenModal( false ) }} modalLoading={( e ) => setLoading( e )} />}
                        {openDiscussModal && <RequestDiscussContainer {...props} visible={openDiscussModal} data={requestData} onClose={( e ) => { CloseResponse( e ); setopenDiscussModal( false ) }} modalLoading={( e ) => setLoading( e )} />}
                        {requestData?.requestStatus !== "ACCEPTED" && <div className={`discard-botton-div ${app_sidebar_expand ? 'discard-div-margin-left' : ''}`}>
                            <Row align='center' justify='center' style={{ gap: "20px", display: "flex", flexDirection: "row", justifyContent: "center center", alignItems: "center" }}>
                                <Button className='approve-btn' onClick={() => setOpenModal( true )}><img style={{ width: "20px" }} src={Green_Tick} alt="" /><span style={{ paddingLeft: "5px" }}>Accept</span></Button>
                                {requestData?.requestStatus === "PENDING" && <Button className='disapprove-btn' style={{ color: "#009AF1", border: "1px solid #009AF1" }} onClick={() => { setopenDiscussModal( true ) }}><img style={{ height: "20px" }} src={chat_bubble} alt="" /><span style={{ paddingLeft: "5px" }}>Discuss</span></Button>}
                            </Row>
                        </div>}

                    </div>
                </div>
            </Spin>

        </>
    )
}
export default RequestDetailView