import gql from 'graphql-tag';
import { QUESTION_TYPE } from './QuestionType.gql';

export const EDIT_QUESTION = gql`
  ${QUESTION_TYPE}
  mutation editQuestion(
    $groupId: Int
    $helpText: String
    $id: ID!
    $questionText: String
    $required: Boolean
    $sequence: Int
  ) {
    updateQuestion(
      groupId: $groupId
      helpText: $helpText
      id: $id
      questionText: $questionText
      required: $required
      sequence: $sequence
    ) {
      question {
        ...QuestionType
      }
    }
  }
`;
