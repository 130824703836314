import gql from 'graphql-tag';
import { QUESTION_TYPE } from '../../survey/graphql/QuestionType.gql';

export const REFERENCE_FIELD_TYPE = gql`
  ${QUESTION_TYPE}
  fragment ReferenceFieldType on ReferenceFieldType {
    id
    question {
      ...QuestionType
    }
    sequence
    editable
    helpText
  }
`;
