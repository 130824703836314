import gql from 'graphql-tag';
import { RESPONSE_TYPE, RESPONSE_TYPE_GROUP_SET } from './ResponseType.gql';

export const EDIT_RESPONSE_MUTATION = gql`
  ${RESPONSE_TYPE}
  mutation editResponse($answers: [AnswerInput]!, $responseId: ID!) {
    updateResponse(answers: $answers, responseId: $responseId) {
      response {
        ...ResponseType
      }
    }
  }
`;


export const EDIT_RESPONSE_MUTATION_GROUPSET = gql`
  ${RESPONSE_TYPE_GROUP_SET}
  mutation editResponse($answers: [AnswerInput]!, $responseId: ID!) {
    updateResponse(answers: $answers, responseId: $responseId) {
      response {
        ...ResponseType
      }
    }
  }
`;

export const EDIT_RESPONSE_MUTATION_OPTIMIZED = gql`
  mutation editResponse($answers: [AnswerInput]!, $responseId: ID!) {
    updateResponse(answers: $answers, responseId: $responseId) {
      response {
        id
        responseDuration
        responseDate
        norm
        answerSet {
          edges{
            node{
              answer
              id
            }
          }
        }
      }
    }
  }
`;