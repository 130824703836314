import gql from 'graphql-tag';

export const RESPONSE_BY_RESPONSE_ID =gql`
query responseByResponseId($id:ID!) {
    responseByResponseId(id:$id) {
        id
        answerSet{
          edges{
            node{
              id
              answer
              question{
                id
                questionText
                choiceSet{
                  edges{
                    node{
                      id
                      inputType
                      label
                      value
                      question {
                        id
                      }
                    }
                  }
                }
              }
            }
          }
        }      
    }
}
`
