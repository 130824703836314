import gql from 'graphql-tag';
import { TEAM_TYPE } from './TeamType.gql';

export const ADD_TEAM = gql`
  ${TEAM_TYPE}
  mutation createTeam($employees: [Int], $managers: [Int], $teamData: TeamInput!) {
    createTeam(employees: $employees, managers: $managers, teamData: $teamData) {
      team {
        ...TeamType
      }
    }
  }
`;
