// @ts-nocheck
import React from 'react';
import { compose } from '../../core';
import { withApollo } from '@apollo/client/react/hoc';
import VerticalDashboardView from '../components/verticalDashboardview';
import { VERTICAL_ALL_CASCADING_OBJECTIVES, VERTICAL_ALL_NON_CASCADING_OBJECTIVES, VERTICAL_OKR_PERFORMANCE } from '../graphql/VerticalDashboardQueries';
import { getIntFromString } from 'modules/look';
const VerticalDashboardContainer = props => {
  const {me,client,dateRangeTofilter,verticalDetail}=props
  const [nonCascadedOkr, setNonCascadedOkr] = React.useState();
  const [cascadedOkr, setCascadedOkr] = React.useState();
  const [cascadeLoader, setCascadeLoader] = React.useState(true);
  const [noncascadeLoader, setNoncascadeLoader] = React.useState(true);
  let manager = React.useRef()
  React.useEffect(()=>{
   
    if(verticalDetail && me && dateRangeTofilter){
      
      getAllCascadedObjectives(me?.employee?.id,0)
      getAllNonCascadedObjectives(0)
    }else{
  
      setCascadedOkr(null)
      setNonCascadedOkr(null)
    }
  },[verticalDetail,me,dateRangeTofilter])

  const getAllCascadedObjectives = async (employeeid,offset,pagination=false) => {
    setCascadeLoader(true)
    const { data } = await client.query({
      query: VERTICAL_ALL_CASCADING_OBJECTIVES,
      variables: {
        okr_Duedate_Range: dateRangeTofilter,
        role_In: ["COLLABORATOR", "ASSIGNED"],
        managerId: verticalDetail?.heads?.edges?.map(({node})=>getIntFromString(node?.id)) || [],
        // createdBy_Ne: employeeid,
        offset: offset * 100,
        first: 100
      },
      // fetchPolicy: 'network-only'
    });

    if (data?.AllCascadingObjectives) {

      if (pagination) {
        let obj = {
          edgeCount: cascadedOkr?.edgeCount + data?.AllCascadingObjectives?.edgeCount,
          edges: [...cascadedOkr?.edges, ...data?.AllCascadingObjectives?.edges],
          totalCount: data?.AllCascadingObjectives?.totalCount
        }
        setCascadedOkr(obj)
      } else {
        
        setCascadedOkr(data?.AllCascadingObjectives)
      }
      setCascadeLoader(false)
    }
  }

  
  const getAllNonCascadedObjectives = async (offset,pagination=false) => {
    setNoncascadeLoader(true)
    const { data } = await client.query({
      query: VERTICAL_ALL_NON_CASCADING_OBJECTIVES,
      variables: {
        id:verticalDetail?.id,
        okr_Duedate_Range: dateRangeTofilter,
        role_In: ["OWNER"],
        managerId:verticalDetail?.heads?.edges?.map(({node})=>getIntFromString(node?.id)) || [],
        offset: offset * 100,
        first: 100
      },
      // fetchPolicy: 'network-only'
    });

    if (data?.AllNonCascadingObjectives) {
        let emp_edges= data.AllNonCascadingObjectives.employees.edges.map(({node})=>{
          return node?.okrContributorSet?.edges?.map((item=>item))
        })
        let head_edges= data.AllNonCascadingObjectives.heads.edges.map(({node})=>{
          return node?.okrContributorSet?.edges?.map((item=>item))
        })
        let edges=[...emp_edges,...head_edges]
        edges = [].concat.apply([],edges) 
        var setObj = new Set(); 
      edges = edges.reduce((acc,item)=>{
        if(!setObj.has(item?.node?.okr?.id)){
          setObj.add(item?.node?.okr?.id)
          acc.push(item)
        }
        return acc;
      },[])
      let fetchedData ={edges:edges,edgeCount:edges?.length,totalCount:edges?.length}
      if (pagination) {
        let obj = {
          edgeCount: nonCascadedOkr?.edgeCount + fetchedData?.edgeCount,
          edges: [...nonCascadedOkr?.edges, ...fetchedData?.edges],
          totalCount: fetchedData?.totalCount
        }
        setNonCascadedOkr(obj)
      } else {
        setNonCascadedOkr(fetchedData)
      }
      setNoncascadeLoader(false)
    }

  }


  
  return (
    <>
    <VerticalDashboardView 
    getAllCascadedObjectives={(empid,offset)=>{getAllCascadedObjectives(empid,offset,true)}} 
    cascadedOkr={cascadedOkr} 
    cascadeLoader={cascadeLoader} 
    setCascadeLoader={(e)=>{setCascadeLoader(e)}}  
    nonCascadedOkr={nonCascadedOkr}
    getAllNonCascadedObjectives={(e)=>{getAllNonCascadedObjectives(e)}}
    setNoncascadeLoader={(e,offset)=>{setNoncascadeLoader(offset,true)}}
    noncascadeLoader={noncascadeLoader}
    {...props}/>
    </>
  );
};

export default compose(withApollo)(VerticalDashboardContainer);
