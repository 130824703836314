import React from 'react';
import UserImpactNarrative from '../components/userImpactNarrative';
import { EMPLOYEE_IMAPACT_NARRATIVE } from '../graphql/EmployeeProfile.gql';

const UserImpactNarrativeContainer = (props) => {
    const { profile_detail,client } = props
    const [impact_narrative, SetImpactNarrative] = React.useState()

    React.useEffect(()=>{
        if(profile_detail){
            getEmployeeImpactnarrative({user:profile_detail?.user?.id})
        }
    },[profile_detail])

    const getEmployeeImpactnarrative= async (filter)=>{
        const { data } = await client.query({
            query: EMPLOYEE_IMAPACT_NARRATIVE,
            // fetchPolicy: 'network-only',
            variables: filter,
        });
        if(data){
            SetImpactNarrative(data?.allImpactNarrative?.edges[0]?.node || {})
        }
    }

    return (
        <>
            <UserImpactNarrative {...props} impact_narrative={impact_narrative}/>
        </>
    )
}

export default UserImpactNarrativeContainer