import gql from 'graphql-tag';
import { EMPLOYEE_TYPE } from './EmployeeType.gql';

export const EMPLOYEE_TYPE_CONNECTION = gql`
  ${EMPLOYEE_TYPE}
  fragment EmployeeTypeConnection on EmployeeTypeConnection {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...EmployeeType
      }
    }
    totalCount
    edgeCount
  }
`;
