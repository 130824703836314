import total_vertical_icon from '../assets/total-vertical-icon.svg'
import total_team_icon from '../assets/total-team-icon.svg'
import total_managers_icon from '../assets/total-manager-icon.svg'
import total_reportee_icon from '../assets/total-reportee-icon.svg'
import add_user from '../assets/poc-dashboard-user-icon.svg'
import verified_user from '../assets/verified-user-icon.svg'
import non_verified_user from '../assets/non-verified-user-icon.svg'
import three_dots from '../assets/organization-new-menu-icon.svg'
import vertical_icon from '../assets/veritcal_icon_tree.svg'
import team_icon from '../assets/team_icon_tree.svg'
import sub_vertical_icon from '../assets/sub_vertical_icon.svg'
import close_icon from '../assets/dashboard-kudos-close-icon.svg'
import tree_vertical_btn from '../assets/tree_vertical_btn.svg'
import tree_team_btn from '../assets/tree_team_btn.svg'
import tree_sub_vertical_btn from '../assets/tree_sub_vertical_btn.svg'
import poc_user_type_inactive from '../assets/poc-user-type-inactive.svg'
import poc_user_type_active from '../assets/poc-user-type-active.svg'
import check_box from '../assets/dashboard-check-box-icon.svg'
import profile_icon from '../assets/avatar.jpg'
import export_icon from '../assets/export-icon.svg'
import report_icon from '../assets/report-icon.svg'
import node_delete_icon from '../assets/tree_delete_icon.svg'
import search_icon from '../assets/search-gray-icon.svg'
import chip_close_icon from '../assets/close.svg'
import check_mark from '../assets/check-mark.svg'
import back_arrow from '../assets/new_back_arrow.svg'
import icon_360 from '../assets/360_icon.svg'
import close_icons from '../assets/close-small.svg'
import vertical_dot from '../assets/vertical_dots.svg'
import poc_tree_recenter from '../assets/poc_tree_recenter.svg'
import green_tick from '../assets/green-tick.svg'
import pending_loader from '../assets/pending-orange-loader.svg'

export const dashboard_home_icons ={
    total_vertical_icon,
    total_team_icon,
    total_managers_icon,
    total_reportee_icon,
    add_user,
    check_box,
    export_icon,
    report_icon,
    profile_icon,
    search_icon,
    icon_360,
    close_icons
}

export const organization_icons={
    verified_user,
    non_verified_user,
    three_dots,
    vertical_icon,
    team_icon,
    sub_vertical_icon,
    close_icon,
    tree_vertical_btn,
    tree_team_btn,
    tree_sub_vertical_btn,
    poc_user_type_inactive,
    poc_user_type_active,
    profile_icon,
    node_delete_icon,
    chip_close_icon,
    check_mark,
    back_arrow,
    vertical_dot,
    poc_tree_recenter,
    search_icon
}

export const usage_module = {
    pending_loader,
    green_tick
}