// @ts-nocheck
import React from 'react';
import {Row, Col,Spin, Select, Table,Tooltip } from 'antd'
import green_arrow from '../../assets/green_arrow.svg';
import {displayUtcToUserTimezone,getIntFromString } from 'modules/look';
import InfoCircle from '../../assets/InfoCircle.svg'
import styled from 'styled-components';
import UpcomingMilestones from './UpcomingMilestones';
import { CustomProgress } from 'modules/look-v2';
import moment from 'moment';
import DoughntChartNonCascaded from 'modules/look-v2/Components/DoughnutChartNonCascaded';
import DoughntChartCascaded from 'modules/look-v2/Components/DoughnutchartCascaded';
import { Link} from 'react-router-dom';
const IndividualDashBoardView = props => {

  const { upcomingMilestones, onPagination, me, cascadedOkr, nonCascadedOkr, getAllNonCascadedObjectives, getAllCascadedObjectives,
   paginatedData, upcomingMilestoneLoading, cascadedOkrLoading,
   nonCascadedOkrLoading,
    paginatedOkrLoading } = props

  const [objectivePageCount, setObjectivePageCount] = React.useState(0);
  const [krPageCount, setkrPageCount] = React.useState(0);
  const [msPageCount, setMsPageCount] = React.useState(0);
  const [paginationData, setPaginationData] = React.useState();
  const [cascadedOkrs, setCascadedOkrs] = React.useState()
  const [nonCascadedOkrs, setNonCascadedOkrs] = React.useState()
  const [filter, setFilter] = React.useState('objective');



  const columns = [
    {
      title: <div>

        <CustomSelect onChange={(e) => handleFilterChange(e)} defaultValue={filter}>
          <Select.Option className='Manrope' style={{ color: '#A3A3A3', fontSize: '13px' }} key='objective' value='objective' >OBJECTIVE</Select.Option>
          <Select.Option className='Manrope' style={{ color: '#A3A3A3', fontSize: '13px' }} key='keyresult' value='keyresult' >KEY RESULT</Select.Option>
          <Select.Option className='Manrope' style={{ color: '#A3A3A3', fontSize: '13px' }} key='milestone' value='milestone' >MILESTONE</Select.Option>
        </CustomSelect>
      </div>,
      width: 180,
      key: 'OBJECTIVE',
      render: (text, record) => record &&
        <div>
          <Row wrap={false} >
             <Tooltip title={record?.node?.okr?.title}>
             <div className='Manrope' style={{
              fontSize: '14px', color: '#000000',
              textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', fontWeight: '600'
              ,marginRight:'10px'
              }}>
              {record?.node?.okr?.title}
              </div>
             </Tooltip>
            <div style={{ marginLeft: '10px' }}>
            { filter==='objective'?<Link to={`/role/objective-keyresult/view/${getIntFromString(record?.node?.okr?.id)}`} target="_blank">
                <img style={{ height: '10px', width: '10px',cursor:'pointer' }} src={green_arrow} />
              </Link>:
              filter==='keyresult'?<Link to={`/role/objective-keyresult/keyresult/view/${getIntFromString(record?.node?.okr.id)}`} target="_blank">
                <img style={{ height: '10px', width: '10px',cursor:'pointer' }} src={green_arrow} />
              </Link>:
              <Link to={`/role/objective-keyresult/milestone/view/${getIntFromString(record?.node?.okr.id)}`} target="_blank">
                <img style={{ height: '10px', width: '10px',cursor:'pointer' }} src={green_arrow} />
              </Link>
            }

            </div>
          </Row>
          <Row>
            <div className='Manrope' style={{ marginBottom: '10px', fontSize: '13px', fontStyle: 'normal', paddingRight: '5px', color: '#8D8D8D',textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
              {`${record?.node?.okr?.owner?.user?.firstName} ${record?.node?.okr?.owner?.user?.lastName}`} 
              </div>
          </Row>
        </div>

    },
    {
      title: <div className='okrPerformance-table-heading' align='center'>PROGRESS</div>,
      key: 'PROGRESS',
      width: 180,
      render: (text, record) => record &&
        <div style={{}}>
          <ProgressRow style={{
            fontSize: '14px', color: '#000000',
            fontWeight: '600'

          }} align='middle'>

            <CustomProgress progress={record?.node?.progress} info={true} showPercentageOnTop={true} color={record?.node?.colorCombination?.color} />
          </ProgressRow>
        </div>
    },
    {
      title: <div className='okrPerformance-table-heading' align='center'>DUE DATE</div>,
      key: 'DUE_DATE',
      width:150,
      render: (text, record) => record &&
        <div align='center' className='Manrope' style={{
          fontSize: '13px', color: `${record?.node?.colorCombination?.color}`,
          // width:'150px'

        }}>
          {displayUtcToUserTimezone(record?.node?.okr?.duedate, "DD MMM YYYY")}
        </div>


    },
    {
      title: <div className='okrPerformance-table-heading' align='center'>STATUS</div>,
      key: 'STATUS',
      width: 120,
      render: (text, record) => record &&

        <div align='center' style={{
          fontSize: '13px', color: `${record?.node?.colorCombination?.color}`,
          fontWeight: '600',
          // width: '110px',
          margin: 'auto',
          background: `${record?.node?.colorCombination?.bg}`,
          padding: '4px',
          borderRadius: '8px',
          // width:'120px'

        }}>

          {record?.node?.status}
        </div>

    }




  ];


  React.useEffect(() => {
      if(paginatedData){
        let list ={
          edgeCount:paginatedData?.edgeCount,
          totalCount:paginatedData?.totalCount,
          edges : paginatedData?.edges?.map(({node})=>{
            return {
              node:{
                ...node,
                status:getStatus({node}),
                colorCombination:getColor({node})
              }
            }
          })
        }
        if(paginatedData?.pagination){
          let paginatedList ={
            edgeCount:paginationData?.edgeCount+list?.edgeCount,
            totalCount:paginatedData?.totalCount,
            edges : [...paginationData?.edges,...list?.edges]
          }
          setPaginationData(paginatedList)
        }else{
          setPaginationData(list)
        }
      }

  }, [paginatedData])



  React.useEffect(() => {

    if (cascadedOkr) {
      setCascadedOkrs(cascadedOkr)
    }
  }, [cascadedOkr])


  React.useEffect(() => {
    if (nonCascadedOkr) {
      setNonCascadedOkrs(nonCascadedOkr)
    }
  }, [nonCascadedOkr])

  const getStatus = (data) => {
    if (data) {
    
      let currentDate = moment();
      if (data?.node?.okr?.isDiscarded?.id) {
        return 'Discarded'
      }
      else if (currentDate.isAfter(data?.node?.okr?.duedate) && data?.node?.progress < 100) {
        return 'Missed'
      }

      else if (moment(data?.node?.okr?.duedate).isAfter(moment()) && data?.node?.progress >= 0 && data?.node?.progress < 100) {
        return 'In progress'
      }

      else if (data?.node?.progress === 100) {
        return 'Completed'
      }

      else if (data?.node?.okr?.discardRequest?.status === 'PENDING' || getPendingRequest(data)) {
        return 'Pending'
      }

    }
  }


  const getColor = (data) => {
    if (data) {

      let currentDate = moment();
      if (data?.node?.okr?.isDiscarded?.id) {
        return {
          color: '#7A7A7A',
          bg: '#DEDEDE'
        }
      }
      else if (currentDate.isAfter(data?.node?.okr?.duedate) && data?.node?.progress < 100) {
        return {
          color: '#EF7878',
          bg: '#FFF2F1'
        }
      }

      else if (moment(data?.node?.okr?.duedate).isAfter(moment()) && data?.node?.progress >= 0 && data?.node?.progress < 100) {
        return {
          color: '#FAC53C',
          bg: '#FDF2D6'
        }
      }

      else if (data?.node?.progress === 100) {
        return {
          color: '#4CCB1F',
          bg: '#D6FFC8'
        }
      }
      else if (data?.node?.okr?.discardRequest?.status === 'PENDING' || getPendingRequest(data)) {
        return {
          color: '#009AF1',
          bg: '#C2F4FF'
        }
      }

    }
  }



  const getPendingRequest = (data) => {

    let pendingAssignCollReq =
      data?.node?.okr?.contributorSet?.edges?.map((element) =>
        (element?.node?.role === 'OWNER' && getIntFromString(element?.node?.employee?.user?.id) === getIntFromString(me?.id) && element?.node?.requestStatus === "PENDING") ?
          element

          :
          ((element?.node?.role === 'ASSIGNED' || element?.node?.role === 'COLLABORATOR') && getIntFromString(element?.node?.employee?.user?.id) === getIntFromString(me?.id) && element?.node?.requestStatus === "PENDING") ?
            element
            : null

      ).filter((node) => node != null)




    if (pendingAssignCollReq?.length >= 2) {
      pendingAssignCollReq = pendingAssignCollReq?.filter(({ node }) => node?.role === 'OWNER')
    }

    return pendingAssignCollReq?.length;
  }


 





  const handleFilterChange = (e) => {
 
    onPagination(0, e);
    setFilter(e)

      if(e==='objective'){
        setObjectivePageCount(0)
      }
      else if(e==='keyresult')  {
        setkrPageCount(0);
      }
      else{
        setMsPageCount(0);
      }
  
  }


  const getPaginatedData = (selectedFilter) => {

      if(selectedFilter==='objective'){
        setObjectivePageCount(objectivePageCount + 1)
        onPagination(objectivePageCount + 1, selectedFilter,true)
        
      }
      if(selectedFilter==='keyresult'){
        setkrPageCount(krPageCount + 1);
        onPagination(krPageCount + 1, selectedFilter,true)
        
      }
      if(selectedFilter === 'milestone') {
        setMsPageCount(msPageCount + 1);
        onPagination(msPageCount + 1, selectedFilter,true)
        
      }
   
  }


  return (
    <>
      <DashboardRow align="center"  style={{gap:"30px"}}>
          <Col className="ind-chart-container dashboard-content" lg={{ span: 11 }}  md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }} >

          <Spin spinning={cascadedOkrLoading} >
            <h4 className="chart-title">Cascaded Objectives 
              <Tooltip title={'Cascaded Objectives'}>
                  <img src={InfoCircle} alt="" style={{cursor:"pointer",marginLeft:"10px"}} />
              </Tooltip>
            </h4>
            <Row align="middle" style={{width:"100%",flexDirection:"column"}}>
            <DoughntChartCascaded
              {...props}
              getNextSetOfCascadedOkrs={(e, offset) => getAllCascadedObjectives(e, offset,true)}
              label={'Cascaded Objectives'}
              topValue = '135px'
              rightValue = '92px'
              fontSize = '14px'
              responsiveFontSize = '11px'
              responsiveTopValue = '100px'
              responsiverightValue = '66px'
              data={cascadedOkrs}
              isIndividual={true}
              loading={cascadedOkrLoading}
            />
            </Row>
          </Spin>

          </Col>
          <Col className="ind-chart-container" lg={{ span: 11 }}  md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }} >
              <Spin spinning={nonCascadedOkrLoading}>
                <h4 className="chart-title">Non Cascaded Objectives 
                    <Tooltip title={'Non-Cascading Objectives'}>
                    <img src={InfoCircle} alt="" style={{cursor:"pointer",marginLeft:"10px"}} />
                    </Tooltip>
                </h4>
                <Row align="middle" style={{width:"100%",flexDirection:"column"}}>
                  <DoughntChartNonCascaded
                    {...props}
                    getNextSetOfNonCascadedOkrs={(e, offset) => getAllNonCascadedObjectives(e, offset,true)}
                    label={'Non cascaded Objectives'}
                    topValue = '135px'
                    rightValue = '90px'
                    fontSize = '14px'
                    responsiveFontSize = '11px'
                    responsiveTopValue = '100px'
                    responsiverightValue = '65px'
                    data={nonCascadedOkrs}
                    isIndividual={true}
                    loading={nonCascadedOkrLoading}
                  />
                </Row>
            </Spin>
          </Col>
      </DashboardRow>
      <DashboardRow align="center"  style={{gap:"30px",marginTop:"20px"}}>
        <Col className="ind-chart-container" style={{padding:"30px 10px",height:'530px'}} xl={{ span: 8 }} lg={{ span: 11 }}  md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }} >
          <h4 className="chart-title" style={{textAlign:"left",margin:upcomingMilestoneLoading ?  '0px auto 0px 25px' : '0px auto 30px 25px'}}>Upcoming Milestones</h4>
        
          {
             upcomingMilestoneLoading ?
            <Row justify='center' align='middle'  style={{height:'100%',margin:'auto'}}>
             <Spin spinning={true}>

             </Spin>
          </Row>
           : 

             <UpcomingMilestones upcomingMilestones={upcomingMilestones} upcomingMilestoneLoading={upcomingMilestoneLoading} {...props} /> 
         }
          
         
        </Col>
        <Col className="ind-chart-container chart-container" style={{padding:'20px 10px 20px 20px'}} xl={{ span: 14 }} lg={{ span: 11 }}  md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }} >

        <h4 className="chart-title" style={{textAlign:"left",margin:'10px auto 20px 20px'}}>My Okr Performance</h4>
        <Spin spinning={paginatedOkrLoading}>
        <HiddenScrollBar  style={{width:"100%",height:'350px',maxHeight:'350px'}}>
        <PerformanceTable
          size={'middle'}
          pagination={false}
          scroll = {{y:300}}
          dataSource={
            
            paginationData?.edges
              ? paginationData?.edges?.map((node) => node)
              : []
          }
          columns={columns.map(c => ({ ...c, sortDirections: ['descend', 'ascend', 'descend'] }))}
        />
        </HiddenScrollBar>
        {
        paginationData?.edgeCount &&  
        <div style={{ margin: '2em auto 2em auto',padding:'1em' }}>Showing {paginationData?.edgeCount}/{paginationData?.totalCount}
          {
          (paginationData?.edgeCount) !== (paginationData?.totalCount) &&
            <span onClick={(e) => getPaginatedData(filter)} style={{ cursor: 'pointer', textDecoration: 'underline', marginLeft: '10px', color: '#3B8855' }} >Load more</span>
          }

        </div>
        }
        </Spin>
        </Col>
      </DashboardRow>
    </>
  );
};

export default IndividualDashBoardView;

const CustomSelect = styled(Select)`
.ant-select-selector{
  border:none !important;
  color:#A3A3A3 !important;
  font-size: 13px !important;
}



`;


const ProgressRow = styled(Row)`

.ant-progress-bg{
height:6px !important;

}

`;


const PerformanceTable = styled(Table)`
.ant-table-body::-webkit-scrollbar {	
  height: 4px;	
  width: 8px;
  padding-left:10px;
}	
.ant-table-body::-webkit-scrollbar-track {	

  display:none;
}	
.ant-table-body::-webkit-scrollbar-thumb {	
  border-radius: 10px;	
  background-color:#F0F0F0;	
}`;

const HiddenScrollBar = styled(Col)`

.ant-table-thead > tr > th{
  background:#FFFFFF;	
}

.ant-table table{
  padding-right:20px !important;
}

.ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
  box-shadow: none !important;
}

.ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before {
  box-shadow: none !important;
}

.ant-select-item .ant-select-item-option .ant-select-item-option-active{
  color: black !important;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled){
  background-color:#fffff0 !important;
}

.ant-table-header{
  padding-right:10px;
}

.ant-table-tbody > tr.ant-table-row:hover > td{
  background:#FFFFFF;	
}

::-webkit-scrollbar {	
 display:none;
}	
::-webkit-scrollbar-track {	

  display:none;
}	
::-webkit-scrollbar-thumb {	
 display:none;
}


`;

// adjust margin left for columns of charts  and remove class chart container add only some selective styles from that class
const DashboardRow = styled(Row)`


  .ant-spin-spinning{
     padding-bottom:50px;
  }

`;


