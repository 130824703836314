import gql from 'graphql-tag';
import { COACHING_COMMITMENT_TYPE } from './CoachingCommitmentType.gql';

export const COACHING_COMMITMENT_TYPE_CONNECTION = gql`
  ${COACHING_COMMITMENT_TYPE}
  fragment CoachingCommitmentTypeConnection on CoachingCommitmentTypeConnection {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...CoachingCommitmentType
      }
    }
    totalCount
    edgeCount
  }
`;
