import gql from 'graphql-tag';
import { SIMPLE_FIELD_CHOICE_TYPE } from './SimpleFieldChoiceType.gql';

export const DELETE_SIMPLE_FIELD_CHOICE = gql`
  ${SIMPLE_FIELD_CHOICE_TYPE}
  mutation deleteSimpleFieldChoice($id: ID!) {
    deleteSimpleFieldChoice(id: $id) {
      simpleFieldChoice {
        ...SimpleFieldChoiceType
      }
    }
  }
`;
