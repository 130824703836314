import gql from 'graphql-tag';

export const LOOK_USER_STATE_QUERY = gql`
  query lookUserState {
    lookUserState @client {
      filter {
        username_Icontains
        firstName_Icontains
        lastName_Icontains
        nameIcontains
      }
      orderBy
    }
  }
`;
