import gql from 'graphql-tag';
import { RESPONSE_TYPE } from './ResponseType.gql';

export const EMAIL_PERCEPTION_REPORT_QUERY = gql`
  ${RESPONSE_TYPE}
  query emailPerceptionReport($responseId: ID!) {
    emailPerceptionReport(responseId: $responseId) {
      ...ResponseType
    }
  }
`;
