import gql from "graphql-tag";

export const ALL_RESPONSES_BY_IMPLICIT_USER_TOP_RESPONSES = gql`
query allResponsesByImplicitUserId($survey: ID) {
   allResponsesByImplicitUserId(survey: $survey) {
    edges {
      node {
        id
        surveyChoice: survey {
          id
          uiFlow
          name
          groupSet(first: 1) {
            edges {
              node {
                id
                questionSet {
                  edges {
                    node {
                      id
                      choiceSet {
                        edges {
                          node {
                            id
                            label
                            value
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        surveyAnswers: survey {
          id
          groupSet(last: 1) {
            edges {
              node {
                id
                questionSet(first:5) {
                  edges {
                    node {
                      id
                      questionText
                      answerSet(first: 1) {
                        edges {
                          node {
                            id
                            answer
                            response{
                              id
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  }  
`;