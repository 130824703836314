import React from 'react';
import { Skeleton, Card } from 'antd';

const QuizLoading = props => {
  return (
    <div style={{ display: 'grid', minHeight: '100vh' }}>
      <Card
        bordered={false}
        style={{ backgroundColor: '#C4C4C4', margin: 'auto', width: '50vh', minHeight: '60vh', minWidth: '40vw' }}
      >
        <br />
        <Skeleton active title={{ width: '30%', color: '#fff' }} paragraph={false} />
        <Skeleton active title={{ width: '80%' }} paragraph={false} />
        <Skeleton active title={{ width: '80%' }} paragraph={false} />
        <br />
        <Skeleton active title={{ width: '30%' }} paragraph={false} />
        <Skeleton active title={{ width: '80%' }} paragraph={false} />
        <Skeleton.Button active />
        <br />
        <br />
      </Card>
    </div>
  );
};

export default QuizLoading;
