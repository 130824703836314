import React from 'react';
import UserCompetencyAssessment from '../components/userCompetencyAssessment';
import { EMPLOYEE_ASSESSMENT } from '../graphql/EmployeeProfile.gql';
import { threshold_colors } from 'modules/competency-assessment/color-config';

const UserCompetencyAssessmentContainer = (props) => {
    const { profile_detail,client } = props
    const [assessment,SetAssessment] = React.useState()

    React.useEffect(()=>{
        if(profile_detail){
            getAssessment({employee:profile_detail?.employee_id})
        }
    },[profile_detail])


    const getAssessment= async (filter)=>{
        const { data } = await client.query({
            query: EMPLOYEE_ASSESSMENT,
            // fetchPolicy: 'network-only',
            variables: filter,
        });
        if(data){
            let emp_assesment =data?.assessments?.edges?.map(({node})=>node)
            let data_assessment = emp_assesment?.length?handleAssessmentData(emp_assesment[0]):{}
            SetAssessment(data_assessment)
        }
    }

    const handleAssessmentData=(data)=>{
        
        let functional_assessment = data?.competencyassessmentSet?.edges?.map(({node})=>node).filter(i=>(i?.mainCompetency?.competencyType || i?.competency?.competencyType) === 'ROLE_SPECIFIC')
        let generic_assessment = data?.competencyassessmentSet?.edges?.map(({node})=>node).filter(i=>(i?.mainCompetency?.competencyType || i?.competency?.competencyType) !== 'ROLE_SPECIFIC')
        
        let role_related_competency =[
            ...data?.role?.optionalSpecificCompetency?.edges?.map(({node})=>[node?.id,node?.competencyCategory?.id]),
            ...data?.role?.requiredSpecificCompetency?.edges?.map(({node})=>[node?.id,node?.competencyCategory?.id]),
        ]
        if(data?.role?.jobfamilySet?.edges?.length){
            let job_family_competency = []
            let job_family = data?.role?.jobfamilySet?.edges?.map(({node})=>node)
            job_family.forEach(i=>{
                let competency = i.genericCompetency?.edges?.map(({node})=>node)
                if(competency?.length){
                    competency.forEach(comp=>{
                        job_family_competency.push([comp?.id,comp?.competencyCategory?.id])
                    })
                }
            })
            role_related_competency = role_related_competency.concat(job_family_competency)

        }
        role_related_competency = [].concat.apply([],role_related_competency)
        
        let only_functional_assessment = functional_assessment?.filter(i=>role_related_competency?.includes(i?.competency?.id || i?.mainCompetency?.id))
        let only_generic_assessment = generic_assessment?.filter(i=>role_related_competency?.includes(i?.competency?.id || i?.mainCompetency?.id))
        let other_assessment = [...generic_assessment,...functional_assessment]?.filter(i=>!role_related_competency?.includes(i?.competency?.id || i?.mainCompetency?.id))

        return {
            id: data?.id,
            user_details: profile_detail,
            email:data?.employeeEmail,
            emplpyee_level: data?.employeeLevel,
            employee_role: data?.role,
            correctionDeadline: data?.correctionDeadline,
            functional_assessment:only_functional_assessment?.length?getColor(only_functional_assessment):null,
            generic_assessment:only_generic_assessment?.length?getColor(only_generic_assessment):null,
            other_assessment:other_assessment?.length?getColor(other_assessment):null,
        }
    }

    const getColor =(data)=>{
       
        let assessmentbarsSet = data?.map(i=>i?.assessmentbarsSet?.edges[0].node)
        let assessment_summary = mostRepeatedKey(assessmentbarsSet)
        assessment_summary['background'] =`rgba(${threshold_colors[ `threshold_${assessment_summary?.qualifyingLevelSequence}`] || threshold_colors['threshold_4']},0.1)`
        assessment_summary['color'] = `rgba(${threshold_colors[ `threshold_${assessment_summary?.qualifyingLevelSequence}`] || threshold_colors['threshold_4']})`
        assessment_summary['border'] = `rgba(${threshold_colors[ `threshold_${assessment_summary?.qualifyingLevelSequence}`] || threshold_colors['threshold_4']})`
        return assessment_summary
    }

    const mostRepeatedKey = (arr) => {
        let list = {}
        arr.forEach(item => {
            if (list[`id_${item?.qualifyingLevelId}`]) {
                list[`id_${item?.qualifyingLevelId}`].count++
            } else {
                list[`id_${item?.qualifyingLevelId}`] = {
                    count: 1,
                    ...item
                }
            }
        })
        let higest_count = Object.values(list)?.sort((a, b) => b.count - a.count)[0]?.count
        let data = Object.values(list)?.filter(item => item?.count === higest_count).sort((a, b) => b.qualifyingLevelSequence - a.qualifyingLevelSequence )
        return data[0]
    }

    return (
        <>
            <UserCompetencyAssessment {...props} assessment={assessment}/>
        </>
    )
}

export default UserCompetencyAssessmentContainer