import gql from 'graphql-tag';
import { QUESTION_TYPE } from './QuestionType.gql';

export const ANSWER_TYPE = gql`
  ${QUESTION_TYPE}
  fragment AnswerType on AnswerType {
    id
    # response {
    #   ...ResponseType
    # }
    question {
      ...QuestionType
    }
    answer
    responseDuration
  }
`;
