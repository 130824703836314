import React from "react";
import SoulCompletedResponseView from "../components/soul-completed-response-view";
import { SURVEY_BY_ID_VALUES_DISPLAy_QUERY } from "modules/learning-path/graphql/learnging-query.gql";
import { compose } from "modules/core";
import { withApollo } from '@apollo/client/react/hoc';
import { getSurveyBase64StringFromInt } from "modules/look";

const SoulCompletedResponseContainer =(props)=>{
    const {client,me,show_view}=props
    const [response_data, setResponseData] = React.useState([])
  const [survey, setSurvey] = React.useState()
  const [loading, setloading] = React.useState(false)

  React.useEffect(() => {
    if (me?.employee&&show_view) {
        let id = getSurveyBase64StringFromInt(show_view?.id);
        getSurveyDetail({ id, responseUser: me?.id })
    }
  }, [me,show_view])

    const getSurveyDetail = async (value) => {
        setloading(true)
        try {
          const { data } = await client.query({
            query: SURVEY_BY_ID_VALUES_DISPLAy_QUERY,
            variables: value,
            fetchPolicy: 'network-only'
          });
          if (data?.surveyAnswers) {
            setSurvey({
              id: data?.surveyAnswers?.id,
              name: data?.surveyAnswers?.name
            })
            let responseData = []
            let survey_type
            if (props.match) {
              survey_type = props.match.params.type;
            } else if (props.navigation) {
              survey_type = props.navigation.state.params.type;
            }
      
            data.surveyAnswers.groupSet.edges.forEach((gs) => {
              gs.node.questionSet.edges.slice(0, 5).forEach((qs) => {
                let d = {}
                d['question_id'] = qs?.node?.id
                qs.node.answerSet.edges.slice(0, 1).forEach((as) => {
                  d['answer'] = as?.node?.answer
                  d['answer_id'] = as?.node?.id
                  d['response_id'] = as?.node?.response?.id
                })
                responseData.push(d)
              })
            })
            console.log("response",responseData)
            setResponseData(responseData)
            setloading(false)
          }
        } catch (error) {
          
        }
      }
    return (
        <>
        <SoulCompletedResponseView loading={loading} response_data={response_data} {...props}/>
        </>
    )
}

export default compose(withApollo)(SoulCompletedResponseContainer);