// @ts-nocheck
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Layout, PageHeader, Select, Skeleton, Row, Button, Col, Form, Empty, Modal, Tooltip, Space, Spin, message } from 'antd';
import { LeftCircleOutlined, InfoCircleOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { ButtonsCatch, getIntFromString, ReactMarkdown, SelectField, SelectFieldSoul } from '../../look';
import { useHistory } from 'react-router-dom';
import homepageContent from '../../page/containers/homePageContent'
import Home_soul from '../../assets/home-icon-soul.svg'
import Right_arow from '../../assets/right-arrow-soul.svg'
import { SOUL_HOME_TOOL_ID } from "../../../config";
import Back_icon from '../../assets/soul-back.svg'
import '../css/editSurveyResponse.css'
import SoulSurveySuccessPopup from './SoulSurveySuccessPopup';
import styled from 'styled-components';
const EditSurveyResponseNew = (props) => {
    const { Header, Content, Footer, Sider } = Layout;
    const { Option } = Select;
    const {
        loading,
        qa,
        allOpts,
        editResponse,
        responseId,
        sId,
        sname,
        createResponse,
        location,
        uiLoading,
        soul_survey_completion,
        app_sidebar_expand
    } = props;
    const [submitLoading, setSubmitLoading] = React.useState(false);
    const [updatedAns, setUpdatedAns] = React.useState({});
    const [choiceList, setChoiceList] = React.useState();
    const [isOtherInput, setIsOtherInput] = useState(false)
    const history = useHistory();
    const [form] = Form.useForm();
    const [disabled_choice, Setdisabled_choice] = React.useState([])
    const [success_popup, SetSuccessPopup] = React.useState(false)
    var timer = 0

    React.useEffect(() => {
        if (qa) {

            let answer = {}
            let list = qa?.filter(i => i?.a) || []
            list.forEach(i => {
                answer[getIntFromString(i?.id)] = { value: i?.a }
            })
            setUpdatedAns(answer)
        }
    }, [qa])

    React.useEffect(() => {
        if (updatedAns) {
            let list = Object.values(updatedAns)?.map(i => i?.value?.toLowerCase())
            Setdisabled_choice([].concat(list))
        }
    }, [updatedAns])

    const goBack = () => {
        if (location?.search) {
            let data = location?.search
            let id = data.split('=')
            if (id[1]) {
                history.push('/soul/values')
            }
        }
        else {
            history.goBack()
        }
    }
    useEffect(() => {
        let timerInterVal = setInterval(() => {
            timer = timer + 1
        }, 2000);
        return () => {
            clearInterval(timerInterVal)
            timer = 0
        }
    }, [uiLoading])

    const handleSubmit = async () => {

        if (responseId) {
            let ansToSend = []
            Object.entries(updatedAns).forEach(item => {
                let res = allOpts?.find(i => i?.value == item[1]?.value)
                ansToSend.push({ 'questionId': item[0], answer: item[1]?.value, 'responseDuration': 1, sequence: res?.sequence || null })
            })


            let data_list = qa?.map(i => {
                let id = getIntFromString(i?.id)
                let update = ansToSend?.find(j => j?.questionId == id)
                return {
                    questionId: id,
                    answer: update ? update?.answer : i?.a
                }
            })
            data_list = data_list?.filter(i => i?.answer)
            let check_unique = [...new Set(data_list?.map(i => i?.answer))]

            if (data_list?.length < 5) {
                message.destroy()
                message.error('Please fill in all the values')
                return
            }

            if (check_unique?.length < 5) {
                message.destroy()
                message.error('Each values must be unique')
                return
            }

            setSubmitLoading(true);
            try {
                await editResponse({ answers: ansToSend, responseId: getIntFromString(responseId) });

                // let param=props.match.params.surveyType;
                let data = homepageContent[props.match.params.surveyType]
                if (data) {
                    history.goBack()
                }
            } catch (e) {
                setSubmitLoading(false);
                throw new Error(e.message);
            }

        } else {
            let ansToSend = []
            Object.entries(updatedAns).forEach(item => {
                ansToSend.push({ 'questionId': item[0], answer: item[1]?.value })
            })
            ansToSend = ansToSend?.filter(i => i?.answer)
            let check_unique = [...new Set(ansToSend?.map(i => i?.answer))]

            if (ansToSend?.length < 5) {
                message.destroy()
                message.error('Please fill in all the values')
                return
            }

            if (check_unique?.length < 5) {
                message.destroy()
                message.error('Each values must be unique')
                return
            }
            try {
                await createResponse({ answers: ansToSend, surveyId: getIntFromString(sId), totalDuration: timer });
                let data = homepageContent[props.match.params.surveyType]
                let param = props.match.params.surveyType;
                if (data) {
                    if (soul_survey_completion && param == 'values' && soul_survey_completion?.Strength?.totalCount === 0) {
                        SetSuccessPopup(true)
                    }
                    else {
                        history.goBack()
                    }
                }
            } catch (e) {
                setSubmitLoading(false);
                throw new Error(e.message);

            }
        }
        setSubmitLoading(false);
    };

    function onSelectChange(id, k) {
        if (k === "") {
            setIsOtherInput(true)
        } else {
            setIsOtherInput(false)
        }
        let qid = getIntFromString(id)
        setUpdatedAns(prevInfo => ({ ...prevInfo, [qid]: { 'value': k } }))
    }

    function onInputChange(id, e) {
        if (e.target.value) {
            let qid = getIntFromString(id)
            setUpdatedAns(prevInfo => ({ ...prevInfo, [qid]: { 'value': e.target.value } }))
        }
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const hasIntegers = (value) => {
        return /\d/.test(value);
    };

    const hasSpecialCharacters = (value) => {
        return /[!@#$%^&*().?":{}|<>]/.test(value);
    };

    const hasBlankSpacesOnly = (value) => {
        return /^\s+$/.test(value);
    };

    const validateNoIntegers = (rule, value) => {
        if (value && hasIntegers(value) && isOtherInput) {
            return Promise.reject('Integers are not allowed');
        }
        return Promise.resolve();
    };

    const validateNoSpecialCharacters = (rule, value) => {
        if (value && hasSpecialCharacters(value) && isOtherInput) {
            return Promise.reject('Special characters are not allowed');
        }
        return Promise.resolve();
    };

    const validateNoBlankSpaces = (rule, value) => {
        if (value && hasBlankSpacesOnly(value) && isOtherInput) {
            return Promise.reject('Blank spaces are not allowed');
        }
        return Promise.resolve();
    };

    const goToNextStep = () => {
        let param = props.match.params.surveyType;
        SetSuccessPopup(false)
        if (param == 'values') {
            history.push('/soul/strengths')
        }
        else {
            history.goBack()
        }
    }

    const getMessage = () => {
        let param = props.match.params.surveyType;
        if (param == 'values') {
            return 'Do you want to go to strength'
        }
    }

    return (
        <>

            <Modal
                visible={success_popup}
                footer={false}
                closable={false}
                destroyOnClose={true}
                centered={true}
                style={{ maxWidth: "45em" }}
                className='idp-modal'
                width={"90%"}
            >
                <SoulSurveySuccessPopup
                    message={getMessage()}
                    main_warning={`You have successfully submitted your response.`}
                    onCancel={() => { { SetSuccessPopup(false); history.goBack() } }}
                    onConfirm={() => { goToNextStep() }}
                />
            </Modal>

            <Spin spinning={submitLoading || uiLoading} size="small">

                <Row justify="center" className='main-container-row main-page-card'>
                    <Col span={24} style={{ position: "relative" }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                            <img className="back-icon-survey back-arrow-global" src={Back_icon} alt="" onClick={() => goBack()} />
                            <Row justify='center' align='center' style={{ width: "100%" }}>
                                <h2 className='soul-survey-edit-head'>My <span style={{ color: "#4CCB1F" }}>Top 5</span> Values</h2>
                            </Row>
                            <img className="back-icon-survey back-arrow-global" style={{ visibility: 'hidden' }} src={Back_icon} alt="" onClick={() => goBack()} />

                        </div>

                        <div className='survey-section-container' align='center' justify='center' style={{ display: "flex", flexDirection: 'column', width: '100%' }}>
                            <Form form={form} name="control-hooks" onFinish={handleSubmit} onFinishFailed={onFinishFailed} >
                                <Row style={{ display: 'flex', justifyContent: 'space-between', marginLeft: '1vw', marginRight: '1vw' }}>
                                    {qa.map((q, idx) => {
                                        return (
                                            <>
                                                <Row align='center' justify='center' style={{ width: '100%', marginTop: '2vh', gap: "5px", flexWrap: "nowrap" }}>
                                                    <h4 className='survey-index' style={{ margin: 0 }}>#{idx + 1}</h4>
                                                    <SelectFieldSoul
                                                        className="survey-select-filed"
                                                        name={q.id}
                                                        label={""}
                                                        rules={[
                                                            {
                                                                validator: validateNoIntegers,
                                                            },
                                                            {
                                                                validator: validateNoSpecialCharacters,
                                                            },
                                                            {
                                                                validator: validateNoBlankSpaces,
                                                            },
                                                            // Add more rules if needed
                                                        ]}
                                                        otherOption={false}
                                                        initialValue={q.a}
                                                        showSearch
                                                        // onSearch={(input) => choiceFilter(input, allOpts)}
                                                        disabled_choice={disabled_choice}
                                                        choices={allOpts}
                                                        onBlur={(v) => { onInputChange(q.id, v) }}
                                                        optionFilterProp="children"
                                                        onChangeSelect={(v, k) => { onSelectChange(q.id, v) }}
                                                        filterOption={(input, option) =>
                                                            option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    // updatedAns={updatedAns}
                                                    >
                                                    </SelectFieldSoul>
                                                </Row>
                                                {idx % 2 != 0 && (
                                                    <div style={{ flexBasis: '100%', height: '0' }}>
                                                    </div>
                                                )}
                                            </>
                                        )
                                    })

                                    }

                                </Row>
                                <div className={`one-on-one-bottom-div ${app_sidebar_expand ? 'survey-section-container-margin-left' : ''}`}
                                    style={{ padding: "0px", paddingBottom: "10px", paddingRight: "0px" }}>
                                    <div style={{ width: '100%' }} className={!app_sidebar_expand ? 'custom-alignment' : ''} align="center">
                                        <Button
                                            className='save-step-2-button'
                                            type="primary" htmlType="submit" style={{ marginTop: '1em' }}>Save & Proceed</Button>
                                    </div>
                                </div>

                            </Form>
                        </div>
                    </Col>
                </Row>
            </Spin>
        </>
    )
}

export default EditSurveyResponseNew
