import gql from 'graphql-tag';

export const ORG_MODULE_SETTING = gql`
  query orgSettingsById($id: ID!) {
    orgSettingsById(id: $id) {
        edges {
          node {
            id
            uiCfg {
              id
              name
              parent {
                id
                name
              }
            }
            status
          }
        }
      }
  }
`;

