import gql from 'graphql-tag';
import { SIMPLE_FIELD_CHOICE_TYPE_CONNECTION } from './SimpleFieldChoiceTypeConnection.gql';

export const SIMPLE_FIELD_TYPE = gql`
  ${SIMPLE_FIELD_CHOICE_TYPE_CONNECTION}
  fragment SimpleFieldType on SimpleFieldType {
    id
    label
    inputType
    editable
    sequence
    helpText
    simplefieldchoiceSet {
      ...SimpleFieldChoiceTypeConnection
    }
  }
`;
