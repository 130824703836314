import gql from 'graphql-tag';
// import { TOOL_TYPE } from './ToolType.gql';

// ${TOOL_TYPE}
export const GET_ALL_TOOLS_ADMIN_QUERY = gql`
  query getAllToolsAdmin(
    $first: Int
    $offset: Int
    $orderBy: [String]
    $title_Icontains: String
    $description_Icontains: String
  ) {
    getAllToolsAdmin(
      first: $first
      offset: $offset
      orderBy: $orderBy
      title_Icontains: $title_Icontains
      description_Icontains: $description_Icontains
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          # ...ToolType
          id
          title
          uiFlow
          thumbnailImage
          description
          status
          public
          pubDate
        }
      }
      totalCount
      edgeCount
    }
  }
`;
