import gql from 'graphql-tag';
import { FAQ_CATEGORY_TYPE_CONNECTION } from './FaqCategoryTypeConnection.gql';

export const FAQ_SEARCH_QUERY = gql`
  ${FAQ_CATEGORY_TYPE_CONNECTION}
  query faqSearch($searchText: String!) {
    faqSearch(searchText: $searchText) {
      ...FaqCategoryTypeConnection
    }
  }
`;
