import { compose } from 'modules/core'
import React from 'react'
import ReadinessDetailedView from '../components/ReadinessDetailView'
import { withApollo } from '@apollo/client/react/hoc';
import { getIntFromString, getReadinessBase64StringFromInt, globalPermissionValidator, parseMarkDownText } from "../../look";
import { withDeleteReadiness } from './ReadinessOperations'
import { GET_TOOL_ID_FOR_REPORTED_ID } from '../graphql/getToolidForReported.gql'
import { GET_TOOL_ID_FOR_REPORTED_RECEIVED_ID } from '../graphql/getToolByIdReceived.gql'
import { READINESS_REPORT_TOOL_ID } from '../../../config';
import { readiness_permission } from 'Permissions/readiness.permission';
import { READINESS_DETAIL } from '../graphql/ReadinessDetail.gql';
import NoPermissionView from 'modules/kudos-user/component/NoPermissionComponent';
import { ai_trigger_content } from 'modules/ai-tips/ai-trigger-content';

const ReadinessDetailComponent = (props) => {
    const { client, me, SetAITrigger, userPermission, readiness_detail } = props
    const [id, setid] = React.useState()
    const [toolIdData, setToolIdData] = React.useState()
    const [toolLoading, setToolLoading] = React.useState(true)
    const [submittedByMe, setsubmittedByMe] = React.useState(false);
    const [answerList, setanswerList] = React.useState([]);
    const [permission, setpermission] = React.useState()
    const [readinessById, setreadinessById] = React.useState()
    const mountedRef = React.useRef(true)
    let readinessLevelSub = undefined
    const childRef = React.useRef();

    React.useEffect(() => {
        return () => {
            if (readinessLevelSub) {
                readinessLevelSub.unsubscribe();
            }
        }
    })

    React.useEffect(() => {
        return () => {
            mountedRef.current = false
        }
    }, [])

    React.useEffect(() => {
        mountedRef.current = true
    }, [])

    React.useEffect(() => {
        if (userPermission?.length && readiness_detail) {
            setToolLoading(true)
            let permission = globalPermissionValidator(readiness_permission, userPermission)
            if (permission?.required_permission) {
                getReadinessDetail(readiness_detail?.id)
            }
            setpermission(permission)
        }
    }, [userPermission, readiness_detail])

    React.useEffect(() => {
        if (readiness_detail && readinessById && me) {
            
            let me_id = getIntFromString(me?.employee?.id) 
            let readiness_to = getIntFromString(readinessById?.readinessTo?.user?.employee?.id)
            let userType = "employee"
            let dynamic_1 = "my"
            let tipFor = null
            let optype ='VIEW'
            let objectId =readiness_detail?.id
            if(!readiness_detail?.readinesslevelSet?.edges?.length){
                return
            }
            let response_set = readiness_detail?.readinesslevelSet?.edges[0]?.node?.response?.answerSet?.edges?.map(({node})=>{
                return `${parseMarkDownText(node?.question?.questionText)} : ${node?.answer}/100`
            })  
             if(me_id != readiness_to){
                userType = "manager"   
                tipFor = getIntFromString(readinessById?.readinessTo?.user?.id)
                dynamic_1 = "my reportee's"
             }
            if(SetAITrigger && readiness_detail?.zone_detail?.length){
                SetAITrigger({
                    trigger: ai_trigger_content.readiness_assessment_skill_summary,
                    dynamic_data: [dynamic_1,readiness_detail?.zone_detail[0]?.skill?.title,response_set?.join(",") ],
                    userType,
                    tipFor,
                    optype,
                    objectId
                })
            }
        
        }
    }, [readiness_detail,readinessById,me])

    const getReadinessDetail = async (id) => {
        const { data } = await client.query({
            query: READINESS_DETAIL,
            fetchPolicy: 'network-only',
            variables: { id },
        });

        if (data?.readinessById) { 
            setreadinessById(data?.readinessById)
        }
    }

    const getTools = async (id) => { 
        let answer = answerList
        let toolId = []
        let responseToolId = {}
        if (me?.employee?.id === readinessById?.readinessTo?.user?.employee?.id) {
            try {
                await Promise.all(answer.map(async list => {
                    await client.query({
                        query: GET_TOOL_ID_FOR_REPORTED_RECEIVED_ID,
                        variables: { toolId: READINESS_REPORT_TOOL_ID, responseId: getIntFromString(list?.response?.id) },
                        fetchPolicy: 'network-only'
                    }).then(({ data }) => {
                        if (data?.getToolById != null) {
                            toolId.push(list?.response?.id)
                            responseToolId[list?.response?.id] = data
                        }
                    });
                })
                )
            }
            catch (error) {

            }
        } else {
            try {
                await Promise.all(answer.map(async list => {
                    await client.query({
                        query: GET_TOOL_ID_FOR_REPORTED_ID,
                        variables: { toolId: READINESS_REPORT_TOOL_ID, employeeId: getIntFromString(id), responseId: getIntFromString(list?.response?.id) },
                        fetchPolicy: 'network-only'
                    }).then(({ data }) => {
                        if (data?.getToolForReporteeByToolId != null) {
                            toolId.push(list?.response?.id)
                            responseToolId[list?.response?.id] = data
                        }
                    });

                })
                )
            }
            catch (error) {

            }
        }
        for (const [key, value] of Object.entries(responseToolId)) {
            if (value?.getToolForReporteeByToolId) {
                let s = value?.getToolForReporteeByToolId?.textsection.edges.sort((a, b) => a?.node?.sequence - b?.node?.sequence)
                responseToolId[key].getToolForReporteeByToolId.textsection.edges = s
            }
            else {

                let resTool = value?.getToolById?.textsection.edges.sort((a, b) => a?.node?.sequence - b?.node?.sequence)
                if (responseToolId[key]?.getToolById?.textsection?.edges) {
                    responseToolId[key].getToolById.textsection.edges = resTool
                }
            }
        }
        setToolIdData(responseToolId)
        setTimeout(() => {
            setToolLoading(false)
        }, 3000)

    }

    React.useEffect(() => {
        if (permission?.required_permission && toolIdData) {
            childRef.current.rerender()
        }

    }, [toolIdData, permission])

    const anserListFn = (id) => {
        getTools(id)
        setid(id)
    }

    React.useEffect(() => {
        if (readinessById && me) {
            if (me?.employee?.id === readinessById?.readinessFrom?.user?.employee?.id) {
                setsubmittedByMe(true)
            }
            else {
                setsubmittedByMe(false)
            }
            let list = readinessById?.readinesslevelSet?.edges?.map(({ node }) => node)
            setanswerList(list)
        }

        localStorage.removeItem('emailRoute')

    }, [readinessById, me])

    React.useEffect(() => {
        if (answerList) {
            if (submittedByMe) {
                anserListFn(readinessById?.readinessTo?.id)
            }
            else {
                anserListFn(readinessById?.readinessTo?.id)
            }
        }

    }, [answerList])


    return (
        <>
            {(toolIdData && permission && permission?.required_permission) && (
                < ReadinessDetailedView
                    {...props}
                    getToolById={toolIdData}
                    submittedByMe={submittedByMe}
                    ref={childRef}
                    answerList={answerList}
                    toolIdData={toolIdData}
                    readinessById={readinessById}
                    toolLoading={toolLoading}
                />)}

            {
                (permission && !permission?.required_permission && !toolLoading) && (
                    <NoPermissionView />
                )
            }
        </>

    )
}
export default compose(withDeleteReadiness, withApollo,)(ReadinessDetailComponent)

// withReadinessDetail