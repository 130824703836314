import gql from 'graphql-tag';
import { STORY_TYPE } from './StoryType.gql';

export const STORY_TYPE_CONNECTION = gql`
  ${STORY_TYPE}
  fragment StoryTypeConnection on StoryTypeConnection {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...StoryType
      }
    }
    totalCount
    edgeCount
  }
`;
