import gql from 'graphql-tag';

export const LOOK_REFERENCE_FIELD_STATE_QUERY = gql`
  query lookReferenceFieldState {
    lookReferenceFieldState @client {
      filter {
        questionTextIcontains
      }
      orderBy
    }
  }
`;
