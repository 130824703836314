import React from "react";
import { Spin } from "antd";

import { ButtonsCatch, getIntFromString } from "../../look";
import { compose } from "../../core";
import ROUTE from "../route";
import EditTagView from "../components/EditTagView";

import { withEditTag, withTagById } from "./StoryOperations";

const EditTag = props => {
  const { history, editTag, tagById } = props;
  const [submitLoading, setSubmitLoading] = React.useState(false);

  const handleSubmit = async (values, item) => {
    setSubmitLoading(true);

    try {
      const response = await editTag({ id: getIntFromString(tagById.id), ...values });

      response && ButtonsCatch(item, ROUTE.tag, history, item === undefined ? window.location.pathname : ROUTE.addTag);
    } catch (e) {
      throw new Error(e.message);
    }
    setSubmitLoading(false);
  };

  return (
    <Spin spinning={submitLoading} size="large">
      <EditTagView onSubmit={handleSubmit} {...props} />
    </Spin>
  );
};

export default compose(withTagById, withEditTag)(EditTag);
