import React from "react";
import UserCompetecnyAssessmentHomeView from "../components/ca-user-detail-view";
import { compose } from "modules/core";
import { withApollo } from '@apollo/client/react/hoc';
import { ALL_COMPETENCIES, COMPETECNY_CATEGORY_BY_ID, COMPETENCY_ASSESSMENT_QUERY_BY_CATEGORY, COMPETENCY_ASSESSMENT_QUERY_BY_EMPLOYEE, RATING_SCALES_LIST, ROLE_BY_ID } from "../graphql/ca-detail-queries.gql";
import {  getCompetencyCategoryStringFromInt, getEmployeeBase64StringFromInt, globalPermissionValidator } from "modules/look";
import { competency_assessment_permission } from "Permissions/competency-assessment";
import NoPermissionView from "modules/kudos-user/component/NoPermissionComponent";
import { Spin } from "antd";
import CompetecnyAssessmentDetailView from "../components/ca-competency-detail-view";

const CompetencyAssessmentDetailContainer = (props) => {

    const { client, match, userPermission } = props
    const [page_loading, setPageLoading] = React.useState(false)
    const [assessment_details, setAssessmentDetails] = React.useState([])
    const [rating_list, setRatingList] = React.useState()
    const [permission, SetPermission] = React.useState()
    const [category_by_id, SetCategoryByid] = React.useState()
    //Permission Check
    React.useEffect(() => {
        if (userPermission?.length && match?.params?.id) {
            let permission = globalPermissionValidator(competency_assessment_permission, userPermission)
            SetPermission(permission)
            if(permission?.required_permission){
                competecnyAssessmentDetail({})
                RatingScaleList()
                competecnyCategoryDetail()
            }
        }
    }, [userPermission,match?.params?.id])

    let chart_filter = React.useRef({})

    const competecnyAssessmentDetail = async (value) => {
        let filter = {...chart_filter?.current,...value}
        chart_filter.current = filter
        setPageLoading(true)
        const { data } = await client.query({
            query: COMPETENCY_ASSESSMENT_QUERY_BY_CATEGORY,
            variables: { category_id: getCompetencyCategoryStringFromInt(match?.params?.id),...filter },
            fetchPolicy: 'network-only'
        });
        if (data?.assessments?.edges?.length) {
            setPageLoading(false)
            let list = data?.assessments?.edges?.map(({ node }) => node)
            setAssessmentDetails(list)
        }
        else {
            setAssessmentDetails([])
            setPageLoading(false)
        }
    }

    const competecnyCategoryDetail = async () => {

        const { data } = await client.query({
            query: COMPETECNY_CATEGORY_BY_ID,
            variables: { id: getCompetencyCategoryStringFromInt(match?.params?.id) },
            fetchPolicy: 'network-only'
        });
        if (data?.competencyCategoryId) {
            SetCategoryByid(data?.competencyCategoryId)
        }
      
    }

    const RatingScaleList = async (filter) => {
        const { data } = await client.query({
            query: RATING_SCALES_LIST,
            variables: { ...filter },
            fetchPolicy: 'network-only'
        });
        if (data?.ratingScales) {
            let list = data?.ratingScales?.edges?.map(({ node }, index) => { return { ...node, sequence: index + 1 } })
            setRatingList(list)
        }
    }

    return (
        <>
            {(permission && permission?.required_permission) && (
                <CompetecnyAssessmentDetailView
                    {...props}
                    assessment_details={assessment_details}
                    page_loading={page_loading}
                    rating_list={rating_list}
                    permission={permission}
                    category_by_id={category_by_id}
                    competecnyAssessmentDetail={competecnyAssessmentDetail}
                />)}
            {(permission && !permission?.required_permission) && (<NoPermissionView />)}
            {
                !permission && (
                    <div className="role-no-permission-container">
                        <Spin spinning={true} size="large"></Spin>
                    </div>
                )
            }
        </>
    )
}

export default compose(withApollo)(CompetencyAssessmentDetailContainer)