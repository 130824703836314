import gql from 'graphql-tag';

export const SURVEY_BY_ID =gql`
query SurveyById($id:ID!){
    SurveyById(id: $id){
      id
      name
    }
  }
`

