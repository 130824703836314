import React from 'react';
import { Form, Switch } from 'antd';

const FormItem = Form.Item;

const SwitchField = props => {
  const { onChange, ...rest } = props;

  return <div /* style={{ marginBottom: '5vh' }} */></div>;
};

export default SwitchField;
