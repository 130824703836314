import React from 'react';
import { Form, Input, Row, Col, Select, Button } from 'antd';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { ReactMarkdown } from 'modules/look';



const FormItem = Form.Item;
const Option = Select.Option;

const SelectFieldReainess = props => {
  const {
    choices,
    onAdd,
    onEdit,
    onDelete,
    margin = true,
    otherOption = false,
    onChangeSelect,
    newTab = false,
    offset,
    onSelectChange,
    onBlur,
    onSearch,
    defaultValue,
    addnewSkill,
    disabled,
    disabledOptions,
    ...rest
  } = props;
  const [value, setValue] = React.useState('');
  const [visible, setVisible] = React.useState(false);
  const ref = React.useRef(null);

  React.useEffect(() => {
    if (ref && ref.current && ref.current.props && ref.current.props.value !== '') {
      setValue(ref.current.props.value);
    }
  }, []);

  React.useEffect(()=>{
    if(defaultValue){
        setValue(defaultValue)
    }
  },[defaultValue])

  const choice =
    choices &&
    choices.map((choice, key) => (
      <Option
        key={key}
        value={String(choice.value) === 'false' ? false : choice.value === '' ? '' : choice.value || choice.label}
        disabled={disabledOptions && disabledOptions?.includes(choice.value)?true:false}
      >
        {typeof choice.label === 'string' ? < ReactMarkdown >{choice.label}</ReactMarkdown> : choice.label}
      </Option>
    ));
  const otherField = (
    <Option key="other" value="add_skill">
      <span><PlusOutlined/> add skill</span>
    </Option>
  );
  const handleChange = params => {
      setVisible(false);
      onSelectChange && onSelectChange(params)
  };
  const handleSelect = params => { 
     setValue(params);
     onChangeSelect && onChangeSelect(params);
  };

  const extraLinkProps = newTab
    ? {
        target: '_blank'
      }
    : {};

  return (
    <div style={{ marginBottom: margin ? '2vh' : '' }}>
      <Row gutter={[15, 5]}>
        <Col span={onAdd ? (onEdit ? 18 : 21) : 24}>
          <FormItem
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24, offset: margin && offset }}
            onChange={onSelectChange}
            {...rest}
          >
            <Select disabled={disabled} onChange={handleChange} {...props} ref={ref} onSelect={handleSelect} onSearch={onSearch}
             filterOption={(input,option)=> option}
             style={{ width: '100%' }}
                placeholder="Select a skill"
             >
              {otherOption ? (choices?.length > 0 ? [...choice, otherField] : [otherField]) : choice}
              {/* {choice} */}
            </Select>
          </FormItem>
        </Col>
        {onEdit && (
          <Col lg={2} md={3} style={{ paddingTop: '30px' }}>
            <Button shape="circle" disabled={!value} /* style={{ marginTop: '10px' }} */>
              <Link to={`${onEdit}${value}`}>
                <EditOutlined />
              </Link>
            </Button>
          </Col>
        )}
        {onAdd && (
          <Col lg={2} md={3} style={{ paddingTop: '30px' }}>
            <Button shape="circle" type="primary" ghost /* style={{ marginTop: '10px' }} */>
              <Link to={`${onAdd}`} {...extraLinkProps}>
                <PlusOutlined />
              </Link>
            </Button>
          </Col>
        )}

      </Row>
      {visible && (
        <FormItem labelCol={{ span: 24 }} wrapperCol={{ span: 24, offset }} onChange={onSelectChange} {...rest} label={null}>
          <Input autoComplete='off' onBlur={onBlur} />
        </FormItem>
      )}
    </div>
  );
};

export default SelectFieldReainess;
