import { Button, Dropdown, Form, Menu } from "antd";
import React from "react";
import styled from "styled-components";
import { DownOutlined } from "@ant-design/icons";
import dropdown_Arrow from '../../../assets/dropdown_Arrow.svg'
const NotificationSettingsView = (props) => {
    const [form] = Form.useForm();
    const Okr_norifications = [
        {
            id: 1,
            label: "Set the frequency for the OKR Summary report updates",
            values: [
                {
                    key: 1,
                    value: "meekly",
                    title: 'Weekly'
                },
                {
                    key: 2,
                    value: "monthly",
                    title: 'Monthly'
                },
                {
                    key: 3,
                    value: "yearly",
                    title: 'Yearly'
                }
            ]
        },
        {
            id: 2,
            label: "Get notified when key results meet their timelines",
            values: [
                {
                    key: 1,
                    value: "10",
                    title: '10%'
                },
                {
                    key: 2,
                    value: "20",
                    title: '20%'
                },
                {
                    key: 3,
                    value: "30",
                    title: '30%'
                }
            ]
        },
        {
            id: 3,
            label: "Activate notifications for achieving timelines on chosen milestone",
            values: [
                {
                    key: 1,
                    value: "10",
                    title: '10%'
                },
                {
                    key: 2,
                    value: "20",
                    title: '20%'
                },
                {
                    key: 3,
                    value: "30",
                    title: '30%'
                }
            ]
        },
        {
            id: 4,
            label: "Arrange reminders for achieving timelines on chosen Objective",
            values: [
                {
                    key: 1,
                    value: "10",
                    title: '10%'
                },
                {
                    key: 2,
                    value: "20",
                    title: '20%'
                },
                {
                    key: 3,
                    value: "30",
                    title: '30%'
                }
            ]
        },
        {
            id: 5,
            label: "Arrange a reminder ahead of your OKR due date.",
            values: [
                {
                    key: 1,
                    value: "1",
                    title: '1 Day'
                },
                {
                    key: 2,
                    value: "2",
                    title: '2 Days'
                },
                {
                    key: 3,
                    value: "3",
                    title: '3 Days'
                }
            ]
        }
    ]
    const feedback_notification = [
        {
            id: 1,
            label: 'Set a reminder for giving feedback to someone',
            values: [
                {
                    key: 1,
                    value: "5",
                    title: "5 Days"
                },
                {
                    key: 2,
                    value: "10",
                    title: "10 Days"
                },
                {
                    key: 3,
                    value: "15",
                    title: "15 Days"
                }
            ]
        }
    ]
    const one_on_one_notification = [
        {
            id: 1,
            label: 'Receive a reminder before your 1:1 meeting',
            values: [
                {
                    key: 1,
                    value: "3",
                    title: "3 Days"
                },
                {
                    key: 2,
                    value: "5",
                    title: "5 Days"
                },
                {
                    key: 3,
                    value: "10",
                    title: "10 Days"
                }
            ]
        }
    ]
    const idp_notification = [
        {
            id: 1,
            label: 'Arrange a reminder ahead of your IDP due date.',
            values: [
                {
                    key: 1,
                    value: "3",
                    title: "3 Days"
                },
                {
                    key: 2,
                    value: "5",
                    title: "5 Days"
                },
                {
                    key: 3,
                    value: "10",
                    title: "10 Days"
                }
            ]
        }
    ]

    const handleUpdateSettings =(value)=>{
        console.log("notification settings",value)
    }
    const Filtermenu = (filterList) => {
        return (
            <Menu style={{ maxHeight: '200px', overflow: 'auto' }}>
                {
                    filterList && filterList.map((data) => (
                        <Menu.Item key={data?.key}>
                            {data?.title}
                        </Menu.Item>
                    ))
                }
            </Menu>
        );
    }
    return (
        <div className="profile-notification-root">
            <h4 className="proile-notification-title">Email notification settings</h4>
            <Form
                form={form}
                layout="vertical"
                style={{ width: '100%', fontSize: "1em", fontFamily: 'Poppins' }}
                onFinish={(value) => handleUpdateSettings(value)} >
                <div className="notifcation-list-container">
                    <div className="notifcation-list-left-container">
                        <h4 className="proile-notification-title">OKRs</h4>
                        <p className="notigcation-label" style={{ fontWeight: "500", fontSize: '1em' }}>Choose your preferred notification settings for the following changes.</p>
                        <p className="notigcation-label" style={{ fontWeight: "400" }}><span style={{ fontWeight: "600" }}>Note</span> : This change will only affect newly created OKRs or KRs in the system; it does not apply to existing OKRs.</p>
                    </div>
                    <div className="notifcation-list-right-container">
                        {Okr_norifications?.length > 0 && Okr_norifications?.map((item, index) => (
                            <Form.Item
                                style={{ width: '100%', fontSize: "1em", fontFamily: 'Poppins' }}
                                name={item?.id} >
                                <div className="notification-type-container"
                                    key={index}
                                    style={{ borderBottom: index === Okr_norifications.length - 1 ? '1px solid #CBD5E1' : 'none' }}>
                                    <p className="notigcation-label">{item?.label}</p>
                                    <Dropdown overlay={Filtermenu(item?.values)}>
                                        <DropdownButton >

                                            <p style={{ marginTop: '10px', width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'left' }}>Select</p>
                                            <DownOutlined />

                                        </DropdownButton>
                                    </Dropdown>
                                </div>
                            </Form.Item>
                        ))}

                    </div>
                </div>
                <div className="notifcation-list-container" style={{ alignItems: 'center' }}>
                    <div className="notifcation-list-left-container">
                        <h4 className="proile-notification-title">Feedback</h4>
                        <p className="notigcation-label" style={{ fontWeight: "500", fontSize: '1em' }}>Select when you'll be notified when the following changes occur.</p>
                    </div>
                    <div className="notifcation-list-right-container">
                        {feedback_notification?.length > 0 && feedback_notification?.map((item, index) => (
                            <Form.Item
                                style={{ width: '100%', fontSize: "1em", fontFamily: 'Poppins' }}
                                name={item?.id} >
                                <div className="notification-type-container"
                                    key={index}
                                    style={{ borderBottom: index === feedback_notification.length - 1 ? '1px solid #CBD5E1' : 'none' }}>
                                    <p className="notigcation-label">{item?.label}</p>
                                    <Dropdown overlay={Filtermenu(item?.values)}>
                                        <DropdownButton >

                                            <p style={{ marginTop: '10px', width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'left' }}>Select</p>
                                            <DownOutlined />

                                        </DropdownButton>
                                    </Dropdown>
                                </div>
                            </Form.Item>
                        ))}

                    </div>
                </div>
                <div className="notifcation-list-container" style={{ alignItems: 'center' }}>
                    <div className="notifcation-list-left-container">
                        <h4 className="proile-notification-title">1:1 meeting</h4>
                        <p className="notigcation-label" style={{ fontWeight: "500", fontSize: '1em' }}>Select when you'll be notified when the following changes occur.</p>
                    </div>
                    <div className="notifcation-list-right-container">
                        {one_on_one_notification?.length > 0 && one_on_one_notification?.map((item, index) => (
                            <Form.Item
                                style={{ width: '100%', fontSize: "1em", fontFamily: 'Poppins' }}
                                name={item?.id} >
                                <div className="notification-type-container"
                                    key={index}
                                    style={{ borderBottom: index === one_on_one_notification.length - 1 ? '1px solid #CBD5E1' : 'none' }}>
                                    <p className="notigcation-label">{item?.label}</p>
                                    <Dropdown overlay={Filtermenu(item?.values)}>
                                        <DropdownButton >

                                            <p style={{ marginTop: '10px', width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'left' }}>Select</p>
                                            <DownOutlined />

                                        </DropdownButton>
                                    </Dropdown>
                                </div>
                            </Form.Item>
                        ))}

                    </div>
                </div>
                <div className="notifcation-list-container" style={{ alignItems: 'center' }}>
                    <div className="notifcation-list-left-container">
                        <h4 className="proile-notification-title">IDP</h4>
                        <p className="notigcation-label" style={{ fontWeight: "500", fontSize: '1em' }}>Select when you'll be notified when the following changes occur.</p>
                    </div>
                    <div className="notifcation-list-right-container">
                        {idp_notification?.length > 0 && idp_notification?.map((item, index) => (
                            <Form.Item
                                style={{ width: '100%', fontSize: "1em", fontFamily: 'Poppins' }}
                                name={item?.id} >
                                <div className="notification-type-container"
                                    key={index}
                                    style={{ borderBottom: index === idp_notification.length - 1 ? '1px solid #CBD5E1' : 'none' }}>
                                    <p className="notigcation-label">{item?.label}</p>
                                    <Dropdown overlay={Filtermenu(item?.values)}>
                                        <DropdownButton >

                                            <p style={{ marginTop: '10px', width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'left' }}>Select</p>
                                            <DownOutlined />

                                        </DropdownButton>
                                    </Dropdown>
                                </div>
                            </Form.Item>
                        ))}

                    </div>
                </div>
                    <Form.Item style={{ marginTop: "2em" }}>
                        <div className="change-password-action-button-group" style={{justifyContent:'end'}}>
                            <Button htmlType="submit" className="notification-action-active-button" style={{padding:'0em 1em'}}>
                                SAVE
                            </Button>
                            <Button className="notification-action-cancel-button">
                                CANCEL
                            </Button>
                        </div>
                    </Form.Item>
            </Form>
        </div>
    )
}

export default NotificationSettingsView;
const DropdownButton = styled(Button)`
width:150px;
display:flex;
height:36px;
justify-content:space-between;
align-items:center;
gap:5px
border:1px solid #D9D9D9;
border-radius:5px;
`