import gql from 'graphql-tag';
// import { RESPONSE_TYPE } from './ResponseType.gql';

//   ${RESPONSE_TYPE}
export const ALL_RESPONSES_BY_USER_ID_QUERY = gql`
  query allResponsesByUserId($userId: ID!, $first: Int, $offset: Int, $orderBy: [String], $survey: ID) {
    allResponsesByUserId(userId: $userId, first: $first, offset: $offset, orderBy: $orderBy, survey: $survey) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          # ...ResponseType
          id
          survey {
            id
            name
          }
          responseDate
          responseDuration
        }
      }
      totalCount
      edgeCount
    }
  }
`;
