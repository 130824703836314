import update from 'immutability-helper';

import { PAGINATION_LIMIT } from '../../../config';

import { PAGE_STATE_QUERY } from '../graphql/PageStateQuery.client.gql';

const TYPE_PAGE_STATE = 'PageState';

const TYPE_PAGE_STATE_FILTER = 'PageFilterInput';

const TYPE_PAGE_STATE_PAGINATION = 'PagePaginationInput';

const defaults = {
  pageState: {
    filter: {
      name_Icontains: '',
      email_Icontains: '',
      message_Icontains: '',
      searchText: '',
      __typename: TYPE_PAGE_STATE_FILTER
    },
    orderBy: ['-id'],
    pagination: {
      first: PAGINATION_LIMIT,
      offset: 0,
      __typename: TYPE_PAGE_STATE_PAGINATION
    },
    __typename: TYPE_PAGE_STATE
  }
};

const resolvers = {
  Mutation: {
    updatePageFilter: (_, { filter, orderBy, pagination }, { cache }) => {
      const { pageState } = cache.readQuery({
        query: PAGE_STATE_QUERY
      });
      const newPageState = update(pageState, {
        filter: { $merge: filter },
        orderBy: { $set: orderBy },
        pagination: { $merge: pagination }
      });

      cache.writeData({
        data: {
          pageState: newPageState,
          __type: TYPE_PAGE_STATE
        }
      });

      return null;
    }
  }
};

export default { defaults, resolvers };
