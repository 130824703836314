import gql from "graphql-tag";

export const VERTICAL_AND_TEAN_LIST=gql`
query vertical {
    verticalTeamList:me {
        id
        employee {
          id
            organizationSet {
                edges {
                    node {
                        id
                        verticalSet {
                            totalCount
                            edgeCount
                            edges {
                                node {
                                    id
                                    name
                                    parentvertical{
                                        id
                                    }
                                    heads {
                                        edges {
                                            node {
                                                id
                                                okrContributorSet(savedOkrType: "OBJECTIVE", orderBy: ["okr__duedate"]) {
                                                    edges {
                                                        node {
                                                            id
                                                            progress
                                                            employee{
                                                                id
                                                            }
                                                            createdBy{
                                                                id
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }                                                           
                                    }
                                    subvertical{
                                        totalCount
                                    }
                                    teamSet{
                                        totalCount
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
`