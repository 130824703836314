import gql from "graphql-tag";

export const DOWNLOAD_EMPLOYEE_TOOL_DATA = gql`
  query getToolForReporteeByToolId($employeeId: ID!) {
      getToolForReporteeByToolId(toolId: 6, employeeId: $employeeId) {
   id
    title
    thumbnailImage
    description
    status
    public
    visible
    organization{
      edges{
        node{
          id
        }
      }
    }
    vertical{
      edges{
        node{
          id
        }
      }
    }
    team{
      edges{
        node{
          id
        }
      }
    }
    commitment{
      edges{
        node{
          id
        }
      }
    }
    textsection{
      edges{
        node{
          id
        }
      }
    }
    formsection{
      edges{
        node{
          id
        }
      }
    }
    pubDate
  }
  }
`;
