import { VALUE_SURVEY_ID } from '../../../config'
//do not change the key of below objects if you are changing make sure to change in both objects
export const soul_type ={
   values:"values",
   strengths:"strengths",
   personalities:"personalities",
   knowledge_and_skills:"knowledge_and_skills",
   impact_narrative:"impact-narrative"
}

const homepageContent = {
   values :{
       content:`Values are our personal guiding principles. Our personal values decide what we value and don't. Therefore, they also influence what we think is right or wrong. Our sense of integrity is based on them. Aligning with values will allow us a space where we feel we are doing the right things the right way. Where values are being compromised, we won't be at peace. We may feel disrespected at one end and compromised at the other. Therefore, it's important for us to know our values and be purposeful about them.`,
       info:'If you are aware of your top 5 values, please click Enter Your Values button. Otherwise, go to Take Test.',
       button1:{show:true,content:'Take Test',navigateTo:`/soul/values/survey/${VALUE_SURVEY_ID}`},
       button2:{show:false,content:'Values & Behaviours',navigateTo:'/tool/tool/uiflow/2'},
       button3:{show:true,content:'Edit Values',navigateTo:`/soul/values/${VALUE_SURVEY_ID}`},
       type:'values',
       pageDescription:'Value home page',
       logo:"https://res.cloudinary.com/mapp-brainayan-app/image/upload/q_auto/v1651231365/MApp/UI/Images/Values_vl9kpi.png",
       id:3,
       value_label:'<h4 style="color: #1F1F1F;font-family: Poppins;font-size: 1.4375rem;font-style: normal;font-weight: 500;line-height: normal;text-align: center;">My <span style="color: #1F1F1F;font-family: Poppins;font-size: 1.4375rem;font-style: normal;font-weight: 700;line-height: normal;">Top 5</span> Values</h4>'
    },
    strengths :{
        content:`Strengths are natural gifts we have that help us do some activities better than others without a lot of effort and, at the same time, be energized by the experience. Given these are natural gifts, if we are able to discover and hone them, we can create outsized impact. There's deep research in positive psychology on strengths and how to discover and grow them so we can be more successful. Click on Take Test button below to discover your strengths. You’ll be taken to High5Test. Feel free to use any other strengths discovery inventories available, like StrengthsFinder and StandOut.`,
        info:'If you are aware of your top 5 strengths, please click Edit Your Strengths.',
        button1:{show:true,content:'Take Test',navigateTo:'https://high5test.com/'},
        button2:{show:false,content:'Strengths & Behaviours',navigateTo:'/tool/tool/uiflow/3'},
        button3: { show: true, content: 'Edit Strengths', navigateTo:'/soul/strengths/7'},
        type:'strengths',
        pageDescription:'Strengths home page',
        logo:"https://res.cloudinary.com/mapp-brainayan-app/image/upload/q_auto/v1651232534/MApp/UI/Images/Strengths_yj2zgg.png",
        id:7,
        value_label:'<h4 style="color: #1F1F1F;font-family: Poppins;font-size: 1.4375rem;font-style: normal;font-weight: 500;line-height: normal;text-align: center;">My <span style="color: #1F1F1F;font-family: Poppins;font-size: 1.4375rem;font-style: normal;font-weight: 700;line-height: normal;">Top 5</span> Strengths</h4>'
     },
     personalities :{
      content:`Where do we get our energy from? How do we take in information? How do we make decisions? How do we get things done? Dr Carl Jung's pioneering research into personality demonstrates that we have our preferred ways, and being aware of these preferences helps us know how we choose to behave and why. Awareness of our personality preferences helps us understand our unique style of showing up and determine whether it's serving us well or not. We can then step out of our comfort zone to achieve better results. Personality awareness helps us build trust, better relationships, be more effective and collaborate smoothly. Click on Take Test button below to discover your personality type.`,
      info:'If you know your personality orientations, please click on the Enter Your Personality Orientations button.',
      button1:{show:true,content:'Take Test',navigateTo:'https://www.16personalities.com/free-personality-test'},
      button2:{show:false,content:'Personality & Behaviours',navigateTo:'/tool/tool/uiflow/5'},
      button3: { show: true, content: 'Edit Personalities', navigateTo:'/soul/personality/8'},
      type:'personalities',
      pageDescription:'Personality home page',
      logo:"https://res.cloudinary.com/mapp-brainayan-app/image/upload/q_auto/v1651231359/MApp/UI/Images/Personality_muw0u1.png",
      id:8,
      value_label:'<h4 style="color: #1F1F1F;font-family: Poppins;font-size: 1.4375rem;font-style: normal;font-weight: 500;line-height: normal;text-align: center;">My Personality Type</h4>'
   },
   knowledge_and_skills :{
      content:`Our education, the school we went to, the training we received, who our teachers and peers were, all of these contribute to shaping our sense of identity. We also gather knowledge through informal channels and from our experience that we are able to use to create visible impact at work and otherwise. This sense of identity also evolves as we become increasingly aware of the skills we have and our levels of expertise. Think about how these factors impact your sense of identity and enter top 5 knowledge areas/skills that contribute the most to your identity.`,
      info:'',
      button1: { show: true, content: 'Add Your Knowledge And Skills', navigateTo:'/soul/knowledge-and-skills/9'},
      button2:{show:false,content:'Knowledge, Skills & Behaviours',navigateTo:'/tool/tool/uiflow/4'},
      button3:{show:true,content:'Edit knowledge & skills',navigateTo:'/soul/knowledge-and-skills/9'},
      type:'knowledge_and_skills',
      pageDescription:'KnowledgeAndSkills home page',
      logo:"https://res.cloudinary.com/mapp-brainayan-app/image/upload/q_auto/v1651231420/MApp/UI/Images/Knowledge_and_Skills_tobzam.png",
      id:9,
      value_label:'<h4 style="color: #1F1F1F;font-family: Poppins;font-size: 1.4375rem;font-style: normal;font-weight: 500;line-height: normal;text-align: center;">My <span style="color: #1F1F1F;font-family: Poppins;font-size: 1.4375rem;font-style: normal;font-weight: 700;line-height: normal;">Top 5</span> Knowledge Areas & Skills</h4>'
   }
}

export default homepageContent