import gql from 'graphql-tag';
import { TOOL_TYPE_WITH_EMPLOYEE_ID } from './ToolTypeWithEmployeeId.gql';

export const TOOL_FOR_REPORTEE_BY_TOOLID_QUERY = gql`
${TOOL_TYPE_WITH_EMPLOYEE_ID}
  query getToolForReporteeByToolId($toolId: ID!, $employeeId: ID!) {
    employeeReport: getToolForReporteeByToolId(toolId: $toolId, employeeId:  $employeeId) {
        ...ToolTypeWithEmployeeId
    }
  }
`;
