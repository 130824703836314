
import React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import gql from 'graphql-tag';
import { Empty, Spin } from 'antd';

import { compose } from '../../../core';
import { getIntFromString } from '../functions';
import { withLookSurveyFilterUpdating, withLookSurveyResponseState } from '../../containers/LookOperations';

import SelectField from './SelectField';
import { ALL_RESPONSES_BY_USER_ID_QUERY } from 'modules/survey/graphql/AllResponsesByUserId.gql';
import { withSurveyResponseFilterUpdating } from 'modules/survey/containers/SurveyOperations';

export const ALL_SURVEY_QUERY = gql`
  query allSurveysForTokens($first: Int, $orderBy: [String], $name_Icontains: String) {
    allSurveysForTokens(first: $first, orderBy: $orderBy, name_Icontains: $name_Icontains) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

const SurveyAutoComplete = props => {
  const { loading, allResponsesByUserId, margin, onSurveyNameChange, onFiltersRemove } = props;
  const handleFilterRemove = React.useRef(() => { });

  handleFilterRemove.current = () => {
    onFiltersRemove();
  };


  const [choices, setChoices] = React.useState([]);

  console.log('onSurveyNameChange::',onSurveyNameChange);

  React.useEffect(() => {

    if (allResponsesByUserId) {
      
    


        let resArr = [];
        allResponsesByUserId.edges.filter(function(item){

        let i = resArr.findIndex(x =>

           (x?.node?.survey?.name?.trim()?.toLowerCase() === item?.node?.survey?.name?.trim()?.toLowerCase() )
           );
           
  if(i <= -1){
       resArr.push(item);
  }

   console.log('resArr::',resArr);
  
});

 let choiceSet =
        !loading &&
        resArr &&
        resArr.length > 0 &&
        resArr.map(({ node }) => ({
          label: node.survey.name,
          value: getIntFromString(node.survey.id)
        }));

console.log('resArr:',resArr);

        setChoices(choiceSet);
    }

  }, [allResponsesByUserId])

  React.useEffect(() => {
    return () => handleFilterRemove.current();
  }, []);



  console.log('allResponsesByUserId::', allResponsesByUserId);

  return (
    <SelectField
      margin={margin}
      showSearch
      choices={choices}
      style={{ width: !margin && 200, marginBottom: !margin && 0 }}
      // labelInValue
      filterOption={false}
      onSearch={onSurveyNameChange}
      notFoundContent={
        loading ? (
          <div align="center">
            <Spin size="small" />
          </div>
        ) : (
          <Empty />
        )
      }
      {...props}
    />
  );
};

export default compose(
  withLookSurveyResponseState,
  graphql(ALL_RESPONSES_BY_USER_ID_QUERY, {
    options: ({ parameter,filter, pagination, orderBy}) => {
      console.log('parameter:', parameter);
      return { variables: { userId: parameter,...filter,...pagination,orderBy } };
    },
    props({ data }) {
      const { loading, error, allResponsesByUserId, subscribeToMore, updateQuery } = data;
      return { loading, error, allResponsesByUserId, subscribeToMore, updateQuery };
    }
  }),
  withSurveyResponseFilterUpdating
)(SurveyAutoComplete);

