import React from 'react';
import gql from 'graphql-tag';
import { withApollo } from '@apollo/client/react/hoc';
import { Row, Col, Divider, Empty, Card } from 'antd';
import AddCommitment from '../containers/AddCommitment';
import EditCommitment from '../containers/EditCommitment';
import { ReactMarkdown, getIntFromString, DeleteIcon } from '../../look';
import { compose } from '../../core';
import { COMMITMENT_SUBSCRIPTION } from '../graphql/CommitmentSubscription.gql';

export const ALL_COMMITMENT_QUERY = gql`
  query getAllCommitments($first: Int, $orderBy: [String]) {
    getAllCommitments(first: $first, orderBy: $orderBy) {
      totalCount
    }
  }
`;

const CommitmentSection = props => {
  const { commitment, handleEditTool, setActionLoading, onCommitmentDelete, setToolDetails, toolDetails, client, me} = props;
 

  const [commitmentSet,setCommitmentSet] = React.useState(undefined);

  
  React.useEffect(() => {
   
    if(me){
      getSubscribedToToolCommitment()
    }

  },[me]);

  React.useEffect(() => {
   
    if(commitment){
      setCommitmentSet(commitment)
      toolCommitmentDataRef.current = commitment;
    }

  },[commitment]);


  const toolCommitmentDataRef = React.useRef(null);

  

  const getSubscribedToToolCommitment = async () => {

    const { data } = await client.subscribe({
      query: COMMITMENT_SUBSCRIPTION,
      fetchPolicy: 'network-only',
    }).subscribe({
      next(result) {
       
        switch (result?.data?.toolsCommitmentSubscription?.mutation) {
          
          case 'DELETE':
            let toolInfo = toolDetails;
            let newCommitments;
            
            newCommitments = toolCommitmentDataRef.current?.filter((node)=>
     
            getIntFromString(node?.node?.id) !== getIntFromString(result?.data?.toolsCommitmentSubscription?.commitment?.id)
            
            )

            toolInfo.commitment = {edges:newCommitments}     
             setToolDetails(toolInfo);
             toolCommitmentDataRef.current =  newCommitments ;
             setCommitmentSet({edges:newCommitments} )
            break
          default:
            break
        }
      }
    })
  }



  return (
    <Row>
      <Col lg={12} xs={24} align="left">
        <h2>Commitments</h2>
      </Col>

      <Col lg={12} xs={24} align="right">
        <AddCommitment handleEdit={e => handleEditTool(e, 'commitment')} setSubmitLoading={setActionLoading}  setToolDetails = {setToolDetails} toolDetails = {toolDetails}  />
      </Col>
      <Divider style={{ width: '100%', marginTop: '10px' }} />

      <Col lg={24}>
        { commitmentSet &&  commitmentSet?.edges?.map(({ node }) => (
          <>
            <Card
              bordered={false}
              style={{
                display: 'grid',
                width: '100%',
                boxShadow: '5px 0px 25px 3px rgba(32, 32, 32, 0.1)',
                borderRadius: '10px'
              }}
              // bodyStyle={{ padding: "10px" }}
            >
              <Row>
                <Col span={20} align="left">
                  <h3>{node.title}</h3>
                </Col>

                <Col span={4} align="right">
                  <EditCommitment /* onSubmit={handleEdit} */ setSubmitLoading={setActionLoading} getCommitmentById={node} setToolDetails = {setToolDetails} toolDetails = {toolDetails} />
                  <Divider type="vertical" />
                  <DeleteIcon
                    tooltipSuffix="Commitment"
                    type="link"
                    size="sm"
                    onClick={() => onCommitmentDelete(getIntFromString(node.id))}
                  />
                </Col>
                <Col span={24}>
                  <ReactMarkdown>{node.description}</ReactMarkdown>
                  <br />
                </Col>
              </Row>
            </Card>
            <br />
          </>
        ))}

        {commitmentSet?.edges?.length === 0 && (
          <div align="center">
            <br />
            <br />
            <br />
            <br />
            <Empty />
            <br />
            <br />
            <br />
          </div>
        )}
      </Col>
    </Row>
  );
};

export default compose(withApollo)(CommitmentSection);
