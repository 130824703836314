import gql from "graphql-tag";
import { SUBMITTED_FEEDBACK_TYPE } from "./SubmittedFeedback.gql";

export const SUBMITTED_FEEDBACK_SUBSCRIPTION = gql`
${SUBMITTED_FEEDBACK_TYPE}
subscription feedbackSubscription {
    feedbackSubscription {
        mutation
        feedback {
            ...FeedbackType
        }
    }
}`