import gql from "graphql-tag";


export const ALL_FEEDBACKS = gql`
query allMeetingFeedbacks($meetingHost:ID,$orderBy:[String],$meetingMember:ID){
    allMeetingFeedbacks(meetingHost:$meetingHost,orderBy:$orderBy,meetingMember:$meetingMember){
     edges{
       node{
         id
         suggestions
         hostSkills
         meetingEffectiveness
         anonymous
         createdAt
         member{
          id
          user{
            id
            firstName
            lastName
          }
        }
          }
        }
     }
    }


`;


export const ALL_MEMBERS_OF_MEETING = gql`
query allMeetingFeedbacks($meetingHost:ID,$orderBy:[String]){
    allMeetingFeedbacks(meetingHost:$meetingHost,orderBy:$orderBy){
     edges{
       node{
         id
        
         member{
          id
          user{
            id
            firstName
            lastName
          }
        }
          }
        }
     }
    }


`;


// member {
//   id
//   user{
//     id
//     firstName
//   }
// }



  export const MEETING_FEEDBACK_BY_ID = gql`
  query meetingFeedbackById($id:ID!){
    meetingFeedbackById(id:$id){
      id
      hostSkills
      meetingEffectiveness
      suggestions
      anonymous
      createdAt
      member{
        id
        user{
          id
          firstName
          lastName
        }
      }
    }
    }
`;