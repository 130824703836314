import gql from 'graphql-tag';
import { USER_TYPE } from './UserType.gql';

export const ALL_NONEMPLOYEE_USERS = gql`
  ${USER_TYPE}
  query users($first: Int, $offset: Int, $orderBy: [String], $firstName_Icontains: String) {
    users(first: $first, offset: $offset, orderBy: $orderBy, firstName_Icontains: $firstName_Icontains,employee_Isnull: true) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...UserType
        }
      }
      totalCount
      edgeCount
    }
  }
`;
