import CustomEmployeeFilterView from "modules/poc-dashboard/components/organization/customEmployeeFilterView";
import React from "react";
import '../../css/custom-filter-style.css';
import { TEAM_LIST_VERTICAL_ID, VERTICAL_LIST } from "modules/poc-dashboard/graphql/organizationQuery.gql";
import { compose } from "modules/core";
import { withApollo } from '@apollo/client/react/hoc';
const CustomEmployeeFilterContainer = (props) => {
    const { client, me } = props
    const [verticals, setVerticals] = React.useState()
    const [teams, setTeams] = React.useState([])

    const team_list_ref = React.useRef([])
    const user_types = [
        {
            key: "Employee",
            title: "Employee"
        },
        {
            key: "Vertical Head",
            title: "Vertical Head"
        },
        {
            key: "Manager",
            title: "Team Manager"
        },
        // {
        //     key:"Sub_Vertical_Head",
        //     title:"Sub Vertical Head"
        // },
    ]
    const status_list = [
        {
            key: "active",
            color: "rgba(76, 203, 31, 1)",
            title: "Active"
        },
        {
            key: "inactive",
            color: "rgba(106, 106, 106, 1)",
            title: "Inactive"
        },
        {
            key: "verified",
            color: "rgba(12, 138, 255, 1)",
            title: "Verified"
        },
        {
            key: "un_verified",
            color: "rgba(249, 95, 83, 1)",
            title: "Unverified"
        }
    ]
    React.useEffect(() => {
        if (me) {
            verticalList()
        }

    }, [me])
    React.useEffect(() => {
        if (teams?.length) {
            team_list_ref.current = teams
        }
    }, [teams])
    const verticalList = async () => {
        const { data } = await client.query({
            query: VERTICAL_LIST,
            fetchPolicy: 'network-only'
        });
        if (data.verticals?.edges?.length) {
            setVerticals(data.verticals?.edges?.map(({ node }) => node))
        }
    }
    const getTeamlist = async (filter) => {
        const { data } = await client.query({
            query: TEAM_LIST_VERTICAL_ID,
            variables: {
                vertical: filter?.id
            },
            fetchPolicy: 'network-only'
        });
        if (data.Teams?.edges?.length) {
            let list = [...team_list_ref.current, ...data.Teams?.edges?.map(({ node }) => node)]
            var setObj = new Set();
            let team_list = list.reduce((acc, item) => {
                if (!setObj.has(item?.id)) {
                    setObj.add(item?.id)
                    acc.push(item)
                }
                return acc;
            }, [])
            let values = team_list.filter(item => filter?.id_list?.includes(item?.vertical?.id))
            setTeams(values)
        }
    }
    return (
        <>
            <CustomEmployeeFilterView
                verticals={verticals}
                teams={teams}
                user_types={user_types}
                status_list={status_list}
                getTeamList={(id) => getTeamlist(id)}
                setTeams={setTeams} {...props} />
        </>
    )
}

export default compose(withApollo)(CustomEmployeeFilterContainer);