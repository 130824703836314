import _ from 'lodash';

import { subscribeToQuery } from '../../look/components/functions';

import { STORY_SUBSCRIPTION } from '../graphql/StorySubscription.gql';
import { STORY_COMMENT_SUBSCRIPTION } from '../graphql/StoryCommentSubscription.gql';
import { STORY_TAG_SUBSCRIPTION } from '../graphql/StoryTagSubscription.gql';

export const subscribeToStoryTag = (subscribeToMore, variables) => {
  return subscribeToQuery(
    subscribeToMore,
    {
      document: STORY_TAG_SUBSCRIPTION,
      subscriptionName: 'storyTagSubscription',
      nodeName: 'tag',
      queryName: 'allStoryTags'
    },
    variables
  );
};

export const subscribeToStory = (subscribeToMore, variables) => {
  return subscribeToQuery(
    subscribeToMore,
    {
      document: STORY_SUBSCRIPTION,
      subscriptionName: 'storySubscription',
      nodeName: 'story',
      queryName: 'allStories'
    },
    variables
  );
};

export const subscribeToStoryComment = (subscribeToMore, variables) => {
  return subscribeToQuery(
    subscribeToMore,
    {
      document: STORY_COMMENT_SUBSCRIPTION,
      subscriptionName: 'storyCommentSubscription',
      nodeName: 'comment',
      queryName: 'allCommentsForStoryByStoryId'
    },
    variables
  );
};

export const subscribeToPublishedStory = (subscribeToMore, variables) => {
  return subscribeToQuery(
    subscribeToMore,
    {
      document: STORY_SUBSCRIPTION,
      subscriptionName: 'storySubscription',
      nodeName: 'story',
      queryName: 'publishedStoryById',
      edge: false
    },
    variables
  );
};

export const subscribeToAllPublishedStory = (subscribeToMore, variables) => {
  return subscribeToQuery(
    subscribeToMore,
    {
      document: STORY_SUBSCRIPTION,
      subscriptionName: 'storySubscription',
      nodeName: 'story',
      queryName: 'allPublishedStories',
      shouldSubscrbe: node => _.get(node, 'published')
    },
    variables
  );
};
