import gql from 'graphql-tag';
import { VERTICAL_TYPE } from './VerticalType.gql';

export const ADD_VERTICAL = gql`
  ${VERTICAL_TYPE}
  mutation createVertical($employees: [Int], $verticalData: VerticalInput!) {
    createVertical(employees: $employees, verticalData: $verticalData) {
      vertical {
        ...VerticalType
      }
    }
  }
`;

export const ADD_VERTICAL_FOR_ORG = gql`
  ${VERTICAL_TYPE}
  mutation createVertical($employees: [Int], $verticalData: VerticalInput!) {
    createVertical(employees: $employees, verticalData: $verticalData) {
      vertical {
        ...VerticalType
      }
    }
  }
`;

