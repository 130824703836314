import gql from 'graphql-tag';

export const ACCEPT_COMPARE_REQUEST = gql`
  mutation respondToResponseCompareRequest($accepted: Boolean!, $id: ID!, $message: String, $sharedResponseId: Int!) {
    respondToResponseCompareRequest(accepted: $accepted, id: $id, message: $message, sharedResponseId: $sharedResponseId) {
      compareRequest {
        id
        sharedResponse{
          id
        }
      }
    }
  }
`;
