import gql from 'graphql-tag';

export const USER_STATE_QUERY = gql`
  query userState {
    userState @client {
      pagination {
        first
        offset
      }
      orderBy
      filter {
        username_Icontains
        firstName_Icontains
        lastName_Icontains
        email_Icontains
      }
    }
  }
`;
