import * as React from "react";
import styled from "styled-components";
import { Col, Row } from "antd";

import AboutUs1 from "../../assets/aboutus1.svg";
import { MetaTags } from "../../look";

const AboutPage = () => {
  return (
    <>
      <MetaTags title="About Us" description="This is about us page" />
      <RowContainer>
        <Row justify="center" align="bottom">

        </Row>
        <Row justify="center" align="bottom">
          <Col lg={14} md={14} xs={22}>
            <br />
            <br />
            <h3>
              Founded in 2016 by Dr. Nitin More, Brainayan emerged from a
              passion to empower people tap into their best and create impact in
              their career and organization. Our mission is to unleash
              unrealized potential—whether it is an individual, a team or an
              company.
              <br />
              <br /> As a team, Brainayan brings insights from decades of
              experience working with top Fortune 100 tech companies. Dr. More
              last led Facebook’s Learning and Development, APAC, after setting
              up Business Skills Development team at Google, India.
              <br />
              <br /> Today, Brainayan programs find place in not only Fortune
              500 tech companies but erstwhile while start-up unicorns in industries
              like entertainment and transport in the APAC region.
            </h3>
          </Col>
          <Col lg={0} md={0} xs={2} />
          <Col lg={8} xs={21} align="right">
            <Row justify="center">
              <Col lg={18} xs={10}>
                <img
                  src={AboutUs1}
                  alt="aboutus1"
                  style={{ width: "125%", height: "100%" }}
                />
              </Col>
            </Row>
          </Col>
          <Col lg={0} md={0} xs={3} />
        </Row>
        <br />
        <br />
        <br />
      </RowContainer>
      {/* <div style={{ backgroundColor: "rgba(202, 202, 202, 0.25)" }}>
        <RowContainer>
          <br />
          <br />
          <br />
          <Row justify="center" gutter={48}>
            <Col lg={9} xs={0}>
              <Row justify="center" align="middle">
                <Col span={13} style={{ paddingTop: "50px" }}>
                  <img
                    src={AboutUs2}
                    alt="aboutus2"
                    style={{ width: "100%", height: "100%" }}
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={13} xs={22}>
              <h2>Our Mission</h2>
              <Col lg={24} xs={0}>
                <br />
                <br />
                <br />
              </Col>
              <h3>
                Today’s workplaces are fluid, especially with the pandemic
                forcing a large- scale shift in how we work. Our MApp is the
                only thing that could keep us a float and help us succeed in the
                face of constant changes and highly stressful uncertainties of
                life and work. <br />
                <br />
                So, we at Brainayan, decided to dig into MApp research and
                identified five anchors that enable us to be Mapp and function
                well under stress. We have developed this app to enable you to
                take your MApp to the next level by: <br />
                1. Identifying factors that you use well, so you can be
                intentional about them, and
                <br /> 2. Identifying underused factors that you can strengthen
                using our MApp tools.
              </h3>
            </Col>
            <Col lg={0} xs={24}>
              <Row justify="center" align="middle">
                <Col lg={13} xs={10} style={{ paddingTop: "50px" }}>
                  <img
                    src={AboutUs2}
                    alt="aboutus2"
                    style={{
                      width: "100%",
                      height: "100%" ,
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <br />
          <br />
          <Row justify="center">
            <Col lg={14} md={14} xs={22}>
              <div
                style={{
                  textAlign: "start",
                  height: "35px",
                  fontSize: "36px",
                  color: "#84A100",
                }}
              >
                “
              </div>
              <h2
                style={{
                  textAlign: "center",
                  fontFamily: "Poppins",
                  marginBottom: "0px",
                  color: "#84A100",
                }}
              >
                Turn adversity into opportunity! Happy bouncing forward!
              </h2>
              <div
                style={{
                  textAlign: "end",
                  height: "35px",
                  fontSize: "36px",
                  color: "#84A100",
                }}
              >
                “
              </div>
            </Col>
          </Row>
          <br />
          <br />
          <br />
        </RowContainer>
      </div> */}
    </>
  );
};

export default AboutPage;

const RowContainer = styled.div`
  /* height: 90vh; */
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  background-color: ${(props) => props.backgroundColor};
`;
