import gql from "graphql-tag";

export const TEAM_SURVEY_360_REPORTS = gql`
query me($user: ID,$id: ID) {
    teamMemberReport:me{
        id
        employee{
          id
          organizationSet{
            edges{
              node
              {
                id
                employees(user:$user){
                  edges{
                    node{
                      id
                      user{
                        id
                        firstName
                        responseSet(id:$id){
                          edges{
                            node{
                              id
                              report360
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
  }
`;
