import gql from 'graphql-tag';

export const CREATE_QUALIFICATION_LEVEL = gql`

  mutation createQualifyingLevel($qualifyingLevelData:QualifyingLevelInput!) {
    createQualifyingLevel(qualifyingLevelData: $qualifyingLevelData) {
        qualifyingLevel{
            id
            name
            sequence
        }
    }
  }
`;

export const CREATE_RATING_SCALE = gql`
mutation createRatingScale($ratingScaleData:RatingScaleInput!){
  createRatingScale(ratingScaleData:$ratingScaleData){
    ratingScale{
      id
      name
      sequence
      organization {
        id
        name
      }
    }
  }
}

`;

