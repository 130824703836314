import ReactMarkdown from 'modules/look/components/ReactMarkdown';
import React from 'react';


const FeedbackQuestion =(props)=>{
    
    const {data} = props

    return (
       <>
       {
        data && 
        <h4 className='one-on-one-feedback-form-title' style={{ margin: 0, marginLeft: '17px' }}>
        {data?.required && (
          <span style={{ color: '#ff4d4f', marginBottom: '15px' }}>*</span>
        )}
        <ReactMarkdown >{data?.questionText}</ReactMarkdown>
      </h4>
       }
       
       </>
    )
}

export default FeedbackQuestion;


