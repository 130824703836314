import { compose } from 'modules/core';
import React from 'react';

import { getIntFromString, AdminLayout } from '../../look';
import { withTeamByTeamIdOptimized } from '../containers/OrganizationOperation';
import ROUTE from '../route';

import TeamFormComponent from './TeamFormComponent';

const EditTeamView = props => {

  
  const {Team} = props

  const [teamByTeamId,setTeamByTeamId] = React.useState();


  React.useEffect(()=>{

    if(Team){
      setTeamByTeamId(Team)
    }




  },[Team])
  

  return (
    <>
      <AdminLayout
        table={false}
        active={ROUTE.team}
        title="Edit team"
        backLink={`${ROUTE.teamDetailLink}${props.teamByTeamId ? getIntFromString(props.teamByTeamId.id)
        : getIntFromString(props?.Team?.id)}`}
        FormComponent={(props.teamByTeamId || props?.Team) && <TeamFormComponent teamByTeamId = { props.teamByTeamId ? props.teamByTeamId : teamByTeamId}  {...props} />}
      />
    </>
  );
};

export default compose(withTeamByTeamIdOptimized)(EditTeamView);
