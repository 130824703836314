import React from 'react';

import { compose } from '../../core';
import { getIntFromString, withMarkdownFieldValue } from '../../look';

import { withAddCustomUserCommitment } from './DashboardOperations';
import CustomCommitFormComponentView, { CKEditorFieldName } from '../components/CustomCommitFormComponentView';
import { withMe } from '../../user/containers/UserOperations';

const CustomCommitFormComponent = props => {
  const { me, createCustomUserCommitment, setVisible } = props;

  const handleAdd = async values => {
    try {
      const response = await createCustomUserCommitment({
        customUserCommitmentData: withMarkdownFieldValue(CKEditorFieldName, {
          ...values,
          userId: getIntFromString(me.id)
        })
      });
      response && setVisible(false);
    } catch (err) {
      throw new Error(err.message);
    }
  };

  return <CustomCommitFormComponentView onSubmit={handleAdd} {...props} />;
};

export default compose(withAddCustomUserCommitment, withMe)(CustomCommitFormComponent);
