import gql from 'graphql-tag';
import { TEXT_SECTION_TYPE } from './TextSectionType.gql';

export const DELETE_TEXT_SECTION = gql`
  ${TEXT_SECTION_TYPE}
  mutation deleteTextSection($id: ID!) {
    deleteTextSection(id: $id) {
      textSection {
        ...TextSectionType
      }
    }
  }
`;
