import React, { useState } from 'react';

import { compose } from '../../core';
import { withMe } from '../../user/containers/UserOperations';

import ManagerDashboardView from '../components/MangerDashboardView';

const ManagerDashboard = props => {
  const [submitLoading, setSubmitLoading] = useState(false);

  return <ManagerDashboardView submitLoading={submitLoading} setSubmitLoading={setSubmitLoading} {...props} />;
};

export default compose(withMe)(ManagerDashboard);
