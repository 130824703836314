import gql from 'graphql-tag';

export const SURVEY_PERMISSIONS = gql`
  query me($identifier: String) {
    surveyPermission: me {
      id
      employee { 
        id    
        organizationSet {
          totalCount
          edges {
            node {
              id
              name
              orgSurvey: surveySet(identifier: $identifier) {
                totalCount
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              publicSurvey:surveySet(identifier: $identifier,public:true) {
                totalCount
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
          }
        }
        verticalMembersEmployee {
          totalCount
          edges {
            node {
              id
              name
              surveySet(identifier: $identifier){
                totalCount
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
          }
        }
        teamMembersEmployee{
          totalCount
          edges{
            node{
              id
              name
              surveySet(identifier: $identifier){
                totalCount
                edges{
                  node{
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;