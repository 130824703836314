import gql from 'graphql-tag';
import { SURVEY_TYPE } from './SurveyType.gql';

export const PUBLIC_SURVEY_BY_ID_OPTIMIZED = gql`
  query publicSurveyById($id: ID!) {
    SurveyById(id: $id) {
        id
        name
        description
        termsConditions
        shuffle
        public
        activeFrom
        activeTo
        reportQuery
        multisourceResponse
        groupSet {
            edges {
                cursor
                node {
                    id
                    name
                    label
                    helpText
                    sequence
                    matrix
                    showInResult
                    showAvgInResult       
                    questionSet {
                        edges {
                            cursor
                            node {
                                id
                                questionText
                                required
                                helpText
                                sequence
                                questionAddress
                                required
                                choiceSet {
                                    edges {
                                        cursor
                                        node {
                                            id
                                            label
                                            value
                                            sequence
                                            inputType
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
  }
`;
