import gql from "graphql-tag"

export const SURVEY_PERMISSION_CHECK = gql`
query allSurveys($identifier:String){
    allSurveys(identifier:$identifier){
      totalCount
    }
}
`


export const DIVERSITY_PROFILE_PERMISSION_CHECK = gql`
query allSurveys($identifier:String){
   diversityProfile:allSurveys(identifier:$identifier){
      totalCount
    }
}
`
export const WHOPOSHORO_ASSESSMENT_PERMISSION_CHECK = gql`
query allSurveys($identifier:String){
    whoposhoroAssessment:allSurveys(identifier:$identifier){
      totalCount
    }
}
`
