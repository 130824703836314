import gql from 'graphql-tag';
import { CUSTOM_FORM_SECTION_TYPE_CONNECTION } from './Custom_FormSectionTypeConnection.gql';

export const GET_FORM_SECTION_RESPONSE_BY_ID_QUERY = gql`
  ${CUSTOM_FORM_SECTION_TYPE_CONNECTION}
  query getFormSectionResponseById($formSectionId: ID!) {
    getFormSectionResponseById(formSectionId: $formSectionId) {
      ...Custom_FormSectionTypeConnection
    }
  }
`;
