const baseName = '/tool';
// const commitmentBaseName = '/commitment';
const adminPanelBaseName = '/admin-panel';

const detailBaseName = '/detail';
const addBaseName = '/new';
const editBaseName = '/edit';
const soul = 'soul'
const impact_narrative = "impact-narrative"
module.exports = {
  tool: baseName + baseName + adminPanelBaseName,

  toolDetail: baseName + baseName + detailBaseName + '/admin-panel' + baseName + '/:id',
  toolDetailLink: baseName + baseName + detailBaseName + '/admin-panel' + baseName + '/',

  genericTool: baseName + baseName + '/generic' + '/:id',
  genericToolLink: baseName + baseName + '/generic' + '/',
  genericToolAdmin: baseName + baseName + adminPanelBaseName + '/generic' + '/:id',
  genericToolAdminLink: baseName + baseName + adminPanelBaseName + '/generic' + '/',

  addTool: baseName + baseName + addBaseName,
  // addCommitment: baseName + commitmentBaseName + addBaseName + '/:id',
  // addCommitmentLink: baseName + commitmentBaseName + addBaseName + '/',
  editTool: baseName + baseName + editBaseName + '/:id',
  editToolLink: baseName + baseName + editBaseName + '/',
  uiFlowEditTool: baseName + baseName + '/uiflow/:id',
  impact_narrative:`/${soul}/${impact_narrative}`,
  wholeTool: '/whole' + '/:id',
};
