import gql from 'graphql-tag';
export const ALL_EMPLOYEE_LIST = gql`
query me{
    me{
        id
        employee {
            id
            orgPocEmployee {
            edges {
                node {
                id
                name
                employees {
                    edges {
                    node {
                        id
                        user{
                        id
                        firstName
                        lastName
                        }
                    }
                    }
                }
                }
            }
            }
            orgCeoEmployee {
            edges {
                node {
                id
                name
                employees {
                    edges {
                    node {
                        id
                        user{
                            id
                        firstName
                        lastName
                        }
                    }
                    }
                }
                }
            }
            }
            teamManagerEmployee {
            edges {
                node {
                id
                name
                employees {
                    edges {
                    node {
                        id
                        user{
                            id
                        firstName
                        lastName
                        }
                    }
                    }
                }
                }
            }
            }
            verticalHeadEmployee {
            edges {
                node {
                id
                name
                employees {
                    edges {
                    node {
                        id
                        user{
                            id
                        firstName
                        lastName
                        }
                    }
                    }
                }
                }
            }
        }
      }
    }
}
  
`