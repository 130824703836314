import gql from 'graphql-tag';


export const CREATE_ACTIONITEM_MUTATION = gql`
  mutation createActionItem($actionItemData: ActionItemInput!) {
    createActionItem(
      actionItemData:$actionItemData
        ) {
        actionItem {
         id
      }
    }
  }
`;
