import gql from 'graphql-tag';

export const ALL_OKR_LIST = gql`
query contributors( $okr_Goalstatement_Icontains:String,$employee:ID){
  Owner:contributors(employee: $employee, role: "OWNER",okr_Goalstatement_Icontains:$okr_Goalstatement_Icontains) {
      edges {
        node {
          id
          role        
          okr {
          id     
          title 
          okrType
          createdAt
          owner{
            id
            user{
              id
            }
          }      
         }
        }
      }
  }
  Assigned:contributors(employee: $employee, role: "ASSIGNED",okr_Goalstatement_Icontains:$okr_Goalstatement_Icontains) {
    edges {
      node {
        id
        role        
        okr {
        id     
        title  
        okrType  
        createdAt
        owner{
          id
          user{
            id
          }
        }   
       }
      }
    }
  }
  Collaborator:contributors(employee: $employee, role: "COLLABORATOR",okr_Goalstatement_Icontains:$okr_Goalstatement_Icontains) {
    edges {
      node {
        id
        role        
        okr {
        id     
        title  
        okrType
        owner{
          id
          user{
            id
          }
        }     
       }
      }
    }
  }
}
`

export const ALL_MEETING_LIST = gql`
query allMeetings($member_User_In: [ID],$title_Icontains:String,$owner:ID){
    allMeetingsForOwner:allMeetings(title_Icontains: $title_Icontains,owner:$owner) {
        edges {
          node {
            id
            title
            createdAt
            owner{
              id
              user{
                id
                firstName
              }
            }
          }
        }
    }
    allMeetingsForMember:allMeetings(title_Icontains: $title_Icontains,member_User_In:$member_User_In) {
      edges {
        node {
          id
          title
          createdAt
          owner{
            id
            user{
              id
              firstName
            }
          }
        }
      }
  }
}
`

export const ALL_FEEDBACK_LIST = gql`
query allFeedback($feedbackFrom: ID,$feedbackTo: ID,$feedbackTo_User_FirstName_Icontains:String,$feedbackFrom_User_FirstName_Icontains:String){
    FeedbackToFirstName:allFeedback(feedbackTo_User_FirstName_Icontains:$feedbackTo_User_FirstName_Icontains,feedbackTo:$feedbackTo) {
        edges {
          node {
            id
            feedbackType
            feedbackTo{
              id
              user{
                  id
                  firstName
                  lastName
              }
            }
            feedbackFrom{
              id
              user{
                id
                firstName
                lastName
              }
            }
            createdAt
          }
        }
    }
    FeedbackFromFirstName:allFeedback(feedbackFrom_User_FirstName_Icontains:$feedbackFrom_User_FirstName_Icontains,feedbackFrom:$feedbackFrom) {
        edges {
          node {
            id
            feedbackType
            feedbackTo{
            id
            user{
                id
                firstName
                lastName
            }
            }
            feedbackFrom{
              id
              user{
                id
                firstName
                lastName
              }
            }
            createdAt
          }
        }
    }
}
`