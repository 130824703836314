import gql from 'graphql-tag';
import { USER_NODE } from './UserNode.gql';

export const USER_QUERY = gql`
  ${USER_NODE}
  query user($id: ID!) {
    user(id: $id) {
      ...UserNode
    }
  }
`;
