import React from 'react';
import gql from 'graphql-tag';
import { Spin } from 'antd';
import { graphql } from '@apollo/client/react/hoc';

import ROUTE from '../../../organization/route';
import SelectField from './SelectField';
import { getIntFromString } from '../functions';

const VERTICALS_QUERY = gql`
  query verticals {
    verticals {
      edges {
        cursor
        node {
          id
          name
          organization {
            id
            name
          }
        }
      }
    }
  }
`;

const VerticalField = props => {
  const { loading, verticals, onAdd, onEdit } = props;

  const choices =
    !loading &&
    verticals &&
    verticals.edges.length > 0 &&
    verticals.edges.map(({ node }) => ({
      label: node.organization ? node.organization.name + ' | ' + node.name : node.name || '',
      value: Number(getIntFromString(node.id))
    }));

  return (
    <Spin spinning={loading} size="small">
      {!loading && (
        <SelectField
          {...props}
          onAdd={onAdd && ROUTE.addVertical}
          onEdit={onEdit && ROUTE.editVerticalLink}
          choices={!loading && verticals ? choices : []}
        />
      )}
    </Spin>
  );
};

export default graphql(VERTICALS_QUERY, {
  options: props => {
    return {
      variables: {
        first: 6
      }
    };
  },
  props({ data: { loading, error, fetchMore, verticals, subscribeToMore, updateQuery } }, history) {
    return { loading, error, verticals, subscribeToMore, updateQuery };
  }
})(VerticalField);
