import { withApollo } from '@apollo/client/react/hoc';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { compose } from '../../core';
import EditFeedbackView from '../components/EditFeedbackView';
import { withFeedBackSurveyByid } from './feedbackOperations';

import { withDeleteBadgeAwardForFeedback } from 'modules/kudos-user/container/badgeAwardOperationFeedback';


const FeedbackEditContainer = props => {
  const { feedbackById } = props
  const history = useHistory();
  
  
  

  return (
    <>
    <EditFeedbackView {...props} 
    history={history}
    feedbackById = {feedbackById}
    {...props}
    ></EditFeedbackView>
    </>
  );
};

export default compose(withApollo,withFeedBackSurveyByid,withDeleteBadgeAwardForFeedback)(FeedbackEditContainer);
