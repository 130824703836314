export default [
  {
    value: 'statistics.mean(data1)',
    label: 'arithmetic mean'
  },
  {
    value: 'statistics.fmean(data1)',
    label: 'Fast, floating point arithmetic mean.'
  },
  {
    value: 'statistics.geometric_mean(data1)',
    label: 'Geometric mean of data.'
  },
  {
    value: 'statistics.harmonic_mean(data1)',
    label: 'Harmonic mean of data.'
  },
  {
    value: 'statistics.median(data1)',
    label: 'Median (middle value) of data.'
  },
  {
    value: 'statistics.median_low(data1)',
    label: 'Low median of data.'
  },
  {
    value: 'statistics.median_high(data1)',
    label: 'High median of data.'
  },
  {
    value: 'statistics.median_grouped(data1)',
    label: 'Median, or 50th percentile, of grouped data.'
  },
  {
    value: 'statistics.mode(data1)',
    label: 'Single mode (most common value) of discrete or nominal data.'
  },
  {
    value: 'statistics.multimode(data1)',
    label: 'List of modes (most common values) of discrete or nominal data.'
  },
  {
    value: 'statistics.pstdev(data1)',
    label: 'Population standard deviation of data.'
  },
  {
    value: 'statistics.pvariance(data1)',
    label: 'Population variance of data.'
  },
  {
    value: 'statistics.stdev(data1)',
    label: 'Sample standard deviation of data.'
  },
  {
    value: 'statistics.variance(data1)',
    label: 'Sample variance of data.'
  },
  {
    value: 'Operand.percentage(self.weightedQuestions, user)',
    label: 'Percentage'
  },
  {
    value: 'min(data1)',
    label: 'Min'
  },
  {
    value: 'max(data1)',
    label: 'Max'
  },
  {
    value: 'Operand.value(data1)',
    label: 'value of (for single question)'
  },
  {
    value: 'Operand.values(data1)',
    label: 'values of (for multiple questions)'
  },
  {
    value: 'sorted(data1)[-2]',
    label: '2nd last min'
  }
];
