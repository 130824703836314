import ROUTE from './route';
import UserManualHomeContainer from './containers/user-manuel-home-container'
import SurveyQuiz from 'modules/survey/containers/SurveyQuiz';
export default [
  {
    name: 'User Manual lHome',
    path: ROUTE.home,
    component: UserManualHomeContainer,
    exact: true,
    protect: true
  },
  {
    name: 'User manual Survey',
    path: ROUTE.surveyQuizUserManual,
    component: SurveyQuiz,
    protect: true,
    exact:true
  }
];
