import React from 'react';
import { compose } from '../../core';
import NotificationViewAll from '../components/NotificationAllView';
import { withApollo } from '@apollo/client/react/hoc';
import { NOTIFICATION_LIST } from '../graphql/NotificationList.gql';
import { NOTIFICATION_SUBSCRIPTION } from '../graphql/NotificationSubscription.gql';
import { withReadNotification } from './NotificationOperation';
import { FEEDBACK_BY_ID } from 'modules/feedback/graphql/FeedbackById.gql';
import { globalPermissionValidator } from 'modules/look';
import { notification_permissions } from 'Permissions/notification.permission';

const NotificationViewAllContainer = props => {
  const { client, me,userPermission } = props
  const [notifications, setnotifications] = React.useState(undefined)
  const [loading, setLoading] = React.useState(true)
  const [readNotifications,setReadNotification] = React.useState(undefined);
  const [paginationCount, setPaginationCount] = React.useState(0);
  const [recordCount, setrecordCount] = React.useState(20);
  const [feedbackBackDetailsLoading, setFeedbackDetailsLoading] = React.useState(false)
  const [feedbackDetails,setFeedbackDetails] = React.useState(undefined);
  let notificationSubscription = undefined
  const [permission, setpermission] = React.useState()

  React.useEffect(() => {
    if (userPermission?.length) {
      let permission = globalPermissionValidator(notification_permissions, userPermission)
      console.log("permission",permission)
      if(permission?.required_permission){
        getNotificationList({
          orderBy: ["-actionTime"],
          recipient: me?.id,
          offset: paginationCount * recordCount,
          first: recordCount
        },true)
  
        // getReadNotifications()
      }
      
      setpermission(permission)
    }
    else {
      setpermission(null)
    }
  }, [userPermission])

  React.useEffect(() => {
    return () => {
      if (notificationSubscription) {
        notificationSubscription.unsubscribe();
      }
    }
  })


  const dataRef = React.useRef({});


  const getReadNotifications =async()=>{
    try{
    const { data } = await client.query({
          query: NOTIFICATION_LIST,
          variables:{
            orderBy:["-actionTime"],
            readTime_Isnull:true,
            recipient:me?.id
          },
          fetchPolicy: 'network-only'
      });
      if(data?.notifications){
        setReadNotification(data?.notifications?.totalCount)
      }
    }
    catch(error){
      setLoading(false)
    }
  }


  const getNotificationList = async (filterData,callSubscription) => {
    try{
    const { data } = await client.query({
      query: NOTIFICATION_LIST,
      variables: filterData,
      fetchPolicy: 'network-only'
    });
    if (data?.notifications) {
      setReadNotification(data?.notifications?.totalCount)
     
      setLoading(false)

      let notificationsData;
      if (notifications) {

        notificationsData = {
          totalCount: data?.notifications?.totalCount,
          edgeCount: data?.notifications?.edgeCount + notifications?.edgeCount,
          edges: [...notifications?.edges,...data?.notifications?.edges]
        }
      } else {
        notificationsData = {
          totalCount: data?.notifications?.totalCount,
          edgeCount: data?.notifications?.edgeCount,
          edges: data?.notifications?.edges
        }
      }

      dataRef.current = notificationsData

      setnotifications(notificationsData)
      if(callSubscription){
        subscribeToMore()
      }
      
     

    }
  }
  catch(error){
    setLoading(false)
  }
  }

  const subscribeToMore = async () => {
   
    if (!notificationSubscription) {

      notificationSubscription = client.subscribe({
        query: NOTIFICATION_SUBSCRIPTION,
        // variables: filterData
      }).subscribe({
        next(result) {
       
          if (result?.data?.notificationSubscription) {
            switch (result?.data?.notificationSubscription?.mutation) {
              case "CREATE":
                let list = [];
                list = dataRef.current?.edges;
                list = [{node:result?.data?.notificationSubscription?.notification}, ...list];
                let newSetOfNotifications = {...dataRef.current,edges:list}
                setnotifications(newSetOfNotifications);
                dataRef.current = newSetOfNotifications
                setReadNotification(readNotifications + 1)
                break;
              case "UPDATE":
                setLoading(true)

                if (result?.data?.notificationSubscription?.notification?.id) {

                  let list = [];
                  list = [].concat(dataRef.current?.edges)
 
                  let index = list.findIndex((value)=>value.node?.id === result?.data?.notificationSubscription?.notification?.id)
      
                  if (index > -1) {
                    let record = dataRef.current?.edges[index];
                   
                    if(record){
                      record.node.readTime = new Date().toISOString();
                      setReadNotification(readNotifications - 1)
                    }
                    list[index] = record
                    setnotifications({...dataRef.current,edges:list})
                  }
                  setLoading(false)
                } else {
                  setLoading(false)
                }

                 break;
              case "DELETE":
                
                  let filteredNotifications = dataRef.current?.edges?.filter((notification)=>
                  notification?.node?.id !== result?.data?.notificationSubscription?.notification?.id)
                  setnotifications({...dataRef.current,edges:filteredNotifications})
              default:
                break;
            }


          }
        }
      })
    }

  }


  const getFeedbackDetails = async (id) => {
    setFeedbackDetailsLoading(true)

    try {
      const { data } = await client.query({
        query: FEEDBACK_BY_ID,
        variables: { id: id },
        fetchPolicy: 'network-only'
      });

      if (data?.feedbackById) {
        setFeedbackDetails(data?.feedbackById)
      }
    } catch (e) {
      console.error(e)
    }
    setFeedbackDetailsLoading(false)
  }


  return (
    <>
      <NotificationViewAll {...props} paginationCount={paginationCount} recordCount={recordCount}
        setrecordCount={setrecordCount}
        loading={loading}
        setLoading={setLoading}
        getFeedbackDetails = {getFeedbackDetails}
        setPaginationCount={setPaginationCount}
        notifications={notifications}
        feedbackDetails = {feedbackDetails}
        feedbackBackDetailsLoading = {feedbackBackDetailsLoading}
        getReadNotifications = {getReadNotifications}
        readNotifications = {readNotifications}
        onfilter={(e) => getNotificationList(e)} />
    </>
  );
}

export default compose(withApollo, withReadNotification)(NotificationViewAllContainer);

