// @ts-nocheck
import React from 'react';
import { compose } from '../../core';
import { withApollo } from '@apollo/client/react/hoc';
import MeetingView from '../components/MeetingView';
import { MEETING_BY_ID } from '../graphql/MeetingByid.gql';
import { Button, Spin, Tooltip } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { withDeleteMeeting, withUpdateActionItems } from './One_on_oneOperations';
import { getMeetingTypeBase64StringFromInt, getIntFromString, globalPermissionValidator } from "../../look";
import { ONEONONE_HOME_TOOL_ID } from '../../../config'
import { useHistory } from 'react-router-dom';
import { MEETING_RATING } from '../graphql/meetingRating.gql';
import { oneonone_permission } from 'Permissions/oneonone.permission';
import NoPermissionView from 'modules/kudos-user/component/NoPermissionComponent';
import one_on_one_route from '../route/index'
const MeetingDetailContainer = props => {
  const { client, me, deleteMeeting, location, userPermission } = props
  const history = useHistory()
  const [meetingById, setMeeting] = React.useState()
  const [loading, setloading] = React.useState()
  const [meetinglist, setMeetingList] = React.useState([])
  const [currentMeetingIndex, setcurrentMeetingIndex] = React.useState()
  const [nextMeetings, setnextMeetings] = React.useState([])
  const [notes, setnote] = React.useState()
  const [index, setIndex] = React.useState()
  const [feedbackLoading, setFeedbackLoading] = React.useState(false)
  const [meetingFeedback, setMeetingFeedBack] = React.useState()
  const [permission, setpermission] = React.useState()
  const [ai_trigger,SetAITrigger] = React.useState()
  
  React.useEffect(() => {
    if (userPermission?.length) {
      let permission = globalPermissionValidator(oneonone_permission, userPermission)
      setpermission(permission)
    }
    else {
      setpermission(null)
    }
  }, [userPermission])

  const getMeetingDetail = async (value) => {
    setloading(true)
    let id = value;
    const { data } = await client.query({
      query: MEETING_BY_ID,
      variables: { id: id },
      fetchPolicy: 'network-only'
    });
    if (data?.meetingById) {
      if (!meetingById && data?.meetingById?.nextMeeting?.edges?.length) {
        let nextMeetinglist = data?.meetingById?.nextMeeting?.edges?.map(({ node }) => node.id)
        setnextMeetings(nextMeetinglist)

      }
      setMeeting(data?.meetingById)
    }
    else {
      setloading(false)
      history.goBack()
    }
  }

  React.useEffect(() => {
    if (meetingById) {
      setloading(false)
      let meetings = [].concat(nextMeetings)
      if (!meetinglist?.find(item => item === meetingById?.id)) {
        meetings = [meetingById.id].concat(meetings)
      }
      if (meetingById?.previousMeeting && !meetinglist?.find(item => item === meetingById?.previousMeeting?.id)) {
        meetings = [meetingById?.previousMeeting?.id].concat(meetings)
      }
      let uniqueMeetings = [...new Set(meetings)];
      let index = uniqueMeetings.indexOf(meetingById?.id)
      let noteslist = meetingById?.meetingnotesSet?.edges?.map(({ node }) => node)
      // let note = noteslist?.find(item=>item?.employee.id===me?.employee?.id)
      let note = noteslist
      setnote(note)
      setMeetingList(uniqueMeetings)
      setnextMeetings(uniqueMeetings)
      setcurrentMeetingIndex(index)
    }
  }, [meetingById])

  async function DeleteMeeting(id) {
    setloading(true)
    try {

      setloading(true)
      const response = await deleteMeeting({ id: getIntFromString(id) });
      if (response) {
        setloading(false)
 
        if (meetinglist.length > 1) {
          setloading(true)
          let id = meetinglist[currentMeetingIndex - 1]
          let query = location?.search
          let data = query.split('=')
          history.push(`${one_on_one_route?.viewMeeting?.replace(":meetingid",getIntFromString(id))}?tab=${data[1]}`)
          window.location.reload(false);
        }
        else {
          history.goBack()
        }
      }
      else {
        setloading(false)
      }

    }
    catch {
      setloading(false)
    }
  }

  const getMeetingFeedBack = async (value) => {
    setFeedbackLoading(true)
    let id = value;
    const { data } = await client.query({
      query: MEETING_RATING,
      variables: { id: id },
      fetchPolicy: 'network-only'
    });
    if (data?.meetingById) {
      setMeetingFeedBack(data?.meetingById?.meetingfeedbackSet)
      setFeedbackLoading(false)
    }
    else {
      setFeedbackLoading(false)
    }
  }

  React.useEffect(() => {
    if (permission?.requried_permission) {
      let id = getMeetingTypeBase64StringFromInt(props?.match?.params?.meetingid)
      getMeetingDetail(id)
      getMeetingFeedBack(id)
    }
  }, [permission])

  React.useEffect(() => {
    if (currentMeetingIndex === meetinglist?.length - 1) {
      setIndex(true)
    }
    else {
      setIndex(false)
    }
  }, [currentMeetingIndex, meetinglist])
  return (
    <>

      {
        (permission && permission?.requried_permission) && (
          <MeetingView {...props}
            remove={(e) => DeleteMeeting(e)}
            selfNotes={notes}
            currentMeetingIndex={currentMeetingIndex}
            meetinglist={meetinglist}
            meetingById={meetingById}
            index={index}
            getMeetingDetail={(e) => { getMeetingDetail(e); getMeetingFeedBack(e) }}
            feedbackLoading={feedbackLoading}
            meetingFeedback={meetingFeedback}
            permission={permission}
            ai_trigger={ai_trigger}
          SetAITrigger={SetAITrigger}
          />
        )
      }

      {
        (permission && !permission?.requried_permission) && (
          <NoPermissionView />
        )
      }
    </>


  )

};

export default compose(withApollo, withDeleteMeeting,withUpdateActionItems)(MeetingDetailContainer);
