import { compose } from 'modules/core'
import React from 'react'
import EditReadinessView from '../components/EditReadinessView'
import { withApollo } from '@apollo/client/react/hoc';
import { withUpdateReadiness, withSurvayById, withCreateReadiness, withCreateReadinessLevel, withDeleteReadinessLevel } from '../containers/ReadinessOperations'
import { SKILLS_SET } from '../graphql/SkillList.gql';
import { conditionCheck, getReadinessBase64StringFromInt, globalPermissionValidator } from 'modules/look';
import { readiness_permission } from 'Permissions/readiness.permission';
import NoPermissionView from 'modules/kudos-user/component/NoPermissionComponent';
import { READINESS_DETAIL } from '../graphql/ReadinessDetail.gql';
import { readiness_color_zone } from '../config/readiness-color.config';

const EditReadinessComponent = (props) => {
    const { client, userPermission } = props
    const [SkillList, setSkillSet] = React.useState();
    const [permission, setpermission] = React.useState()
    const [readinessById, setreadinessById] = React.useState()

    React.useEffect(() => {
        if (userPermission?.length) {
            let permission = globalPermissionValidator(readiness_permission, userPermission)
            if (permission?.required_permission) {
                getReadinessDetail()
                getSkills({})
            }
            setpermission(permission)
        }
    }, [userPermission])

    const getSkills = async (filter) => {
        const { data } = await client.query({
            query: SKILLS_SET,
            variables: filter,
        });

        if (data?.allCompetencies) {
            setSkillSet(data?.allCompetencies)
        }
    }

    const getReadinessDetail = async () => {
        let id = getReadinessBase64StringFromInt(props?.match?.params?.readid);
        const { data } = await client.query({
            query: READINESS_DETAIL,
            fetchPolicy: 'network-only',
            variables: { id },
        });

        if (data?.readinessById) {
            handleReadinessStatus(data?.readinessById)
        }
    }

    const handleReadinessStatus = (data) => {

       data.readinesslevelSet.edges.forEach(({ node }) => {
            let response_set = node?.response?.answerSet?.edges?.map(an => an?.node)?.map(s => {
                return {
                    question: s?.question?.id,
                    answer: parseInt(s?.answer)
                }
            })
            let rule_validation = {}
            readiness_color_zone.forEach(z => {
                rule_validation[z.zone_id] = {}
                response_set.forEach(rs => {
                    rule_validation[z.zone_id][rs.question] = true
                    let question_rule = z.rule?.find(rl => rl?.question_id == rs.question)
                    if (question_rule) {
                        question_rule.conditions.forEach(c => {
                            if (!conditionCheck(rs.answer, c.value, c.operation)) {
                                rule_validation[z.zone_id][rs.question] = false
                            }
                        })
                    } else {
                        rule_validation[z.zone_id][rs.question] = false
                    }
                })
            })
            let zone_enteries = Object.entries(rule_validation).find(rv => Object.values(rv[1])?.every(item => item === true))
            let zone
            if (zone_enteries) {
                zone = readiness_color_zone?.find(z => z?.zone_id == zone_enteries[0])
            }

            node["zone"] = zone
        })
        console.log('answer_set',data);
        setreadinessById(data)
    }

    return (
        <>
            {
                (permission && permission?.required_permission) && (
                    <EditReadinessView
                        {...props}
                        withSkillList={SkillList}
                        onSkillSearch={(e) => { getSkills(e) }}
                        readinessById={readinessById}
                        permission={permission}
                    />
                )
            }

            {
                (permission && !permission?.required_permission) && (
                    <NoPermissionView />
                )
            }

        </>
    );
}

export default compose( withUpdateReadiness, withSurvayById, withApollo, withCreateReadiness, withCreateReadinessLevel, withDeleteReadinessLevel)(EditReadinessComponent)