import gql from 'graphql-tag';
import { RESPONSE_TYPE } from './ResponseType.gql';

export const CREATE_RESPONSE_MUTATION = gql`
  ${RESPONSE_TYPE}
  mutation createResponse($answers: [AnswerInput]!, $surveyId: Int!, $totalDuration: Int) {
    createResponse(answers: $answers, surveyId: $surveyId, totalDuration: $totalDuration) {
      response {
        ...ResponseType
      }
    }
  }
`;
