import gql from 'graphql-tag';
import { COMMITMENT_TYPE } from './CommitmentType.gql';

export const ADD_COMMITMENT = gql`
  ${COMMITMENT_TYPE}
  mutation createCommitment($commitmentData: CommitmentInput!, $condition: [Int]) {
    createCommitment(commitmentData: $commitmentData, condition: $condition) {
      commitment {
        ...CommitmentType
      }
    }
  }
`;
