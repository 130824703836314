import { Button, Select } from "antd";
import React from "react";
import { ca_detail_icons } from "../icon";
import { getIntFromString } from "modules/look";
import { useHistory } from "react-router-dom";
import ca_route from '../route/index'
const CompetencyModalView =(props)=>{
    const {competecny_list,getCompetecyList,onCancel}=props
    const [selected_competecny,setSelectedCompetecy]=React.useState()
    const { Option } = Select;
    const history = useHistory()
    return (
        <>
        <div className="ca-root">
            <div className="ca-section-container" style={{marginTop:".5em"}}>
                <h4 className="ca-section-modal-heading">View by Competency</h4>
                <Select
                    className="ca-select-filed ca-type-select"
                    style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", width: "100%" }}
                    suffixIcon={<img src={ca_detail_icons?.dropdown_Arrow} alt="" style={{ width: "10px", height: "10px" }} />}
                    showSearch
                    onSearch={(e) => {getCompetecyList({title_Icontains:e}) }}
                    filterOption={false}
                    placeholder='Search by name'
                    onSelect={(e)=>{setSelectedCompetecy(e)}}
                    
                    {...props}
                >
                    {
                        competecny_list.map(item => (
                            <Option
                                className='ca-select-option'
                                key={item?.id}
                                value={item?.id}
                            >
                                {item?.title}
                            </Option>
                        ))
                    }

                </Select>
                <div className='rd-confirmation-action-container'>
                <Button className='ca-cancel-btn' onClick={() => onCancel( false )}>Cancel</Button>
                <Button className='ca-save-btn' disabled={!selected_competecny} onClick={()=>history.push(`${ca_route?.competecny_assessment_detail?.replace(":id",getIntFromString(selected_competecny))}`)}>continue </Button>
            </div>
            </div>
        </div>
        </>
    )
}

export default CompetencyModalView