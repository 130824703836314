import React from 'react';
import IDPApproveView from '../components/idpApproveView';


const IDPApproveContainer =(props)=>{
    return (
        <>
        <IDPApproveView {...props}/>
        </>
    )
}

export default IDPApproveContainer