import gql from 'graphql-tag';
import { USER_TYPE } from './UserType.gql';

export const LIST_ALL_USERS_QUERY = gql`
  ${USER_TYPE}
  query listAllUsers(
    $first: Int
    $offset: Int
    $orderBy: [String]
    $username_Icontains: String
    $firstName_Icontains: String
    $lastName_Icontains: String
    $email_Icontains: String
  ) {
    listAllUsers(
      first: $first
      offset: $offset
      orderBy: $orderBy
      username_Icontains: $username_Icontains
      firstName_Icontains: $firstName_Icontains
      lastName_Icontains: $lastName_Icontains
      email_Icontains: $email_Icontains
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...UserType
        }
      }
      totalCount
      edgeCount
    }
  }
`;

export const LIST_USERS_BY_EMAIL = gql`
  query listAllUsers(
    $email: String
  ) {
    listAllUsers(
      first: 1
      email: $email
    ) {
      edges {
        node {
          id
          email
          employee{
            id
          }
        }
      }
    }
  }
`;


export const LIST_ALL_USERS_ADMIN = gql`
  query users(
    $first: Int
    $after: String
    $username_Icontains: String
    $status_Verified: Boolean
    $email_Icontains: String
  ) {
    users(
      first: $first
      after: $after
      username_Icontains: $username_Icontains
      status_Verified: $status_Verified
      email_Icontains: $email_Icontains
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        cursor
        node {
          id
          email
          username
          verified
          firstName
          lastName
          employee{
            id
            organizationSet{
              totalCount
            }
          }
        }
      }
    }
  }
`;