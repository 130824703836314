import React, { useState } from 'react';
import styled from 'styled-components';
import { Col, Card, Row, Progress, Button, Spin, Empty, Collapse, Space, Tooltip } from 'antd';
import { DoubleRightOutlined } from '@ant-design/icons';

import { compose, getItem } from '../../core';
import { RECENT_TOKEN } from '../../../config';

import { withMe } from '../../user/containers/UserOperations';
import RecentToolComponent from './RecentToolComponent';

const RecentTools = props => {
  const { me } = props;
  // const recentToken = JSON.parse(getItem(RECENT_TOKEN));
  const [visible, setVisible] = useState(false);
  const isEmployee = me?.employee;
  const [recentToken, setRecentToken] = useState(false);
  const handleLoggedIn = React.useRef(() => {});

  handleLoggedIn.current = async () => {
    getItem(RECENT_TOKEN).then(getrecentToken => {
      if (getrecentToken!=undefined) setRecentToken(JSON.parse(getrecentToken));
    })
    
  };

  React.useEffect(() => {
    handleLoggedIn.current();
  }, []);

  return (
    <Col lg={8} md={12} sm={12} xs={24}>
      {/* <Tooltip title="This feature is comming soon!"> */}
      <Card
        style={{ display: 'grid', height: '100%' }}
        bodyStyle={{
          boxShadow: '5px 0px 25px 3px rgba(32, 32, 32, 0.1)',
          borderRadius: '2%'
          // paddingBottom: 0
        }}
        bordered={false}
      >
        <Spin spinning={false}>
          <Row gutter={24} /* align="middle" */>
            <Col span={24}>
              <div align="center">
                <Row align="middle">
                  <Col span={24}>
                    <h2 style={{ fontWeight: 'normal' }}>
                      My Recent
                      <br />
                      Tools
                    </h2>
                  </Col>
                </Row>

                <Col span={20} >
                  <Row justify="center" align="middle">
                    {isEmployee && recentToken?.length > 0 ? (
                      recentToken.map((id, index) => (
                        <>
                          <Col span={15} align="left" >
                            <HoverSpace size="middle" align="center">
                              <SequenceNo>{index + 1}</SequenceNo>
                              <RecentToolComponent id={id} />
                            </HoverSpace>
                          </Col>
                        </>
                      ))
                    ) : (
                      <div align="center">
                        <Empty />
                      </div>
                    )}
                  </Row>
                </Col>
              </div>
            </Col>
          </Row>
        </Spin>
      </Card>
      {/* </Tooltip> */}
    </Col>
  );
};

export default compose(withMe)(RecentTools);

const SequenceNo = styled.div`
  border-radius: 50%;
  width: 28px;
  height: 28px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  border: 1px solid #cacaca;
`;

const HoverSpace = styled(Space)`
  margin-bottom: 10px;
  &:hover {
    color: PRIMARY_CLR;
    cursor: pointer;
  }
`;
