import gql from 'graphql-tag';
import { CONDITION_TYPE_CONNECTION } from '../../text-section/graphql/ConditionTypeConnection.gql';
import { COACHING_COMMITMENT_TYPE_CONNECTION } from '../../dashboard/graphql/CoachingCommitmentTypeConnection.gql';

export const COMMITMENT_TYPE = gql`
  ${CONDITION_TYPE_CONNECTION}, ${COACHING_COMMITMENT_TYPE_CONNECTION}
  fragment CommitmentType on CommitmentType {
    id
    title
    description
    condition {
      ...ConditionTypeConnection
    }
    coachingcommitmentSet {
      ...CoachingCommitmentTypeConnection
    }
  }
`;
