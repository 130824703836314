import React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import gql from 'graphql-tag';
import { Empty, Spin } from 'antd';

import { compose } from '../../../core';
import { getIntFromString } from '../functions';
import { withLookWeightedQuestionFilterUpdating, withLookWeightedQuestionState } from '../../containers/LookOperations';

import SelectField from './SelectField';

export const ALL_SURVEY_QUERY = gql`
  query allSurveysForTokens(
    $first: Int
    $orderBy: [String] # $name_Icontains: String,
    $question_QuestionText_Icontains: String
  ) {
    allSurveysForTokens: getAllWeightedQuestions(
      first: $first
      orderBy: $orderBy
      question_QuestionText_Icontains: $question_QuestionText_Icontains
    ) {
      edges {
        node {
          id
          question {
            id
            questionText
            group {
              id
              name
              survey {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

const WeightedQuestionsAutoComplete = props => {
  const { loading, allSurveysForTokens, margin, onQuestionTextChange, onFiltersRemove } = props;
  const handleFilterRemove = React.useRef(() => {});

  handleFilterRemove.current = () => {
    onFiltersRemove();
  };

  React.useEffect(() => {
    return () => handleFilterRemove.current();
  }, []);

  const choices =
    !loading &&
    allSurveysForTokens &&
    allSurveysForTokens.edges.length > 0 &&
    allSurveysForTokens.edges.map(({ node }) => ({
      label: `${node?.question.group?.survey?.name} | ${node?.question.group?.name} | ${node.question.questionText}`,
      value: getIntFromString(node.id)
    }));

  return (
    <SelectField
      margin={margin}
      showSearch
      choices={choices}
      offset={1}
      style={{ width: '100%', marginBottom: !margin && 0 }}
      // labelInValue
      filterOption={false}
      onSearch={onQuestionTextChange}
      notFoundContent={
        loading ? (
          <div align="center">
            <Spin size="small" />
          </div>
        ) : (
          <Empty />
        )
      }
      {...props}
    />
  );
};

export default compose(
  withLookWeightedQuestionState,
  graphql(ALL_SURVEY_QUERY, {
    options: ({ filter, orderBy }) => {
      return { variables: { ...filter, orderBy } };
    },
    props({ data }) {
      const { loading, error, allSurveysForTokens, subscribeToMore, updateQuery } = data;
      return { loading, error, allSurveysForTokens, subscribeToMore, updateQuery };
    }
  }),
  withLookWeightedQuestionFilterUpdating
)(WeightedQuestionsAutoComplete);
