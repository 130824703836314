

import gql from 'graphql-tag';



export const DISCARD_SUBSCRIPTION = gql`

subscription discardRequestSubscription{
  discardRequestSubscription {
    mutation
    discardRequest{
      id
      status
      okr{
        id
        okrType
        owner{
          id
        }
      }
    }
  }
}

`


export const DISCARD_SUBSCRIPTION_OKR_DETAILS = gql`

subscription discardRequestSubscription{
  discardRequestSubscription {
    mutation
    discardRequest{
      id
      createdAt
      okr{
        id
      }
      initiator{
        id
        user{
          id
          firstName
          lastName
        }
      
      }
      actorsComment
      initiatorsRational
      status
    }
  }
}

`