import gql from 'graphql-tag';

export const COACHING_CONVERSATION = gql`
  query allCoachingConversation($question_QuestionAddress:String,$coachee_User_FirstName_Icontains: String,$coach_User_FirstName_Icontains: String,$nextCoachingConversation_Isnull:Boolean,$coach_cursor:String,$coachee_cursor:String,$first:Int,$offset:Int,$employee: ID) {
    coach:allCoachingConversation(coach:$employee,coachee_User_FirstName_Icontains:$coachee_User_FirstName_Icontains,coach_User_FirstName_Icontains:$coach_User_FirstName_Icontains,nextCoachingConversation_Isnull:$nextCoachingConversation_Isnull,after:$coach_cursor,first:$first,offset:$offset){
      totalCount
      edgeCount
      pageInfo{
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
      edges{
        node{
          id
          followUpOn
          response{
            id
              answerSet(question_QuestionAddress:$question_QuestionAddress){
                edges{
                  node{
                    id
                    question{
                      id
                      questionAddress
                    }
                    answer
                  }
                }
              }
          }
          coachee{
            id
            user{
              id
              firstName
              lastName
              profile{
                id
                profileImage
              }
            }
          }
          coach{
            id
            user{
              id
              firstName
              lastName
              profile{
                id
                profileImage
              }
            }
          }
          createdAt
          previousConversation{
            id
            response{
              id
              answerSet(question_QuestionAddress:$question_QuestionAddress){
                edges{
                  node{
                    id
                    question{
                      id
                      questionAddress
                    }
                    answer
                  }
                }
              }
            }
          }
        }
      }
    }
      coachee:allCoachingConversation(coachee:$employee,coachee_User_FirstName_Icontains:$coachee_User_FirstName_Icontains,coach_User_FirstName_Icontains:$coach_User_FirstName_Icontains,nextCoachingConversation_Isnull:$nextCoachingConversation_Isnull,after:$coachee_cursor,first:$first,offset:$offset){
      totalCount
      edgeCount
      pageInfo{
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
      edges{
        node{
          id
          followUpOn
          response{
            id
              answerSet(question_QuestionAddress:$question_QuestionAddress){
                edges{
                  node{
                    id
                    question{
                      id
                      questionAddress
                    }
                    answer
                  }
                }
              }
          }
          coachee{
            id
            user{
              id
              firstName
              lastName
              profile{
                id
                profileImage
              }
            }
          }
          coach{
            id
            user{
              id
              firstName
              lastName
              profile{
                id
                profileImage
              }
            }
          }
          createdAt
          previousConversation{
            id
            response{
              id
              answerSet(question_QuestionAddress:$question_QuestionAddress){
                edges{
                  node{
                    id
                    question{
                      id
                      questionAddress
                    }
                    answer
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;


export const COACHING_CONVERSATION_SUBSCRIPTION = gql`
subscription coachingConversationSubscription{
  coachingConversationSubscription {
      mutation
      coachingConversation {
        id
        followUpOn
        response {
          id
          answerSet{
            edges {
              node {
                id
                question {
                  id
                  questionAddress
                }
                answer
              }
            }
          }
        }
        coachee {
          id
          user {
            id
            firstName
            lastName
            profile {
              id
              profileImage
            }
          }
        }
        coach {
          id
          user {
            id
            firstName
            lastName
            profile {
              id
              profileImage
            }
          }
        }
        createdAt
        previousConversation {
          id
          response {
            id
            answerSet {
              edges {
                node {
                  id
                  question {
                    id
                    questionAddress
                  }
                  answer
                }
              }
            }
          }
        }
      }
    }
  }
    `