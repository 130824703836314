import gql from 'graphql-tag';

export const FILTER_SURVEY_CHOICE = gql`
  query SurveyById($id: ID!,$label_Icontains: String) {
    Options:SurveyById(id: $id) {
        id
        groupSet {
            edges {
              node {
                id
                questionSet {
                  edges {
                    node {
                      id
                      choiceSet(label_Icontains: $label_Icontains) {
                        edges {
                          node {
                            id
                            label
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
    }
  }
`;
