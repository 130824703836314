import gql from 'graphql-tag';
import { EMPLOYEE_TYPE } from './EmployeeType.gql';

export const ADD_EMPLOYEE = gql`
  ${EMPLOYEE_TYPE}
  mutation addEmployee($employeeData: EmployeeInput!) {
    addEmployee(employeeData: $employeeData) {
      employee {
        ...EmployeeType
      }
    }
  }
`;

export const ADD_EMPLOYEE_OPTIMIZED = gql`
  mutation addEmployee($employeeData: EmployeeInput!) {
    addEmployee(employeeData: $employeeData) {
      employee {
        id
      }
    }
  }
`;
