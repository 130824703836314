// @ts-nocheck
import React from 'react';
import { Row, Dropdown, Menu, Input, Button } from "antd";
import search_icon from '../../assets/search-gray-icon.svg'
import dropdown_Arrow from '../../assets/dropdown_Arrow.svg'
import styled from 'styled-components';
import { competency_icons } from 'modules/role_definition/icon';
import { CloseCircleFilled } from '@ant-design/icons'
const ReadinessSearchView = ( props ) => {

  const {
    filterData,
    onfilterChange,
    id,
    selected_tab
  } = props;

  const [ selectedFilter, setFilter ] = React.useState()
  const [ type, setType ] = React.useState()
  const [ select_default, setSelectDefault ] = React.useState( false )
  const [search, setSearch] = React.useState(null);

  React.useEffect( () => {
    if ( filterData && !selectedFilter ) {
      let find_value = filterData?.find( Item => Item.default === true )
      if ( find_value ) {
        setFilter( find_value )
      }
      else {
        setSelectDefault( true )
      }

    }
  }, [ filterData ] )

  React.useEffect(()=>{
    if(selected_tab?.key == 'self'){
      let filter = filterData?.find(i=>i?.key =="readinesslevel_Skill_Title_Icontains")
      setSearch(null)
      setFilter(filter)
    }
  },[selected_tab])

  React.useEffect(()=>{
    if(search!=null){
      onSearch(search);
    }
  },[search])

  const search_api_debounce = React.useRef()
  const onSearch = (text) => {
    if (search_api_debounce.current) {
      clearTimeout(search_api_debounce.current)
    }
    search_api_debounce.current = setTimeout(() => {
      let filter = {}
      filter[ selectedFilter?.key ] = text
      onfilterChange( filter )
    }, 1000);
  }
  
  const onSelectedfilterChange=(key)=>{
    if (search_api_debounce.current) {
      clearTimeout(search_api_debounce.current)
    }
    setSearch(null)
    onfilterChange({[ key ]:""}) 
  }
  //Filter choices render
  const Filtertype = ( choice ) => (
    <Menu style={{ maxHeight: '200px', overflow: 'auto' }} suffixIcon={<img src={dropdown_Arrow} alt="" style={{ width: "10px", height: "10px" }} />}>
      {
        choice && (
          choice?.map( ( data ) =>
            <Menu.Item className='rd-search-menu-title' style={{ color: selectedFilter?.label === data?.label ? '#4CCB1F' : '#BBBBBB' }} key={data?.key} onClick={() => {setType( data?.label ) }}>
              {data?.label}
            </Menu.Item>
          )
        )
      }
    </Menu>
  );

  // Filter type category
  const FilterType = (
    <div>
      {( selectedFilter?.ui_flow === 'input_box' || select_default ) && (
        <div style={{ position: "relative" }}>
          <Input
            className='search-input-filter'
            onChange={(e) => { setSearch(e?.target?.value) }} 
            value={search}
            autoComplete='off'
            placeholder="Search"
            style={{ width: "16em", borderRight: "1px solid #A9A9A9" }}
          />
          <img src={search_icon} alt="" style={{ position: 'absolute', left: "10px", width: "20px", top: "13px" }} />
        </div>
      )}
      {
        ( selectedFilter?.ui_flow === 'dropdown' ) && (
          <Dropdown overlay={Filtertype( selectedFilter?.choice )} overlayStyle={{zIndex:"1050"}} className="rd-input-filter-new">
            <DropdownButton style={{ borderLeft: '1px solid #D9D9D9', display: "flex", justifyContent: "center", alignItems: "center", width: "10em" }}>
              <p className='rd-search-menu-title' style={{ marginTop: '13px', width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'left' }}>{type ? type : <span style={{ color: "#D9D9D9" }}>Select</span>}</p>
              <img src={competency_icons?.dropdown_Arrow} alt="" style={{ width: "10px", height: "10px" }} />
            </DropdownButton>
          </Dropdown> )
      }
    </div>
  )

  //Filter menu
  const Filtermenu = () => (
    <Menu style={{ maxHeight: '200px', overflow: 'auto' }} suffixIcon={<img src={dropdown_Arrow} alt="" style={{ width: "10px", height: "10px" }} />}>
      {
        filterData && (
          filterData?.map( ( data ) =>
            <Menu.Item className='rd-search-menu-title' style={{ color: selectedFilter?.key === data?.key ? '#4CCB1F' : '#BBBBBB' }} key={data?.key} onClick={() => { setFilter( data );onSelectedfilterChange(data?.key)}}>
              {data?.label}
            </Menu.Item>
          )
        )
      }
    </Menu>
  );


  return (
    <>
      <Row style={{ flexWrap: "nowrap" }} id={id}>
        <div>
          {FilterType}
        </div>
        <Dropdown overlay={Filtermenu} overlayStyle={{zIndex:"1050"}} className="rd-input-filter" >
          <DropdownButton style={{ borderLeft: '1px solid #D9D9D9', display: "flex", justifyContent: "center", alignItems: "center" }}>
            <p className="rd-search-menu-title" style={{ margin: "0px", width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'left' }}>{selectedFilter?.label ? selectedFilter?.label : <p className='rd-search-drop-placeholder'>Filter</p>}</p>

            <img src={competency_icons?.dropdown_Arrow} alt="" style={{ width: "10px", height: "10px" }} />
          </DropdownButton>
        </Dropdown>
      </Row>
    </>
  )

}

export default ReadinessSearchView;
const DropdownButton = styled( Button )`
width:fit-content;
min-width:100px;
display:flex;
height:36px;
border-left:none;
justify-content:space-between;
align-items:center;
gap:5px
`