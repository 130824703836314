import gql from 'graphql-tag';

export const CREATE_EMPLOYEE_LEVEL = gql`

  mutation createEmployeeLevel($employeeLevelData:EmployeeLevelInput!) {
    createEmployeeLevel(employeeLevelData: $employeeLevelData) {
        employeeLevel{
            name
            id
        }
    }
  }
`;
