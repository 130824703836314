import gql from "graphql-tag";

export const BADGE_FAMILY_FILTER_LIST =gql`
query BadgeFamily($title_Icontains: String){
    BadgeFamily(title_Icontains:$title_Icontains) {
        edges {
            node {
                id
                title
            }
        }
    }
}
`

export const BADGE_FAMILY_DETAIL =gql`
query badgeFamilyById($id: ID!){
    badgeFamilyById(id:$id) {
        id
        title
        description
    }
}
`