// @ts-nocheck
import { message } from 'antd';
import _ from 'lodash';
import { graphql } from '@apollo/client/react/hoc';
import { CREATE_CONVERSATION_MUTATION } from '../graphql/ConversationMutation.gql'
import { CREATE_ACTION_ITEM_MUTATION } from '../graphql/createActionItem.gql';
import { UPDATE_ACTIONITEM_MUTATION } from 'modules/one-on-one/graphql/UpdateActionItems.gql';
import { EDIT_ACTION_ITEM } from '../graphql/EditActionItem.gql';

export const withCreateConversation = Component =>
  graphql(CREATE_CONVERSATION_MUTATION, {
    props: ({ mutate, history }) => ({
      createCoachingConversation: async values => {
        try {
          const {
            data: { createCoachingConversation }
          } = await mutate({
            variables: {
              ...values
            }
          });

          message.destroy();
          message.success('Submitted response!!');
          return createCoachingConversation.createCoaching;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
          console.error(e);
        }
      }
    })
  })(Component);

  export const withCreateActionItem = Component =>
  graphql(CREATE_ACTION_ITEM_MUTATION, {
    props: ({ mutate, history }) => ({
      newCreateActionItem: async values => {
        try {
          const {
            data: { newCreateActionItem }
          } = await mutate({
            variables: {
              ...values
            }
          });

          message.destroy();
          // message.success('Submitted response!!');
          return newCreateActionItem.actionItem;
        } catch (e) {
          message.destroy();
          // message.error("Couldn't perform the action");
          console.error(e);
        }
      }
    })
  })(Component);

  export const withUpdateActionItem = Component =>
  graphql(EDIT_ACTION_ITEM, {
    props: ({ mutate, history }) => ({
      newUpdateActionItem: async values => {
        try {
          const {
            data: { newUpdateActionItem }
          } = await mutate({
            variables: {
              ...values
            }
          }); 
          
          message.destroy();
          // message.success('Submitted response!!');
          return newUpdateActionItem;
        } catch (e) {
          message.destroy();
          // message.error("Couldn't perform the action");
          console.error(e);
        }
      }
    })
  })(Component);