const { default: AddEditTool } = require("../components/AddEditToolComponent");
const { default: GenericUiFlowTool } = require("../components/GenericUiFlowToolViewComponent");
const { default: impactNarrativeContainer } = require("../containers/impactNarrativeContainer");


module.exports = {
    themeConfig : {
        "TOOL_EDIT_TWO_COLUMN": AddEditTool,
        "SOUL_MODULE_SURVEY_TOOLS":GenericUiFlowTool,
        "IMPACT_NARRATIVE_TOOL_TWO_COLUMN":impactNarrativeContainer,
        "GENERIC_TOOL_VIEW":GenericUiFlowTool
    }
}