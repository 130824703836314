
import feedbackRoutes from "./feedback/route/index";
import okrRoutes from './Objective-keyresult/route/index'
import meetingRoutes from './one-on-one/route/index'
import readinessZoneRoutes from './readiness-level/routes/index'
import kudosRoutes from './kudos-user/route/index'
import surveyRoutes from './survey/route/index'
import userRoutes from  './user/route/index'
import idpRoutes from './IDP/route/index'
import diversity_survey_route from "./diversity-survey/route";

export const RoutesConfigs = {
    giveFeedback:feedbackRoutes.feedback.replace(':type','received'),
    update_objective:okrRoutes.viewObjective?.replace('/:okrId', ''),
    update_keyresult:okrRoutes.keyResultDetail?.replace('/:okrId', ''),
    update_milestone:okrRoutes.milestoneDetail?.replace('/:okrId', ''),
    okr_home:okrRoutes?.home,
    one_on_one:meetingRoutes?.oneOnOneHome.replace(':type','upcoming'),
    schedule_meeting:meetingRoutes?.addOneonOne,
    readiness_zone:readinessZoneRoutes?.readinessHome?.replace(":type","assessments"),
    reminder_give_feedback:feedbackRoutes.feedback.replace(':type','received'),
    discard_request_detail:okrRoutes?.requestDetail?.replace(':type','discard')?.replace('/:id',''),
    give_kudos:kudosRoutes?.add_badge,
    collaboration_request:okrRoutes?.discardRequest,
    survey_invite:surveyRoutes?.tokenSurvey,
    password_reset:userRoutes?.login + '/password-reset',
    idp_detail:idpRoutes?.detail?.replace('/:idp_id',''),
    idp_home:idpRoutes?.home,
    diversitysurvey:diversity_survey_route?.diversityHome.replace('/:id',''),
    survey:surveyRoutes?.surveyQuiz?.replace("/:id",'')
}



