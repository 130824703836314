import gql from 'graphql-tag';
import { CONTACT_REQUEST_TYPE } from './ContactRequestType.gql';

export const TOGGLE_CONTACT_STATUS = gql`
  ${CONTACT_REQUEST_TYPE}
  mutation toggleContactStatus($id: ID!, $status: Boolean!) {
    updateContactRequest(id: $id, status: $status) {
      contactRequest {
        ...ContactRequestType
      }
    }
  }
`;
