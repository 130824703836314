import React from 'react';
import { compose } from '../../core';
import { withApollo } from '@apollo/client/react/hoc';
import ActionListView from '../components/ActionItemListView';
import { withChangeActionItem } from './One_on_oneOperations';

const ActionListContainer =(props)=>{
    const {client}=props

    return(
        <>
        <ActionListView {...props}/>
        </>
    )

}

export default compose(withApollo)(ActionListContainer)