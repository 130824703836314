// @ts-nocheck
import React from 'react';
import { compose } from '../../core';
import { withCreateContributor, withCreateOKR } from './objective-keyresult-operations';
import { withApollo } from '@apollo/client/react/hoc';
import { OKR_CHILD_LIST_QUERY, OKR_CHILD_SUBSCRIPTION } from '../graphql/ChildOKRList.gql';
import MileStoneTableView from '../components/MileStoneTableView';
import MileStoneDetailTableView from '../components/MileStoneDetailTableView';
import moment from 'moment';
import { BADGE_AWARD_ADD_PERMISSION } from 'config';
import { globalPermissionValidator, permissionValidation } from 'modules/look';
import { kudos_permission } from 'Permissions/kudos.permission';
import { DISCARD_SUBSCRIPTION } from '../graphql/DiscardSubscriptionOkr.gql';
const ObjDetailMileStoneTableContainer = props => {
  const {me,client,keyResultDetail,disabled, isDiscarded,userPermission,getWightList}=props
  const [loading,setloading]= React.useState(true)
  const [allMilestone,setallMilestone]= React.useState()
  const [permission,setpermission]= React.useState()

  const dataRef = React.useRef()
  const m = React.useRef( true )

  let contributorsSub = undefined
  let milestonediscardSub = undefined;
  React.useEffect(() => {
    return () => {
      if (contributorsSub) {
        contributorsSub.unsubscribe();
      }
      if(milestonediscardSub){
        milestonediscardSub.unsubscribe();
      }
    }
  },[])
  // React.useEffect( () => {
  //   return () => {
  //     if ( contributorsSub ) {
  //       contributorsSub.unsubscribe();
  //     }
  //   }
  // })

  React.useEffect( () => {
    return () => {
      m.current = false
    }
  }, [] )

  React.useEffect( () => {
    m.current = true

  }, [] )


  React.useEffect(()=>{
    if(userPermission?.length){
      let permission=globalPermissionValidator(kudos_permission,userPermission)
      setpermission(permission)
    }
    else{
      setpermission(null)
    }
  },[userPermission])

  React.useEffect(()=>{
    if(allMilestone?.edges?.length){
      getWightList()
    }
  },[allMilestone])

  const getMilestoneList =async(filter)=>{
    setloading(true)
    const { data } = await client.query({
      query:OKR_CHILD_LIST_QUERY,
      fetchPolicy: 'network-only',
      variables: {okr_Parent:keyResultDetail?.id,createdBy:me?.employee?.id,...filter,tz:moment.tz.guess()} 
    });

    if(data){
      discardSubscription()
      if(!data?.contributors?.edges.length){
        disabled(true);
      }
      let incomingDataFromQuery = data?.contributors?.edges?.map(({ node }) => {
        return {
          id: node?.okr?.id,
          title: node?.okr?.title,
          okrType: node?.okrType,
          startdate: node?.okr?.startdate,
          duedate: node?.okr?.duedate,
          goalStatement: node?.okr?.goalStatement,
          correctionDeadline: node?.okr?.correctionDeadline,
          contributors:node?.okr?.contributorSet?.edges?.map(({node})=>
          {
              return{
                ...node?.employee?.user,role:node?.role,employee_id:node?.employee?.id
              }
          }),
          progress: node?.okr?.progress ?? 0,
          childSet: node?.okr?.childSet?.totalCount ? true : false,
          discardRequestRaised:node?.okr?.discardRequestRaised.totalCount>0?true:false,
          isDiscarded: {
            status: (isDiscarded === "APPROVED") ? isDiscarded :
              node?.okr?.isDiscarded?.status ?
                node?.okr?.isDiscarded?.status :
                node?.okr?.discardRequest?.totalCount ? "PENDING" : ''
            // id: node?.okr?.isDiscarded?.id ? node?.okr?.isDiscarded?.id :
              // node?.okr?.discardRequest?.totalCount ? node?.okr?.discardRequest?.edges[node?.okr?.discardRequest?.edges?.length - 1]?.node?.id : ''
          }
        }
      })

      
        
        setallMilestone({edges:incomingDataFromQuery} )
        dataRef.current = {edges:incomingDataFromQuery} 
        contributorsSub = await client.subscribe( {
          query: OKR_CHILD_SUBSCRIPTION,
          variables: {okr_Parent:keyResultDetail?.id,createdBy:me?.employee?.id,...filter}, 
          fetchPolicy: 'network-only'
        } ).subscribe( {
          next( result ) {
            let contributorsCacheData = dataRef.current
           

            if(result?.data?.contributorSubscription?.contributor?.okrType==='MILESTONE' && result?.data?.contributorSubscription?.contributor?.role ==="OWNER" && result?.data?.contributorSubscription?.contributor?.okr?.parent?.id===keyResultDetail?.id){
               switch ( result.data.contributorSubscription.mutation ) {
              case 'CREATE':
               
                const create = contributorsCacheData?.edges?.filter(item =>
                  item.id === result.data?.contributorSubscription?.contributor?.id
                      ? false
                      : true).concat([result?.data?.contributorSubscription?.contributor?.okr])
                  
                      let incomingData;
                      if(create){
                       
                       incomingData = create?.map((node ) => {
                        return {
                          id: node?.id,
                          title: node?.title,
                          okrType: node?.okrType,
                          startdate: node?.startdate,
                          duedate: node?.duedate,
                          goalStatement: node?.goalStatement,
                          correctionDeadline: node?.correctionDeadline,
                          contributors:node?.contributorSet?.edges?.map(({node})=>
                          {
                              return{
                                  ...node?.employee?.user,role:node?.role,employee_id:node?.employee?.id
                              }
                          }),
                          progress: node?.progress ?? 0,
                          childSet: node?.childSet?.totalCount ? true : false,
                          discardRequestRaised:node?.okr?.discardRequestRaised.totalCount>0?true:false,
                          isDiscarded: {
                            status: (isDiscarded === "APPROVED") ? isDiscarded :
                              node?.isDiscarded?.status ?
                                node?.isDiscarded?.status :
                                node?.discardRequest?.totalCount ? "PENDING" : ''
                            // id: node?.okr?.isDiscarded?.id ? node?.okr?.isDiscarded?.id :
                              // node?.okr?.discardRequest?.totalCount ? node?.okr?.discardRequest?.edges[node?.okr?.discardRequest?.edges?.length - 1]?.node?.id : ''
                          }
                        }
                      })
                     }
                     
                if ( m.current ) {
                 
                 
                  if(create){

                    setallMilestone({edges:incomingData})
                    dataRef.current = ({edges:incomingData})
                  }
                  
                }
                break
              case 'UPDATE':
                if ( m.current ) {
                  const Update =contributorsCacheData?.edges?.map( item =>
                      item?.node?.id === result?.data?.contributorSubscription.contributor?.id
                        ? result.data.contributorSubscription.contributor
                        : item
                    ) 
                    if(Update){
                      setallMilestone({edges:Update})
                      dataRef.current = {edges:Update}
                    }
                    
                }
                break
                case 'DELETE':
                  if ( m.current ) {
                    const deleted=contributorsCacheData?.edges?.filter( item =>
                        item?.node?.id === result?.data?.contributorSubscription.contributor?.id
                          ? false
                          : true
                      ) 
                      if(deleted){
                        setallMilestone({edges:deleted})
                        dataRef.current = {edges:deleted}
                      }
                     
                  }
                  break
                default:
                  break
            }
          }
          }})
    }
     
}

const discardSubscription=async()=>{
  milestonediscardSub = await client.subscribe({
    query: DISCARD_SUBSCRIPTION,
    // variables: { id: discardedOkrId },
    fetchPolicy: 'network-only'
  }).subscribe({
    next(result) {
     
      switch (result.data?.discardRequestSubscription?.mutation) {
        case 'CREATE':{
          let list ={ ...dataRef.current}
          let discarded_okr = result.data?.discardRequestSubscription?.discardRequest?.okr
          let okr_data = list.edges?.find(okr=>okr?.id==discarded_okr?.id)
          if(okr_data){
            okr_data.isDiscarded = result.data?.discardRequestSubscription?.discardRequest
            okr_data['discardRequestRaised'].totalCount = 1
            setallMilestone(list)
            dataRef.current = list
          }
          break
        }
        default:
          break
      }
    }
  })
}

const updateMileStoneRef=(e)=>{
  const {record,type}=e
  let list ={ ...dataRef.current}
  let okr_data = list.edges?.find(okr=>okr?.id==record?.id)
  if(okr_data){
    if(type=='UPDATE'){
      Object.entries(record).forEach(item=>{
        if(item[0]!='id'){
          okr_data[item[0]] = item[1]
        }
      })
    }
    else if(type=='DELETE'){
      list.edges = list.edges?.filter(okr=>okr?.id!=record?.id)
    }
    dataRef.current = list
  }
 
}

React.useEffect(()=>{
  if(me && keyResultDetail){
    getMilestoneList()
  }
},[me,keyResultDetail])
  return (
    <>

    <MileStoneDetailTableView allMilestone={allMilestone} updateMileStoneRef={(e)=>{updateMileStoneRef(e)}} keyResultDetail={keyResultDetail} setallMilestone={setallMilestone} dataRef={dataRef} loading={loading} setloading={(e)=>setloading(e)} {...props}permission={permission} />

    </>
  );
};

export default  compose(withApollo,withCreateOKR,withCreateContributor)(ObjDetailMileStoneTableContainer);
