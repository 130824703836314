import React from "react";
import { compose } from "../../core";
import { JOB_FAMILY_LIST, JOB_FAMILY_SUBSCRIPTION } from "../graphql/JobFamilyQuries.gql";
import { withDeleteJobFamily } from "../RoleDefinitionOperation";
import JobFamilyHomeView from "../components/JobFamilyHomeView";
import { getIntFromString } from "modules/look";

const JobFamilyHomeContainer = (props) => {

    const { client,permission,deleteJobFamily } = props

    const [jobfamily_loading, SetJobFamilyLoading] = React.useState(false)
    const [jobfamily_list, SetJobFamilyList] = React.useState([])
    const [jobfamily_delete,SetJobFamilyDelete] = React.useState()
    const [jobfamily_page_info,SetJobFamilyPageInfo] = React.useState()

    const jobfamily_list_ref = React.useRef([])
    const jobfamily_subscription = React.useRef(null)
    const filter_ref = React.useRef( {} )

     // to update the jobfamily list refference when the state gets changed
     React.useEffect(() => {
        jobfamily_list_ref.current = jobfamily_list
    }, [jobfamily_list])

     // to check if the user have permission to fetch jobfamily data
     React.useEffect(() => {
        if (permission && permission?.required_permission) {
            getJobFamilyList()
        }
    }, [permission])

    // Code to be executed when the component is unmounted
    React.useEffect(() => {
        return () => {
            if(jobfamily_subscription?.current){
                jobfamily_subscription.current.unsubscribe();
                jobfamily_subscription.current = null
            }
        };
    }, []);

    // qraphql api to fetch job family data list
    const getJobFamilyList = async (filter) => {
        filter_ref.current={...filter,after:null} || {}
        SetJobFamilyLoading(true)
        const { data } = await client.query({
            query: JOB_FAMILY_LIST,
            fetchPolicy: 'network-only',
            variables: {...filter,first:25}
        });
        if (data?.allJobFamilies) {
            // to set the pagination detail
            SetJobFamilyPageInfo({...data?.allJobFamilies?.pageInfo})

            let list = data?.allJobFamilies?.edges.map(({ node }) => node)
            // to handle pagination if after is present its a paginated data
            if(filter?.after){
                list = jobfamily_list_ref.current.concat(list)
            }
            SetJobFamilyList(list)
            SetJobFamilyLoading(false)
            if (!jobfamily_subscription?.current) {
                jobFamilySubscription()
            }
        }
    }

 
    // to add subscription to jobfamily api
    const jobFamilySubscription = async () => {
        jobfamily_subscription.current = client.subscribe({
            query: JOB_FAMILY_SUBSCRIPTION,
            fetchPolicy: 'no-cache'
        }).subscribe({
            next(result) {
                let jobfamily_cache_data = [].concat(jobfamily_list_ref.current)
                let scbscription_data = result.data.jobfamilySubscription
                switch (scbscription_data.mutation) {
                    case 'CREATE':
                        jobfamily_cache_data.push(scbscription_data?.jobfamily)
                        SetJobFamilyList(jobfamily_cache_data)
                        break
                    case 'UPDATE':
                        let doc = jobfamily_cache_data?.find(item=>item?.id === scbscription_data?.jobfamily?.id)
                        if(doc){
                            Object.entries(scbscription_data?.jobfamily).forEach(item=>{
                                doc[item[0]]=item[1]
                            })
                            SetJobFamilyList(jobfamily_cache_data)
                        }
                        break
                    case 'DELETE':
                        jobfamily_cache_data = jobfamily_cache_data?.filter(item=>item?.id !== scbscription_data?.jobfamily?.id)
                        SetJobFamilyList(jobfamily_cache_data)
                        break
                    default:
                        break
                }
            }
        });
    }

     // delete jobfamily data
     const removeJobFamily =async(id)=>{
        try{
            SetJobFamilyDelete(null)
            SetJobFamilyLoading(true)
            const response = await deleteJobFamily({ id });
            if (response) {
                let jobfamily_cache_data = [].concat(jobfamily_list_ref.current)
                jobfamily_cache_data = jobfamily_cache_data?.filter(item=>getIntFromString(item?.id) !== id)
                SetJobFamilyList(jobfamily_cache_data)
                SetJobFamilyLoading(false)
                
            }else{
                SetJobFamilyLoading(false)
            }
        }catch(e){
            SetJobFamilyLoading(false)
        }
    }

    return (
        <JobFamilyHomeView
            {...props}
            jobfamily_loading={jobfamily_loading}
            jobfamily_list={jobfamily_list}
            jobfamily_delete={jobfamily_delete}
            SetJobFamilyDelete={(data)=>{SetJobFamilyDelete(data)}}
            jobfamily_page_info={jobfamily_page_info}
            removeJobFamily={(id)=>{removeJobFamily(id)}}
            getJobFamilyList={(filter)=>{getJobFamilyList({...filter_ref.current,...filter})}}
        />
    )

}
export default compose(withDeleteJobFamily)(JobFamilyHomeContainer)