import React, { useEffect } from 'react';
import { Progress, Spin } from 'antd';
import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

import { compose } from '../../core';

const GET_MY_COACHING_COMMITMENTS = gql`
  query getMyCoachingCommitmentsProgress($completed: Boolean) {
    getMyCoachingCommitmentsProgress(completed: $completed) {
      totalCount
    }
  }
`;

const CoachingCommitmentProgressBar = props => {
  const { loading, coachingCommitmentsCompleted, coachingCommitments, updated, refetchCompleted, refetchTotal } = props;
  const totalCompleted = coachingCommitmentsCompleted?.totalCount;
  const totalCount = coachingCommitments?.totalCount;
  const progress = React.useRef(() => {});

  progress.current = () => {
    refetchCompleted();
    refetchTotal();
  };

  useEffect(() => {
    progress.current();
  }, [updated]);

  return (
    <Spin spinning={loading} size="small">
      <Progress
        type="circle"
        percent={((totalCompleted * 100) / totalCount || 0).toFixed(2)}
        strokeColor="#B0CB1F"
        strokeWidth="10"
      />
    </Spin>
  );
};

export default compose(
  graphql(GET_MY_COACHING_COMMITMENTS, {
    options: () => {
      return { variables: { completed: true } };
    },
    props({ data }) {
      const { loading, error, getMyCoachingCommitmentsProgress, subscribeToMore, updateQuery, refetch } = data;
      return {
        loading,
        error,
        coachingCommitmentsCompleted: getMyCoachingCommitmentsProgress,
        subscribeToMore,
        updateQuery,
        refetchCompleted: refetch
      };
    }
  }),
  graphql(GET_MY_COACHING_COMMITMENTS, {
    props({ data }) {
      const { loading, error, getMyCoachingCommitmentsProgress, subscribeToMore, updateQuery, refetch } = data;
      return {
        loading,
        error,
        coachingCommitments: getMyCoachingCommitmentsProgress,
        subscribeToMore,
        updateQuery,
        refetchTotal: refetch
      };
    }
  })
)(CoachingCommitmentProgressBar);
