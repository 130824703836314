import gql from 'graphql-tag';

export const ALL_QUALIFYING_LEVEL = gql`
  query allQualifyingLevels {
    allQualifyingLevels(orderBy:["sequence"]){
        totalCount
        edges{
          node{
            id
            name
            sequence
          }
        }
    }
  }
`;

export const RATING_SCALES = gql`
  query ratingScales {
    ratingScales(orderBy:["sequence"]){
        totalCount
        edges{
          node{
            id
            name
            sequence
          }
        }
    }
  }
`;

export const All_QUALIFYING_LEVEL_SUBSCRIPTION =gql`
subscription ratingScaleSubscription{
  ratingScaleSubscription {
    mutation
    ratingScale{
      id
      name
      sequence
    } 
  }
}
`
