import gql from 'graphql-tag';
import { TOKEN_TYPE } from '../../survey/graphql/TokenType.gql';

export const VERIFY_TOKEN = gql`
  ${TOKEN_TYPE}
  query verifyToken($token: String!) {
    verifyToken(token: $token) {
      ...TokenType
    }
  }
`;
