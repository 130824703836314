import React from "react";
import { compose } from '../../core';
import {getIntFromString} from "../../look";
import { Row, Col, Button,Modal,Spin  } from "antd";
import {  withDeleteOKR } from "../container/objective-keyresult-operations";
import close_small from '../../assets/close-small.svg'

const DeleteOkr =(props)=>{

    const {visible,type,okrId,deleteOkr,closeModal,deletesuccess}=props
    const [deleteLoader,setdeleteLoader]=React.useState(false)
    const cancel =()=>{
        closeModal()
    }
    
    const DeleteSelected= async()=>{
        setdeleteLoader(true)
        let response =await deleteOkr({id:getIntFromString(okrId)})
        setdeleteLoader(false)
        if (response){
            deletesuccess(okrId)
        }
    }


    return (
        <>
        <Modal
           footer={null}
           visible={visible}
           centered
           closable={false}
           destroyOnClose={true}
           className='custom-new-modal'>
            <Spin spinning={deleteLoader}>
            <Col style={{position:'relative'}}>
                <img style={{position:'absolute',right:'0px',top:"0px",cursor:'pointer'}} src={ close_small} onClick={()=>cancel()}/>
                <h3 className="modal-head">Warning</h3>
                {type==="Objective"&&(
                    <div>
                        <p className="modal-sub-head">Are you sure you want to delete this Objective ?</p>
                        <p className="modal-sub-head" style={{color:"#E86161"}}>Note: Deleting an objective will result in deletion of all KRs and Milestones under it</p>
                    </div>
                )}
                {type==="KeyResult"&&(
                    <div>
                        <p className="modal-sub-head">Are you sure you want to delete this Key Result ?</p>
                        <p className="modal-sub-head" style={{color:"#E86161"}}>Note: Deleting a Key Result will result in deletion of all Milestones under it</p>
                    </div>
                )}
                {type==="Milestone"&&(
                    <div>
                        <p className="modal-sub-head">Are you sure you want to delete this Milestone ?</p>
                    </div>
                )}
                <Row style={{width:"100%",gap:"20px",paddingTop:"20px"}} justify="center">
                    <Button className="modal-cancel-btn" onClick={cancel}>Cancel</Button>
                    <Button className="modal-submit-btn" style={{background: '#E86161',border:'1px solid #E86161'}} onClick={()=>DeleteSelected()}>Delete</Button>
                </Row>
            </Col>
            </Spin>
         </Modal>
     
     </>
    )
}

export default compose( withDeleteOKR)(DeleteOkr )