

import ScapeReportHomeContiner from './containers/scape-report-home-container';
import ROUTE from './route';

export default [
    {
        name: 'scapeReport',
        path: ROUTE.scapeReportHome,
        component: ScapeReportHomeContiner,
        protect: true
    }
]