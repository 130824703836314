import React from 'react';

import { AdminLayout } from '../../look';
import ROUTE from '../route';

import SurveyFormComponent from './SurveyFormComponent';

const EditSurveyView = props => {
  return (
    <AdminLayout
      table={false}
      active={ROUTE.employee}
      title="Edit Survey"
      FormComponent={props.SurveyById && <SurveyFormComponent {...props} />}
    />
  );
};

export default EditSurveyView;
