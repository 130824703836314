import gql from 'graphql-tag';
import { VERTICAL_TYPE } from './VerticalType.gql';

export const EDIT_VERTICAL = gql`
  ${VERTICAL_TYPE}
  mutation editVertical(
    $description: String
    $employees: [Int]
    $heads: [Int]
    $id: ID!
    $name: String
    $organizationId: Int
    $parentverticalId: Int
  ) {
    updateVertical(
      description: $description
      employees: $employees
      heads: $heads
      id: $id
      name: $name
      organizationId: $organizationId
      parentverticalId: $parentverticalId
    ) {
      vertical {
        ...VerticalType
      }
    }
  }
`;
