import React from 'react';
import { Button, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';

class EditIcon extends React.Component {
  render() {
    const { color = 'primary', type, size, ghost = true, tooltipSuffix, ...props } = this.props;

    let buttonSize = 'default';

    if (size === 'sm') {
      buttonSize = 'small';
    } else if (size === 'lg') {
      buttonSize = 'large';
    }

    return (
      <Tooltip title={ "Edit " + (tooltipSuffix ? tooltipSuffix : "") }>
        <Button
          type={color}
          htmlType={type}
          ghost={ghost}
          size={buttonSize}
          icon={<EditOutlined />}
          shape="circle"
          {...props}
        />
      </Tooltip>
    );
  }
}
export default EditIcon;
