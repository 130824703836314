import gql from 'graphql-tag';

export const DISCARD_TYPE =gql`
fragment DiscardRequestType on DiscardRequestType {
    id
    createdAt
    status
    initiator{
        id
        user{
            id
            firstName
            lastName
        }
    }
    okr{
        id
        title(tz:$tz)
        okrType
    }
}
`

export const DISCARD_REQUEST_LIST = gql`
${DISCARD_TYPE}
query discardRequests($actor:ID,$initiator:ID,$okr_Goalstatement_Icontains:String,$offset: Int,$first: Int,$tz:String) {
    Incomming:discardRequests(actor:$actor,okr_Goalstatement_Icontains:$okr_Goalstatement_Icontains,offset:$offset,first:$first) {
        totalCount
        edgeCount
        edges {
            node {
            ...DiscardRequestType
            }
        }
    }
    Outgoing:discardRequests(initiator:$initiator,okr_Goalstatement_Icontains:$okr_Goalstatement_Icontains,offset:$offset,first:$first) {
        totalCount
        edgeCount
        edges {
            node {
            ...DiscardRequestType
            }
        }
    }
}
`