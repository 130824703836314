import gql from 'graphql-tag';

export const LOOK_CONDITION_STATE_QUERY = gql`
  query lookConditionState {
    lookConditionState @client {
      filter {
        leftOperand
      }
      orderBy
    }
  }
`;
