// @ts-nocheck
import { Row,Col,Spin,Tooltip } from "antd";
import DoughntChartCascaded from "modules/look-v2/Components/DoughnutchartCascaded";
import DoughntChartNonCascaded from "modules/look-v2/Components/DoughnutChartNonCascaded";
import React from "react";
import InfoCircle from '../../assets/InfoCircle.svg'
import SubVerticalAndTeamsContainer from "../container/SubVerticalAndTeamsContainer";
import VerticalOKRPerformanceContainer from "../container/VerticalOKRPerformanceContainer";

const VerticalDashboardView = ( props ) => {
  const {noncascadeLoader,nonCascadedOkr,getAllNonCascadedObjectives,cascadedOkr,verticalDetail,cascadeLoader,getAllCascadedObjectives}=props
  const [teams,setTeam] = React.useState()
  return (
    <>
        <Row align="center" style={{gap:"30px"}}>
             
          <Col className="chart-container verticalCharts" lg={{ span: 11 }}  md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }} >

            <Spin spinning={cascadeLoader} >
            <h4 className="chart-title">Cascaded Objectives 
                <Tooltip title={'Cascaded Objectives'}>
                    <img src={InfoCircle} alt="" style={{cursor:"pointer",marginLeft:"10px"}} />
                </Tooltip>
            </h4>
            <Row align="middle" style={{width:"100%",flexDirection:"column"}}>
                <DoughntChartCascaded
                    {...props}
                    topValue = '135px'
                    rightValue = '92px'
                    fontSize = '14px'
                    responsiveFontSize = '11px'
                    responsiveTopValue = '100px'
                    responsiverightValue = '67px'
                    getNextSetOfCascadedOkrs={(e,offset)=>getAllCascadedObjectives(offset)}
                    label={'Cascaded Objectives'}
                    data={cascadedOkr}
                    managerids={verticalDetail?.heads?.edges?.map(({node})=>node?.id) || []}
                    loading={cascadeLoader}
                />
            </Row>
            </Spin>
          
          </Col>
          <Col className="chart-container verticalCharts" lg={{ span: 11 }}  md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }} >
                 <Spin spinning={noncascadeLoader}>
                    <h4 className="chart-title">Non-Cascading Objectives  
                        <Tooltip title={'Non-Cascading Objectives'}>
                        <img src={InfoCircle} alt="" style={{cursor:"pointer",marginLeft:"10px"}} />
                        </Tooltip>
                    </h4>
                    <Row align="middle" style={{width:"100%",flexDirection:"column"}}>
                        <DoughntChartNonCascaded
                            {...props}
                            topValue = '135px'
                            rightValue = '92px'
                            fontSize = '14px'
                            responsiveFontSize = '11px'
                            responsiveTopValue = '100px'
                            responsiverightValue = '66px'
                            getNextSetOfNonCascadedOkrs = {(e,offset)=> getAllNonCascadedObjectives(offset)}
                            label={'Non cascaded Objectives'}
                            data={nonCascadedOkr}
                            managerids={verticalDetail?.heads?.edges?.map(({node})=>node?.id) || []}
                            loading={noncascadeLoader}
                        />
                    </Row>
                </Spin>
          </Col>

        </Row>
        <Row align="center"  style={{gap:"30px",marginTop:"20px"}}>
             
          <Col className="chart-container verticalCharts performanceContainer"  xl={{ span: 14 }} lg={{ span: 11 }}  md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }} >
            <h4 className="chart-title" style={{textAlign:"left"}}>{verticalDetail?.name}’s  OKR Performance </h4>
            <VerticalOKRPerformanceContainer teams={teams} {...props}/>
          </Col>
          <Col className="chart-container verticalCharts" xl={{ span: 8 }} lg={{ span: 11 }}  md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }} >

            <SubVerticalAndTeamsContainer setTeam={(e)=>{setTeam(e)}} {...props} />
           
          </Col>

        </Row>


    </>
  );
};

export default VerticalDashboardView

