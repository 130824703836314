export const coaching_engagement = "coaching-engagement"
const baseName =`/role/${coaching_engagement}`
const create ='/add'
const view ='/detail'
const home ='/home'
const  coaching_engagement_route = {
    grow_create:baseName+create,
    grow_landing:baseName,
    grow_home:baseName+home,
    grow_detail:baseName+view+'/:convoId',
}

export default coaching_engagement_route