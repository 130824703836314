

import edit_role_definition from '../assets/edit_role_definition.svg'
import delete_icon from '../assets/delete-rd.svg'
import detail_page_view from '../assets/detail_page_view.svg'
import bars_action_set from '../assets/bars_action_set.svg'
import new_back_arrow from '../assets/new_back_arrow.svg'
import dropdown_arrow from '../assets/dropdown-arrow.svg'
import dropdown_Arrow from '../assets/bars-drop-icon.svg'
import active_row from '../assets/active-row.svg'
import non_active_row from '../assets/non-active-row.svg'
import complete_bar from '../assets/complete_bar.svg'
import incomplete_bar from '../assets/incomplete_bar.svg'
import search_icon from '../assets/search-gray-icon.svg'
import dropdown_submenu from '../assets/dropdown_submenu.svg'
import left_arrow_bars_with_group from '../assets/left-arrow-bars-with-group.svg'
import right_arrow_bars from '../assets/right-arrow-bars.svg'
import warning_bar from '../assets/warning_bar.svg'
import import_info from '../assets/import_info.svg'

export const Bars_Home_Icons = {

    edit_role_definition,
    delete_icon,
    detail_page_view,
    bars_action_set,
    dropdown_arrow,
    new_back_arrow,
    active_row,
    non_active_row,
    complete_bar,
    incomplete_bar,
    search_icon,
    warning_bar


}

export const DEFINE_BARS = {
    dropdown_submenu,
    new_back_arrow,
    left_arrow_bars_with_group,
    right_arrow_bars
}

export const CREATE_BARS_ICON = {
    left_arrow_bars_with_group,
    right_arrow_bars,
    new_back_arrow,
}

export const EDIT_BARS_ICON = {
    new_back_arrow,
    import_info
}



export const Bars_Detail_Icons = {
    dropdown_Arrow 
}

export const Bars_Create_Subcompetency_Icons = {
    import_info 
}

export const Bars_Details_Subcompetency_Icons = {
    new_back_arrow 
}



