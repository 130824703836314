import gql from 'graphql-tag';
import { RESPONSE_COMPARE_REQUEST_TYPE } from './ResponseCompareRequestType.gql';

export const RESPONSE_COMPARE_REQUEST = gql`
  ${RESPONSE_COMPARE_REQUEST_TYPE}
  mutation respondToResponseCompareRequest($accepted: Boolean!, $id: ID!, $message: String, $sharedResponseId: Int!) {
    respondToResponseCompareRequest(accepted: $accepted, id: $id, message: $message, sharedResponseId: $sharedResponseId) {
      compareRequest {
        ...ResponseCompareRequestType
      }
    }
  }
`;
