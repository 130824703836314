// @ts-nocheck
import React, { useState } from 'react';
import { Col, Card, Row, Spin, Empty } from 'antd';
import { DoubleRightOutlined, CloseOutlined } from '@ant-design/icons';

import { getIntFromString } from '../../look';

import ManagerCommitmentProgressBar from '../../manager/containers/ManagerCommitmentProgressBar';
import CoachingCommitmentProgressBar from '../../manager/containers/CoachingCommitmentProgressBar';
import PersonalCommitmentProgressBar from '../containers/PersonalCommitmentProgressBar';

import PersonalEditCommitmentView from './PersonalEditCommitmentView';
import CommitmentComponent from './CommitmentComponent';

const CommitmentView = props => {
  const {
    loading,
    customUserCommitments = { edges: [] },
    defaultCustomUserCommitments = { edges: [] },
    allDefaultCommitmentsProgress,
    onCustomUserCommitment,
    onDefaultCommitmentProgress,
    dashboard,
    employeeId,
    title = 'Commitments',
    forUser,
    commitmentKey = 'commitment'
  } = props;
  const [visible, setVisible] = useState(false);
  const [updated, setUpdated] = useState(0);

  return (
    <>
      <Col lg={visible ? 24 : 8} md={visible ? 24 : 12} sm={visible ? 24 : 12} xs={visible ? 24 : 24}>
        <Card
          style={{ display: 'grid', height: '100%' }}
          bodyStyle={{
            boxShadow: '5px 0px 25px 3px rgba(32, 32, 32, 0.1)',
            borderRadius: '2%'
            // paddingBottom: 0
          }}
          bordered={false}
        >
          <Row /* gutter={24} */ align="start">
            <Col  lg={visible ? 7 : 24} md={visible ? 7 : 24} sm={visible ? 24 : 24} xs={visible ? 24 : 24}>
              <div align="center">
                <Row align="middle">
                  <Col span={21}>
                    <h2 style={{ fontWeight: 'normal' }}>
                      {title}
                      <br />
                      {dashboard !== 'manager' && 'Progress Bar'}
                    </h2>
                  </Col>

                  <Col span={2} align="right">
                    {visible ? (
                      <CloseOutlined onClick={() => setVisible(false)} style={{ fontSize: '20px' }} />
                    ) : (
                      // <Tooltip title="This feature is comming soon!">
                      <DoubleRightOutlined
                        onClick={() => setVisible(true)}
                        style={{
                          fontSize: '20px',
                          transform: 'rotateZ(90deg)'
                        }}
                      />
                      // </Tooltip>
                    )}
                  </Col>
                </Row>

                <br />
                <br />

                {dashboard === 'manager-personal' && <CoachingCommitmentProgressBar updated={updated} />}
                {dashboard === 'manager' && <ManagerCommitmentProgressBar employeeId={employeeId} updated={updated} />}
                {dashboard === 'personal' && <PersonalCommitmentProgressBar updated={updated} />}
                <br />
                <br />
              </div>
            </Col>

            <Col lg={visible ? 16 : 0} md={visible ? 16 : 0} sm={visible ? 24 : 0} xs={visible ? 24 : 0} style={{paddingLeft:"20px"}}>
              <Spin spinning={loading}>
                {!loading && (
                  <Row justify={'space-between'}>
                    <Col>
                      <h2 style={{ fontWeight: 'normal' }}>{dashboard === 'manager' ? 'My Commitments' : 'Commitments'}</h2>
                    </Col>
                    {dashboard !== 'manager' && (
                      <Col>
                        <PersonalEditCommitmentView
                          defaultCustomUserCommitments={defaultCustomUserCommitments}
                          allDefaultCommitmentsProgress={
                            dashboard === 'manager-personal'
                              ? {
                                edges: allDefaultCommitmentsProgress?.edges?.length
                                  ? [].concat.apply(
                                    ...(allDefaultCommitmentsProgress?.edges?.map(({ node }) =>
                                      node?.commitment?.coachingcommitmentSet?.edges?.map(({ node }) => ({
                                        node
                                      }))
                                    ) ||
                                      [] ||
                                      [])
                                  )
                                  : []
                              }
                              : null
                          }
                          customUserCommitments={customUserCommitments}
                          dashboard={dashboard}
                          commitmentKey={commitmentKey}
                          forUser={forUser}
                        />
                      </Col>
                    )}

                    <Col span={24} style={{ minHeight: '25vh' }}>
                      {defaultCustomUserCommitments?.edges?.length > 0
                        ? defaultCustomUserCommitments?.edges?.map(({ node }, idx) => (
                          <>
                            <Row gutter={24} align="middle">
                              <CommitmentComponent
                                dashboard={dashboard}
                                commitment={node[commitmentKey]}
                                completed={node?.completed}
                                dateCreated={node?.dateCreated}
                                dateCompleted={node?.dateCompleted}
                                onDefaultCommitmentProgress={values =>
                                  onDefaultCommitmentProgress({
                                    id: getIntFromString(node?.id),
                                    ...values
                                  })
                                }
                                setUpdated={() => setUpdated(updated + 1)}
                                defaultCustomUserCommitment={true}
                              />
                            </Row>
                            <br />
                          </>
                        ))
                        : customUserCommitments?.length === 0 && (
                          <div align="center">
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                          </div>
                        )}

                      {customUserCommitments?.length > 0 &&
                        customUserCommitments?.map(({ node }, idx) => (
                          <>
                            <Row gutter={24} align="middle">
                              <CommitmentComponent
                                dashboard={dashboard}
                                commitment={node}
                                completed={node?.completed}
                                dateCreated={node?.dateCreated}
                                dateCompleted={node?.dateCompleted}
                                onCustomUserCommitment={onCustomUserCommitment}
                                setUpdated={() => setUpdated(updated + 1)}
                                defaultCustomUserCommitment={false}
                              />
                            </Row>
                            <br />
                          </>
                        ))}
                    </Col>
                  </Row>
                )}
              </Spin>
            </Col>
          </Row>
        </Card>
      </Col>
    </>
  );
};

export default CommitmentView;
