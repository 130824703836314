// @ts-nocheck
import { Spin, Modal, Popover } from 'antd';
import React from 'react';
import '../css/EmployeeLevel.css'
import { employee_level_icons } from '../icon';
import RoleDefinitionConfirmation from '../../look-v2/Components/ConfirmationDialog';
import { getIntFromString } from 'modules/look';
import AddEmployeeLevelContainer from '../containers/AddEmployeeLevelContainer';

const EmployeeLevelView = (props) => {

    const { employee_level_list, employee_level_loading, employee_level_delete, SetEmployeeLevelDelete, removeEmployeeLevel } = props
    const [expanded_row, SetExpandedRow] = React.useState([])
    const [add_level_visible,setAddLevelVisible]=React.useState(false)

    // to expand each row of the table
    const expandRow = (id) => {
        if (expanded_row?.includes(id)) {
            SetExpandedRow([])
        } else {
            SetExpandedRow([id])
        }
    }

    return (
        <Spin spinning={employee_level_loading} size='small'>
            {/* confirmation dialog for delete employee level */}
            <Modal
                visible={employee_level_delete}
                footer={false}
                closable={false}
                destroyOnClose={true}
                centered={true}
                className='rd-modal'
            >
                <RoleDefinitionConfirmation
                    message={`Do you want to delete the Employee level ${employee_level_delete?.name}?`}
                    onCancel={() => { SetEmployeeLevelDelete(null) }}
                    onConfirm={() => { removeEmployeeLevel(getIntFromString(employee_level_delete?.id)) }}
                />
            </Modal>

              {/* Add Employee Level Dialog */}
              <Modal
                visible={add_level_visible}
                footer={false}
                closable={false}
                destroyOnClose={true}
                centered={true}
                className='rd-modal rd-modal-max-width'
                width={"90%"}
            >
                <AddEmployeeLevelContainer 
                employee_level_list={employee_level_list}
                onCancel={(e)=>setAddLevelVisible(e)}/>
            </Modal>
            
            <div>
                <div className='rd-align-center' style={{marginBottom:"2em"}}>
                    <button className='rd-add-button' onClick={()=>setAddLevelVisible(true)}>+ Add Level</button>
                </div>
                
                {/* custom expansion table for employee level */}
                <div className='rd-table-scroll'>
                    <div className='rd-custom-table'>
                        <div className='rd-table-header'>
                            <div className='rd-th-full'>
                                <h4 className='rd-th-label'>Name</h4>
                            </div>
                            <div className='rd-th-action'>
                                <h4 className='rd-th-label'>Action</h4>
                            </div>
                        </div>
                        <div className='rd-table-body'>
                            {employee_level_list?.map(item => (
                                <>
                                    <div className='rd-table-content'>
                                        {item?.description&&<div className='el-expansion-container'>
                                            <img src={employee_level_icons?.table_expand_arrow} className={`rd-expansion-arrow ${expanded_row?.includes(item?.id) ? 'rd-open-expansion' : ''}`} onClick={() => { expandRow(item?.id) }} alt="" />
                                        </div>}
                                        <div className='rd-th-full'>
                                            <h4 className='rd-td-label'>{item?.name}</h4>
                                        </div>
                                       { <div className='rd-th-action'>
                                       <Popover overlayClassName="rd-add-new-popover" style={{ background: "rgba(170, 186, 198, 1)" }} placement="topRight" content={"Delete employee level"} trigger="hover">
                                            <img className='rd-delete-icon' src={employee_level_icons?.delete_icon} onClick={() => { SetEmployeeLevelDelete(item) }} alt="" />
                                            </Popover>
                                        </div>}
                                    </div>

                                    {/* expanded detail section */}
                                    <div className={`rd-expanded-detail ${expanded_row?.includes(item?.id) ? 'rd-open-expansion-detail' : ''}`}>
                                        <h4 className='rd-expanded-label'style={{fontSize:'1.25em'}}>Description</h4>
                                        <div className='expand-dis'>
                                        <h4 className='rd-expanded-label-desc'>{item?.description}</h4>
                                        </div>
                                    </div>
                                </>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </Spin>
    )
}

export default EmployeeLevelView