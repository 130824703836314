// @ts-nocheck
import React from 'react';
import gql from 'graphql-tag';
import { Modal, Form, Transfer, Row, Col, Button } from 'antd';
import { graphql } from '@apollo/client/react/hoc';
import { IMG } from '../../../../config';
import Loading from '../Loading';
import { getIntFromString } from '../functions';
import _debounce from 'lodash/debounce';
import { compose } from 'modules/core';
import { withApollo } from '@apollo/client/react/hoc';
export const LIST_USERS_FOR_ORG_BY_ORGID_QUERY = gql`
  query listUsersForOrgByOrgId(
    $orgId: ID!
    $before: String
    $after: String
    $first: Int
    $last: Int
    $offset: Int
    $firstName_Icontains: String
    $lastName_Icontains: String
    $employee_Isnull: Boolean
  ) {
    listUsersForOrgByOrgId(
      orgId: $orgId
      before: $before
      after: $after
      first: $first
      last: $last
      offset: $offset
      firstName_Icontains:$firstName_Icontains
      lastName_Icontains:$lastName_Icontains
      employee_Isnull: $employee_Isnull
    ) {
      edges {
        node {
          id
          username
          firstName
          lastName
          email
          dateJoined
          employee {
            id
          }
        }
      }
    }
  }
`;

export const GET_ALL_NON_ORG_EMPLOYEES = gql `
query getNonOrgEmployees($fnameIcontains: String,
  $before: String
  $after: String
  $first: Int
  $last: Int
  $offset: Int  ){
  getNonOrgEmployees(fnameIcontains: $fnameIcontains,
    before: $before
    after: $after
    first: $first
    last: $last
    offset: $offset){
    edges{
      node{
        id
        user{
          id
          firstName
          lastName
          username
          email
          employee{
            id
          }
        }
      }
    }
  }
}
`
const EmployeeTransfer = props => {

  const { initialValues, handleSubmit,client } = props;
  const { ...rest } = props;
  const FormItem = Form.Item;
  const [mockData, setmockData] = React.useState([]);
  const [targetKeys, setTargetKeys] = React.useState([]);
  const [targetDatas, setTargetDatas] = React.useState([]);
  const [visible, setVisible] = React.useState(false);
  const [searchValue,setSearchValue]=React.useState("")

  React.useEffect (()=>{
    if(props.orgId){
      fetchData(props.orgId,"")
    }
  },[props])

  const fetchData= async(id,searchKey)=>{
    const { data } = await client.query({
      query: GET_ALL_NON_ORG_EMPLOYEES,
      variables: { fnameIcontains:searchKey.toLowerCase()},
      fetchPolicy: 'network-only'
    });
    const mockupData =
      data?.getNonOrgEmployees &&
      data?.getNonOrgEmployees?.edges.map(({ node }) => ({
        title: node.user.firstName && node.user.lastName ? `${node.user.firstName} ${node.user.lastName}` : node.user.username,
        key: node.user.employee && node.user.employee.id && getIntFromString(node.user.employee.id)
      }))
    let mk = []
    mockupData.map((item, i) =>{
      mk.push(item['key'])
    })
    let filtered = targetDatas.filter(t => !mk.includes(t['key']))
    setmockData([...mockupData, ...filtered]);
  }

  React.useEffect(() => {
    const targetKeys = [];
    let targetData = [];
    initialValues &&
      initialValues.edgeCount > 0 &&
      initialValues.edges.map(({ node }) => {
        targetKeys.push(getIntFromString(node.id));
        targetData.push({
          title: node.user.firstName && node.user.lastName ? `${node.user.firstName} ${node.user.lastName}` : node.user.username,
          key:  node.id && getIntFromString(node.id)
        })
      });
    // targetData = targetData.filter(t => t.title !== undefined)
    setTargetKeys(targetKeys);
    setTargetDatas(targetData);
  }, [initialValues]);

  const filterOption = (inputValue, option) =>  option.title.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;

  const handleChange = targetkeys => {
    let tData = []
    mockData.map(m => {
      if (targetkeys.includes(m.key)){
        tData.push(m)
      }
    })
    setTargetDatas(tData)
    setTargetKeys(targetkeys);
  };
  const handleSearch= (dir,value)=>{
    
    if(dir==='left'){
      fetchData(props.orgId,value)
      // fetchData(props.orgId,value)
    }
    // _debounce(()=>fetchData(props.orgId,value),1000)
  }
  

  return (
    <>
      <Button type="primary" ghost onClick={() => setVisible(true)}>
        Add Employee
      </Button>
      <Modal
        destroyOnClose
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        title="Employee"
        width="45%"
        bodyStyle={{ padding: 12 }}
      >
        <div>
          <Row>
            <Col span={24}>
              {mockData ? (
                <FormItem labelCol={{ span: 24 }} wrapperCol={{ span: 24, offset: 1 }} {...rest}>
                  <Transfer
                    listStyle={{ height: '50vh', width: '18vw' }}
                    dataSource={mockData}
                    showSearch
                    filterOption={filterOption}
                    targetKeys={targetKeys}
                    onChange={handleChange}
                    onSearch={handleSearch}
                    render={item => (
                      <div>
                        <Row
                          style={{ display: 'block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        >
                          <img src={IMG} alt="" style={{ width: 30, borderRadius: 30, marginRight: 10 }} />
                          {item.title}
                        </Row>
                      </div>
                    )}
                    
                  />
                </FormItem>
              ) : (
                <Loading size="small" />
              )}
            </Col>
          </Row>
          <Row justify="end">
            <Button
              type="primary"
              onClick={async () => {
                await handleSubmit(targetKeys);
                setVisible(false);
              }}
            >
              Save
            </Button>
          </Row>
        </div>
      </Modal>
    </>
  );
};
export default compose(withApollo,)(EmployeeTransfer)

// export default graphql(LIST_USERS_FOR_ORG_BY_ORGID_QUERY, {
//   options: props => {
//     return {
//       variables: {
//         orgId: props.orgId,
//         employee_Isnull: false,
//         offset:0
//       },
//       fetchPolicy: 'network-only'
//     };
//   },
//   props({ data: { loading, error, listUsersForOrgByOrgId, subscribeToMore, updateQuery } }) {
//     return { loading, error, listUsersForOrgByOrgId, subscribeToMore, updateQuery };
//   }
// })(EmployeeTransfer);
