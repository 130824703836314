import React from "react";
import { compose } from "modules/core";
import MeetingFeedbackView from "../components/meetingFeedbackView";
import { withCreateFeedback, withDeleteFeedback, WithUpdateFeedback } from '../../feedback/containers/feedbackOperations'
import { withUpdateMeeting } from "./One_on_oneOperations";
import { withCreateBadgeAward, withDeleteBadgeAward } from "modules/kudos-user/container/badgeAwardOperationFeedback";

import { FEEDBACK_BY_ID } from "modules/feedback/graphql/FeedbackById.gql";
import { CONTRIBUTOR_OKR_LIST } from '../../feedback/graphql/ContributerOkrList.gql';

const MeetingFeedbackContainer =(props)=>{
   const {client}=props

   const[feedbackById,setfeedbackById]=React.useState(undefined)
   const [okrByrecipiant,setokrByrecipiant]= React.useState([]);

   const getFeedbackById = async(id)=>{
      const data = await client.query({
          query: FEEDBACK_BY_ID,
          variables: { id: id },
          fetchPolicy: 'network-only'
      });
      if(data?.data?.feedbackById){
          setfeedbackById(data?.data?.feedbackById)
      }
  }
  

  const getOKRByRecepiant = async (filter) => {

   const { data } = await client.query({
       query: CONTRIBUTOR_OKR_LIST,
       variables: filter,
   });
   if(data?.contributors){
     setokrByrecipiant(data?.contributors)
   }
   
}

  
  


   return (
    <>
    <MeetingFeedbackView getOKRByRecepiant = {getOKRByRecepiant} okrByrecipiant = {okrByrecipiant}  getFeedbackById = {getFeedbackById} feedbackById = {feedbackById} setfeedbackById = {setfeedbackById} {...props}/>
    </>
   )
}

export default compose(withDeleteFeedback,withCreateFeedback,withUpdateMeeting,WithUpdateFeedback,withCreateBadgeAward,withDeleteBadgeAward)(MeetingFeedbackContainer)