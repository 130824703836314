import React from 'react';
import CompetencyHomeContainer from '../containers/CompetencyHomeContainer';
import CompetencyAddEditContainer from '../containers/CompetencyAddEditContainer';

const SubCompetencyMainView = (props) => {

    const { current_comptency_tab } = props

    // list all view that are in the role definition
    const views = {
        competency_home_view: CompetencyHomeContainer,
        competency_add_edit_view:CompetencyAddEditContainer
    }
    const CurrentView = views[ Object.keys(views)?.includes(current_comptency_tab?.key)?current_comptency_tab?.key:'competency_home_view' ]

    return (
        <CurrentView {...props} />
    )

}
export default SubCompetencyMainView