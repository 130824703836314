// @ts-nocheck
import { compose } from 'modules/core';
import React from 'react';
import EditSurveyResponseContainer from './EditSurveyResponseContainer';
import EditSurveyResponseContainerQuestionBased from './EditSureyResponseQuestionBased'

const SurveyHandler = props => {
    const {match}=props

    const qb_based_survey = ["strengths","personality","knowledge-and-skills"]
    return (
        
            qb_based_survey?.includes(match.params.surveyType)?
            <EditSurveyResponseContainerQuestionBased {...props}/>
            :
            <EditSurveyResponseContainer {...props}/>
    )
};

export default compose()(SurveyHandler);

