import gql from 'graphql-tag';

export const GET_TOOL_ID_FOR_REPORTED_RECEIVED_ID = gql`
query getToolById($toolId:ID!,$responseId:ID!) {
    getToolById(toolId:$toolId,responseId:$responseId) {
        id
        title
        description
        textsection{
            totalCount 
            edges {    
                node {    
                    id    
                    title
                    text   
                    renderedText(responseId: $responseId)       
                    sequence  
                }  
            }
        }  
    }

}
`