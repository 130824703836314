// @ts-nocheck
import React from "react";
import { Button, Form, Input, Modal, Spin, Select, Tooltip } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { ReactMarkdown, getIntFromString } from 'modules/look';
import SubCompetencyAddContainer from '../containers/AddSubCompetencyContainer'
import { role_icons } from "../icon";
import MultiSelectFormDropdown from "modules/look-v2/Components/MultiSelectFormDropdown";
import CreateEligibilityContainer from "../containers/eligibilityContainer";
import AddEmployeeLevelContainer from "../containers/AddEmployeeLevelContainer";
import CreateResponsibilityContainer from "../containers/CreateResponsibilityContainer";
import CompetencyImportContainer from "../containers/CompetencyImportContainer";

const RoleAddEditView = (props) => {

    const { submit_loader, role_specific_competency_list, getRoleSpecificCompetencyList, onCancel, SetRemoveBaground, remove_background, employee_level_list, getEmployeLevel, SetRoleSpecificCompList, vertical_list, getVerticalList, reponsibility_list, SetAllResponsibilities, getResponsibilityList, onSubmit, role_detail, permission } = props
    const [initial_values, setInitialValues] = React.useState();
    const [add_competency, setAddCompetency] = React.useState(false)
    const [add_responsibility, setAddResponsibility] = React.useState(false)
    const [add_eligibility, setAddEligibility] = React.useState(false)
    const [form] = Form.useForm();
    const [selected_role_specific_competency, SetSelectedRoleSpecificCompetency] = React.useState([])
    const [selected_responsibility, SetSelectedResponsibility] = React.useState([])
    const [selected_eligibility, SetSelectedEligibility] = React.useState([])
    const [competency_must_have, SetCompetencyMustHave] = React.useState(true)
    const [competency_row_expanded, SetCompetencyExpanded] = React.useState([])
    const [responsibility_row_expanded, SetResponsibilityExpanded] = React.useState([])
    const [add_employee_level, SetAddEmployeeLevel] = React.useState(false)
    const [eligibility_detail, SetEligibilityDetail] = React.useState()
    const [responsibility_upload, SetResponsibilityUpload] = React.useState(false)

    React.useEffect(() => {
        if (!initial_values && role_detail) {
            setInitialValues(role_detail)
            if (role_detail?.selected_role_specific_competency?.length) {
                SetSelectedRoleSpecificCompetency(role_detail?.selected_role_specific_competency)
            }
            if (role_detail?.selected_eligibility?.length) {
                SetSelectedEligibility(role_detail?.selected_eligibility)
            }
            if (role_detail?.selected_responsibility?.length) {
                SetSelectedResponsibility(role_detail?.selected_responsibility)
            }
        }
    }, [role_detail])

    React.useEffect(() => {
        if (add_eligibility || selected_eligibility?.length || eligibility_detail) {
            SetRemoveBaground(true)
        } else {
            SetRemoveBaground(false)
        }
    }, [selected_eligibility, add_eligibility, eligibility_detail])

    // to handle the data before submitting the form
    const handleFormData = (data) => {
        let sumbit_data = {
            optionalEligibility: selected_eligibility?.filter(ele => !ele?.must_have).map(item => getIntFromString(item?.id)) || [],
            requiredEligibility: selected_eligibility?.filter(ele => ele?.must_have).map(item => getIntFromString(item?.id)) || [],
            responsibility: selected_responsibility?.map(item => getIntFromString(item?.id)) || [],
            optionalSpecificCompetency: selected_role_specific_competency?.filter(comp => !comp?.must_have).map(item => getIntFromString(item?.id)) || [],
            requiredSpecificCompetency: selected_role_specific_competency?.filter(comp => comp?.must_have).map(item => getIntFromString(item?.id)) || [],
            roleData: {
                title: data?.title,
                description: data?.description,
                employeeLevelId: getIntFromString(data?.employeeLevelId),
                verticalId: getIntFromString(data?.verticalId),
            }
        }
        onSubmit(sumbit_data)
    }

    // to change the selected role and competency
    const changeDropdownSelection = (data, type) => {
        if (type === 'responsibility') {
            let list = selected_responsibility.concat([])
            let doc = list?.find(item => item?.id === data?.id)
            if (doc) {
                list = list.filter(item => item?.id != data?.id)
            } else {
                list.push(data)
            }
            SetSelectedResponsibility(list)
        }
        else if (type === 'role_specific_competency') {
            let list = selected_role_specific_competency.concat([])
            let doc = list?.find(item => item?.id === data?.id)
            if (doc) {
                list = list.filter(item => item?.id != data?.id)
            } else {
                list.push({ ...data, must_have: competency_must_have })
            }
            SetSelectedRoleSpecificCompetency(list)
        }
    }

    const removeAdditionalDetails = (data, type) => {
        if (type === 'responsibility') {
            let list = selected_responsibility.concat([])
            list = list.filter(item => item?.id != data?.id)
            SetSelectedResponsibility(list)
        }
        else if (type === 'role_specific_competency') {
            let list = selected_role_specific_competency.concat([])
            list = list.filter(item => item?.id != data?.id)
            SetSelectedRoleSpecificCompetency(list)
        }
    }

    // to expand each row of the table
    const expandRow = (id, type) => {
        if (type === 'responsibility') {
            if (responsibility_row_expanded?.includes(id)) {
                SetResponsibilityExpanded([])
            } else {
                SetResponsibilityExpanded([id])
            }
        }
        else if (type === 'competency') {
            if (competency_row_expanded?.includes(id)) {
                SetCompetencyExpanded([])
            } else {
                SetCompetencyExpanded([id])
            }
        }
    }
    const changeMustHave = (id, must_have) => {
        let list = selected_role_specific_competency.concat([])
        let doc = list?.find(item => item?.id === id)
        if (doc) {
            doc.must_have = must_have
            SetSelectedRoleSpecificCompetency(list)
        }
    }

    // handle data after creation of competency
    const CreateRoleSpecificCompetencyResponse = (data) => {
        setAddCompetency(false)
        if (data) {
            let competency_list = [data].concat(role_specific_competency_list)
            SetRoleSpecificCompList(competency_list)
            let selected_competency = selected_role_specific_competency.concat([{ label: data?.title, ...data }])
            SetSelectedRoleSpecificCompetency(selected_competency)
        }
    }

    // handle data after creation of responsibility
    const CreateResponsibilityResponse = (data) => {
        setAddResponsibility(false)
        if (data) {
            let responsibility_list = [data].concat(reponsibility_list)
            SetAllResponsibilities(responsibility_list)
            let selected_responsibility_list = selected_responsibility.concat([{ label: data?.title, ...data }])
            SetSelectedResponsibility(selected_responsibility_list)
        }
    }

    // handle data after creation of eligibility
    const CreateEligibilityResponse = (data) => {
        setAddEligibility(false)
        SetEligibilityDetail(null)
        if (!data?.is_updated) {
            let selected_eligibility_list = selected_eligibility.concat([data])
            SetSelectedEligibility(selected_eligibility_list)
        } else {
            let selected_eligibility_list = selected_eligibility.concat([])
            let doc = selected_eligibility_list?.find(item => item?.id === data?.id)
            if (doc) {
                Object.entries(data).forEach(item => {
                    doc[item[0]] = item[1]
                })
                SetSelectedEligibility(selected_eligibility_list)
            }
        }
    }

    // remove added eligibility
    const removeEligibility = (eligibility) => {
        if (eligibility_detail && eligibility_detail?.id === eligibility?.id) {
            SetEligibilityDetail(null)
        }
        let selected_eligibility_list = selected_eligibility.concat([])
        SetSelectedEligibility(selected_eligibility_list?.filter(item => item?.id != eligibility?.id))
    }

    return (
        <>
            {
                initial_values && (
                    <Spin spinning={submit_loader} size='large'>

                        <Modal
                            visible={responsibility_upload}
                            footer={false}
                            closable={false}
                            destroyOnClose={true}
                            centered={true}
                            className='rd-modal cp-modal-max-width'
                            width={"90%"}
                        >
                            <CompetencyImportContainer {...props} type={"responsiblitiy"} onCancel={() => { SetResponsibilityUpload(false); getResponsibilityList() }} />
                        </Modal>

                        {/* to add role specific competency */}
                        <Modal
                            visible={add_competency}
                            footer={false}
                            closable={false}
                            destroyOnClose={true}
                            centered={true}
                            className='rd-modal cp-modal-max-width'
                            width={"90%"}
                        >
                            <SubCompetencyAddContainer {...props} must_have={true} onCancel={() => setAddCompetency(false)} competency_type='Role_Specific' onSuccess={(res) => { CreateRoleSpecificCompetencyResponse(res) }} />
                        </Modal>

                        {/* to create employee level */}
                        <Modal
                            visible={add_employee_level}
                            footer={false}
                            closable={false}
                            destroyOnClose={true}
                            centered={true}
                            className='rd-modal rd-modal-max-width'
                            width={"90%"}
                        >
                            <AddEmployeeLevelContainer
                                page_title={'Add Employee Level'}
                                onCancel={() => SetAddEmployeeLevel(false)} />
                        </Modal>

                        {/* to create responsibility */}
                        <Modal
                            visible={add_responsibility}
                            footer={false}
                            closable={false}
                            destroyOnClose={true}
                            centered={true}
                            className='rd-modal rd-modal-max-width'
                            width={"90%"}
                        >
                            <CreateResponsibilityContainer
                                onSuccess={(res) => { CreateResponsibilityResponse(res) }}
                                onCancel={() => setAddResponsibility(false)} />
                        </Modal>

                        <Form
                            layout="vertical"
                            onFinish={values => handleFormData(values)}
                            scrollToFirstError={true}
                            className='rd-align-center'
                            style={{ gap: '1em' }}
                            initialValues={initial_values}
                            form={form}
                        >
                            <div style={{ width: "100%" }} className={`${remove_background ? 'rd-main-content' : ''}`}>
                                <div className='rd-input-container'>
                                    <h4 className='rd-input-title'><span className='rd-input-star'>*</span> Title</h4>
                                    <Form.Item
                                        name="title"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input title',
                                            },
                                        ]}
                                        style={{ width: '100%' }}
                                    >
                                        <Input className="rd-text-input" placeholder='Enter role Title  ' />
                                    </Form.Item>
                                </div>

                                <div className='rd-input-container'>
                                    <h4 className='rd-input-title'>Description</h4>
                                    <Form.Item
                                        name="description"
                                        style={{ width: '100%' }}
                                    >
                                        <TextArea name='description' className="rd-text-area" placeholder='Enter role description' />
                                    </Form.Item>
                                </div>

                                <div className="role-row-alignment">
                                    {/* employee level list */}
                                    <div className='rd-input-container'>
                                        <h4 className='rd-input-title'><span className='rd-input-star'>*</span> Select Level</h4>
                                        <div className="role-dropsection-alignment">
                                            <Form.Item
                                                name="employeeLevelId"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please select Employee level',
                                                    },
                                                ]}
                                                style={{ width: '100%' }}
                                            >
                                                <Select
                                                    className="rd-select-filed rd-type-select"
                                                    style={{ width: '100%', marginTop: "10px" }}
                                                    suffixIcon={<img src={role_icons?.dropdown_Arrow} alt="" style={{ width: "10px", height: "10px" }} />}
                                                    showSearch={true}
                                                    filterOption={false}
                                                    onSearch={(e) => { getEmployeLevel({ name_Icontains: e }) }}
                                                    placeholder='select employee level'
                                                    dropdownClassName="content-dropdown"
                                                >
                                                    {
                                                        employee_level_list?.map(item => (
                                                            <Select.Option key={item?.id} value={item?.id}>{item?.name}</Select.Option>
                                                        ))
                                                    }
                                                </Select>
                                            </Form.Item>
                                            <Tooltip title="Add employee level">
                                                <Button className="role-plus-btn" onClick={() => { SetAddEmployeeLevel(true) }}>
                                                    <img src={role_icons.plus} alt="" />
                                                </Button>
                                            </Tooltip>
                                        </div>
                                    </div>

                                    {/* vertical list */}
                                    <div className='rd-input-container'>
                                        <h4 className='rd-input-title'>Select Vertical</h4>
                                        <Form.Item
                                            name="verticalId"
                                            style={{ width: '100%' }}
                                        >
                                            <Select
                                                className="rd-select-filed rd-type-select"
                                                style={{ width: '100%', marginTop: "10px" }}
                                                suffixIcon={<img src={role_icons?.dropdown_Arrow} alt="" style={{ width: "10px", height: "10px" }} />}
                                                showSearch={true}
                                                filterOption={false}
                                                placeholder='select Vertical'
                                                onSearch={(e) => { getVerticalList({ name_Icontains: e }) }}
                                                dropdownClassName="content-dropdown"
                                            >
                                                {
                                                    vertical_list?.map(item => (
                                                        <Select.Option key={item?.id} value={item?.id}>{item?.name}</Select.Option>
                                                    ))
                                                }
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </div>

                                {/* functional competency section */}
                                <div className='rd-input-container'>
                                    <h4 className='rd-input-title'>Assign or add functional competency</h4>

                                    <div className="role-row-alignment" style={{ marginTop: "1em" }}>
                                        {/* must have radio btn */}
                                        <div className="must-have-section" style={{ border: "1px solid #0098F0" }} onClick={() => SetCompetencyMustHave(true)}>
                                            <h4 className="must-have-label" style={{ color: "#0098F0" }}>Must have</h4>
                                            <div className={`must-have-parent ${competency_must_have ? 'competency-blue' : ''}`}>
                                                <div className={`must-have-child ${competency_must_have ? 'competency-child-blue' : ''}`}></div>
                                            </div>
                                        </div>
                                        {/* good to have radio btn */}
                                        <div className="must-have-section" style={{ border: "1px solid #4CCB1F" }} onClick={() => SetCompetencyMustHave(false)}>
                                            <h4 className="must-have-label" style={{ color: "#4CCB1F" }}>Good to have</h4>
                                            <div className={`must-have-parent ${!competency_must_have ? 'competency-green' : ''}`}>
                                                <div className={`must-have-child ${!competency_must_have ? 'competency-child-green' : ''}`}></div>
                                            </div>
                                        </div>
                                    </div>

                                    <MultiSelectFormDropdown
                                        onSelect={(e) => { changeDropdownSelection(e, 'role_specific_competency') }}
                                        selected_data={selected_role_specific_competency?.map(comp => comp?.id)}
                                        onSearch={(e) => { getRoleSpecificCompetencyList({ title_Icontains: e }) }}
                                        placeholder={'Select Functional competencies'}
                                        choice_list={role_specific_competency_list?.map(item => { return { ...item, label: item?.title } })}
                                        add_btn='Add Functional competency'
                                        on_add={(e) => { setAddCompetency(true) }}
                                    />

                                    {/* table to show selected rolespecific competency */}
                                    <div className='rd-table-scroll' style={{ width: "100%", marginTop: "1.5em" }}>
                                        <div className='rd-custom-form-table'>
                                            <div className='rd-form-table-header'>
                                                <div className='rd-th-full'>
                                                    <h4 className='rd-th-label-select-table'>Functional competency Name</h4>
                                                </div>
                                                <div className='rd-th-full table-must-have-container' >
                                                    <div className="role-comp-table-must-have" style={{ background: "transparent", margin: "0px", height: "0.5em" }}>

                                                    </div>
                                                </div>
                                                <div className='rd-th-action'>
                                                    <h4 className='rd-th-label-select-table'>Action</h4>
                                                </div>
                                            </div>
                                            <div className='rd-form-table-body'>
                                                {selected_role_specific_competency?.map(item => (
                                                    <>
                                                        <div className='rd-form-table-content' style={{ alignItems: "center" }}>
                                                            {item?.description && <div className='el-expansion-container'>
                                                                <img src={role_icons?.table_expand_arrow} className={`rd-expansion-arrow ${competency_row_expanded?.includes(item?.id) ? 'rd-open-expansion' : ''}`} onClick={() => { expandRow(item?.id, "competency") }} alt="" />
                                                            </div>}
                                                            <div className='rd-th-full' >
                                                                <h4 className='rd-td-label'>{item?.label}</h4>
                                                            </div>
                                                            <div className='rd-th-full table-must-have-container'>
                                                                <div className="role-comp-table-must-have" >
                                                                    <div className={`role-comp-table-setion`} onClick={() => { changeMustHave(item.id, true) }} style={{ background: `${item.must_have ? '#0098F0' : 'transparent'}` }}>
                                                                        <h4 className={`role-comp-table-label`} style={{ color: `${item.must_have ? '#fff' : '#AABAC6'}` }}>Must have</h4>
                                                                    </div>

                                                                    <div className={`role-comp-table-setion`} onClick={() => { changeMustHave(item.id, false) }} style={{ background: `${!item.must_have ? '#4CCB1F' : 'transparent'}` }}>
                                                                        <h4 className={`role-comp-table-label`} style={{ color: `${!item.must_have ? '#fff' : '#AABAC6'}` }}>Good to have</h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='rd-th-action'>
                                                                <img className='rd-delete-icon' src={role_icons?.delete_icon} onClick={() => { removeAdditionalDetails(item, 'role_specific_competency') }} alt="" />
                                                            </div>
                                                        </div>

                                                        {/* expanded detail section */}
                                                        <div className={`rd-expanded-detail ${competency_row_expanded?.includes(item?.id) ? 'rd-open-expansion-detail' : ''}`}>
                                                            <h4 className='rd-expanded-label'>Description</h4>
                                                            <div className='expand-dis'>
                                                                <h4 className='rd-expanded-label-desc'> <ReactMarkdown >{item?.description}</ReactMarkdown></h4>
                                                            </div>
                                                        </div>
                                                    </>
                                                ))}

                                                {
                                                    (!selected_role_specific_competency?.length) ?
                                                        <div className='rd-nodata'>
                                                            <h4 className='rd-nodata-label'>No functional competency selected</h4>
                                                        </div> : ''
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* add responsibilities section */}
                                {
                                    permission?.responsibility_permission && (
                                        <div className='rd-input-container' style={{ marginTop: "1.5em" }}>
                                            <h4 className='rd-input-title'>Select Associated Responsibilities</h4>

                                            <div className="role-row-alignment" style={{ marginTop: "1em" }}>
                                                <MultiSelectFormDropdown
                                                    onSelect={(e) => { changeDropdownSelection(e, 'responsibility') }}
                                                    selected_data={selected_responsibility?.map(comp => comp?.id)}
                                                    onSearch={(e) => { getResponsibilityList({ title_Icontains: e }) }}
                                                    placeholder={'Select Associated Responsibilities'}
                                                    choice_list={reponsibility_list?.map(item => { return { ...item, label: item?.title } })}
                                                    add_btn='Add Responsibility'
                                                    on_add={(e) => { setAddResponsibility(true) }}
                                                />
                                                <Button className='rd-dotted-button' onClick={() => { SetResponsibilityUpload(true) }} style={{ width: "8.5em", margin: "0em", height: "2.25em" }} > <img src={role_icons?.import_icon} alt="" /> Import</Button>
                                            </div>

                                            {/* table to show selected responsibility */}
                                            <div className='rd-table-scroll' style={{ width: "100%", marginTop: "1.5em" }}>
                                                <div className='rd-custom-form-table'>
                                                    <div className='rd-form-table-header'>
                                                        <div className='rd-th-full'>
                                                            <h4 className='rd-th-label-select-table'>Associated responsibilities  Name</h4>
                                                        </div>

                                                        <div className='rd-th-action'>
                                                            <h4 className='rd-th-label-select-table'>Action</h4>
                                                        </div>
                                                    </div>
                                                    <div className='rd-form-table-body'>
                                                        {selected_responsibility?.map(item => (
                                                            <>
                                                                <div className='rd-form-table-content' style={{ alignItems: "center" }}>
                                                                    {item?.description && <div className='el-expansion-container'>
                                                                        <img src={role_icons?.table_expand_arrow} className={`rd-expansion-arrow ${responsibility_row_expanded?.includes(item?.id) ? 'rd-open-expansion' : ''}`} onClick={() => { expandRow(item?.id, "responsibility") }} alt="" />
                                                                    </div>}
                                                                    <div className='rd-th-full' >
                                                                        <h4 className='rd-td-label'>{item?.label}</h4>
                                                                    </div>

                                                                    <div className='rd-th-action'>
                                                                        <img className='rd-delete-icon' src={role_icons?.delete_icon} onClick={() => { removeAdditionalDetails(item, 'responsibility') }} alt="" />
                                                                    </div>
                                                                </div>

                                                                {/* expanded detail section */}
                                                                <div className={`rd-expanded-detail ${responsibility_row_expanded?.includes(item?.id) ? 'rd-open-expansion-detail' : ''}`}>
                                                                    <h4 className='rd-expanded-label'>Description</h4>
                                                                    <div className='expand-dis'>
                                                                        <h4 className='rd-expanded-label-desc'> <ReactMarkdown>{item?.description}</ReactMarkdown> </h4>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ))}

                                                        {
                                                            (!selected_responsibility?.length) ?
                                                                <div className='rd-nodata'>
                                                                    <h4 className='rd-nodata-label'>No responsibilities selected</h4>
                                                                </div> : ''
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }

                                {
                                    !remove_background && (
                                        <>
                                            {
                                                permission?.eligibility_permission && (
                                                    <div align="center">
                                                        <Button className="add-eligibility-btn" onClick={() => { setAddEligibility(true) }}>+ Add eligibility</Button>
                                                    </div>
                                                )
                                            }

                                            <div className='rd-confirmation-action-container' style={{ padding: "3em 1em 2.5em 1em" }}>
                                                <Button className='global-submit-btn' htmlType="submit">Save</Button>
                                                <Button className='global-cancel-btn' onClick={() => onCancel('role_home_view')}>Cancel</Button>
                                            </div>
                                        </>
                                    )
                                }

                            </div>

                            {
                                remove_background && (
                                    <>
                                        {
                                            selected_eligibility?.length ?
                                                <div className="rd-main-content" style={{ width: "100%", marginTop: "1em" }}>
                                                    <h4 className='rd-input-title' style={{ marginBottom: "1.5em" }}>Eligibility</h4>
                                                    {
                                                        selected_eligibility?.map(item => (
                                                            <div className="role-eligibility-table">
                                                                <div className="eligibility-table-main-header" style={{ background: item?.must_have ? '#0098F0' : '#4CCB1F', border: item?.must_have ? '1px solid #0098F0' : '1px solid #4CCB1F' }}>
                                                                    <h4>{item?.must_have ? 'Must have' : 'Good to have'}</h4>
                                                                    {
                                                                        permission?.eligibility_permission && (
                                                                            <div className="eligible-table-action">
                                                                                {
                                                                                    (!add_eligibility && !eligibility_detail) && (
                                                                                        <img src={role_icons.eligible_edit} onClick={() => { SetEligibilityDetail(item) }} alt="" />
                                                                                    )
                                                                                }
                                                                                <img src={role_icons.eligible_delete} onClick={() => { removeEligibility(item) }} alt="" />
                                                                            </div>
                                                                        )
                                                                    }

                                                                </div>
                                                                <div className="eligibility-table-body" style={{ width: '100%' }}>
                                                                    <div className="eligibility-tr" style={{ background: '#31D0AA', border: '1px solid #31D0AA' }}>
                                                                        <div className="eligibility-th">
                                                                            <h4>Required education</h4>
                                                                        </div>
                                                                        <div className="eligibility-th">
                                                                            <h4>Specialisation</h4>
                                                                        </div>
                                                                        <div className="eligibility-th">
                                                                            <h4>Required Experience</h4>
                                                                        </div>
                                                                    </div>
                                                                    {/* body */}
                                                                    <div className="eligibility-tr eligible-tr-border">
                                                                        <div className="eligibility-td">
                                                                            {
                                                                                item.education.map(ed => (
                                                                                    <h4>{ed.stream}</h4>
                                                                                ))
                                                                            }
                                                                        </div>
                                                                        <div className="eligibility-td" style={{ border: '1px solid #AABAC6', borderTop: 'none', borderBottom: 'none' }}>
                                                                            {
                                                                                item.education.map(ed => (
                                                                                    <h4>{ed.degree}</h4>
                                                                                ))
                                                                            }
                                                                        </div>
                                                                        <div className="eligibility-td">
                                                                            <h4>{parseInt(item?.eligibilityData?.quantitativeExperience?.quantitativeExperienceMin)} - {parseInt(item?.eligibilityData?.quantitativeExperience?.quantitativeExperienceMax)} year</h4>
                                                                            {
                                                                                item?.eligibilityData?.qualitativeExperience && (
                                                                                    <h4>[{item?.eligibilityData?.qualitativeExperience}]</h4>
                                                                                )
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div> : ''
                                        }



                                        {
                                            (add_eligibility || eligibility_detail) && (
                                                <div className="rd-main-content" style={{ width: "100%", marginTop: "1em" }}>
                                                    <CreateEligibilityContainer {...props} eligibility_detail={eligibility_detail} onCancel={() => { setAddEligibility(false); SetEligibilityDetail(null) }} eligibilityResponse={(data) => { CreateEligibilityResponse(data) }} />
                                                </div>
                                            )
                                        }

                                        {
                                            permission?.eligibility_permission && (
                                                <div align="center">
                                                    <Button className="add-eligibility-btn" disabled={add_eligibility || eligibility_detail} onClick={() => { setAddEligibility(true) }}>+ Add eligibility</Button>
                                                </div>
                                            )
                                        }



                                        <div className='rd-confirmation-action-container' style={{ padding: "3em 1em 2.5em 1em" }}>
                                            <Button className='global-submit-btn' htmlType="submit">Save</Button>
                                            <Button className='global-cancel-btn' onClick={() => onCancel('role_home_view')}>Cancel</Button>
                                        </div>
                                    </>
                                )
                            }

                        </Form>
                    </Spin>
                )
            }
        </>
    )
}

export default RoleAddEditView