// @ts-nocheck
import React, { useState } from "react";
import "../../kudos/kudos.css";
import { Spin, Button, Modal } from "antd";
import delete_bg_logo from '../../assets/delete-badge.svg'
import { getIntFromString } from "modules/look";
const DeleteConfiramtion = ( props ) => {
    const { loading, deleteType, closeModal, deleteBadge, deleteBadgeFamily,Visible,deleteBadgeAward } = props
    console.log("deleteType",deleteType)
    const HandleDelete = async () => {
        if ( deleteType.type === 'Badge' ) {
            let res = await deleteBadge( { badgeId: getIntFromString( deleteType.id ) } )
            if ( res ) {
                closeModal()
            }
        }
        else if ( deleteType.type === 'Badge_Family' ) {
            let res = await deleteBadgeFamily( { badgeFamilyId: getIntFromString( deleteType.id ) } )
            if ( res ) {
                closeModal()
            }
        }
        else if(deleteType.type ==='badge_award'){
            let res  = await deleteBadgeAward({badgeAwardId:getIntFromString(deleteType?.id)})
            if(res){
                closeModal()
            }
        }
    }

    return (
        <>
            <Modal
                visible={Visible}
                destroyOnClose={true}
                footer={false}
                closeIcon={false}
                closable={false}
                className="kudos-modal-delete"
                centered={true}>
                <div style={{ width: "100%" }} align="center" className="kudos-root-class">
                    <div style={{ width: "100%" }} align="end" className="align-logo">
                        <img src={delete_bg_logo} className="delete-logo" alt="" srcset="" />
                    </div>
                    <h4 className="kudos-delete-title">sure you want to delete this given kudos.</h4>
                    <div>
                        <Button className="kudos-delete-btn" style={{width:"fit-content"}} onClick={() => { HandleDelete() }}>DELETE KUDOS AWARD</Button>
                        <Button className="kudos-cancel-btn" onClick={() => { closeModal() }}>CANCEL</Button>
                    </div>
                </div>
            </Modal>
        </>
    )
};

export default DeleteConfiramtion;
