import { Col, Modal, Row } from "antd";
import React from "react";
import whatsapp from '../../assets/WhatsApp.svg'
import instagram from '../../assets/Instagram.svg'
import linkedin from '../../assets/LinkedIn.svg'
import teams from '../../assets/Microsoft Teams.svg'
import twitter from '../../assets/Twitter Squared.svg'
import facebook from '../../assets/Facebook.svg'
import modal_close from '../../assets/badge-modal-close.svg'
const ShareModal = ( props ) => {
    const { SharedVisible,sharedClose,sharedValue } = props
    console.log(sharedValue)
    return (
        <>
            <Modal
                closable={false}
                visible={SharedVisible}
                destroyOnClose={true}
                footer={false}
                centered={true}
                className="user-badge-award-share-modal">
                <div className="share-modal-container" style={{  display: "flex", flexDirection: "row", justifyContent: "space-between",alignItems:"center", width: "100%", height: "500px",paddingRight:"50px",position:"relative"}}>
                <img src={modal_close} alt="" style={{ cursor: "pointer", position: "absolute", right: "-10px", top: "-10px" }} onClick={() => sharedClose( false )} />
                    <div>

                    </div>
                    <div style={{display:"flex",flexDirection:"column",gap:"20px"}}>
                        <Row justify="center" align="center" style={{ width: "100%",gap:"20px" }}>
                            <div className="user-badge-award-share-div">
                                <img src={twitter} alt="" className="social-icon" />
                            </div>
                            <div className="user-badge-award-share-div">
                                <img src={facebook} alt="" className="social-icon"/>
                            </div>
                            <div className="user-badge-award-share-div">
                                <img src={linkedin} alt="" className="social-icon" />
                            </div>  
                        </Row>
                        <Row justify="center" align="center" style={{ width: "100%",gap:"20px" }}>
                            <div className="user-badge-award-share-div">
                                <img src={teams} alt="" className="social-icon"/>
                            </div>
                            <div className="user-badge-award-share-div">
                                <img src={whatsapp} alt="" className="social-icon"/>
                            </div>
                            <div className="user-badge-award-share-div">
                                <img src={instagram} alt="" className="social-icon"/>
                            </div>
                        </Row>
                    </div>
                    
                </div>

            </Modal>
        </>
    )

}

export default ShareModal