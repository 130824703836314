import gql from "graphql-tag";
export const CONTRIBUTOR_OKR_LIST = gql`
query contributors($employee: ID){
    contributors(employee:$employee){
        edges{
          node{
            id
            okr{
              title
              id
              okrType
              isDiscarded{
                id
                status
              }
              owner {
                id
                user {
                  id
                  firstName
                }
              }
            }
          }
        }
      }
}
`;
