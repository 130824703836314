import gql from "graphql-tag";

export const REPORTING_TEAMS_MApp_ANCHORS_STATE_QUERY = gql`
  query reportingTeamsMAppAnchorsState {
    reportingTeamsMAppAnchorsState @client {
      filter {
        includingManager
      }
    }
  }
`;
