
import _ from 'lodash';
import { graphql } from '@apollo/client/react/hoc';
import { message } from 'antd';
import { CREATE_FEEDBACK_MUTATION } from 'modules/feedback/graphql/createfeedbackmutation.gql';
import { FEEDBACK_SURVEY_BY_ID } from 'modules/feedback/graphql/FeedBackSurveyByid.gql';
import { FEEDBACK_SURVEY_ID } from 'config';
import { USER_EMPLOYEE_QUERY } from 'modules/feedback/graphql/employees.gql';
import { UPDATE_MEETIING_MUTATION } from 'modules/one-on-one/graphql/UpdateMeeting.gql';
import { CREATE_BADGE_AWARD } from 'modules/kudos-user/graphql/createBadgeAwardMutation.gql';

export const withCreateFeedback = Component =>
  graphql(CREATE_FEEDBACK_MUTATION, {
    props: ({ mutate, history }) => ({
      createFeedback: async values => {
        try {
          const {
            data: { createFeedback }
          } = await mutate({
            variables: {
              ...values
            }
          });

          return createFeedback.feedback;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
          console.error(e);
        }
      }
    })
  })(Component);



  export const withCreateBadgeAward = Component =>
  graphql(CREATE_BADGE_AWARD, {
    props: ({ mutate, history }) => ({
      createBadgeAward: async values => {
        try {
          const {
            data: { createBadgeAward }
          } = await mutate({
            variables: {
              ...values
            }
          });
          
          return createBadgeAward.badgeAward;
        } catch (e) {
          message.destroy();
          message.error("Failed to create Badge Award");
          console.error(e);
        }
      }
    })
  })(Component);

  export const UseremployeeList = Component =>
  graphql(USER_EMPLOYEE_QUERY, {
    props({ data: { loading, error, allEmployee} }) {
      return { loading, error, allEmployee };
    }
   
  })(Component);