// @ts-nocheck
import { message } from 'antd';
import _ from 'lodash';
import { graphql } from '@apollo/client/react/hoc';
import { CREATE_NOTIFICATION_MUTATION } from '../graphql/CreateNotificationMutation.gql'
import { READ_NOTIFICATION_MUTATION } from '../graphql/ReadNotificationMutation.gql'
import { UNREAD_NOTIFICATION_MUTATION } from '../graphql/unReadnotification.gql';
// Mutation
export const withCreateNotification = Component =>
  graphql(CREATE_NOTIFICATION_MUTATION, {
    props: ({ mutate, history }) => ({
        createNotification: async values => {
        try {
          const {
            data: { createNotification }
          } = await mutate({
            variables: {
              ...values
            }
          });

          message.destroy();
          message.success('Notification sent successfully');
          return createNotification.notifications;
        } catch (e) {
          message.destroy();
          message.error("Failed to sent Notification");
          console.error(e);
        }
      }
    })
  })(Component);

  export const withReadNotification = Component =>
  graphql(READ_NOTIFICATION_MUTATION, {
    props: ({ mutate, history }) => ({
      readNotification: async values => {
        try {
          const {
            data: { readNotification }
          } = await mutate({
            variables: {
              ...values
            }
          });
          return readNotification.notification;
        } catch (e) {
          console.error(e);
        }
      }
    })
  })(Component);

  export const withUndoNotification = Component =>
  graphql(UNREAD_NOTIFICATION_MUTATION, {
    props: ({ mutate, history }) => ({
      unreadNotification: async values => {
        try {
          const {
            data: { unreadNotification }
          } = await mutate({
            variables: {
              ...values
            }
          });
          return unreadNotification.notification;
        } catch (e) {
          console.error(e);
        }
      }
    })
  })(Component);
