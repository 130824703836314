import React from "react";
import CompetecnyView from "../okrCompetecnyView";


const CompetencyContainer =(props)=>{

    const {setDeletedCompetencies} = props

    return(
        <>
        <CompetecnyView 
        setDeletedCompetencies = {setDeletedCompetencies}
        {...props}/>
        </>
    )
}
export default CompetencyContainer