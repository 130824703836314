import React from 'react';
import AddNewEmployeeView from '../components/addNewEmployeeView';
import { DASHBOARD_ALL_EMPLOYEES, DASHBOARD_TEAMS_BY_ID } from 'modules/home/graphql/teamDashboardQueries.gql';
import { compose } from 'modules/core';
import { withUpdateTeam } from './teamsDashboard.operation';
import { withApollo } from '@apollo/client/react/hoc';
import { message } from 'antd'

const AddNewEmployeeContainer = ( props ) => {
    const { client, me, team_id, updateTeam, onCancel,dataReload } = props
    const [ all_employee_list, setAllEmployeeList ] = React.useState()
    const [ loading, setLoading ] = React.useState( false )
    const [ team_employees_list, setTeamEmployeesList ] = React.useState()
    const [ all_employee_info, setAllEmployeeInfo ] = React.useState()
    const filter_ref = React.useRef( {} )
    const all_employee_list_ref = React.useRef( [] )
    React.useEffect( () => {
        if ( me ) {
            allEamployeeList()
            getTeamsById( team_id)
        }
    }, [ me ] )
    React.useEffect( () => {
        all_employee_list_ref.current = all_employee_list
    }, [ all_employee_list ] )

    const getTeamsById = async ( id ) => {
        const { data } = await client.query( {
            query: DASHBOARD_TEAMS_BY_ID,
            fetchPolicy: 'network-only',
            variables: { id: id }
        } );
        if ( data?.Team ) {
            setTeamEmployeesList( data?.Team?.employees?.edges?.map( ( { node } ) => node ) )
        }
    }

    const allEamployeeList = async ( filter ) => {
        filter_ref.current = { ...filter, after: null } || {}
        setLoading( true )
        const { data } = await client.query( {
            query: DASHBOARD_ALL_EMPLOYEES,
            fetchPolicy: 'network-only',
            variables: { ...filter, first: 15 }
        } );
        if ( data?.allEmployees ) {
            setAllEmployeeInfo( { ...data?.allEmployees?.pageInfo } )
            let list = data?.allEmployees?.edges?.map( ( { node } ) => node )
            // to handle pagination if after is present its a paginated data
            if ( filter?.after ) {
                list = all_employee_list_ref.current.concat( list )
            }
            setAllEmployeeList( list )
            setLoading( false )
        }
    }

    const addEmployees = async ( value ) => {
        let response = await updateTeam( value )
        if ( response?.id ) {
            message.success( 'Updated Successfully' )
            dataReload()
            onCancel()
        }
    }
    return (
        <>
            <AddNewEmployeeView {...props}
                all_employee_list={all_employee_list}
                loading={loading}
                onSearch={( e ) => ( allEamployeeList( { nameIcontains: e } ) )}
                team_employees_list={team_employees_list}
                handleSubmit={( value ) => addEmployees( value )}
                getAllEmployees={( filter ) => { allEamployeeList( { ...filter_ref.current, ...filter } ) }}
                all_employee_info={all_employee_info} />
        </>
    )
}

export default compose( withApollo, withUpdateTeam )( AddNewEmployeeContainer )