import gql from 'graphql-tag';
import { SIMPLE_FIELD_TYPE } from './SimpleFieldType.gql';

export const ADD_SIMPLE_FIELD_MUTATION = gql`
  ${SIMPLE_FIELD_TYPE}
  mutation createSimpleField($simpleFieldData: SimpleFieldInput!) {
    createSimpleField(simpleFieldData: $simpleFieldData) {
      simpleField {
        ...SimpleFieldType
      }
    }
  }
`;
