export default [
  {
    label: 'Greater than',
    value: '>',
    fetchValue: '_',
  },
  {
    label: 'Greater than Equal to',
    value: '>=',
    fetchValue: '__1',
  },
  {
    label: 'Equals to',
    value: '==',
    fetchValue: '__2',
  },
  {
    label: 'Not equals to',
    value: '!=',
    fetchValue: '__3',
  },
  {
    label: 'Smaller than',
    value: '<',
    fetchValue: '__4',
  },
  {
    label: 'Smaller than Equal to',
    value: '<=',
    fetchValue: '__5',
  }
]











