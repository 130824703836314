import React from 'react';
import { Link } from 'react-router-dom';

import SURVEY_ROUTE from '../../survey/route';
import { compose } from '../../core';
import { AdminLayout, displayDataCheck, GetColumnSearchProps, getIntFromString, getOrderBy } from '../../look';
import ROUTE from '../route';

import {
  withDeleteVertical,
  withOrganizationFilterUpdating,
  withVerticals,
  withOrganizationState
} from './OrganizationOperation';
import { subscribeToVertical } from './OrganizationSubscriptions';
import ReactMarkdown from '../../look/components/ReactMarkdown';

export const verticalColumns = (onOrderByChange, orderBy, searchFunc) => [
  {
    title: 'Name',
    key: 'name',
    sorter: () => onOrderByChange(getOrderBy('name', orderBy)),
    ...GetColumnSearchProps('user', searchFunc.onNameChange),
    render: (text, record) =>
      record && (
        // <Link to={`${SURVEY_ROUTE.verSurveyLink}${getIntFromString(record.id)}`}>{displayDataCheck(record.name)}</Link>
        <Link to={`${ROUTE.verticalDetailLink}${getIntFromString(record.id)}`}>{displayDataCheck(record.name)}</Link>
      )
  },
  {
    title: 'Description',
    key: 'description',
    sorter: () => onOrderByChange(getOrderBy('description', orderBy)),
    ...GetColumnSearchProps('description', searchFunc.onDescriptionChange),
    render: (text, record) => record && <ReactMarkdown>{record.description}</ReactMarkdown>
  },
  {
    title: 'Organization',
    key: 'organization',
    ...GetColumnSearchProps('organization', searchFunc.onOrganizationChange, 'organization'),
    render: (text, record) => record && displayDataCheck(record.organization.name)
  },
  {
    title: 'Sub-Vertical',
    key: 'subVertical',
    ...GetColumnSearchProps('subvertical', searchFunc.onSubverticalChange, 'subvertical'),
    render: (text, record) => record && displayDataCheck(record.subvertical?.name)
  },
  {
    title: 'Head',
    key: 'head',
    render: (text, record) =>
      record && record.head && displayDataCheck(`${record.head.user.firstName} ${record.head.user.lastName}`)
  }
];

const Vertical = props => {
  const {
    loading,
    verticals,
    onPaginationChange,
    deleteVertical,
    onOrderByChange,
    orderBy,
    onNameChange,
    onDescriptionChange,
    onFiltersRemove,
    onOrganizationChange,
    onSubverticalChange,
    subscribeToMore
  } = props;

  React.useEffect(() => {
    const subscribe = subscribeToVertical(subscribeToMore /* , props.filter */);
    return () => subscribe();
  });

  return (
    <AdminLayout
      table={true}
      active={ROUTE.vertical}
      title="Vertical"
      loading={loading}
      tableData={verticals}
      columns={verticalColumns(onOrderByChange, orderBy, {
        onNameChange,
        onDescriptionChange,
        onOrganizationChange,
        onSubverticalChange
      })}
      onDelete={deleteVertical}
      editLink={ROUTE.editVerticalLink}
      // addLink={ROUTE.addVertical}
      onPaginationChange={onPaginationChange}
      onFiltersRemove={onFiltersRemove}
    />
  );
};

export default compose(withOrganizationState, withDeleteVertical, withVerticals, withOrganizationFilterUpdating)(Vertical);
