import delete_icon from '../assets/delete-rd.svg'
import edit_icon from '../assets/edit_role_definition.svg'
import long_term_goal from '../assets/Long-term-goal.svg'
import short_term_goal from '../assets/Short-term-goal.svg'
import pending_identification from '../assets/idp-pending-identification.svg'
import apporved_identification from '../assets/idp-approved-identification.svg'
import revise_identification from '../assets/idp-revise-identification.svg'
import chip_close_icon from '../assets/chip_close_icon.svg'
import back_arrow from '../assets/new_back_arrow.svg'
import calender from '../assets/icons/calender.svg'
export const idp_home_icons ={
    delete_icon,
    edit_icon,
    long_term_goal,
    short_term_goal,
    pending_identification,
    apporved_identification,
    revise_identification,
    calender
}

export const idp_add_icons = {
    chip_close_icon,
    back_arrow,
    calender
}
export const idp_tam_icons ={
    back_arrow
}