import { Button, Dropdown, Input, Select, Spin, Tooltip, message } from "antd";
import Growpagination from "modules/grow-model/components/growPagination";
import { color_list_for_assessment, displayUtcToUserTimezone, getIntFromString, getVerticalBase64StringFromInt } from "modules/look";
import { dashboard_home_icons, organization_icons } from "modules/poc-dashboard/icons";
import React from "react";
import styled from "styled-components";
import PocDashboartBarChart from "./DashboardSurveyBarChart";
import ExportReportContainer from "modules/poc-dashboard/containers/dashboard/exportReportContainer";
import poc_dashboard_routes from "modules/poc-dashboard/routes";

const SurveyResponseVerticalView = (props) => {
    const { tableData, table_loading, SetRemainderData, setSelectedEmployees, selected_employees, setSelectAll, select_all, verical_response_page_info, getVerticalResponseList, vertical_list, selected_vertical_detail, setSelectedVerticalDetail, selected_survey_detail, org_survey_list, setSelectedSurveyDetail, navigateRoute,team_assessment_data,setSelectedTeamDetail,selected_team_detail,getTeamSurveyResponse} = props
    const [export_view, setExportView] = React.useState(false)
    const [filter, setFilter] = React.useState()
    const [graph_data_list, setGraphDataList] = React.useState([])
    const [avarage_percentage, setAvaragePercentage] = React.useState()
 
    React.useEffect(() => {
        if (select_all) {
            let list = tableData?.filter(item => item?.status == "Pending")
            setSelectedEmployees(list?.map(item => item?.user_id))
        }
        else {
            setSelectedEmployees([])
        }
    }, [select_all])
    const generateRandomRGBA = () => {
        const red = Math.floor(Math.random() * 256);
        const green = Math.floor(Math.random() * 256);
        const blue = Math.floor(Math.random() * 256);
        const alpha = Math.random() * (1 - 0.5) + 0.5;
        return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
    }
    React.useEffect(() => {
        if (org_survey_list?.length > 0) {
            setFilter({ id: org_survey_list[0]?.id, name: org_survey_list[0]?.name })
        }
    }, [org_survey_list])
    const changeEmployeeList = (value) => {
        let lists = [...selected_employees]

        if (lists?.includes(value)) {
            let index = lists?.indexOf(value)
            lists.splice(index, 1)
            setSelectedEmployees(lists)
        }
        else {
            lists.push(value)
            setSelectedEmployees(lists)
        }
    }
    React.useEffect(() => {
        if(team_assessment_data?.length>0){ 
            let graph_data = team_assessment_data?.map((item,index)=>{
                let random_color = generateRandomRGBA()
                return {
                    title: item?.Team_Name,
                    value: item["Completed%"],
                    color:  color_list_for_assessment[index] || random_color,
                    detail: "",
                    id: item?.Team_Id
                }
            })
            setGraphDataList(graph_data)
            let percentages = team_assessment_data?.map(item => item["Completed%"]) || [];
            let sum = percentages.reduce((acc, percentage) => acc + percentage, 0);
            let average = percentages.length > 0 ? Math.round(sum / percentages.length) : 0;
            setAvaragePercentage(average)
        }
        else{
            setGraphDataList([])
            setAvaragePercentage(0)
        }
    },[team_assessment_data])

    const onSubmit = () => {
        if (selected_employees?.length > 0) {
            let data = tableData?.filter(i => selected_employees?.includes(i?.user_id))
            let data_set = data?.map(i => {
                return {
                    id: getIntFromString(selected_survey_detail?.id),
                    usr: getIntFromString(i?.user_id),
                    tlink: i?.is_360 ? i?.link : ""
                }
            })
            SetRemainderData(data_set)
        }
        else {
            message.error("Please select a employee!")
        }
    }

    const navigateTo360Report=(item)=>{
        navigateRoute(poc_dashboard_routes?.assessment_report.replace(":user_id",getIntFromString(item?.employee_id)).replace(":responseid",getIntFromString(item?.response_id)))
    }

    return (
        <>
            <Spin spinning={table_loading}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "start", gap: "1em", alignItems: "center" }}>
                    <img src={organization_icons?.back_arrow} alt="back_arrow" style={{ width: "25px", cursor: "pointer" }} onClick={() => navigateRoute("-1")} />
                    <h4 className="poc-dashboard-card-title" style={{ fontWeight: "600" }}>Survey Status</h4>
                </div>
                <div className="poc-dahboard-root poc-grap-container" style={{ marginTop: "1em" }}>
                    <div className='poc-org-nav-view' style={{ flexDirection: "column", marginTop: "1em" }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%", padding: "0px 1em" }}>
                            {selected_vertical_detail && <div className='border-line' style={{ maxWidth: "17em", borderBottom: "5px solid #4CCB1F" }}>
                                <CustomSelect className="vertical-select" style={{ border: "none" }} defaultValue={selected_vertical_detail?.name} onChange={(e, item) => setSelectedVerticalDetail({ id: item?.key, name: item?.value })}>
                                    {vertical_list?.map(vertical => (
                                        <Select.Option className='dashobard-performance-table-heading' style={{fontWeight:"500"}} key={vertical?.id} value={vertical?.name}  >{vertical?.name}</Select.Option>
                                    ))}
                                </CustomSelect>
                            </div>}
                            <div className='border-line' style={{ width: "100%", display: "flex", justifyContent: "end" }}>
                                <Button className="export-btn" style={{ marginBottom: ".5em" }} onClick={()=>setExportView(true)}><img src={dashboard_home_icons?.export_icon} alt="export_icon" style={{ height: "1.25em", marginRight: "1em" }}  /> Export</Button>
                            </div>

                        </div>
                        {/* <div className='border-line'></div> */}
                    </div>
                    {org_survey_list?.length > 0 && selected_survey_detail && <div className="dasboard-banner-card" style={{ width: "100%" }}>
                        <div style={{ display: "flex", justifyContent: 'space-between', gap: "1em", alignItems: 'center', width: "100%", flexWrap: "wrap" }}>
                            <h4 className="poc-dashboard-card-title">Survey Assessment Status</h4>
                            <CustomSelectSurvey dropdownClassName="content-dropdown" onChange={(e, item) => setSelectedSurveyDetail({ id: item?.key, name: item?.value,is_360:item?.label })} defaultValue={selected_survey_detail?.name}>
                                {org_survey_list?.map(item => (
                                    <Select.Option className='dashobard-performance-table-heading' style={{ color: '#A3A3A3', fontFamily: "Poppins", fontWeight: 500 }} key={item?.id} value={item?.name} label={item?.multisourceResponse}>{item?.name}</Select.Option>
                                ))}
                            </CustomSelectSurvey>
                        </div>
                        <PocDashboartBarChart table_loading={table_loading} filter={selected_survey_detail} graph_data_list={graph_data_list} noDataMessage={'No Team Assessment Found'} requiredNavigation={false} {...props} fetchTeamData={(value)=>setSelectedTeamDetail(value)} />
                    </div>}
                    <h4 className="poc-banner-title" style={{ paddingLeft: "1em", fontSize: '1em' }}>{selected_vertical_detail?.name} {selected_survey_detail?.name} completion Status :<span style={{ fontWeight: 700, color: "rgba(0, 202, 100, 1)" }}> {avarage_percentage}%</span></h4>
                </div>
                <div className="poc-dahboard-root poc-org-root-style" style={{ marginTop: "1em", padding: '1em' }}>
                    <div style={{ width: '100%', display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: '1em' }}>
                        <h4 className="poc-banner-title">{selected_team_detail?selected_team_detail?.title:selected_vertical_detail?.name}</h4>
                        <div style={{ position: "relative", width: "100%", maxWidth: "17.5em", display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center", gap: ".25em" }}>
                            <img src={dashboard_home_icons?.search_icon} alt="search_icon" style={{ height: "20px" }} />
                            <Input style={{ width: "100%", border: "none", height: "3em", fontSize: "1em", fontFamily: "Poppins" }} placeholder="Search" onChange={(e) => {selected_team_detail?getTeamSurveyResponse({ user_FirstName: e?.target?.value }):getVerticalResponseList({ user_FirstName: e?.target?.value })}} />
                        </div>
                    </div>

                    {/* <Spin spinning={table_loading}> */}
                    <div className='org-table-container'>
                        <table className='org-table'>
                            <thead>
                                <tr className='org-row-header'>
                                    <th className='org-table-row-padding-left org-left-radious' style={{ textAlign: "left", width: "25%" }}>
                                        <h4 className='org-table-header-label' style={{ paddingLeft: "3em" }}>Employee</h4>
                                    </th>
                                    <th className='org-table-header-label' style={{ textAlign: "center" }}>
                                        <h4 className='org-table-header-label'>Team</h4>
                                    </th>
                                    <th className='org-table-header-label' style={{ textAlign: "center" }}>
                                        <h4 className='org-table-header-label'>Response date </h4>
                                    </th>
                                    <th className='org-table-header-label' style={{ textAlign: "center" }}>
                                        <h4 className='org-table-header-label'>Status</h4>
                                    </th>
                                    <th className='org-table-row-padding-rigth org-content-center org-table-header-label org-right-radious'>
                                        <h4 className='org-table-header-label'>Actions</h4>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {selected_employees?.length > 0 && <tr>
                                    <td className="org-table-row-padding-left" style={{ display: 'flex', flexDirection: "row", justifyContent: "start", alignItems: 'center', gap: "1em" }}>
                                        {select_all ? <img src={dashboard_home_icons?.check_box} style={{ width: "30px", cursor: "pointer" }} alt="check_box" onClick={() => setSelectAll(false)} /> :
                                            <div className="dashboard-check-box" onClick={() => setSelectAll(true)}></div>}
                                        <h4 className='org-table-body-label' style={{ fontWeight: '500' }}>Select All</h4>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td style={{ display: "flex", justifyContent: "end" }}><Button className="export-btn" style={{ fontSize: '1em', fontWeight: "500", height: "2.5em", color: "rgb(76, 203, 31)", borderColor: "rgb(76, 203, 31)" }} onClick={() => onSubmit()}>Send Reminder</Button></td>
                                </tr>}
                                {tableData?.length > 0 ? tableData?.map(item => (
                                    <tr className='org-row-body'>
                                        <td className='org-left-radious org-table-row-padding-left table-border-left' style={{ borderRight: "none" }}>
                                            <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'start', alignItems: "center", gap: "1em" }}>
                                                {item?.status == "Pending" ? <div>
                                                    {selected_employees?.includes(item?.user_id) ?
                                                        <img src={dashboard_home_icons?.check_box} style={{ width: "30px", cursor: "pointer" }} alt="check_box" onClick={() => changeEmployeeList(item?.user_id)} /> :
                                                        <div className="dashboard-check-box" onClick={() => changeEmployeeList(item?.user_id)}></div>}
                                                </div> : <div className="dashboard-check-box" style={{ border: "none" }}></div>}
                                                {item?.profileImage ? <img style={{ width: "3em", height: "3em", borderRadius: "50%", backgroundColor: "rgba(217, 217, 217, 1)" }} src={item?.profileImage} alt="profileImage" /> : <img style={{ width: "3em", height: "3em", borderRadius: "50%", backgroundColor: "rgba(217, 217, 217, 1)" }} src={dashboard_home_icons?.profile_icon} alt="profile_icon" />}
                                                <h4 className='org-table-body-label' style={{ fontWeight: '500' }}>{item?.firstName} {item?.lastName}</h4>
                                            </div>
                                        </td>
                                        <td className='table-border-up-down' style={{ textAlign: "center", borderRight: "none", borderLeft: "none" }}>
                                            <h4 className='org-table-body-label' style={{ fontWeight: '500' }}>{item?.team?.map(member => member?.name).join(item?.team?.length > 1 ? ', ' : '')}</h4>
                                        </td>
                                        <td className='table-border-up-down' style={{ textAlign: "center", borderRight: "none", borderLeft: "none" }}>
                                            <h4 className='org-table-body-label' style={{ fontWeight: '500' }}>{item?.responseDate ? displayUtcToUserTimezone(item?.responseDate, 'MMM DD YYYY, hh:mm') : '-'}</h4>
                                        </td>
                                        <td className='table-border-up-down' style={{ textAlign: "center", borderRight: "none", borderLeft: "none" }}>
                                            <div style={{ width: "100%",display:'flex',justifyContent:'center',alignItems:'center' }}>
                                                <div className={item?.status == 'Completed' ? 'org-survey-completed' : 'org-survey-pending'} style={{ fontWeight: '500', width: "8em", }}>{item?.status}</div>
                                            </div>

                                        </td>
                                        <td className='org-table-row-padding-rigth org-content-center org-right-radious table-border-right' style={{ borderLeft: "none" }}>
                                            {item?.status == 'Completed'&&item?.is_360?
                                            <Tooltip title={"View Report"}>
                                            <img src={dashboard_home_icons?.report_icon} alt="report_icon" style={{ cursor: "pointer" }} onClick={()=>navigateTo360Report(item)}/>
                                            </Tooltip>:"-"}
                                        </td>
                                    </tr>
                                )) : null}
                            </tbody>
                        </table>
                    </div>
                    {(verical_response_page_info?.hasNextPage && verical_response_page_info?.cursor) && <div align='center'>
                        <Growpagination on_pagination={() => {selected_team_detail?getTeamSurveyResponse({ cursor: verical_response_page_info?.cursor }):getVerticalResponseList({ cursor: verical_response_page_info?.cursor }) }} />
                    </div>}
                    {/* </Spin> */}
                </div>
            </Spin>
            <ExportReportContainer export_view={export_view} onCancel={(e) => setExportView(e)}  {...props} />
        </>
    )
}

export default SurveyResponseVerticalView

const CustomSelect = styled(Select)`
.ant-select-selector{
    border: none !impoortant;
    color:  #4CCB1F;
    font-weight: 500;
    font-family: Poppins;
    font-size: 1em;
    line-height: normal;
    letter-spacing: 0.01em;
    text-align: left;
    border-radius:none !important;
    width:17em !important;
}
`;
const CustomSelectSurvey = styled(Select)`
.ant-select-selector{
    border: 1px solid #AABAC6 !impoortant;
    color:  #000;
    font-weight: 500;
    font-family: Poppins;
    font-size: .85em;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.01em;
    text-align: left;
    border-radius:.75em !important;
    width:250px !important;
}
`;