import gql from 'graphql-tag';
// import { USER_TYPE } from '../../user/graphql/UserType.gql';
// import { CUSTOM_FORM_SECTION_TYPE } from './Custom_FormSectionType.gql';
// import { SIMPLE_FIELD_TYPE } from './SimpleFieldType.gql';

// ${USER_TYPE}, ${CUSTOM_FORM_SECTION_TYPE}, ${SIMPLE_FIELD_TYPE},
export const SIMPLE_FIELD_RESPONCES_TYPE = gql`
  fragment SimpleFieldResponcesType on SimpleFieldResponcesType {
    id
    # user {
    #   ...UserType
    # }
    # formSection {
    #   ...Custom_FormSectionType
    # }
    # simpleField {
    #   ...SimpleFieldType
    # }
    answer
    responseDate
  }
`;
