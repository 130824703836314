
import React from 'react';
import SoulIdentityView from '../components/SoulIdentityView';


const SoulIdentityContainer = props => {

   
    return (
       
        <>
         <SoulIdentityView/>
        </>
       
    );
};

export default SoulIdentityContainer;