// @ts-nocheck
import React from "react";
import { compose } from "../../core";
import CompetencyMainView from "../components/CompetencyMainView";
import '../css/CompetencyStyle.css'

const CompetencyContainer = (props) => {

    const { client, me, permission, history } = props
    const [current_comptencymain_tab, SetCurrentCompetencyMainTab] = React.useState()

    // to set the route to local storage when child route is changed
    React.useEffect(() => {
        if (current_comptencymain_tab) {
            let role_definition_navigation = {
                parent_route: { key: 'competency', label: 'Competency' },
                child_route: current_comptencymain_tab
            }
            localStorage.setItem('role_definition_navigation', JSON.stringify(role_definition_navigation))
        }
    }, [current_comptencymain_tab])

    // to handle child route when user navigate back to role definition
    const competency_navigation = JSON.parse(localStorage?.getItem('role_definition_navigation'))
    if (!current_comptencymain_tab) {
        if (competency_navigation?.child_route?.key?.includes('competencymain_')) {
            SetCurrentCompetencyMainTab(competency_navigation?.child_route)
        } else {
            SetCurrentCompetencyMainTab({ key: "competencymain_home_view" })
        }
    }

    return (
        <CompetencyMainView
            {...props}
            current_comptencymain_tab={current_comptencymain_tab}
            SetCurrentCompetencyMainTab={(e) => { SetCurrentCompetencyMainTab(e) }}
        />
    )

}

export default compose()(CompetencyContainer);