import React, { useState } from "react";
import { Col, Card, Modal, Row, Progress, Form } from "antd";

import { compose } from "../../core";
import { getStrokeColor } from "../../look";
import { withMe } from "../../user/containers/UserOperations";

import TeamsMAppPulseCheck from "../components/pulse-check/TeamsMAppPulseCheck";
import RenderMyTeamsMAppPulseCheck from "../components/pulse-check/RenderMyTeamsMAppPulseCheck";
import RenderMyVerticalMAppPulseCheck from "../components/pulse-check/RenderMyVerticalMAppPulseCheck";
import RenderMyOrganizationMAppPulseCheck from "../components/pulse-check/RenderMyOrganizationMAppPulseCheck";

import { withmyResiliencePulseCheck } from "./DashboardOperations";

const FormItem = Form.Item;

const MAppPulseCheck = (props) => {
  const { myResiliencePulseCheck, me, personal, manager } = props;
  const [visible, setVisible] = useState(false);

  const isEmployee = me?.employee;
  const isManager = manager && isEmployee;
  return (
    <>
      <Col span={24}>
        <Card
          bodyStyle={{
            boxShadow: "5px 0px 25px 3px rgba(32, 32, 32, 0.1)",
            borderRadius: "10px",
            paddingBottom: 0,
            paddingTop: isManager ? 20 : 0,
            cursor: "pointer",
          }}
          bordered={true}
          hoverable
          onClick={() => (isManager ? null : setVisible(true))}
        >
          {isManager ? (
            <TeamsMAppPulseCheck />
          ) : (
            <h2
              align="center"
              style={{
                margin: "1em 0",
                fontWeight: "normal",
                color: visible ? "#881FCC" : "#222",
              }}
            >
              Resilence Pulse Check
            </h2>
          )}
        </Card>
      </Col>

      <Modal
        footer={null}
        centered
        visible={/* true || */ visible}
        width={"40%"}
        destroyOnClose={true}
        onCancel={() => setVisible(false)}
        // bodyStyle={{ padding: '0px' }}
      >
        <Row>
          <Col span={22}>
            {personal && (
              <>
                <FormItem
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24, offset: 1 }}
                  label={"User’s MApp Pulse Check"}
                >
                  <Progress
                    format={(percent) => percent / 10 + "%"}
                    percent={(myResiliencePulseCheck || 0).toFixed(2) * 10}
                    strokeColor={getStrokeColor(myResiliencePulseCheck || 0)}
                  />
                </FormItem>

                {isEmployee && <RenderMyTeamsMAppPulseCheck />}
                {isEmployee && <RenderMyVerticalMAppPulseCheck />}
                {isEmployee && <RenderMyOrganizationMAppPulseCheck />}
              </>
            )}

            {manager && isEmployee && <TeamsMAppPulseCheck />}
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default compose(withMe, withmyResiliencePulseCheck)(MAppPulseCheck);

// modifications on line
// 8(2)
// 9(2)
// 10(2)
// 11(2)
// 13
// 17
// 18
// 39
// 64
// 68
// 69
// 73
// 74
// 75
// 79
// 87(2)
