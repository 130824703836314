import gql from 'graphql-tag';
import { STORY_TYPE } from './StoryType.gql';

export const PUBLISHED_STORY_BY_ID_QUERY = gql`
  ${STORY_TYPE}
  query publishedStoryById($id: ID!) {
    publishedStoryById(id: $id) {
      ...StoryType
    }
  }
`;
