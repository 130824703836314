import gql from 'graphql-tag';
import { COMMITMENT_TYPE } from './CommitmentType.gql';

export const COMMITMENT_TYPE_CONNECTION = gql`
  ${COMMITMENT_TYPE}
  fragment CommitmentTypeConnection on CommitmentTypeConnection {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...CommitmentType
      }
    }
    totalCount
    edgeCount
  }
`;
