import React from "react";
import AddEmployeeLevelView from "../components/AddEmployeeLevelView";
import { withCreateEmployeeLevel } from "../RoleDefinitionOperation";
import { compose } from "modules/core";
import { message } from "antd";

const AddEmployeeLevelContainer =(props)=>{
    const {onCancel,createEmployeeLevel,employee_level_list}=props
    const [api_loader,SetApiLoader] = React.useState(false)
    //Create a new employee level
    const createNewEmployeeLevel = async(data)=>{
        try {
            let list = employee_level_list?.map(i=>i?.name?.toLowerCase()) || []
            if(list?.includes(data?.name?.toLowerCase())){
                message.destroy()
                message.error("Employee level with same name already exist")
                return
            }
            SetApiLoader(true)
            let employeeLevelData ={
                name:data?.name,
                description:data.description
            }
            let response  = await createEmployeeLevel({employeeLevelData})
            if(response?.id){
                SetApiLoader(false)
                onCancel(false)
            }else{
                SetApiLoader(false)
            }
        } catch (error) {
            SetApiLoader(false)
           message.error("failed to create employee level") 
        }
    }
    
    return (
        <>
        <AddEmployeeLevelView {...props} api_loader={api_loader} onSubmit={(data)=>createNewEmployeeLevel(data)}></AddEmployeeLevelView>
        </>
    )

}

export default compose(withCreateEmployeeLevel)(AddEmployeeLevelContainer)