import gql from "graphql-tag";

export const UPCOMING_MEETING = gql`
query allMeetings($when_Range: [DateTime],$title_Icontains:String,$orderBy: [String],$endedAt_Isnull:Boolean,$actionitem_Title_Icontains:String,$owner_User_FirstName_Icontains: String,$member_User_FirstName_Icontains: String,$offset: Int,$first: Int){
  upcomingMeeting:allMeetings(
    when_Range:$when_Range,
    title_Icontains:$title_Icontains,
    orderBy:$orderBy,
    endedAt_Isnull:$endedAt_Isnull,
    actionitem_Title_Icontains:$actionitem_Title_Icontains,
    owner_User_FirstName_Icontains:$owner_User_FirstName_Icontains,
    member_User_FirstName_Icontains:$member_User_FirstName_Icontains,
    offset:$offset,
    first:$first
  ) {
      pageInfo{
        hasNextPage
      }
      totalCount
      edges {
        node {
          id
          title
          rrule
          summary
          event: when
          nextMeeting {
            totalCount
          }
          actionitemSet {
            edges {
              node {
                id
                title
                meeting {
                  id
                  title
                }
                okr {
                  id
                  okrType
                  title
                }
                commitment {
                  id
                  commitment {
                    id
                    title
                  }
                  completed
                }
                coachingCommitment {
                  id
                  coachingcommitment {
                    id
                    title
                  }
                  completed
                }
                notes
                completed
                isAgenda
                createdAt
                updatedAt
              }
            }
          }
          member {
            edges {
              node {
                id
                user {
                  id
                  firstName
                  lastName
                  email
                  profile {
                    id
                    profileImage
                  }
                }
              }
            }
          }
          owner {
            id
            user {
              id
              firstName
              lastName
            }
          }
        }
      }
    }
}
`;