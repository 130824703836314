import React from 'react';
import _ from 'lodash';
import { Spin } from 'antd';

import { compose } from '../../core';
import { getIntFromString, ButtonsCatch, withMarkdownFieldValue } from '../../look';

import ROUTE from '../route';
import AddTeamView from '../components/AddTeamView';
import { CKEditorFieldName } from '../components/TeamFormComponent';

import { withAddTeam, withOrganizationState } from './OrganizationOperation';

const AddTeam = props => {
  const { history, createTeam, verId, setVisible,TeamDataReasponse } = props;
  const [ submitLoading, setSubmitLoading ] = React.useState( false );

  const FormLoading = ( value ) => {
    console.log( "value", value )
  }
  const handleSubmit = async ( values, item ) => {
    setSubmitLoading( true );
    const managers = _.get( values, 'managers' );
    const teamData = ( ( { name, description, verticalId } ) => ( { name, description, verticalId } ) )( values );
    // const employees = (({ employees }) => ({ employees }))(values);
    try {
      const response = await createTeam
      ( {
        employees: values.employees,
        managers,
        teamData: withMarkdownFieldValue( CKEditorFieldName, { ...teamData, verticalId: verId } )
      } );
      if(response){
        setVisible( false );
        ButtonsCatch(
          item,
          window.location.pathname,
          history,
          item === undefined
            ? `${ROUTE.editTeamLink}${getIntFromString( response.id )}`
            : `${window.location.pathname}?args=openmodal`
        );
        TeamDataReasponse(response);
      }
      // response && ButtonsCatch(item, ROUTE.team, history);
    } catch ( e ) {
      throw new Error( e.message );
    }
    setSubmitLoading( false );
  };

  return (
    <Spin spinning={submitLoading} size="large">
      <AddTeamView onSubmit={handleSubmit} {...props} onLoading={FormLoading} />
    </Spin>
  );
};

export default compose( withOrganizationState, withAddTeam )( AddTeam );
