import { graphql } from "@apollo/client/react/hoc";

import { removeTypename } from "../../core/clientStorage";
import { withOperation } from "../../look";

import dashboardResolvers from "../resolvers";
import { message } from 'antd'

// Query
import { PERCEPTION_SURVEY_REPORT } from "../graphql/PerceptionSurveyReport.gql";
import { PREDICTION_SURVEY_REPORT } from "../graphql/PredictionSurveyReport.gql";
import { MY_MApp_PULSE_CHECK } from "../graphql/myResiliencePulseCheck.gql";
import { MY_TEAMS_MApp_PULSE_CHECK } from "../graphql/MyTeamsMAppPulseCheck.gql";
import { MY_VERTICALS_MApp_PULSE_CHECK } from "../graphql/MyVerticalsMAppPulseCheck.gql";
import { MY_ORGANIZATION_MApp_PULSE_CHECK } from "../graphql/MyOrganizationMAppPulseCheck.gql";
import { TEAMS_MApp_PULSE_CHECK } from "../graphql/TeamsMAppPulseCheck.gql";
import { MY_MApp_ANCHORS } from "../graphql/myResilienceAnchors.gql";

import { GET_ALL_COACHING_COMMITMENTS } from "../graphql/GetAllCoachingCommitments.gql";
import { GET_ALL_DEFAULT_COMMITMENTS_PROGRESS } from "../graphql/GetAllDefaultCommitmentsProgress.gql";
import { GET_ALL_CUSTOM_USER_COMMITMENTS, GET_ALL_CUSTOM_USER_COMMITMENTS_FOR_COACHING } from "../graphql/GetAllCustomUserCommitments.gql";
import { ALL_COMPARISON_REQUEST_FOR_USER } from "../graphql/AllComparisonRequestForUser.gql";
import { ALL_COMPARISON_REQUEST_FROM_USER } from "../graphql/AllComparisonRequestFromUser.gql";

// Client
import { DASHBOARD_STATE_QUERY } from "../graphql/DashboardStateQuery.client.gql";
import { UPDATE_DASHBOARD_FILTER } from "../graphql/UpdateDashboardFilter.client.gql";

// Mutation
import { ADD_CUSTOM_USER_COMMITMENT } from "../graphql/AddCustomUserCommitment.gql";
import { ADD_COACHING_COMMITMENT } from "../graphql/AddCoachingCommitment.gql";
import { ADD_SELECT_DEFAULT_TOOL_COMMITMENT } from "../graphql/AddSelectDefaultToolCommitment.gql";
import { ADD_RESPONSE_COMPARE_REQUEST } from "../graphql/AddResponseCompareRequest.gql";
import { ADD_COACHING_COMMITMENT_PROGRESS } from "../graphql/AddCoachingCommitmentProgress.gql";

import { EDIT_DEFAULT_TOOL_COMMITMENT } from "../graphql/EditDefaultToolCommitment.gql";
import { EDIT_CUSTOM_USER_COMMITMENT } from "../graphql/EditCustomUserCommitment.gql";
import { EDIT_COACHING_COMMITMENT } from "../graphql/EditCoachingCommitment.gql";
import { RESPONSE_COMPARE_REQUEST } from "../graphql/RespondToResponseCompareRequest.gql";

import { DELETE_DEFAULT_TOOL_COMMITMENT } from "../graphql/DeleteDefaultToolCommitment.gql";
import { DELETE_CUSTOM_USER_COMMITMENT } from "../graphql/DeleteCustomUserCommitment.gql";
import { DELETE_RESPONSE_COMPARE_REQUEST } from "../graphql/DeleteResponseCompareRequest.gql";
import { DELETE_COACHING_COMMITMENT } from "../graphql/DeleteCoachingCommitment.gql";
import { DELETE_COACHING_COMMITMENT_PROGRESS } from "../graphql/DeleteCoachingCommitmentProgress.gql";
import { line } from "@antv/g2plot";

// import { ADD_STORY } from '../graphql/AddStory.gql';

// Query
export const withTheirPerceptionSurveyReport = (Component) =>
  graphql(PERCEPTION_SURVEY_REPORT, {
    options: ({ requestedUserId }) => {
      return {
        variables: { requestedUserId },
      };
    },
    props({
      data: {
        loading,
        error,
        getReportDataForRequestedUsersPerceptionSurvey,
        subscribeToMore,
        updateQuery,
      },
    }) {
      return {
        loading,
        error,
        theirReport:
          getReportDataForRequestedUsersPerceptionSurvey &&
          JSON.parse(getReportDataForRequestedUsersPerceptionSurvey),
        subscribeToMore,
        updateQuery,
      };
    },
  })(Component);

export const withTheirPredictionSurveyReport = (Component) =>
  graphql(PREDICTION_SURVEY_REPORT, {
    options: ({ requestedUserId }) => {
      return {
        variables: { requestedUserId },
      };
    },
    props({
      data: {
        loading,
        error,
        getReportDataForRequestedUsersPredictionSurvey,
        subscribeToMore,
        updateQuery,
      },
    }) {
      return {
        loading,
        error,
        theirReport:
          getReportDataForRequestedUsersPredictionSurvey &&
          JSON.parse(getReportDataForRequestedUsersPredictionSurvey),
        subscribeToMore,
        updateQuery,
      };
    },
  })(Component);

export const withmyResiliencePulseCheck = (Component) =>
  graphql(MY_MApp_PULSE_CHECK, {
    props({ data }) {
      const { loading, error, myResiliencePulseCheck, subscribeToMore, updateQuery } =
        data;
      return { loading, error, myResiliencePulseCheck, subscribeToMore, updateQuery };
    },
  })(Component);

export const withMyTeamsMAppPulseCheck = (Component) =>
  graphql(MY_TEAMS_MApp_PULSE_CHECK, {
    props({ data }) {
      const {
        loading,
        error,
        myTeamsMAppPulseCheck,
        subscribeToMore,
        updateQuery,
      } = data;
      return {
        loading,
        error,
        myTeamsMAppPulseCheck,
        subscribeToMore,
        updateQuery,
      };
    },
  })(Component);

export const withMyVerticalMAppPulseCheck = (Component) =>
  graphql(MY_VERTICALS_MApp_PULSE_CHECK, {
    props({ data }) {
      const {
        loading,
        error,
        myVerticalMAppPulseCheck,
        subscribeToMore,
        updateQuery,
      } = data;
      return {
        loading,
        error,
        myVerticalMAppPulseCheck,
        subscribeToMore,
        updateQuery,
      };
    },
  })(Component);

export const withMyOrganizationMAppPulseCheck = (Component) =>
  graphql(MY_ORGANIZATION_MApp_PULSE_CHECK, {
    props({ data }) {
      const {
        loading,
        error,
        myOrganizationMAppPulseCheck,
        subscribeToMore,
        updateQuery,
      } = data;
      return {
        loading,
        error,
        myOrganizationMAppPulseCheck,
        subscribeToMore,
        updateQuery,
      };
    },
  })(Component);

export const withTeamsMAppPulseCheck = (Component) =>
  graphql(TEAMS_MApp_PULSE_CHECK, {
    props({ data }) {
      const {
        loading,
        error,
        reportingTeamsMAppPulseCheck,
        subscribeToMore,
        updateQuery,
      } = data;
      return {
        loading,
        error,
        reportingTeamsMAppPulseCheck,
        subscribeToMore,
        updateQuery,
      };
    },
  })(Component);

export const withmyResilienceAnchors = (Component) =>
  graphql(MY_MApp_ANCHORS, {
    props({ data }) {
      const { loading, error, myResilienceAnchors, subscribeToMore, updateQuery } =
        data;
      return { loading, error, myResilienceAnchors, subscribeToMore, updateQuery };
    },
  })(Component);

export const withGetAllDefaultCommitmentsProgress = (Component) =>
  graphql(GET_ALL_DEFAULT_COMMITMENTS_PROGRESS, {
    props({ data }) {
      const {
        loading,
        error,
        getAllDefaultCommitmentsProgress,
        subscribeToMore,
        updateQuery,
      } = data;
      return {
        fetchPolicy: 'network-only',
        loading,
        error,
        getAllDefaultCommitmentsProgress,
        subscribeToMore,
        updateQuery,
      };
    },
  })(Component);

export const withGetAllCustomUserCommitments = (Component) =>
  graphql(GET_ALL_CUSTOM_USER_COMMITMENTS, {
    props({ data }) {
      const {
        loading,
        error,
        getAllCustomUserCommitments,
        subscribeToMore,
        updateQuery,
      } = data;
      return {
        fetchPolicy: 'network-only',
        loading,
        error,
        getAllCustomUserCommitments,
        subscribeToMore,
        updateQuery,
      };
    },
  })(Component);

  export const withGetAllCustomUserCommitmentsForCoaching = (Component) =>
  graphql(GET_ALL_CUSTOM_USER_COMMITMENTS_FOR_COACHING, {
    props({ data }) {
      const {
        loading,
        error,
        getAllCustomUserCommitments,
        subscribeToMore,
        updateQuery,
      } = data;
      return {
        fetchPolicy: 'network-only',
        loading,
        error,
        getAllCustomUserCommitments,
        subscribeToMore,
        updateQuery,
      };
    },
  })(Component);

export const withAllComparisonRequestForUser = (Component) =>
  graphql(ALL_COMPARISON_REQUEST_FOR_USER, {
    options: ({ filter, orderBy, pagination }) => {
      return { variables: { ...pagination, ...filter, orderBy } };
    },
    props({ data }) {
      const {
        loading,
        error,
        allComparisonRequestForUser,
        subscribeToMore,
        updateQuery,
      } = data;
      return {
        loading,
        error,
        allComparisonRequestForUser,
        subscribeToMore,
        updateQuery,
      };
    },
  })(Component);

export const withAllComparisonRequestFromUser = (Component) =>
  graphql(ALL_COMPARISON_REQUEST_FROM_USER, {
    options: ({ filter, orderBy, pagination }) => {
      return { variables: { ...pagination, ...filter, orderBy } };
    },
    props({ data }) {
      const {
        loading,
        error,
        allComparisonRequestFormUser,
        subscribeToMore,
        updateQuery,
      } = data;
      return {
        loading,
        error,
        allComparisonRequestFromUser: allComparisonRequestFormUser,
        subscribeToMore,
        updateQuery,
      };
    },
  })(Component);

// Client
export const withDashboardState = (Component) =>
  graphql(DASHBOARD_STATE_QUERY, {
    props({ data }) {
      const { orderBy, ...rest } = data.dashboardState;
      const dashboardState = { ...removeTypename(rest), orderBy };
      return { ...dashboardState, stateLoading: data.loading };
    },
  })(Component);

export const withDashboardFilterUpdating = (Component) =>
  graphql(UPDATE_DASHBOARD_FILTER, {
    props: ({ mutate }) => ({
      onOrderByChange(orderBy) {
        mutate({ variables: { orderBy } });
      },
      onPaginationChange(pagination) {
        mutate({ variables: { pagination } });
      },
      onFiltersRemove() {
        mutate({ variables: { ...dashboardResolvers.defaults.surveyState } });
      },
    }),
  })(Component);

// Mutation
  export const withAddCustomUserCommitment = Component =>
    graphql(ADD_CUSTOM_USER_COMMITMENT, {
    props: ({ mutate, history }) => ({
      createCustomUserCommitment: async values => {
        try {
          const {
            data: { createCustomUserCommitment }
          } = await mutate({
            variables: {
              ...values
            }
          });

          message.destroy();
          return createCustomUserCommitment.customUserCommitment;
        } catch (e) {
          message.destroy();
          console.error(e);
        }
      }
    })
  })(Component);


  export const withAddCoachingCommitment = Component =>
graphql(ADD_COACHING_COMMITMENT, {
  props: ({ mutate, history }) => ({
    createCoachingCommitment: async values => {
      try {
        const {
          data: { createCoachingCommitment }
        } = await mutate({
          variables: {
            ...values
          }
        });

        message.destroy();
        message.success('Created Commitment Successfully');
        return createCoachingCommitment?.coachingCommitment;
      } catch (e) {
        message.destroy();
        message.error("Failed to create Commitment");
        console.error(e);
      }
    }
  })
})(Component);


  export const withDeleteDefaultToolCommitment = Component =>
  graphql(DELETE_DEFAULT_TOOL_COMMITMENT, {
    props: ({ mutate, history }) => ({
      deleteDefaultToolCommitment: async values => {
        try {
          const {
            data: { deleteDefaultToolCommitment }
          } = await mutate({
            variables: {
              ...values
            }
          });
  
          message.destroy();
          message.success('Created Commitment Successfully');
          return deleteDefaultToolCommitment?.defaultCommitmentProgress;
        } catch (e) {
          message.destroy();
          message.error("Failed to create Commitment");
          console.error(e);
        }
      }
    })
  })(Component);
  

  export const withDeleteCustomUserCommitment = Component =>
  graphql(DELETE_CUSTOM_USER_COMMITMENT, {
    props: ({ mutate, history }) => ({
      deleteCustomUserCommitment: async values => {
        try {
          const {
            data: { deleteCustomUserCommitment }
          } = await mutate({
            variables: {
              ...values
            }
          });
  
          message.destroy();
          message.success('Delete Commitment Successfully');
          return deleteCustomUserCommitment?.customUserCommitment;
        } catch (e) {
          message.destroy();
          message.error("Failed to create Commitment");
          console.error(e);
        }
      }
    })
  })(Component);


  export const withDeleteResponseFromCompareRequest = Component =>
  graphql(DELETE_RESPONSE_COMPARE_REQUEST, {
    props: ({ mutate, history }) => ({
      deleteResponseCompareRequest: async values => {
        try {
          const {
            data: { deleteResponseCompareRequest }
          } = await mutate({
            variables: {
              ...values
            }
          });
  
          message.destroy();
          message.success('Delete Comparerequest Successfully');
          return deleteResponseCompareRequest?.compareRequest;
        } catch (e) {
          message.destroy();
          message.error("Failed to create Commitment");
          console.error(e);
        }
      }
    })
  })(Component);


  export const withDeleteCoachingCommitment = Component =>
  graphql(DELETE_COACHING_COMMITMENT, {
    props: ({ mutate, history }) => ({
      deleteCoachingCommitment: async values => {
        try {
          const {
            data: { deleteCoachingCommitment }
          } = await mutate({
            variables: {
              ...values
            }
          });
  
          message.destroy();
          message.success('Delete Coaching Commitment Successfully');
          return deleteCoachingCommitment?.coachingCommitment;
        } catch (e) {
          message.destroy();
          message.error("Failed to delete Commitment");
          console.error(e);
        }
      }
    })
  })(Component);


  export const withDeleteResponseForCompareRequest = Component =>
  graphql(DELETE_RESPONSE_COMPARE_REQUEST, {
    props: ({ mutate, history }) => ({
      deleteResponseCompareRequest: async values => {
        try {
          const {
            data: { deleteResponseCompareRequest }
          } = await mutate({
            variables: {
              ...values
            }
          });
  
          message.destroy();
          message.success('Delete Compere request Successfully');
          return deleteResponseCompareRequest?.compareRequest;
        } catch (e) {
          message.destroy();
          message.error("Failed to delete Commitment");
          console.error(e);
        }
      }
    })
  })(Component);

export const withEditDefaultToolCommitment = (Component) =>
  graphql(EDIT_DEFAULT_TOOL_COMMITMENT, {
    props: ({ mutate }) => ({
      updateDefaultToolCommitment: async (values) => {
        try {
          const {
            data: { updateDefaultToolCommitment },
          } = await mutate({
            variables: {
              ...values,
            },
          });
          return updateDefaultToolCommitment.defaultCommitmentProgress;
        } catch (e) {
          console.error(e);
        }
      },
    }),
  })(Component);


  export const withAddSelectDefaultToolCommitment = Component =>
  graphql(ADD_SELECT_DEFAULT_TOOL_COMMITMENT, {
    props: ({ mutate, history }) => ({
      addSelectDefaultToolCommitment: async values => {
        try {
          const {
            data: { addSelectDefaultToolCommitment }
          } = await mutate({
            variables: {
              ...values
            }
          });
  
          message.destroy();
          message.success('Add Default tool Commitment Successfully');
          return addSelectDefaultToolCommitment?.defaultCommitmentProgress;
        } catch (e) {
          message.destroy();
          message.error("Failed to Add Default tool Commitment");
          console.error(e);
        }
      }
    })
  })(Component);

  export const withAddResponseCompareRequest = Component =>
  graphql(ADD_RESPONSE_COMPARE_REQUEST, {
    props: ({ mutate, history }) => ({
      createResponseCompareRequest: async values => {
        try {
          const {
            data: { createResponseCompareRequest }
          } = await mutate({
            variables: {
              ...values
            }
          });
  
          message.destroy();
          message.success('Add Response Compare Request Successfully');
          return createResponseCompareRequest?.compareRequest;
        } catch (e) {
          message.destroy();
          message.error("Failed to Add Response Compare Request");
          console.error(e);
        }
      }
    })
  })(Component);

export const withResponseCompareRequest = (Component) =>
  graphql(RESPONSE_COMPARE_REQUEST, {
    props: ({ mutate }) => ({
      respondToResponseCompareRequest: async (values) => {
        try {
          const {
            data: { respondToResponseCompareRequest },
          } = await mutate({
            variables: {
              ...values,
            },
          });

          return respondToResponseCompareRequest.compareRequest;
        } catch (e) {
          console.error(e);
        }
      },
    }),
  })(Component);

export const withEditCustomUserCommitment = (Component) =>
  graphql(EDIT_CUSTOM_USER_COMMITMENT, {
    props: ({ mutate }) => ({
      updateCustomUserCommitment: async (values) => {
        try {
          const {
            data: { updateCustomUserCommitment },
          } = await mutate({
            variables: {
              ...values,
            },
          });
          return updateCustomUserCommitment.customUserCommitment;
        } catch (e) {
          console.error(e);
        }
      },
    }),
  })(Component);

export const withEditCoachingCommitment = (Component) =>
  graphql(EDIT_COACHING_COMMITMENT, {
    props: ({ mutate }) => ({
      updateCoachingCommitment: async (values) => {
        try {
          const {
            data: { updateCoachingCommitment },
          } = await mutate({
            variables: {
              ...values,
            },
          });
          return updateCoachingCommitment.coachingCommitment;
        } catch (e) {
          console.error(e);
        }
      },
    }),
  })(Component);

// modifications on line
// 11-16  (2)

// 88
// 89
// 91
// 92

// 96
// 97
// 99
// 100

// 104
// 105
// 107
// 108

// 112
// 113
// 115
// 116

// 120
// 121
// 123
// 124

// 128
// 129
// 131
// 132
