import React from "react";
import { compose } from "../../core";
import CompetencyImportView from "../components/CompetencyImportView";
import { getItem } from "../../../modules/core/clientStorage";
import { ACCESS_TOKEN, BACKEND_URI } from '../../../config';
import { message } from "antd";

const CompetencyImportContainer = (props) => {
  const {type,onCancel}=props
   const [uploadErrors, setUploadErrors] = React.useState(undefined);
   const [fileUploadSuccess, setFileUploadSuccess] = React.useState(undefined)
   
   const url ={
    import_main_competency:'/organization/CompetencyCategoryImport/',
    responsiblitiy:'/organization/ResponsibilityImport/',
    sub_competency:'/organization/CompetencyImport/'
   }

   const data_type ={
    import_main_competency:'Competency',
    responsiblitiy:'Responsibility',
    sub_competency:'Sub competency'
   }

   /* Upload File Functionality */
   const uploadNewFile= async(file)=>{
      let token = await getItem(ACCESS_TOKEN)
    const formData = new FormData();
   formData.append('file', file);
   formData.append('file-format',file?.type === "text/csv" ? "CSV" : "XLSX");
    fetch(BACKEND_URI.replace('/api', '')+ url[type], {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `JWT ${token}`
      }
    })
    .then((res) => res.json())
      .then((response) => {
        if (response?.Successs) {
          message.success(`${data_type[type]} uploaded successfully`)
          setFileUploadSuccess(response)
        } else {
         message.error(`${data_type[type]} upload failed`)
         setUploadErrors(response)
        }

      })
      .catch((res) => {
        setUploadErrors(res)
      })
   }

    return (
       <CompetencyImportView {...props}
        uploadfile={(file)=>uploadNewFile(file)} 
        uploadErrors={uploadErrors}
        fileUploadSuccess={fileUploadSuccess}
        cancel={()=>onCancel()}></CompetencyImportView> 
    )

}
export default compose()(CompetencyImportContainer)