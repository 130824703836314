const baseName = '/survey';
const adminPanelBaseName = '/admin-panel';
const tokenBaseName = '/token';
const groupBaseName = '/group';
const questionBaseName = '/question';
const responseBaseName = '/response';
const detailBaseName = '/detail';
const addBaseName = '/new';
const editBaseName = '/edit';
const submit = '/submit';
const survey_360_manager_report = '/survey_team_report'
const survey_360_token = '/survey_token'
export const three_cs = "3cs"
const survey_route = {
  allOrganizationSurvey: baseName + '/all-organization-survey',
  token: baseName + tokenBaseName + adminPanelBaseName,
  group: baseName + groupBaseName + adminPanelBaseName + '/:id',
  question: baseName + questionBaseName + adminPanelBaseName + '/:id',
  questionLink: baseName + questionBaseName + adminPanelBaseName + '/',
  groupLink: baseName + groupBaseName + adminPanelBaseName + '/',
  survey: baseName + baseName + adminPanelBaseName,
  response: baseName + responseBaseName + adminPanelBaseName,
  surveyToken: baseName + survey_360_token + adminPanelBaseName,
  surveyDetail: baseName + baseName + detailBaseName + '/admin-panel' + baseName + '/:id',
  surveyDetailLink: baseName + baseName + detailBaseName + '/admin-panel' + baseName + '/',

  surveyQuiz: baseName + baseName + '/:id',
  surveyQuizLink: baseName + baseName + '/',
  surveyQuizResponse: baseName + baseName + responseBaseName + '/:id',
  surveyQuizresponseLink: baseName + baseName + responseBaseName + '/',
  tokenSurvey: baseName + baseName + tokenBaseName + '/:token',
  tokenSurveyLink: baseName + baseName + tokenBaseName + '/',
  surveyResponse: baseName + responseBaseName + '/:id',
  surveyResponseLink: baseName + responseBaseName + '/',

  addToken: baseName + tokenBaseName + addBaseName,
  addMultipleToken: baseName + tokenBaseName + '/multiple' + addBaseName,
  editToken: baseName + tokenBaseName + editBaseName + '/:id',
  editTokenLink: baseName + tokenBaseName + editBaseName + '/',
  addGroup: baseName + groupBaseName + addBaseName + '/:id',
  addGroupLink: baseName + groupBaseName + addBaseName + '/',
  editGroup: baseName + groupBaseName + editBaseName + '/:id',
  editGroupLink: baseName + groupBaseName + editBaseName + '/',
  addQuestion: baseName + questionBaseName + addBaseName + '/:id',
  addQuestionLink: baseName + questionBaseName + addBaseName + '/',
  editQuestion: baseName + questionBaseName + editBaseName + '/:id',
  editQuestionLink: baseName + questionBaseName + editBaseName + '/',
  addSurvey: baseName + baseName + addBaseName,
  editSurvey: baseName + baseName + editBaseName + '/:id',
  editSurveyLink: baseName + baseName + editBaseName + '/',
  addResponse: baseName + responseBaseName + addBaseName,
  editResponse: baseName + responseBaseName + editBaseName + '/:id',
  editResponseLink: baseName + responseBaseName + editBaseName + '/',
  takeTestLink: '/soul' + '/values'+'/survey' + '/:id',
  soulSurveyset: '/soul' + '/values'+'/survey'+'/detail' + '/:id' +'',
  soulSurveySubmission: '/soul' + '/values'+'/survey'+'/submit' + '/:id',
  uiFlowEditSurveyResponse: '/soul' + '/:surveyType/:id',
  surveyQuiz3cs: `/shoal/${three_cs}/survey/:id`,
  surveyQuizWhole: '/whole/survey'+ '/:id',
  report3cs: `/shoal/${three_cs}/report`,
};

export default survey_route