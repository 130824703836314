import { Spin, Select, Input, Button, Modal, message } from "antd";
import React from "react";
import { ca_create_icon } from "../icon";
import '../css/competency-assessment-create.css'
import { threshold_colors } from "../color-config";
import CompetencySelectorContainer from "../containers/competency-selector-container";
import ca_route from '../route/index'
const CompetencyAssessmentCreateView = (props) => {
    const { history, is_loading, member_list, getRoleDetailByid, getAllMemberList, role_related_competency, threshold_list, permission, addOtherCompetency, existing_category, SetRoleRelatedCompetency, SetRoleById, OnSubmitAssessment, all_role_list, getAllRoleList } = props
    const [user_email, SetUserEmail] = React.useState()
    const [selected_member, SetSelectedMember] = React.useState()
    const [selected_role, SetSelectedRole] = React.useState()
    const [bars_detail_view, SetBarsDetail] = React.useState()
    const [child_expansion_key, SetChildExpansionKey] = React.useState()
    const [selected_assessment, SetSelectedAssessment] = React.useState([])
    const [other_category_selector, SetOtherCategorySelector] = React.useState(false)
    const { Option } = Select;

    // to handle the role data after user selection 
    React.useEffect(() => {
        SetUserEmail(null)
        SetSelectedRole(null)
        SetSelectedRole(selected_member?.role || null)
        SetSelectedAssessment([])
    }, [selected_member])

    // to get the role detail
    React.useEffect(() => {
        if (selected_role) {
            getRoleDetailByid(selected_role?.id, selected_role?.employeeLevel?.id)
        }
    }, [selected_role])

    // method to handle member selection
    const OnSelectMember = (id) => {
        let member = member_list?.find(item => item?.id == id)
        if (member) {
            SetBarsDetail(null)
            SetSelectedMember(member)
        }
    }

    // method to handle role selection
    const OnSelectRole = (id) => {
        let role = all_role_list?.find(item => item?.id == id)
        if (role) {
            SetSelectedAssessment([])
            SetBarsDetail(null)
            SetChildExpansionKey(null)
            SetSelectedRole(role)
        }
    }

    const changeBarsExpansion = (id) => {
        if (bars_detail_view == id) {
            SetBarsDetail(null)
        } else {
            SetBarsDetail(id)
        }
    }

    const getColor = (ui_type, threshold, key, type) => {
        let list = selected_assessment
        let doc = list?.find(assessment => assessment?.key === key && assessment?.type === type && assessment?.threshold?.id === threshold?.id)
        if (ui_type === 'background') {
            return doc ? `rgba(${threshold_colors[`threshold_${threshold?.sequence}`] || threshold_colors['threshold_4']},0.1)` : '#fff'
        }
        else if ((ui_type === 'solid_background' || ui_type === 'border') && doc) {
            return `rgba(${threshold_colors[`threshold_${threshold?.sequence}`] || threshold_colors['threshold_4']})`
        }
        else if ((ui_type === 'solid_background' || ui_type === 'border') && !doc) {
            if (ui_type === 'solid_background') {
                return 'transparent'
            } else {
                return '#AABAC6'
            }
        }
    }

    const BehaviourSampleRender = (item, threshold, type) => {
        let bar_detail = item?.behaviourallyanchoredratingSet?.edges?.map(({ node }) => node)?.find(bars => bars?.ratingScale?.id === threshold?.id)
        let behaviour_example = bar_detail?.behaviourExample
        return (
            <div className={`ca-behaviour-sample-container ${!behaviour_example ? 'align-content-center' : ''}`}>
                <h4 className="ca-behaviour-sample" style={{ textAlign: behaviour_example ? 'left' : 'center' }}>
                    {behaviour_example ? behaviour_example : <span>behaviourally anchored rating <br></br> is not added</span>}
                </h4>
            </div>
        )
    }

    const changeAssessment = (key, type, threshold, parent_key, data, parent,child_list) => {
        let bar = data?.behaviourallyanchoredratingSet?.edges?.map(({ node }) => node)?.find(bars => bars?.ratingScale?.id === threshold?.id)
        if (!bar?.behaviourExample) {
            message.destroy()
            message.warning('behaviourally anchored rating is not created')
            return
        }
        if (type == 'subcompetency'){
            let bar_list = parent?.behaviourallyanchoredratingSet?.edges?.map(({node})=>node)
            let valid = true
            threshold_list.forEach(r => {
                let rating_data = bar_list?.find(b=>b?.ratingScale?.id==r?.id)
                if(!rating_data || !rating_data?.behaviourExample){
                    valid=false
                }
            });
            if(!valid){
                message.destroy()
                message.warning('behaviourally anchored rating is not created for parent competency')
                return
            }

        }
        let list = selected_assessment.concat([])
        let doc = list?.find(assessment => assessment?.key === key && assessment?.type === type && assessment?.threshold === threshold?.id)
        if (!doc) {
            if (type == 'competency') {
                let child_available = list?.find(i => i?.parent_key == key && i?.active)
                if (child_available) {
                    message.destroy()
                    message.warning('Cannot change the main competency selection since you have already selected sub competency')
                    return
                }
            }
            list = list?.filter(assessment => assessment.key != key)
            list.push({
                key,
                parent_key: parent_key,
                type,
                bar,
                threshold,
                behaviourallyanchoredratingSet: data?.behaviourallyanchoredratingSet?.edges?.map(({ node }) => node),
                active: true,
                display:true
            })
            if (type == 'subcompetency') {
                let sub_comp_list = list?.filter(sub => sub?.parent_key == parent_key)
                if (sub_comp_list?.length) {
                    let repeated_threshold = mostRepeatedKey(sub_comp_list)
                    let parent_exist = list?.find(i => i?.key == parent_key)
                    if (parent_exist) {
                        parent_exist.active = false
                        parent_exist.threshold = repeated_threshold
                        parent_exist.bar = parent?.behaviourallyanchoredratingSet?.edges?.map(({ node }) => node)?.find(bars => bars?.ratingScale?.id === repeated_threshold?.id)
                    } else {
                        list.push({
                            key: parent_key,
                            parent_key: null,
                            type: 'competency',
                            bar: parent?.behaviourallyanchoredratingSet?.edges?.map(({ node }) => node)?.find(bars => bars?.ratingScale?.id === repeated_threshold?.id),
                            threshold: repeated_threshold,
                            behaviourallyanchoredratingSet: parent?.behaviourallyanchoredratingSet?.edges?.map(({ node }) => node),
                            active: false
                        })
                    }
                }
            }
            if(type == 'competency' && child_list?.length){
                list = list?.filter(assessment => assessment.parent_key != key)
                child_list.forEach((sub,index)=>{
                    let sub_bar = sub?.behaviourallyanchoredratingSet?.edges?.map(({ node }) => node)?.find(bars => bars?.ratingScale?.id === threshold?.id)
                    if(sub_bar){
                        list.push({
                            key:`${sub?.id}_${index + 1}_${key}_sub`,
                            parent_key: key,
                            type:'subcompetency',
                            bar:sub_bar,
                            threshold,
                            behaviourallyanchoredratingSet: sub?.behaviourallyanchoredratingSet?.edges?.map(({ node }) => node),
                            active: false,
                            display:false
                        })
                    }
                    
                })
            }
            SetSelectedAssessment(list)
        }
    }

    
    const mostRepeatedKey = (arr) => {
        let list = {}
        arr.forEach(item => {
            if (list[item?.threshold?.id]) {
                list[item?.threshold?.id].count++
            } else {
                list[item?.threshold?.id] = {
                    count: 1,
                    ...item?.threshold
                }
            }
        })
        let higest_count = Object.values(list)?.sort((a, b) => b.count - a.count)[0]?.count
        let data = Object.values(list)?.filter(item => item?.count === higest_count).sort((a, b) => b.sequence - a.sequence)
        return data[0]
    }

    const changeSubCompetencyExpansion = (key) => {
        if (child_expansion_key === key) {
            SetChildExpansionKey(null)
        } else {
            SetChildExpansionKey(key)
        }
    }

    const onEmployeeClear = () => {
        SetUserEmail(null)
        SetSelectedMember(null)
        SetSelectedRole(null)
        SetRoleById(null)
        SetSelectedAssessment([])
        SetBarsDetail(null)
        SetChildExpansionKey(null)
        SetRoleRelatedCompetency(null)
    }

    const SubCompetencyListRender = (item, key, parent_key, parent) => (
        <div className="sub-competency-list-container" id={key}>
            <div className="row-justify-space-between " style={{ gap: "2.69em" }}>
                <h4 className="ca-competency-title">{item?.title} :</h4>
                <h4 className="ca-sample-detail" onClick={() => changeBarsExpansion(key)}>
                    {
                        bars_detail_view == key ?
                            'Hide' : 'See detail'
                    }
                </h4>
            </div>
            {/* threshold list */}
            <div className="ca-threshold-container" style={{ padding: "0px 1em 0px 0em" }}>
                {
                    threshold_list?.map(threshold => (
                        <div
                            className={`ca-threshold-card ${bars_detail_view == key ? 'ca-threshold-card-expanded' : ''}`}
                            style={{ background: getColor('background', threshold, key, 'subcompetency'), border: `1px solid ${getColor('border', threshold, key, 'subcompetency')}` }}
                            onClick={() => changeAssessment(key, 'subcompetency', threshold, parent_key, item, parent)}
                        >
                            <div className="ca-threshold-card-tile" >
                                <h4 className="ca-threshold-label">{threshold?.name}</h4>
                                {/* custom radio button */}
                                <div className={`ca-radio-outer`} style={{ border: `4px solid ${getColor('border', threshold, key, 'subcompetency')}` }}>
                                    <div className={`ca-radio-inner`} style={{ background: getColor('solid_background', threshold, key, 'subcompetency') }}>

                                    </div>
                                </div>
                            </div>
                            {/* Behaviour sample */}
                            {
                                bars_detail_view == key && (
                                    BehaviourSampleRender(item, threshold, 'subcompetency')
                                )
                            }
                        </div>
                    ))
                }
            </div>

        </div>
    )

    const CompetencyListRender = (item, key) => (
        <div className="competency-list-container" id={key}>
            <div className="row-justify-space-between " style={{ gap: "2.69em" }}>
                <h4 className="ca-competency-title">
                    {
                        item?.sub_comptency?.length ?
                            <img src={ca_create_icon?.table_expand_arrow} onClick={() => { changeSubCompetencyExpansion(key) }} className={child_expansion_key !== key ? "category-expand-icon" : "category-active-expand-icon"} alt="" /> : ''
                    }

                    {item?.main_category?.title} :</h4>
                <h4 className="ca-sample-detail" onClick={() => changeBarsExpansion(key)}>
                    {
                        bars_detail_view == key ?
                            'Hide' : 'See detail'
                    }
                </h4>
            </div>
            {/* threshold list */}
            <div className="ca-threshold-container">
                {
                    threshold_list?.map(threshold => (
                        <div
                            className={`ca-threshold-card ${bars_detail_view == key ? 'ca-threshold-card-expanded' : ''}`}
                            style={{ background: getColor('background', threshold, key, 'competency'), border: `1px solid ${getColor('border', threshold, key, 'competency')}` }}
                            onClick={() => changeAssessment(key, 'competency', threshold, null, item?.main_category,null,item?.sub_comptency)}
                        >
                            <div className="ca-threshold-card-tile" >
                                <h4 className="ca-threshold-label">{threshold?.name}</h4>
                                {/* custom radio button */}
                                <div className={`ca-radio-outer`} style={{ border: `3px solid ${getColor('border', threshold, key, 'competency')}` }}>
                                    <div className={`ca-radio-inner`} style={{ background: getColor('solid_background', threshold, key, 'competency') }}>

                                    </div>
                                </div>
                            </div>
                            {/* Behaviour sample */}
                            {
                                bars_detail_view == key && (
                                    BehaviourSampleRender(item?.main_category, threshold, 'competency')
                                )
                            }
                        </div>
                    ))
                }
            </div>
            {/* subcompetency list */}
            {
                child_expansion_key === key && (
                    <div>
                        <h4 className="ca-sub-label">Sub-competency</h4>
                        {
                            item?.sub_comptency?.map((sub, index) => (
                                // <h4 className="ca-competency-title" style={{ color: "red" }}>{sub?.title}</h4>
                                SubCompetencyListRender(sub, `${sub?.id}_${index + 1}_${key}_sub`, key, item?.main_category)
                            ))
                        }
                    </div>
                )
            }

        </div>
    )

    return (
        <>
            <Modal
                visible={other_category_selector}
                destroyOnClose={true}
                footer={false}
                closable={false}
                width={'80%'}
            >
                <CompetencySelectorContainer {...props} permission={permission} onSubmit={(data) => { addOtherCompetency(data); SetOtherCategorySelector(false) }} onCancel={() => { SetOtherCategorySelector(false) }} existing_category={existing_category} employee_level={selected_role?.employeeLevel?.id} />
            </Modal>
            {
                threshold_list?.length ?
                    <Spin spinning={is_loading}>
                        <div className="ca-heading-container ca-root">
                            <img onClick={(e) => history.push(ca_route?.competency_assessment_home)} style={{ paddingLeft: ".5em", cursor: "pointer" }} src={ca_create_icon?.new_back_arrow} alt="" />
                            <h4 className="ca-main-title">Competency Assessment</h4>
                            <div></div>
                        </div>
                        <div className="ca-root ca-root-padding ca-page-title-margin" >

                            <div className="ca-container-bg selection-container-padding">
                                <div className="row-justify-space-between col-1000-justify-space-between-center ca-action-gap" style={{ alignItems: "start" }}>
                                    <Select
                                        className="rd-select-filed rd-type-select"
                                        style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", width: "100%" }}
                                        suffixIcon={<img src={ca_create_icon?.dropdown_Arrow} alt="" style={{ width: "10px", height: "10px" }} />}
                                        showSearch
                                        onSelect={(e) => { OnSelectMember(e) }}
                                        onSearch={(e) => { getAllMemberList({ toEmployee_User_FirstName_Icontains: e }) }}
                                        filterOption={false}
                                        placeholder='Select employee'
                                        allowClear
                                        onClear={() => { onEmployeeClear() }}
                                        dropdownClassName="content-dropdown"
                                        {...props}
                                    >
                                        {member_list?.map(value => (
                                            <Option
                                                className='rd-select-option'
                                                key={value?.id}
                                                value={value?.id}
                                            >
                                                {value?.toEmployee?.user?.firstName}
                                            </Option>
                                        ))}
                                    </Select>
                                    <h4 className="ca-label">
                                        or
                                    </h4>
                                    <div style={{ width: "100%" }}>
                                        <Input className="ca-text-input ca-input-max" disabled={selected_member} placeholder='Enter employee email id ' style={{ height: "3em" }} value={user_email} onChange={(e) => { SetUserEmail(e?.target?.value) }} />
                                        {
                                            user_email && (
                                                <Select
                                                    className="rd-select-filed rd-type-select ca-input-max"
                                                    style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", width: "100%", marginTop: "1.5em" }}
                                                    suffixIcon={<img src={ca_create_icon?.dropdown_Arrow} alt="" style={{ width: "10px", height: "10px" }} />}
                                                    showSearch
                                                    onSelect={(e) => { OnSelectRole(e) }}
                                                    onSearch={(e) => { getAllRoleList({ title_Icontains: e }) }}
                                                    filterOption={false}
                                                    placeholder='Select role'
                                                    dropdownClassName="content-dropdown"
                                                    {...props}
                                                >
                                                    {all_role_list?.map(value => (
                                                        <Option
                                                            className='rd-select-option'
                                                            key={value?.id}
                                                            value={value?.id}
                                                        >
                                                            {value?.title} - {value?.employeeLevel?.name}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            )
                                        }

                                    </div>
                                </div>
                                {
                                    selected_role && (
                                        <div style={{ marginTop: "3.19em" }}>
                                            <h4 className="ca-role-detail">Role : <span>{selected_role?.title}</span></h4>
                                            <h4 className="ca-role-detail">Employee level : <span>{selected_role?.employeeLevel?.name}</span></h4>
                                        </div>
                                    )
                                }
                            </div>
                            {
                                role_related_competency && (
                                    <>
                                        {/* section for functional competency */}
                                        {
                                            (role_related_competency?.functional_competency?.good_to_have?.length || role_related_competency?.functional_competency?.must_have?.length) ?

                                                <div className="ca-container-bg section-container-padding" style={{ marginTop: "1.12em" }} >
                                                    <h4 className="section-title">Functional competency</h4>
                                                    {/* must have competency section */}
                                                    {
                                                        role_related_competency?.functional_competency?.must_have?.length ?
                                                            <div className="ca-gradient ca-must-have-grad">
                                                                <h4 className="ca-label">Must have competency</h4>
                                                            </div> : ''
                                                    }


                                                    <div>
                                                        {
                                                            role_related_competency?.functional_competency?.must_have?.map((item, index) => (
                                                                CompetencyListRender(item, `${item?.main_category?.id}_${index + 1}_functionalmust_main`)
                                                            ))
                                                        }
                                                    </div>

                                                    {/* good to have competency section */}
                                                    {
                                                        role_related_competency?.functional_competency?.good_to_have?.length ?
                                                            <div className="ca-gradient ca-good-have-grad">
                                                                <h4 className="ca-label">Good to have competency</h4>
                                                            </div> : ''
                                                    }

                                                    <div>
                                                        {
                                                            role_related_competency?.functional_competency?.good_to_have?.map((item, index) => (
                                                                CompetencyListRender(item, `${item?.main_category?.id}_${index + 1}_functionalgood_main`)
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                                : ''
                                        }


                                        {/* section for generic competency */}
                                        {
                                            role_related_competency?.generic_competency?.length ?
                                                <div className="ca-container-bg section-container-padding" style={{ marginTop: "1.12em" }} >
                                                    <h4 className="section-title">Generic Competencies</h4>
                                                    <div>
                                                        {
                                                            role_related_competency?.generic_competency?.map((item, index) => (
                                                                CompetencyListRender(item, `${item?.main_category?.id}_${index + 1}_generic_main`,)
                                                            ))
                                                        }
                                                    </div>
                                                </div> : ''
                                        }

                                        {/* section for other competency  */}
                                        {
                                            role_related_competency?.other_competency?.length ?
                                                <div className="ca-container-bg section-container-padding" style={{ marginTop: "1.12em" }} >
                                                    <h4 className="section-title">Other Competencies</h4>
                                                    <div>
                                                        {
                                                            role_related_competency?.other_competency?.map((item, index) => (
                                                                CompetencyListRender(item, `${item?.main_category?.id}_${index + 1}_other_main`,)
                                                            ))
                                                        }
                                                    </div>
                                                    {
                                                        role_related_competency?.other_competency?.length ?
                                                            <div className="ca-action-container">
                                                                <Button className="other-competency-btn" onClick={() => { SetOtherCategorySelector(true) }}>+ Add Other Competencies</Button>
                                                            </div> : ''
                                                    }
                                                </div> : ''
                                        }

                                    </>
                                )
                            }
                            {
                                (threshold_list?.length && selected_role) ? <div className="ca-action-container">
                                    {
                                        !role_related_competency?.other_competency?.length ?
                                            <Button className="other-competency-btn" onClick={() => { SetOtherCategorySelector(true) }}>+ Add Other Competencies</Button> : ''
                                    }
                                    <Button className="global-submit-btn" onClick={() => { OnSubmitAssessment(selected_assessment, selected_role, selected_member, user_email) }} disabled={!selected_assessment?.length} style={{ height: "3.4375em", minWidth: "12em", marginTop: "2.62em" }}>Submit</Button>
                                </div> : ''
                            }

                        </div>


                    </Spin>
                    : ''
            }

        </>
    )

}

export default CompetencyAssessmentCreateView