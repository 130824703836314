import update from 'immutability-helper';
import { LOOK_SURVEY_RESPONSE_STATE_QUERY } from 'modules/look/graphql/LookSurveyResponseStateQuery.client.gql';

import { PAGINATION_LIMIT } from '../../../config';
import { SURVEY_RESPONSE_STATE_QUERY } from '../graphql/SurveyResponseStateQuery.client.gql';

import { SURVEY_STATE_QUERY } from '../graphql/SurveyStateQuery.client.gql';

const TYPE_SURVEY_STATE = 'SurveyState';

const TYPE_SURVEY_RESPONSE_STATE = 'surveyResponseState'

const TYPE_SURVEY_STATE_FILTER = 'SurveyFilterInput';

const TYPE_SURVEY_STATE_PAGINATION = 'SurveyPaginationInput';

const TYPE_SURVEY_RESPONSE_STATE_PAGINATION = 'SurveyResponsePaginationInput';

const TYPE_SURVEY_RESPONSE_STATE_FILTER = 'SurveyResponseFilterInput';

const defaults = {
  surveyState: {
    filter: {
      name_Icontains: '',
      description_Icontains: '',
      label_Icontains: '',
      helpText_Icontains: '',
      sequence_Icontains: '',
      questionText_Icontains: '',
      questionAddress_Icontains: '',
      user: '',
      survey: '',
      createdBy: '',
      invited: '',
      valid: '',
      survey_Name_Icontains:'',
      __typename: TYPE_SURVEY_STATE_FILTER
    },
    orderBy: ['-id'],
    pagination: {
      first: PAGINATION_LIMIT,
      offset: 0,
      __typename: TYPE_SURVEY_STATE_PAGINATION
    },
    __typename: TYPE_SURVEY_STATE
  },

  surveyResponseState: {
    filter: {
    
      survey_Name_Icontains:'',
      __typename: TYPE_SURVEY_RESPONSE_STATE_FILTER
    },
    orderBy: ['-id'],
    pagination: {
      first: PAGINATION_LIMIT,
      offset: 0,
      __typename: TYPE_SURVEY_RESPONSE_STATE_PAGINATION
    },
    __typename: TYPE_SURVEY_RESPONSE_STATE
  }
};

const resolvers = {
  Mutation: {
    updateSurveyFilter: (_, { filter, orderBy, pagination }, { cache }) => {
      const { surveyState } = cache.readQuery({
        query: SURVEY_STATE_QUERY
      });
      const newSurveyState = update(surveyState, {
        filter: { $merge: filter },
        orderBy: { $set: orderBy },
        pagination: { $merge: pagination }
      });

      cache.writeData({
        data: {
          surveyState: newSurveyState,
          __type: TYPE_SURVEY_STATE
        }
      });

      return null;
    },
    updateSurveyResposesFilter: (_, { filter, orderBy }, { cache }) => {
      const { surveyResponseState } = cache.readQuery({
        query: SURVEY_RESPONSE_STATE_QUERY
      });

     
      const newSurveyResponseState = update(surveyResponseState, {
        filter: { $merge: filter },
        orderBy: { $set: orderBy }
      });

      cache.writeData({
        data: {
          lookSurveyResponseState: newSurveyResponseState,
          __type: TYPE_SURVEY_RESPONSE_STATE
        }
      });

      return null;
    }
  }
};

export default { defaults, resolvers };
