import React from 'react';

import { AdminLayout, getIntFromString } from '../../look';
import ROUTE from '../route';

import VerticalFormComponent from './VerticalFormComponent';

const EditVerticalView = props => {
  return (
    <AdminLayout
      table={false}
      active={ROUTE.vertical}
      title="Edit vertical"
      backLink={`${ROUTE.verticalDetailLink}${
        props.vertical && getIntFromString(props.vertical.id)
      }`}
      FormComponent={props.vertical && <VerticalFormComponent {...props} />}
    />
  );
};

export default EditVerticalView;
