import { message } from "antd";
import { graphql } from '@apollo/client/react/hoc';
import { CREATE_RATING_SCALE, DELETE_RATING_SCALE } from "../graphql/RatingScale.gql";





  export const withDeleteRatingScale = Component =>
  graphql(DELETE_RATING_SCALE, {
    props: ({ mutate}) => ({
      deleteRatingScale: async values => {
        try {
          const {
            data: { deleteRatingScale }
          } = await mutate({
            variables: {
              ...values
            }
          });

          message.destroy();
          message.success('Successfully deleted');
          return true;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
          console.error(e);
        }
      }
    })
  })(Component);




  export const withCreateRatingScale = Component =>
  graphql(CREATE_RATING_SCALE, {
    props: ({ mutate, history }) => ({
      createRatingScale: async values => {
        try {
          const {
            data: { createRatingScale }
          } = await mutate({
            variables: {
              ...values
            }
          });

          message.destroy();
          message.success('Submitted response!!');
          return createRatingScale?.ratingScale;
        } catch (e) {
          message.destroy();
          // message.error("Couldn't perform the action");
          console.error(e);
        }
      }
    })
  })(Component);