import React from 'react';
import { Button } from 'antd'
import warning_icon from '../../assets/deleteWarning_icon.svg'

const RoleDefinitionConfirmation = (props)=>{

    const { message,onCancel,onConfirm,confirm_action_label,cancel_action_label,main_warning } = props

    return (
        <div className='rd-confirmation-dialog'>
            <img src={warning_icon} alt="" />
            {
                main_warning && (
                    <h4 className='rd-confirmation-message' style={{fontSize:"1.5em"}}>{main_warning}</h4>
                )
            }
            <h4 className='rd-confirmation-message'>{message}</h4>
            <div className='rd-confirmation-action-container'>
                <Button className='global-cancel-btn' style={{color:"#8C8CA1",border:"1px solid #8C8CA1"}} onClick={()=>{onCancel()}}>{cancel_action_label || 'Go Back'}</Button>
                <Button className='global-submit-btn' style={{background:"#E86161",color:"#FFFFFF",border:"1px solid #E86161"}} onClick={()=>{onConfirm()}}>{confirm_action_label || 'Delete'}</Button>
            </div>
        </div>
    )

}

export default RoleDefinitionConfirmation