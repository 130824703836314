// @ts-nocheck
import React from 'react';
import { Row, Col, Button,Spin,Table ,Modal,Input, Menu, Dropdown } from "antd";
import styled from 'styled-components';
import { ONEONONE_ADD_EDIT_TOOL_ID, ONE_ON_ONE_TOOL_WHILE_MEETING } from '../../../config'
import MeetingTableView from './Table'
import Quick1_1 from './QuickMeeting'
import { displayUtcToUserTimezone, getIntFromString } from "../../look";
import agenda_arrow from '../../assets/agenda-arrow.svg'
import creator from '../../assets/one-on-one-creator.svg'
import assigne from '../../assets/one-on-one-contributor.svg'
import edit from '../../assets/one-on-one-edit.svg'
import bookmark_disabel from '../../assets/bookmark-inactive.svg'
import { LoadingOutlined } from '@ant-design/icons';
import AgendaPopup from './AgendaPopupView';
import MeetingCard from './OneOnOneMeetingCard';

const UpcomingMeetingView = props => {
  const {
    history,
    me,
    onfilter,
    upcomingMeeting,setloading,loading,selectedTab,onPaginantion,onFilterList
  } = props;
  const antIcon = <LoadingOutlined style={{ fontSize: "34px",color:"#4CCB1F" }} spin />;
  const [quickMeeting, setquickMeeting] = React.useState();
  const [dataSource, setDataSource] = React.useState([]);
  const [agendaPopup, setagendaPopup] = React.useState(false);
  const [agendaList, setagendaList] = React.useState([]);
  const [pageloading, setpageloading] = React.useState(false)
  const [nextPage,setNextPage]=React.useState(false)
  const [currentPage, setcurrentPage] = React.useState(1)
  let currentFilter = React.useRef({})

  React.useEffect(()=>{
    if(upcomingMeeting){
      setNextPage(upcomingMeeting?.pageInfo?.hasNextPage)
      let list =  upcomingMeeting?.edges?.map(({node})=>node)
      if(dataSource?.length&&onFilterList){
        let finalMetingList =dataSource?.concat( list)
        var setObj = new Set();
        let meetList= finalMetingList.reduce((acc,item)=>{
          if(!setObj.has(item?.id)){
            setObj.add(item?.id)
            acc.push(item)
          }
          return acc;
        },[])
        setDataSource(meetList)
      }
      else{
        setDataSource(list)
      }
    }
  },[upcomingMeeting,onFilterList])

  const pagination=()=>{
    let current_page=currentPage
    currentFilter.current['offset']=current_page*20
    setcurrentPage(current_page+1)
    setpageloading(true)
    onPaginantion({...currentFilter?.current,pagination_value:true})
  }

  return (
    <>
       <div className='agenda-create-overflow' style={{width:"100%",maxHeight:'440px',overflow:'auto',height:"100%",minHeight:"440px"}}>
          <MeetingCard dataSource={dataSource} selectedTab={selectedTab} loading={loading} {...props} paginationLoad={()=>pagination()} nextPage={nextPage} tab="upComming"/>
       </div>
      
    </>
  );
};

export default UpcomingMeetingView;

const CreateButton = styled(Button)`
width:170px;
height:40px;
border-radius:5px;
`

const ViewButton = styled(Button)`
width:60px;
height:35px;
border-radius:5px;
`

export const MyTable = styled(Table)`
  table {
    border-spacing: 0 1em;
    tbody {
      tr {
        td {
          border: 1px solid rgba(0, 0, 0, 0.06) !important;
          border-style: solid none !important;
        }
        td:first-child {
          border-left-style: solid !important;
          border-top-left-radius: 5px !important;
          border-bottom-left-radius: 5px !important;
        }
        td:last-child {
          border-right-style: solid !important;
          border-bottom-right-radius: 5px !important;
          border-top-right-radius: 5px !important;
          border-bottom: 1px solid rgba(0, 0, 0, 0.06) !important;
        }
      }
    }
  }
`;
const RowDiv =styled(Row)`
display:flex;
justify-content:space-between;
align-items:center;
margin-bottom:20px
`