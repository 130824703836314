import React from "react";
import RecivedAwardsView from "../component/RecivedAwardView";
import { compose } from "modules/core";
import { withApollo } from '@apollo/client/react/hoc';
import { getIntFromString } from "modules/look";
import { RECIVED_AWARD_LIST } from "../graphql/RecivedAwards.gql";
import { BADGE_AWARD_SUBSCRIPTION } from "../graphql/badgeAwardSubscription.gql";
const RecivedAwardContainer =(props)=>{
    const {client,userPermission,me,search_award}=props
    const [loading,setLoading]=React.useState(false)
    const [recievedAwardList,setRecievedAwardList]=React.useState()
    const dataRef = React.useRef()
    const m = React.useRef( true )

    let badgeAwardSub = undefined

    React.useEffect( () => {
        return () => {
          if ( badgeAwardSub ) {
            badgeAwardSub.unsubscribe();
          }
        }
      } )
    
      React.useEffect( () => {
        return () => {
          m.current = false
        }
      }, [] )
    
      React.useEffect( () => {
        m.current = true
    
      }, [] )


    React.useEffect(()=>{
        if(search_award){
            getBadgeFamilyList({recipient:me?.employee?.id,badge_Title_Icontains:search_award})
        }
    },[search_award])

    const getBadgeFamilyList=async(filterValue)=>{

        setLoading(true)
        const { data } = await client.query({
          query: RECIVED_AWARD_LIST,
          variables: {...filterValue},
          fetchPolicy:"network-only"
        });

        if(data){
            setRecievedAwardList(data?.badgeAwards?.edges?.map( ( { node } ) => node ))
            dataRef.current=data?.badgeAwards?.edges?.map( ( { node } ) => node )
        }
          badgeAwardSub = client.subscribe( {
            query: BADGE_AWARD_SUBSCRIPTION,
            variables:  {...filterValue},
            fetchPolicy:"network-only"
          } ).subscribe( {
            next( result ) {
              console.log("updated",result)
              let receivedBadgeCacheData = dataRef.current
              switch ( result.data.badgeAwardSubscription.mutation ) {
                case 'CREATE':
                  const updated = [ ...receivedBadgeCacheData, result.data.badgeAwardSubscription.badgeAward ]
                  if ( m.current ) {
                    setRecievedAwardList( updated )
                    dataRef.current=updated
                  }
                  break
                case 'UPDATE':
                  if ( m.current ) {
                    const updateRsponse= receivedBadgeCacheData.map( item =>
                      item.id === result?.data?.badgeAwardSubscription?.badgeAward?.id
                        ? result.data.badgeAwardSubscription.badgeAward
                        : item
                    ) 
                    setRecievedAwardList(updateRsponse)
                    dataRef.current=updateRsponse
                  }
                  break
                case 'DELETE':
                  if ( m.current ) {
                    const deleteData =receivedBadgeCacheData.filter( item =>
                      item.id === result?.data?.badgeAwardSubscription?.badgeAward?.id
                        ? false
                        : true
                    ) 
                    setRecievedAwardList(deleteData)
                    dataRef.current=deleteData
                  }
                  break
                default:
                  break
              }
            }
          } )
          setLoading(false)
    }
    
    React.useEffect(()=>{
    if(me?.employee?.user?.id){
        getBadgeFamilyList({recipient:me?.employee?.id})
    }
  },[me])

    return(
        <>
            <RecivedAwardsView {...props} loading={loading} recievedAwardList={recievedAwardList} loading={loading}/>
        </>
    )
}

export default compose(withApollo)(RecivedAwardContainer)