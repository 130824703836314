import React from 'react';
import { dashboard_home_icons, organization_icons } from 'modules/poc-dashboard/icons';
import { Button, Dropdown, Menu, Spin, Tooltip } from 'antd';
import CreateVerticalView from './createVerticalView';
import EditEmployeeDetailContainer from 'modules/poc-dashboard/containers/organization/editEmpolyeeDetailContainer';
import DeleteEmployeeContainer from 'modules/poc-dashboard/containers/organization/deleteEmployeeContainer';
import CancelInvitationContainer from 'modules/poc-dashboard/containers/organization/cancelInvitationContainer';
import { exportToCsv } from 'modules/look';
const OrgEmployeeTableView = (props) => {
    const { employee_list, emp_list_loading,page_info,onPagination,updateRow,total_users } = props
    const [edit_details, setEditDetail] = React.useState()
    const [cancel_invitation, setCancelInvitation] = React.useState()
    const [delete_user, setDeleteUser] = React.useState()
    const [export_employee_list,setExportEmployeeList]=React.useState()
    React.useEffect(() => { 
            let exp_list = employee_list?.map(item=>{
                return {
                    FirstName: item?.firstName,
                    LastName: item?.lastName,
                    Email: item?.email,
                    Position:item?.user_type,
                    Vertical:item?.verticals?.map(item=>item?.name)?.join(','),
                    Team:item?.teams?.map(item=>item?.name)?.join(','),
                    verified:item?.verified,
                    Status:item?.active_status,
                }
            })
            setExportEmployeeList(exp_list)
        
    },[employee_list])
    const MenuDropDown = (item) => {
        const options = [
          {
            key: "edit",
            title: "Edit",
            disabled: false,
          },
          {
            key: item?.active_status==='Active' ? "deactivate" : "activate",
            title: item?.active_status==='Active' ?  "Deactivate" : "Activate",
            disabled: item?.user_type=="CEO"||item?.user_type=="POC Admin"?true:false,
          },
          {
            key: "delete",
            title: "Remove Employee",
            disabled: item?.user_type=="CEO"||item?.user_type=="POC Admin"?true:false,
          },
        ];
      
        return (
          <Menu className="three-dot-menu">
            {options.map((val) => (
              <Menu.Item
                key={val.key}
                disabled={val?.disabled}
                onClick={() => menuAction({ key: val.key, item: item })}
                className="org-table-body-label"
                style={{ fontWeight: "500", padding: ".5em", cursor: "pointer",color:val?.disabled?'#D7D7D7':"#1F1F1F"}}
              >
                {val.title}
              </Menu.Item>
            ))}
          </Menu>
        );
      };
    const listDropDown = (value) => {
        return (
            <Menu style={{ width: "100%" }}>
                <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'center', alignItems: "center", gap: '1em', padding: "1em 2em" }}>
                    <h4 className='org-table-body-label' style={{ fontWeight: "600", fontSize: "1.5em" }}>{value?.title}</h4>
                    {value?.item?.map((vertical, index) => (
                        <h4 key={index} className='org-table-body-label' style={{ fontWeight: "500", fontSize: "1em" }}>{vertical?.name}</h4>
                    ))}
                </div>
            </Menu>
        );
    }
    const menuAction = (value) => {
        if (value?.key === "edit") {
            setEditDetail(value?.item)
        }
        else if (value?.key === "deactivate" || value?.key === "activate") {
            setCancelInvitation(value?.item)
        }
        else if (value?.key === "delete") {
            setDeleteUser(value?.item)
        }
    }
    return (
        <div className='poc-dahboard-root'>
            <div style={{ display: "flex", gap: '1em', justifyContent: "space-between", alignItems: "center" }}>
                <CreateVerticalView {...props} label={'Create Vertical'} />
                <div style={{display:"flex",flexDirection:'row',alignItems:'center',gap:'1em',paddingTop:'1em',marginRight:"1em"}}>
                    <h4 className='org-table-body-label' style={{ color: "rgb(141, 141, 141)", fontSize: ".85em" }}>Users: {total_users}</h4>
                    <Button className="export-btn" onClick={()=>exportToCsv( "org_employee_list", export_employee_list)}><img src={dashboard_home_icons?.export_icon} alt="export_icon" style={{ height: "1.25em", marginRight: "1em" }}/> Export</Button>
                </div>
            </div>
            <Spin spinning={emp_list_loading}>
                <div className='org-table-container poc_table_scroll container-alignment' >
                    <table className='org-table'>
                        <thead>
                            <tr className='org-row-header'>
                                <th className='org-table-row-padding-left org-left-radious' >
                                    <h4 className='org-table-header-label'>Employee</h4>
                                </th>
                                <th className='org-table-header-label' style={{ textAlign: "center" }}>
                                    <h4 className='org-table-header-label'>Position</h4>
                                </th>
                                <th className='org-table-header-label' style={{ textAlign: "center" }}>
                                    <h4 className='org-table-header-label'>Vertical</h4>
                                </th>
                                <th className='org-table-header-label' style={{ textAlign: "center" }}>
                                    <h4 className='org-table-header-label'>Status</h4>
                                </th>
                                <th className='org-table-header-label' style={{ textAlign: "center" }}>
                                    <h4 className='org-table-header-label'>Team</h4>
                                </th>
                            
                                <th className='org-table-row-padding-rigth org-content-center org-table-header-label org-right-radious' >
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {employee_list?.map(item => (
                                <tr className='org-row-body'>
                                    <td className='org-left-radious org-table-row-padding-left table-border-left' style={{ borderRight: "none" }}>
                                        <div style={{ display: 'flex', justifyContent: "start", alignItems: 'center', gap: ".5em" }}>
                                            {item?.verified ?
                                            <Tooltip title={"Verifed User"}><img src={organization_icons.verified_user} alt="verified_user" style={{opacity:item?.active_status=="Inactive"?'.5':'1'}} /></Tooltip>:
                                            <Tooltip title={" Non Verifed User"}> <img src={organization_icons.non_verified_user} alt="non_verified_user" /></Tooltip>}
                                            <div style={{ display: 'flex', flexDirection: "column", justifyContent: "start", alignItems: 'start', gap: ".5em",opacity:(item?.verified&&item?.active_status=="Inactive")?'.5':'1' }}>
                                                <h4 className='org-table-body-label'>{item?.firstName} {item?.lastName}</h4>
                                                <h4 className='org-table-body-label' style={{ color: "rgb(141, 141, 141)", fontSize: ".85em" }}>{item?.email}</h4>
                                            </div>
                                        </div>
                                    </td>
                                    <td className='table-border-up-down' style={{ textAlign: "center", borderRight: "none", borderLeft: "none" }}>
                                        <h4 className='org-table-body-label org-poc-type' style={{opacity:(item?.verified&&item?.active_status=="Inactive")?'.5':'1' }}>{item?.user_type}</h4>
                                    </td>
                                    <td className='table-border-up-down' style={{ textAlign: "center", borderRight: "none", borderLeft: "none" }}>
                                        {item?.verticals?.length > 0 ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center", gap: ".25em", width: '100%',opacity:(item?.verified&&item?.active_status=="Inactive")?'.5':'1'  }}>
                                            <h4 className='org-table-body-label org-vertical-type' >{item?.verticals[0]?.name}</h4>
                                            {item?.verticals?.length > 1 &&
                                                <Dropdown overlay={listDropDown({ item: item?.verticals, title: "Verticals" })} placement="topCenter"
                                                    overlayStyle={{ width: 'fit-content' }} >
                                                    <h4 className='org-table-body-label org-vertical-type' style={{ padding: '.5em .5em', cursor: "pointer" }}>{item?.verticals?.length - 1}+</h4>
                                                </Dropdown>}
                                        </div> : <h4 className='org-table-body-label' style={{opacity:(item?.verified&&item?.active_status=="Inactive")?'.5':'1' }}>-</h4>}

                                    </td>
                                    <td className='table-border-up-down' style={{ textAlign: "center", borderRight: "none", borderLeft: "none"}}>
                                        <div style={{display:'flex',flexDirection:"row",justifyContent:'center',alignItems:"center",opacity:(item?.verified&&item?.active_status=="Inactive")?'.5':'1' }}>
                                            <div className={`org-table-body-label ${item?.active_status === 'Active' ? 'org-status-active' : 'org-table-body-label org-status-inactive'}`} style={{ display: 'flex', flexDirection: "row", gap: ".5em", justifyContent: 'center', alignItems: "center" }}><span></span>{item?.active_status}</div>
                                        </div> 
                                    </td>
                                    <td className='table-border-up-down' style={{ textAlign: "center", borderRight: "none", borderLeft: "none" }}>
                                        {/* {item?.teams?.length>0?<div style={{ display: 'flex', justifyContent: 'center', alignItems: "center", gap: ".25em", width: '100%' }}>
                                        <h4 className='org-table-body-label' >{item?.teams[0]?.name}</h4>
                                        {item?.teams?.length>1&&<h4 className='org-table-body-label' style={{ padding: '.5em .5em', cursor: "pinter" }}>{item?.teams?.length-1}+</h4>}
                                    </div>:<h4 className='org-table-body-label'>-</h4>} */}
                                        {item?.teams?.length > 0 ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center", gap: ".25em", width: '100%',opacity:(item?.verified&&item?.active_status=="Inactive")?'.5':'1'  }}>
                                            <h4 className='org-table-body-label org-vertical-type' >{item?.teams[0]?.name}</h4>
                                            {item?.teams?.length > 1 &&
                                                <Dropdown overlay={listDropDown({ item: item?.teams, title: "Teams" })} placement="topCenter"
                                                    overlayStyle={{ width: 'fit-content' }} >
                                                    <h4 className='org-table-body-label org-vertical-type' style={{ padding: '.5em .5em', cursor: "pointer" }}>{item?.teams?.length - 1}+</h4>
                                                </Dropdown>}
                                        </div> : <h4 className='org-table-body-label' style={{opacity:(item?.verified&&item?.active_status=="Inactive")?'.5':'1' }}>-</h4>}
                                    </td>
                                    {/* <td className='table-border-up-down' style={{ textAlign: "center", borderRight: "none", borderLeft: "none" }}>
                                    <h4 className='org-table-body-label' style={{whiteSpace:"nowrap"}}>{item?.valid_upto}</h4>
                                </td> */}
                                    <td className='org-table-row-padding-rigth org-content-center org-right-radious table-border-right' style={{ borderLeft: "none", marginLeft: "1em" }}>
                                        <Dropdown overlay={MenuDropDown(item)} placement="bottomLeft"
                                            overlayStyle={{ width: '160px' }} >
                                            <img src={organization_icons.three_dots} alt="" style={{ cursor: "pointer", width: "2em" }} />
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {(!emp_list_loading &&employee_list?.length && page_info?.has_next_page)? <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", marginTop: "1em" }}>
                        <h4 onClick={() => {onPagination(page_info)}} className='pagination-label' style={{ cursor: "pointer" ,margin:"0px",padding:"0px"}}>View More</h4>
                    </div>:''}
                    {
                        (!employee_list?.length && !emp_list_loading) ?
                            <div className='rd-nodata'>
                                <h4 className='rd-nodata-label'>No data found</h4>
                            </div> : ''
                    }
                </div>
            </Spin>
            {edit_details && <EditEmployeeDetailContainer {...props} edit_details={edit_details} onCancel={() => setEditDetail(null)} onSuccess={(value)=>{updateRow("profile_update",value);setEditDetail(null)}}/>}
            {cancel_invitation && <CancelInvitationContainer {...props} cancel_invitation={cancel_invitation} onCancel={() => setCancelInvitation(null)} onSuccess={(value)=>{updateRow("active_status",value);setCancelInvitation(null)}}/>}
            {delete_user && <DeleteEmployeeContainer {...props} delete_user={delete_user} onCancel={() => setDeleteUser(null)} onSuccess={(value)=>{updateRow("delete_user",value);setDeleteUser(null)}}/>}
        </div>
    )

}

export default OrgEmployeeTableView;