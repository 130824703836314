import { GET_MY_COACH_COMMITMENTS_PROGRESS_SUBSCRIPTION } from "../graphql/GetMyCoachCommitmentsProgress.gql";
import { subscribeToQuery } from '../../look';
import { GET_MY_CUSTOMIZED_COACHING_COMMITMENTS_QUERY_SUBSCRIPTION } from "../graphql/GetMyCustomizedCoachingCommitments.gql";


export const subscribeToCoachingCommitmentProgress = (subscribeToMore, variables) => {
    return subscribeToQuery(
      subscribeToMore,
      {
        document: GET_MY_COACH_COMMITMENTS_PROGRESS_SUBSCRIPTION,
        subscriptionName: 'toolsCoachingCommitmentProgressSubscription',
        nodeName: 'coachingCommitmentProgress',
        queryName: 'getMyCoachingCommitmentsProgress'
      },
      variables
    );
  };

  export const subscribeToGetCustomCoachingCommitmentProgress = (subscribeToMore, variables) => {
    return subscribeToQuery(
      subscribeToMore,
      {
        document: GET_MY_CUSTOMIZED_COACHING_COMMITMENTS_QUERY_SUBSCRIPTION,
        subscriptionName: 'toolsCoachingCommitmentSubscription',
        nodeName: 'coachingCommitment',
        queryName: 'getMyCustomizedCoachingCommitments'
      },
      variables
    );
  };