import React from 'react';

import { AdminLayout } from '../../look';
import ROUTE from '../route';

import TagFormComponent from './TagFormComponent';

const AddTag = props => {
  return <AdminLayout table={false} active={ROUTE.tag} title="Add tag" FormComponent={<TagFormComponent {...props} />} />;
};

export default AddTag;
