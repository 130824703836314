import gql from 'graphql-tag';

export const LOOK_SIMPLE_FIELD_STATE_QUERY = gql`
  query lookSimpleFieldState {
    lookSimpleFieldState @client {
      filter {
        label_Icontains
      }
      orderBy
    }
  }
`;
