import React, { useState, useEffect } from "react";
import { Row, Col, Spin, Card, Button, Empty, Space } from "antd";

import { getIntFromString, MetaTags, ReactMarkdown } from "../../look";
import AddToolToRecent from "./AddToolToRecent";
import { RenderFormSection } from "./generic-tool";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { ArrowLeftOutlined } from '@ant-design/icons';
import './uiFlowTool.css';

const NewRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  @media only screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  @media only screen and (max-width: 1000px) {
    width: 100%;
  }
`;

const AddEditTool = (props) => {
  const { loading, admin = false, refetch, items, noOfHeaders = 2, l, r, displayBack = true, isImpactNarrative,location} = props;
  const [leftItems, setLeftItems] = useState([]);
  const [rightItems, setRightItems] = useState([]);
  const [headerItems, setHeaderItems] = useState([]);
  const [noLeftItems, setNoLeftItems] = useState(false);
  const [noRightItems, setNoRightItems] = useState(false);
  const history = useHistory();

  const goBack=()=>{
    if(location?.search){
      let data =location?.search
      let id =data.split('=')
      console.log("id!!!!!!!!!!!!!!!!!",id)
      if(id[1]){
        history.push(`/soul/values/3?response=${id[1]}`)

      }
    }
    else{
      history.goBack()
    }
  }
  useEffect(() => {
    // l and r are prefetch items if they are provided then we do not need to filter items and construct left and right items.
    if (l !== undefined && r !== undefined){
      if (l.length !== 0 && r.length !== 0) {
        setLeftItems(l);
        setRightItems(r);
      } else if (l.length !== 0 && r.length === 0) {
        setLeftItems(l);
        setNoRightItems(true);
      } else if (l.length === 0 && r.length !== 0) {
        setRightItems(r);
        setNoLeftItems(true);
      }
    } else {
      let left_section=[]
      let right_section=[]
      items
        .filter((data, idx) => idx > noOfHeaders - 1)
        .forEach((section, idx) => {
          if (idx % 2 != 0) {
            left_section.push(section)
            // setLeftItems((leftItems) => [...leftItems, section]);
          } else {
            right_section.push(section)
            // setRightItems((rightItems) => [...rightItems, section]);
          }
        });
        setLeftItems(left_section)
        setRightItems(right_section)
      setHeaderItems(items
        .filter((data, idx) => idx < noOfHeaders))
    }
  }, [items, l, r]);

  const RenderTextSection = ({ textSection, idx }) => {
    return (
      <Row gutter={24}>
        <Col span={24}>
          {/* <ReactMarkdown>{textSection.text}</ReactMarkdown> */}
          <ReactMarkdown>{textSection.renderedText || "<p></p>"}</ReactMarkdown>
        </Col>
      </Row>
    );
  };

  const getBorderedStatus = ((idx) => {
    if (idx == 0) {
      return false
    }
    return true
  })

  const renderHeaders = (items) => {
    return items.map((section, idx) => {
        return section.type === "textsection" ? (
          <>
            <Col span={24} style={{ textAlign: "left", marginTop: "5vh" }}>
              <Card
                style={{
                  backgroundColor: (idx != 0 ? (2 * idx + 1) % 2 !== 0 && "#8080801c" : 'white')
                }}
                bordered={getBorderedStatus(idx)}
              >
                <RenderTextSection
                  textSection={section}
                  idx={idx}
                />
              </Card>
            </Col>
          </>
        ) : (
          <>
            <Col
              span={24}
              align="left"
              style={{ textAlign: "left", marginTop: "5vh" }}
            >
              <Card
                style={{
                  backgroundColor: (idx != 0 ? (2 * idx + 1) % 2 !== 0 && "#8080801c" : 'white')
                }}
                bordered={getBorderedStatus(idx)}
              >
                <RenderFormSection
                  admin={admin}
                  formSectionId={getIntFromString(section.id)}
                  formSection={section}
                  refetch={refetch}
                />
              </Card>
            </Col>
          </>
        )}
      )
  }

  const fetchCards = (items, type) => {
    let data =  items.map((section, idx) => {
      return section.type === "textsection" ? (
        <>
          <Card
            style={{
              backgroundColor:
                (2 * idx + 1) % 2 !== 0 && "#8080801c",
            }}
          >
            <RenderTextSection
              textSection={section}
              idx={idx}
            />
          </Card>
          <div style={{ height: "5vh" }}></div>
        </>
      ) : (
        <>
          <Card
            style={{
              backgroundColor:
                (2 * idx + 1) % 2 !== 0 && "#8080801c",
            }}
          >
            <RenderFormSection
              admin={admin}
              formSectionId={getIntFromString(section.id)}
              formSection={section}
              refetch={refetch}
            />

          </Card>
          <div style={{ height: "5vh" }}></div>
        </>
      )
    })
    if (type === "left" && noLeftItems) {
      return (
        <div style={{textAlign : 'center'}}>
          <Empty />
        </div>
      )
    } else if (type === "right" && noRightItems) {
      return (
        <div style={{ textAlign: 'center' }}>
          <Empty />
        </div>
      )
    } else{
      return data
    }
  }

  return (
    <Row justify="center">
      <AddToolToRecent />
      <MetaTags title="Tool Details" description={`This is too details page`} />
      <Col
        span={20}
        style={{
          border: "1px solid #EBEBEB",
          borderRadius: 10,
          padding: isImpactNarrative ? "10px 0px" : "50px 0px",
          paddingBottom: 80,
          backgroundColor: "#fff",
        }}
      >
        <Spin spinning={loading} size="large">
          <div style={{ minHeight: "70vh" }}>
            {!loading && (
              <>
                <Row>
                  <>
                    {displayBack && (
                    <Col xs={24} sm={24} md={12} lg={24} xl={24} >
                        <Space style={{ display:'flex',justifyContent:"space-between"}}>
                          {isImpactNarrative?
                            <Button   style={{ marginLeft: "2em"}} type="primary" onClick={() => history.push("/manager/impact/6")}>Team Narratives</Button>:<div></div>
                          }
                          <Button
                            shape="circle"
                            onClick={goBack}
                            style={{ marginRight: "2em"}}
                          >
                            <ArrowLeftOutlined />
                          </Button>
                        </Space>
                    </Col>
                    )}
                    {renderHeaders(headerItems)}

                    <Col span={24} style={{ height: "5vh" }}></Col>
                  <NewRow>
                        <Content className="toolcontent">
                          {fetchCards(leftItems, "left")}
                        </Content>
                        <Content className="toolcontent" >
                          {fetchCards(rightItems, "right")}
                        </Content>
                  </NewRow>
                  </>
                </Row>
              </>
            )}
          </div>
        </Spin>
      </Col>
    </Row>
  );
};

export default AddEditTool;
