import gql from 'graphql-tag';
import { REFERENCE_FIELD_TYPE } from './ReferenceFieldType.gql';

export const EDIT_REFERENCE_FIELD = gql`
  ${REFERENCE_FIELD_TYPE}
  mutation editReferenceField($editable: Boolean, $helpText: String, $id: ID!, $questionId: Int, $sequence: Int) {
    updateReferenceField(editable: $editable, helpText: $helpText, id: $id, questionId: $questionId, sequence: $sequence) {
      referenceField {
        ...ReferenceFieldType
      }
    }
  }
`;
