import React from "react";
import styled from "styled-components";
import { Row, Col, Card, Spin, Empty } from "antd";

import { DeleteIcon, getIntFromString, AddIcon } from "../../look";

const CommitmentsListView = (props) => {
  const {
    defaultCustomUserCommitments,
    loading,
    customUserCommitments,
    onDeleteDefaultToolCommitment,
    onDeleteCustomUserCommitment,
    handleSelectDefaultToolCommitment,
    commitmentRecommendations,
    commitmentKey = "commitment",
  } = props;

  return (
    <>
      <>
        <h3>
          {props.dashboard === "manager-personal"
            ? "Recommended Coaching Commitments"
            : "Edit Commitments"}
        </h3>
        <br />
        <Spin spinning={loading}>
          <Row style={{ maxHeight: "30vh", overflow: "auto" }}>
            <Col span={24}>
              {defaultCustomUserCommitments?.length > 0
                ? defaultCustomUserCommitments?.map(({ node }, idx) => (
                    <>
                      <Row gutter={[24,24]} align="middle">
                        <Col span={2} align="center" style={{ minWidth: '70px'}}>
                          <span
                            style={{
                              borderRadius: "50%",
                              padding: "10px 13px",
                              border: "1px solid #CACACA",
                            }}
                          >
                            {idx + 1}
                          </span>
                        </Col>
                        <Col span={21}  style={{minWidth:"200px"}}>
                          <Card
                            bordered={false}
                            style={{
                              boxShadow:
                                "5px 0px 25px 3px rgba(32, 32, 32, 0.1)",
                              borderRadius: "10px",
                            }}
                            bodyStyle={{ padding: "12px" }}
                          >
                            <Row justify="space-between" gutter={[12, 12]}>
                              <Col span={20}>
                                <Hoverable /* style={{ whiteSpace: 'nowrap', overflow: 'scroll' }} */
                                >
                                  {node[commitmentKey]?.title}
                                </Hoverable>
                              </Col>

                              <Col span={4}>
                                <DeleteIcon
                                  size="sm"
                                  tooltipSuffix="Commitment"
                                  onClick={() =>
                                    onDeleteDefaultToolCommitment(
                                      getIntFromString(node?.id)
                                    )
                                  }
                                />
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      </Row>
                      <br />
                    </>
                  ))
                : customUserCommitments?.length === 0 && (
                    <div align="center">
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                    </div>
                  )}

              {customUserCommitments?.map(({ node }, idx) => (
                <>
                  <Row gutter={[12,24]} align="middle"style={{paddingTop:'20px'}}>
                    <Col span={2} align="center" style={{ minWidth: '70px'}}>
                      <span
                        style={{
                          borderRadius: "50%",
                          padding: "10px 13px",
                          border: "1px solid #CACACA",
                        }}
                        >
                        {(defaultCustomUserCommitments?.edges?.length || 0) +
                          idx +
                          1}
                      </span>
                    </Col>
                    <Col span={21} style={{minWidth:"200px"}}>
                      <Card
                        bordered={false}
                        style={{
                          boxShadow: "5px 0px 25px 3px rgba(32, 32, 32, 0.1)",
                          borderRadius: "10px",
                          width:'100%'
                        }}
                        bodyStyle={{ padding: "12px" }}
                      >
                        <Row justify="space-between" gutter={[12, 12]}>
                          <Col span={20}>
                            <Hoverable /* style={{ whiteSpace: 'nowrap', overflow: 'scroll' }} */
                            >
                              {node?.title}
                            </Hoverable>
                          </Col>
                          <Col span={4}>
                            <DeleteIcon
                              size="sm"
                              tooltipSuffix="Commitment"
                              onClick={() =>
                                onDeleteCustomUserCommitment(
                                  getIntFromString(node.id)
                                )
                              }
                            />
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                  <br />
                </>
              ))}
            </Col>
          </Row>
        </Spin>
      </>

      <>
        <h3>Recommended Commitment​</h3>
        <br />
        <Row gutter={[24, 24]} style={{ maxHeight: "30vh", overflow: "auto" }}>
          <Col span={24}>
            {commitmentRecommendations?.edges?.length > 0
              ? commitmentRecommendations?.edges?.map(({ node }, idx) => (
                  <>
                    <Row gutter={[24,24]} align="middle">
                      <Col span={24}>
                        <Card
                          bordered={false}
                          style={{
                            boxShadow: "5px 0px 25px 3px rgba(32, 32, 32, 0.1)",
                            borderRadius: "10px",
                          }}
                          bodyStyle={{ padding: "12px" }}
                        >
                          <Row justify="space-between" gutter={[12, 12]}>
                            <Col span={20}>
                              <Hoverable /* style={{ whiteSpace: 'nowrap', overflow: 'scroll' }} */
                              >
                                {node?.title}
                              </Hoverable>
                            </Col>

                            <Col span={4}>
                              <AddIcon
                                size="sm"
                                tooltipSuffix="Commitment"
                                onClick={() =>
                                  handleSelectDefaultToolCommitment(
                                    getIntFromString(node?.id)
                                  )
                                }
                              />
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    </Row>
                    <br />
                  </>
                ))
              : commitmentRecommendations?.edges?.length === 0 && (
                  <div align="center">
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                  </div>
                )}
          </Col>
        </Row>
      </>
    </>
  );
};

export default CommitmentsListView;

const Hoverable = styled.div`
  white-space: nowrap;
  overflow: hidden;
  :hover {
    white-space: unset;
    overflow: unset;
    cursor: pointer;
  }
`;
