// @ts-nocheck
import React from "react";
import { Button, Modal, Row, Spin } from "antd";
import SurveyComparisonRequestContainer from '../../look-v2/Container/SurveyComparisonRequest'
import SurveyResponseRenderContainer from '../containers/surveyResponseRenderCotainer'
import '../user-manual.css'
import { getIntFromString } from "modules/look";
import Back_icon from '../../assets/soul-back.svg'
import { useHistory } from "react-router-dom";
import { SHOAL_HOME_TOOL_ID } from "../../../config";
import PAGE_ROUTE from '../../page/route';

const UserManualHomeView = (props) =>{
    const {me,allowComparison,surveyReasponse,loading,surveyById,getRequestResponse,comparisonResponse,clearComparison,permission } = props
    const [user_manual_pop,set_user_manual_pop] = React.useState(false)
    const [other_employee,setOtherEmployee]=React.useState()
    const [suevey_response,setSurveyResponse]=React.useState()
    const [show_noData,setShowNodata]=React.useState(false)

    React.useEffect(()=>{
        setSurveyResponse(null)
        if(surveyReasponse?.length>0){
            let responseData =surveyReasponse.map(({node})=>{
                return {
                    question:{
                        id:node?.question?.id,
                        choiceSet:node?.question?.choiceSet,
                        questionText:node?.question?.questionText,
                        [me?.employee?.user?.id]:node?.answer
                    }
                }
            })

            setSurveyResponse(responseData)
        }
    },[surveyReasponse])
    React.useEffect(()=>{
        if(comparisonResponse!=undefined){
            if(comparisonResponse?.length==0){
                setShowNodata(true)
            }
            else{
                setShowNodata(false) 
                let responseData = suevey_response?.map((item)=>{
                    return {
                        question:{
                            id:item?.question?.id,
                            choiceSet:item?.question?.choiceSet,
                            questionText:item?.question?.questionText,
                            [me?.employee?.user?.id]:item?.question[me?.employee?.user?.id],
                            [other_employee?.type=='user_id'?other_employee?.user?.id:other_employee?.receiver?.id]:comparisonResponse?.find(({node})=>node?.question?.id==item?.question?.id).node?.answer
                        }
                    }
                }) 
                if(responseData?.length){
                    setSurveyResponse(responseData)
                }
            }
           
          
        }
    },[comparisonResponse])
    React.useEffect(()=>{
        if(other_employee){
            
            if(other_employee?.type==="user_id"){
                getRequestResponse({requestedUserId:getIntFromString(other_employee?.user?.id)})
            }
            else if(other_employee?.type==="req_id"){
                getRequestResponse({requestId:getIntFromString(other_employee?.id)})
            }
        }
    },[other_employee])

    const PreviewUserManual=(data)=>{
        console.log('PreviewUserManual',data);
    }

    return (
        <>
            <div className="comparison-container user-manual-root" style={{position:"relative"}}>

                {
                    permission?.comparison_permission && (
                        <Row justify="space-between" className="heading-section" align="center" style={{width:"100%"}}>
                            {
                            comparisonResponse&&(comparisonResponse?.length==0||comparisonResponse?.length>0)?<Button className="comparison-btn" onClick={()=>{setOtherEmployee(null);clearComparison();setShowNodata(false)}}>Hide User Manual Comparison</Button>:<Button className="comparison-btn" onClick={()=>{set_user_manual_pop(true);clearComparison()}}>See Others User Manual</Button>
                            }
                        </Row>
                    )
                }
               
                <Spin spinning={loading}>
                    <div className="comparison-secton">
                        <div className="comparision-headingSectiion">
                            <h4 className="comparison-title comparison-left-section">Your user manual</h4>
                            {other_employee&&<h4 className="comparison-title comparison-right-section">{other_employee?.type=="user_id"?`${other_employee?.user?.firstName} ${other_employee?.user?.lastName}`:`${other_employee?.receiver?.firstName} ${other_employee?.receiver?.lastName}`}’s user manual</h4>}
                        </div>
                        <div style={{display:show_noData?"flex":"block",gap:"20px"}}>
                            <div style={{width:show_noData?"50%":"100%"}}>
                                {suevey_response!=undefined&&suevey_response?.length>0&&suevey_response?.map((item,i)=>(
                                    <SurveyResponseRenderContainer {...props} showNodata={show_noData} index={i} noQuestion={false} editable={true} surveyQuestionResponse={item}  other_employee={other_employee!=undefined&&other_employee}/>
                                ))}
                            </div>
                            <div style={{width:"50%"}}>
                                {show_noData&&<div style={{display:'flex',justifyContent:'center',paddingTop:"100px",width:"50%"}}>
                                <h4  style={{fontSize:'1.125em',fontWeight:"500"}}>No Data Found</h4>
                                 </div>}
                            </div>
                        </div>
                    </div>  
                    {suevey_response==undefined&&<div style={{height:"500px"}}></div>}      
                </Spin>
            </div>
            <Modal
             visible={user_manual_pop}
             footer={false}
             closable={false}
             className="comparison-modal"
             destroyOnClose={true}
             centered={true}
            >
                <SurveyComparisonRequestContainer {...props} preview_shared_user_manual={(e)=>{PreviewUserManual(e);set_user_manual_pop(false)}} allowComparison={allowComparison} survey={surveyById} onEmployeeSelect={(e)=>{setOtherEmployee(e);set_user_manual_pop(false)}} onClose={()=>set_user_manual_pop(false)}/>
            </Modal>
        </>
    )
}

export default UserManualHomeView;