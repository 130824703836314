import gql from 'graphql-tag';
import { FAQ_CATEGORY_TYPE } from './FaqCategoryType.gql';

export const FAQ_CATEGORY_BY_ID_QUERY = gql`
  ${FAQ_CATEGORY_TYPE}
  query faqCatagoryById($id: ID!) {
    faqCatagoryById(id: $id) {
      ...FaqCategoryType
    }
  }
`;
