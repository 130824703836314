import gql from 'graphql-tag';

const READINESS_TYPE = gql`
  fragment ReadinessType on ReadinessType {
    id      
    readinessTo {      
      id  
      user{
        id
        firstName
        lastName
      }    
    }      
    readinessFrom {     
      id   
      user{
        id
        firstName
        lastName
      }  
    }        
    readinesslevelSet {      
      edges {     
        node {      
          id 
          skill {
            id
            title
          }     
          response{
            id 
            answerSet{
              edges{
                node{
                  id
                  question{
                    id
                    questionText
                  }
                  answer
                }
              }
            }
          }
        }      
      }     
    }      
    createdAt     
    updatedAt   
  }
`;

export const READINESS_SUBSCRIPTION = gql`
${READINESS_TYPE}
  subscription readinessSubscription {
    readinessSubscription {
      mutation
      readiness {
        ...ReadinessType
      }
    }
  }
`;


export const READINESS_ASSESSMENT = gql`
${READINESS_TYPE}
query  allReadines(
  $user: ID!,
  $search: String,
  $createdAt_Day_Gt:DateTime,
  $orderBy:[String],
  $received_after:String,
  $submitted_after:String,
  $readinesslevel_Skill_Title_Icontains: String,
  $first:Int) 
{
  allReceivedReadiness:allReadines(
    readinessTo:$user,
    readinessFrom_Ne: $user
    readinessFrom_User_FirstName_Icontains:$search,
    createdAt_Day_Gt:$createdAt_Day_Gt,
    orderBy:$orderBy,
    first:$first,
    after:$received_after
    readinesslevel_Skill_Title_Icontains:$readinesslevel_Skill_Title_Icontains)
    {
      pageInfo{
        hasNextPage
        endCursor
      }
      edges {
          node {     
              ...ReadinessType  
          }      
      }
  }
  allSubmittedReadiness:allReadines(
    readinessFrom:$user,
    readinessTo_Ne:$user,
    readinessTo_User_FirstName_Icontains:$search,
    createdAt_Day_Gt:$createdAt_Day_Gt,
    orderBy:$orderBy,
    first:$first,
    after:$submitted_after
    readinesslevel_Skill_Title_Icontains:$readinesslevel_Skill_Title_Icontains)
    {
      pageInfo{
        hasNextPage
        endCursor
      }
      edges {
          node {     
              ...ReadinessType  
          }      
      }
  }
}
`

export const SELF_READINESS_ASSESSMENT = gql`
${READINESS_TYPE}
query  allReadines(
  $user: ID!,
  $createdAt_Day_Gt:DateTime,
  $orderBy:[String],
  $first:Int,
  $after:String,
  $readinesslevel_Skill_Title_Icontains: String) 
{
  selfReadiness:allReadines(
    readinessTo:$user,
    readinessFrom:$user,
    createdAt_Day_Gt:$createdAt_Day_Gt,
    orderBy:$orderBy,
    readinesslevel_Skill_Title_Icontains:$readinesslevel_Skill_Title_Icontains,
    first:$first,
    after:$after
    )
    {
      pageInfo{
        hasNextPage
        endCursor
      }
      edges {
          node {     
              ...ReadinessType  
          }      
      }
  }
}
`

export const EMPLOYEE_READINESS_ASSESSMENT = gql`
${READINESS_TYPE}
query  allReadines(
  $user: ID!,
  $createdAt_Day_Gt:DateTime,
  $orderBy:[String],
  $search:String,
  $first:Int,
  $after:String,
  $readinesslevel_Skill_Title_Icontains: String) 
{
  employeeReadiness:allReadines(
    readinessTo_Ne:$user,
    createdAt_Day_Gt:$createdAt_Day_Gt,
    orderBy:$orderBy,
    readinessTo_User_FirstName_Icontains:$search
    first:$first,
    after:$after
    readinesslevel_Skill_Title_Icontains:$readinesslevel_Skill_Title_Icontains)
    {
      pageInfo{
        hasNextPage
        endCursor
      }
      edges {
          node {     
              ...ReadinessType  
          }      
      }
  }
}
`