import React from 'react';
import { Spin } from 'antd';

const Loading = props => {
  const { text, size = 'large', ...rest } = props;
  return (
    <div className={'HVCenter'}>
      <div style={{ textAlign: 'center' }}>
        <Spin size={size} {...rest} />
        {text && (
          <>
            <br />
            <br />
            <h3>{text}</h3>
          </>
        )}
      </div>
    </div>
  );
};

export default Loading;
