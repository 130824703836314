import React from 'react';
import { Form, Radio } from 'antd';

const SurveyMLSelectField = props => {
  const { choices, disabled,onChange, ...rest } = props;
  const FormItem = Form.Item;
  const RadioGroup = Radio.Group;

  const choice = choices.map((choice, key) => (
    <Radio
      key={key}
      value={choice.value || choice.label}
      style={{ justifyContent: 'center',alignItems:'center',margin:"0px",padding:"0px",width:"150px" }}
    >
      {/* {choice.label} */}
    </Radio>
  ));

  const onChangeValue =(e)=>{
  
  }
  return (
    <>
      <FormItem
        className='generic-survey-radio-choice-container survey-radio-choice'
        labelCol={{ span: 6,}}
        style={{marginBottom:"0px",padding:"0px"}}
        {...rest}
      >
        <RadioGroup disabled={disabled} onChange={onChange} style={{ display: 'flex',gap:"5px" }}>
          {choice}
        </RadioGroup>
      </FormItem>
    </>
  );
};

export default SurveyMLSelectField;
