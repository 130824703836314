import gql from "graphql-tag";

export const EMPLOYEE_LIST_FILTER_SEARCH = gql`
  query me($user_FirstName: String, $user_LastName: String) {
    first: me {
      id
      employee {
        id
        orgCeoEmployee {
          edges {
            node {
              id
              employees(user_FirstName: $user_FirstName) {
                edges {
                  node {
                    id
                    user {
                      id
                      firstName
                      lastName
                      email
                      employee {
                        id
                      }
                    }
                  }
                }
              }
            }
          }
        }
        orgPocEmployee {
          edges {
            node {
              id
              employees(user_FirstName: $user_FirstName) {
                edges {
                  node {
                    id
                    user {
                      id
                      firstName
                      lastName
                      email
                      employee {
                        id
                      }
                    }
                  }
                }
              }
            }
          }
        }
        teamManagerEmployee {
          edges {
            node {
              id
              employees(user_FirstName: $user_FirstName) {
                edges {
                  node {
                    id
                    user {
                      id
                      firstName
                      lastName
                      email
                      employee {
                        id
                      }
                    }
                  }
                }
              }
            }
          }
        }
        verticalHeadEmployee {
          edges {
            node {
              id
              employees(user_FirstName: $user_FirstName) {
                edges {
                  node {
                    id
                    user {
                      id
                      firstName
                      lastName
                      email
                      employee {
                        id
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    last: me {
      id
      employee {
        id
        orgCeoEmployee {
          edges {
            node {
              id
              employees(user_LastName: $user_LastName) {
                edges {
                  node {
                    id
                    user {
                      id
                      firstName
                      lastName
                      email
                      employee {
                        id
                      }
                    }
                  }
                }
              }
            }
          }
        }
        orgPocEmployee {
          edges {
            node {
              id
              employees(user_LastName: $user_LastName) {
                edges {
                  node {
                    id
                    user {
                      id
                      firstName
                      lastName
                      email
                      employee {
                        id
                      }
                    }
                  }
                }
              }
            }
          }
        }
        teamManagerEmployee {
          edges {
            node {
              id
              employees(user_LastName: $user_LastName) {
                edges {
                  node {
                    id
                    user {
                      id
                      firstName
                      lastName
                      email
                      employee {
                        id
                      }
                    }
                  }
                }
              }
            }
          }
        }
        verticalHeadEmployee {
          edges {
            node {
              id
              employees(user_LastName: $user_LastName) {
                edges {
                  node {
                    id
                    user {
                      id
                      firstName
                      lastName
                      email
                      employee {
                        id
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
