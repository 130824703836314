import { getAllByAltText } from '@testing-library/react';
import React from 'react';
import { compose } from '../../core';
import OneOnOneEffectivenssFeedbackHome from '../components/OneOnOneEffectivenssHome';
import { withApollo } from '@apollo/client/react/hoc'
import { ALL_FEEDBACKS, ALL_MEMBERS_OF_MEETING, MEETING_FEEDBACK_BY_ID } from '../graphql/EffectivenessFeedback.gql';
import { getIntFromString } from 'modules/look';


const OneonOneHomeContainer = props => {

  const {client,me} = props
  const [feedbacks,setFeedbacks] = React.useState();
  const [feedbackDetail,setFeedbackDetail] = React.useState();
  const [allMembers,setAllMembers] = React.useState();
  const [loading,setLoading] = React.useState(true);

  React.useEffect(()=>{
    if(me?.employee?.id){
      getAllFeedbacks({meetingHost:me?.employee?.id,orderBy:["-createdAt"]});
      getAllMembers();
    }   
  },[me])


  const getAllFeedbacks = async (filter) => {
    setLoading(true);
    const { data } = await client.query({
        query: ALL_FEEDBACKS,
        variables: filter,
        fetchPolicy: 'network-only'
    });
    if(data?.allMeetingFeedbacks){
      setFeedbacks(data?.allMeetingFeedbacks)
      setLoading(false)
    }   
}


const getDetailOfFeedback = async (feedbackId) => {
  let id=getIntFromString(feedbackId)
  const { data } = await client.query({
      query: MEETING_FEEDBACK_BY_ID,
      variables: {id:feedbackId},
  });
  if(data?.meetingFeedbackById){
    setFeedbackDetail(data?.meetingFeedbackById)
  }   
}

const getAllMembers = async () => {
  
  const { data } = await client.query({
      query: ALL_MEMBERS_OF_MEETING,
      variables: {meetingHost:me?.employee?.id}
  });
  if(data?.allMeetingFeedbacks){
    setAllMembers(data?.allMeetingFeedbacks)
  }   
}



    return (
      <>
       <OneOnOneEffectivenssFeedbackHome {...props} loading={loading}  allMembers={allMembers} getDetailOfFeedback={getDetailOfFeedback} feedbackDetail={feedbackDetail} feedbacks = {feedbacks}  getAllFeedbacks = {getAllFeedbacks} />
      </>
    );
  }




export default compose(withApollo)(OneonOneHomeContainer);
