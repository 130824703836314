import React from "react";
import { compose } from "../../core";
import { withApollo } from '@apollo/client/react/hoc';
import BarsCreateView from "../Components/BarsCreateView";
import { ALL_RATING_SCALES, COMPETENCY_BY_ID } from "../graphql/BarsCreateQueries.gql";
import { getCompetencyCategoryStringFromInt, globalPermissionValidator } from "modules/look";
import { withCreateBarsInBulk } from "./BarsOperations";
import { ALL_EMPLOYEE_LEVELS } from "../graphql/BarsQueries.gql";
import { Row, Spin } from "antd";
import NoPermissionView from "modules/kudos-user/component/NoPermissionComponent";
import { bars_permission } from "Permissions/bars.permission";



const BarsCreateContainer = (props) => {

  const { client, me, permissionLoader, userPermission } = props;

  const [competencyById, setCompetencyById] = React.useState(undefined);
  const [competencyLoading, setCompetencyLoadig] = React.useState(false);
  const [ratingScales, setRatingScales] = React.useState(undefined);
  const [allEmployeeLevels, setAllEmployeeLevels] = React.useState(undefined);
  const [barsPermission, setBarsPermission] = React.useState(undefined);


  const scales = React.useRef(null);





  // Combining employee levels for main competency
  const getEmployeeLevels = () => {

    let levels = allEmployeeLevels?.map(( node ) => {
      return { ...node,status:'pending', ratingScales: [...ratingScales] }
    }
    )

    return levels;

  }




  const getDateGenerated = (data) => {

    let record = undefined;
    let empLevels = getEmployeeLevels()

    record = {
      id: data?.id,
      mainCompetency: true,
      title: data?.title,
      employeeLevel: empLevels,
      competencySet:data?.competencySet?.edges

    }

    return record;

  }

  

  React.useEffect(() => {

    if (ratingScales && allEmployeeLevels && props.match.params.competency_id) {
      let id = props.match.params.competency_id;
      if(id){
        getCompetencyById(id)
      }
     
    }

  }, [ratingScales, allEmployeeLevels,props.match])

  // For fetching all rating scales
  const getAllRatingScales = async () => {
    setCompetencyLoadig(true)

    const { data } = await client.query({
      query: ALL_RATING_SCALES,
      fetchPolicy: 'network-only',
    });
    if (data?.ratingScales) {
      let scales = data?.ratingScales?.edges?.map(({ node }) => node);
      scales.current = scales
      setRatingScales(scales);

    }
  }

  // Fetching all the bars
  const getAllEmployeeLevels = async (filter) => {

    const { data } = await client.query({
      query: ALL_EMPLOYEE_LEVELS,
      variables:{organization:me?.employee?.organizationSet?.edges[0]?.node?.id},
      fetchPolicy: 'network-only'
      
    });
    if (data?.allEmployeeLevels) {
      let allLevels = data?.allEmployeeLevels?.edges?.map(({ node }) => node);
      setAllEmployeeLevels(allLevels)
    }
  }



  // Fetching the details of competency
  const getCompetencyById = async (id) => {
    setCompetencyLoadig(true)
    let competency_Id = getCompetencyCategoryStringFromInt(id)
    const { data } = await client.query({
      query: COMPETENCY_BY_ID,
      fetchPolicy: 'network-only',
      variables: { id: competency_Id }
    });
    if (data?.competencyCategoryId) {
      let competencyData = getDateGenerated(data?.competencyCategoryId)
      setCompetencyById(competencyData)
      setCompetencyLoadig(false)

    }
  }


  React.useEffect(() => {

    // Checking for permission of user
    if (userPermission?.length) {
      let permission = globalPermissionValidator(bars_permission, userPermission);
      setBarsPermission(permission)
    }

  }, [userPermission])


  React.useEffect(() => {

    // based on permission of user bars will be di
    if (barsPermission?.required_permission) {
      getAllRatingScales();
      getAllEmployeeLevels()
    }

  }, [barsPermission,scales.current])




  return (
    <>

      <Row justify='center' align='middle'>
        {
          <Spin spinning={permissionLoader} wrapperClassName ='permission-check-bars'>
            <div>
              {
                barsPermission !== undefined &&
                <div>
                  {
                    barsPermission?.required_permission ?
                      <BarsCreateView
                        competencyById={competencyById}
                        setCompetencyById={setCompetencyById}
                        competencyLoading={competencyLoading}
                        ratingScales={ratingScales}
                        allEmployeeLevels={allEmployeeLevels}
                        {...props}
                      />
                      :
                      <NoPermissionView />
                  }

                </div>
              }
            </div>
           </Spin>
        }
      </Row>

    </>
  );
};

export default compose(withApollo, withCreateBarsInBulk)(BarsCreateContainer);
