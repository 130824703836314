import gql from 'graphql-tag';
import { USER_TYPE } from '../../user/graphql/UserType.gql';
import { SURVEY_TYPE } from './SurveyType.gql';

export const TOKEN_TYPE = gql`
  ${SURVEY_TYPE}, ${USER_TYPE}
  fragment TokenType on TokenType {
    id
    user {
      ...UserType
    }
    createdBy {
      ...UserType
    }
    survey {
      ...SurveyType
    }
    invited
    dateCreated
    message
    valid
  }
`;
