import gql from 'graphql-tag';


export const CREATE_ACTIONITEM_EDIT_MUTATION = gql`
  mutation createActionItem($actionItemData: ActionItemInput!) {
    createActionItem(
      actionItemData:$actionItemData
        ) {
            actionItem {
                id
                title
                meeting {
                  id
                  title
                }
                okr {
                  id
                   title
                   owner {
                     id
                     user {
                       id
                       firstName
                       lastName
                       employee {
                         id
                       }
                     }
                   }
                 }
                commitment {
                  id
                  commitment {
                    id
                    title
                  }
                  user{
                    id
                    firstName
                    lastName
                    employee{
                      id
                    }
                  }
                  completed
                }
                coachingCommitment {
                  id
                  coachingcommitment {
                    id
                    title
                  }
                  user{
                    id
                    firstName
                    lastName
                    employee{
                      id
                    }
                  }
                  completed
                }
                notes
                completed
                isAgenda
                createdAt
                updatedAt
          }
    }
  }
`;
