import React from 'react';
import ROUTE from './route';
import AwardBadgeContainer from './container/awardBadgeContainer';
import userProfileAwardsContainer from './container/userProfileAwardsContainer';
import UseAwardTabContainer from './container/userAwardsTabContainer';
import issueNewBadgeContainer from './container/issueNewBadgeContainer';
export default [

        {
            name: 'kudosHome',
            path: ROUTE.badge_awards_view,
            component: UseAwardTabContainer,
            exact: true,
            protect: true
        },
        {
            name: 'AddBadge',
            path: ROUTE.add_badge,
            component: issueNewBadgeContainer,
            exact: true,
            protect: true
        },
    
]