// @ts-nocheck
import { Row,Col,Spin,Radio } from "antd";
import PaginationDropdown from "modules/look-v2/Components/PaginantionDropdowm";
import PerformanceBar from "modules/look-v2/Components/PerformanceBar";

import React from "react";

const VerticalPerfrmanceView = ( props ) => {
  const {dateRangeTofilter,verticalDetail,performanceLoader,okrPerformanceForVertical,getOkrPerformanceForvertical,okrPerformanceForTeam,getOkrPerformanceForTeam,team_manager}=props
  const [PerformanceTab, setPerformanceTab] = React.useState('vertical');

  const changeTab=(event)=>{
    setPerformanceTab(event?.target?.value)
    if(event?.target?.value==='team' && !okrPerformanceForTeam?.edges){
      getOkrPerformanceForTeam(5,0,false)
    }
  }

  let current_result_count = React.useRef({
    team:5,
    vertical:5
  })

  React.useEffect(()=>{
    if(verticalDetail && dateRangeTofilter){
      current_result_count['current']={
        team:5,
        vertical:5
      }
      setPerformanceTab('vertical')
    }
  },[verticalDetail,dateRangeTofilter])

  const filterData=(first)=>{
    if(PerformanceTab==='vertical'){
      getOkrPerformanceForvertical(first,0,false)
      current_result_count['current'].vertical=first
    }else{
      getOkrPerformanceForTeam(first,0,false)
      current_result_count['current'].team=first
    }
  }
  return (
    <div>
     <Spin spinning={performanceLoader}>
        
          <Row justify="space-between" style={{margin:"30px 0px"}}>
          {
          team_manager?.length?
            <Col>
                <Radio.Group value={PerformanceTab} onChange={(e) => changeTab(e)}>
                    <Radio.Button style={{borderRight:"none",width:"80px"}} className={`section-assign ${PerformanceTab==='vertical'?'active-assign':''}`} value="vertical">Vertical</Radio.Button>
                    <Radio.Button style={{width:"80px"}}  className={`section-collab ${PerformanceTab ==='team'?'active-collab':''}`} value="team">Teams</Radio.Button>
                </Radio.Group>
            </Col>:<div style={{width:"5px"}}></div>
          }
            {(PerformanceTab==='vertical' &&okrPerformanceForVertical?.totalCount && okrPerformanceForVertical?.edgeCount<100)?<PaginationDropdown current={current_result_count['current'].vertical} onselect={(e)=>{filterData(e)}} totalCount={okrPerformanceForVertical?.totalCount}/>:''}
            {(PerformanceTab==='team' && okrPerformanceForTeam?.totalCount && okrPerformanceForTeam?.edgeCount<100)?<PaginationDropdown hideResult={true} showTooltip={{show:true,label:null,customType:"OKR's"}} current={current_result_count['current'].team} onselect={(e)=>{filterData(e)}} totalCount={okrPerformanceForTeam?.totalCount}/>:''}
          </Row>
        
        {
            okrPerformanceForVertical?.edges &&PerformanceTab==='vertical' && (
                
              okrPerformanceForVertical?.edges?.length?
                <>
                  <PerformanceBar {...props} performanceData={okrPerformanceForVertical?.edges||[]}/>
                 {(okrPerformanceForVertical?.totalCount>100 && okrPerformanceForVertical?.edgeCount>=100)
                   &&(
                    <div style={{width:'100%'}} align="center">
                    <h4 className="pagination-label" >{okrPerformanceForVertical?.edgeCount<okrPerformanceForVertical?.totalCount&&(<span style={{cursor:"pointer",color:"#B0CB1F"}} onClick={()=>{getOkrPerformanceForvertical(5,okrPerformanceForVertical?.edgeCount,true)}}><u>View more Okrs</u></span>)} <span>{`${okrPerformanceForVertical?.edgeCount===okrPerformanceForVertical?.totalCount?'Viewing':''}(${okrPerformanceForVertical?.edgeCount}/${okrPerformanceForVertical?.totalCount})`}</span> </h4>
                    </div>
                   )
                 }
                </>
                :
                <h4 style={{textAlign:'center',fontSize:"17px",marginTop:"30px"}}>No Records found</h4>    
              )

             
        }
        {
          okrPerformanceForTeam?.edges &&PerformanceTab==='team' && (
              
            okrPerformanceForTeam?.edges?.length?
              <>
              <PerformanceBar {...props} performanceData={okrPerformanceForTeam?.edges||[]}/>
              {(okrPerformanceForTeam?.totalCount>100 && okrPerformanceForTeam?.edgeCount>=100)
                &&(
                 <div style={{width:'100%'}} align="center">
                 <h4 className="pagination-label" >{okrPerformanceForTeam?.edgeCount<okrPerformanceForTeam?.totalCount&&(<span style={{cursor:"pointer",color:"#B0CB1F"}} onClick={()=>{getOkrPerformanceForTeam(10,okrPerformanceForTeam?.edgeCount,true)}}><u>View more Okrs</u></span>)} <span>{`${okrPerformanceForTeam?.edgeCount===okrPerformanceForTeam?.totalCount?'Viewing':''}(${okrPerformanceForTeam?.edgeCount}/${okrPerformanceForTeam?.totalCount})`}</span> </h4>
                 </div>
                )
              }
              </>
              :
              <h4 style={{textAlign:'center',fontSize:"17px",marginTop:"30px"}}>No Records found</h4>    
            )
        }
     </Spin>
    </div>
  )
};

export default VerticalPerfrmanceView

