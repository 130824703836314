import gql from 'graphql-tag';
import { FORM_SECTION_TYPE } from './FormSectionType.gql';

export const EDIT_FORM_SECTION = gql`
  ${FORM_SECTION_TYPE}
  mutation editFormSection(
    $editable: Boolean
    $id: ID!
    $referencefieldId: [Int]
    $sequence: Int
    $simplefieldId: [Int]
    $title: String
  ) {
    updateFormSection(
      editable: $editable
      id: $id
      referencefieldId: $referencefieldId
      sequence: $sequence
      simplefieldId: $simplefieldId
      title: $title
    ) {
      formSection {
        ...FormSectionType
      }
    }
  }
`;
