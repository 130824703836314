import React from 'react';

import { compose } from '../../core';
import { AdminLayout, displayDataCheck, displayBoolean, GetColumnSearchProps, getOrderBy, displayUtcToUserTimezone } from '../../look';
import { subscribeToQuestion } from './SurveySubscriptions';

import ROUTE from '../route';
import {
  withAllQuestionsForAGroupByGroupIdQuery,
  withDeleteQuestion,
  withSurveyFilterUpdating,
  withSurveyState
} from './SurveyOperations';

const Question = props => {
  const {
    loading,
    allQuestionsForAGroupByGroupId,
    onPaginationChange,
    deleteQuestion,
    onQuestionTextChange,
    onOrderByChange,
    orderBy,
    // onQuestionAddressChange,
    // onHelpTextChange,
    onSequenceChange,
    onFiltersRemove,
    subscribeToMore
  } = props;

  React.useEffect(() => {
    const subscribe = subscribeToQuestion(subscribeToMore /* , props.filter */);
    return () => subscribe();
  });

  const columns = [
    {
      title: 'Question Text',
      key: 'questionText',
      sorter: () => onOrderByChange(getOrderBy('questionText', orderBy)),
      ...GetColumnSearchProps('questionText', onQuestionTextChange),
      render: (text, record) => <>{record && displayDataCheck(record.questionText)}</>
    },
    {
      title: 'Sequence',
      key: 'sequence',
      sorter: () => onOrderByChange(getOrderBy('sequence', orderBy)),
      ...GetColumnSearchProps('sequence', onSequenceChange),
      render: (text, record) => <>{record && record.sequence}</>
    },
    {
      title: 'Date Published',
      key: 'pubDate',
      sorter: () => onOrderByChange(getOrderBy('pubDate', orderBy)),
      render: (text, record) => `${displayUtcToUserTimezone(record.pubDate, 'YYYY-M-DD HH:mm')}`
    },
    {
      title: 'Required',
      key: 'required',
      sorter: () => onOrderByChange(getOrderBy('required', orderBy)),
      render: (text, record) => <>{displayBoolean(record.required)}</>
    }
  ];

  const handleDeleteRecord = async(id) =>{

    await deleteQuestion({id:id})

  }
  

  return (
    <AdminLayout
      table={true}
      active={ROUTE.question}
      title="Question"
      loading={loading}
      tableData={allQuestionsForAGroupByGroupId}
      columns={columns}
      onDelete={deleteQuestion}
      specificMutation = {true}
      handleDeleteRecord = {handleDeleteRecord}
      editLink={ROUTE.editQuestionLink}
      addLink={`${ROUTE.addQuestionLink}${props.match.params.id}`}
      onPaginationChange={onPaginationChange}
      onFiltersRemove={onFiltersRemove}
      showBack={true}
    />
  );
};

export default compose(
  withSurveyState,
  withAllQuestionsForAGroupByGroupIdQuery,
  withDeleteQuestion,
  withSurveyFilterUpdating
)(Question);
