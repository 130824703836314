import gql from 'graphql-tag';
import { SURVEY_TYPE_CONNECTION } from './SurveyTypeConnection.gql';

export const ORG_SURVEY_BY_TEAM_ID_QUERY = gql`
  ${SURVEY_TYPE_CONNECTION}
  query orgSurveyByTeamId(
    $teamId: ID!
    $offset: Int
    $orderBy: [String]
    $first: Int
    $name_Icontains: String
    $description_Icontains: String
  ) {
    orgSurveyByTeamId(
      teamId: $teamId
      first: $first
      offset: $offset
      orderBy: $orderBy
      name_Icontains: $name_Icontains
      description_Icontains: $description_Icontains
    ) {
      ...SurveyTypeConnection
    }
  }
`;
