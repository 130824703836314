import { withApollo } from '@apollo/client/react/hoc'
import { compose } from 'modules/core'
import NoPermissionView from 'modules/kudos-user/component/NoPermissionComponent'
import { globalPermissionValidator } from 'modules/look'
import { scape_report_permission } from 'Permissions/ScapeReport.permission'
import React from 'react'
import ScapeReportTablePersonalityView from '../components/ScapeReportTablePersonality'
import ScapeReportView from '../components/ScapeReportView'
import { SURVEY_BY_ID } from '../graphql/surveyById.gql'


const ScapeReportContainer = (props) => {
    const { me, client, permissionLoader, userPermission,personality_survey } = props
    const [responseSurveyAnswer, setResponseSurveyAnswer] = React.useState([])
    const [responseTableData, setResponseTableData] = React.useState([])
    const [responseTableDataNew, setResponseTableDataNew] = React.useState([])
    const [scapeReportPermitted, setScapeReportPermitted] = React.useState(false);
    const [loading, setLoading] = React.useState(false)
    
    const selectedId = (val) => {
        setLoading(true)
        featchSurveyAnswers(val?.surveyId)
        // featchTableData(val)
        setResponseTableData([])
        setResponseSurveyAnswer([])
    }


    
    React.useEffect(() => {

        if (userPermission?.length) {
            let permission = globalPermissionValidator(scape_report_permission, userPermission)
            if (permission?.required_permission) {
                setScapeReportPermitted(permission)

            }
        }

    }, [userPermission])

    const featchSurveyAnswers = async (surveyId) => {
        const { data } = await client.query({
            query: SURVEY_BY_ID,
            fetchPolicy: 'network-only',
            variables: { id: surveyId },
        });
        if (data) {
            let surveyList = [].concat.apply([], data?.SurveyById?.groupSet?.edges[0]?.node?.questionSet?.edges.map(({ node }) => node?.choiceSet?.edges.map(({ node }) => node)))
            let surveyAfterRemoveDuplicates = removeDuplicateObjectFromArray(surveyList, 'value')
            setResponseSurveyAnswer(surveyAfterRemoveDuplicates)
            setLoading(false)
        }
    }
    function removeDuplicateObjectFromArray(array, key) {
        var check = new Set();
        return array.filter(obj => !check.has(obj[key]) && check.add(obj[key]));
    }

   
 
    return (
        <div>
            {
                !permissionLoader &&
                <>
                    {
                        (scapeReportPermitted?.required_permission) ?
                            
                            personality_survey ?

                               <ScapeReportTablePersonalityView
                               {...props} onSelectedId={(e) => selectedId(e)} responseSurveyAnswer={responseSurveyAnswer} responseTableData={responseTableData} responseTableDataNew={responseTableDataNew} loading={loading} />
                            :
                            <ScapeReportView {...props} onSelectedId={(e) => selectedId(e)} responseSurveyAnswer={responseSurveyAnswer} responseTableData={responseTableData} responseTableDataNew={responseTableDataNew} loading={loading}  />
                            
                            :
                            <NoPermissionView />
                    }
                </>
            }
        </div>

    )
}

export default compose( withApollo)(ScapeReportContainer)