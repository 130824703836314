import { Row ,Tooltip} from "antd";
import EmployeeListContainer from "modules/Objective-keyresult/container/employeeListContainer";
import TeamOkrPerformanceContainerPopup from "modules/Objective-keyresult/formComponents/Container/TeamOkrPerformanceContainerPopup";
import React from "react";
import ash_expansion from '../../assets/ash_expansion.svg'
import team_icon from '../../assets/team_icon.svg'
import transparent_expansion from '../../assets/transparent_expansion.svg'
const TeamListComponent =(props)=>{
    const{TeamList,me,styleName,dateRangeTofilter}=props
    const[openDetail,setOpenDetail]=React.useState(false)
    const[selectedTeam,setselectedTeam]=React.useState()
    const [expandedRow, setexpandedRow] = React.useState([])
    const openDetails =(item)=>{
        setOpenDetail(true)
        setselectedTeam(item)
    }

    const expand=(id)=>{
 
        let list = [].concat(expandedRow)
        if(!list?.includes(id)){
            // list.push(id)
            list = [id]
        }else{
            list = list.filter(item=>item!==id)
        }
        setexpandedRow(list)
      }

    return (
        <>
            <div style={{width:'100%',boxSizing:"border-box"}}>
                {TeamList?.length&&TeamList?.map((item)=>(
                    <>
                    {/* <div className="row" style={{width:"100%"}}>
                        <div className={styleName||"table-side-section"}></div> */}
                        <div>
                            <div  className="user-card user-card-attribute" onClick={(e)=>{setselectedTeam(item)}} style={{margin:"10px 10px",minWidth:"250px",marginLeft:"40px"}}>
                                <Row style={{width:"100%",display:"flex",flexDirection:"row",flexWrap:"nowrap",gap:"0px",alignItems:"center"}}>
                                    <div style={{width:"68px",minWidth:"68px"}}>
                                        {item?.employeeCount?
                                        <img src={ash_expansion} onClick={(e)=>{expand(item?.id);e.stopPropagation()}} className={`expansion-arrow ${expandedRow?.includes(item?.id)?'expansion-arrow-down':''}`} alt="" />:
                                        <img src={transparent_expansion} alt="" />
                                        }
                                        <img style={{width:"33px",height:"33px",marginLeft:"10px"}} src={team_icon} alt="" />
                                    </div>
                                    <Tooltip title={item?.name}>
                                        <h4 className="graph-title" style={{padding:"0px",fontWeight:"600",textAlign:'left',margin:0}}>{item?.name}</h4>
                                    </Tooltip>
                                    
                                </Row> 
                            </div>
                            {
                                expandedRow?.includes(item?.id) && ( 
                                    <EmployeeListContainer styleName={'table-side-section-by-sub-team'} teamDetail={item}/>
                                ) 

                            }
                        </div>
                    {/* </div> */}
                    {
                        // expandedRow?.includes(item?.id) && ( 
                        //     <EmployeeListContainer styleName={'table-side-section-by-sub-team'} teamDetail={item}/>
                        // ) 
                    }
                  
                    </>
                ))}
            </div>
           
            {selectedTeam&&<TeamOkrPerformanceContainerPopup dateRangeTofilter={dateRangeTofilter}  visible={selectedTeam} me={me} teamDetail={selectedTeam} onClose={()=>{setOpenDetail(false);setselectedTeam(null)}}/>}
        </>
    )
}

export default TeamListComponent