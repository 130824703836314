import React from 'react';
import { compose } from '../../core';
import { getIntFromString, withMarkdownFieldValue } from '../../look';
import ManagerAddCommitmentView, { CKEditorFieldName } from '../components/ManagerAddCommitmentView';
import { withAddCustomizedCoachingCommitment, withMyCustomizedCoachingCommitments } from './ManagerOperations';
import { withMe } from '../../user/containers/UserOperations';
import { subscribeToGetCustomCoachingCommitmentProgress } from './ManagerSubscription';

const ManagerAddCommitment = props => {
  const { createCustomizedCoachingCommitment, me, modal2, setModal2,subscribeToMore } = props;

  React.useEffect(() => {
    const subscribe = subscribeToGetCustomCoachingCommitmentProgress(subscribeToMore /* , props.filter */);
    return () => subscribe();
  });
  const handleSubmit = async values => {
    try {
      const data = {
        ...withMarkdownFieldValue(CKEditorFieldName, values),
        forUserId: getIntFromString(me.id)
      };
      if (modal2.default) {
        data.coachingCommitmentData.commitmentId = modal2.id;
      } else {
        data.coachingCommitmentData.customusercommitmentId = modal2.id;
      }
      // console.log(data);
      const response = await createCustomizedCoachingCommitment(data);
      if (response) {
        setModal2({ visible: false, id: 0, default: false });
      }
    } catch (e) {
      throw new Error(e.message);
    }
  };
  return <ManagerAddCommitmentView {...props} onSubmit={handleSubmit} />;
};

export default compose(
  withMe,
  withMyCustomizedCoachingCommitments,
  withAddCustomizedCoachingCommitment
)(ManagerAddCommitment);
