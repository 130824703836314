import gql from 'graphql-tag';

export const SURVEY_RESPONSE_STATE_QUERY = gql`
  query surveyResponseState {
    surveyResponseState @client {
      pagination {
        first
        offset
      }
      orderBy
      filter {
        survey_Name_Icontains
      }
    }
  }
`;
