import { Button, Col, List, Modal, Row } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { IMG } from '../../../config';
import { compose } from '../../core';
import { getIntFromString, getOrgListFromTeam, getOrgListFromVer, getToolTypeBase64StringFromInt, removeDuplicate } from '../../look';
import OrganizationTree from './OrganizationTree';
import { withApollo } from '@apollo/client/react/hoc';
import SelectFilterField from '../../look/components/SelectFilterField';
import {
  withAllOrganization,
  withOrganizationFilterUpdating, withOrganizationState
} from '../../organization/containers/OrganizationOperation';
import { withEditToolAdminPanel } from './ToolOperations';
import { TOOL_SUBSCRIPTION } from '../graphql/ToolSubscription.gql';

const ToolAssign = props => {
  const {
    assign,
    setAssign,
    getToolByIdAdmin,
    onFiltersRemove,
    editTool,
    allOrganization,
    onNameChange,
    onDescriptionChange,
    onEmailChange,
    client, 
    setToolDetails
  } = props;
  const [visible, setVisible] = React.useState(false);
  const [orgId, setOrgId] = React.useState(0);
  const [assignedOrgs,setAssignedOrgs] = React.useState({});
  const [tree, setTree] = React.useState(false);
  const handleSubmit = async organizations => {
    try {
      await editTool({ id: getIntFromString(getToolByIdAdmin.id), name: getToolByIdAdmin.name, organizations });
    } catch (e) {
      throw new Error(e.message);
    }
  };
  const renderOrgList = (org, header = false) =>
    org?.edgeCount > 0 ? (
      <ListScroll
        header={header && <div align="center">Organizations</div>}
        bordered
        dataSource={org?.edgeCount > 0 && org?.edges.map(({ node }) => node)}
        renderItem={item => (
          <Selectable>
            <List.Item>
              <Row justify="space-between">
                <Col span={24}
                  type="default"
                  ghost
                  onClick={() => {
                    setOrgId(item.id);
                    setTree(true);
                  }}
                >
                  <img src={item?.logo ? item?.logo : IMG} alt="" style={{ width: 40, borderRadius: 30, marginRight: 10 }} /> {`${item.name}`}
                </Col>
              </Row>
            </List.Item>
          </Selectable>
        )}
      />
    ) : null;

    let toolSub = undefined;

    const ToolSubscription = async() =>{
      let id = getToolTypeBase64StringFromInt( props.match.params.id );
      toolSub = await client.subscribe( {
        query: TOOL_SUBSCRIPTION,
        variables: {id},
        fetchPolicy: 'network-only'
      } ).subscribe( {
        next( result ) {
             switch ( result.data.toolsSubscription.mutation ) {
    
            case 'UPDATE':
              
              let changedOrgSet = [];
              let changedVerticalSet = [];
              let changedTeamSet = [];
              changedOrgSet = [...result.data.toolsSubscription.tool.organization?.edges]
               changedTeamSet = [...result.data.toolsSubscription.tool.team?.edges]
               changedVerticalSet = [...result.data.toolsSubscription.tool.vertical?.edges]
              let toolDetails = {...getToolByIdAdmin};
              
              toolDetails = {...toolDetails,organization:{edges:changedOrgSet,edgeCount:result.data.toolsSubscription.tool.organization?.edgeCount},
              vertical:{edges:changedVerticalSet,edgeCount:result.data.toolsSubscription.tool.vertical?.edgeCount},
              team:{edges:changedTeamSet,edgeCount:result.data.toolsSubscription.tool.team?.edgeCount}}
              
              setToolDetails(toolDetails)
              break;
  
            default:
                break
          }
        
        }})
    }

    React.useEffect(()=>{
      if(getToolByIdAdmin){
        
        ToolSubscription()
        const assignedOrg = removeDuplicate([
          ...getToolByIdAdmin.organization.edges,
          ...((getToolByIdAdmin?.vertical &&
            getToolByIdAdmin.vertical.edgeCount > 0 &&
            getOrgListFromVer(getToolByIdAdmin.vertical).edges) ||
            []),
          ...((getToolByIdAdmin?.team &&
            getToolByIdAdmin.team.edgeCount > 0 &&
            renderOrgList(getOrgListFromTeam(getToolByIdAdmin.team)).edges) ||
            [])
        ]);
         
        setAssignedOrgs(assignedOrg)
      }
    },[getToolByIdAdmin])


  const allVerticals =
    getToolByIdAdmin &&
    getToolByIdAdmin.vertical &&
    getToolByIdAdmin.vertical.edges.map(({ node }) => getIntFromString(node.id));
  const allTeams =
    getToolByIdAdmin && getToolByIdAdmin.team && getToolByIdAdmin.team.edges.map(({ node }) => getIntFromString(node.id));
  const allOrganizations =
    getToolByIdAdmin &&
    getToolByIdAdmin.organization &&
    getToolByIdAdmin.organization.edges.map(({ node }) => getIntFromString(node.id));
 
  return (
    <>
      <Button type="primary" ghost onClick={() => setAssign(true)}>
        Tool Assigned
      </Button>
      <Modal
        destroyOnClose
        visible={assign}
        onCancel={() => setAssign(false)}
        footer={null}
        title="Tool Assigned"
        width={'45%'}
        bodyStyle={{ padding: 16, minHeight: '80%' }}
      >
        {visible ? (
          <>
            <Row>
              <Button type="primary" onClick={() => setVisible(false)}>
                Assigned
              </Button>
            </Row>
            <br />
            {getToolByIdAdmin && renderOrgList(assignedOrgs, true)}
          </>
        ) : (
          <>
            <Row justify="space-between">
              <Button type="primary" onClick={() => setVisible(true)}>
              All Organizations
              </Button>
              <SelectFilterField
                placeholder="Search Organization by"
                onFiltersRemove={onFiltersRemove}
                options={[
                  {
                    label: 'Name',
                    type: 'text',
                    value: onNameChange
                  },
                  {
                    label: 'Description',
                    type: 'text',
                    value: onDescriptionChange
                  },
                  {
                    label: 'Email',
                    type: 'text',
                    value: onEmailChange
                  }
                ]}
              />
            </Row>
            <br />
            {allOrganization && allOrganization.edgeCount > 0 && (
              <ListScroll
                header={<div align="center">Organizations</div>}
                
                bordered
                dataSource={
                  allOrganization && allOrganization.edgeCount > 0 && 
                  
                  allOrganization.edges
                  .filter(({ node }) => assignedOrgs?.edges?.map(({ node }) => node.id).indexOf(node.id) === -1)
                  .map(({ node }) => node)
                }
                renderItem={item => (
                  <Selectable>

                    <List.Item>
                      <Row justify="space-between">
                        <Col span={24}
                          type="default"
                          ghost
                          onClick={() => {
                            setOrgId(item.id);
                            setTree(true);
                          }}
                        >
                          <img src= {item?.logo ? item?.logo : IMG} alt="" style={{ width: 40, borderRadius: 30, marginRight: 10 }} /> {`${item.name}`}
                        </Col>
                      </Row>
                    </List.Item>
                  </Selectable>
                )}
              />
            )}
          </>
        )}
      </Modal>
      <Modal
        destroyOnClose
        visible={tree}
        onCancel={() => setTree(false)}
        footer={null}
        title="Survey Assigned"
        width={'80%'}
        bodyStyle={{ padding: 16, maxHeight: '80vh', overflowX: 'scroll' }}
      >
        {tree && (
          <OrganizationTree
            orgId={getIntFromString(orgId)}
            toolId={getToolByIdAdmin.id}
            toolName={getToolByIdAdmin.name}
            allVerticals={allVerticals}
            allTeams={allTeams}
            allOrganizations={allOrganizations}
            editTool={editTool}
          />
        )}
      </Modal>
    </>
  );
};

const Selectable = styled.div`
  &:hover {
    cursor: pointer;
    background-color: #fffff0;
  }
`;

const ListScroll = styled(List)`
  .ant-list-items {
    max-height: 60vh;
    overflow-y: scroll;
  }
`;
export default compose(
  withApollo,
  withOrganizationState,
  withAllOrganization,
  withOrganizationFilterUpdating,
  withEditToolAdminPanel
)(ToolAssign);
