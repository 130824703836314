import gql from 'graphql-tag';

export const FAQ_QUESTION_TYPE = gql`
  fragment FaqQuestionType on FAQQuestionType {
    id
    question
    answer
    catagory {
      id
    }
  }
`;
