import gql from "graphql-tag";


export const COMPETENCY_BY_ID = gql`
query competencyCategoryId($id:ID!){
    competencyCategoryId(id:$id){
      id
      title
      competencySet {
        edges {
          node {
            id
            title
            behaviourallyanchoredratingSet {
              edges {
                node {
                  id
                  employeeLevel {
                    id
                  }
                  ratingScale {
                    id
                    sequence
                  }
                  behaviourExample
                }
              }
            }
          }
        }
      }
    }
  }

`



export const ALL_RATING_SCALES = gql`
  query ratingScales {
    ratingScales(orderBy:["sequence"]){
        totalCount
        edges{
          node{
            id
            name
            sequence
          }
        }
    }
  }
`;

export const CREATE_BARS_IN_BULK_MUTATION = gql`
  mutation createBulkBehaviourallyAnchoredRatings($behaviourallyAnchoredRatingDataList: [BehaviourallyAnchoredRatingInput]!) {
    createBulkBehaviourallyAnchoredRatings(behaviourallyAnchoredRatingDataList:$behaviourallyAnchoredRatingDataList){
      behaviourallyAnchoredRating{
            id
            employeeLevel {
              id
            }
            ratingScale {
              id
              sequence
            }
            behaviourExample
          }
    }
  }
`;
