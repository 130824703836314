import gql from 'graphql-tag';

export const CREATE_BADGE_FAMILY =gql`
    mutation createBadgeFamily($badgeFamilyData: BadgeFamilyInput!){
        createBadgeFamily(badgeFamilyData:$badgeFamilyData){
            badgeFamily{
                id
            }
        }
    }
`