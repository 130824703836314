import gql from 'graphql-tag';
import { FAQ_CATEGORY_TYPE } from './FaqCategoryType.gql';

export const ADD_FAQ_CATEGORY = gql`
  ${FAQ_CATEGORY_TYPE}
  mutation createFaqCategory($categoryData: CategoryInput!) {
    createFaqCategory(categoryData: $categoryData) {
      category {
        ...FaqCategoryType
      }
    }
  }
`;
