import { Progress, message } from "antd";
import { learningConfig } from "modules/learning-path/configs/learning-config";
import { learningPathIcons } from "modules/learning-path/icons";
import React from "react";


const LandingView = (props) => {
    const { navigateRoute, complete_survey_data,app_sidebar_expand } = props
    const [completed_percentage, setCompletedPercentage] = React.useState(0)
    const [hoveredIndex, setHoveredIndex] = React.useState(null);

    console.log("hoveredIndex",hoveredIndex)
    React.useEffect(() => {
        if (complete_survey_data) {
            let total_cards = learningConfig?.card_details?.length
            let totalCounts = Object.values(complete_survey_data).map(item => item.totalCount);
            let completed_count = totalCounts.every(count => count > 0) ? 1 : 0;
            let completed_percentage = (completed_count / totalCounts.length) * 100
            setCompletedPercentage(completed_percentage % 1 === 0 ? completed_percentage : parseFloat(completed_percentage.toFixed(2)))
        }
    }, [complete_survey_data])
    return (
        <div className="learning-root">
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "start", gap: "1em", alignItems: "center" }}>
                <img src={learningPathIcons?.back_arrow} alt="back_arrow" style={{ width: "20px", cursor: "pointer" }} onClick={() => navigateRoute("-1")} />
                <h4 className="learing-back-title">Learning Path</h4>
            </div>
            <div className="learning-container">
                <div className="learing-space-between">
                    <h1 className="learing-main-title">MApp <span style={{ fontWeight: "275" }}>Learning Path</span>
                        <img src={learningPathIcons?.bulb_icon} alt="bulb_icon" style={{ width: '1.5em' }} /></h1>
                    <h4 className="learn-completed-progress">{completed_percentage}% Completed</h4>
                </div>
                <Progress
                    style={{ marginTop: '2em' }}
                    className="learn-progress"
                    showInfo={false}
                    strokeColor={{
                        '0%': '#37FF9A',
                        '100%': '#62C83D',
                    }}
                    percent={completed_percentage}
                />
                <div className="landing-card-container" style={{justifyContent:app_sidebar_expand?"start":'center'}}>
                    {learningConfig?.card_details?.map((item, index) => (
                        <div className="landing-card"
                            style={{
                                background: item?.background,
                                boxShadow: item?.boxshadow,
                                zIndex: index < hoveredIndex?`${1000 + (index + 1)}`:`${1000 - (index + 1)}`,
                                marginLeft: index > 0 ? `-${index + 1 * 4}em` : '0'
                            }}
                            onMouseEnter={() => setHoveredIndex(index)}
                            onMouseLeave={() => setHoveredIndex(null)}>
                            <div>
                                <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", gap: '1em' }}>
                                    <div>
                                        <h4 className="landing-card-title" style={{ fontWeight: "500", fontSize: '.9em' }}>Module</h4>
                                        <h4 className="landing-card-title">{item?.card_title}</h4>
                                        <h4 className="landing-card-title" style={{ fontWeight: "500", fontSize: '.9em' }}>{item?.time}</h4>
                                    </div>
                                    <h2 className="landing-card-counter">{index + 1}</h2>
                                </div>
                                <p className="landing-card-description">{item?.description}</p>
                            </div>
                            <div style={{ width: '100%', display: 'flex', justifyContent: "end" }}>
                                <img className="learn-card-icon" src={item?.icon} alt={`icon_${index + 1}`} />
                            </div>
                            <div className="learn-start-button" onClick={() => {item?.route?navigateRoute(item?.route):message.info('Feature coming soon!!')}}>START</div>
                        </div>
                    ))}

                </div>

            </div>

        </div>
    )
}

export default LandingView;