import gql from 'graphql-tag';
import { CONDITION_TYPE } from './ConditionType.gql';

export const DELETE_CONDITION = gql`
  ${CONDITION_TYPE}
  mutation deleteCondition($id: ID!) {
    deleteCondition(id: $id) {
      condition {
        ...ConditionType
      }
    }
  }
`;
