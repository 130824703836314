import gql from 'graphql-tag';
import { ORGANIZATION_TYPE_CONNECTION } from '../../organization/graphql/OrganizationTypeConnection.gql';
import { VERTICAL_TYPE_CONNECTION } from '../../organization/graphql/VerticalTypeConnection.gql';
import { TEAM_TYPE_CONNECTION } from '../../organization/graphql/TeamTypeConnection.gql';
// import { FORM_SECTION_TYPE_CONNECTION } from '../../form-section/graphql/FormSectionTypeConnection.gql';
import { CUSTOM_FORM_SECTION_TYPE_CONNECTION } from '../../form-section/graphql/Custom_FormSectionTypeConnection.gql';
import { TEXT_SECTION_TYPE_CONNECTION } from '../../text-section/graphql/TextSectionTypeConnection.gql';
import { COMMITMENT_TYPE_CONNECTION } from './CommitmentTypeConnection.gql';

export const TOOL_TYPE = gql`
  ${ORGANIZATION_TYPE_CONNECTION}, ${VERTICAL_TYPE_CONNECTION}, ${TEAM_TYPE_CONNECTION}, ${COMMITMENT_TYPE_CONNECTION}, ${CUSTOM_FORM_SECTION_TYPE_CONNECTION}, ${TEXT_SECTION_TYPE_CONNECTION}
  fragment ToolType on ToolType {
    id
    title
    uiFlow
    thumbnailImage
    description
    status
    public
    pubDate
    organization {
      ...OrganizationTypeConnection
    }
    vertical {
      ...VerticalTypeConnection
    }
    team {
      ...TeamTypeConnection
    }
    commitment {
      ...CommitmentTypeConnection
    }
    textsection {
      ...TextSectionTypeConnection
    }
    formsection {
      # ...FormSectionTypeConnection
      ...Custom_FormSectionTypeConnection
    }
  }
`;
