import { Modal } from "antd";
import React from "react";
import readiness_close_icon from '../../assets/readiness-close-icon.svg'
import { ReactMarkdown } from "modules/look";
const LearnInfoView =(props)=>{
    const {info_detail,onClose}=props
    return (
        <div>
            <Modal
            visible={info_detail}
            footer={false}
            closable={false}
            width={"90%"}
            centered={true}
            destroyOnClose={true}
            className="info-preview-modal">
                 <div style={{ width: "100%"}}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: '100%' }}>
                        <div className="landing-card-title" style={{ fontSize: "1.25em" }}>{info_detail?.title}</div>
                        <img src={readiness_close_icon} alt="readiness_close_icon" onClick={() => onClose()} style={{ cursor: "pointer",marginTop:"-.5em",marginRight:'-.5em' }} />
                    </div>
                    <p className="landing-card-title" style={{ fontSize: "1.1em",fontWeight:"500",color:"#141414",marginTop:"1em" }}> <ReactMarkdown>{info_detail?.detail}</ReactMarkdown></p>
                 </div>
            </Modal>
        </div>
    )
}

export default LearnInfoView;