import gql from 'graphql-tag';
import { FAQ_QUESTION_TYPE } from './FaqQuestionType.gql';

export const EDIT_FAQ_QUESTION = gql`
  ${FAQ_QUESTION_TYPE}
  mutation editFaqQuestion($id: ID!, $questionText: String, $answer: String, $catagoryId: Int) {
    updateFaqQuestion(id: $id, questionText: $questionText, answer: $answer, catagoryId: $catagoryId) {
      question {
        ...FaqQuestionType
      }
    }
  }
`;
