import React from "react";
import { Progress, Form } from "antd";

import { getStrokeColor } from "../../../look";

import { withMyTeamsMAppPulseCheck } from "../../containers/DashboardOperations";

const FormItem = Form.Item;

const RenderMyTeamsMAppPulseCheck = ({ loading, myTeamsMAppPulseCheck }) => (
  <FormItem
    labelCol={{ span: 24 }}
    wrapperCol={{ span: 24, offset: 1 }}
    label={"User’s Team’s MApp Pulse Check"}
  >
    <Progress
      format={(percent) => percent / 10 + "%"}
      percent={
        (myTeamsMAppPulseCheck &&
          JSON.parse(myTeamsMAppPulseCheck)?.overall?.toFixed(2) * 10) ||
        0
      }
      strokeColor={
        myTeamsMAppPulseCheck &&
        getStrokeColor(
          JSON.parse(myTeamsMAppPulseCheck)?.overall?.toFixed(2) || 0
        )
      }
    />
  </FormItem>
);

export default withMyTeamsMAppPulseCheck(RenderMyTeamsMAppPulseCheck);

//modifications on line
// 6;
// 10;
// 12;
// 17;
// 22;
// 23;
// 27;
// 29;
// 36;
// 37;
