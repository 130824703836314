import gql from 'graphql-tag';

export const STORY_STATE_QUERY = gql`
  query storyState {
    storyState @client {
      pagination {
        first
        offset
      }
      orderBy
      filter {
        title_Icontains
        content_Icontains
        name_Icontains
        description_Icontains
        commentText_Icontains
        user
        author
      }
    }
  }
`;
