import gql from "graphql-tag";

export const FEEDBACK_BY_ID_TYPE = gql`
fragment FeedbackType on FeedbackType {
  id
  createdAt
  feedbackFrom{
    id
    user{
      id
      employee{
        id
      }
      firstName
      lastName
    }
  }

  feedbackTo{
    id
    user{
      id
      employee{
        id
      }
      firstName
      lastName
    }
  }

  okr{
    id
    title
    okrType
  }
  badgeAward {
    edges {
      node {
        id
        expirationDate
        badge {
          id
          title
          behaviouralDescription
          image
        }
      }
    }
  }

  detailFillIn
feedbackType
createdAt
updatedAt

response{
  id
  answerSet{
    edges{
      node{
        id
        answer
        question{
          id
          questionAddress
          questionText
        }
      }
    }
  }
}

        
}
`

export const FEEDBACK_BY_ID_SUBSCRIPTION = gql`
${FEEDBACK_BY_ID_TYPE}
subscription feedbackSubscription {
  feedbackSubscription {
      mutation
      feedback {
          ...FeedbackType
      }
  }
}`

export const FEEDBACK_BY_ID = gql`
${FEEDBACK_BY_ID_TYPE}
query feedbackById($id:ID!){
  feedbackById(id:$id){
    ...FeedbackType 
  }
}
`;
