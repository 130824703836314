import gql from 'graphql-tag';
import { EMPLOYEE_TYPE } from '../../organization/graphql/EmployeeType.gql';
// import { RESPONSE_TYPE } from '../../survey/graphql/ResponseType.gql';
// import { ANSWER_TYPE } from '../../survey/graphql/AnswerType.gql';

import { PROFILE_TYPE } from './ProfileType.gql';

// ${EMPLOYEE_TYPE}, ${RESPONSE_TYPE}, ${PROFILE_TYPE}, ${ANSWER_TYPE}, ${TOKEN_TYPE}
export const USER_NODE = gql`
  ${PROFILE_TYPE},${EMPLOYEE_TYPE},
  fragment UserNode on UserNode {
    id
    username
    firstName
    lastName
    # email
    # isActive
    # dateJoined
    # updatedId {
    #   ...EmployeeType
    # }
    # responseSet {
    #   ...ResponseType
    # }
    profile {
      ...ProfileType
    }
    # UserprofileCraetedId {
    #   ...ProfileType
    # }
    # UserprofileUpdatedId {
    #   ...ProfileType
    # }
    # lastLogin
    isStaff
    isSuperuser
    employee {
      ...EmployeeType
    }
    # craetedId {
    #   ...EmployeeType
    # }
    # token {
    #   ...TokenType
    # }
    # externalUserCraetedId {
    #   ...TokenType
    # }
    # externalUserUpdatedId {
    #   ...TokenType
    # }
    # pk
    # archived
    verified
    # secondaryEmail
  }
`;
