import React from 'react';
import { Form, Button, Row, Col, Empty, Divider, Modal, Spin } from 'antd';
import { EyeOutlined } from '@ant-design/icons';

import { FormSectionAutoComplete, getIntFromString, TextField } from '../../look';
import AddFormSection from './AddFormSection';

const FormSectionComponent = props => {
  const { btn, formSection, onSubmit, sequence } = props;
  const [visible, setVisible] = React.useState(false);
  const [actionLoading, setActionLoading] = React.useState(false);
  const [data, setData] = React.useState(formSection?.condition?.edges);
  const [form] = Form.useForm();

  const handleSubmit = async values => {
    setActionLoading(true);
    try {
      if (formSection.id) {
        // await editTextSection({
        //   id: getIntFromString(formSection.id),
        //   condition: data?.map(({ node }) => getIntFromString(node.id)),
        //   sequence: formSection.sequence
        // });
      } else {
        // const response = await addTextSection({
        //   condition: data?.map(({ node }) => getIntFromString(node.id)),
        //   textSectionData: {
        //     ...withMarkdownFieldValue(CKEditorFieldName, values).textSectionData,
        //     sequence: formSection.sequence || 1
        //   }
        // });
        // response && setData(response?.condition?.edges);
        /* response &&  */ onSubmit([...form.getFieldValue('formsection'), ...getIntFromString(values.formsection)]);
        /* response &&  */ form.setFieldsValue({
          ...form.getFieldValue(true),
          formsection: [...form.getFieldValue('formsection'), ...getIntFromString(values.formsection)]
        });
        setVisible(false);
      }
      setActionLoading(false);
    } catch (err) {
      setActionLoading(false);
      throw new Error(err.message);
    }
  };

  const initialValue = {
    formsection: formSection?.edges?.map(({ node }) => getIntFromString(node.id)) || []
  };

  return (
    <>
      {btn === 'add' ? (
        <Button block type={'primary'} onClick={() => setVisible(true)}>
          Form
        </Button>
      ) : (
        <Button
          type={'primary'}
          // htmlType={type}
          ghost={true}
          size={'small'}
          icon={<EyeOutlined />}
          shape="circle"
          onClick={() => setVisible(true)}
        />
      )}

      <Modal
        destroyOnClose
        centered
        visible={/* true || */ visible || window.location.href.includes('openmodal')}
        onCancel={() => setVisible(false)}
        // footer={null}
        // width="50%"
        title={formSection.title}
        footer={null}
      >
        <Spin spinning={actionLoading} size="medium">
          <Form layout="vertical" form={form} onFinish={handleSubmit} initialValues={initialValue} scrollToFirstError={true}>
            {/* {data?.map(({ node }) => (
              <>
                <Row justify="space-between" align="middle">
                </Row>
                <Divider />
              </>
            ))}
            {!data && (
              <div align="center">
                <Empty />
                <br />
              </div>
            )} */}
            {btn === 'add' && (
              <FormSectionAutoComplete offset={1} name="formsection" /* margin={false} */ label="Form" mode="multiple" />
            )}

            {/* <AssignCondition onSubmit={e => setData(data ? [...data, e] : [e])} /> */}
            <br />
            <br />
            <Row justify="space-between">
              <Col>
                <AddFormSection btn="add" onSubmit={handleSubmit} sequence={sequence} btnText={'Add new form'} />
              </Col>
              <Col>
                <Button type="primary" htmlType="submit">
                  Done
                </Button>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default FormSectionComponent;
