// @ts-nocheck
import { subscribeToQuery } from '../../look';

import { SURVEY_SUBSCRIPTION } from '../graphql/SurveySubscription.gql';
import { QUESTION_SUBSCRIPTION } from '../graphql/QuestionSubscription.gql';
import { SURVEY_QUESTION_GRPOUP_SUBSCRIPTION } from '../graphql/SurveyQuestionGroupSubscription.gql';
import { SURVEY_RESPONSE_SUBSCRIPTION } from '../graphql/SurveyResponseSubscription.gql';
import { SURVEY_TOKEN_SUBSCRIPTION } from '../graphql/SurveyTokenSubscription.gql';
import { SURVEY_BY_ID_VALUES_SUBSCRIPTION } from '../graphql/SurveyByIdQuery.gql';

export const subscribeToSurvey = ( subscribeToMore, variables ) => {
  return subscribeToQuery(
    subscribeToMore,
    {
      document: SURVEY_SUBSCRIPTION,
      subscriptionName: 'surveySubscription',
      nodeName: 'survey',
      queryName: 'allSurveysForTokens'
    },
    variables
  );
};

export const subscribeToSurveyToken = ( subscribeToMore, variables ) => {
  return subscribeToQuery(
    subscribeToMore,
    {
      document: SURVEY_TOKEN_SUBSCRIPTION,
      subscriptionName: 'surveyTokenSubscription',
      nodeName: 'token',
      queryName: 'getAllTokensList'
    },
    variables
  );
};

export const subscribeToQuestion = ( subscribeToMore, variables ) => {
  return subscribeToQuery(
    subscribeToMore,
    {
      document: QUESTION_SUBSCRIPTION,
      subscriptionName: 'surveyQuestionSubscription',
      nodeName: 'question',
      queryName: 'allQuestionsForAGroupByGroupId'
    },
    variables
  );
};

export const subscribeToGroup = ( subscribeToMore, variables ) => {
  return subscribeToQuery(
    subscribeToMore,
    {
      document: SURVEY_QUESTION_GRPOUP_SUBSCRIPTION,
      subscriptionName: 'surveyQuestionGroupSubscription',
      nodeName: 'group',
      queryName: 'allGroupsForSurveyBySurveyId'
      // queryName: 'SurveyById'
    },
    variables
  );
};

export const subscribeToResponses = ( subscribeToMore, variables ) => {
  return subscribeToQuery(
    subscribeToMore,
    {
      document: SURVEY_RESPONSE_SUBSCRIPTION,
      subscriptionName: 'surveyResponseSubscription',
      nodeName: 'surveyResponse',
      queryName: 'allResponsesByUserId'
    },
    variables
  );
};

export const subscribeToMyResponses = ( subscribeToMore, variables ) => {
  return subscribeToQuery(
    subscribeToMore,
    {
      document: SURVEY_RESPONSE_SUBSCRIPTION,
      subscriptionName: 'surveyResponseSubscription',
      nodeName: 'surveyResponse',
      queryName: 'allResponsesByImplicitUserId'
    },
    variables
  );
};

export const subscribeToAllOrgSurveysByImplicitOrgId = ( subscribeToMore, variables ) => {
  return subscribeToQuery(
    subscribeToMore,
    {
      document: SURVEY_SUBSCRIPTION,
      subscriptionName: 'surveySubscription',
      nodeName: 'survey',
      queryName: 'allOrgSurveysByImplicitOrgId'
    },
    variables
  );
};

export const subscribeToAllPublicSurveysSubscribeToMore = ( subscribeToMore, variables ) => {
  return subscribeToQuery(
    subscribeToMore,
    {
      document: SURVEY_SUBSCRIPTION,
      subscriptionName: 'surveySubscription',
      nodeName: 'survey',
      queryName: 'allPublicSurveys'
    },
    variables
  );
};

export const suerveyIdByValuesSubscription = ( subscribeToMore, variables ) => {
  return subscribeToQuery(
    subscribeToMore,
    {
      document: SURVEY_BY_ID_VALUES_SUBSCRIPTION,
      subscriptionName: ' surveySubscription',
      nodeName: 'survey',
      queryName: 'SurveyById'
    },
    variables
  );
};