import gql from 'graphql-tag';

export const GET_USER_BY_USERNAME_OR_EMAIL_QUERY = gql`
  query users($email: String, $username: String) {
    users(email: $email, username: $username) {
      edges{
        node{
          id
          firstName
          lastName
          username
          email
        }
      }
    }
  }
`;
