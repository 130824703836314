import gql from 'graphql-tag';
import { STORY_TYPE_CONNECTION } from './StoryTypeConnection.gql';

export const ALL_STORIES_QUERY = gql`
  ${STORY_TYPE_CONNECTION}
  query allStories(
    $first: Int
    $offset: Int
    $orderBy: [String]
    $title_Icontains: String
    $content_Icontains: String
    $author: String
  ) {
    allStories(
      first: $first
      offset: $offset
      orderBy: $orderBy
      title_Icontains: $title_Icontains
      content_Icontains: $content_Icontains
      author: $author
    ) {
      ...StoryTypeConnection
    }
  }
`;
