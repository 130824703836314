import React from "react";

import { compose } from "../../core";
import EmployeeMAppAnchorsView from "../../dashboard/components/EmployeeMAppAnchorsView";

import { withEmployeeMAppAnchors } from "./ManagerOperations";

const EmployeeMAppAnchors = (props) => {
  const { reportingEmployeesMAppAnchors } = props;

  return (
    <EmployeeMAppAnchorsView
      title={"MApp"}
      MAppAnchors={reportingEmployeesMAppAnchors}
      {...props}
    />
  );
};

export default compose(withEmployeeMAppAnchors)(EmployeeMAppAnchors);
