import React from 'react';

import { AdminLayout, getIntFromString } from '../../look';
import ROUTE from '../route';

import ToolFormComponent from './ToolFormComponent';

const EditToolView = props => {
  return (
    <AdminLayout
      table={false}
      active={ROUTE.tool}
      title="Edit Tool"
      backLink={`${ROUTE.toolDetailLink}${props.getToolByIdAdmin && getIntFromString(props.getToolByIdAdmin.id)}`}
      FormComponent={props.getToolByIdAdmin && <ToolFormComponent {...props} />}
    />
  );
};

export default EditToolView;
