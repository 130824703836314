import gql from "graphql-tag";

export const USER_ME_QUERY = gql`
query allOrganizationEmployees{
  allOrganizationEmployees {
    edges {
      node {
        id
        user{
          firstName
          lastName
          email
          id
        	employee{
            id
          }
        }
        
      }
    }
  }
}
`;
