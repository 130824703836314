import React from 'react';
import { Form, Checkbox, Row, Col } from 'antd';
import chunk from 'lodash/chunk';
import { ReactMarkdown } from 'modules/look';


const FormItem = Form.Item;

const SurveyCheckBoxField = props => {
  const { vertical = true, choices, onChange,left,selectedValue,required, ...rest } = props;
  const choice = choices.map((choice, i) => (
    <Checkbox className='survey-check-box-content' style={{ display: vertical && 'flex', marginLeft: left?left:'2vw' }} value={choice.value} key={i}>
      {typeof choice.label === 'string' ? <ReactMarkdown>{choice.label}</ReactMarkdown> : choice.label}
    </Checkbox>
  ));

  const parts = chunk(choice, 5);
  return (
    <div style={{ marginBottom: '5vh' }}>
      <FormItem labelCol={{ span: 24 }} wrapperCol={{ span: 24, offset: 1 }} required={required}  {...rest}>
        <Checkbox.Group onChange={onChange} style={{ width: '100%' }}>
          <Row style={{marginTop:'10px'}}>{choices.length && parts?.map((chunk, i) => <Col span={24 / parts?.length}>{chunk}</Col>)}</Row>
        </Checkbox.Group>
      </FormItem>
    </div>
  );
};

export default SurveyCheckBoxField;
