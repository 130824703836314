import gql from 'graphql-tag';
// import { SURVEY_TYPE_CONNECTION } from './SurveyTypeConnection.gql';

//   ${SURVEY_TYPE_CONNECTION}
export const ALL_SURVEY_QUERY = gql`
  query allSurveysForTokens(
    $first: Int
    $offset: Int
    $orderBy: [String]
    $name_Icontains: String
    $description_Icontains: String
  ) {
    allSurveysForTokens(
      first: $first
      offset: $offset
      orderBy: $orderBy
      name_Icontains: $name_Icontains
      description_Icontains: $description_Icontains
    ) {
      # ...SurveyTypeConnection
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          id
          name
          description
          shuffle
          public
          activeFrom
          activeTo
          pubDate
        }
      }
      totalCount
      edgeCount
    }
  }
`;
