import gql from 'graphql-tag';
export const TABLE_DATA_NEW_QUERY = gql`
query me($id:ID!,$id_In: String){
    tableDataNew: me {
        id
        username
        employee {
          id
          organizationSet {
            edges {
              node {
                id
                employees(id_In: $id_In) {
                  edges {
                    node {
                      id
                      user {
                        id
                        firstName
                        lastName
                        responseSet(survey:$id, last: 1) {
                          edges {
                            node {
                              id
                              responseDate
                              survey {
                                id
                                groupSet(last: 1) {
                                  edges {
                                    node {
                                      id
                                      questionSet {
                                        totalCount
                                        edges {
                                          node {
                                            id
                                            sequence
                                            pubDate
                                            answerSet(first: 1) {
                                              edges {
                                                node {
                                                  id
                                                  answer
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
    }
}
  
`