import React from 'react';
import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import { Spin } from 'antd';
import { compose } from "modules/core";
import { withApollo } from '@apollo/client/react/hoc'
import SelectField from './SelectField';
import { getIntFromString, getScapeStringFromInt } from '../functions';

export const LIST_ALL_USERS_QUERY = gql`
query listAllUsers($employee_Isnull:Boolean,$username_Icontains:String){
  listAllUsers(employee_Isnull:$employee_Isnull,username_Icontains:$username_Icontains) {
    edges {
      node {
        id
        firstName
        lastName
        username
        employee {
          id
        }
      }
    }
  }
}
`;



const UsersFieldAddEmployee = props => {
  const { loading, listAllUsers, id = true, client, me } = props;
  const [ choice_list, setChoiceList ] = React.useState()
  const [ choice_loading, setChoiceLoading ] = React.useState( false )
  React.useEffect( () => {
    if(me){
    getUserList()
    }
  }, [me] )

  const getUserList = async ( e ) => {
    setChoiceLoading( true )
    try {
      const { data } = await client.query( {
        query: LIST_ALL_USERS_QUERY,
        fetchPolicy: 'network-only',
        variables: { username_Icontains: e,employee_Isnull:true }
      } );
      if ( data?.listAllUsers.edges ) {
        let choices =data?.listAllUsers?.edges?.map( ( { node } ) => ( {
            label: node.firstName && node.lastName ? `${node.firstName} ${node.lastName}` : node.username,
            value: id ? getIntFromString( node.id ) : `${node.email},${node.firstName},${node.lastName},${node.username}`
          } ) );
        setChoiceList( choices );
      
      }
      setChoiceLoading( false )
    }
    catch ( e ) {
      setChoiceLoading( false )
    }
  }

  return (
      <SelectField {...props} loading={choice_loading} choices={choice_list} showSearch={true} onSearch={( e ) => getUserList( e )} />
  );
};

export default compose( withApollo )( UsersFieldAddEmployee );
