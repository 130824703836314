import gql from 'graphql-tag';
export const DIRECT_MANAGER = gql`
query me{
    me{
        id
        employee{
            id
            memberSet(reportingLine_Iexact:"Solid",first:1){
                edges {
                  node {
                    id
                    reportingLine
                    fromOrganization{
                      id
                      ceo{
                        id
                        user{
                          id
                          firstName
                        }
                      }
                    }
                    fromVertical{
                      id
                      heads(first:1){
                        edges{
                          node{
                            id
                            user{
                              id
                              firstName
                            }
                          }
                        }
                      }
                    }
                    fromTeam {
                      id
                      managers(first:1) {
                        edges {
                          node {
                            id
                            user {
                              id
                              firstName
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
        }
    }
}
`

export const ORGANIZATION_MANAGERS = gql`
query me($user_FirstName:String){
    me{
        id
        employee {
            id
            organizationSet(first:1) {
              edges {
                node {
                  id
                  name
                  employees(first:25,user_FirstName:$user_FirstName) {
                    edges {
                      node {
                        id
                        teamManagerEmployee {
                          totalCount
                        }
                        verticalHeadEmployee {
                          totalCount
                        }
                        orgPocEmployee {
                          totalCount
                        }
                        orgCeoEmployee {
                          totalCount
                        }
                        user {
                          id
                          firstName
                        }
                      }
                    }
                  }
                }
              }
            }
          }
    }
}
`

export const CREATE_IDP_MUTATION =gql`
  mutation createIdp($IdpData: IdpInput!,$skillData: [SkillInput]!){
    createIdp(IdpData: $IdpData,skillData: $skillData){
      idp{
        id
      }
    }
  }
`

export const UPDATE_IDP_MUTATION =gql`
  mutation updateIdp($id: ID!,$activity: String,$duedate: DateTime,$goal: String,$goalType: String,$milestone: String,$resources: String,$skills: [SkillInput],$status: String,$suggestion: String,$comments: String){
    updateIdp(id: $id,activity: $activity,duedate: $duedate,goal: $goal,goalType: $goalType,milestone: $milestone,resources: $resources,skills: $skills,status: $status,suggestion: $suggestion,comments: $comments){
      idp{
        id
      }
    }
  }
`

export const PUBLIC_UPDATE_IDP_MUTATION =gql`
  mutation externalApprovalIdp($id: ID!,$status: String!,$suggestion: String,$comments: String,$token: String!){
    externalApprovalIdp(id: $id,status:$status,suggestion: $suggestion,comments: $comments,token:$token){
      idp{
        id
      }
    }
  }
`