import React from "react";
import { Pagination as ADPagination } from "antd";
import { PAGINATION_LIMIT } from "../../../config";

const Pagination = props => {
  const {
    data,
    pagination: { first, offset },
    onPaginationChange
  } = props;
  const paginationProps = !(data && data.totalCount)
    ? {}
    : {
        defaultCurrent: 1,
        pageSizeOptions: [12, 24, 48, 102],
        showTotal: (total, range) =>
          data.totalCount && (
            <>
              {total < PAGINATION_LIMIT
                ? `Showing 1 to ${total} of ${total} entries.`
                : `Showing ${offset + 1} to ${data.edgeCount} of ${total} entries.`}
            </>
          ),
        onChange: (page, pageSize) =>
          onPaginationChange({
            offset: page === 0 ? 1 * pageSize - pageSize : page * pageSize - pageSize,
            first: pageSize
          }),

        pageSize: first,
        total: data.totalCount,
        style: {
          textAlign: "right"
        }
      };

  return <ADPagination {...paginationProps} />;
};

export default Pagination;
