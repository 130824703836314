
import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { Row, Button, Tooltip } from 'antd';
import { ReactMarkdown, getIntFromString, interpolate, parseMarkDownText } from 'modules/look';
import SurveyMLSelectField from './surveyMLRadioButtom';
const GenericMartx = (props) => {
    const {
        questionList,
        sectionLabel,
        srNo,
        formValues,
        values,
        form_values,
        open_questions,
        onAnswerChange,
        form
    } = props;
    const [showInput, setShowInput] = React.useState(false)
    const [hide_okay_btn,SetHideOkayBtn]=React.useState(true)
    const obj = {};
    const matrixLabel = (
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: "10px", width: "100%", paddingTop: "10px" }}>
            {props.required && <span style={{ color: "#DE4A4A" }}>*</span>}
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '0px' }}>
                <h4>{srNo}.</h4>
                <ReactMarkdown style={{ marginLeft: '4px' }}>{interpolate(sectionLabel?.lable.replace(/\{\d+}/g, ''), obj)}</ReactMarkdown>
            </div>
        </div>
    );
    const areIdsIncludedInObjects = (idArray, objectArray) => {
        console.log(idArray, objectArray)
        // Extract all the IDs from objectArray
        const idsInObjects = objectArray.map(obj => obj.questionId);
        return idArray.every(id => idsInObjects.includes(id));
    }

    const onDataChange=(id,value,type)=>{
        if(onAnswerChange){
            onAnswerChange(id,value,"matrix")
        }
    }

    let oke_time_out_ref = React.useRef()
        const handleOkayBtn =(hide_okay)=>{
            if(oke_time_out_ref?.current){
                clearTimeout(oke_time_out_ref?.current)
                oke_time_out_ref.current = undefined
            }
            SetHideOkayBtn(hide_okay)
            if(!hide_okay){
                oke_time_out_ref.current = setTimeout(() => {
                    SetHideOkayBtn(true)
                }, 10000);
            }
        }

    const HandleDataChange=(id,value)=>{
        form.setFieldsValue( _.set( form_values, id, value?.target?.value ) )
    } 
    return (
        <>
            <MatrixDiv className="generic-survey-question-container" style={{ background: areIdsIncludedInObjects(questionList?.map(questions => getIntFromString(questions?.id)), formValues) ? "rgba(236, 241, 244, 1)" : "rgba(244, 249, 253, 1)" }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: "10px" }}
                    onClick={() => setShowInput(true)}>
                    <h4 className='generic-survey-question' style={{ cursor: "pointer", whiteSpace: "nowrap" }}>{matrixLabel}</h4>
                </div>
                
                {(open_questions?.includes(sectionLabel?.id) || showInput) && (

                    <>
                        <Row>
                            {questionList?.length > 0 ? (
                                <div className='survey-matrix-main-container'>
                                    <div className='survey-matrix-question-container' style={{ width: "200px", overflow: "hidden", paddingTop: "35px" }}>
                                        {questionList
                                            ?.sort(function (a, b) {
                                                return parseInt(a.id) - parseInt(b.id);
                                            })
                                            .map((question, index) => {
                                                const { id, questionText, choiceSet, required } = question;
                                                return (
                                                    <div className='survey-matrix-sections'>
                                                        <Tooltip title={parseMarkDownText(questionText)}>
                                                            <p className='survey-metrix-col-data' >
                                                                {
                                                                    required ?
                                                                        <span style={{ color: "rgb(222, 74, 74)", marginRight: "3px" }}>*</span>
                                                                        : <span style={{ color: "transparent", marginRight: "3px" }}>*</span>
                                                                }
                                                                {parseMarkDownText(questionText)}</p>
                                                        </Tooltip>

                                                    </div>
                                                );
                                            })}

                                    </div>
                                    <div className='survey-matrix-question-container' style={{ width: "100%" }}>
                                        <div className='survey-matrix-table'>
                                            <div className='survey-matrix-table-head'>
                                                {questionList[0]?.choiceSet?.edges?.map(({ node: choice }, key) => (
                                                    <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', margin: "0px", padding: "0px", width: "150px" }}>
                                                        <Tooltip title={parseMarkDownText(choice?.label)}>
                                                            <p className='survey-metrix-col-data'>{parseMarkDownText(choice?.label)}</p>
                                                        </Tooltip>
                                                    </div>

                                                ))}
                                            </div>
                                            <div style={{ marginTop: "10px", display: "flex", gap: "1em", flexDirection: "column" }}>
                                                {questionList
                                                    ?.sort(function (a, b) {
                                                        return parseInt(a.id) - parseInt(b.id);
                                                    })
                                                    .map((question, index) => {
                                                        const { id, questionText, choiceSet, required } = question;
                                                        const rules = [{ required: true, message: 'An answer is required.' }];
                                                        return (
                                                            <div className='survey-matrix-sections'>
                                                                <SurveyMLSelectField
                                                                    {...props}
                                                                    otherOption={true}
                                                                    name={id}
                                                                    choices={choiceSet?.edges?.map(({ node }) => node).map(c => ({ label: c.label, value: c.value }))}
                                                                    rules={required ? rules : []}
                                                                    onChange={e => {HandleDataChange(id,e);handleOkayBtn(false)}}
                                                                />
                                                            </div>
                                                        );
                                                    })}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            ) : null}
                        </Row>
                        {!hide_okay_btn && (
                            <Button className='generic-survey-ok-button' style={{height:"2.5em",marginTop:"1em"}} onClick={e => {onDataChange(sectionLabel?.id);handleOkayBtn(true)}} {...props}>
                            Okay
                        </Button>
                        )}
                    </>
                )
                }
            </MatrixDiv>
        </>
    )
}


export default GenericMartx

const MatrixDiv = styled.div`
  max-height: 75vh;
  overflow-y: auto;
  width:100%;
`;