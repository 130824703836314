import tipListContainer from './container/tip-list.container';
import ROUTE from './route/index';

export default [
    {
      name: 'AI Tips',
      path: ROUTE.tip,
      component: tipListContainer,
      exact: true,
      protect: true
    }
]
