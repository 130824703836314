import gql from 'graphql-tag';

export const ALL_MEMBER_LIST = gql`
query members{
  members(role_Isnull: false){
    edges{
      node{
        id
        toEmployee{
          id
          user{
            id
            firstName
            lastName
          }
        }
       
      }
    }
  }
}
`

export const ALL_ROLE_QUERY = gql`
  query allRoles($title_Icontains:String,$first:Int) {
    allRoles(title_Icontains:$title_Icontains,first:$first){
      edges{
        node{
          id
          title
        }
      }
    }
  }
`;

export const ALL_EMPLOYEE_LEVEL = gql`
  query allEmployeeLevels{
    allEmployeeLevels{
        edges{
          node{
            id
            name
          }
        }
      }
  }
`;

export const ALL_VERTICAL_AND_TEAM = gql`
  query me{
    me{
        id
        employee{
          id
          organizationSet{
            edges{
              node
              {
                id
                verticalSet{
                  edges{
                    node{
                      id
                      name
                      teamSet{
                        edges{
                          node{
                            id
                            name
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
  }
`;

