import React from "react";
import { useParams } from "react-router-dom";
import { message } from "antd";

import { compose } from "../../core";

import AdminStoryDetailView from "../components/AdminStoryDetailView";

import { withAllCommentsForStoryByStoryId, withEditStory, withStoryById } from "./StoryOperations";
import { subscribeToPublishedStory } from "./StorySubscriptions";
import { getIntFromString } from "../../look";

const AdminStoryDetail = props => {
  const { editStory, subscribeToMore, storyById } = props;
  const { id } = useParams();
  const [actionLoading, setActionLoading] = React.useState(false);

  React.useEffect(() => {
    const subscribe = subscribeToPublishedStory(subscribeToMore /* , props.filter */);
    return () => subscribe();
  });
  const handleEdit = async (e, id) => {
    setActionLoading(true);
    try {
      await editStory({
        id: Number(getIntFromString(id)),
        published: e
      });
      setActionLoading(false);
    } catch (e) {
      setActionLoading(false);
      throw new Error(e.message);
    }
  };

  return <AdminStoryDetailView admin={true} publishedStoryById={storyById} actionLoading={actionLoading} handleEdit={handleEdit} {...props} />;
};

export default compose(withStoryById, withEditStory, withAllCommentsForStoryByStoryId)(AdminStoryDetail);
