import React from 'react';

import { compose } from '../../core';

import GenericToolView from '../components/GenericToolView';

import { withToolByIdAdmin } from './ToolOperations';

const GenericToolAdmin = props => {
  const { getToolByIdAdmin, ...rest } = props;
  return <GenericToolView admin={true} getToolById={getToolByIdAdmin} keepDesign={true} {...rest} />;
};

export default compose(withToolByIdAdmin)(GenericToolAdmin);
