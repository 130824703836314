// @ts-nocheck
import React from 'react'
import { Button, Row, Col, Spin, Modal, Input, message, Checkbox } from "antd";
import search from '../../assets/search.svg'
import styled from "styled-components";
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { getIntFromString } from 'modules/look/components/functions';
const CompetecnyView = (props) => {
  
  const { compModalVisible, allCompetency, selectedCompetency, okrId, onClose,searchCompetency } = props
  const [allSelectedCompetencies, setAllCompetencies] = React.useState([])
  const [CompetencyList, setCompetencyList] = React.useState([])
  const [comploading,setcomploading]= React.useState(false);
  const [mutationData, setmutationData] = React.useState();
  const previousCom = React.useRef([])
  React.useEffect(() => {
    if (selectedCompetency?.length) {
      setPreviousData(selectedCompetency)
    }
  }, [selectedCompetency])

  const setPreviousData=(comp)=>{
    previousCom['current']=comp
    setAllCompetencies(comp?.map(data=>data?.competency))
  }

  React.useEffect(()=>{
    if(compModalVisible){
      setPreviousData(previousCom?.current)
    }
  },[compModalVisible])
  
  React.useEffect(() => {
    if (allCompetency?.length) {
      
      setCompetencyList(allCompetency)
    }
  }, [allCompetency])


  
  const COMPETENCY_DYNAMIC = gql`  
  mutation createOkrCompetency {
  ${mutationData?.filter(val=>val?.create)?.map(
      (item, index) =>
        `create${index}: createOkrCompetency(okrCompetencyData:{okrId:${props.match.params.okrId},competencyId:${getIntFromString(item?.id)}}) {
        okrCompetency{
          id
        }
       }
     `
    ).join('')}
    ${mutationData?.filter(val=>!val?.create)?.map(
      (item, index) =>
        `delete${index}: deleteOkrCompetency(id:${getIntFromString(item?.id)}) {
        okrCompetency{
          id
        }
       }
     `
    ).join('')}
  }
  `;
  
  
    const [competencyDefinition, { data, isloading, error }] = useMutation(COMPETENCY_DYNAMIC);
  
    React.useEffect(() => {
      
      if (mutationData && mutationData?.length) {
        competencyMutation()
      }
    }, [mutationData])
  
    const competencyMutation = async () => {
      setcomploading(true)
      const response = await competencyDefinition()
      // new to do send back the response as props
      if (response) {
            setcomploading(false)
            message.success("Submitted successfully")
            onClose()
            // window.location.reload()
          }else{
            setcomploading(false)
          }
    }
  

  const handleCompetenciesSubmit = async () => {
    let mutation_data = []
    let deleteId = []
    allSelectedCompetencies.forEach(val=>
      {
        if(!previousCom?.current?.map(pre=>pre?.competency?.id)?.includes(val?.id)){
          mutation_data.push({...val,create:true})
        }
      }
    )
    previousCom.current.forEach(val=>{
      if(!allSelectedCompetencies?.find(re=>re?.id===val?.competency?.id)){
        deleteId.push({...val,create:false})
      }
    })
    if([...mutation_data,...deleteId]?.length){
      setmutationData([...mutation_data,...deleteId])
    }else{
      message.warning('please update any competency')
    }
    
 
  }

  const UpdateselectCompetency = (e, must_have, competency) => {
    let list = [].concat(allSelectedCompetencies);
    if (!list?.find(val=>val?.id===competency?.id)) {
      list = list.concat([competency]);
    } else {
      list=list?.filter(val=>val?.id!==competency?.id)
    }
    setAllCompetencies(list);
  }



  const renderCompetency = () => {

    return (
      <div style={{ paddingRight: "10px" }}>
        <ColScroll style={{ padding: "0px 20px", height: "250px", overflow: 'auto' }}>
         

          {allSelectedCompetencies?.map((node) =>
          (<Row className="radio-row" style={{ marginTop: "15px" }}>
            <Col span={18} className="title-container">
              {node?.title}
            </Col>
            <Col span={6} className="align-center">
              
              <div className="custom-ckeck">
                <Checkbox name={node?.id} checked={true} defaultChecked={true} onChange={(e) => { UpdateselectCompetency(e, false, node) }} />
              </div>
            </Col>
          </Row>)
          )}
         
          {CompetencyList?.map(({ node }) =>
            !allSelectedCompetencies?.find(data => data?.id === node?.id)
            &&
            (<Row className="radio-row" style={{ marginTop: "15px" }}>
          

              <Col span={18} className="title-container">
                {node?.title}
              </Col>
              <Col span={6} className="align-center">
                <div className="custom-ckeck">

                  <Checkbox
                  defaultChecked={false}
                  checked={false}
                  name={node?.id}
                    onChange={(e) => { UpdateselectCompetency(e, false, node) }} />
                </div>
              </Col>
            </Row>)
          )}

          {
            (!allSelectedCompetencies?.length && !CompetencyList?.length)?
            <h4 style={{fontSize:"15px",textAlign:"center"}}>No competencies found</h4>:''
          }
         
        </ColScroll>
        <Row style={{ marginTop: '50px' }} align='middle' justify="center">
            <Button onClick={() => { onClose();}} style={{ width: '120px', height: "40px", borderRadius: '8px', fontFamily: 'Poppins', marginRight: '10px', color: '#8C8C8C' }}>Cancel</Button>
            <Button onClick={(e) => handleCompetenciesSubmit()} style={{ width: '120px', height: "40px", borderRadius: '8px', fontFamily: 'Poppins', background: '#4CCB1F', color: "#FFFFFF" }} >Save</Button>
          </Row>
      </div>
    )
  }


 
  return (
    <>
     

      <CompetencyModal
        visible={compModalVisible}
        footer={null}
        centered
        closable={false}
        destroyOnClose={true}
        className='custom-new-modal'
      >
        <Spin spinning={comploading}>
        <Col>
          <label className="custom-label">Select Competencies</label>
          <Col lg={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }} style={{ padding: "0px", position: "relative" }}>
            <Col lg={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }} style={{ padding: "0px", position: "relative" }}>
              <Input
                placeholder="Search... "
                className='conatiner-search'
                onChange={e => (searchCompetency(e.target.value))}
                style={{ paddingRight: "40px" }}
                autoComplete='off'
              />
              <img alt='' src={search} style={{ position: "absolute", right: "10px", top: "30px", height: "20px" }} />
            </Col>
            <Col style={{ background: "#fff", padding: "20px 0px", marginTop: "20px", borderRadius: "8px" }}>

              {renderCompetency()}
            </Col>
          </Col>
        </Col>
        </Spin>
      </CompetencyModal>
    </>
  )
}

export default CompetecnyView

const ColScroll = styled(Col)`
::-webkit-scrollbar {
  height: 0px;
  width: 4px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  border-radius: 10px;
  padding-right: 10px;
  margin-bottom:10px;
  margin-top:10px
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color:#F0F0F0;
}`


const CompetencyModal = styled(Modal)`
.ant-modal-body{
  position:relative;
  background:#F0F0F0
}
`