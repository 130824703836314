import gql from 'graphql-tag';

export const CREATE_TRACKING_MUTATION = gql`
mutation createTracking($trackingData:TrackingInput!){
    createTracking(trackingData:$trackingData){
       tracking{
        id
        updatedAt
        createdAt
       }
    }
}
`