import { Button, Col, Form, Row } from 'antd';
import React from 'react';
import { CKEditorField, TextField } from '../../look';

export const CKEditorFieldName = 'description';

const CoachingCommitmentFormComponent = props => {
  const { btnText, coachingCommitment, onSubmit, setVisible } = props;

  const initialValue = {
    title: coachingCommitment && coachingCommitment.title,
    description: coachingCommitment && coachingCommitment.description
    // customusercommitmentId: coachingCommitment && coachingCommitment.customusercommitmentId
  };

  return (
    <Form onFinish={onSubmit} layout="vertical" initialValues={initialValue} required={false} scrollToFirstError={true}>
      <Row gutter={[24, 24]}>
        <Col span={23}>
          <TextField name="title" offset={1} label="Title" rules={[{ required: true, message: 'Title is required!' }]} />
        </Col>

        <Col span={23}>
          <CKEditorField
            name={CKEditorFieldName}
            label="Description"
            initialValue={coachingCommitment && coachingCommitment.description}
          />
        </Col>
      </Row>

      <Row justify="space-between">
        <Col>
          <Button htmlType="submit" type="primary">
            {btnText}
          </Button>
        </Col>
        <Col>
          <Button ghost type="primary" onClick={() => setVisible(false)}>
            Cancel
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default CoachingCommitmentFormComponent;
