import React, { useState } from 'react';
import { compose } from '../../core';
import { withApollo } from '@apollo/client/react/hoc';
import DiscardRequestView from '../components/DiscardView';
import { PENDING_COUNTS } from '../graphql/TabPendingCounts.gql';
import { Spin } from 'antd';
import { MEMEBER_BY_TEAM_ID } from '../graphql/MembersByTeamId.gql';
import { getIntFromString } from 'modules/look';
import EmployeeList from 'modules/look-v2/Components/EmployeeList';
const EmployeeListContainer = (props)=>{
const [TeammemberList,setTeammemberList]=React.useState([])
const [teamMemberLoader,setteamMemberLoader]=React.useState(true)
const {me,client,teamDetail}=props

    React.useEffect(()=>{
        if(teamDetail){
           getMemberList(teamDetail?.id)
        }
    },[teamDetail])

    const getMemberList=async(teamId)=>{
    setteamMemberLoader(true)
    const { data } = await client.query({
      query: MEMEBER_BY_TEAM_ID,
      variables: {id:teamId},
    //   fetchPolicy: 'network-only'
    });
    console.log("data",data)
    if(data?.Team){
        let list=data?.Team?.employees?.edges?.map(({node})=>{
            return {
                id:node?.id,
                name:`${node?.user?.firstName} ${node?.user?.lastName||''}`,
                profileImage:node?.user?.profile?.profileImage
            }
        })
        setTeammemberList(list)
        setteamMemberLoader(false)
    }
    }

    return (

        !teamMemberLoader?
        <EmployeeList TeammemberList={TeammemberList} {...props} />:
        <div align="center">
        <Spin spinning={teamMemberLoader}></Spin>
        </div>

    )
}
export default compose(withApollo)(EmployeeListContainer)