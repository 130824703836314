import { graphql } from '@apollo/client/react/hoc';
import { CANCEL_AI_TRIGGER } from 'modules/ai-tips/query/ai-tip-query.gql';

export const CancelAiTrigger = Component =>
    graphql(CANCEL_AI_TRIGGER, {
      props: ({ mutate, history }) => ({
        cancelRunpodtips: async values => {
          try {
            const {
              data: { cancelRunpodtips }
            } = await mutate({
              variables: {
                ...values
              }
            });
  
 
            return cancelRunpodtips.idp;
          } catch (e) {
   
          }
        }
      })
    })(Component);