import gql from 'graphql-tag';
import { FAQ_QUESTION_TYPE } from './FaqQuestionType.gql';

export const DELETE_FAQ_QUESTION = gql`
  ${FAQ_QUESTION_TYPE}
  mutation deleteFaqQuestion($questionId: ID!) {
    deleteFaqQuestion(questionId: $questionId) {
      question {
        ...FaqQuestionType
      }
    }
  }
`;
