// @ts-nocheck
import React from 'react';
import { LeftOutlined, CheckOutlined, EyeOutlined } from "@ant-design/icons";
import { Row, Col, Button, Table, Tooltip, Modal, Input, Spin } from "antd";
import styled from "styled-components";
import { displayUtcToUserTimezone, MetaTags, getIntFromString, globalPermissionValidator } from '../../look';
import NotificationDetailView from '../components/NotificationDetail'
import notification_content from '../../../Notification_config'
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import okr_route from '../../Objective-keyresult/route/index'
import { feedback_permission } from 'Permissions/feedback.permission';
import FeedbackDetailView from 'modules/feedback/components/ViewFeedbackPopUp';
import AwardDetailPopup from 'modules/kudos-user/container/awardDetaailPopup';
const NotificationViewAll = props => {
  const { readNotification, notifications, me, onfilter, paginationCount, recordCount, setPaginationCount, setrecordCount, loading, setLoading
    , readNotifications, getReadNotifications, getFeedbackDetails, feedbackBackDetailsLoading, feedbackDetails, userPermission } = props

  const [notificationList, setNotificationList] = React.useState([])
  const [selectedNotification, setselectedNotification] = React.useState()
  const [feedbackPermission, setFeedbackPermission] = React.useState()
  const [visible, setvisible] = React.useState(false);
  const [selectedAward, setSelectedAward] = React.useState()
  const [feedbackDetailsModalVisible, setFeedbackDetailsModalVisible] = React.useState(false)
  const history = useHistory()
  React.useEffect(() => {

    if (notifications) {

      let list = notifications?.edges?.map(({ node }) => node)
      setNotificationList(list)
      setLoading(false)
    }
  }, [notifications])

  const back = () => {
    history.goBack()
  };


  React.useEffect(() => {
    if (me && userPermission) {
      let permission = globalPermissionValidator(feedback_permission, userPermission);
      // @ts-ignore
      setFeedbackPermission(permission)
    }
  }, [me, userPermission])

  const anchorStyle = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '200px',
    marginTop: '5px'
  }


  const routeToOkrDetailsPage = (data) => {

    switch (data?.okrType) {
      case "OBJECTIVE":
        window.open(okr_route?.viewObjective?.replace(':okrId', getIntFromString(data?.id)))
        break;
      case "KEY_RESULT":
        window.open(okr_route?.keyResultDetail?.replace(':okrId', getIntFromString(data?.id)))
        break;
      case "MILESTONE":
        window.open(okr_route?.milestoneDetail?.replace(':okrId', getIntFromString(data?.id)))
        break;
    }
  }

  const viewData = (data) => {
    let detail = notification_content[data?.relatedObject?.__typename]
    
    if (detail && data?.relatedObject?.id) {
      if (detail?.model === "okrType") {
        routeToOkrDetailsPage(data?.relatedObject)
      }
      else if (data?.relatedObject?.__typename === "ContributorType") {
        routeToOkrDetailsPage(data?.relatedObject?.okr)
      }
      else if (data?.relatedObject?.__typename === "BadgeAwardType") {
        
        setvisible(true)
        setSelectedAward(data?.relatedObject)
      }
      else {
        let url = detail?.url.replace('{{objectId}}', detail?.isIntid ? getIntFromString(data?.relatedObject?.id) : data?.relatedObject?.id)
        window.open(url)
      }
    }
  }


  const MarkAsRead = async (record) => {

    try {
      if (record) {
        setLoading(true)
        const response = await readNotification({ id: getIntFromString(record?.id) })
        if (response) {
          getReadNotifications()
        }
        setLoading(false)
      }
    }
    catch (e) {
      console.log(e)
    }

  }



  const handlePaginationCount = () => {

    let count = paginationCount + 1;

    setPaginationCount(count);

    onfilter({
      orderBy: ["-actionTime"],
      recipient: me?.id,
      offset: count * recordCount,
      first: recordCount,

    }, false)

  }

  const columns = [
    {
      title: <h4>Title</h4>,
      key: 'Title',
      render: (text, record) => record &&
        <p >{record?.title}</p>
    },
    {
      title: <h4>Message</h4>,
      key: 'Message',
      render: (text, record) => record &&
        <p className="message" style={{ ...anchorStyle, cursor: 'pointer' }} onClick={() => setselectedNotification(record)}>{record?.changeMessage}</p>
    },
    {
      title: <h4>Time</h4>,
      key: 'Time',
      render: (text, record) => record &&
        <p>{displayUtcToUserTimezone(record?.actionTime, "DD MMMM YYYY")}</p>
    },
    {
      title: <h4 style={{ textAlign: 'right' }}>Action</h4>,
      key: 'Time',
      render: (text, record) => record &&
        <TableRow align="end">
          {
            record?.readTime ?
              <Button type='primary' disabled={true} style={{ width: '28px', height: '28px', borderRadius: '50%', background: "#009fff" }} size='small'>
                <CheckOutlined style={{ color: "#fff" }} />
              </Button>
              :
              <Tooltip title="Mark as read">
                <Button type='primary' onClick={() => { MarkAsRead(record) }} style={{ width: '28px', height: '28px', borderRadius: '50%' }} size='small'>
                  <CheckOutlined />
                </Button>
              </Tooltip>
          }
          {

            record?.relatedObject && record?.relatedObject?.__typename && record?.relatedObject?.id &&
            <div>
              {
                record?.relatedObject?.__typename === "FeedbackType" ?
                  <Tooltip title="View">
                    <Button type='primary' onClick={() => { setFeedbackDetailsModalVisible(true); getFeedbackDetails(record?.relatedObject?.id) }} style={{ width: '28px', height: '28px', borderRadius: '50%', marginLeft: '5px' }} size='small'>
                      <EyeOutlined />
                    </Button>
                  </Tooltip>
                  :
                  (notification_content[record?.relatedObject?.__typename] && record?.relatedObject?.__typename !== "FeedbackType") ?
                    <Tooltip title="View">
                      <Button type='primary' onClick={() => { viewData(record) }} style={{ width: '28px', height: '28px', borderRadius: '50%', marginLeft: '5px' }} size='small'>
                        <EyeOutlined />
                      </Button>
                    </Tooltip>
                    : null
              }
            </div>
          }


        </TableRow>

    }
  ];

  const search = (title) => {
    setLoading(true)
    onfilter(
      {
        orderBy: ["actionTime"],
        recipient: me?.id,
        changeMessage_Icontains: title
      }
    )
  }

  return (
    <>
      <MetaTags
        title="View Notification"
        description="This is View Notification page."
      />

      <div style={{ padding: '0px 20px' }}>
        {
          selectedNotification && (
            <Modal
              visible={selectedNotification}
              destroyOnClose={true}
              closable={false}
              footer={false}
              width={'40%'}
            >
              <NotificationDetailView
                NotificationDetail={selectedNotification}
                onClose={() => { setselectedNotification(null) }}
                view={(e) => { setselectedNotification(null); viewData(e) }}
              />
            </Modal>
          )
        }
        <RowDiv >
          <Col span={21} >

            <h4 style={{ fontSize: '20px' }}>All Notifications</h4>

          </Col>
          <Button shape="circle" style={{ marginLeft: '20px' }} onClick={back}>
            <LeftOutlined />
          </Button>
        </RowDiv>
        <div align={'end'}>
          <Input
            style={{
              border: '1px solid #CACACA',
              width: '250px'
            }}
            placeholder="search message"
            onChange={(e) => { search(e.target.value) }}
            autoComplete='off'
          />
        </div>

        <MyTable
          size={'middle'}
          loading={loading}
          showHeader={true}
          pagination={false}
          dataSource={
            notificationList
              ? notificationList
              : []
          }
          columns={columns.map(c => ({ ...c, sortDirections: ['descend', 'ascend', 'descend'] }))}
        />


        {
          notifications?.totalCount ?
            <Row align='middle' justify='center' style={{ gap: '10px' }}>
              {
                notifications?.edgeCount !== notifications?.totalCount &&
                <Button onClick={(e) => handlePaginationCount()}>View more</Button>
              }

              <span>{`${notifications?.edgeCount}/${notifications?.totalCount}`}</span>
            </Row>
            : null
        }


        <Modal visible={feedbackDetailsModalVisible} footer={false}
          footer={null}
          centered
          destroyOnClose={true}
          closable={false}
        >
          <Spin spinning={feedbackBackDetailsLoading}>
            <FeedbackDetailView feedbackPermission={feedbackPermission} feedbackDetails={feedbackDetails} setFeedbackModalVisible={setFeedbackDetailsModalVisible} me={me} />
          </Spin>
        </Modal>
        {
          visible && <AwardDetailPopup selectedAward={selectedAward} onClose={(e) => { setvisible(e); setSelectedAward() }} visible={visible} shareAward={(e) => { setvisible(false); setSelectedAward() }} />
        }
      </div>

    </>
  );
}

export default NotificationViewAll;
const RowDiv = styled(Row)`
display:flex;
justify-content:space-between;
align-items:center;
margin-bottom:20px
`
export const MyTable = styled(Table)`
  table {
    .message{
        curspor:pointer !important;
    }
    .message:hover{
        color:#B0CB1F;
        text-decoration:underline
    }
    border-spacing: 0 1em;
    tbody {
      tr {
        td {
          border: 1px solid rgba(0, 0, 0, 0.06) !important;
          border-style: solid none !important;
        }
        td:first-child {
          border-left-style: solid !important;
          border-top-left-radius: 5px !important;
          border-bottom-left-radius: 5px !important;
        }
        td:last-child {
          border-right-style: solid !important;
          border-bottom-right-radius: 5px !important;
          border-top-right-radius: 5px !important;
          border-bottom: 1px solid rgba(0, 0, 0, 0.06) !important;
        }
      }
    }
  }
`;

const TableRow = styled(Row)`
display:flex;
flex-direction:row;
flex-wrap:nowrap;
`