import React from "react";
import SalesPageView from "../components/SalesPageView";

const SalesPageContainer = (props) => {

    return (
        <>
            <SalesPageView />
        </>
    );
};

export default SalesPageContainer;

