// @ts-nocheck
import React from 'react';
import { compose } from '../../core';
import { SUBVERTICAL_AND_TEAMS_UNDER_VERTICAL } from '../graphql/VerticalDashboardQueries';
import {  getIntFromString, getVerticalBase64StringFromInt } from 'modules/look';
import { withApollo } from '@apollo/client/react/hoc';
import ORGSubVerticalAndTeamsView from '../components/OrgSubverticalAndTeamsView';
const ORGSubVerticalAndTeamsContainer = props => {
  const {me,client,dateRangeTofilter,verticalDetail,setdataSourceLoader}=props
  const [dataSourceforSub, setdataSourceForSub] = React.useState();

  React.useEffect(()=>{
    if(verticalDetail && me && dateRangeTofilter){
        getSubverticalAndTeams(getIntFromString(verticalDetail?.id),verticalDetail?.heads?.edges?.map(({node})=>getIntFromString(node?.id)))
    }
  },[verticalDetail,me,dateRangeTofilter])

  const getSubverticalAndTeams = async(verticalid,managerId)=>{
    setdataSourceLoader(true)
    const { data } = await client.query({
      query: SUBVERTICAL_AND_TEAMS_UNDER_VERTICAL,
      variables: {
        id:getVerticalBase64StringFromInt(verticalid),
        savedOkrType: "OBJECTIVE",
        okr_Duedate_Range: dateRangeTofilter,
        role_In: ["COLLABORATOR", "ASSIGNED"],
        managerId: managerId||[],
        orderBy: ["okr__duedate"]
      },
    //   fetchPolicy: 'network-only'
    });
    if(data?.vertical){
        let vertical = data?.vertical
        vertical['managerId']=data?.vertical?.heads?.edges?.map(({node})=>getIntFromString(node?.id))
        let sub_vertical_list = vertical?.subvertical?.edges?.map(({node})=>{
            return {
                type:'vertical',
                id:node?.id,
                name:node?.name,
                subvertical:node?.subvertical?.totalCount,
                teamSet:node?.teamSet?.totalCount,
                employeesList:node?.employees,
                progress:calculateProgress(node?.heads?.edges),
                managerId:node?.heads?.edges?.map(({node})=>getIntFromString(node?.id))
            }
        })
        let teams_list =vertical?.teamSet?.edges?.map(({node})=>{
          return {
            type:"team",
            id:node?.id,
            name:node?.name,
            employeeCount:node?.employees?.totalCount,
            managerId:node?.managers?.edges?.map(({node})=>getIntFromString(node?.id))
          }
        })
        let edges=[...sub_vertical_list,...teams_list]
        setdataSourceForSub({edges,vertical})
        setdataSourceLoader(false)
        
    }
  }

  const calculateProgress=(userlist)=>{
    let contributors_progress = userlist?.map(({node})=>node?.okrContributorSet?.edges?.map(({node})=>node?.progress))
    contributors_progress = [].concat.apply([],contributors_progress) 
    let progress =contributors_progress.reduce((a, b) => a + b, 0)
    return (!progress || isNaN(progress) || isNaN(progress/contributors_progress?.length)||(progress/contributors_progress?.length)===Infinity)?'0%':`${(progress/contributors_progress?.length)?.toString()?.includes('.')?(progress/contributors_progress?.length).toFixed(2):(progress/contributors_progress?.length)}%`
  }
 
  
  return (
    <>
    <ORGSubVerticalAndTeamsView 
        setdataSourceLoader={(e)=>setdataSourceLoader(e)} 
        dataSource={dataSourceforSub}
        getSubverticalAndTeams={(e)=>{getSubverticalAndTeams(e)}}
       {...props}/>
    </>
  );
};

export default compose(withApollo)(ORGSubVerticalAndTeamsContainer);
