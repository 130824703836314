import gql from 'graphql-tag';
import { CUSTOM_FORM_SECTION_TYPE_CONNECTION } from '../../form-section/graphql/Custom_FormSectionTypeConnection.gql';

export const TOOL_BY_ID_OPTIMIZED = gql`
${CUSTOM_FORM_SECTION_TYPE_CONNECTION}
  query getToolById($toolId: ID!,$responseId: ID) {
    getToolById(toolId: $toolId,responseId:$responseId) {
        id
        title
        uiFlow
        thumbnailImage
        description
        status
        public
        pubDate
        textsection {
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          edges {
            cursor
            node {
              id
              title
              text
              renderedText(responseId:$responseId)
              sequence
            }
          }
          totalCount
          edgeCount
        }
        formsection {
         ...Custom_FormSectionTypeConnection
        }
    }
  }
`;
