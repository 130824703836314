// @ts-nocheck
import React, { useLayoutEffect } from 'react';
import { sidebar_icons } from '../icon'
import { Dropdown, Menu } from 'antd';
const RoleDefinitionSideBar = (props) => {

    const { permission, setCurrentTab, current_tab } = props
    const [selected_multi_menu, setSelectedmultiMenu] = React.useState({ competency: current_tab?.key?.includes("competency") ? current_tab : { key: 'competency', label: 'Competency' } })

    const [sidebar_tab, setSideBarTab] = React.useState([
        {
            key: 'employee_level',
            label: 'Employee Level',
            active_icon: sidebar_icons?.employee_level_active,
            inactive_icon: sidebar_icons?.employee_level_inactive
        },
        {
            key: 'competency',
            label: 'Competency',
            active_icon: sidebar_icons?.competency_active,
            inactive_icon: sidebar_icons?.competency_inactive,
            child_menu: [
                {
                    key: 'competency', // child key should ends with parent key
                    label: 'Competency',
                    active_icon: sidebar_icons?.competency_active,
                    inactive_icon: sidebar_icons?.competency_inactive,
                },
                {
                    key: 'sub_competency', // child key should ends with parent key
                    label: 'Sub Competency',
                    parent: 'competency',
                    active_icon: sidebar_icons?.sub_competency_active_icon,
                    inactive_icon: sidebar_icons?.sub_competency_inactive_icon,
                    child: true
                }
            ]
        },
        {
            key: 'role',
            label: 'Role',
            active_icon: sidebar_icons?.role_active,
            inactive_icon: sidebar_icons?.role_inactive
        },
        {
            key: 'job_family',
            label: 'Job Family',
            active_icon: sidebar_icons?.jobfamily_active,
            inactive_icon: sidebar_icons?.jobfamily_inactive
        },
        {
            key: 'rating_scale',
            label: 'Threshold',
            active_icon: sidebar_icons?.ratingscale_active,
            inactive_icon: sidebar_icons?.ratingscale_inactive
        },
        {
            key: 'member',
            label: 'Employee',
            active_icon: sidebar_icons?.employee_active_icon,
            inactive_icon: sidebar_icons?.employee_inactive_icon
        }
    ]) // list of sidebar menu

    const onChangeMultiMenu = (menu, main) => {
        setCurrentTab(menu)
        let submenu_list = {
            ...selected_multi_menu
        }
        submenu_list[main?.key] = menu
        let update = { ...submenu_list }
        setSelectedmultiMenu(update)
    }

    const Relatedmenu = (sidebar) => {
        return (
            <Menu className='rd-main-menu'>
                {
                    sidebar?.child_menu.map((data) => (

                        selected_multi_menu[sidebar?.key]?.key != data?.key && (
                            <Menu.Item className='rd-menu-label' onClick={() => { onChangeMultiMenu(data, sidebar) }}>
                                {data?.label}
                            </Menu.Item>
                        )

                    ))
                }
            </Menu>
        )
    }


    return (
        <>
            <div className='rd-container rd-sidebar-view'>
                {
                    sidebar_tab?.map(sidebar => (

                        !sidebar?.child_menu?.length ?
                            <div className={`rd-sidebar-normal ${(current_tab?.key === sidebar?.key || (current_tab?.key?.includes(sidebar?.key))) ? 'rd-active-sidebar' : ''}`} onClick={() => setCurrentTab(sidebar)}>
                                <h4 className={`rd-sidebar-label ${(current_tab?.key === sidebar?.key || (current_tab?.key?.includes(sidebar?.key))) ? 'rd-active-sidebar-label' : ''}`}>{sidebar?.label}</h4>
                            </div> :

                            <Dropdown overlay={Relatedmenu(sidebar)} overlayClassName='content-dropdown' style={{ width: "100%" }} onClick={() => { onChangeMultiMenu(selected_multi_menu[sidebar?.key], sidebar) }}
                            >
                                <div className={`rd-sidebar-normal ${(current_tab?.key === sidebar?.key || (current_tab?.key?.includes(sidebar?.key))) ? 'rd-active-sidebar' : ''}`}>
                                    <h4 className={`rd-sidebar-label ${(current_tab?.key === sidebar?.key || (current_tab?.key?.includes(sidebar?.key))) ? 'rd-active-sidebar-label' : ''}`}>{selected_multi_menu[sidebar?.key]?.label}</h4>
                                </div>
                            </Dropdown>
                    ))
                }
                <div className='border-line'>

                </div>
            </div>
        </>
    );


}

export default RoleDefinitionSideBar