import { message } from 'antd';
import _ from 'lodash';
import { graphql } from '@apollo/client/react/hoc';
import { CREATE_IDP_MUTATION, PUBLIC_UPDATE_IDP_MUTATION, UPDATE_IDP_MUTATION } from './graphql/idpCreate.gql';
import { DELETE_IDP_MUTATION } from './graphql/userIdpList.gql';

export const withCreateIdp = Component =>
  graphql(CREATE_IDP_MUTATION, {
    props: ({ mutate, history }) => ({
        createIdp: async values => {
        try {
          const {
            data: { createIdp }
          } = await mutate({
            variables: {
              ...values
            }
          });

          message.destroy();
          message.success('IDP created successfully !!');
          return createIdp.idp;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
          console.error(e);
        }
      }
    })
  })(Component);

  export const withUpdateIdp = Component =>
  graphql(UPDATE_IDP_MUTATION, {
    props: ({ mutate, history }) => ({
      updateIdp: async values => {
        try {
          const {
            data: { updateIdp }
          } = await mutate({
            variables: {
              ...values
            }
          });

          message.destroy();
          message.success('IDP updated successfully !!');
          return updateIdp.idp;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
          console.error(e);
        }
      }
    })
  })(Component);

  export const withDeleteIdp = Component =>
  graphql(DELETE_IDP_MUTATION, {
    props: ({ mutate, history }) => ({
      deleteIdp: async values => {
        try {
          const {
            data: { deleteIdp }
          } = await mutate({
            variables: {
              ...values
            }
          });

          message.destroy();
          return deleteIdp.idp;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
          console.error(e);
        }
      }
    })
  })(Component);

  export const withPublicUpdateIdp = Component =>
  graphql(PUBLIC_UPDATE_IDP_MUTATION, {
    props: ({ mutate, history }) => ({
      externalApprovalIdp: async values => {
        try {
          const {
            data: { externalApprovalIdp }
          } = await mutate({
            variables: {
              ...values
            }
          });

          message.destroy();
          message.success('IDP updated successfully !!');
          return externalApprovalIdp.idp;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
          console.error(e);
        }
      }
    })
  })(Component);