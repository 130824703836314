import gql from "graphql-tag";


export const COMPETENCY_ASSESSMENT_QUERY_BY_EMPLOYEE = gql`

query assessments($employee: ID,$employeeEmail_Iexact:String,$mainCompetency_In: [ID],$createdAt_Range: [DateTime]){
  assessments(orderBy: ["-createdAt"],employeeEmail_Iexact:$employeeEmail_Iexact,employee:$employee,createdAt_Range:$createdAt_Range) {
    edges {
      node {
        id
        createdAt
        correctionDeadline
        employeeEmail
        assessedBy {
          id
        }
        employee{
          id
          user {
            id
            username
            firstName
            profile {
              id
              profileImage
            }
          }
				}
        employeeLevel
        role{
          title
          id
        }
        competencyassessmentSet(mainCompetency_In:$mainCompetency_In) {
          edges {
            node {
              id
              competency {
                id
                title
                competencyType
                competencyCategory{
                  id
                  title
                  competencyType
                }
              }
              mainCompetency {
                id
                title
                competencyType
              }
              
              assessmentbarsSet(selected:true) {
                edges {
                  node {
                    id
                     behaviourExample
                    qualifyingLevelName
                    qualifyingLevelId
                    qualifyingLevelSequence
                    selected
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`
export const MEMBER_TEUNRE_QUERY =gql`
query members($toEmployee:ID){
  members(toEmployee:$toEmployee,orderBy:["-updatedAt"],role_Isnull:false){
    edges {
      node {
        id
        updatedAt
        role{
          id
        }
        toEmployee{
          id
          user{
            id
            firstName
          }
        }
      }
    }
  }
}
`

export const COMPETENCY_ASSESSMENT_QUERY_BY_CATEGORY = gql`

query assessments($category_id: ID,$createdAt_Range: [DateTime],$employeeLevel_In: [String],$employee_In: [ID],$role_In: [ID],$employee_VerticalMembersEmployee_In: [String],$employee_TeamMembersEmployee_In: [String]){
  assessments(orderBy: ["-createdAt"],competencyassessment_MainCompetency_Id:$category_id,createdAt_Range:$createdAt_Range,employeeLevel_In:$employeeLevel_In,employee_In:$employee_In,role_In:$role_In,employee_VerticalMembersEmployee_In:$employee_VerticalMembersEmployee_In,employee_TeamMembersEmployee_In:$employee_TeamMembersEmployee_In) {
    edges {
      node {
        id
        createdAt
        correctionDeadline
        employeeEmail
        assessedBy {
          id
        }
        employee{
          id
          user {
            id
            username
            firstName
            profile {
              id
              profileImage
            }
          }
				}
        employeeLevel
        role{
          title
          id
        }
        competencyassessmentSet {
          edges {
            node {
              id
              competency {
                id
                title
                competencyType
                competencyCategory{
                  id
                  title
                  competencyType
                }
              }
              mainCompetency {
                id
                title
                competencyType
              }
              
              assessmentbarsSet(selected:true) {
                edges {
                  node {
                    id
                     behaviourExample
                    qualifyingLevelName
                    qualifyingLevelId
                    qualifyingLevelSequence
                    selected
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`

export const RATING_SCALES_LIST = gql`
  query ratingScales {
    ratingScales(orderBy:["sequence"]){
        totalCount
        edges{
          node{
            id
            name
            sequence
          }
        }
    }
  }
`;

export const ALL_COMPETENCIES = gql`
query allCompetencyCategory($title_Icontains: String) {
  allCompetencyCategory(title_Icontains:$title_Icontains){
    edges{
      node{
        id
        title
        competencyType
      }
    }
  }
}
`
export const COMPETECNY_CATEGORY_BY_ID = gql`
query competencyCategoryId($id: ID!){
  competencyCategoryId(id:$id){
    id
    title
    competencySet{
      edges{
        node{
          id
        }
      }
    }
  }
}
`

export const ROLE_BY_ID = gql`
query roleById($id: ID!){
  roleById(id:$id){
    id
    title
    employeeLevel{
      id
      name
    }
    jobfamilySet{
      edges{
        node{
          id
          genericCompetency{
            edges{
        node{
          id
          competencyType
          competencyCategory{
            id
            competencyType
          }
        }
      }
          }
        }
      }
    }
    optionalSpecificCompetency{
      edges{
        node{
          id
          competencyType
          competencyCategory{
            id
            competencyType
          }
        }
      }
    }
    requiredSpecificCompetency{
      edges{
        node{
          id
          competencyType
          competencyCategory{
            id
            competencyType
          }
        }
      }
    }
  }
}`