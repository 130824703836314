import React from 'react';
import scapeReportContainer from './containers/scapeReportContainer';


import ROUTE from './route';
export default [
    {
        name: 'scapeReport',
        path: ROUTE.scapeReportHome,
        component: scapeReportContainer,
        protect: true
    }
]