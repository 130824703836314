export const preference_colors = {
    preference_1:{
        hex:"#AE3EC9",
        gradient_hex:"#F3C2FF", 
        gradient:"radial-gradient(75.2% 75.2% at 33.33% 26.67%, #F3C2FF 0%, #AE3EC9 100%)"
    },
    preference_2:{
        hex:"#7048E8",
        gradient_hex:"#D0C0FF", 
        gradient:"radial-gradient(75.58% 75.58% at 24.71% 28.78%, #D0C0FF 0%, #7048E8 100%)"
    },
    preference_3:{
        hex:"#4263EB",
        gradient_hex:"#C0CDFF", 
        gradient:"radial-gradient(72.72% 72.72% at 30.52% 29.94%, #C0CDFF 0%, #4263EB 100%)"
    },
    preference_4:{
        hex:"#1C7ED6",
        gradient_hex:"#B9E0FF", 
        gradient:"radial-gradient(73.95% 73.95% at 29.07% 29.07%, #B9E0FF 0%, #1C7ED6 100%)"
    },
    preference_5:{
        hex:"#1098AD",
        gradient_hex:"#B9F7FF", 
        gradient:"radial-gradient(74.57% 74.57% at 27.91% 28.78%, #B9F7FF 0%, #1098AD 100%)"
    },
    preference_6:{
        hex:"#0CA678",
        gradient_hex:"#BEFFEC", 
        gradient:"radial-gradient(72.67% 72.67% at 29.65% 30.23%, #BEFFEC 0%, #0CA678 100%)"
    },
    preference_7:{
        hex:"#37B24D",
        gradient_hex:"#B9FFC5", 
        gradient:"radial-gradient(72.69% 72.69% at 31.69% 29.65%, #B9FFC5 0%, #37B24D 100%)"
    },
    preference_8:{
        hex:"#74B816",
        gradient_hex:"#E7FFBF", 
        gradient:"radial-gradient(75.31% 75.31% at 30.23% 27.33%, #E7FFBF 0%, #74B816 100%)"
    },
    preference_9:{
        hex:"#F59F00",
        gradient_hex:"#FFEFB4", 
        gradient:"radial-gradient(71.84% 71.84% at 29.65% 31.1%, #FFEFB4 0%, #F59F00 100%)"
    },
    preference_10:{
        hex:"#F76707",
        gradient_hex:"#FFA94D", 
        gradient:"radial-gradient(77.59% 77.59% at 26.16% 26.16%, #FFA94D 0%, #F76707 100%)"
    }
}