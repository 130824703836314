import gql from "graphql-tag";
import { CHOICE_TYPE_CONNECTION } from "./ChoiceTypeConnection.gql";

export const QUESTION_TYPE = gql`
  ${CHOICE_TYPE_CONNECTION}
  fragment QuestionType on QuestionType {
    id
    questionText
    required
    sequence
    questionAddress
 
    answerSet {
      edges {
        node {
          id
          answer
        }
      }
    }
    pubDate
    required
    choiceSet {
      ...ChoiceTypeConnection
    }
  }
`;
