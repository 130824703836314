import gql from 'graphql-tag';
import { VERTICAL_TYPE_CONNECTION } from './VerticalTypeConnection.gql';
import { VERTICAL_TYPE } from './VerticalType.gql';

export const VERTICALS_BY_ORG_ID_QUERY_OPTIMIZED = gql`
  
  query verticals(
    $first: Int
    $offset: Int
    $orderBy: [String]
    $name_Icontains: String
    $description_Icontains: String
    $organization: ID
    $subvertical: [ID]
  ) {
    verticals(
      first: $first
      offset: $offset
      orderBy: $orderBy
      name_Icontains: $name_Icontains
      description_Icontains: $description_Icontains
      organization: $organization
      subvertical: $subvertical
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
         id 
         name 
         description
         subvertical {
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          edges {
            cursor
            node {
              id
              name
              description
            }
          }
          totalCount
          edgeCount
        }
        }
      }
      totalCount
      edgeCount
    }
  }
`;




export const VERTICALS_BY_ORG_ID_QUERY = gql`
  ${VERTICAL_TYPE_CONNECTION}
  query verticals(
    $first: Int
    $offset: Int
    $orderBy: [String]
    $name_Icontains: String
    $description_Icontains: String
    $organization: ID
    $subvertical: [ID]
  ) {
    verticals(
      first: $first
      offset: $offset
      orderBy: $orderBy
      name_Icontains: $name_Icontains
      description_Icontains: $description_Icontains
      organization: $organization
      subvertical: $subvertical
    ) {
      ...VerticalTypeConnection
    }
  }
`;


export const VERTICALS_BY_ORG_ID_QUERY_SUBSCRIPTION = gql`
${VERTICAL_TYPE}
  subscription verticalSubscription{
    verticalSubscription{
      mutation
      Vertical{
        ...VerticalType
      }
    }
  }
  `