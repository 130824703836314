import gql from "graphql-tag";


export const EMPLOYEE_BY_ID_FEEDBACK = gql`
  
  query employeeById($id: ID!) {
    employeeById(id: $id) {
        id
        user
        {
          id
          firstName
          lastName
        }
    }
  }
`;