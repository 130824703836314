import gql from 'graphql-tag';

export const UPDATE_MEETIING_MUTATION = gql`
  mutation updateMeeting($id:ID!,$title: String,$when: DateTime,$members: [Int],$feedbacks: [Int],$scheduleDuration: Int) {
    updateMeeting(id:$id,title: $title,when:$when,members:$members,feedbacks:$feedbacks,scheduleDuration:$scheduleDuration) {
        meeting {
        id
      }
    }
  }
`;
