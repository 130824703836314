import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal, Checkbox, Button, Space, Row, Col, Card } from 'antd';
import { ReactMarkdown, getIntFromString } from '../../look/';
import ManagerAddCommitment from '../../manager/containers/ManagerAddCommitment';

const CommitmentComponent = props => {
  const {
    commitment,
    completed,
    onCustomUserCommitment,
    onDefaultCommitmentProgress,
    dateCreated,
    dateCompleted,
    dashboard,
    setUpdated,
    defaultCustomUserCommitment
  } = props;
  const [visible, setVisible] = useState(false);
  const [modal2, setModal2] = useState({ visible: false, id: 0, default: false });
  function handleChange(e) {
    if(onCustomUserCommitment){
      onCustomUserCommitment({
        id: getIntFromString(commitment?.id),
        title: commitment?.title,
        toolId: getIntFromString(commitment?.tool?.id),
        completed: e?.target?.checked
      }) &&
      setUpdated();
    }
     
    onDefaultCommitmentProgress &&
      onDefaultCommitmentProgress({
        completed: e?.target?.checked,
        notes: commitment?.notes
      }) &&
      setUpdated();
  }

  return (
    <Card bordered={false}
      style={{
        display: 'grid',
        width: '100%',
        boxShadow:
          "5px 0px 25px 3px rgba(32, 32, 32, 0.1)",
        borderRadius: "10px",
      }}
      bodyStyle={{ padding: "10px" }}>
      {/* <Hoverable
        
      > */}
        <Row>
          <Col span={24}>
            <div style={{ display: 'flex', gap: '15px',width:"100%",cursor:"pointer" }}>
              {dashboard !== 'manager' && <Checkbox checked={completed} onChange={handleChange} />}
              <span style={{wordBreak:"break-all",width:"100%"}}onClick={() => {
        if (dashboard !== 'manager') {
          setVisible(true);
        } else {
          setModal2({ visible: true, id: getIntFromString(commitment.id), default: defaultCustomUserCommitment });
        }
      }}>{commitment?.title}</span>
            </div>
          </Col>
        </Row>
      {/* </Hoverable> */}
      {dashboard === 'manager' && <ManagerAddCommitment modal2={modal2} setModal2={setModal2} />}
      <Modal
        footer={null}
        centered
        visible={/* getIntFromString(commitment.id) === 1 || */ visible}
        width={'40%'}
        destroyOnClose={true}
        onCancel={() => setVisible(false)}
      >
        <h3>{commitment?.title}</h3>

        <br />
        <div
          style={{
            border: '1px solid rgba(202, 202, 202, 0.25)',
            margin: '7px',
            padding: '15px',
            height: '80%',
            borderRadius: '2px',
            minHeight: '200px'
          }}
        >
          <ReactMarkdown>{commitment?.description}</ReactMarkdown>
        </div>

        {((commitment?.notes && commitment?.notes !== '') || dashboard === 'manager-personal') && (
          <>
            <br />
            <div
              style={{
                border: '1px solid rgba(202, 202, 202, 0.25)',
                margin: '7px',
                padding: '15px',
                height: '80%',
                borderRadius: '2px',
                minHeight: '200px'
              }}
            >
              <ReactMarkdown>{commitment?.notes}</ReactMarkdown>
            </div>
          </>
        )}
        <br />

        <Row justify={'space-between'} align="middle">
          <Col>
            {dashboard !== 'manager' && (
              <Checkbox defaultChecked={completed} onChange={handleChange}>
                Completed
              </Checkbox>
            )}
          </Col>
          <Col>Commited on : {new Date(dateCreated).toDateString()}</Col>
          <Col>Completed on : {dateCompleted ? new Date(dateCompleted).toDateString() : 'Not-completed'}</Col>
        </Row>
      </Modal>
    </Card>
  );
};

export default CommitmentComponent;

const Hoverable = styled.div`
  white-space: nowrap;
  overflow: hidden;
  :hover {
    white-space: unset;
    overflow: unset;
    cursor: pointer;
  }
`;
