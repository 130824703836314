import React, { useEffect } from 'react';
import { Progress, Spin } from 'antd';
import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

import { compose } from '../../core';

const GET_ALL_DEFAULT_COMMITMENTS_PROGRESS = gql`
  query getAllDefaultCommitmentsProgressForReportingEmployee($employeeId: ID!, $completed: Boolean) {
    getAllDefaultCommitmentsProgressForReportingEmployee(employeeId: $employeeId, completed: $completed) {
      # totalCount
      edges {
        node {
          id
        }
      }
    }
  }
`;

const GET_ALL_CUSTOM_USER_COMMITMENTS_FOR_REPORTING_EMPLOYEE = gql`
  query getAllCustomUserCommitmentsForReportingEmployee($employeeId: ID!, $completed: Boolean) {
    getAllCustomUserCommitmentsForReportingEmployee(employeeId: $employeeId, completed: $completed) {
      totalCount
    }
  }
`;

const ManagerCommitmentProgressBar = props => {
  const {
    loading,
    defaultCommitmentsProgressCompleted,
    defaultCommitmentsProgress,
    customUserCommitmentsCompleted,
    customUserCommitments,
    updated,
    refetchCommitmentsProgressCompleted,
    refetchCommitmentsProgressForReportingEmployee,
    refetchCustomUserCommitmentsForReportingEmployee,
    refetchAllCustomUserCommitmentsForReportingEmployee
  } = props;
  const totalCompleted = customUserCommitmentsCompleted?.totalCount + defaultCommitmentsProgressCompleted?.edges?.length;
  const totalCount = customUserCommitments?.totalCount + defaultCommitmentsProgress?.edges?.length;
  const progress = React.useRef(() => {});

  progress.current = () => {
    refetchCommitmentsProgressCompleted();
    refetchCommitmentsProgressForReportingEmployee();
    refetchCustomUserCommitmentsForReportingEmployee();
    refetchAllCustomUserCommitmentsForReportingEmployee();
  };

  useEffect(() => {
    progress.current();
  }, [updated]);

  return (
    <Spin spinning={loading} size="small">
      <Progress
        type="circle"
        percent={((totalCompleted * 100) / totalCount || 0).toFixed(2)}
        strokeColor="#B0CB1F"
        strokeWidth="10"
      />
    </Spin>
  );
};

export default compose(
  graphql(GET_ALL_DEFAULT_COMMITMENTS_PROGRESS, {
    options: ({ employeeId }) => {
      return { variables: { completed: true, employeeId } };
    },
    props({ data }) {
      const { loading, error, getAllDefaultCommitmentsProgressForReportingEmployee, subscribeToMore, updateQuery, refetch } =
        data;
      return {
        loading,
        error,
        defaultCommitmentsProgressCompleted: getAllDefaultCommitmentsProgressForReportingEmployee,
        subscribeToMore,
        updateQuery,
        refetchCommitmentsProgressCompleted: refetch
      };
    }
  }),
  graphql(GET_ALL_DEFAULT_COMMITMENTS_PROGRESS, {
    options: ({ employeeId }) => {
      return { variables: { employeeId } };
    },
    props({ data }) {
      const { loading, error, getAllDefaultCommitmentsProgressForReportingEmployee, subscribeToMore, updateQuery, refetch } =
        data;
      return {
        loading,
        error,
        defaultCommitmentsProgress: getAllDefaultCommitmentsProgressForReportingEmployee,
        subscribeToMore,
        updateQuery,
        refetchCommitmentsProgressForReportingEmployee: refetch
      };
    }
  }),

  graphql(GET_ALL_CUSTOM_USER_COMMITMENTS_FOR_REPORTING_EMPLOYEE, {
    options: ({ employeeId }) => {
      return { variables: { completed: true, employeeId } };
    },
    props({ data }) {
      const { loading, error, getAllCustomUserCommitmentsForReportingEmployee, subscribeToMore, updateQuery, refetch } =
        data;
      return {
        loading,
        error,
        customUserCommitmentsCompleted: getAllCustomUserCommitmentsForReportingEmployee,
        subscribeToMore,
        updateQuery,
        refetchCustomUserCommitmentsForReportingEmployee: refetch
      };
    }
  }),
  graphql(GET_ALL_CUSTOM_USER_COMMITMENTS_FOR_REPORTING_EMPLOYEE, {
    options: ({ employeeId }) => {
      return { variables: { employeeId } };
    },
    props({ data }) {
      const { loading, error, getAllCustomUserCommitmentsForReportingEmployee, subscribeToMore, updateQuery, refetch } =
        data;
      return {
        loading,
        error,
        customUserCommitments: getAllCustomUserCommitmentsForReportingEmployee,
        subscribeToMore,
        updateQuery,
        refetchAllCustomUserCommitmentsForReportingEmployee: refetch
      };
    }
  })
)(ManagerCommitmentProgressBar);
