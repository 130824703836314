import gql from 'graphql-tag';

export const DELETE_BADGE_AWARD =gql`
    mutation deleteBadgeAward($badgeAwardId: ID!){
        deleteBadgeAward(badgeAwardId: $badgeAwardId){
            badgeAward{
                id
            }
        }
    }
`