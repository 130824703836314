import React from 'react';
import { displayUtcToUserTimezone } from 'modules/look';
import { Spin } from 'antd';
const UserSurveyStatus = (props) => {
    const { survey_data } = props


    return (
        <>
            {survey_data ?

                <div className='table-overflow'>
                    <table className='employee-profile-table'>

                        <thead>
                            <tr>
                                <th style={{ paddingLeft: "1.5em", textAlign: "left" }}>SURVEY NAME</th>
                                <th>RESPONSE DATE</th>
                                <th >STATUS</th>
                            </tr>
                        </thead>

                        {
                            survey_data?.length ?

                                survey_data?.map(record => (
                                    <tbody>
                                        <tr className='table-body-tr'>
                                            <td className='table-body-td first-child td-main-label' style={{ textAlign: "left" }}>
                                                {record?.name}
                                            </td>

                                            <td className='table-body-td td-main-label'>
                                                {
                                                    record?.response_date ?
                                                        displayUtcToUserTimezone(record?.response_date, "DD MMM YYYY") : ''
                                                }
                                            </td>
                                            <td className='table-body-td last-child' style={{ display: "flex", justifyContent: "center" }}>
                                                <div className='dashboard-performance-status-btn' style={{ color: `${record.colorCombination?.color}`, background: `${record.colorCombination?.bg}` }}>
                                                    {record?.status}
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                ))
                                :
                                ''
                        }

                    </table>
                    {survey_data?.length===0&&<div style={{display:'flex',justifyContent:"center",alignItems:"center",marginTop:"3em"}}>
                        <h4 className='no-data-profile'>No Data Found</h4>
                    </div>}
                </div>
                :
                <div align="center" style={{ width: "100%", height: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Spin spinning={true} ></Spin>
                </div>
            }
        </>
    )
}

export default UserSurveyStatus
