import gql from "graphql-tag";
import { RECEIVED_FEEDBACK_TYPE } from "./ReceivedFeedback.gql";

export const RECEIVED_FEEDBACK_SUBSCRIPTION = gql`
${RECEIVED_FEEDBACK_TYPE}
subscription feedbackSubscription {
    feedbackSubscription {
        mutation
        feedback{
            ...FeedbackType
        }
    }
}
`