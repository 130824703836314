import gql from 'graphql-tag';


export const SOULTIPS_FOR_REPORTEE = gql`
query getSoulAiTip($reportUserId:ID!,$other: Boolean!){
  getSoulAiTip(other:$other,reportUserId:$reportUserId){
    id
    forOther
  }
}
`;

export const SOULTIPS_FOR_REPORTEE_CALL = gql`
query getSoulAiTip($reportUserId:ID!,$other: Boolean!){
  getSoulAiTip(other:$other,reportUserId:$reportUserId){
    id
    forOther
  }
}
`;

export const SOUL_AI_TIP_FOR_OTHER =gql`
subscription soulTipSubscription {
  soulTipSubscription {
     soulTip{
      id
      forOther
    }
    mutation
  }
}
`