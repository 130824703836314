import React from 'react';
import { Spin } from 'antd';

import { compose } from '../../core';
import { getIntFromString, ButtonsCatch, withMarkdownFieldValue } from '../../look';
import EditOrganizationView from '../components/EditOrganizationView';
import ROUTE from '../route';

import { withOrganizationById, withEditOrganization } from './OrganizationOperation';
import { CKEditorFieldName, CKEditorFieldName1 } from '../components/OrganizationFormComponent';

const EditOrganization = props => {
  const { history, editOrganization, organizationById } = props;
  const [submitLoading, setSubmitLoading] = React.useState(false);

  const handleSubmit = async (values, item) => {
    setSubmitLoading(true);

    try {
      const response = await editOrganization(
        withMarkdownFieldValue(CKEditorFieldName, {
          id: getIntFromString(organizationById.id),
          ...withMarkdownFieldValue(CKEditorFieldName1, { id: getIntFromString(organizationById.id), ...values })
        })
      );

      response &&
        ButtonsCatch(
          item,
          `${ROUTE.organizationDetailLink}${getIntFromString(organizationById.id)}`,
          history,
          item === undefined ? window.location.pathname : `${ROUTE.addOrganization}`
        );
    } catch (e) {
      throw new Error(e.message);
    }
    setSubmitLoading(false);
  };

  return (
    <Spin spinning={submitLoading} size="large">
      <EditOrganizationView onSubmit={handleSubmit} {...props} />
    </Spin>
  );
};

export default compose(withOrganizationById, withEditOrganization)(EditOrganization);
