import gql from 'graphql-tag';
import { TEAM_TYPE } from './TeamType.gql';

export const TEAM_QUERY = gql`
  ${TEAM_TYPE}
  query Team($id: ID!) {
    Team(id: $id) {
      ...TeamType
    }
  }
`;
