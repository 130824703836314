import React, { useState } from "react";
import { Row, Col, Card, Spin, Tooltip } from "antd";

import ManagerTeamComponent from "../containers/ManagerTeamComponent";
import DashboardMenuBar from "../../dashboard/components/DashboardMenuBar";
import Dashboard from "../../dashboard/containers/Dashboard";

import ROUTE from "../route";

import TeamsMAppAnchors from "../containers/TeamsMAppAnchors";
import MAppPulseCheck from "../../dashboard/containers/MAppPulseCheck";
import CoachingCommitment from "../containers/CoachingCommitment";
import MyCoachingCommitment from "../containers/MyCoachingCommitment";
import EmployeeMAppAnchors from "../containers/EmployeeMAppAnchors";

const ManagerDashboardComponent = (props) => {
  const { loading, submitLoading, setSubmitLoading } = props;
  const [activeEmployee, setActiveEmployee] = useState(/* 11 */ null);
  const [activeUser, setActiveUser] = useState(null);
  const [id, setId] = React.useState(1);

  return (
    <Spin spinning={loading || submitLoading} size="large">
      <Dashboard active={ROUTE.manager}>
        {!loading && (
          <Row gutter={[24, 24]}>
            <Col lg={16} span={24}>
              <DashboardMenuBar active={ROUTE.manager} />
              <br />
              <Row gutter={[24,24]}>
                <TeamsMAppAnchors />
                <Col lg={12} md={24} xs={24}>
                  <Row gutter={[24, 20]}>
                    <MAppPulseCheck manager />
                    <Col span={24}>
                      <Tooltip title="This feature is comming soon!">
                        <Card
                          bodyStyle={{
                            boxShadow: "5px 0px 25px 3px rgba(32, 32, 32, 0.1)",
                            borderRadius: "2%",
                            paddingBottom: 0,
                            paddingTop: 0,
                            cursor: "not-allowed",
                          }}
                          bordered={false}
                          // onClick={() => setId(2)}
                        >
                          <Row justify="center">
                            <h2
                              style={{
                                margin: "1em 0",
                                fontWeight: "normal",
                                color: id === 2 ? "#881FCC" : "#222",
                              }}
                            >
                              Compare
                            </h2>
                          </Row>
                        </Card>
                      </Tooltip>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            <ManagerTeamComponent
              me={props.me}
              activeEmployee={activeEmployee}
              setActiveEmployee={(e) => setActiveEmployee(e)}
              setActiveUser={(e) => setActiveUser(e)}
            />

            {activeEmployee && (
              <Col lg={{ span: 24 }}>
                <Row gutter={[24, 24]}>
                  <CoachingCommitment
                    employeeId={activeEmployee}
                    setSubmitLoading={setSubmitLoading}
                    dashboard={"manager"}
                  />
                  <EmployeeMAppAnchors employeeId={activeEmployee} />
                  <MyCoachingCommitment
                    forUser={activeUser}
                    setSubmitLoading={setSubmitLoading}
                    dashboard={"manager-personal"}
                  />
                </Row>
              </Col>
            )}
          </Row>
        )}
      </Dashboard>
    </Spin>
  );
};

export default ManagerDashboardComponent;
