import CancelInvitationView from "modules/poc-dashboard/components/organization/cancelInvitationView";
import React from "react";
import { withEditPocEmpolee } from "./organizationOperations";
import { compose } from "modules/core";
import { withApollo } from '@apollo/client/react/hoc';
import { getIntFromString } from "modules/look";

const CancelInvitationContainer  =(props)=>{
    const {cancel_invitation,updateEmployee,onSuccess} =props;

    const changeStatus =async(value)=>{
        let update_data={
            id:getIntFromString(cancel_invitation?.id),
            status:value
        }
        let response = await updateEmployee({...update_data})
        if(response){
            let detail ={...cancel_invitation,active_status:value?'Active':'Inactive'};
            onSuccess(detail)
        }
    }

    return(
        <>
            <CancelInvitationView 
            changeStatus={(value)=>changeStatus(value)}
            {...props}/>
        </>
    )
}

export default compose(withApollo,withEditPocEmpolee)(CancelInvitationContainer);