import gql from "graphql-tag"





export const ALL_COMPETENCY_CATEGORIES = gql`

query allCompetencyCategory($organization:ID){
    allCompetencyCategory(organization:$organization) {
      edgeCount
      edges {
        node {
          id
          title
          behaviourallyanchoredratingSet {
            totalCount
          }
          competencySet {
            edges {
              node {
                id
                title
              }
            }
          }
        }
      }
    }
    
  }

`
