import gql from 'graphql-tag';

export const ALL_MEETING_COUNT = gql`
query allMeetings($when_Range: [DateTime],$meetingType: String,$orderBy:[String],$endedAt_Isnull:Boolean,$member: [ID]){
    allMeetings(when_Range:$when_Range, meetingType:$meetingType,orderBy:$orderBy,endedAt_Isnull:$endedAt_Isnull,member:$member) {
        totalCount
        edges {
            node {
              id
              title
              when
                owner{
                    id
                    user{
                        id
                        firstName
                        lastName
                    }
                }
                member{
                    edges{
                        node{
                            id
                            user{
                            id
                            firstName
                            lastName
                            }
                        }
                    }
                }
            }
        }
      }
}
`