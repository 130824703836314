import React from 'react';
import { Card, Row, Col, Menu, Drawer, Button } from 'antd';
import { BarsOutlined, UserOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

import USER_ROUTE from '../../user/route';
import DASHBOARD_ROUTE from '../../dashboard/route';
import { NODE_ENV } from '../../../config';


const UserLayout = props => {
  const { children, active, me } = props;
  const [visible, setVisible] = React.useState(false);
  let history = useHistory();

  const onSelect = params => {
    history.push(params.key);
  };

  // const getDashboardkey = () => {
  //   // console.log(window.location.pathname);
  //   return DASHBOARD_ROUTE.personal;
  //   // if (typeof window !== 'undefined')
  //   //   switch (window.location.pathname) {
  //   //     case DASHBOARD_ROUTE.personal:
  //   //       return DASHBOARD_ROUTE.personal;
  //   //     case DASHBOARD_ROUTE.manager:
  //   //       return DASHBOARD_ROUTE.manager;
  //   //     default:
  //   //       return '';
  //   //   }
  // };

  const MenuItems = (
    <>
      <Menu.Item
        key={DASHBOARD_ROUTE.personal /* getDashboardkey */}
        // disabled={NODE_ENV === 'production'}
        icon={<BarsOutlined />}
        style={{ paddingRight: '2vw' }}
      >
        Dashboard
      </Menu.Item>
      <Menu.Item key={USER_ROUTE.profile} icon={<UserOutlined />} style={{ paddingRight: '2vw' }}>
        My Profile
      </Menu.Item>
      <Menu.Item key={USER_ROUTE.myResponse} icon={<BarsOutlined />} style={{ paddingRight: '2vw' }}>
        My Responses
      </Menu.Item>
    </>
  );

  return (
    <Row>
      <Col lg={{ span: 1, offset: 0 }} xs={{ span: 0, offset: 0 }} />
      <Col lg={{ span: 3, offset: 0 }} xs={{ span: 0, offset: 0 }}>
        <Card
          bodyStyle={{
            padding: '15vh 0px 60vh 0px',
            boxShadow: '5px 0px 25px 3px rgba(32, 32, 32, 0.1)',
            borderRadius: '3%',
            height: '80vh'
          }}
          bordered={false}
        >
          <Row justify="end">
            <Menu mode="inline" defaultSelectedKeys={[`${active}`]} onSelect={onSelect}>
              {MenuItems}
            </Menu>
          </Row>
        </Card>
      </Col>
      {me?.isSuperuser && (
        <Col lg={{ span: 0, offset: 0 }} xs={{ span: 24, offset: 0 }}>
          <Row justify="center">
            <Col span={20}>
              <Button block type="primary" onClick={() => setVisible(true)}>
                Drawer
              </Button>
            </Col>
          </Row>
          <Drawer
            width="190px"
            bodyStyle={{ padding: '24px 0px' }}
            placement="left"
            closable={false}
            onClose={() => setVisible(false)}
            visible={visible}
          >
            <Menu mode="inline" defaultSelectedKeys={[`${active}`]} onSelect={onSelect}>
              {MenuItems}
            </Menu>
          </Drawer>
        </Col>
      )}
      <Col lg={{ span: 18, offset: 1 }} xs={{ span: 24, offset: 0 }}>
        {children}
      </Col>
      <Col lg={{ span: 1, offset: 0 }} xs={{ span: 0, offset: 0 }} />
    </Row>
  );
};

export default UserLayout;