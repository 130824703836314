import gql from 'graphql-tag';

export const EMPLOYEE_LIST = gql`
query{
    allOrganizationEmployees:me{
        id
        employee{
            id
          organizationSet {
            edges {
              node {
                id
                employees {
                  edges {
                    node {
                      id
                      user{
                          id
                            firstName
                            lastName
                        }
                    }
                  }
                }
              }
            }
          }
        }
    }
}
`