// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { compose } from '../../core';
import { withCreateContributor, withCreateOKR } from './objective-keyresult-operations';
import { withApollo } from '@apollo/client/react/hoc';
import KeyResultTableView from '../components/KeyResultTableView';
import { OKR_CHILD_LIST_QUERY, OKR_CHILD_SUBSCRIPTION } from '../graphql/ChildOKRList.gql';
import moment from 'moment';
import { globalPermissionValidator } from 'modules/look';
import { kudos_permission } from 'Permissions/kudos.permission';

const OkrKeyResultTableContainer = props => {
  const {me,client,objectiveDetail,isDiscarded,userPermission, keyResultDiscardState}=props
  const [loading,setloading]= React.useState(true)
  const [ discardedOkrId , setDiscardedOkrId ] = useState()
  const [allkr,setallkr]= React.useState()
  const [permission,setpermission]= React.useState()
  const dataRef = React.useRef()
  const m = React.useRef( true )

  let contributorsSub = undefined

  let discardSub = undefined

  React.useEffect( () => {
    return () => {
      m.current = false
    }
  }, [] )

  useState(()=>{
    dataRef.current = allkr
  },
  [allkr])

  React.useEffect( () => {
    m.current = true

  }, [] )
  
  const changeReference = (value) => {
    const {okrId, type} = value
    dataRef.current = allkr
    let list 
    if (!dataRef.current?.edges){
      list = dataRef?.current
    } else {
      list = dataRef?.current?.edges?.map(({node})=> node?.okr)
    }
    if (type === 'DELETE'){
      let newDelArray = list?.filter(item => item?.id != okrId)
      dataRef.current = newDelArray
    }
  }

  useEffect(()=>{
    if (keyResultDiscardState){
      if (dataRef.current){
        dataRef.current = allkr
        let krList = [];
        if (!dataRef.current?.edges){
          krList = dataRef?.current
        } else {
          krList = dataRef?.current?.edges?.map(({node})=> node?.okr)
        }
           let indexOfDiscardedRequestObj = undefined;
      
           if(krList?.length){
           indexOfDiscardedRequestObj = krList.findIndex(
            (value)=>value?.id === keyResultDiscardState?.discardRequest?.okr?.id
           )
           }
           let record = krList[indexOfDiscardedRequestObj];
           if(record){
            record.discardRequest = keyResultDiscardState?.discardRequest
            record.discardRequestRaised = true
            record.isDiscarded = {status:"PENDING"}
           } 
    

           dataRef.current = krList;
           setallkr([...krList])
      }


    }
  }, [keyResultDiscardState])

  const getKRList =async(filter)=>{
    setloading(true)
    let { data } = await client.query({
      query:OKR_CHILD_LIST_QUERY,
      fetchPolicy: 'network-only',
      variables: {okr_Parent:objectiveDetail?.id,createdBy:me?.employee?.id,tz:moment.tz.guess(),...filter,recipient:me?.employee?.id} 
    });
    if(data){

    let incomingDataFromQuery = data?.contributors?.edges?.map(({ node }) => {
        return {
          id: node?.okr?.id,
          title: node?.okr?.title,
          startdate: node?.okr?.startdate,
          duedate: node?.okr?.duedate,
          goalStatement: node?.okr?.goalStatement,
          correctionDeadline: node?.okr?.correctionDeadline,
          badge_award:node?.okr?.badgeAward?.edges,
          contributors: node?.okr?.contributorSet?.edges?.map(({ node }) => {
            return { ...node?.employee, role: node?.role }
          }),
          progress: node?.okr?.progress ?? 0,
          childSet: node?.okr?.childSet?.totalCount ? true : false,
          discardRequestRaised:node?.okr?.discardRequestRaised.totalCount>0?true:false,
          isDiscarded: {
            status: (isDiscarded === "APPROVED") ? isDiscarded :
              node?.okr?.isDiscarded?.status ?
                node?.okr?.isDiscarded?.status :
                node?.okr?.discardRequest?.totalCount ? "PENDING" : '',
          }
        }
      })

    setallkr(incomingDataFromQuery)
   
    data.contributors.edges.forEach(({node})=>{
      let okr = incomingDataFromQuery?.find(i=> i.id== node?.okr?.id)
      if(okr){
        node.okr.isDiscarded = okr.isDiscarded
      }
    })
    dataRef.current =data?.contributors
    contributorsSub = await client.subscribe( {
      query: OKR_CHILD_SUBSCRIPTION,
      variables: {okr_Parent:objectiveDetail?.id,createdBy:me?.employee?.id,...filter},
      fetchPolicy: 'network-only'
    } ).subscribe( {
      next( result ) {
        let contributorsCacheData = dataRef.current
        if(result?.data?.contributorSubscription?.contributor?.okrType==='KEY_RESULT' && result?.data?.contributorSubscription?.contributor?.role ==="OWNER" && result?.data?.contributorSubscription?.contributor?.okr?.parent?.id===objectiveDetail?.id){
           switch ( result.data.contributorSubscription.mutation ) {
          case 'CREATE':
            const create = contributorsCacheData?.edges?.filter(item =>
              item.id === result.data?.contributorSubscription?.contributor?.id
                  ? false
                  : true
                  ).concat([{node:result?.data?.contributorSubscription?.contributor}])
                  let incomingData = create?.map(({ node }) => {
                    return {
                      id: node?.okr?.id,
                      title: node?.okr?.title,
                      startdate: node?.okr?.startdate,
                      duedate: node?.okr?.duedate,
                      goalStatement: node?.okr?.goalStatement,
                      correctionDeadline: node?.okr?.correctionDeadline,
                      badge_award:node?.badge_award || node?.okr?.badgeAward?.edges,
                      contributors: node?.okr?.contributorSet?.edges?.map(({ node }) => {
                        return { ...node?.employee, role: node?.role }
                      }),
                      progress: node?.okr?.progress ?? 0,
                      childSet: node?.okr?.childSet?.totalCount ? true : false,
                      discardRequestRaised:node?.okr?.discardRequestRaised.totalCount>0?true:false,
                      isDiscarded: {
                        status: (isDiscarded === "APPROVED") ? isDiscarded :
                          node?.okr?.isDiscarded?.status ?
                            node?.okr?.isDiscarded?.status :
                            node?.okr?.discardRequest?.totalCount ? "PENDING" : '',
                        // id:node?.okr?.isDiscarded?.id?node?.okr?.isDiscarded?.id:
                        // node?.okr?.discardRequest?.totalCount?node?.okr?.discardRequest?.edges[node?.okr?.discardRequest?.edges?.length-1]?.node?.id:''
                      }
                    }
                  })   
                  

            if ( m.current ) {
              if(create){
                setallkr(incomingData)
                dataRef.current =  incomingData
              }
             
            }
            break
          case 'UPDATE':
            if(result?.data?.contributorSubscription?.contributor?.okrType==='KEY_RESULT'){
            if ( m.current ) {
              const Update =contributorsCacheData?.edges?.map( item =>
                  item?.node?.id === result?.data?.contributorSubscription.contributor?.id
                    ? result.data.contributorSubscription.contributor
                    : item
                ) 
         
                if(Update){
          
                  setallkr(Update)
                dataRef.current = {edges:Update}
                }
                
            }
          }
            break
            case 'DELETE':
              if(result?.data?.contributorSubscription?.contributor?.okrType==='KEY_RESULT'){
              if ( m.current ) {
                const deleted=contributorsCacheData?.edges?.filter( item =>
                    item?.node?.id === result?.data?.contributorSubscription.contributor?.id
                      ? false
                      : true
                  ) 
                  if(deleted){
                    setallkr(deleted)
                    dataRef.current = {edges:deleted}
                  }
                
              }
              }
              break
            default:
              break
        }
      }
      }})

    }

}

React.useEffect(()=>{
  if(userPermission?.length){
    let permission=globalPermissionValidator(kudos_permission,userPermission)
    setpermission(permission)
  }
  else{
    setpermission(null)
  }
},[userPermission])

React.useEffect(()=>{
  if(me && objectiveDetail){
    getKRList()
  }
},[me,objectiveDetail])
  return (
    <>
    <KeyResultTableView changeReference={(e)=> changeReference(e)} setDiscardedOkrId={setDiscardedOkrId} allkr={allkr} loading={loading} setallkr={setallkr} dataRef={dataRef} setloading={(e)=>setloading(e)} {...props}permission={permission} />
    </>
  );
};

export default  compose(withApollo,withCreateOKR,withCreateContributor)(OkrKeyResultTableContainer);
