import gql from 'graphql-tag';
import { QUESTION_TYPE } from './QuestionType.gql';

export const DELETE_QUESTION = gql`
  ${QUESTION_TYPE}
  mutation deleteQuestion($id: ID!) {
    deleteQuestion(id: $id) {
      question {
        ...QuestionType
      }
    }
  }
`;
