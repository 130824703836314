import OrgEmployeeTableView from 'modules/poc-dashboard/components/organization/orgEmployeeTableView';
import React from 'react';
import '../../css/org-table-style.css'
import { withCreateVertical } from './organizationOperations';
import { compose } from 'modules/core';
import { withApollo } from '@apollo/client/react/hoc';
import { ALL_EMPLOYEE_LIST_QUEY, ALL_TEAM_EMPLOYEE_LIST_QUEY, ALL_VERTICAL_EMPLOYEE_LIST_QUEY, POC_EMPLOYEE_TOTAL_COUNT } from 'modules/poc-dashboard/graphql/organizationQuery.gql';
import { getIntFromString } from 'modules/look';
const OrgEmployeeTableContainer = (props) => {
    const { me, client, filterResponse,search } = props;
    const [employee_list, setEmployeeList] = React.useState()
    const [emp_list_loading, setEmployeeListLoading] = React.useState(false)
    const [page_info, SetPageInfo] = React.useState()
    const [total_users, setTotalUsers] = React.useState()
    let filter_ref = React.useRef({})
    let page_info_ref = React.useRef({})
    let data_list_ref = React.useRef([])

    React.useEffect(()=>{
        page_info_ref.current = page_info
    },[page_info])

    React.useEffect(()=>{
        data_list_ref.current = employee_list
    },[employee_list])

    React.useEffect(()=>{
        if(search && search!="**"){
            filter_ref.current = {
                ...filter_ref.current,
                search
            }
            selectApiModel(filter_ref.current)
        }else{
            filter_ref.current = {
                ...filter_ref.current,
                search:""
            }
            selectApiModel(filter_ref.current)
        }
    },[search])

    React.useEffect(() => {
        filter_ref.current = {...filter_ref.current,...filterResponse}
        selectApiModel(filter_ref.current)
    }, [filterResponse])

    const getAvailableStatus=(list,type)=>{
        return list?.includes(type)
    }
    React.useEffect(()=>{
        if(me?.employee){
            getTotalEmployeeCount()
        }
    },[me])
    const getTotalEmployeeCount =async()=>{
        const { data } = await client.query({
            query: POC_EMPLOYEE_TOTAL_COUNT,
            fetchPolicy: 'network-only'
        });
        if(data){
            setTotalUsers(data?.allEmployees?.totalCount)
        }
    }
    const selectApiModel = (filter_data,pagination_data=null) => {
        let user_IsActive 
        let active_available = false
        let verified 
        let verified_available = false
        if(filter_data?.status?.length){
            let list = filter_data?.status
            if(getAvailableStatus(list,"active") && getAvailableStatus(list,"inactive")){
                active_available = false
            }
            else if(getAvailableStatus(list,"active")){
                user_IsActive = true
                active_available = true
            }
            else if(getAvailableStatus(list,"inactive")){
                user_IsActive = false
                active_available = true
            }

            if(getAvailableStatus(list,"verified") && getAvailableStatus(list,"un_verified")){
                verified_available = false
            }
            else if(getAvailableStatus(list,"verified")){
                verified = true
                verified_available = true
            }
            else if(getAvailableStatus(list,"un_verified")){
                verified = false
                verified_available = true
            }
        }

        if(filter_data?.teams?.length){
            let filter = {
                id_In :filter_data?.teams?.map(i=>getIntFromString(i)?.toString()),
                verified_available,
                verified,
                user_types:filter_data?.user_types,
                search:filter_data?.search,
                offset:0,
                page:1
            }
            if(active_available){
                filter.user_IsActive = user_IsActive
            }
            if(pagination_data && pagination_data?.type=="team_employee_data"){
                filter.offset = (pagination_data?.page*10)
                filter.page = pagination_data?.page+1
            }
            TeamEmployeeList(filter)
        }else if(filter_data?.verticals?.length){
            let filter = {
                id_In :filter_data?.verticals?.map(i=>getIntFromString(i)?.toString()),
                verified_available,
                verified,
                user_types:filter_data?.user_types,
                search:filter_data?.search,
                offset:0,
                page:1
            }
            if(active_available){
                filter.user_IsActive = user_IsActive
            }
            if(pagination_data && pagination_data?.type=="vertical_employee_data"){
                filter.offset = (pagination_data?.page*10)
                filter.page = pagination_data?.page+1
            }
            verticalEmployeeList(filter)
        }else{
            let filter = {
                verified_available,
                verified,
                user_types:filter_data?.user_types,
                search:filter_data?.search
            }
            if(pagination_data && pagination_data?.type=="all_employee_data"){
                filter.employee_nonverified_cursor = pagination_data?.employee_nonverified_cursor
                filter.employee_verified_cursor = pagination_data?.employee_verified_cursor
            }
            if(active_available){
                filter.user_IsActive = user_IsActive
            }
            AllemployeeList(filter)
        }
    }

    // vertical_selection 
    const verticalEmployeeList = async (filter) => {
        setEmployeeListLoading(true)
        const { data } = await client.query({
            query: ALL_VERTICAL_EMPLOYEE_LIST_QUEY,
            variables: filter,
            fetchPolicy: 'network-only'
        });

        if (data) {
            let next_pages = []
            let list = data?.verticals?.edges?.map(({ node }) => {
                // ******* heads
                if(!filter?.verified_available || (filter?.verified_available && filter?.verified)){
                    if(node?.verified_heads?.pageInfo?.hasNextPage){
                        next_pages.push({hasNextPage:true})
                    }
                }

                let verifiled_list_heads = node?.verified_heads?.edges?.map(i => {
                    return {
                        id: i?.node.id,
                        user_id: i?.node?.user?.id,
                        firstName: i?.node?.user?.firstName,
                        lastName: i?.node?.user?.lastName,
                        email: i?.node?.user?.email,
                        active_status: i?.node?.user?.isActive ? "Active" : "Inactive",
                        verified: true,
                        user_type: getUserType(i?.node),
                        verticals: RemoveDuplicates([...i?.node?.verticalHeadEmployee?.edges?.map((v) => v?.node)].concat(i?.node?.verticalMembersEmployee?.edges.map((v) => v?.node))),
                        teams: RemoveDuplicates([...i?.node?.teamManagerEmployee?.edges.map((t) => t?.node)].concat(i?.node?.teamMembersEmployee?.edges.map((t) => t?.node))),
                        valid_upto: '12 Nov, 2024'
                    }
                })

              
                if(!filter?.verified_available || (filter?.verified_available && !filter?.verified)){
                    if(node?.nonverified_heads?.pageInfo?.hasNextPage){
                        next_pages.push({hasNextPage:true})
                    }
                }
                let non_verifiled_list_heads = node?.nonverified_heads?.edges?.map(i => {
                    return {
                        id: i?.node.id,
                        user_id: i?.node?.user?.id,
                        firstName: i?.node?.user?.firstName,
                        lastName: i?.node?.user?.lastName,
                        email: i?.node?.user?.email,
                        active_status: i?.node?.user?.isActive ? "Active" : "Inactive",
                        verified: false,
                        user_type: getUserType(i?.node),
                        verticals: RemoveDuplicates([...i?.node?.verticalHeadEmployee?.edges?.map((v) => v?.node)].concat(i?.node?.verticalMembersEmployee?.edges.map((v) => v?.node))),
                        teams: RemoveDuplicates([...i?.node?.teamManagerEmployee?.edges.map((t) => t?.node)].concat(i?.node?.teamMembersEmployee?.edges.map((t) => t?.node))),
                        valid_upto: '12 Nov, 2024'
                    }
                })

                // ******* employee
             

                if(!filter?.verified_available || (filter?.verified_available && filter?.verified)){
                    if(node?.verified?.pageInfo?.hasNextPage){
                        next_pages.push({hasNextPage:true})
                    }
                }

                let verifiled_list = node?.verified?.edges?.map(i => {
                    return {
                        id: i?.node.id,
                        user_id: i?.node?.user?.id,
                        firstName: i?.node?.user?.firstName,
                        lastName: i?.node?.user?.lastName,
                        email: i?.node?.user?.email,
                        active_status: i?.node?.user?.isActive ? "Active" : "Inactive",
                        verified: true,
                        user_type: getUserType(i?.node),
                        verticals: RemoveDuplicates([...i?.node?.verticalHeadEmployee?.edges?.map((v) => v?.node)].concat(i?.node?.verticalMembersEmployee?.edges.map((v) => v?.node))),
                        teams: RemoveDuplicates([...i?.node?.teamManagerEmployee?.edges.map((t) => t?.node)].concat(i?.node?.teamMembersEmployee?.edges.map((t) => t?.node))),
                        valid_upto: '12 Nov, 2024'
                    }
                })

             
                if(!filter?.verified_available || (filter?.verified_available && !filter?.verified)){
                    if(node?.nonverified?.pageInfo?.hasNextPage){
                        next_pages.push({hasNextPage:true})
                    }
                }
                let non_verifiled_list = node?.nonverified?.edges?.map(i => {
                    return {
                        id: i?.node.id,
                        user_id: i?.node?.user?.id,
                        firstName: i?.node?.user?.firstName,
                        lastName: i?.node?.user?.lastName,
                        email: i?.node?.user?.email,
                        active_status: i?.node?.user?.isActive ? "Active" : "Inactive",
                        verified: false,
                        user_type: getUserType(i?.node),
                        verticals: RemoveDuplicates([...i?.node?.verticalHeadEmployee?.edges?.map((v) => v?.node)].concat(i?.node?.verticalMembersEmployee?.edges.map((v) => v?.node))),
                        teams: RemoveDuplicates([...i?.node?.teamManagerEmployee?.edges.map((t) => t?.node)].concat(i?.node?.teamMembersEmployee?.edges.map((t) => t?.node))),
                        valid_upto: '12 Nov, 2024'
                    }
                })
                return [...verifiled_list, ...non_verifiled_list,...verifiled_list_heads,...non_verifiled_list_heads]
            })
            list = [].concat.apply([],list)
            list = sortData(list)
            if(filter?.page>1){
                list = data_list_ref.current?.concat(list)
            }
            var setObj = new Set();
            var result = list.reduce((acc, item) => {
              if (!setObj.has(item?.user_id)) {
                setObj.add(item?.user_id)
                acc.push(item)
              }
              return acc;
            }, [])
            if(filter?.verified_available){
                result = result.filter(i=>i?.verified == filter?.verified)
            }
            if(filter?.user_types?.length){
                result = result.filter(i=>filter?.user_types?.includes(i?.user_type))
            }
          
            setEmployeeList(result)
            SetPageInfo({
                type:"vertical_employee_data",
                has_next_page:next_pages?.find(i=>i?.hasNextPage)?true:false,
                page:filter?.page || 1
            })
            setEmployeeListLoading(false)
        }
    }
    // ******

    // team_selection 
    const TeamEmployeeList = async (filter) => {
        setEmployeeListLoading(true)
        const { data } = await client.query({
            query: ALL_TEAM_EMPLOYEE_LIST_QUEY,
            variables: filter,
            fetchPolicy: 'network-only'
        });

        if (data) {
            let next_pages = []
            let list = data?.Teams?.edges?.map(({ node }) => {
                // ******* managers
                if(!filter?.verified_available || (filter?.verified_available && filter?.verified)){
                    if(node?.verified_managers?.pageInfo?.hasNextPage){
                        next_pages.push({hasNextPage:true})
                    }
                }

                let verifiled_list_heads = node?.verified_managers?.edges?.map(i => {
                    return {
                        id: i?.node.id,
                        user_id: i?.node?.user?.id,
                        firstName: i?.node?.user?.firstName,
                        lastName: i?.node?.user?.lastName,
                        email: i?.node?.user?.email,
                        active_status: i?.node?.user?.isActive ? "Active" : "Inactive",
                        verified: true,
                        user_type: getUserType(i?.node),
                        verticals: RemoveDuplicates([...i?.node?.verticalHeadEmployee?.edges?.map((v) => v?.node)].concat(i?.node?.verticalMembersEmployee?.edges.map((v) => v?.node))),
                        teams: RemoveDuplicates([...i?.node?.teamManagerEmployee?.edges.map((t) => t?.node)].concat(i?.node?.teamMembersEmployee?.edges.map((t) => t?.node))),
                        valid_upto: '12 Nov, 2024'
                    }
                })

                if(!filter?.verified_available || (filter?.verified_available && !filter?.verified)){
                    if(node?.nonverified_managers?.pageInfo?.hasNextPage){
                        next_pages.push({hasNextPage:true})
                    }
                }
                let non_verifiled_list_heads = node?.nonverified_managers?.edges?.map(i => {
                    return {
                        id: i?.node.id,
                        user_id: i?.node?.user?.id,
                        firstName: i?.node?.user?.firstName,
                        lastName: i?.node?.user?.lastName,
                        email: i?.node?.user?.email,
                        active_status: i?.node?.user?.isActive ? "Active" : "Inactive",
                        verified: false,
                        user_type: getUserType(i?.node),
                        verticals: RemoveDuplicates([...i?.node?.verticalHeadEmployee?.edges?.map((v) => v?.node)].concat(i?.node?.verticalMembersEmployee?.edges.map((v) => v?.node))),
                        teams: RemoveDuplicates([...i?.node?.teamManagerEmployee?.edges.map((t) => t?.node)].concat(i?.node?.teamMembersEmployee?.edges.map((t) => t?.node))),
                        valid_upto: '12 Nov, 2024'
                    }
                })

                // ******* employee

                if(!filter?.verified_available || (filter?.verified_available && filter?.verified)){
                    if(node?.verified?.pageInfo?.hasNextPage){
                        next_pages.push({hasNextPage:true})
                    }
                }
                let verifiled_list = node?.verified?.edges?.map(i => {
                    return {
                        id: i?.node.id,
                        user_id: i?.node?.user?.id,
                        firstName: i?.node?.user?.firstName,
                        lastName: i?.node?.user?.lastName,
                        email: i?.node?.user?.email,
                        active_status: i?.node?.user?.isActive ? "Active" : "Inactive",
                        verified: true,
                        user_type: getUserType(i?.node),
                        verticals: RemoveDuplicates([...i?.node?.verticalHeadEmployee?.edges?.map((v) => v?.node)].concat(i?.node?.verticalMembersEmployee?.edges.map((v) => v?.node))),
                        teams: RemoveDuplicates([...i?.node?.teamManagerEmployee?.edges.map((t) => t?.node)].concat(i?.node?.teamMembersEmployee?.edges.map((t) => t?.node))),
                        valid_upto: '12 Nov, 2024'
                    }
                })

               
                if(!filter?.verified_available || (filter?.verified_available && !filter?.verified)){
                    if(node?.nonverified?.pageInfo?.hasNextPage){
                        next_pages.push({hasNextPage:true})
                    }
                }
                let non_verifiled_list = node?.nonverified?.edges?.map(i => {
                    return {
                        id: i?.node.id,
                        user_id: i?.node?.user?.id,
                        firstName: i?.node?.user?.firstName,
                        lastName: i?.node?.user?.lastName,
                        email: i?.node?.user?.email,
                        active_status: i?.node?.user?.isActive ? "Active" : "Inactive",
                        verified: false,
                        user_type: getUserType(i?.node),
                        verticals: RemoveDuplicates([...i?.node?.verticalHeadEmployee?.edges?.map((v) => v?.node)].concat(i?.node?.verticalMembersEmployee?.edges.map((v) => v?.node))),
                        teams: RemoveDuplicates([...i?.node?.teamManagerEmployee?.edges.map((t) => t?.node)].concat(i?.node?.teamMembersEmployee?.edges.map((t) => t?.node))),
                        valid_upto: '12 Nov, 2024'
                    }
                })
                return [...verifiled_list, ...non_verifiled_list,...verifiled_list_heads,...non_verifiled_list_heads]
            })
            list = [].concat.apply([],list)
            list = sortData(list)
            if(filter?.page>1){
                list = data_list_ref.current?.concat(list)
            }
            var setObj = new Set();
            var result = list.reduce((acc, item) => {
              if (!setObj.has(item?.user_id)) {
                setObj.add(item?.user_id)
                acc.push(item)
              }
              return acc;
            }, [])
            if(filter?.verified_available){
                result = result.filter(i=>i?.verified == filter?.verified)
            }
            if(filter?.user_types?.length){
                result = result.filter(i=>filter?.user_types?.includes(i?.user_type))
            }
            setEmployeeList(sortData(result))
            SetPageInfo({
                type:"team_employee_data",
                has_next_page:next_pages?.find(i=>i?.hasNextPage)?true:false,
                page:filter?.page || 1
            })
            setEmployeeListLoading(false)
        }
    }
    // ******

    const AllemployeeList = async (filter) => {
        setEmployeeListLoading(true)
        const { data } = await client.query({
            query: ALL_EMPLOYEE_LIST_QUEY,
            variables: filter,
            fetchPolicy: 'network-only'
        });
        
        if (data) {
            let verifiled_list = data?.verified?.edges?.map(({ node }) => {
                return {
                    id: node.id,
                    user_id: node?.user?.id,
                    firstName: node?.user?.firstName,
                    lastName: node?.user?.lastName,
                    email: node?.user?.email,
                    active_status: node?.user?.isActive ? "Active" : "Inactive",
                    verified: true,
                    user_type: getUserType(node),
                    verticals: RemoveDuplicates([...node?.verticalHeadEmployee?.edges?.map(({ node }) => node)].concat(node?.verticalMembersEmployee?.edges.map(({ node }) => node))),
                    teams: RemoveDuplicates([...node?.teamManagerEmployee?.edges.map(({ node }) => node)].concat(node?.teamMembersEmployee?.edges.map(({ node }) => node))),
                    valid_upto: '12 Nov, 2024'
                }
            })
            let non_verifiled_list = data?.nonverified?.edges?.map(({ node }) => {
                return {
                    id: node.id,
                    user_id: node?.user?.id,
                    firstName: node?.user?.firstName,
                    lastName: node?.user?.lastName,
                    email: node?.user?.email,
                    active_status: node?.user?.isActive ? "Active" : "Inactive",
                    verified: false,
                    user_type: getUserType(node),
                    verticals: RemoveDuplicates([...node?.verticalHeadEmployee?.edges?.map(({ node }) => node)].concat(node?.verticalMembersEmployee?.edges.map(({ node }) => node))),
                    teams: RemoveDuplicates([...node?.teamManagerEmployee?.edges.map(({ node }) => node)].concat(node?.teamMembersEmployee?.edges.map(({ node }) => node))),
                    valid_upto: '12 Nov, 2024'
                }
            })
            let list = [...verifiled_list, ...non_verifiled_list]
            if(filter?.verified_available){
                list = list.filter(i=>i?.verified == filter?.verified)
            }
            if(filter?.user_types?.length){
                list = list.filter(i=>filter?.user_types?.includes(i?.user_type))
            }
            list = sortData(list)
            if(filter?.employee_verified_cursor || filter?.employee_nonverified_cursor){
                list = data_list_ref.current?.concat(list)
            }
            setEmployeeList(list)
            let has_next_page =false
            if(filter?.verified_available){
                if(filter?.verified){
                    has_next_page = data?.verified?.pageInfo?.hasNextPage
                }else{
                    has_next_page = data?.nonverified?.pageInfo?.hasNextPage
                }
            }else{
                has_next_page = data?.verified?.pageInfo?.hasNextPage || data?.nonverified?.pageInfo?.hasNextPage
            }
            SetPageInfo({
                type:"all_employee_data",
                has_next_page,
                employee_verified_cursor: data?.verified?.pageInfo?.endCursor,
                employee_nonverified_cursor: data?.nonverified?.pageInfo?.endCursor
            })
            setEmployeeListLoading(false)
        }
    }
    const RemoveDuplicates = (value) => {
        var setObj = new Set();
        var result = value?.reduce((acc, item) => {
            if (!setObj.has(item?.id)) {
                setObj.add(item?.id)
                acc.push(item)
            }
            return acc;
        }, [])
        return result
    }

    const user_type_detail = {
        "CEO":{
            order:1
        },
        "POC Admin":{
            order:2
        },
        "Vertical Head":{
            order:3
        },
        "Manager":{
            order:4
        },
        "Employee":{
            order:5
        }
    }

    const sortData=(list=[])=>{
        return list?.sort((a,b)=>user_type_detail[a?.user_type]?.order - user_type_detail[b?.user_type]?.order)
    }

    const getUserType = (employee) =>{
        if(employee?.orgCeoEmployee?.totalCount){
            return  "CEO" 
        }
        else if(employee?.orgPocEmployee?.totalCount){
            return  "POC Admin" 
        }
        else if(employee?.verticalHeadEmployee?.totalCount){
            return  "Vertical Head" 
        }
        else if(employee?.teamManagerEmployee?.totalCount){
            return  "Manager" 
        }else{
            return "Employee"
        }
        
    }

    const onPagination=(data)=>{
        if(data?.has_next_page){
            selectApiModel(filter_ref.current,data)
        }
        
    }

    const updateRow=(type,value)=>{
        let list = [].concat(employee_list)
        let user = list?.find(i=>i?.user_id == value?.user_id )
        if(user){
            if(type=="profile_update"){
                if(user){
                    user.firstName = value?.firstName
                    user.lastName = value?.lastName
                    user.email = value?.email
                }
            }
            else if(type=="active_status"){
                user.active_status = value?.active_status
            }
            else if(type=="delete_user"){
                list = list?.filter(i=>i?.user_id != value?.user_id)
            }

            setEmployeeList(list)
        }
        
    }

    return (
        <>
            <OrgEmployeeTableView employee_list={employee_list} emp_list_loading={emp_list_loading} {...props} page_info={page_info} onPagination={onPagination} updateRow={updateRow} total_users={total_users}/>
        </>
    )
}
export default compose(withCreateVertical, withApollo)(OrgEmployeeTableContainer)