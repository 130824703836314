
import { scape_report_permission } from "./ScapeReport.permission";
import { feedback_permission } from "./feedback.permission";
import { kudos_permission } from "./kudos.permission";
import { okr_permission } from "./okr.permission";
import { oneonone_permission } from "./oneonone.permission";


export const teams_dashboard_permission= {
    feedback_permission:feedback_permission?.requried_permission,
    kudos_permission:kudos_permission?.requried_permission,
    oneonone_permission:oneonone_permission?.requried_permission,
    scape_report_permission:scape_report_permission?.required_permission,
    award_badge_permission:kudos_permission?.can_award_badge,
    okr_permission:okr_permission?.requried_permission,
}