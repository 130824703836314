import { Button } from 'antd';
import _ from 'lodash';
import React from 'react';

import { compose } from '../../core';
import { getIntFromString, withMarkdownFieldValue } from '../../look';

import AddCommitmentView from '../components/AddCommitmentView';
import { CKEditorFieldName } from '../components/ToolFormComponent';

import { withAddCoachingCommitment } from '../../dashboard/containers/DashboardOperations';
import { withAddCommitment } from './ToolOperations';

const AddCommitment = props => {
  const { history, createCommitment, handleEdit, setSubmitLoading, createCoachingCommitment } = props;
  const [visible, setVisible] = React.useState(false);

  const handleSubmit = async (values, condition, coachingCommitment) => {
    setSubmitLoading(true);
    try {
      const response = await createCommitment({ commitmentData: withMarkdownFieldValue(CKEditorFieldName, values), condition });
      await Promise.all(
        coachingCommitment.map(async ({ node }) => {
          await createCoachingCommitment({
            coachingCommitmentData: _.omit(
              withMarkdownFieldValue(CKEditorFieldName, {
                ...node,
                commitmentId: getIntFromString(response.id)
              }),
              'index'
            )
          });
        })
      );
      response && handleEdit(getIntFromString(response.id), response);
      response && setVisible(false);
    } catch (e) {
      
      throw new Error(e.message);
    }
    setSubmitLoading(false);
  };

  return (
    <>
      <Button
        type={'primary'}
        onClick={() =>
          setVisible(true)
        } 
      >
        Add Commitment
      </Button>
      <AddCommitmentView onSubmit={handleSubmit} visible={visible} setVisible={setVisible} {...props} />
    </>
  );
};

export default compose( withAddCommitment, withAddCoachingCommitment)(AddCommitment);
