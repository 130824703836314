// @ts-nocheck
import { Button, Col, Input, Row, Spin, Tabs, Tooltip } from "antd";
import { MetaTags, displayUtcToUserTimezone, getIntFromString } from "modules/look";
import React from "react";
import Home_soul from '../../assets/home-icon-soul.svg'
import Right_arow from '../../assets/right-arrow-soul.svg'
import { useHistory } from "react-router-dom";
import Back_icon from '../../assets/soul-back.svg'
import RecivedAwadsContainer from "../container/RecivedAwadsContainer";
import IssuedAwardContainer from "../container/issuedAwardContainer";
import search from '../../assets/search.svg'
import back_to from '../../assets/back-to-badge.svg'
import { empoloyee } from "modules/organization-user-view/route";
import IssuedBadgeDetail from "./issuedBadgeDetailView";
import kudos_user_route from "../route";
import { idp_tam_icons } from "modules/IDP/idpIcons";
const UserProfileAwardTabView = (props) => {

  const { me, getReceientList, issuedAwardList, allRecepient, onSearchRecepient, permission, loading, deleteBadgeAward,location,navigateRoute } = props
  console.log("location",location.search)
  const [tab, setTab] = React.useState("received")
  const [search_award, setSearch] = React.useState()
  const [selectedAwardData, setSelectedAward] = React.useState()
  const [employeeList, setEmployeeList] = React.useState([])
  const [currentIndex, setCurrentIndex] = React.useState(0)
  const history = useHistory()

  const back = () => {
    history.push('/')
  }
  React.useEffect(() => {
    if (search_award) {
      if (tab === 'received') {
        onSearchRecepient({ recipient: getIntFromString(me?.employee?.user?.id), recipient_User_FirstName_Icontains: search_award, recipient_User_LastName_Icontains: search_award })
      }
    }
  }, [search_award])

  React.useEffect(() => {

    if (selectedAwardData?.badge) {
      getReceientList({ badge: selectedAwardData?.badge?.id, issuer: me?.employee?.id })
    }

  }, [selectedAwardData])


  React.useEffect(() => {
    if (issuedAwardList?.length && selectedAwardData) {
      console.log("issuedAwardList", issuedAwardList)
      console.log("selectedAwardData", selectedAwardData)
      let index = issuedAwardList.findIndex(object => {
        return object?.id === selectedAwardData?.id;
      });
      setCurrentIndex(index)
    }
  }, [issuedAwardList, selectedAwardData])


  React.useEffect(() => {
      let list = allRecepient?.map(item => item)
      console.log("list", list)
      setEmployeeList(list)
  }, [allRecepient])


  const employeeSearch = (value) => {
    if (value) {
      getReceientList({ badge: selectedAwardData?.badge?.id, issuer: me?.employee?.id, recipient_User_FirstName_Icontains: value, recipient_User_LastName_Icontains: value })
    }
    else {
      getReceientList({ badge: selectedAwardData?.badge?.id, issuer: me?.employee?.id })
    }
  }

  const PreviousBadge = (curent) => {
    if (curent > 0) {
      let index = currentIndex - 1
      setCurrentIndex(index)
      getReceientList({ badge: issuedAwardList[index]?.badge?.id, issuer: me?.employee?.id })
      setSelectedAward(issuedAwardList[index])
    }
  }

  const NextBadge = (curent) => {
    if (curent < issuedAwardList?.length - 1) {
      let index = currentIndex + 1
      setCurrentIndex(index)
      getReceientList({ badge: issuedAwardList[index]?.badge?.id, issuer: me?.employee?.id })
      setSelectedAward(issuedAwardList[index])
    }
  }

  return (
    <>
      <MetaTags
        title="Kudos"
      />
      {selectedAwardData? <div></div> : <div style={{ display: 'flex', justifyContent: "end", width: "100%", marginTop: tab === 'issued' ? '-20px' : "-20px" }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%',marginTop: '2em' }}>
          <div className="kudos-search-container" style={{ visibility: 'hidden' }}>
            <img className="kudos-search-icon" style={{ zIndex: 150 }} src={search} alt="" />

            <Input className="kudos-search-input" autoComplete='off' style={{ zIndex: 100 }} onChange={(e) => { setSearch(e?.target.value) }} />

          </div>
          {tab === 'issued' && permission?.can_award_badge && (permission?.one_on_one_permission || permission?.okr_permission || permission?.feedback_permission) && !selectedAwardData ? <Button className="give-kudos-btn" style={{ width: "fit-content", cursor: "pointer", zIndex: 100 }} onClick={() => history.push("/kudos/add/badge")} >GIVE KUDOS </Button> : <div style={{ width: "10px", height: "30px" }}></div>}
          <div className="kudos-search-container"style={{marginTop:'10px'}}>
            <img className="kudos-search-icon" style={{ zIndex: 150 }} src={search} alt="" />
            <Input className="kudos-search-input" autoComplete='off' style={{ zIndex: 100 }} onChange={(e) => { setSearch(e?.target.value) }} />
          </div>
        </div>
      </div>}
      {selectedAwardData ?
        <div className="kudos-root-class" style={{ paddingTop: "20x" }}>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "start", gap: "1em", alignItems: "center" }}>              
              <img src={idp_tam_icons?.back_arrow} alt="back_arrow" style={{ width: "25px", cursor: "pointer" }} onClick={() =>setSelectedAward()} />
              <h4 className="team-idp-main-head">Given Kudos Detail</h4>
          </div>
          <IssuedBadgeDetail {...props} selectedAwardData={selectedAwardData} loading={loading} employeeList={employeeList} employeeSearch={e => employeeSearch(e)} Oncancel={() => setSelectedAward()} />

          <Row justify="space-between" align="center" style={{ width: "100%" }}>
            <Tooltip title={currentIndex == 0 ? "No Badges Left" : ""}>
              <h4 className="issued-detail-nav-button" onClick={() => PreviousBadge(currentIndex)}>Previous Kudos</h4>
            </Tooltip>
            <Tooltip title={currentIndex == issuedAwardList?.length - 1 ? "No Badges Left" : ""}>
              <h4 className="issued-detail-nav-button" onClick={() => NextBadge(currentIndex)}>Next Kudos</h4>
            </Tooltip>
          </Row>
        </div>
        : <div className="kudos-fluid-container kudos-tab user-kudos-padding" style={{marginTop:"-5px"}}>
          <Tabs
            defaultActiveKey={tab}
            activeKey={tab}
            onChange={(e) => { setTab(e) }}
            style={{ marginTop: "-25px" }}
          >
            <Tabs.TabPane tab="Received" key="received" className="kudos-root-class">
              <RecivedAwadsContainer {...props} search_award={search_award} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Given" key="issued" className="kudos-root-class">
              <IssuedAwardContainer {...props} selectedAward={(e) => { setSelectedAward(e) }} search_award={search_award} />
            </Tabs.TabPane>

          </Tabs>
        </div>}
    </>
  )
}

export default UserProfileAwardTabView