// @ts-nocheck
import React from 'react';
import '../Css/callhandler.css'
import { Button } from "antd"
const CallEndState = props => {
    const { relateddocument, callstatus,resetToDefaulTab,end_call_message} = props
    console.log('end_call_message',end_call_message);
    return (
        <div className='call-state-container'>
            <div style={{width:"100%",height:"20px"}}></div>
            <h4 className='handler-info' style={{ fontSize: "1.2em" }}>{
            callstatus== 'Decline'?
            "Looks like the caller is not available right now. Let's try later again I guess":
            callstatus == 'Call_ended'?
            end_call_message||'I have ended your call. See you!'
            :''
            }</h4>
            <Button className='transcript-rcd-btn' onClick={()=>{resetToDefaulTab()}}>Go Back</Button>
        </div>
    )
}
export default CallEndState