import gql from 'graphql-tag';
import { TEAM_TYPE_CONNECTION } from './TeamTypeConnection.gql';

export const TEAMS_BY_VERTICAL_ID_QUERY = gql`
  ${TEAM_TYPE_CONNECTION}
  query Teams(
    $vertical: ID!
    $first: Int
    $offset: Int
    $orderBy: [String]
    $name_Icontains: String
    $description_Icontains: String
  ) # $organization: ID
  {
    Teams(
      vertical: $vertical
      first: $first
      offset: $offset
      orderBy: $orderBy
      name_Icontains: $name_Icontains
      description_Icontains: $description_Icontains
    ) # organization: $organization
    {
      ...TeamTypeConnection
    }
  }
`;


export const TEAMS_BY_VERTICAL_ID_QUERY_OPTUMIZED = gql`
  
  query Teams(
    $vertical: ID!
    $first: Int
    $offset: Int
    $orderBy: [String]
    $name_Icontains: String
    $description_Icontains: String
  ) # $organization: ID
  {
    Teams(
      vertical: $vertical
      first: $first
      offset: $offset
      orderBy: $orderBy
      name_Icontains: $name_Icontains
      description_Icontains: $description_Icontains
    ) # organization: $organization
    {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          id
          name
          description
        }
      }
      totalCount
      edgeCount
    }
  }
`;

