import React from 'react'
import { compose } from '../../core';
import {getIntFromString} from "../../look";
import { Row, Col, Button,Modal  } from "antd";
import { withUpdateDiscardRequest } from '../container/objective-keyresult-operations';
const DiscardActionView =(props)=>{
    const {discardData,visible,updateDiscardRequest,type,onClose}=props
    const [commentText,setComment]=React.useState()
    const commentValue=(e)=>{
        setComment(e.target.value)
    }

    const Discard = async(status)=>{
            let discardRequestData
            discardRequestData={
                okrId:getIntFromString(discardData?.id),
                initiatorsRational:commentText
            }
            const response =await updateDiscardRequest({ actorsComment:commentText,id:getIntFromString(discardData?.id),status:status})
            if(response?.id){
                onClose(false)
                // window.location.reload();
            }
    }

    const cancel =()=>{
        onClose(false)
    }

    return (
        <>
        <Modal
              footer={null}
              visible={visible}
              centered
              closable={false}
              destroyOnClose={true}
              className='custom-new-modal'
              style={{padding:"20px 10px"}}>
                <Col style={{position:'relative'}}>
                    <p className="modal-sub-head"style={{margin:0}}>Are you sure you want to  {type==='approve'&&<span style={{color:"#4CCB1F"}}>approve</span>} {type==='disapprove'&&<span style={{color:"#E86161"}}>disapprove</span>} discard request for <span>{discardData?.okr?.title}</span> ?</p>
                    <textarea className="modal-textarea" style={{marginTop:"20px"}} placeholder="Comment (Optional):" onChange={commentValue}></textarea>
                    <Row style={{width:"100%",gap:"20px",paddingTop:"20px"}} justify="center">
                        <Button className="modal-cancel-btn" onClick={()=>cancel()}>Cancel</Button>
                        {type==='approve'&&<Button className="modal-submit-btn" style={{background:"#4CCB1F",border:'1px solid #4CCB1F'}} onClick={()=>Discard('APPROVED')}>Approve</Button>}
                        {type==='disapprove'&&<Button className="modal-submit-btn" style={{background: '#E86161',border:'1px solid #E86161'}} onClick={()=>Discard('DISAPPROVED')}>Disapprove</Button>}
                    </Row>
                </Col>
            </Modal>
        </>
    )
}

export default compose(withUpdateDiscardRequest)(DiscardActionView)