// @ts-nocheck
import { Button, Form, Input, Select, message } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import { competency_icons } from '../icon';

const SubCompetencyCreateView = (props) => {
    const { onSubmit, competency_category_list, current_comptency_tab, onCancel, getCompetencyCategoryList, competency_type, must_have, createCompetencyCategory } = props
    const [modal_competency_must_have, SetModalCompetencyMustHave] = React.useState(true)
    const { Option } = Select;
    const [selected_category, SetSelectedCategory] = React.useState()
    const [new_category, SetNewCategory] = React.useState()
    const [selected_competency_type, SetSelectedCompetencyType] = React.useState()
    const title = {
        Generic: 'Add Generic competencies',
        Role_Specific: 'Add functional Competency'
    }
     
    let type = {
        ROLE_SPECIFIC:"Role_Specific",
        GENERIC:"Generic"
    }
    React.useEffect(()=>{
        if(selected_category){
            if(selected_category=='other'){
                SetSelectedCompetencyType(null)
                SetNewCategory(null)
            }else{
                let competency = competency_category_list?.find(i=>i?.id==selected_category)
                SetSelectedCompetencyType(type[competency?.competencyType])
                SetNewCategory(null)
            }
        }
    },[selected_category])

    const handleData = (value) => {
        value["competency_type"] = competency_type || selected_competency_type
        if(!value?.competency_type){
            message.error("Please select competency type")
            return
        }
        if (value?.competency_category == 'other' && (!new_category || new_category?.trim() == '')) {
            message.error("Please enter competency title")
        }
        else if (value?.competency_category == 'other') {
            createMainCompetency(value)
        }
        else {
            onSubmit(value)
        }
    }

    const createMainCompetency = async (value) => {
        let competencyCategoryData = {
            title: new_category,
            competencyType: value?.competency_type
        }
        let response = await createCompetencyCategory({ competencyCategoryData })
        if (response?.id) {
            let res = {
                ...value,
                competency_category: response?.id,
            }
            onSubmit(res)
        }
    }


    return (
        <>
            <div align="center" style={{ marginBottom: "3em" }}>
                <h4 className='competency-popup-title'>{title[competency_type] || 'Add Skill'}</h4>
            </div>
            {
                must_have && (
                    <div className="role-row-alignment" style={{ marginBottom: "1em" }}>
                        {/* must have radio btn */}
                        <div className="must-have-section" style={{ border: "1px solid #0098F0" }} onClick={() => SetModalCompetencyMustHave(true)}>
                            <h4 className="must-have-label" style={{ color: "#0098F0" }}>Must have</h4>
                            <div className={`must-have-parent ${modal_competency_must_have ? 'competency-blue' : ''}`}>
                                <div className={`must-have-child ${modal_competency_must_have ? 'competency-child-blue' : ''}`}></div>
                            </div>
                        </div>
                        {/* good to have radio btn */}
                        <div className="must-have-section" style={{ border: "1px solid #4CCB1F" }} onClick={() => SetModalCompetencyMustHave(false)}>
                            <h4 className="must-have-label" style={{ color: "#4CCB1F" }}>Good to have</h4>
                            <div className={`must-have-parent ${!modal_competency_must_have ? 'competency-green' : ''}`}>
                                <div className={`must-have-child ${!modal_competency_must_have ? 'competency-child-green' : ''}`}></div>
                            </div>
                        </div>
                    </div>
                )
            }
            <Form
                layout="vertical"
                onFinish={values => handleData({ ...values, modal_competency_must_have })}
                scrollToFirstError={true}
                className='rd-align-center rd-add-edit-competency-container'
                style={{ gap: '1em' }}
            >

                <div className='rd-input-container'>
                    <h4 className='rd-input-title'><span className='rd-input-star'>*</span> Title</h4>
                    <Form.Item
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: 'Please input title',
                            },
                        ]}
                        style={{ width: '100%' }}
                    >
                        <Input className="rd-text-input" placeholder='Enter title' />
                    </Form.Item>
                </div>

                <div className='rd-input-container'>
                    <h4 className='rd-input-title'> <span className='rd-input-star'>*</span> Competency</h4>
                    <Form.Item
                        name="competency_category"
                        rules={[
                            {
                                required: true,
                                message: 'Please select category',
                            },
                        ]}
                        style={{ width: '100%' }}

                    >
                        <Select
                            className="rd-select-filed rd-type-select"
                            style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                            disabled={current_comptency_tab?.competency_id ? true : false}
                            suffixIcon={<img src={competency_icons?.dropdown_Arrow} alt="" style={{ width: "10px", height: "10px" }} />}
                            showSearch
                            onSearch={(e) => getCompetencyCategoryList({ title_Icontains: e })}
                            placeholder='select competency'
                            filterOption={false}
                            onSelect={e => { SetSelectedCategory(e) }}
                            {...props}
                        >
                            {competency_category_list?.map(value => (
                                <Option
                                    className='rd-select-option'
                                    key={value?.id}
                                    value={value?.id}
                                >
                                    {value?.title}
                                </Option>
                            ))}
                            <Option
                                className='rd-select-option'
                                key={"other"}
                                value={"other"}
                            >
                                Other
                            </Option>
                        </Select>
                    </Form.Item>
                </div>

                {
                    selected_category == 'other' && (

                        <div className='rd-input-container'>
                            <h4 className='rd-input-title'><span className='rd-input-star'>*</span> Competency Title</h4>
                            <Input className="rd-text-input" value={new_category} onChange={(e) => SetNewCategory(e?.target?.value)} placeholder='Enter competency title' />
                        </div>
                    )
                }

                {
                    selected_category == 'other' && (

                        <div className='rd-input-container'>
                            <h4 className='rd-input-title'><span className='rd-input-star'>*</span> Type</h4>
                            <Select
                                className="rd-select-filed rd-type-select"
                                style={{ width: '100%', marginTop: "10px" }}
                                suffixIcon={<img src={competency_icons?.dropdown_Arrow} alt="" style={{ width: "10px", height: "10px" }} />}
                                placeholder='select competency type'
                                value={selected_competency_type}
                                onChange={(e) => { SetSelectedCompetencyType(e) }}>
                                <Select.Option
                                    className='rd-select-option'
                                    key={'Generic'}
                                    value={'Generic'}
                                >
                                    Generic Competency
                                </Select.Option>
                                <Select.Option
                                    className='rd-select-option'
                                    key={'Role_Specific'}
                                    value={'Role_Specific'}
                                >
                                    Functional competency(Role specific)
                                </Select.Option>
                            </Select>
                        </div>
                    )
                }

                <div className='rd-input-container'>
                    <h4 className='rd-input-title'>Description</h4>
                    <Form.Item
                        name="description"
                        style={{ width: '100%' }}
                    >
                        <TextArea name='description' className="rd-text-area" placeholder='Enter competency description' />
                    </Form.Item>
                </div>

                <div className='rd-confirmation-action-container'>
                    <Button className='global-submit-btn' htmlType="submit">Save</Button>
                    <Button className='global-cancel-btn' onClick={() => onCancel()}>Cancel</Button>
                </div>

            </Form>
        </>
    )

}

export default SubCompetencyCreateView