import React from 'react';
import { Button, Modal } from 'antd';
import { PlusSquareOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import TextSectionComponent from '../../text-section/containers/TextSectionComponent';
import FormSectionComponent from '../../form-section/containers/FormSectionComponent';

const AddComponent = props => {
  const { sequence, onSubmit, formSection } = props;
  const [visible, setVisible] = React.useState(false);

  return (
    <>
      {!(sequence || sequence === 0) ? (
        <Button type={'primary'} onClick={() => setVisible(true)}>
          Add Components
        </Button>
      ) : (
        <TargetedDiv align="center">
          <Button /* type={'primary'} */ onClick={() => setVisible(true)}>
            <PlusSquareOutlined /> Add Components
          </Button>
        </TargetedDiv>
      )}

      <Modal
        destroyOnClose
        centered
        visible={/* true || */ visible || window.location.href.includes('openmodal')}
        onCancel={() => setVisible(false)}
        // footer={null}
        title="Add Component"
        footer={null}
      >
        <TextSectionComponent
          btn={'add'}
          textSection={{ renderedText: 'Add', text: '' /* , condition: { edges: [{ node: {} }] } */ }}
          onSubmit={e => onSubmit(e, 'textsection')}
          sequence={sequence}
        />

        <br />
        <br />
        <FormSectionComponent
          btn={'add'}
          formSection={formSection}
          sequence={sequence}
          onSubmit={e => onSubmit(e, 'formsection')}
        />
      </Modal>
    </>
  );
};

export default AddComponent;

export const TargetedDiv = styled.div`
  opacity: 0;
  text-align: center;
  &:hover {
    opacity: 1;
  }
`;
