import React from "react";
import { Spin } from "antd";

import { compose } from "../../../core";
import { getIntFromString, ButtonsCatch } from "../../../look";

import EditFaqView from "./components/EditFaqView";
import ROUTE from "../../route";

import { withEditFaqCategory, withFaqCatagoryById } from "../PageOperations";

const EditFaq = props => {
  const { history, editFaqCatgory, faqCatagoryById } = props;
  const [submitLoading, setSubmitLoading] = React.useState(false);

  const handleSubmit = async (values, item) => {
    setSubmitLoading(true);
    try {
      const response = await editFaqCatgory({ id: getIntFromString(faqCatagoryById.id), ...values });
      response && ButtonsCatch(item, ROUTE.faq, history, item === undefined ? window.location.pathname : `${ROUTE.addFaq}`);
    } catch (e) {
      throw new Error(e.message);
    }
    setSubmitLoading(false);
  };

  return (
    <Spin spinning={submitLoading} size="large">
      <EditFaqView onSubmit={handleSubmit} {...props} />
    </Spin>
  );
};

export default compose(withFaqCatagoryById, withEditFaqCategory)(EditFaq);
