import gql from 'graphql-tag';


export const RATING_FEEDBACK_MUTATION = gql`
  mutation createMeetingFeedback($meetingFeedback: MeetingFeedbackInput!) {
    createMeetingFeedback(meetingFeedback:$meetingFeedback) {
        meetingFeedback{
            id
        }
    }
  }
`;