import gql from 'graphql-tag';
import { TEAM_TYPE } from './TeamType.gql';

export const EDIT_TEAM = gql`
  ${TEAM_TYPE}
  mutation editTeam($description: String, $employees: [Int], $id: ID!, $managers: [Int], $name: String, $verticalId: Int) {
    updateTeam(
      description: $description
      employees: $employees
      id: $id
      managers: $managers
      name: $name
      verticalId: $verticalId
    ) {
      team {
        ...TeamType
      }
    }
  }
`;
