// @ts-nocheck
import React from "react";
import UserProfileAwardTabView from "../component/userAwardsTabView";
import { getIntFromString, globalPermissionValidator, permissionValidation } from "modules/look";
import { ISSUED_AWARD_LIST } from "../graphql/issuedAwardList.gql";
import { compose } from "modules/core";
import { withApollo } from '@apollo/client/react/hoc';
import { ALL_RECEPIENT_LIST, ALL_RECEPIENT_LIST_SUBSCRIPTION } from "../graphql/allRecepientQuery.gql";
import { BADGE_AWAED_ADD_USER_PERMISSION, BADGE_AWAED_DELETE_USER_PERMISSION, BADGE_AWARD_VIEW_PERMISSION, VIEW_FEEDBACK, VIEW_MEETING, VIEW_OKR } from "../../../config";
import { withDeleteBadgeAward } from "./badgeAwardOperation";
import existing_no_data from '../../assets/existing-no-data.svg'
import { BADGE_AWARD_SUBSCRIPTION } from "../graphql/badgeAwardSubscription.gql";
import { Button, Col } from "antd";
import { useHistory } from "react-router-dom";
import NoPermissionView from "../component/NoPermissionComponent";
import { kudos_permission } from "Permissions/kudos.permission";
const UseAwardTabContainer = (props) => {
  const { client, userPermission, me, permissionLoader } = props
  const [loading, setLoading] = React.useState(false)
  const [issuedAwardList, setIssuedAwardList] = React.useState()
  const [allRecepient, setAllRecepients] = React.useState()
  const [permission, setpermission] = React.useState()
  const dataRef = React.useRef()
  const m = React.useRef(true)

  let badgeAwardSub = undefined

  React.useEffect(() => {
    return () => {
      if (badgeAwardSub) {
        badgeAwardSub.unsubscribe();
      }
    }
  })

  React.useEffect(() => {
    return () => {
      m.current = false
    }
  }, [])

  React.useEffect(() => {
    m.current = true

  }, [])


  const getBadgeFamilyList = async (filterValue) => {

    setLoading(true)
    const { data } = await client.query({
      query: ISSUED_AWARD_LIST,
      variables: { ...filterValue },
      fetchPolicy: "network-only"
    });
    if (data) {
      setIssuedAwardList(data?.badgeAwards?.edges?.map(({ node }) => node))
      setLoading(false)
    }
  }

  const getReceientList = async (filterValue) => {
    setLoading(true)
    const { data } = await client.query({
      query: ALL_RECEPIENT_LIST,
      variables: { ...filterValue },
      fetchPolicy: "network-only"
    });
    if (data) {
      let mergeData = data?.firstName?.edges?.map(({ node }) => node).concat(data?.lastName?.edges?.map(({ node }) => node))
      var setObj = new Set();
      var result = mergeData.reduce((acc, item) => {
        if (!setObj.has(item?.id)) {
          setObj.add(item?.id)
          acc.push(item)
        }
        return acc;
      }, [])
      setAllRecepients(result)
      dataRef.current = result
    }
    badgeAwardSub = client.subscribe({
      query: ALL_RECEPIENT_LIST_SUBSCRIPTION,
      variables: filterValue,
      fetchPolicy: "network-only"
    }).subscribe({
      next(result) {
        let receivedBadgeCacheData = dataRef.current
        switch (result.data.badgeAwardSubscription.mutation) {
          case 'CREATE':
            const updated = [...receivedBadgeCacheData, result?.data?.badgeAwardSubscription?.badgeAwardSubscription]
            if (m.current) {
              setAllRecepients(updated)
            }
            dataRef.current = updated
            break
          case 'UPDATE':
            const updateRsponse = receivedBadgeCacheData.map(item =>
              item?.id === result?.data?.badgeAwardSubscription?.badgeAward?.id
                ? result?.data?.badgeAwardSubscription?.badgeAward
                : item
            )
            if (m.current) {
              setAllRecepients(updateRsponse)
              dataRef.current = updateRsponse
            }
            break
          case 'DELETE':

            const deleteData = receivedBadgeCacheData.filter(item =>
              item?.id === result?.data?.badgeAwardSubscription?.badgeAward?.id
                ? false
                : true
            )
            if (m.current) {
              setAllRecepients(deleteData)
              dataRef.current = deleteData
            }
            break
          default:
            break
        }
      }

    })
    setLoading(false)
  }

  React.useEffect(() => {
    if (userPermission?.length) {
      let permission = globalPermissionValidator(kudos_permission, userPermission)

      setpermission(permission)
    }
    else {
      setpermission(null)
    }
  }, [userPermission])
  React.useEffect(() => {
    if (me?.employee?.user?.id) {
      getBadgeFamilyList({ issuer: me?.employee?.id })
    }
  }, [me])

  return (
    <>
      {
        permission && permission?.can_award_badge &&
        <UserProfileAwardTabView {...props} allRecepient={allRecepient} issuedAwardList={issuedAwardList} getReceientList={(e) => getReceientList(e)} onSearchRecepient={e => getReceientList(e)} permission={permission} loading={loading} />
      }

      {

        permission && !permission?.can_award_badge &&
        <NoPermissionView />
      }



    </>
  )
}

export default compose(withApollo, withDeleteBadgeAward)(UseAwardTabContainer)