import { Select, Spin } from 'antd';
import { getIntFromString } from 'modules/look';
import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import '../css/BarsDetail.css';
import { Bars_Detail_Icons, Bars_Home_Icons } from '../Icon';

const BarsDetailVew = (props) => {

    const { main_competecny_employee_level,
        main_competecny_behavior,
        getBehaviourAnchorListByIdMinCompetency,
        loading, main_competecny_loading,
        sub_competecny_employee_level,
        getBehaviourAnchorListByIdSubCompetecny,
        subCompetecny_behaviour,
        sub_competecny_loading } = props
    const { Option } = Select;
    const history = useHistory()
    const [selected_main_employee, setSelectMainEmployee] = React.useState()
    const [selected_sub_competency, setSelectedSubCompetency] = React.useState()
    const [selected_sub_competency_employe, setSelectedSubCompetency_employee] = React.useState()

    
    React.useEffect(()=>{
        window.scrollTo(0,0);
    },[])

    
    React.useEffect(() => {
        if (main_competecny_employee_level?.main_competency_employee_levels) {
            setSelectMainEmployee(main_competecny_employee_level?.main_competency_employee_levels[0]?.id)
        }
    }, [main_competecny_employee_level])

    React.useEffect(() => {
        if (sub_competecny_employee_level?.sub_competency) {
            setSelectedSubCompetency(sub_competecny_employee_level?.sub_competency[0]?.id)
        }
    }, [sub_competecny_employee_level])

    React.useEffect(() => {
        if (sub_competecny_employee_level?.sub_competency_employee_level) {
            setSelectedSubCompetency_employee(sub_competecny_employee_level?.sub_competency_employee_level[0]?.id)
        }
    }, [sub_competecny_employee_level])

    return (
        <div>
            <Spin spinning={loading}>

                <div className="bar-detail-heading-container" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', position: 'relative', margin: '0em 1em' }}>
                    <img onClick={(e) => history.goBack()} src={Bars_Home_Icons?.new_back_arrow} alt='' style={{ cursor: "pointer" }} />
                    <p className="bar-detail-title">BARS for Functional Competency : {main_competecny_employee_level?.main_competency?.title} </p>
                    <div></div>
                </div>

                {/* Main competecny thershold listing */}
                <div className='bar-detail-container-seaction'>
                    <Select
                        className="bar-select-filed bar-type-select"
                        style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                        suffixIcon={<img src={Bars_Detail_Icons?.dropdown_Arrow} alt="" style={{ width: '15px' }} />}
                        value={selected_main_employee}
                        dropdownClassName="content-dropdown"
                        onSelect={e => { setSelectMainEmployee(e); getBehaviourAnchorListByIdMinCompetency({ employeeLevel: e }) }}
                        {...props}
                    >
                        {main_competecny_employee_level?.main_competency_employee_levels?.map(value => (
                            <Option
                                className='rd-select-option'
                                key={value?.id}
                                value={value?.id}
                            >
                                {value?.name}
                            </Option>
                        ))}
                    </Select>
                    <Spin spinning={main_competecny_loading}>
                        <div className="bar-raing-scale-container">
                            {main_competecny_behavior?.rating_scale_with_employee_level?.length > 0 ? (main_competecny_behavior?.rating_scale_with_employee_level?.map(item => (
                                <div className="bar-rating-card-container">
                                    <div className="bar-rating-card">
                                        <h4 className='bar-rating-scale-title'>{item?.title}</h4>
                                        <p className='bar-rating-scale-description'>
                                            {item?.behaviourExample}
                                        </p>
                                    </div>
                                </div>
                            ))) : <div style={{ width: "100%", marginTop: '5em' }}>
                                <h4 className='no-data'>No Data Found</h4>
                            </div>}
                        </div>
                    </Spin>

                    {/* Sub competecny thershold listing */}
                    {sub_competecny_employee_level?.sub_competency?.length > 0 && <div style={{ width: "100%" }}>

                        <div className="bar-sub-title-container">
                            <p className="bar-detail-title" style={{ textAlign: "center" }}>Sub Competency</p>
                        </div>

                        <div className='bar-detail-container-seaction'>
                            <div className='bar-tab-container-seaction' style={{ width: '100%' }}>
                                <div className='bar-tab-container'>
                                    {
                                        sub_competecny_employee_level?.sub_competency?.map((item) => (
                                            <h4 className='bar-tab-title' style={{ color: getIntFromString(item?.id) == getIntFromString(selected_sub_competency) ? '#000' : '#8C8CA1' }} onClick={() => { setSelectedSubCompetency(item?.id); getBehaviourAnchorListByIdSubCompetecny({ employeeLevel: selected_sub_competency_employe, comp_id: item?.id }) }}>{item?.title}</h4>
                                        ))
                                    }
                                </div>
                            </div>

                            {
                                sub_competecny_employee_level?.sub_competency_employee_level?.length ?
                                    <Select
                                        className="bar-select-filed bar-type-select"
                                        style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                                        suffixIcon={<img src={Bars_Detail_Icons?.dropdown_Arrow} alt="" style={{ width: '15px' }} />}
                                        value={selected_sub_competency_employe}
                                        onSelect={e => { setSelectedSubCompetency_employee(e); getBehaviourAnchorListByIdSubCompetecny({ employeeLevel: e, comp_id: selected_sub_competency }) }}
                                        dropdownClassName="content-dropdown"
                                        {...props}
                                    >
                                        {sub_competecny_employee_level?.sub_competency_employee_level?.map(value => (
                                            <Option
                                                className='rd-select-option'
                                                key={value?.id}
                                                value={value?.id}
                                            >
                                                {value?.name}
                                            </Option>
                                        ))}
                                    </Select> : null
                            }

                        </div>
                        <Spin spinning={sub_competecny_loading}>
                            <div className="bar-raing-scale-container">
                                {subCompetecny_behaviour?.rating_scale_with_employee_level?.length > 0 ? (subCompetecny_behaviour?.rating_scale_with_employee_level?.map(item => (
                                    <div className="bar-rating-card-container">
                                        <div className="bar-rating-card">
                                            <h4 className='bar-rating-scale-title'>{item?.title}</h4>
                                            <p className='bar-rating-scale-description'>
                                                {item?.behaviourExample}
                                            </p>
                                        </div>
                                    </div>
                                ))) : <div style={{ width: "100%", marginTop: '5em' }}>
                                    <h4 className='no-data'>No Data Found</h4>
                                </div>}
                            </div>
                        </Spin>
                    </div>}
                </div>
            </Spin>
        </div>
    )
}

export default BarsDetailVew;