import gql from 'graphql-tag';
import { RESPONSE_COMPARE_REQUEST_TYPE } from './ResponseCompareRequestType.gql';

export const ADD_RESPONSE_COMPARE_REQUEST = gql`
  ${RESPONSE_COMPARE_REQUEST_TYPE}
  mutation createResponseCompareRequest($compareRequestData: ResponseCompareRequestInput!) {
    createResponseCompareRequest(compareRequestData: $compareRequestData) {
      compareRequest {
        ...ResponseCompareRequestType
      }
    }
  }
`;
