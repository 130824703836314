// @ts-nocheck
import React from 'react';
import { compose } from '../../core';
import { getOKRById, getUserRole, withCreateContributor, withCreateOKR, withDeleteDiscardRequest, withDeleteOKR, withEntityHeadRoles, withUpdateCorrectionDeadline, withUpdateOKR } from './objective-keyresult-operations';
import { withApollo } from '@apollo/client/react/hoc';
import { OKR_LIST_QUERY } from '../graphql/OKRList.gql';
import { OKR_CHILD_LIST_QUERY } from '../graphql/ChildOKRList.gql';
import KeyResultDetailView from '../components/KeyResultDetailView';
import { getBase64StringFromInt, getIntFromString, getOKRBase64StringFromInt, globalPermissionValidator, replaceAITipDynamicContent } from 'modules/look';
import { ALL_COMPETENCY, COMPETENCY_PERMISSIONS } from 'modules/role/graphql/allCompitency.gql';
import MilestoneDetailView from '../components/MilestoneDetailView'
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { OKR_COMPETENCIES } from '../graphql/OkrCompetencies.gql';
import { withMe } from 'modules/user/containers/UserOperations';
import { OKR_BY_ID_QUERY } from '../graphql/OKRById.gql';
import { GET_ENTITY_HEAD_ROLE } from '../graphql/ExtendCorrectionDeadline.gql';
import { USER_ROLE } from '../graphql/UserRoles.gql';
import { okr_permission } from 'Permissions/okr.permission';
import NoPermissionView from 'modules/kudos-user/component/NoPermissionComponent';
import { okr_competency_permission } from 'Permissions/okrCompetency.permission';
import { DISCARD_SUBSCRIPTION_OKR_DETAILS } from '../graphql/DiscardSubscriptionOkr.gql';
import { ai_trigger_content } from 'modules/ai-tips/ai-trigger-content';
import moment from 'moment';
import OkrAiTipContainer from 'modules/ai-tips/container/okr-ai-tip-container';
const MilestonDetailContainer = props => {
  const { me, client, userPermission, permissionLoader } = props
  const [loading, setloading] = React.useState(true)
  const [allCompetencies, setAllCompetencies] = React.useState();
  const [allMs, setallMs] = React.useState([]);
  const [withAllCompetency, setwithAllCompetency] = React.useState()
  const [comptenciesModalVisible, setCompetenciesModalVisible] = React.useState(false)
  const [okrCompetenies, setOkrCompetencies] = React.useState();
  const [deletedCompetencies, setDeletedCompetencies] = React.useState();
  const [entityHeadRoles, setEntityHEadRoles] = React.useState();
  const [addedCompetencies, setAddedCompetencies] = React.useState();
  const [okrPermitted, setOkrPermitted] = React.useState({});
  const [user_level, setUserLevel] = React.useState();
  const [compPermissions, setCompetencyPermissions] = React.useState({});
  const [okrById, setokrById] = React.useState();
  const [ai_trigger, SetAITrigger] = React.useState()
  React.useEffect(() => {
    if (me && props.match.params && okrPermitted) {
      getMSList(props.match.params.okrId)
    }
  }, [me, okrPermitted])

  React.useEffect(() => {
    if (me && props.match.params && okrPermitted) {
      getOkrCompetencies(props.match.params.okrId)
    }
  }, [me, props, okrPermitted])


  React.useEffect(() => {
    if (me && okrPermitted) {
      getUserRole()
    }
  }, [me, okrPermitted])


  React.useEffect(() => {
    if (userPermission && !permissionLoader) {
      let okrPermission = globalPermissionValidator(okr_permission, userPermission)
      setOkrPermitted(okrPermission);
      let okrcompetencyPermission = globalPermissionValidator(okr_competency_permission, userPermission)
      setCompetencyPermissions(okrcompetencyPermission);
    }
  }, [userPermission, permissionLoader])


  React.useState(() => {
    if (compPermissions?.required_permission && okrPermitted?.required_permission) {
      competencyList()
    }
  }, [compPermissions, okrPermitted])


  const getUserRole = async () => {

    const { data } = await client.query({
      query: USER_ROLE,
      fetchPolicy: 'network-only',
    });
    if (data?.user_level) {
      setUserLevel(data?.user_level)
    }
  }



  const getMSList = async (okrId) => {
    let krId = getBase64StringFromInt('okrType', okrId);
    const { data } = await client.query({
      query: OKR_CHILD_LIST_QUERY,
      fetchPolicy: 'network-only',
      variables: { okr_Parent: krId, createdBy: me?.employee?.id }
    });
    if (data) {
      setallMs(data?.contributors);
    }
  }





  const competencyList = async (search) => {

    const { data } = await client.query({
      query: ALL_COMPETENCY,
      variables: { title_Icontains: search },
      fetchPolicy: 'network-only'
    });

    if (data.allCompetencies) {
      setwithAllCompetency(data.allCompetencies)
    }
  }

  const getOkrCompetencies = async (id) => {
    let krId = getBase64StringFromInt('okrType', id);
    const { data } = await client.query({
      query: OKR_COMPETENCIES,
      fetchPolicy: 'network-only',
      variables: { id: krId }
    });
    if (data) {
      setOkrCompetencies(data?.okrById?.okrcompetencySet?.edges);
    }
  }


  const CREATE_COMPETENCY_DYNAMIC = gql`  
mutation createOkrCompetency {
${addedCompetencies?.map(
    (item, index) =>
      `create${index}: createOkrCompetency(okrCompetencyData:{okrId:${props.match.params.okrId},competencyId:${getIntFromString(item?.competency?.id)}}) {
      okrCompetency{
        id
      }
     }
   `
  ).join('')}
}
`;


  const [competencyDefinition, { data, isloading, error }] = useMutation(CREATE_COMPETENCY_DYNAMIC);

  React.useEffect(() => {

    if (addedCompetencies && addedCompetencies?.length) {
      createcompetencyMutation()
    }
  }, [addedCompetencies])

  const createcompetencyMutation = async () => {
    const response = await competencyDefinition()
    // new to do send back the response as props
    if (response) {

    }
  }





  const DELETE_COMPETENCIES = gql`  
mutation deleteOkrCompetency {
${deletedCompetencies?.map(
    (item, index) =>
      `delete${index}: deleteOkrCompetency(id:"${getIntFromString(item?.id)}") {
      okrCompetency{
        id
      }
     }
   `
  ).join('')}
}
`;


  const [deletecompetencyDefinition, { }] = useMutation(DELETE_COMPETENCIES);

  React.useEffect(() => {
    if (deletedCompetencies && deletedCompetencies?.length) {
      deletecompetencyMutation()
    }
  }, [deletedCompetencies])

  const deletecompetencyMutation = async () => {
    const response = await deletecompetencyDefinition()
    // new to do send back the response as props
    if (response) {
      console.log('got response:', response);
    }
  }
  const okrById_ref = React.useRef()

  React.useEffect(() => {
    if (okrById && me) {
      okrById_ref.current = okrById
      if (okrById?.owner?.user?.employee?.id == me?.employee?.id) {
        withEntityHeadRoles({ user: me?.id, organizationid: me?.employee?.organizationSet?.edges[0]?.node?.id })
      }
    }
  }, [okrById, me])

  const OKR_BY_ID = async () => {
    const { data } = await client.query({
      query: OKR_BY_ID_QUERY,
      fetchPolicy: 'network-only',
      variables: { id: getOKRBase64StringFromInt(props.match.params.okrId), createdBy: me?.employee?.id }
    });

    if (data?.okrById) {
      MileStoneDiscardSub()
      setokrById(data?.okrById);
      let detail = data?.okrById
      let trigger = {
        trigger: ai_trigger_content.okr_summary,
        dynamic_data: [detail?.goalStatement, moment(detail?.duedate).format("MMMM D, YYYY"), detail?.progress, detail?.progress < 100 ? "Assist to complete this okr before due date" : " "]
      }
      SetAITrigger({ ...trigger })
    }
  }

  let MilestoneDetailSub = undefined;
  React.useEffect(() => {
    return () => {
      if (MilestoneDetailSub) {
        MilestoneDetailSub.unsubscribe();
      }
    }
  }, [])

  const MileStoneDiscardSub = async () => {
    MilestoneDetailSub = await client.subscribe({
      query: DISCARD_SUBSCRIPTION_OKR_DETAILS,
      // variables: { id: discardedOkrId },
      fetchPolicy: 'network-only'
    }).subscribe({
      next(result) {
        switch (result.data?.discardRequestSubscription?.mutation) {
          case 'CREATE':
            let discarded_okr = result.data?.discardRequestSubscription?.discardRequest?.okr
            if (okrById_ref.current?.id === discarded_okr?.id) {
              okrById_ref.current.discardRequestRaised.totalCount = 1
              okrById_ref.current.pending = { totalCount: okrById_ref.current.pending?.totalCount + 1, edges: [{ ...okrById_ref.current.pending?.edges, node: result.data?.discardRequestSubscription?.discardRequest }] }
              setokrById({ ...okrById_ref.current })
            }


            break
          case 'UPDATE':

            break
          case 'DELETE':
            let cancel_discarded_okr = result.data?.discardRequestSubscription?.discardRequest?.okr
            if (okrById_ref.current?.id === cancel_discarded_okr?.id) {
              okrById_ref.current.discardRequestRaised.totalCount = 0
              okrById_ref.current.pending = { totalCount: 0, edges: [] }
              setokrById({ ...okrById_ref.current })
            }
            break
          default:
            break
        }
      }
    })
  }


  React.useEffect(() => {
    if (props.match.params.okrId && me) {
      OKR_BY_ID()
    }
  }, [me, props.match.params.okrId])

  const withEntityHeadRoles = async (filter) => {
    const { data } = await client.query({
      query: GET_ENTITY_HEAD_ROLE,
      fetchPolicy: 'network-only',
      variables: filter
    });

    if (data?.entityHeadRoles) {
      let manager_detail = data?.entityHeadRoles?.employee?.organizationSet?.edges[0]?.node?.employees?.edges[0]?.node

      if (manager_detail && (manager_detail?.orgCeoEmployee?.totalCount || manager_detail?.orgPocEmployee?.totalCount || manager_detail?.teamManagerEmployee?.totalCount || manager_detail?.verticalHeadEmployee?.totalCount))
        setEntityHEadRoles({ isallowed_role: true });
    }

  }

  return (
    <>
      <div>
        {
          !permissionLoader ?
            <div>
              {
                (okrPermitted?.requried_permission) ?
                  <>


                    {
                      (okrPermitted && okrPermitted?.runpod_permission) && (
                        <OkrAiTipContainer {...props} ai_trigger={ai_trigger}
                          SetAITrigger={SetAITrigger} />
                      )
                    }
                    <MilestoneDetailView loading={loading} setloading={(e) => setloading(e)}
                      searchCompetency={(e) => { competencyList(e) }}
                      withAllCompetency={withAllCompetency}
                      setAllCompetencies={setAllCompetencies}
                      setDeletedCompetencies={setDeletedCompetencies}
                      setCompetenciesModalVisible={setCompetenciesModalVisible}
                      okrCompetenies={okrCompetenies}
                      okrById={okrById}
                      user_level={user_level}
                      compPermissions={compPermissions}
                      entityHeadRoles={entityHeadRoles}
                      refetchOkr={(e) => { OKR_BY_ID() }}
                      {...props} />
                  </>
                  :
                  <NoPermissionView
                  />
              }
            </div>
            :
            null
        }
      </div>

    </>
  );
};

export default compose(withApollo, withCreateOKR, withDeleteOKR, withUpdateOKR, withDeleteDiscardRequest, withMe, withUpdateCorrectionDeadline)(MilestonDetailContainer);
