import React from 'react';
import { Form, DatePicker } from 'antd';
import moment from 'moment';
const { RangePicker } = DatePicker;
const RangeDatePicker = props => {
  const { onChange, disabled,onBlur,dateFormat,disabledRange,className,defaultValue,dropdownClassName,...rest } = props;

  const FormItem = Form.Item;

  return (

      <FormItem  {...rest}>
         <RangePicker
            defaultValue={defaultValue}
            className={className||'range-date-picker'}
            style={{width:"100%"}}
            dropdownClassName = {dropdownClassName||''}
            allowClear={false}
            format={dateFormat||'DD/MM/YY'}
            disabledDate={current => {
               return disabledRange? current > moment(disabledRange?.duedate) ||  current < moment(disabledRange?.startdate).subtract(1, "day"):current.isBefore(moment().subtract(1,"day")) ;
             }}
         />
      </FormItem>

  );
};

export default RangeDatePicker;

