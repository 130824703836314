import gql from 'graphql-tag';
// import { GROUP_TYPE } from './GroupType.gql';

//  ${GROUP_TYPE}
export const ALL_GROUPS_FOR_SURVEY_BY_SURVEY_ID_QUERY = gql`
  query allGroupsForSurveyBySurveyId(
    $first: Int
    $offset: Int
    $orderBy: [String]
    $id: ID!
    $name_Icontains: String
    $label_Icontains: String
    $helpText_Icontains: String
    $sequence_Icontains: Int
  ) {
    allGroupsForSurveyBySurveyId(
      first: $first
      offset: $offset
      orderBy: $orderBy
      id: $id
      name_Icontains: $name_Icontains
      label_Icontains: $label_Icontains
      helpText_Icontains: $helpText_Icontains
      sequence_Icontains: $sequence_Icontains
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          # ...GroupType
          id
          name
          label
          helpText
          sequence
          matrix
          showInResult
          showAvgInResult
        }
      }
      totalCount
      edgeCount
    }
  }
`;
