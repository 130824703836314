import React from "react";
import SoulHomeLearningView from "../components/soul-home-view";
import '../../css/soul-learn.css'
import { getQuestionBase64StringFromInt, getSurveyBase64StringFromInt } from "modules/look";
import { COMPLETE_LEARNING_SOUL_SURVEY } from "modules/learning-path/graphql/learnging-query.gql";
import { VALUE_SUERVEY_PART_1,STRENGTH_PART_1,PERSONALITY_PART_1,KNOWLEDGE_PART_1,diversity_survey_id,diversity_questions_for_in } from "../../../../config";
import { compose } from "modules/core";
import { withApollo } from '@apollo/client/react/hoc';
const SoulHomeLearningContainer =(props)=>{
    const {client,me,navigateRoute}=props
    const [survey_statuses,setSurveyStatuses]=React.useState()
    const [page_loading,setPageLoading]=React.useState(false)
    React.useEffect(()=>{
        if (me) {
            checkForImpactNarrative()
        }
    },[me])

    const checkForImpactNarrative = async () => {
        setPageLoading(true)
        try {
           
            const { data } = await client.query({
                query: COMPLETE_LEARNING_SOUL_SURVEY,
                variables: {
                    user: me?.id,
                    value: getSurveyBase64StringFromInt(VALUE_SUERVEY_PART_1),
                    strength: getSurveyBase64StringFromInt(STRENGTH_PART_1),
                    personality: getSurveyBase64StringFromInt(PERSONALITY_PART_1),
                    knowledge: getSurveyBase64StringFromInt(KNOWLEDGE_PART_1),
                    diversity: getSurveyBase64StringFromInt(diversity_survey_id),
                    gender: getQuestionBase64StringFromInt(diversity_questions_for_in?.gender),
                    HighestDegree: getQuestionBase64StringFromInt(diversity_questions_for_in?.HighestDegree),
                    YearsOfExperience: getQuestionBase64StringFromInt(diversity_questions_for_in?.YearsOfExperience),
                    CurrentNationality: getQuestionBase64StringFromInt(diversity_questions_for_in?.CurrentNationality)
                },
                fetchPolicy: 'network-only'
            });

            if (data) {
                let diversity = data?.diversity?.edges[0]?.node
                let diversity_completed = false
                if (diversity && diversity?.CurrentNationality?.totalCount && diversity?.HighestDegree?.totalCount && diversity?.YearsOfExperience?.totalCount && diversity?.gender?.totalCount) {
                    diversity_completed = true
                }
                let detail = {
                    diversity_profile: {
                        totalCount: diversity_completed ? 1 : 0
                    },
                    value:data?.Values,
                    strengths:data?.Strength,
                    personality:data?.Personality,
                    knowledge_skills:data?.knowledge,
                    impact_narrative:data?.impact_narrative
                }
                setSurveyStatuses(detail)
                setPageLoading(false)
            }
        } 
        catch (error){
            console.log(error)
            setPageLoading(false)
        }
    }
    return(
        <>
        <SoulHomeLearningView 
        page_loading={page_loading}
         survey_statuses={survey_statuses}
          {...props}
          getSurveyStatus={()=>checkForImpactNarrative()}/>
        </>
    )
}

export default compose(withApollo)(SoulHomeLearningContainer);