import gql from 'graphql-tag';

export const LOOK_WEIGHTED_QUESTION_STATE_QUERY = gql`
  query lookWeightedQuestionState {
    lookWeightedQuestionState @client {
      filter {
        question_QuestionText_Icontains
      }
      orderBy
    }
  }
`;
