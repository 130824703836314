// @ts-nocheck
import React from 'react';
import { Button, Form, Input, Modal, Spin, message } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { jobfamily_icons } from '../icon';
import MultiSelectFormDropdown from 'modules/look-v2/Components/MultiSelectFormDropdown';
import { getIntFromString } from 'modules/look';
import SubCompetencyAddContainer from '../containers/AddSubCompetencyContainer'

const JobFamilyAddEditView = (props) => {

    const { onCancel, onSubmit, submit_loader, role_list, getRoleList, generic_competency_list, genericCompetencyList, jobfamily_detail, SetGenericCompetencyList, setCurrentTab } = props
    const [initial_values, setInitialValues] = React.useState();
    const [selected_roles, SetSelectedRoles] = React.useState([])
    const [role_row_expanded, SetRoleExpanded] = React.useState([])
    const [selected_generic_competency, SetSelectedGenericCompetency] = React.useState([])
    const [competency_row_expanded, SetCompetencyExpanded] = React.useState([])
    const [add_competency, setAddCompetency] = React.useState(false)
    const [form] = Form.useForm();

    React.useEffect(() => {
        if (!initial_values && jobfamily_detail) {
            setInitialValues(jobfamily_detail)
            if (jobfamily_detail?.role) {
                SetSelectedRoles(jobfamily_detail?.role)
            }

            if (jobfamily_detail?.generic_competency) {
                SetSelectedGenericCompetency(jobfamily_detail?.generic_competency)
            }
        }
    }, [jobfamily_detail]);

    // to expand each row of the table
    const expandRow = (id, type) => {
        if (type === 'role') {
            if (role_row_expanded?.includes(id)) {
                SetRoleExpanded([])
            } else {
                SetRoleExpanded([id])
            }
        }
        else if (type === 'competency') {
            if (competency_row_expanded?.includes(id)) {
                SetCompetencyExpanded([])
            } else {
                SetCompetencyExpanded([id])
            }
        }
    }

    // to change the selected role and competency
    const changeDropdownSelection = (data, type) => {
        if (type === 'role') {
            let list = selected_roles.concat([])
            let doc = list?.find(item => item?.id === data?.id)
            if (doc) {
                list = list.filter(item => item?.id != data?.id)
            } else {
                list.push(data)
            }
            SetSelectedRoles(list)
        }
        else if (type === 'generic_competency') {
            let list = selected_generic_competency.concat([])
            let doc = list?.find(item => item?.id === data?.id)
            if (doc) {
                list = list.filter(item => item?.id != data?.id)
            } else {
                list.push(data)
            }
            SetSelectedGenericCompetency(list)
        }
    }

    const removeAdditionalDetails = (data, type) => {
        if (type === 'role') {
            let list = selected_roles.concat([])
            list = list.filter(item => item?.id != data?.id)
            SetSelectedRoles(list)
        }
        else if (type === 'generic_competency') {
            let list = selected_generic_competency.concat([])
            list = list.filter(item => item?.id != data?.id)
            SetSelectedGenericCompetency(list)
        }
    }

    // to handle the data before submitting the form
    const handleFormData = (data) => {
        if(!selected_roles?.length){
            message.error('Atleast one role should be selected')
            return
        }
      
        let form_value = {
            genericCompetency: selected_generic_competency?.length ? selected_generic_competency?.map(item => getIntFromString(item?.id)) : [],
            role: selected_roles?.length ? selected_roles?.map(item => getIntFromString(item?.id)) : [],
            jobFamilyData: { title: data?.title, description: data.description || '' }
        }
        onSubmit(form_value)
    }

    //to handle the navigation to role 
    const navigateToAddRole = () => {
        let jobfamily_data = {
            ...form.getFieldsValue(true),
            role: selected_roles,
            generic_competency: selected_generic_competency,
            id: jobfamily_detail?.id || null
        }
        let role_definition_navigation = JSON.parse(localStorage.getItem('role_definition_navigation'))
        role_definition_navigation = {
            ...role_definition_navigation,
            parent_route: { key: 'role', label: 'Role' },
            child_route: { key: 'role_add_edit_view' },
            jobfamily_data
        }
        localStorage.setItem('role_definition_navigation', JSON.stringify(role_definition_navigation))
        setCurrentTab({ key: 'role', label: 'Role' })
    }

    // handle data after creation of competency
    const CreateGenericCompetencyResponse = (data) => {
        setAddCompetency(false)
        if (data) {
            let competency_list = [data].concat(generic_competency_list)
            SetGenericCompetencyList(competency_list)
            let selected_competency = selected_generic_competency.concat([{ label: data?.title, ...data }])
            SetSelectedGenericCompetency(selected_competency)
        }
    }

    return (
        <>
            {
                initial_values && (
                    <Spin spinning={submit_loader} size='large'>
                        {/* to create generic competency */}
                        <Modal
                            visible={add_competency}
                            footer={false}
                            closable={false}
                            destroyOnClose={true}
                            centered={true}
                            className='rd-modal cp-modal-max-width'
                            width={"90%"}
                        >
                            <SubCompetencyAddContainer {...props} onCancel={() => setAddCompetency(false)} competency_type='Generic' onSuccess={(res) => { CreateGenericCompetencyResponse(res) }} />
                        </Modal>

                        <Form
                            layout="vertical"
                            onFinish={values => handleFormData(values)}
                            scrollToFirstError={true}
                            className='rd-align-center'
                            style={{ gap: '1em' }}
                            initialValues={initial_values}
                            form={form}
                        >
                            <div className='rd-input-container'>
                                <h4 className='rd-input-title'><span className='rd-input-star'>*</span> Title</h4>
                                <Form.Item
                                    name="title"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input title',
                                        },
                                    ]}
                                    style={{ width: '100%' }}
                                >
                                    <Input className="rd-text-input" placeholder='Enter Job family Title  ' />
                                </Form.Item>
                            </div>

                            <div className='rd-input-container'>
                                <h4 className='rd-input-title'>Description</h4>
                                <Form.Item
                                    name="description"
                                    style={{ width: '100%' }}
                                >
                                    <TextArea name='description' className="rd-text-area" placeholder='Enter job family description' />
                                </Form.Item>
                            </div>

                            <div className='rd-input-container'>
                                <h4 className='rd-input-title'><span className='rd-input-star'>*</span> Add Roles</h4>
                                <MultiSelectFormDropdown
                                    onSelect={(e) => { changeDropdownSelection(e, 'role') }}
                                    selected_data={selected_roles?.map(role => role?.id)}
                                    onSearch={(e) => { getRoleList({ title_Icontains: e }) }}
                                    placeholder={'Select role'}
                                    choice_list={role_list?.map(item => { return { ...item, label: item?.title } })}
                                    add_btn='Add Role'
                                    on_add={(e) => { navigateToAddRole() }}
                                />
                            </div>
                            {/* table to show selected roles */}
                            <div className='rd-table-scroll' style={{ width: "100%", marginTop: "1.5em" }}>
                                <div className='rd-custom-form-table'>
                                    <div className='rd-form-table-header'>
                                        <div className='rd-th-full'>
                                            <h4 className='rd-th-label-select-table'>Name</h4>
                                        </div>
                                        <div className='rd-th-action'>
                                            <h4 className='rd-th-label-select-table'>Action</h4>
                                        </div>
                                    </div>
                                    <div className='rd-form-table-body'>
                                        {selected_roles?.map(item => (
                                            <>
                                                <div className='rd-form-table-content'>
                                                    {item?.description && <div className='el-expansion-container'>
                                                        <img src={jobfamily_icons?.table_expand_arrow} className={`rd-expansion-arrow ${role_row_expanded?.includes(item?.id) ? 'rd-open-expansion' : ''}`} onClick={() => { expandRow(item?.id, "role") }} alt="" />
                                                    </div>}
                                                    <div className='rd-th-full'>
                                                        <h4 className='rd-td-label'>{item?.label}</h4>
                                                    </div>
                                                    <div className='rd-th-action'>
                                                        <img className='rd-delete-icon' src={jobfamily_icons?.delete_icon} onClick={() => { removeAdditionalDetails(item, 'role') }} alt="" />
                                                    </div>
                                                </div>

                                                {/* expanded detail section */}
                                                <div className={`rd-expanded-detail ${role_row_expanded?.includes(item?.id) ? 'rd-open-expansion-detail' : ''}`}>
                                                    <h4 className='rd-expanded-label'>Description</h4>
                                                    <div className='expand-dis'>
                                                        <h4 className='rd-expanded-label-desc'>{item?.description}</h4>
                                                    </div>
                                                </div>
                                            </>
                                        ))}

                                        {
                                            (!selected_roles?.length) ?
                                                <div className='rd-nodata'>
                                                    <h4 className='rd-nodata-label'>No role selected</h4>
                                                </div> : ''
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className='rd-input-container' style={{ marginTop: "1.5em" }}>
                                <h4 className='rd-input-title'>Generic Competencies</h4>
                                <MultiSelectFormDropdown
                                    onSelect={(e) => { changeDropdownSelection(e, 'generic_competency') }}
                                    selected_data={selected_generic_competency?.map(comp => comp?.id)}
                                    onSearch={(e) => { genericCompetencyList({ title_Icontains: e }) }}
                                    placeholder={'Select Generic competencies'}
                                    choice_list={generic_competency_list?.map(item => { return { ...item, label: item?.title } })}
                                    add_btn='Generic competencies'
                                    on_add={(e) => { setAddCompetency(true) }}
                                />
                            </div>
                            {/* table to show selected generic competency */}
                            <div className='rd-table-scroll' style={{ width: "100%", marginTop: "1.5em" }}>
                                <div className='rd-custom-form-table'>
                                    <div className='rd-form-table-header'>
                                        <div className='rd-th-full'>
                                            <h4 className='rd-th-label-select-table'>Name</h4>
                                        </div>
                                        <div className='rd-th-action'>
                                            <h4 className='rd-th-label-select-table'>Action</h4>
                                        </div>
                                    </div>
                                    <div className='rd-form-table-body'>
                                        {selected_generic_competency?.map(item => (
                                            <>
                                                <div className='rd-form-table-content'>
                                                    {item?.description && <div className='el-expansion-container'>
                                                        <img src={jobfamily_icons?.table_expand_arrow} className={`rd-expansion-arrow ${competency_row_expanded?.includes(item?.id) ? 'rd-open-expansion' : ''}`} onClick={() => { expandRow(item?.id, "competency") }} alt="" />
                                                    </div>}
                                                    <div className='rd-th-full'>
                                                        <h4 className='rd-td-label'>{item?.label}</h4>
                                                    </div>
                                                    <div className='rd-th-action'>
                                                        <img className='rd-delete-icon' src={jobfamily_icons?.delete_icon} onClick={() => { removeAdditionalDetails(item, 'generic_competency') }} alt="" />
                                                    </div>
                                                </div>

                                                {/* expanded detail section */}
                                                <div className={`rd-expanded-detail ${competency_row_expanded?.includes(item?.id) ? 'rd-open-expansion-detail' : ''}`}>
                                                    <h4 className='rd-expanded-label'>Description</h4>
                                                    <div className='expand-dis'>
                                                        <h4 className='rd-expanded-label-desc'>{item?.description}</h4>
                                                    </div>
                                                </div>
                                            </>
                                        ))}

                                        {
                                            (!selected_generic_competency?.length) ?
                                                <div className='rd-nodata'>
                                                    <h4 className='rd-nodata-label'>No generic competency selected</h4>
                                                </div> : ''
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className='rd-confirmation-action-container' style={{ padding: "3em 1em 2.5em 1em" }}>
                                <Button className='global-submit-btn' htmlType="submit">Save</Button>
                                <Button className='global-cancel-btn' onClick={() => onCancel('jobfamily_home_view')}>Cancel</Button>
                            </div>
                        </Form>
                    </Spin>
                )
            }
        </>
    )

}
export default JobFamilyAddEditView