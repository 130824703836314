// @ts-nocheck
import React from 'react';
import RoleDefinitionSearchView from './RoleDefinitionSearchView';
import CreateMainCompetecny from '../containers/createMainCompetency';
import { Modal, Popover, Spin, Tooltip } from 'antd';
import { competency_icons } from '../icon';
import CompetencyImportContainer from '../containers/CompetencyImportContainer';
import MainCompetencyEditContainer from '../containers/MainCompetencyEdit';
import RoleDefinitionpagination from './roleDefinitionPagination';

const MainCompetencyHomeView = ( props ) => {
    const { setCurrentTab, competency_category_list, competency_loading, competency_page_info, onFilter, getCompetencyCategoryList } = props;

    const [ add_competency, setAddCompetency ] = React.useState( false )
    const [ show_import_competency, setShowImportCompetency ] = React.useState( false )
    const [ edit_competency_value, setEditCompetencyValue ] = React.useState( false )

    const filter_data = [
        {
            key: "title_Icontains",
            label: "Name",
            ui_flow: "input_box",
            default: true
        }
    ]

    const navigationToCompetencyLink = ( data ) => {
        let role_definition_navigation = {
            parent_route: { key: 'sub_competency', label: 'Sub Competency' },
            child_route: { key: 'competency_add_edit_view', main_competency: data }
        }
        localStorage.setItem( 'role_definition_navigation', JSON.stringify( role_definition_navigation ) )
        setCurrentTab( { key: 'sub_competency', label: 'Sub Competency', child: true, parent: 'competency' } )
    }

    return (
        <>
            { /* Add new competency modal*/}
            <Modal
                visible={add_competency}
                footer={false}
                closable={false}
                destroyOnClose={true}
                centered={true}
                className='rd-modal cp-modal-max-width'
                width={"90%"}
            >
                <CreateMainCompetecny {...props} onCancel={() => setAddCompetency( false )} />
            </Modal>

            { /* Edit competency modal*/}
            <Modal
                visible={edit_competency_value}
                footer={false}
                closable={false}
                destroyOnClose={true}
                centered={true}
                className='rd-modal cp-modal-max-width'
                width={"90%"}
            >
                <MainCompetencyEditContainer {...props} edit_competency_value={edit_competency_value} onCancel={() => setEditCompetencyValue()} />
            </Modal>

            { /* Import competency modal*/}
            <Modal
                visible={show_import_competency}
                footer={false}
                closable={false}
                destroyOnClose={true}
                centered={true}
                className='rd-modal cp-modal-max-width'
                width={"90%"}
            >
                <CompetencyImportContainer {...props} type={"import_main_competency"} onCancel={() => { setShowImportCompetency( false ); getCompetencyCategoryList() }} />
            </Modal>

            <div className='rd-coll-competency' style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between', alignItems: 'center', gap: ".5em", flexWrap: "wrap", width: "100%",columnGap:"1.5em !important"}}>
                <div className='rd-root rd-coll-btn'>
                    <button className='rd-add-button' style={{ width: "9em", marginRight: "1.5em" }} onClick={() => { setAddCompetency( true ) }}>+ Add</button>
                    <button className='rd-dotted-button' style={{ width: "9em", marginRight: "1.5em" }} onClick={() => { setShowImportCompetency( true ) }}> <img src={competency_icons?.import_icon} alt="" /> Import</button>
                </div>
                <div className='rd-search-container'>
                    <RoleDefinitionSearchView filterData={filter_data} onfilterChange={e => onFilter( e )} />
                </div>
            </div>
            {/* competency table listing */}
            <Spin spinning={competency_loading}>
                <div className='rd-table-scroll'>
                    <div className='rd-table-container'style={{minWidth:"300px"}}>
                        <table className='rd-table'>
                            <thead>
                                <tr className='rd-row-header'>
                                    <th className='rd-table-row-padding-left rd-left-radious'>
                                        <h4 className='rd-table-header-label' style={{ fontSize: "1.35em" }}>Name</h4>
                                    </th>
                                    <th className='rd-table-row-padding-left'>
                                        <h4 className='rd-table-header-label' style={{ fontSize: "1.35em" }}>Type</h4>
                                    </th>
                                    <th className='rd-table-row-padding-rigth rd-content-center rd-table-header-label rd-right-radious'>
                                        <h4 className='rd-table-header-label' style={{ fontSize: "1.35em" }}>Actions</h4>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    competency_category_list?.map( item => (
                                        <tr className='rd-row-body'>
                                            <td style={{width:'30%'}} className='rd-table-row-padding-left rd-left-radious'>
                                                <Tooltip title={item?.title?.length > 30 ? item?.title : ''} placement='topLeft'>
                                                <h4  className='rd-table-body-label rd-text-ellipsis'>{item?.title}</h4>
                                                </Tooltip>
                                            </td>
                                            <td className='rd-table-row-padding-left rd-left-radious'>
                                                <h4 className='rd-table-body-label'>{item?.competencyType==='GENERIC'?'Generic Competency':"Functional (role specific)"}</h4>
                                            </td>
                                            <td className='rd-table-row-padding-rigth rd-content-center rd-right-radious'>
                                                <div className='rd-table-actions'>
                                                    <Popover overlayClassName="rd-add-new-popover" style={{ background: "rgba(170, 186, 198, 1)" }} placement="topRight" content={"Edit competency"} trigger="hover">
                                                        <img className='rd-delete-icon' src={competency_icons?.edit_role_definition} alt="" onClick={() => setEditCompetencyValue( item )} />
                                                    </Popover>

                                                    <Popover overlayClassName="rd-add-new-popover" style={{ background: "rgba(170, 186, 198, 1)" }} placement="topRight" content={"Add sub - competency"} trigger="hover">
                                                        <img className='rd-delete-icon' src={competency_icons?.add_new_competency} onClick={() => { navigationToCompetencyLink( item ) }} alt="" />
                                                    </Popover>
                                                </div>
                                            </td>
                                        </tr>
                                    ) )
                                }
                            </tbody>
                        </table>
                        {
                            ( !competency_category_list?.length && !competency_loading ) ?
                                <div className='rd-nodata'>
                                    <h4 className='rd-nodata-label'>No data found</h4>
                                </div> : ''
                        }
                         {
                        ( competency_page_info?.hasNextPage && competency_page_info?.endCursor ) && (
                            <RoleDefinitionpagination on_pagination={() => { getCompetencyCategoryList( { after: competency_page_info?.endCursor } ) }} />
                        )
                    }
                    </div>
                </div>
            </Spin>
        </>
    )
}

export default MainCompetencyHomeView