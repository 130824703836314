import gql from 'graphql-tag';

export const EMPLOYEE_LIST_FILTER = gql`
query me{
  allEmpoloyee:me{
       id
       employee{
           id
           orgCeoEmployee {
               edges {
                   node {
                       id
                       employees {
                           edges {
                               node {
                               id
                               user{
                                   id
                                   firstName
                                   lastName
                                   email
                                   employee{
                                       id
                                   }
                               }
                               }
                           }
                       }
                   }
               }
           }
           orgPocEmployee {
               edges {
                   node {
                       id
                       employees {
                           edges {
                               node {
                               id
                               user{
                                   id
                                   firstName
                                   lastName
                                   email
                                   employee{
                                       id
                                   }
                               }
                               }
                           }
                       }
                   }
               }
           }
           teamManagerEmployee {
               edges {
                   node {
                       id
                       employees {
                           edges {
                               node {
                                   id
                                   user{
                                       id
                                       firstName
                                       lastName
                                       email
                                       employee{
                                           id
                                       }
                                   }
                               }
                           }
                       }
                   }
               }
           }
           verticalHeadEmployee {
               edges {
                   node {
                       id
                       employees {
                           edges {
                               node {
                                   id
                                   user{
                                       id
                                       firstName
                                       lastName
                                       email
                                       employee{
                                           id
                                       }
                                   }
                               }
                           }
                       }
                   }
               }
           }
       }
   }   
 }
`