import React from 'react';
import _ from 'lodash';
import { Button, Modal, Progress } from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import { withApollo } from '@apollo/client/react/hoc';
import exportFromJSON from 'export-from-json';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { GET_ALL_TOKENS_LIST_QUERY } from '../graphql/GetAllTokensListQuery.gql';

import { getLink } from './Token';

const today = new Date();
const ITEMS_TO_DOWNLOAD = 30;
const FILE_NAME =
  `Tokens_` + today.toLocaleDateString().replaceAll('/', '-') + 'T' + today.toLocaleTimeString().replaceAll(':', '-');

const ExportTokens = props => {
  const { filter, getAllTokensList, client, setLoading } = props;
  const noOfSteps = Math.ceil(getAllTokensList?.totalCount / ITEMS_TO_DOWNLOAD);

  const exportTokens = async () => {
    setLoading(true);
    let allTokenData = [];
    await Promise.allSettled(
      [...Array(noOfSteps).keys()].map(async (e, i) => {
        const offset = e * ITEMS_TO_DOWNLOAD;
        let newFilter = filter;
        if (filter.invited === '') newFilter = _.omit(newFilter, 'invited');
        if (filter.valid === '') newFilter = _.omit(newFilter, 'valid');
        const { data } = await client.query({
          query: GET_ALL_TOKENS_LIST_QUERY,
          variables: {
            offset,
            first: ITEMS_TO_DOWNLOAD,
            ...newFilter
          }
        });
        allTokenData = [
          ...allTokenData,
          ...data.getAllTokensList.edges.map(({ node }) => ({
            firstName: node.user.firstName,
            lastName: node.user.lastName,
            username: node.user.username,
            email: node.user.email,
            mobileNumber: node.user.profile.phoneNumber,
            createdBy: `${node.createdBy.firstName} ${node.createdBy.lastName}`,
            survey: node.survey.name,
            invited: node.invited,
            valid: node.valid,
            dateCreated: node.dateCreated,
            link: window.location.origin + getLink(node.link)
          }))
        ];
      })
    );
    setLoading(false);
    exportFromJSON({ data: allTokenData, fileName: FILE_NAME, exportType: 'xls' });
  };

  return (
    <Button
      size="md"
      type="primary"
      ghost
      onClick={() =>
        Modal.confirm({
          title: 'Tokens will be exported to xls file.',
          centered: true,
          icon: <ExclamationCircleOutlined />,
          content: `${getAllTokensList?.totalCount} tokens to be exported.`,
          okText: 'Export',
          cancelText: 'Cancel',
          onOk: exportTokens
        })
      }
    >
      <ExportOutlined />
      Export Token
    </Button>
  );
};

export default withApollo(ExportTokens);
