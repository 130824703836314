import * as React from 'react';

const CookiePage = () => {
  return (
    <>
      <h1>Cookie Usage Statement</h1>
      <h4>
        This website uses cookies. By using the website and agreeing to this policy, you consent to our use of cookies in
        accordance with the terms of this policy.
      </h4>
      <br />
      <h2>About Cookies</h2>
      <h4>
        Cookies are files, often including unique identifiers, that are sent by web servers to web browsers, and which may
        then be sent back to the server each time the browser requests a page from the server.
        <br />
        <br /> Cookies can be used by web servers to identity and track users as they navigate different pages on a website,
        and to identify users returning to a website.
        <br />
        <br /> Cookies may be either “persistent” cookies or “session” cookies. A persistent cookie consists of a text file
        sent by a web server to a web browser, which will be stored by the browser and will remain valid until its set expiry
        date (unless deleted by the user before the expiry date). A session cookie, on the other hand, will expire at the end
        of the user session, when the web browser is closed.
      </h4>
      <br />
      <h2>Cookies on the Website</h2>
      <h4>We use both session cookies and persistent cookies on the website.</h4>
      <br />
      <h2>How we use Cookies</h2>
      <h4>
        Cookies do not contain any information that personally identifies you publicly. The cookies used on the website
        include those which are strictly necessary cookies for access and navigation, remember your login (functionality
        cookies).
        <br />
        <br /> We may use the information we obtain from your use of our cookies for the following purposes:
        <br />
        <br /> to recognise your computer when you visit the website.
        <br /> to track you as you navigate the website, and to enable the use of any e-commerce facilities.
        <br /> to improve the website’s usability.
        <br /> to analyse the use of the website. in the administration of the website.
        <br /> to personalise the website for you, including website appearance which m ay be of particular interest to you.
      </h4>
      <br />
      <h2>Third Party Cookies</h2>
      <h4>No third party cookies are used.</h4>
      <br />
      <br />
      <h2>Blocking Cookies</h2>
      <h4>
        Most browsers allow you to refuse to accept cookies. For example:
        <br />
        <br /> 1. In Internet Explorer you can refuse all cookies by clicking “Tools”, “Internet Options”, “Privacy”, and
        selecting “Block all cookies” using the sliding selector;
        <br /> 2. In Firefox you can block all cookies by clicking “Tools”, “Options”, and un-checking “Accept cookies from
        sites” in the “Privacy” box.
        <br /> 3. In Google Chrome you can adjust your cookie permissions by clicking “Options”, “Under the hood”, Content
        Settings in the “Privacy” section. Click on the Cookies tab in the Content Settings.
        <br /> 4. In Safari you can block cookies by clicking “Preferences”, selecting the “Privacy” tab and “Block cookies”.
        <br />
        <br /> Blocking all cookies will, however, have a negative impact up on the usability of many websites. If you block
        cookies, you may not be able to use certain features on the website (log on, access content, use search functions).
      </h4>
      <br />
      <h2>Deleting Cookies</h2>
      <h4>
        You can also delete cookies already stored on your computer: <br />
        <br />
        1. In Internet Explorer, you must manually delete cookie files.
        <br /> 2. In Firefox, you can delete cookies by, first ensuring that cookies are to be deleted when you “clear
        private data” (this setting can be changed by clicking “Tools”, “Options” and “Settings” in the “Private Data” box)
        and then clicking “Clear private data” in the “Tools” menu.
        <br /> 3. In Google Chrome you can adjust your cookie permissions by clicking “Options”, “Under the hood”, Content
        Settings in the “Privacy” section. Click on the Cookies tab in the Content Settings. <br />
        4. In Safari you can delete cookies by clicking “Preferences”, selecting the “Privacy” tab and “Remove All Website
        Data”.
        <br /> <br />
        Obviously, doing this may have a negative impact on the usability of many websites.
      </h4>
    </>
  );
};

export default CookiePage;
