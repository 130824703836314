import gql from 'graphql-tag';
import { EMPLOYEE_TYPE } from './EmployeeType.gql';

export const EDIT_EMPLOYEE = gql`
  ${EMPLOYEE_TYPE}
  mutation editEmployee($id: ID!) {
    updateEmployee(id: $id) {
      employee {
        ...EmployeeType
      }
    }
  }
`;
