export const surveyAnswerGroup = [
    {
        7: [
            {
                Doing: ["Analyst", "Believer", "Brainstormer", "Catalyst", "Chameleon"]
            },
            {
                Feeling: ["Coach", "Commander", "Deliverer", "Empathizer", "Focus Expert"]
            },
            {
                Motivating: ["Optimist", "Peacekeeper", "Philomath", "Problem Solver", "Self-Believer"]
            },
            {
                Thinking: ["Story Teller", "Strategist", "Time Keeper", "Thinker", "Winner"]
            }
        ],
        3: [
            {
                Doing: ["Activism", "Adaptability", "Autonomy", "Beauty", "Cleanliness"]
            },
            {
                Feeling: ["Collaboration", "Comfort", "Commitment", "Consistency"]
            },
            {
                Motivating: ["Creativity", "Credibility", "Curiosity", "Decisiveness", "Detachment"]
            },
            {
                Thinking: ["Diligence", "Diversity & Inclusion", "Easygoing", "Efficiency", "Empathy"]
            }
        ],
        9: [
            {
                Doing: ["Coding", "Python", "Media Planning", "Vendor Management", "Understanding of various business models, tax implications, investor choices", "Managing investor relations"]
            },
            {
                Feeling: ["Digital marketing", "Offline Marketing", "Technical hiring", "Manpower planning", "Using CLAPS model of feedback", "Building a strategic plan"]
            },
            {
                Motivating: ["Managing a book of business", "Consultative skills", "People management", "Leadership Development", "Team building", "DEI"]
            },
            {
                Thinking: ["Scale", "Operational Plan", "Technical Documentation", "Hiring", "Goal oriented thinking", "Content and Design"]
            }
        ]
    }
]