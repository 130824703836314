// @ts-nocheck
import { Button, Form, Input, Spin } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';

const AddEmployeeLevelView = (props) => {
    const { onCancel, onSubmit, page_title,api_loader } = props

    return (
        <Spin spinning={api_loader}>
            <Form
                layout="vertical"
                onFinish={values => onSubmit(values)}
                scrollToFirstError={true}
                className='rd-align-center '
                style={{ gap: '1em' }}
            >
                {
                    page_title && (
                        <div align="center" style={{ marginBottom: "3em" }}>
                            <h4 className='competency-popup-title'>{page_title}</h4>
                        </div>
                    )
                }
                <div className='rd-input-container'>
                    <h4 className='rd-input-title'><span className='rd-input-star'>*</span> Title</h4>
                    <Form.Item
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Please input title',
                            },
                        ]}
                        style={{ width: '100%' }}
                    >
                        <Input name='name' className="rd-text-input" placeholder='Enter level title' />
                    </Form.Item>

                </div>
                <div className='rd-input-container'>
                    <h4 className='rd-input-title'>Description</h4>
                    <Form.Item
                        name="description"
                        style={{ width: '100%' }}
                    >
                        <TextArea name='description' className="rd-text-area" />
                    </Form.Item>
                </div>
                <div className='rd-confirmation-action-container'>
                    <Button className='global-submit-btn' htmlType="submit">Save</Button>
                    <Button className='global-cancel-btn' onClick={() => onCancel(false)}>Cancel</Button>
                </div>
            </Form>
        </Spin>
    )
}

export default AddEmployeeLevelView