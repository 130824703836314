// @ts-nocheck
import { withApollo } from '@apollo/client/react/hoc';
import React, { useState } from 'react';
import { compose } from '../../core';
import { UseremployeeList, withFeedBackSurveyByid } from '../../feedback/containers/feedbackOperations';

import { useHistory } from 'react-router-dom';
import { USER_EMPLOYEE_SEARCH } from '../../feedback/graphql/searchEmployeee.gql';
import GiveFeedbackView from '../components/GiveFeedbackView';
import { CONTRIBUTOR_FEEDBACK_OKR_LIST } from '../graphql/Contributor.feedback.gql';

const PostFeedbackContainer = props => {
  const { client, allEmployee, me, Recipient, feedbackPermission,setRecipient, setrecipientName } = props
  const history = useHistory();
  const [okrByrecipiant, setokrByrecipiant] = React.useState([]);
  const [allOrganizationEmployees, setEmployees] = React.useState()
  const [okrloading, setOkrLoading] = React.useState(false)
  const [okrNeeded, setOkrNeeded] = useState(true)

  React.useEffect(() => {
    if (Recipient) {
      getOKRByRecepiant({ employee: Recipient?.id })
    }

  }, [Recipient])


  const getOKRByRecepiant = async (filter) => {
    try{
      if (okrNeeded) {
        setOkrLoading(true)
        const { data } = await client.query({
          query: CONTRIBUTOR_FEEDBACK_OKR_LIST,
          variables: filter,
        });
        if (data?.contributors) {
       
       
          setokrByrecipiant(data?.contributors)
        }
        setOkrLoading(false)
      }
    }catch(e){
      console.error(e)
    }
  

  }

  let okrDetails = JSON.parse(localStorage.getItem('okrDetails'))

  const handleSearch = async (value) => {
    try{
      const { data } = await client.query({
        query: USER_EMPLOYEE_SEARCH,
        variables: { user_FirstName: value, user_LastName: value },
        fetchPolicy: 'network-only'
      });
      if (data?.first && data?.last) {
        let user = data?.first.edges.concat(data?.last?.edges)
        var setObj = new Set(); // create key value pair from array of array
        var result = user.reduce((acc, item) => {
          if (!setObj.has(item.node?.user?.id)) {
            setObj.add(item.node?.user?.id)
            acc.push(item)
          }
          return acc;
        }, [])
  
        setEmployees({ edges: result })
      }
    }catch(e){
      console.error(e)
    }
  
  }

  React.useEffect(() => {
    if (allEmployee?.edges?.length) {
      setEmployees(allEmployee)
      
    }
  }, [allEmployee])

  return (
    <>
      <GiveFeedbackView  {...props}
      setOkrNeeded={setOkrNeeded}
        feedbackPermission={feedbackPermission}
        history={history}
        okrDetails={okrDetails}
        onSelectRecepiant={(e) => getOKRByRecepiant(e)}
        allOrganizationEmployees={allOrganizationEmployees}
        handleSearch={(e) => { handleSearch(e) }}
        okrSearch={(e) => getOKRByRecepiant(e)}
        okrByrecipiant={okrByrecipiant}
        okrloading={okrloading}
        me={me}
      ></GiveFeedbackView >
    </>
  );
};

export default compose(withApollo, UseremployeeList, withFeedBackSurveyByid)(PostFeedbackContainer);
