import gql from 'graphql-tag';

export const CREATE_TOKEN_UNDER_SURVEY = gql`
  mutation createSourceResponse($email: String, $firstName: String!, $invite: Boolean, $lastName: String!, $message: String, $surveyId: Int!, $mobileNumber: String,$respondentType:String!,$responseSourceForId:Int!,$createdById: Int!) {
    createSourceResponse(email: $email, firstName: $firstName, invite: $invite, lastName: $lastName, message: $message, surveyId: $surveyId, mobileNumber: $mobileNumber,respondentType:$respondentType,responseSourceForId:$responseSourceForId,createdById:$createdById) {
      token{
        id
      }
    }
  }
`;



export const CREATE_TOKEN_UNDER_SURVEY_OPTIMIZED = gql`
  mutation createSourceResponse($email: String, $firstName: String!, $invite: Boolean, $lastName: String!, $message: String, $surveyId: Int!, $mobileNumber: String,$respondentType:String!,$responseSourceForId:Int!,$createdById: Int!) {
    createSourceResponse(email: $email, firstName: $firstName, invite: $invite, lastName: $lastName, message: $message, surveyId: $surveyId, mobileNumber: $mobileNumber,respondentType:$respondentType,responseSourceForId:$responseSourceForId,createdById:$createdById) {
      token{
        id
      }
    }
  }
`;

// user {
//   id
//   username
//   firstName
//   lastName
//   email
//   profile {
//     phoneNumber
//   }
// }
// invited
// createdBy {
//   firstName
//   lastName
// }
// valid
// dateCreated
// survey {
//   id
//   name
// }
// link