import gql from "graphql-tag";



export const TOOL_COACHING_COMMITMENT_SUBSCRIPTION = gql`

subscription{
    toolsCoachingCommitmentSubscription {
      mutation
      coachingCommitment {
        id
        title
        description
        commitment{
          id
        }
      }
    }
  }
  
`;