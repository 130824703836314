import gql from 'graphql-tag';
import { FORM_SECTION_TYPE } from './FormSectionType.gql';

export const FORM_SECTION_SUBSCRIPTION = gql`
  ${FORM_SECTION_TYPE}
  subscription toolsFormSectionSubscription {
    toolsFormSectionSubscription {
      mutation
      formSection {
        ...FormSectionType
      }
    }
  }
`;
