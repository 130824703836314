import gql from 'graphql-tag';
import { TAG_TYPE } from './TagType.gql';

export const ALL_STORY_TAGS_QUERY = gql`
  ${TAG_TYPE}
  query allStoryTags(
    $first: Int
    $offset: Int
    $orderBy: [String]
    $name_Icontains: String
    $description_Icontains: String
  ) {
    allStoryTags(
      first: $first
      offset: $offset
      orderBy: $orderBy
      name_Icontains: $name_Icontains
      description_Icontains: $description_Icontains
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...TagType
        }
      }
      totalCount
      edgeCount
    }
  }
`;
