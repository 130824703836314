import gql from 'graphql-tag';
import { RESPONSE_COMPARE_REQUEST_TYPE } from './ResponseCompareRequestType.gql';

export const DELETE_RESPONSE_COMPARE_REQUEST = gql`
  ${RESPONSE_COMPARE_REQUEST_TYPE}
  mutation deleteResponseCompareRequest($id: ID!) {
    deleteResponseCompareRequest(id: $id) {
      compareRequest {
        ...ResponseCompareRequestType
      }
    }
  }
`;
