import gql from 'graphql-tag';
import { COACHING_COMMITMENT_PROGRESS_TYPE } from './CoachingCommitmentProgressType.gql';

export const ADD_COACHING_COMMITMENT_PROGRESS = gql`
  ${COACHING_COMMITMENT_PROGRESS_TYPE}
  mutation createCoachingCommitmentProgress($coachingCommitmentProgressData: CoachingCommitmentProgressInput!) {
    createCoachingCommitmentProgress(coachingCommitmentProgressData: $coachingCommitmentProgressData) {
        coachingCommitmentProgress {
            ...CoachingCommitmentProgressType,
        }
    }
  }
`;
