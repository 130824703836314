import React from 'react';

import { AdminLayout } from '../../look';
import { employee } from '../route';

import EmployeeFormComponent from '../../organization/components/EmployeeFormComponent';

const AddEmployeeView = props => {
    return (
        <>
            <AdminLayout
                table={false}
                active={employee}
                title="Add Employee"
                sidebar={true}
                FormComponent={<EmployeeFormComponent {...props} />}
            ></AdminLayout>
        </>
    );
};

export default AddEmployeeView;
