// @ts-nocheck
import React from "react";
import RoleHomeView from "../components/RoleHomeView";
import { ALL_EDUCATIONS_LIST, ALL_ROLES_LIST, ALL_ROLES_LIST_LEVEL_FILTER, ALL_ROLES_LIST_NAME_FILTER, ALL_ROLES_LIST_VERTICAL_FILTER, All_ROLE_SUBSCRIPTION } from "../graphql/RoleQueries.gql";
import { compose } from "modules/core";
import { withDelete } from "../RoleDefinitionOperation";
import { message } from "antd";

const RoleHomeContainer = ( props ) => {

    const { client, me, history, permission, deleteRole } = props

    const [ allRoles, setAllRole ] = React.useState( [] )
    const [ loading, setLoading ] = React.useState( false )
    const [ role_delete, SetRoleDelete ] = React.useState()
    const [role_page_info,SetRolePageInfo] = React.useState()
    const [education_list,SetEducationList] = React.useState()
    const [role_Level_list,SetRoleLevelList] = React.useState()
    const [role_vertical_list,SetRoleVerticalList] = React.useState()

    const role_list_ref = React.useRef( [] )
    const role_subscription = React.useRef( null )
    const filter_ref = React.useRef( {} )

    React.useEffect(() => {
        role_list_ref.current = allRoles
    }, [allRoles])

    React.useEffect( () => {
        if ( permission?.required_permission ) {
            getRoleLevel()
            roleFilterEducationList()
            roleFilterLevelList()
            roleFilterVerticalList()
        }
    }, [ permission ] )

    //Role list
    const getRoleLevel = async ( filter ) => {
        filter_ref.current={...filter,maximum_cursor:null,minimum_cursor:null} || {}
        setLoading( true )
        const { data } = await client.query( {
            query: ALL_ROLES_LIST,
            variables:  {...filter,first:25},
            fetchPolicy: 'network-only'
        } );

        if ( data?.minimum && data?.maximum) {
            SetRolePageInfo({
                hasNextPage:data?.minimum?.pageInfo?.hasNextPage||data?.maximum?.pageInfo?.hasNextPage,
                minumum_cursor:data?.minimum?.pageInfo?.endCursor,
                maximum_cursor:data?.maximum?.pageInfo?.endCursor
            })
            let list = data?.minimum?.edges.map(({ node }) => node).concat(data?.maximum?.edges.map(({ node }) => node))
            var setObj = new Set(); // create key value pair from array of array

            if(filter?.maximum_cursor||filter?.minumum_cursor){
                list = role_list_ref.current.concat(list)
            }
            var result = list.reduce((acc,item)=>{
                if(!setObj.has(item.id)){
                  setObj.add(item.id)
                  acc.push(item)
                }
                return acc;
              },[])
            setAllRole( result )
            if ( !role_subscription?.current ) {
                roleSubscription()
            }
            setLoading( false )

        }
    }

    //Role subscription
    const roleSubscription = () => {

        role_subscription.current = client.subscribe( {
            query: All_ROLE_SUBSCRIPTION
        } ).subscribe( {
            next( result ) {
                let roleCacheData = role_list_ref.current
                let scbscription_data = result.data.roleSubscription
                switch ( scbscription_data.mutation ) {
                    case 'CREATE':
                        const deletedIfExists = roleCacheData?.filter( item =>
                            item.id === scbscription_data.role.id
                                ? false
                                : true ).concat( [ scbscription_data.role ] )
                        setAllRole( deletedIfExists )
                        break
                    case 'UPDATE':
                        const updated = roleCacheData?.map( item =>
                            item.id === scbscription_data.role.id
                                ? scbscription_data.role
                                : item
                        )
                        setAllRole( updated )
                        break
                    case 'DELETE':
                        const deleted = roleCacheData?.filter( item => item.id !== scbscription_data.role.id )
                        setAllRole( deleted )
                        break
                    default:
                        break
                }
            }
        } );
    }

    const deleteSelectedRole = async ( id ) => {
        try {
            let response = await deleteRole( { id: id } )
            if ( response ) {
                SetRoleDelete( null )
            }
        }
        catch ( e ) {
            message.error("couldn't perform this action !!")
        }
    }

    //education Filter
    const roleFilterEducationList = async(filter)=>{
        filter_ref.current={...filter,after:null} || {}
        const { data } = await client.query( {
            query: ALL_EDUCATIONS_LIST,
            variables:  {...filter},
            fetchPolicy: 'network-only'
        } );

        if ( data?.allEducations) {
            let list = data?.allEducations?.edges.map(({ node }) => node)
            var setObj = new Set(); // create key value pair from array of array
            var unique = list.reduce((acc,item)=>{
              if(!setObj.has(item.id)){
                setObj.add(item.id)
                acc.push(item)
              }
              return acc;
            },[])
            SetEducationList( unique )
        }
    }

    //Role Level Filter
    const roleFilterLevelList = async(filter)=>{
        filter_ref.current={...filter,after:null} || {}
        const { data } = await client.query( {
            query: ALL_ROLES_LIST_LEVEL_FILTER,
            variables:  {...filter},
            fetchPolicy: 'network-only'
        } );

        if ( data?.allRoles) {
            let list = data?.allRoles?.edges.map(({ node }) => node?.employeeLevel)
            var setObj = new Set(); // create key value pair from array of array
            var unique = list.reduce((acc,item)=>{
              if(!setObj.has(item.id)){
                setObj.add(item.id)
                acc.push(item)
              }
              return acc;
            },[])
            SetRoleLevelList( unique )
        }
    }

    //Role Level Filter
    const roleFilterVerticalList = async(filter)=>{
        filter_ref.current={...filter,after:null} || {}
        const { data } = await client.query( {
            query: ALL_ROLES_LIST_VERTICAL_FILTER,
            variables:  {...filter},
            fetchPolicy: 'network-only'
        } );

        if ( data?.allRoles) {
            let list = data?.allRoles?.edges.map(({ node }) => node?.vertical)
            var setObj = new Set(); // create key value pair from array of array
            var unique = list.reduce((acc,item)=>{
              if(!setObj.has(item?.id)){
                setObj.add(item?.id)
                acc.push(item)
              }
              return acc;
            },[])
            SetRoleVerticalList( unique?.filter(item=>item) )
        }
    }
    return (
        <>
            <RoleHomeView
                role_list={allRoles}
                {...props}
                role_loading={loading}
                role_delete={role_delete}
                SetRoleDelete={( e ) => SetRoleDelete( e )}
                deleteSelectedRole={( id ) => deleteSelectedRole( id )} 
                getRoleLevel={(filter)=>{getRoleLevel({...filter_ref.current,...filter})}}
                role_page_info={role_page_info}
                education_list={education_list}
                role_Level_list={role_Level_list}
                search_with_level={(filter)=>roleFilterLevelList({...filter})}
                search_with_Education ={(filter)=>roleFilterEducationList({...filter})}
                role_vertical_list={role_vertical_list}
                search_with_vertical={(filter)=>roleFilterVerticalList({...filter})}
                />

                    
        </>
    )
}

export default compose( withDelete )( RoleHomeContainer )