import { Spin } from 'antd';
import React from 'react';

const UserImpactNarrative = (props) => {
    const { impact_narrative } = props
    return (
        <>
            {
                impact_narrative ?

                    impact_narrative?.overview ?
                        <div className="impact-header-userprofile" >
                            <div className="header-border-user-profile">
                                <div className="border-inner-content-user-profile">
                                    <h4 className="header-border-label-user-profile">Impact Narrative</h4>
                                </div>
                            </div>

                            <div>
                                <h4 className='user-impact-content'>{impact_narrative?.overview}</h4>
                            </div>
                        </div> :
                        <div style={{display:'flex',justifyContent:"center",alignItems:"center",marginTop:"3em"}}>
                        <h4 className='no-data-profile'>No Data Found</h4>
                    </div>
                    :
                    <div align="center" style={{ width: "100%", height: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Spin spinning={true} ></Spin>
                    </div>
            }



        </>
    )
}

export default UserImpactNarrative
