import gql from 'graphql-tag';
import { CHOICE_TYPE } from './ChoiceType.gql';

export const DELETE_CHOICE = gql`
  ${CHOICE_TYPE}
  mutation deleteChoice($id: ID!) {
    deleteChoice(id: $id) {
      choice {
        ...ChoiceType
      }
    }
  }
`;
