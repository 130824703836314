import gql from 'graphql-tag';
import { TAG_TYPE } from './TagType.gql';

export const TAG_TYPE_CONNECTION = gql`
  ${TAG_TYPE}
  fragment TagTypeConnection on TagTypeConnection {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...TagType
      }
    }
    totalCount
    edgeCount
  }
`;
