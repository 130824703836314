import gql from "graphql-tag";

export const FEEDBACK_MEETING_ID = gql`
query meetingById($id:ID!){
    meetingById(id:$id){
        id
        feedback {
          edges {
            node {
              id
              feedbackTo {
                id
                user {
                  id
                  firstName
                  lastName
                }
              }
              response{
                id
                answerSet{
                  edges{
                    node{
                      id
                      answer
                      question{
                        id
                        questionAddress
                        questionText
                      }
                    }
                  }
                }
              }
              feedbackType
            }
          }
        }
      }
}
`;
