import React from 'react'
import '../css/teamIdp.css'
import { idp_tam_icons } from '../idpIcons'
import idp_route from '../route';
import RequestContainer from '../containers/requestContainer';
import EmployeeIDPStatus from '../containers/employeeIdpStatus';

const TeamIdpView = ( props ) => {
    const {navigateRoute} = props
    const [ current_tab, SetCurrentTab ] = React.useState( {
        key: 'request',
        label: 'Request',
    } )
    const [ tab, setSideBarTab ] = React.useState( [
        {
            key: 'request',
            label: 'Request',
        },
        {
            key: 'employee_idp_status',
            label: 'Employee IDP Status',

        },
    ] )
    const content = {
        request: RequestContainer,
        employee_idp_status: EmployeeIDPStatus,
    }



    const CurrentView = content[ current_tab?.key ]

    return (
        <div className="idp-home-root">
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "start", gap: "1em", alignItems: "center",marginBottom:"1em" }}>
                <img src={idp_tam_icons?.back_arrow} alt="back_arrow" style={{ width: "25px", cursor: "pointer" }} onClick={() => navigateRoute( idp_route?.home )} />
                <h4 className="team-idp-main-head">Team IDP</h4>
            </div>
            <div className="idp-home-container" style={{padding:"0px"}}>
                <div className='idp-tab-view'>
                    {
                        tab?.map( tab => (
                            <div className={`idp-tab-normal ${( current_tab?.key === tab?.key || ( current_tab?.key?.includes( tab?.key ) ) ) ? 'idp-tab-active' : ''}`} onClick={() => SetCurrentTab( tab )}>
                                <h4 className={`idp-tab-label ${( current_tab?.key === tab?.key || ( current_tab?.key?.includes( tab?.key ) ) ) ? 'idp-tab-active-label' : ''}`}>{tab?.label}</h4>
                            </div>
                        ) )
                    }
                    <div className='border-line'>
                    </div>
                </div>
                <div style={{padding:"2em 0.5em"}}>
                    {
                        content[ current_tab?.key ] && (
                            <CurrentView current_tab={current_tab}{...props} />
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default TeamIdpView