import gql from 'graphql-tag';
export const ALL_RESPONSES_BY_IMPLICIT_USER = gql`
  query allResponsesByImplicitUserId($first: Int, $orderBy: [String], $survey: ID) {
    allResponsesByImplicitUserId(first: $first, orderBy: $orderBy, survey: $survey) {
      edges {
        node {
          id
          responseDuration
          responseDate
        }
      }
    }
  }
`;