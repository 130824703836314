import gql from 'graphql-tag';
import { RESPONSE_TYPE } from './ResponseType.gql';

export const SURVEY_RESPONSE_SUBSCRIPTION = gql`
  ${RESPONSE_TYPE}
  subscription surveyResponseSubscription {
    surveyResponseSubscription {
      mutation
      surveyResponse {
        ...ResponseType
      }
    }
  }
`;


export const SURVEY_RESPONSE_SUBSCRIPTION_OPTIMIZED = gql`
  
  subscription surveyResponseSubscription {
    surveyResponseSubscription {
      mutation
      surveyResponse {
        id
        survey {
          id
              }
      }
    }
  }
`;
