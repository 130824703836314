import React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import gql from 'graphql-tag';
import { Empty, Spin } from 'antd';

import { compose } from '../../../core';
import { getIntFromString, getIntString, getUniqueListBy } from '../functions';
import { withLookSurveyFilterUpdating, withLookSurveyState } from '../../containers/LookOperations';

import SelectField from './SelectField';

export const ALL_PUBLIC_SURVEY_QUERY = gql`
  query allPublicSurveys($first: Int, $orderBy: [String], $name_Icontains: String) {
    allPublicSurveys(first: $first, orderBy: $orderBy, name_Icontains: $name_Icontains) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const ALL_ORG_SURVEY_BY_IMPLICIT_BY_ORG_ID_QUERY = gql`
  query allOrgSurveysByImplicitOrgId($first: Int, $orderBy: [String], $name_Icontains: String) {
    allOrgSurveysByImplicitOrgId(first: $first, orderBy: $orderBy, name_Icontains: $name_Icontains) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

const SurveyAutoCompleteEndUser = props => {
  const {
    loading,
    allPublicSurveys,
    allOrgSurveysByImplicitOrgId,
    margin,
    onNameChange,
    onFiltersRemove,
    id = true
  } = props;
  const handleFilterRemove = React.useRef(() => {});

  handleFilterRemove.current = () => {
    onFiltersRemove();
  };

  React.useEffect(() => {
    return () => handleFilterRemove.current();
  }, []);

  const choices =
    !loading &&
    getUniqueListBy(
      [].concat.apply(
        [],
        [
          allPublicSurveys?.edges?.map(({ node }) => ({
            label: node.name,
            value: id ? getIntFromString(node.id) : node.name
          })) || [],
          allOrgSurveysByImplicitOrgId?.edges?.map(({ node }) => ({
            label: node.name,
            value: id ? getIntFromString(node.id) : node.name
          })) || []
        ]
      ),
      'value'
    );

  return (
    <SelectField
      margin={margin}
      showSearch
      choices={choices}
      style={{ width: !margin && 200, marginBottom: !margin && 0 }}
      // labelInValue
      filterOption={false}
      onSearch={onNameChange}
      notFoundContent={
        loading ? (
          <div align="center">
            <Spin size="small" />
          </div>
        ) : (
          <Empty />
        )
      }
      {...props}
    />
  );
};

export default compose(
  withLookSurveyState,

  graphql(ALL_PUBLIC_SURVEY_QUERY, {
    options: ({ filter, orderBy }) => {
      return { variables: { ...filter, orderBy } };
    },
    props({ data }) {
      const { loading, error, allPublicSurveys, subscribeToMore, updateQuery } = data;
      return { loading, error, allPublicSurveys, subscribeToMore, updateQuery };
    }
  }),

  graphql(ALL_ORG_SURVEY_BY_IMPLICIT_BY_ORG_ID_QUERY, {
    options: ({ filter, orderBy }) => {
      return { variables: { ...filter, orderBy } };
    },
    props({ data }) {
      const { loading, error, allOrgSurveysByImplicitOrgId, subscribeToMore, updateQuery } = data;
      return { loading, error, allOrgSurveysByImplicitOrgId, subscribeToMore, updateQuery };
    }
  }),

  withLookSurveyFilterUpdating
)(SurveyAutoCompleteEndUser);
