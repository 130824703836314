import gql from 'graphql-tag';
import { VERTICAL_TYPE } from './VerticalType.gql';

export const VERTICAL_BY_VERTICAL_Id_QUERY = gql`
  ${VERTICAL_TYPE}
  query vertical($id: ID!) {
    vertical(id: $id) {
      ...VerticalType
    }
  }
`;



export const VERTICAL_BY_VERTICAL_Id_QUERY_OPTIMIZED = gql`
  
  query vertical($id: ID!) {
    vertical(id: $id) {
      id
      name
      organization {
        id
        name
        description
      }
      description
      heads {
        edges {
          node {
            id
            user {
              id
              firstName
              lastName
              
            }
          }
        }
      }
      
      subvertical {
        edges {
          node {
            id
            name
              organization {
                id
                name
                description
                 }
            
              heads {
        edges {
          node {
            id
            user {
              id
              firstName
              lastName
              
            }
          }
        }
      }
          }
        }
      }
      
      employees {
        edges {
          node {
            id
            user {
              id
              firstName
              lastName
            }
            
          }
        }
      }
    }
  }
`;



