
import gql from "graphql-tag";

export const PREVOIUS_MEETING_LIST = gql`
query allMeetings($member:[ID],$when_Range: [DateTime],$endedAt_Isnull:Boolean){
    allMeetings(member:$member ,when_Range: $when_Range,endedAt_Isnull:$endedAt_Isnull){
        edges{
            node{
                id
                title
                when
            }
        }
    }
}
`