import React from 'react';
import KudosAndFeedbackView from '../components/kudosAndFeedbackView';
import { DASHBOARD_RECIVED_AWARD_LIST } from '../graphql/userDashboardQueries.gql';
import { withApollo } from '@apollo/client/react/hoc';
import { compose } from 'modules/core';
import { FEEDBACK_BY_ID } from 'modules/feedback/graphql/FeedbackById.gql';

const KudosAndFeedbackContainer = ( props ) => {
  const { me, userPermission, client,user_permission } = props;

  // state to store permissions data
  const [ loading, setLoading ] = React.useState( false )
  const [ recievedAwardList, setRecievedAwardList ] = React.useState( {} )
  const [feedback_detail_loading,setFeedbackDetailsLoading]=React.useState(false)
  const [feedback_details,setFeedbackDetails]=React.useState()
  // code to setup the permissions validator
  React.useEffect( () => {
    if ( user_permission?.kudos_permission&&user_permission?.feedback_permission ) {
      // @ts-ignore
      getBadgeFamilyList( { recipient: me?.employee?.id, feedbackTo: me?.employee?.id, orderBy: [ "-createdAt", "-updatedAt" ] } )
    }
  }, [ userPermission ] )

  const getBadgeFamilyList = async ( filterValue ) => {
    setLoading( true )
    const { data } = await client.query( {
      query: DASHBOARD_RECIVED_AWARD_LIST,
      variables: { ...filterValue },
      fetchPolicy: "network-only"
    } );
    if ( data ) {
      setRecievedAwardList(
        {
          badges: data?.badges?.edges?.map( ( { node } ) => node ),
          feedbacks: data?.feedbacks?.edges?.map( ( { node } ) => node )
        }
      )
    }
    setLoading( false )
  }

  const getFeedbackDetails = async (id) => {
    setFeedbackDetailsLoading(true)
    try {
      const { data } = await client.query({
        query: FEEDBACK_BY_ID,
        variables: { id: id },
        fetchPolicy: 'network-only'
      });

      if (data?.feedbackById) {
        setFeedbackDetails(data?.feedbackById)
      }
    } catch (e) {
    }
    setFeedbackDetailsLoading(false)
  }
  
  return (
    <>
      {user_permission?.kudos_permission&&user_permission?.feedback_permission&&<KudosAndFeedbackView 
      feedbackPermission={{requried_permission:user_permission?.feedback_permission,okr_permission:user_permission?.okr_permission,kudos_permission:user_permission?.kudos_permission}}
       recievedAwardList={recievedAwardList} 
       loading={loading}
       feedback_detail_loading={feedback_detail_loading}
       feedback_details={feedback_details}
       getFeedbackDetails={(filter)=>getFeedbackDetails(filter)}
        {...props} />}
    </>
  )
}
export default compose( withApollo )( KudosAndFeedbackContainer )