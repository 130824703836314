import gql from "graphql-tag";


export const BEHAVIOR_ANCHOR_RATING_BY_ID_MAIN_COMPETECNY_EMPLOYEE_LEVEL = gql `
query behaviourallyAnchoredRatingById($id: ID!){
    behaviourallyAnchoredRatingById(id: $id){
        id
        mainCompetency {
          id
          title
          behaviourallyanchoredratingSet{
            edges {
              node {
                id
                employeeLevel {
                  id
                  name
                }
              }
            }
          }
        }
    }
}
`
export const BEHAVIOR_ANCHOR_RATING_BY_ID_SUB_COMPETECNY_EMPLOYEE_LEVEL = gql `
query behaviourallyAnchoredRatingById($id: ID!){
    behaviourallyAnchoredRatingById(id: $id){
        id
        mainCompetency {
          id 
          competencySet{
            edges {
              node {
                id
                title
                behaviourallyanchoredratingSet{
                  edges {
                    node {
                      id
                      employeeLevel {
                        id
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
    }
}
`

export const BEHAVIOR_ANCHOR_RATING_BY_ID_MAIN_COMPETECNY = gql `
query behaviourallyAnchoredRatingById($id: ID!,$employeeLevel: ID){
    behaviourallyAnchoredRatingById(id: $id){
        id
        mainCompetency {
          id
          behaviourallyanchoredratingSet(employeeLevel:$employeeLevel) {
            edges {
              node {
                id
                behaviourExample
                employeeLevel {
                  id
                }
                ratingScale {
                  id
                  name
                }
              }
            }
          }
        }
    }
}
`

export const BEHAVIOR_ANCHOR_RATING_BY_ID_SUB_COMPETECNY = gql `
query behaviourallyAnchoredRatingById($id: ID!,$employeeLevel: ID,$comp_id: ID){
    behaviourallyAnchoredRatingById(id: $id){
        id
        mainCompetency {
          id 
          competencySet(id:$comp_id) {
            edges {
              node {
                id
                behaviourallyanchoredratingSet(employeeLevel:$employeeLevel){
                  edges {
                    node {
                      id
                      behaviourExample
                      employeeLevel {
                        id
                      }
                      ratingScale {
                        id
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
    }
}
`


export const COMPETENCY_BY_ID = gql `
query competencyById($id:ID!){
  competencyById(id:$id){
    id
    title
    behaviourallyanchoredratingSet {
      edges {
        node {
          id
          employeeLevel {
            id
            name
          }
          ratingScale {
            id
            name
          }
          behaviourExample
        }
      }
    }
  }
  
}
`


