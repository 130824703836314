import React from 'react';

import { AdminLayout } from '../../look';
import ROUTE from '../route';

import ResponseFormComponent from './ResponseFormComponent';

const AddResponseView = props => {
  return (
    <>
      <AdminLayout
        table={false}
        active={ROUTE.employee}
        title="Add Response"
        FormComponent={<ResponseFormComponent {...props} />}
      ></AdminLayout>
    </>
  );
};

export default AddResponseView;
