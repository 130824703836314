import new_back_arrow from '../assets/new_back_arrow.svg'
import dropdown_Arrow from '../assets/dropdown_Arrow.svg'
import menu_icon from '../assets/competency-menu-icon.svg'
import table_expand_arrow from '../assets/el-table-arrow.svg'
import competency_delete_icon from '../assets/competecny-assessment-delete-icon.svg'
import icon_filter from '../assets/icon_filter.svg'
import filter_remove from '../assets/filter-close.svg'
import dropdown_submenu from '../assets/dropdown_submenu.svg'
import competency_edit from '../assets/competency-assessment-edit-new.svg'
import next_icon from '../assets/ca-next-arrow-icon.svg'
import prev_icon from '../assets/ca-previous-arrow-icon.svg'
import edit_read from '../assets/ca-edit-read-icon.svg'
export const competency_assessment_home_icon ={
    new_back_arrow,
    dropdown_Arrow,
    menu_icon,
    competency_edit,
    next_icon,
    prev_icon,
    edit_read
}

export const ca_create_icon ={
    new_back_arrow,
    dropdown_Arrow,
    table_expand_arrow
}

export const ca_detail_icons ={
    new_back_arrow,
    dropdown_Arrow,
    competency_delete_icon,
    icon_filter,
    filter_remove,
    dropdown_submenu
}