import React from "react";
import { Spin } from "antd";

import { compose } from "../../core";

import EditTeamView from "../components/editTeamView";
import { ButtonsCatch, getIntFromString, withMarkdownFieldValue } from "../../look";
import ROUTE from "../route";

import { withEditTeam, withTeam, withTeamByTeamId } from "../../organization/containers/OrganizationOperation";
import { CKEditorFieldName } from "../components/teamFormComponent";

const EditTeam = props => {
    const { history, editTeam, teamByTeamId } = props;
    const [submitLoading, setSubmitLoading] = React.useState(false);

    const handleSubmit = async (values, item) => {
        setSubmitLoading(true);

        try {
            const response = await editTeam(
                withMarkdownFieldValue(CKEditorFieldName, {
                    id: getIntFromString(teamByTeamId.id),
                    ...values,
                    verticalId: getIntFromString(teamByTeamId.vertical.id)
                })
            );
            response &&
                ButtonsCatch(
                    item,
                    `${ROUTE.teamDetailLink}${getIntFromString(teamByTeamId.id)}`,
                    history,
                    item === undefined
                        ? window.location.pathname
                        : `${ROUTE.verticalDetailLink}${getIntFromString(response.vertical.id)}?args=openmodal`
                );
        } catch (e) {
            throw new Error(e.message);
        }
        setSubmitLoading(false);
    };

    return (
        <Spin spinning={submitLoading} size="large">
            <EditTeamView onSubmit={handleSubmit} {...props} />
        </Spin>
    );
};

export default compose(
    // withTeam,
    withTeamByTeamId,
    withEditTeam
)(EditTeam);
