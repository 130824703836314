import { Row ,Tooltip} from "antd";
import React from "react";
import User_profile from '../../assets/user-profile.svg'
import Call_Made from '../../assets/call-made.svg'
import OkrPerformanceContainer from "modules/Objective-keyresult/formComponents/Container/OkrPerformancePopupContainer";

const EmployeeList =(props)=>{
    const{TeammemberList,me,styleName,dateRangeTofilter}=props
    const[openDetail,setOpenDetail]=React.useState(false)
    const[selectedMember,setSelectedMember]=React.useState()
    const openDetails =(item)=>{
        setOpenDetail(true)
        setSelectedMember(item)
    }

    return (
        <>
            <div style={{width:'100%',boxSizing:"border-box"}}>
                {TeammemberList?.length&&TeammemberList?.map((item)=>(
                    <div className="row" style={{width:"100%"}}>
                        <div className={styleName||"table-side-section"}></div>
                        <div className="user-card" style={{width:"100%",minWidth:"220px"}} onClick={()=>openDetails(item)}>
                            <div style={{display:"flex",flexDirection:"row",gap:"10px",flexWrap:"nowrap",alignItems:"center"}}>
                                <img style={{width:"40px",height:"40px",borderRadius:"50%"}} src={item?.profileImage||User_profile} alt="" />
                                <Tooltip title={item?.name}>
                                <h4 className="graph-title" style={{paddingLeft:"0px",fontWeight:"600",textAlign:'left',margin:0,whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden",maxWidth:'180px'}}>{item?.name}</h4>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
           
            {openDetail&&<OkrPerformanceContainer dateRangeTofilter={dateRangeTofilter} visible={openDetail} {...props} selectedMember={selectedMember} onClose={()=>{setOpenDetail(false);setSelectedMember(null)}}/>}
        </>
    )
}

export default EmployeeList