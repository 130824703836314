import gql from 'graphql-tag';
import { COMMITMENT_TYPE_CONNECTION } from './CommitmentTypeConnection.gql';

export const GET_COMMITMENT_RECOMMENDATION_QUERY = gql`
  ${COMMITMENT_TYPE_CONNECTION}
  query getCommitmentRecommendations($first: Int, $offset: Int, $orderBy: [String], $description_Icontains: String) {
    getCommitmentRecommendations(
      first: $first
      offset: $offset
      orderBy: $orderBy
      description_Icontains: $description_Icontains
    ) {
      ...CommitmentTypeConnection
    }
  }
`;
