import gql from 'graphql-tag';
import { GROUP_TYPE_CONNECTION } from 'modules/survey/graphql/GroupTypeConnection.gql';

export const UNAUTH_VERIFY_TOKEN = gql`
  query verifyToken($token: String!) {
    verifyToken(token: $token) {
        id
        createdBy{
            id
            firstName
            lastName
        }
        user{
            id
            firstName
            lastName
        }
        survey{
            id
            name
            termsConditions
            groupSet{
              edges{
                node{
                  id
                  name
                  label
                  matrix
                  showInResult
                  showAvgInResult
                       helpText
                  sequence
                  questionSet{
                    edges{
                      node{
                        id
                        questionText
                        questionAddress
                        required
                        helpText
                        sequence  
                        choiceSet{
                          edgeCount
                          pageInfo{
                            endCursor
                            hasNextPage
                            hasPreviousPage
                            startCursor
                          }
                          totalCount
                          edges{
                            node{
                              id
                              inputType
                              label
                              sequence
                              value
                            }
                          }
                        }            
                      }
                    }
                  }
                }
              }
            }
        }
        invited
        dateCreated
        message
        valid
        responseSourceFor{
          user{
            firstName
            lastName
          }
        }
      }
  }
`;
