// @ts-nocheck
import React from 'react'
import { Col,Spin,Tooltip } from "antd";
import transparent_expansion from '../../assets/transparent_expansion.svg'
import Verticl_link from '../../assets/vertical-link.svg'
import OkrVerticalContainerPopup from '../formComponents/Container/OkrVerticalContainerPopup';
import ORGSubVerticalAndTeamsContainer from '../container/OrgSubVerticalandTeamContainer'
const VerticalTeamView =(props)=>{
    const {me,dateRangeTofilter,setdataSourceLoader,dataSourceLoader,dataSource}=props
    const[selectedVertical,setselectedVertical]=React.useState()
    const[subverticalTab,setsubverticalTab]=React.useState()
  const ActionPannal=(item)=>{
    if(item.subvertical||item.teamSet){
        setsubverticalTab(item)
    }
    else{
        setselectedVertical(item)
    }
  }
  
  
    return (
     <>
      {selectedVertical&&<OkrVerticalContainerPopup dateRangeTofilter={dateRangeTofilter} visible={selectedVertical} me={me} verticalDetailModal={selectedVertical} onClose={()=>{setselectedVertical(null)}}/>}
      <Spin spinning={dataSourceLoader}>
        <div style={{minHeight:"100%"}}>
           {
            !subverticalTab?
            <>
            <div style={{width:"100%",display:"flex",flexDirection:"row",flexWrap:"nowrap",gap:"5px",justifyContent:"space-between"}}>
            
             
                <div style={{width:"28px",height:"5px"}}></div>
           
                <Col style={{width:"100%"}}>
                <h4 className="chart-title" >
                Verticals
                </h4>
              
                </Col>
                <div style={{width:"2px",height:"2px"}}></div>
            </div>
            <div className="performamce-overFlow" style={{maxHeight:"500px",paddingBottom:"10px",height:"100%",minHeight:"430px",paddingTop:"30px"}}>
                <div style={{display:"flex",flexDirection:"column",gap:"15px"}} >
                {
                dataSource?.edges?.map((item)=>(
                    
                    item.type==="parent-vertical"&&
                    <>
                    <div className="user-card user-card-attribute" onClick={()=>{ActionPannal(item)}} style={{margin:'0px 10px'}}>
                    <div style={{width:"100%",display:"flex",flexDirection:"row",flexWrap:"nowrap",gap:"0px",alignItems:"center"}}>
                    <div style={{width:"68px",minWidth:"68px"}}>
                        <img src={transparent_expansion} alt="" />
                        <img style={{width:"33px",height:"33px",marginLeft:"10px"}} src={Verticl_link} alt="" />
                    </div>
                    <Tooltip title={item?.name}>
                    <h4 className="graph-title" style={{padding:"0px",fontWeight:"600",textAlign:'left',margin:0}}>{item?.name} </h4>
                    </Tooltip>
                    </div>
                    <div style={{width:"fit-content",maxWidth:"fit-content",minWidth:"fit-content"}}>
                    <h4 className="member-progress" style={{margin:0}}>
                        {item?.progress}
                    </h4>
                    </div>
                    </div>
                 
                    </>
                ))
                }
               
                </div>
            </div>
            </>:
            <ORGSubVerticalAndTeamsContainer setselectedVertical={setselectedVertical} dateRangeTofilter={dateRangeTofilter} me={me} setdataSourceLoader={(e)=>{setdataSourceLoader(e)}} verticalDetail={subverticalTab}  setsubverticalTab={(e)=>{setsubverticalTab(e)}} />
           }
           
        </div>
        </Spin>
      </>
    )
  };

export default VerticalTeamView