import gql from 'graphql-tag';

export const UPDATE_FEEDBACK_MUTATION = gql`
  mutation updateFeedback($id:ID!,$answers: [AnswerInput]!,$detailFillIn: Boolean,$feedbackType: String,$okrId: Int) {
    updateFeedback(id:$id,answers:$answers,detailFillIn:$detailFillIn,feedbackType:$feedbackType,okrId:$okrId) {
        feedback{
            id
            badgeAward{
              edges{
                node{
                  id
                  badge{
                    id
                  }
                }
              }
            }
          }
    }
  }
`;
