import gql from 'graphql-tag';

export const ADD_REDINESS = gql`
mutation createReadiness($readinessData: ReadinessInput!,$readinessLevelData: [ReadinessLevelInput]!){
  createReadiness(readinessData: $readinessData,readinessLevelData: $readinessLevelData){
    readiness{
      id
    }
  }
}
`