import { Button, Modal } from 'antd';
import React from 'react';
import readiness_close_icon from '../../../assets/readiness-close-icon.svg'
import win_bg from '../../../assets/learn-icons/win-soul.gif'
import page_route from 'modules/page/route';
import { soul_type } from 'modules/page/containers/homePageContent';
const SoulCompletedView = (props) => {
    const {show_view,onClose,navigateRoute}=props
    return (
    <Modal
        visible={show_view}
        footer={false}
        closable={false}
        centered={true}
        destroyOnClose={true}
        className="congrats-modal-value">
        <div style={{ width: '100%' }}>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: '100%', padding: '1em' }}>
                <div></div>
                <img src={readiness_close_icon} alt="readiness_close_icon" onClick={() => onClose()} style={{ cursor: "pointer",marginTop:'1em',marginRight:'1em'}} />
            </div>
            <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center', gap: '1em',width:'100%' }}>
                <img src={win_bg} alt="win_bg" style={{width:"22em"}}/>
                <h2 className="landing-card-title" style={{ fontWeight: 700, fontSize: "2.5em", textAlign: "center" }}>Congratulations!!</h2>
                <h4 className="landing-card-title" style={{ fontWeight: 600, fontSize: "1.75em", textAlign: "center" }}>You have complete the <br/> Soul Identity</h4>
                <Button className='learn-active-button' onClick={()=>navigateRoute(page_route.home?.replace(":type",soul_type.impact_narrative))}>Back to impact narrative</Button>
            </div>
        </div>
    </Modal>
    )
}
export default SoulCompletedView
