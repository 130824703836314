import React from "react";
import warning_icon from '../../assets/warning-icon.svg'
import {Button} from 'antd'
const WarningModal =(props)=>{
    const {onClose,onGoBack,title,delete_warning}=props
    return (
        <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",gap:'1.5em'}}>
            <img src={warning_icon} alt="warning_icon" />
            <h4 className="readiness-waring-title">{title}</h4>
            {delete_warning?<div style={{display:'flex',flexDirection:"row",gap:'1em',justifyContent:"center",alignItems:"center",flexWrap:"wrap"}}>
                <Button className="readiness-cancel-btn" onClick={()=>onClose()}>CANCEL</Button>
                <Button className="readiness-delete-btn" onClick={()=>onGoBack()}>DELETE</Button>
            </div>:<div style={{display:'flex',flexDirection:"row",gap:'1em',justifyContent:"center",alignItems:"center",flexWrap:"wrap"}}>
                <Button className="readiness-cancel-btn" onClick={()=>onClose()}>NO</Button>
                <Button className="readiness-save-btn" onClick={()=>onGoBack()}>YES</Button>
            </div>}
        </div>
    )
}

export default WarningModal