import React from "react";
import CompareResponseView from "../components/compare-response-view";
import { EMPLOYEE_BY_ID_RESPONSE } from "../graphql/CompletedSurvey.gql";
import { compose } from "modules/core";
import { withApollo } from '@apollo/client/react/hoc';
const CompareResponseContainer =(props)=>{
    const {selected_employee_list,client}=props
    const [selected_employees_response,setSelectedEmployeesResponse]=React.useState()
    const [response_loading,setResponseLoading] = React.useState(true)
    React.useEffect(() => {
        const fetchEmployeeReports = async () => {
            if (selected_employee_list) {
                try {
                    setResponseLoading(true)
                    let emp_response ={
                        emp_1: await compareReport(selected_employee_list?.employee_1?.id),
                        emp_2: await compareReport(selected_employee_list?.employee_2?.id),
                    };
                    setSelectedEmployeesResponse(emp_response)
                    setResponseLoading(false)
                } catch (error) {
                    console.error("Error fetching reports:", error);
                }
            }
        };
        fetchEmployeeReports();
    }, [selected_employee_list]);

    const compareReport =async(id)=>{
        try {
            const { data } = await client.query({
                query: EMPLOYEE_BY_ID_RESPONSE,
                variables: {id: id},
                fetchPolicy: 'network-only'
            });
            if (data?.employeeById) {
                const {values,strength,personality,knowledge}=data?.employeeById?.user
                let data_set = {}
                data_set["name"]=data?.employeeById?.user?.firstName
                if(values?.edges[0]?.node?.answerSet?.edges?.length){
                    let answer_set = values?.edges[0]?.node?.answerSet?.edges?.map(({node})=>node)
                    data_set["Values"] = answer_set?.sort((a,b)=>a?.question?.sequence - b?.question?.sequence)
                }

                if(strength?.edges[0]?.node?.answerSet?.edges?.length){
                    let answer_set = strength?.edges[0]?.node?.answerSet?.edges?.map(({node})=>node)
                    data_set["Strengths"] = answer_set?.sort((a,b)=>a?.question?.sequence - b?.question?.sequence)
                }

                if(personality?.edges[0]?.node?.answerSet?.edges?.length){
                    let answer_set = personality?.edges[0]?.node?.answerSet?.edges?.map(({node})=>node)
                    data_set["Personality"] = answer_set?.sort((a,b)=>a?.question?.sequence - b?.question?.sequence)
                }

                if(knowledge?.edges[0]?.node?.answerSet?.edges?.length){
                    let answer_set = knowledge?.edges[0]?.node?.answerSet?.edges?.map(({node})=>node)
                    data_set["Knowledge & Skills"] = answer_set?.sort((a,b)=>a?.question?.sequence - b?.question?.sequence)
                }  
                return data_set;
            } else {
                return null;
            }
        }
        catch(e){
            console.error(e)
        }
    }
    return (
        <>
            <CompareResponseView {...props} selected_employees_response={selected_employees_response} response_loading={response_loading} />
        </>
    )
}

export default compose(withApollo)(CompareResponseContainer);