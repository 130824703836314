import React from 'react';
import { compose } from '../../core';
import AddReadinessView from '../components/AddReadinessView';
import { withApollo } from '@apollo/client/react/hoc';
import {
    withSurvayById,
    withCreateReadiness
} from './ReadinessOperations'
import { getIntFromString, globalPermissionValidator } from 'modules/look';
import { SKILLS_SET } from '../graphql/SkillList.gql';
import { EMPLOYEE_LIST_FILTER_SEARCH } from '../graphql/EmployeeListSearch.gql';
import { readiness_permission } from 'Permissions/readiness.permission';
import NoPermissionView from 'modules/kudos-user/component/NoPermissionComponent';
import { EMPLOYEE_LIST_FILTER } from '../graphql/employeeListfilter.gql';
import {message} from 'antd'
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import readiness_zone_route from '../routes';
const CreateReadinessContainer = props => {
    const { client, me,userPermission,navigateRoute } = props
    const [allOrganizationEmployees,setEmployees] = React.useState()
    const [permission, setpermission] = React.useState()
    const [allEmployee,setallEmployees]= React.useState()
    const [allEmpoloyeeResult, setAllEmpoloyeeResult] = React.useState()
    const [mutationData, setmutationData] = React.useState();
    const [page_loading,setPageLoading]=React.useState(true)
    const [ai_trigger,SetAITrigger] = React.useState()
    let okrReadinessDetails = JSON.parse(localStorage.getItem('orkReadinessDetail'))

    React.useEffect(()=>{
        if (allEmployee && me) {
            let list
            let teamMembersEmployee = [].concat.apply(
              [], allEmployee?.employee?.teamMembersEmployee?.edges.map(({ node }) => node?.managers?.edges.map(({ node }) => node?.user)))
            let teamManager = [].concat.apply(
              [], allEmployee?.employee?.teamManagerEmployee?.edges.map(({ node }) => node?.vertical?.heads?.edges.map(({ node }) => node?.user)))
            let teamManagerEmployee = [].concat.apply(
              [], allEmployee?.employee?.teamManagerEmployee?.edges.map(({ node }) => node?.employees?.edges.map(({ node }) => node?.user)))
            let orgPocEmployee = [].concat.apply(
              [], allEmployee?.employee?.orgPocEmployee?.edges.map(({ node }) => node?.employees.edges.map(({ node }) => node.user)))
            let orgCeoEmployee = [].concat.apply(
              [], allEmployee?.employee?.orgCeoEmployee?.edges.map(({ node }) => node?.employees.edges.map(({ node }) => node.user)))
              let orgVerticalEmployee=[].concat.apply(
                [],allEmployee?.employee?.verticalHeadEmployee?.edges.map(({node})=>node?.employees.edges.map(({node})=>node.user)))
                // let verticalMembersEmployee=[].concat.apply(
                //   [],allEmpoloyee?.employee?.verticalMembersEmployee?.edges.map(({node})=>node?.employees.edges.map(({node})=>node.user)))
                  let verticalHeadEmployee=[].concat.apply(
                    [],allEmployee?.employee?.verticalMembersEmployee?.edges.map(({node})=>node?.heads.edges.map(({node})=>node.user)))
                list=[].concat(teamMembersEmployee,teamManager,teamManagerEmployee,orgPocEmployee,orgCeoEmployee,orgVerticalEmployee,verticalHeadEmployee)
            let data = list.filter((value, index, self) =>
              index === self.findIndex((t) => (
                t?.firstName === value?.firstName && t?.id === value?.id
              )))
            let filtered = data.filter(item => item?.employee?.id !== me?.employee?.id)
            setEmployees(filtered.filter(item => item !== undefined))
           
          }
        }, [allEmployee, me,])
      
        React.useEffect(() => {
            let lastFilter
            let firstFilter
            if (allEmpoloyeeResult?.first) {
              let allEmpoloyee = allEmpoloyeeResult?.first
              if (allEmpoloyee) {
                let list
                let teamMembersEmployee = [].concat.apply(
                  [], allEmpoloyee?.employee?.teamMembersEmployee?.edges.map(({ node }) => node?.managers?.edges.map(({ node }) => node?.user)))
                let teamManager = [].concat.apply(
                  [], allEmpoloyee?.employee?.teamManagerEmployee?.edges.map(({ node }) => node?.vertical?.heads?.edges.map(({ node }) => node?.user)))
                let teamManagerEmployee = [].concat.apply(
                  [], allEmpoloyee?.employee?.teamManagerEmployee?.edges.map(({ node }) => node?.employees?.edges.map(({ node }) => node?.user)))
                let orgPocEmployee = [].concat.apply(
                  [], allEmpoloyee?.employee?.orgPocEmployee?.edges.map(({ node }) => node?.employees.edges.map(({ node }) => node.user)))
                let orgCeoEmployee = [].concat.apply(
                  [], allEmpoloyee?.employee?.orgCeoEmployee?.edges.map(({ node }) => node?.employees.edges.map(({ node }) => node.user)))
                  let orgVerticalEmployee=[].concat.apply(
                    [],allEmpoloyee?.employee?.verticalHeadEmployee?.edges.map(({node})=>node?.employees.edges.map(({node})=>node.user)))
                    // let verticalMembersEmployee=[].concat.apply(
                    //   [],allEmpoloyee?.employee?.verticalMembersEmployee?.edges.map(({node})=>node?.employees.edges.map(({node})=>node.user)))
                      let verticalHeadEmployee=[].concat.apply(
                        [],allEmpoloyee?.employee?.verticalMembersEmployee?.edges.map(({node})=>node?.heads.edges.map(({node})=>node.user)))
                    list=[].concat(teamMembersEmployee,teamManager,teamManagerEmployee,orgPocEmployee,orgCeoEmployee,orgVerticalEmployee,verticalHeadEmployee)
                let data = list.filter((value, index, self) =>
                  index === self.findIndex((t) => (
                    t?.firstName === value?.firstName && t?.id === value?.id
                  )))
                firstFilter = data.filter(item => item?.employee?.id !== me?.employee?.id)?.filter(item=>item!=undefined)
              }
            }
            if (allEmpoloyeeResult?.last) {
              let allEmpoloyee = allEmpoloyeeResult?.last
              if (allEmpoloyee) {
                let list
                let teamMembersEmployee = [].concat.apply(
                  [], allEmpoloyee?.employee?.teamMembersEmployee?.edges.map(({ node }) => node?.managers?.edges.map(({ node }) => node?.user)))
                let teamManager = [].concat.apply(
                  [], allEmpoloyee?.employee?.teamManagerEmployee?.edges.map(({ node }) => node?.vertical?.heads?.edges.map(({ node }) => node?.user)))
                let teamManagerEmployee = [].concat.apply(
                  [], allEmpoloyee?.employee?.teamManagerEmployee?.edges.map(({ node }) => node?.employees?.edges.map(({ node }) => node?.user)))
                let orgPocEmployee = [].concat.apply(
                  [], allEmpoloyee?.employee?.orgPocEmployee?.edges.map(({ node }) => node?.employees.edges.map(({ node }) => node.user)))
                let orgCeoEmployee = [].concat.apply(
                  [], allEmpoloyee?.employee?.orgCeoEmployee?.edges.map(({ node }) => node?.employees.edges.map(({ node }) => node.user)))
                  let orgVerticalEmployee=[].concat.apply(
                    [],allEmpoloyee?.employee?.verticalHeadEmployee?.edges.map(({node})=>node?.employees.edges.map(({node})=>node.user)))
                    // let verticalMembersEmployee=[].concat.apply(
                    //   [],allEmpoloyee?.employee?.verticalMembersEmployee?.edges.map(({node})=>node?.employees.edges.map(({node})=>node.user)))
                      let verticalHeadEmployee=[].concat.apply(
                        [],allEmpoloyee?.employee?.verticalMembersEmployee?.edges.map(({node})=>node?.heads.edges.map(({node})=>node.user)))
                    list=[].concat(teamMembersEmployee,teamManager,teamManagerEmployee,orgPocEmployee,orgCeoEmployee,orgVerticalEmployee,verticalHeadEmployee)
                let data = list.filter((value, index, self) =>
                  index === self.findIndex((t) => (
                    t?.firstName === value?.firstName && t?.id === value?.id
                  )))
                lastFilter = data.filter(item => item?.employee?.id !== me?.employee?.id)
              }
              let user = firstFilter.concat(lastFilter)?.filter(item=>item!=undefined)
              var setObj = new Set(); // create key value pair from array of array
              var result = user.reduce((acc, item) => {
                if (!setObj.has(item?.employee?.id)) {
                  setObj.add(item?.employee?.id)
                  acc.push(item)
                }
                return acc;
              }, [])
              setEmployees(result?.filter(item=>item!=undefined))
            }
          }, [allEmpoloyeeResult])
    React.useEffect(() => {
        if (userPermission?.length) {
            let permission = globalPermissionValidator(readiness_permission, userPermission)
            if(permission?.required_permission){
                setPageLoading(false)
                if(permission?.employee_permission){
                    getEmployee()
                }
            }
            setpermission(permission)
        }
    }, [userPermission])
  
    const getEmployee = async()=>{
        const { data } = await client.query({
            query: EMPLOYEE_LIST_FILTER,
            variables: {},
            fetchPolicy: 'network-only'
          });
          if (data?.allEmpoloyee) {
            let allEmpoloyee = data?.allEmpoloyee
          
            if (allEmpoloyee) {
                setallEmployees(allEmpoloyee)
            }
          }
    }

    const handleSearch = async(value)=>{

        const { data } = await client.query({
          query: EMPLOYEE_LIST_FILTER_SEARCH,
          variables: { user_FirstName: value,user_LastName:value },
          fetchPolicy: 'network-only'
        });
        if (data) {
            let allEmpoloyee = data
            if (allEmpoloyee) {
              setAllEmpoloyeeResult(allEmpoloyee)
            }
          }
      }

      const CREATE_READINESS_DYNAMIC = gql`
      mutation createReadiness {
      ${mutationData?.length>0&&mutationData?.map(
          (item, index) =>
            `create${index}: createReadiness(readinessData:{readinessToId:${item?.readinessData?.readinessToId?item?.readinessData?.readinessToId:getIntFromString(me?.employee?.id)}},readinessLevelData:[
              {
              answers:[
                {questionId: 688, answer: "${item?.data?.answers["q_688"]}"},
                {questionId: 689, answer: "${item?.data?.answers["q_689"]}"},
                {questionId: 690, answer: "${item?.data?.answers["q_690"]}"},
                {questionId: 691, answer: "${item?.data?.answers["q_691"]}"}
              ],
              skillId:${item?.data?.skillId},
              surveyId:${item?.data?.surveyId}
              }]) {
              readiness{
                id
              }
          }
         `
        ).join('')}
      }
  `
  const [addReadinessForSkills, { data, isloading, error }] = useMutation(CREATE_READINESS_DYNAMIC);

      React.useEffect(() => {
        if (mutationData?.length) {
          AddReadiness()
        }
    }, [mutationData])

    const AddReadiness = async () => {
      try {
        const response = await addReadinessForSkills()
        if (response) {
            message.success("Submitted successfully")
            localStorage.removeItem('orkReadinessDetail')
            let params = new URLSearchParams(window.location.search)
            let tab = params.get("tab")
            if(mutationData && !mutationData[0]?.readinessData?.readinessToId){
              navigateRoute(readiness_zone_route?.readinessHome?.replace(":type",'self_assessments'))
            }
            else{
              navigateRoute(readiness_zone_route?.readinessHome?.replace(":type",'assessments'))
            }
        } else {
            localStorage.removeItem('orkReadinessDetail')
        }
      } catch (error) {
        message.error("Error in create Readiness")
        // SetAssignLoading(false)
      }
    }
    return (
        <>
            {
                (permission && permission?.required_permission) && (
                    < AddReadinessView {...props} editView={false}
                    okrReadinessDetails={okrReadinessDetails}
                    allOrganizationEmployees={allOrganizationEmployees}
                    handleSearch={(e)=>{handleSearch(e)}}
                    permission={permission}
                    readinessMutationData={(e)=>{setmutationData(e)}}
                    page_loading={page_loading}
                    ai_trigger={ai_trigger}
                    SetAITrigger={SetAITrigger}
                    ></AddReadinessView>
                )
            }

            {
                (permission && !permission?.required_permission) && (
                    <NoPermissionView />
                )
            }
           
        </>
    );
};
export default compose( withApollo, withSurvayById, withCreateReadiness)(CreateReadinessContainer);