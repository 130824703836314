import gql from 'graphql-tag';
export const SURVEY_RESPONSES = gql`
  query surveyResponses($user: ID,$survey:ID,$orderBy:[String],$first: Int,$survey_Name_Icontains:String) {
    surveyResponses(user: $user,survey:$survey,orderBy:$orderBy,first:$first,survey_Name_Icontains:$survey_Name_Icontains) {
        edges {
            node {
              id
              survey {
                id
                name
              }
              responseDate
            }
          }
    }
  }
`;
