import gql from 'graphql-tag';

export const UPDATE_CONTRIBUTOR_REQUEST_MUTATION = gql`
mutation updateContributorRequestStatus($requestStatus:String!,$id: ID!){
    updateContributorRequestStatus(requestStatus:$requestStatus,id:$id){
        contributor{
            id
          }
    }
}
`