import { Spin } from "antd";
import React from "react";

import { compose } from "../../core";

import { ButtonsCatch, getIntFromString, withMarkdownFieldValue } from "../../look";
import EditTeamView from "../components/EditTeamView";
import ROUTE from "../route";

import { CKEditorFieldName } from "../components/TeamFormComponent";
import { withEditTeam, withTeamByTeamIdOptimized } from "./OrganizationOperation";

const EditTeam = props => {
  const { history, editTeam, Team } = props;
  const [submitLoading, setSubmitLoading] = React.useState(false);

  const handleSubmit = async (values, item) => {
    setSubmitLoading(true);

    try {
      const response = await editTeam(
        withMarkdownFieldValue(CKEditorFieldName, {
          id: getIntFromString(Team.id),
          ...values,
          verticalId: getIntFromString(Team.vertical.id)
        })
      );
      response &&
        ButtonsCatch(
          item,
          `${ROUTE.teamDetailLink}${getIntFromString(Team.id)}`,
          history,
          item === undefined
            ? window.location.pathname
            : `${ROUTE.verticalDetailLink}${getIntFromString(response.vertical.id)}?args=openmodal`
        );
    } catch (e) {
      throw new Error(e.message);
    }
    setSubmitLoading(false);
  };

  return (
    <Spin spinning={submitLoading} size="large">
      <EditTeamView onSubmit={handleSubmit} teamByTeamId = {Team} {...props} />
    </Spin>
  );
};

export default compose(
  // withTeam,
  withTeamByTeamIdOptimized,
  withEditTeam
)(EditTeam);
