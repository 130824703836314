import { coaching_engagement_permission } from "./coachingEngagement.permission";
import { feedback_permission } from "./feedback.permission";
import { kudos_permission } from "./kudos.permission";
import { okr_permission } from "./okr.permission";
import { oneonone_permission } from "./oneonone.permission";
import { soultip_permission } from "./soul-tip.permission";
import { survey_permission } from "./survey.permission";
import { token_360_permission } from "./token.permission";


export const user_dashboard_permissions ={
    feedback_permission:feedback_permission?.requried_permission,
    kudos_permission:kudos_permission?.requried_permission,
    oneonone_permission:oneonone_permission?.requried_permission,
    award_badge_permission:kudos_permission?.can_award_badge,
    okr_permission:okr_permission?.requried_permission,
    coaching_engagement_permission:coaching_engagement_permission?.required_permission,
    survey_permission:survey_permission?.required_permission,
    soul_tip_permission:soultip_permission.required_permission,
    token_survey_permission:token_360_permission.required_permission
}