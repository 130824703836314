import gql from 'graphql-tag';
import { FAQ_CATEGORY_TYPE } from './FaqCategoryType.gql';

export const EDIT_FAQ_CATEGORY = gql`
  ${FAQ_CATEGORY_TYPE}
  mutation editFaqCatgory($description: String, $id: ID!, $name: String!, $active: Boolean!) {
    updateFaqCategory(description: $description, id: $id, name: $name, active: $active) {
      category {
        ...FaqCategoryType
      }
    }
  }
`;
