import React from 'react';

import { AdminLayout } from '../../look';
import ROUTE from '../route';

import SurveyFormComponent from './SurveyFormComponent';

const AddSurveyView = props => {
  return (
    <>
      <AdminLayout
        table={false}
        active={ROUTE.employee}
        title="Add Survey"
        FormComponent={<SurveyFormComponent {...props} />}
      ></AdminLayout>
    </>
  );
};

export default AddSurveyView;
