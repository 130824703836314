import React, { useState } from 'react';

import { compose } from '../../core';
import { getIntFromString } from '../../look';
import { withMe } from '../../user/containers/UserOperations';
import { withApollo } from '@apollo/client/react/hoc';
import CommitmentView from '../components/CommitmentView';

import {
  withGetAllDefaultCommitmentsProgress,
  withGetAllCustomUserCommitments,
  withEditCustomUserCommitment,
  withEditDefaultToolCommitment
} from './DashboardOperations';
import {subscribeToCustomUserCommitments,subscribeToDefaultCommitmentProgress,subscribeToCustomUserCommitmentsForReporting,subscribeToSefaultCommitment} from './DashboardSubscriptions'
import { GET_ALL_CUSTOM_USER_COMMITMENTS, GET_ALL_CUSTOM_USER_COMMITMENTS_SUBSCRIPTION } from '../graphql/GetAllCustomUserCommitments.gql';
import { detail } from 'modules/feedback/route';

const PersonalCommitment = props => {
  const {
    setSubmitLoading,
    getAllCustomUserCommitments,
    getAllDefaultCommitmentsProgress,
    updateDefaultToolCommitment,
    updateCustomUserCommitment,
    subscribeToMore,
    client,
    me
  } = props;

  const [commitmentResponse,setCommitmentResponse]=React.useState([])
  const dataRef =React.useRef(null)
  const m = React.useRef( true )

  let badgeAwardSub = undefined

  React.useEffect( () => {
      return () => {
        if ( badgeAwardSub ) {
          badgeAwardSub.unsubscribe();
        }
      }
    } )
  
    React.useEffect( () => {
      return () => {
        m.current = false
      }
    }, [] )
  
    React.useEffect( () => {
      m.current = true
  
    }, [] )
  

  const getSubscribedToUserCommitment = async () => {
    const { data } = await client.query({
      query: GET_ALL_CUSTOM_USER_COMMITMENTS,
      variables: {},
      fetchPolicy:"network-only"
    });
    if(data){
      setCommitmentResponse(data?.getAllCustomUserCommitments?.edges)
      dataRef.current =data?.getAllCustomUserCommitments?.edges
    }
    badgeAwardSub = await client.subscribe({

      query: GET_ALL_CUSTOM_USER_COMMITMENTS_SUBSCRIPTION,
      fetchPolicy: 'network-only',
    }).subscribe({
      next(result) {
        let cachedData = dataRef.current
        switch (result?.data?.toolsCustomUserCommitmentSubscription?.mutation) {
          
          case 'CREATE':  
          if ( m.current ) {
            const created = [ ...cachedData, {node:result?.data?.toolsCustomUserCommitmentSubscription?.customUserCommitment} ]
            setCommitmentResponse(created)
            dataRef.current=created
          }
            break
          case 'DELETE':
            if ( m.current ) {
            const deleteData =cachedData.filter(({node}) =>
              getIntFromString(node?.id) === getIntFromString(result?.data?.toolsCustomUserCommitmentSubscription?.customUserCommitment?.id)
                ? false
                : true
            )
            setCommitmentResponse(deleteData)
            dataRef.current=deleteData
            }
            break;
            case 'UPDATE':
              if ( m.current ) {
              const index = cachedData.findIndex(({node}) =>getIntFromString(node.id) === getIntFromString(result?.data?.toolsCustomUserCommitmentSubscription?.customUserCommitment?.id));
              cachedData[index] = {node:result?.data?.toolsCustomUserCommitmentSubscription?.customUserCommitment}
              setCommitmentResponse(cachedData)
              dataRef.current=cachedData
              }
              break;
          
          default:
            break
        }
      }
    })
  }

    React.useEffect(() => {
      const subscribe =subscribeToDefaultCommitmentProgress(subscribeToMore /* , props.filter */) ||
      subscribeToCustomUserCommitmentsForReporting(subscribeToMore /* , props.filter */) ||
      subscribeToSefaultCommitment(subscribeToMore /* , props.filter */)
      return () => subscribe();
    });

React.useEffect(()=>{
    getSubscribedToUserCommitment()
},[])
  async function handleDefaultCommitmentProgress(values) {
    setSubmitLoading(true);
    try {
      const response = await updateDefaultToolCommitment(values);
      response && setSubmitLoading(false);
    } catch (err) {
      setSubmitLoading(false);
      throw new Error(err.message);
    }
  }

  async function handleCustomUserCommitment(values) {
    setSubmitLoading(true);
    try {
      const response = await updateCustomUserCommitment(values);
      if(response){
        setSubmitLoading(false);
      }
    } catch (err) {
      setSubmitLoading(false);
      throw new Error(err.message);
    }
  }

  return (
    <CommitmentView
      customUserCommitments={commitmentResponse}
      defaultCustomUserCommitments={getAllDefaultCommitmentsProgress}
      onDefaultCommitmentProgress={handleDefaultCommitmentProgress}
      onCustomUserCommitment={handleCustomUserCommitment}
      {...props}
    />
  );
};

export default compose(
  withEditDefaultToolCommitment,
  withGetAllDefaultCommitmentsProgress,
  // withGetAllCustomUserCommitments,
  withEditCustomUserCommitment,
  withApollo
)(PersonalCommitment);
