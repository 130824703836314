import gql from 'graphql-tag';
import { CUSTOM_USER_COMMITMENT_TYPE } from './CustomUserCommitmentType.gql';

export const EDIT_CUSTOM_USER_COMMITMENT = gql`
  ${CUSTOM_USER_COMMITMENT_TYPE}
  mutation updateCustomUserCommitment($description: String, $id: ID!, $title: String!, $completed: Boolean, $toolId: Int!) {
    updateCustomUserCommitment(description: $description, id: $id, title: $title, completed: $completed, toolId: $toolId) {
      customUserCommitment {
        ...CustomUserCommitmentType
      }
    }
  }
`;
