import React from 'react';

import { AdminLayout } from '../../look';
import { employee } from '../route';

import EmployeeFormComponent from './EmployeeFormComponent';

const EditEmployeeView = props => {
  return (
    <AdminLayout
      table={false}
      active={employee}
      title="Edit Employee"
      backLink={`${employee}`}
      FormComponent={props.employeeById && <EmployeeFormComponent {...props} />}
    />
  );
};

export default EditEmployeeView;
