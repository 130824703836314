import gql from 'graphql-tag';


export const DELETE_FEEDBACK_MUTATION = gql`

  mutation deleteFeedback($id: ID!) {
    deleteFeedback(id:$id) {
        feedback  {
            id
      }
    }
  }
`;
