import { Button, Modal, Select, Spin, message } from "antd";
import { organization_icons } from "modules/poc-dashboard/icons";
import React from "react";

const ExportReportView = (props) => {
    const { export_view, onCancel, verticals, org_survey_list, exportData, export_loading,setExportTitle } = props
    const [selected_vertical, setSelectedVertical] = React.useState()
    const [selected_survey, setSelectedSurvey] = React.useState()

    const onExport = () => {
        if (selected_vertical && selected_survey) {
            exportData({ vertical_id: selected_vertical, survey_id: selected_survey })
            setExportTitle(`${selected_vertical?.value} ${selected_survey?.value} Report`)
        }
        else {
            message.error("please select the required fields")
        }
    }
    return (
        <>
            <Modal
                visible={export_view}
                centered={true}
                footer={false}
                destroyOnClose={true}
                closable={false}
                style={{ maxWidth: "40%" }}
                width={"90%"}>
                <Spin spinning={export_loading}>
                    <div style={{ display: "flex", flexDirection: 'column', gap: '1em', padding: '1em' }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "1em" }}>
                            <h4 className="poc-dashboard-card-title" style={{ fontSize: "1.5em", fontWeight: 600, whiteSpace: "normal" }}>Export Report </h4>
                            <img src={organization_icons.close_icon} alt="close_icon" style={{ cursor: "pointer" }} onClick={() => onCancel(false)} />
                        </div>
                        <div style={{ marginTop: "1em", width: "100%" }}>
                            <h4 className='vertical-form-label'><span style={{ color: "#E86161" }}>*</span>Vertical</h4>
                            {/* <PocCustomDropdown
                            choice_list={verticals}
                            onSelect={(value)=>setSelectedVertical(value)}/> */}
                            <Select
                                className='org-poc-select-filed'
                                showSearch={true}
                                style={{ width: "100%", marginTop: "1em" }}
                                placeholder="Select Vertical"
                                onChange={(value, options) => setSelectedVertical(options)}
                            >
                                {
                                    verticals?.map(item => {
                                        return <Select.Option className="org-option" key={item?.id} value={item?.name} >{item?.name}</Select.Option>
                                    })
                                }
                            </Select>
                        </div>
                        <div style={{ marginTop: "1em" }}>
                            <h4 className='vertical-form-label'><span style={{ color: "#E86161" }}>*</span>Survey</h4>
                            <Select
                                className='org-poc-select-filed'
                                showSearch={true}
                                style={{ width: "100%", marginTop: "1em" }}
                                placeholder="Select Survey"
                                onChange={(value, options) => setSelectedSurvey(options)}
                            >
                                {
                                    org_survey_list?.map(item => {
                                        return <Select.Option className="org-option" key={item?.id} value={item?.name} >{item?.name}</Select.Option>
                                    })
                                }
                            </Select>
                        </div>
                        <div style={{ width: "100%", display: 'flex', gap: "1em", justifyContent: 'end', alignItems: "center", marginTop: "2em" }}>
                            <Button className="organization-cancel-btn" onClick={() => onCancel(false)}>Cancel</Button>
                            <Button className="organization-save-btn" onClick={() => onExport()}>Export</Button>
                        </div>
                    </div>
                </Spin>
            </Modal>
        </>
    )
}

export default ExportReportView;