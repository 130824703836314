import { withUpdateContributorWeights } from 'modules/Objective-keyresult/container/objective-keyresult-operations';
import React from 'react';
import { compose } from '../../../core';
import AddWeight from '../addWeight';
import ProgessUpdate from '../progeressUpdate';
import { withApollo } from '@apollo/client/react/hoc';
import {CHILD_OKR_CONTRIBUTOR_WEIGHT_QUERY } from 'modules/Objective-keyresult/graphql/ChildOKRContribution.gql';
const AddWeightContainer = props => {

    const {updateContributor,okrId,client,me } = props
    const [loading,setloading]= React.useState(true)
    const [allokrweights,setokrweights]= React.useState()

    const getOKRList=async ()=>{
        setloading(true)
        const { data } = await client.query({
          query:CHILD_OKR_CONTRIBUTOR_WEIGHT_QUERY,
          fetchPolicy: 'network-only',
          variables: {okr_Parent:okrId,createdBy:me?.employee?.id} 
        });
       
        if(data?.contributors){
            setokrweights(data?.contributors)
            setloading(false)
        }
    }

    return (
        <>
            <AddWeight {...props} allokrweights={allokrweights} setokrweights={(e)=>{setokrweights(e)}} loading={loading} setloading={(e)=>setloading(e)} getOKRList={(e)=>{getOKRList()}} />
        </>
    );
};

export default compose(withApollo,withUpdateContributorWeights)(AddWeightContainer);
