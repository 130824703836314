import gql from 'graphql-tag';

export const ORGANIZATION_STATE_QUERY = gql`
  query organizationState {
    organizationState @client {
      pagination {
        first
        offset
      }
      orderBy
      filter {
        name_Icontains
        description_Icontains
        email_Icontains
        poc
        ceo
        organization
        vertical
        subvertical
        user
      }
    }
  }
`;
