import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Card, Modal, Row, Empty, Spin, Tabs } from "antd";
import { NewTab } from "./MAppToolkit";
import styled from "styled-components";

import { compose } from "../../core";
import { getIntFromString } from "../../look";
import {
  withAllOrgSurveysByImplicitOrgIdQuery,
  withAllPublicSurveysQuery,
} from "../../survey/containers/SurveyOperations";
import { withMe } from "../../user/containers/UserOperations";

import SURVEY_ROUTE from "../../survey/route";

const TaskAssisment = (props) => {
  const { me, loading: publicSurveyLoading, allPublicSurveys } = props;
  const isEmployee = me?.employee;
  const [visible, setVisible] = useState(false);

  const choices =
    !publicSurveyLoading &&
    [].concat.apply(
      allPublicSurveys?.edges?.map(({ node }) => ({
        name: node.name,
        activeFrom: node.activeFrom,
        id: getIntFromString(node.id),
      })) || []
    );

  const RenderAllOrgSurveyData = withAllOrgSurveysByImplicitOrgIdQuery(
    ({ allOrgSurveysByImplicitOrgIdLoading, allOrgSurveysByImplicitOrgId }) => {
      return (
        <Spin spinning={allOrgSurveysByImplicitOrgIdLoading}>
          <RenderTabData property={allOrgSurveysByImplicitOrgId} />
        </Spin>
      );
    }
  );

  const RenderPublicSurveyData = withAllPublicSurveysQuery(
    ({ loading, allPublicSurveys }) => {
      return (
        <Spin spinning={loading}>
          <RenderTabData property={allPublicSurveys} />
        </Spin>
      );
    }
  );

  const RenderTabData = ({ property }) => {
    return property && property.edges.length > 0 ? (
      property.edges.map(({ node }, id) => (
        <>
          <Row gutter={24} align="middle">
            <Col span={2} align="center">
              <span
                style={{
                  borderRadius: "50%",
                  padding: "10px 13px",
                  border: "1px solid #CACACA",
                }}
              >
                {id + 1}
              </span>
            </Col>
            <Col span={21}>
              <Link
                to={`${SURVEY_ROUTE.surveyQuizLink}${getIntFromString(
                  node.id
                )}`}
                target="_blank"
              >
                <Card
                  bordered={false}
                  style={{
                    boxShadow: "5px 0px 25px 3px rgba(32, 32, 32, 0.1)",
                    borderRadius: "10px",
                  }}
                  bodyStyle={{ padding: "12px" }}
                >
                  {node.name}
                </Card>
              </Link>
            </Col>
            <Col span={1} />
          </Row>
          <br />
        </>
      ))
    ) : (
      <div style={{ margin: "100px" }}>
        <Empty />
      </div>
    );
  };

  return (
    <>
      <Col span={24}>
        <Spin spinning={!choices}>
          {/* <Tooltip title="This feature is comming soon!"> */}
          <Card
            bodyStyle={{
              boxShadow: "5px 0px 25px 3px rgba(32, 32, 32, 0.1)",
              borderRadius: "10px",
              paddingBottom: 0,
              paddingTop: 0,
              cursor: "pointer",
            }}
            bordered={true}
            hoverable
            onClick={() => setVisible(true)}
          >
            <h2
              align="center"
              style={{
                margin: "1em 0",
                fontWeight: "normal",
                color: visible ? "#881FCC" : "#222",
              }}
            >
              Take Assessment
            </h2>
          </Card>
          {/* </Tooltip> */}
        </Spin>
      </Col>

      <Modal
        footer={null}
        centered
        visible={visible}
        width={"50%"}
        destroyOnClose={true}
        bodyStyle={{ background: "#F9F9F8", minHeight: "50vh" }}
        onCancel={() => setVisible(false)}
        // bodyStyle={{ padding: '0px' }}
      >
        <h3>Take Assessment</h3>
        <br />
        <Row>
          <Col span={24}>
            {isEmployee ? (
              <NewTab defaultActiveKey="1">
                <>
                  <Tabs.TabPane tab="Assigned to you" key="1">
                    <RenderAllOrgSurveyData />
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Other Survey" key="2">
                    <RenderPublicSurveyData />
                  </Tabs.TabPane>
                </>
              </NewTab>
            ) : (
              <CustomSingleTab>
                <Tabs.TabPane key="1">
                  <RenderPublicSurveyData />
                </Tabs.TabPane>
              </CustomSingleTab>
            )}
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default compose(withMe)(TaskAssisment);

const CustomSingleTab = styled(Tabs)`
  .ant-tabs-nav {
    display: none;
  }
`;
