import gql from "graphql-tag";

export const CONTRIBUTOR_OKR_WEIGHTS_UNDER_OBJECTIVE_WITH_ID = gql`
query contributors($okr_Parent: ID,$employee: ID,$id: ID!,$tz:String){
    okrById(id:$id){
      id
      title(tz:$tz)
    }
    contributors(okr_Parent:$okr_Parent,role:"OWNER",employee:$employee){
       edges {
      node {
        id
        progress
        employee{
          id
          user{
            id
            firstName
            lastName
           
          }
        }
        okr{
          id
          title
          contributorSet{
            edges{
              node{
                id
      					employee{
                  id
                  user{
                    id
                    firstName
                    lastName
                  }
                }
                
              }
            }
          }
        }
      }
    }
      }
}
`;
