import React from 'react';

import { AdminLayout, getIntFromString } from '../../look';
import ROUTE from '../route';

import OrganizationFormComponent from './organizationFormComponent';

const EditOrganizationView = props => {
    return (
        <AdminLayout
            table={false}
            active={ROUTE.organization}
            title="Edit Organization"
            backLink={`${ROUTE.organizationDetailLink}${props.organizationById && getIntFromString(props.organizationById.id)}`}
            sidebar={true}
            FormComponent={props.organizationById && <OrganizationFormComponent {...props} />}
        />
    );
};

export default EditOrganizationView;
