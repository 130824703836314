import gql from 'graphql-tag';

export const GET_TOOL_ID_FOR_REPORTED_ID = gql`
query getToolForReporteeByToolId($toolId:ID!, $employeeId:ID!, $responseId:ID!) {
    getToolForReporteeByToolId(toolId:$toolId, employeeId:$employeeId, responseId:$responseId) {
        id
        title
        description
        textsection{
            totalCount 
            edges {    
                node {    
                    id    
                    title
                    text   
                    renderedText(employeeId:$employeeId, responseId: $responseId)       
                    sequence  
                }  
            }
        }  
    }

}
`