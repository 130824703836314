import OkrPerformanceView from "../components/okrPerformanceView";
import { compose } from 'modules/core';
import React from 'react';
import { withApollo } from '@apollo/client/react/hoc';
import { localTz } from 'modules/look';
import { ALL_OBJECTIVES_PERFORMANCE_NEW_QUERY, NON_CASCADED_OKRS_PERFORMANCE_PAGINATED } from "../graphql/userDashboardQueries.gql";

const OkrPerformanceContainer =(props)=>{

    const {me,client,user_permission}=props
    const [paginatedOkrLoading, setPaginatedOkrLoading] = React.useState(true);
    const [performanceData,setPerformanceData]=React.useState()
    let currentTab = React.useRef('objective')

    React.useEffect(() => {
        if (user_permission?.okr_permission) {
          setPaginatedOkrLoading(true);
          paginaionLoad(0,currentTab['current'])
        }
      }, [user_permission])

      const getFilters = (page, okrType) => {
        let  filter = {
          orderBy: ["okr__duedate"],
          offset: page*5,
          first: 5,
          employee: me?.employee?.id,
          // role_In_non_cascaded: ["OWNER"],
          requestStatus: "ACCEPTED",
          savedOkrType: okrType==='objective'?"OBJECTIVE":okrType==='keyresult'?'KEY_RESULT':'MILESTONE',
          tz: localTz
        }
        return filter
      }

      const getOkrs = (okrType,data,pagination) => {
        let obj;
          if(okrType==='objective'){
            obj = {
              edgeCount: data?.AllCascadingObjectivesPaginated?.edgeCount,
              edges:[...data?.AllCascadingObjectivesPaginated?.edges],
              totalCount: data?.AllCascadingObjectivesPaginated?.totalCount,
              pagination:pagination
            }
            
          }
          else if(okrType==='keyresult')  {
            obj = {
              edgeCount:data?.AllNonCascadingObjectivesPaginated?.edgeCount,
              edges: data?.AllNonCascadingObjectivesPaginated?.edges,
              totalCount: data?.AllNonCascadingObjectivesPaginated?.totalCount,
              pagination:pagination
            }
          }
         else {
            obj = {
              edgeCount:data?.AllNonCascadingObjectivesPaginated?.edgeCount, 
              edges:data?.AllNonCascadingObjectivesPaginated?.edges,
              totalCount: data?.AllNonCascadingObjectivesPaginated?.totalCount,
              pagination:pagination
            } 
          }
       
        return obj;
    
      }


    const paginaionLoad = async (page, okrType,pagination=false) => {
        currentTab['current']=okrType
        let filters = getFilters(page, okrType);
        // switch (okrType) {
          if(okrType==='objective'){
            setPaginatedOkrLoading(true);
            const { data } = await client.query({
              query: ALL_OBJECTIVES_PERFORMANCE_NEW_QUERY,
              fetchPolicy: 'network-only',
              variables: filters,
            });
            if (data) {
              let fetchedData=getOkrs(okrType,data,pagination)
              setPerformanceData(fetchedData)
            }
            setPaginatedOkrLoading(false);
            
          }
          else if(okrType==='keyresult'){
            setPaginatedOkrLoading(true);
            const { data } = await client.query({
              query: NON_CASCADED_OKRS_PERFORMANCE_PAGINATED,
              variables: filters,
              fetchPolicy: 'network-only'
            });
    
            if (data) {
              let fetchedData=getOkrs(okrType,data,pagination)
             
              setPerformanceData(fetchedData)
            }
            setPaginatedOkrLoading(false);
            
          }
          else{
            setPaginatedOkrLoading(true);
            const { data } = await client.query({
              query: NON_CASCADED_OKRS_PERFORMANCE_PAGINATED,
              variables: filters,
              fetchPolicy: 'network-only'
            });
            if (data) {
              let fetchedData=getOkrs(okrType,data,pagination)
              setPerformanceData(fetchedData)
            }
            setPaginatedOkrLoading(false);
            
          }
         
    
      }

    return (
        <>
        {user_permission?.okr_permission&&<OkrPerformanceView
        paginatedOkrLoading={paginatedOkrLoading}
        paginatedData={performanceData}
        onPagination={(e, okrType,pagination) => { paginaionLoad(e,okrType,pagination) }}
         {...props}/>}
        </>
    )
}

export default compose(withApollo)(OkrPerformanceContainer)