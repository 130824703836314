import gql from "graphql-tag";

export const OKR_BY_SELECTED_NODE = gql`
query okrById($id: ID!)
{
    okrById(id:$id){
        id
        okrType
        goalStatement
        parent{
          id
        }
        owner{
          id
        }
        progress
        contributorSet(role_In:["ASSIGNED","COLLABORATOR"]){
          totalCount
        }
        childSet{
          totalCount
        }
    }
}
`;