import React from 'react';
import moment from 'moment';
import { Select } from 'antd';
import calender_icon from '../../assets/meeting-calender-icon.svg'
import time_icon from '../../assets/time-icon.svg'
const CustomTimePicker =(props)=>{
    const {onChange,selectedTime,when,onedit,disabled}=props
        const selectRef = React.useRef(null);

    React.useEffect(() => {
        if (selectRef.current && selectRef.current.rcSelect) {
            const selectInstance = selectRef.current.rcSelect;
            const selectedOption = selectInstance.domRef.current.querySelector('.ant-select-selection-selected-value');
            if (selectedOption) {
              selectedOption.scrollIntoView();
            }
          }
        }, []);

    const roundTime =(time)=>{
        let timeValue
        if(moment(time).minute()<45){
            timeValue =moment({
            hour:moment(time).hour(),
            minute: Math.ceil(moment(time).minute() / 15) * 15,
            })
        }
        else{
            let time_diff = 60-Math.ceil(moment(time).minute())
            let new_time =moment(time).add(time_diff, 'minutes')
            timeValue = moment({
            hour:moment(new_time).hour(),
            minute: Math.ceil(moment(new_time).minute() / 15) * 15,
            }) 
        }
        return timeValue
    }
    const options = [];
    const startOfDay = (when && moment(when).isAfter(moment()))? moment().startOf('day'): roundTime(when?._d);
    const endOfDay = moment().endOf('day');
    while (startOfDay.isSameOrBefore(endOfDay)) {
        options.push({
          value: startOfDay.format("h:mm A"),
          label: startOfDay.format('h:mm A'),
        });
        startOfDay.add(15, 'minutes');
    }

    const handleTimeChange = (selectedOption) => {
        onChange(selectedOption);
      };
    return (
        <>
        <Select
            // suffixIcon={<img src={time_icon} alt=""/>}
            ref={selectRef}
            style={{width:"120px"}}
            placeholder='Select Time'
            className="one-on-one-custom-time-picker"
            onChange={handleTimeChange}
            disabled={disabled}
            value={selectedTime ? moment(selectedTime, 'HH:mm A').format('h:mm A') :when&&!onedit&&(moment(moment(when)).day()===moment().day())?roundTime(when?._d).format('h:mm A'):when&&onedit?moment(when).format('h:mm A'):null}>
                {
                options?.map(item => 
                    {
                        return <Select.Option className="one-on-one-select-employee-dropdown-item" key={item?.value} value={item?.value} >{item?.label}</Select.Option>
                    }
                )
                }
        </Select>
        </>
    )
}

export default CustomTimePicker