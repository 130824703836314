import React from 'react';
import ROUTE from './route';
import OneonOneHomeContainer from './containers/oneOnoneHomeContainer'
import AddOneonOneContainer from './containers/AddOneonOneContainer'
import MeetingDetailContainer from './containers/MeetingViewContainer'
import EditMeetingContainer from './containers/EditMeetingContainer'
import MeetingStartContainer from './containers/MeetingStartContainer'
import EditMeetingSeriesContainer from './containers/EditMeetingSeriesContainer';
import OneOnOneEffectivenssContainer from './containers/OneOnOneEffectivenssContainer';
export default [
    {
      name: 'oneOnOneHome',
      path: ROUTE.oneOnOneHome,
      component: OneonOneHomeContainer,
      exact:true,
      protect: true
    },
    {
      name: 'oneOnOneCreate',
      path: ROUTE.addOneonOne,
      component: AddOneonOneContainer,
      exact:true,
      protect: true
    },
    {
      name: 'meetingView',
      path: ROUTE.viewMeeting,
      component: MeetingDetailContainer,
      exact:true,
      protect: true
    },
    {
      name: 'EditMeeting',
      path: ROUTE.EditMeeting,
      component: EditMeetingContainer,
      exact:true,
      protect: true
    },
    {
      name: 'Meeting',
      path: ROUTE.Meeting,
      component: MeetingStartContainer,
      exact:true,
      protect: true
    },
    {
      name:'EditMeetingSeries',
      path:ROUTE.EditMeetingSeries,
      component:EditMeetingSeriesContainer,
      exact:true,
      protect: true
    },
    {
      name:'effectiveness feedback home',
      path:ROUTE.effectivenessHome,
      component:OneOnOneEffectivenssContainer,
      exact:true,
      protect: true
    }

]