import { LeftOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Col, Divider, Drawer, Menu, message, Row, Switch } from 'antd';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { menuItems } from './AdminLayout';
import DeleteIcon from './DeleteIcon';
import EditIcon from './EditIcon';
import { getIntFromString } from './functions';
import MetaTags from './MetaTags';
import RenderCardWithLayout from './RenderCardWithLayout';
// const AdminAffix = styled(Affix)`
const AdminAffix = styled.div`
  /* position: absolute;
  bottom: 0;
  left: 0;
  width: 78vw; */
`;

const CardLayout = props => {
  const {
    children,
    showTitle = true,
    table = undefined,
    active,
    title,
    loading,
    tableData,
    columns,
    onDelete,
    addLink,
    FormComponent,
    onPaginationChange,
    extra,
    selectedRowKeys,
    rowKey = 'id',
    editLink,
    showBack = false,
    onFiltersRemove,
    components,
    onSort,
    backLink,
    specificMutation,
    handleDeleteRecord
  } = props;

  const [visible, setVisible] = React.useState(false);
  const [openKeys, setOpenKeys] = React.useState([]);
  const [checked, setChecked] = React.useState(null);
  const handleFilterRemove = React.useRef(() => { });
  const [showEdit, setShowEdit] = React.useState(false)
  const affixRef = React.useRef(null);
  const history = useHistory();

  handleFilterRemove.current = () => {
    onFiltersRemove && onFiltersRemove();
  };

  React.useEffect(() => {
    return () => handleFilterRemove.current();
  }, []);

  const handleDelete = async () => {
    try {
      await onDelete();
      message.success('Deleted!');
    } catch (e) {
      message.error('Failed, try again!');
      throw new Error(e.message);
    }
  };

  const onSelect = params => {
    if (document) {
      if (document.body) {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }
    }
    params?.key && history.push(params.key);
  };


  

  const MenuItems = (
    <Menu
      mode="inline"
      defaultSelectedKeys={[`${active}`]}
      onSelect={onSelect}
      onOpenChange={setOpenKeys}
      openKeys={openKeys}
    >
      {menuItems.map(item => (
        <Menu.SubMenu title={item.title} key={item.key}>
          {item.children.map(child => (
            <Menu.Item key={child.key} icon={child.icon} disabled={child.disabled}>
              <Link to={child.link}>{child.label}</Link>
            </Menu.Item>
          ))}
        </Menu.SubMenu>
      ))}
    </Menu>
  );
  const CardWithLayout = (
    <RenderCardWithLayout
      loading={loading}
      title={title}
      tableData={tableData}
      columns={columns}
      onDelete={onDelete}
      addLink={addLink}
      selectedRowKeys={selectedRowKeys}
      handleDrawer={() => setVisible(!visible)}
      showBack={showBack}
      extra={extra}
      onFiltersRemove={onFiltersRemove}
      onPaginationChange={onPaginationChange}
      rowKey={rowKey}
      editLink={editLink}
      components={components}
      onSort={onSort}
      children={children}
      specificMutation = {specificMutation}
      handleDeleteRecord = {handleDeleteRecord}
    />
  );
  return (
    <>
      <MetaTags title={title.length ? title[title.length - 1].name : title} description={`This is ${title} page`} />
      <Row /* gutter={48} */ style={{ marginTop: '0vh', minHeight: '100vh' }}>
        {props.sidebar ? null : <Col lg={4} xs={0} /* style={{ backgroundColor: 'rgba(202, 202, 202, 0.15)' }} */>
          <div align="right" style={{ widht: '100%', marginBottom: '20px' }}>
            <Switch
              checked={checked}
              onChange={() =>
                !checked
                  ? setChecked(true) || setOpenKeys(menuItems.map(item => item.key))
                  : setChecked(false) || setOpenKeys([])
              }
              unCheckedChildren="Expand"
              checkedChildren="Collapse"
            />
          </div>
          {MenuItems}
        </Col>}

        <Col style={{ padding: props.sidebar ? '0px 20px' : '0px 0px' }} lg={{ span: props.sidebar ? 24 : 19, offset: props.sidebar ? 0 : 1 }} xs={24} /* style={{ marginTop: '5vh' }} */>
          {table === true && <AdminAffix offsetBottom={0}>{CardWithLayout}</AdminAffix>}
          {table === false && (
            <Row>
              <Col lg={extra ? 8 : 18} md={24}>
                <Breadcrumb separator="">
                  {title.length ? (
                    title.length > 0 &&
                    title.map((t, index) => (
                      <>
                        <Breadcrumb.Item style={{ fontSize: 20, fontWeight: 'bold' }}>
                          {t.link ? <Link to={t.link}>{t.name}</Link> : t.name}
                        </Breadcrumb.Item>
                        {title.length !== index + 1 && <Breadcrumb.Separator>{'>'}</Breadcrumb.Separator>}
                      </>
                    ))
                  ) : (
                    <Breadcrumb.Item>{title}</Breadcrumb.Item>
                  )}
                </Breadcrumb>
              </Col>
              <Col lg={extra ? 12 : 0} md={extra ? 12 : 0} xs={24} align="right">
                {extra}
              </Col>
              <Col lg={6} xs={24} md={24} align="right" style={{ paddingRight: '25px' }}>
                {onDelete && (
                  <>
                    {!props?.onEdit ? <DeleteIcon size="md" onClick={handleDelete} /> : null}
                    {!props?.onEdit ? <Divider type="vertical" /> : null}
                  </>
                )}
                {!props?.onEdit ? <EditIcon color="default" ghost={false} href={`${editLink}${getIntFromString(rowKey)}`} /> : null}
                <Button type="link" ghost onClick={()=>history?.goBack()}>
                  <LeftOutlined />
                  {' Back'}
                </Button>
              </Col>
              {props?.sidebar ? null : <Col span={24}>
                <Divider style={{ marginTop: '10px' }} />
              </Col>}

              <Col span={24}>{children}</Col>
            </Row>
          )}
          {table === undefined && (
            <>
              <Row>
                {showTitle && (
                  <Col span={19}>
                    <h2>
                      <strong>{title}</strong>
                    </h2>
                  </Col>
                )}
                <Col span={24}>{children}</Col>
              </Row>
            </>
          )}
        </Col>
        <Drawer
          width="190px"
          bodyStyle={{ padding: '24px 0px' }}
          placement="left"
          closable={false}
          onClose={() => setVisible(false)}
          visible={visible}
        >
          {MenuItems}
        </Drawer>
      </Row>
    </>
  );
};

export default CardLayout;
