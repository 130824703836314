import gql from 'graphql-tag';

export const UPDATE_RESPONSE_MUTATION = gql`
mutation updateResponse($answers: [AnswerInput]!, $responseId: ID!) {
    updateResponse(answers: $answers, responseId: $responseId){
        response{
            id
            answerSet(first:1){
                edges{
                    node{
                        id
                        answer
                    }
                }
            }
        }
    }
}`
