export const replaceAnswer1 =(question1Field1,question1Field2)=>{
    return `I want to discuss ${question1Field1} in / during ${question1Field2}. Is this a good time?`
}

export const replaceAnswer2 =(question2Field1,question2Field2,question2Field3)=>{
    return `I am referring to the ${question2Field1} with ${question2Field2} on ${question2Field3}`
}

export const replaceAnswer3 =(question3Field1,question3Field2)=>{
    return `You ${question3Field1} . It felt like ${question3Field2}`
}

export const replaceAnswer4 =(question4Field1)=>{
    return `How else could you have handled that? Here are my suggestions.${question4Field1}`
}

export const replaceAnswer5Feedback1 =(question5Field1)=>{

    // return `You should try and do this more often and if possible, even try and help the team adopt this behavior.`
    return question5Field1
}

export const replaceAnswer5Feedback2 =(question5Field2)=>{
    // return `If it’s helpful, I can continue to observe and provide feedback if I see you try something differently. I am happy to catch up again in some time, so you can bounce off any challenges you may be facing. Let me know.`
    return question5Field2
}