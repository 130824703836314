import gql from 'graphql-tag';

export const UPDATE_BADGE =gql`
    mutation updateBadge($id: ID!,$behaviouralDescription: String,$title: String,$image: String){
        updateBadge(id: $id,behaviouralDescription: $behaviouralDescription,title: $title,image:$image){
            badge{
              id
            }
        }
    }
`