import React from 'react';
import { useParams } from 'react-router-dom';

import { getItem, setItem } from '../../core';
import { RECENT_TOKEN } from '../../../config';

const AddToolToRecent = props => {
  const { id } = useParams();

  React.useEffect(() => {
    return () => {
      getItem(RECENT_TOKEN)
      .then(token => {
        if (token) {
          const recentToken = JSON.parse(token).reverse();
          const filteredRecentToken = recentToken.filter(i => i !== String(id));
          setItem('recentToken', JSON.stringify([...filteredRecentToken.slice(-4), id].reverse()));
        } else setItem(RECENT_TOKEN, JSON.stringify([id]));
      })
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default AddToolToRecent;
