import gql from 'graphql-tag';

export const CREATE_COMPETENCY_SKILL = gql`
mutation createCompetency($competencyData: CompetencyInput!){
    createCompetency(competencyData: $competencyData){
    competency{
      id
      title
      competencyType
    }
  }
}
`