import gql from 'graphql-tag';

export const COMPETENCY_BY_ID_QUERY = gql`
  query competencyById($id: ID!) {
    competencyById(id: $id) {
      id
      title
      competencyType
      description
    }
  }
`;
