// DO NOT EDIT THIS FILE

import gql from 'graphql-tag';

export const COLLAB_UNDER_ORG = gql`
  query me($search:String) {
    collab:me{
        id
      employee{
        id
        orgPocEmployee(employees_User_FirstName_Icontains:$search) {
          edges{
            node{
              id
              employees(user_FirstName:$search){
                edges{
                  node{
                    id
                    user{
                      id
                      firstName
                      lastName
                    }
                  }
                }
              }
            }
          }
        }
        orgCeoEmployee(employees_User_FirstName_Icontains: $search) {
          edges{
            node{
              id
              employees(user_FirstName: $search){
                edges{
                  node{
                    id
                     user{
                      id
                      firstName
                      lastName
                    }
                  }
                }
              }
            }
          }
        }      
      }
    }
  }
`;
