// @ts-nocheck
import React from 'react';
import { compose } from '../../core';
import { withApollo } from '@apollo/client/react/hoc';
import IDPCreateView from '../components/idpCreateView';
import { COMPETENCY_LIST_JOBFAMILY } from 'modules/role_definition/graphql/JobFamilyQuries.gql';
import { IDP_DETAIL_BY_ID } from '../graphql/idpDetail.gql';
import { getIdpBase64StringFromInt, getIntFromString, globalPermissionValidator } from 'modules/look';
import { Spin } from 'antd'
import { withUpdateIdp } from '../idpOperations';
import { idp_permission } from 'Permissions/idp.permission';
import NoPermissionView from 'modules/kudos-user/component/NoPermissionComponent';

const IDPEditContainer = props => {
  const { me, match, client, updateIdp, userPermission,navigateRoute } = props
  const [role_specific_competency_list, SetRoleSpecificCompList] = React.useState([])
  const [loading, Setloading] = React.useState(false)
  const [selected_manager, SetSelectedManager] = React.useState()
  const [idp_detail, setIdpDetail] = React.useState()
  const [permission, setPermission] = React.useState()
  const [ai_trigger,SetAITrigger] = React.useState()
  //Permission Check
  React.useEffect(() => {
    if (userPermission?.length) {
      let permission = globalPermissionValidator(idp_permission, userPermission)
      setPermission(permission)
    }
  }, [userPermission])

  React.useEffect(() => {
    if (match?.params && me && permission?.required_permission) {
      let id = getIdpBase64StringFromInt(match?.params?.idp_id)
      getCompetencyList()
      getIdpById(id)
    }
  }, [match, me, permission])

  const getIdpById = async (id) => {
    Setloading(true)
    const { data } = await client.query({
      query: IDP_DETAIL_BY_ID,
      variables: { id: id },
      fetchPolicy: 'network-only'
    });
    if (data?.idpById && me?.employee?.id == data?.idpById?.employee?.id) {
      setIdpDetail(data?.idpById)
    }
    Setloading(false)
  }


  const getCompetencyList = async (filter) => {
    const { data } = await client.query({
      query: COMPETENCY_LIST_JOBFAMILY,
      variables: { ...filter },
      fetchPolicy: 'network-only'
    });
    if (data?.allCompetencies) {
      SetRoleSpecificCompList(data?.allCompetencies?.edges?.map(({ node }) => node))
    } else {
      SetRoleSpecificCompList([])
    }
  }

  const OnSubmitIDP = async (data) => {
    try {
      Setloading(true)
      let update = { ...data?.IdpData, skills: data?.skillData, id: getIntFromString(idp_detail?.id) }
      delete update?.approverId
      delete update?.sendTo
      const response = await updateIdp(update)
      if (response?.id) {
        navigateRoute("-1")
      }
      Setloading(false)
    } catch (error) {
      Setloading(false)
    }
  }

  return (
    <>
      {
        (permission && permission?.required_permission) && (
          idp_detail ?
            <IDPCreateView
              role_specific_competency_list={role_specific_competency_list}
              SetRoleSpecificCompList={(e) => { SetRoleSpecificCompList(e) }}
              getCompetencyList={(filter) => { getCompetencyList(filter) }}
              SetSelectedManager={SetSelectedManager}
              selected_manager={selected_manager}
              OnSubmitIDP={OnSubmitIDP}
              loading={loading}
              idp_detail={idp_detail}
              ai_trigger={ai_trigger}
              SetAITrigger={SetAITrigger}
              permission={permission}
              {...props} />

            :
            <div className="role-no-permission-container">
            <Spin spinning={true} size="large"></Spin>
          </div>
        )
      }

      {(permission && !permission?.required_permission) && (<NoPermissionView />)}
      {
        !permission && (
          <div className="role-no-permission-container">
            <Spin spinning={true} size="large"></Spin>
          </div>
        )
      }
    </>
  );
};

export default compose(withApollo, withUpdateIdp)(IDPEditContainer);
