import gql from 'graphql-tag';
import { SIMPLE_FIELD_CHOICE_TYPE } from './SimpleFieldChoiceType.gql';

export const SIMPLE_FIELD_CHOICE_TYPE_CONNECTION = gql`
  ${SIMPLE_FIELD_CHOICE_TYPE}
  fragment SimpleFieldChoiceTypeConnection on SimpleFieldChoiceTypeConnection {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...SimpleFieldChoiceType
      }
    }
    totalCount
    edgeCount
  }
`;
