import ManagerDashboard from './containers/ManagerDashboard';
import ManagerImpactNarrativeContainer from './containers/ManagerImpactNarrativeContainer';

import ROUTE from './route';

export default [
  {
    name: 'Manager Dashboard',
    path: ROUTE.manager,
    component: ManagerDashboard,
    protect: true
  },
  {
    name: 'Impact Narrative',
    path: ROUTE.impact,
    component: ManagerImpactNarrativeContainer,
    protect: false
  }
];
