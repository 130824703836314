import gql from 'graphql-tag';
import { SURVEY_TYPE_CONNECTION } from './SurveyTypeConnection.gql';

export const ORG_SURVEY_BY_VERTICAL_ID_QUERY = gql`
  ${SURVEY_TYPE_CONNECTION}
  query orgSurveyByVerticalId(
    $verticalId: ID!
    $first: Int
    $offset: Int
    $orderBy: [String]
    $name_Icontains: String
    $description_Icontains: String
  ) {
    orgSurveyByVerticalId(
      verticalId: $verticalId
      first: $first
      offset: $offset
      orderBy: $orderBy
      name_Icontains: $name_Icontains
      description_Icontains: $description_Icontains
    ) {
      ...SurveyTypeConnection
    }
  }
`;
