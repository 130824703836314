import gql from 'graphql-tag';
import { SURVEY_TYPE } from './SurveyType.gql';

export const ALL_PUBLIC_SURVEY_QUERY = gql`
  ${SURVEY_TYPE}
  query allPublicSurveys($before: String, $after: String, $first: Int, $last: Int) {
    allPublicSurveys(before: $before, after: $after, first: $first, last: $last) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...SurveyType
        }
      }
      totalCount
      edgeCount
    }
  }
`;
