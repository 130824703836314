import React from 'react';
import { Result, Button } from 'antd';
import { useParams } from 'react-router-dom';

import { compose } from '../../core';
import { Loading } from '../../look';

import ROUTE from '../route';

import { withVerifyAccount } from './UserOperations';

const ActivateAccount = props => {
  const [loadingVerifyAccount, setLoadingVerifyAccount] = React.useState(true);
  const [verifyAccountResponse, setVerifyAccountResponse] = React.useState(null);
  const { verifyAccount } = props;
  const { token } = useParams();
  const handleVerifyAccount = React.useRef(() => {});

  handleVerifyAccount.current = async () => {
    try {
      const response = await verifyAccount(token);
      setVerifyAccountResponse(response);
      setLoadingVerifyAccount(false);
    } catch (e) {
      throw new Error(e.message);
    }
  };

  React.useEffect(() => {
    handleVerifyAccount.current();
  }, []);

  return (
    <>
      {loadingVerifyAccount && <Loading text={'Verifing Account!!'} />}
      {!loadingVerifyAccount && verifyAccount ? (
        <div className="HVCenter">
          {verifyAccountResponse.success ? (
            <Result
              status="success"
              title="Your account has been verified!"
              subTitle="Head to login page!!"
              extra={[
                <a href={ROUTE.login}>
                  <Button type="primary" key="console">
                    Login
                  </Button>
                </a>
              ]}
            />
          ) : (
            <Result
              status="warning"
              title={verifyAccountResponse.errors.nonFieldErrors[0].message}
              extra={
                <a href={ROUTE.login}>
                  <Button type="primary" key="console">
                    Login
                  </Button>
                </a>
              }
            />
          )}
        </div>
      ) : (
        !loadingVerifyAccount && (
          <div className="HVCenter">
            <h2>Token is no longer valid!</h2>
          </div>
        )
      )}
    </>
  );
};

export default compose(withVerifyAccount /* , withSubmitTokenResponse */)(ActivateAccount);
