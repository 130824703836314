import gql from 'graphql-tag';
import { TEAM_TYPE } from './TeamType.gql';

export const DELETE_TEAM = gql`
  ${TEAM_TYPE}
  mutation deleteTeam($id: ID!) {
    deleteTeam(id: $id) {
      team {
        ...TeamType
      }
    }
  }
`;
