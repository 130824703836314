import gql from 'graphql-tag';

export const UPDATE_DISCARD_MUTATION = gql`
mutation updateDiscardRequest($actorsComment: String,$id: ID!,$status: String!){
    updateDiscardRequest(actorsComment: $actorsComment,id: $id,status: $status){
        discardRequest{
            id
        }
    }
}
`