import React from 'react';
import { MacCommandOutlined, ApartmentOutlined, TeamOutlined, SolutionOutlined } from '@ant-design/icons';

import Organization from './containers/Organization';
import Vertical from './containers/Vertical';
import Team from './containers/Team';
import Employee from './containers/Employee';

import OrganizationDetail from './containers/OrganizationDetail';
import VerticalDetail from './containers/VerticalDetail';
import TeamDetail from './containers/TeamDetail';

import AddVertical from './containers/AddVertical';
import AddOrganization from './containers/AddOrganization';
import AddTeam from './containers/AddTeam';
import AddEmployee from './containers/AddEmployee';

import EditVertical from './containers/EditVertical';
import EditOrganization from './containers/EditOrganization';
import EditTeam from './containers/EditTeam';
import EditEmployee from './containers/EditEmployee';

import ROUTE from './route';

export default [
  {
    name: 'Organizations',
    path: ROUTE.organization,
    component: Organization,
    adminDropdown: true,
    admin: true,
    icon: <MacCommandOutlined />
  },
  {
    name: 'OrganizationDetail',
    path: ROUTE.organizationDetail,
    component: OrganizationDetail,
    admin: true
    // adminDropdown: true,
    // icon: <MacCommandOutlined />
  },
  {
    name: 'Vertical',
    path: ROUTE.vertical,
    component: Vertical,
    icon: <ApartmentOutlined />,
    admin: true
  },
  {
    name: 'VerticalDetail',
    path: ROUTE.verticalDetail,
    component: VerticalDetail,
    icon: <ApartmentOutlined />,
    admin: true
  },
  {
    name: 'Team',
    path: ROUTE.team,
    component: Team,
    icon: <TeamOutlined />,
    admin: true
  },
  {
    name: 'TeamDetail',
    path: ROUTE.teamDetail,
    component: TeamDetail,
    icon: <TeamOutlined />,
    admin: true
  },
  {
    name: 'Employee',
    path: ROUTE.employee,
    component: Employee,
    icon: <SolutionOutlined />,
    admin: true
  },
  {
    name: 'AddVertical',
    path: ROUTE.addVertical,
    component: AddVertical,
    admin: true
  },
  {
    name: 'AddOrganization',
    path: ROUTE.addOrganization,
    component: AddOrganization,
    admin: true
  },
  {
    name: 'AddTeam',
    path: ROUTE.addTeam,
    component: AddTeam,
    admin: true
  },
  {
    name: 'AddEmployee',
    path: ROUTE.addEmployee,
    component: AddEmployee,
    admin: true
  },
  {
    name: 'EditVertical',
    path: ROUTE.editVertical,
    component: EditVertical,
    admin: true
  },
  {
    name: 'EditOrganization',
    path: ROUTE.editOrganization,

    component: EditOrganization,
    admin: true
  },
  {
    name: 'EditTeam',
    path: ROUTE.editTeam,
    component: EditTeam,
    admin: true
  },
  {
    name: 'EditEmployee',
    path: ROUTE.editEmployee,
    component: EditEmployee,
    admin: true
  }
];
