import gql from 'graphql-tag';
import { SURVEY_TYPE_CONNECTION } from './SurveyTypeConnection.gql';

export const ALL_ORG_SURVEY_BY_VERTICAL_QUERY = gql`
  ${SURVEY_TYPE_CONNECTION}
  query orgSurveyByVertical($id: ID!, $before: String, $after: String, $first: Int, $last: Int) {
    orgSurveyByVertical(id: $id, before: $before, after: $after, first: $first, last: $last) {
      ...SurveyTypeConnection
    }
  }
`;
