import gql from 'graphql-tag';
import { VERTICAL_TYPE } from '../../organization/graphql/VerticalType.gql';

export const VERTICAL_TYPE_CONNECTION = gql`
  ${VERTICAL_TYPE}
  fragment VerticalTypeConnection on VerticalTypeConnection {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...VerticalType
      }
    }
    totalCount
    edgeCount
  }
`;
