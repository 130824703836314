import React from "react";
import { Modal } from "antd";
import { useHistory } from "react-router-dom";

import TeamFormComponent from "./TeamFormComponent";

const AddTeam = props => {
  const { setVisible } = props;
  
  const history = useHistory();
  return (
    <>
      <Modal
        destroyOnClose
        visible={props.visible || window.location.href.includes( "openmodal" )}
        onCancel={() => {
          setVisible( false );
          history.push( window.location.pathname );
        }}
        footer={null}
        title="Add Team"
        width="80%"
      >
        <TeamFormComponent {...props} />
      </Modal>
    </>
  );
};

export default AddTeam;
