import gql from 'graphql-tag';
import { RESPONSE_TYPE } from './ResponseType.gql';

export const RESPONSE_BY_RESPONSE_ID_QUERY = gql`
  ${RESPONSE_TYPE}
  query responseByResponseId($id: ID!) {
    responseByResponseId(id: $id) {
      ...ResponseType
    }
  }
`;
