// @ts-nocheck
import { Button, Form, Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';

const CreateResponsibilityView = (props) => {
    const { onCancel, onSubmit } = props

    return (
        <Form
            layout="vertical"
            onFinish={values => onSubmit(values)}
            scrollToFirstError={true}
            className='rd-align-center '
            style={{ gap: '1em' }}
        >
            <div align="center" style={{ marginBottom: "3em" }}>
                <h4 className='competency-popup-title'>Add Responsibility</h4>
            </div>
            <div className='rd-input-container'>
                <h4 className='rd-input-title'><span className='rd-input-star'>*</span> Title</h4>
                <Form.Item
                    name="title"
                    rules={[
                        {
                            required: true,
                            message: 'Please input title',
                        },
                    ]}
                    style={{ width: '100%' }}
                >
                    <Input name='title' className="rd-text-input" placeholder='Enter responcibility Title  ' />
                </Form.Item>

            </div>
            <div className='rd-input-container'>
                <h4 className='rd-input-title'>Description</h4>
                <Form.Item
                    name="description"
                    style={{ width: '100%' }}
                >
                    <TextArea name='description' className="rd-text-area" />
                </Form.Item>
            </div>
            <div className='rd-confirmation-action-container'>
                <Button className='global-submit-btn' htmlType="submit">Save</Button>
                <Button className='global-cancel-btn' onClick={() => onCancel(false)}>Cancel</Button>
            </div>
        </Form>
    )
}

export default CreateResponsibilityView