import gql from "graphql-tag";

export const DEFAULT_COMMITMENT = gql`
  query defaultCommitmentProgress($user: ID) {
    defaultCommitmentProgress(user: $user) {
      edges {
        node {
            id
            commitment{
                id
                title
            }      
        }
      }
    }
  }
`;
