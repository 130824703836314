import gql from 'graphql-tag';

export const ME_SURVEY_RESPONSES_FOR_CEO_OR_POC_QUERY = gql`
  query me($id: ID!, $surveyId:ID!) {
    surveys: me{
      id
    emp: employee{
      id
      orgPocEmployee(id: $id){
        edges{
          node{
            employees{
              edges{
                node{
                  id
                  user{
                    id
                    firstName
                    responseSet(survey: $surveyId) {
                      edges {
                        node {
                          id
                          user {
                            id
                          }
                          survey {
                            id
                            name
                          }
                          answerSet{
                            edges{
                              node{
                                id
                                answer
                                question{
                                  id
                                  questionText
																}
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      orgCeoEmployee(id: $id){
        edges {
          node {
            id
            employees{
              edges{
                node{
                  id
                  user{
                    id
                    firstName
                    responseSet(survey: $surveyId) {
                      edges {
                        node {
                          id
                          survey {
                            id
                            name
                          }
                          answerSet{
                            edges{
                              node{
                                id
                                answer
                                question{
                                  id
                                  questionText
																}
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;
