import gql from 'graphql-tag';
import { SIMPLE_FIELD_CHOICE_TYPE_CONNECTION } from './SimpleFieldChoiceTypeConnection.gql';
import { SIMPLE_FIELD_RESPONCES_TYPE_CONNECTION } from './SimpleFieldResponcesTypeConnection.gql';

export const CUSTOM_SIMPLE_FIELD_TYPE = gql`
  ${SIMPLE_FIELD_CHOICE_TYPE_CONNECTION}, ${SIMPLE_FIELD_RESPONCES_TYPE_CONNECTION}
  fragment Custom_SimpleFieldType on Custom_SimpleFieldType {
    id
    label
    inputType
    editable
    sequence
    helpText
    simplefieldchoiceSet {
      ...SimpleFieldChoiceTypeConnection
    }
    simpleFieldResponse {
      ...SimpleFieldResponcesTypeConnection
    }
  }
`;
