import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import CKEditor from 'ckeditor4-react';
import { Spin, Form } from 'antd';
import { withHTMLFieldValue } from '../functions';
import { CLOUDINARY_CLOUDNAME, CLOUDINARY_PRESET } from '../../../../config';

const editorVersion = '4.15.1';
const editorUrl = `https://cdn.ckeditor.com/${editorVersion}/full-all/`;

CKEditor.editorUrl = `${editorUrl}ckeditor.js`;

const HVCenter = styled.div`
  min-height: 40vh;
  display: grid;
  grid-template-rows: auto;
`;

const FormItem = Form.Item;

const GraphicsField = props => {
  const [mounted, setMounted] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const { label, value, name, inFilter = false, rules, initialValue, changeHandler } = props;
  let validateStatus = '';

  // const changeHandler = e => {
  //   setFieldValue(name, e && e.editor && e.editor.getData());
  // };

  let labels = inFilter
    ? {}
    : {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 }
      };

  const cloudName = `${CLOUDINARY_CLOUDNAME}/image`;
  const unsignedUploadPreset = `${CLOUDINARY_PRESET}`;

  if (typeof window !== 'undefined') {
    // To load the script -> <script src="https://www.wiris.net/demo/plugins/app/WIRISplugins.js?viewer=image"></script>
    const scr = document.createElement('script');
    const head = document.head || document.getElementsByTagName('head')[0];
    scr.src = 'https://www.wiris.net/demo/plugins/app/WIRISplugins.js?viewer=image';
    scr.async = false;
    head.insertBefore(scr, head.firstChild);
  }

  return (
    <>
      <FormItem
        name={name}
        rules={rules}
        label={label} /* {...labels} */
        /* validateStatus={validateStatus} */
        // initialValue={initialValue}
        /* help={touched && error} */
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24, offset: 1 }}
      >
        {mounted && (
          <CKEditor
            name={Array.isArray(name) ? name.join('.') : name}
            mode="wysiwyg"
            data={withHTMLFieldValue(initialValue)}
            onChange={changeHandler}
            config={{
              extraPlugins: [/* 'ckeditor_wiris', */ 'image2'],
              removePlugins: ['forms', 'link', 'bidi', 'div', 'flash', 'iframe', 'about'],
              toolbar: [ { name: 'insert', items: ['Image', 'Smiley'] } ],
              uiColor: '#9bd446',
              // stylesSet: {
              // },
              allowedContent: true,
              height: 200,
              language: 'en',
              // startupFocus: 'end',
              filebrowserImageUploadUrl: '/uploader/upload.php', // this is only for the upload tab to appear on dialog
              // Change request to upload to Cloudinary server
              on: {
                loaded: function () {
                  setLoaded(true);
                },
                fileUploadRequest: function (evt) {
                  // Prepare request to Cloudinary
                  var url = 'https://api.cloudinary.com/v1_1/' + cloudName + '/upload';
                  var xhr = evt.data.fileLoader.xhr;
                  xhr.open('POST', url, true);
                  xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

                  // Fill all necessary fields for Cloudinary
                  // eslint-disable-next-line no-undef
                  var fd = new FormData();
                  fd.append('upload_preset', unsignedUploadPreset);
                  // fd.append('folder', 'content/articles'); // Optional - place image at specific folder admin in Cloudinary
                  // fd.append('tags', 'browser_upload'); // Optional - add tag for image admin in Cloudinary
                  fd.append('file', evt.data.fileLoader.file);
                  // Send the request to Cloudinary server
                  xhr.send(fd);

                  // Prevented the default behavior.
                  evt.stop();
                },
                // Change response to handle Cloudinary response
                fileUploadResponse: function (evt) {
                  // Prevent the default response handler.
                  evt.stop();

                  // Get XHR and response.
                  var data = evt.data,
                    xhr = data.fileLoader.xhr,
                    response = JSON.parse(xhr.responseText);

                  // Transfer the response to Ckeditor format
                  data.uploaded = 1;
                  data.fileName = response.public_id + '.' + response.format;
                  data.url = response.secure_url;
                }
              }
            }}
            // onSelectionChange={changeHandler}
            onBeforeLoad={CKEDITOR => {
              // CKEDITOR.plugins.addExternal('ckeditor_wiris', 'https://www.wiris.net/demo/plugins/ckeditor/', 'plugin.js');
              CKEDITOR.plugins.addExternal(
                'image2',
                `https://ckeditor.com/docs/vendors/${editorVersion}/ckeditor/plugins/image2/`,
                'plugin.js'
              );
            }}
          />
        )}
      </FormItem>
      {!loaded && (
        <div style={{ height: '40vh', display: 'grid' }} align="center">
          <Spin style={{ margin: 'auto' }} />
        </div>
      )}
    </>
  );
};

GraphicsField.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  inFilter: PropTypes.bool,
  meta: PropTypes.object,
  formik: PropTypes.object
};

export default GraphicsField;
