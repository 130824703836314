import gql from 'graphql-tag';


export const CREATE_ACTION_ITEM_MUTATION = gql`
  mutation newCreateActionItem($actionItemData: NewActionItemInput!) {
    newCreateActionItem(
        actionItemData:$actionItemData
        ) {
            actionItem {
         id
      }
    }
  }
`;
