import gql from 'graphql-tag';


export const END_MEETING_MUTATION = gql`
  mutation endMeeting($id: ID!) {
    endMeeting(id:$id) {
        meeting{
            id
        }
    }
  }
`;