import gql from 'graphql-tag';
import { FORM_SECTION_TYPE } from './FormSectionType.gql';

export const DELETE_FORM_SECTION = gql`
  ${FORM_SECTION_TYPE}
  mutation deleteFormSection($id: ID!) {
    deleteFormSection(id: $id) {
      formSection {
        ...FormSectionType
      }
    }
  }
`;
