import gql from 'graphql-tag';

export const DELETE_REDINESS_BY_LEVEL = gql`
mutation  deleteReadinessLevel($id:ID!){
  deleteReadinessLevel(id:$id){
    readinessLevel{
      id
    }
  }
}
`