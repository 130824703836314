import gql from "graphql-tag";

export const SURVEY_BY_ID_FOR_360 = gql`
query SurveyById($id:ID!){
    SurveyById(id:$id){
        id
        name
    }
}
`;
