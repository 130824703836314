export const one_on_one="one-on-one"
const baseName =`/role/${one_on_one}`
const home = '/home'
const create ='/add'
const view ='/view'
const edit ='/edit'
const editSeries='/edit_series'
const meeting = '/meeting'
const feedback = '/feedback'
const one_on_one_route = {
    oneOnOneHome:baseName+home+'/:type',
    addOneonOne:baseName+create,
    viewMeeting:baseName+view+'/:meetingid',
    EditMeeting:baseName+edit+'/:meetingid',
    Meeting:baseName+meeting+'/:meetingid',
    EditMeetingSeries:baseName+editSeries+'/:meetingid',
    effectivenessHome:baseName+feedback+home
}

export default one_on_one_route