import React, { useState } from "react";
import { withApollo } from "@apollo/client/react/hoc";
import { useParams } from "react-router-dom";

import { compose } from "../../core";
import { handleGetReport } from "../../look";
import { message } from "antd";
import { withMe } from "../../user/containers/UserOperations";

import SurveyReport from "../components/SurveyReport";

const SurveyComparisonReport = (props) => {
  const { me, client } = props;
  const [actionLoading, setActionLoading] = useState(false);
  const { id, uid, username } = useParams();

  // const [report, setReport] = React.useState({
  //   surveyName: 'Workplace MApp Prediction Survey',
  //   receiver: 'firstName lastName',
  //   userReport: {
  //     MApp_score: 4.833333333333333,
  //     socres: {
  //       Identity: {
  //         overall: 4.636363636363637,
  //         factors: {
  //           Values: 4.5,
  //           Personality: 5.0,
  //           Strengths: 4.5,
  //           'Knowledge, Skills, Experience': 4.666666666666667
  //         }
  //       },
  //       'Future Orientation': {
  //         overall: 4.8,
  //         factors: {
  //           Action: 4.666666666666667,
  //           Commitment: 5.0
  //         }
  //       },
  //       Ability: {
  //         overall: 5.083333333333333,
  //         factors: {
  //           Learnability: 5.0,
  //           Quality: 5.25,
  //           Integrity: 5.0
  //         }
  //       },
  //       Execution: {
  //         overall: 4.833333333333333,
  //         factors: {
  //           Adaptability: 5.2,
  //           Perseverance: 4.583333333333333,
  //           Positivity: 4.75
  //         }
  //       },
  //       'Social Immunity': {
  //         overall: 4.9375,
  //         factors: {
  //           'People Orientation': 4.9,
  //           'Vulnerability Comfort': 5.0
  //         }
  //       }
  //     },
  //     questions: {
  //       'GRC.1.2': {
  //         question:
  //           'I share my views - even if contrarian -\\u00a0confidently without feeling pressured by colleagues and managers.',
  //         answer: 5.5
  //       },
  //       'GRD3.2': {
  //         question: 'When things do not work out, I remind myself of others that have it worse and practice gratitude.',
  //         answer: 5.0
  //       },
  //       'GRD.3.3': {
  //         question:
  //           'When working with people that have a negative outlook, I ensure that my morale is not affected by them.',
  //         answer: 5.0
  //       },
  //       'GRD.3.4': {
  //         question: 'When things go wrong, I see that as an opportunity.',
  //         answer: 4.5
  //       },
  //       'GRE.1.1': {
  //         question:
  //           'I build and nurture relationships both personally and professionally, so I can lean on them when I need help.',
  //         answer: 5.5
  //       },
  //       'GRE.1.2': {
  //         question: 'I take time to listen to people and help them.',
  //         answer: 4.5
  //       },
  //       'GRE.1.3': {
  //         question: 'I communicate with people in a way that works for them.',
  //         answer: 5.0
  //       },
  //       'GRE.1.4': {
  //         question: 'I make sure to accommodate people with different styles of working.',
  //         answer: 4.5
  //       },
  //       'GRE.1.5': {
  //         question: 'I help others by sharing my knowledge and skills.',
  //         answer: 5.0
  //       },
  //       'GRB.1.1': {
  //         question: 'I am positive about my life and career trajectory.',
  //         answer: 5.0
  //       },
  //       'GRB.1.2': {
  //         question: 'When I face challenges to my life / career goals, I act with resolve to address them.',
  //         answer: 5.0
  //       },
  //       'GRC.1.1': {
  //         question: 'My team members tell me that I deliver high-quality work seemingly\\u00a0effortlessly.',
  //         answer: 5.0
  //       },
  //       'GRD3.1': {
  //         question: 'When I hit a roadblock, I focus on finding a detour rather than complaining about the situation.',
  //         answer: 4.5
  //       },
  //       'GRC.2.1': {
  //         question: 'I hold myself accountable for integrity, including focusing on team goals vs individual goals.',
  //         answer: 5.0
  //       },
  //       'GRC.2.2': {
  //         question: 'I hold others accountable for integrity, including focusing on team goals vs individual goals.',
  //         answer: 5.0
  //       },
  //       'GRE2.1': {
  //         question: 'I am comfortable being vulnerable and sharing about myself.',
  //         answer: 5.0
  //       },
  //       'GRE.2.2': {
  //         question: 'Asking for help does not make me feel incompetent.',
  //         answer: 5.0
  //       },
  //       'GRE2.3': {
  //         question: 'I lean on the community I belong to when I need help.',
  //         answer: 5.0
  //       },
  //       'GRD.1.1.1': {
  //         question: 'I find ways to apply my natural strengths to whatever I am working on.',
  //         answer: 5.5
  //       },
  //       'GRD.1.1.2': {
  //         question: 'I enjoy the challenge involved in learning new skills on the job.',
  //         answer: 5.0
  //       },
  //       'GRD2.1.1': {
  //         question: 'When I fail at something, I try different ways to get it right.',
  //         answer: 4.5
  //       },
  //       'GRD2.1.2': {
  //         question: 'On a challenging project, I choose to work with others to have company and support in tough times.',
  //         answer: 4.0
  //       },
  //       'GRD2.1.3': {
  //         question: 'When faced with difficult situations, I am able to quickly resume day-to-day responsibilities.',
  //         answer: 4.5
  //       },
  //       'GRB.2.1': {
  //         question: 'I am working towards a life / career goal I have set up for myself.',
  //         answer: 4.5
  //       },
  //       'GRA.1.2': {
  //         question: 'I connect my personal values to my work style and impact.',
  //         answer: 4.0
  //       },
  //       'GRA.1.3': {
  //         question: 'I manage\\u00a0misalignment with my personal values carefully.',
  //         answer: 5.0
  //       },
  //       'GRA.2.1': {
  //         question: 'I know my personality.',
  //         answer: 5.0
  //       },
  //       'GRA.2.2': {
  //         question: 'I use my personality in an intentional way.',
  //         answer: 5.0
  //       },
  //       'GRA.3.1': {
  //         question: 'I invest in discovering my strengths.',
  //         answer: 4.0
  //       },
  //       'GRA.3.2': {
  //         question: 'I use my strengths to deliver outsized impact consistently.',
  //         answer: 4.5
  //       },
  //       'GRA.3.3': {
  //         question: 'I regularly\\u00a0gather advanced knowledge and skills in the area of my strengths.',
  //         answer: 5.0
  //       },
  //       'GRA.4.1': {
  //         question: 'I keep up-to-date with the developments in the industry I work in.',
  //         answer: 4.5
  //       },
  //       'GRA.4.2': {
  //         question: 'I look for and take on experiences that will allow me to gather hands on knowledge and skills.',
  //         answer: 5.0
  //       },
  //       'GRA.4.3': {
  //         question: 'I take learning courses - even self-paid - to continuously uplevel myself.',
  //         answer: 4.5
  //       },
  //       'GRA.1.1': {
  //         question: 'I know my personal values.',
  //         answer: 4.5
  //       },
  //       'GRB.2.2': {
  //         question: 'I feel excited about the life / career goal I am chasing.',
  //         answer: 4.5
  //       },
  //       'GRB.2.3': {
  //         question: 'When I feel low, reminding myself of life / career goals re-energizes me.',
  //         answer: 5.0
  //       },
  //       'GRC.3.1': {
  //         question: 'I learn new skills quickly and efficiently.',
  //         answer: 5.0
  //       },
  //       'GRC.3.2': {
  //         question: 'I almost always figure out ways to make things happen.',
  //         answer: 5.0
  //       },
  //       'GRD.1.2.1': {
  //         question: 'I plan contingency to deal with unexpected scenarios.',
  //         answer: 5.0
  //       },
  //       'GRD.1.2.2': {
  //         question: 'I\\u00a0can quickly rejig plans with new inputs even if they do not align with my original thinking.',
  //         answer: 5.0
  //       },
  //       'GRD.1.2.3': {
  //         question: 'I am generally not thrown off by changes and willingly try different things to address them.',
  //         answer: 5.5
  //       },
  //       'GRD2.2.1': {
  //         question: 'When I reach a milestone towards the end goal, I celebrate and renew my commitment to the goal.',
  //         answer: 4.5
  //       },
  //       'GRD2.2.4': {
  //         question: 'I take breaks between intense phases to recharge.',
  //         answer: 5.0
  //       },
  //       'GRD.1.1': {
  //         question: 'No text here.',
  //         answer: 5.25
  //       },
  //       'GRD.1.2': {
  //         question: 'No text here.',
  //         answer: 5.166666666666667
  //       },
  //       'GRD.2.1': {
  //         question: 'No text here.',
  //         answer: 4.333333333333333
  //       },
  //       'GRD.2.2': {
  //         question: 'No text here.',
  //         answer: 4.833333333333333
  //       }
  //     }
  //   },
  //   report: {
  //     MApp_score: 4.833333333333333,
  //     socres: {
  //       Identity: {
  //         overall: 4.636363636363637,
  //         factors: {
  //           Values: 4.5,
  //           Personality: 5.0,
  //           Strengths: 4.5,
  //           'Knowledge, Skills, Experience': 4.666666666666667
  //         }
  //       },
  //       'Future Orientation': {
  //         overall: 4.8,
  //         factors: {
  //           Action: 4.666666666666667,
  //           Commitment: 5.0
  //         }
  //       },
  //       Ability: {
  //         overall: 5.083333333333333,
  //         factors: {
  //           Learnability: 5.0,
  //           Quality: 5.25,
  //           Integrity: 5.0
  //         }
  //       },
  //       Execution: {
  //         overall: 4.833333333333333,
  //         factors: {
  //           Adaptability: 5.2,
  //           Perseverance: 4.583333333333333,
  //           Positivity: 4.75
  //         }
  //       },
  //       'Social Immunity': {
  //         overall: 4.9375,
  //         factors: {
  //           'People Orientation': 4.9,
  //           'Vulnerability Comfort': 5.0
  //         }
  //       }
  //     },
  //     questions: {
  //       'GRC.1.2': {
  //         question:
  //           'I share my views - even if contrarian -\\u00a0confidently without feeling pressured by colleagues and managers.',
  //         answer: 5.5
  //       },
  //       'GRD3.2': {
  //         question: 'When things do not work out, I remind myself of others that have it worse and practice gratitude.',
  //         answer: 5.0
  //       },
  //       'GRD.3.3': {
  //         question:
  //           'When working with people that have a negative outlook, I ensure that my morale is not affected by them.',
  //         answer: 5.0
  //       },
  //       'GRD.3.4': {
  //         question: 'When things go wrong, I see that as an opportunity.',
  //         answer: 4.5
  //       },
  //       'GRE.1.1': {
  //         question:
  //           'I build and nurture relationships both personally and professionally, so I can lean on them when I need help.',
  //         answer: 5.5
  //       },
  //       'GRE.1.2': {
  //         question: 'I take time to listen to people and help them.',
  //         answer: 4.5
  //       },
  //       'GRE.1.3': {
  //         question: 'I communicate with people in a way that works for them.',
  //         answer: 5.0
  //       },
  //       'GRE.1.4': {
  //         question: 'I make sure to accommodate people with different styles of working.',
  //         answer: 4.5
  //       },
  //       'GRE.1.5': {
  //         question: 'I help others by sharing my knowledge and skills.',
  //         answer: 5.0
  //       },
  //       'GRB.1.1': {
  //         question: 'I am positive about my life and career trajectory.',
  //         answer: 5.0
  //       },
  //       'GRB.1.2': {
  //         question: 'When I face challenges to my life / career goals, I act with resolve to address them.',
  //         answer: 5.0
  //       },
  //       'GRC.1.1': {
  //         question: 'My team members tell me that I deliver high-quality work seemingly\\u00a0effortlessly.',
  //         answer: 5.0
  //       },
  //       'GRD3.1': {
  //         question: 'When I hit a roadblock, I focus on finding a detour rather than complaining about the situation.',
  //         answer: 4.5
  //       },
  //       'GRC.2.1': {
  //         question: 'I hold myself accountable for integrity, including focusing on team goals vs individual goals.',
  //         answer: 5.0
  //       },
  //       'GRC.2.2': {
  //         question: 'I hold others accountable for integrity, including focusing on team goals vs individual goals.',
  //         answer: 5.0
  //       },
  //       'GRE2.1': {
  //         question: 'I am comfortable being vulnerable and sharing about myself.',
  //         answer: 5.0
  //       },
  //       'GRE.2.2': {
  //         question: 'Asking for help does not make me feel incompetent.',
  //         answer: 5.0
  //       },
  //       'GRE2.3': {
  //         question: 'I lean on the community I belong to when I need help.',
  //         answer: 5.0
  //       },
  //       'GRD.1.1.1': {
  //         question: 'I find ways to apply my natural strengths to whatever I am working on.',
  //         answer: 5.5
  //       },
  //       'GRD.1.1.2': {
  //         question: 'I enjoy the challenge involved in learning new skills on the job.',
  //         answer: 5.0
  //       },
  //       'GRD2.1.1': {
  //         question: 'When I fail at something, I try different ways to get it right.',
  //         answer: 4.5
  //       },
  //       'GRD2.1.2': {
  //         question: 'On a challenging project, I choose to work with others to have company and support in tough times.',
  //         answer: 4.0
  //       },
  //       'GRD2.1.3': {
  //         question: 'When faced with difficult situations, I am able to quickly resume day-to-day responsibilities.',
  //         answer: 4.5
  //       },
  //       'GRB.2.1': {
  //         question: 'I am working towards a life / career goal I have set up for myself.',
  //         answer: 4.5
  //       },
  //       'GRA.1.2': {
  //         question: 'I connect my personal values to my work style and impact.',
  //         answer: 4.0
  //       },
  //       'GRA.1.3': {
  //         question: 'I manage\\u00a0misalignment with my personal values carefully.',
  //         answer: 5.0
  //       },
  //       'GRA.2.1': {
  //         question: 'I know my personality.',
  //         answer: 5.0
  //       },
  //       'GRA.2.2': {
  //         question: 'I use my personality in an intentional way.',
  //         answer: 5.0
  //       },
  //       'GRA.3.1': {
  //         question: 'I invest in discovering my strengths.',
  //         answer: 4.0
  //       },
  //       'GRA.3.2': {
  //         question: 'I use my strengths to deliver outsized impact consistently.',
  //         answer: 4.5
  //       },
  //       'GRA.3.3': {
  //         question: 'I regularly\\u00a0gather advanced knowledge and skills in the area of my strengths.',
  //         answer: 5.0
  //       },
  //       'GRA.4.1': {
  //         question: 'I keep up-to-date with the developments in the industry I work in.',
  //         answer: 4.5
  //       },
  //       'GRA.4.2': {
  //         question: 'I look for and take on experiences that will allow me to gather hands on knowledge and skills.',
  //         answer: 5.0
  //       },
  //       'GRA.4.3': {
  //         question: 'I take learning courses - even self-paid - to continuously uplevel myself.',
  //         answer: 4.5
  //       },
  //       'GRA.1.1': {
  //         question: 'I know my personal values.',
  //         answer: 4.5
  //       },
  //       'GRB.2.2': {
  //         question: 'I feel excited about the life / career goal I am chasing.',
  //         answer: 4.5
  //       },
  //       'GRB.2.3': {
  //         question: 'When I feel low, reminding myself of life / career goals re-energizes me.',
  //         answer: 5.0
  //       },
  //       'GRC.3.1': {
  //         question: 'I learn new skills quickly and efficiently.',
  //         answer: 5.0
  //       },
  //       'GRC.3.2': {
  //         question: 'I almost always figure out ways to make things happen.',
  //         answer: 5.0
  //       },
  //       'GRD.1.2.1': {
  //         question: 'I plan contingency to deal with unexpected scenarios.',
  //         answer: 5.0
  //       },
  //       'GRD.1.2.2': {
  //         question: 'I\\u00a0can quickly rejig plans with new inputs even if they do not align with my original thinking.',
  //         answer: 5.0
  //       },
  //       'GRD.1.2.3': {
  //         question: 'I am generally not thrown off by changes and willingly try different things to address them.',
  //         answer: 5.5
  //       },
  //       'GRD2.2.1': {
  //         question: 'When I reach a milestone towards the end goal, I celebrate and renew my commitment to the goal.',
  //         answer: 4.5
  //       },
  //       'GRD2.2.4': {
  //         question: 'I take breaks between intense phases to recharge.',
  //         answer: 5.0
  //       },
  //       'GRD.1.1': {
  //         question: 'No text here.',
  //         answer: 5.25
  //       },
  //       'GRD.1.2': {
  //         question: 'No text here.',
  //         answer: 5.166666666666667
  //       },
  //       'GRD.2.1': {
  //         question: 'No text here.',
  //         answer: 4.333333333333333
  //       },
  //       'GRD.2.2': {
  //         question: 'No text here.',
  //         answer: 4.833333333333333
  //       }
  //     }
  //   }
  // });

  const [report, setReport] = React.useState({});
  async function handleReport(responseId, variables) {
    setActionLoading(true);
    try {
      const res = await handleGetReport(
        Number(id),
        client,
        undefined,
        responseId
      );
      setActionLoading(false);
      res && res.report && setReport({ surveyId: id, username, ...res });
      res && res.errors && message.error(res.errors);
    } catch (err) {
      setActionLoading(false);
      throw new Error(err.message);
    }
  }

  // console.log(props);
  return (
    <SurveyReport
      actionLoading={actionLoading}
      onReport={handleReport}
      userReport={report}
      {...props}
    />
  );
};

export default compose(withMe, withApollo)(SurveyComparisonReport);
