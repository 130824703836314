import React, { useEffect, useRef } from 'react';
import { Progress, Spin } from 'antd';
import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

import { compose } from '../../core';

const GET_ALL_DEFAULT_COMMITMENTS_PROGRESS = gql`
  query getAllDefaultCommitmentsProgress($completed: Boolean) {
    getAllDefaultCommitmentsProgress(completed: $completed) {
      # totalCount
      edges {
        node {
          id
          completed
        }
      }
    }
  }
`;

const GET_ALL_CUSTOM_USER_COMMITMENTS = gql`
  query getAllCustomUserCommitments($completed: Boolean) {
    getAllCustomUserCommitments(completed: $completed) {
      totalCount
    }
  }
`;

const PersonalCommitmentProgressBar = props => {
  const {
    updated,
    refetchCommitmentsProgressCompleted,
    refetchCommitmentsProgress,
    refetchCustomUserCommitmentsCompleted,
    refetchCustomUserCommitments
  } = props;
  const progress = useRef(() => {});

  progress.current = () => {
    refetchCommitmentsProgressCompleted();
    refetchCommitmentsProgress();
    refetchCustomUserCommitmentsCompleted();
    refetchCustomUserCommitments();
  };

  useEffect(() => {
    progress.current();
  }, [updated]);

  const {
    loading,
    defaultCommitmentsProgressCompleted,
    defaultCommitmentsProgress,
    customUserCommitmentsCompleted,
    customUserCommitments
  } = props;
  const totalCompleted = customUserCommitmentsCompleted?.totalCount + defaultCommitmentsProgressCompleted?.edges?.length;
  const totalCount = customUserCommitments?.totalCount + defaultCommitmentsProgress?.edges?.length;
  return (
    <Spin spinning={loading} size="small">
      <Progress
        type="circle"
        percent={((totalCompleted * 100) / totalCount || 0).toFixed(2)}
        strokeColor="#B0CB1F"
        strokeWidth="10"
      />
    </Spin>
  );
};

export default compose(
  graphql(GET_ALL_DEFAULT_COMMITMENTS_PROGRESS, {
    options: () => {
      return { variables: { completed: true } };
    },
    props({ data }) {
      const { loading, error, getAllDefaultCommitmentsProgress, subscribeToMore, updateQuery, refetch } = data;
      return {
        loading,
        error,
        defaultCommitmentsProgressCompleted: getAllDefaultCommitmentsProgress,
        subscribeToMore,
        updateQuery,
        refetchCommitmentsProgressCompleted: refetch
      };
    }
  }),
  graphql(GET_ALL_DEFAULT_COMMITMENTS_PROGRESS, {
    props({ data }) {
      const { loading, error, getAllDefaultCommitmentsProgress, subscribeToMore, updateQuery, refetch } = data;
      return {
        loading,
        error,
        defaultCommitmentsProgress: getAllDefaultCommitmentsProgress,
        subscribeToMore,
        updateQuery,
        refetchCommitmentsProgress: refetch
      };
    }
  }),

  graphql(GET_ALL_CUSTOM_USER_COMMITMENTS, {
    options: () => {
      return { variables: { completed: true } };
    },
    props({ data }) {
      const { loading, error, getAllCustomUserCommitments, subscribeToMore, updateQuery, refetch } = data;
      return {
        loading,
        error,
        customUserCommitmentsCompleted: getAllCustomUserCommitments,
        subscribeToMore,
        updateQuery,
        refetchCustomUserCommitmentsCompleted: refetch
      };
    }
  }),
  graphql(GET_ALL_CUSTOM_USER_COMMITMENTS, {
    props({ data }) {
      const { loading, error, getAllCustomUserCommitments, subscribeToMore, updateQuery, refetch } = data;
      return {
        loading,
        error,
        customUserCommitments: getAllCustomUserCommitments,
        subscribeToMore,
        updateQuery,
        refetchCustomUserCommitments: refetch
      };
    }
  })
)(PersonalCommitmentProgressBar);
