import gql from 'graphql-tag';

export const UPDATE_ORGANIZATION_FILTER = gql`
  mutation updateOrganizationFilter(
    $filter: OrganizationFilterInput!
    $orderBy: [String]
    $pagination: OrganizationPaginationInput
  ) {
    updateOrganizationFilter(filter: $filter, orderBy: $orderBy, pagination: $pagination) @client
  }
`;
