import my_profile_inactive from '../../assets/my-profile-inactive-vector.svg'
import my_profile_active from '../../assets/my-profile-active-vetor.svg'
import notification_inactive from '../../assets/notification-settings-inactive-vector.svg'
import notification_active from '../../assets/notification-settings-active-vector.svg'
import change_password_inactive from '../../assets/change-password-inactive-vector.svg'
import change_password_active from '../../assets/change-password-active-vector.svg'
import delete_account from '../../assets/remove-user-vector.svg'
export const profile_icons ={
    my_profile_inactive,
    my_profile_active,
    notification_inactive,
    notification_active,
    change_password_inactive,
    change_password_active,
    delete_account
}