// DO NOT EDIT THIS FILE

import gql from 'graphql-tag';

export const USER_ROLE = gql`
  query me {
  user_level:me{
    id
    employee {
      id
      orgCeoEmployee {
        totalCount
      }
      orgPocEmployee {
        totalCount
      }
            verticalHeadEmployee {
        totalCount
            }
      teamManagerEmployee {
        totalCount

      }
    }
  }
  }
`;
