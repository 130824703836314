import gql from "graphql-tag";
export const MEETING_SUBSCRIPTION = gql`
subscription meetingSubscription {
    meetingSubscription{
        mutation
        meeting{
          id
          endedAt
        }
      }
    }
`