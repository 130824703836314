import ROUTE from './route'
import IdpHomeContainer from "./containers/IdpHomeContainer";
import idpCreateContainer from './containers/idpCreateContainer';
import TeamIdpContainer from './containers/teamIdpContainer';
import IDPDetailComtainer from './containers/idpDetailContainer';
import IDPEditContainer from './containers/idpEditContainer'
import IDPPublicManagerComtainer from './containers/idpPublicManager'
export default [
    {
        name: 'idp home',
        path: ROUTE.home,
        component: IdpHomeContainer,
        exact: true,
        protect:true
    },
    {
        name: 'idp create',
        path: ROUTE.create,
        component: idpCreateContainer,
        exact: true,
        protect:true
    },
    {
        name: 'team idp',
        path: ROUTE.team_idp,
        component: TeamIdpContainer,
        exact: true,
        protect:true
    },
    {
        name: 'idp detail',
        path: ROUTE.detail,
        component: IDPDetailComtainer,
        exact: true,
        protect:true
    },
    {
        name: 'idp edit',
        path: ROUTE.edit,
        component: IDPEditContainer ,
        exact: true,
        protect:true
    },
    {
        name: 'idp manager',
        path: ROUTE.public_detail,
        component: IDPPublicManagerComtainer,
        exact: true
    }
    
]