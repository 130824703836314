import { compose } from "modules/core";
import AssessmentReportView from "modules/poc-dashboard/components/dashboard/AssessmentReportView";
import React from "react";
import { withApollo } from '@apollo/client/react/hoc';
import '../../css/poc-dashboard-style.css'
import { getEmployeeBase64StringFromInt, getResponsesBase64StringFromInt, getUserBase64StringFromInt } from "modules/look";
import { ORG_MANAGER_EMPLOYEE_COUNT, ORG_SURVEY_360_REPORTS, ORG_USER_DETAIL } from "modules/poc-dashboard/graphql/pocDashboardQuery.gql";
const AssessmentReportContainer =(props)=>{
   
    const { client,me,history } = props
    const [SurveyReport,setSurveyReport]=React.useState()
    const [permission,setpermission]= React.useState(false)
    const [user_details,setUserDetails]=React.useState()
    const [response_loading,setResponseLoading]=React.useState(false)
    const getSurveyReport = async (filterData) => {
        const { data } = await client.query({
            query: ORG_SURVEY_360_REPORTS,
            variables: filterData,
            fetchPolicy: 'network-only'
        });

        if(data?.responseByResponseId){
            if(typeof(data.responseByResponseId?.report360) === 'string'){
              data.responseByResponseId.report360 = JSON.parse(data?.responseByResponseId?.report360)
            }
            setSurveyReport(data?.responseByResponseId)
    
        }
        
    }
    const getUserDetail =async (user_id)=>{
      const { data } = await client.query({
        query: ORG_USER_DETAIL,
        variables:{ id:getEmployeeBase64StringFromInt(user_id)},
        fetchPolicy: 'network-only'
    });
    if(data?.employeeById){
      setUserDetails(data?.employeeById?.user)
    }
    } 
    const getEmployeeData = async()=>{
        const { data } = await client.query({
              query: ORG_MANAGER_EMPLOYEE_COUNT,
          });
     
          if(data?.me){
            let Detail =data?.me?.employee
            if(Detail?.teamManagerEmployee?.totalCount || Detail?.orgCeoEmployee?.totalCount || Detail?.orgPocEmployee?.totalCount || Detail?.verticalHeadEmployee?.totalCount){
              setpermission(true)
            }
            else{
              setpermission(false)
            }
          }
      }

    React.useEffect(()=>{
        if(me){
            if(props?.match?.params?.responseid){
              setResponseLoading(true)
                let id = getResponsesBase64StringFromInt( props?.match?.params?.responseid )
                getSurveyReport({ id: id})
                getUserDetail(props?.match?.params?.user_id)
                getEmployeeData()
            }
            
          }
          else if (me===null){
            history.push('/user/login')
          }
    },[me])

    return (
        <>
        <AssessmentReportView user_details={user_details} SurveyReport={SurveyReport} permission={permission} response_loading={response_loading}{...props}/>
        </>
    )
}

export default compose(withApollo)(AssessmentReportContainer);