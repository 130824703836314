import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { Space, Col, Row, Empty, Button, Carousel, Progress } from 'antd';
import { CaretRightOutlined, CheckOutlined } from '@ant-design/icons';
import {
  ReactMarkdown,
  RadioField,
  MLCheckBoxField,
  TextField,
  MLSelectField,
  RangeField,
  SelectField,
  displayDataCheck,
  ColorField,
  InputValuesObject,
  DatePickerField,
  RenderUpload
} from '../../look';

import ProgressCounterBtn from './ProgressCounterBtn';

export const TIME_LIMIT = 5;

const emailRules = { type: 'email', message: 'Not a vaild email' };

export function getTypeFromQuestion(question) {
  return question?.choiceSet?.edges[0]?.node?.inputType;
}

const SectionComponent = props => {
  const {
    questionList,
    matrix,
    sectionLabel,
    form,
    nextStep,
    srNo,
    timeoutFunc,
    closetimer,
    currentIsMatrix,
    // currentSection,
    showOk,
    setShowOk,
    currentMatrixQuestion
  } = props;
  console.log("matrix",matrix)
  const getRangeValues = (choiceSet, type) => {
    let defaultValue = 0,
      marks = {},
      step = 1;
    const value = choiceSet[0]?.value.split(':') || [];
    if (type === 'RANGE') {
      marks[value[0]] = value[0];
      marks[value[1]] = value[1];
      defaultValue = value[2];
      step = value[3];
    }
    return { step, marks, defaultValue, min: Number(value[0]), max: Number(value[1]) };
  };

  const RenderQuestion = (question, index) => {
    const [colorLoading, setColorLoading] = React.useState(false);
    const [load, setLoad] = useState(false);
    const { id, sequence, questionText, choiceSet, required, helpText } = question;
    const type = getTypeFromQuestion(question);
    const [reset, setReset] = useState(false);

    const label = (
      <Space align="baseline">
        <SequenceNo>{srNo}</SequenceNo>
        <h3 style={{ fontWeight: 'bold', marginLeft: '4px' }}>{questionText}</h3>
      </Space>
    );

    const renderLabel = (
      <div>
        {label}
        <ReactMarkdown style={{ marginLeft: '3vw' }}>{helpText}</ReactMarkdown>
      </div>
    );

    const rules = [{ required: true, message: 'All questions are required!' }];

    const { step, marks, defaultValue, min, max } = getRangeValues(
      choiceSet?.edges?.map(({ node }) => node),
      type
    );

    const handleChange = doNext => {
      if (doNext) {
        if (showOk) {
          setShowOk(false);
          setShowOk(true);
          setReset(true);
          closetimer();
        } else {
          setShowOk(true);
          timeoutFunc(srNo);
        }
      } else {
        setShowOk(true);
      }
    };

    return true ? (
      <div key={index} style={{ /* minHeight: '75vh', */ padding: '3vh 0' }}>
        {/* {currentSection === srNo && ( */}
        <>
          {type === InputValuesObject.checkbox && (
            <MLCheckBoxField
              name={id}
              label={renderLabel}
              rules={required ? rules : []}
              choices={choiceSet.edges.map(({ node }) => node).map(c => ({ label: c.label, value: c.value }))}
              onChange={e => e && handleChange(false)}
              disabled={true}
            />
          )}

          {type === InputValuesObject.color && !colorLoading && (
            <ColorField
              name={id}
              label={renderLabel}
              rules={required ? rules : []}
              choices={choiceSet.edges.map(({ node }) => node).map(c => ({ label: c.label, value: c.value }))}
              onChange={e => {
                setColorLoading(true);
                e && form.setFieldsValue(_.set(form.getFieldsValue(true), [id], e)) && setShowOk(true);
                setColorLoading(false);
              }}
              value={form.getFieldValue(id)}
              disabled={true}
            />
          )}

          {type === InputValuesObject.date && (
            <DatePickerField
              margin={false}
              name={id}
              label={label}
              offset={3}
              format="YYYY-MM-DD"
              rules={[{ required, message: 'Please select a date' }]}
              enabledisabledDate={false}
              onChange={e => e && handleChange(true)}
              disabled={true}
            />
          )}

          {type === InputValuesObject.datetime && (
            <DatePickerField
              margin={false}
              name={id}
              label={label}
              offset={3}
              showTime={{ format: 'HH:mm' }}
              format="YYYY-MM-DD HH:mm"
              rules={[{ required, message: 'Please select a date and time' }]}
              enabledisabledDate={false}
              onChange={e => e && handleChange(true)}
              disabled={true}
              // defaultValue={moment('2015-06-06', 'YYY-MM-DD')}
            />
          )}

          {type === InputValuesObject.month && (
            <DatePickerField
              margin={false}
              name={id}
              label={label}
              offset={3}
              rules={[{ required, message: 'Please select a month' }]}
              enabledisabledDate={false}
              picker="month"
              onChange={e => e && handleChange(true)}
              disabled={true}
              // defaultValue={moment('2015-06-06', 'YYY-MM-DD')}
            />
          )}

          {type === InputValuesObject.time && (
            <DatePickerField
              margin={false}
              name={id}
              label={label}
              offset={3}
              rules={[{ required, message: 'Please select a month' }]}
              enabledisabledDate={false}
              type="time"
              onChange={e => e && handleChange(true)}
              disabled={true}
              // defaultValue={moment('2015-06-06', 'YYY-MM-DD')}
            />
          )}

          {type === InputValuesObject.week && (
            <DatePickerField
              margin={false}
              name={id}
              label={label}
              offset={3}
              rules={[{ required, message: 'Please select a month' }]}
              enabledisabledDate={false}
              picker="week"
              onChange={e => e && handleChange(true)}
              disabled={true}
              // defaultValue={moment('2015-06-06', 'YYY-MM-DD')}
            />
          )}

          {type === InputValuesObject.radio && (
            <RadioField
              name={id}
              label={renderLabel}
              choices={choiceSet.edges.map(({ node }) => node).map(c => ({ label: c.label, value: c.value }))}
              rules={required ? rules : []}
              onChange={e => e?.target?.value && handleChange(true)}
              disabled={true}
            />
          )}

          {type === InputValuesObject.range && (
            <RangeField
              name={id}
              label={
                <div style={{ minWidth: '25vw' }}>
                  {label}
                  <ReactMarkdown style={{ marginLeft: '3vw' }}>{helpText}</ReactMarkdown>
                </div>
              }
              // rules={required ? rules : []}
              marks={marks}
              step={step}
              defaultValue={defaultValue}
              min={min}
              max={max}
              trackStyle={{ backgroundColor: '#881FCC' }}
              handleStyle={{ backgroundColor: '#881FCC' }}
              onAfterChange={e => e && handleChange(true)}
              disabled={true}
            />
          )}

          {type === InputValuesObject.email && (
            <TextField
              name={id}
              label={renderLabel}
              rules={required ? rules.map(r => ({ ...r, ...emailRules })) : [{ ...emailRules }]}
              t
              // rules={[{ type: 'email' }]}
              offset={2}
              // onChange={e => e.target.value && handleChange(false)}
              onBlur={e => e?.target?.value && handleChange(false)}
              disabled={true}
            />
          )}

          {type === InputValuesObject.file && (
            <RenderUpload
              setUrl={e => form.setFieldsValue(_.set(form.getFieldsValue(true), id, e))}
              setLoad={setLoad}
              offset={2}
              name={id}
              label={renderLabel}
              disabled={true}
            />
          )}

          {type === InputValuesObject.text && (
            <TextField
              name={id}
              label={renderLabel}
              // rules={required ? rules : []}
              offset={2}
              onBlur={e => e?.target?.value && handleChange(false)}
              disabled={true}
            />
          )}

          {type === InputValuesObject.number && (
            <TextField
              name={id}
              label={renderLabel}
              rules={required ? rules : []}
              type="number"
              offset={2}
              // onChange={e => e && handleChange(false)}
              onBlur={e => e?.target?.value && handleChange(false)}
              disabled={true}
            />
          )}

          {type === InputValuesObject.select && (
            <SelectField
              otherOption={true}
              name={id}
              offset={2}
              label={renderLabel}
              rules={required ? rules : []}
              choices={choiceSet.edges.map(({ node }) => node).map(c => ({ label: c.label, value: c.value }))}
              onSelectChange={e => e && handleChange(false)}
              onBlur={e => e?.target?.value && handleChange(false)}
              disabled={true}
            />
          )}
        </>
        {/* )} */}
      </div>
    ) : null;
  };

  return matrix && matrix ? (
    <MatrixDiv /* currentIsMatrix={currentSection === srNo && currentIsMatrix} */>
      <Space align="baseline">
        <SequenceNo>{srNo}</SequenceNo>
        <h3 style={{ fontWeight: 'bold' }}>{displayDataCheck(sectionLabel)}</h3>
      </Space>
      <Row style={{ paddingBottom: '2vh', paddingTop: '2vh' }}>
        <Col span={8}></Col>
        <Col span={16}>
          <Row>
            {questionList[1]?.choiceSet?.edges?.map(({ node: choice }, key) => (
              <Col span={6} align="center">
                {choice?.label}
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      <Row style={{ backgroundColor: 'rgba(202, 202, 202, 0.05)', marginBottom: '2vh' }}>
        {questionList?.length > 0 ? (
          questionList
            ?.sort(function (a, b) {
              return parseInt(a.id) - parseInt(b.id);
            })
            .map((question, index) => {
              const { id, questionText, choiceSet, required } = question;
              const rules = [{ required: true, message: 'All questions are required!' }];
              return (
                <Col span={23} offset={1}>
                  {/* {currentSection === srNo && ( */}
                  <MLSelectField
                    otherOption={true}
                    name={id}
                    label={<Space>{questionText}</Space>}
                    choices={choiceSet?.edges?.map(({ node }) => node).map(c => ({ label: c.label, value: c.value }))}
                    rules={required ? rules : []}
                    disabled={true}
                  />
                  {/* )} */}
                </Col>
              );
            })
        ) : (
          <br />
        )}
      </Row>
    </MatrixDiv>
  ) : questionList?.length > 0 ? (
    questionList?.map(RenderQuestion)
  ) : (
    <Empty />
  );
};

export default SectionComponent;

const SequenceNo = styled.div`
  border-radius: 50%;
  width: 28px;
  height: 28px;
  justify-content: center;
  align-items: center;
  background: rgba(98, 98, 98, 1);
  color: white;
  display: inline-flex;
`;

const MatrixDiv = styled.div`
  height: 75vh;
  padding: 3vh 0;
  overflow-y: auto;
`;
