import gql from 'graphql-tag';

export const GET_LIST_BADGE =gql`
    query Badges($badgeFamily: ID){
        Badges(badgeFamily:$badgeFamily){
            edges{
                node{
                    id
                    title
                    behaviouralDescription
                    image
                    duration
                }
            }
          }
    }
`