import React from "react";
import IssuedAwardView from "../component/IssuedAwardView";
import { compose } from "modules/core";
import { withApollo } from '@apollo/client/react/hoc';
import { getIntFromString } from "modules/look";
import { ISSUED_AWARD_LIST } from "../graphql/issuedAwardList.gql";
import { withDeleteBadgeAward } from "./badgeAwardOperation";
import { BADGE_AWARD_SUBSCRIPTION } from "../graphql/badgeAwardSubscription.gql";

const IssuedAwardContainer =(props)=>{
    const {client,userPermission,me,search_award}=props
    const [loading,setLoading]=React.useState(false)
    const [issuedAwardList,setIssuedAwardList]=React.useState()


    const m = React.useRef( true )

    let badgeAwardSub = undefined

    React.useEffect( () => {
        return () => {
          if ( badgeAwardSub ) {
            badgeAwardSub.unsubscribe();
          }
        }
      } )
    
      React.useEffect( () => {
        return () => {
          m.current = false
        }
      }, [] )
    
      React.useEffect( () => {
        m.current = true
    
      }, [] )

    const getBadgeFamilyList=async(filterValue)=>{
        setLoading(true)
        const { data } = await client.query({
          query: ISSUED_AWARD_LIST,
          variables: {...filterValue},
          fetchPolicy:"network-only"
        });
        if(data){
            setIssuedAwardList(data?.badgeAwards?.edges?.map( ( { node } ) => node ))
        }
        badgeAwardSub = client.subscribe( {
            query: BADGE_AWARD_SUBSCRIPTION,
            variables: filterValue
          } ).subscribe( {
            next( result ) {
              const data = client.readQuery( { query: ISSUED_AWARD_LIST, variables: filterValue } )
              let receivedBadgeCacheData = data?.badgeAwards?.edges?.map( ( { node } ) => node )
              switch ( result.data.badgeAwardSubscription.mutation ) {
                case 'CREATE':
                  const updated = [ ...receivedBadgeCacheData, result.data.badgeAwardSubscription.badgeAward ]
                  if ( m.current ) {
                    setIssuedAwardList( updated )
                  }
                  break
                case 'UPDATE':
                  if ( m.current ) {
                    setIssuedAwardList(
                        receivedBadgeCacheData.map( item =>
                        item.id === result?.data?.badgeAwardSubscription?.badgeAward?.id
                          ? result.data.badgeAwardSubscription.badgeAward
                          : item
                      ) )
                  }
                  break
                case 'DELETE':
                  if ( m.current ) {
                    setIssuedAwardList(
                        receivedBadgeCacheData.filter( item =>
                        item.id === result?.data?.badgeAwardSubscription?.badgeAward?.id
                          ? false
                          : true
                      ) )
                  }
                  break
                default:
                  break
              }
            }
          } )
          setLoading(false)
    }

    React.useEffect(()=>{
        if(search_award){
            getBadgeFamilyList({issuer:me?.employee?.id,badge_Title_Icontains:search_award})
        }
        else{
            getBadgeFamilyList({issuer:me?.employee?.id})
        }
    },[search_award])
    React.useEffect(()=>{
    if(me?.employee?.user?.id){
        getBadgeFamilyList({issuer:me?.employee?.id})
    }
  },[me])

    return(
        <>
            <IssuedAwardView {...props} loading={loading} issuedAwardList={issuedAwardList}/>
        </>
    )
}

export default compose(withApollo,withDeleteBadgeAward)(IssuedAwardContainer)