
import gql from 'graphql-tag';



export const BARS_INFO = gql`
  fragment BehaviourallyAnchoredRatingType on BehaviourallyAnchoredRatingType
  {
    id
    mainCompetency {
      id
      title
      competencySet {
        edges {
          node {
            id
            title
            behaviourallyanchoredratingSet {
              edgeCount
              edges {
                node {
                  id
                  behaviourExample
                  employeeLevel {
                    id
                    name
                  }
                  ratingScale {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
      behaviourallyanchoredratingSet {
        edges {
          node {
            id
            behaviourExample
            employeeLevel {
              id
              name
            }
            ratingScale {
              id
              name
            }
          }
        }
      }
    }
      employeeLevel {
        id
        name
      }
  }
`;


export const BARS_LIST = gql`
  query allBehaviourallyAnchoredRatings($title_Icontains:String,$mainCompetency_Title_Icontains:String,$mainCompetency_Isnull:Boolean,$offset:Int,$first:Int) {
    allBehaviourallyAnchoredRatings(mainCompetency_Title_Icontains:$mainCompetency_Title_Icontains,mainCompetency_Isnull:$mainCompetency_Isnull,first:$first,offset:$offset) {
      totalCount
      edgeCount
      edges {
          node {
              id
              mainCompetency {
                id
                title
                competencySet(title_Icontains:$title_Icontains) {
                  edges {
                    node {
                      id
                      title
                      behaviourallyanchoredratingSet {
                        edgeCount
                        edges {
                          node {
                            id
                            behaviourExample
                            employeeLevel {
                              id
                              name
                            }
                            ratingScale {
                              id
                              name
                            }
                          }
                        }
                      }
                    }
                  }
                }
                behaviourallyanchoredratingSet {
                  edges {
                    node {
                      id
                      behaviourExample
                      employeeLevel {
                        id
                        name
                      }
                      ratingScale {
                        id
                        name
                      }
                    }
                  }
                }
              }
                employeeLevel {
                  id
                  name
                }
            
          }
        }
      }
  }
`;




export const ALL_BEHAVIOUR_LIST_SUBSCRIPTION =gql`
${BARS_INFO}
subscription behaviourallyAnchoredRatingSubscription {
  behaviourallyAnchoredRatingSubscription {
    mutation
    behaviourallyAnchoredRating{
      ...BehaviourallyAnchoredRatingType
    }
  }
}
`

export const BARS_DELETE = gql`
mutation deleteBehaviourallyAnchoredRating($behaviourallyAnchoredRatingId: ID!){
    deleteBehaviourallyAnchoredRating(behaviourallyAnchoredRatingId:$behaviourallyAnchoredRatingId){
        behaviourallyAnchoredRating{
            id
        }
    }
}
`


export const ALL_EMPLOYEE_LEVELS = gql`
  query allEmployeeLevels($name_Icontains: String,$organization:ID) {
    allEmployeeLevels(name_Icontains:$name_Icontains,organization:$organization){
        edges{
            node{
                id
                name
            }
        }
    }
  }
`;


export const ALL_RATING_SCALES = gql`
  query ratingScales {
    ratingScales(orderBy:["sequence"]){
        totalCount
        edges{
          node{
            id
            name
            sequence
          }
        }
    }
  }
`;

