import gql from 'graphql-tag';

export const ISSUED_AWARD_LIST = gql`
query badgeAwards($issuer: ID,$badge_Title_Icontains:String){
    badgeAwards(issuer: $issuer,badge_Title_Icontains:$badge_Title_Icontains) {
        edges {
          node {
            id   
            issuer {    
              id   
              user{
                id
                firstName
                lastName
              } 
            }    
            recipient {    
              id   
              user{
                id
                firstName
                lastName
              } 
            }    
            evidence   
            expirationDate    
            badge{    
              id
              image    
              title    
              badgeFamily {
                id
                title
              }
              behaviouralDescription   
            }   
          }    
        }    
    }
}
`