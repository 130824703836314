import gql from 'graphql-tag';

export const CREATE_PARAMETRIC_GROUP = gql`
mutation createParametricGroup($parametricGroupData: ParametricGroupInput!){
    createParametricGroup(parametricGroupData: $parametricGroupData){
        parametricGroup{
            id
          }
    }
}
`
export const UPDATE_PARAMETRIC_GROUP = gql`
mutation updateParametricGroup($id: ID!,$name: String!){
    updateParametricGroup(id:$id,name:$name){
        parametricGroup{
            id
          }
    }
}
`
export const DELETE_PARAMETRIC_GROUP = gql`
mutation deleteParametricGroup($parametricGroupId: ID!){
    deleteParametricGroup(parametricGroupId:$parametricGroupId){
        parametricGroup{
            id
          }
    }
}
`