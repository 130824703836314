import React from 'react';
import gql from 'graphql-tag';
import { Spin } from 'antd';
import { graphql } from '@apollo/client/react/hoc';

import ROUTE from '../../../organization/route';

import SelectField from './SelectField';
import { getIntFromString } from '../functions';

const TEAMS_QUERY = gql`
  query Teams {
    Teams {
      edges {
        node {
          id
          name
          vertical {
            id
            name
            organization {
              id
              name
            }
          }
        }
      }
    }
  }
`;

const TeamField = props => {
  const { loading, Teams, onAdd, onEdit } = props;

  const choices =
    !loading &&
    Teams &&
    Teams.edges.length > 0 &&
    Teams.edges.map(({ node }) => ({
      label: node.vertical
        ? node.vertical.organization.name + ' | ' + node.vertical.name + ' | ' + node.name
        : node.name || '',
      value: Number(getIntFromString(node.id))
    }));

  return (
    <Spin spinning={loading} size="small">
      {!loading && (
        <SelectField
          {...props}
          onAdd={onAdd && ROUTE.addTeam}
          onEdit={onEdit && ROUTE.editTeamLink}
          choices={!loading && Teams ? choices : []}
        />
      )}
    </Spin>
  );
};

export default graphql(TEAMS_QUERY, {
  props({ data: { loading, error, Teams, subscribeToMore, updateQuery } }) {
    return { loading, error, Teams, subscribeToMore, updateQuery };
  }
})(TeamField);
