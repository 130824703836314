// @ts-nocheck
import { Button, Input, Modal, Spin, Tabs } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import search from '../../assets/search.svg';
import Back_icon from '../../assets/soul-back.svg';
import Badge_family_create_container from "../container/badge_family_create_container";
import Badge_family_update_container from "../container/badge_family_update_container";
import KudosDeleteConfirmation from '../container/DeleteConfirmation';
import ExistingBadgeFamilyContainer from '../container/existing_badge_container';
import MyCreationBadgeFamilyContainer from '../container/my_creation_container';
import "../kudos.css";
const BadgeFamilyListView = (props) => {

  const { me, loading, badge_family_list, permission, subcriptionChange, showPermission, permissionLoader } = props;
  const [tab, setTab] = React.useState("all")
  const [badge_family_modal, setbadge_family_modal] = React.useState(false)
  const [badge_family_update_data, setbadge_family_update_data] = React.useState()
  const [deleteType, setdeleteType] = React.useState()
  const [search_job_familyMine, setSearchMine] = React.useState()
  const [search_job_familyAll, setSearchAll] = React.useState()
  const history = useHistory()
  const goHome = () => {
    history.push("/")
  }
  const addBadgeFamily = () => {
    if (permission.requried_permission) {
      setbadge_family_modal(true)
    }
  }
  React.useEffect(() => {
    if (subcriptionChange?.length) {
      if (subcriptionChange[0].mutation === "CREATE") {
        console.log('mine', subcriptionChange[0].mutation);
        setTab('mine')
      }
    }
  }, [subcriptionChange])

 


  const handleSearch = (search) =>{

    if(tab === "mine"){
        setSearchMine(search) 
    }else if(tab === "all"){
      setSearchAll(search) 
    }else{
      return null
    }

  }

  

  return (
    <>
      {/* create badge family */}
      <Modal
        visible={badge_family_modal}
        destroyOnClose={true}
        footer={false}
        closeIcon={false}
        closable={false}
        className="kudos-modal"
        centered={true}
      >
        <Badge_family_create_container closeModal={() => { setbadge_family_modal(false) }}  {...props} />
      </Modal>
      {/* update badge family */}
      <Modal
        visible={badge_family_update_data}
        destroyOnClose={true}
        footer={false}
        closeIcon={false}
        closable={false}
        className="kudos-modal"
        centered={true}
      >
        <Badge_family_update_container permission={permission} badge_family_update_data={badge_family_update_data} closeModal={() => { setbadge_family_update_data(null) }}  {...props} />
      </Modal>
      {/* delete confirmation */}
      <Modal
        visible={deleteType}
        destroyOnClose={true}
        footer={false}
        closeIcon={false}
        closable={false}
        className="kudos-modal-delete"
        centered={true}
      >
        <KudosDeleteConfirmation closeModal={() => { setdeleteType() }} deleteType={deleteType}  {...props} />
      </Modal>

      <Spin spinning={loading}>
        <div className="back-arrow-global" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          <img style={{ cursor: "pointer", margin: '1em' }} src={Back_icon} onClick={() => { history.goBack() }} alt="" />
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center", gap: "20px" }}>
            <h4 className="kudos-tool-titile" style={{ fontWeight: "700", margin: 0 }}>Kudos Family</h4>

          </div>
          <img style={{ cursor: "pointer", visibility: 'hidden', margin: '1em' }} src={Back_icon} alt="" />

        </div>
        
            <div className="kudos-root-class">
            {/* <div className="kudos-space-between-row">
              <img style={{cursor:"pointer", margin: '0 1.4em'}} src={Back_icon} onClick={()=>{history.goBack()}} alt=""/>
            <h4 className="kudos-tool-titile" style={{fontWeight:"700"}}>KUDOS FAMILY</h4>
              <img style={{cursor:"pointer", visibility: 'hidden', margin: '0 1.4em'}} src={Back_icon} alt=""/>
          </div> */}


            <p className="kudos-sub-title">CREATE YOUR  KUDOS FAMILY </p>

            <div className="kudos-col-450" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginTop: '2em' }} >

              <div className="kudos-search-container kudos-hide-450"></div>

              {tab === 'mine' && permission.requried_permission && permission?.requried_permission && <Button className='kudos-add-badge' style={{ zIndex: 100 }} onClick={() => { addBadgeFamily() }}>add new +</Button>}

              <div className="kudos-search-container" style={{ marginTop: tab === 'mine' ? '10px' : "0px" }}>
                <img className="kudos-search-icon" style={{ zIndex: 150 }} src={search} alt="" />
                <Input className="kudos-search-input" autoComplete='off' style={{ zIndex: 100 }} value={search_job_familyMine ? search_job_familyMine : search_job_familyAll} onChange={(e) => { handleSearch(e?.target.value) }} />
              </div>
            </div>
            <div className="kudos-fluid-container" style={{ marginTop: "-5px" }}>
              <Tabs
                defaultActiveKey={tab}
                activeKey={tab}
                onChange={(e) => { setTab(e) }}
                style={{ marginTop: "-25px" }}
              >
                <Tabs.TabPane tab="Your creation" key="mine" className="kudos-root-class">
                  <MyCreationBadgeFamilyContainer fromCreationContainer={true} search_job_family={search_job_familyMine} setbadge_family_update_data={setbadge_family_update_data} setdeleteType={setdeleteType} addNewBadgeFamily={() => addBadgeFamily()} permission={permission} {...props} />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Existing" key="all" className="kudos-root-class">
                  <ExistingBadgeFamilyContainer search_job_family={search_job_familyAll} setbadge_family_update_data={setbadge_family_update_data} setdeleteType={setdeleteType} {...props} clearSearch={() => setSearchAll()} permission={permission}/>
                </Tabs.TabPane>
              </Tabs>
            </div>
          </div>
        
      </Spin>



    </>
  )
};

export default BadgeFamilyListView;