import gql from 'graphql-tag';
import { CUSTOM_USER_COMMITMENT_TYPE } from './CustomUserCommitmentType.gql';

export const GET_ALL_CUSTOM_USER_COMMITMENTS = gql`
  ${CUSTOM_USER_COMMITMENT_TYPE}
  query getAllCustomUserCommitments(
    $orderBy: [String]
    $offset: Int
    $first: Int
    $title_Icontains: String
    $description_Icontains: String
  ) {
    getAllCustomUserCommitments(
      orderBy: $orderBy
      offset: $offset
      first: $first
      title_Icontains: $title_Icontains
      description_Icontains: $description_Icontains
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          ...CustomUserCommitmentType
        }
        cursor
      }
      edgeCount
      totalCount
    }
  }
`;

export const GET_ALL_CUSTOM_USER_COMMITMENTS_FOR_COACHING = gql`
  query customUserCommitmentForCoaching(
    $orderBy: [String]
    $offset: Int
    $first: Int
    $title_Icontains: String
    $description_Icontains: String
    $coachingId: ID!
  ) {
    customUserCommitmentForCoaching(
      orderBy: $orderBy
      offset: $offset
      first: $first
      title_Icontains: $title_Icontains
      description_Icontains: $description_Icontains
      coachingId: $coachingId
    ) {
      edges{
        node{
          id
          tool{
            id
          }
          user{
            employee{
              id
            }
            id
            username
            email
            firstName
            lastName
            customusercommitmentSet{
              edges{
                node{
                  user{
                    id
                    firstName
                    lastName
                  }
                  id
                  title
                  completed
                  dueDate
                }
              }
            }
          }
          title
          description
          completed
          dateCreated
          dateCompleted
          dueDate
        }
      }
    }
  }
`;

export const GET_ALL_CUSTOM_USER_COMMITMENTS_SUBSCRIPTION = gql`
${CUSTOM_USER_COMMITMENT_TYPE}
subscription toolsCustomUserCommitmentSubscription {
  toolsCustomUserCommitmentSubscription{
    mutation
    customUserCommitment{
      ...CustomUserCommitmentType
    }
  }
}
`

