import React from 'react';
import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import { Spin } from 'antd';

import SelectField from './SelectField';
import { getIntFromString } from '../functions';

export const LIST_ALL_STORY_TAGS_QUERY = gql`
  query allStoryTags {
    allStoryTags {
      edges {
        cursor
        node {
          id
          name
        }
      }
    }
  }
`;

const TagField = props => {
  const { loading, allStoryTags } = props;

  const choices =
    allStoryTags &&
    allStoryTags.edges.length > 0 &&
    allStoryTags.edges.map(({ node }) => ({
      label: node.name,
      value: getIntFromString(node.id)
    }));

  return (
    <Spin spinning={loading} size="small">
      <SelectField {...props} choices={choices} mode="multiple" />
    </Spin>
  );
};

export default graphql(LIST_ALL_STORY_TAGS_QUERY, {
  props({ data: { loading, error, fetchMore, allStoryTags, subscribeToMore, updateQuery } }) {
    return { loading, error, allStoryTags, subscribeToMore, updateQuery };
  }
})(TagField);
