import gql from 'graphql-tag';
import { STORY_TYPE } from './StoryType.gql';

export const LIKE_STORY = gql`
  ${STORY_TYPE}
  mutation likeStory($id: ID!) {
    likeStory(id: $id) {
      story {
        ...StoryType
      }
    }
  }
`;
