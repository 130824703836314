
import { gql, useMutation } from "@apollo/react-hooks";
import { Button, Dropdown, Input, Menu, message, Modal, Row, Table, Tooltip } from "antd";
import MenuItem from "antd/lib/menu/MenuItem";
import { getIntFromString } from "modules/look";
import React from "react";
import styled from "styled-components";
import { BARS_HOME_TOOL_ID } from "../../../config";
import BarsSideToolContainer from "../Containers/BarsSideToolContainer";
import '../css/BarsHome.css';
import { Bars_Home_Icons } from "../Icon";
import bars_route from "../route";
import BarsDeleteConfirmation from "./BarDeleteConfirmation";
import BarsExpandedComponent from "./BarsExpandedTable";



const BarsHomeView = (props) => {
  const { bars, barsLoading, setBarsLoading, getBarsList, setBars, allEmployeeLevels,
    allRatingScales,navigateRoute } = props

  const [barsList, setbarsList] = React.useState(undefined);
  const [empLevel, setEmpLevel] = React.useState(undefined);
  const [barToDelete, setBarToDelete] = React.useState(undefined);
  const [subCompetencyBarToDelete, setSubcompetencyBarToDelete] = React.useState(undefined);
  const [selectedEmpLevel, setSelectedEmpLevel] = React.useState(undefined);
  const [filter, setFilter] = React.useState(undefined);
  const [suffix, setSuffix] = React.useState(true);
  const [expandedRecord, setexpandedRecord] = React.useState([]);
  const [offSetCount, setOffSetCount] = React.useState(0);
  const [filters, setFilters] = React.useState(undefined);
  const [invalidInput,setInvalidInput] = React.useState(false)




  React.useEffect(()=>{

    window.scrollTo(0,0);
   
 },[])

  const timeout_ref = React.useRef(null)


  // Setting bars for received from container
  React.useEffect(() => {
    if (bars) {
      let list = bars?.uniqueBars?.map((node) => { return { ...node, key: getIntFromString(node?.id) } })
      setbarsList(list);

    }
  }, [bars])




  const handleLevelSelection = (record, bar, index) => {


    let list = [].concat(bars?.uniqueBars);
    
    let doc = list.find((value) => value?.id === bar?.id);
    setSelectedEmpLevel(index)
    
    // setEmpLevelForEdit(record)
    if (doc) {
      doc.selectedLevel = record;
      doc.employeeLevel.forEach((level) => {

        if (level?.id === record?.id) {
          return level.selectedLevel = true
        } else {
          return level.selectedLevel = false
        }
      }
      )
      
      list = { ...bars, uniqueBars: list }
      setBars(list);
    }



  }

  const getClassesOfElement = (node) => {
    
    let string;
  
    switch (node.selectedLevel) {
      case true:
        if (node?.status === "completed") {
          string = 'bars-filter-element poppins bars-selected-level'
        } else {
          string = 'bars-filter-element-incomplete poppins bars-selected-level'
        }
        break;
      case false:
        if (node?.status === "completed") {
          string = 'bars-filter-element poppins'
        } else {
          string = 'bars-filter-element-incomplete poppins'
        }
        break;
      default:
        break;
    }

    return string;

  }

  

  // Employee level dropdown
  const empLevelDropDownOverlay = (record) => {

    let allEmployeeLevels = [].concat(record?.employeeLevel);



    return (

      <Menu className="filter-overlay-container">
        {
          allEmployeeLevels?.map((node, index) =>
            <Menu.Item className={`text-ellipsis ${getClassesOfElement(node)}`} key={node?.node?.id} onClick={(e) => handleLevelSelection(node, record, index)}>
              {node?.name}
            </Menu.Item>
          )
        }
      </Menu>
    )
  }



  const DELETE_DEFINITION = gql`
  mutation deleteBehaviourallyAnchoredRating {
    ${barToDelete?.barsSet?.length && barToDelete?.barsSet
      ?.map(
        (item) =>
          `delete${item.intId}: deleteBehaviourallyAnchoredRating(behaviourallyAnchoredRatingId: "${item.intId}") {
          behaviourallyAnchoredRating{
            id
          }
         }
       `
      )
      .join('')}
  }
  `;
  const [deleteDefinition, { data, error }] = useMutation(DELETE_DEFINITION);







  
  // mutation call for deleting bar
  const handleDeleteBar = async () => {
    setBarsLoading(true)


    const response = await deleteDefinition()
    if (response?.data) {
      setBarsLoading(false)
      setBarToDelete(undefined)
      message.success('Submitted successfully');
    }



  }






  const renderStatus = (record) => {



    return (
      <div align="center">
        {
          record?.status === "Pending" ?
            <Row wrap={false} justify="center" align="middle" className='bars-status-container-incomplete'>
              <img src={Bars_Home_Icons.incomplete_bar} />
              <div>Pending</div>
            </Row>
            :
            <Row wrap={false} justify="center" align="middle" className='bars-status-container-complete'>
              <img src={Bars_Home_Icons.complete_bar} />
              <div>Completed</div>
            </Row>
        }
      </div>
    )

  }



  const handleEditBars = (record, mainCompetency,data) => {

    if (mainCompetency) {
      if (selectedEmpLevel) {
        navigateRoute(bars_route?.edit_bars?.replace(":bar_id",getIntFromString(record?.barsSet[0]?.id))?.replace(":level_id",selectedEmpLevel))
      } else {
        navigateRoute(bars_route?.edit_bars?.replace(":bar_id",getIntFromString(record?.barsSet[0]?.id))?.replace("/:level_id",''))
      }

    } else {
     
      localStorage.setItem('mainCompetencyData',JSON.stringify(data?.barsSet))
      if (selectedEmpLevel) {
        navigateRoute(bars_route?.edit_bars_sub_competency?.replace(":bar_id",getIntFromString(record?.id))?.replace(":level_id",selectedEmpLevel))
      } else {
        navigateRoute(bars_route?.edit_bars_sub_competency?.replace(":bar_id",getIntFromString(record?.id))?.replace("/:level_id",''))

      }

    }

  }


  const handleDetailsPageRoute = (mainCompetency, record) => {

    if (mainCompetency) {
      navigateRoute(bars_route?.bars_detail?.replace(":id",getIntFromString(record?.barsSet[0]?.id)))
    } else {
      navigateRoute(bars_route?.bars_sub_competency_detail?.replace(":id",getIntFromString(record?.id)))

    }

  }

  const handleDeleteBarData = (record, mainCompetency) => {
   
  
    if (mainCompetency) {
      let bars = [];
    let mainCompetencyBars = record?.barsSet;
    let subCompetencyBars = record?.competencySet?.map((value)=>value?.behaviourallyanchoredratingSet?.edges?.map(({node})=>{return{...node,intId: getIntFromString(node?.id)}}))?.flat()
    bars = [].concat(mainCompetencyBars).concat(subCompetencyBars)
      
      setBarToDelete({barsSet:bars})
    } else {
      if (record?.barsSet?.length) {
        setSubcompetencyBarToDelete(record?.barsSet)
      }

    }

  }

  // Dropdown overlay for actions view,edit and delete
  const actionOverlay = (record, mainCompetency,data) => (

    <Menu className="filter-overlay-container action-overlay-bar" style={{ padding: '0em' }}>
      <MenuItem >

        <Row align="middle" justify="start" className="bars-filter-element" style={{ gap: '0.5em' }} onClick={() => handleDetailsPageRoute(mainCompetency, record)}>
          <img className="bar-action-icon" src={Bars_Home_Icons.detail_page_view} />
          <div>View Details</div>
        </Row>
        <Row align="middle" justify="start" style={{ gap: '0.5em' }} onClick={(e) => handleEditBars(record, mainCompetency,data)} className="bars-filter-element"  >
          <img className="bar-action-icon" src={Bars_Home_Icons.edit_role_definition} />
          <div>Edit</div>
        </Row>
        <Row align="middle" justify="start" style={{ gap: '0.5em' }} onClick={(e) => handleDeleteBarData(record, mainCompetency)} className="bars-filter-element" >
          <img className="bar-action-icon" src={Bars_Home_Icons.delete_icon} />
          <div>Delete</div>
        </Row>
      </MenuItem>
    </Menu>

  )


  const renderIncompleteBarsAction = (record, mainCompetency,data) => (

    <Row justify="center" style={{ gap: '10px' }}>
      <Tooltip overlayClassName="bars-defining-hover-effect" title={"Continue defining BARS from where you left off"}>
        <div align='center'  onClick={(e) => handleEditBars(record, mainCompetency,data)} className="bars-incomplete-action pointer">
          Continue Defining BARS
        </div>
      </Tooltip>
     
      <Dropdown overlay={actionOverlay(record, mainCompetency,data)} overlayStyle={{zIndex:"1050"}}>
        {/* <p>test</p> */}
        <img className="pointer" src={Bars_Home_Icons.bars_action_set} />
      </Dropdown>
    </Row>

  )


  const columns = [

    {
      title: <div>Competency</div>,
      key: 'Competency',
      align: "left",
      width: '50px',
      render: (text, record) =>
        <div className="bars-table-attribute">{record.title}</div>
    },
    {
      title: <div >Level</div>,
      key: 'Level',
      align: "center",
      width: 50,
      render: (text, record) => record &&
        <div>
          <Dropdown overlayStyle={{maxWidth:'25%',zIndex:"1050"}} overlay={empLevelDropDownOverlay(record, true)}>
            <BarsDropdownButton>
              <p style={{textAlign:'center',margin:'0px'}} className="poppins text-ellipsis"  id={`empLevel-${getIntFromString(empLevel?.id)}`}>{record?.selectedLevel ? record?.selectedLevel?.name : 'Level'}</p>
            </BarsDropdownButton>
          </Dropdown>
        </div>

    },
    {
      title: 'Status',
      key: 'Action',
      width: 100,
      render: (text, record) => record &&
        <div>{renderStatus(record)}</div>
    },
    {
      title: 'Action',
      key: 'Action',
      width: 200,
      render: (text, record) => record &&
        record?.status === "completed" ?
        <Row wrap={false} style={{ gap: '10px' }} justify='center'>
          <img className="pointer bars-action-icon" onClick={(e) => navigateRoute(bars_route?.bars_detail?.replace(":id",getIntFromString(record?.barsSet[0]?.id)))} src={Bars_Home_Icons.detail_page_view} />
          <img className="pointer bars-action-icon" onClick={(e) => handleEditBars(record, true)} src={Bars_Home_Icons.edit_role_definition} />
          <img className="pointer bars-action-icon" onClick={(e) => handleDeleteBarData(record,true)} src={Bars_Home_Icons.delete_icon} />
        </Row>
        :
        renderIncompleteBarsAction(record, true)

    }
  ];


  const handleDropdownFilter = (dropdown) => {

    setFilter(dropdown)
    if (filters) {
      handleBarsFilter(filters, dropdown)
    }

  }



  const renderFilter = () => (

    <Menu className="filter-overlay-container" >
      <MenuItem className="bars-filter-element" onClick={(e) => handleDropdownFilter("comptency")}>Competency</MenuItem>
      <MenuItem className="bars-filter-element" onClick={(e) => handleDropdownFilter("sub-comptency")}>Sub Competency</MenuItem>
    </Menu>

  )


  // functioning of filters in bars home page
  const handleBarsFilter = (value, selectedFilter) => {

  
    if (selectedFilter) {
      setInvalidInput(false)
      clearTimeout(timeout_ref.current)

      timeout_ref.current = setTimeout(() => {
        let filters;
        switch (selectedFilter) {
          case "comptency":
            if (value?.trim() !== "") {
              filters = { mainCompetency_Title_Icontains: value }
              setFilters(value)
              setBars(undefined);
              getBarsList(filters)
            }else{
              getBarsList()
              setFilters("")
            }

            break;
          case "sub-comptency":
            if (value?.trim() !== "") {
              filters = { title_Icontains: value, mainCompetency_Isnull: false }
              setFilters(value)
              setBars(undefined);
              getBarsList(filters)
            }else{
              getBarsList()
              setFilters("")
            }

            break;
          default:
            break;
        }
      }, 600)
    } else {
      setFilters(value)
      setInvalidInput(true)
    }

  }


  const renderSuffix = () => (

    <div>
      {
        suffix ?
          <img src={Bars_Home_Icons.search_icon} className='bars-search-icon' alt="" />
          :
          null
      }
    </div>

  )

  const handlePagination = () => {

    setOffSetCount(offSetCount + 1);
    getBarsList({ ...filters, offset: ((offSetCount + 1)) * 100, first: 100 });

  }


  return (


    <Row justify="end" className="bars-home-root poppins">

      <div style={{ width: '95%', margin: 'auto' }} >

        {/* Bars header and back arrow container */}
        <Row className="bars-functionality-panel" align="middle" justify="center">
        <h4 className="bars-header" style={{textAlign:"center"}}>BARS Dashboard</h4>



          <div className="side-tool-role">
            <BarsSideToolContainer tool_id={BARS_HOME_TOOL_ID} {...props} />
          </div>

          <Button className="bars-add" onClick={(e) => navigateRoute(bars_route?.bars_define)}>+ Add</Button>


          {/* Filter panel */}
          <Row justify="center" wrap={false}>
            <Input className="bars-filter-input"
              // onMouseDown={(e) => setSuffix(false)}
              onMouseUp={(e) => setSuffix(true)}
              onClick={(e) => setSuffix(false)}
              suffix={renderSuffix()}
              onChange={(e) => handleBarsFilter(e.target.value, filter)} />

            <Dropdown overlay={renderFilter} overlayStyle={{zIndex:"1050"}}>
              <Row align="middle" justify="center" wrap={false} className="bars-filter-container">
                <div className="bars-selected-filter">{filter ? filter : "Filter"}</div>
                <img className="bars-filter-drop-down-icon pointer" src={Bars_Home_Icons.dropdown_arrow} />
              </Row>
            </Dropdown>
          </Row>
         
         
        </Row>
        {
            invalidInput &&  <p style={{fontSize:'0.8em',color:'red',textAlign:'center',position:'relative',right:'7em',bottom:'1em'}}>Please select a value from dropdown</p>
          }


        {/* table for listing all bars */}
        <div className="bars-table-gradient">

        </div>
        <Table
          className="bars-table"
          size={'middle'}
          pagination={false}
          loading={barsLoading}
          expandedRowKeys={expandedRecord}
          showHeader={true}
          dataSource={
            barsList
              ? barsList
              : []
          }
          columns={columns.map(c => ({ ...c, sortDirections: ['descend', 'ascend', 'descend'] }))}

          expandable={{
            expandedRowRender: (record, index, indent, expanded) => (

              <BarsExpandedComponent
                record={record?.competencySet}
                data = {record}
                index={index}
                indent={indent}
                expanded={expanded}
                bars={bars}
                // Props to render expanded table 
                renderStatus={renderStatus}
                renderIncompleteBarsAction={renderIncompleteBarsAction}
                allEmployeeLevels={allEmployeeLevels}
                allRatingScales={allRatingScales}
                subCompetencyBarToDelete={subCompetencyBarToDelete}
                setSubcompetencyBarToDelete={setSubcompetencyBarToDelete}
                navigateRoute = {navigateRoute}
              />
            ),
            expandIcon: ({ expanded, onExpand, record }) =>
            
            (
              expanded ? (
                <img src={Bars_Home_Icons.active_row}
                  onClick={e => {
                    onExpand(record, e); setexpandedRecord([]);
                  }} />

              ) : (
                <img
                  src={Bars_Home_Icons.non_active_row}
                  onClick={e => {
                    onExpand(record, e); setexpandedRecord([record?.key]);
                  }}
                />

              )
            )
            // : null
          }}
        />

        {/* Delete confirmation popup */}

        <Modal visible={barToDelete}
          footer={null}
          centered
          destroyOnClose={true}
          closable={false}>
          <BarsDeleteConfirmation
            message={'Are you sure you want to delete this BARS ?'}
            subMessage={"All your data for this competency, its sub-competencies and levels will be lost. Would you like to delete it anyway ?"}
            icon={Bars_Home_Icons.warning_bar}
            onConfirm={() => setBarToDelete(undefined)}
            onCancel={() => handleDeleteBar()}
            confirm_action_label='Cancel'
            cancel_action_label='Delete'
            confirm_class="bars-cancel-action"
            cancel_class="bars-confirm-action-delete"
          />
        </Modal>
      </div>


      {
        bars?.uniqueBars?.length && bars?.edgeCount !== bars?.totalCount ?
          <Row justify="center" align="middle" className="pointer view-more-bars poppins" onClick={(e) => handlePagination()}>View More</Row>
          : null
      }

    </Row>
  );
};

export default BarsHomeView;

export const BarsDropdownButton = styled(Button)`
width:200px;
display:flex;
justify-content:center;
align-items:center;
text-align:center;
gap:5px;
height:37px;
border-radius:0.5em;
font-family:poppins;
`