import gql from 'graphql-tag';
import { GROUP_TYPE } from './GroupType.gql';

export const EDIT_GROUP = gql`
  ${GROUP_TYPE}
  mutation editGroup(
    $helpText: String
    $id: ID!
    $label: String
    $matrix: Boolean
    $name: String
    $sequence: Int
    $showAvgInResult: Boolean
    $showInResult: Boolean
    $surveyId: Int
  ) {
    updateGroup(
      helpText: $helpText
      id: $id
      label: $label
      matrix: $matrix
      name: $name
      sequence: $sequence
      showAvgInResult: $showAvgInResult
      showInResult: $showInResult
      surveyId: $surveyId
    ) {
      group {
        ...GroupType
      }
    }
  }
`;
