

import { Button, message, Modal, Row, Tabs } from "antd";
import React from "react";
import '../css/CreateBars.css';
import { Bars_Create_Subcompetency_Icons, CREATE_BARS_ICON } from "../Icon";
import bars_route from "../route";
import BarsDeleteConfirmation from "./BarDeleteConfirmation";
import BarsAddInfo from "./BarsAddInfo";



const BarsSubCompetencyCreateView = (props) => {
  const { competencyById, createBulkBehaviourallyAnchoredRatings, ratingScales,
    importModalVisible, setImportModalVisible, importLoading, setCompetencyById,navigateRoute, allEmployeeLevels } = props


  const [barsToAdd, setBarsToAdd] = React.useState([]);
  const [subCompetencyModalVisible, setSubCompetencyModalVisible] = React.useState(false)
  const [activeCompetency, setActiveCompetency] = React.useState(0);
  const [dataSource, setDataSource] = React.useState(undefined);
  const [activeLevel, setActiveLevel] = React.useState(0);
  const [invalidBars, setInvalidBars] = React.useState(false);
  const [invalidBehaviourExamples, setInvalidBehaviourExamples] = React.useState(undefined);
  const [importedData, setImportedData] = React.useState(undefined);
  const barsUpdateRef = React.useRef(undefined);

  

  React.useEffect(() => {

    window.scrollTo(0, 0);

  }, [])


  React.useEffect(() => {

    if (competencyById) {
      setDataSource(competencyById)
    }

  }, [competencyById])



  




 








  const handleBarsSubmission = async () => {

    let bars = [];
    let updatedBars = []
    
    bars =
      competencyById?.competencySet?.map((record) =>

        record?.employeeLevel?.map((level) =>
          level?.ratingScales?.map((scale) => {
            
            if (scale?.behaviourExample && !scale?.barIntId) {
              return {
                competency: { behaviourId: record?.id },
                ratingScaleId: scale?.id,
                employeeLevelId: level?.id,
                behaviourExample: scale?.behaviourExample
              }
            } else {
              updatedBars.push(scale)
            }
          }
          )

        )?.flat()?.filter((value) => value !== null)


      )?.flat()

    


    bars = bars?.filter((value) => value !== undefined)

    updatedBars = updatedBars?.filter((value) => value?.barIntId);

    barsUpdateRef.current = updatedBars;
    
    let  createResponse
    
    if (bars?.length) {
      createResponse = await createBulkBehaviourallyAnchoredRatings({ behaviourallyAnchoredRatingDataList: bars })
      message.success('Submitted successfully');
      localStorage.removeItem('mainCompetencyData')
      navigateRoute( bars_route.bars_home)
    } else{
      setInvalidBars(true)
    }


  

  }

  React.useEffect(() => {

    setActiveLevel(0);

  }, [activeCompetency])


  const handleCancelSubCompetency = () => {

    setSubCompetencyModalVisible(false);
    navigateRoute( bars_route.bars_home)

  }


  const handleSubCompetencyConfirm = () => {

    setSubCompetencyModalVisible(false)
    navigateRoute('/hr/bars/create/subcompetency')

  }


  const handleImportCompetency = async () => {

    if (activeLevel > 0) {

      let levelToEdit = competencyById?.competencySet[activeCompetency]?.employeeLevel[activeLevel];

      let test = competencyById?.competencySet[activeCompetency]?.employeeLevel[activeLevel - 1]?.ratingScales?.forEach((scale) =>

        handleExampleChange(scale?.behaviourExample, levelToEdit, scale, competencyById?.competencySet[activeCompetency])

      )


    } else {

      let importedData = JSON.parse(localStorage.getItem('mainCompetencyData'));

      let test = importedData?.ratingScales?.forEach((scale) =>

        handleExampleChange(scale?.behaviourExample, importedData, scale, competencyById?.competencySet[activeCompetency])

      )
      setImportedData(importedData)

    }

    setInvalidBehaviourExamples(true);
    setImportModalVisible(false)




  }






  const checkStatus = (data) => {



    let levelToCheck = data?.competencySet[activeCompetency]?.employeeLevel[activeLevel]?.ratingScales?.map((scale) => scale?.behaviourExample?.trim());

    if (levelToCheck?.length !== ratingScales?.length && !(invalidBehaviourExamples)) {
      data.competencySet[activeCompetency].employeeLevel[activeLevel].status = 'pending'
    } else {
      data.competencySet[activeCompetency].employeeLevel[activeLevel].status = 'completed'
    }


    setCompetencyById(data);

  }




  const handleExampleChange = (e, level, scale, compData) => {

    let data;
    setCompetencyById((prevState) => {
      const competencySet = prevState.competencySet.map((competency) => {
        if (competency.id === compData?.id) {
          return {
            ...competency,
            employeeLevel: competency.employeeLevel.map(
              (employeeLevel) => {
                if (employeeLevel.id === level?.id) {
                  return {
                    ...employeeLevel,
                    ratingScales: employeeLevel.ratingScales.map(
                      (ratingScale) => {
                        if (ratingScale.id === scale?.id) {
                          return { ...ratingScale, behaviourExample: e };
                        }
                        return ratingScale;
                      }
                    ),
                  };
                }
                return employeeLevel;
              }
            ),
          };
        }
        return competency;
      });
      data = { ...prevState, competencySet }
      return data;
    });



    if (data) {
      let dataToCheck = importedData?.ratingScales?.map((value) => value?.behaviourExample?.trim());
      let dataFromCompetency = data?.competencySet[activeCompetency]?.employeeLevel[activeLevel]?.ratingScales?.map((value) => value?.behaviourExample?.trim())
      let comparison = dataToCheck?.every((value, index) => value === dataFromCompetency[index]);
      setInvalidBehaviourExamples(comparison);
      checkStatus(data)


    }

  }

  const handlePreviousRoute = () => {

    localStorage.removeItem('mainCompetencyData');
    navigateRoute( bars_route.bars_home)

  }

  

  return (

    <div className="create-bars-root poppins">



      <img src={CREATE_BARS_ICON.new_back_arrow} onClick={(e) => handlePreviousRoute()} className="create-bar-back-arrow pointer" />

      <Row align="middle" justify="center">
        <div className="create-bars-header">Define <span>BARS</span></div>
      </Row>
      <div className="create-bars-body">
        <Row justify="space-between">
          <div className="create-bar-competency-heading create-bar-margin-seperator">Competency - {dataSource?.title}</div>
          <img className="pointer" style={{ width: '1.2em' }} src={Bars_Create_Subcompetency_Icons.import_info} onClick={(e) => setImportModalVisible(true)} />
        </Row>

        <div className="create-bar-sub-heading create-bar-margin-seperator">Sub Competency</div>

        {/* Tabs of Sub competencies */}
        <Tabs className='bar-sub-competency-view' activeKey={activeCompetency.toString()}
          onChange={(e) => setActiveCompetency(e)}>
          {
            dataSource?.competencySet?.map((competency, index) =>
              <Tabs.TabPane tab={competency?.title} key={index} >

                <BarsAddInfo competency={competency}
                  setBarsToAdd={setBarsToAdd}
                  activeCompetency={activeCompetency}
                  barsToAdd={barsToAdd}
                  subCompetencies={dataSource?.competencySet}
                  indexOfElements={1}
                  compData={competency}
                  handleExampleChange={handleExampleChange}
                  activeLevel={activeLevel}
                  invalidBars={invalidBars}
                  setActiveLevel={setActiveLevel}
                  setActiveCompetency={setActiveCompetency}
                  mainCompetency={false}
                  invalidBehaviourExamples={invalidBehaviourExamples}
                  setInvalidBehaviourExamples={setInvalidBehaviourExamples}
                  ratingScales = {ratingScales}
                />


              </Tabs.TabPane>
            )
          }
        </Tabs>

        {/* Component for adding data into rating scales with respective employee levels */}

      </div>
      {

        (allEmployeeLevels?.length && ratingScales?.length) ?
        <Row justify="center">
        <Button className="bars-submit-action" onClick={(e) => handleBarsSubmission()}>Submit</Button>
        </Row>
        :null

      }
    
      <Modal
        visible={importModalVisible}
        footer={null}
        centered
        destroyOnClose={true}
        closable={false}
      >
        <BarsDeleteConfirmation
          message={"Would you like to import the rating scale from the previously defined competency? You will be able to proceed only after editing the rating scale for the sub-competency."}
          confirm_action_label={"Yes"}
          cancel_action_label={"No"}
          onConfirm={(e) => handleImportCompetency(dataSource?.id)}
          onCancel={(e) => setImportModalVisible(false)}
          confirm_class="bars-confimation-action"
          cancel_class="bars-cancel-action"
        ></BarsDeleteConfirmation>

      </Modal>

      <Modal
        visible={subCompetencyModalVisible}
        footer={null}
        centered
        destroyOnClose={true}
        closable={false}
      >
        <BarsDeleteConfirmation
          message={"Yay! You've defined the competencies! Would you like to continue defining the sub-competencies?"}
          confirm_action_label={"Yes"}
          cancel_action_label={"No"}
          onConfirm={(e) => handleSubCompetencyConfirm()}
          onCancel={(e) => handleCancelSubCompetency()}
          confirm_class="bars-confimation-action"
        ></BarsDeleteConfirmation>

      </Modal>

      <Modal
        visible={importModalVisible}
        footer={null}
        centered
        destroyOnClose={true}
        closable={false}
      >
        <BarsDeleteConfirmation
          message={"Would you like to import the rating scale from the previously defined competency? You will be able to proceed only after editing the rating scale for the sub-competency."}
          confirm_action_label={"Yes"}
          cancel_action_label={"No"}
          onConfirm={(e) => handleImportCompetency()}
          onCancel={(e) => setImportModalVisible(false)}
          confirm_class="bars-confimation-action"
          cancel_class="bars-cancel-action"
        ></BarsDeleteConfirmation>

      </Modal>

    </div>

  );
};

export default BarsSubCompetencyCreateView;

