import React from 'react';
import ROUTE from './route';
import CreateConversationContainer from './container/CreateConversationContainer';
import GrowHomeContainer from './container/GrowHomeContainer';
import ConversationDetailContainer from './container/ConversationDetailContainer';
export default [
    {
      name: 'grow_create',
      path: ROUTE.grow_create,
      component: CreateConversationContainer,
      exact:true,
      protect:true
    },
    {
      name: 'grow_conversation',
      path: ROUTE.grow_home,
      component: GrowHomeContainer,
      exact:true,
      protect:true
    },
    {
      name: 'grow_conversation_detail',
      path: ROUTE.grow_detail,
      component: ConversationDetailContainer,
      exact:true,
      protect:true
    }
]