// @ts-nocheck
import React from "react";
import { Input, Select, Button, Switch,Spin } from "antd";
import styled from "styled-components";
import { validateEmail } from "modules/look";
import close_icon from '../../../assets/dashboard-kudos-close-icon.svg';
const CreateInvitationMultippleEmployeeView = (props) => {

    const { cancel, sendMultippleInvitation,loading,error_on_mutation } = props;
    const [attach_message, SetAttachMessage] = React.useState(false)
    const [invitation_message_attachment, setInvitationMessage] = React.useState()
    let node_ref = React.useRef(1)
    const [formData, setFormData] = React.useState([
        { email: "", firstname: "", lastname: "", respondent: "",invited:false,error:false,error_message:"",node:node_ref?.current||1 },
    ]);
    const [successTag,SetSuccessTag] = React.useState([])
    const [showtag,SetShowTag] = React.useState(false)
    const message_content = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,";

    const handleChange = (index, field, value) => {
        const updatedFormData = [...formData];
        updatedFormData[index][field] = field=="email"?value?.trim():value?.trim()==""?"":value;
        setFormData(updatedFormData);
    };

    React.useEffect(()=>{
        if(error_on_mutation?.length){
            try {
                let err_list = error_on_mutation?.map(i=>{
                    return {
                        message:i?.message,
                        index: parseInt(i?.path[0]?.replace('create',''))
                    }
                })
                handleErrorData(err_list)
            } catch (error) {
                
            }
        }
    },[error_on_mutation])

    const handleErrorData =(list)=>{
        let form_list = [].concat(formData)
        form_list.forEach((i,index)=>{
            let error_index = list?.find(er=>er?.index == i?.node)
            if(error_index){
                i.invited=false
                i.error=true
                i.error_message=error_index?.message || "failed to send invitation" 
            }else{
                i.invited=true
                i.error=false
                i.error_message=""
            }
        })
        let success = form_list?.filter(i=>i?.invited)
        let error = form_list?.filter(i=>!i?.invited)
        setFormData(error)
        let success_tag = [...success,...successTag]
        SetSuccessTag(success_tag)
        if(success_tag?.length){
            SetShowTag(true)
        }else{
            SetShowTag(false)
        }
        
    }

    const addForm = () => {
        node_ref.current = node_ref.current + 1
        let form_data = [...formData];
        form_data.push({ email: "", firstname: "", lastname: "", respondent: "" ,invited:false,error:false,error_message:"",node:node_ref.current})
        setFormData(form_data);
    };



    const handleInvitation = () => {
        let data = formData?.filter(i=>!i?.invited)?.map(i=>{
            return {
                ...i,
                message:attach_message?invitation_message_attachment:""
            }
        })
        sendMultippleInvitation(data)
    }

    const checkVlidation = (value) => {
        const firstnameLastnameNotEmpty = value.every(obj =>
            obj.firstname !== '' && obj.lastname !== '' && obj.respondent !== '' && obj.email !== '' && validateEmail(obj?.email)
        );
        return firstnameLastnameNotEmpty
    }

    

    return (
        <Spin spinning={loading}> 
        {
            (successTag?.length && showtag)?
            <div className="marquee-container">
                <div behavior="smooth" direction="" className="marquee-text">
                <span>successfully invited {successTag?.length} users.</span>
                {
                    successTag?.map((user,index)=>(
                        <><span > {user?.email}</span> {(index+1)!=successTag?.length?',':''}</>
                    ))
                }

            </div>
            <img src={close_icon}  onClick={()=>{SetShowTag(false)}} alt="" srcset="" />
            </div>
            :''
        }
        
        <div
            className="invite-main-content-container"
            style={{
                marginTop: "1em",
            }}
        >
            {formData?.map((field, index) => (
                <div
                    key={index}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1em",
                        width: "100%",
                        marginTop:"2em"
                    }}
                >
                    <div className="invite-multipple-employee-list-container">
                        <h4 className="invite-multipple-employee-title">
                            User : {field?.node} 
                            {field?.invited && (
                                <span style={{color:"rgba(76, 203, 31, 1)",marginLeft:"1em",fontSize:"1em"}}>Invitation sent successfully</span>
                            )}
                            {(!field?.invited && field?.error)&& (
                                <span style={{color:"#E86161",marginLeft:"1em",fontSize:"1em"}}>{field?.error_message}</span>
                            )}
                        </h4>
                    </div>
                    <div className="invite-wrap-container">
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: ".5em",
                                width: "100%",
                            }}
                        >
                            <h4 className="survey-invite-form-title">
                                <span className="rd-input-star">*</span>First name
                            </h4>
                            <Input
                                className="survey-invite-form-title-input"
                                placeholder="Enter first name"
                                value={field.firstname}
                                onChange={(e) =>
                                    handleChange(index, "firstname", e.target.value)
                                }
                                autoComplete="off"
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: ".5em",
                                width: "100%",
                            }}
                        >
                            <h4 className="survey-invite-form-title">
                                <span className="rd-input-star">*</span>Last name
                            </h4>
                            <Input
                                className="survey-invite-form-title-input"
                                placeholder="Enter last name"
                                value={field.lastname}
                                onChange={(e) =>
                                    handleChange(index, "lastname", e.target.value)
                                }
                                autoComplete="off"
                            />
                        </div>
                    </div>
                    <div className="invite-wrap-container">
                    <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: ".5em",
                                width: "100%",

                            }}
                        >
                            <h4 className="survey-invite-form-title">
                                <span className="rd-input-star">*</span>Email
                            </h4>
                            <Input
                                className="survey-invite-form-title-input"
                                placeholder="Enter Email"
                                value={field.email}
                                onChange={(e) =>
                                    handleChange(index, "email", e.target.value)
                                }
                                autoComplete="off"
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: ".5em",
                                width: "100%",
                            }}
                        >
                            <h4 className="survey-invite-form-title">
                                <span className="rd-input-star">*</span>Respondent is my
                            </h4>
                            <CustomSelect
                                onChange={(e) =>
                                    handleChange(index, "respondent", e)
                                }
                                placeholder="Select one"
                            >
                                <Select.Option
                                    className="dashobard-performance-table-heading"
                                    style={{
                                        color: "#A3A3A3",
                                        fontSize: "1em",
                                        fontWeight: "500",
                                    }}
                                    key="Manager"
                                    value="Manager"
                                >
                                    Manager
                                </Select.Option>
                                <Select.Option
                                    className="dashobard-performance-table-heading"
                                    style={{
                                        color: "#A3A3A3",
                                        fontSize: "1em",
                                        fontWeight: "500",
                                    }}
                                    key="Manager of Manager"
                                    value="Manager of Manager"
                                >
                                    Manager of Manager
                                </Select.Option>
                                <Select.Option
                                    className="dashobard-performance-table-heading"
                                    style={{
                                        color: "#A3A3A3",
                                        fontSize: "1em",
                                        fontWeight: "500",
                                    }}
                                    key="Report"
                                    value="Report"
                                >
                                    Report
                                </Select.Option>
                            </CustomSelect>
                        </div>
                    
                    </div>

                </div>
            ))}
            <div style={{display:"flex",justifyContent:"space-between",alignContent:"center"}}>
                <button
                    className="user-board-welcome-banner-button"
                    style={{
                        height: "2.5em",
                        fontSize: "1.1em",
                        width: "6em",
                        cursor: "pointer",
                    }}
                    onClick={addForm}
                >
                    + Add
                </button>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: ".5em",
                        width: "50%",
                        paddingLeft:"1em"
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "start",
                            alignItems: "center",
                            gap: "1em",
                        }}
                    >
                        <h4
                            className="survey-invite-form-title"
                            style={{ margin: "0px" }}
                        >
                            Attach message
                        </h4>
                        <Switch onChange={(e) => SetAttachMessage(e)} />
                    </div>
                    {/* <p className="invite-message-content">{message_content}</p> */}
                   {attach_message?<textarea
                        className="survey-invite-form-title-input"
                        placeholder="Message"
                        style={{ height: "7em", padding: ".5em", resize: "none",outline:"none" }}
                        value={invitation_message_attachment}
                        onChange={e => (setInvitationMessage(e.target.value?.trim()==""?"":e?.target?.value))}
                        autoComplete="off"
                    />:null}
                </div>
            </div>

            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "end",
                    alignItems: "center",
                    gap: "1em",
                    width: "100%",
                }}
            >
                <Button className="survey-invite-cancel-btn" onClick={() => cancel()}>
                    CANCEL
                </Button>
                <Button
                    className="survey-invite-send-btn"
                    disabled={!checkVlidation(formData)}
                    onClick={() => handleInvitation()}
                >
                    SEND INVITE
                </Button>
            </div>
        </div>
        </Spin>
    );
};

export default CreateInvitationMultippleEmployeeView;

const CustomSelect = styled(Select)`
  .ant-select-selector {
    border: 1px solid rgba(170, 186, 198, 1) !impoortant;
    color: #000;
    font-weight: 400;
    font-family: Poppins;
    font-size: 1em;
    line-height: normal;
    letter-spacing: 0.01em;
    text-align: left;
    border-radius: 0.5em !important;
    width: 100% !important;
    height: 2.7em !important;
  }
`;
