import gql from 'graphql-tag';
import { TAG_TYPE } from './TagType.gql';

export const STORY_TAG_SUBSCRIPTION = gql`
  ${TAG_TYPE}
  subscription storyTagSubscription {
    storyTagSubscription {
      mutation
      tag {
        ...TagType
      }
    }
  }
`;
