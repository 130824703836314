import { getIntFromString, ReactMarkdown } from 'modules/look';
import React from 'react';
import styled from 'styled-components';
import { Button,Table } from 'antd';
const SurveyChildExpansion = props => {
    const { selectSurvey,record } = props
    const [dataSource, setDataSource] = React.useState([]);

    React.useEffect(()=>{
        if(record){
            
            let list = record?.survey?.edges.map(item=>{
                return {
                  id:item?.node?.id,
                  name:item?.node?.name,
                  key:item?.node?.id
                }
              })
            
            setDataSource(list)
        }
    },[record])

    const columns = [

        {
          title: <h4 >Survey Name</h4>,
          key: 'Name',
          render: (text, record) => record &&
            <ReactMarkdown>{record?.name}</ReactMarkdown>
        },
        {
            title: '',
            key: 'Actions',
            render: (text, record) => record &&
            <div align="end">
                <Button type='primary' onClick={()=>{selectSurvey(record?.key)}}>Take Survey</Button>
            </div>
              
        }
    
      ];
    
    return (
        <>
        <MyTable
          size={'middle'}
          pagination={false}
          showHeader={false}
          dataSource={
            dataSource
              ? dataSource.map((item) => item)
              : []
          }
          // bordered
          columns={columns.map(c => ({ ...c, sortDirections: ['descend', 'ascend', 'descend'] }))}
        
  
        />
      </>
    )
}

export default SurveyChildExpansion;

const MyTable = styled(Table)`
  table {
    border-spacing: 0 1em;
    tbody {
      tr {
        td {
          border: 1px solid rgba(0, 0, 0, 0.06) !important;
          border-style: solid none !important;
        }
        td:first-child {
          border-left-style: solid !important;
          border-top-left-radius: 5px !important;
          border-bottom-left-radius: 5px !important;
        }
        td:last-child {
          border-right-style: solid !important;
          border-bottom-right-radius: 5px !important;
          border-top-right-radius: 5px !important;
          border-bottom: 1px solid rgba(0, 0, 0, 0.06) !important;
        }
      }
    }
  }
`;