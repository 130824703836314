// @ts-nocheck
import { Modal, Row } from "antd";
import { Button } from "antd/lib/radio";
import React from "react";
import Agenda_close from '../../assets/agenda-close.svg'


const MeetingEndedModal = props => {
    const { meetingEnded,setMeetingEnded,setHideButtonPanel,handleSubmit } = props



    return (
        <>

            <Modal visible={meetingEnded}
                footer={null}
                centered
                destroyOnClose={true}
                closable={false}>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", position: "relative" }}>
                    <img src={Agenda_close} alt="" style={{ position: "absolute", top: "0px", right: "0px", cursor: "pointer" }} onClick={() => setMeetingEnded(false)} />
                    <h2 className="one-on-one-meeting-popup-head poppins">THIS 1:1 MEETING HAS ENDED</h2>
                    <p className="one-on-one-meeting-popup-para " style={{ marginTop: "10px" }}>Do you want to save this 1:1 ?</p>
                    <Row justify="center" align="center" style={{ gap: "10px", marginTop: "10px" }} className="meeting-enede-button-panel">
                        <Button onClick={() => { setMeetingEnded(false); setHideButtonPanel(false) }} style={{borderRadius:'8px',borderLeft:'1px solid #8C8C8C'}} className="one-on-one-cancel-btn-modal">No</Button>
                        <Button onClick={() => handleSubmit()} className="one-on-one-save-btn-modal"  style={{borderRadius:'8px'}} >Yes</Button>
                    </Row>
                </div>

            </Modal>


        </>
    )
};

export default MeetingEndedModal;


