import gql from 'graphql-tag';

export const RECIVED_AWARD_LIST = gql`
query badgeAwards($recipient: ID,$badge_Title_Icontains: String){
    badgeAwards(recipient: $recipient,badge_Title_Icontains:$badge_Title_Icontains) {
        edges {
          node {
            id   
            issuer {    
              id    
              user{
                id
                firstName
                lastName
              }
            }    
            recipient {    
              id    
              user{
                id
                firstName
                lastName
              }
            }    
            evidence   
            expirationDate    
            badge{   
              id 
              image    
              badgeFamily {
                id
                title
              }
              title    
              behaviouralDescription   
            }   
          }    
        }    
    }
}
`