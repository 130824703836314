// DO NOT EDIT THIS FILE

import gql from 'graphql-tag';

export const COLLAB_ALL_TEAMS = gql`
  query Teams($search:String) {
    collab:Teams(employees_User_FirstName_Icontains:$search) {
        edges {
          node {
            id
            employees(user_FirstName:$search){
              edges{
                node{
                  id
                  user{
                    id
                    firstName
                    lastName
                  }
                }
              }
            }
          }
        }
      }
  }
`;
