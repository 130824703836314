import React from 'react';
import { Spin, Row, Col, Button, Divider, message, Input, Dropdown, Menu } from 'antd';
import { MenuFoldOutlined, ArrowLeftOutlined, PlusOutlined, UndoOutlined } from '@ant-design/icons';
import { Link, withRouter } from 'react-router-dom';

import EditIcon from './EditIcon';
import DeleteIcon from './DeleteIcon';
import RenderTable from './RenderTable';
import { getIntFromString } from './functions';
import styled from 'styled-components';
import dropdown_Arrow from '../../assets/dropdown_Arrow.svg'
const RenderTableWithLayout = props => {
  const {
    history,
    title,
    loading,
    tableData,
    columns,
    onDelete,
    addLink,
    handleDrawer,
    showBack = false,
    extra,
    onFiltersRemove,
    onPaginationChange,
    rowKey = 'id',
    editLink,
    components,
    onSort,
    currentPage,
    currentSize,
    specificMutation,
    handleDeleteRecord,
    show_search_input,
    onSearchTable,
    view_more_pagination,
    onViewMore,
    table_tabs,
    selected_table_tab,
    set_table_tab,
    search_filter,
    selected_search_filter,
    SetSelectedSearchFilter
  } = props;
  const [deleteLoading, setDeleteLoading] = React.useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
  const isUser = Boolean(addLink || editLink);
  const handleFilterRemove = React.useRef(() => { });
  const [search, setSearch] = React.useState(null);
  
  React.useEffect(()=>{
    if(search!=null){
      onSearch(search);
    }
  },[search])

  React.useEffect(()=>{
    if(search){
      onSearchTable(search)
    }
  },[selected_search_filter])

  handleFilterRemove.current = () => {
    onFiltersRemove();
  };

  React.useEffect(() => {
    return () => handleFilterRemove.current();
  }, []);

  const handleDelete = async () => {
    setDeleteLoading(true);
    try {
      await Promise.all(
        selectedRowKeys.map(async i => {
          if (specificMutation) {
            handleDeleteRecord(i)
          } else {
            await onDelete(i)

          }
        })
      );
    } catch (e) {
      throw new Error(e.message);
    }
    setDeleteLoading(false);
  };

  const search_api_debounce = React.useRef()
  const onSearch = (text) => {
    if (search_api_debounce.current) {
      clearTimeout(search_api_debounce.current)
    }
    search_api_debounce.current = setTimeout(() => {
      onSearchTable(text)
    }, 1000);
  }

  React.useEffect(() => {
    return () => {
      // Code to be executed when the component is unmounted
      if (search_api_debounce.current) {
        clearTimeout(search_api_debounce.current)
      }
    };
  }, []);

  const Filtermenu =()=> (
    <Menu style={{maxHeight:'200px',overflow:'auto'}} >
      {
        search_filter && (
          search_filter?.map((data)=>
          <Menu.Item key={data?.key} onClick={()=>{SetSelectedSearchFilter(data)}}>
            {data?.label}
          </Menu.Item>
          )
        )
      }
    </Menu>
  );

  return (
    <Spin spinning={deleteLoading} size="large">
      <Row>
        <Col xs={4} lg={0}>
          <MenuFoldOutlined
            onClick={handleDrawer}
            style={{
              padding: '12px 0px 0px 24px',
              display: 'flex',
              fontSize: '18px',
              lineHeight: '64px',
              cursor: 'pointer',
              transition: 'color 0.3s'
            }}
          />
        </Col>
        <Col xs={17} lg={showBack ? (isUser ? 21 : 19) : isUser ? 23 : 21}>
          <h2>{title}</h2>
        </Col>
        {!isUser && (
          <Col lg={3} md={3} xs={2} align="right">
            <Button
              icon={<UndoOutlined />}
              shape="circle"
              type="primary"
              ghost
              disabled={tableData && tableData.totalCount < 1}
              onClick={() => {
                onFiltersRemove && onFiltersRemove();
              }}
            />
            <Divider type="vertical" />
            <DeleteIcon size="md" disabled={!(onDelete && selectedRowKeys.length > 0)} onClick={handleDelete} />
          </Col>
        )}
        {showBack && (
          <Col xs={0} lg={2} align="right">
            <Button shape="circle" onClick={() => history.goBack()}>
              <ArrowLeftOutlined />
            </Button>
            {' Back'}
          </Col>
        )}
      </Row>
      <Row>
        <Col lg={24} xs={23}>
          {isUser && (
            <Row style={{ width: "100%", display: "flex", flexDirection: "row", gap: "16px", flexWrap: "nowrap" }} >
              <div style={{ width: "100%", display: "flex", flexDirection: "row", gap: "16px" }}>
                {/* <Col lg={extra ? 5 : 19} md={extra ? 2 : 20} xs={extra ? 3 : 20}> */}
                <Button disabled={!addLink} size="medium" type="primary" ghost>
                  <Link to={addLink}>
                    <PlusOutlined />
                    Add {title}
                  </Link>
                </Button>
                {/* </Col> */}
                {extra && (
                  // <Col lg={extra ? 14 : 0} xs={extra ? 18 : 0}>
                  <>{extra}</>
                  // </Col>
                )}
              </div>
              <div style={{ width: "100%", display: "flex", flexDirection: "row", gap: "16px" }}>
                {
                  !show_search_input ?
                    <div style={{ width: "100%", display: "flex", flexDirection: "row", gap: "16px", justifyContent: "end" }}>
                      <Button
                        icon={<UndoOutlined />}
                        shape="circle"
                        type="primary"
                        ghost
                        disabled={tableData && tableData.totalCount < 1}
                        onClick={() => {
                          onFiltersRemove && onFiltersRemove();
                        }}
                      />
                      <Divider type="vertical" />
                      <EditIcon
                        disabled={!(editLink && selectedRowKeys.length === 1)}
                        href={
                          selectedRowKeys[0] &&
                          `${editLink}${rowKey === 'index'
                            ? getIntFromString(tableData?.edges?.map(({ node }) => node)[selectedRowKeys[0] - 1]['id'])
                            : selectedRowKeys[0]
                          }`
                        }
                      />
                      <Divider type="vertical" />
                      <DeleteIcon size="md" disabled={!(onDelete && selectedRowKeys.length > 0)} onClick={handleDelete} />
                    </div>
                    : <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
                      <Input placeholder='Search' onChange={(e) => { setSearch(e?.target?.value) }} value={search} style={{ width: "250px", maxWidth: "100%",borderRadius: "12px 0px 0px 12px" }} ></Input>
                      {
                        (search_filter?.length && SetSelectedSearchFilter && SetSelectedSearchFilter) ?
                          <Dropdown overlay={Filtermenu} overlayStyle={{ zIndex: "1050" }} style={{ border: '1px solid #D9D9D9', color: '#BBBBBB' }} trigger="click" className="filter-drop">
                            <DropdownButton style={{ justifyContent: "center", borderRadius: "0px 12px 12px 0px" }}>
                              <p className='selected-filter' style={{ marginTop: '13px', width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'left', paddingTop: "2px" }}>{selected_search_filter?.label}</p>
                              <img src={dropdown_Arrow} alt="" style={{ width: "13px", height: "10px" }} />
                            </DropdownButton>
                          </Dropdown>
                          : ""
                      }
                    </div>
                }
              </div>

            </Row>
          )}
          <br />
          {
            table_tabs?.length ?
              <div className="idp-switch-tab" style={{ margin: "16px 0px" }}>
                {table_tabs?.map(i => (
                  <div
                    className={`idp-switch-tab-text ${selected_table_tab?.key === i?.key ? "idp-switch-tab-selected" : ""}`}
                    onClick={() => set_table_tab(i)}
                  >
                    {i?.label}
                  </div>
                ))}
              </div>
              : ''
          }


          <RenderTable
            tableData={tableData}
            loading={loading}
            rowKey={rowKey}
            onPaginationChange={onPaginationChange}
            columns={columns}
            selectedRowKeys={selectedRowKeys}
            handleChange={setSelectedRowKeys}
            components={components}
            onSort={onSort}
            currentPage={currentPage}
            currentSize={currentSize}
            {...props}
          />
          {
            view_more_pagination && (
              <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <h4 onClick={() => onViewMore()} className='pagination-label' style={{ cursor: "pointer", color: "#B0CB1F" }}>View More</h4>
              </div>
            )
          }

        </Col>
      </Row>
    </Spin>
  );
};

export default withRouter(RenderTableWithLayout);

const DropdownButton = styled(Button)`
width:140px !important;
display:flex;
height:36px;
justify-content:space-between;
align-items:center;
gap:5px
`
