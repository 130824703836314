import gql from "graphql-tag";

export const MINE_OKRS = gql`
query contributors($employee:ID!)
{
   mineOkrs:contributors(employee:$employee) {
     edges {
       node {
         id
          role
        okr {
          id
          title
        }
       }
     }
   }
}
`;



export const PAST_OKRS = gql`
query($duedate_Date_Lt:DateTime,$user:ID!){
    pastOkrs:okrs(duedate_Date_Lt:$duedate_Date_Lt) {
     edgeCount
      edges {
        node {
          id
         title
         duedate
         contributors(user:$user) {
           edges {
             node {
               id
               user {
                 id
                 firstName
                 lastName
               }
             }
           }
         }
        }
      }
    }
   }
   `;