import gql from 'graphql-tag';
import { EMPLOYEE_TYPE } from './EmployeeType.gql';

export const EMPLOYEE_SUBSCRIPTION = gql`
  ${EMPLOYEE_TYPE}
  subscription employeeSubscription {
    employeeSubscription {
      mutation
      employee {
        ...EmployeeType
      }
    }
  }
`;
