import gql from 'graphql-tag';

export const OKR_BY_ID_QUERY = gql`
query okrById($id: ID!,$tz: String,$requestRaisedBy:ID){
  okrById(id:$id){
    id
    goalStatement
    duedate
    correctionDeadline
    progress
    pending: discardRequest(status_Iexact:"PENDING",orderBy:["createdAt"]) {
      totalCount
      edges {
        node {
          id
          status
          initiatorsRational
          initiator {
            id
            user {
              id
              firstName
              lastName
            }
          }
      createdAt
        }
      }
    }
    discardRequestRaised:discardRequest(initiator:$requestRaisedBy){
      totalCount
     }
     disapproved: discardRequest(status_Iexact:"DISAPPROVED",orderBy:["createdAt"]) {
      totalCount
      edges {
        node {
          id
          status
        }
      }
    }
    contributorSet {
      edges {
        node {
          id
          role
          employee {
            id
            user{
              id
              firstName
              lastName
            }
           
          }
          requestStatus
        }
      }
    }
    correctionDeadline
    startdate
    isDiscarded {
      id
      status
      initiatorsRational
      initiator {
        id
        user {
          id
          firstName
          lastName
        }
      }
      createdAt
      actedOn
    }
    title(tz:$tz)
    childSet {
      edges {
        node {
          id
          title
          contributors {
            edges {
              node {
                id
                user{
                  id
                  firstName
                }
              }
            }
          }
        }
      }
    }
    parent{
      id
      title
      goalStatement
      startdate
      duedate
    }
    isDiscarded{
      id
      isDiscarded
    }
    correctionDeadline
    createdAt
    updatedAt
    okrType
    okrTypeId
    owner{
      id
      user{
        id
        firstName
        lastName
        employee{
          id
        }
      }
    }
  }
}
`
