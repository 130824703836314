import gql from "graphql-tag";

export const SURVEY_360_REPORTS = gql`
query responseByResponseId($id:ID!){
    responseByResponseId(id:$id){
        id
        survey{
          id
          name
        }
        report360
      }
}
`;