import React from "react";
import { Spin } from "antd";

import { compose } from "../../core";

import EditStoryView from "../components/EditStoryView";
import { ButtonsCatch, getIntFromString, withMarkdownFieldValue } from "../../look";
import ROUTE from "../route";

import { withEditStory, withStoryById } from "./StoryOperations";
import { CKEditorFieldName } from "../components/StoryFormComponent";

const EditStory = props => {
  const { history, editStory, storyById } = props;
  const [submitLoading, setSubmitLoading] = React.useState(false);

  const handleSubmit = async (values, item) => {
    setSubmitLoading(true);
    const { storyData, storyTags } = values;
    try {
      const response = await editStory(
        withMarkdownFieldValue(CKEditorFieldName[1], { id: getIntFromString(storyById.id), ...storyData, storyTags })
      );

      response &&
        ButtonsCatch(
          item,
          `${ROUTE.adminStoryDetailLink}${getIntFromString(response.id)}`,
          history,
          item === undefined ? window.location.pathname : `${ROUTE.addStory}`
        );
    } catch (e) {
      throw new Error(e.message);
    }
    setSubmitLoading(false);
  };

  return (
    <Spin spinning={submitLoading} size="large">
      <EditStoryView onSubmit={handleSubmit} {...props} />
    </Spin>
  );
};

export default compose(withStoryById, withEditStory)(EditStory);
