import React from "react";
import ScapeReportHomeView from "../components/scape-report-home-view";
import { ALL_EMPLOYEE_LIST, ALL_ORGANIZTION_LEVEL_LIST, ALL_ORGANIZTION_LEVEL_LIST_FOR_CEO_AND_POC, SCAPE_BY_ID_QUERY, SCAPE_LIST, TABLE_DATA_NEW_QUERY } from "../graphql/scapeQueryList.gql";
import { withApollo } from '@apollo/client/react/hoc'
import { compose } from 'modules/core'
import { getIntFromString, globalPermissionValidator } from "modules/look";
import { scape_report_permission } from "Permissions/ScapeReport.permission";
import NoPermissionView from "modules/kudos-user/component/NoPermissionComponent";
import { SCAPE_PERSONALITY_SURVEY_ID } from "config";
import { withToolById } from "modules/tool/containers/ToolOperations";
import { Spin } from "antd";
import { EMPLOYEE_USER_COUNT } from "modules/page/graphql/userCount.gql";
import { log } from "@craco/craco/lib/logger";

const ScapeReportHomeContiner = (props) => {

    const { client, me, permissionLoader, userPermission } = props;
    const [scape_list, setScapeList] = React.useState()
    const [organization_level_list, setOrganizatinLevelList] = React.useState()
    const [parametric_groups, setParametricGroups] = React.useState([])
    const [table_data, setTableData] = React.useState()
    const [data_loading, setDataLoading] = React.useState(false)
    const [organization_loading, setOrganizationLoading] = React.useState(false)
    const [scapeReportPermitted, setScapeReportPermitted] = React.useState();
    const [selected_survey_id, setSelectedSurvey] = React.useState()
    const [paginationData, setPaginationData] = React.useState({})
    const [group, SetGroup] = React.useState()
    const employee_list_ref = React.useRef([])
    const [main_loading, setMainLoading] = React.useState(false)
    const [managerPermission, SetmanagerPermission] = React.useState();
    React.useEffect(() => {

        if (userPermission?.length && managerPermission) {
            let permission
            if (managerPermission?.permission) {
                permission = globalPermissionValidator(scape_report_permission, userPermission)
            } else {
                permission = { required_permission: false }
            }

            setScapeReportPermitted(permission)
            if (permission?.required_permission) {
                featchScapeList()
                if (me?.employee?.orgCeoEmployee?.totalCount > 0 || me?.employee?.orgPocEmployee?.totalCount > 0) {
                    featchOrganizationLevelsforCeoandPoc(me?.employee?.organizationSet?.edges[0]?.node?.id)
                    featchOrganizationLevels(me?.employee?.organizationSet?.edges[0]?.node?.id)
                }
                else {
                    featchOrganizationLevels(me?.employee?.organizationSet?.edges[0]?.node?.id)
                }
            }
        }

    }, [userPermission, me, managerPermission])

    React.useEffect(() => {
        if (me) {
            getEmployeeData()
        }
    }, [me])

    //check user permissions
    const getEmployeeData = async () => {
        const { data } = await client.query({
            query: EMPLOYEE_USER_COUNT,
        });

        if (data?.me) {
            let Detail = data?.me?.employee
            if (Detail?.orgCeoEmployee?.totalCount > 0 || Detail?.orgPocEmployee?.totalCount > 0 || Detail?.teamManagerEmployee?.totalCount || Detail?.verticalHeadEmployee?.totalCount) {
                SetmanagerPermission({ permission: true })
            }
            else {
                SetmanagerPermission({ permission: false })
            }
        }
    }

    // Query call for organization filter
    const featchOrganizationLevels = async (id) => {
        setOrganizationLoading(true)
        const { data } = await client.query({
            query: ALL_ORGANIZTION_LEVEL_LIST,
            fetchPolicy: 'network-only',
            variables: { organization: id }
        });
        if (data?.verticals) {
            let list = data.verticals?.edges?.map(({ node }) => node)
            var setObj = new Set();
            let org_data = list.reduce((acc, item) => {
                if (!setObj.has(item?.id)) {
                    setObj.add(item?.id)
                    acc.push(item)
                }
                return acc;
            }, [])
            console.log("lists!!!",org_data);
            setOrganizatinLevelList(org_data)
            setOrganizationLoading(false)
        }
    }
    const featchOrganizationLevelsforCeoandPoc = async (id) => {
        setOrganizationLoading(true)
        const { data } = await client.query({
            query: ALL_ORGANIZTION_LEVEL_LIST_FOR_CEO_AND_POC,
            fetchPolicy: 'network-only',
            variables: { id: id }
        });
        if (data?.organizationById) {
            let list = data.organizationById?.employees?.edges?.map(({ node }) => node)
           
            let vertical_lists=  [].concat.apply([], list?.map(item=>item?.verticalMembersEmployee?.edges?.map(({node})=>node)))
            var setObj = new Set();
            let final_verical_list = vertical_lists.reduce((acc, item) => {
                if (!setObj.has(item?.id)) {
                    setObj.add(item?.id)
                    acc.push(item)
                }
                return acc;
            }, [])
            console.log("lists",list,"vertical_lists",final_verical_list);
            // setOrganizatinLevelList(org_data)
            setOrganizationLoading(false)
        }
    }
    const getAllEmployeeList = async () => {
        const { data } = await client.query({
            query: ALL_EMPLOYEE_LIST,
            fetchPolicy: 'network-only',
            variables: {},
        });
        if (data) {
            let list
            let teamMembersEmployee = [].concat.apply(
                [], data?.me?.employee?.teamMembersEmployee?.edges?.map(({ node }) => node?.managers?.edges?.map(({ node }) => node)))
            let teamManager = [].concat.apply(
                [], data?.me?.employee?.teamManagerEmployee?.edges?.map(({ node }) => node?.vertical?.heads?.edges?.map(({ node }) => node)))
            let teamManagerEmployee = [].concat.apply(
                [], data?.me?.employee?.teamManagerEmployee?.edges?.map(({ node }) => node?.employees?.edges?.map(({ node }) => node)))
            let orgPocEmployee = [].concat.apply(
                [], data?.me?.employee?.orgPocEmployee?.edges?.map(({ node }) => node?.employees?.edges?.map(({ node }) => node)))
            let orgCeoEmployee = [].concat.apply(
                [], data?.me?.employee?.orgCeoEmployee?.edges?.map(({ node }) => node?.employees?.edges?.map(({ node }) => node)))

            list = [].concat(teamMembersEmployee, teamManager, teamManagerEmployee, orgPocEmployee, orgCeoEmployee)
            let datalist = list.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t?.firstName === value?.firstName && t?.id === value?.id
                )))
            let filtered = datalist.filter(item => item?.employee?.id !== me?.employee?.id)
            console.log("filtered",filtered)
            // setEmployeeList(filtered?.filter(item => item !== undefined))

        }
    }
    // Query call for all scape list
    const featchScapeList = async () => {
        const { data } = await client.query({
            query: SCAPE_LIST,
            fetchPolicy: 'network-only',
        });
        if (data?.scapes) {
            let list = data.scapes?.edges?.map(({ node }) => node)
            setScapeList(list)
        }
    }

    const changeparameterSet = (data) => {

        let list = data?.map(i => {
            return {
                node: {
                    ...i.node,
                    choice: {
                        ...i?.node?.choice,
                        value: i?.node?.choice?.value?.trim()
                    }
                }
            }
        })
        return { edges: list }
    }

    // Query call for all scape Parametric Groups and parameters list
    const fetchScapeById = async (scape_data) => {
        setDataLoading(true)
        setMainLoading(true)
        setParametricGroups([])
        const { data } = await client.query({
            query: SCAPE_BY_ID_QUERY,
            fetchPolicy: 'network-only',
            variables: { id: scape_data?.scape_id }
        });
        if (data?.scapeById) {
            let parametric_groups = [...data?.scapeById?.parametricgroupSet?.edges]
            let g = data?.scapeById?.parametricgroupSet?.edges?.map(({ node }) => node)
            g = g?.map(i => {
                return {
                    ...i,
                    parameterSet: changeparameterSet(i.parameterSet?.edges)
                }
            })
            SetGroup(g)
            let group = {
                node: {
                    id: "1", name: "Name", parameterSet: {
                        edges:
                            [{ node: { id: "1", show: "ORDER", choice: { id: "1", value: "Name" } } }]
                    }
                }
            }


            parametric_groups.splice(0, 0, group);

            if (data?.scapeById?.survey?.id && getIntFromString(data?.scapeById?.survey?.id) === SCAPE_PERSONALITY_SURVEY_ID) {

                let personalityGroup = {
                    node: {
                        id: "1", name: "Personality", parameterSet: {
                            edges:
                                [{ node: { id: "1", show: "ORDER", choice: { id: "1", value: "Personality" } } }]
                        }
                    }
                }
                parametric_groups.push(personalityGroup)
            }


            setParametricGroups(parametric_groups)
            scapeTableData({ emp_list: scape_data?.emp_list, survey_id: data?.scapeById?.survey?.id })
            setSelectedSurvey(data?.scapeById?.survey?.id)
        }
    }


    // Query call for Employee Scape report filter
    const scapeTableData = async (user_ids, filter) => {
        setDataLoading(true)
        const { data } = await client.query({
            query: TABLE_DATA_NEW_QUERY,
            fetchPolicy: 'network-only',
            variables: { ...filter, id: user_ids?.survey_id, id_In: user_ids?.emp_list?.map(item => getIntFromString(item)).join(',') }
        });
        if (data) {

            let tableData = data?.tableDataNew?.employee?.organizationSet?.edges[0]?.node?.employees?.edges.map(({ node }) => node)
            if (filter?.after) {
                tableData = employee_list_ref.current.concat(tableData)
            }
            setPaginationData({ totalCount: data?.tableDataNew?.employee?.organizationSet?.edges[0]?.node?.employees?.totalCount, pageInfo: { ...data?.tableDataNew?.employee?.organizationSet?.edges[0]?.node?.employees?.pageInfo } })

            tableData = tableData?.map(i => {
                return {
                    ...i,
                    user: {
                        ...i?.user,
                        responseSet: generateSequnce(i?.user?.responseSet)
                    }
                }
            })
            setTableData(tableData)
            setDataLoading(false)
            setMainLoading(false)
        }
    }

    const generateSequnce = (responseSet) => {
        let res = responseSet?.edges?.map(i => {
            let list = generateAnswerSet(i.node?.answerSet)
            return {
                node: {
                    ...i.node,
                    answerSet: checkForMultichoice(list)
                }
            }
        })

        return { edges: res }
    }

    const generateAnswerSet = (answerSet) => {
        let res = answerSet?.edges?.map(i => {
            return {
                node: {
                    ...i.node,
                    input_type: i.node?.question?.choiceSet?.edges[0]?.node?.inputType || 'TEXT',
                    sequence: i.node.sequence || 1
                }
            }
        })
        return { edges: res }
    }

    const checkForMultichoice = (data) => {

        let list = []
        data.edges.forEach(i => {
            if (i?.node?.input_type == "CHECKBOX") {
                let answer_list = i.node.answer?.split(',')?.map(t => t?.trim())?.filter(f => f)
                answer_list.forEach(j => {
                    list.push({
                        node: {
                            ...i?.node,
                            answer: j
                        }
                    })
                })
            } else {
                i.node.answer = i.node.answer?.trim()
                list.push(i)
            }
        })
        return { edges: list }
    }
    return (
        <>
            {
                (scapeReportPermitted && scapeReportPermitted?.required_permission) && (
                    <ScapeReportHomeView {...props}
                        scape_list={scape_list}
                        organization_level_list={organization_level_list}
                        parametric_groups={parametric_groups}
                        getScapeById={(id) => fetchScapeById(id)}
                        table_data={table_data}
                        data_loading={data_loading}
                        organization_loading={organization_loading}
                        selected_survey_id={selected_survey_id}
                        paginationData={paginationData}
                        scapeTableData={(filter) => scapeTableData(filter)}
                        group={group}
                        onFilter={filter => scapeTableData(filter?.id_filter, filter?.search)}
                        main_loading={main_loading}
                    />
                )
            }
            {(scapeReportPermitted && !scapeReportPermitted?.required_permission) && (<NoPermissionView />)}
            {
                !scapeReportPermitted && (
                    <div className="role-no-permission-container">
                        <Spin spinning={true} size="large"></Spin>
                    </div>
                )
            }
        </>
    )

}

export default compose(withApollo)(ScapeReportHomeContiner)