import gql from 'graphql-tag';

export const COMPARE_REQUEST_DELETE_MUTATION = gql`
mutation deleteResponseCompareRequest($id: ID!){
    deleteResponseCompareRequest(id:$id){
        compareRequest{
            id
        }
    }
}
`