import gql from 'graphql-tag';

export const ALL_EMPLOYEE_LEVEL = gql`
  query allEmployeeLevels($name_Icontains: String) {
    allEmployeeLevels(name_Icontains:$name_Icontains){
        edges{
            node{
                id
                name
                description
            }
        }
    }
  }
`;

export const DELETE_EMPLOYEE_MUTATION = gql`
  mutation deleteEmployeeLevel($id: ID!) {
    deleteEmployeeLevel(id:$id) {
        employeeLevel{
          id
        }
    }
  }
`;

export const All_EMPLOYEE_LEVEL_SUBSCRIPTION =gql`
subscription employeeLevelSubscription{
  employeeLevelSubscription {
    mutation
    employeeLevel{
      id
      name
      description
    } 
  }
}
`
// Create Employee Level Mutations
export const CREATE_EMPLOYEE_LEVEL = gql`

  mutation createEmployeeLevel($employeeLevelData:EmployeeLevelInput!) {
    createEmployeeLevel(employeeLevelData: $employeeLevelData) {
        employeeLevel{
            name
            id
        }
    }
  }
`;