// @ts-nocheck
import { Col, Radio, Row, Spin, Tooltip } from "antd";
import DoughntChartNonCascaded from "modules/look-v2/Components/DoughnutChartNonCascaded";
import PaginationDropdown from "modules/look-v2/Components/PaginantionDropdowm";
import PerformanceBar from "modules/look-v2/Components/PerformanceBar";
import React from "react";
import styled from "styled-components";
import InfoCircle from '../../assets/InfoCircle.svg'
import UpcomingMilestonesOrganization from "./UpcommingMileStoneOrganizationView";
import VerticalAndTeamsContainer from '../container/VerticalAndTeamContainer'

const OrganizationDashboardView =(props)=>{
    const {performanceDataloader,organizationDetail,dateRangeTofilter,getPerformanceRecord,getVertovalPerformanceRecord,chartData,getAllNonCascadedObjectives,upcomingMilestones,performanceData,VerticalperformanceData}=props
    const [chartList,setChartList]=React.useState() 
    const [PerformanceTab, setPerformanceTab] = React.useState('Organisation');
    const [PerformanceList,setPerformanceList]=React.useState()
    const [VerticalPerformanceList,setVerticalPerformanceList]=React.useState()
    React.useEffect(()=>{
        if(chartData){
            let list =chartData?.edges?.map(({node})=>node?.ceo?.okrContributorSet?.edges.map((item)=>item))
            let listData={
                edgeCount:chartData?.edgeCount,
                totalCount:chartData?.totalCount,
                edges:list[0]
            }
            setChartList(listData)
        }
    },[chartData])
    React.useEffect(()=>{
        if(performanceData){
            let listData={
                edgeCount:performanceData?.edgeCount,
                totalCount:performanceData?.totalCount,
                edges:performanceData?.edges.map(({node})=>(
                    {
                        id:node?.okr?.id,
                        title:node?.okr?.title,
                        progress:node?.progress,
                        isOkr:true,
                        okrType:node?.okrType,

                    }
                )),
               
            }
            setPerformanceList(listData)
        }
    },[performanceData])

    React.useEffect(()=>{
        let vertivalTeam=[]
        if(VerticalperformanceData){
            setVerticalPerformanceList(VerticalperformanceData?.edges)
        }
    },[VerticalperformanceData])

    const CheckNumber=(number)=>{
        if(isNaN(number)){
            return 0
        }
        else{
            if(Number.isInteger(number)){
                return number
            }
            else{
                return number.toFixed(2)
            }
        }
    } 

    const changeTab=(event)=>{
        setPerformanceTab(event?.target?.value)
        if(event?.target?.value==='Verticals' && !VerticalPerformanceList){
            getVertovalPerformanceRecord(5,0,false)
          }
    }

    let current_result_count = React.useRef({
        Organisation:5,
        Verticals:5
    })

    React.useEffect(()=>{
        if(dateRangeTofilter && organizationDetail){
            current_result_count['current']={
                Organisation:5,
                Verticals:5
            }
            setPerformanceTab('Organisation')
            setVerticalPerformanceList(null)
        }
    },[dateRangeTofilter,organizationDetail])

    const filterData=(first)=>{
        if(PerformanceTab==='Organisation'){
            getPerformanceRecord(first,0,false)
          current_result_count['current'].Organisation=first
        }else{
            getVertovalPerformanceRecord(first,0,false)
          current_result_count['current'].Verticals=first
        }
      }

    return(     
        <>
         <Row align="center" className="orgCharts" style={{gap:"30px",width:'100%',display:"flex",flexDirection:"row"}}>
            <Col lg={11} className="chart-container">
                <Spin spinning={!chartList}>
                    <h4 className="chart-title" style={{paddingTop:"20px"}}>Organisation Objectives 
                        <Tooltip title={'Cascaded Objectives'}>
                            <img src={InfoCircle} alt="" style={{cursor:"pointer",marginLeft:"10px"}} />
                        </Tooltip>
                    </h4>
                    <Row align="middle" style={{width:"100%",flexDirection:"column"}}>
                        <DoughntChartNonCascaded
                            {...props}
                            topValue = '135px'
                            rightValue = '92px'
                            fontSize = '14px'
                            responsiveFontSize = '11px'
                            responsiveTopValue = '100px'
                            responsiverightValue = '66px'
                            getNextSetOfNonCascadedOkrs = {(e,offset)=> getAllNonCascadedObjectives(offset,true)}
                            label={'Non cascaded Objectives'}
                            data={chartList}
                            managerids={[organizationDetail?.ceo?.id] || []}
                            loading={!chartList}
                        />
                    </Row>
                </Spin>
            </Col>
            <Col lg={11} className="chart-container " style={{display:"row",justifyContent:'center',alignItems:"center"}}>
                <Spin spinning={!upcomingMilestones}>
                    <UpcomingMilestonesOrganization upcomingMilestones={upcomingMilestones} />
                </Spin>
            </Col>
        </Row>
        <Row align="center" className="orgCharts" style={{gap:"30px",width:'100%',display:"flex",flexDirection:"row",marginTop:"20px"}}>
            <Col lg={14} className="chart-container performanceContainer">
                <Spin spinning={performanceDataloader}>
                    <h4 className="chart-title" style={{textAlign:"left"}}>{organizationDetail?.name}’s OKR Performance</h4>
                    <Row justify="space-between" style={{margin:"20px 0px"}}>
                        <Col style={{marginBottom:"20px"}}>
                            <Radio.Group value={PerformanceTab} onChange={(e) => changeTab(e)}>
                                <RadioButton style={{borderRight:"none",width:"110px"}} className={`section-assign ${PerformanceTab==='Organisation'?'active-assign':''}`} value="Organisation">Organisation</RadioButton>
                                <RadioButton style={{width:"100px"}}  className={`section-collab ${PerformanceTab ==='Verticals'?'active-collab':''}`} value="Verticals">Verticals</RadioButton>
                            </Radio.Group>
                        </Col>
                        {(PerformanceTab==='Organisation'&&PerformanceList?.totalCount)?<PaginationDropdown totalCount={PerformanceList?.totalCount} current={current_result_count['current'].Organisation} onselect={(e)=>{filterData(e)}}/>:''}
                        {(PerformanceTab==='Verticals'&&VerticalperformanceData?.totalCount)?<PaginationDropdown totalCount={VerticalperformanceData?.totalCount} current={current_result_count['current'].Verticals} onselect={(e)=>{filterData(e)}}/>:''}
                        
                    </Row>
                    {PerformanceTab==='Organisation'&&<PerformanceBar loading={performanceDataloader} {...props} performanceData={PerformanceList} type={"Organization_Dashboard"}/>}
                    {PerformanceTab==='Verticals'&&<PerformanceBar loading={performanceDataloader} {...props} performanceData={VerticalPerformanceList}/>}
                </Spin>
            </Col>
            <Col lg={8} className="chart-container">
                <Spin spinning={false}>
                    <VerticalAndTeamsContainer {...props}/>
                </Spin>
            </Col>
        </Row>
        </>
    )
}
const RadioButton = styled(Radio.Button)`
`;

export default OrganizationDashboardView